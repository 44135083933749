<template>
    <div>
        <!-- <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
        </div> -->
    <div>
		<div class="dashboard-content-inner desktop_padding_unset desktop_padding_top_zero"  >
			
			<!-- Dashboard Headline -->
			<!-- <div class="dashboard-headline"> -->
				<!-- <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>View Candidates</h1> -->
				<!-- <h1>View Candidates</h1> -->

				<!-- <span class="margin-top-7">Job Applications for <b-link :to="{name: 'employerJobDetail', params: {id: job_id}}" target="_blank">{{job_title | capitalize}}</b-link></span> -->

				<!-- Breadcrumbs -->
				<!-- <nav id="breadcrumbs" class="dark employer_nav">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">View Candidates</a></li>
						<li>View Candidates</li>
					</ul>
				</nav> -->
			<!-- </div> -->
             <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
			<!-- Row -->
			<div class="row">

				<!-- Dashboard Box -->
				<div class="col-xl-12">
					<div class="dashboard-box margin-top-0 custom_box_border_radius_unset_top_bottom">

						<!-- Headline -->
						
                        <div class="content" v-if="userDatas.length > 0">
                                <ul class="dashboard-box-list box1 view_candidate_in_mobile">
                                    <li v-for="userData in userDatas">
                                        <!-- Overview -->
                                        <div class="freelancer-overview manage-candidates">
                                            <div class="freelancer-overview-inner">
                                                    <div class="view_candidate_in_mobile_status">
                                                        <span class="user_status_emp_new pending_apply_job" v-if="userData.applied_status=='active'">NEW</span>
                                                        <span class="user_status_emp_shortlisted shortlisted_apply_job" v-if="userData.applied_status=='shortlisted'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_hired hired_apply_job" v-if="userData.applied_status=='hired'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_rejected declined_apply_job" v-if="userData.applied_status=='declined'">{{userData.applied_status | capitalizeAll}}</span>
                                                    </div>
                                                <!-- Avatar -->
                                                <div class="freelancer-avatar">
                                                    <div class="verified-badge"></div>
                                                    <span v-if="show('view profile')"><b-link :to="{name: 'jobseeker-dp-profile-view', params: {id: encrypt(userData.applicant_id)}}"><img :src="userData.profile_image ? userData.profile_image :  webUrl+'assets/images/user-avatar-small-01.png'" alt="" class="common_max_width_unset"></b-link></span>
                                                    <span v-else><img :src="userData.profile_image ? userData.profile_image :  webUrl+'assets/images/user-avatar-small-01.png'" alt="" class="common_max_width_unset"></span>
                                                
                                                </div>

                                                <!-- Name -->
                                                <div class="freelancer-name">
                                                    <div class="view_candidate_in_desktop_status">
                                                        <span class="user_status_emp_new pending_apply_job" v-if="userData.applied_status=='active'">NEW</span>
                                                        <span class="user_status_emp_shortlisted shortlisted_apply_job" v-if="userData.applied_status=='shortlisted'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_hired hired_apply_job" v-if="userData.applied_status=='hired'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_rejected declined_apply_job" v-if="userData.applied_status=='declined'">{{userData.applied_status | capitalizeAll}}</span>
                                                    </div>
                                                    <!-- <span class="user_status_emp">{{userData.applied_status == 'active' ? 'NEW' : userData.applied_status | capitalizeAll}}</span> -->
                                                    <h4 class="common_cusror_pointer">
                                                        <!-- <b-link :to="{name: 'jobseeker-profile-view', params: {id: encrypt(userData.applicant_id)}}">{{userData.last_name}} {{userData.first_name}} 
                                                    </b-link> -->
                                                    {{userData.last_name | capitalize}} {{userData.first_name | capitalize}} 
                                                    <span class="flag_pin"><i class="fa fa-mars" v-if="userData.user_gender == 'male'"></i>
                                                    <i class="fa fa-venus" v-else></i></span>
                                                    <span class="salary_emp"  v-if="userData.expected_salary">(${{userData.expected_salary}})</span>
                                                    </h4>


                                                    
                                                    <!-- Details -->
                                                    <span class="freelancer-detail-item common_word_break"><a href="#"><i class="icon-feather-mail common_i_color"></i> {{userData.email}}</a></span>
                                                    <span class="freelancer-detail-item"><i class="icon-feather-phone common_i_color"></i><a href="tel:userData.mobile_number">{{userData.mobile_number}}</a></span>

                                                    <!-- Rating -->
                                                    <!-- <div class="freelancer-rating">
                                                        <div class="star-rating" data-rating="5.0"></div>
                                                    </div> -->

                                                    <!-- Buttons -->
                                                    <div class="buttons-to-right always-visible  margin-bottom-5 emp_inline_btn" v-if="show('view profile') || show('download cv')">
                                                        <div class="manage_candidate_in_admin" v-if="show('view profile')">
                                                            <!-- <a href="javascript:void(11);" @click="viewProfile(encrypt(userData.applicant_id),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i> View Profile </a> -->
                                                            <a :href="'/v2/admin/fulltime-job-fl-profile/view/'+ encrypt(userData.applicant_id)" target=_blank class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i> View Profile </a>
                                                        </div>
                                                        <div class="manage_candidate_in_admin" v-if="show('download cv')"> 
                                                            <a :href="userData.cv" target="_blank" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a>
                                                        </div>
                                                        <!-- <a href="#small-dialog" class="popup-with-zoom-anim button dark ripple-effect"><i class="icon-feather-mail"></i> Send Message</a>
                                                        <b-dropdown class="mx-1" right text="Manage" boundary="window" v-if="userData.applied_status == 'active'">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'shortlisted')">Shortlist</b-dropdown-item>
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <b-dropdown class="mx-1" right text="Manage" boundary="window" v-if="userData.applied_status == 'shortlisted'">
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <b-dropdown class="mx-1" right text="Manage" boundary="window" v-if="userData.applied_status == 'hired'">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <b-dropdown class="mx-1" right text="Manage" boundary="window" v-if="userData.applied_status == 'declined'">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'shortlisted')">Shortlist</b-dropdown-item>
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                        </b-dropdown> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                        <div class="text-center" v-else>
                            <p class="no_record_candidate_padding desktop_margin_bottom_zero"><b>No Record Found</b> </p>
                        </div>
					</div>
				</div>

			</div>
			<!-- Row / End -->
            <div class="clearfix"></div>
            <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input="changeTabStatus">
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                        </b-form-select></span>
                    </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                    <ul>
                        <li>
                            <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                        </li>
                    </ul>
                </div>
                <b-pagination @input="changeTabStatus"   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
            </nav>
            <div class="clearfix"></div>
        <!-- <div class="margin-top-70"></div> -->
		</div>
	</div>
    </div>
</template>

<script>
    import { POST_API } from "../../../store/actions.type"
    import moment from 'moment'
    import permission from "../../../../../server/permission.js";

    export default {

        data() {
            return {
                userDatas: '',
				job_title:'',
				job_id:'',
                activeTab:'active',
                keyword:'',
                error_message:'',
                value: [0, 15000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                new_tab:true,
                shortlisted:false,
                hired:false,
                declined:false,
                all:false,
                activeCount:'',
                shortlistedCount:'',
                hiredCount:'',
                declinedCount:'',
                allCount:'',
                webUrl: process.env.VUE_APP_URL,
                actions:'',

            }
        },
        filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        capitalizeAll: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        },
       
    },

        methods:{   
            breadCrumb(){
                var item = [{
                    to:{name:'fulltime-job-list'},
                    text: 'Job Ads',
                },{
                    to:null,
                    text: 'View Candidates'
                }];
                return item;
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            viewProfile(id,event){
                if (event) {
                event.stopPropagation()
                }
                // window.location.replace('/v2/admin/database/jobseeker-dp-profile/view/'+ id);
                // window.open('/v2/admin/database/jobseeker-dp-profile/view/'+ id, '_blank');
                window.open('/v2/admin/fulltime-job-fl-profile/view/'+ id, '_blank');

            },

            // changeStatus(applicant_id,job_start_date,user_jobs_id, status) {
            // if (status == 'shortlisted') {
            //     var msg = 'Are you sure want to shortlist this record?';
            //     var message='Candidate Shortlisted successfully.';

            // }else if (status == 'hired') {
            //     var msg = 'Are you sure want to hire this record?';
            //     var message='Candidate Hired successfully.';
            // }  
            // else{
            //     var msg = 'Are you sure want to decline this record?';
            //     var message='Candidate Declined successfully.';
            // }
            // this.$swal({
            //     title: 'Please Confirm',
            //     text: msg,
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'No'
            // })
            // .then(result => {
            //     if (result.value) {
            //         return this.$store.dispatch(POST_API, {
            //                 data:{
            //                 applicant_id:applicant_id,   
            //                 user_jobs_id: user_jobs_id,
            //                 status:status,
            //                 job_id:job_id,
            //                 job_title:job_title,
            //                 job_date:job_start_date ? moment(job_start_date).format('DD-MM-YYYY') : null,
            //                 page: this.currentPage,
            //                 keyword: this.filter
            //                 },
            //                 api:'/api/change-candidate-status' 
            //         })
            //         .then(() => {
            //             if (this.$store.getters.containsErrors) {
            //                 this.error_message = this.$store.getters.getErrors;
            //                 this.showAlert();
            //             } else {
            //                 this.showSuccessAlert(message);
            //                 this.changeTabStatus(this.activeTab);
			//                 this.getjobsDataById();
			//                 this.getTotalCount();

            //             }
            //         });
            //     }
            // })
            // .catch(err => {
            // })
            // },
            changeTabStatus() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPage,
                        rowsPerPage:this.form.rowsPerPage,
                        jobId:atob(this.$route.params.id),
            },
                api: '/api/fullTimeJobsViewCandidates'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {

                    this.userDatas = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.userDatas;
                }
            });
            },

			getjobsDataById() {
                return this.$store.dispatch(POST_API, {
                     data:{
                            id: atob(this.$route.params.id),
                },
                    api: '/api/adminJobDataById'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        // this.showAlert();
                        return [];
                    } else {
                        this.job_title = this.$store.getters.getResults.data[0].job_title;
                        this.job_id = this.$store.getters.getResults.data[0].jobId;

                    }
                });
            },
            // getTotalCount() {
            //         return this.$store.dispatch(POST_API, {
            //             data:{
            //                     jobId: atob(this.$route.params.id),
            //         },
            //             api: '/api/candidateTotalCount'
            //         })
            //         .then(() => {
            //             if (this.$store.getters.containsErrors) {
            //                 this.error_message = this.$store.getters.getErrors;
            //                 // this.showAlert();
            //                 return [];
            //             } else {
            //                 this.activeCount = this.$store.getters.getResults.activeCount;
            //                 this.shortlistedCount = this.$store.getters.getResults.shortlistedCount;
            //                 this.hiredCount = this.$store.getters.getResults.hiredCount;
            //                 this.declinedCount = this.$store.getters.getResults.declinedCount;
            //                 this.allCount = this.$store.getters.getResults.allCount;
            //             }
            //         });
            // },
            permissionStaff(){
                if(this.$store.getters.getCurrentUserPermission){
                    if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
                        var menu = this.$store.getters.getCurrentUserPermission.data;
                        this.actions = permission.getPermissionStaff(menu,'View Candidates')
                    }
                }
            },
            show(action){
                if (this.$store.getters.currentUser.user_type_id == 2) {
                    return this.actions.indexOf(action) >= 0 ? true : false ;  
                }else{
                    return true;
                }
            },
        },
         mounted(){
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Admin - Full Time Job - View Candidates";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.changeTabStatus();
			this.getjobsDataById();
            this.permissionStaff();

			// this.getTotalCount();

        }
    }
</script>

