<template>
  <div v-if="visible" class="custom-info-box" :style="styleObject">
    <div class="info-box-content">
      <div class="close_icon_heat_map">
        <button type="button" aria-label="Close" class="close" @click="closeInfoBoxHeatMap()">×</button>
      </div>
      <img :src="content.image" style="width: 30px;" />
      <div style="float: right; margin-left: 8px;">
        <a :href="content.link" target="_blank">
          <b>{{ content.name }}</b>
        </a><br>{{ content.date }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    content: Object,
    position: Object,
  },
  methods:{
    closeInfoBoxHeatMap(){
      $('.vue-map-hidden').removeClass('show_box_info');
    }
  },
  computed: {
    styleObject() {
      return {
        position: 'absolute',
        top: this.position.top + 'px',
        left: this.position.left + 'px',
        zIndex: 9999, // Ensure it's above other elements
      };
    },
  },
  
};
</script>

<style>
.custom-info-box {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.vue-map-hidden .custom-info-box {
    display: block;
    visibility: visible;
    position: absolute; /* Ensure it is positioned correctly */
    z-index: 9999; /* Keep it above other elements */
}
</style>
