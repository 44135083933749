<template>
  <div>
    <!-- Dashboard Headline -->
    <!-- Page Content
        ================================================== -->

    <!--<div class="single-page-header" :data-background-image="jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg'">-->
    <div
      class="mobile_view_jobs_banner bg_img_top new_bg_img_tp new_job_detail_full_dvz"
      v-bind:style="{
        backgroundImage:
          'url(' +
          (jobData.banner_image ? jobData.banner_image : webUrl + 'assets/images/single-job.jpg') +
          ')',
      }"
    ></div>
    <div
      class="mobile_view_jobs bg_img_top new_bg_img_tp new_job_detail_full_dvz"
      v-bind:style="{
        backgroundImage:
          'url(' +
          (jobData.banner_image ? jobData.banner_image : webUrl + 'assets/images/single-job.jpg') +
          ')',
      }"
    >
      <div class="grd_div inside_grd">
        <div class="container mobile_tab_container_hundered">
          <div class="back_arrow_icon new_bak_arr">
            <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="inrs_main">
                <div class="left-side updated_left_side_banner">
                  <div
                    class="header-image updated_header_image_banner updated_image_in_square_shape"
                  >
                    <b-link :to="{ name: 'web-emp-profile-view', params: { id: posted_by } }"
                      ><img
                        :src="
                          jobData.company_logo != null
                            ? jobData.company_logo
                            : webUrl + 'assets/images/company-logo-placeholders.png'
                        "
                        alt="company_logo"
                    /></b-link>
                    <div
                      class="right-side daily_right_side mobile_view_salary"
                      v-if="
                        (min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0
                      "
                    >
                      <div class="right_inner_contents">
                        <div class="salary-box">
                          <div class="salary-type" v-if="job_type == 'part-time'">
                            Per Hour Rate
                          </div>
                          <div class="salary-type" v-else>Salary</div>
                          <div class="salary-amount">${{ min_salary }}-${{ max_salary }}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="header-details updated_header_details_banner">
                    <h1 class="header_detail_new_h1 new_daily_paid_jjobs">
                      {{ title | capitalize }}
                    </h1>
                    <h5>About the Employer</h5>
                    <ul class="margin_top_12 mobile_header_ul">
                      <h4 class="new_h4" v-if="jobData.short_description">
                        {{ jobData.short_description | capitalize }}
                      </h4>
                      <li :class="company_name ? 'margin_right_12' : 'margin_right_zero'">
                        <b-link :to="{ name: 'web-emp-profile-view', params: { id: posted_by } }"
                          ><i class="icon-material-outline-business common_i_color"></i>
                          <span class="company_name_dt highlight">{{
                            company_name | capitalize
                          }}</span></b-link
                        >
                      </li>
                      <!-- <li v-if="company_description">
                                    <p v-if="company_description.length >= 200" v-html="company_description.substring(0,200)+'...' "></p>
                                    <p v-else v-html="company_description"></p>
                                </li> -->
                      <!-- <li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li> -->
                      <!-- <li><img class="flag" src="images/flags/gb.svg" alt="country_flag">{{user_address | capitalize}}</li> -->
                      <li v-if="email_verified == 'yes'">
                        <div class="verified-badge-with-title">Verified</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="right-side daily_right_side desktop_salary"
                  v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0"
                >
                  <div class="right_inner_contents">
                    <div class="salary-box">
                      <div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
                      <div class="salary-type" v-else>Salary</div>
                      <div class="salary-amount">${{ min_salary }}-${{ max_salary }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="single-page-header"
      :data-background-image="
        jobData.banner_image ? jobData.banner_image : webUrl + 'assets/images/single-job.jpg'
      "
      style="display: none"
    >
      <div class="container mobile_tab_container_hundered">
        <div class="back_arrow_icon">
          <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="single-page-header-inner">
              <div class="left-side">
                <div class="header-image">
                  <b-link :to="{ name: 'web-emp-profile-view', params: { id: encrypt(posted_by) } }"
                    ><img
                      :src="
                        profile_image != null
                          ? profile_image
                          : webUrl + 'assets/images/company-logo-placeholders.png'
                      "
                      alt="company_logo"
                  /></b-link>
                </div>
                <div class="header-details">
                  <h3>{{ title | capitalize }}</h3>
                  <h5>About the Employer</h5>
                  <ul>
                    <h4 class="new_h4" v-if="jobData.short_description">
                      {{ jobData.short_description | capitalize }}
                    </h4>
                    <li>
                      <b-link
                        :to="{ name: 'web-emp-profile-view', params: { id: encrypt(posted_by) } }"
                        ><i class="icon-material-outline-business highlight"></i>
                        {{ company_name | capitalize }}</b-link
                      >
                    </li>
                    <!-- <li v-if="company_description">
                                    <p v-if="company_description.length >= 200" v-html="company_description.substring(0,200)+'...' "></p>
                                    <p v-else v-html="company_description"></p>
                                </li> -->
                    <!-- <li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li> -->
                    <!-- <li><img class="flag" src="images/flags/gb.svg" alt="country_flag">{{user_address | capitalize}}</li> -->
                    <li v-if="email_verified == 'yes'">
                      <div class="verified-badge-with-title">Verified</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="right-side"
                v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0"
              >
                <div class="salary-box">
                  <div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
                  <div class="salary-type" v-else>Salary</div>
                  <div class="salary-amount">${{ min_salary }}-${{ max_salary }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container new_detail_bottom_mrgn mobile_tab_container_hundered">
      <div class="row">
        <!-- Content -->
        <div
          class="col-xl-8 col-lg-8 single_left_content tab_max_width_and_flex single_left_content"
        >
          <a
            href="javascript:void(0)"
            class="apply-now-button full_time_nz_hide_desktop_show_mobile"
            @click="$bvModal.show('bv-modal-example-ft-apply')"
            style="color: white"
            v-if="user_type_id != 3 && user_type_id != 4"
            >Apply Now
          </a>

          <div class="single-page-section" v-if="description">
            <h3 class="margin-bottom-25 font_weight_600">Job Description</h3>
            <p v-html="description"></p>
          </div>

          <!-- <div class="single-page-section">
                    <h3 class="margin-bottom-30">Location</h3>
                    {{address | capitalize}}
                    <div id="single-job-map-container">
                        <div id="singleListingMap" data-latitude="51.507717" data-longitude="-0.131095" data-map-icon="im im-icon-Hamburger"></div>
                        <a href="#" id="streetView">Street View</a>
                    </div>
                </div> -->

          <!-- <div class="single-page-section">
                    <h3 class="margin-bottom-25">Similar Jobs</h3>


                    <VueSlickCarousel :arrows="true" :dots="false" v-bind="settings" class="job_detail_slider">
                            <div><a href="#" class="job-listing">

                                <div class="job-listing-details">
                                    <div class="job-listing-company-logo">
                                        <img src="" alt="">
                                    </div>

                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company">test company</h4>
                                        <h3 class="job-listing-title">Barista and Cashier</h3>
                                    </div>
                                </div>

                                <div class="job-listing-footer">
                                    <ul>
                                        <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                        <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                        <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                    </ul>
                                </div>
                            </a></div>
                            <div><a href="#" class="job-listing">

                                <div class="job-listing-details">
                                    <div class="job-listing-company-logo">
                                        <img src="" alt="">
                                    </div>

                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company">test company</h4>
                                        <h3 class="job-listing-title">Barista and Cashier</h3>
                                    </div>
                                </div>

                                <div class="job-listing-footer">
                                    <ul>
                                        <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                        <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                        <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                    </ul>
                                </div>
                            </a></div>
                            <div><a href="#" class="job-listing">

                                <div class="job-listing-details">
                                    <div class="job-listing-company-logo">
                                        <img src="" alt="">
                                    </div>

                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company">test company</h4>
                                        <h3 class="job-listing-title">Barista and Cashier</h3>
                                    </div>
                                </div>

                                <div class="job-listing-footer">
                                    <ul>
                                        <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                        <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                        <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                    </ul>
                                </div>
                            </a></div>
                            <div><a href="#" class="job-listing">

                                <div class="job-listing-details">
                                    <div class="job-listing-company-logo">
                                        <img src="" alt="">
                                    </div>

                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company">test company</h4>
                                        <h3 class="job-listing-title">Barista and Cashier</h3>
                                    </div>
                                </div>

                                <div class="job-listing-footer">
                                    <ul>
                                        <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                        <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                        <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                    </ul>
                                </div>
                            </a></div>
                            <div><a href="#" class="job-listing">

                                <div class="job-listing-details">
                                    <div class="job-listing-company-logo">
                                        <img src="" alt="">
                                    </div>

                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company">test company</h4>
                                        <h3 class="job-listing-title">Barista and Cashier</h3>
                                    </div>
                                </div>

                                <div class="job-listing-footer">
                                    <ul>
                                        <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                        <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                        <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                    </ul>
                                </div>
                            </a></div>
                            <div><a href="#" class="job-listing">

                                <div class="job-listing-details">
                                    <div class="job-listing-company-logo">
                                        <img src="" alt="">
                                    </div>

                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company">test company</h4>
                                        <h3 class="job-listing-title">Barista and Cashier</h3>
                                    </div>
                                </div>

                                <div class="job-listing-footer">
                                    <ul>
                                        <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                        <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                        <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                    </ul>
                                </div>
                            </a></div>
                            <div><a href="#" class="job-listing">

                                <div class="job-listing-details">
                                    <div class="job-listing-company-logo">
                                        <img src="" alt="">
                                    </div>

                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company">test company</h4>
                                        <h3 class="job-listing-title">Barista and Cashier</h3>
                                    </div>
                                </div>

                                <div class="job-listing-footer">
                                    <ul>
                                        <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                        <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                        <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                    </ul>
                                </div>
                            </a></div>
                    </VueSlickCarousel>
                </div> -->
        </div>

        <!-- Sidebar -->
        <div class="col-xl-4 col-lg-4 single_right_sidebar tab_max_width_and_flex">
          <div class="sidebar-container">
            <!-- <a v-if="alreadyApplied == true" class="apply-now-button" style="color: white;background: green;">Already Applied </a> -->

            <!-- Sidebar Widget -->
            <a
              href="javascript:void(0)"
              class="apply-now-button sidebar_apply_now_desktop"
              @click="$bvModal.show('bv-modal-example-ft-apply')"
              style="color: white"
              v-if="user_type_id != 3 && user_type_id != 4"
              >Apply Now
            </a>
            <div
              class="sidebar-widget jobseeker_dp_sidebar mobile_tab_margin_top_zero margin-bottom-25"
            >
              <div class="job-overview">
                <div class="job-overview-headline font_weight_600">Job Summary</div>
                <div class="job-overview-inner">
                  <ul>
                    <li>
                      <i class="icon-material-outline-location-on"></i>
                      <span>Location</span>
                      <h5>{{ address == "Other" ? other_location : address | capitalize }}</h5>
                    </li>
                    <li>
                      <i class="icon-material-outline-business-center"></i>
                      <span>Job Role Type</span>
                      <h5>{{ job_type | customizeRoleType | capitalize }}</h5>
                    </li>
                    <li>
                      <i class="icon-material-outline-business"></i>
                      <span>Job Category</span>
                      <h5>
                        {{
                          jobData.service_categories_name == "Other"
                            ? jobData.other_service_category
                            : jobData.service_categories_name | removeUnderscore
                        }}
                      </h5>
                    </li>
                    <li>
                      <i class="icon-material-outline-school"></i>
                      <span>Minimum Qualification Level</span>
                      <h5>{{ jobData.qualification_title | capitalize }}</h5>
                    </li>
                    <li>
                      <i class="icon-material-outline-watch"></i>
                      <span>Minimum Years of Experience</span>
                      <h5>{{ jobData.experience }}</h5>
                    </li>
                    <li>
                      <i class="icon-material-outline-face"></i>
                      <span>Position Level</span>
                      <h5>{{ jobData.level_title | capitalize }}</h5>
                    </li>
                    <li v-if="min_salary && max_salary">
                      <i class="icon-material-outline-local-atm"></i>
                      <span v-if="job_type == 'part-time'">Per Hour Rate</span>
                      <span v-else>Salary</span>
                      <h5>${{ min_salary }}-${{ max_salary }}</h5>
                    </li>
                    <!-- <li>
                                        <i class="icon-material-outline-face"></i>
                                        <span>Featured</span>
                                        <h5>{{jobData.featured | capitalize}}</h5>
                                    </li> -->
                    <li>
                      <i class="icon-material-outline-access-time"></i>
                      <span>Job Listed Date</span>
                      <h5>{{ posted_date }}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Sidebar Widget -->
            <!-- <div class="sidebar-widget">
                        <h3>Bookmark or Share</h3> -->

            <!-- Bookmark Button -->
            <!-- <button class="bookmark-button margin-bottom-25"> -->
            <!-- <b-form-checkbox :value="jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobId,$event)" :disabled="true"></b-form-checkbox> -->
            <!-- <span class="bookmark-icon"></span>
                            <span class="bookmark-text">Bookmark</span>
                            <span class="bookmarked-text">Bookmarked</span> -->
            <!-- </button> -->

            <!-- Copy URL -->
            <div class="copy-url">
              <input
                id="copy-url"
                type="text"
                value=""
                class="with-border desktop_margin_bottom_zero"
                readonly
              />
              <button
                class="copy-url-button ripple-effect"
                @click="copyURL()"
                data-clipboard-target="#copy-url"
                title="Copy to Clipboard"
                data-tippy-placement="top"
                style="height: 48px"
              >
                <i class="icon-material-outline-file-copy"></i>
              </button>
            </div>

            <div class="share-buttons margin-top-25">
              <div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
              <div class="share-buttons-content">
                <span>Interesting? <strong>Share It!</strong></span>

                <q-btn> </q-btn>

                <ul class="share-buttons-icons">
                  <li style="">
                    <ShareNetwork
                      network="facebook"
                      quote="AwesomeArticle"
                      description="test"
                      :url="this.jobUrl"
                      hashtags="Jobs, SharedJobs"
                    >
                      <!-- <span  style="color:#3b5998"><v-icon class="mr-3" style="color: #3b5998">fab fa-facebook</v-icon></span> -->
                    </ShareNetwork>
                  </li>

                  <li>
                    <ShareNetwork
                      network="twitter"
                      :url="this.jobUrl"
                      title="Please find the shared job by using the given url"
                      hashtags="Jobs, SharedJobs"
                    >
                      <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                    </ShareNetwork>
                  </li>

                  <li>
                    <ShareNetwork
                      network="linkedin"
                      :url="this.jobUrl"
                      title="Please find the shared job by using the given url"
                      description="test"
                      hashtags="Jobs, SharedJobs"
                    >
                      <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                    </ShareNetwork>
                  </li>

                  <!-- <li><a href="#" data-button-color="#3b5998" title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                                    <li><a href="#" data-button-color="#1da1f2" title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                                    <li><a href="#" data-button-color="#dd4b39" title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                                    <li><a href="#" data-button-color="#0077b5" title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li> -->
                </ul>
              </div>
            </div>

            <!-- Share Buttons -->
            <!-- <div class="share-buttons margin-top-25">
                            <div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
                            <div class="share-buttons-content">
                                <span>Interesting? <strong>Share It!</strong></span>
                                <ul class="share-buttons-icons">
                                    <li><a href="#" data-button-color="#3b5998" title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                                    <li><a href="#" data-button-color="#1da1f2" title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                                    <li><a href="#" data-button-color="#dd4b39" title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                                    <li><a href="#" data-button-color="#0077b5" title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div> -->
            <!-- </div> -->

            <div class="task-tags margin-top-15">
              <span v-for="tags in tagArr">{{ tags | capitalize }}</span>
            </div>
          </div>
          <a
            href="javascript:void(0)"
            class="apply-now-button desktop_sidebar_bottom_apply_now"
            @click="$bvModal.show('bv-modal-example-ft-apply')"
            style="color: white"
            v-if="user_type_id != 3 && user_type_id != 4"
            >Apply Now
          </a>
        </div>
      </div>

      <!-- Single Page Job Slider Start-->

      <div class="row" v-if="simJobs.length > 0">
        <div class="col-xl-12 col-lg-12">
          <h3 class="margin-bottom-25 font_weight_600">Similar Jobs</h3>
          <VueSlickCarousel
            :arrows="true"
            :dots="true"
            v-bind="settings"
            ref="carousel"
            v-if="simJobs.length > 0"
            class="web_slider job_detail_slider"
          >
            <div v-for="(si, index) in simJobs" :key="index">
              <a
                href="#"
                @click="jobDetail(encrypt(si.jobId), $event)"
                class="job-listing job_detail_footer_cs"
              >
                <!-- Job Listing Details -->
                <div class="job-listing-details">
                  <!-- Logo -->
                  <div class="job-listing-company-logo">
                    <img
                      :src="
                        si.company_logo != null
                          ? si.company_logo
                          : webUrl + 'assets/images/company-logo-placeholders.png'
                      "
                      alt="company_logo"
                    />
                  </div>

                  <!-- Details -->
                  <div class="job-listing-description">
                    <!-- <h4 class="job-listing-company">{{si.name}}</h4> -->
                    <h4
                      class="job-listing-company"
                      v-if="si.name.length >= 46"
                      v-html="si.name.substring(0, 46) + '...'"
                    ></h4>
                    <h4 class="job-listing-company company_height" v-else>{{ si.name }}</h4>
                    <!-- <h3 v-if="si.title.length<30"  class="job-listing-title">{{si.title}}</h3>
                                            <h3 v-else>{{si.title.substring(0,27)+"..."}}</h3> -->
                    <h3
                      class="job-listing-title"
                      v-if="si.title.length >= 49"
                      v-html="si.title.substring(0, 49) + '...'"
                    ></h3>
                    <h3 v-else v-html="si.title" class="job-listing-title"></h3>
                  </div>
                </div>

                <!-- Job Listing Footer -->
                <div class="job-listing-footer new_footer_slider all_job_slider_detail">
                  <ul>
                    <div class="li_main_div_dt">
                      <li class="one_li" v-if="si.job_address">
                        <i class="icon-material-outline-location-on common_i_color"></i>
                        {{
                          si.job_address == "Other"
                            ? si.other_location
                            : si.job_address | capitalize
                        }}
                      </li>
                      <!-- <li><i class="icon-material-outline-business-center common_i_color"></i> {{si.sc_name | capitalize}}</li> -->
                      <li
                        class="two_li"
                        v-if="
                          (si.min_salary != 0 && si.max_salary != 0) ||
                          si.min_salary > 0 ||
                          si.max_salary > 0
                        "
                      >
                        <i
                          data-v-e4caeaf8=""
                          class="common_i_color material-icons-outlined custom dollar_custom_sign new_dollar_custom_sign"
                          >monetization_on_outline</i
                        >
                        ${{ si.min_salary != null ? si.min_salary : 0 }}-${{
                          si.max_salary != null ? si.max_salary : 0
                        }}
                      </li>
                    </div>
                    <div class="li_main_div_dt">
                      <!-- <li v-if="(si.min_salary != 0 && si.max_salary != 0) || si.min_salary > 0 || si.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> ${{si.min_salary != null ? si.min_salary : 0}}-${{si.max_salary != null ? si.max_salary : 0}}</li> -->
                      <li class="one_li similar_job_li_height">
                        <i class="icon-material-outline-business-center common_i_color"></i>
                        {{ si.sc_name | capitalize }}
                      </li>
                      <li class="two_li">
                        <i
                          class="icon-material-outline-access-time common_i_color time_slider_icon"
                        ></i>
                        {{ si.posted_date | dateTimeAgo }}
                      </li>
                    </div>
                  </ul>
                </div>
              </a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <br />

      <!-- Single Page Job Slider / End -->
    </div>

    <div class="home_mdl">
      <b-modal
        ref="bv-modal-example-ft-apply"
        id="bv-modal-example-ft-apply"
        title="BootstrapVue login"
        class="home_model"
        content-class="common_model_header common_model_title"
        hide-footer
        modal-class="background_custom_class"
      >
        <div slot="modal-title" class="custom_model_header">
          <div
            class="modal"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            style="display: block"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="">
                  <div class="">
                    <div class="">
                      <div>
                        <!-- Nav tabs -->
                        <ul
                          class="nav nav-tabs"
                          role="tablist"
                          v-if="user_type_id == '' || user_type_id == 5"
                        >
                          <!-- <li role="presentation" class="active" id="login_li"><a href="#login" aria-controls="login" role="tab" data-toggle="tab">Login</a>
                            </li>
                            <li role="presentation" id="sign_up_li"><a href="#register" aria-controls="register" role="tab" data-toggle="tab" >Register</a>
                            </li> -->
                          <li>
                            <b-button
                              class="mt-3 custom_close_btn model_margin_left_zero hello"
                              block
                              @click="$bvModal.hide('bv-modal-example-ft-apply')"
                              >x</b-button
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane active" id="login">
            <div class="welcome-text remove_mrgn top_mrgn">
              <h3>We're glad to see you again!</h3>
              <span
                >Don't have an account?
                <b-link
                  href="#register"
                  @click="register_click('register')"
                  aria-controls="register"
                  role="tab"
                  data-toggle="tab"
                  class="register_tab"
                  >Sign Up!</b-link
                ></span
              >
            </div>
            <!-- <div class="account-type cst_type my_account_type type_in_mobile_tab"><div><input type="radio" v-model="user_type" value="5" name="account-type-radios" id="freelancer-radio" @change="radioClickLogin" checked="checked" class="account-type-radio"><label for="freelancer-radio" class="ripple-effect-dark mobile_radio login_forms"><i class="icon-material-outline-account-circle"></i> Jobseeker</label></div><div><input type="radio" v-model="user_type" value="3" @change="radioClickLogin" name="account-type-radi" id="employer-radio" class="account-type-radio"><label for="employer-radio" class="ripple-effect-dark login_forms"><i class="icon-material-outline-business-center"></i> Employer</label></div></div> -->
            <div class="login_forms">
              <b-form id="login-form" @submit.prevent="login" class="new_pop_up_frm">
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline"></i>
                    <b-form-input
                      type="text"
                      v-model="email"
                      name="emailaddress"
                      id="emailaddress"
                      placeholder="Email Address"
                      class="input-text with-border required-field1"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter email </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left" id="show_hide_password_login">
                    <i class="icon-material-outline-lock"></i>
                    <b-form-input
                      type="password"
                      v-model="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      class="input-text with-border required-field1"
                    />
                    <div class="input-group-addon-custom">
                      <b-link @click="passwordClick"
                        ><i class="fa fa-eye-slash" aria-hidden="true"></i
                      ></b-link>
                    </div>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>
                <b-link @click="clickForgotPassword($event)">Forgot Password?</b-link>
              </b-form>
              <button
                type="submit"
                form="login-form"
                class="button full-width button-sliding-icon ripple-effect model_btn"
                id="login_button"
              >
                Log In <i class="icon-material-outline-arrow-right-alt"></i>
              </button>
              <button
                form="login-form"
                class="button full-width button-sliding-icon ripple-effect model_btn"
                style="display: none"
                id="loaderlogin"
                disabled
              >
                <span class="fa fa-spinner fa-spin" id="spinner"></span> Loading
              </button>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="register">
            <div class="welcome-text remove_mrgn top_mrgn">
              <h3 style="font-size: 26px">Let's create your account!</h3>
              <span
                >Already have an account?
                <b-link href="#login" @click="register_click('login')" role="tab" data-toggle="tab"
                  >Log In!</b-link
                ></span
              >
            </div>
            <!-- <b-form @submit.prevent="signup"> -->
            <!-- <div class="account-type cst_type my_account_type type_in_mobile_tab"><div><input type="radio" v-model="user_type" @change="radioClickLogin" value="5" name="account-type-radio" id="freelancer-radio" checked="checked" class="account-type-radio"><label for="freelancer-radio" class="ripple-effect-dark mobile_radio login_forms"><i class="icon-material-outline-account-circle"></i> Jobseeker</label></div><div><input type="radio" v-model="user_type" @change="radioClickLogin" value="3" name="account-type-radio" id="employer-radio" class="account-type-radio"><label for="employer-radio" class="ripple-effect-dark login_forms"><i class="icon-material-outline-business-center"></i> Employer</label></div></div> -->

            <!-- <div class="account-type">
                        <div>
                            <input type="radio" v-model="suser_type" name="account-type-radio" id="freelancer-radio" checked="checked" value="5" class="account-type-radio">
                            <label for="freelancer-radio" class="ripple-effect-dark"><i class="icon-material-outline-account-circle"></i> Jobseeker</label>
                                </div><div><input type="radio" v-model="suser_type" name="account-type-radio" id="employer-radio" value="3" class="account-type-radio">
                                <label for="employer-radio" class="ripple-effect-dark">
                                    <i class="icon-material-outline-business-center"></i> Employer</label>

                        </div>
                    </div> -->
            <div class="login_forms">
              <b-form
                @submit.prevent="signup"
                id="register-account-form"
                class="pop_up_frm new_pop_up_frm"
              >
                <div class="account-type remove_mrgn input-with-icon-left mobile_tab_login_pp">
                  <div>
                    <div class="full_width_input">
                      <i class="fa fa-user pop_up_user common_profile_icon" aria-hidden="true"></i
                      ><b-form-input
                        id="fname"
                        name="login-email"
                        v-model="fname"
                        type="text"
                        placeholder="Given Name"
                        class="input-text with-border form-control required-field"
                      />
                    </div>
                    <div class="show_span">
                      <span class="error_x_white">Please enter Given Name </span>
                    </div>
                  </div>

                  <div>
                    <div class="full_width_input">
                      <i
                        class="fa fa-user pop_up_user common_profile_icon surname_icon"
                        aria-hidden="true"
                      ></i
                      ><b-form-input
                        id="lname"
                        name="login-email"
                        type="text"
                        v-model="lname"
                        placeholder="Surname"
                        class="input-text with-border form-control required-field"
                      />
                    </div>
                    <div class="show_span">
                      <span class="error_x_white">Please enter Surname </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                    <b-form-input
                      id="semail"
                      v-model="semail"
                      name="login-email"
                      type="text"
                      placeholder="john@example.com"
                      class="input-text with-border form-control required-field"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Email </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left" id="show_hide_password_login">
                    <i class="icon-material-outline-lock common_profile_icon"></i>
                    <b-form-input
                      id="spassword"
                      v-model="spassword"
                      name="signup-password"
                      type="password"
                      placeholder="Password"
                      class="input-text with-border form-control required-field"
                    />
                    <div class="input-group-addon-custom">
                      <span @click="passwordClick"
                        ><i
                          class="fa fa-eye-slash cusror_point common_profile_icon"
                          aria-hidden="true"
                        ></i
                      ></span>
                    </div>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left" id="show_hide_repassword_signup">
                    <i class="common_profile_icon icon-material-outline-lock"></i>
                    <b-form-input
                      id="srepassword"
                      name="repeate-password"
                      v-model="srepassword"
                      type="password"
                      placeholder="Confirm Password"
                      class="input-text with-border form-control required-field"
                    />
                    <div class="input-group-addon-custom">
                      <b-link @click="repasswordClick"
                        ><i class="fa fa-eye-slash common_profile_icon" aria-hidden="true"></i
                      ></b-link>
                    </div>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Confirm Password </span>
                  </div>
                </div>
                <div class="">
                  <div class="tacbox agree_note common_sgn_agree">
                    <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                    <span for="" id="empcontr" style="display: none">
                      I hereby agree to the
                      <a href="/em-terms-of-service" target="_blank">Terms of Service</a> of EL
                      Connect Pte Ltd.</span
                    >
                    <span for="" id="jobcontr">
                      I hereby agree to the
                      <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                      Pte Ltd.</span
                    >
                  </div>
                </div>
              </b-form>

              <button
                type="submit"
                form="register-account-form"
                class="button full-width button-sliding-icon ripple-effect model_btn"
                id="reg_button"
              >
                Register <i class="icon-material-outline-arrow-right-alt"></i>
              </button>
              <button
                form="register-account-form"
                class="button full-width button-sliding-icon ripple-effect model_btn"
                style="display: none"
                id="loaderreg"
                disabled
              >
                <span class="fa fa-spinner fa-spin" id="spinner"></span> Loading
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { POST_API, LOGIN } from "../../store/actions.type";
import moment from "moment";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueSocialSharing from "vue-social-sharing";

export default {
  metaInfo: {
    title: "EL Connect - All Jobs Detail",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "All Jobs Detail - View detail job description, job requirements for full time jobs and get hired.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - All Jobs Detail" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/all-jobs-detail" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "All Jobs Detail - View detail job description, job requirements for full time jobs and get hired.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/all-jobs-detail" }],
  },
  data() {
    return {
      email: "",
      password: "",
      user_type: "5",
      fname: "",
      lname: "",
      semail: "",
      spassword: "",
      srepassword: "",
      suser_type: "5",
      bookmarkVal: "",
      bookmarkjobs: [],
      simJobs: [],
      company_name: "",
      jobData: [],
      id: atob(this.$route.params.id),
      title: "",
      daily: "",
      posted_date: "",
      posted_by: "",
      profile_image: "",
      email_verified: "",
      designation: "",
      address: "",
      other_location: "",
      description: "",
      company_description: "",
      category_id: "",
      end_date: "",
      items: null,
      job_type: "",
      keyword: "",
      userId: "",
      jobId: "",
      tagArr: [],
      average_rating: "",
      alreadyApplied: false,
      min_salary: "",
      max_salary: "",
      error_message: "",
      skillsArr: [],
      tagArr: [],

      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      form: {
        rowsPerPage: 25,
      },
      webUrl: process.env.VUE_APP_URL,
      user_address: "",
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 800,
        infinite: false,
        responsive: [
          {
            // breakpoint: 768,
            // settings: {
            // 	"slidesToShow": 1,
            // 		"slidesToScroll": 1,
            // 		"speed": 800,
            // 		"infinite": false,
            //         "dots":true,
            //         "arrows":false
            //     }
            breakpoint: 998,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrow: true,
            },
          },
        ],
      },
    };
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    dateTimeAgo: function (value) {
      var m = moment(value); // or whatever start date you have
      // var today = moment().startOf('day');
      var today = moment();
      var days = Math.round(moment.duration(today - m).asDays());
      console.log(days);
      if (days > 0) {
        return days + " Days Ago";
      } else {
        return "today";
      }
      // if (!value) return ''
      // const dateTimeAgo = moment(value).fromNow();
      // return dateTimeAgo
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    customizeRoleType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },

  methods: {
    async copyURL() {
      try {
        let copyText = document.querySelector("#copy-url");
        copyText.select();
        document.execCommand("copy");

        Snackbar.show({
          text: "Copied to clipboard!",
        });
        // alert('Copied to Clipboard.');
      } catch ($e) {
        console.log($e);

        // alert('Cannot copy');
      }
    },

    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    jobDetail(id, event) {
      if (event) {
        event.stopPropagation();
      }
      // window.location.replace('/all-jobs-detail/'+btoa(id));

      window.location.replace("/all-jobs-detail/" + id);
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    getjobsDataById() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.id,
          },
          api: "/api/all-jobs-detail",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.jobData = this.$store.getters.getResults.data[0];
            this.title = this.$store.getters.getResults.data[0].job_title;
            this.category_id = this.$store.getters.getResults.data[0].service_categories_id;
            this.company_name = this.$store.getters.getResults.data[0].company_name;

            this.email_verified = this.$store.getters.getResults.data[0].email_verified;
            this.description = this.$store.getters.getResults.data[0].job_description;
            this.company_description = this.$store.getters.getResults.data[0].company_description;
            this.min_salary = this.$store.getters.getResults.data[0].min_salary;
            this.max_salary = this.$store.getters.getResults.data[0].max_salary;
            this.profile_image = this.$store.getters.getResults.data[0].profile_image;
            this.address = this.$store.getters.getResults.data[0].job_address;
            this.other_location = this.$store.getters.getResults.data[0].other_location;

            this.job_type = this.$store.getters.getResults.data[0].job_type;
            this.end_date = this.$store.getters.getResults.data[0].end_date;

            this.posted_by = this.$store.getters.getResults.data[0].posted_by;

            this.designation = this.$store.getters.getResults.data[0].designation;
            this.jobId = this.$store.getters.getResults.data[0].jobId;
            this.average_rating = this.$store.getters.getResults.data[0].average_rating;
            this.bookmarkjobs =
              this.$store.getters.getResults.data[0].bookmark == "yes"
                ? this.$store.getters.getResults.data[0].jobId
                : "";
            this.posted_date = moment(
              String(this.$store.getters.getResults.data[0].start_date)
            ).format("DD MMM YYYY");
            this.tagArr = this.jobData.tag_names ? this.jobData.tag_names.split(",") : [];
            this.similarJobs(this.category_id);

            // if(this.end_date < moment().tz("Singapore").format('YYYY-MM-DD')){
            //     window.location.replace('/v2/error-404');
            // }
          }
        });
    },
    clickForgotPassword(event) {
      if (event) {
        event.stopPropagation();
      }
      var userTypeId = {
        user_type_id: this.user_type,
      };
      localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
      this.$refs["bv-modal-example-ft-apply"].hide();
      this.$router.push({ name: "forgotpassword" });
    },
    signup() {
      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please accept terms and condition first.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      console.log("signup", this.suser_type, this.user_type, "ddd");
      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.srepassword,
              register_from: "web",
              popup: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.showAlert();
            } else {
              console.log(this.$store.getters.currentUser);

              this.slogin();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },
    slogin() {
      console.log("lsignup", this.suser_type, this.user_type, "ddd");
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              if (this.error_message == "Incorrect Email or Password.") {
                this.$swal({
                  position: "center",
                  icon: "error",
                  // iconColor:'#4c8bf5',
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                if (
                  this.is_company_head == "no" &&
                  (this.user_type_id == "3" || this.user_type_id == "4")
                ) {
                  if (this.account_verification_status == "completed") {
                    window.location.replace("/v2/employer/dp-job-manage");
                  } else if (this.ft_account_status == "completed") {
                    window.location.replace("/v2/employer/manage-jobs");
                  } else {
                    window.location.replace("/v2/employer/om-supervisor-profile");
                  }
                  // window.location.replace('/v2/employer/om-supervisor-profile');
                } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
                  if (this.account_verification_status == "completed") {
                    window.location.replace("/v2/employer/dp-job-manage");
                  } else if (this.ft_account_status == "completed") {
                    window.location.replace("/v2/employer/manage-jobs");
                  } else {
                    window.location.replace("/v2/employer/employer-profile");
                  }
                  // window.location.replace('/v2/employer/employer-profile');
                } else if (this.user_type_id == "5") {
                  window.location.replace("/v2/jobseeker/jobseeker-profile");
                } else {
                  window.location.replace("/v2/admin/dashboard");
                }
              });
            }
          });
      }
    },
    login() {
      $("#login_button").hide();
      $("#loaderlogin").show();
      var err = false;

      $(".required-field1").each(function () {
        console.log($(this).val(), "$(this)");
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.email,
              password: this.password,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#login_button").show();
            $("#loaderlogin").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              if (this.error_message == "Incorrect Email or Password.") {
                this.$swal({
                  position: "center",
                  icon: "error",
                  // iconColor:'#4c8bf5',
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Login successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                if (
                  this.is_company_head == "no" &&
                  (this.user_type_id == "3" || this.user_type_id == "4")
                ) {
                  if (this.account_verification_status == "completed") {
                    window.location.replace("/v2/employer/dp-job-manage");
                  } else if (this.ft_account_status == "completed") {
                    window.location.replace("/v2/employer/manage-jobs");
                  } else {
                    window.location.replace("/v2/employer/om-supervisor-profile");
                  }
                  // window.location.replace('/v2/employer/om-supervisor-profile');
                } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
                  if (this.account_verification_status == "completed") {
                    window.location.replace("/v2/employer/dp-job-manage");
                  } else if (this.ft_account_status == "completed") {
                    window.location.replace("/v2/employer/manage-jobs");
                  } else {
                    window.location.replace("/v2/employer/employer-profile");
                  }
                  // window.location.replace('/v2/employer/employer-profile');
                } else if (this.user_type_id == "5") {
                  window.location.replace("/v2/jobseeker/jobseeker-profile");
                } else {
                  window.location.replace("/v2/admin/dashboard");
                }
                //    this.is_company_head = this.$store.getters.currentUser.is_company_head;
                //     if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //         window.location.replace('/v2/employer/employer-profile');
                //     }else if(this.user_type == '5'){
                //         window.location.replace('/v2/jobseeker/jobseeker-profile');
                //     }else{
                //         window.location.replace('/v2/admin/dashboard');
                //     }
              });
            }
          });
      } else {
        $("#login_button").show();
        $("#loaderlogin").hide();
      }
    },
    passwordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_login input").attr("type") == "text") {
        $("#show_hide_password_login input").attr("type", "password");
        $("#show_hide_password_login i").addClass("fa-eye-slash");
        $("#show_hide_password_login i").removeClass("fa-eye");
      } else if ($("#show_hide_password_login input").attr("type") == "password") {
        $("#show_hide_password_login input").attr("type", "text");
        $("#show_hide_password_login i").removeClass("fa-eye-slash");
        $("#show_hide_password_login i").addClass("fa-eye");
      }
    },
    repasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repassword_signup input").attr("type") == "text") {
        $("#show_hide_repassword_signup input").attr("type", "password");
        $("#show_hide_repassword_signup i").addClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").removeClass("fa-eye");
      } else if ($("#show_hide_repassword_signup input").attr("type") == "password") {
        $("#show_hide_repassword_signup input").attr("type", "text");
        $("#show_hide_repassword_signup i").removeClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").addClass("fa-eye");
      }
    },
    register_click(type) {
      if (type == "login") {
        $("#sign_up_li").removeClass("active");
        $("#login_li").addClass("active");
      } else {
        $("#sign_up_li").addClass("active");
        $("#login_li").removeClass("active");
      }
    },

    similarJobs(category_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            category_id: category_id,
            job_id: this.id,
            user_id: this.user_id,
          },
          api: "/api/similarJobs",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.simJobs = this.$store.getters.getResults.data;

            console.log(this.simJobs.length);
          }
        });
    },
  },
  mounted() {
    $(".share-buttons-icons").addClass("hide_social_share");
    $(".share-buttons-content").click(function () {
      $(".share-buttons-content").toggleClass("show_hide_share_text");
      // $(".share-buttons-icons").addClass('show_social_share');
      $(".share-buttons-icons").toggleClass("show_social_share");
    });
    $(".share-buttons-trigger").click(function () {
      $(".share-buttons-content").toggleClass("show_hide_share_text");
      // $(".share-buttons-icons").addClass('show_social_share');
      $(".share-buttons-icons").toggleClass("show_social_share");
    });
    window.scrollTo(0, 0);
    //document.title = "EL Connect - All Jobs Detail";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    $("#copy-url").val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));

    this.jobUrl = process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id);

    this.getjobsDataById();
    this.user_type_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
    console.log(this.user_type_id, "this.user_type_id");
  },
  components: {
    VueSlickCarousel,
    Carousel,
    VueSocialSharing,

    Slide,
  },
};
</script>
