<template>
  <div>
      <!-- Titlebar
    ================================================== -->
    <!-- <div id="titlebar" class="gradient">
      <div class="container">
        <div class="row">
          <div class="col-md-12">

            <h2>404 Not Found</h2>
            <nav id="breadcrumbs" class="dark">
              <ul>
                <li><b-link href="" :to="{name: 'homepage'}" >Home</b-link></li>
                <li>404 Not Found</li>
              </ul>
            </nav>

          </div>
        </div>
      </div>
    </div> -->

    <!-- Page Content
    ================================================== -->
    <!-- Container -->
    <div class="container">
      <div class="row">
        <div class="col-xl-6">
          <section id="not-found" class="center not_found_first_section">
            <!-- <img :src="webUrl+'assets/images/error-img.jpg'" class="error_img"> -->
            <img :src="webUrl+'assets/images/Elcy_404.gif'" class="error_img" alt="Error 404">
             
          </section>

        </div>
        <div class="col-xl-6">
            <section id="not-found" class="not_found_second_section">
              <h1>Page Not Found</h1>
              <h4>We're sorry, the page you requested could not be found.</h4><br>
              <h4>You will be redirecting to homepage in <span class="sec_span_not_found">{{counter}} sec</span></h4><br>
              <div class="retun_to_home_btn_div">
                <b-link href="" :to="{name: 'jobseekers2'}" style="color: #9F096C" class="retun_to_home_btn">Return to Homepage</b-link>
              </div>
            </section>
        </div>
      </div>

    </div>
    <!-- Container / End -->

  </div>
</template>

<script>

export default {
    data() {
        return {
          counter: 3,
          webUrl: process.env.VUE_APP_URL,
        }
    },
  metaInfo: {
            title: 'EL Connect - Error',
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/error-404'}
  		    ]
        },
        methods:{
              countDownNotFound() {
              //If the counter has not reached the end
              if(this.counter > 0) {
                  //Wait 1 second, then decrement the counter
                  setTimeout(()=>{
                      this.counter -= 1;
                      this.countDownNotFound();
                  },1000)
              }
              else
              {
                //Count down has reached zero, redirect
                 this.$router.push({ path: '/'});
              }
            },
        },
       mounted(){
        this.countDownNotFound();
     },
}
</script>

