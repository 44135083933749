import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from 'vuex-shared-mutations'
import common from './modules/common.module'
import auth from './modules/auth.module'
import admin from './modules/admin.module'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
    	common,
    	auth,
    	admin
    },
    plugins: [
        createPersistedState(),
        createMutationsSharer({ predicate: ['logout', 'setUser'] })
    ]
});
