<template>
    <div>
    <div>
		<div class="dashboard-content-inner my_custom_dash desktop_padding_top_zero" >

			<!-- Dashboard Headline -->
            <div class="row">
                <div class="container my_new_container">
                    <div class="dashboard-headline">
                        <h1>Applied Jobs</h1>
                        <!-- <span class="margin-top-7">Job Applications for <b-link :to="{name: 'employerJobDetail', params: {id: job_id}}">{{job_title | capitalize}}</b-link></span> -->

                        <!-- Breadcrumbs -->
                        <nav id="breadcrumbs" class="dark employer_nav">
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Job</a></li>
                                <li>Applied Jobs</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

			<!-- Row -->
			<div class="row manage_job_emp_rw mobile_manage_jb_cs new_manage_li transform_unset">
                <div class="container my_new_container">
				<!-- Dashboard Box -->
				<div class="">
					<div class="dashboard-box margin-top-0 remove_bx">

						<!-- Headline -->

                        <div class="tab-section">
                        <b-tabs class="my_tab_job remove_bx set_nav_mb">
                            <b-tab :title="'PENDING (' + activeCount + ')'" :active='new_tab' @click="changeTabStatus('active')">
                              <div class="row">
                                <div class="col-xl-8 col-md-8">
                                    <!-- <div class="keywords-container new_search_div_daily_job applied_search"> -->
                                        <!-- <div class="keyword-input-container search-box custom_search_admin desktop_margin_top_zero"> -->
                                            <!-- <div class="input-group-prepend"> -->
                                                <div class="single_search_web applied_job_sear">
                                                    <input type="text" class="keyword-input" style="background-color: rgba(0,0,0,.03); border:3px solid #ced4da ;" placeholder="Search by job title, company name" v-model="keywordPending" v-on:change="changeTabStatus('active')"/>
                                                    <b-button @click="changeTabStatus('active')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                                </div>
                                            <!-- </div> -->
                                        <!-- </div> -->
                                    <!-- </div> -->
                                    <!-- <b-button @click="changeTabStatus('active')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button> -->
                                </div>
                            </div>
                            <div class="content unset_brd" v-if="userDatas.length > 0">
                                <ul class="dashboard-box-list new_tabbed_jb">
                                   <li v-for="userData in userDatas" class="apply_pdngzz">
                                        <!-- Overview -->
                                        <div class="freelancer-overview manage-candidates applied_job_tz_dv">
                                            <div class="freelancer-overview-inner">

                                                <!-- Avatar -->
                                                <div class="freelancer-avatar remove_margin_avatar single_logo_img">
                                                        <b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}"><img :src="userData.thumbnail != null ? userData.thumbnail: userData.company_logo != null ? userData.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo"></b-link>
                                                    </div>

                                                <!-- Name -->
                                                <div class="freelancer-name applied_innrz">
                                                  <div class="title_and_stars bookmark_flex_big_screen">
                                                    <div class="content_jobz">
                                                        <!-- <p class="new_font_title" v-if="userData.job_title.length >= 166"><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title.substring(0,166)+'...' | capitalize}}</b-link></b></p>
                                                        <p class="new_font_title" v-else><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title | capitalize}}</b-link></b></p>
                                                        <h4 class="new_font_company"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}" class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                        </b-link></h4> -->
                                                        <p class="new_font_title" v-if="userData.job_title.length >= 166"><b>{{userData.job_title.substring(0,166)+'...' | capitalize}}</b></p>
                                                        <p class="new_font_title" v-else><b>{{userData.job_title | capitalize}}</b></p>
                                                        <h4 class="new_font_company">
                                                            <a href="javascript:void(0);" @click="employerDetail(encrypt(userData.posted_by), $event)">
                                                                <i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </a>
                                                        </h4>
                                                    </div>
                                                     <div class="star_icon">
                                                            <ul class="desktop_margin_bottom_zero bookmark_delete_icon">
                                                                <b-link href="" @click="removeApplication(userData.jobId,userData.applicant_id,$event)" class="button red ripple-effect ico" title="Cancel Application" data-tippy-placement="left"><i class="icon-feather-trash-2"></i></b-link>
                                                            </ul>
                                                        </div>
                                                  </div>
                                                    <h3 class="job-listing-title new_all_job_title" v-if="userData.job_description" style="margin-left: 20px;">
                                                        <p v-if="userData.job_description.length >= 250" v-html="userData.job_description.substring(0,250)+'...' "></p>
                                                        <p v-else v-html="userData.job_description"></p>
                                                    </h3>
                                                    <div class="">
                                                        <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect bookmark_profile_btn"> View Detail </a> -->
                                                        <a :href="'/v2/jobseeker/ft-jobdetail/'+ encrypt(userData.jobId)" target=_blank class="button ripple-effect bookmark_profile_btn"> View Detail </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="job-listing-footer">
                                                <ul>
                                                    <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{userData.job_address == 'Other' ? userData.other_location : userData.job_address | capitalize}}</li>
                                                    <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{userData.job_type | customizeRoleType}}</li>
                                                    <li class="black_job_color" v-if="(userData.min_salary != 0 && userData.max_salary != 0) || userData.min_salary > 0 || userData.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{userData.min_salary}}-${{userData.max_salary}}</li>
                                                    <li class="black_job_color"><i class="icon-material-outline-access-time  common_i_color"></i>{{userData.posted_date | dateTimeAgo | capitalize}}</li>
                                                </ul>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="text-center" v-else>
                                <p class="margin_top_1rem"><b>No Record Found</b> </p>
                            </div>
                            </b-tab>
                            <b-tab :title="'SHORTLISTED (' + shortlistedCount + ')'" :active='shortlisted' @click="changeTabStatus('shortlisted')" class="reject_last_li short_list_tbl">
                                <div class="row">
                                        <div class="col-xl-8 col-md-8">
                                            <!-- <div class="keywords-container new_search_div_daily_job applied_search"> -->
                                                <!-- <div class="keyword-input-container search-box custom_search_admin desktop_margin_top_zero"> -->
                                                    <!-- <div class="input-group-prepend"> -->
                                                        <div class="single_search_web applied_job_sear">
                                                            <input type="text" class="keyword-input" style="background-color: rgba(0,0,0,.03); border:3px solid #ced4da ;" placeholder="Search by job title, company name" v-model="keywordShortlisted" v-on:change="changeTabStatus('shortlisted')"/>
                                                            <b-button @click="changeTabStatus('shortlisted')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                                        </div>
                                                    <!-- </div> -->
                                                <!-- </div> -->
                                            <!-- </div> -->
                                            <!-- <b-button @click="changeTabStatus('active')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button> -->

                                        </div>
                                </div>
                                <div class="content unset_brd" v-if="userDatas.length > 0">
                                    <ul class="dashboard-box-list">
                                        <li v-for="userData in userDatas" class="apply_pdngzz">
                                            <!-- Overview -->
                                            <div class="freelancer-overview manage-candidates applied_job_tz_dv">
                                                <div class="freelancer-overview-inner">

                                                    <!-- Avatar -->
                                                    <div class="freelancer-avatar remove_margin_avatar single_logo_img">
                                                            <b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}"><img :src="userData.thumbnail != null ? userData.thumbnail: userData.company_logo != null ? userData.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo"></b-link>
                                                        </div>

                                                    <!-- Name -->
                                                    <div class="freelancer-name applied_innrz">
                                                    <div class="title_and_stars bookmark_flex_big_screen">
                                                        <div class="content_jobz">
                                                            <!-- <p class="new_font_title" v-if="userData.job_title.length >= 166"><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title.substring(0,166)+'...' | capitalize}}</b-link></b></p>
                                                            <p class="new_font_title" v-else><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title | capitalize}}</b-link></b></p>
                                                            <h4 class="new_font_company"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}" class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </b-link></h4> -->
                                                            <p class="new_font_title" v-if="userData.job_title.length >= 166"><b>{{userData.job_title.substring(0,166)+'...' | capitalize}}</b></p>
                                                            <p class="new_font_title" v-else><b>{{userData.job_title | capitalize}}</b></p>
                                                            <h4 class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </h4>
                                                        </div>
                                                        <div class="star_icon">
                                                                <ul class="desktop_margin_bottom_zero bookmark_delete_icon">
                                                                    <b-link href="" @click="removeApplication(userData.jobId,userData.applicant_id,$event)" class="button red ripple-effect ico" title="Cancel Application" data-tippy-placement="left"><i class="icon-feather-trash-2"></i></b-link>
                                                                </ul>
                                                            </div>
                                                    </div>
                                                        <h3 class="job-listing-title new_all_job_title" v-if="userData.job_description" style="margin-left: 20px;">
                                                            <p v-if="userData.job_description.length >= 250" v-html="userData.job_description.substring(0,250)+'...' "></p>
                                                            <p v-else v-html="userData.job_description"></p>
                                                        </h3>
                                                        <div class="">
                                                            <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect bookmark_profile_btn"> View Detail </a> -->
                                                             <a :href="'/v2/jobseeker/ft-jobdetail/'+ encrypt(userData.jobId)" target=_blank  class="button ripple-effect bookmark_profile_btn"> View Detail </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="job-listing-footer">
                                                    <ul>
                                                        <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{userData.job_address == 'Other' ? userData.other_location : userData.job_address | capitalize}}</li>
                                                        <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{userData.job_type | customizeRoleType}}</li>
                                                        <li class="black_job_color" v-if="(userData.min_salary != 0 && userData.max_salary != 0) || userData.min_salary > 0 || userData.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{userData.min_salary}}-${{userData.max_salary}}</li>

                                                        <li class="black_job_color"><i class="icon-material-outline-access-time  common_i_color"></i>{{userData.posted_date | dateTimeAgo | capitalize}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="text-center" v-else>
                                    <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                </div>
                            </b-tab>
                            <b-tab :title="'HIRED (' + hiredCount + ')'" :active='hired' @click="changeTabStatus('hired')" class="reject_last_li hired_tbl updatd_hired_tabs">
                                <div class="row">
                                    <div class="col-xl-8 col-md-8">
                                        <div class="single_search_web applied_job_sear">
                                            <input type="text" class="keyword-input" style="background-color: rgba(0,0,0,.03); border:3px solid #ced4da ;" placeholder="Search by job title, company name" v-model="keywordHired" v-on:change="changeTabStatus('hired')"/>
                                            <b-button @click="changeTabStatus('hired')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="content unset_brd" v-if="userDatas.length > 0">
                                    <ul class="dashboard-box-list">
                                    <li v-for="userData in userDatas" class="apply_pdngzz">
                                            <!-- Overview -->
                                            <div class="freelancer-overview manage-candidates applied_job_tz_dv">
                                                <div class="freelancer-overview-inner">

                                                    <!-- Avatar -->
                                                    <div class="freelancer-avatar remove_margin_avatar single_logo_img">
                                                            <b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}"><img :src="userData.thumbnail != null ? userData.thumbnail: userData.company_logo != null ? userData.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo"></b-link>
                                                        </div>

                                                    <!-- Name -->
                                                    <div class="freelancer-name applied_innrz">
                                                    <div class="title_and_stars bookmark_flex_big_screen">
                                                        <div class="content_jobz">
                                                            <!-- <p class="new_font_title" v-if="userData.job_title.length >= 166"><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title.substring(0,166)+'...' | capitalize}}</b-link></b></p>
                                                            <p class="new_font_title" v-else><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title | capitalize}}</b-link></b></p>
                                                            <h4 class="new_font_company"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}" class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </b-link></h4> -->
                                                            <p class="new_font_title" v-if="userData.job_title.length >= 166"><b>{{userData.job_title.substring(0,166)+'...' | capitalize}}</b></p>
                                                            <p class="new_font_title" v-else><b>{{userData.job_title | capitalize}}</b></p>
                                                            <h4 class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </h4>
                                                        </div>
                                                        <div class="star_icon">
                                                                <ul class="desktop_margin_bottom_zero bookmark_delete_icon">
                                                                    <b-link href="" @click="removeApplication(userData.jobId,userData.applicant_id,$event)" class="button red ripple-effect ico" title="Cancel Application" data-tippy-placement="left"><i class="icon-feather-trash-2"></i></b-link>
                                                                </ul>
                                                            </div>
                                                    </div>
                                                        <h3 class="job-listing-title new_all_job_title" v-if="userData.job_description" style="margin-left: 20px;">
                                                            <p v-if="userData.job_description.length >= 250" v-html="userData.job_description.substring(0,250)+'...' "></p>
                                                            <p v-else v-html="userData.job_description"></p>
                                                        </h3>
                                                        <div class="">
                                                            <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect bookmark_profile_btn"> View Detail </a> -->
                                                            <a :href="'/v2/jobseeker/ft-jobdetail/'+ encrypt(userData.jobId)" target=_blank  class="button ripple-effect bookmark_profile_btn"> View Detail </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="job-listing-footer">
                                                    <ul>
                                                        <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{userData.job_address == 'Other' ? userData.other_location : userData.job_address | capitalize}}</li>
                                                        <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{userData.job_type | customizeRoleType}}</li>
                                                        <li class="black_job_color" v-if="(userData.min_salary != 0 && userData.max_salary != 0) || userData.min_salary > 0 || userData.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{userData.min_salary}}-${{userData.max_salary}}</li>

                                                        <li class="black_job_color"><i class="icon-material-outline-access-time  common_i_color"></i>{{userData.posted_date | dateTimeAgo | capitalize}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="text-center" v-else>
                                    <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                </div>
                            </b-tab>
                            <b-tab :title="'DECLINED (' + declinedCount + ')'" :active='declined' @click="changeTabStatus('declined')" class="reject_last_li updated_reject_li">
                                <div class="row">
                                    <div class="col-xl-8 col-md-8">
                                        <div class="single_search_web applied_job_sear">
                                            <input type="text" class="keyword-input" style="background-color: rgba(0,0,0,.03); border:3px solid #ced4da ;" placeholder="Search by job title, company name" v-model="keywordDeclined" v-on:change="changeTabStatus('declined')"/>
                                            <b-button @click="changeTabStatus('declined')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="content unset_brd" v-if="userDatas.length > 0">
                                    <ul class="dashboard-box-list">
                                        <li v-for="userData in userDatas" class="apply_pdngzz">
                                            <!-- Overview -->
                                            <div class="freelancer-overview manage-candidates applied_job_tz_dv">
                                                <div class="freelancer-overview-inner">

                                                    <!-- Avatar -->
                                                    <div class="freelancer-avatar remove_margin_avatar single_logo_img">
                                                            <b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}"><img :src="userData.thumbnail != null ? userData.thumbnail: userData.company_logo != null ? userData.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo"></b-link>
                                                        </div>

                                                    <!-- Name -->
                                                    <div class="freelancer-name applied_innrz">
                                                    <div class="title_and_stars bookmark_flex_big_screen">
                                                        <div class="content_jobz">
                                                            <!-- <p class="new_font_title" v-if="userData.job_title.length >= 166"><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title.substring(0,166)+'...' | capitalize}}</b-link></b></p>
                                                            <p class="new_font_title" v-else><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title | capitalize}}</b-link></b></p>
                                                            <h4 class="new_font_company"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}" class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </b-link></h4> -->
                                                            <p class="new_font_title" v-if="userData.job_title.length >= 166"><b>{{userData.job_title.substring(0,166)+'...' | capitalize}}</b></p>
                                                            <p class="new_font_title" v-else><b>{{userData.job_title | capitalize}}</b></p>
                                                            <h4 class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </h4>
                                                        </div>
                                                        <div class="star_icon">
                                                                <ul class="desktop_margin_bottom_zero bookmark_delete_icon">
                                                                    <b-link href="" @click="removeApplication(userData.jobId,userData.applicant_id,$event)" class="button red ripple-effect ico" title="Cancel Application" data-tippy-placement="left"><i class="icon-feather-trash-2"></i></b-link>
                                                                </ul>
                                                            </div>
                                                    </div>
                                                        <h3 class="job-listing-title new_all_job_title" v-if="userData.job_description" style="margin-left: 20px;">
                                                            <p v-if="userData.job_description.length >= 250" v-html="userData.job_description.substring(0,250)+'...' "></p>
                                                            <p v-else v-html="userData.job_description"></p>
                                                        </h3>
                                                        <div class="">
                                                            <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect bookmark_profile_btn"> View Detail </a> -->
                                                            <a :href="'/v2/jobseeker/ft-jobdetail/'+ encrypt(userData.jobId)" target=_blank  class="button ripple-effect bookmark_profile_btn"> View Detail </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="job-listing-footer">
                                                    <ul>
                                                        <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{userData.job_address == 'Other' ? userData.other_location : userData.job_address | capitalize}}</li>
                                                        <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{userData.job_type | customizeRoleType}}</li>
                                                        <li class="black_job_color" v-if="(userData.min_salary != 0 && userData.max_salary != 0) || userData.min_salary > 0 || userData.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{userData.min_salary}}-${{userData.max_salary}}</li>

                                                        <li class="black_job_color"><i class="icon-material-outline-access-time  common_i_color"></i>{{userData.posted_date | dateTimeAgo | capitalize}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="text-center" v-else>
                                    <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                </div>
                            </b-tab>
                            <b-tab :title="'ALL (' + allCount + ')'" :active='all' @click="changeTabStatus('all')" class="reject_last_li">
                                <div class="row">
                                     <div class="col-xl-8 col-md-8">
                                         <div class="single_search_web applied_job_sear">
                                            <input type="text" class="keyword-input" style="background-color: rgba(0,0,0,.03); border:3px solid #ced4da ;" placeholder="Search by job title, company name" v-model="keywordAll" v-on:change="changeTabStatus('all')"/>
                                            <b-button @click="changeTabStatus('all')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                        </div>
                                         <!-- <div class="keywords-container new_search_div_daily_job applied_search">
                                             <div class="keyword-input-container search-box custom_search_admin desktop_margin_top_zero">
                                                <div class="input-group-prepend">
                                                    <input type="text" class="keyword-input" placeholder="Search by keyword" v-model="keywordAll" v-on:change="changeTabStatus('all')"/>
                                                </div>
                                             </div>
                                        </div>
                                        <b-button @click="changeTabStatus('all')" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button> -->
                                    </div>
                                </div>
                                <div class="content unset_brd" v-if="userDatas.length > 0">
                                    <ul class="dashboard-box-list">
                                        <li v-for="userData in userDatas" class="apply_pdngzz">
                                            <!-- Overview -->
                                            <div class="freelancer-overview manage-candidates applied_job_tz_dv">
                                                <div class="freelancer-overview-inner">

                                                    <!-- Avatar -->
                                                    <div class="freelancer-avatar single_logo_img">
                                                            <b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}"><img :src="userData.thumbnail != null ? userData.thumbnail: userData.company_logo != null ? userData.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo"></b-link>
                                                        </div>

                                                    <!-- Name -->
                                                    <div class="freelancer-name applied_innrz">
                                                    <div class="title_and_stars bookmark_flex_big_screen">
                                                        <div class="content_jobz">
                                                            <span class="user_status_emp_new pending_apply_job" v-if="userData.applied_status=='active'">PENDING</span>
                                                            <span class="user_status_emp_shortlisted shortlisted_apply_job" v-if="userData.applied_status=='shortlisted'">{{userData.applied_status | capitalizeAll}}</span>
                                                            <span class="user_status_emp_hired hired_apply_job" v-if="userData.applied_status=='hired'">{{userData.applied_status | capitalizeAll}}</span>
                                                            <span class="user_status_emp_rejected declined_apply_job" v-if="userData.applied_status=='declined'">{{userData.applied_status | capitalizeAll}}</span>
                                                            <span class="user_status_emp_new expired_apply_job" v-if="userData.job_status=='inactive'">EXPIRED</span>

                                                            <!-- <p class="new_font_title" v-if="userData.job_title.length >= 166"><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title.substring(0,166)+'...' | capitalize}}</b-link></b></p>
                                                            <p class="new_font_title" v-else><b><b-link :to="{name: 'jobDetail', params: {id: encrypt(userData.jobId)}}" class="new_font_title">{{userData.job_title | capitalize}}</b-link></b></p>
                                                            <h4 class="new_font_company"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(userData.posted_by)}}" class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </b-link></h4> -->
                                                            <p class="new_font_title" v-if="userData.job_title.length >= 166"><b>{{userData.job_title.substring(0,166)+'...' | capitalize}}</b></p>
                                                            <p class="new_font_title" v-else><b>{{userData.job_title | capitalize}}</b></p>
                                                            <h4 class="new_font_company"><i class="icon-material-outline-business apply_i common_i_color"></i>{{userData.name}}
                                                            </h4>
                                                            <!-- <h3 class="job-listing-title new_all_job_title" v-if="userData.job_description">
                                                                <p v-if="userData.job_description.length >= 250" v-html="userData.job_description.substring(0,250)+'...' "></p>
                                                                <p v-else v-html="userData.job_description"></p>
                                                            </h3> -->
                                                        </div>
                                                        <div class="star_icon">
                                                                <ul class="desktop_margin_bottom_zero bookmark_delete_icon">
                                                                    <b-link href="" @click="removeApplication(userData.jobId,userData.applicant_id,$event)" class="button red ripple-effect ico" title="Cancel Application" data-tippy-placement="left"><i class="icon-feather-trash-2"></i></b-link>
                                                                    <!-- <b-form-checkbox :value="userData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobData.jobId,$event)" :disabled="true"></b-form-checkbox> -->
                                                                </ul>
                                                            </div>
                                                    </div>
                                                        <h3 class="job-listing-title new_all_job_title" v-if="userData.job_description" style="margin-left: 20px;">
                                                            <p v-if="userData.job_description.length >= 250" v-html="userData.job_description.substring(0,250)+'...' "></p>
                                                            <p v-else v-html="userData.job_description"></p>
                                                        </h3>
                                                        <div class="">
                                                            <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect bookmark_profile_btn"> View Detail </a> -->
                                                            <a :href="'/v2/jobseeker/ft-jobdetail/'+ encrypt(userData.jobId)" target=_blank  class="button ripple-effect bookmark_profile_btn"> View Detail </a>
                                                        </div>
                                                        <!-- Details -->
                                                        <!-- Rating -->
                                                        <!-- <div class="freelancer-rating">
                                                            <div class="star-rating" data-rating="5.0"></div>
                                                        </div> -->
                                                        <!-- <div class="star_icon">
                                                                <ul class="desktop_margin_bottom_zero bookmark_delete_icon">
                                                                    <b-link href="" @click="removeApplication(userData.jobId,userData.applicant_id,$event)" class="button red ripple-effect ico" title="Cancel Application" data-tippy-placement="left"><i class="icon-feather-trash-2"></i></b-link> -->
                                                                    <!-- <b-form-checkbox :value="userData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobData.jobId,$event)" :disabled="true"></b-form-checkbox> -->
                                                                <!-- </ul>
                                                            </div> -->
                                                        <!-- <div class="job-listing-footer"> -->
                                                            <!-- <div class="radio-star">
                                                            <b-form-group>
                                                                <b-form-checkbox :value="jobDatas.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobDatas.jobId)" :disabled="true"></b-form-checkbox>
                                                            </b-form-group>
                                                            </div> -->
                                                            <!-- <span class="bookmark-icon" @click="bookmarkJob(jobDatas.jobId)"></span> -->
                                                        <!-- <ul>
                                                                <li><i class="icon-material-outline-location-on common_i_color"></i>{{userData.job_address == 'Other' ? userData.other_location : userData.job_address | capitalize}}</li>
                                                                <li><i class="icon-material-outline-business-center common_i_color"></i>{{userData.job_type | customizeRoleType}}</li>
                                                                <li v-if="(userData.min_salary != 0 && userData.max_salary != 0) || userData.min_salary > 0 || userData.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{userData.min_salary}}-${{userData.max_salary}}</li>

                                                                <li><i class="icon-material-outline-access-time  common_i_color"></i>{{userData.posted_date | dateTimeAgo | capitalize}}</li>
                                                        </ul>
                                                        </div> -->
                                                        <!-- Buttons -->
                                                    </div>
                                                </div>
                                                <div class="job-listing-footer">
                                                    <!-- <div class="radio-star">
                                                    <b-form-group>
                                                        <b-form-checkbox :value="jobDatas.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobDatas.jobId)" :disabled="true"></b-form-checkbox>
                                                    </b-form-group>
                                                    </div> -->
                                                    <!-- <span class="bookmark-icon" @click="bookmarkJob(jobDatas.jobId)"></span> -->
                                                    <ul>
                                                            <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{userData.job_address == 'Other' ? userData.other_location : userData.job_address | capitalize}}</li>
                                                            <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{userData.job_type | customizeRoleType}}</li>
                                                            <li class="black_job_color" v-if="(userData.min_salary != 0 && userData.max_salary != 0) || userData.min_salary > 0 || userData.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{userData.min_salary}}-${{userData.max_salary}}</li>

                                                            <li class="black_job_color"><i class="icon-material-outline-access-time  common_i_color"></i>{{userData.posted_date | dateTimeAgo | capitalize}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="text-center" v-else>
                                    <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                </div>
                            </b-tab>
                        </b-tabs>
                        </div>
					</div>
                     <nav class="pagination-box appli_pagination">
                                    <div class="page-row-box d-inline-block">
                                        <b-form-group id="input-group-4">
                                            <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input="changeTabStatus(activeTab)">
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>75</option>
                                                        <option>100</option>
                                            </b-form-select></span>
                                        </b-form-group>
                                    </div>
                                    <div class="total-page-count d-inline-block">
                                        <ul>
                                            <li>
                                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <b-pagination @input="changeTabStatus(activeTab)"   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                </nav>
				</div>
                </div>
			</div>
			<!-- Row / End -->
            <div class="clearfix"></div>
                                <!-- <nav class="pagination-box appli_pagination">
                                    <div class="page-row-box d-inline-block">
                                        <b-form-group id="input-group-4">
                                            <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input="changeTabStatus('active')">
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>75</option>
                                                        <option>100</option>
                                            </b-form-select></span>
                                        </b-form-group>
                                    </div>
                                    <div class="total-page-count d-inline-block">
                                        <ul>
                                            <li>
                                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <b-pagination @input="changeTabStatus('active')"   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                </nav> -->
                                <div class="clearfix"></div>
        <!-- <div class="margin-top-70"></div> -->
		</div>
	</div>
    </div>
</template>

<script>
    import { POST_API } from "../../../store/actions.type"
    import moment from 'moment'
    import Bus from "../../../event-bus";

    export default {

        data() {
            return {
                userDatas: '',
				job_title:'',
				job_id:'',
                activeTab:'active',
                keyword:'',
                keywordPending:'',
                keywordShortlisted:'',
                keywordHired:'',
                keywordDeclined:'',
                keywordAll:'',
                error_message:'',
                value: [0, 15000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                new_tab:true,
                shortlisted:false,
                hired:false,
                declined:false,
                all:false,
                activeCount:'',
                shortlistedCount:'',
                hiredCount:'',
                declinedCount:'',
                allCount:'',
                webUrl: process.env.VUE_APP_URL,
            }
        },
        filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        capitalizeAll: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        },
        
        dateTimeAgo: function(value) {
                var m = moment(value);  // or whatever start date you have
                // var today = moment().startOf('day');
                var today = moment();
                    var days = Math.round(moment.duration(today - m).asDays());
                if(days > 0){
                    return days +' Days Ago'
                }else{
                    return 'today'
                }
                // if (!value) return ''
                // const dateTimeAgo = moment(value).fromNow();
                // return dateTimeAgo
		    },
        customizeRoleType: function(value) {
            if (!value) return ''
            var i, frags = value.split('-');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
    },

        methods:{
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },


            getTotalCount() {
                if(this.activeTab == 'active'){
                    this.keywordShortlisted = '';
                    this.keywordHired = '';
                    this.keywordDeclined = '';
                    this.keywordAll = '';
                }else if(this.activeTab == 'shortlisted'){
                    this.keywordPending = '';
                    this.keywordHired = '';
                    this.keywordDeclined = '';
                    this.keywordAll = '';
                }else if(this.activeTab == 'hired'){
                    this.keywordPending = '';
                    this.keywordShortlisted = '';
                    this.keywordDeclined = '';
                    this.keywordAll = '';
                }else if(this.activeTab == 'declined'){
                    this.keywordPending = '';
                    this.keywordShortlisted = '';
                    this.keywordHired = '';
                    this.keywordAll = '';
                }else{
                    this.keywordPending = '';
                    this.keywordShortlisted = '';
                    this.keywordHired = '';
                    this.keywordDeclined = '';
                }
                return this.$store.dispatch(POST_API, {
                    data:{
                        user_id: this.user_id,
                        keywordPending: this.keywordPending,
                        keywordShortlisted: this.keywordShortlisted,
                        keywordHired: this.keywordHired,
                        keywordDeclined: this.keywordDeclined,
                        keywordAll: this.keywordAll,
                    },
                        api: '/api/applied-jobs-total-count'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            // this.showAlert();
                            return [];
                        } else {
                            this.activeCount = this.$store.getters.getResults.activeCount;
                            this.shortlistedCount = this.$store.getters.getResults.shortlistedCount;
                            this.hiredCount = this.$store.getters.getResults.hiredCount;
                            this.declinedCount = this.$store.getters.getResults.declinedCount;
                            this.allCount = this.$store.getters.getResults.allCount;
                        }
                    });
                },

            changeTabStatus(status) {
                this.activeTab = status;
                if(this.activeTab == 'active'){
                    this.keywordShortlisted = '';
                    this.keywordHired = '';
                    this.keywordDeclined = '';
                    this.keywordAll = '';
                }else if(this.activeTab == 'shortlisted'){
                    this.keywordPending = '';
                    this.keywordHired = '';
                    this.keywordDeclined = '';
                    this.keywordAll = '';
                }else if(this.activeTab == 'hired'){
                    this.keywordPending = '';
                    this.keywordShortlisted = '';
                    this.keywordDeclined = '';
                    this.keywordAll = '';
                }else if(this.activeTab == 'declined'){
                    this.keywordPending = '';
                    this.keywordShortlisted = '';
                    this.keywordHired = '';
                    this.keywordAll = '';
                }else{
                    this.keywordPending = '';
                    this.keywordShortlisted = '';
                    this.keywordHired = '';
                    this.keywordDeclined = '';
                }
                return this.$store.dispatch(POST_API, {
                        data:{
                            user_id: this.user_id,
                            keywordPending: this.keywordPending,
                            keywordShortlisted: this.keywordShortlisted,
                            keywordHired: this.keywordHired,
                            keywordDeclined: this.keywordDeclined,
                            keywordAll: this.keywordAll,
                            company_id: this.company_id,
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                            status:status
                },
                    api: '/api/applied-jobs'
                })
                .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    $(".dashboard-content-container .simplebar-scroll-content").animate({
                        scrollTop: 0
                        }, "slow");
                    this.userDatas = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    this.getTotalCount();
                    return this.userDatas;
                }
            });
            },
            // changeTabStatusSearch(status) {
            //     this.activeTab = status;
            //     return this.$store.dispatch(POST_API, {
            //             data:{
            //                 user_id: this.user_id,
            //                 keyword: this.keyword == null ? '': this.keyword,
            //                 company_id: this.company_id,
            //                 page: this.currentPage,
            //                 rowsPerPage:this.form.rowsPerPage,
            //                 status:status
            //     },
            //         api: '/api/applied-jobs'
            //     })
            //     .then(() => {
            //     if (this.$store.getters.containsErrors) {
            //         this.error_message = this.$store.getters.getErrors;
            //         // this.showAlert();
            //         return [];
            //     } else {
            //         $(".dashboard-content-container .simplebar-scroll-content").animate({
            //             scrollTop: 0
            //             }, "slow");
            //         this.userDatas = this.$store.getters.getResults.pageOfItems;
            //         this.totalRows = this.$store.getters.getResults.pager.totalItems;
            //         this.perPage = this.$store.getters.getResults.pager.pageSize;
            //         this.from = this.$store.getters.getResults.pager.from;
            //         this.to = this.$store.getters.getResults.pager.to;
            //         this.getTotalCount();
            //         return this.userDatas;
            //     }
            // });
            // },
            jobDetail(id,event){
                if (event) {
                event.stopPropagation()
                }
                
                // window.location.replace('/v2/jobseeker/ft-jobdetail/'+id);
                window.open('/v2/jobseeker/ft-jobdetail/'+ id, '_blank');

            },
            employerDetail(id,event) {
                if (event) {
                    event.stopPropagation();
                }
                // window.location.replace('/v2/jobseeker/employer-profile/view/'+id);
                window.open("/v2/jobseeker/employer-profile/view/"+ id, "_blank");
            },
            removeApplication(job_id,user_id,event){
                if (event) {
                event.stopPropagation()
                }
                this.$swal({
                    title: 'Please Confirm',
                    text: 'Are you sure you want to remove this job application?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                job_id:job_id,
                                user_id:user_id
                        },
                        api: '/api/cancel-job-application'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            // this.showAlert();
                            return [];
                        } else {
                            var message = this.$store.getters.getResults.message

                            this.showSuccessAlert(message);
                             this.changeTabStatus(this.activeTab);
                        }
                    });
                    }
                })
                .catch(err => {
                })
            },
        },
         mounted(){
             window.scrollTo(0,0);
            $(".dashboard-content-container .simplebar-scroll-content").animate({
                scrollTop: 0
            }, "fast");
            //document.title = "EL Connect - Employer - Manage All Jobs Candidates";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            Bus.$emit('active_users');
            this.changeTabStatus(this.activeTab);
			// this.getTotalCount();

        }
    }
</script>

