<template>
        
    <div>
        
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">

            <h1>Settings(Employer)</h1>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark nav_on_emp">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Settings</li>
                </ul>
            </nav>
        </div>
        <!-- Row -->
        <div class="row mobile_stng">

            <!-- Dashboard Box -->
            <div class="col-xl-12">
                <div class="dashboard-box mobile_top_mrgn">

                    <!-- Headline -->
                    <div class="headline">
                        <h3><i class="icon-material-outline-settings"></i> Settings</h3>
                    </div>

                    <div class="content with-padding padding-bottom-0">

                        <div class="row">

		<div class="col-xl-3 col-md-3">
			<div class="switches-list">
				<div class="switch-container">
					<label class="switch"><input type="checkbox"  true-value="yes" false-value="no" :checked="temp_arr.length > 0"><span class="switch-button"></span> Email Notifications</label>
				</div>

				<div class="switch-container">
                    <div v-for="skillsArrs in skillsArr">
					    <label class="switch" style="margin-left:10%"><input type="checkbox"  true-value="yes" false-value="no" @change="changeNotificationStatus(skillsArrs.id)" :checked="condition && condition.includes(skillsArrs.id)"><span class="switch-button" ></span> {{ skillsArrs.title}} </label>
                    </div>
				</div>
                <br>
            </div>
        </div>
		<div class="col-xl-3 col-md-3">
			<div class="switches-list">
				<div class="switch-container">
					<label class="switch"><input type="checkbox" checked><span class="switch-button"></span> Light / Dark Mode</label>
                    <label class="switch"><input type="checkbox" checked><span class="switch-button"></span> English / Chinese</label>
				</div>
                <br>
            </div>
        </div>
			</div>
		</div>

        </div>
            </div>

            <!-- Dashboard Box -->
            <!-- <div class="col-xl-12">
                <div id="test1" class="dashboard-box">

                    <div class="headline">
                        <h3><i class="icon-material-outline-lock"></i> Password & Security</h3>
                    </div>

                    <div class="content with-padding">
                        <div class="row">
                            <div class="col-xl-4">
                                <div class="submit-field single_profile_eye" id="show_hide__current_password">
                                    <h5>Current Password</h5>
                                     <div class="input-group">
                                    <input type="password" v-model="current_password" class="with-border form-control">
                                <div class="input-group-append">
                                     <a href="">
                                         <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </a>
                                     </div>
                                     </div>
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="submit-field single_profile_eye" id="show_hide__new_password">
                                    <h5>New Password</h5>
                                    <div class="input-group">
                                    <input type="password" v-model="new_password" class="with-border form-control">
                                    <div class="input-group-append">
                                     <a href="">
                                         <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </a>
                                     </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="submit-field single_profile_eye" id="show_hide__repeat_password">
                                    <h5>Repeat New Password</h5>
                                    <div class="input-group">
                                    <input type="password" v-model="repassword" class="with-border form-control">
                                    <div class="input-group-append">
                                     <a href="">
                                         <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </a>
                                     </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12">
                                <div class="checkbox">
                                    <input type="checkbox" id="two-step" checked>
                                    <label for="two-step"><span class="checkbox-icon"></span> Enable Two-Step Verification Login via Email</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            
            <!-- Button -->
            <div class="col-xl-12">
                <a href="#" @click="changePassword()" class="button ripple-effect big margin-top-30 stng_mbl">Save Changes</a>
            </div>

        </div>
        <!-- Row / End -->

    </div>

</template>

<script>
import { POST_API, GET_API } from '../../store/actions.type'
import Bus from "../../event-bus";


export default {

    data() {
        return {
            form:{
                email_notifications:'no',
                forgotMail:'no',
                email_temp:'no',

                daily_paid_application_status:'no',
                message_notifications:'no',
                latest_news_subscriptions:'no',
                el_coins_expiring:'no',
                recommended_jobs:'no',
                light_dark_mode:'no',
                english_chinese:'no'
            },
            current_password: '',
            new_password: '',
            repassword: '',
            skillsArr: [],
            temp_arr:  [],
            condition:  [],

            error_message:''
        }
        },
    components: {
    },
    methods:{
        updateNotifications(e){
				if (e == 'no') {
                    this.form.job_application_status = 'no';
                    this.form.daily_paid_application_status = 'no';
                    this.form.message_notifications = 'no';
                    this.form.latest_news_subscriptions = 'no';
                    this.form.el_coins_expiring = 'no';
                    this.form.recommended_jobs = 'no';
                }
        },

        changeNotificationStatus(id) {



            if(!this.temp_arr.includes(id)){
                this.temp_arr.push(id);
            }else{
                const index = this.temp_arr.indexOf(id);
                this.temp_arr.splice(index, 1);
            }
        },
        changePassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        email: this.$store.getters.currentUser.email,
                        user_type: this.$store.getters.currentUser.user_type_id,
                        user_id: this.$store.getters.currentUser.id,
                        // current_password: this.current_password,
                        // new_password: this.new_password,
                        // confirm_new_password: this.repassword,
                        email_notifications:this.form.email_notifications,
                        job_application_status:this.form.forgotMail,
                        item : this.form,
                        temp_id: this.temp_arr
                    },
                    api:'/api/changepassword-email'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.current_password = "";
                        this.new_password = "";
                        this.repassword = "";
                    
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Settings Updated successfully',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                        });
                    }        
                }); 
            },
            getEmailTemp(type){
                if(this.$store.getters.currentUser.user_type_id == 3){
                   var user_type = 'employer';
                }else{
                   var user_type = 'jobseeker';

                }
            this.$store.dispatch(POST_API, {
                
                data:{
                    table:user_type
                },
                api:'/api/email-temp'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    
                    this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: this.error_message,
                        showConfirmButton: false,
                        timer: 1500
                    });
                            
                } else {
                    this.skillsArr = this.$store.getters.getResults.data;
                }       
            }); 
        },

        getChecked(){
            this.$store.dispatch(POST_API, {

                data:{
                    id: this.$store.getters.currentUser.id
                },
                
                api:'/api/getChecked'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    
                    this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: this.error_message,
                        showConfirmButton: false,
                        timer: 1500
                    });
                            
                } else {

                    for (let i = 0; i < this.$store.getters.getResults.data.length; i++) {
                        this.condition.push(this.$store.getters.getResults.data[i].template_id);
                        this.temp_arr.push(this.$store.getters.getResults.data[i].template_id);
                    }
                
                }       
            }); 
        },
    },
    mounted(){
            //document.title = "EL Connect - Employer - Settings";
            this.getEmailTemp('email_templates');
            Bus.$emit('active_users');

            this.getChecked();

    },
}
</script>
