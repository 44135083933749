import { render, staticRenderFns } from "./AdminResetPasswordPage.vue?vue&type=template&id=e17da18c&scoped=true&"
import script from "./AdminResetPasswordPage.vue?vue&type=script&lang=js&"
export * from "./AdminResetPasswordPage.vue?vue&type=script&lang=js&"
import style0 from "./AdminResetPasswordPage.vue?vue&type=style&index=0&id=e17da18c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e17da18c",
  null
  
)

export default component.exports