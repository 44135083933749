<template>
  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Bonus Users List</h1>
        </div>

        <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
              </div> -->
      </div>
    </div>
    <b-row>
      <b-col lg="12">
          <div class="tab-box--head top_custom_head desktop_search_top_div">
            <div class="row">
              <div class="col-xl-4 mobile_max_widht_and_flex search_common_margin_bottom_28">
                <div class="select-service-box date-picker-field clearfix">
                  <!-- <div class="label_div text_align_end_desktop">
                            <label>Date</label>
                    </div> -->
                  <b-form-group id="input-group-7" class="required mb-0">
                    <datepicker v-model="month" @input="dateFilterFunction" :format="customFormatter" minimum-view="month" maximum-view="year" class="add_calender_icon common_normal_calender_height freelancer_incentive_calender"></datepicker>   
                  </b-form-group>
                </div>
              </div>
              <div
                class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
              >
                <div class="search-box custom_search_admin desktop_margin_top_zero">
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = search"
                      v-model="search"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = search"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>
              <div class="col-xl-4 search_common_margin_bottom_28">
                <b-button v-if="show('export')"
                  class="common_z_index_zero button ripple-effect ml-0 new_add_btn_admin_e desktop_margin_right_zero"
                  @click="exportData()"
                  >Export</b-button>
              </div>
            </div>
          </div>
          <div class="my_new_container show_filter_arrow">
            <b-table
              ref="datatable"
              show-empty
              striped
              hover
              responsive
              :items="getBonusListing"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="new_layout_table_like_v1"
            >
            <template #cell(no)="items"  >
                    {{(items.index + 1)}}
                  </template>
              <template #cell(full_name)="items">
                      <div class="new-name-td-box bonus_user_name_with_image">
                        <div class="whole_div_img_and_name">
                          <b-img
                              v-if="items.item.thumbnail || items.item.profile_image"
                              :src="
                                items.item.thumbnail
                                  ? items.item.thumbnail
                                  : items.item.profile_image
                                  ? items.item.profile_image
                                  : webUrl + 'assets/images/user-avatar-small-01.png'
                              "
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img"
                            >
                            </b-img>
                            <b-img
                              v-else
                              :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                              alt="User Icon"
                              height="100px"
                              width="100px"
                              class="square_img"
                            >
                            </b-img>
                          <div class="user_name_div">
                            <b-link
                              :to="
                                showFreelancer('view profile')
                                  ? {
                                      name: 'jobseeker-dp-profile-view',
                                      params: { id: encrypt(items.item.id) },
                                    }
                                  : null
                              "
                              target="_blank"
                              style="color: #4c8bf5"
                            >
                              {{ items.item.full_name | capitalize }}
                            </b-link>
                          </div>
                        </div>
                        
                      </div>
                    </template>
                <template #cell(mobile_number)="items">
                  <b-link :href="'https://wa.me/+65' + items.item.mobile_number" target="_blank">
                  {{items.item.country_code}}-{{items.item.mobile_number}}
                  </b-link>
                </template>
              <template #cell(created_at)="items">
                  {{ items.item.created_at | moment}}
              </template>
              <template #cell(approved_on)="items">
                  {{ items.item.approved_on | moment}}
              </template>
              <template #cell(approved_by)="items">
                  {{items.item.approved_by != null ? items.item.approved_by.full_name : '-' | capitalize}}
              </template>
              <template #cell(transaction_id)="items">
                  {{ items.item.user_transactions != null ? items.item.user_transactions.transaction_number : '-'}}
              </template>
              <template #cell(paid_on)="items">
                  {{items.item.user_transactions != null ? items.item.user_transactions.created_at : '' | moment}}
              </template>
            </b-table>
          </div>
          <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
              <b-form-group id="input-group-4">
                <span class="d-inline-block">Rows per page</span>
                <span class="d-inline-block"
                  ><b-form-select v-model="form.rowsPerPage" @change="getBonusListing">
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                  </b-form-select></span
                >
              </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
              <ul>
                <li>
                  <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                </li>
              </ul>
            </div>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              prev-text=""
              next-text=""
              hide-goto-end-buttons
            />
          </nav>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      fields: [
        { key: "no", label: "No.", sortable: true, thStyle: { width: "10%" } },
        { key: "full_name", label: "Name", sortable: true, thStyle: { width: "20%" } },
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "10%" } },
        { key: "created_at", label: "Register On", sortable: true, thStyle: { width: "15%" } },
        { key: "approved_on", label: "Approved On", sortable: true, thStyle: { width: "15%" } },
        { key: "approved_by", label: "Approved By", sortable: true, thStyle: { width: "10%" } },
        { key: "transaction_id", label: "Transaction ID", sortable: true, thStyle: { width: "10%" } },
        { key: "paid_on", label: "Paid On", sortable: true, thStyle: { width: "10%" } },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      search: "",
      perPage: 25,
      itemsJobs: null,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,
      month: moment().format('DD MMM YYYY'),
      pageOptions: [25, 50, 75, 100],
      locale: {
        direction: "ltr", //direction of text   
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      webUrlV1: process.env.VUE_APP_URL_V1,
      form: {
        rowsPerPage: 25,
        status: "",
      },
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
      actionsFreelancer: "",
      user_id:"",
      designation:""

    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    moment: function(date) {
            if (!date) return '-'
            return moment(date).format('DD-MM-YYYY hh:mm A')
     },
    momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
  },
  components: {
    Datepicker,
  },
  methods: {
    customFormatter(date) {
            return moment(date).format('MMM-YYYY');
     },
    exportData() {
      var base_url = this.webUrlV1;
      //var page = this.currentPage;
      var keyword = this.search ? this.search : "null";
      //var rowsPerPage = this.form.rowsPerPage;
      var monthYear = this.month? moment(this.month).format("YYYY-MM")
              : "null";
      //var sortBy = this.sortBy ? this.sortBy : "date";
      //var sortDirection = this.sortDesc ? "desc" : "asc";
      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/export-bonus-users-list/" +
        keyword +
        "/" +
        monthYear;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    getBonusListing() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.search,
            monthYear: this.month? moment(this.month).format("MM-YYYY")
              : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "date",
            sortDesc:this.sortDesc,
            sortDirection: this.sortDirection ? "desc" : "asc",
          },
          api: "/api/bonus-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.totalRows = this.$store.getters.getResults.data.total;
            this.perPage = this.$store.getters.getResults.data.per_page;
            this.from = this.$store.getters.getResults.data.from
            this.to = this.$store.getters.getResults.data.to
              
            return this.items;
          }
        });
    },
    dateFilterFunction()
    {
        this.getBonusListing().then(() => {
            this.$refs.datatable.refresh();
        })
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Bonus Users List");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    show(action){
        if (this.$store.getters.currentUser.user_type_id == 2) {
            return this.actions.indexOf(action) >= 0 ? true : false ;  
        }else{
            return true;
        }
    },
    showFreelancer(action) {
      return true;
      // if (this.$store.getters.currentUser.user_type_id == 2) {
      //   return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      // } else {
      //   return true;
      // }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
      this.permissionStaff();
  },
};
</script>
