<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Blog</h1 >
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Add Blog</h1 >
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg commmon_label_heading common_padding_30">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12" class="submit-field">
                                        <h5 id="title-group" class="required">Title <span class="required_star">*</span></h5>
                                        <b-form-input id="title" v-model="form.title" type="text" placeholder="Enter Title.."></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12" class="submit-field">
                                    <h5 id="title-group" class="required">Description <span class="required_star">*</span></h5>
                                    <ckeditor v-model="form.description" :config="editorConfig"></ckeditor>
                                        <!-- <textarea cols="30" rows="5" class="with-border" v-model="form.description"></textarea> -->

                                    <!-- <br> -->
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12" class="submit-field">
                                        <h5 id="title-group" class="required">SEO Title</h5>
                                        <b-form-input id="title" v-model="form.seo_title" type="text" placeholder="Enter SEO Title.."></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12" class="submit-field">
                                        <h5 id="title-group" class="required">SEO Description</h5>
                                        <b-form-textarea id="title" v-model="form.seo_description" type="textarea" placeholder="Enter SEO Description.."></b-form-textarea>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12" class="submit-field">
                                        <h5 id="title-group" class="required">SEO Permalink</h5>
                                        <b-form-input id="title" v-model="form.seo_permalink" type="text" placeholder="Enter SEO Permalink.."></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="4" md="4" class="submit-field">
                                        <h5 id="type-group" class="required">Date <span class="required_star">*</span></h5>
                                        <datepicker v-model="form.date" :format="customFormatterTwo" :disabled-dates="disabledFromDate" class="certificate_date_picker"></datepicker>
                                </b-col>
                                <b-col lg="4" md="4" class="submit-field">
                                    <h5 id="title-group" class="required">Tag <span class="required_star">*</span></h5>
                                    <b-form-input id="title" v-model="form.tag" type="text" placeholder="Enter Tag.."></b-form-input>
                                </b-col>
                                <b-col lg="4" md="4" class="submit-field">
                                        <h5 id="title-group" class="required">Featured Post</h5>
                                        <b-form-select data-size="7" title="Select Option"  v-model="form.featured_post" class="custom-select web_bottom_zero">
                                            <option value="" disabled>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no" selected>No</option>
                                        </b-form-select>
                                </b-col>
                               <!--  <b-col lg="4" md="4" class="submit-field">
                                        <h5 id="title-group" class="required">Recent Post</h5>
                                        <b-form-select data-size="7" title="Select Option"  v-model="form.recent_post" class="custom-select web_bottom_zero">
                                            <option value="" disabled>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no" selected>No</option>
                                        </b-form-select>
                                </b-col>
                                <b-col lg="4" md="4" class="submit-field">
                                        <h5 id="title-group" class="required">Trending Post</h5>
                                        <b-form-select data-size="7" title="Select Option"  v-model="form.trending_post" class="custom-select web_bottom_zero">
                                            <option value="" disabled>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no" selected>No</option>
                                        </b-form-select>
                                </b-col> -->
                            </b-row>
                            <b-row>
                              <b-col lg="3" md="3" class="submit-field">
                                    <div class="upload-form-group">
                                        <h5 id="title-group" class="required">Upload Cover Photo <span class="required_star">*</span></h5>
                                        <b-form-group id="link-group" class="required">
                                            <div class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.cover.image ? 'if_image_exist' : 'if_image_not_exist'">
                                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onCoverImageChange" ref="coverimage"  v-model="form.cover_image"></b-form-file>
                                                <span v-bind:style="form.cover.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                    <b-img v-if='form.cover.image' :src="form.cover.image" alt="cover-image" class="actual_img"></b-img>
                                                    <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" alt="image" class="dummy_img"></b-img>
                                                </span>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col lg="3" md="3" class="submit-field">
                                    <div class="upload-form-group">
                                        <h5 id="title-group" class="required">Upload Banner Image <span class="required_star">*</span></h5>
                                        <b-form-group id="link-group" class="required">
                                            <div class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.selectedImage.image ? 'if_image_exist' : 'if_image_not_exist'">
                                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                                <span v-bind:style="form.selectedImage.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                    <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="image"></b-img> -->
                                                    <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" alt="image" class="actual_img"></b-img>
                                                    <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" alt="image" class="dummy_img"></b-img>
                                                </span>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'blog-list'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
                <b-modal ref="crop-modal" title="Edit Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImg">
                        <template>
                              <div>
                                <div class="content" @change="setImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImage">Crop</b-button>
                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
                </b-modal>

                <b-modal ref="crop-modal-cover" title="Edit Cover Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitCoverImg">
                        <template>
                              <div>
                                <div class="content" @change="setCoverImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropCoverImage">Crop</b-button>
                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import CKEditor from 'ckeditor4-vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";

export default {

    data() {
        return {
            form: {
                title: '',
                description: '',
                seo_title: '',
                seo_description: '',
                seo_permalink: '',
                tag: '',
                featured_post:'',
                recent_post:'',
                trending_post:'',
                date:'',
                image:'',
                selectedImage:{
                    name:'',
                    image:''
                },
                cover_image:'',
                cover:{
                  name: '',
                  image:''
                },
            },
            items: null,
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,

            delta: undefined,
            editorConfig: {
                versionCheck : false,
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify',
                allowedContent : true
                },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
              // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            },
            webUrl: process.env.VUE_APP_URL,
            cropImg: '',
            cropCoverImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true,
            disabledFromDate: {
                from: new Date(Date.now() + 8640000)
            },
        }
    },
    components: {
        ckeditor: CKEditor.component,
        VueCropper,
        Datepicker
    },

    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'blog-list'},
                text: 'Blog',
            },{
                to:null,
                text: 'Add Blog'
            }];
            return item;
        },
        customFormatterTwo(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        cropImage() {
          this.$refs['crop-modal'].hide();
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        cropCoverImage() {
          this.$refs['crop-modal-cover'].hide();
          this.form.cover.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
              this.cropImg = event.target.result;
              this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        setCoverImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
              this.cropCoverImg = event.target.result;
              this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        showCoverImg() {
            this.$refs['crop-modal-cover'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();
            this.$refs['crop-modal'].hide();
            this.$refs['crop-modal'].refresh();
        },
        onSubmitCoverImg(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-cover'].hide();
            this.$refs['crop-modal-cover'].refresh();
        },
        onImageChangePng(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.image = input.files[0];
                if (this.form.image.type != 'image/png' && this.form.image.type != 'image/jpeg' && this.form.image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    this.showAlert();
                } else if (this.form.image.size > 5242880) {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Image should be less than 5 MB.';
                    this.form.selectedImage.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.image.name;
                        this.showImg();
                        event.target.value = '';

                    }
                }
            }
        },
        onCoverImageChangePng(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.cover_image = input.files[0];
                if (this.form.cover_image.type != 'image/png' && this.form.cover_image.type != 'image/jpeg' && this.form.cover_image.type != 'image/jpg') {
                    this.form.cover.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.cover.image='';
                    this.showAlert();
                } else if (this.form.cover_image.size > 5242880) {
                    this.form.cover.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Image should be less than 5 MB.';
                    this.form.cover.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.cover.name = this.form.cover_image.name;
                        this.showCoverImg();
                        event.target.value = '';

                    }
                }
            }
        },
        onImageChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.image = input.files[0];
                if (this.form.image.type != 'image/jpeg' && this.form.image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    this.showAlert();
                } else if (this.form.image.size > 5242880) {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Image should be less than 5 MB.';
                    this.form.selectedImage.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.image.name;
                        this.showImg();
                        event.target.value = '';
                    }
                }
            }
        },
        onCoverImageChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.cover_image = input.files[0];
                if (this.form.cover_image.type != 'image/jpeg' && this.form.cover_image.type != 'image/jpg') {
                    this.form.cover.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .jpg or .jpeg image.';
                    this.form.cover.image='';
                    this.showAlert();
                } else if (this.form.cover_image.size > 5242880) {
                    this.form.cover.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Image should be less than 5 MB.';
                    this.form.cover.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.cover.name = this.form.cover_image.name;
                        this.showCoverImg();
                        event.target.value = '';
                    }
                }
            }
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'blog-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {

            if(this.form.seo_permalink){
                this.form.seo_permalink = this.form.seo_permalink.replace('&', "and")
                this.form.seo_permalink = this.form.seo_permalink.replace('?', "")
                this.form.seo_permalink = this.form.seo_permalink.toLowerCase();
            }else{
                this.form.seo_permalink = this.form.title.replace(/ /g, "-")
                this.form.seo_permalink = this.form.seo_permalink.replace('&', "and")
                this.form.seo_permalink = this.form.seo_permalink.replace('?', "")
                this.form.seo_permalink = this.form.seo_permalink.toLowerCase();
            }


            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        title: this.form.title,
                        description: this.form.description,
                        date: this.form.date,
                        tag: this.form.tag,
                        featured_post: this.form.featured_post,
                        recent_post: this.form.recent_post,
                        trending_post: this.form.trending_post,
                        image: this.form.selectedImage.image,
                        cover_image: this.form.cover.image,
                        seo_title: this.form.seo_title,
                        seo_description: this.form.seo_description,
                        seo_permalink: this.form.seo_permalink ? this.form.seo_permalink : this.form.title.replace(/ /g, "-"),
                        items:this.form,
                    },
                    api: "/api/blog-store",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Blog Updated successfully.'
                        this.showSuccessAlert(message);
                        this.$router.push({ name: 'blog-list' });
                    }
                });
        },


    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
       // document.title = "EL Connect - Admin - Website Contents - Edit Blog";
    },
};

</script>
