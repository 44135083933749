<template>
        
    <div>
        
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">

            <h1>Change Password</h1>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark nav_on_emp">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Change Password</li>
                </ul>
            </nav>
        </div>
        <!-- Row -->
        <!-- <div class="row mobile_stng"> -->
        <div class="row">

            <!-- Dashboard Box -->
            <div class="col-xl-12">
                <div id="test1" class="dashboard-box desktop_margin_top_zero">

                    <!-- Headline -->
                    <div class="headline">
                        <h3><i class="icon-material-outline-lock"></i> Password & Security</h3>
                    </div>

                    <div class="content with-padding">
                        <div class="row">
                            <div class="col-xl-4">
                                <div class="submit-field big_screen_bottom_28 mobile_margin_bottom_28 desktop_margin_bottom_zero  single_profile_eye" id="show_hide_current_password">
                                    <h5>Current Password <span class="required_star">*</span></h5>
                                     <div class="input-group ">
                                        <input type="password" @click="showPasswordRequirements" v-model="current_password" class="with-border form-control new_group_space">
                                        <div class="input-group-append">
                                            <a href="" @click="passwordCurrent">
                                                <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                     </div>
                                </div>
                                 <div class="password_requirements_text abs_div desktop_margin_bottom_zero" :style="{ backgroundImage: 'url('+webUrl+'assets/images/password-bg-img.png'+')'}">
                                    <span>Minimum 8 characters, must contain an uppercase letter, a
                                        lowercase letter, at least 1 number and 1 special character
                                    </span>
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="submit-field big_screen_bottom_28 mobile_margin_bottom_28 desktop_margin_bottom_zero single_profile_eye" id="show_hide_new_password">
                                    <h5>New Password <span class="required_star">*</span></h5>
                                    <div class="input-group">
                                    <input type="password" @click="hidePasswordRequirements" v-model="new_password" class="with-border form-control new_group_space">
                                    <div class="input-group-append">
                                     <a href=""  @click="passwordNew">
                                         <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </a>
                                     </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="submit-field desktop_margin_bottom_zero single_profile_eye" id="show_hide_repeat_password">
                                    <h5>Repeat New Password <span class="required_star">*</span></h5>
                                    <div class="input-group">
                                    <input type="password" @click="hidePasswordRequirements"  v-model="repassword" class="with-border form-control new_group_space">
                                    <div class="input-group-append">
                                     <a href=""  @click="passwordRepeat">
                                         <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </a>
                                     </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-xl-12">
                                <div class="checkbox">
                                    <input type="checkbox" id="two-step" checked>
                                    <label for="two-step"><span class="checkbox-icon"></span> Enable Two-Step Verification Login via Email</label>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Button -->
            <div class="col-xl-12">
                <a @click="changePassword()" class="button new_mobile_margin_bottom_zero ripple-effect big margin-top-30 stng_mbl common_vertical_align_bottom" style="color: white;">Save Changes</a>
            </div>

        </div>
        <!-- Row / End -->

    </div>

</template>

<script>

import { POST_API, GET_API } from '../../store/actions.type'
import Bus from "../../event-bus";


export default {
    data() {
        return {
            webUrl: process.env.VUE_APP_URL,
            current_password: '',
            new_password: '',
            repassword: '',
            temp_arr:  [],
            condition:  [],

            error_message:''
        }
        },
    components: {
    },
    methods:{
        showPasswordRequirements()
        {
            $('.password_requirements_text').show();
            $('.password_margin').addClass('desktop_margin_bottom_zero');
            // $('.margin_jb').addClass('margin_bottom_fifty_five');
        },
        hidePasswordRequirements(){
            $('.password_margin').removeClass('desktop_margin_bottom_zero');
            // $('.margin_jb').removeClass('margin_bottom_fifty_five');
            $('.password_requirements_text').hide();
        },
        passwordCurrent(event){
                event.preventDefault();
                if($('#show_hide_current_password input').attr("type") == "text"){
                    $('#show_hide_current_password input').attr('type', 'password');
                    $('#show_hide_current_password i').addClass( "fa-eye-slash" );
                    $('#show_hide_current_password i').removeClass( "fa-eye" );
                }else if($('#show_hide_current_password input').attr("type") == "password"){
                    $('#show_hide_current_password input').attr('type', 'text');
                    $('#show_hide_current_password i').removeClass( "fa-eye-slash" );
                    $('#show_hide_current_password i').addClass( "fa-eye" );
                }
        },
        passwordNew(event){
            event.preventDefault();
            if($('#show_hide_new_password input').attr("type") == "text"){
                $('#show_hide_new_password input').attr('type', 'password');
                $('#show_hide_new_password i').addClass( "fa-eye-slash" );
                $('#show_hide_new_password i').removeClass( "fa-eye" );
            }else if($('#show_hide_new_password input').attr("type") == "password"){
                $('#show_hide_new_password input').attr('type', 'text');
                $('#show_hide_new_password i').removeClass( "fa-eye-slash" );
                $('#show_hide_new_password i').addClass( "fa-eye" );
            }
        },
        passwordRepeat(event){
            event.preventDefault();
            if($('#show_hide_repeat_password input').attr("type") == "text"){
                $('#show_hide_repeat_password input').attr('type', 'password');
                $('#show_hide_repeat_password i').addClass( "fa-eye-slash" );
                $('#show_hide_repeat_password i').removeClass( "fa-eye" );
            }else if($('#show_hide_repeat_password input').attr("type") == "password"){
                $('#show_hide_repeat_password input').attr('type', 'text');
                $('#show_hide_repeat_password i').removeClass( "fa-eye-slash" );
                $('#show_hide_repeat_password i').addClass( "fa-eye" );
            }
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        changePassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        email: this.$store.getters.currentUser.email,
                        user_type: this.$store.getters.currentUser.user_type_id,
                        user_id: this.$store.getters.currentUser.id,
                        current_password: this.current_password,
                        new_password: this.new_password,
                        confirm_new_password: this.repassword,
                        item : this.form,
                    },
                    api:'/api/changepassword'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.showAlert();
                             
                    } else {
                        // this.current_password = "";
                        // this.new_password = "";
                        // this.repassword = "";
                    
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Password Changed successfully',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {

                             if(this.user_type_id=='5'){
                                 window.location.replace('/v2/jobseeker/jobseeker-profile');
                             }

                            if(this.user_type_id=='3')
                            {
                                window.location.replace('/v2/employer/employer-profile');
                            }
                        });
                    }        
                }); 
            },
    },
    mounted()
    {
        $('.password_requirements_text').hide();
         window.scrollTo(0,0);
         $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        Bus.$emit('active_users');

        this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');
            if(this.user_type_id=='5')
            {
                document.title = "EL Connect - Jobseeker - Change Password"; 
                document.querySelector('meta[name="description"]') == 'EL Connect - Jobseeker - Change Password';
            }
            if(this.user_type_id=='3')
            {
                document.title = "EL Connect - Employer - Change Password"; 
                document.querySelector('meta[name="description"]') == 'EL Connect - Employer - Change Password';
            }
    }
}
</script>
