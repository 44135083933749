<template>

    <div>
 <!-- <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
        </div> -->
        <!-- Dashboard Headline -->
        <div class="dashboard-headline job_profile_headline">
            <h1> <span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Daily Paid Profile</h1>
            <!-- Breadcrumbs -->
        </div>
        <div class="notification notice common_margin_bottom_28" v-if="status == 'pending'">
            <p><span class="icon-feather-info"></span> Account status {{status}}</p>
            <a class="close"></a>
        </div>
        <div class="notification warning" v-if="status == 'incomplete'">
            <p><span class="icon-feather-info"></span> Account status {{status}} , to complete profile:</p>
            <ul class="notification_box">
                <li v-if="this.form.mobile_verified_check != true || this.form.email_verify == 'no'"> - Verify Mobile Number & Email Address</li>
                <!-- <li v-if="this.form.selectedImage.image == '' || this.form.nric_front.image == '' || this.form.nric_back.image == ''"> - Upload Passport Photo & NRIC Images</li> -->
                <!-- <li v-if="(this.form.nric == '' || this.form.nric == null) || (this.form.paynow == '' && (this.form.bank == '' || this.form.acc_number == '' || this.form.holder_name == ''))"> - Fill NRIC & Payout Details</li> -->
                <li v-if="this.form.selectedImage.image == '' || this.form.nric_front.image == '' || this.form.nric_back.image == '' || (this.form.nric == '' || this.form.nric == null) || (this.form.paynow == '' && (this.form.bank == '' || this.form.acc_number == '' || this.form.holder_name == '')) || (this.form.dob == '' || this.form.dob == 'Invalid date' || this.form.dob == null) || this.form.gender == null || this.form.nationality == null || this.form.raceid == null "> - Complete Personal Profile section</li>
            </ul>
            <a class="close"></a>
        </div>
        <!-- Row -->
        <!-- <div class="row second_indiv_step_wrap overflow_hidden_mobile"> -->
        <div class="row second_indiv_step_wrap ">
            <b-form method="post" id="register-account-form" class="single_jb_frm">

                <!-- Dashboard Box -->
                <div class="col-xl-12    ">
                    <!--<b-form-group id="link-group"  class="top_banner_jobs_full">
                            <div class="single-image-upload">
                                <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChange" ref="image"  v-model="form.banner_image"></b-form-file>
                                <span>
                                   <b-img v-if='form.profile_image' :src="form.profile_image.image ? form.profile_image.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;"></b-img>
                                <b-img v-if='form.profile_image.image' :src="form.profile_image.image"></b-img>

                               <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>
                                <i v-else   class="fa fa-camera default_img"></i>
                                </span>
                            </div>
                        </b-form-group>-->
                        <!--<p class="img_txt">Banner Image<span>(Banner Image size should be 952*280.)</span></p>-->
                    <div class="dashboard-box new_job_bx desktop_margin_top_zero">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-face"></i> Personal Profile</h3>
                                 <!-- <button @click="retriveData()" type="button" class="button ripple-effect" style="float: right;margin-bottom:-25px;margin-top:-34px;background-color:red">Retrive my info from SINGPASS</button> -->
                        </div>

                        <div class="content with-padding">

                            <div class="row">
                                <!-- <div class="col-auto mobile_cl">
                                    <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                        <img class="profile-pic" :src="form.image" alt="" />
                                        <div class="upload-button"></div>
                                        <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                    </div>
                                    <div class="single-image-upload">
                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image"></b-form-file>
                                        <span>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 300px;"></b-img>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 300px;"></b-img>
                                        </span>
                                    </div>
                                </div>-->

                                <div class="col ">
                                    <div class="row">

                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Given Name <span class="required_star">*</span></h5>
                                                <input type="text" v-model="form.first_name" class="with-border" id="first_name">
                                                <input type="hidden" v-model="form.user_id" class="with-border" >
                                            </div>
                                             <div class="show_span">
                                                     <span class="error_x_white">Please enter Given Name </span>
                                                  </div>
                                        </div>

                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Surname <span class="required_star">*</span></h5>
                                                <input type="text" v-model="form.last_name" class="with-border " id="last_name">
                                            </div>
                                             <div class="show_span">
                                                     <span class="error_x_white">Please enter Surname </span>
                                                  </div>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Daily Paid Account Status <span class="required_star"></span></h5>
                                                <input type="text" v-model="account_verification_status" class="with-border form-control required-field" id="status" disabled>
                                            </div>

                                        </div>
                                        <b-input-group>

                                            <div class="col-xl-6">

                                                <div class=" error_submit_field mobile_view_numbr desktop_margin_bottom_zero mobile_email submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 " >
                                                    <h5>Mobile Number <span class="required_star">*</span><span class="verify_badge" v-if="this.form.mobile_verified_check == true"><i class="fa fa-check"></i>Verified</span></h5>
                                                    <div class="input-group mb-3 web_bottom_zero error_submit_field">
                                                    <input type="text" v-model="form.country_code" class="with-border mobile_input_prf form-control required-field country_code_txt" readonly="readonly">

                                                    <input type="text" v-model="form.mobile_number" class="with-border mobile_input_prf form-control required-field" id="number">
                                                    <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                        <button @click="sendOTP" class="btn btn-outline-primary" type="button">Send OTP</button>
                                                    </div> -->

                                                    </div>
                                                     <span class="error_x_white web_bottom_zero">Please enter Contact Number </span>

                                                </div>
                                                <!-- <div class="show_span">
                                                     <span class="error_x_white">Please enter Contact Number </span>
                                                  </div> -->
                                            </div>
                                            <div class="col-xl-6">

                                            <div class="error_submit_field submit-field mobile_view_numbr mobile_email web_bottom_zero" >
                                                    <h5>Email Address<span class="required_star">*</span><span class="verify_badge" v-if="this.form.email_verify == 'yes'"><i class="fa fa-check"></i>Verified</span></h5>
                                                    <div class="input-group mb-3 web_bottom_zero error_submit_field">
                                                    <input type="text" v-model="form.email" class="with-border mobile_input_prf form-control required-field" id="email">
                                                    <!-- <div class="input-group-append" v-if="this.form.email_verify == 'no'" >
                                                        <button class="btn btn-outline-primary" @click="emailVerify" type="button">Resend Link</button>
                                                    </div> -->
                                                    </div>
                                                     <span class="error_x_white web_bottom_zero">Please enter Email </span>

                                                </div>
                                                 <!-- <div class="show_span">
                                                     <span class="error_x_white">Please enter Email </span>
                                                  </div> -->
                                        </div>
                                        </b-input-group>



                                            <!--<div class="col-xl-6">

                                                <div class="submit-field mobile_view_numbr mobile_email"  v-if="this.form.mobile_verified_check == false"  >
                                                    <h5>Verification Code</h5>
                                                    <div class="input-group mb-3">
                                                    <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" id="verification">
                                                    <div class="input-group-append" v-if="this.form.verify_otp_button == true" >
                                                        <button class="btn btn-outline-primary" @click="mobileVerify" type="button">Verify OTP</button>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="show_span">
                                                                <span id="verification-warning-message" class="text-danger"> </span>
                                                            </div>
                                            </div>-->

                                        <!--<div class="col-xl-6">

                                            <div class="submit-field mobile_view_numbr mobile_email" >
                                                    <h5>Email <span class="required_star">*</span><span class="verify_badge" v-if="this.form.email_verify == 'yes'"><i class="fa fa-check"></i>Verified</span></h5>
                                                    <div class="input-group mb-3">
                                                    <input type="text" v-model="form.email" class="with-border mobile_input_prf form-control required-field" id="email">
                                                    <div class="input-group-append" v-if="this.form.email_verify == 'no'" >
                                                        <button class="btn btn-outline-primary" @click="emailVerify" type="button">Resend Link</button>
                                                    </div>
                                                    </div>
                                                </div>
                                                 <div class="show_span">
                                                     <span class="error_x_white">Please enter Email </span>
                                                  </div>
                                        </div>-->
                                        <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Address</h5>
                                            <input type="text" class="with-border required-field" v-model="form.address" id="address">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Address </span>
                                                  </div>
                                    </div>-->
                                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Gender <span class="required_star">*</span></h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select" id="gender">
                                                <option value="" disabled>Select</option>
                                                <option value="female">Female</option>
                                                <option value="male" selected>Male</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div>
                                    </div>-->
                                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Nationality <span class="required_star">*</span></h5>
                                            <b-form-select v-model="form.nationality" class="custom-select">
                                                <option value="" disabled>Select</option>
                                                <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.country_name}}</option>
                                            </b-form-select>
                                        </div>
                                    </div>-->

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-xl-12    ">
                    <div class="dashboard-box">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-account-circle"></i>Personal Profile</h3>
                        </div>

                        <div class="content">
                            <ul class="fields-ul">
                            <li class="common_padding_30">
                                <div class="row">
                                   <div class="col-auto big_screen_max_width_and_flex mobile_cl max_width_and_flex_769 col-xl-3 mobile_max_width_and_flex submit-field">
                                        <h5>Passport/Selfie Image<span class="required_star">*</span><br><b-link @click="showsampleimageModel" style="color: #4c8bf5 !important;">(Example Sample)</b-link></h5>
                                    <!-- <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                        <img class="profile-pic" :src="form.image" alt="" />
                                        <div class="upload-button"></div>
                                        <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                    </div> -->
                                    <div class="mobile_tab_image_change single-image-upload emp_img_one top_banner_jobs_full new_update_banner profile_top_div">
                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.profile_image"></b-form-file>
                                        <span>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 300px;"></b-img>
                                            <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 300px;"></b-img> -->
                                        </span>
                                    </div>
                                </div>

                                <div class="col-xl-4 max_width_and_flex_769 mobile_max_width_and_flex submit-field custom_width_nric">
                                    <h5 class="img_txt height_31_19 height_38_banner_heading">NRIC Front <span style="color:red !important">*</span></h5>
                                    <b-form-group id="link-group"  class="mobile_tab_image_change top_banner_jobs_full new_update_banner">
                                    <div class="single-image-upload" v-if="designation != 'Customer Service'">
                                        <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front"></b-form-file>
                                        <span>
                                        <!-- <b-img v-if='form.profile_image' :src="form.profile_image.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;"></b-img>-->
                                        <b-img v-if='form.nric_front.image' :src="form.nric_front.image"></b-img>

                                    <!-- <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                                        <i v-else   class="fa fa-camera default_img"></i>
                                        </span>
                                    </div>

                                    <div class="single-image-upload" v-if="designation == 'Customer Service' && status == 'completed'">
                                        <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front"></b-form-file>
                                        <span>
                                        <!-- <b-img v-if='form.profile_image' :src="form.profile_image.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;"></b-img>-->
                                        <b-img v-if='form.nric_front.image' :src="form.nric_front.image"></b-img>

                                    <!-- <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                                        <i v-else   class="fa fa-camera default_img"></i>
                                        </span>
                                    </div>
                                </b-form-group>
                             </div>

                              <div class="col-xl-4 mobile_max_width_and_flex max_width_and_flex_769 submit-field custom_width_nric">
                                    <h5 class="img_txt height_31_19 height_38_banner_heading">NRIC Back <span style="color:red !important">*</span></h5>
                                    <b-form-group id="link-group"  class="mobile_tab_image_change top_banner_jobs_full new_update_banner">
                                    <div class="single-image-upload">
                                        <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangeback" ref="image"  v-model="form.banner_image_back"></b-form-file>
                                        <span>
                                        <!-- <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;"></b-img>-->
                                        <b-img v-if='form.nric_back.image' :src="form.nric_back.image"></b-img>

                                    <!-- <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                                        <i v-else   class="fa fa-camera default_img"></i>
                                        </span>
                                    </div>
                                </b-form-group>
                             </div>

                             <div class="col-xl-3">
                                                <div class="submit-field">
                                                    <h5>Gender <span class="required_star">*</span></h5>
                                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select web_bottom_zero ">
                                                        <option value="" disabled>Select</option>
                                                        <option value="female">Female</option>
                                                        <option value="male">Male</option>
                                                    </b-form-select>
                                                </div>
                                                <div class="show_span">
                                                            <span class="error_x_white">Please select Gender </span>
                                                    </div>
                                            </div>
                                           <div class="col-xl-3">
                                        <div class="submit-field mobile_bottom_two_eight">
                                            <h5>Nationality <span class="required_star">*</span></h5>
                                            <b-form-select v-model="form.nationality" class="custom-select web_bottom_zero">
                                                <option value="" disabled>Select</option>
                                                <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.title}}</option>
                                            </b-form-select>
                                        </div>
                                            </div>
                                            <div class="col-xl-3">
                                                <div class="submit-field">
                                                    <h5>NRIC <span class="required_star">*</span></h5>
                                                    <input type="text" v-model="form.nric" class="web_bottom_zero with-border" id="nric">
                                                </div>
                                                <div class="show_span">
                                                        <span class="error_x_white">Please enter NRIC </span>
                                                    </div>
                                            </div>

                                            <div class="col-xl-3">
                                                <div class="submit-field">
                                                    <h5>Race <span class="required_star">*</span></h5>
                                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.raceid" class="web_bottom_zero custom-select" id="nation">
                                                        <option :value="null" disabled>Select</option>
                                                        <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>

                                                    </b-form-select>
                                                </div>

                                            </div>


                                               <div class="col-xl-3">
                                        <div class="submit-field  mobile_bottom_two_eight">
                                            <h5>Is Agent</h5>
                                            <b-form-select data-size="7" title="Select Agent Type"  v-model="form.agent" class="custom-select web_bottom_zero" id="gender">
                                                <option value="" disabled>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </b-form-select>
                                        </div>
                                         <!-- <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div> -->
                                    </div>
                                    <div class="col-xl-3">
                                        <div class="submit-field  mobile_bottom_two_eight">
                                            <h5>Is Fulltime</h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.full_time" class="custom-select web_bottom_zero" id="gender" @change="showRemark(form.full_time)">
                                                <option :value="null" disabled>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </b-form-select>
                                        </div>
                                         <!-- <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div> -->
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="submit-field remark_class">
                                            <h5>Fulltime Remarks</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.fulltime_remark" id="address">
                                        </div>
                                         <!-- <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div> -->
                                    </div>

                                            <div class="col-xl-3">
                                                <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                                    <h5>Date of Birth <span class="required_star">*</span></h5>
                                                    <datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterDob" :minimumView="'day'" :maximumView="'year'" id="fromyear" class="web_bottom_zero add_calender_icon new_add_calender_icon"></datepicker>

                                                </div>

                                            </div>


                                            <div class="col-xl-9">
                                            <div class="submit-field web_bottom_zero">
                                                <h5>Address <span class="required_star"></span></h5>
                                                <input type="text" v-model="form.address" class="with-border web_bottom_zero" id="address">
                                            </div>
                                             <div class="show_span">
                                                     <span class="error_x_white">Please enter Address </span>
                                                  </div>
                                            </div>


                                </div>
                            </li>

                        </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xl-12    ">
                    <div class="dashboard-box">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-account-circle"></i>Personal History</h3>
                        </div>

                        <div class="content">
                            <ul class="fields-ul">
                            <li class="common_padding_30">
                                <div class="row">

                             <div class="col-xl-5">
                                        <div class="submit-field  mobile_bottom_two_eight">
                                            <h5>Do you have any previous criminal record? <span class="required_star">*</span></h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.crime" class="custom-select web_bottom_zero" id="gender"  @change="showCrime(form.crime)">
                                                <option value="" disabled>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">please select Pay Out Option </span>
                                         </div>
                                    </div>


                                    <div class="col-xl-7">
                                        <div class="submit-field mobile_margin_bottom_28 big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight other_category_class" >
                                            <h5>Please specify</h5>
                                            <input type="text" class="with-border web_bottom_zero "  v-model="form.crime_reason">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Mobile </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-5">
                                        <div class="submit-field web_bottom_zero mobile_bottom_two_eight">
                                            <h5>Do you have any past/previous medical history? <span class="required_star">*</span></h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.medical" class="custom-select web_bottom_zero" id="gender" @change="showMedical(form.medical)">
                                                <option value="" disabled>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">please select Pay Out Option </span>
                                         </div>
                                    </div>



                                    <div class="col-xl-7">
                                        <div class="submit-field margin_top_28_769 mobile_top_margin_28 big_screen_top_28 web_bottom_zero other_medical_class">
                                            <h5>Please specify</h5>
                                            <input type="text" class="with-border web_bottom_zero " v-model="form.medical_reason">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Mobile </span>
                                                  </div>
                                    </div>

                                </div>
                            </li>

                        </ul>
                        </div>
                    </div>
                </div>


                <!-- Dashboard Box -->
                <div class="col-xl-12    ">
                    <div class="dashboard-box">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-account-circle"></i> Payout Details</h3>
                        </div>

                        <div class="content">
                            <ul class="fields-ul">
                            <li class="common_padding_30">
                                <div class="row">

                             <div class="col-xl-3">
                                        <div class="submit-field  mobile_bottom_two_eight">
                                            <h5>Preferred Payout Option</h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.payout" class="custom-select web_bottom_zero" id="gender" @change="payout()">
                                                <option value="" disabled>Select</option>
                                                <option value="paynow">Paynow</option>
                                                <option value="bank">Bank</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">please select Pay Out Option </span>
                                         </div>
                                    </div>

                                     <div class="col-xl-9">
                                     </div>

                                    <div class="col-xl-3">
                                        <div class="submit-field  mobile_bottom_two_eight" id="pay">
                                            <h5>Paynow Number</h5>
                                            <input type="text" id="mob" class="with-border " v-model="form.paynow">
                                        </div>
                                         <div class="show_span" id="paynow">
                                                     <span class="error_x_white">Please enter Mobile </span>
                                                  </div>
                                    </div>
                                     <div class="col-xl-9">
                                     </div>
                                    <div class="col-xl-4">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight web_bottom_zero big_screen_bottom_28"  id="b_name">
                                            <h5>Bank Name</h5>
                                            <b-form-select data-size="7" title="Select Bank"  v-model="form.bank" class="web_bottom_zero  custom-select" id="bankName">
                                                        <option value="" disabled>Select</option>
                                                        <option :value="bank.id" v-for="(bank,id) in banks" :key="id">{{bank.bank_name}}</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please select Bank </span>
                                          </div>
                                    </div>

                                     <div class="col-xl-4">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero"  id="b_number">
                                            <h5>Bank Account Number</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.acc_number" id="accNo">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Account Number </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-4">
                                       <div class="submit-field web_bottom_zero"  id="b_holder">
                                            <h5>Bank Account Holder Name</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.holder_name" id="accHold">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Account Holder name </span>
                                                  </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                        </div>
                    </div>
                </div>

               <div class="col-xl-12">
                    <div id="" class="dashboard-box">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="fas fa-tools"></i> Emergency Contact Details</h3>


                        </div>

                        <div class="content with-padding">
                            <div class="row">
                               <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>First Name</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_name" id="emer_name">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter last Name </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>Last Name</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_name_last" id="emer_name_last">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter last Name </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>Mobile Number</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_phone" id="emer_phone">
                                        </div>
                                          <div class="show_span">
                                                     <span class="error_x_white">Please enter mobile number </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field web_bottom_zero">
                                            <h5>Relationship</h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.emer_relation" class="custom-select web_bottom_zero" id="emer_relation">
                                                        <option value="">Select</option>
                                                        <option :value="relation.id" v-for="(relation,id) in relations" :key="id">{{relation.relation_name | capitalize}}</option>

                                                    </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Relationship </span>
                                                  </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-12">
                    <div  class="dashboard-box">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>DP Job Preferences</h3>

                        </div>

                        <div class="content with-padding  new_account_pdng">
                            <div class="row">
                                <div class="col-xl-12">
                                    <input type='checkbox' @click='dpcheckAll()' v-model='isCheckAll'> Check All <br/>

                                        <span v-for='cat in dp_category'>
                                        <input type='checkbox' v-bind:value='cat.id' v-model='dp_categories' @change='dpupdateCheckall()'> {{ cat.name }} <br/>
                                        </span>

                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-12">
                    <div  class="dashboard-box">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>FT Job Preferences</h3>

                        </div>

                        <div class="content with-padding  new_account_pdng">
                            <div class="row">
                                <div class="col-xl-12">
                                    <input type='checkbox' @click='checkAll()' v-model='isCheckAll'> Check All <br/>

                                        <span v-for='cat in category'>
                                        <input type='checkbox' v-bind:value='cat.id' v-model='categories' @change='updateCheckall()'> {{ cat.name }} <br/>
                                        </span>

                                    </div>
                            </div>
                        </div>
                    </div>
                </div>




                <!-- Dashboard Box -->

                <!-- Button -->
                <div class="col-xl-12    ">
                    <button @click="updatePersonalInfo()" type="button" class="button ripple-effect margin-top-30">Save Changes</button>
                </div>
            </b-form>
            <b-modal ref="crop-modal" title="Edit Passport/Selfie Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImg">
                        <template>
                              <div>
                                <div class="content" @change="setImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="1 / 1" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImage">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
            </b-modal>
            <b-modal ref="crop-modal-banner" title="Edit NRIC Front Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImgBanner">
                        <template>
                              <div>
                                <div class="content" @change="setImageBanner">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="3 / 2" :src="tempLogoBanner" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImageBanner">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
            </b-modal>

            <b-modal ref="crop-modal-food" title="Edit NRIC Back Image" hide-footer :no-close-on-backdrop="noClose">
                    <b-form @submit="onSubmitFoodCert">
                        <template>
                              <div>
                                <div class="content" @change="setImageBannerback">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="3 / 2" :src="tempLogoBanner" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropFoodCert">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
            </b-modal>

            <b-modal ref="crop-modal-seq" title="Edit Security Certificate" hide-footer :no-close-on-backdrop="noClose">
                    <b-form @submit="onSubmitSeqCert">
                        <template>
                              <div>
                                <div class="content" @change="setImageBannerback">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogoBanner" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropSeqCert">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
            </b-modal>

            <b-modal ref="crop-modal-banner-back" title="Edit Banner Image Back" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImgBannerback">
                        <template>
                              <div>
                                <div class="content" @change="setImageBannerback">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogoBanner" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImageBannerback">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
            </b-modal>
             <b-modal ref="sample-image-modal" no-close-on-backdrop hide-footer content-class="common_model_title mobile_verification_model" title="Sample Image" modal-class="background_custom_class">
            <b-form class="">
                <div class="edit-form-box web_top_zero">
                    <div class="profile-repeate form-box-field">
                        <!-- <div class="col-xl-12">
                        <h3><b>Sample Image</b></h3>
                        </div> -->
                        <b-row>
                             <b-col lg="12" md="12">
                                <img src="https://sgp1.digitaloceanspaces.com/elconnect/v2_images/sample_profile.png" alt="Italian Trulli" class="sample_image_single">
                            </b-col>
                        </b-row>

                    </div>
                </div>
            </b-form>
        </b-modal>
        </div>
        <!-- Row / End -->

    </div>

</template>

<script>
import { POST_API, GET_API } from '../../../store/actions.type'
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import CKEditor from 'ckeditor4-vue';

export default {

    data() {
        return {
            form:{
                first_name:'',
                last_name:'',
                nric :'',
                banner_image_back:'',
                banner_image_front:'',
                crime:'',
                crime_reason:'',
                food_type:'',
                medical:'',
                medical_reason:'',
                seq_cert_image:'',
                agent:'no',
                full_time:null,
                fulltime_remark:'',
                food_cert_image:'',
                profile_image:'',
                address:'',
                race:'',
                verify_otp_button: false,
                mobile_verified_check: false,
                sortdesc:'',
                skills:[],
                dob:'',
                obtainment_date:'',

                payout:'paynow',
                paynow:'',
                nric_front:{
                    name:'',
                    image:''
                },
                selectedFoodCert:{
                    name:'',
                    image:''
                },

                 selectedseqCert:{
                    name:'',
                    image:''
                },

                nric_back:{
                    name:'',
                    image:''
                },
                selectedImage:{
                    name:'',
                    image:''
                },
                selectedResume:{
                    name:'',
                    image:''
                },
                user_id: atob(this.$route.params.id),
                gender:'',
                nationality:'5',
                bank :'',
                acc_number :'',
                holder_name:'',
                emer_name:'',
                emer_name_last:'',
                emer_phone:'',
                emer_relation:'',
                raceid:null,
                mobile_number:'',
                email:''
            },
            images:[],
            type:[],
            seqcerificateType:[],
            foodcerificateType:[],

            link:{
                website: '',
                fb: '',
                google: '',
                twitter: '',
                linkedin: '',
            },
            email: '',
            password: '',
            user_type: '5',

            status: '',
            account_verification_status:'',
            showDismissibleAlert:false,
            error_message:'',
            success_message:'',
            isActive: 1,
            appendQa:[],
            workExperienceData:[],
            qualificationData:[],
            socialData:[],
            blockRemovalQualification:true,
            appendExp:[],
            blockRemovalExperience:true,
            skillsArr: [],
            countries:[],
            race:[],
            designation:'',
            banks:[],
            relations:[],
            qualificationArr:[],
            fieldOfStudyArr:[],
            universityArr:[],

            disabledFromDate: {
                from: new Date(Date.now() - 8640000),
                to: new Date(moment().subtract(100, 'years'))
            },
            disabledFromDateDob: {
                from: new Date(moment( moment().subtract(15, 'years') ).format("DD-MM-YYYY"))
            },
            personal:true,
            links:false,
            disabled:true,
            img: '',
            showCV:'',
            cvUrl:'',
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            cropImgBanner: '',
            cropImgBannerback: '',


            tempLogoBanner:'',
            noClose:true,
            duration_yrs: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
            duration_mn: [0,1,2,3,4,5,6,7,8,9,10,11],
            webUrl: process.env.VUE_APP_URL,
            editorConfig: {
            removeButtons:'Maximize',
            extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
            },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
            // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            },
            status:'',
            isCheckAll: false,
            category : [],
            categories:[],
            isCheckAllDP: false,
            dp_category : [],
            dp_categories:[],
            is_production_val: 'yes'
        }
    },
    components: {
        Multiselect,
        Datepicker,
        VueCropper,
        ckeditor: CKEditor.component,
    },
    filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
        },
    methods:{
        showCrime(id){
            if(id == "yes"){
                $('.other_category_class').css('display', 'block');
            }else{
                $('.other_category_class').css('display', 'none');
            }
        },

        showMedical(id){
                if(id == "yes"){
                    $('.other_medical_class').css('display', 'block');
                }else{
                    $('.other_medical_class').css('display', 'none');
                }
            },
            showRemark(id){
            if(id == "yes"){
                $('.remark_class').css('display', 'block');
            }else{
                $('.remark_class').css('display', 'none');
            }
        },
        checkAll: function(){

            this.isCheckAll = !this.isCheckAll;
            this.categories = [];
            if(this.isCheckAll){ // Check all
            for (var key in this.category) {
                this.categories.push(this.category[key].id);
            }
            }
            },
        updateCheckall: function(){
            if(this.categories.length == this.category.length){
            this.isCheckAll = true;
            }else{
            this.isCheckAll = false;
            }
        },

        dpcheckAll: function(){

            this.isCheckAllDP = !this.isCheckAllDP;
            this.dp_categories = [];
            if(this.isCheckAllDP){ // Check all
            for (var key in this.dp_category) {
                this.dp_categories.push(this.dp_category[key].id);
            }
            }
            },
        dpupdateCheckall: function(){
            if(this.dp_categories.length == this.dp_category.length){
            this.isCheckAllDP = true;
            }else{
            this.isCheckAllDP = false;
            }
        },
        showUniversity(id, index){
            if(id == this.other_category_id_university){
                $('#other_university_class'+index).css('display', 'block');
            }else{
                $('#other_university_class'+index).css('display', 'none');
            }
        },
        showsampleimageModel()
        {
          this.$refs['sample-image-modal'].show();
        },
        cropImage() {
          this.$refs['crop-modal'].hide();
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImg = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();
            this.$refs['crop-modal'].hide();
            this.$refs['crop-modal'].refresh();
        },

        cropImageBanner() {
          this.$refs['crop-modal-banner'].hide();
          this.form.nric_front.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },

        showfoodCert() {
            this.$refs['crop-modal-food'].show();
        },
         showseqCert() {
            this.$refs['crop-modal-seq'].show();
        },

        onSubmitFoodCert(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-food'].hide();
            this.$refs['crop-modal-food'].refresh();
        },

        onSubmitSeqCert(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-seq'].hide();
            this.$refs['crop-modal-seq'].refresh();
        },

        // getType() {
        //     return this.$store.dispatch(POST_API, {
        //             api: '/api/food-type-list-api'
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 this.showAlert();
        //             } else {
        //                 this.types = this.$store.getters.getResults.data;

        //                 // this.showSuccessPopup();
        //             }
        //         });
        // },


        onFoodCertChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.food_cert_image = input.files[0];
                if (this.form.food_cert_image.type != 'image/png' && this.form.food_cert_image.type != 'image/jpeg' && this.form.food_cert_image.type != 'image/jpg') {
                    this.form.selectedFoodCert.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.selectedFoodCert.image='';
                    this.showAlert();
                } else if (this.form.food_cert_image.size > 5242880) {
                    this.form.selectedFoodCert.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Banner image should be less than 5 MB.';
                    this.form.selectedFoodCert.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.selectedFoodCert.name = this.form.food_cert_image.name;
                        this.showfoodCert();
                        event.target.value = '';

                    }
                }
            }
        },

        onSeqCertChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.seq_cert_image = input.files[0];
                if (this.form.seq_cert_image.type != 'image/png' && this.form.seq_cert_image.type != 'image/jpeg' && this.form.seq_cert_image.type != 'image/jpg') {
                    this.form.selectedseqCert.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.selectedseqCert.image='';
                    this.showAlert();
                } else if (this.form.seq_cert_image.size > 5242880) {
                    this.form.selectedseqCert.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Banner image should be less than 5 MB.';
                    this.form.selectedseqCert.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.selectedseqCert.name = this.form.seq_cert_image.name;
                        this.showseqCert();
                        event.target.value = '';

                    }
                }
            }
        },


        cropFoodCert() {
          this.$refs['crop-modal-food'].hide();
          this.form.selectedFoodCert.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },

        cropSeqCert() {
          this.$refs['crop-modal-seq'].hide();
          this.form.selectedseqCert.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        showImgBanner() {
            this.$refs['crop-modal-banner'].show();
        },
        setImageBanner(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBanner = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        cropImageBannerback() {
          this.$refs['crop-modal-banner-back'].hide();
          this.form.nric_back.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImageBannerback(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBannerback = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },



        showImgBannerback() {
            this.$refs['crop-modal-banner-back'].show();
        },
        onSubmitImgBanner(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-banner'].hide();
            this.$refs['crop-modal-banner'].refresh();
        },

        onSubmitImgBannerback(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-banner-back'].hide();
            this.$refs['crop-modal-banner-back'].refresh();
        },

        onBannerChangeback(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.banner_image_back = input.files[0];
                if (this.form.banner_image_back.type != 'image/png' && this.form.banner_image_back.type != 'image/jpeg' && this.form.banner_image_back.type != 'image/jpg') {
                    this.form.nric_back.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.nric_back.image='';
                    this.showAlert();
                } else if (this.form.banner_image_back.size > 5242880) {
                    this.form.nric_back.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'NRIC back image should be less than 5 MB.';
                    this.form.nric_back.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.nric_back.name = this.form.banner_image_back.name;
                        this.showImgBannerback();
                        event.target.value = '';

                    }
                }
            }
        },

        onBannerChangefront(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.banner_image_front = input.files[0];
                if (this.form.banner_image_front.type != 'image/png' && this.form.banner_image_front.type != 'image/jpeg' && this.form.banner_image_front.type != 'image/jpg') {
                    this.form.nric_front.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.nric_front.image='';
                    this.showAlert();
                } else if (this.form.banner_image_front.size > 5242880) {
                    this.form.nric_front.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'NRIC fron image should be less than 5 MB.';
                    this.form.nric_front.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.nric_front.name = this.form.banner_image_front.name;
                        this.showImgBanner();
                        event.target.value = '';

                    }
                }
            }
        },
        onprofileChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.profile_image = input.files[0];
                if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.profile_image.size > 1048576) {
                    this.form.selectedImage.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Profile image should be less than 1 MB.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.profile_image.name;
                        this.showImg();
                        event.target.value = '';

                    }
                }
            }
        },

        payout(){
            if(this.form.payout == 'bank'){

                $("#mob").removeClass('error');
                $('#paynow').hide();
                $('#mob').parent().parent().find('.error_x_white').removeClass('show');

                $('#accNo').addClass('required-field');
                $('#accHold').addClass('required-field');
                // $('#pay').css('display','none');

                $('#b_name').css('display','block');
                $('#b_number').css('display','block');
                $('#b_holder').css('display','block');
            }else{
                $('#mob').addClass('required-field');

                $("#accNo").removeClass('error');
                $('#accNo').parent().parent().find('.error_x_white').removeClass('show');

                $("#accHold").removeClass('error');
                $('#accHold').parent().parent().find('.error_x_white').removeClass('show');

                $('#pay').css('display','block');
                // $('#b_name').css('display','none');
                // $('#b_number').css('display','none');
                // $('#b_holder').css('display','none');
            }

        },
        activeTab(){
            this.personal = true;
            this.links = false;

        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        customFormatterDob(date) {
            return moment(date).format('DD MMM YYYY');
        },

        maxdate() {
            return moment().subtract(16, 'years');;
        },

        customFormattermonth(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        getCountryCode() {
            return this.$store.dispatch(POST_API, {
                    api: '/api/get-nationality-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.countries = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },

        getRace() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/race'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.race = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },

        getbanks() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/banks'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.banks = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },

        getrelation() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/relations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.relations = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },



        // getDropdownParamsData(type,field,id,row)
        // {

        //     return this.$store.dispatch(POST_API, {
        //             data:{
        //                 field:field,
        //                 id:id,
        //                 table:type

        //             },
        //             api:'/api/get-params-dropdown-data',
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 this.showAlert();
        //             } else {
        //                 $('.fos-'+row).val();

        //                 this.fieldOfStudyArr[row] = '';
        //                 this.fieldOfStudyArr[row] = this.$store.getters.getResults.data;
        //                 return this.fieldOfStudyArr[row];
        //             }
        //         });
        // },
        onInput(value) {
            this.inputArr = value.map(a => a.id);
        },

        retriveData(){
                this.callAuthoriseApi();

        },



         callAuthoriseApi() {

            var authApiUrl = 'https://sandbox.api.myinfo.gov.sg/com/v3/authorise'; // URL for authorise API
            var clientId = 'STG-202006353G-ELCONNECT-JOBSEEKERREG'; // your app_id/client_id provided to you during onboarding
            var redirectUrl ='http://localhost:3001/callback'; //callback url for your application

            var attributes = 'uinfin,name,sex,race,dob,nationality,mobileno,email,regadd'; // the attributes you are retrieving for your application to fill the form
            var authLevel = 'L0'; // the auth level, determines the flow
            // the purpose of your data retrieval
            var purpose = "demonstrating MyInfo APIs";

            // randomly generated state
            var state = "123";
			var authoriseUrl = authApiUrl + "?client_id=" + clientId +
				"&attributes=" + attributes +
				"&purpose=" + purpose +
				"&state=" + encodeURIComponent(state)  +
				"&redirect_uri=" + redirectUrl;

			window.location = authoriseUrl;
		},
        updatePersonalInfo() {
            var cur = moment().diff(this.form.dob, 'years');
            var err = false;
            var errnric = false;
            var errnric_front = false;
            var errmedical = false;
            var errfull_time = false;
            var errcrime = false;
            var errage = false;

            //  $('.required-field').each(function () {

            //             if ($(this).val() == '' || $(this).val().trim().length === 0) {
            //                 err = true;
            //                 $(this).addClass('error');
            //                 $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
            //                 $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
            //             } else {
            //                 $(this).removeClass('error');
            //                 $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
            //                 $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
            //             }

            //         });

            //     setTimeout(function ()
            //  {
            //         $('.second_indiv_step_wrap .error').first().focus();

            //         $('.error').unbind('keypress');
            //         $('.error').bind('keypress', function(){
            //             $(this).removeClass('error');
            //             $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
            //         });
            // }, 100);

                        if (this.form.mobile_number == '' || this.form.mobile_number.trim().length === 0 || this.form.mobile_number == null) {
                            err = true;
                            $('#number').addClass('error');
                            $('#number').parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                            $('#number').parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                        } else {
                            $('#number').removeClass('error');
                            $('#number').parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                            $('#number').parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                        }

         setTimeout(function ()
             {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    });
            }, 100);


            if(this.form.mobile_number == ''){
                                $('#number').addClass('required-field');
                                    err = true;


                            }

                            if(this.form.email == ''){
                                $('#email').addClass('required-field');
                                    err = true;


                            }

                            if(!this.form.mobile_number.match('^[0-9]*$')){

                                err = true;

                                this.$swal({
                                    position: 'center',
                                    icon: 'error',
                                    title: 'Only number allow for mobile number',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }



            if(this.form.emer_name != '' || this.form.emer_name_last != '' || this.form.emer_phone != '' || this.form.emer_relation != ''){
                if(this.form.emer_name == '' || this.form.emer_name_last == '' || this.form.emer_phone == '' || this.form.emer_relation == ''){

                    if(this.form.emer_name == ''){
                            $('#emer_name').addClass('required-field');

                    }
                    if(this.form.emer_name_last == ''){
                            $('#emer_name_last').addClass('required-field');


                    }
                    if(this.form.emer_phone == ''){
                            $('#emer_phone').addClass('required-field');


                    }
                    if(this.form.emer_relation == ''){

                            $('#emer_relation').addClass('required-field');

                    }

                    err = true;


                }

            }

            if(err == false && ((this.form.nric_front.image != '' && this.form.nric_back.image == '') || this.form.nric_back.image != '' && this.form.nric_front.image == '')){
                    errnric_front = true;
                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Please upload nric image.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }


             if(err == false && (!this.form.medical || !this.form.crime)){
                    errmedical1 = true;
                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Please fill personal history.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            if(err == false && (this.form.nric) && this.is_production_val == 'yes'){
                this.form.nric = this.form.nric.toUpperCase();
                var icArray = new Array(9);
                for (var i = 0; i < 9; i++) {
                    icArray[i] = this.form.nric.charAt(i);
                }
                icArray[1] *= 2;
                icArray[2] *= 7;
                icArray[3] *= 6;
                icArray[4] *= 5;
                icArray[5] *= 4;
                icArray[6] *= 3;
                icArray[7] *= 2;
                var weight = 0;
                for (var i = 1; i < 8; i++) {
                    weight += parseInt(icArray[i]);
                }
                var offset = (icArray[0] == "T" || icArray[0] == "G") ? 4 : 0;
                var temp = (offset + weight) % 11;
                var st = Array("J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A");
                var fg = Array("X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K");
                var theAlpha;
                if (icArray[0] == "S" || icArray[0] == "T") {
                    theAlpha = st[temp];
                } else if (icArray[0] == "F" || icArray[0] == "G") {
                    theAlpha = fg[temp];
                }
                var nricCheck = icArray[8] == theAlpha;
                if(!nricCheck || this.form.nric.length > 9){
                    err = true;

                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Invalid NRIC.',
                    showConfirmButton: false,
                    timer: 1500
                })
                }
            }
            if(err == false && (this.form.nric) && this.is_production_val == 'no'){
                if(!this.form.nric.match(/^[S-Ts-t]+\d{7}[A-Za-z]+$/)){
                    err = true;
                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Invalid NRIC.',
                    showConfirmButton: false,
                    timer: 1500
                    })
                }
            }
            if(err == false && (this.form.full_time == 'yes')){
                if(this.form.fulltime_remark == ''){

                    errfull_time = true;

                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Please enter Full time remark.',
                    showConfirmButton: false,
                    timer: 1500
                })
                }

            }

             if(err == false && (this.form.medical == 'yes')){
                if(this.form.medical_reason == '' || this.form.medical_reason == null){

                    errmedical = true;

                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Please enter medical reason.',
                    showConfirmButton: false,
                    timer: 1500
                })
                }


            }

            if(err == false && (this.form.crime == 'yes')){
                if(this.form.crime_reason == '' || this.form.crime_reason == null){

                    errcrime = true;

                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Please enter criminal reason.',
                    showConfirmButton: false,
                    timer: 1500
                })
                }

            }
            // if(this.form.emer_name != '' || this.form.emer_name_last != '' || this.form.emer_phone != '' || this.form.emer_relation != ''){
            //     if(this.form.emer_name == '' || this.form.emer_name_last == '' || this.form.emer_phone == '' || this.form.emer_relation == ''){

            //         err = true;

            //         this.$swal({
            //         position: 'center',
            //         icon: 'error',
            //         title: 'Please fill all Emergency Contact Detail.',
            //         showConfirmButton: false,
            //         timer: 1500
            //     })
            //     }

            // }



            if(this.form.payout == 'bank'){
                            $("#mob").removeClass('error');
                             $('#mob').removeClass('required-field');

                            $('#paynow').hide();
                            $('#mob').parent().parent().find('.error_x_white').removeClass('show');

                            $('#accNo').addClass('required-field');
                            $('#accHold').addClass('required-field');
                            // $('#pay').css('display','none');

                            $('#b_name').css('display','block');
                            $('#b_number').css('display','block');
                            $('#b_holder').css('display','block');
            }else{
                $('#mob').addClass('required-field');

                $('#accNo').removeClass('required-field');
                $('#accHold').removeClass('required-field');

                $("#accNo").removeClass('error');
                $('#accNo').parent().parent().find('.error_x_white').removeClass('show');
                $("#accHold").removeClass('error');
                $('#accHold').parent().parent().find('.error_x_white').removeClass('show');
                $('#pay').css('display','block');
                // $('#b_name').css('display','none');
                // $('#b_number').css('display','none');
                // $('#b_holder').css('display','none');
            }

        //     if(!this.form.nric_front.image){

        //     err = true;
        //      this.$swal({
        //         position: 'center',
        //         icon: 'error',
        //         title: 'NRIC front image is required.',
        //         showConfirmButton: false,
        //         timer: 1500
        //     })
        // }
        // else if(!this.form.nric_back.image){

        //     err = true;
        //      this.$swal({
        //         position: 'center',
        //         icon: 'error',
        //         title: 'NRIC back image is required.',
        //         showConfirmButton: false,
        //         timer: 1500
        //     })
        // }


        if(err == false && (cur < 16)){



            errage = true;

             this.$swal({
                position: 'center',
                icon: 'error',
                title: 'The age should be minimum of 16 year.',
                showConfirmButton: false,
                timer: 1500
            })
        }


        if(err == false && errmedical == false && errnric_front == false && errage == false && errcrime == false && errfull_time == false && errnric == false && errmedical1 == false ){
            this.form.skills = this.form.skills != [] ? this.form.skills : null;
            this.form.dob = this.form.dob == "Invalid date" ? null : this.form.dob;
            this.$store.dispatch(POST_API, {
                data:{
                    items:this.form,
                    categories:this.categories,
                    dp_categories:this.dp_categories,

                    image:this.form.selectedImage.image,
                },
                api:'/api/update-dp-profile-jobseeker-admin'
            })
            .then(() => {
                var yearERR = '';
                var monthERR = ''; // see the change here
                 // see the change here


                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                }
                     else {
                    this.success_message = this.$store.getters.getResults.message;

                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        iconColor:'#4c8bf5',
                        title: this.success_message,
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                        // if(this.$store.getters.currentUser.profile_image != this.form.profile_image){
                            // this.$store.getters.currentUser.profile_image = this.form.selectedImage;
                            window.location.reload();

                        // }

                        window.scrollTo(0,0);
                    });
                }
            });
        }
        },


        getUserData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.form.user_id,
                },
                api: '/api/user-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){

                        this.form.selectedImage.image = this.$store.getters.getResults.data.profile_image != null ? this.$store.getters.getResults.data.profile_image : this.webUrl+'assets/images/user-avatar-small-01.png';
                        // this.form.selectedBannerImage.image = this.$store.getters.getResults.data.banner_image;
                        this.form.first_name = this.$store.getters.getResults.data.first_name;
                        this.form.mobile_number = this.$store.getters.getResults.data.mobile_number;
                        this.form.country_code = this.$store.getters.getResults.data.country_code;

                        this.form.verify_otp_button = this.$store.getters.getResults.data.mobile_number ? this.form.verify_otp_button = true : this.form.verify_otp_button = false;
                        this.user_type = this.$store.getters.getResults.data.user_type_id;
                        this.form.expected_salary = this.$store.getters.getResults.data.expected_salary;
                        this.form.about = this.$store.getters.getResults.data.about;
                        this.form.nric = this.$store.getters.getResults.data.nric;
                        this.form.raceid = this.$store.getters.getResults.data.race_id;
                        this.form.dob = this.$store.getters.getResults.data.dob ? this.$store.getters.getResults.data.dob:'';
                        this.form.gender = this.$store.getters.getResults.data.gender ? this.$store.getters.getResults.data.gender : '';
                        this.form.nationality = this.$store.getters.getResults.data.nationality ? this.$store.getters.getResults.data.nationality : '5';
                        this.form.email = this.$store.getters.getResults.data.email;
                        this.form.last_name = this.$store.getters.getResults.data.last_name;
                        this.form.address = this.$store.getters.getResults.data.address;
                        this.form.agent = this.$store.getters.getResults.data.is_agent;
                        this.form.full_time = this.$store.getters.getResults.data.is_fulltime;
                        this.form.fulltime_remark = this.$store.getters.getResults.data.fulltime_remarks;
                        this.form.skills = this.$store.getters.getResults.data.skills != null ? (this.$store.getters.getResults.data.skills).split(',') : null;
                        // this.form.skills = ["Vue", "Angular", "React"];
                        this.form.email_verify = this.$store.getters.getResults.data.email_verified;
                        this.showCV = (this.$store.getters.getResults.data.cv != '' && this.$store.getters.getResults.data.cv != null) ? true : false;
                        this.cvUrl = (this.$store.getters.getResults.data.cv != '' && this.$store.getters.getResults.data.cv != null) ? this.$store.getters.getResults.data.cv : '';
                        this.form.resume = this.cvUrl;
                        this.form.profile_image = this.$store.getters.getResults.data.profile_image != null ? this.$store.getters.getResults.data.profile_image : '';
                        // this.form.banner_image_back = this.$store.getters.getResults.data.banner_image_back != null ? this.$store.getters.getResults.data.banner_image_back : '';
                        // this.form.banner_image_front = this.$store.getters.getResults.data.banner_image_front != null ? this.$store.getters.getResults.data.banner_image_front : '';
                        this.form.mobile_verify = this.$store.getters.getResults.data.mobile_verified;
                        this.workExperienceData = this.$store.getters.getResults.experience;
                        this.qualificationData = this.$store.getters.getResults.qualification;
                        this.socialData = this.$store.getters.getResults.social;
                        this.form.mobile_verified_check = this.$store.getters.getResults.data.mobile_verified == 'yes' ? this.form.mobile_verified_check = true : this.form.mobile_verified_check = false;
                        this.status = this.$store.getters.getResults.data.account_verification_status;
                        this.account_verification_status = this.$store.getters.getResults.data.account_verification_status.charAt(0).toUpperCase() + this.$store.getters.getResults.data.account_verification_status.slice(1);
                        this.form.paynow = this.$store.getters.getResults.payout[0]  ? this.$store.getters.getResults.payout[0].mobile_number: '';
                        this.form.medical = this.$store.getters.getResults.data.medical_condition != "not_answered" ? this.$store.getters.getResults.data.medical_condition : 'no';
                        this.form.medical_reason = this.$store.getters.getResults.data.medical_condition_desc;
                        this.form.crime = this.$store.getters.getResults.data.criminal_record != "not_answered" ? this.$store.getters.getResults.data.criminal_record : 'no';
                        this.form.crime_reason = this.$store.getters.getResults.data.criminal_record_desc;
                        this.form.payout = this.$store.getters.getResults.data.payout_type;

                        var j = 0;
                        var k = 0;
                         for (var i =0; i < this.$store.getters.getResults.certificate.length; i++) {
                                this.images[j] = this.$store.getters.getResults.certificate[i].url;
                                this.type[j] = this.$store.getters.getResults.certificate[i].title;
                                j++;

                        }

                        this.form.cerificate = this.$store.getters.getResults.certificate;


                        if(this.form.paynow == ''){

                            this.form.paynow = this.$store.getters.getResults.data.mobile_number;

                        }
                        if(this.$store.getters.getResults.bank[0]){
                            this.form.bank = this.$store.getters.getResults.bank[0].bank_id;
                            this.form.acc_number = this.$store.getters.getResults.bank[0].account_number;
                            this.form.holder_name = this.$store.getters.getResults.bank[0].account_holder_name;
                        }
                        if(this.$store.getters.getResults.emer[0]){
                            this.form.emer_name = this.$store.getters.getResults.emer[0].first_name;
                            this.form.emer_name_last = this.$store.getters.getResults.emer[0].last_name == null ? '' : this.$store.getters.getResults.emer[0].last_name;

                            this.form.emer_phone = this.$store.getters.getResults.emer[0].mobile_number;
                            this.form.emer_relation = this.$store.getters.getResults.emer[0].relation_id ? this.$store.getters.getResults.emer[0].relation_id : '';
                        }

                        if(this.$store.getters.getResults.document[0]){
                            this.form.banner_image_front = this.$store.getters.getResults.document[0].document;
                            this.form.nric_front.image = this.$store.getters.getResults.document[0].document;
                        }
                        if(this.$store.getters.getResults.document[1]){
                            this.form.banner_image_back = this.$store.getters.getResults.document[1].document;
                            this.form.nric_back.image = this.$store.getters.getResults.document[1].document;
                        }
                         if(this.$store.getters.getResults.food_cert_data[0]){
                            this.form.food_cert_image = this.$store.getters.getResults.food_cert_data[0].url;
                            this.form.selectedFoodCert.image = this.$store.getters.getResults.food_cert_data[0].url;

                            this.form.food_type = this.$store.getters.getResults.food_cert_data[0].cert_type;

                            this.form.food_obtainment_date = this.$store.getters.getResults.food_cert_data[0].obtainment_date;

                        }

                        if(this.$store.getters.getResults.seq_cert_data[0]){
                            this.form.seq_cert_image = this.$store.getters.getResults.seq_cert_data[0].url;
                            this.form.selectedseqCert.image = this.$store.getters.getResults.seq_cert_data[0].url;

                            this.form.seq_type = this.$store.getters.getResults.seq_cert_data[0].cert_type;
                            this.form.seq_obtainment_date = this.$store.getters.getResults.seq_cert_data[0].obtainment_date;

                        }

                         if(this.form.full_time == "yes"){
                            $('.remark_class').css('display', 'block');
                        }else{
                            $('.remark_class').css('display', 'none');
                        }
                        // if(this.form.email_verify == 'yes'){

                        //     $('#email').attr('disabled', 'disabled');

                        // }else{

                        //     $('#email').removeAttr('disabled');

                        // }



                            // if(this.form.mobile_verify == 'yes'){

                            //     $('#number').attr('disabled', 'disabled');

                            // }else{

                            //     $('#number').removeAttr('disabled');

                            // }

                             if(this.form.crime == "yes"){
                            $('.other_category_class').css('display', 'block');
                        }else{
                            $('.other_category_class').css('display', 'none');
                        }

                         if(this.form.medical == "yes"){
                            $('.other_medical_class').css('display', 'block');
                        }else{
                            $('.other_medical_class').css('display', 'none');
                        }

                        if(!this.form.paynow){

                            this.form.payout = 'paynow'
                        }
                        this.payout();

                        if(this.form.payout == 'paynow'){
                            $('#mob').addClass('required-field');
                        }else{

                            $('#accNo').addClass('required-field');
                            $('#accHold').addClass('required-field');
                        }
                    }else{

                    }
                }
            });
        },

        getFoodType() {
            return this.$store.dispatch(POST_API, {
                    data: {
                            id: this.cerificate[0].id,
                    },
                    api: '/api/certificatetype'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.foodcerificateType = this.$store.getters.getResults.data;


                        // this.showSuccessPopup();
                    }
                });
        },

        getSecurityType() {
            return this.$store.dispatch(POST_API, {
                    data: {
                            id: this.cerificate[1].id,
                    },
                    api: '/api/certificatetype'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.seqcerificateType = this.$store.getters.getResults.data;


                        // this.showSuccessPopup();
                    }
                });
        },
        getIsProductionSetting() {
            return this.$store.dispatch(GET_API, {
                api: '/api/get-is-production-nric'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.is_production_val = this.$store.getters.getResults.data;
                }
            });
        },
        getCertificate() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/certificate'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.cerificate = this.$store.getters.getResults.data;

                        if(this.cerificate[1]){

                            this.getSecurityType();
                        }

                         if(this.cerificate[0]){

                            this.getFoodType();
                        }

                        // this.showSuccessPopup();
                    }
                });
        },


    },
    mounted(){

        let params = new URLSearchParams(location.search);
        var data = params.get('data')

        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");

        this.form.admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.designation = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : '');

        this.getUserData();
        this.getRace();
        this.getbanks();
        this.getrelation();
        this.getCountryCode();
        // this.getType();
        this.getCertificate();
        this.getIsProductionSetting();
s
    }
}
</script>
<style>
    .single-image-upload .form-control-file, .single-image-upload .form-control-range {
        position: absolute;
        height: 100%;
        opacity: 0;
    }
    .single-image-upload .form-control-file, .custom-file-input {
        height: 200px;
    }
</style>
