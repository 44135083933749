<template>
  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Credit Note</h1>
        </div>
        <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
        </div> -->
      </div>
    </div>
    <b-row>
        <b-col lg="12">
          <div class="border-radius-4 white-bg p-lg-4 p-3" v-if="show('add')">
              <b-form @submit="formSubmit">
                  <div class="edit-form-box form-box-field">
                      <!-- <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                          <span>{{ error_message }}</span>
                      </b-alert> -->
                      <div  class="profile-repeate form-box-field mobile_max_width_unset">
                          <b-row>
                              <b-col lg="6" md="6" sm="12">
                                <b-form-group id="input-group-8" label="Invoice No." class="required">
                                  <multiselect v-model="form.invoice_id" id="ajax" label="invoice_number" track-by="id" placeholder="Type to search invoice no." open-direction="bottom" :options="invoices_list"  :searchable="true" :clear-on-select="true" :close-on-select="true"
                                    :allow-empty="true" @input="invoice(form.invoice_id)">
                                  <!--  <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.img" alt="No Man’s Sky">
                                    <div class="option__desc"><span class="option__title">{{ props.option.title }}</span><span class="option__small">{{ props.option.desc }}</span></div>
                                  </template> -->
                                      <span slot="noResult">No Invoice No. Found.</span>
                                  </multiselect>
                              </b-form-group>
                                  <!--  <b-form-group id="input-group-9" label="Invoice No." class="required">
                                      <b-form-input id="input-9" v-model="form.invoice" type="text" placeholder="" :disabled="true"></b-form-input>
                                  </b-form-group> -->
                                  <!--  <b-form-group id="input-group-8" label="Invoice No." class="required">
                                    <div class="custom-dropdown">
                                        <b-form-select v-model="form.invoice_id">
                                            <option value="">Select Invoice No.</option>
                                            <option :value="invoices.id" v-for="invoices in invoices_list">{{invoices.invoice_number}}</option>
                                        </b-form-select>
                                    </div>
                                </b-form-group> -->
                              </b-col>
                              <b-col lg="6" md="6" sm="12">
                                  <b-form-group id="input-group-10" label="Rebates / Promotion" class="required">
                                      <b-form-input id="input-10" v-model="form.rebate" type="text" placeholder=""></b-form-input>
                                  </b-form-group>
                              </b-col>
                              <b-col lg="6" md="6" sm="12">
                                  <b-form-group id="input-group-10" label="Comments" class="required">
                                      <b-form-input id="input-10" v-model="form.comments" type="text" placeholder=""></b-form-input>
                                  </b-form-group>
                              </b-col>
                          </b-row>
                      </div>                    
                      <div slot="footer" class="form-btn mt-3">
                          <b-button type="submit" variant="success" class="mr-0 desktop_margin_left_zero">Submit</b-button>
                          <b-button :to="{name:'CreditNote'}" variant="light">Cancel</b-button>
                      </div>
                  </div>
              </b-form>
          </div>
        </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col lg="6" md="6" sm="12">
          <!--  <b-form-group id="input-group-9" label="Invoice No." class="required">
              <b-form-input id="input-9" v-model="form.invoice" type="text" placeholder="" :disabled="true"></b-form-input>
          </b-form-group> -->
          <!-- <b-form-group id="input-group-8" label="Invoice No." class="required">
            <div class="custom-dropdown">
                <b-form-select v-model="inv_id" @change='changeInv()'>
                    <option value="">Select Invoice No.</option>
                    <option :value="invoices.id" v-for="invoices in invoices_list">{{invoices.invoice_number}}</option>
                </b-form-select>
            </div>
        </b-form-group> -->
        <b-form-group id="input-group-8" label="Invoice No." class="required">
              <multiselect v-model="inv_id" id="ajax" label="invoice_number" track-by="id" placeholder="Type to search invoice no." open-direction="bottom" :options="invoices_list"  :searchable="true" :clear-on-select="true" :close-on-select="true"
                :allow-empty="true" @input="changeInv()">
                  <span slot="noResult">No Invoice No. Found.</span>
              </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <div class="my_new_container show_filter_arrow">
          <b-table
            ref="datatable"
            show-empty
            striped
            hover
            responsive
            :items="getCreditNoteList"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="new_layout_table_like_v1"
          >
              <template #cell(invoice_number)="items">
                {{items.item.invoice_number}}
              </template>
            <template #cell(invoice_date)="items">
                {{ items.item.invoice_date | moment}}
            </template>
            <template #cell(rebate)="items">
                {{ items.item.rebate}}
            </template>
            <template #cell(gst_amount)="items">
                {{items.item.gst_amount | roundNo}}
            </template>
            <template #cell(name)="items">
                {{items.item.users.last_name | capitalize}} {{items.item.users.first_name | capitalize}}
            </template>
            <template #cell(comment)="items">
                {{items.item.comments | capitalize}}
            </template>
            <template #cell(toggleAction)="items">
                  <div class="toggle-action">
                    <b-dropdown class="mx-1" right text="Actions" boundary="window">
                        <b-dropdown-item v-on:click="deleteNote(items.item.id)" v-if="show('delete')">Delete
                        </b-dropdown-item>
                        <!-- <b-dropdown-item :to="{name: 'CreditNoteUpdate', params: {id: items.item.id }}" v-if="show('edit')">Edit</b-dropdown-item> -->

                        <!-- <b-dropdown-item v-on:click="changeBannerStatus(items.item.id, items.item.status)" v-if="items.item.status == 'active' && show('activate/inactivate')">Inactivate</b-dropdown-item>

                        <b-dropdown-item v-else-if="items.item.status == 'inactive' && show('activate/inactivate')" v-on:click="changeBannerStatus(items.item.id, items.item.status)">Activate</b-dropdown-item>

                        <b-dropdown-item v-on:click="deleteBanner(items.item.id)" v-if="show('delete banner')">Delete
                        </b-dropdown-item> -->

                    </b-dropdown>
                  </div>
              </template>
          </b-table>
        </div>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @change="getCreditNoteList">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { GET_API, POST_API } from "../../../store/actions.type";
import moment from "moment";
import permission from "../../../../../server/permission.js";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      fields: [
        { key: 'user_id', label: 'Customer Id', sortable: true, thStyle: { width: "10%" } },
        { key: 'name', label: 'Employer / Individual Name', sortable: false, thStyle: { width: "20%" } },
        { key: 'invoice_number', label: 'Invoice No.', sortable: true, thStyle: { width: "20%" } },
        { key: 'invoice_date', label: 'Invoice Date', sortable: true, thStyle: { width: "10%" } },
        { key: 'rebate', label: 'Rebates / Promo', sortable: true, thStyle: { width: "10%" } },
        { key: 'gst_amount', label: 'GST Amount', sortable: true, thStyle: { width: "10%" } },
        { key: 'comment', label: 'Comment', sortable: true, thStyle: { width: "10%" } },
        { key: 'toggleAction', label: 'Actions',thStyle: { width: "10%" } }
      ],
      form : {
              rowsPerPage : 100,
              invoice_id:'',
              rebate:'',
              comments:'',
            },
      items         : null,
            invoices_list : [],
            totalRows     : 1,
            currentPage   : 1,
            perPage       : 25,
            pageOptions   : [25, 50, 75, 100],
            sortBy        : null,
            sortDirection : 'asc',
            filter        : null,
            status        : 'active',
            isBusy        : false,
            sortBy        : '',
            sortDesc      : false,
            sortDirection : 'asc',
            to            : '',
            from          : '',
            webUrl        : process.env.MIX_APP_URL,
            actions       : '',
            user_type_id  : '',
            inv_id        :'',
            success_message:'',
      };
  },
  filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
          if (date == null || date == "") return "-";
            return moment(date).format('DD MMM, YYYY')
        },
        roundNo:function(val){
          return val.toFixed(2);
        }
  },
  components: {
    Multiselect
  },
  methods: {
    customFormatter(date) {
            return moment(date).format('MMM-YYYY');
     },
    getCreditNoteList() {

             return this.$store.dispatch(POST_API, {
                   data:{
                     status: this.status,
                     inv_id: this.inv_id,
                     page: this.currentPage,
                     keyword: this.filter,
                     rowsPerPage:this.form.rowsPerPage,
                     sortBy:this.sortBy,
                     sortDirection:this.sortDirection,
                     sortDesc:this.sortDesc
                   },
                   api: '/api/credit-note-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.totalRows = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.per_page;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        
                        return this.items;
                    }
                });
                },
      changeInv(){
          this.getCreditNoteList();
          this.$refs.datatable.refresh();
      },
      invoice(value) {
          this.form.invoice_id = value.map(a => a.id);
      },
      formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        invoice_id: this.form.invoice_id,
                        rebate: this.form.rebate,
                        comments: this.form.comments,
                    },
                    api: "/api/credit-note-save",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                      this.success_message = 'Credit Note Added Successfully.';
                        this.showSuccessAlert(this.success_message);
                        this.form.invoice_id ='';
                        this.form.rebate = '';
                        this.form.comments = '',
                        this.$refs.datatable.refresh();
                        this.getCreditNoteList()

                    }
                });
        },
        showAlert() {
            // window.scrollTo(0,0);
            this.dismissCountDown = this.dismissSecs
            this.$swal({
              position: 'top-center',
              type: 'error',
              text: this.error_message,
              showConfirmButton: false,
              timer: 3000
            })
        },

        showSuccessAlert(message) {
            this.$swal({
              position: 'top-center',
              type: 'success',
              text: message,
              showConfirmButton: false,
              timer: 3000
            })
            // Toast.fire({
            //     type: 'success',
            //     title: 'Banner Deleted Successfully.',
            // });
        },
      getInvoiceList() {

          this.$store.dispatch(GET_API, {
                    api: '/api/invoice-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.invoices_list = this.$store.getters.getResults.data;
                        return this.invoices_list;

                    }
                });
      },
      deleteNote(id)
             {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
              })
            .then(result => {
                if (result.value) {
                        return this.$store.dispatch(GET_API, {
                          data:{
                                
                              },
                          api:"/api/delete-credit-note/"+id,
                            })
                            .then(() => {
                                if (this.$store.getters.containsErrors) {
                                    this.error_message = this.$store.getters.getErrors;
                                    this.showAlert();
                                } else {
                                    this.success_message = 'Credit Note Deleted Successfully. '
                                    this.showSuccessAlert(this.success_message);
                                    this.$refs.datatable.refresh();
                                }
                            });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Credit Note");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    show(action){
        if (this.$store.getters.currentUser.user_type_id == 2) {
            return this.actions.indexOf(action) >= 0 ? true : false ;  
        }else{
            return true;
        }
    },
    showFreelancer(action) {
      return true;
      // if (this.$store.getters.currentUser.user_type_id == 2) {
      //   return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      // } else {
      //   return true;
      // }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
      this.permissionStaff();
      this.getInvoiceList();
      this.getCreditNoteList();
  },
};
</script>
