<template>
  <div>
       
         <div class="container full_container_dsk">
            <div class="row custom_dsk_row">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Gallery</h1>
            <b-link :to="{}" class="btn green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn" @click="clickToAdd('add')" v-if="show('upload-image')">
                Upload Image
            </b-link>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Gallery</li>
                </ul>
            </nav>
        </div>
                <div class="col-xl-12 remove_search_box_pdng">
                    <div class="search-box custom_search_admin new_custom_search_admin_remove">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-form-input id="searchBtn" v-model="filter" v-on:change="getListing" placeholder="Enter keyword to search"></b-form-input>
                                <b-button class="btn"><i class="fa fa-search"></i></b-button>
                            </b-input-group-prepend>
                            </b-input-group>
                        </div>
                    </div>
                </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <div class="container full_container_dsk">
           <div class="row">
              <b-table ref="datatable" show-empty striped hover responsive :items="getListing" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="five_grid_tbl new_box_tbl_mrgn common_tbl_width gallery_width">
                
                <template #cell(title)="items">
                  {{items.item.title}}
                </template>
                <template #cell(description)="items">
                    {{ items.item.description != null ? items.item.description.substring(0,50)+"..." : ''}}                
                </template>
                <template #cell(url)="items">
                  <a :href="items.item.url" target="_blank"><img :src="items.item.url ? items.item.url : webUrl + 'assets/images/logo.png'" height="100" width="100" alt="logo_url"/></a>
                </template>
                <template #cell(status)="items">
                  {{items.item.status | capitalize}}
                </template>

                <template #cell(toggleAction)="items">
                  <div class="toggle-action">
                    <b-dropdown class="mx-1" right text="Actions" boundary="window">
                        <b-dropdown-item v-on:click="updateImage(items.item.id)" v-if="show('update image')">Update
                        </b-dropdown-item>
                        <b-dropdown-item v-if="items.item.status == 'active' && show('status update')" v-on:click="changeStatus(items.item.id,items.item.status)">Inactive
                        </b-dropdown-item>
                        <b-dropdown-item v-else-if="items.item.status == 'inactive' && show('status update')" v-on:click="changeStatus(items.item.id,items.item.status)">Active
                        </b-dropdown-item>
                        <b-dropdown-item v-on:click="deleteImage(items.item.id)" v-if="show('remove image')">Delete
                        </b-dropdown-item>
                    </b-dropdown>

                  </div>
                </template>
              </b-table>
              <nav class="pagination-box">
            <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"> <b-form-select v-model="form.rowsPerPage" @change='getListing'>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                      </b-form-select></span>
                </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
                <ul>
                    <li>
                        <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                    </li>
                </ul>
            </div>
            <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
          </nav>
          </div>
          
		</div>
        
        <b-modal ref="upload-modal" :title="modalTitle" hide-footer>
            <b-form @submit="formSubmit">
                <div class="edit-form-box">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <div class="form-map-box">
                                    <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Title" class="required">
                                        <b-form-input id="input-9" maxlength="20" v-model="form.title" type="text" placeholder=""></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Description" class="required">
                                        <b-form-textarea id="input-9" maxlength="200" v-model="form.description" type="text" placeholder=""></b-form-textarea>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12">
                                        <div class="upload-form-group upload-files-box">
                                            <b-form-group id="link-group" label="Upload Image" class="required">
                                                <div class="single-image-upload my_single_img">
                                                    <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onFileChange"  ref="url" id="userPic" v-model="form.url"></b-form-file>
                                                    <span>
                                                       <b-img  :src="selectedImage? selectedImage : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="logo_selected_image"></b-img>
                                                    </span>
                                                </div>
                                            <span>Note: Image should be of 1900 * 500</span>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                <div slot="footer" class="form-btn col-md-12 col-lg-12 gallery_btn">
                                    <b-button type="submit" variant="success">Submit</b-button>
                                    <b-button @click = "hideModel()" variant="light">Cancel</b-button>
                                </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-form>
        </b-modal>
      
        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
        <!-- Row / End -->
  </div>
</template>
<script>
  import { POST_API} from "../../../store/actions.type";
  import JwtService from "../../../common/jwt.service";
import permission from "../../../../../server/permission.js";

  export default {

    data() {
      return {
        fields: [
                { key: 'title', label: 'Title', sortable: true    },
                { key: 'description', label: 'Description', sortable: true },
                { key: 'url', label: 'Image', sortable: true },
                { key: 'status', label: 'Status', sortable: true},
                { key: 'toggleAction', label: 'Actions', thStyle:  {width: '10%'}},
            ],
            form: {
                rowsPerPage:25,
                title: '',
                description: '',
                url: '',
            },
            pager: {},
            pageOfItems: [],
            selectedImage: '',
            selectedImageName: '',
            items:null,
            totalRows: 0,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortBy: '',
            sortDesc: true,
            to:'',
            from:'',
            webUrl: process.env.VUE_APP_URL,
            actions:'',
            user_type_id:'',
            user_id:'',
            modalTitle: 'Upload Image',
            type : 'add',
            id : '',
            actions:'',

      }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    methods: {
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'gallery-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        onFileChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.url = input.files[0];
                if(this.form.url.type !='image/png' && this.form.url.type !='image/jpeg' && this.form.url.type !='image/jpg'){
                    this.selectedImage = this.webUrl +'/images/upload-icon.svg'
                    this.error_message = 'Please select only .png, .jpg, or .jpeg image.';
                    this.selectedImage = '';
                    this.showAlert();
                  }
                  else if(this.form.url.size > 5242880 ){
                    
                    this.selectedImage = this.webUrl +'/images/upload-icon.svg'
                    this.error_message = 'Gallery image should be less than 5 MB.';
                    this.selectedImage = '';
                    this.showAlert();
                  }
                  else
                  {
                    reader.onload = (e) => {
                    this.selectedImage = e.target.result;
                    this.selectedImageName = this.form.url.name;
                    }
                  }
            }
        },
        clickToAdd(typeVal)
        {
          this.$refs['upload-modal'].show();
          this.modalTitle = typeVal == 'add' ? 'Upload Image' : 'Update Image'
          this.type = typeVal == 'add' ? 'Add' : 'Update'

        },
        hideModel()
        {
          this.$refs['upload-modal'].hide();
          this.form.title = '';
          this.form.description = '';
          this.form.url = '';
          this.selectedImage = '';
        },
        getListing() {
            return this.$store.dispatch(POST_API, { 
                   data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                   },
                   api: '/api/gallery-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.items;
                    }
                });
        },

        formSubmit(evt) {
            evt.preventDefault();
            if(this.type == 'update'){
                var api = '/api/gallery-update';
            }else{
                var api = '/api/gallery-store';
            }

            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        title: this.form.title,
                        description: this.form.description,
                        url: this.selectedImage ,
                        selectedImageName: this.selectedImageName,
                        type: 'website',
                    },
                    api: api,
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        if(this.type == 'update'){
                            var message='Image Updated successfully';
                        }else{
                            var message='Image Added successfully';
                        }
                        this.$refs.datatable.refresh();
                        this.showSuccessAlert(message);
                        this.hideModel();
                    }
                });
        },
    
        updateImage(id){
            this.clickToAdd('update');
            this.type = 'update';
            this.id = id;

            return this.$store.dispatch(POST_API, { 
                data:{
                    id:id,
                },
                api: '/api/gallery-edit'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    this.form.title = this.$store.getters.getResults.data.title;
                    this.form.description = this.$store.getters.getResults.data.description;
                    this.selectedImage = this.$store.getters.getResults.data.url;
                }
            });
        },
        
        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Image Inactivated successfully.';
                var new_status = 'inactive';
            } 
            else{
                var msg = 'Are you sure you want to activate this record?';
                var message='Image Activated successfully.';
                var new_status = 'active';

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            status:new_status,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/gallery-status' 
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        
        deleteImage(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/gallery-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Image Deleted successfully.';
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Gallery');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },

    },
    mounted(){
        //document.title = "EL Connect - Admin - Website Contents - Gallery";
        this.user_type_id = this.$store.getters.currentUser.user_type_id;
        this.user_id = this.$store.getters.currentUser.id;
        this.permissionStaff();
    }
  }
  
</script>

