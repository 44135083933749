<template>
    <div>
           
        <!-- Titlebar
        ================================================== -->
        <div id="titlebar" class="gradient">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">


                    </div>
                </div>
            </div>
        </div>


        <!-- Page Content
        ================================================== -->
        <div class="container" v-if="display==true">
            <div class="row">
                <div class="col-xl-5 offset-xl-3">

                        <!-- Welcome Text -->
                        <div class="welcome-text">
                            <h3>Reset Password</h3>
                            <span>Please enter your new password.</span>
                        </div>

                        <!-- Account Type -->
                    <b-form method="post" id="reset-password-form" @submit.prevent="resetPassword">
                            
                        <!-- Form -->
                            <div class="input-with-icon-left" id="show_hide_password_login">
                                <i class="icon-material-outline-lock"></i>
                                <b-form-input
                                    type="password"
                                    id="password"
                                    v-model="password"
                                    class="input-text with-border password_margin"
                                    name="signup-password"
                                    placeholder="Password"
                                    @click="showPasswordRequirements"
                                />
                                <div class="input-group-addon-custom">
                                        <a  @click="passwordClick">
                                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                <div class="password_requirements_text common_z_index_zero" :style="{ backgroundImage: 'url('+webUrl+'assets/images/password-bg-img.png'+')'}" style="display:none;">
                                    <span>Minimum 8 characters, must contain an uppercase letter, a
                                        lowercase letter, at least 1 number and 1 special character
                                    </span>
                                </div>
                            </div>

                            <div class="input-with-icon-left" id="show_hide_repassword_login">
                                <i class="icon-material-outline-lock"></i>
                                <b-form-input
                                    type="password"
                                    v-model="repassword"
                                    id="repassword"
                                    class="input-text with-border"
                                    name="repeate-password"
                                    placeholder="Confirm Password"
                                    @click="hidePasswordRequirements"
                                />
                                <div class="input-group-addon-custom">
                                        <a  @click="repasswordClick">
                                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                        </a>
                                    </div>
                            </div>

                        </b-form>
                        
                        <!-- Button -->
                        <button class="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit" form="reset-password-form">Confirm <i class="icon-material-outline-arrow-right-alt"></i></button>
                        
                        <!-- Direct to Login Text -->
                        <div class="welcome-text">
                            <span>Click here to return to <b-link href="" :to="{name: 'login'}" >Log In</b-link></span>
                        </div>
                </div>
            </div>
        </div>

                <div class="container" v-else>
            <div class="row">
                <div class="col-xl-5 offset-xl-3">

                        <!-- Welcome Text -->
                        <div style="text-align:left; font-size:40px; color:red;" >
                            <span>{{link_error}}</span>
                        </div>
                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
    </div>
</template>

<style scoped>
  @import '../../assets/css/style.css';
  @import '../../assets/css/icons.css';

  /* Color css  */

  @import '../../assets/css/colors/blue.css';

</style>
<script>
    import { POST_API } from "../../store/actions.type"

    export default {

        data() {
            return {
                webUrl: process.env.VUE_APP_URL,
                token: this.$route.params.token,
                user_id: this.$route.params.id,
                showDismissibleAlert:false,
                error_message:'',
                password:'',
                repassword:'',
                display:false,
                link_error:'',
                user_type_id:''
            }
        },
        methods:{
            showPasswordRequirements()
            {
                $('.password_requirements_text').show();
                $('.password_margin').addClass('desktop_margin_bottom_zero');
            },
            hidePasswordRequirements(){
                $('.password_margin').removeClass('desktop_margin_bottom_zero');
                $('.password_requirements_text').hide();
            },
            resetPassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        token: this.token,
                        password: this.password,
                        repassword: this.repassword,
                        user_id: this.user_id

                    },
                    api:'/api/emailresetpassword'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.user_type_id = this.$store.getters.getResults.user_type_id;
                        console.log(this.user_type_id,'this.user_type_id')
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Password updated successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            if(this.user_type_id == 5){
                                window.location.replace('/login-jobseeker');
                            }else{
                                window.location.replace('/login-employer');
                            }
                        });
                    }        
                }); 
            },
            passwordClick(event){
                event.preventDefault();
                if($('#show_hide_password_login input').attr("type") == "text"){
                    $('#show_hide_password_login input').attr('type', 'password');
                    $('#show_hide_password_login i').addClass( "fa-eye-slash" );
                    $('#show_hide_password_login i').removeClass( "fa-eye" );
                }else if($('#show_hide_password_login input').attr("type") == "password"){
                    $('#show_hide_password_login input').attr('type', 'text');
                    $('#show_hide_password_login i').removeClass( "fa-eye-slash" );
                    $('#show_hide_password_login i').addClass( "fa-eye" );
                }
            },
            repasswordClick(event){
                event.preventDefault();
                if($('#show_hide_repassword_login input').attr("type") == "text"){
                    $('#show_hide_repassword_login input').attr('type', 'password');
                    $('#show_hide_repassword_login i').addClass( "fa-eye-slash" );
                    $('#show_hide_repassword_login i').removeClass( "fa-eye" );
                }else if($('#show_hide_repassword_login input').attr("type") == "password"){
                    $('#show_hide_repassword_login input').attr('type', 'text');
                    $('#show_hide_repassword_login i').removeClass( "fa-eye-slash" );
                    $('#show_hide_repassword_login i').addClass( "fa-eye" );
                }
            },
            checkToken() {
                this.$store.dispatch(POST_API, {
                    data:{
                        token: this.token,
                    },
                    api:'/api/checktoken'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                    if (this.$store.getters.getResults.data == true)
                    {
                         this.display = true;
                    }
                    else {
                        this.display = false;
                        this.link_error = "Your link has expired."
                    }
            }
            })
            }
        },
            mounted(){
                $('.password_requirements_text').hide();
                //document.title = "EL Connect - Reset Password"; 
                this.checkToken();
                
        },
    }
</script>
