<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section new_updated_promotion">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Add Promotion</h1>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Add Promotion</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <!-- <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 edit_genneral_stng_dsk"> -->
            <div class="edit-profile-section border-radius-4 commmon_label_heading edit_genneral_stng_dsk">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box dashboard-box">
                        <div class="cover_hole_inner_cntent desktop_margin_top_zero">
                        <div class="headline"><h3><span class="icon-feather-plus-circle"></span> Promotion Submission Form</h3></div>
                        
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col  xl="12">
                                    <b-form-group id="title-group" label="Promotion Title" class="required promo_require">
                                        <b-form-input id="title" v-model="form.title" type="text" ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col  xl="12">
                                    <b-form-group label="Promotion Description" class="required promo_require">
                                        <ckeditor v-model="form.description" :config="editorConfig"></ckeditor> 
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col  xl="4"  >
                                    <div class="upload-form-group">
                                        <b-form-group id="link-group" label="Promotion Image" class="required promo_require admin_promo_img_set">
                                            <div class="single-image-upload my_single_img img_back_clr" :style=" { background: backgroundColour}">
                                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                                <span>
                                                    <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" class="after_img_set"></b-img>
                                                    <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="before_img_promo"></b-img>
                                                </span>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-col>

                                <b-col  xl="8">
                                    <div class="upload-form-group">
                                        <b-form-group id="link-group" label="Promotion Banner Image (Will use Promotion Image as default if blank)" class="required  admin_promo_img_set">
                                            <div class="single-image-upload my_single_img img_back_clr" :style=" { background: backgroundColour}">
                                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                                <span>
                                                   <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" alt="logo"></b-img>-->
                                                   <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" class="after_img_set"></b-img>
                                                    <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="before_img_promo"></b-img>
                                                </span>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                
                                
                                <b-col  xl="4"  >
                                    <b-form-group id="title-group" label="Start Date" class="required promo_require">
                                        <datepicker v-model="form.start_date" :disabled-dates="disabledFromDate"  :format="customFormatterTwo" @input="resetTodate"></datepicker>
                                        <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span> -->
                                    </b-form-group>
                                </b-col>

                                <b-col  xl="4"  >
                                    <b-form-group id="title-group" label="End Date" class="required promo_require">
                                        <datepicker v-model="form.end_date" :disabled-dates="disabledDate()"  :format="customFormatterTwo"></datepicker>
                                        <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span> -->
                                    </b-form-group>
                                </b-col>

                                <b-col  xl="4"  >
                                    <b-form-group id="title-group" label="Promotion Run Days" class="required">
                                        <b-form-input id="title" v-model="form.title" type="text" ></b-form-input>
                                        <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span> -->
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                
                                <b-col  xl="4"  >
                                    <b-form-group id="title-group" label="Promotion Posting Package" class="required promo_require">
                                        <b-form-select v-model="selected" :options="options" id="jbs_category" class="jbs_category">
                                    
                                        </b-form-select>
                                        <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span> -->
                                    </b-form-group>
                                </b-col>

                                <b-col  xl="4"  >
                                    <b-form-group id="title-group" label="Total Posting Cost" class="required">
                                        <b-form-input id="title" v-model="form.title" type="text" ></b-form-input>
                                        <img :src= "webUrl+'/assets/images/Icons_Coin.png'" class="posting_cost_img">
                                        <!-- <span class="input-icon"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span> -->
                                    </b-form-group>
                                </b-col>

                                
                            </b-row>
                            
                            
                            <b-row>
                                <b-col  xl="12">
                                    <b-form-group id="title-group" label="Sort Order (Admin Backend Only)" class="required desktop_margin_bottom_zero">
                                        <b-form-input id="sortOrder" v-model="form.sort_order" type="number" min="0" max="1000"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                        </div>
                        </div>
                        <div slot="footer" class="form-btn mt-3 admin_promo_footer">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero"><span class="custom_plus">+</span> Create Promotion</b-button>
                            <b-button :to="{name:'promotion-list'}" variant="light" class="drft_btn"><span class="icon-material-outline-drafts"></span>Save as Draft</b-button>
                        
                    </div>
                    </div>
                </b-form>
                <b-modal ref="crop-modal" title="Edit Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImg">
                        <template>
                              <div>
                                <!-- <input ref="input" name="image" accept="image/*" @change="setImage"/> -->
                                <div class="content" @change="setImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImage">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import CKEditor from 'ckeditor4-vue';


export default {

    data() {
        return {
            selected: null,
            options: [
            { value: 'a', text: 'First Package' },
            { value: 'b', text: 'Second Package' },
            { value: 'b', text: 'Third Package' },
            { value: 'b', text: 'Fourth Package' },
            { value: 'b', text: 'Fifth Package' }
            ],
            form: {
                name: '',
                start_date: '',
                end_date: '',
                description: '',
                sort_order: '',
                image: '',
                selectedImage:{
                    name:'',
                    image:''
                },
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: this.$route.params.id,
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            delta: undefined,
            disabledFromDate: {
                to: new Date(Date.now() - 8640000)
            },
            webUrl: process.env.VUE_APP_URL,
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true,
            editorConfig: {
                versionCheck : false,
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
                },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
              // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            },
            backgroundColour: '#eee'
        }
    },
    components: {
        Datepicker,
        VueCropper,
        ckeditor: CKEditor.component,
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'promotion-list'},
                text: 'Promotions',
            },{
                to:null,
                text: 'Add Promotion'
            }];
            return item;
        },
        cropImage() {
          this.$refs['crop-modal'].hide(); 
          this.backgroundColour='transparent';
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
              this.cropImg = event.target.result;
              this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal'].hide(); 
            this.$refs['crop-modal'].refresh();
          
        },
        onImageChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.image = input.files[0];
                if (this.form.image.type != 'image/jpeg' && this.form.image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.image.size > 1048576) {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Image should be less than 1 MB.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.image.name;
                        this.showImg();
                        event.target.value = '';

                    }
                }
            }
        },
        formatDate(date) {
            
            return moment(date).format('DD-MM-YYYY');
        },
        customFormatterTwo(date) {
            
            return moment(date).format('DD-MM-YYYY');
        },
        disabledDate(){
             var d = new Date(this.form.start_date);
           return {

               to: new Date(d.getFullYear(), d.getMonth(), d.getDate())
           };
        },
        resetTodate(){
            this.form.end_date = "";
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'promotion-list' });
            });
        },
        
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        title: this.form.title,
                        start_date  : this.form.start_date ? moment(this.form.start_date).format('YYYY-MM-DD') : '',
                        end_date    : this.form.end_date ? moment(this.form.end_date).format('YYYY-MM-DD') : '',
                        description: this.form.description,
                        sort_order: this.form.sort_order,
                        image: this.form.selectedImage.image,
                        items:this.form,
                    },
                    api: "/api/promotion-store",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Promotion Added successfully.'
                        this.showSuccessAlert(message);
                        this.$router.push({ name: 'promotion-list' });      
                    }
                });
        },     
        getLastSortData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    status: this.status,
                    page: this.currentPage,
                },
                api: '/api/promotion-sort-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.data.data;
                    this.form.sort_order = parseInt(this.$store.getters.getResults.data.sort_order) + 1;
                }
            });
        },   
        
    },
    mounted()
    {
        this.getLastSortData();
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
      //  document.title = "EL Connect - Admin - Website Contents - Add Promotion";
    }
};

</script>
