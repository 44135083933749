<template>
    <div>

         <!-- <div class="container full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Billing Address</h1>
            <!-- <b-link v-if="address_count == 0" class="btn green-border-btn" :to="{name:'addbillingAddress'}">Add</b-link> -->

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Billing Addressss</li>
                </ul>
            </nav>
        </div>
            <div class="row">
                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job submit-field">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <b-input-group-prepend>
                                        <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                                    </b-input-group-prepend>
                                    <!-- </b-input-group> -->
                            </div>
                </div>
                <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop submit-field" v-if="address_count == 0">
                    <b-link v-if="address_count == 0" class="add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero btn green-border-btn" :to="{name:'addbillingAddress'}">Add</b-link>
                </div>
            </div>
            </div>
        </div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk">
           <div class="row"> -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getBillingAddress" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="new_box_tbl_mrgn five_grid_tbl common_tbl_width"> -->
               <b-table ref="datatable" show-empty striped hover responsive :items="getBillingAddress" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 countries_five_grid_table">
                
                    <template #cell(name)="items">
                        {{items.item.name | capitalize}}
                    </template>
                    <template #cell(billing_email)="items">
                        {{items.item.billing_email}}
                    </template>
                    <template #cell(street)="items">
                        {{items.item.street | capitalize}}
                    </template>
                    <template #cell(city)="items">
                        {{items.item.city | capitalize}}
                    </template>
                     <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item :to="{name: 'editbillingAddress', params: {id: encrypt(items.item.id)}}">Edit
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
            
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getBillingAddress'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../store/actions.type'
import Bus from "../../event-bus";

export default {

    data() {
        return {
            fields: [
                { key: 'name', label: 'Name', sortable: true },
                { key: 'billing_email', label: 'Billing Email', sortable: true },
                { key: 'street', label: 'Address', sortable: true },
                { key: 'city', label: 'City', sortable: true },
                { key: 'toggleAction', label: 'Actions', }

            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            address_count:0,
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            }
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getBillingAddress() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    id:this.user_id,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/billing-address'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.address_count = this.$store.getters.getResults.address_count;
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },

        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Country Inactivated successfully.';
                var new_status = 'inactive';
            } 
            else{
                var msg = 'Are you sure you want to activate this record?';
                var message='Country Activated successfully.';
                var new_status = 'active';
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            status:new_status,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/country-status' 
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            //document.title = "EL Connect - Employer - Billing Address"; 
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        Bus.$emit('active_users');
     }
}
</script>
