<template>
  <div>
    <section id="form">
      <div class="bg_color_singpass">
        <div class="main_two_div">
          <SingpassLeftSideContentComponent />
          <div class="right_side_div_sinpass">
            <div class="">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="create_account_h2_sing common_montesrat_regular_singpass">
                    Create Account
                  </h2>
                  <img :src="webUrl + 'assets/images/Group-732.png'" class="common_verify_img" />
                  <p
                    class="common_montesrat_regular_singpass top_information common_font_weight_600"
                  >
                    Kindly Confirm your Mobile Number to Continue
                  </p>
                </div>
              </div>
            </div>
            <form id="formApplication" class="toggle_content">
              <div class="">
                <div class="row page_height_row">
                  <div class="col-md-7">
                    <div class="new_submit_field">
                      <h5 class="">Mobile Number</h5>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control with-border"
                          v-model="mobile"
                          disabled
                        />
                        <!-- <input type="text" class="form-control with-border" v-model="mobile"> -->

                        <div class="verify-btn">
                          <button
                            type="submit"
                            id="otpbutton"
                            @click="sendOTP"
                            class="button ripple-effect continue_btn_singpass verify_btn_common"
                          >
                            Send OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
										<input type="text" id="otp" class="form-control with-border" v-model="otp" style="display:none;">
								  </div> -->
                </div>
                <!-- <div class="row">
								<div class="col-md-12 align_end page_height_button">
									<button type="submit"  @click="mobileVerify" class="button ripple-effect continue_btn_singpass">Continue</button>
								</div>
							</div> -->
                <div class="row">
                  <div class="col-md-12 align_end page_height_button">
                    <button
                      @click="skipMobileVerification"
                      class="button ripple-effect continue_btn_singpass"
                    >
                      Skip
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <b-modal
          ref="verify-mobile-modal"
          no-close-on-backdrop
          hide-footer
          content-class="mobile_verification_model common_model_title"
          title="Phone Verification"
          modal-class="background_custom_class mobile_pop_up_mdl"
        >
          <template #modal-title>
            Phone Verification
            <span
              ><button type="button" @click="mobileVerifyCancel" class="close closefirstmodal">
                &times;
              </button></span
            >
          </template>
          <b-form class="">
            <div class="edit-form-box web_top_zero">
              <div class="profile-repeate form-box-field">
                <div class="col-xl-12">
                  <!-- <h3><b>Phone Verification</b></h3> -->
                  <h5 class="gray_h5">
                    Enter the verification code that<br />
                    was sent to your mobile number.
                  </h5>
                </div>
                <b-row>
                  <b-col lg="12" md="12">
                    <div class="form-map-box">
                      <b-col lg="12" md="12" class="input-with-icon-left">
                        <i class="fa fa-mobile pop_up_i"></i>
                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                          <b-form-input
                            id="input-9"
                            maxlength="20"
                            v-model="mobile"
                            type="text"
                            placeholder=""
                            readonly="readonly"
                            class="number_input"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                          <input
                            type="text"
                            v-model="otp"
                            class="with-border form-control"
                            placeholder="Enter the 4-digit code"
                          />
                        </b-form-group>
                      </b-col>

                      <div slot="footer" class="form-btn col-md-12 col-lg-12">
                        <b-button
                          @click="mobileVerify"
                          variant="success"
                          style="background-color: #4c8bf5 !important"
                          class="otp_btn verify_otp_btn_mod"
                          >Verify OTP</b-button
                        >
                        <!-- <b-button @click = "hideVerifyMobileModel()" variant="light">Cancel</b-button> -->
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <div class="col-xl-12 bottom_text_pop_phone">
                  <h5>
                    Verification code may take up to a few <br />minutes to be sent to your phone.
                  </h5>
                  <h5 class="web_bottom_zero" v-if="countDown > 0">
                    Didn't receive the code? Resend code in
                    <span style="color: #4c8bf5 !important"> {{ countDown }} seconds</span>
                  </h5>
                  <h5 class="web_bottom_zero" v-else>
                    Didn't recevie the code?
                    <b-link @click="sendOTP" style="color: #4c8bf5 !important"> Resend Code</b-link>
                  </h5>
                </div>
              </div>
            </div>
          </b-form>
        </b-modal>
      </div>
    </section>
    <!-- <div class="margin-top-70 mobile_tab_margin_top_45"></div> -->
  </div>
</template>

<script>
import { POST_API, PERMISSION_DATA, LOGIN } from "../../store/actions.type";
import axios from "axios";
import SingpassLeftSideContentComponent from "./SingpassLeftSideContentComponent.vue";

function str(data) {
  if (!data) return null;
  if (data.value) return data.value;
  else if (data.desc) return data.desc;
  else if (typeof data == "string") return data;
  else return "";
}

function populate(frm, data) {
  $.each(data, function (key, value) {
    $("[name=" + key + "]", frm).val(value);
    // $('[name=' + key + ']', frm).prop('disabled', true);
  });
}
// ---END---PREFILL FORM (with MyInfo data)

export default {
  metaInfo: {
    title: "EL Connect - Terms of Service",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - Terms of Service" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/terms-of-service" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/terms-of-service" }],
  },
  data() {
    return {
      x: "terms_jobseeker",
      terms_and_conditions_ptUser: "",
      terms_and_conditions_supervisor: "",
      showDismissibleAlert: false,
      error_message: "",
      display: true,
      otp: "",
      link_error: "",
      webUrl: process.env.VUE_APP_URL,
      codeVerifier1: "",
      mobile: window.localStorage.getItem("mobile"),
      user_id: window.localStorage.getItem("user_id"),

      showVerifyOtp: true,
      countDown: 60,
    };
  },
  components: {
    SingpassLeftSideContentComponent,
  },
  methods: {
    // getGenerateCode(){
    //      this.$store.dispatch(POST_API, {
    //         data:{
    //             authCode: this.$route.query.code,
    //         },
    //         api:'/getfl/generateCodeChallenge'
    //     })
    //     .then((res) => {

    // 			this.codeVerifier1 = this.$store.getters.getResults.sessiondata

    // 			console.log(this.codeVerifier1,'uuuuuuuuuu');
    // 			this.getSingpassData();
    // 	})
    // },
    // getSingpassData(){

    // 	this.$store.dispatch(POST_API, {
    //         data:{
    //             authCode: this.$route.query.code,
    // 			codeVerifier:this.codeVerifier1
    //         },
    //         api:'/getfl/getPersonData'
    //     })
    // 	// const formData = new FormData()
    // 	// formData.append('authCode', this.$route.query.code)
    // 	// formData.append('codeVerifier', this.codeVerifier1)

    // 	// axios.post('http://localhost:3001/v2/getfl/getPersonData', formData, {
    // 	// })
    // 	.then((res) => {

    // 		console.log(res);

    //         if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;

    //             this.$swal({
    //                 position: 'center',
    //                 icon: 'error',
    //                 title: this.error_message,
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             });

    //         } else {
    //         }
    // })

    // }

    redirect() {
      if (window.localStorage.getItem("old_email") != window.localStorage.getItem("email")) {
        window.location.replace("/singpass-verify-email");
      } else {
        this.getMenuPermission();
      }
    },

    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.user_type == "3") {
              // window.location.replace('/v2/employer/dashboard');
              this.is_company_head = this.$store.getters.currentUser.is_company_head;
              this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                if (this.is_company_head == "yes") {
                  window.location.replace("/v2/employer/employer-profile");
                } else {
                  window.location.replace("/v2/employer/om-supervisor-profile");
                }
              }
            } else {
              window.location.replace("/v2/jobseeker/daily-job-profile");
            }
          }
        });
    },
    mobileVerifyCancel() {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to close this popup?",
        // text: "You can resend OTP in " +this.countDown+ " seconds.Are you sure you want to close this popup?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((value) => {
        if (value.isConfirmed) {
          //clearTimeout(this.timeOutCounter);
          this.$refs["verify-mobile-modal"].hide();
        }
      });
    },
    hideVerifyMobileModel() {
      clearTimeout(this.timeOutCounter);
      this.$refs["verify-mobile-modal"].hide();
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.showVerifyOtp = false;
        this.timeOutCounter = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.showVerifyOtp = true;
      }
    },
    showVerifyMobileModel() {
      (this.countDown = 60), (this.otp = "");
      this.countDownTimer();
      this.$refs["verify-mobile-modal"].show();
    },
    sendOTP() {
      if (this.showVerifyOtp) {
        clearTimeout(this.timeOutCounter);
        this.$store
          .dispatch(POST_API, {
            data: {
              mobile: this.mobile,
              user_id: this.user_id,
              // user_id:14227,

              user_type_id: 5,
            },
            api: "/api/jobseekerSendOTP",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "OTP sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.showVerifyMobileModel();
                // $('#otp').css("display","block");
                // $('#otpbutton').css("display","none");
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please try again in " + this.countDown + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    showAlert() {
      this.$swal({
        position: "center",
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    skipMobileVerification() {
      this.redirect();
    },

    mobileVerify() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            verification_code: this.otp,
            user_id: this.user_id,
          },
          api: "/api/verifyMobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            // return [];
          } else {
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Mobile Number verified successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.redirect();

              // window.location.reload();
            });
          }
        });
    },

    prefillForm(data) {
      console.log(data);
      // prefill form data
      var noaData = "";
      var address = "";
      if (data["noa-basic"]) {
        noaData = str(data["noa-basic"].amount)
          ? formatMoney(str(data["noa-basic"].amount), 2, ".", ",")
          : "";
      }
      if (data.regadd.type == "SG") {
        address =
          str(data.regadd.country) == ""
            ? ""
            : str(data.regadd.block) +
              " " +
              str(data.regadd.building) +
              " \n" +
              "#" +
              str(data.regadd.floor) +
              "-" +
              str(data.regadd.unit) +
              " " +
              str(data.regadd.street) +
              " \n" +
              "Singapore " +
              str(data.regadd.postal);
      } else if (data.regadd.type == "Unformatted") {
        address = str(data.regadd.line1) + "\n" + str(data.regadd.line2);
      }
      var formValues = {
        uinfin: str(data.uinfin),
        name: str(data.name),
        sex: str(data.sex),
        race: str(data.race),
        nationality: str(data.nationality),
        dob: str(data.dob),
        email: str(data.email),
        // str(data.mobileno.prefix) + str(data.mobileno.areacode) + " " +
        mobileno: str(data.mobileno.nbr),
        regadd: address,
      };

      // Populate values
      populate("#formApplication", formValues);
    },
  },
  mounted() {
    $(function () {
      $("#formAuthorize").submit(function (event) {
        event.preventDefault();
        callAuthorizeApi();
      });
      $("#formApplication").submit(function (event) {
        event.preventDefault();
        // add code here to submit the application form back to server for processing
        $("#complete").toggleClass("hidden");
      });
    });
    // ---END---MAIN HANDLER---

    // ---START---AUTH API---
    function callAuthorizeApi() {
      //Call backend server to generate code challenge
      $.ajax({
        url: "/getfl/generateCodeChallenge",
        data: {},
        type: "POST",
        success: function (result) {
          //Redirect to authorize url after generating code challenge
          var authorizeUrl =
            authApiUrl +
            "?client_id=" +
            clientId +
            "&scope=" +
            scope +
            "&purpose_id=" +
            purpose_id +
            "&code_challenge=" +
            result +
            "&code_challenge_method=" +
            method +
            "&redirect_uri=" +
            redirectUrl;

          window.location = authorizeUrl;
        },
        error: function (result) {
          alert("ERROR:" + JSON.stringify(result.responseJSON.error));
        },
      });
    }
    // ---END---AUTH API---

    var data = window.localStorage.getItem("singpassData");

    console.log(JSON.parse(data));

    //document.title = "EL Connect - Terms of Service";
    if (data) {
      this.prefillForm(JSON.parse(data));
    }

    function populate(frm, data) {
      $.each(data, function (key, value) {
        $("[name=" + key + "]", frm).val(value);
        $("[name=" + key + "]", frm).prop("disabled", true);
      });
    }

    window.scrollTo(0, 0);
  },
};
</script>
