<template>
    <!-- Wrapper -->
<div id="wrapper wrapper_pdng">
<!-- <div class="back_arrow_icon"> -->
    <!-- <span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span> -->
	<!-- <b-link class="icon-material-outline-arrow-back new_arrow_back" :to="{name: 'manage-jobs'}"></b-link> -->
<!-- </div> -->
		<div id="breadcrumb-v2" class="mb-1">
			<div class="d-flex justify-content-start breadcrumb-wrapper">
				<b-breadcrumb
				:items="breadCrumb()"
				/>
			</div>
        </div>
<!-- Titlebar
================================================== -->
<!--<div class="single-page-header freelancer-header single_top_bk">
	<div class="single_gradient">
	<div class="container" v-if="items != null">
		<div class="row">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image freelancer-avatar jobseeker_img"><img :src="items.profile_image ? items.profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt=""></div>
						<div class="header-details">
							<h3 class="name_mrgn">{{ items.last_name | capitalize }} {{ items.first_name | capitalize }} <span>{{ items.designation }}</span></h3>
                            <h4 class="new_h4">Short Description</h4>
							<ul >
								<li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false" v-if="items.average_rating != null"></star-rating></li>
								<li><div class="star-rating" data-rating="5.0" v-if="items.average_rating != null"></div></li>
								<li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li> 
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</div>-->


    <div class="bg_img_top new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( items.banner_image ? items.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
        <div class="grd_div inside_grd">
        <div class="container mx_width my_new_container job_detail_container mobile_tab_padding_left_right_15">
            <div class="row">
			<div class="col-md-12">
				<div class="inrs_main">
					<div class="left-side updated_left_side_banner_employer new_left_sides profile_hundered_per">
						<!-- <div class="header-image freelancer-avatar jobseeker_img new_rm_img"> -->
						<!-- <div class="header-image freelancer-avatar jobseeker_img remove_profile_back_pdng"> -->
						<div class="header-image new_up_header_img updated_image_in_round_shape updated_header_image_banner_employer">
							<!-- <img :src="items.ft_profile_image ? items.ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="" class="user_image_bordrs remove_box_shadow"> -->
							<viewer>
							<img :src="items.ft_profile_image ? items.ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="" class="user_dn_image common_border-radius">
							</viewer>
						</div>
						<!-- <div class="header-details new_header_details"> -->
						<div class="header-details updated_header_details_banner_employer">
							<h1 class="name_mrgn header_detail_new_h1 new_daily_paid_jjobs">{{ items.last_name | capitalize }} {{ items.first_name | capitalize }} <span>{{ items.designation }}</span></h1>
                            <!-- <h4 class="new_h4" v-if="items.short_description">{{ items.short_description | capitalize }}</h4> -->
							<div>
							<ul class="margin_top_12 mobile_header_ul">
								<!-- <li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false" v-if="items.average_rating != null"></star-rating></li> -->
								<!-- <li><div class="star-rating" data-rating="5.0" v-if="items.average_rating != null"></div></li> -->
								<!--<li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li> -->
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
						</div>
					</div>
					<!--<div class="right-side">
                        <div class="right_inner_contents">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
                        </div>
					</div>-->
				</div>
			</div>
		</div>
        </div>
     
        
    </div>
    </div>


<!-- Page Content
================================================== -->
<div class="container mx_width my_new_container job_detail_container">
	<div class="row">
		
		<!-- Content -->
		<!-- <div class="col-xl-8 col-lg-8 content-right-offset emp_prf"> -->
		<div class="col-xl-8 col-lg-8 single_page_sec_updated single_left_content tab_max_width_and_flex sidebar_left_i_margin_tp">	
			
			<!-- Page Content -->
			<div class="single-page-section desktop_margin_bottom_zero" v-if="items != null && items.about">
				<h3 class="margin-bottom-25">About Me</h3>
				<p v-html="items.about" class="desktop_margin_bottom_zero"></p>
			</div>

			<!-- Boxed List -->
			<!-- <div class="boxed-list  new_single_page_section single-page-section" v-if="work_history.length > 0">
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Work History and Feedback</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li v-for="wr_history in work_history">
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>{{wr_history.title | capitalize}} <span>Rated as Freelancer</span></h4>
								<div class="item-details margin-top-10" v-if="wr_history.rating || wr_history.end_date">
									<div><star-rating v-model="wr_history.rating" v-bind:star-size="20" :show-rating="false"  v-if="wr_history.rating"></star-rating></div>
									<div class="star-rating" data-rating="5.0" v-if="wr_history.rating"></div>
									<div class="detail-item" v-if="wr_history.end_date"><i class="icon-material-outline-date-range"></i> {{wr_history.end_date | moment}}</div>
								</div>
								<div class="item-description" v-if="wr_history.review">
									<p>{{wr_history.review}}</p>
								</div>
							</div>
						</div>
					</li>
				</ul>

				<div class="clearfix"></div>
				<div class="pagination-container margin-top-40 ">
					<nav class="pagination-box custom_pagination_border_radius">
						<div class="page-row-box d-inline-block">
							<b-form-group id="input-group-4">
								<span class="d-inline-block">Rows per page</span>
							<span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input="getJobseekers">
											<option>10</option>
											<option>20</option>
											<option>30</option>
											<option>40</option>
								</b-form-select></span>
							</b-form-group>
						</div>
						<div class="total-page-count d-inline-block">
							<ul>
								<li>
									<span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
								</li>
							</ul>
						</div>
						<b-pagination @input="getJobseekers"   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
					</nav>
				</div>
				<div class="clearfix"></div>

			</div> -->
			<!-- Boxed List / End -->
			
			<!-- Boxed List -->
			<div class="boxed-list  single-page-section" v-if="employment_history.length >0">
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Employment History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="em_history in employment_history">
						<div class="boxed-list-item" v-if="em_history.job_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->
							
							<!-- Content -->
							<div class="item-content">
								<h4>{{em_history.job_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="em_history.company_name"><i class="icon-material-outline-business"></i> {{em_history.company_name | capitalize}}</div>
									<!-- <div class="detail-item" v-if="em_history.experience_in_year > 0 || em_history.months > 0"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year > 0 ">{{em_history.experience_in_year }} years</span> <span v-if="em_history.months > 0 ">{{em_history.months}} months</span></div> -->
									<!-- <div class="detail-item" v-else><i class="icon-material-outline-date-range"></i>No Experience</div> -->
									<div class="detail-item" v-if="em_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="em_history.from_year">{{em_history.from_year | educationMonthYear}} - </span><span v-if="em_history.currently == 'yes'">Currently Working</span> <span v-else>{{em_history.to_year | educationMonthYear}}</span></div>
								</div>
								<div class="item-description" v-if="em_history.description">
									<p>{{em_history.description  | capitalize}}</p>
								</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>
			<div class="" v-else>
				<div v-if="employment_history_v1.length >0" class="boxed-list  single-page-section">
					<div class="boxed-list-headline">
						<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Employment History</h3>
					</div>
					<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
						<li v-for="em_history in employment_history_v1">
							<div class="boxed-list-item" v-if="em_history.job_title">
								<!-- Avatar -->
								<!-- <div class="item-image">
									<img :src= "webUrl+'assets/images/logo.png'" alt="">
								</div> -->
								
								<!-- Content -->
								<div class="item-content">
									<h4>{{em_history.job_title | capitalize}}</h4>
									<div class="item-details margin-top-7">
										<div class="detail-item" v-if="em_history.company_name"><i class="icon-material-outline-business"></i> {{em_history.company_name | capitalize}}</div>
										<!-- <div class="detail-item" v-if="em_history.experience_in_year > 0 || em_history.months > 0"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year > 0 ">{{em_history.experience_in_year }} years</span> <span v-if="em_history.months > 0 ">{{em_history.months}} months</span></div> -->
										<!-- <div class="detail-item" v-else><i class="icon-material-outline-date-range"></i>No Experience</div> -->
										<div class="detail-item" v-if="em_history.experience_in_year"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year">{{em_history.experience_in_year}} Years </span><span v-if="em_history.month"> - {{em_history.month}} Months</span></div>
									</div>
									
								</div>
							</div>
							<div class="content text-center" v-else>
								<p><b>No Record Found</b> </p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="boxed-list  single-page-section" v-if="education_history.length > 0">
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-line-awesome-graduation-cap"></i> Education History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="ed_history in education_history">
						<div class="boxed-list-item" v-if="ed_history.eq_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->
							
							<!-- Content -->
							<div class="item-content">
								<h4>{{ed_history.eq_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="ed_history.fs_title"><i class="icon-material-outline-business"></i> {{ed_history.fs_title == 'Other' ? ed_history.specified_field_study : ed_history.fs_title | capitalize}}</div>
									<!-- <div class="detail-item" v-if="ed_history.from_year && ed_history.to_month"><i class="icon-material-outline-date-range"></i>{{ed_history.from_year | educationMonthYear}} - {{ed_history.to_month | educationMonthYear}}</div> -->
									<div class="detail-item" v-if="ed_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="ed_history.from_year">{{ed_history.from_year | educationMonthYear}} - </span> <span v-if="ed_history.currently == 'yes'">Currently Studying</span> <span v-else>{{ed_history.to_month | educationMonthYear}}</span></div>
								</div>
								<div class="item-description">
									<p>{{ed_history.un_title  == 'Other' ? ed_history.specified_university : ed_history.un_title | capitalize}}</p>
								</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>

			<div class="" v-else>
				<div v-if="education_history_v1.length >0" class="boxed-list  single-page-section">

					<div class="boxed-list-headline">
						<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-line-awesome-graduation-cap"></i> Education History</h3>
					</div>
					<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
						<li v-for="ed_history in education_history_v1">
							<div class="boxed-list-item" v-if="ed_history.qualification_name">
								<!-- Avatar -->
								<!-- <div class="item-image">
									<img :src= "webUrl+'assets/images/logo.png'" alt="">
								</div> -->
								
								<!-- Content -->
								<div class="item-content">
									<h4>{{ed_history.qualification_name | capitalize}}</h4>
									<div class="item-details margin-top-7">
										<div class="detail-item" v-if="ed_history.university_address"><i class="icon-material-outline-business"></i> {{ed_history.university_address == 'Other' ? ed_history.university_address : ed_history.university_address | capitalize}}</div>
										<!-- <div class="detail-item" v-if="ed_history.from_year && ed_history.to_month"><i class="icon-material-outline-date-range"></i>{{ed_history.from_year | educationMonthYear}} - {{ed_history.to_month | educationMonthYear}}</div> -->
										<div class="detail-item" v-if="ed_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="ed_history.from_year">{{ed_history.from_year}} - </span> <span>{{ed_history.to_year}}</span></div>
									</div>
									<div class="item-description">
										<p>{{ed_history.university_name  == 'Other' ? ed_history.university_name : ed_history.university_name | capitalize}}</p>
									</div>
								</div>
							</div>
							<div class="content text-center" v-else>
								<p><b>No Record Found</b> </p>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div class="boxed-list  single-page-section" v-if="ft_preferenes.length > 0">
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-line-awesome-graduation-cap"></i> Full Time Job Preferences</h3>
				</div>

				<ul class="color_prp_new common_edu_clor">
					<li v-for="prefer in ft_preferenes">
						<div v-if="prefer.id">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->
							
							<!-- Content -->
							<div class="item-content">
								
								<span>{{prefer.name | capitalize}}</span>
								
							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
				
			</div>


            <!--<div class="boxed-list margin-bottom-60" v-if="education_history.length > 0">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-business"></i> Education History</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li v-for="ed_history in education_history">
						<div class="boxed-list-item">
							 Avatar 
							<div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div>
							
							 Content 
							<div class="item-content">
								<h4>{{ed_history.eq_title}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item"><i class="icon-material-outline-business"></i> {{ed_history.fs_title}}</div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i>{{ed_history.from_year | educationMonthYear}}</div>
								</div>
								<div class="item-description">
									<p>{{ed_history.un_title}}</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div> -->
			<!-- Boxed List / End -->

            <!-- Boxed List -->
			<!-- <div class="boxed-list margin-bottom-60">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-thumb-up"></i> Reviews</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Doing things the right way <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
										<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span>
									</div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> August 2019</div>
								</div>
								<div class="item-description">
									<p>Great company and especially ideal for the career-minded individual. The company is large enough to offer a variety of jobs in all kinds of interesting locations. Even if you never change roles, your job changes and evolves as the company grows, keeping things fresh.</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Outstanding Work Environment <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
									<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span></div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> May 2019</div>
								</div>
								<div class="item-description">
									<p>They do business with integrity and rational thinking. Overall, it's an excellent place to work, with products that are winning in the marketplace.</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div> -->

				

				<!-- <div class="centered-button margin-top-35">
					<b-button variant="success" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="changeUserStatus('approve', items.user_id)">Approve</b-button>&nbsp;
					<b-button variant="danger" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="openModel('reject', items.user_id)">Reject</b-button>
				</div> -->

			<!-- Boxed List / End -->
		</div>

		

		<!-- Sidebar -->
		<div class="col-xl-4 col-lg-4 new_sidebar_section_correct_vw single_right_sidebar tab_max_width_and_flex new_sidebar_i_margin_tp">
			<div class="sidebar-container desktop_margin_bottom_zero">
				<!-- <div class="sidebar-widget"><a href="#small-dialog" class="apply-now-button popup-with-zoom-anim margin-bottom-50 msg_btn"><i class="fa fa-envelope new_letter" aria-hidden="true"></i>Send a Message </a></div> -->
				<!-- <div class="sidebar-widget margin-bottom-25"><a class="mobile_margin_bottom_zero apply-now-button popup-with-zoom-anim margin-bottom-35 msg_btn view_daily_paid_profile_btn" @click="dailyPaidProfile((items.user_id),$event)">View Daily Paid Profile </a></div> -->
				
            
                <!-- <div class="sidebar-widget" v-if="social_profile.length > 0 ? social_profile[0].url || social_profile[1].url ||  social_profile[2].url||  social_profile[3].url || social_profile[4].url : false">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile[0].url"><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile[1].url"><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="social_profile[2].url"><a :href="social_profile[2].url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li v-if="social_profile[3].url"><a :href="social_profile[3].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile[4].url"><a :href="social_profile[4].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div> -->
                <!-- Profile Overview 
				<div class="profile-overview">
					<div class="overview-item" v-if="expected_salary"><strong>${{expected_salary}}</strong><span style="white-space: nowrap;">Expected Salary</span></div>
					<div class="overview-item"><strong>53</strong><span>Jobs Done</span></div>
					<div class="overview-item"><strong>22</strong><span>Rehired</span></div>
				</div>-->

				<!-- Button -->
				<!-- <a href="#small-dialog" class="apply-now-button popup-with-zoom-anim margin-bottom-50">Make an Offer <i class="icon-material-outline-arrow-right-alt"></i></a> -->

				<!-- Freelancer Indicators 
				<div class="sidebar-widget">
					<div class="freelancer-indicators">-->

						<!-- Indicator 
						<div class="indicator">
							<strong>88%</strong>
							<div class="indicator-bar" data-indicator-percentage="88"><span></span></div>
							<span>Job Success</span>
						</div>-->

						<!-- Indicator 
						<div class="indicator">
							<strong>100%</strong>
							<div class="indicator-bar" data-indicator-percentage="100"><span></span></div>
							<span>Recommendation</span>
						</div>-->
						
						<!-- Indicator 
						<div class="indicator">
							<strong>90%</strong>
							<div class="indicator-bar" data-indicator-percentage="90"><span></span></div>
							<span>On Time</span>
						</div>	-->
											
						<!-- Indicator 
						<div class="indicator">
							<strong>80%</strong>
							<div class="indicator-bar" data-indicator-percentage="80"><span></span></div>
							<span>On Budget</span>
						</div>
					</div>
				</div>-->
				
				<!-- <div class="sidebar-widget" v-if="skillArr.length > 0">
					<h3>Skills</h3>
					<div class="task-tags">
						<span v-for="skills in skillArr">{{skills}}</span>
					</div>
				</div> -->
				<div class="sidebar-widget common_side_bar_sections_margin desktop_margin_top_zero jobseeker_dp_sidebar mobile_margin_top_zero">
                <div class="job-overview">
                    <div class="job-overview-headline font_weight_600">Personal Detail</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li><i class="icon-material-outline-email"></i><span>Email</span><h5 class="common_word_break">{{items.email}}</h5></li>
                                <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span>Mobile Number</span><h5>+65-{{items.mobile_number}}</h5></li>
									<!-- <datepicker v-model="items.dob" :format="customFormatterTwo" :minimumView="'day'" :maximumView="'year'" id="fromyear"  :disabled="true"></datepicker> -->

								<li><i class="icon-line-awesome-birthday-cake"></i><span>Date Of Birth</span>
                                <!-- <h5 v-if="items.dob != null">
									{{items.dob | customFormatterDob}} <span class="display_inline">({{items.dob | calculateAge}} Yrs)</span></h5> -->
								<h5 v-if="items.dob != null">XX XXX {{items.dob | customFormatterDob}} <span class="display_inline">({{items.dob | calculateAge}} Yrs)</span></h5>
                                
                                </li>
								<li><i class="material-icons-outlined">picture_in_picture_outlined</i><span>Gender</span><h5>{{items.gender | capitalize}}</h5></li>
                                <li><i class="icon-line-awesome-flag"></i><span>Nationality</span>
                                <h5 v-if="items.nationality != null">
                                    <b-form-select v-model="items.nationality" class="custom-select">
                                        <option value="" disabled>Select</option>
                                        <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.country_name}}</option>
                                    </b-form-select>
                                </h5>
                                </li>
                                <li><i class="material-icons-outlined">south_america_outlined</i><span>Race</span>
                                <h5 v-if="items.race_id != null">
                                    <b-form-select data-size="7" title="Select Race"  v-model="items.race_id" class="custom-select" id="nation" disabled>
                                        <option value="" disabled>Select</option>
                                        <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>
                                    </b-form-select>
                                </h5>
                                </li>
							</ul>
                        </div>
                    </div>
                </div>
				<!-- Widget -->
				<div class="sidebar-widget common_side_bar_sections_margin mobile_display_inline_width margin-bottom-25" v-if="cvUrl != ''">
					<h3 class="font_weight_600">Attachments</h3>
					<div class="attachments-container desktop_margin_bottom_zero">
                        <a :href="cvUrl" target="_blank">
                            <div class="attachment-box ripple-effect desktop_margin_bottom_zero" v-show="showCV">
                                <span>Cover Letter</span>
                                <i v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
								<i v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'">IMAGE</i>
								<i v-else>DOC</i>
                            </div>
                        </a>
						
						<!-- <a :href="cvUrl" class="attachment-box ripple-effect"><span>Cover Letter</span><i>PDF</i></a> -->
						<!-- <a :href="cvUrl" class="attachment-box ripple-effect"><span>Contract</span><i>DOCX</i></a> -->
					</div>
					<!-- Added on 28 june -->
							<div class="file_name_full_div detail_cv_admin">
								<span class="single_span">File Name</span>
								<span class="file_name_clr">{{items.last_name | capitalize}} {{items.first_name | capitalize}} - <span v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'" class="file_name_clr">Resume.pdf</span>
								<span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png'" class="file_name_clr">Resume.png</span>
								<span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg'" class="file_name_clr">Resume.jpg</span>
								<span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'" class="file_name_clr">Resume.jpeg</span>
								<span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='docx'" class="file_name_clr">Resume.docx</span>
								<span v-else class="file_name_clr">Resume.doc</span>
								</span>
							</div>
							<span class="single_span">Uploaded</span>
							<span class="file_name_clr">{{cv_detail}}</span>
							<span></span>
					<!-- Added on 28 june -->
				</div>
                <div class="sidebar-widget common_side_bar_sections_margin new_bookmark_share_btn desktop_margin_bottom_zero">
					<h3 class="font_weight_600">Bookmark</h3>

					<!-- <button class="bookmark-button margin-bottom-25">
						<span class="bookmark-icon"></span>
						<span class="bookmark-text">Bookmark</span>
						<span class="bookmarked-text">Bookmarked</span>
					</button> -->
					
					<b-form-checkbox buttons class="desktop_margin_bottom_zero bookmark-button margin-bottom-25 mobile_margin_bottom_zero" id="bookmark" v-model="bookmarkJobseeker" @click.native="bookmarkCurrentJobseeker($event)" :disabled="true">
                            <span class="bookmark-icon"></span>
                            <span class="bookmarked-text" v-if="bookmarkJobseeker == this.id">Bookmarked</span>
                            <span class="bookmark-text" v-if="bookmarkJobseeker == false">Bookmark</span>
                    </b-form-checkbox>
					<!-- <div class="star_icon">
                    <ul>
                    <b-form-checkbox :value="this.id" v-model="bookmarkJobseeker" buttons @click.native="bookmarkCurrentJobseeker($event)" :disabled="true"></b-form-checkbox>
					</ul>
					</div> -->
					<!-- <div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border">
						<button class="copy-url-button ripple-effect" @click="copyURL()"  data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
								<li><a href="#" data-button-color="#3b5998" title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							</ul>
						</div>
					</div> -->
				</div> 
				<!-- Sidebar Widget -->
				<!-- <div class="sidebar-widget">
					<h3>Bookmark or Share</h3>

					<button class="bookmark-button margin-bottom-25">
						<span class="bookmark-icon"></span>
						<span class="bookmark-text">Bookmark</span>
						<span class="bookmarked-text">Bookmarked</span>
					</button>

					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border">
						<button class="copy-url-button ripple-effect" data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
								<li><a href="#" data-button-color="#3b5998" title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							</ul>
						</div>
					</div>
				</div> -->

			</div>
		</div>

	</div>
</div>


<!-- Spacer -->
<!-- <div class="margin-top-15"></div> -->
<!-- Spacer / End-->

</div>
<!-- Wrapper / End -->



</template>

<script>

import { POST_API,GET_API } from '../../store/actions.type'
import moment from "moment";
import StarRating from 'vue-star-rating'
import Datepicker from 'vuejs-datepicker';


export default {

    data() {
        return {
            fields: [
                { key: 'name', label: 'NAME & EMAIL', sortable: true },
                { key: 'mobile_number', label: 'MOBILE NUMBER', sortable: true   },
                { key: 'created_at', label: 'CREATED ON', sortable: true   },
                { key: 'status', label: 'STATUS', sortable: true },
                { key: 'toggleAction', label: 'ACTION', }
            ],
            id: atob(this.$route.params.id),
            pager: {},
            pageOfItems: [],
            items:[],
            work_history:[],
            education_history:[],
            ft_preferenes:[],

            education_history_v1:[],

            employment_history:[],
            employment_history_v1:[],

            social_profile:[],
			bookmarkJobseekerVal:'',
			bookmarkJobseeker:[],
            totalRows: 0,
			age:0,
            from: '',
            to: '',
			expected_salary:'',
			cv_detail:'',
			skillArr:[],
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,20,30,40],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: false,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:10,
            },
            showCV:'',
            cvUrl:'',
			countries:[],
            race:[],
        }
    },
    filters: {
		customFormatterTwo(date) {
            	return moment(date).utc().format('DD MMM YYYY');
        },
		customFormatterDob(date) {
            	return moment(date).format('YYYY');
        },
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD MMM YYYY')
        },

		jobMonth: function(date) {
			return moment(date, 'M').format('MMMM');
        },
		
		educationMonthYear: function(date) {
            return moment(date).format('MMM YYYY')
        },
		calculateAge: function(value) {
			if (!value) return ''
			return moment().diff(value, 'years')
			// return  moment(value, "YYYY-MM-DD").month(0).from(moment().month(0))
		},
    },
    components: {
      StarRating,
	  Datepicker
    },
    methods:{
		breadCrumb(){
                var item = [{
                   to:{name:'manage-jobs'},
                    text: 'Manage Job Ads',
                },{
					to: null,
					text: 'View Profile',
                }];
                return item;
        },
		 async copyURL() {
                    try {
                        
                        let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");
                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        }); 
                        // alert('Copied to Clipboard.');
                    } catch($e) {

                        console.log($e);
                        
                        // alert('Cannot copy');
                    }
              },
			customFormatterTwo(date) {
            	return moment(date).utc().format('DD MMM YYYY');
        	},
		showAlert() {
			this.$swal({
				position: 'center',
				// icon: 'error',
				imageUrl: '/assets/images/404_elcy.gif',
				customClass:{
					container: 'mascot_error_container',
				},
				title: this.error_message,
				showConfirmButton: false,
				timer: 1500
			})
		},
		showSuccessAlert(message) {
			this.$swal({
				position: 'center',
				icon: 'success',
				iconColor:'#4c8bf5',
				title: message,
				showConfirmButton: false,
				timer: 1500
			});
		},
		getCountryCode() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/countries'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.countries = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },

        getrace() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/race'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.race = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },
        getJobseekers() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.id,
						user_id: this.form.user_id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/jobseeker-profile-view-emp'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
						var checked = [];
                        this.items = this.$store.getters.getResults.user;
                        this.cv_detail = this.$store.getters.getResults.cv_detail;
						this.expected_salary = this.$store.getters.getResults.user.expected_salary? this.$store.getters.getResults.user.expected_salary : '';
                        this.education_history = this.$store.getters.getResults.education_history;
                        this.education_history_v1 = this.$store.getters.getResults.education_history_v1;

                        this.ft_preferenes = this.$store.getters.getResults.ft_preferences;


                        this.employment_history = this.$store.getters.getResults.employment_history;
                        this.employment_history_v1 = this.$store.getters.getResults.employment_history_v1;

                        this.social_profile = this.$store.getters.getResults.social_profile;
                        this.showCV = (this.$store.getters.getResults.user.cv != '' && this.$store.getters.getResults.user.cv != null) ? true : false;
                        this.cvUrl = (this.$store.getters.getResults.user.cv != '' && this.$store.getters.getResults.user.cv != null) ? this.$store.getters.getResults.user.cv : '';
                        this.work_history = this.$store.getters.getResults.work_history;
                        // this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        // this.perPage = this.$store.getters.getResults.pager.pageSize;
                        // this.from = this.$store.getters.getResults.pager.from;
                        // this.to = this.$store.getters.getResults.pager.to;
						this.skillArr = this.items.skills ? this.items.skills.split(',') : [];

						this.age = moment().diff(this.items.dob, 'years');


                        if(this.items.bookmark =='yes'){
                            checked.push (this.items.bookmark_to);
							$('#bookmark').parent().addClass('bookmarked');
                        }
                        this.bookmarkJobseeker = checked;
                        // return this.items;
                    }
                });
        },
		dailyPaidProfile(id,event){
                if (event) {
                event.stopPropagation()
                }
                window.location.replace('/v2/employer/jobseeker-dp-profile/view/'+ btoa(id));
            },
		bookmarkCurrentJobseeker(event){
                if (event) {
                event.stopPropagation()
                }
                return this.$store.dispatch(POST_API, {
                        data:{
                            bookmark_to: this.id,
                            bookmark_by: this.form.user_id
                    },
                    api: '/api/bookmark-user'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.bookmarkJobseekerVal = this.$store.getters.getResults.bookmarkStatus
                        var message = this.$store.getters.getResults.message
                        if (this.bookmarkJobseekerVal == 'yes') {
                            this.bookmarkJobseeker = true;
							$('#bookmark').parent().addClass('bookmarked');
                        } else {
                            this.bookmarkJobseeker = false;
							$('#bookmark').parent().removeClass('bookmarked');

                        }
                        this.showSuccessAlert(message);
                        this.getJobseekers()
                    }
                });
            },
    },
    mounted(){
		$(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
		//document.title = "EL Connect - Employer - Jobseeker Profile";
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        // $('#copy-url').val(window.location.href);
		 $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
        this.getJobseekers();
        this.getrace();
        this.getCountryCode();
    }
}
</script>
