<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Freelancer Incentives Details</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Freelancer Incentives Details</li>
            </ul>
          </nav>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <!-- <b-input-group> -->
              <!-- <br/> -->
              <b-input-group-prepend class="common_bg_white">
                <b-form-input
                  id="searchBtn"
                  @keyup.enter.native="filter = searchKeyword"
                  v-model="searchKeyword"
                  placeholder="Search by keyword"
                  class="common_bg_white"
                ></b-form-input>
                <b-button class="btn points_events"
                  ><i class="fa fa-search common_bg_white" @click="filter = searchKeyword"></i
                ></b-button>
              </b-input-group-prepend>
              <!-- </b-input-group> -->
            </div>
          </div>
          <div
            class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex col-md-8 col-xl-8 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
          >
            <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn"> -->
            <!-- <b-button @click="changeUserIncentiveFilter()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero desktop_margin_right_zero" style="width: 120px;">Search</b-button> -->
            <b-button
              class="btn desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e btn-secondary mr-3"
              @click="exportUserIncentives()"
              v-if="show('export')"
              >Export</b-button
            >
            <!-- </div> -->
          </div>
          <div class="col-xl-5 col-md-5 search_common_margin_bottom_28 mobile_tab_max_width_flex">
            <label
              class="mobile_label big_screen_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero"
              >Created Year:</label
            >
            <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
              <datepicker
                v-model="year"
                @input="changeUserIncentiveFilter"
                format="yyyy"
                minimum-view="year"
                year-picker
                class="add_calender_icon common_normal_calender_height freelancer_incentive_calender freelancer_incentive_big_calender"
              ></datepicker>
            </b-form-group>
          </div>
          <!-- <div class="col-xl-4 col-md-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <label class="mobile_label big_screen_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Created Month:</label>                                
                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">   
                            <datepicker v-model="month" @input="changeUserIncentiveFilter" format="MMMM" minimum-view="month" month-picker class="add_calender_icon common_normal_calender_height freelancer_incentive_calender freelancer_incentive_big_calender"></datepicker>     
                        </b-form-group>
                    </div> -->
          <div
            class="col-xl-5 col-md-5 search_common_margin_bottom_28 mobile_tab_max_width_flex admin_freelancer_select"
          >
            <label
              class="freelancer_label_admin mobile_label big_screen_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero"
              >Created Month:</label
            >
            <select
              v-model="month"
              class="common_border_search remove_box_shadow desktop_margin_bottom_zero form-control date_style_size"
              @change="changeUserIncentiveFilter"
            >
              <option value="" disabled>Select</option>
              <option
                :value="month_val.substr(0, 3)"
                v-for="(month_val, id) in all_month_arr"
                :key="id"
              >
                {{ month_val }}
              </option>
            </select>
          </div>
          <div
            class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex col-md-2 col-xl-2 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
          >
            <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn"> -->
            <!-- <b-button @click="changeUserIncentiveFilter()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero desktop_margin_right_zero" style="width: 120px;">Search</b-button> -->
            <b-button
              v-if="filter || searchKeyword || year || month"
              @click="clearUserIncentiveFilter()"
              class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
              style="background-color: red; margin-top: 20px;">
              <span class="mobile_reset">Reset filters</span></b-button
>
            <!-- </div> -->
          </div>
        </div>

        <!-- Page Content
                ================================================== -->

        <div class="row">
          <div class="container my_new_container show_filter_arrow">
            <b-table
              ref="datatable"
              show-empty
              striped
              hover
              responsive
              :items="getUserIncentiveData"
              :fields="fieldsUserIncentiveData"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 nine_grid_tbl_new"
            >
              <template #cell(last_name)="items">
                <p v-if="items.item.user_type_id == 5">
                  <b-link
                    :to="
                      showFreelancer('view profile')
                        ? {
                            name: 'jobseeker-dp-profile-view',
                            params: { id: encrypt(items.item.user_id) },
                          }
                        : null
                    "
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                    {{ items.item.user_last_name | capitalize }}
                    {{ items.item.user_first_name | capitalize }}
                  </b-link>
                </p>
                <p v-else>
                  <b-link
                    :to="
                      showEmployer('view profile')
                        ? {
                            name: 'employer-profile-view',
                            params: {
                              id: encrypt(items.item.user_id),
                              company_id: encrypt(items.item.company_id),
                            },
                          }
                        : null
                    "
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                    {{ items.item.user_last_name | capitalize }}
                    {{ items.item.user_first_name | capitalize }}
                  </b-link>
                </p>
              </template>

              <template #cell(document)="items">
                <p v-if="items.item.document">
                  <a :href="items.item.document" target="_blank"
                    ><img :src="webUrl + 'assets/images/pdf.png'" height="80" width="80"
                  /></a>
                  <!-- <a :href="items.item.document" target="_blank"><img :src="items.item.document" height="80" width="80"/></a> -->
                </p>
              </template>
              <template #cell(incentive_type)="items">
                {{ (items.item.range_type ? items.item.range_type : "") | capitalize }}
              </template>

              <template #cell(achievement)="items">
                {{ items.item.achievement ? items.item.achievement : "" }} Jobs
              </template>
              <template #cell(created_at)="items">
                {{ (items.item.created_at ? items.item.created_at : "") | customFormatterOne }}
              </template>
              <template #cell(month)="items">
                {{ items.item.user_incentives_month ? items.item.user_incentives_month : "" }}
              </template>
              <template #cell(amount)="items">
                {{ items.item.amount ? "S$" + items.item.amount : "S$0" }}
              </template>
            </b-table>

            <nav class="pagination-box custom_pagination_border_radius">
              <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                  <span class="d-inline-block">Rows per page</span>
                  <span class="d-inline-block"
                    ><b-form-select v-model="form.rowsPerPage" @change="getUserIncentiveData">
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                    </b-form-select></span
                  >
                </b-form-group>
              </div>
              <div class="total-page-count d-inline-block">
                <ul>
                  <li>
                    <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                  </li>
                </ul>
              </div>
              <b-pagination
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                prev-text=""
                next-text=""
                hide-goto-end-buttons
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../../../store/actions.type";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Datepicker from "vuejs-datepicker";
import permission from "../../../../../../server/permission.js";

export default {
  data() {
    return {
      fieldsUserIncentiveData: [
        { key: "last_name", label: "PTuser", sortable: true },
        { key: "incentive_type", label: "Incentive Type", sortable: true },
        { key: "achievement", label: "Achievement", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        { key: "month", label: "Month", sortable: false },
        { key: "created_at", label: "Created On", sortable: true },
      ],
      pager: {},
      pageOfItems: [],
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      year: moment().format("YYYY"),
      // month: moment().format('MMM YYYY'),

      month: new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString("default", {
        month: "short",
      }),
      perPage: 25,
      pageOptions: [25, 50, 75, 100],

      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
      },
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      disabledFrom: {
        to: new Date(Date.now() - 8640000),
      },
      all_month_arr: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      actions: "",
      actionsFreelancer: "",
      actionsEmployer: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    moment: function () {
      return moment();
    },
  },

  components: {
    Datepicker,
  },
  methods: {
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.getUserIncentiveData().then(() => {
          this.$refs.datatable.refresh();
        });
      });
    },
    customFormatterOne(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    changeUserIncentiveFilter() {
      this.getUserIncentiveData().then(() => {
        this.$refs.datatable.refresh();
      });
    },
    clearUserIncentiveFilter() {
      this.filter = "";
      this.searchKeyword = "";
      this.year = "";
      this.month = "";
      this.$refs.datatable.refresh();
    },

    exportUserIncentives() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      // var month= this.month ? moment(this.month).format('MM') : null;
      var month = this.month ? this.month : null;
      var year = this.year ? moment(this.year).format("YYYY") : null;
      var sortBy = this.sortBy ? this.sortBy : "user_incentives.id";
      var sortDirection = this.sortDesc ? "desc" : "asc";
      var keyword = this.searchKeyword ? this.searchKeyword : null;

      var export_url =
        "/api/user-incentive-admin-excel/" +
        keyword +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        month +
        "/" +
        year +
        "/" +
        sortBy +
        "/" +
        sortDirection;
      window.open(base_url + export_url, "_blank");
    },

    getUserIncentiveData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.searchKeyword,
            // month:this.month ? moment(this.month).format('YYYY-MM-DD') : '',
            month: this.month ? this.month : "",
            year: this.year ? moment(this.year).format("YYYY-MM-DD") : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "user_incentives.id",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/user-incentive-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "User Incentives");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
          this.actionsEmployer = permission.getPermissionStaff(menu, "Companies");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showEmployer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsEmployer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.permissionStaff();
  },
};
</script>
