<template>
        <div class="left_side_div_sinpass">
				<div class="top_bubble_div_singpass">
					<img :src="webUrl+'assets/images/Ellipse-43.png'">
				</div>
				<!-- <div class="custom_container_singpass">
					<div class="logo_singpass">
						<img :src="webUrl+'assets/images/NewLogo-4.png'">
					</div>
				</div> -->
				<div class="middle_bubble_div_singpass">
					<img :src="webUrl+'assets/images/Ellipse-44.png'">
				</div>
				<div class="custom_container_singpass">
					<div class="row">
						<div class="col-md-12">

							<h1 class="wlcome_singpass_txt">Welcome to EL Connect</h1>
							<p>Unlock your earnings at EL Connect job platform, where everyday is payday!</p>
						</div>
					</div>
				</div>
				<div class="custom_container_singpass go_back_div" :class="$route.fullPath=='/singpass-verify-email' || $route.fullPath=='/singpass-verify-mobile' || $route.fullPath=='/singpass-login' ? 'go_back_btn_opacity_available' : 'go_back_btn_opacity_not_available'">
					<div class="row">
						<div class="col-md-12">
						 <div class="image_with_txt_singpass">
								<div class="go_back_anchor">
									<a href="#">Go Back</a>
								</div>
								<div class="bottom_image_div_singpass">
									<img :src="webUrl+'assets/images/Ellipse-45.png'">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
</template>

<script>

 export default {
        data() {
            return {
                webUrl: process.env.VUE_APP_URL,
            }
        }
    }
</script>
