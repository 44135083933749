
<template>



    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->



                <div class="row" >
                    <div class="col-xl-12 col-lg-12 content-right-offset">


                <!-- Hedaline -->
                <h1>Add Billing Address</h1>


                <!-- Billing Cycle Radios  -->
                <div class="billing-cycle margin-top-25">

                    <!-- Radio -->

                    <div class="content">
                                <ul class="fields-ul chek_page second_indiv_step_wrap">
                                <li>
                                    <div class="row">


                                        <div>
                                        </div>
                                         <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Full Name <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                    type="text"
                                                    v-model="name"
                                                    id="full_name"
                                                    class="input-text with-border required-field"
                                                    name="name"
                                                    placeholder=""
                                                    value=""
                                                />
                                            </div>
                                             <div class="show_span">
                                                <span class="error_x_white">Please enter Name </span>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Email <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                    type="text"
                                                    v-model="email"

                                                    id="email"
                                                    class="input-text with-border required-field"
                                                    name="name"
                                                    placeholder=""
                                                    value=""
                                                />
                                            </div>
                                             <div class="show_span">
                                                <span class="error_x_white">Please enter Email </span>
                                            </div>
                                        </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Mobile Number <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="phone"

                                                        id="number"
                                                        class="input-text with-border required-field"
                                                        name="office_address"
                                                        placeholder=""
                                                        value=""
                                                        maxlength="8"
                                                    />
                                                </div>
                                                <div class="show_span">
                                                <span class="error_x_white">Please enter Mobile number </span>
                                            </div>
                                            </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Postal Code <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="code"

                                                        id="code"
                                                        class="input-text with-border required-field"
                                                        name="office_address"
                                                        value=""
                                                        maxlength="6"
                                                    />
                                                </div>
                                                <div class="show_span">
                                                <span class="error_x_white">Please enter Postal Code </span>
                                            </div>
                                            </div>

                                            <div class="col-xl-12">
                                                <div class="submit-field">
                                                    <h5>Address 1 <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                        type="text"
                                                    v-model="address"

                                                        id="address"
                                                        class="input-text with-border required-field"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                               <div class="show_span">
                                                <span class="error_x_white">Please enter Address </span>
                                            </div>
                                            </div>



                                            <div class="col-xl-12">
                                                <div class="submit-field">
                                                    <h5>Address 2</h5>
                                                    <b-form-input
                                                        type="text"
                                                    v-model="address2"

                                                        id="maxSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>City <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                        type="text"
                                                    v-model="city"

                                                        id="city"
                                                        class="input-text with-border required-field"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                                <div class="show_span">
                                                <span class="error_x_white">Please enter City </span>
                                            </div>
                                            </div>

                                        <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Country <span class="required_star">*</span></h5>
                                                      <b-form-select v-model="country" id="country">
                                                        <option value="" disabled>Select Country</option>
                                                        <option :value="country.id" v-for="country in countries">{{country.country_name}}</option>
                                                    </b-form-select>
                                                </div>

                                                <div class="show_span">
                                                    <span class="error_x_white">Please select Country </span>
                                                </div>
                                            </div>


                                    </div>

                                </li>
                            </ul>
                            </div>


                    <!-- Radio -->

                </div>
              </div>

               <div class="col-xl-12 post_jb_btn ">
                    <a @click="submit" class="button ripple-effect big margin-top-30 employee_btn mobile_last_btn_mrgn" style="background:#4c8bf5 !important">Submit</a>
                    <a  @click="$router.go(-1)" class="button ripple-effect big margin-top-30 employee_btn" style="background:black !important">Cancel</a>
                </div>


                    <!-- Summary -->
                </div>

    </div>

</template>

<script>
  import { POST_API } from "../../../store/actions.type"

  export default {

  data: () => {
    return {
             formPosition: 0,
             page : 1,
             coins: '',
             coinData:'',
             packageData:'',
             total_amount : 0,
            loading: false,

             cardHolderName:'',
            cardNumber:'',
            expiryMonth:'',
            expiryYear: '',
            cvv:'',
            name:'',
            email:'',
            phone:'',
            code:'',
            address:'',
            city:'',
            country:'',
            elcoinDatabyid:'',
            settings:'',
            countries:''


            }},
  methods: {
     nextStep(){
      this.page = this.page+1;
     },

     back(){
      this.page = this.page-1;
     },

     getElCoins(){

           this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/elCoins'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.coins = this.$store.getters.getResults.data;
                        this.coinData =  this.coins[0].id,
                        this.packageData = this.coins[0].id,


                        this.getElCoinsById();


                    }
                });
     },

      getElCoinsById(){

           this.$store.dispatch(POST_API, {
                    data:{
                        id: this.coinData
                    },
                    api:'/api/elCoinsById'
                })
                .then(() => {

                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.elcoinDatabyid = this.$store.getters.getResults.data;

                         const percent = (this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100;

                         this.total_amount = percent + parseInt(this.elcoinDatabyid[0].value_in_dollar);

                    }
                });
     },

      showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            submit(){

                var err = false;

                 $('.required-field').each(function () {

                        if ($(this).val() == '' || $(this).val().trim().length === 0) {
                            err = true;
                            $(this).addClass('error');
                            $(this).parent().parent().find('.required_content').addClass('show');
                            $(this).parent().parent().find('.error_x_white').addClass('show');
                        } else {

                            $(this).removeClass('error');
                            $(this).parent().parent().find('.required_content').removeClass('show');
                            $(this).parent().parent().find('.error_x_white').removeClass('show');
                        }

                    });

                      $('#country').each(function () {

                if( !$('#country').val() ){
                            err = true;

                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });

                setTimeout(function ()
             {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show');
                    });
            }, 100);

            if(err == false){
                this.$store.dispatch(POST_API, {
                        data:{
                            user_id:this.user_id,
                            email:this.email,
                            fullname:this.name,
                            phone:this.phone,
                            code:this.code,
                            address:this.address,
                            address2:this.address2,
                            city:this.city,
                            country:this.country,
                            company_id: this.company_id,
                    },
                    api: '/api/storeBillingAddress'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        // var message = 'Job Bookmarked successfully.'
                        var message = this.$store.getters.getResults.message
                        this.showSuccessAlert(message);
                        this.loading = false;
                        window.location.replace('/v2/jobseeker/billing-address-list');
                    }
                });
            }

            },

            onChange(id){

                this.packageData = id,
                this.coinData = id,

                this.getElCoinsById();
            },

            show(id)
            {

                if(id == 'card'){
                   $('#cardDialogue').addClass('payment-tab-active');
                    $('#paypalData').removeClass('payment-tab-active');

                }else{
                    $('#paypalData').addClass('payment-tab-active');
                    $('#cardDialogue').removeClass('payment-tab-active');
                }
            },

        getSettings(){

           this.$store.dispatch(POST_API, {

                    api:'/api/settings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.settings = this.$store.getters.getResults.data;

                    }
                });
     },

      getCountry(){

           this.$store.dispatch(POST_API, {

                    api:'/api/countriesData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.countries = this.$store.getters.getResults.data;

                    }
                });
     },

    },

     mounted(){
            //document.title = "EL Connect - Jobseeker - Add Billing Address";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.email = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            this.getElCoins();
            this.getSettings();
            this.getCountry();
     }

    }
</script>
