<template>
    <div>

        <!-- Titlebar
        ================================================== -->
        <div id="titlebar" class="gradient signup_mrgns">
            <div class="container mobile_tab_container_hundered">
                <div class="row">
                    <div class="col-md-12">
                             <h1 class="common_center">Forget Password</h1>

                    </div>
                </div>
            </div>
        </div>


        <!-- Page Content
        ================================================== -->
        <div class="container mobile_tab_container_hundered">
            <div class="row">
                <div class="col-xl-5 common_block">

                        <!-- Welcome Text -->
                        <div class="welcome-text">

                            <span>Please enter your email address used for log in below and we will send you a password reset email to you shortly.</span>
                        </div>

                        <!-- Account Type -->
                    <b-form method="post" id="forgot-password-form" @submit.prevent="forgotPassword">

                        <!-- Form -->
                             <div class="account-type  mobile_tab_display_block mobile_tab_hundered_width">
                                <div class="mobile_tab_margin_right_zero pop_up_forget_top_dvx">
                                    <input type="radio" v-model="user_type_id" name="account-type-radio" id="freelancer-radios" class="account-type-radio" value="5" checked/>
                                    <label for="freelancer-radio" class="ripple-effect-dark"><i class="icon-material-outline-account-circle"></i> Jobseeker</label>
                                </div>

                                <div class="mobile_tab_margin_right_zero mobile_tab_display_inline mobile_tab_margin_top_12 pop_up_forget_top_dvx employer_change_to_pink">
                                    <input type="radio" name="account-type-radio" v-model="user_type_id" id="employer-radios" class="account-type-radio" value="3"/>
                                    <label for="employer-radio" class="ripple-effect-dark"><i class="icon-material-outline-business-center"></i> Employer</label>
                                </div>
                            </div>
                            <div class="input-with-icon-left">
                                <i class="icon-material-baseline-mail-outline"></i>
                                <b-form-input
                                    type="text"
                                    id="email"
                                    v-model="email"
                                    name="login-email"
                                    class="input-text with-border"
                                    placeholder="john@example.com"
                                    autofocus
                                />
                            </div>

                        </b-form>

                        <!-- Button -->
                        <button class="button desktop_width_hundered full-width button-sliding-icon ripple-effect margin-top-10" type="submit" form="forgot-password-form">Confirm <i class="icon-material-outline-arrow-right-alt"></i></button>

                        <!-- Direct to Login Text -->
                        <div class="welcome-text redirect_to_login">
                            <span v-if="user_type_id == 3">Click here to return to <b-link href="" :to="{name: 'login-employer'}">Log In</b-link></span>
                            <span v-else>Click here to return to <b-link href="" :to="{name: 'login'}">Log In</b-link></span>

                            
                        </div>
                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70 mobile_tab_margin_top_45"></div>
        <!-- Spacer / End-->
    </div>
</template>

<style scoped>
  @import '../../assets/css/style.css';
  @import '../../assets/css/icons.css';

  /* Color css  */

  @import '../../assets/css/colors/blue.css';

</style>
<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        metaInfo: {
            title: 'EL Connect - Forgot Password',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'Forgot Password - Click here to reset your password on our Daily Paid and Full Time job platform.' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                {property: 'og:title', content: 'EL Connect - Forgot Password'},
			    {property: 'og:site_name', content: 'EL Connect'},
			    {property: 'og:type', content: 'website'},
			    {property: 'og:url', content: 'https://elconnect.sg/forgotpassword'},
			    {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			    {property: 'og:description', content: 'Forgot Password - Click here to reset your password on our Daily Paid and Full Time job platform.'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/forgotpassword'}
  		    ]
        },
        data() {
            return {
                email: '',
                showDismissibleAlert:false,
                error_message:'',
                user_type_id:'5'
            }
        },
        methods:{
            forgotPassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        emailormobile: this.email,
                        user_type_id: this.user_type_id
                    },
                    api:'/api/forgot-password'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {

                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Reset Password link has been sent to your registered email.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            if(this.user_type_id == 5){
                                window.location.replace('/login-jobseeker');
                            }else{
                                window.location.replace('/login-employer');
                            }
                        });
                    }
                });
            },
        },
        mounted()
        {
            this.userTypeId = JSON.parse(localStorage.getItem('userTypeId')) ? JSON.parse(localStorage.getItem('userTypeId')) : '';
            this.user_type_id = this.userTypeId.user_type_id ? this.userTypeId.user_type_id : '5';
            // this.user_type_id = atob(this.$route.params.id) ? atob(this.$route.params.id) : '5';
            localStorage.removeItem('userTypeId')
            //document.title = "EL Connect - Forgot Password";
        }
    }
</script>
