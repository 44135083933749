<template>

    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->
        <div class="">
            <!-- <div class="dashboard-headline">
                <div class="back_arrow_icon new_bak_arr">
                    <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Post Job</h1>
                </div>
                <nav id="breadcrumbs" class="dark employer_nav">
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Job</a></li>
                        <li>post Job</li>
                    </ul>
                </nav>
            </div> -->
        <div id="breadcrumb-v2" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
                <b-breadcrumb
                :items="breadCrumb()"
                />
            </div>
        </div>
        <div class="notification warning" v-if="acStatus == 'pending' || acStatus == 'incomplete'">
            <!-- <p><span class="icon-feather-info"></span> Account status {{acStatus}}. Jobs can be posted after profile completion.</p> -->
            <p class="offers_desktop"><span class="icon-feather-info"></span> EL Connect offers a dynamic solution for hirers – Temp. Staff On-Demand Manpower Services. Experience the ease of instantly accessing freelancers, without the traditional hiring process. Benefit from cost-efficiency, reliability, and the convenience of daily pay. <a :href="'https://wa.link/k7p8ww'" target="_blank">Contact us</a>  to know more.</p>
            <div class="offers_mobile">
                <p>
                    <span class="icon-feather-info"></span> EL Connect offers a dynamic solution for hirers – Temp. Staff On-Demand Manpower Services.</p>
                    <p>Experience the ease of instantly accessing freelancers, without the traditional hiring process.</p>
                    <p>Benefit from cost-efficiency, reliability, and the convenience of daily pay.</p>
                    <p><a :href="'https://wa.link/k7p8ww'" target="_blank">Contact us</a>  to know more.</p>
            </div>
            <a class="close"></a>
        </div>

         <div class="notification warning" v-else-if="companyAdminDPStatus != 'completed'">
            <p><span class="icon-feather-info"></span> Company Account status {{companyAdminDPStatus}}. Jobs can be posted after profile completion.</p>
            <a class="close"></a>
        </div>
        <div class="notification warning" v-else-if="companyAdminPostingPaused == 'yes'">
            <!-- <p><span class="icon-feather-info"></span> Account status {{acStatus}}. Jobs can be posted after profile completion.</p> -->
            <p class="offers_desktop"><span class="icon-feather-info"></span> Your account has been frozen . <a :href="'https://wa.link/r0qtxs'" target="_blank">Contact us</a>  to know more.</p>
            <div class="offers_mobile">
                <p>
                    <span class="icon-feather-info"></span> Your account has been frozen.</p>
                    <p><a :href="'https://wa.link/k7p8ww'" target="_blank">Contact us</a>  to know more.</p>
            </div>
            <a class="close"></a>
        </div>
           <div class="row">
            <b-form method="post" id="register-account-form" class="nw_frm">
                <!-- <p id="error"></p> -->
				<!-- Dashboard Box -->

                <div class="col-xl-12">
                    <div class="dashboard-box desktop_margin_top_zero">

                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> Job Submission Form</h3>
                        </div>

                        <div class="content">
                            <ul class="fields-ul second_indiv_step_wrap">
                                <li class="common_padding_30 common_input_field">
                                    <div class="row">
                                        <div class="col-xl-12 cat_inner_divs">
                                            <div class="submit-field error_submit_field">
                                                <h5>Job Template</h5>
                                                <b-form-select v-model="selectedJobTemplate" id="jbs_template" class="jbs_template" @change="templateChangeHandler()">
                                                    <option value="" disabled>Select Template</option>
                                                    <option :value="jobTemplate" v-for="jobTemplate in jobTemplates">{{jobTemplate}}</option>
                                                </b-form-select>
                                            </div>
                                        </div>
                                         <div class="col-xl-5 cat_inner_divs">
                                            <div class="submit-field error_submit_field">
                                                <h5>Job Category <span class="required_star">*</span></h5>
                                                <b-form-select v-model="form.service_categories_id" id="jbs_category" class="jbs_category" @change="showCategory(form.service_categories_id)" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                    <option value="" disabled>Select Category</option>
                                                    <option :value="category.id" v-for="category in categories">{{category.name |capitalize}}</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Job Category !</span>
                                        </div>
                                        <div class="col-xl-12 submit-field cat_inner_divs fb_category_class">
                                            <div class="submit-field desktop_margin_bottom_15" v-if="form.service_categories_id == fb_category_id">
                                                <div class="employer_multiple_radio">
                                                <b-form-checkbox value="yes" unchecked-value="no"  v-model="form.is_hygiene_cert_req" @input="hyiegne_cert($event)"><strong>Food Safety Certificate Required</strong></b-form-checkbox>
                                            </div>
                                            </div>
                                            <div class="" v-if="form.service_categories_id == fb_category_id">
                                                <div class="employer_multiple_radio">
                                                <b-form-checkbox value="yes" unchecked-value="no" v-model="form.medical_history" @input="medical_hist($event)"><strong>With No Medical History</strong></b-form-checkbox>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-12">
                                            <div class="submit-field error_submit_field">
                                                <h5>Job Title <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.title"
                                                    id="company_reg_no"
                                                    class="input-text with-border required-field"
                                                    name="company_reg_no"
                                                    placeholder=""
                                                    value=""
                                                    :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Job Title !</span>
                                        </div>

                                        <div class="col-xl-12">
                                            <div class="submit-field error_bg_white error_submit_field">
                                                <h5>Job Scope <span class="required_star">*</span> <span class="eye_span_post"><i class="fa fa-eye-slash  job_scope_eye_pass" id="show_job_scope_data" @click="JobScopeEyeReveal" style="cursor:pointer;"></i></span></h5>
                                                <!-- <ckeditor v-model="form.scope" :config="editorConfig" id="jb_description1" class="jb_description1"></ckeditor>  -->
                                                    <textarea type="text" class="job_scope_toggle with-border required-field" value="" v-model="form.scope" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'" :class="{ 'textarea_dsb': acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes' }"></textarea>
                                            </div>
                                                <span class="error_x_white">Please enter Job Scope !</span>
                                        </div>

                                        <div class="col-xl-12">
                                            <div class="submit-field error_bg_white error_submit_field">
                                                <h5>Job Requirement <span class="required_star">*</span> <span class="eye_span_post"><i class="fa fa-eye-slash  job_requirement_eye_pass" id="show_job_requirement_data" @click="JobRequirementEyeReveal" style="cursor:pointer;"></i></span></h5>
                                                <!-- <ckeditor v-model="form.requirement" :config="editorConfig1" id="jb_description" class="jb_description"></ckeditor>  -->
                                                    <textarea type="text" class="job_requirement_toggle with-border required-field" value="" v-model="form.requirement" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'" :class="{ 'textarea_dsb': acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes' }"></textarea>
                                            </div>
                                                <span class="error_x_white">Please enter Job Requirement !</span>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                    <h5>Work Site Contact Person 1 Name <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.work_site_contact_person"
                                                        id="company_reg_no"
                                                        class="input-text with-border required-field"
                                                        name="company_reg_no"
                                                        placeholder=""
                                                        value=""
                                                        :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"
                                                    />
                                            </div>
                                            <span class="error_x_white">Please enter Work Site Contact Person 1 Name !</span>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5> Work Site Contact Person 1 Number <span class="required_star">*</span></h5>
                                                <div class="submit-field mobile_view_numbr mobile_email" >
                                                            <div class="input-group mb-3">
                                                            <input type="text" v-model="form.work_site_contact_person_country_code" class="with-border mobile_number_padding mobile_input_prf form-control required-field country_code_txt" readonly="readonly">
                                                            <input type="text" v-model="form.work_site_contact_person_mobile_no" class="with-border mobile_input_prf form-control required-field" id="contact" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                            <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                                <button @click="sendOTP" class="btn btn-outline-primary" type="button">Send OTP</button>
                                                            </div> -->
                                                            </div>
                                                            <span class="error_x_white">Please enter Work Site Contact Person 1 Number !</span>
                                            </div>
                                            <!-- <span class="error_x_white">Please enter Minimum Year of Experience !</span> -->
                                        </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                    <h5>Work Site Contact Person 2 Name </h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.work_site_contact_person_2"
                                                        id="company_reg_no"
                                                        class="input-text with-border"
                                                        name="company_reg_no"
                                                        placeholder=""
                                                        value=""
                                                        :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"
                                                    />
                                            </div>
                                            <!-- <span class="error_x_white">Please enter Work Site Contact Person 2 Name !</span> -->
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5> Work Site Contact Person 2 Number </h5>
                                                <div class="submit-field mobile_view_numbr mobile_email" >
                                                            <div class="input-group mb-3">
                                                            <input type="text" v-model="form.work_site_contact_person_2_country_code" class="with-border mobile_number_padding mobile_input_prf form-control country_code_txt" readonly="readonly">
                                                            <input type="text" v-model="form.work_site_contact_person_2_mobile_no" class="with-border mobile_input_prf form-control" id="contact" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                            <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                                <button @click="sendOTP" class="btn btn-outline-primary" type="button">Send OTP</button>
                                                            </div> -->
                                                            </div>
                                                            <!-- <span class="error_x_white">Please enter Work Site Contact Person 2 Number !</span> -->
                                            </div>
                                            <!-- <span class="error_x_white">Please enter Minimum Year of Experience !</span> -->
                                        </div>
                                        </div>

                                         <div class="col-xl-12">
                                            <div class="submit-field error_submit_field">
                                                    <h5>Remarks (Internal use)<span class="required_star"></span></h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.remarks"
                                                        id="company_reg_no"
                                                        class="input-text with-border"
                                                        name="company_reg_no"
                                                        placeholder=""
                                                        value=""
                                                        :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"
                                                    />
                                            </div>
                                            <span class="error_x_white">Please enter Remarks !</span>
                                        </div>



                                          <div class="col-xl-6">
                                            <div class="submit-field error_submit_field multiple_date_top_div">
                                                <h5>Start Date <span class="required_star">*</span></h5>
                                                    <div class="new_date_pk">
                                                        <b-input-group>
                                                                <date-picker
                                                                    v-model="dateArr"
                                                                    multiple
                                                                    locale="en"
                                                                    @change="dateupdate()"
                                                                    format="DD-MM-YYYY"
                                                                    display-format="DD-MM-YYYY"
                                                                    :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"
                                                                    id="scheduled_post"
                                                                    class="scheduled_post error_submit_field"
                                                                />
                                                                <template v-slot:append>
                                                                    <b-button aria-label="Clear date" @click="dateArr = []" class="custom_close_date">
                                                                    <span class="clear-btn multiple_close_btn">x</span>
                                                                    </b-button>
                                                                </template>
                                                     </b-input-group>
                                                    <!-- <m-date-picker v-model="dateArr" :multi="multi" :always-display="false" lang="en" :format="formatDate" id="scheduled_post" class="scheduled_post common_date_range_picker" min="2023-01-19" style="background-color:#fff"></m-date-picker> -->
                                                     <span class="error_x_white">Please enter Start Date !</span>
                                                    </div>
                                                    <!-- <span class="error_x_white">Please enter Start Date !</span> -->
                                                <!-- <datepicker v-model="form.scheduled_post" :open-date="new Date()" :disabled-dates="disabledFromDate"  :format="customFormatterTwo" id="scheduled_post" class="scheduled_post"></datepicker> -->
                                            </div>

                                        </div>

                                         <div class="col-xl-6 start_time_new">
                                            <div class="submit-field error_submit_field">
                                                <h5>Start Time <span class="required_star">*</span></h5>
                                                <vue-timepicker format="hh:mm A" v-model="form.starttime" id="scheduled_min" class="common_time_picker scheduled_min desktop_margin_bottom_zero" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'" :class="{ 'start_time_dsb': acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes' }"></vue-timepicker>
                                            </div>
                                            <span class="error_x_white">Please enter Start Time !</span>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5>No. of Hours <span class="required_star">*</span></h5>
                                                <select v-model="form.hour" class="custom-select number_of_hour" id="number_of_hour" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                    <option value="" disabled>Select</option>
                                                    <option :value="hour" v-for="(hour,id) in hours" :key="id">{{hour}}</option>
                                                </select>
                                            </div>
                                            <span class="error_x_white">Please enter No. of Hours !</span>
                                        </div>

                                         <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5>No. of Minutes <span class="required_star">*</span></h5>
                                                <select v-model="form.min" class="custom-select number_of_minute" id="number_of_minute" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                    <option value="" disabled>Select</option>
                                                    <option value="00">00</option>
                                                    <option value="15">15</option>
                                                    <option value="30">30</option>
                                                    <option value="45">45</option>



                                                </select>
                                            </div>
                                            <span class="error_x_white">Please enter No. of Minutes !</span>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5>No. of Vacancy <span class="required_star">*</span></h5>
                                                 <b-form-input id="input-9" maxlength="6" v-model="form.vacancy" type="text" placeholder="" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'" class="required-field"></b-form-input>

                                            </div>
                                            <span class="error_x_white">Please enter No. of Vacancy !</span>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5>Per hour Cost<span class="required_star">*</span></h5>
                                                 <b-form-select v-model="form.price" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'" id="per_hour_cost" class="per_hour_cost">
                                                    <option value="" disabled="true">Please Select Per Hour Cost</option>
                                                    <option :value="cost.per_hour_cost" v-for="cost in costListing">{{cost.per_hour_cost}}</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Per hour Cost !</span>
                                        </div>

                                        <div class="col-xl-12">
                                            <div class="submit-field error_submit_field">
                                                <h5>Address<span class="required_star">*</span></h5>
                                                <gmap-autocomplete
                                                @place_changed="setPlace" style="width: 1000px;
                                                " placeholder="Please type for suggestions"
                                                @click="addMarker" :options="autocompleteOptions"
                                                :value="form.formattedAddress">
                                            </gmap-autocomplete>
                                                <!-- <vue-google-autocomplete
                                                    ref="address"
                                                    id="map"
                                                    classname="form-control required-field"
                                                    placeholder="Please type your address"
                                                    v-on:placechanged="setPlace"
                                                    country="SG"
                                                    v-model="form.formattedAddress" :options="autocompleteOptions"
                                                    >
                                                    </vue-google-autocomplete> -->

                                            </div>
                                            <span class="error_x_white">Please enter Address !</span>
                                        </div>

                                         <div class="col-xl-12">
                                            <div class="submit-field">
                                                <h5>Unit No. <span class="red-text"><strong>(Optional)</strong></span></h5>
                                               <b-form-group id="input-group-2" label="" class="">
                                                    <b-form-input id="input-2" v-model="form.unit" type="text" placeholder="" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                                        </div>


                                         <div class="col-xl-12">
                                            <div class="submit-field">
                                               <GmapMap
                                                    :center="center"
                                                    :zoom="12"
                                                    style="width:100%;  height: 400px;"
                                                    map-type-id="terrain"
                                                >
                                                <GmapMarker
                                                    :key="index"
                                                     v-for="(m, index) in markers"
                                                    :position="m.position"
                                                     @dragend="showLocation"
                                                    @click="center=m.position"
                                                />
                                                </GmapMap>

                                            </div>
                                            <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                                        </div>
                                        <b-col lg="12" md="12">
                                            <div class="full_border submit-field ">
                                                <p class="red-text top_text_common_heading"><strong>Additional Optional</strong></p>
                                                <!-- <hr> -->
                                                 <div class="inner_common_content">
                                                    <!-- <div class="col-xl-12"> -->

                                                    <div class="submit-field employer_multiple_radio common_margin_bottom_15">
                                                        <b-form-checkbox value="yes" unchecked-value="no" v-model="is_ot" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'" class="lunch_check_box"><strong>Is OT Job?</strong></b-form-checkbox>
                                                    </div>
                                                    <!-- </div> -->
                                                    <!-- <div class="col-xl-12"> -->
                                                        <div class="employer_multiple_radio">
                                                            <b-form-checkbox value="yes" unchecked-value="no" v-model="lunch_pay_not_provided" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'" class="lunch_check_box"><strong>Unpaid break?</strong></b-form-checkbox>

                                                        </div>
                                                        <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                                                    <!-- </div> -->
                                                    <div class="margin_top_28"  v-if="lunch_pay_not_provided == 'yes'">
                                                        <div class="">

                                                            <b-form-select v-model="form.lunch_pay_hours" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                                <option value="" disabled="true">Please Select No. of Hours</option>
                                                                <option :value="arr" v-for="arr in lunch_hours_arr">{{arr}}</option>
                                                            </b-form-select>

                                                        </div>
                                                        <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-col>



                                        <b-col lg="12" md="12">
                                             <div class="full_border">
                                                <p class="red-text top_text_common_heading"><strong>FL Requirements (Optional)</strong></p>
                                                <!-- <hr> -->
                                                <div class="inner_common_content">
                                                    <div class="row">
                                                        <div class="col-xl-4">
                                                            <div class="submit-field error_submit_field mobile_and_big_screen_margin_bottom">
                                                                    <h5>Gender</h5>
                                                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.gender" id="genderval" class="custom-select web_bottom_zero" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                                        <option :value="null">Select</option>
                                                                        <option value="female">Female</option>
                                                                        <option value="male">Male</option>
                                                                    </b-form-select>
                                                                </div>
                                                        </div>
                                                        <!-- <div class="col-xl-3">
                                                            <div class="submit-field error_submit_field mobile_and_big_screen_margin_bottom">
                                                                    <h5>Race</h5>
                                                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.race_id" class="web_bottom_zero custom-select" id="race" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                                        <option :value="null">Select</option>
                                                                        <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>
                                                                    </b-form-select>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-xl-4">
                                                            <div class="submit-field desktop_margin_bottom_zero">
                                                                <h5>Age Range </h5>
                                                                <b-form-input id="input-9" v-model="form.min_age" type="number" min="15" max="70" placeholder="Min Age" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"></b-form-input>
                                                                <!-- <b-form-input id="input-9" v-model="form.max_age" type="number" min="15" max="70" placeholder="Max Age" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"></b-form-input> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4">
                                                            <div class="submit-field desktop_margin_bottom_zero">
                                                                <h5 class="max_age_opacity">Age Range </h5>
                                                                <b-form-input id="input-9" v-model="form.max_age" type="number" min="15" max="70" placeholder="Max Age" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"></b-form-input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-col>




                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="mobile_list_mrgn_btm mobile_tab_margin_bottom_zero">
                    <div class="col-xl-12 post_jb_btn">

                        <a  @click="submit" class="button ripple-effect big margin-top-30 employee_btn common_vertical_align_bottom" style="
    background: #4c8bf5 !important;
"><i class="material-icons-outlined">border_color_outlined</i>Post Job</a>

                    </div>
                </div>
				<!-- Button -->
            </b-form>

			</div>
            <b-modal @hidden="hideModal" hide-footer hide-header ref="cost-modal" centered content-class="" modal-class="background_custom_class cost-modal">
                <div class="edit-task">
                    <div class="popup-header">
                        <h3 class="">Please confirm and agree</h3>
                        <div class="mt-2">
                            <div class="addUserForm">
                                <div class="editForm mw-100 pl-0">
                                    <b-row>
                                        <b-col lg="12" md="12" >
                                            <div class="summary_table_div">
                                                <table class="table-bordered ml-2 mr-2 mb-2 mt-2" size="lg" v-if="dataItem != null">
                                                    <!-- <thead>
                                                        <tr>
                                                            <th>Postings</th>
                                                            <th>Dates</th>
                                                            <th>Timing</th>
                                                            <th>Paid Hours</th>
                                                            <th>Rate / Hr</th>
                                                            <th>Vacancy</th>
                                                            <th>Total</th>
                                                        </tr>

                                                    </thead> -->
                                                    <tbody>
                                                        <tr class="head_tr">
                                                            <td>Postings</td>
                                                            <td>Dates</td>
                                                            <td>Timing</td>
                                                            <td>Paid Hours</td>
                                                            <td>Rate / Hr</td>
                                                            <td>Vacancy</td>
                                                            <td>Total</td>
                                                        </tr>
                                                        <tr v-for="(val,key) in dataItem" :key="key">
                                                            <td>{{ val.sno }}.</td>
                                                            <td class="blue-text">{{ val.date }}</td>
                                                            <td>{{ val.time }}</td>
                                                            <td>{{ val.hours }}</td>
                                                            <td :class="val.job_type_id == 2 ? 'medium-red-text' : ''">${{ val.basic_rate }}</td>
                                                            <td>{{ val.vacancy }}</td>
                                                            <td>${{ val.total_cost }}</td>

                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Overall</b></td>
                                                            <td>{{ dataItem.length }}</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td class="bold-text"><b>${{ sum_total }}</b></td>


                                                        </tr>
                                                        <tr v-if="is_prepaid == 'yes'">
                                                            <td style="text-align:right" colspan="6" class="mobile_td"><b>Total with GST({{gst_percent}}%)</b></td>
                                                            <td class="bold-text"><b>${{ gst_total }}</b></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </b-col>

                                    </b-row>
                                    <br>
                                    <div slot="footer" class="form-btn">
                                        <b-button @click="Finalsubmit()" type="button" variant="success" class="desktop_margin_left_zero desktop_margin_right_zero">Agree</b-button>
                                        <b-button @click="hideModal()" type="button" variant="light">Cancel</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>

        <!-- Spacer -->

        <!-- Spacer / End-->
        <!-- Row / End -->

    </div>

</template>

<script>


    import { GET_API,POST_API } from "../../../store/actions.type"
    import CKEditor from 'ckeditor4-vue';
    import Datepicker from 'vuejs-datepicker';
    import moment from "moment";
    import VueTimepicker from 'vuejs-timepicker'
    import VueGoogleAutocomplete from "vue-google-autocomplete";
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

    import Bus from "../../../event-bus";

    $('#scheduled_post_multi').click(function(e){
            this.dateArr = new Date();
    });



    export default {
        name: 'GoogleMap',
        props: ['name'],

        data() {
            const date = new Date();
            const isoDate = date.toISOString().split('T')[0];
            const [year, month, day] = isoDate.split('-');
            const formattedDate = `${day}-${month}-${year}`;
            return {
                form:{
                    title: '',
                    type: 'full_time',
                    role_type_id:'',
                    service_categories_id:'',
                    other_service_category:'',
                    location :'',
                    scope :'',
                    requirement:"",
                    remarks:'',
                    work_site_contact_person_country_code:'+65',
                    work_site_contact_person_2_country_code:'+65',
                    work_site_contact_person_mobile_no:'',
                    work_site_contact_person:'',
                    work_site_contact_person_2_mobile_no:'',
                    work_site_contact_person_2:'',
                    max_salary: '',
                    tagArr: [],
                    description: '',
                    qualification_id:'',
                    location_id:'',
                    other_job_location:'',
                    position_level_id:'',
                    experience: '',
                    level: '',
                    package: '',
                    scheduled_post: '',
                    user_id: '',
                    company_id: '',
                    postedjobs:'',
                    hour:'',
                    min:'',
                    vacancy:'',
                    price:'',
                    lunch_pay_hours:'',
                    unit:'',
                    formattedAddress:'',
                    is_hygiene_cert_req: false,
                    medical_history: false,
                    // race_id:null,
                    gender:null,
                    min_age:'',
                    max_age:'',
                    starttime: {
                    hh: 'hh',
                    mm: 'mm',
                    // // ss: '00',
                    A: 'AM'

                },

                },
                race:[],
                dataItem:null,
                sum_total:0,
                acStatus:'',
                companyAdminPostingPaused:'',
                address:'',
                companyAdminDPStatus:'',
                company_status: '',
                autocompleteOptions: {
                componentRestrictions: {
                  country: [
                    'SG'
                  ],
                },
              },
                lunch_pay_not_provided:'no',
                is_ot:'no',
                hours: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],
                lunch_hours_arr:[0.5,0.75, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],


                salaryType:'Salary',
                other_location_id:'',
                fb_category_id:'',
                 center: { lat: 1.3521, lng: 103.8198 },
            markers: [],
            places: [],
            costListing:[],
            currentPlace: null,

             coordl2:null,
            coordl2:null,
            l1:null,
            l2:null,
            latLng:'',
            gst_percent: 0,
            gst_total: 0,
            is_prepaid: 'no',
            datas:'',
                part_time_id:'',
                // dateArr:[new Date().toISOString().split('T')[0]],
                //dateArr: new Date(),
                dateArr: [formattedDate],
                package_id:'',
                remaining_jobs:0,
                qualifications:[],
                locations:[],
                position_levels: [],
                categories: [],
                role_types:[],
                experience_yrs: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50],
                showDismissibleAlert:false,
                error_message:'',
                isActive: 1,
                personal:true,
                links:false,
                disabled:true,
                disabledFromDate: {
                    to: new Date(Date.now() - 8640000)
                },
                editorConfig1: {
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
                },
                coreStyles_bold: {
                    element: 'b',
                    overrides: 'strong'
                },
                // Custom style definition for the Italic feature.
                coreStyles_italic: {
                    element: 'i',
                    overrides: 'em'
                },
                editorConfig: {
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
                },

                isSubmitting: false,
                acStatus:'',
                loading:'',
                job_id: (this.$route.params.id),
                selectedJobTemplate: '',
                jobTemplates: [],
            }
        },
        components: {
            ckeditor: CKEditor.component,
            Datepicker,
            VueGoogleAutocomplete,
            VueTimepicker,
            datePicker: VuePersianDatetimePicker,

        },
        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },
        methods:{
            breadCrumb(){
                var item = [{
                    to:{name:'DailyJobManage'},
                    text: 'Manage Jobs',
                },{
                    to:null,
                    text: 'Post Job'
                }];
                return item;
            },
            JobScopeEyeReveal(){
                $(".job_scope_eye_pass").toggleClass("fa-eye fa-eye-slash");
            },
             JobRequirementEyeReveal(){
                $(".job_requirement_eye_pass").toggleClass("fa-eye fa-eye-slash");
            },
            dateupdate(){
                if(this.dateArr.length > 31){
                    this.error_message = 'Maximum 31 days jobs can be created at one time'
                    this.showAlert()
                    this.dateArr = []
                }
                else if(this.dateArr.length > 0){
                    this.dateArr.sort( ( a, b) => {
                        return new Date(a) - new Date(b);
                    });
                }
            },


            activeTab(){
                this.personal = true;
                this.links = false;
            },

            showSalary(id){
                if(id == this.part_time_id){
                    $('.perHourRate').css('display', 'block');
                    $('.minMaxSalary').css('display', 'none');
                }else{
                    $('.perHourRate').css('display', 'none');
                    $('.minMaxSalary').css('display', 'block');
                }
            },
            hyiegne_cert($event){
                if($event != null){
                    this.form.is_hygiene_cert_req = $event;
                }else{
                    this.form.is_hygiene_cert_req = $event;
                }
            },
            getRace() {
                return this.$store.dispatch(GET_API, {
                        api: '/api/race'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.race = this.$store.getters.getResults.data;
                        // this.showSuccessPopup();
                    }
                });
            },
            medical_hist($event){
                if($event != null){
                    this.form.medical_history = $event;
                }else{
                    this.form.medical_history = $event;
                }
            },
            showCategory(id){
                if(id == this.fb_category_id){
                    $('.fb_category_class').css('display', 'block');
                }else{
                    $('.fb_category_class').css('display', 'none');
                }
            },
             showLocation: function(evt){
                //   this.l1=evt.latlng.latitude;

             this.datas = evt.latLng.toString().split(',');


             const marker = {
              lat: +this.datas[0].substring(1),
              lng: +this.datas[1].substring(0, this.datas[1].length-1)
            };
            this.center.lat= this.datas[0].substring(1),
            this.center.lng= this.datas[1].substring(0, this.datas[1].length-1)

            this.center = marker;
            this.currentPlace = null;
            const _googleApiGeocoder = new google.maps.Geocoder();


                  _googleApiGeocoder.geocode(
                    {
                      location: {
                        lat: parseFloat(this.center.lat),
                        lng: parseFloat(this.center.lng)
                      }
                    },
                    (results_, status_) => {
                      if (status_ !== google.maps.GeocoderStatus.OK) {
                        // error process here
                      } else {
                        this.form.formattedAddress=results_[0].formatted_address;
                      }
                    });
        },
        setPlace(place) {
            this.l1 = place.latitude;
            this.l2 = place.longitude;

          this.currentPlace = place;
          this.addMarker();
        },
        getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
      },
      addMarker() {
          if (this.currentPlace) {
            const marker = {
              lat: +parseFloat(this.currentPlace.geometry.location.lat()),
              lng: +parseFloat(this.currentPlace.geometry.location.lng())
            };
            this.form.formattedAddress =this.currentPlace.formatted_address;
            this.markers = [];
            this.markers.push({ position: marker });
            this.places = [];

            this.places.push(this.currentPlace);

            this.center = marker;
            this.currentPlace = null;
          }else {
            const marker = {
              lat: this.center.lat,
              lng: this.center.lng
            };
            // console.log(marker);
            // this.form.formattedAddress =this.currentPlace.formatted_address;
            this.markers = [];
            this.markers.push({ position: marker });
            this.places = [];

            this.places.push(this.currentPlace);

            this.center = marker;
            this.currentPlace = null;
            console.log(this.center,this.currentPlace,'this.currentPlacethis.currentPlace');
          }
        },
        geolocate(){
            navigator.geolocation.getCurrentPosition(position => {

            this.center = {
              lat: +parseFloat(position.coords.latitude),
              lng: +parseFloat(position.coords.longitude)
            };

              const marker = {
                  lat: this.center.lat,
                  lng: this.center.lng
                };
                // this.form.formattedAddress =this.currentPlace.formatted_address;
                this.markers = [];
                this.markers.push({ position: marker });
                this.places = [];

                this.places.push(this.currentPlace);

                console.log(marker,'marker');
                // this.center = marker;
                this.currentPlace = this.center;
                this.addressUpdate();

          }, err => {

                  this.addMarker();
                  this.addressUpdate();
                  //console.log(err.message);

                });
        },
        //  addMarker() {
        //   if (this.currentPlace) {
        //     const marker = {
        //       lat: +parseFloat(this.currentPlace.latitude),
        //       lng: +parseFloat(this.currentPlace.longitude)
        //     };
        // if(this.currentPlace.street_number){

        //     this.currentPlace.street_number = this.currentPlace.street_number;
        // }else{

        //             this.currentPlace.street_number = '';

        // }
        //     this.form.formattedAddress =this.currentPlace.street_number + " " + this.currentPlace.route + ", " + this.currentPlace.locality;
        //     this.markers = [];
        //     this.markers.push({ position: marker });
        //     this.places = [];

        //     this.places.push(this.currentPlace);

        //     this.center = marker;
        //     this.currentPlace = null;
        //   }
        // //   else {
        // //     const marker = {
        // //       lat: this.center.lat,
        // //       lng: this.center.lng
        // //     };
        // //     // this.form.formattedAddress =this.currentPlace.formatted_address;
        // //     this.markers = [];
        // //     this.markers.push({ position: marker });
        // //     this.places = [];

        // //     this.places.push(this.currentPlace);

        // //     this.center = marker;
        // //     this.currentPlace = null;
        // //   }
        // },
        // geolocate() {

        //   navigator.geolocation.getCurrentPosition(position => {

        //     this.center = {
        //       lat: +parseFloat(position.coords.latitude),
        //       lng: +parseFloat(position.coords.longitude)
        //     };

        //       const marker = {
        //           lat: this.center.lat,
        //           lng: this.center.lng
        //         };
        //         // this.form.formattedAddress =this.currentPlace.formatted_address;
        //         this.markers = [];
        //         this.markers.push({ position: marker });
        //         this.places = [];

        //         this.places.push(this.currentPlace);

        //         // this.center = marker;
        //         this.currentPlace = this.center;
        //         this.addressUpdate();

        //   }, err => {

        //           this.addMarker();
        //           this.addressUpdate();

        //         });
        // },
        addressUpdate(){
             setTimeout(()=>{
                const _googleApiGeocoder = new google.maps.Geocoder();


                  _googleApiGeocoder.geocode(
                    {
                      location: {
                        lat: parseFloat(this.center.lat),
                        lng: parseFloat(this.center.lng)
                      }
                    },
                    (results_, status_) => {
                      if (status_ !== google.maps.GeocoderStatus.OK) {
                        // error process here
                      } else {
                        this.form.formattedAddress=results_[0].formatted_address;
                      }
                    });
              },200);
        },


            salaryTypeData(id){
                if(id == '4'){
                    this.salaryType = 'Per Hour Rate';
                }else{
                    this.salaryType = 'Salary';
                }
            },


            customFormatterTwo(date) {
                return moment(date).format('DD-MM-YYYY');
            },



            showAlert() {
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },

            getDropdownData(type){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/service-categories-list-dp'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.categories = this.$store.getters.getResults.data;
                        this.other_category_id = this.$store.getters.getResults.other_category_id;
                        this.fb_category_id = this.$store.getters.getResults.fb_category_id;
                        $('.fb_category_class').css('display', 'none');
                    }
                });
            },
            hideModal() {
                this.$refs['cost-modal'].hide()
            },

             submit() {


            var err = false;

            $('.required-field').each(function () {

                if ($(this).val() == '') {
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                }

            });

             $('.job_role_type').each(function () {

                if( !$('#job_role_type').val() ){
                    err = true;

                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });


                 if( $('#maxSal').val() ){
                     if(!$('#minSal').val()){
                        err = true;

                         $('#minSal').addClass('error');
                        $('#minSal').parent().parent().find('.required_content').addClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {

                        $('#minSal').removeClass('error');
                        $('#minSal').parent().parent().find('.required_content').removeClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').removeClass('show');
                }

                }

                if( $('#minSal').val() ){
                     if(!$('#maxSal').val()){
                    err = true;

                         $('#maxSal').addClass('error');
                        $('#maxSal').parent().parent().find('.required_content').addClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {

                        $('#maxSal').removeClass('error');
                        $('#maxSal').parent().parent().find('.required_content').removeClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').removeClass('show');
                    }

                }

            //     $('.per_hour_cost').each(function () {

            //     if( !$('#per_hour_cost').val() ){
            //         err = true;

            //         $(this).addClass('error');
            //         $(this).parent().parent().find('.required_content').addClass('show');
            //         $(this).parent().parent().find('.error_x_white').addClass('show');
            //     } else {

            //         $(this).removeClass('error');
            //         $(this).parent().parent().find('.required_content').removeClass('show');
            //         $(this).parent().parent().find('.error_x_white').removeClass('show');
            //     }



            // });




            $('.jbs_category').each(function () {

                if( !$('#jbs_category').val() ){
                    err = true;

                //     $(this).addClass('error');
                //     $(this).parent().parent().find('.required_content').addClass('show');
                //     $(this).parent().parent().find('.error_x_white').addClass('show');
                // } else {

                //     $(this).removeClass('error');
                //     $(this).parent().parent().find('.required_content').removeClass('show');
                //     $(this).parent().parent().find('.error_x_white').removeClass('show');
                // }
                $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                }



            });

             $('.per_hour_cost').each(function () {

                if( !$('#per_hour_cost').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                }

            });

            $('.number_of_hour').each(function () {

                if( !$('#number_of_hour').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                }

            });

            $('.number_of_minute').each(function () {

                if( !$('#number_of_minute').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                }

            });

            if(this.dateArr.length == 0 ){


                    err = true;

                    $('.scheduled_post').addClass('error');
                    $('.scheduled_post').parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    $('.scheduled_post').parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                } else {

                    $('.scheduled_post').removeClass('error');
                    $('.scheduled_post').parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    $('.scheduled_post').parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                }

                if(this.form.starttime.hh == 'hh' ||  this.form.starttime.mm == 'mm' || this.form.starttime.hh == '' || this.form.starttime.mm == ''){
                    err = true;

                    $('.scheduled_min').addClass('error');
                    $('.scheduled_min').parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    $('.scheduled_min').parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                } else {

                    $('.scheduled_min').removeClass('error');
                    $('.scheduled_min').parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    $('.scheduled_min').parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                }

                if(this.dateArr.length == 0){
                    err = true;
                    this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: "Please select Start date",
                            showConfirmButton: false,
                            timer: 1500
                        });

                }
                if(this.dateArr.length > 0){
                    this.dateArr.sort( ( a, b) => {
                        return new Date(a) - new Date(b);
                    });
                }

            // $('.min_year_exp').each(function () {

            //     if( !$('#min_year_exp').val() ){
            //         err = true;

            //         $(this).addClass('error');
            //         $(this).parent().parent().find('.required_content').addClass('show');
            //         $(this).parent().parent().find('.error_x_white').addClass('show');
            //     } else {

            //         $(this).removeClass('error');
            //         $(this).parent().parent().find('.required_content').removeClass('show');
            //         $(this).parent().parent().find('.error_x_white').removeClass('show');
            //     }



            // });

            $('.min_month_exp').each(function () {

                if( !$('#min_month_exp').val() ){
                    err = true;

                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }



            });




             setTimeout(function ()
            {
                    //$('.second_indiv_step_wrap .error').first().focus();
                    document.querySelector(".second_indiv_step_wrap .error").scrollIntoView({ behavior: "smooth", block: "center" });
                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    });
            }, 100);




            // if(this.form.package == 'free'){
            //     if(this.remaining_jobs == 0){
            //             this.$swal({
            //                     position: 'center',
            //                     icon: 'error',
            //                     title: 'You dont have free jobs for posting.',
            //                     showConfirmButton: false,
            //                     timer: 1500
            //                 });
            //     }
            // }else{
            //     if(this.coins[0].total_coins < 800){
            //                 this.$swal({
            //                     position: 'center',
            //                     icon: 'error',
            //                     title: 'Insufficient coins for posting jobs',
            //                     showConfirmButton: false,
            //                     timer: 1500
            //                 });

            //     }
            // }


                if(this.form.role_type_id == this.part_time_id){
                    // this.form.min_salary = 0
                }

                // if(this.form.formattedAddress == ''){
                //     err = true;
                //     this.$swal({
                //             position: 'center',
                //             icon: 'error',
                //             title: "Please select Address again",
                //             showConfirmButton: false,
                //             timer: 1500
                //         });

                // }

                this.loading = true;

                var total = this.form.vacancy * this.form.price * this.form.hour;

                var htmldata = "You are creating a job list with "+ this.form.vacancy +" vacancy for "+ this.form.hour +" hours. <br><br> Hourly Service Fee : <b>$" + this.form.price + "</b> <br><br>Est. Total Service Fee: <b>$" + total;
                if(err == false){


                            this.loading = false;

                                 this.$store.dispatch(POST_API, {
                                    data:{
                                    title: this.form.title,
                                    job_scope: this.form.scope,
                                    description: this.form.requirement,
                                    categories: this.form.service_categories_id,

                                    work_site_contact_person: this.form.work_site_contact_person,
                                    work_site_contact_person_country_code:"+65",
                                    work_site_contact_person_mobile_no: this.form.work_site_contact_person_mobile_no,
                                    work_site_contact_person_2: this.form.work_site_contact_person_2,
                                    work_site_contact_person_2_country_code:"+65",
                                    work_site_contact_person_2_mobile_no: this.form.work_site_contact_person_2_mobile_no,
                                    salaryMax: this.form.max_salary ? this.form.max_salary: 0,
                                    remarks: this.form.remarks,
                                    // working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : this.form.hour +'.5',
                                    working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : ((this.form.min == '45') ? this.form.hour + '.75' : (this.form.min == '15' ? this.form.hour +'.25' : this.form.hour +'.5')),

                                    vacancy : this.form.vacancy,
                                    price : this.form.price,
                                    userId : this.user_id,
                                    company_admin_id : this.company_admin_id ? this.company_admin_id: this.user_id,
                                    company_id : this.company_id,
                                    job_type: this.form.role_type_id,
                                    start_date: this.dateArr ? this.dateArr : null,
                                    start_time: this.form.starttime,
                                    package: this.form.package,
                                    remaining_free: this.remaining_jobs,
                                    unit: this.form.unit,
                                    lunch_pay_hours : this.lunch_pay_not_provided == 'yes' ? this.form.lunch_pay_hours : 0,
                                    lunch_pay_not_provided: this.lunch_pay_not_provided,
                                    is_ot: this.is_ot,
                                    status: 'active',
                                    address : this.form.formattedAddress,
                                    lat : this.center.lat,
                                    long : this.center.lng,
                                    is_hygiene_cert_req:this.form.is_hygiene_cert_req == 'yes' ? 'yes' : null,
                                    medical_history:this.form.medical_history == 'yes' ? 'yes' : null,
                                    showCosting:true,
                                    gender:this.form.gender ? this.form.gender : null,
                                    // race_id:this.form.race_id ? this.form.race_id : null,
                                    min_age:this.form.min_age ? this.form.min_age : null,
                                    max_age:this.form.max_age ? this.form.max_age : null,
                                },

                            api:'/api/postDailyJob'
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                var timerVal = 1500
                                this.showDismissibleAlert = true;

                                if(this.error_message == "You don't have enough credits to post this job. Please contact our finance department @ 6802 9119."){
                                    timerVal = 5000
                                    this.$swal({
                                    // title: 'Please Confirm',
                                    // icon: 'error',
                                    imageUrl: '/assets/images/404_elcy.gif',
                                    customClass:{
                                        container: 'mascot_error_container',
                                    },
                                    title: "Would you like to recieve a top-up reminder email?",
                                    type: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    customClass: {
                                        text: 'pop_up_text_class',
                                        container: 'pop_up_containerclass post_job_image_size',
                                        popup: 'popup_class',
                                        header: 'pop_up_header_class',
                                        title: 'pop_up_title_class',
                                        closeButton: 'pop_up_close_button_class',
                                        icon: 'cross_icon_class',
                                        image: 'your-image-class',
                                        content: 'pop_up_content_class',
                                        input: 'pop_up_input_class',
                                        actions: 'pop_up_actions_class',
                                        confirmButton: 'pop_up_cancel_button_class',
                                        cancelButton: 'pop_up_confirm_button_class',
                                        footer: 'pop_up_footer_class'
                                    }
                                });
                                }else{

                                    timerVal = 1500
                                    this.showDismissibleAlert = true;
                                    this.$swal({
                                    position: 'center',
                                    // icon: 'error',
                                    imageUrl: '/assets/images/404_elcy.gif',
                                    customClass:{
                                        container: 'mascot_error_container',
                                    },
                                    title: this.error_message,
                                    showConfirmButton: false,
                                    timer: timerVal
                                });
                                }

                            } else {
                                //Commented on 17-07-23
                                // this.dataItem =this.$store.getters.getResults.message;

                                //  this.$swal({
                                //     title: 'Please Confirm',
                                //     html: this.dataItem,
                                //     type: 'warning',
                                //     showCancelButton: true,
                                //     confirmButtonColor: '#3085d6',
                                //     cancelButtonColor: '#d33',
                                //     confirmButtonText: 'OK'
                                //     }).then(res => {

                                //     if(res.value){


                                //          this.Finalsubmit();
                                //     }
                                // })
                                //Commented on 17-07-23
                                //Added on 17-07-23
                                if(this.$store.getters.getResults.dataItem.length > 0){
                                    this.dataItem =this.$store.getters.getResults.dataItem;
                                    this.sum_total =this.$store.getters.getResults.sum_total_cost;
                                    var title = this.dataItem[0].is_prepaid == 'yes' ? '<span class="swalHeader">Please Confirm</br> <b style="color:green">S$'+this.dataItem[0].total_after_gst+'</b></span>' : 'Please Confirm';
                                    var html = this.dataItem[0].is_prepaid == 'yes' ? ( '<span class="dd_para">will be deducted from your account</span> <span class="dd_third_para">Available to spend <b>S$'+this.dataItem[0].available_coins+' </b></span> '+
                                        '<center><table class=""> <tr><td>Vacancy: </td><td><b>'+this.dataItem[0].vacancy+'</b></td></tr> <tr><td>Job Hours: </td><td><b>'+this.dataItem[0].hours+'</b></td></tr>'+
                                        '<tr><td>Hourly Service Fee: </td><td> <b>'+this.dataItem[0].basic_rate+'</b></td></tr> <tr><td>Subtotal (Excluding GST)</td><td><b>'+this.dataItem[0].total_cost+'</b></td></tr>'+
                                        '<tr><td>GST '+this.dataItem[0].gst+'%:</td><td><b>'+this.dataItem[0].gst_value+'</b></td></tr>'+
                                        '<tr><td>Total Payable</td><td><b>'+this.dataItem[0].total_after_gst+'</b></td></tr></table></center>') :
                                        ('<center><p class="mb-0 mt-3">You are creating a joblist with <b>'+this.dataItem[0].vacancy+'</b> vacancy for <b>'+this.dataItem[0].hours+'</b> hours.</p></center>' +
                                        '<center><p class="middle_para_job">Hourly Service Fee: <b>$'+this.dataItem[0].basic_rate+'</b></p>' +
                                        '<p class="last_para_job">Total Payable: <b>$'+this.dataItem[0].total_cost+'</b></p></center> ');

                                    this.gst_percent = this.dataItem[0].gst;
                                    this.gst_total = this.$store.getters.getResults.total_cost_gst;
                                    this.is_prepaid = this.dataItem[0].is_prepaid;

                                    this.$swal({
                                        title: title,
                                        html:html,
                                        type: this.dataItem[0].is_prepaid == 'no' ? 'warning' : '',
                                        icon: this.dataItem[0].is_prepaid == 'no' ? 'warning' : '',
                                        showCancelButton: this.dataItem[0].is_prepaid == 'no' ? true : false,
                                        showDenyButton: true,
                                        confirmButtonColor: '#3085d6',
                                        denyButtonColor: '#cccccc',
                                        cancelButtonColor: '#d33',
                                        denyButtonText: `View Summary`,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            container: 'repost_job_container_for_prepaid',
                                        },
                                        }).then(res => {
                                            if (res.isConfirmed) {
                                                 this.Finalsubmit();
                                            } else if (res.isDenied) {
                                                this.$refs['cost-modal'].show();
                                            }
                                        })
                                    // this.$refs['cost-modal'].show();
                                }else{
                                    this.dataItem =this.$store.getters.getResults.message;
                                    this.$swal({
                                        title: 'Please Confirm',
                                        html: this.dataItem,
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'OK'
                                        }).then(res => {

                                        if(res.value){
                                            this.Finalsubmit();
                                        }
                                    })
                                }
                                //Added on 17-07-23
                                // Bus.$emit('active_users');

                                // this.$swal({
                                //     position: 'center',
                                //     icon: 'success',
                                //     iconColor:'#4c8bf5',
                                //     title: 'Job Posted Successfully.',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                // }).then((result) => {
                                // window.location.replace('/v2/employer/dp-job-manage');




                            }

                });
            }
         },

            Finalsubmit() {


            var err = false;





                if(this.form.role_type_id == this.part_time_id){
                    // this.form.min_salary = 0
                }

                this.loading = true;

                var total = this.form.vacancy * this.form.price * this.form.hour;

                var htmldata = "You are creating a job list with "+ this.form.vacancy +" vacancy for "+ this.form.hour +" hours. <br><br> Hourly Service Fee : <b>$" + this.form.price + "</b> <br><br>Est. Total Service Fee: <b>$" + total;
                if(err == false){

                        // this.$swal({
                        //    title: 'Please Confirm',
                        //    html: htmldata,
                        //    type: 'warning',
                        //    showCancelButton: true,
                        //    confirmButtonColor: '#3085d6',
                        //    cancelButtonColor: '#d33',
                        //    confirmButtonText: 'OK'
                        //  }).then(res => {

                            // if(res.value){

                            this.loading = false;

                                 this.$store.dispatch(POST_API, {
                                    data:{
                                    title: this.form.title,
                                    job_scope: this.form.scope,
                                    description: this.form.requirement,
                                    categories: this.form.service_categories_id,
                                    showCosting:false,
                                    work_site_contact_person: this.form.work_site_contact_person,
                                    work_site_contact_person_country_code:"+65",
                                    work_site_contact_person_mobile_no: this.form.work_site_contact_person_mobile_no,
                                    work_site_contact_person_2: this.form.work_site_contact_person_2,
                                    work_site_contact_person_2_country_code:"+65",
                                    work_site_contact_person_2_mobile_no: this.form.work_site_contact_person_2_mobile_no,
                                    salaryMax: this.form.max_salary ? this.form.max_salary: 0,
                                    remarks: this.form.remarks,
                                    // working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : this.form.hour +'.5',
                                    working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : ((this.form.min == '45') ? this.form.hour + '.75' : (this.form.min == '15' ? this.form.hour +'.25' : this.form.hour +'.5')),

                                    vacancy : this.form.vacancy,
                                    price : this.form.price,
                                    userId : this.user_id,
                                    company_admin_id : this.company_admin_id ? this.company_admin_id: this.user_id,
                                    company_id : this.company_id,
                                    job_type: this.form.role_type_id,
                                    start_date: this.dateArr ? this.dateArr : null,
                                    start_time: this.form.starttime,
                                    package: this.form.package,
                                    remaining_free: this.remaining_jobs,
                                    unit: this.form.unit,
                                    lunch_pay_hours : this.lunch_pay_not_provided == 'yes' ? this.form.lunch_pay_hours : 0,
                                    lunch_pay_not_provided: this.lunch_pay_not_provided,
                                    is_ot: this.is_ot,
                                    status: 'active',
                                    address : this.form.formattedAddress,
                                    lat : this.center.lat,
                                    long : this.center.lng,
                                    is_hygiene_cert_req:this.form.is_hygiene_cert_req == 'yes' ? 'yes' : null,
                                    medical_history:this.form.medical_history == 'yes' ? 'yes' : null,
                                    gender:this.form.gender ? this.form.gender : null,
                                    // race_id:this.form.race_id ? this.form.race_id : null,
                                    min_age:this.form.min_age ? this.form.min_age : null,
                                    max_age:this.form.max_age ? this.form.max_age : null,

                                },

                            api:'/api/postDailyJob'
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                var timerVal = 1500
                                if(this.error_message == "You don't have enough credits to post this job. Please contact our finance department @ 6802 9119."){
                                    timerVal = 5000
                                    this.$swal({
                                        title: 'Insufficient ELC Credits',
                                    // icon: 'error',
                                    // imageUrl: '/assets/images/404_elcy.gif',
                                    customClass:{
                                        container: 'mascot_error_container',
                                    },
                                    // title: "Would you like to recieve a top-up reminder email?",
                                    // title: title,
                                    html:"<center><span><img style='width: 60%;'' class='mascot_error_container' src='/assets/images/404_elcy.gif'></img></span><br><p><b>Would you like to recieve a top-up reminder email?</b></p></center>",
                                    type: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                    customClass: {
                                        text: 'pop_up_text_class',
                                        container: 'pop_up_containerclass post_job_image_size',
                                        popup: 'popup_class',
                                        header: 'pop_up_header_class',
                                        title: 'pop_up_title_class',
                                        closeButton: 'pop_up_close_button_class',
                                        icon: 'cross_icon_class',
                                        image: 'your-image-class',
                                        content: 'pop_up_content_class',
                                        input: 'pop_up_input_class',
                                        actions: 'pop_up_actions_class',
                                        confirmButton: 'pop_up_cancel_button_class',
                                        cancelButton: 'pop_up_confirm_button_class',
                                        footer: 'pop_up_footer_class'
                                    }
                                }).then(res => {

                                    console.log(res.value,'hhh');

                                if(res.value){
                                    this.sendEmail();
                                };})
                                }else{

                                    timerVal = 1500
                                    this.showDismissibleAlert = true;
                                    this.$swal({
                                    position: 'center',
                                    // icon: 'error',
                                    imageUrl: '/assets/images/404_elcy.gif',
                                    customClass:{
                                        container: 'mascot_error_container',
                                    },
                                    title: this.error_message,
                                    showConfirmButton: false,
                                    timer: timerVal
                                });
                                }
                                // if(this.error_message == "You don't have enough credits to post this job. Please contact our finance department @ 6802 9119."){
                                //     timerVal = 5000
                                // }else{
                                //     timerVal = 1500
                                // }
                                // this.showDismissibleAlert = true;
                            //     this.$swal({
                            //     position: 'center',
                            //     // icon: 'error',
                            //     imageUrl: '/assets/images/404_elcy.gif',
                            //     customClass:{
                            //         container: 'mascot_error_container',
                            //     },
                            //     title: this.error_message,
                            //     showConfirmButton: false,
                            //     timer: timerVal
                            // });

                            } else {

                                Bus.$emit('active_users');

                                this.$swal({
                                    position: 'center',
                                    icon: 'success',
                                    iconColor:'#4c8bf5',
                                    title: 'Job Posted Successfully.',
                                    showConfirmButton: false,
                                    timer: 1500
                                }).then((result) => {
                                 window.location.replace('/v2/employer/dp-job-manage');

                                });


                            }
                        });
                    //         }
                    // });

                }






            },


            getEmployeeProfile() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.user_id,
                    },
                    api: '/api/employee-profile'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){
                            if(this.$store.getters.getResults.data.contact_number == '12345678'){
                                this.form.mobile = '';
                            }else{
                                this.form.mobile = this.$store.getters.getResults.data.contact_number;
                            }
                            this.form.email = this.$store.getters.getResults.data.email;
                            if(this.$store.getters.getResults.data.name == 'test.co'){
                                this.form.company_name = '';
                            }else{
                                this.form.company_name = this.$store.getters.getResults.data.name;
                            }
                            this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
                            this.form.overview = this.$store.getters.getResults.data.description;
                            this.form.office_address = this.$store.getters.getResults.data.address;
                            this.form.image = this.$store.getters.getResults.data.company_logo;
                            this.form.email_verify = this.$store.getters.getResults.user['email_verified'];
                            this.form.mobile_verify = this.$store.getters.getResults.user['mobile_verified'];
                            this.acStatus = this.$store.getters.getResults.user['account_verification_status'];


                            this.company_status = this.$store.getters.getResults.company_status;
                            this.companyAdminDPStatus = this.$store.getters.getResults.companyAdminData[0].account_verification_status;
                            this.companyAdminPostingPaused = this.$store.getters.getResults.companyAdminData[0].posting_paused;

                        }else{
                            this.form.mobile = '';
                            this.form.email = '';
                        }
                    }
                });
            },

            isInteger(num) {
            return num.toString().indexOf('.') === -1;
        },
            getPackageData() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.package_id,
                        company_admin_id: this.company_admin_id
                    },
                    api: '/api/package-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){

                            this.form.freejobs = this.$store.getters.getResults.data[0].option_value;
                            this.form.packageName = this.$store.getters.getResults.data[0].title;
                            var rem_jobs = parseInt(this.form.freejobs) - parseInt(this.form.postedjobs);

                            if(rem_jobs < 0){
                                this.remaining_jobs = 0;
                            }else{
                                this.remaining_jobs = rem_jobs;
                            }

                        }
                    }
                });
            },

             getHourlyCost() {
                return this.$store.dispatch(POST_API, {
                    data: {

                    },
                    api: '/api/hourly-cost'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){

                            this.costListing = this.$store.getters.getResults.data;


                        }
                    }
                });
            },



            postedJobs() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.user_id,
                    },
                    api: '/api/postedJobs'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){
                            this.form.postedjobs = this.$store.getters.getResults.data[0].posted_jobs;

                            this.getPackageData();

                        }
                    }
                });
            },

            companyData() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.user_id,
                    },
                    api: '/api/companyData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){
                            // this.form.service_categories_id = this.$store.getters.getResults.data[0].company_industry_id;
                        }
                    }
                });
            },

            getQualification(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/qualification'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.qualifications = this.$store.getters.getResults.data;
                    }
                });
            },
            getLocation(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/location'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.locations = this.$store.getters.getResults.data;
                        this.other_location_id = this.$store.getters.getResults.other_location_id;
                        this.locations.forEach(element => {
                            if(element.location_names){
                                element.location_names_val = element.location_names.split(',');
                                element.location_names_id = element.location_ids.split(',');
                                // location[element.location_ids.split(',')] = element.location_names.split(',')
                            }
                        });
                    }
                });
            },
            getPositionLevel(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/position-level'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.position_levels = this.$store.getters.getResults.data;
                    }
                });
            },
            getjobRoleType(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/job-role-type'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.role_types = this.$store.getters.getResults.data;
                        this.role_types.forEach(element => {
                            if(element.name == 'part-time'){
                                this.part_time_id = element.id;
                            }
                        });
                        $('.perHourRate').css('display', 'none');
                        $('.minMaxSalary').css('display', 'block');
                    }
                });
            },
            getEditJob() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: atob(this.job_id),
                    },
                    api: '/api/dailyJobDataById'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){

                        this.form.title = this.$store.getters.getResults.data[0].title;
                        this.form.requirement = this.$store.getters.getResults.data[0].description;
                        this.form.scope = this.$store.getters.getResults.data[0].job_scope;
                        this.form.remarks = this.$store.getters.getResults.data[0].remarks;
                        this.form.unit = this.$store.getters.getResults.data[0].unit;
                        this.form.postal_code = this.$store.getters.getResults.data[0].postal_code;
                        this.form.formattedAddress = this.$store.getters.getResults.data[0].address;
                        this.l1 = parseFloat(this.$store.getters.getResults.data[0].lat);
                        this.l2 = parseFloat(this.$store.getters.getResults.data[0].long);
                        this.form.work_site_contact_person = this.$store.getters.getResults.data[0].work_site_contact_person;
                        // this.form.work_site_contact_person_country_code = this.$store.getters.getResults.data[0].work_site_contact_person_country_code;
                        this.form.work_site_contact_person_mobile_no = this.$store.getters.getResults.data[0].work_site_contact_person_mobile_no;
                        this.form.work_site_contact_person_2 = this.$store.getters.getResults.data[0].work_site_contact_person_2;
                        // this.form.work_site_contact_person_2_country_code = this.$store.getters.getResults.data[0].work_site_contact_person_2_country_code;
                        this.form.work_site_contact_person_2_mobile_no = this.$store.getters.getResults.data[0].work_site_contact_person_2_mobile_no;

                        this.form.service_categories_id = this.$store.getters.getResults.data[0].service_categories_id;
                        this.form.is_hygiene_cert_req = this.$store.getters.getResults.data[0].is_hygiene_cert_req != null ? (this.$store.getters.getResults.data[0].is_hygiene_cert_req == 'yes' ? 'yes' : false) : '';
                        this.form.medical_history = this.$store.getters.getResults.data[0].medical_history != null ? (this.$store.getters.getResults.data[0].medical_history == 'yes' ? 'yes' : false) : '';
                        if(this.form.service_categories_id == this.fb_category_id){
                            $('.fb_category_class').css('display', 'block');
                        }else{
                            $('.fb_category_class').css('display', 'none');
                        }
                        this.lunch_pay_not_provided = this.$store.getters.getResults.data[0].lunch_pay_not_provided;
                        this.is_ot = this.$store.getters.getResults.data[0].is_ot;

                        let marker = {
                            lat: parseFloat(this.$store.getters.getResults.data[0].lat),
                            lng: parseFloat(this.$store.getters.getResults.data[0].long)
                        };

                        this.markers.push({ position : marker });
                        //this.addMarker();
                        // this.places.push(this.currentPlace);
                        this.center = marker;

                        this.start = this.$store.getters.getResults.data[0].start_time
                        if(this.start)
                        {
                            this.startArray = this.start.split(':');
                            this.form.starttime.hh = this.startArray[0] > 12 ? this.startArray[0]-12 : this.startArray[0];

                            if(this.form.starttime.hh.length){
                                (this.form.starttime.hh.length != 2) ? this.form.starttime.hh=("0" + this.form.starttime.hh) : this.form.starttime.hh;
                            }else{
                                (this.form.starttime.hh < 10) ? this.form.starttime.hh=("0" + this.form.starttime.hh) : this.form.starttime.hh;
                            }
                            this.form.starttime.mm = this.startArray[1] ? this.startArray[1] : '00';
                            this.form.starttime.A = this.startArray[0] >= 12 ? 'PM' : 'AM' ;

                        }
                        else
                        {
                            this.form.starttime.hh = '00',
                            this.form.starttime.mm = '00';
                            this.form.starttime.A = 'AM';
                        }

                        this.form.scheduled_post = this.$store.getters.getResults.data[0].start_date;
                        this.form.vacancy = this.$store.getters.getResults.data[0].total_vacancies;
                        this.form.price = parseFloat(this.$store.getters.getResults.data[0].hourly_cost);
                        this.form.lunch_pay_hours = this.$store.getters.getResults.data[0].lunch_pay_hours;

                        /*this.form.hours = this.$store.getters.getResults.data.job_details.working_hours;*/

                        var sum_hrs = parseFloat(this.$store.getters.getResults.data[0].working_hours) + parseFloat(this.$store.getters.getResults.data[0].lunch_pay_hours);

                        if(this.$store.getters.getResults.data[0].lunch_pay_not_provided == 'yes'){

                            this.form.hour = this.isInteger(sum_hrs) ? Math.floor(sum_hrs) : Math.floor(sum_hrs);
                        }else{
                            this.form.hour = this.isInteger(this.$store.getters.getResults.data[0].working_hours) ? this.$store.getters.getResults.data[0].working_hours : Math.floor(this.$store.getters.getResults.data[0].working_hours);
                        }
                        var h = sum_hrs.toString();

                        var split = this.isInteger(h) ? [h] : h.split(".");
                        console.log(split, 1)
                        if(split.length > 1){
                            // ommented on 08-03-2024
                            // if(split[1] == '25'){
                            //     this.form.min = '30';
                            // }else{
                            //     this.form.min = split[1] == '75' ? '45' : (split[1] == '5' ? '30' : '00');
                            // }
                            // commented on 08-03-2024
                            if(split[1] == '25'){
                                this.form.min = '15';
                            }else{
                                this.form.min = split[1] == '75' ? '45' : (split[1] == '5' ? '30' : (split[1] == '25' ? '15' : '00'));
                            }
                        }else{
                            this.form.min = '00'
                        }
                        // this.form.min = this.isInteger(sum_hrs) ? '00' : '30'

                            // this.form.postedjobs = this.$store.getters.getResults.data[0].posted_jobs;
                            // this.form.title = this.$store.getters.getResults.data[0].title;
                            // this.form.service_categories_id = this.$store.getters.getResults.data[0].service_categories_id;
                            // this.form.scope = this.$store.getters.getResults.data[0].job_scope;
                            // this.form.requirement = this.$store.getters.getResults.data[0].description;
                            // this.form.contactperson = this.$store.getters.getResults.data[0].work_site_contact_person;
                            // this.form.mobile = this.$store.getters.getResults.data[0].work_site_contact_person_mobile_no;
                            // this.form.remarks = this.$store.getters.getResults.data[0].remarks;
                            // this.form.hour = this.$store.getters.getResults.data[0].working_hours;
                            // this.form.vacancy = this.$store.getters.getResults.data[0].total_vacancies;
                            // this.form.start = this.$store.getters.getResults.data[0].total_vacancies;


                        }
                    }
                });
            },

            getElCoins(){
                this.$store.dispatch(POST_API, {
                    data:{
                        user_id: this.company_admin_id ? this.company_admin_id : this.user_id
                    },
                    api:'/api/userCoins'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.coins = this.$store.getters.getResults.data;

                    }
                });
            },

            sendEmail(){
                this.$store.dispatch(POST_API, {
                    data:{
                                    title: this.form.title,
                                    job_scope: this.form.scope,
                                    description: this.form.requirement,
                                    categories: this.form.service_categories_id,
                                    showCosting:false,
                                    work_site_contact_person: this.form.work_site_contact_person,
                                    work_site_contact_person_country_code:"+65",
                                    work_site_contact_person_mobile_no: this.form.work_site_contact_person_mobile_no,
                                    work_site_contact_person_2: this.form.work_site_contact_person_2,
                                    work_site_contact_person_2_country_code:"+65",
                                    work_site_contact_person_2_mobile_no: this.form.work_site_contact_person_2_mobile_no,
                                    salaryMax: this.form.max_salary ? this.form.max_salary: 0,
                                    remarks: this.form.remarks,
                                    // working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : this.form.hour +'.5',
                                    working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : ((this.form.min == '45') ? this.form.hour + '.75' : (this.form.min == '15' ? this.form.hour +'.25' : this.form.hour +'.5')),

                                    vacancy : this.form.vacancy,
                                    price : this.form.price,
                                    userId : this.user_id,
                                    company_admin_id : this.company_admin_id ? this.company_admin_id: this.user_id,
                                    company_id : this.company_id,
                                    job_type: this.form.role_type_id,
                                    start_date: this.dateArr ? this.dateArr : null,
                                    start_time: this.form.starttime,
                                    package: this.form.package,
                                    remaining_free: this.remaining_jobs,
                                    unit: this.form.unit,
                                    lunch_pay_hours : this.lunch_pay_not_provided == 'yes' ? this.form.lunch_pay_hours : 0,
                                    lunch_pay_not_provided: this.lunch_pay_not_provided,
                                    is_ot: this.is_ot,
                                    status: 'active',
                                    address : this.form.formattedAddress,
                                    lat : this.center.lat,
                                    long : this.center.lng,
                                    is_hygiene_cert_req:this.form.is_hygiene_cert_req == 'yes' ? 'yes' : null,
                                    medical_history:this.form.medical_history == 'yes' ? 'yes' : null,
                                    gender:this.form.gender ? this.form.gender : null,
                                    // race_id:this.form.race_id ? this.form.race_id : null,
                                    min_age:this.form.min_age ? this.form.min_age : null,
                                    max_age:this.form.max_age ? this.form.max_age : null,

                                },
                    api:'/api/sendInsufficientCoinEmail'
                })
                .then(() => {

                    this.$swal({
                                    position: 'center',
                                    icon: 'success',
                                    iconColor:'#4c8bf5',
                                    title: 'Mail sent Successfully.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                })

            },
            getDpJobTemplate(){
                this.$store.dispatch(POST_API, {
                    data:{
                        userId: this.user_id,
                    },
                    api:'/api/findUniqueDPJobs4Employer'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.jobTemplates = this.$store.getters.getResults.data;

                    }
                })
            },
            templateChangeHandler() {
            this.$store.dispatch(POST_API, {
                data: {
                    userId: this.user_id,
                    selectedJobTemplate: this.selectedJobTemplate
                },
                api: '/api/handleDpJobTemplate'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass: {
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        // console.log(this.$store.getters.getResults.message)
                        if (this.$store.getters.getResults.data) {

                            this.form.title = this.$store.getters.getResults.data.title;
                            this.form.requirement = this.$store.getters.getResults.data.description;
                            this.form.scope = this.$store.getters.getResults.data.job_scope;
                            this.form.remarks = this.$store.getters.getResults.data.remarks ? this.$store.getters.getResults.data.remarks : '';

                            this.form.gender = this.$store.getters.getResults.data.gender;
                            // this.form.race_id = this.$store.getters.getResults.data.race_id;
                            this.form.min_age = this.$store.getters.getResults.data.min_age;
                            this.form.max_age = this.$store.getters.getResults.data.max_age;

                            this.form.unit = this.$store.getters.getResults.data.unit;
                            this.form.postal_code = this.$store.getters.getResults.data.postal_code;
                            this.form.formattedAddress = this.$store.getters.getResults.data.address;
                            this.l1 = parseFloat(this.$store.getters.getResults.data.lat);
                            this.l2 = parseFloat(this.$store.getters.getResults.data.long);
                            this.form.work_site_contact_person = this.$store.getters.getResults.data.work_site_contact_person;
                            this.form.work_site_contact_person_mobile_no = this.$store.getters.getResults.data.work_site_contact_person_mobile_no;
                            this.form.work_site_contact_person_2 = this.$store.getters.getResults.data.work_site_contact_person_2;
                            this.form.work_site_contact_person_2_mobile_no = this.$store.getters.getResults.data.work_site_contact_person_2_mobile_no;

                            this.form.service_categories_id = this.$store.getters.getResults.data.serviceCat.id;
                            this.form.is_hygiene_cert_req = this.$store.getters.getResults.data.is_hygiene_cert_req != null ? (this.$store.getters.getResults.data.is_hygiene_cert_req == 'yes' ? 'yes' : false) : '';
                            this.form.medical_history = this.$store.getters.getResults.data.medical_history != null ? (this.$store.getters.getResults.data.medical_history == 'yes' ? 'yes' : false) : '';
                            if (this.form.service_categories_id == this.fb_category_id) {
                                $('.fb_category_class').css('display', 'block');
                            } else {
                                $('.fb_category_class').css('display', 'none');
                            }
                            this.lunch_pay_not_provided = this.$store.getters.getResults.data.lunch_pay_not_provided;
                            this.is_ot = this.$store.getters.getResults.data.is_ot;


                            let marker = {
                                lat: parseFloat(this.$store.getters.getResults.data.lat),
                                lng: parseFloat(this.$store.getters.getResults.data.long)
                            };

                            this.markers.push({ position: marker });
                            // this.addMarker();
                            // this.places.push(this.currentPlace);
                            this.center = marker;

                            this.start = this.$store.getters.getResults.data.start_time;
                            if (this.start) {
                                this.startArray = this.start.split(':');
                                this.form.starttime.hh = this.startArray[0] > 12 ? this.startArray[0] - 12 : this.startArray[0];

                                if (this.form.starttime.hh.length) {
                                    (this.form.starttime.hh.length != 2) ? this.form.starttime.hh = ("0" + this.form.starttime.hh) : this.form.starttime.hh;
                                } else {
                                    (this.form.starttime.hh < 10) ? this.form.starttime.hh = ("0" + this.form.starttime.hh) : this.form.starttime.hh;
                                }
                                this.form.starttime.mm = this.startArray[1] ? this.startArray[1] : '00';
                                this.form.starttime.A = this.startArray[0] >= 12 ? 'PM' : 'AM';

                            } else {
                                this.form.starttime.hh = '00',
                                    this.form.starttime.mm = '00';
                                this.form.starttime.A = 'AM';
                            }

                            this.form.vacancy = this.$store.getters.getResults.data.total_vacancies;
                            this.form.price = parseFloat(this.$store.getters.getResults.data.hourly_cost);
                            var sum_hrs = parseFloat(this.$store.getters.getResults.data.working_hours) + parseFloat(this.$store.getters.getResults.data.lunch_pay_hours);

                            if (this.$store.getters.getResults.data.lunch_pay_not_provided == 'yes') {
                                this.form.lunch_pay_hours = this.$store.getters.getResults.data.lunch_pay_hours;
                                this.form.hour = this.isInteger(sum_hrs) ? Math.floor(sum_hrs) : Math.floor(sum_hrs);
                            } else {
                                this.form.lunch_pay_hours = ''
                                this.form.hour = this.isInteger(this.$store.getters.getResults.data.working_hours) ? this.$store.getters.getResults.data.working_hours : Math.floor(this.$store.getters.getResults.data.working_hours);
                            }
                            var h = sum_hrs.toString();

                            var split = this.isInteger(h) ? [h] : h.split(".");
                            console.log(split, 1)
                            if (split.length > 1) {
                                if (split[1] == '25') {
                                    this.form.min = '15';
                                } else {
                                    this.form.min = split[1] == '75' ? '45' : (split[1] == '5' ? '30' : (split[1] == '25' ? '15' : '00'));
                                }

                            } else {
                                this.form.min = '00'
                            }
                        }
                    }
                })
        },

        },


         mounted(){
            $("#show_job_scope_data").click(function(e){
                $(".job_scope_toggle").toggleClass('job_scope_textarea');
                $(".job_scope_toggle").each(function () {
                    if ( $(".job_scope_toggle").hasClass("job_scope_textarea") ){
                        $(".job_scope_textarea").attr("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
                    }
                    else{
                        $(".job_scope_toggle").removeAttr("style")
                    }
                })
                // .on("input", function () {
                // this.style.overflow = "hidden";
                // this.style.height = 0;
                // this.style.height = (this.scrollHeight) + "px";
                // });
            });
        // $(".job_scope_toggle").on("input", function () {
        //     $(".job_scope_toggle").toggleClass('job_scope_textarea');
        //     this.style.overflow = "hidden";
        //     this.style.height = 0;
        //     this.style.height = (this.scrollHeight) + "px";
        // });
         $("#show_job_requirement_data").click(function(e){
                $(".job_requirement_toggle").toggleClass('job_scope_textarea');
                $(".job_requirement_toggle").each(function () {
                    if ( $(".job_requirement_toggle").hasClass("job_scope_textarea") ){
                        $(".job_scope_textarea").attr("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
                    }
                    else{
                        $(".job_requirement_toggle").removeAttr("style")
                    }
                })
        });
            //document.title = "EL Connect - Employer - Post Job";
             $('#minSal').keyup(function(e)
            {
            if (/\D/g.test(this.value))
            {
                // Filter non-digits from input value.
                this.value = this.value.replace(/\D/g, '');
            }
            });





            $('.date_select .vpd-actions:nth-child(2)').click(function(e){
                this.dateArr = []
                });

            $('#maxSal').keyup(function(e)
                                            {
            if (/\D/g.test(this.value))
            {
                // Filter non-digits from input value.
                this.value = this.value.replace(/\D/g, '');
            }
            });

             $('#exp').keyup(function(e)
                                            {
            if (/\D/g.test(this.value))
            {
                // Filter non-digits from input value.
                this.value = this.value.replace(/\D/g, '');
            }
            });
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.package_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.package_id : '');
            this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            Bus.$emit('active_users');

            if(this.package_id == 13){
                $('#specific_job_cat').show();
            }
            this.getDpJobTemplate();
            this.getDropdownData('service_categories');
           // this.getEditJob();
            // this.geolocate();
            this.getEmployeeProfile();
            this.getQualification();
            this.getPositionLevel();
            this.getLocation();

            this.getjobRoleType();
            this.postedJobs();
            //this.companyData();
            this.getPackageData();
            this.getElCoins();
            this.getHourlyCost();
            // this.getRace();

            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        }
    }
</script>


