<template>

    <div>

        <!-- Dashboard Headline -->
        <div class="dashboard-headline">

            <h1>Delete Account</h1>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark nav_on_emp">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Delete Account</li>
                </ul>
            </nav>
        </div>
        <!-- Row -->
        <!-- <div class="row mobile_stng"> -->
        <div class="row">

            <!-- Dashboard Box -->
            <div class="col-xl-12">
                <div id="test1" class="dashboard-box desktop_margin_top_zero">

                    <!-- Headline -->
                    <div class="headline deactivate_para">
                        <h3><b>Are you sure you want to delete?</b></h3>
                        <div class="">
                            <p>Once you delete your EL Connect account, information will be gone forever. Please be certain.</p>
                            <!-- <p>Need to talk to someone? <a :href="'https://wa.link/k7p8ww'" target="_blank">Contact Us</a> we'd love to help.</p> -->
                            <p>Need to talk to someone? <a :href="'https://wa.me/6568029119'" target="_blank">Contact Us</a> we'd love to help.</p>
                        </div>
                    </div>

                    <div class="content with-padding desktop_padding_top_zero">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="submit-field  desktop_margin_bottom_zero  single_profile_eye" id="show_hide_current_password">
                                    <h5>Why are you deleting your account?</h5>
                                        <select v-model="deactivate_reason" class="custom-select min_year_exp" id="min_year_exp">
                                            <option value="" disabled>Choose your reason</option>
                                            <option value="platform_complexity">Platform Complexity</option>
                                            <option value="queries_not_resolved">Queries Not Resolved</option>
                                            <option value="privacy_concerns">Privacy Concerns</option>
                                            <option value="technical_issues">Technical Issues</option>
                                            <option value="limited_job_opportunities">Limited Job Opportunities</option>
                                            <option value="foreign_worker">Foreign Worker</option>
                                            <option value="other_reason">Other (Please Specify)</option>
                                        </select>
                                    </div>
                            </div>

                            <div class="col-xl-12" v-if="deactivate_reason == 'other_reason'">
                                <div class="submit-field  desktop_margin_bottom_zero" :class="deactivate_reason == 'other_reason' ? 'other_margin_top_28' : 'other_margin_top_zero'">
                                    <div class="">
                                        <div class="">
                                            <h5 class="">Remark<span class="required_star">*</span></h5>
                                                <textarea type="text" class="with-border desktop_margin_bottom_zero" v-model="remark"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Button -->
                            <div class="col-xl-12 deactivate_btn">
                                <a @click="deactivateAccount()" class="button new_mobile_margin_bottom_zero ripple-effect big margin-top-30 stng_mbl common_vertical_align_bottom" style="color: white;">Delete Account</a>
                                <b-link :to="{name: 'jobseeker-profile'}" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn">Cancel</b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Row / End -->

    </div>

</template>

<script>

import { POST_API, GET_API,LOGOUT } from '../../store/actions.type'
import Bus from "../../event-bus";


export default {
    data() {
        return {
            deactivate_reason: '',
            remark: '',
            error_message:''
        }
        },
    components: {
    },
    methods:{
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        logout() {
            var user = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');
            this.$store.dispatch(LOGOUT, {
                data: {
                },
                api: '/api/logout'
            })
            .then(() => {
                if (!this.$store.getters.containsErrors) {
                    this.$router.push({ name: 'jobseekers2' })
                }
            });
        },
        deactivateAccount() {
            // if(this.deactivate_reason != 'other_reason'){
            //     var i, frags = this.deactivate_reason.split('_');
            //     for (i=0; i<frags.length; i++) {
            //         frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            //     }
            //     this.deactivate_reason = frags.join(' ');
            // }
                this.$store.dispatch(POST_API, {
                    data:{
                        deactivate_reason: this.deactivate_reason,
                        reason: this.remark,
                        user_id: this.$store.getters.currentUser.id,
                    },
                    api:'/api/deactivateAccountFLWeb'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {

                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Account Deleted successfully',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            Bus.$emit('active_users');
                            this.logout();
                            //  if(this.user_type_id=='5'){
                            //      window.location.replace('/');
                            //  }


                        });
                    }
                });
            },
    },
    mounted()
    {
         window.scrollTo(0,0);
         $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        Bus.$emit('active_users');

        this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');
            if(this.user_type_id=='5')
            {
                document.title = "EL Connect - Jobseeker - Delete Account";
                document.querySelector('meta[name="description"]') == 'EL Connect - Jobseeker - Delete Account';
            }
            if(this.user_type_id=='3')
            {
                document.title = "EL Connect - Employer - Delete Account";
                document.querySelector('meta[name="description"]') == 'EL Connect - Employer - Delete Account';
            }
    }
}
</script>
