<template>
    <div>
      <!-- <div class="container full_container_dsk">
              <div class="row custom_dsk_row"> -->
      <div class="row">
        <div class="container my_new_container">
          <!-- Dashboard Headline -->
          <div class="dashboard-headline">
            <h1>Upload Image</h1>
            <!-- <b-link class="btn green-border-btn" :to="{name:'app-banner-create'}">Add</b-link> -->
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
              <ul>
                <li><a href="#">Home</a></li>
                <li>Upload Image</li>
              </ul>
            </nav>
          </div>
          <b-tabs class="my_tab_job tab_header_action_th">
            <b-tab :title="'Active'" @click="changeTab('active')">
              <div class="row">
                <div
                  class="col-xl-12 col-md-12 mobile_tab_max_width_flex new_search_div_daily_job desktop_margin_bottom_zero search_common_margin_bottom_28"
                >
                <div class="row align-items-center">
                  <div class="col">
                    <div
                    class="search-box new_marketing_pop_up_search custom_search_admin desktop_margin_top_zero w-50"
                  >
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                  </div>
                  <div class="col-auto">
                      <b-link
                        class="btn green-border-btn"
                        style="margin-bottom: 28px;"
                        @click="clickToAdd('add')"
                        v-if="show('add')"
                      >Add</b-link>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- Page Content
                          ================================================== -->
              <!-- <div class="container full_container_dsk">
                          <div class="row"> -->
              <div class="row">
                <div class="container my_new_container show_filter_arrow">
                  <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getAppBanners" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_field_of_study new_box_tbl_mrgn nationality_tbl_width"> -->
                  <b-table
                    ref="datatableActive"
                    show-empty
                    striped
                    hover
                    responsive
                    :items="getImage"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    class="memberhip_six_grid_table mobile_min_width_1000"
                  >
                    <template #cell(title)="items">
                      {{ items.item.title | capitalize }}
                    </template>
                        <template #cell(image_url)="items">
                        <a :href="items.item.image">
                          {{ items.item.image }}
                        </a>
                      </template>
                    <template #cell(image)="items">
                      <img
                        :src="
                          items.item.image != null
                            ? items.item.image
                            : webUrl + 'assets/images/logo.png'
                        "
                        height="100px"
                        width="100px"
                        alt="logo"
                      />
                    </template>
                    <template #cell(status)="items">
                      <p
                        class="btn green-bg-btn desktop_margin_left_zero desktop_margin_right_zero"
                        v-if="items.item.status == 'active'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                      <p
                        class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero"
                        v-if="items.item.status == 'inactive'"
                      >
                        {{ items.item.status | capitalize }}
                      </p>
                    </template>
                    <template #cell(toggleAction)="items">
                      <div class="toggle-action toggle_margin_unset">
                        <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          :to="{ name: 'upload-image-edit', params: { id: encrypt(items.item.id) } }"
                          v-if="show('edit')"
                          >Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          v-if="items.item.status == 'active' && show('activate/inactivate')"
                          >Inactivate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          >Activate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-on:click="deleteImage(items.item.id)"
                          v-if="show('delete')"
                          >Delete</b-dropdown-item
                        >
                      </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                  <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select v-model="form.rowsPerPage" @change="getImage">
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                      <ul>
                        <li>
                          <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRows"
                      :per-page="perPage"
                      v-model="currentPage"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </div>
              </div>
            </b-tab>
  
            <b-tab :title="'Inactive'" @click="changeTab('inactive')">
            <div class="row">
              <div
                class="col-xl-5 col-md-5 mobile_tab_max_width_flex new_search_div_daily_job desktop_margin_bottom_zero search_common_margin_bottom_28"
              >
                <div
                  class="search-box new_marketing_pop_up_search custom_search_admin desktop_margin_top_zero"
                >
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = searchKeyword"
                      v-model="searchKeyword"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = searchKeyword"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>
              <!-- <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                                <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" :to="{name:'marketing-popup-create'}">Add</b-link>
                            </div> -->
            </div>
            <!-- Page Content
                            ================================================== -->
            <!-- <div class="container full_container_dsk">
                            <div class="row"> -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getAppBanners" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_field_of_study new_box_tbl_mrgn nationality_tbl_width"> -->
                <b-table
                  ref="datatableInactive"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getImage"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="memberhip_six_grid_table mobile_min_width_1000"
                >
                <template #cell(title)="items">
                      {{ items.item.title | capitalize }}
                    </template>
                        <template #cell(image_url)="items">
                        <a :href="items.item.image">
                          {{ items.item.image }}
                        </a>
                      </template>
                    <template #cell(image)="items">
                      <img
                        :src="
                          items.item.image != null
                            ? items.item.image
                            : webUrl + 'assets/images/logo.png'
                        "
                        height="100px"
                        width="100px"
                        alt="logo"
                      />
                    </template>
                  <template #cell(status)="items">
                    <p
                      class="btn green-bg-btn desktop_margin_left_zero desktop_margin_right_zero"
                      v-if="items.item.status == 'active'"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                    <p
                      class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero"
                      v-if="items.item.status == 'inactive'"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          :to="{ name: 'upload-image-edit', params: { id: encrypt(items.item.id) } }"
                          v-if="show('edit')"
                          >Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          v-if="items.item.status == 'active' && show('activate/inactivate')"
                          >Inactivate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          >Activate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-on:click="deleteBanner(items.item.id)"
                          v-if="show('delete')"
                          >Delete</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPage" @change="getImage">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
          </b-tabs>
        </div>
      </div>
      <b-modal
        ref="upload-modal"
        :title="modalTitle"
        hide-footer
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="formSubmit">
          <div class="edit-form-box model_legend model_margin_top_zero">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <div class="form-map-box">
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-10" label="Title">
                            <b-form-input id="title" v-model="form.title" type="text" ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col  xl="12"  >
                    <div class="upload-form-group">
                        <b-form-group id="input-group-10" label="Campaign Image" class="admin_promo_img_set">
                            <div class="single-image-upload my_single_img img_back_clr" :style=" { background: backgroundColour}">
                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                <span>
                                    <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" class="after_img_set"></b-img>
                                    <b-img v-else :src="webUrl+'assets/images/company-logo-placeholders.png'" class="before_img_promo"></b-img>
                                </span>
                            </div>
                        </b-form-group>
                    </div>
                </b-col>
                    <div
                      slot="footer"
                      class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn"
                    >
                      <b-button type="submit" variant="success" class="model_common_bt_success_color"
                        >Submit</b-button
                      >
                      <b-button
                        @click="hideModel()"
                        variant="light"
                        class="model_common_bt_lite_color"
                        >Cancel</b-button
                      >
                    </div>
                  </div>
                </b-col>
                
              </b-row>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="crop-modal" title="Edit Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImg">
                        <template>
                              <div>
                                <!-- <input ref="input" name="image" accept="image/*" @change="setImage"/> -->
                                <div class="content" @change="setImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImage">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
                </b-modal>
      <!-- Page Content
          ================================================== -->
      <!-- <div class="container full_container_dsk">
             <div class="row"> -->
  
      <!-- Spacer -->
      <!-- <div class="margin-top-70"></div> -->
      <!-- Spacer / End-->
      <!-- Row / End -->
    </div>
  </template>
  
  <script>
  import { POST_API } from "../../../../store/actions.type";
  import permission from "../../../../../../server/permission.js";
  
  import draggable from "vuedraggable";
  import { draggabletable } from "../../../v-draggable.js";
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    draggableOptions: {
      animation: 400,
    },
  
    name: "VDraggableBTable",
    directives: { draggabletable },
  
    data() {
      return {
        selected: null,
        fields: [
          { key: "title", label: "Title", sortable: true },
          { key: "image_url", label: "Image_Url", sortable: false },
          { key: "image", label: "Image", sortable: false },
          { key: "status", label: "Status", sortable: true },
          { key: "toggleAction", label: "Actions" },
        ],
        form: {
                title: '',
                image: '',
                rowsPerPage: 25,
                selectedImage:{
                    name:'',
                    image:''
                },
            },
        cropImg: '',
        data: null,
        cropmove:null,
        tempLogo:'',
        noClose:true,
        pager: {},
        pageOfItems: [],
        items: null,
        totalRows: 0,
        from: "",
        to: "",
        currentPage: 1,
        perPage: 25,
        pageOptions: [25, 50, 75, 100],
        sortBy: null,
        sortDirection: "desc",
        filter: "",
        status: "active",
        sortDesc: true,
        webUrl: process.env.VUE_APP_URL,
        modalTitle:'',
        appBanners: [],
        currentTab: "active",
        searchKeyword: "",
        actions: "",
        image_title : "",
        image_url : "",
        backgroundColour: '#eee'
      };
    },
    components: {
      draggable,
      VueCropper,
    },
    filters: {
      capitalize: function (value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    },
    methods: {
      cropImage() {
          this.$refs['crop-modal'].hide(); 
          this.backgroundColour='transparent';
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
              this.cropImg = event.target.result;
              this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal'].hide(); 
            this.$refs['crop-modal'].refresh();
            
        },
        onImageChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.image = input.files[0];
                if (this.form.image.type != 'image/jpeg' && this.form.image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.image.size > 5242880) {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Image should be less than 5 MB.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.image.name;
                        this.showImg();
                        event.target.value = '';

                    }
                }
            }
        },
        clickToAdd(typeVal) {
        this.$refs["upload-modal"].show();
        this.modalTitle = typeVal == "add" ? "Upload Image" : "Update Image";
        this.type = typeVal == "add" ? "Add" : "Update";
      },
      hideModel() {
        this.form.title = "";
        this.form.selectedImage = "";
        this.$refs["upload-modal"].hide();
        
      },
      showSuccessAlert(message) {
        this.$swal({
          position: "center",
          icon: "success",
          iconColor: "#4c8bf5",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        })
      },
      showAlert() {
        this.$swal({
          position: "center",
          icon: "error",
          title: this.error_message,
          showConfirmButton: false,
          timer: 1500,
        });
      },
      refreshDatatable() {
        this.$refs.datatableActive.refresh();
        this.$refs.datatableInactive.refresh()
      },
      formSubmit(evt) {
            evt.preventDefault();
          if(this.form.title == ''){
            this.error_message='Please fill up the title'
            this.showAlert()
            return
          }
          if(this.form.selectedImage.image ==''){
            this.error_message='Please select an image'
            this.showAlert()
            return
          }
            let formData = {
              title:this.form.title,
              selectedImage:this.form.selectedImage
      };
            this.$store.dispatch(POST_API, {
                    data: formData,
                    api: "/api/upload-image",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Image Added successfully.'
                        this.showSuccessAlert(message); 
                        this.form.title = "";
                        this.form.selectedImage.name = "";
                        this.form.selectedImage.image=""
                        this.$refs['upload-modal'].hide()
                        this.refreshDatatable()    
                    }
                });
        },     

        changeStatus(id, status) {
      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Image Inactivated successfully.";
        var new_status = "inactive";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Image Activated successfully.";
        var new_status = "active";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: new_status,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/upload-image-status",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.refreshDatatable();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    changeTab(currentTab) {
      this.currentTab = currentTab;
      this.getImage().then(() => {
        if (currentTab == "active") {
          this.$refs.datatableActive.refresh();
        } else {
          this.$refs.datatableInactive.refresh();
        }
      });
    },
      getImage() {
        return this.$store
          .dispatch(POST_API, {
            data: {
              page: this.currentPage,
              keyword: this.filter,
              rowsPerPage: this.form.rowsPerPage,
              sortBy: this.sortBy ? this.sortBy : "id",
              sortDirection: this.sortDesc ? "desc" : "asc",
              status: this.currentTab,
            },
            api: "/api/upload-image-list",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              // this.showAlert();
              return [];
            } else {
              this.items = this.$store.getters.getResults.pageOfItems;
              this.totalRows = this.$store.getters.getResults.pager.totalItems;
              this.perPage = this.$store.getters.getResults.pager.pageSize;
              this.from = this.$store.getters.getResults.pager.from;
              this.to = this.$store.getters.getResults.pager.to;
              console.log(this.items)
              return this.items;
            }
          });
      },
      deleteImage(id) {
        this.$swal({
          title: "Please Confirm",
          text: "Are you sure you want to delete this record?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#4C8BF5",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
          .then((result) => {
            if (result.value) {
              return this.$store
                .dispatch(POST_API, {
                  data: {
                    id: id,
                  },
                  api: "/api/upload-image-delete",
                })
                .then(() => {
                  if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                  } else {
                    var message = "Image Deleted successfully.";
                    this.refreshDatatable();
                    this.showSuccessAlert(message);
                  }
                });
            }
          })
          .catch((err) => {});
      },

      permissionStaff() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.user_type_id == 2
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.actions = permission.getPermissionStaff(menu, "Upload Image");
          }
        }
      },
      show(action) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          return this.actions.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
    },
  
    mounted() {
      $(".show_filter_arrow .table thead th div").contents().unwrap();
      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
      this.permissionStaff();
    },
  };
  </script>
  