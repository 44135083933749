<template>
    <div>
    <div>
		<div class="dashboard-content-inner my_custom_dash desktop_padding_top_zero" >
			
			<!-- Dashboard Headline -->
			<!-- <div class="dashboard-headline"> -->
				<!-- <h1>Manage Candidates</h1> -->
                <div class="manage_candidates_singlez">
                    <div id="breadcrumb-v2" class="mb-1">
                        <div class="d-flex justify-content-start breadcrumb-wrapper">
                            <b-breadcrumb
                            :items="breadCrumb()"
                            />
                        </div>
                    </div>
                    <span class="margin-top-7" v-if="job_title.length >= 25">Job Applications for <b-link :to="{name: 'employerJobDetail', params: {id: encrypt(job_id)}}" target="_blank" class="job_application_anchor">{{job_title.substring(0,25)+'...' | capitalize}}</b-link></span>
                    <span class="margin-top-7" v-else>Job Applications for <b-link :to="{name: 'employerJobDetail', params: {id: encrypt(job_id)}}" target="_blank" class="job_application_anchor">{{job_title | capitalize}}</b-link></span>
                    <!-- <span class="margin-top-7" v-if="job_title.length >= 25">Job Applications for <b>{{job_title.substring(0,25)+'...' | capitalize}}</b></span>
                    <span class="margin-top-7" v-else>Job Applications for <b>{{job_title | capitalize}}</b></span> -->
                    <!-- Breadcrumbs -->
                    <nav id="breadcrumbs" class="dark employer_nav">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Job</a></li>
                            <li><a href="#">Manage Jobs</a></li>
                            <li>Manage Candidates</li>
                        </ul>
                    </nav>
                </div>
			<!-- </div> -->
	
			<!-- Row -->
			<div class="row">

				<!-- Dashboard Box -->
				<div class="col-xl-12">
					<div class="dashboard-box margin-top-0">

						<!-- Headline -->
						
                        <div class="tab-section all_dropdown_new big_tab_screen">
                        <b-tabs class="my_tab_job new_my_tab_job remove_bx unset_overflow_for_dropdown_hide daily_job_mange_div desktop_margin_top_zero">
                            <!-- <b-tab :title="'NEW (' + activeCount + ')'" :active='new_tab' @click="changeTabStatus('active')" class="reject_last_li ">
                            <div class="content" v-if="userDatas.length > 0">
                                <ul class="dashboard-box-list view_candidate_in_mobile">
                                    <li v-for="userData in userDatas">
                                        <div class="freelancer-overview manage-candidates">
                                            <div class="freelancer-overview-inner">

                                                <div class="freelancer-avatar">
                                                    <div class="verified-badge"></div>
                                                    <b-link :to="{name: 'employer-jobseeker-profile-view', params: {id: encrypt(userData.applicant_id)}}">
                                                        <img v-if="userData.ft_profile_image_thumbnail || userData.ft_profile_image" :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                        <img v-else :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                    </b-link>
                                                </div>

                                                <div class="freelancer-name">
                                                    
                                                    <h4 class="common_cusror_pointer">{{userData.last_name | capitalize}} {{userData.first_name | capitalize}} 
                                                    <span class="flag_pin" v-if="userData.user_gender"><i class="fa fa-mars" v-if="userData.user_gender == 'male'"></i>
                                                    <i class="fa fa-venus" v-else></i></span>
                                                    <span class="salary_emp"  v-if="userData.expected_salary">(${{userData.expected_salary}})</span>
                                                    </h4>

                                                    <span class="freelancer-detail-item"><i class="icon-feather-mail common_i_color"></i><a href="mailto:userData.email"> {{userData.email}}</a></span>
                                                    <span class="freelancer-detail-item"><i class="icon-feather-phone common_i_color"></i><a href="tel:userData.mobile_number">+65-{{userData.mobile_number}}</a></span>

                                                   
                                                    <div class="buttons-to-right manage_btn_mobile_padding mobile_tab_display_flex all_dropdown_new  always-visible margin-top-25 margin-bottom-5 emp_inline_btn">
                                                        <a href="#" @click="viewProfile(encrypt(userData.applicant_id),userData.user_jobs_id,$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a>
                                                        <a :href="userData.cv" target="_blank" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a>
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'shortlisted')">Shortlist</b-dropdown-item>
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <a href="#" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Job Detail</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="text-center" v-else>
                                <p class="no_record no_record_candidate_padding desktop_margin_top_zero desktop_margin_bottom_zero"><b>No Record Found</b> </p>
                            </div>
                            </b-tab> -->
                            <b-tab :title="'APPLIED (' + allCount + ')'" :active='all' @click="changeTabStatus('all')" class="reject_last_li  box2">
                            <div class="content" v-if="userDatas.length > 0">
                                <ul class="dashboard-box-list box1 view_candidate_in_mobile">
                                    <li v-for="userData in userDatas">
                                        <!-- Overview -->
                                        <div class="freelancer-overview manage-candidates">
                                            <div class="freelancer-overview-inner">
                                                <div class="view_candidate_in_mobile_status">
                                                        <!-- <span class="user_status_emp">{{userData.applied_status == 'active' ? 'NEW' : userData.applied_status | capitalizeAll}}</span>-->
                                                        <span class="user_status_emp_new pending_apply_job" v-if="userData.applied_status=='active' && userData.newly_applied=='yes'">NEW</span>
                                                        <span class="user_status_emp_shortlisted shortlisted_apply_job" v-if="userData.applied_status=='shortlisted'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_hired hired_apply_job" v-if="userData.applied_status=='hired'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_rejected declined_apply_job" v-if="userData.applied_status=='declined'">{{userData.applied_status | capitalizeAll}}</span>
                                                    </div>
                                                <!-- Avatar -->
                                                <div class="freelancer-avatar">
                                                    <div class="verified-badge"></div>
                                                    <b-link :to="{name: 'employer-jobseeker-profile-view', params: {id: encrypt(userData.applicant_id)}}">
                                                        <img v-if="userData.ft_profile_image_thumbnail || userData.ft_profile_image" :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                        <img v-else :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                    </b-link>
                                                </div>

                                                <!-- Name -->
                                                <div class="freelancer-name">
                                                    <div class="view_candidate_in_desktop_status">
                                                        <!-- <span class="user_status_emp">{{userData.applied_status == 'active' ? 'NEW' : userData.applied_status | capitalizeAll}}</span>-->
                                                        <span class="user_status_emp_new pending_apply_job" v-if="userData.applied_status=='active' && userData.newly_applied=='yes'">NEW</span>
                                                        <span class="user_status_emp_shortlisted shortlisted_apply_job" v-if="userData.applied_status=='shortlisted'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_hired hired_apply_job" v-if="userData.applied_status=='hired'">{{userData.applied_status | capitalizeAll}}</span>
                                                        <span class="user_status_emp_rejected declined_apply_job" v-if="userData.applied_status=='declined'">{{userData.applied_status | capitalizeAll}}</span>
                                                    </div>
                                                    <h4 class="common_cusror_pointer">{{userData.last_name | capitalize}} {{userData.first_name | capitalize}} 
                                                    <span class="flag_pin" v-if="userData.user_gender"><i class="fa fa-mars" v-if="userData.user_gender == 'male'"></i>
                                                    <i class="fa fa-venus" v-else></i></span>
                                                    <span class="salary_emp"  v-if="userData.expected_salary">(${{userData.expected_salary}})</span>
                                                    </h4>


                                                    
                                                    <!-- Details -->
                                                    <span class="freelancer-detail-item"><i class="icon-feather-mail common_i_color"></i><a href="mailto:userData.email"> {{userData.email}}</a></span>
                                                    <span class="freelancer-detail-item"><i class="icon-feather-phone common_i_color"></i><a href="tel:userData.mobile_number">+65-{{userData.mobile_number}}</a></span>

                                                    <!-- Rating -->
                                                    <!-- <div class="freelancer-rating">
                                                        <div class="star-rating" data-rating="5.0"></div>
                                                    </div> -->

                                                    <!-- Buttons -->
                                                    <div class="desktop_position_unset all_dropdown_new manage_btn_mobile_padding mobile_tab_display_flex  buttons-to-right always-visible margin-top-25 margin-bottom-5 emp_inline_btn">
                                                        <!-- <a href="javascript:void(0);" @click="viewProfile(encrypt(userData.applicant_id),userData.user_jobs_id,$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a> -->
                                                        <a :href="'/v2/employer/jobseeker-profile/view/'+ encrypt(userData.applicant_id,userData.user_jobs_id)" target=_blank class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a>
                                                        <!-- <a :href="userData.cv" target="_blank" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a> -->
                                                        <a href="javascript:void(0);" @click="downloadCV($event,userData.cv,userData.user_jobs_id,)" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a>
                                                        
                                                        <!-- <a href="#small-dialog" class="popup-with-zoom-anim button dark ripple-effect"><i class="icon-feather-mail"></i> Send Message</a> -->
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window" v-if="userData.applied_status == 'active'">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'shortlisted')">Shortlist</b-dropdown-item>
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window" v-if="userData.applied_status == 'shortlisted'">
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window" v-if="userData.applied_status == 'hired'">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window" v-if="userData.applied_status == 'declined'">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'shortlisted')">Shortlist</b-dropdown-item>
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                        </b-dropdown>
                                                        <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Job Detail</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="text-center" v-else>
                                <p class="no_record no_record_candidate_padding desktop_margin_top_zero desktop_margin_bottom_zero"><b>No Record Found</b> </p>
                            </div>
                            </b-tab>
                            <b-tab :title="'SHORTLISTED (' + shortlistedCount + ')'" :active='shortlisted' @click="changeTabStatus('shortlisted')" class="reject_last_li ">
                            <div class="content" v-if="userDatas.length > 0">
                                <ul class="dashboard-box-list view_candidate_in_mobile">
                                    <li v-for="userData in userDatas">
                                        <!-- Overview -->
                                        <div class="freelancer-overview manage-candidates">
                                            <div class="freelancer-overview-inner">

                                                <!-- Avatar -->
                                                <div class="freelancer-avatar">
                                                    <div class="verified-badge"></div>
                                                    <b-link :to="{name: 'employer-jobseeker-profile-view', params: {id: encrypt(userData.applicant_id)}}">
                                                        <img v-if="userData.ft_profile_image_thumbnail || userData.ft_profile_image" :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                        <img v-else :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                    </b-link>
                                                </div>

                                                <!-- Name -->
                                                <div class="freelancer-name">
                                                    <h4 class="common_cusror_pointer">{{userData.last_name | capitalize}} {{userData.first_name | capitalize}} 
                                                    <span class="flag_pin" v-if="userData.user_gender"><i class="fa fa-mars" v-if="userData.user_gender == 'male'"></i>
                                                    <i class="fa fa-venus" v-else></i></span>
                                                    <span class="salary_emp"  v-if="userData.expected_salary">(${{userData.expected_salary}})</span>
                                                    </h4>

                                                    <!-- Details -->
                                                    <span class="freelancer-detail-item"><i class="icon-feather-mail common_i_color"></i><a href="mailto:userData.email"> {{userData.email}}</a></span>
                                                    <span class="freelancer-detail-item"><i class="icon-feather-phone common_i_color"></i><a href="tel:userData.mobile_number">+65-{{userData.mobile_number}}</a></span>

                                                    <!-- Rating -->
                                                    <!-- <div class="freelancer-rating">
                                                        <div class="star-rating" data-rating="5.0"></div>
                                                    </div> -->

                                                    <!-- Buttons -->
                                                    <div class="buttons-to-right manage_btn_mobile_padding mobile_tab_display_flex all_dropdown_new  always-visible margin-top-25 margin-bottom-5 emp_inline_btn">
                                                        <!-- <a href="javascript:void(0);" @click="viewProfile(encrypt(userData.applicant_id),userData.user_jobs_id,$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a> -->
                                                        <a :href="'/v2/employer/jobseeker-profile/view/'+ encrypt(userData.applicant_id,userData.user_jobs_id)" target=_blank  class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a>
                                                        <!-- <a :href="userData.cv" target="_blank" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a> -->
                                                        <a href="javascript:void(0);" @click="downloadCV($event,userData.cv,userData.user_jobs_id,)" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a>
                                                        
                                                        <!-- <a href="#small-dialog" class="popup-with-zoom-anim button dark ripple-effect"><i class="icon-feather-mail"></i> Send Message</a> -->
                                                        <!-- <button class="button gray ripple-effect manage_btn"><i class='fa fa-angle-down'></i>Manage -->
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window">
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Job Detail</a>
                                                        <!-- </button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="text-center" v-else>
                                <p class="no_record no_record_candidate_padding desktop_margin_top_zero desktop_margin_bottom_zero"><b>No Record Found</b> </p>
                            </div>
                            </b-tab>
                            <b-tab :title="'HIRED (' + hiredCount + ')'" :active='hired' @click="changeTabStatus('hired')" class="reject_last_li ">
                            <div class="content" v-if="userDatas.length > 0">
                                <ul class="dashboard-box-list view_candidate_in_mobile">
                                    <li v-for="userData in userDatas">
                                        <!-- Overview -->
                                        <div class="freelancer-overview manage-candidates">
                                            <div class="freelancer-overview-inner">

                                                <!-- Avatar -->
                                                <div class="freelancer-avatar">
                                                    <div class="verified-badge"></div>
                                                    <b-link :to="{name: 'employer-jobseeker-profile-view', params: {id: encrypt(userData.applicant_id)}}">
                                                        <img v-if="userData.ft_profile_image_thumbnail || userData.ft_profile_image" :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                        <img v-else :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                    </b-link>
                                                </div>

                                                <!-- Name -->
                                                <div class="freelancer-name">
                                                    <h4 class="common_cusror_pointer">{{userData.last_name | capitalize}} {{userData.first_name | capitalize}} 
                                                    <span class="flag_pin" v-if="userData.user_gender"><i class="fa fa-mars" v-if="userData.user_gender == 'male'"></i>
                                                    <i class="fa fa-venus" v-else></i></span>
                                                    <span class="salary_emp"  v-if="userData.expected_salary">(${{userData.expected_salary}})</span>
                                                    </h4>

                                                    <!-- Details -->
                                                    <span class="freelancer-detail-item"><i class="icon-feather-mail common_i_color"></i><a href="mailto:userData.email"> {{userData.email}}</a></span>
                                                    <span class="freelancer-detail-item"><i class="icon-feather-phone common_i_color"></i><a href="tel:userData.mobile_number">+65-{{userData.mobile_number}}</a></span>

                                                    <!-- Rating -->
                                                    <!-- <div class="freelancer-rating">
                                                        <div class="star-rating" data-rating="5.0"></div>
                                                    </div> -->

                                                    <!-- Buttons -->
                                                    <div class="buttons-to-right manage_btn_mobile_padding mobile_tab_display_flex all_dropdown_new  always-visible margin-top-25 margin-bottom-5 emp_inline_btn">
                                                        <!-- <a href="javascript:void(0);" @click="viewProfile(encrypt(userData.applicant_id),userData.user_jobs_id,$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a> -->
                                                        <a :href="'/v2/employer/jobseeker-profile/view/'+ encrypt(userData.applicant_id,userData.user_jobs_id)" target=_blank  class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a>
                                                        <!-- <a :href="userData.cv" target="_blank" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a> -->
                                                        <a href="javascript:void(0);" @click="downloadCV($event,userData.cv,userData.user_jobs_id,)" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a>
                                                        
                                                        <!-- <a href="#small-dialog" class="popup-with-zoom-anim button dark ripple-effect"><i class="icon-feather-mail"></i> Send Message</a> -->
                                                        <!-- <button class="button gray ripple-effect manage_btn"><i class='fa fa-angle-down'></i>Manage -->
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'declined')">Decline</b-dropdown-item>
                                                        </b-dropdown>
                                                        <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Job Detail</a>
                                                        <!-- </button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="text-center" v-else>
                                <p class="no_record no_record_candidate_padding desktop_margin_top_zero desktop_margin_bottom_zero"><b>No Record Found</b> </p>
                            </div>
                            </b-tab>
                            <b-tab :title="'DECLINED (' + declinedCount + ')'" :active='declined' @click="changeTabStatus('declined')" class="reject_last_li ">
                            <div class="content" v-if="userDatas.length > 0">
                                <ul class="dashboard-box-list view_candidate_in_mobile">
                                    <li v-for="userData in userDatas">
                                        <!-- Overview -->
                                        <div class="freelancer-overview manage-candidates">
                                            <div class="freelancer-overview-inner">

                                                <!-- Avatar -->
                                                <div class="freelancer-avatar">
                                                    <div class="verified-badge"></div>
                                                    <b-link :to="{name: 'employer-jobseeker-profile-view', params: {id: encrypt(userData.applicant_id)}}">
                                                        <img v-if="userData.ft_profile_image_thumbnail || userData.ft_profile_image" :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                        <img v-else :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                    </b-link>
                                                </div>

                                                <!-- Name -->
                                                <div class="freelancer-name">
                                                    <h4 class="common_cusror_pointer">{{userData.last_name | capitalize}} {{userData.first_name | capitalize}} 
                                                    <span class="flag_pin" v-if="userData.user_gender"><i class="fa fa-mars" v-if="userData.user_gender == 'male'"></i>
                                                    <i class="fa fa-venus" v-else></i></span>
                                                    <span class="salary_emp"  v-if="userData.expected_salary">(${{userData.expected_salary}})</span>
                                                    </h4>

                                                    <!-- Details -->
                                                    <span class="freelancer-detail-item"><i class="icon-feather-mail common_i_color"></i><a href="mailto:userData.email"> {{userData.email}}</a></span>
                                                    <span class="freelancer-detail-item"><i class="icon-feather-phone common_i_color"></i><a href="tel:userData.mobile_number">+65-{{userData.mobile_number}}</a></span>

                                                    <!-- Rating -->
                                                    <!-- <div class="freelancer-rating">
                                                        <div class="star-rating" data-rating="5.0"></div>
                                                    </div> -->

                                                    <!-- Buttons -->
                                                    <div class="buttons-to-right manage_btn_mobile_padding mobile_tab_display_flex all_dropdown_new  always-visible margin-top-25 margin-bottom-5 emp_inline_btn">
                                                        <!-- <a href="javascript:void(0);" @click="viewProfile(encrypt(userData.applicant_id),userData.user_jobs_id,$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a> -->
                                                        <a :href="'/v2/employer/jobseeker-profile/view/'+ encrypt(userData.applicant_id,userData.user_jobs_id)" target=_blank  class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Profile </a>
                                                        <!-- <a :href="userData.cv" target="_blank" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a> -->
                                                        <a href="javascript:void(0);" @click="downloadCV($event,userData.cv,userData.user_jobs_id,)" class="button ripple-effect" v-if = "userData.cv"><i class="icon-feather-file-text"></i> Download CV</a>
                                                        
                                                        <!-- <a href="#small-dialog" class="popup-with-zoom-anim button dark ripple-effect"><i class="icon-feather-mail"></i> Send Message</a> -->
                                                        <b-dropdown class="mx-1 manage_ripple_effect desktop_padding_left_right_12 show_update_status_icon" right text="Update Status" boundary="window">
                                                            <b-dropdown-item v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'shortlisted')">Shortlist</b-dropdown-item>
                                                            <b-dropdown-item  v-on:click="changeStatus(userData.applicant_id,userData.job_start_date,userData.user_jobs_id,'hired')">Hire</b-dropdown-item>
                                                        </b-dropdown>
                                                        <a href="javascript:void(0);" @click="jobDetail(encrypt(userData.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Job Detail</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="text-center" v-else>
                                <p class="no_record no_record_candidate_padding desktop_margin_top_zero desktop_margin_bottom_zero"><b>No Record Found</b> </p>
                            </div>
                            </b-tab>
                            
                        </b-tabs>
                        </div>
					</div>
				</div>

			</div>
			<!-- Row / End -->
            <div class="clearfix"></div>
            <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input="changeTabStatus(activeTab)">
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                        </b-form-select></span>
                    </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                    <ul>
                        <li>
                            <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                        </li>
                    </ul>
                </div>
                <b-pagination @input="changeTabStatus(activeTab)"   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
            </nav>
            <div class="clearfix"></div>
        <!-- <div class="margin-top-70"></div> -->
		</div>
	</div>
    </div>
</template>

<script>
    import { POST_API } from "../../store/actions.type"
    import moment from 'moment'
    import Bus from "../../event-bus";

    export default {

        data() {
            return {
                userDatas: '',
				job_title:'',
				job_id:'',
                activeTab:'all',
                keyword:'',
                error_message:'',
                value: [0, 15000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                new_tab:true,
                shortlisted:false,
                hired:false,
                declined:false,
                all:false,
                activeCount:'',
                shortlistedCount:'',
                hiredCount:'',
                declinedCount:'',
                allCount:'',
                webUrl: process.env.VUE_APP_URL,
            }
        },
        filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        capitalizeAll: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY');
        },
    },

        methods:{   
            breadCrumb(){
                var item = [{
                   to:{name:'manage-jobs'},
                    text: 'Manage Job Ads',
                },{
                    to:null,
                    text: 'Manage Candidates'
                }];
                return item;
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showAlert() {
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            jobDetail(id,event){
                if (event) {
                event.stopPropagation()
                }
                // window.location.replace('/v2/employer/ft-jobdetail/'+ id);
                window.open('/v2/employer/ft-jobdetail/'+ id, '_blank');

            },
            viewProfile(id,user_jobs_id,event){
                if (event) {
                    event.stopPropagation()
                }
                this.changeNewlyAppliedStatus(user_jobs_id);
                // window.location.replace('/v2/employer/jobseeker-profile/view/'+ id);
                window.open('/v2/employer/jobseeker-profile/view/'+ id, '_blank');

            },
            downloadCV(event,cv,user_jobs_id){
                if (event) {
                    event.stopPropagation()
                }
                this.changeNewlyAppliedStatus(user_jobs_id);
                window.open(cv,'_blank');
            },
            changeStatus(applicant_id,job_start_date,user_jobs_id, status) {
            if (status == 'shortlisted') {
                var msg = 'Are you sure you want to shortlist this record?';
                var message='Candidate Shortlisted successfully.';

            }else if (status == 'hired') {
                var msg = 'Are you sure you want to hire this record?';
                var message='Candidate Hired successfully.';
            }  
            else{
                var msg = 'Are you sure you want to decline this record?';
                var message='Candidate Declined successfully.';
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {

                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            applicant_id:applicant_id,   
                            user_jobs_id: user_jobs_id,
                            status:status,
                            job_id:atob(this.$route.params.id),
                            job_title:this.job_title,
                            job_date:job_start_date ? moment(job_start_date).format('DD-MM-YYYY') : null,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/change-candidate-status' 
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            this.showSuccessAlert(message);
                            this.changeTabStatus(this.activeTab);
			                this.getjobsDataById();
			                this.getTotalCount();
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
            changeTabStatus(status) {
            this.activeTab = status;
            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPage,
                        rowsPerPage:this.form.rowsPerPage,
                        jobId:atob(this.$route.params.id),
                        status:status
            },
                api: '/api/userJobs'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {

                    this.userDatas = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    this.getTotalCount();
                    return this.userDatas;
                }
            });
            },
            changeNewlyAppliedStatus(user_jobs_id) {
                return this.$store.dispatch(POST_API, {
                     data:{
                            user_jobs_id: user_jobs_id,
                },
                    api: '/api/changeNewlyAppliedStatus'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        // this.showAlert();
                        return [];
                    } else {

                    }
                });
            },
			getjobsDataById() {
                return this.$store.dispatch(POST_API, {
                     data:{
                            id: atob(this.$route.params.id),
                },
                    api: '/api/adminJobDataById'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        // this.showAlert();
                        return [];
                    } else {
                        this.job_title = this.$store.getters.getResults.data[0].job_title;
                        this.job_id = this.$store.getters.getResults.data[0].jobId;

                    }
                });
            },
            getTotalCount() {
                    return this.$store.dispatch(POST_API, {
                        data:{
                                jobId: atob(this.$route.params.id),
                    },
                        api: '/api/candidateTotalCount'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            // this.showAlert();
                            return [];
                        } else {
                            this.activeCount = this.$store.getters.getResults.activeCount;
                            this.shortlistedCount = this.$store.getters.getResults.shortlistedCount;
                            this.hiredCount = this.$store.getters.getResults.hiredCount;
                            this.declinedCount = this.$store.getters.getResults.declinedCount;
                            this.allCount = this.$store.getters.getResults.allCount;
                        }
                    });
            },
        },
         mounted(){
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Employer - Manage Candidates";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            Bus.$emit('active_users');
            this.changeTabStatus(this.activeTab);
			this.getjobsDataById();
			this.getTotalCount();

        }
    }
</script>

