var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container chatbot_container scroll_container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"chat_section"},[_c('div',{staticClass:"ai_img"},[_c('img',{attrs:{"src":_vm.webUrl+'assets/chatbotImages/Frame 1283.png'}}),_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"ai_img"},[_c('img',{attrs:{"src":_vm.webUrl+'assets/chatbotImages/Frame 1283.png'}}),_vm._m(3)]),_vm._m(4)])])]),_vm._m(5)]),_c('div',{staticClass:"textarea_chatbox_div"},[_c('div',{staticClass:"container chatbot_container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('textarea',{staticClass:"inner_textarea",attrs:{"placeholder":"Your Message"}}),_c('div',{staticClass:"send_icon"},[_c('img',{attrs:{"src":_vm.webUrl+'assets/chatbotImages/tablers.png'}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_bg_chatbot"},[_c('div',{staticClass:"container chatbot_container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"chatbot_whole_div chatbot_dektop_div"},[_c('div',{staticClass:"whole_chatbot_header"},[_c('span',{staticClass:"material-icons-outlined back_arrow_chatbot"},[_vm._v("arrow_back_outlined")]),_c('h2',{staticClass:"chatbot_header_h2"},[_vm._v("Need assistance? Chat with us")]),_c('p',{staticClass:"chatbot_header_p"},[_c('span',{staticClass:"chatbot_status"}),_vm._v("We reply immediately")])])]),_c('div',{staticClass:"chatbot_mobile_div"},[_c('div',{staticClass:"whole_chatbot_header"},[_c('h2',{staticClass:"chatbot_header_h2"},[_c('span',{staticClass:"material-icons-outlined back_arrow_chatbot"},[_vm._v("arrow_back_outlined")]),_c('span',[_vm._v("AI CHATBOT")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatbot_text_section"},[_c('span',{staticClass:"chatbot_text_section_name"},[_vm._v("ai elcy")]),_c('div',{staticClass:"left_side_box_message_chatbot"},[_c('span',[_vm._v("Hi there! I am AI chat assistant Elcy from EL Connect. How can i help you?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatbot_text_right_section"},[_c('p',{staticClass:"user_name_chatbot"},[_vm._v("You")]),_c('div',{staticClass:"right_side_box_message_chatbot"},[_c('span',[_vm._v("Hi can i know how to apply for job?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatbot_text_section"},[_c('span',{staticClass:"chatbot_text_section_name"},[_vm._v("ai elcy")]),_c('div',{staticClass:"left_side_box_message_chatbot"},[_c('span',[_vm._v(" Apply for a job in 4 easy steps: "),_c('ul',[_c('li',[_vm._v("Step 1: Download App")]),_c('li',[_vm._v("Step 2: Create an account ")]),_c('li',[_vm._v("Step 3: Update your profile ")]),_c('li',[_vm._v("Step 4: Browse and apply")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatbot_text_right_section"},[_c('p',{staticClass:"user_name_chatbot"},[_vm._v("You")]),_c('div',{staticClass:"right_side_box_message_chatbot"},[_c('span',[_vm._v("Hi can i know why cant i apply for job? blabla")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{},[_c('div',{staticClass:"support_chatbox"},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("Need help? Contact Support")])])])])])
}]

export { render, staticRenderFns }