import Vue from 'vue';
import axios from "axios";

import VueAxios from "vue-axios";

import JwtService from "../common/jwt.service";
import { API_URL } from "../common/config";

const ApiService = {

    
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        Vue.axios.interceptors.request.use(config => {
            NProgress.start();
        //   console.log(config);
            return config;
        });

        Vue.axios.interceptors.response.use(
            response => {
                NProgress.done();
            //   console.log(response);
                return response;
            }, error => {
                NProgress.done();
            //   console.log(response);
                return error.response;
        });
    },

   setHeader() {
       Vue.axios.defaults.headers.common = {
           'X-Requested-With': 'XMLHttpRequest',
           "Authorization": `Bearer ${JwtService.getToken()}`
       };
   },

   setMultipartHeader() {
       Vue.axios.defaults.headers.common = {
           'X-Requested-With': 'XMLHttpRequest',
           'content-type': 'multipart/form-data',
           "Authorization": `Bearer ${JwtService.getToken()}`,
       };
   },

   get(resource, slug = "") {
       return Vue.axios.get(process.env.VUE_APP_SERVER_URL+`${resource}?nocache=` + new Date().getTime()+`/`).catch(error => {
           throw new Error(`[RWV] ApiService ${error}`);
       });
   },

   post(resource, params) {
       return Vue.axios.post(process.env.VUE_APP_SERVER_URL+`${resource}`, params,{timeout: 500000,maxContentLength: Infinity,maxBodyLength: Infinity});
   },


   put(resource, params) {
       return Vue.axios.put(process.env.VUE_APP_SERVER_URL+`${resource}`, params);
   },
   delete(resource, params) {
       return Vue.axios.delete(process.env.VUE_APP_SERVER_URL+`${resource}`, params);
   }

};


export default ApiService;
