<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Static Page</h1>
            </div> -->
            <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Service Agreement</h1>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                           
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group label="Description" class="required">
                                        <ckeditor v-model="form.description" :config="editorConfig"></ckeditor> 
                                        <!-- <br> -->
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                            <!-- <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="input-group-8" label="Show Banner Image" class="required">
                                        <div class="custom-dropdown">
                                            <b-form-select v-model="form.show_banner_image">
                                                <option value="" disabled>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </b-form-select>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row> -->
                            
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'service-agreement'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
                
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import CKEditor from 'ckeditor4-vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {

    data() {
        return {
            form: {
                title: '',
                show_banner_image:false,
                description: '',
                static_image:'',
                selectedImage:{
                    name:'',
                    image:''
                },
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: '',
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            
            delta: undefined,
            editorConfig: {
                versionCheck : false,
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify,font'
                },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
              // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            },
            webUrl: process.env.VUE_APP_URL,
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true,
        }
    },
    components: {
        ckeditor: CKEditor.component,
        VueCropper
    },
                                                                                                                                                                                                                                                                                                         
    methods: {
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'service-agreement' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                       
                        id: '',
                    },
                    api: '/api/view-service-agreement'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.form.description = this.$store.getters.getResults.data.description;
                        this.id = this.$store.getters.getResults.data.id;


                    }
                });
        },
        
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        description: this.form.description,
                    },
                    api: "/api/service-agreement-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Service Agreement Updated successfully.'
                        this.showSuccessAlert(message);
                        this.$router.push({ name: 'service-agreement' });
                    }
                });
        },
        
        
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Website Contents - Edit Static Page";
        this.getEditData();
    },
};

</script>
