<template>
        

</template>

<script>
    import { POST_API } from "../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import CKEditor from 'ckeditor4-vue';
    import VueRouter from 'vue-router';


    var router = new VueRouter({
        mode: 'history',
        routes: []
    });
    export default {
       
         mounted(){

            this.user_type = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');
            if(this.user_type == 5){
                window.location.replace('/v2/jobseeker/ft-jobdetail/'+ this.$route.query.jobId);
            }else if(this.user_type == 3){
                window.location.replace('/v2/employer/ft-jobdetail/'+ this.$route.query.jobId);

            }else{
                window.location.replace('/singapore-job-ad/'+this.$route.query.company_name +'/'+ this.$route.query.job_title + '/'+this.$route.query.jobId);

                // window.location.replace('/all-jobs-detail/'+ this.$route.query.jobId);
            }
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        }
    }
</script>


