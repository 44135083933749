<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />
    <button @click="submit">Pay now!</button>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";

export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = "pk_test_YNQkEAWJ6zvsJFe1aHvyocIX00qGeVPBsJ";
    return {
      loading: false,
      lineItems: [
        {
          // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: "http://localhost:8080/v2/employer/elcoins",
      cancelURL: "http://localhost:8080/v2/employer/elcoins",
    };
  },
  methods: {
    async submit() {
      const amount = 40;
      const response = await axios.post("http://localhost:3000/create-payment-intent", { amount });
      // var product = await axios.post('http://localhost:3000/create-product');

      // this.pid = response.data;
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },

    async product() {
      const product = await axios.post("http://localhost:3000/create-product");

      this.price_id = product.id;

      const amount = 40;
      const response = await axios.post("http://localhost:3000/create-payment-intent", { amount });
    },
  },

  mounted() {
    this.product();

    this.lineItems[0].price = "price_1O2VoDCOOvYdEcXwfkC208P5";
  },
};
</script>
