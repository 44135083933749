<template>
    <div class="flex-container flex_chat_container">
        <div class="left-container left_chat_container">
            <div class="color_header">
                  <h5>Chat List (Past 14 Days)</h5>
                </div>
            <div class="top p-4">
                
                <div @click="getUserThreads()" class="button count_btns user_btn active_tab_chat common_hover_effect_white_bg">
                <div class="" @click="currentPage = 'user'">
                    User Chats
                </div>
                </div>
                <div @click="getGuestThreads()" class="button count_btns guest_btn common_hover_effect_white_bg">
                <div class="" @click="currentPage = 'guest'">
                    Guest Chats
                </div>
                </div>
            </div>
            <div class="bottom" v-show="currentPage == 'user'">
                <div class="chat">
                    <div class="time-stamp">Users <i class="icon-line-awesome-sort" id="user-thread-sort"
                            @click="handleSortClick()"></i> {{ sortDirection | customSortedDir }}</div>
                    <div class="chat-history user_chat">
                        <ul class="mb-4">
                            <li class="clearfix" v-for="(thread, index) in userThreads">
                                <div class="conversations" :class="'user_conversationz' + index"
                                    :id="'user_conversationz' + index" @click="getMessages(thread, index)">
                                    {{ thread.metadata.user_name }}, {{ thread.metadata.mobile_number }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="bottom" v-show="currentPage == 'guest'">
                <div class="chat">
                    <div class="time-stamp">Guests</div>
                    <div class="chat-history guest_chat">
                        <ul class="mb-4">
                            <li class="clearfix" v-for="(thread, index) in guestThreads">
                                <div class="conversations guest_convo" :class="'guest_conversationz' + index"
                                    :id="'guest_conversationz' + index" @click="getMessages(thread, index)">
                                    {{ thread.created_at | customDate }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-container right_chat_container">
            <div class="color_header">
                <h5>Chat History</h5>
            </div>
            <div class="chat" v-show="chatHistoryShow">
                <div class="chat-history">
                    <div class="time-stamp" v-if="chatHistoryShow">{{ messageArr[messageArr.length - 1].timeStamp |
                        customDate }}</div>
                    <ul class="mb-4" v-for="(msg, index) in messageArr">
                        <li class="clearfix" v-if="msg.role == 'user'">
                            <div class="message-data text-right">
                                <span class="message-data-name">{{ currentChatName }}, {{ msg.timeStamp | customDate
                                    }}</span>
                            </div>
                            <div class="message other-message float-right">{{ msg.message }}</div>
                        </li>
                        <li class="clearfix" v-else>
                            <div class="message-data text-left">
                                <span class="message-data-name">ELCY, {{ msg.timeStamp | customDate
                                    }}</span>
                            </div>
                            <div class="message my-message float-left" id="flex-container"
                                v-html="customMessage(markdown.render(customMessage(msg.message)))">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div v-show="showNoMessage" class="no_conversation_msg">
                No Message for this conversation
            </div> -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import markdownit from 'markdown-it';


export default {
    data() {
        return {
            elcyUrl: process.env.VUE_APP_URL_ELCY,
            userThreads: [],
            sortDirection: "recent",
            guestThreads: [],
            messageArr: [],
            currentPage: "user",
            chatHistoryShow: false,
            showNoMessage: false,
            currentChatName: "",
            markdown: markdownit(),
            olRegExp: new RegExp(/<ol>/g),
            ulRegExp: new RegExp(/<ul>/g),
            aRegExp: new RegExp(/<a/g),
            errMessage: "",
        }
    },
    filters: {
        customDate(date) {
            return moment.unix(date).utcOffset('+08:00').format('DD-MMM-YYYY HH:mm:ss');
        },
        customSortedDir(text) {
            return text.substr(0, 1).toUpperCase() + text.substr(1);
        }
    },
    methods: {
        showAlert() {
            this.$swal({
                position: "center",
                icon: "error",
                title: this.errMessage,
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                this.errMessage = "";
            });
        },
        getUserThreads() {
            axios.get(this.elcyUrl + '/getAllUserThreads')
                .then((res) => {
                    if (res.data) {
                        this.userThreads = res.data.data;
                        this.filterThreads(this.userThreads)
                        this.sortUserThreads(this.userThreads, this.sortDirection);
                        this.getMessages(this.userThreads[0], 0);
                    }
                }).catch(() => {
                    this.errMessage = 'Failed to get user conversations';
                    this.showAlert();
                });
        },
        getGuestThreads() {
            axios.get(this.elcyUrl + '/getAllGuestThreads')
                .then((res) => {
                    if (res.data) {
                        // $(".guest_conversationz0").addClass("active_tab_chat");
                        this.guestThreads = res.data.data;
                        this.filterThreads(this.guestThreads)
                        this.sortUserThreads(this.guestThreads, 'recent');
                        this.getMessages(this.guestThreads[0], 0);
                    }
                }).catch(() => {
                    this.errMessage = 'Failed to get guest conversations';
                    this.showAlert();
                });
        },
        filterThreads(threads) {
            for(let i; i < threads.length-1; i++) {
                if (threads[i].length == 0) {
                    threads.splice(i,1);
                }
            }
        },
        getMessages(thread, index) {
            $("#user_conversationz" + index).addClass("active_tab_chat");
            $("#guest_conversationz" + index).addClass("active_tab_chat");

            //   $("#user_conversationz" + index).on("click", (event) => {
            //     event.preventDefault();
            //     console.log("hello");
            //     $(".conversations").removeClass("active_tab_chat");
            //     $("#user_conversationz" + index).addClass("active_tab_chat");
            //   });
            //   // $('.guest_conversationz'+index).click(function(){
            //   $("#guest_conversationz" + index).on("click", (event) => {
            //     event.preventDefault();
            //     $(".conversations").removeClass("active_tab_chat");
            //     $("#guest_conversationz" + index).addClass("active_tab_chat");
            //   });

            //  $('.user_conversationz'+index).click(function(){

            const thread_id = thread.id;
            this.currentChatName = thread.metadata.user_name;
            axios.post(this.elcyUrl + '/getMessageFromThread', {
                thread_id: thread_id
            })
                .then((res) => {
                    if (res.data) {
                        this.chatHistoryShow = false;
                        this.showNoMessage = false;
                        this.messageArr = res.data.data;
                        if (this.messageArr.length > 0) {
                            this.chatHistoryShow = true;
                        } else {
                            this.showNoMessage = true;
                        }
                    }
                }).catch((err) => {
                    console.log(`err`, err)
                    this.errMessage = 'Failed to get messages';
                    this.showAlert();
                });
        },
        customMessage(msg) {
            if (this.aRegExp.test(msg)) {
                msg = msg.replace(/<a h/g, '<a target="_blank" h');
            }
            // if (this.olRegExp.test(msg)) {
            //     msg = msg.replace(/<ol>/g, '<ol style="display: flex; flex-direction: column; align-item: stretch;">')
            // }
            // if (this.ulRegExp.test(msg)) {
            //     msg = msg.replace(/<ul>/g, '<ul style="display: flex; flex-direction: column; align-item: stretch;">')
            // }
            return msg;
        },
        sortUserThreads(array, direction) {
            if (direction == 'recent') {
                array.sort((thread1, thread2) => {
                    if (moment(moment.unix(thread1.created_at)).isBefore(moment.unix(thread2.created_at))) {
                        return 1
                    }
                    return -1
                });
            } else {
                array.sort((thread1, thread2) => {
                    if (moment(moment.unix(thread1.created_at)).isBefore(moment.unix(thread2.created_at))) {
                        return -1
                    }
                    return 1
                });
            }
        },
        handleSortClick() {
            this.sortDirection = (this.sortDirection == 'recent') ? 'oldest' : 'recent';
            this.sortUserThreads(this.userThreads, this.sortDirection);
        }
    },
    mounted() {
        $(document).ready(function () {
            console.log("hghh");
            $(".conversations").removeClass("active_tab_chat");
            $("#user_conversationz0").addClass("active_tab_chat");
            $("#guest_conversationz0").addClass("active_tab_chat");

            $(".user_chat").on("click", "li .conversations", function () {
                $(".conversations").removeClass("active_tab_chat");
                $(this).addClass("active_tab_chat");
            });

            $(".guest_chat").on("click", "li .conversations", function () {
                $(".conversations").removeClass("active_tab_chat");
                $(this).addClass("active_tab_chat");
            });

            $(".count_btns").click(function () {
                $(".conversations").removeClass("active_tab_chat");
                $("#user_conversationz0").addClass("active_tab_chat");
                $("#guest_conversationz0").addClass("active_tab_chat");
                $(".count_btns").removeClass("active_tab_chat");
                $(this).addClass("active_tab_chat");
            });
        });
        
        this.getUserThreads();
        this.getGuestThreads();
    }
}
</script>

<style>
.flex-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100vh;
}

.left-container {
    order: 1;
    width: 30%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.color_header {
    width: 100%;
    top: 0%;
}

.right-container {
    order: 2;
    border: 2px solid #31d8ee;
    border-radius: 20px;
    width: 68%;
    height: 100vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.top {
    border: 2px solid #31d8ee;
    border-radius: 20px;
    width: 100%;
    height: 15vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0px;
}

.top .text {
    width: 100%;
    text-align: left;
    height: 5px;
    padding-left: 10px;
}

.top .button {
    width: 45%;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
}

.top .button:hover {
    background-color: #7e7a7aab;
}

.bottom {
    border: 2px solid #31d8ee;
    border-radius: 20px;
    width: 100%;
    height: 85vh;
}

.chat .chat-history {
    max-height: 85svh;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
}

.chat .chat-history ul {
    padding: 0;
}

.chat .chat-history ul li {
    list-style: none;
    margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
    margin-bottom: 0px;
}

.chat .chat-history .message-data {
    margin-bottom: 15px;
}

.chat .chat-history .message-data img {
    border-radius: 40px;
    width: 40px;
}

.chat .chat-history .message-data-name {
    color: #434651;
    padding-left: 6px;
}

.chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    display: inline-block;
    position: relative;
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
}

.chat .chat-history .my-message {
    max-width: 80%;
    /* white-space: pre-line; */
    /* overflow-wrap: break-word; */
    background: #e8f1f3;
}

.chat .chat-history .my-message:after {
    bottom: 100%;
    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #e8f1f3;
    border-width: 10px;
    margin-left: -10px;
}

.chat .chat-history .other-message {
    max-width: 80%;
    white-space: pre-line;
    overflow-wrap: break-word;
    text-align: left;
    background: #efefef;
}

.chat .chat-history .other-message:after {
    border-bottom-color: #efefef;
    left: calc(100% - 20px);
}

.chat .chat-message {
    padding: 20px;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.time-stamp {
    width: 100%;
    text-align: center;
    height: 20px;
}

.conversations {
    background: #efefef;
    width: 100%;
    cursor: pointer;
    padding: 10px;
}

#user-thread-sort {
    cursor: pointer
}
</style>