<template>
        
    <div class="web_emp_list_top_div">
        
           <div class="row">
            <div class="container mobile_tab_container_hundered">
               <div class="full-page-container cnt_height common_scroll_content unset_flex_wrap job_list_top_divz new_layout_side">
                        <div class="full-page-sidebar my_sd_bar">
                            <div class="full-page-sidebar-inner" data-simplebar>
                                <div class="sidebar-container remove_pdng jb_side_bar mobile_tab_padding_left_right_zero">
                                    <!-- Keywords -->
                                    <div class="sidebar-widget">
                                        <h3>Keywords</h3>
                                        <div class="keywords-container">
                                            <div class="keyword-input-container">
                                                <input type="text" class="keyword-input" placeholder="e.g. job title" v-model="keyword" v-on:change="submit"/>
                                            </div>
                                            <div class="keywords-list"></div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    
                                    <!-- Category -->
                                    <!-- <div class="sidebar-widget">
                                        <h3>Category</h3>
                                        <b-form-select v-model="skill" class="select_mrgn" v-on:change="submit">
                                            <option value="">All Categories</option>
                                            <option :value="skillsArrs.id" v-for="skillsArrs in skillsArr">{{skillsArrs.name | capitalize}}</option>
                                        </b-form-select>
                                    </div> -->
                                    <div class="sidebar-widget">
                                        <h3>Category</h3>
                                        <b-form-group id="input-group-8" class="required">
                                            <multiselect id="ajax" @input="submit" v-model="skill"  tag-placeholder="Search Category" placeholder="Enter to search Category" :options="skillsArr" label="name" track-by="id" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="true" :show-labels="false" class="job_list_multi_select model_multi_select" ><span slot="noResult">No Category Found.</span></multiselect>
                                        </b-form-group>
                                    </div>
                                    <!-- Location -->
                                    <div class="sidebar-widget">
                                        <h3>Location</h3>
                                        <div class="input-with-icon">
                                            <select v-model="location_id" id="job_location" class="job_location custom-select" v-on:change="submit">    
                                                <option value='' selected='selected' disabled>Select Location</option>
                                                <optgroup :label="location.title" v-for="location in locations">
                                                    <option :value="location.location_names_id[index]" v-for="(area,index) in location.location_names_val">{{area | capitalize}}</option>
                                                </optgroup>
                                            </select>
                                            <!-- <b-form-select v-model="location_id">
                                                <option value=""  disabled>Select Location</option>
                                                <option :value="location.id" v-for="location in locations">{{location.title | capitalize}}</option>
                                            </b-form-select> -->
                                            <!-- <i class="icon-material-outline-location-on"></i> -->
                                        </div>
                                    </div>

                                    
                                    <!-- Job Types -->
                                    <div class="sidebar-widget">
                                        <h3>Job Role Type</h3>
                                        <b-form-select v-model="role_type_id" @change="get_job_type($event)">
                                                    <option value="">All Job Role Types</option>
                                                    <option :value="role_type.id" v-for="role_type in role_types">{{role_type.name | customizeRoleType}}</option>
                                                </b-form-select>
                                        <!-- <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" v-model="daily"><span class="switch-button"></span> Daily</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" v-model="full_time"><span class="switch-button"></span> Full Time</label>
                                            </div> 
                                        </div> -->
                                    </div>

                                    <!-- Salary -->
                                    <div class="sidebar-widget">
                                        <h3>Salary</h3>
                                        <div class="salary_slider"><span>${{this.minimum_val}}-${{this.maximum_val}}</span></div>
                                         <vue-slider v-model="value" :min="min" :max="max" :interval="interval"  @change="get_salary_data($event)" :formatter="formatter"  class="point_slider" style="padding:11px 27px !important"></vue-slider>
                                         <!-- <vue-slider v-model="value" :min="min" :max="max" :formatter="formatter" class="point_slider"></vue-slider> -->
                                   </div>

                                     <div class="sidebar-widget">
                                        <h3>Tags</h3>
                                        <div class="keywords-container">
                                            <div class="keyword-input-container">
                                                 <multiselect v-model="tagArr" @search-change="getTagData($event)" @input="getDataTag($event)" tag-placeholder="Add this as new tag" placeholder="Enter to search tag" label="title" track-by="id" :show-labels=false :options="options" :multiple="true" class="new_multi_slec_list job_list_multi_select" ></multiselect>
                                           </div>
                                        </div>
                                    </div>
                                     <div class=" sidebar-widget mobile_padding_bottom_zero job_list_btn_search mobile_space_between">
                                    <b-button @click="submit" class="button ripple-effect filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                    <b-button @click="clear" class="button ripple-effect filter_btn_widht_120 desktop_margin_right_zero" style="background-color: red; margin-left:20px;">Clear Filter</b-button>

                                </div>

                                 <!-- <div class="sidebar-search-button-container sidebar-widget job_list_btn_search">
                                </div> -->
                                </div>
                            
                               
                                <!-- Search Button / End-->
                            </div>
                        </div>
                        <div class="full-page-content-container job_list_height" data-simplebar>
                            <div class="full-page-content-inner job_list_btm common_padding_30">

                                <h1 class="page-title search_h1">Full Time Jobs</h1>

                                <!-- <div class="notify-box margin-top-15 desktop_margin_bottom_zero">
                                    <div class="switch-container">
                                        <label class="switch"><input type="checkbox"><span class="switch-button"></span><span class="switch-text">Turn on email alerts for this search</span></label>
                                    </div> 
                                </div> -->
                                <div class="content margin-top-30" v-if="jobData.length > 0">
                                    <div class="listings-container new_liting_containerz grid-layout " v-for="jobDatas in jobData" :current-page="currentPage" :per-page="perPage">
                                        <!-- Job Listing -->
                                        <a href="#" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="new_job_listingz job-listing" style="width: 100%;">
                                        <!-- <a href="#"  class="job-listing" style="width: 100%;"> -->

                                            <!-- Job Listing Details -->
                                            <div class="job-listing-details new_job_listing_detailz">
                                                <!-- Logo -->
                                                <div class="job-listing-company-logo new_job_listing_company_logo single_logo_img">
                                                    <img :src="jobDatas.company_logo != null ? jobDatas.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt="company_logo">
                                                </div>
                                                <!-- Details -->
                                                <div class="job_listing_card_inner new_job_listing_card_innerz">
                                                <div class="job-listing-description">

                                                    <!-- <h4 class="job-listing-company bold_heading_iinner suggested_title" v-if="jobDatas.job_title.length >= 25"><b>{{jobDatas.job_title.substring(0,25)+'...' | capitalize}}</b></h4>
                                                    <h4 class="job-listing-company bold_heading_iinner suggested_title" v-else><b>{{jobDatas.job_title | capitalize}}</b></h4> -->
                                                    <h4 class="job-listing-company bold_heading_iinner suggested_title"><b>{{jobDatas.job_title | capitalize}}</b></h4>
                                                    <h3 class="job-listing-title new_web_emp_title" v-if="jobDatas.description">
                                                        <p v-if="jobDatas.description.length >= 250" v-html="jobDatas.description.substring(0,250)+'...' "></p>
                                                        <p v-else v-html="jobDatas.description"></p>
                                                    </h3>
                                                    <!-- <h3 class="job-listing-title"><p v-html="jobDatas.description.substring(0,110)+'...' "></p></h3> -->

                                                </div>
                                                <!-- <div class="star_icon">
                                                     <ul>
                                                    <b-form-checkbox :value="jobDatas.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobDatas.jobId,$event)" :disabled="true"></b-form-checkbox>
                                                </ul>
                                                </div> -->
                                                </div>
                                            </div>

                                            <!-- Job Listing Footer -->
                                            <div class="job-listing-footer">
                                                <!-- <div class="radio-star">
                                                <b-form-group>
                                                    <b-form-checkbox :value="jobDatas.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobDatas.jobId)" :disabled="true"></b-form-checkbox>
                                                </b-form-group>
                                                </div> -->
                                                <!-- <span class="bookmark-icon" @click="bookmarkJob(jobDatas.jobId)"></span> -->
                                               <ul>
                                                    <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{jobDatas.job_address == 'Other' ? jobDatas.other_location : jobDatas.job_address | capitalize}}</li>
                                                    <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{jobDatas.job_type | customizeRoleType}}</li>
                                                    <li class="black_job_color" v-if="jobDatas.min_salary && jobDatas.max_salary"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{jobDatas.min_salary}}-${{jobDatas.max_salary}}</li>
                                                    <li class="black_job_color"><i class="icon-material-outline-access-time common_i_color"></i>{{jobDatas.posted_date | dateTimeAgo | capitalize}}</li>
                                               </ul>
                                            </div>
                                        </a>	
                                        <!-- Job Listing -->
                                    </div>
                                </div>
                                <div class="content text-center" v-else>
                                    <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                </div>

                                <div class="clearfix"></div>
                                <nav class="pagination-box ">
                                    <div class="page-row-box d-inline-block">
                                        <b-form-group id="input-group-4">
                                            <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getjobsData'>
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>75</option>
                                                        <option>100</option>
                                            </b-form-select></span>
                                        </b-form-group>
                                    </div>
                                    <div class="total-page-count d-inline-block">
                                        <ul>
                                            <li>
                                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <b-pagination @input='getjobsData'   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                </nav>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                </div>
			</div>
        </div>
      
    </div>

</template>



<script>
    import { POST_API } from "../../store/actions.type"
    import 'vue-slider-component/theme/default.css'
    import VueSlider from 'vue-slider-component'
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {

        data() {
            return {
                bookmarkVal:'',
                job_id:'',
                bookmarkjobs:[],
                jobData: '',
                skill:[],
                daily:'',
                full_time:'',
                location:'',
                type:'',
                keyword:'',
                salary:'',
                error_message:'',
                skillsArr: [],
                tagArr: [],
                value: [0, 22000],
                minimum_val:0,
                maximum_val:22000,
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                sliderValue: 50,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                locations:[],
                location_id:'',
                webUrl: process.env.VUE_APP_URL,
                role_type_id:'',
                role_types:[],
                company_id: this.$route.params.id,
            }
        },
        components: {
                VueSlider,Multiselect
        },

        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            
            dateTimeAgo: function(value) {
                var m = moment(value);  // or whatever start date you have
                // var today = moment().startOf('day');
                var today = moment();
                    var days = Math.round(moment.duration(today - m).asDays());
                if(days > 0){
                    return days +' Days Ago'
                }else{
                    return 'today'
                }
                // if (!value) return ''
                // const dateTimeAgo = moment(value).fromNow();
                // return dateTimeAgo
		    },
            
            customFormatterOne(date) {
                return moment(date).utc().format('DD-MM-YYYY');
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },
       created() {
            this.min = 0
            this.max = 22000
            this.interval = 1000
            this.formatter = value => `$${value}`
        },

        methods:{   
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            
            getDropdownData(type){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/dropdown-list-category'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                                
                    } else {
                        this.skillsArr = this.$store.getters.getResults.data;
                    
                    }       
                }); 
            },
                
            getjobsData() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPage,
                        rowsPerPage:this.form.rowsPerPage,
                        user_id: this.user_id,
                        company_id: this.company_id,
                        category: this.skill,
            },
                api: '/api/webEmployerJobData'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    var checked = [];
                   
                    this.jobData = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    // $.each(this.jobData, function(key, value) {
                    //     if(value.bookmark =='yes'){
                    //         checked.push (value.jobId );
                    //     }
                    // });
                    // this.bookmarkjobs = checked;
                    return this.jobData;
                }
            });
            },

            getTagData(ev) {

                if(ev){
                    return this.$store.dispatch(POST_API, {
                        data:{
                    },
                    api: '/api/tagData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.options = this.$store.getters.getResults.data;
                    }
                });
                }else{
                        this.options = [];
                }
                this.submit();
                
            },
             get_salary_data(event){
                this.minimum_val = event[0];
                this.maximum_val = event[1];
                if(this.jobTypedata != 2){
                    if(event[1] <= 10000){
                        this.interval = 1000;
                    }else{
                        this.interval = 2000;
                    }
                }
                // this.submit();
            },
            get_job_type(event){
                this.jobTypedata = event;

                if(event == 2){
                    this.value = [1, 100]
                    this.minimum_val = 1;
                    this.maximum_val = 100;
                    this.min = 1;
                    this.max = 100;
                    this.interval = 1
                }
                // this.submit();
            },  
            getDataTag(ev){

                console.log(ev)
            },

            submit() {
                this.$store.dispatch(POST_API, {
                    data:{
                        location: this.location_id,
                        type: this.type,
                        keyword: this.keyword,
                        category: this.skill,
                        salary: this.value,
                        daily: this.daily,
                        tagArr: this.tagArr,
                        full_time: this.full_time,
                        job_type_id:this.role_type_id,
                        page: this.currentPage,
                        rowsPerPage:this.form.rowsPerPage,
                        user_id: this.user_id,
                        company_id: this.company_id
                    },
                    api:'/api/webEmployerJobData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        // this.location = this.$store.getters.getResults.pager.location;
                        this.jobData = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        // return this.jobData;
                    }   
                }); 
            },

            clear(){
                this.location_id = '';
                this.type = '';
                this.keyword = '';
                this.skill = [];
                // this.value = '';
                this.daily = '';
                this.tagArr = '';
                this.role_type_id = '';
                this.full_time = '';
                this.getjobsData();
            },

            getjobRoleType(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/job-role-type'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                                
                    } else {
                        this.role_types = this.$store.getters.getResults.data;
                    }       
                }); 
            },
            
            jobDetail(id,event){

                if (event) {
                event.stopPropagation()
                }
                 window.location.replace('/all-jobs-detail/'+id);

            },
            getLocation(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/location'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                                
                    } else {
                        this.locations = this.$store.getters.getResults.data;
                        this.locations.forEach(element => {
                            if(element.location_names){
                                element.location_names_val = element.location_names.split(',');
                                element.location_names_id = element.location_ids.split(',');
                            }
                        });
                    }       
                }); 
            },
        },
         mounted(){
            this.minimum_val=0;
            this.maximum_val=22000;
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getjobsData();
            this.getDropdownData();
            // this.getTagData();
            this.getLocation();
            this.getjobRoleType();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        }
    }
</script>


