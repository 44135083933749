<template>
    <div class="animated fadeIn">
        <div class="create-new-job-section email-template-section">
            <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Designation</h1>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Title" class="required">
                                        <b-form-input id="title" v-model="form.title" type="text" placeholder="Enter Designation Title.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3">
                            <b-button type="submit" variant="success">Submit</b-button>
                            <b-button :to="{name:'designation-list'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import CKEditor from 'ckeditor4-vue';
import Vue from 'vue';

// import { button, input, select } from 'iview';

export default {

    data() {
        return {
            form: {
                title: '',
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            
            delta: undefined,
            editorConfig: {
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
                },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
              // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            }
        
    
        }
    },
    components: {
        ckeditor: CKEditor.component
    },
    
    methods: {
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        status: this.status,
                        page: this.currentPage,
                        keyword: this.filter,
                        id: this.id,
                    },
                    api: '/api/designation-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.title = this.$store.getters.getResults.data.title;
                        this.form.description = this.$store.getters.getResults.data.description;

                    }
                });
        },
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        title: this.form.title,
                    },
                    api: "/api/designation-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Designation Updated successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'designation-list' });
            });
        },
    },
    mounted() {
       // document.title = "EL Connect - Admin - Database - Edit Designation";
        this.getEditData();
    },
};

</script>
