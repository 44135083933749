<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Add Position Level</h1>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Add Position Level</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Title" class="required">
                                        <b-form-input id="title" v-model="form.title" type="text" placeholder="Enter Position Level Title.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'position-level-list'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";

export default {

    data() {
        return {
            form: {
                title: '',
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: this.$route.params.id,
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            delta: undefined,
        }
    },
    
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'position-level-list'},
                text: 'Position Levels',
            },{
                to:null,
                text: 'Add Position Level'
            }];
            return item;
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        title: this.form.title,
                    },
                    api: "/api/position-level-store",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Position Level Added successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'position-level-list' });
            });
        },
    },
    mounted()
    {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
       // document.title = "EL Connect - Admin - Database - Add Position Level";
    }
};

</script>
