<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Banner</h1>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Banner</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <!-- <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 edit_genneral_stng_dsk"> -->
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading">
            
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group label="Banner Title" class="required">
                                        <b-form-input id="title" v-model="form.banner_title" type="text" placeholder="Enter Banner Title.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="color-group" label="Title Color" class="required">
                                        <b-form-input id="title_color" name="title_color" type="color" placeholder="Choose a title color" v-model="form.title_color">
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="description-group" label="Banner Description" class="required">
                                        <b-form-textarea id="description" name="description" type="text" placeholder="Enter Banner Desription.." v-model="form.banner_description">
                                        </b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="color-group" label="Description Color" class="required">
                                        <b-form-input id="description_color" name="description_color" type="color" placeholder="Choose a description color" v-model="form.description_color">
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="link-group" label="Banner Link Title" class="required">
                                        <b-form-input id="image-link-title" name="image-link-title" type="text" placeholder="Enter Banner Link Title.." v-model="form.image_link_title">
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="link-group" label="Banner Link" class="required">
                                        <b-form-input id="image-link" name="image-link" type="text" placeholder="Enter Banner Link.." v-model="form.image_link">
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" md="6">
                                    <div class="upload-form-group">
                                        <b-form-group id="link-group" label="Upload Banner Image" class="required">
                                            <div class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.selectedImage.image ? 'if_image_exist' : 'if_image_not_exist'">
                                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.banner_image"></b-form-file>
                                                <span v-bind:style="form.selectedImage.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                    <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="banner_image"></b-img> -->
                                                    <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" alt="banner_image" class="actual_img"></b-img>
                                                    <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" alt="banner_image" class="dummy_img"></b-img>
                                                </span>
                                            </div>
                                            <span><b>Note: Banner Image should be of 1900 * 500</b></span>
                                        </b-form-group>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'banner-list'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
                <b-modal ref="crop-modal" title="Edit Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImg">
                        <template>
                              <div>
                                <div class="content" @change="setImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImage">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {

    data() {
        return {
            form: {
                banner_title: '',
                banner_description: '',
                title_color:'#000000',
                description_color:'#000000',
                image_link: '',
                image_link_title:'',
                banner_image: '',
                selectedImage:{
                    name:'',
                    image:''
                },
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            delta: undefined,
            webUrl: process.env.VUE_APP_URL,
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true
        }
    },
    components: {
        VueCropper
    },
    
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'banner-list'},
                text: 'Banners',
            },{
                to:null,
                text: 'Edit Banner'
            }];
            return item;
        },
        cropImage() {
          this.$refs['crop-modal'].hide(); 
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImg = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal'].hide(); 
            this.$refs['crop-modal'].refresh();
        },
        onImageChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.banner_image = input.files[0];
                if (this.form.banner_image.type != 'image/jpeg' && this.form.banner_image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    this.showAlert();
                } else if (this.form.banner_image.size > 5242880) {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Image should be less than 5 MB.';
                    this.form.selectedImage.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.banner_image.name;
                        this.showImg();
                        event.target.value = '';

                    }
                }
            }
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'banner-list' });
            });
        },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        status: this.status,
                        page: this.currentPage,
                        keyword: this.filter,
                        id: this.id,
                    },
                    api: '/api/banner-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.banner_title = this.$store.getters.getResults.data.image_title;
                        this.form.banner_description = this.$store.getters.getResults.data.image_description;
                        this.form.title_color = this.$store.getters.getResults.data.title_color;
                        this.form.description_color = this.$store.getters.getResults.data.description_color;
                        this.form.image_link = this.$store.getters.getResults.data.banner_link;
                        this.form.image_link_title = this.$store.getters.getResults.data.link_title;
                        this.form.selectedImage.image = this.$store.getters.getResults.data.banner_image;
                    }
                });
        },
        
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                     data: {
                        id: this.id,
                        banner_title: this.form.banner_title,
                        banner_description: this.form.banner_description,
                        title_color: this.form.title_color,
                        description_color: this.form.description_color,
                        banner_link: this.form.image_link,
                        banner_image: this.form.selectedImage.image,
                        link_title:this.form.image_link_title,
                        items:this.form,
                    },
                    api: "/api/banner-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Banner Updated successfully.'
                        this.showSuccessAlert(message);
                        this.$router.push({ name: 'banner-list' });
                    }
                });
        },
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Website Contents - Edit Banner";
        this.getEditData();
    },
};

</script>
