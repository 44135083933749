<template>
  <div>
    <nav id="navigation" class="jb_emp_navigation jobseeker_header_new_layout web_navigation">
        <ul id="responsive">
            <li class="current_web_nav"><b-link href="#" class="current_an dropdown_custom_arrow" :class="$route.fullPath=='/' ? 'coloured_dropdown' : 'non_coloured_dropdown'">For Jobseeker</b-link>
            <ul class="dropdown-nav router_current_link_with_anchor">
                <li><b-link href="" :to="{name: 'jobseekers2'}">Jobseekers</b-link></li>
                <!-- <li><b-link href="" :to="{name: 'signup'}">Signup</b-link></li>
                <li><b-link href="" :to="{name: 'login'}">Login</b-link></li> -->
                <li><b-link href="" :to="{name: 'allDailyPaidJoblisting'}">Daily Paid Jobs</b-link></li>
                <li><b-link href="" :to="{name: 'allJobsListing'}">Full Time Jobs</b-link></li>
                <li><a :href="'resume-builder'" target="_blank">Resume Builder</a></li>
            </ul>
            </li>
            <li><b-link href="" :to="{name: 'employer2'}">For Employer</b-link></li>
             <li><b-link href="" :to="{name: 'blog'}">Blog</b-link></li>
             <li><b-link href="" :to="{name: 'Rewards'}">Rewards</b-link></li>
             <li><b-link href="" :to="{name: 'aboutUs'}">About Us</b-link></li>
        </ul>
    </nav>
  </div>
</template>

<script>

export default{
    data() {
		return {
            webUrl: process.env.VUE_APP_URL
        }
	},
	methods:{

	},
    components:{

    },
	mounted()
    {

    }
}

</script>
