<template>
  <div>
    <!-- <div class="container full_container_dsk">
            <div class="row custom_dsk_row"> -->
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Company Whitelists</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Company Whitelists</li>
            </ul>
          </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th common_tab_inline_scroll">
          <b-tab :title="'WHITELIST'" @click="changeTab('whitelist')">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="search_common_margin_bottom_28 col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_18_mobile"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search by Name, Email, Company Name"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>
                <div
                  class="search_common_margin_bottom_28 col-xl-4 search_common_margin_bottom_18_mobile mobile_tab_max_width_flex"
                >
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                    <multiselect
                      id="ajax"
                      v-model="company_whitelist"
                      :custom-label="customLabel"
                      tag-placeholder="Search Company"
                      placeholder="Company"
                      :options="employers"
                      label="full_name"
                      track-by="company_id"
                      @input="changeTab('whitelist')"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      deselect-label="Can't remove this value"
                      open-direction="bottom"
                      class="job_list_multi_select model_multi_select admin_multi_select new_multiselct_height common_black_multiselect_border"
                      ><span slot="noResult">No Employer Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <div
                  class="jobseeker_status_div search_common_margin_bottom_28 col-xl-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <b-form-select
                    v-model="flStatus_whitelist"
                    @change="changeTab('whitelist')"
                    class="common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero date_style_size"
                  >
                    <option value="">Jobseeker Status</option>
                    <option :value="ac_status" v-for="ac_status in accountStatus">
                      {{ ac_status | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="search_btn_div_width search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <b-button
                    @click="filter = searchKeyword"
                    class="search_btn_whitelist mobile_tab_margin_right_zero common_width_100_min_width_100_button button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero"
                    style="width: 120px"
                    >Search</b-button
                  >
                </div>
                <div class="col-xl-12 mobile_tab_max_width_flex">
                  <div class="sidebar-widget penalty_btn desktop_margin_bottom_zero">
                    <div
                      class="search_common_margin_bottom_18_mobile select-all-checkbox whitelist_select employer_multiple_radio"
                      v-if="items && items.length > 0 && showFreelancer('whitelist fl')"
                    >
                      <div class="checkbox_with_trash">
                        <b-form-checkbox
                          v-model="isSelectAllCheckedWhitelist"
                          @change="toggleSelectAllWhitelist"
                        >
                          Select All
                        </b-form-checkbox>
                        <b-link
                          v-if="items && items.length > 0"
                          class="btn green-border-btn trash_btn_whitelist"
                          :class="whitelistRemoveIDArr.length == 0 ? 'disabled' : ''"
                          @click="removeMultipleFromWhitelist()"
                          ><i class="icon-feather-trash-2"></i
                        ></b-link>
                      </div>
                    </div>
                    <b-button
                      v-if="filter || searchKeyword || company_whitelist || flStatus_whitelist"
                      @click="resetFilter()"
                      class="new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero"
                      style="background-color: red"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >
                    <b-button
                      v-if="showFreelancer('whitelist fl')"
                      @click="openInfoModel(null, 'whitelist create')"
                      class="desktop_margin_left_zero search_common_margin_bottom_18_mobile search_common_margin_bottom_28 mobile_tab_margin_left_zero common_width_100_min_width_100_button common_hover_effect_white_bg"
                      >Create</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- <b-link
              v-if="items && items.length > 0"
              class="btn green-border-btn"
              :class="whitelistRemoveIDArr.length == 0 ? 'disabled' : ''"
              @click="removeMultipleFromWhitelist()"
              >Remove</b-link
            > -->

            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!--     <div class="select-all-checkbox" v-if="items && items.length > 0">
                  <b-form-checkbox v-model="isSelectAllCheckedWhitelist" @change="toggleSelectAllWhitelist">
                    Select All
                  </b-form-checkbox>
                </div> -->
                <b-table
                  ref="datatableWhitelist"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getWhitelistEM"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="mobile_min_width_1000 eight_grid_tbl_new"
                >
                  <!-- <template #cell(checkBox)="items">
                                    <b-form-checkbox
                                        @input="singleIDSelectWhitelist($event,items.item.user_id)"
                                    ></b-form-checkbox>
                                </template> -->
                  <!-- <template #head(checkBox)="items">
                                    <b-form-checkbox
                                    v-model="isSelectAllCheckedWhitelist"
                                    @change="toggleSelectAllWhitelist"
                                    ></b-form-checkbox>
                                </template> -->

                  <template #cell(checkBox)="items" v-if="showFreelancer('whitelist fl')">
                    <b-form-checkbox
                      v-model="whitelistRemoveIDArr"
                      :value="items.item.user_id"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(last_name)="items">
                    <div class="new-name-td-box">
                      <div class="whole_div_img_and_name">
                        <div class="manage_user_img">
                          <b-img
                            v-if="items.item.thumbnail || items.item.profile_image"
                            :src="
                              items.item.thumbnail
                                ? items.item.thumbnail
                                : items.item.profile_image
                                ? items.item.profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                          <b-img
                            v-else-if="
                              items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                            "
                            :src="
                              items.item.ft_profile_image_thumbnail
                                ? items.item.ft_profile_image_thumbnail
                                : items.item.ft_profile_image
                                ? items.item.ft_profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>

                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                        </div>
                        <div class="user_name_div">
                          <b-link
                            :to="
                              showFreelancer('view profile')
                                ? {
                                    name: 'jobseeker-dp-profile-view',
                                    params: { id: encrypt(items.item.user_id) },
                                  }
                                : null
                            "
                            target="_blank"
                            style="color: #4c8bf5"
                          >
                            {{ items.item.last_name | capitalize }}
                            {{ items.item.first_name | capitalize }}
                          </b-link>
                        </div>
                      </div>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </div>
                  </template>
                  <template #cell(mobile_number)="items">
                    <b-link
                      :href="'https://wa.me/+65' + items.item.mobile_number"
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                      {{ items.item.country_code }}-{{ items.item.mobile_number }}
                    </b-link>
                  </template>
                  <template #cell(other_description)="items">
                    {{ items.item.other_description ? items.item.other_description : "-" }}
                  </template>
                  <template #cell(user_status)="items">
                    <p class="green_status" v-if="items.item.user_status == 'active'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="orange__status" v-if="items.item.user_status == 'inactive'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'deleted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'blacklisted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                  </template>

                  <template #cell(name)="items">
                    {{ items.item.company_name | capitalize }}
                  </template>

                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <!-- <b-dropdown-item v-if="items.item.user_status != 'deleted' && showFreelancer('restrict companies')" @click="openInfoModel(items.item.user_id,'restrict',items.item.restricted_companies)">Ban From EM
                                                </b-dropdown-item>
                                                <b-dropdown-item v-if="items.item.user_status != 'deleted' && showFreelancer('link companies')" @click="openInfoModel(items.item.user_id,'link',items.item.linked_companies)">View Only EM
                                                </b-dropdown-item> -->
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' && showFreelancer('whitelist fl')
                          "
                          @click="
                            openInfoModel(
                              items.item.user_id,
                              'whitelist',
                              items.item.whitelisted_companies
                            )
                          "
                          >Whitelist FL
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' && showFreelancer('whitelist fl')
                          "
                          @click="removeFromWhitelistCompanies(items.item.user_id)"
                          >Remove from Whitelist
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPage" @change="getWhitelistEM">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'BAN FROM EM'" @click="changeTab('ban')">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterBan = searchKeywordBan"
                        v-model="searchKeywordBan"
                        placeholder="Search by Name, Email, Company Name"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterBan = searchKeywordBan"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>
                <div class="col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                    <multiselect
                      id="ajax"
                      v-model="company_ban"
                      :custom-label="customLabel"
                      tag-placeholder="Search Company"
                      placeholder="Company"
                      :options="employers"
                      label="full_name"
                      track-by="company_id"
                      @input="changeTab('ban')"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      deselect-label="Can't remove this value"
                      open-direction="bottom"
                      class="job_list_multi_select model_multi_select admin_multi_select new_multiselct_height common_black_multiselect_border"
                      ><span slot="noResult">No Employer Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <div
                  class="jobseeker_status_div search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="flStatus_ban"
                    @change="changeTab('ban')"
                    class="common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="">Jobseeker Status</option>
                    <option :value="ac_status" v-for="ac_status in accountStatus">
                      {{ ac_status | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="search_btn_div_width search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <!-- <div class="sidebar-widget tab_display_block_btn mobile_space_between penalty_btn desktop_margin_bottom_zero"> -->
                  <b-button
                    @click="filterBan = searchKeywordBan"
                    class="btn search_btn_whitelist mobile_tab_margin_right_zero common_width_100_min_width_100_button button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero btn-secondary"
                    style="width: 120px"
                    >Search</b-button
                  >

                  <!-- </div> -->
                </div>

                <div class="col-xl-12 col-md-12 mobile_tab_max_width_flex">
                  <div class="sidebar-widget penalty_btn desktop_margin_bottom_zero">
                    <div
                      class="search_common_margin_bottom_18_mobile select-all-checkbox whitelist_select employer_multiple_radio"
                      v-if="itemsBan && itemsBan.length > 0 && showFreelancer('restrict companies')"
                    >
                      <div class="checkbox_with_trash">
                        <b-form-checkbox
                          v-model="isSelectAllCheckedBan"
                          @change="toggleSelectAllBan"
                        >
                          Select All
                        </b-form-checkbox>
                        <b-link
                          v-if="itemsBan && itemsBan.length > 0"
                          class="btn green-border-btn trash_btn_whitelist"
                          :class="banRemoveIDArr.length == 0 ? 'disabled' : ''"
                          @click="removeMultipleFromBan()"
                          ><i class="icon-feather-trash-2"></i
                        ></b-link>
                      </div>
                    </div>
                    <b-button
                      v-if="filterBan || searchKeywordBan || company_ban || flStatus_ban"
                      @click="resetFilter()"
                      class="btn new_reset_text big_reset_txt  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero btn-secondary"
                      style="background-color: red"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >

                    <b-button
                      v-if="showFreelancer('restrict companies')"
                      @click="openInfoModel(null, 'ban create')"
                      class="desktop_margin_left_zero search_common_margin_bottom_18_mobile search_common_margin_bottom_28 mobile_tab_margin_left_zero common_width_100_min_width_100_button common_hover_effect_white_bg"
                      >Create</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Page Content
                                ================================================== -->
            <!-- <div class="container full_container_dsk">
                                <div class="row"> -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getJobPosters" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_field_of_study new_box_tbl_mrgn nationality_tbl_width"> -->
                <b-table
                  ref="datatableBan"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getBanEM"
                  :fields="fieldsBanLinked"
                  :current-page="currentPageBan"
                  :per-page="perPageBan"
                  :filter="filterBan"
                  :sort-by.sync="sortByBan"
                  :sort-desc.sync="sortDescBan"
                  :sort-direction="sortDirectionBan"
                  class="mobile_min_width_1000 eight_grid_tbl_new"
                >
                  <template #cell(checkBox)="items" v-if="showFreelancer('restrict companies')">
                    <b-form-checkbox
                      v-model="banRemoveIDArr"
                      :value="items.item.user_id"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(last_name)="items">
                    <div class="new-name-td-box">
                      <div class="whole_div_img_and_name">
                        <div class="manage_user_img">
                          <b-img
                            v-if="items.item.thumbnail || items.item.profile_image"
                            :src="
                              items.item.thumbnail
                                ? items.item.thumbnail
                                : items.item.profile_image
                                ? items.item.profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                          <b-img
                            v-else-if="
                              items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                            "
                            :src="
                              items.item.ft_profile_image_thumbnail
                                ? items.item.ft_profile_image_thumbnail
                                : items.item.ft_profile_image
                                ? items.item.ft_profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>

                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                        </div>
                        <div class="user_name_div">
                          <b-link
                            :to="
                              showFreelancer('view profile')
                                ? {
                                    name: 'jobseeker-dp-profile-view',
                                    params: { id: encrypt(items.item.user_id) },
                                  }
                                : null
                            "
                            target="_blank"
                            style="color: #4c8bf5"
                          >
                            {{ items.item.last_name | capitalize }}
                            {{ items.item.first_name | capitalize }}
                          </b-link>
                        </div>
                      </div>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </div>
                  </template>
                  <template #cell(mobile_number)="items">
                    <b-link
                      :href="'https://wa.me/+65' + items.item.mobile_number"
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                      {{ items.item.country_code }}-{{ items.item.mobile_number }}
                    </b-link>
                  </template>
                  <template #cell(other_description)="items">
                    {{ items.item.other_description ? items.item.other_description : "-" }}
                  </template>
                  <template #cell(user_status)="items">
                    <p class="green_status" v-if="items.item.user_status == 'active'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="orange__status" v-if="items.item.user_status == 'inactive'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'deleted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'blacklisted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                  </template>

                  <template #cell(name)="items">
                    {{ items.item.company_name | capitalize }}
                  </template>

                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' &&
                            showFreelancer('restrict companies')
                          "
                          @click="
                            openInfoModel(
                              items.item.user_id,
                              'restrict',
                              items.item.restricted_companies
                            )
                          "
                          >Ban From EM
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' &&
                            showFreelancer('restrict companies')
                          "
                          @click="removeFromBanLinkCompanies('restrict', items.item.user_id)"
                          >Remove from Restricted list
                        </b-dropdown-item>
                        <!-- <b-dropdown-item v-if="items.item.user_status != 'deleted' && showFreelancer('link companies')" @click="openInfoModel(items.item.user_id,'link',items.item.linked_companies)">View Only EM
                                                        </b-dropdown-item>
                                                        <b-dropdown-item v-if="items.item.user_status != 'deleted' && showFreelancer('whitelist fl')" @click="openInfoModel(items.item.user_id,'whitelist',items.item.whitelisted_companies)">Whitelist FL
                                                        </b-dropdown-item> -->
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPageBan" @change="getBanEM">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsBan != 0"
                          >{{ fromBan }} - {{ toBan }} of {{ totalRowsBan }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsBan"
                    :per-page="perPageBan"
                    v-model="currentPageBan"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
          <b-tab :title="'VIEW ONLY EM'" @click="changeTab('linked')">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterLinked = searchKeywordLinked"
                        v-model="searchKeywordLinked"
                        placeholder="Search by Name, Email, Company Name"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterLinked = searchKeywordLinked"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>
                <div class="col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                    <multiselect
                      id="ajax"
                      v-model="company_linked"
                      :custom-label="customLabel"
                      tag-placeholder="Search Company"
                      placeholder="Company"
                      :options="employers"
                      label="full_name"
                      track-by="company_id"
                      @input="changeTab('linked')"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      deselect-label="Can't remove this value"
                      open-direction="bottom"
                      class="job_list_multi_select model_multi_select admin_multi_select new_multiselct_height common_black_multiselect_border"
                      ><span slot="noResult">No Employer Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <div
                  class="jobseeker_status_div search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <!-- <span>DP Status:</span> -->
                  <b-form-select
                    v-model="flStatus_linked"
                    @change="changeTab('linked')"
                    class="common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="">Jobseeker Status</option>
                    <option :value="ac_status" v-for="ac_status in accountStatus">
                      {{ ac_status | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="search_btn_div_width search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <!-- <div
                    class="sidebar-widget tab_display_block_btn mobile_space_between penalty_btn desktop_margin_bottom_zero"
                  > -->
                  <b-button
                    @click="filterLinked = searchKeywordLinked"
                    class="btn search_btn_whitelist mobile_tab_margin_right_zero common_width_100_min_width_100_button button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero btn-secondary"
                    style="width: 120px"
                    >Search</b-button
                  >

                  <!-- </div> -->
                </div>

                <div class="col-xl-12 col-md-12 mobile_tab_max_width_flex">
                  <div class="sidebar-widget penalty_btn desktop_margin_bottom_zero">
                    <div
                      class="search_common_margin_bottom_18_mobile select-all-checkbox whitelist_select employer_multiple_radio"
                      v-if="
                        itemsLinked && itemsLinked.length > 0 && showFreelancer('link companies')
                      "
                    >
                      <div class="checkbox_with_trash">
                        <b-form-checkbox
                          v-model="isSelectAllCheckedLink"
                          @change="toggleSelectAllLink"
                        >
                          Select All
                        </b-form-checkbox>
                        <b-link
                          v-if="itemsLinked && itemsLinked.length > 0"
                          class="btn green-border-btn trash_btn_whitelist"
                          :class="linkRemoveIDArr.length == 0 ? 'disabled' : ''"
                          @click="removeMultipleFromLink()"
                          ><i class="icon-feather-trash-2"></i
                        ></b-link>
                      </div>
                    </div>
                    <b-button
                      v-if="
                        filterLinked || searchKeywordLinked || company_linked || flStatus_linked
                      "
                      @click="resetFilter()"
                      class="btn new_reset_text big_reset_txt  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero btn-secondary"
                      style="background-color: red"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >
                    <b-button
                      v-if="showFreelancer('link companies')"
                      @click="openInfoModel(null, 'link create')"
                      class="desktop_margin_left_zero search_common_margin_bottom_18_mobile search_common_margin_bottom_28 mobile_tab_margin_left_zero common_width_100_min_width_100_button common_hover_effect_white_bg"
                      >Create</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Page Content
                                ================================================== -->
            <!-- <div class="container full_container_dsk">
                                <div class="row"> -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getJobPosters" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_field_of_study new_box_tbl_mrgn nationality_tbl_width"> -->
                <b-table
                  ref="datatableLinked"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getLinkedEM"
                  :fields="fieldsBanLinked"
                  :current-page="currentPageLinked"
                  :per-page="perPageLinked"
                  :filter="filterLinked"
                  :sort-by.sync="sortByLinked"
                  :sort-desc.sync="sortDescLinked"
                  :sort-direction="sortDirectionLinked"
                  class="mobile_min_width_1000 eight_grid_tbl_new"
                >
                  <template #cell(checkBox)="items" v-if="showFreelancer('link companies')">
                    <b-form-checkbox
                      v-model="linkRemoveIDArr"
                      :value="items.item.user_id"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(last_name)="items">
                    <div class="new-name-td-box">
                      <div class="whole_div_img_and_name">
                        <div class="manage_user_img">
                          <b-img
                            v-if="items.item.thumbnail || items.item.profile_image"
                            :src="
                              items.item.thumbnail
                                ? items.item.thumbnail
                                : items.item.profile_image
                                ? items.item.profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                          <b-img
                            v-else-if="
                              items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                            "
                            :src="
                              items.item.ft_profile_image_thumbnail
                                ? items.item.ft_profile_image_thumbnail
                                : items.item.ft_profile_image
                                ? items.item.ft_profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>

                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                        </div>
                        <div class="user_name_div">
                          <b-link
                            :to="
                              showFreelancer('view profile')
                                ? {
                                    name: 'jobseeker-dp-profile-view',
                                    params: { id: encrypt(items.item.user_id) },
                                  }
                                : null
                            "
                            target="_blank"
                            style="color: #4c8bf5"
                          >
                            {{ items.item.last_name | capitalize }}
                            {{ items.item.first_name | capitalize }}
                          </b-link>
                        </div>
                      </div>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </div>
                  </template>
                  <template #cell(mobile_number)="items">
                    <b-link
                      :href="'https://wa.me/+65' + items.item.mobile_number"
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                      {{ items.item.country_code }}-{{ items.item.mobile_number }}
                    </b-link>
                  </template>
                  <template #cell(other_description)="items">
                    {{ items.item.other_description ? items.item.other_description : "-" }}
                  </template>
                  <template #cell(user_status)="items">
                    <p class="green_status" v-if="items.item.user_status == 'active'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="orange__status" v-if="items.item.user_status == 'inactive'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'deleted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'blacklisted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                  </template>

                  <template #cell(name)="items">
                    {{ items.item.company_name | capitalize }}
                  </template>

                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <!-- <b-dropdown-item v-if="items.item.user_status != 'deleted' && showFreelancer('restrict companies')" @click="openInfoModel(items.item.user_id,'restrict',items.item.restricted_companies)">Ban From EM
                                                        </b-dropdown-item> -->
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' && showFreelancer('link companies')
                          "
                          @click="
                            openInfoModel(items.item.user_id, 'link', items.item.linked_companies)
                          "
                          >View Only EM
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' && showFreelancer('link companies')
                          "
                          @click="removeFromBanLinkCompanies('link', items.item.user_id)"
                          >Remove from Linked list
                        </b-dropdown-item>
                        <!-- <b-dropdown-item v-if="items.item.user_status != 'deleted' && showFreelancer('whitelist fl')" @click="openInfoModel(items.item.user_id,'whitelist',items.item.whitelisted_companies)">Whitelist FL
                                                        </b-dropdown-item> -->
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPageLinked" @change="getLinkedEM">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsLinked != 0"
                          >{{ fromLinked }} - {{ toLinked }} of {{ totalRowsLinked }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsLinked"
                    :per-page="perPageLinked"
                    v-model="currentPageLinked"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
    <b-modal
      ref="restrict-modal"
      title="Ban From EM"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="restricted_companies"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="companiesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="restricted_companies"
                  :allow-empty="true"
                  @input="mapCompId(restricted_companies)"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="restrict_remark"
                  @change="(v) => (restrict_remark = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
            <!-- <b-button v-if="designation != 'Customer Service'" type="button" @click="saveCompanies('restrict')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button> -->
            <b-button
              type="button"
              @click="saveCompanies('restrict')"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="whitelist-modal"
      title="Whitelist Companies"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="whitelisted_companies"
                  id="ajax"
                  :disabled="whitelist_disable_companies"
                  label="name"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="companiesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="whitelisted_companies"
                  :allow-empty="true"
                  @input="mapCompId(whitelisted_companies)"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="whitelist_all_companies"
                  @input="whitelistAllCompanies($event)"
                  ><strong>All Companies</strong></b-form-checkbox
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="whitelist_remark"
                  @change="(v) => (whitelist_remark = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            class="pt-0 mt-0 text-md-left text-center model_margin_top_zero common_crop_reset_btn"
          >
            <b-button
              type="button"
              v-if="designation != 'Customer Service'"
              @click="saveWhitelistCompanies('whitelist')"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="link-modal"
      title="View Only EM"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="linked_companies"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="companiesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="linked_companies"
                  :allow-empty="true"
                  @input="mapCompId(linked_companies)"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="view_remark"
                  @change="(v) => (view_remark = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            class="pt-0 mt-0 text-md-left text-center model_margin_top_zero common_crop_reset_btn"
          >
            <!-- <b-button type="button" v-if="designation != 'Customer Service'" @click="saveCompanies('link')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button> -->
            <b-button
              type="button"
              @click="saveCompanies('link')"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="create-whitelist-modal"
      title="Company Whitelist"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="create_whitelist_companies"
                  id="ajax"
                  label="name"
                  :custom-label="customLabel"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="createWhitelistCompaniesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="create_whitelist_companies"
                  :allow-empty="true"
                  @input="mapCompId(create_whitelist_companies)"
                  @open="handleOpenComFL"
                  @search-change="handleSearchChangeCom"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Freelancer"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="create_whitelist_fl"
                  id="ajax"
                  label="last_name"
                  :custom-label="customLabelFL"
                  track-by="id"
                  placeholder="Type to search Freelancer"
                  open-direction="bottom"
                  :options="createWhitelistFLList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="create_whitelist_fl"
                  :allow-empty="true"
                  @input="mapCompId(create_whitelist_fl)"
                  @open="handleOpenComFL"
                  @search-change="handleSearchChangeFL"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="create_whitelist_remarks"
                  @change="(v) => (create_whitelist_remarks = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
            <center>
              <b-button
                type="button"
                @click="storeMultipleWhitelistCompanies()"
                variant="success"
                class="button_min_width_130 model_common_bt_success_color x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
                >Submit</b-button
              >
            </center>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="create-ban-linked-modal"
      :title="createBanLinkedTitle"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="create_ban_linked_companies"
                  id="ajax"
                  label="name"
                  :custom-label="customLabel"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="createWhitelistCompaniesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="create_ban_linked_companies"
                  :allow-empty="true"
                  @input="mapCompId(create_ban_linked_companies)"
                  @open="handleOpenComFL"
                  @search-change="handleSearchChangeCom"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Freelancer"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="create_ban_linked_fl"
                  id="ajax"
                  label="last_name"
                  :custom-label="customLabelFL"
                  track-by="id"
                  placeholder="Type to search Freelancer"
                  open-direction="bottom"
                  :options="createWhitelistFLList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="create_ban_linked_fl"
                  :allow-empty="true"
                  @input="mapCompId(create_ban_linked_fl)"
                  @open="handleOpenComFL"
                  @search-change="handleSearchChangeFL"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="create_ban_linked_remarks"
                  @change="(v) => (create_ban_linked_remarks = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
            <center>
              <b-button
                type="button"
                @click="storeMultipleBanLinkedCompanies(restrict)"
                variant="success"
                class="button_min_width_130 model_common_bt_success_color x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
                >Submit</b-button
              >
            </center>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { POST_API } from "../../../../store/actions.type";
import permission from "../../../../../../server/permission.js";
import Multiselect from "vue-multiselect";

import moment from "moment";

export default {
  data() {
    return {
      fields: [
        { key: "checkBox", label: "Select All", sortable: false, thStyle: { width: "8%" } },
        { key: "last_name", label: "Name & Email", sortable: true, thStyle: { width: "22%" } },
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "15%" } },
        {
          key: "user_status",
          label: "Jobseeker Status",
          sortable: true,
          thStyle: { width: "15%" },
        },
        { key: "company_names", label: "Company Name", sortable: false, thStyle: { width: "20%" } },
        { key: "remarks", label: "Remarks", sortable: true, thStyle: { width: "15%" } },
        { key: "toggleAction", label: "Edit" },
      ],
      fieldsBanLinked: [
        { key: "checkBox", label: "Select All", sortable: false, thStyle: { width: "8%" } },
        { key: "last_name", label: "Name & Email", sortable: true, thStyle: { width: "22%" } },
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "15%" } },
        {
          key: "user_status",
          label: "Jobseeker Status",
          sortable: true,
          thStyle: { width: "15%" },
        },
        { key: "company_names", label: "Company Name", sortable: false, thStyle: { width: "20%" } },
        { key: "remarks", label: "Remarks", sortable: true, thStyle: { width: "15%" } },
        { key: "toggleAction", label: "Edit" },
      ],
      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,

      pagerBan: {},
      pageOfItemsBan: [],
      itemsBan: null,
      totalRowsBan: 0,
      fromBan: "",
      toBan: "",
      currentPageBan: 1,
      searchKeywordBan: "",
      perPageBan: 25,
      pageOptionsBan: [25, 50, 75, 100],
      sortByBan: null,
      sortDirectionBan: "desc",
      filterBan: "",
      sortDescBan: true,

      pagerLinked: {},
      pageOfItemsLinked: [],
      itemsLinked: null,
      totalRowsLinked: 0,
      fromLinked: "",
      toLinked: "",
      currentPageLinked: 1,
      searchKeywordLinked: "",
      perPageLinked: 25,
      pageOptionsLinked: [25, 50, 75, 100],
      sortByLinked: null,
      sortDirectionLinked: "desc",
      filterLinked: "",
      statusLinked: "active",
      sortDescLinked: true,

      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
        rowsPerPageBan: 25,
        rowsPerPageLinked: 25,
        user_id: "",
      },
      currentTab: "whitelist",
      actions: "",
      actionsFreelancer: "",
      employers: [],
      accountStatus: ["active", "inactive", "blacklisted"],
      company_whitelist: "",
      company_ban: "",
      company_linked: "",
      flStatus_whitelist: "",
      flStatus_ban: "",
      flStatus_linked: "",
      restricted_companies: "",
      linked_companies: "",
      whitelisted_companies: "",
      whitelist_disable_companies: false,
      whitelist_all_companies: false,
      restrict_remark: "",
      view_remark: "",
      whitelist_remark: "",
      restrict: false,
      companiesList: "",
      rest_user_id: "",
      designation: "",
      whitelistRemoveIDArr: [],
      isSelectAllCheckedWhitelist: false,
      create_whitelist_companies: [],
      create_whitelist_fl: [],
      create_whitelist_remarks: "",
      createWhitelistCompaniesList: [],
      createWhitelistFLList: [],
      is_whitelist: "no",
      isCompanyListLoaded: false,
      isFLListLoaded: false,
      searchDataCompany: "",
      searchDataFL: "",
      createBanLinkedTitle: "Ban From EM",
      banRemoveIDArr: [],
      isSelectAllCheckedBan: false,
      linkRemoveIDArr: [],
      isSelectAllCheckedLink: false,
      create_ban_linked_companies: [],
      create_ban_linked_fl: [],
      create_ban_linked_remarks: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      if (date != null) return moment(date).utc().format("DD MMM YYYY hh:mm A");
      else return "-";
    },
  },
  components: {
    Multiselect,
  },
  watch: {
    whitelistRemoveIDArr(newSelectedItems) {
      this.isSelectAllCheckedWhitelist = newSelectedItems.length === this.items.length;
    },
    items(newItems) {
      if (newItems.length !== this.whitelistRemoveIDArr.length) {
        this.isSelectAllCheckedWhitelist = false;
      }
    },
    banRemoveIDArr(newSelectedItems) {
      this.isSelectAllCheckedBan = newSelectedItems.length === this.itemsBan.length;
    },
    itemsBan(newItems) {
      if (newItems.length !== this.banRemoveIDArr.length) {
        this.isSelectAllCheckedBan = false;
      }
    },
    linkRemoveIDArr(newSelectedItems) {
      this.isSelectAllCheckedLink = newSelectedItems.length === this.itemsLinked.length;
    },
    itemsLinked(newItems) {
      if (newItems.length !== this.linkRemoveIDArr.length) {
        this.isSelectAllCheckedLink = false;
      }
    },
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.refreshDataTable();
        // this.$router.push({ name: 'job-poster-list' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    customLabelCompany({ name }) {
      return `${name}`;
    },
    // customLabelFL({ first_name, last_name }) {
    //   return `${last_name} ${first_name}`;
    // },
    customLabelFL({ full_name, first_name, last_name, country_code, mobile_number }) {
      // return `${full_name} (${country_code}-${mobile_number})`;
      return `${last_name} ${first_name} (${country_code}-${mobile_number})`;
    },
    changeTab(currentTab) {
      this.currentTab = currentTab;
      this.isSelectAllCheckedWhitelist = false;
      this.whitelistRemoveIDArr = [];
      this.isSelectAllCheckedBan = false;
      this.banRemoveIDArr = [];
      this.isSelectAllCheckedLink = false;
      this.linkRemoveIDArr = [];
      if (this.currentTab == "whitelist") {
        this.$refs.datatableWhitelist.refresh();
      } else {
        if (this.currentTab == "ban") {
          // console.log(this.company_ban, "company_bancompany_ban");
          // this.getBanEM().then(() => {
          this.$refs.datatableBan.refresh();
          // });
        } else {
          // this.getLinkedEM().then(() => {
          this.$refs.datatableLinked.refresh();
          // });
        }
      }
    },
    whitelistAllCompanies($event) {
      if ($event != null) {
        this.whitelist_all_companies = $event;
      } else {
        this.whitelist_all_companies = $event;
      }
      if (this.whitelist_all_companies) {
        this.whitelist_disable_companies = true;
        this.whitelisted_companies = [];
      } else {
        this.whitelist_disable_companies = false;
      }
    },
    resetFilter() {
      if (this.currentTab == "whitelist") {
        this.company_whitelist = "";
        this.flStatus_whitelist = "";
        this.filter = "";
        this.searchKeyword = "";
        this.$refs.datatableWhitelist.refresh();
      } else {
        if (this.currentTab == "ban") {
          this.company_ban = "";
          this.flStatus_ban = "";
          this.filterBan = "";
          this.searchKeywordBan = "";
          this.$refs.datatableBan.refresh();
        } else {
          this.company_linked = "";
          this.flStatus_linked = "";
          this.filterLinked = "";
          this.searchKeywordLinked = "";
          this.$refs.datatableLinked.refresh();
        }
      }
    },
    refreshDataTable() {
      this.rest_user_id = "";
      this.whitelisted_companies = "";
      this.whitelist_all_companies = false;
      this.whitelist_disable_companies = false;
      this.restricted_companies = "";
      this.linked_companies = "";
      this.restrict = "";
      this.restrict_remark = "";
      this.view_remark = "";
      this.whitelist_remark = "";
      this.isSelectAllCheckedWhitelist = false;
      this.whitelistRemoveIDArr = [];
      this.isSelectAllCheckedBan = false;
      this.banRemoveIDArr = [];
      this.isSelectAllCheckedLink = false;
      this.linkRemoveIDArr = [];
      this.create_whitelist_companies = [];
      this.create_whitelist_fl = [];
      this.create_whitelist_remarks = "";
      this.create_ban_linked_companies = [];
      this.create_ban_linked_fl = [];
      this.create_ban_linked_remarks = "";
      this.$refs.datatableWhitelist.refresh();
      this.$refs.datatableBan.refresh();
      this.$refs.datatableLinked.refresh();
    },
    // singleIDSelectWhitelist(eve, id){
    //     console.log(id);
    //     if(eve == true){
    //         this.whitelistRemoveIDArr.push(id)
    //     }else{
    //         for (let i = 0; i < this.whitelistRemoveIDArr.length; i++) {
    //             if (this.whitelistRemoveIDArr[i] == id) {
    //                  this.whitelistRemoveIDArr.splice(i, 1);
    //             }
    //         }
    //     }
    // },
    toggleSelectAllWhitelist() {
      if (this.isSelectAllCheckedWhitelist) {
        this.whitelistRemoveIDArr = this.items.map((item) => item.user_id);
      } else {
        this.whitelistRemoveIDArr = [];
      }
    },
    singleIDSelectWhitelist(event, userId) {
      if (event) {
        this.whitelistRemoveIDArr.push(userId);
      } else {
        const index = this.whitelistRemoveIDArr.indexOf(userId);
        if (index > -1) {
          this.whitelistRemoveIDArr.splice(index, 1);
        }
      }
      this.isSelectAllCheckedWhitelist = this.whitelistRemoveIDArr.length === this.items.length;
    },
    toggleSelectAllBan() {
      if (this.isSelectAllCheckedBan) {
        this.banRemoveIDArr = this.itemsBan.map((item) => item.user_id);
      } else {
        this.banRemoveIDArr = [];
      }
    },
    singleIDSelectBan(event, userId) {
      if (event) {
        this.banRemoveIDArr.push(userId);
      } else {
        const index = this.banRemoveIDArr.indexOf(userId);
        if (index > -1) {
          this.banRemoveIDArr.splice(index, 1);
        }
      }
      this.isSelectAllCheckedBan = this.banRemoveIDArr.length === this.itemsBan.length;
    },
    toggleSelectAllLink() {
      if (this.isSelectAllCheckedLink) {
        this.linkRemoveIDArr = this.itemsLinked.map((item) => item.user_id);
      } else {
        this.linkRemoveIDArr = [];
      }
    },
    singleIDSelectLink(event, userId) {
      if (event) {
        this.linkRemoveIDArr.push(userId);
      } else {
        const index = this.linkRemoveIDArr.indexOf(userId);
        if (index > -1) {
          this.linkRemoveIDArr.splice(index, 1);
        }
      }
      this.isSelectAllCheckedLink = this.linkRemoveIDArr.length === this.itemsLinked.length;
    },
    getWhitelistEM() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "ul.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
            currentTab: this.currentTab,
            fl_status: this.flStatus_whitelist,
            // company_filter: this.company_whitelist.length > 0 ? this.company_whitelist : [],
            company_filter: this.company_whitelist ? this.company_whitelist : [],
          },
          api: "/api/companiesWhitelist",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    getBanEM() {
      // console.log(this.company_ban,'this.company_ban',this.company_ban.length > 0 ? this.company_ban : [],this.company_ban.length)
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageBan,
            keyword: this.filterBan,
            rowsPerPage: this.form.rowsPerPageBan,
            sortBy: this.sortByBan ? this.sortByBan : "ul.created_at",
            sortDirection: this.sortDescBan ? "desc" : "asc",
            currentTab: this.currentTab,
            fl_status: this.flStatus_ban,
            company_filter: this.company_ban.length > 0 ? this.company_ban : [],
            // company_filter: this.company_ban ? this.company_ban : [],
          },
          api: "/api/companiesBanViewEM",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsBan = this.$store.getters.getResults.pageOfItems;
            this.totalRowsBan = this.$store.getters.getResults.pager.totalItems;
            this.perPageBan = this.$store.getters.getResults.pager.pageSize;
            this.fromBan = this.$store.getters.getResults.pager.from;
            this.toBan = this.$store.getters.getResults.pager.to;
            return this.itemsBan;
          }
        });
    },
    getLinkedEM() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageLinked,
            keyword: this.filterLinked,
            rowsPerPage: this.form.rowsPerPageLinked,
            sortBy: this.sortByLinked ? this.sortByLinked : "ul.created_at",
            sortDirection: this.sortDescLinked ? "desc" : "asc",
            currentTab: this.currentTab,
            fl_status: this.flStatus_linked,
            // company_filter: this.company_linked.length > 0 ? this.company_linked : [],
            company_filter: this.company_linked ? this.company_linked : [],
          },
          api: "/api/companiesBanViewEM",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsLinked = this.$store.getters.getResults.pageOfItems;
            this.totalRowsLinked = this.$store.getters.getResults.pager.totalItems;
            this.perPageLinked = this.$store.getters.getResults.pager.pageSize;
            this.fromLinked = this.$store.getters.getResults.pager.from;
            this.toLinked = this.$store.getters.getResults.pager.to;
            return this.itemsLinked;
          }
        });
    },
    storeMultipleWhitelistCompanies(type) {
      var msg = "Are you sure you want to whitelist these users for these companies?";
      var msgg = "Users Whitelisted successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  flIDArr: this.create_whitelist_fl,
                  admin_id: this.form.user_id,
                  type: "whitelist",
                  whitelist_all_companies: false,
                  // companies:this.whitelist_all_companies == false ? this.whitelisted_companies : [],
                  comIDArr: this.create_whitelist_companies ? this.create_whitelist_companies : [],
                  remarks: this.create_whitelist_remarks,
                },
                api: "/api/whitelist-multiple-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    saveWhitelistCompanies(type) {
      var msg = "Are you sure you want to whitelist this user for these companies?";
      var msgg = "User Whitelisted successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.rest_user_id,
                  admin_id: this.form.user_id,
                  type: type,
                  whitelist_all_companies: this.whitelist_all_companies,
                  companies:
                    this.whitelist_all_companies == false ? this.whitelisted_companies : [],
                  remarks: this.whitelist_remark,
                },
                api: "/api/whitelist-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    saveCompanies(type) {
      var msg =
        type == "restrict"
          ? "Are you sure you want to ban this user for these companies?"
          : "Are you sure you want this user to view only EM?";
      var msgg = type == "restrict" ? "User Banned successfully." : "Now user can view only EM.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.rest_user_id,
                  admin_id: this.form.user_id,
                  type: type,
                  companies: type == "restrict" ? this.restricted_companies : this.linked_companies,
                  remarks: type == "restrict" ? this.restrict_remark : this.view_remark,
                },
                api: "/api/restrict-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    storeMultipleBanLinkedCompanies(type) {
      var typeVal = type == "ban create" ? "restrict" : "link";
      var msg =
        typeVal == "restrict"
          ? "Are you sure you want to ban these users for these companies?"
          : "Are you sure you want these user to view only these EM?";
      var msgg =
        typeVal == "restrict" ? "Users Banned successfully." : "Now users can view only EM.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  flIDArr: this.create_ban_linked_fl,
                  admin_id: this.form.user_id,
                  type: typeVal,
                  // companies:this.ban_linked_all_companies == false ? this.ban_linkeded_companies : [],
                  comIDArr: this.create_ban_linked_companies
                    ? this.create_ban_linked_companies
                    : [],
                  remarks: this.create_ban_linked_remarks,

                  // user_id: this.rest_user_id,
                  // admin_id: this.form.user_id,
                  // type: type,
                  // companies: type == "restrict" ? this.restricted_companies : this.linked_companies,
                  // remarks: type == "restrict" ? this.restrict_remark : this.view_remark,
                },
                api: "/api/restrictview-multiple-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    removeFromWhitelistCompanies(user_id) {
      var msg = "Are you sure you want to remove this user from the whitelist of this company?";
      var msgg = "User removed from Whitelist successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showCloseButton: true,
        imageUrl: "/assets/images/alert-icon.png",
        customClass: {
          container: "whitelist_container",
        },
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: user_id,
                  admin_id: this.form.user_id,
                  type: "whitelist",
                  whitelist_all_companies: false,
                  companies: [],
                  remarks: "",
                },
                api: "/api/whitelist-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    removeFromBanLinkCompanies(type, user_id) {
      var msg =
        type == "restrict"
          ? "Are you sure you want to remove this user from the resticted list of this company?"
          : "Are you sure you want to remove this user from the linked list of this company?";
      var msgg =
        type == "restrict"
          ? "User removed from Restricted list successfully."
          : "User removed from Linked list successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showCloseButton: true,
        imageUrl: "/assets/images/alert-icon.png",
        customClass: {
          container: "whitelist_container",
        },
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: user_id,
                  admin_id: this.form.user_id,
                  type: type,
                  companies: [],
                  remarks: "",
                },
                api: "/api/restrict-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    removeMultipleFromWhitelist() {
      var msg = "Are you sure you want to remove these users from the whitelist of company?";
      var msgg = "Users removed from Whitelist successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        imageUrl: "/assets/images/alert-icon.png",
        customClass: {
          container: "whitelist_container",
        },
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                idArr: this.whitelistRemoveIDArr,
                admin_id: this.form.user_id,
                type: "whitelist",
                whitelist_all_companies: false,
                companies: [],
                remarks: "",
              },
              api: "/api/remove-multiple-whitelistfl-from-companies",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                this.$swal({
                  position: "center",
                  icon: "success",
                  iconColor: "#4c8bf5",
                  title: msgg,
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.refreshDataTable();
                });
              }
            });
        }
      });
    },
    removeMultipleFromBan() {
      var msg = "Are you sure you want to remove these users from the restricted list of company?";
      var msgg = "Users removed from Restricted list successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        imageUrl: "/assets/images/alert-icon.png",
        customClass: {
          container: "whitelist_container",
        },
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                idArr: this.banRemoveIDArr,
                admin_id: this.form.user_id,
                type: "restrict",
                companies: [],
                remarks: "",
              },
              api: "/api/remove-multiple-banfl-from-companies",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                this.$swal({
                  position: "center",
                  icon: "success",
                  iconColor: "#4c8bf5",
                  title: msgg,
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.refreshDataTable();
                });
              }
            });
        }
      });
    },
    removeMultipleFromLink() {
      var msg = "Are you sure you want to remove these users from the linked list of company?";
      var msgg = "Users removed from Linked list successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        imageUrl: "/assets/images/alert-icon.png",
        customClass: {
          container: "whitelist_container",
        },
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                idArr: this.linkRemoveIDArr,
                admin_id: this.form.user_id,
                type: "link",
                companies: [],
                remarks: "",
              },
              api: "/api/remove-multiple-linkedfl-from-companies",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                this.$swal({
                  position: "center",
                  icon: "success",
                  iconColor: "#4c8bf5",
                  title: msgg,
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.refreshDataTable();
                });
              }
            });
        }
      });
    },
    getEmployers() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/company-all-list-whitelist-page",
          // api:'/api/employer-job-list-ft-admin'
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            // this.employers = this.$store.getters.getResults.data;
            this.employers = this.$store.getters.getResults.companies;
          }
        });
    },
    openInfoModel(id, type, info = null) {
      if (type == "restrict") {
        this.restrict = type;
        this.rest_user_id = id;

        this.getCompanies(type);
        this.$refs["restrict-modal"].show();
      } else if (type == "link") {
        this.restrict = type;
        this.rest_user_id = id;

        this.getCompanies(type);
        this.$refs["link-modal"].show();
      } else if (type == "whitelist") {
        this.restrict = type;
        this.rest_user_id = id;

        this.getCompanies(type);
        this.$refs["whitelist-modal"].show();
      } else if (type == "ban create") {
        this.restrict = type;
        this.is_whitelist = "no";

        this.createBanLinkedTitle = "Ban From EM";

        // this.companyListCreateWhitelistAdmin(type);
        this.loadLimitedCompanies();
        this.loadLimitedFreelancer();
        this.create_ban_linked_companies = [];
        this.create_ban_linked_fl = [];
        this.create_ban_linked_remarks = "";
        this.$refs["create-ban-linked-modal"].show();
      } else if (type == "link create") {
        this.restrict = type;
        this.is_whitelist = "no";
        this.createBanLinkedTitle = "View Only EM";
        // this.companyListCreateWhitelistAdmin(type);
        this.loadLimitedCompanies();
        this.loadLimitedFreelancer();
        this.create_ban_linked_companies = [];
        this.create_ban_linked_fl = [];
        this.create_ban_linked_remarks = "";
        this.$refs["create-ban-linked-modal"].show();
      } else {
        this.restrict = type;
        this.is_whitelist = "yes";
        // this.companyListCreateWhitelistAdmin(type);
        this.loadLimitedCompanies();
        this.loadLimitedFreelancer();
        this.create_whitelist_companies = [];
        this.create_whitelist_fl = [];
        this.create_whitelist_remarks = "";
        this.$refs["create-whitelist-modal"].show();
      }
    },
    mapCompId(value) {
      this.comp_id = value.id;
    },
    hideModel() {
      this.$refs["restrict-modal"].hide();
      this.$refs["link-modal"].hide();
      this.$refs["whitelist-modal"].hide();
      this.$refs["create-whitelist-modal"].hide();
      this.$refs["create-ban-linked-modal"].hide();
    },
    getCompanies(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.rest_user_id,
            type: type,
          },
          api: "/api/company-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.companiesList = this.$store.getters.getResults.companies;
            this.restricted_companies = this.$store.getters.getResults.restricted_companies;
            this.linked_companies = this.$store.getters.getResults.linked_companies;
            if (type == "whitelist") {
              if (this.$store.getters.getResults.whitelisted_companies.length > 0) {
                if (this.$store.getters.getResults.whitelisted_companies[0] == null) {
                  this.whitelist_all_companies = true;
                  this.whitelist_disable_companies = true;
                  this.whitelisted_companies = [];
                } else {
                  this.whitelist_all_companies = false;
                  this.whitelist_disable_companies = false;
                  this.whitelisted_companies = this.$store.getters.getResults.whitelisted_companies;
                }
              } else {
                this.whitelisted_companies = [];
                this.whitelist_all_companies = false;
                this.whitelist_disable_companies = false;
              }
            }
          }
        });
    },
    handleOpenComFL() {
      if (!this.isCompanyListLoaded) {
        this.loadLimitedCompanies();
        // this.companyListCreateWhitelistAdmin();
      }
      if (!this.isFLListLoaded) {
        this.loadLimitedFreelancer();
        // this.freelancerListCreateWhitelistAdmin();
      }
    },
    handleSearchChangeCom(searchDataCompany) {
      this.searchDataCompany = searchDataCompany;
      if (searchDataCompany) {
        this.searchCompanies(searchDataCompany);
      } else {
        this.loadLimitedCompanies();
      }
    },
    handleSearchChangeFL(searchDataFL) {
      this.searchDataFL = searchDataFL;
      if (searchDataFL) {
        this.searchFreelancer(searchDataFL);
      } else {
        this.loadLimitedFreelancer();
      }
    },
    loadLimitedCompanies() {
      this.$store
        .dispatch(POST_API, {
          data: { limit: 20 },
          api: "/api/company-list-create-whitelist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.createWhitelistCompaniesList = this.$store.getters.getResults.companies;
            this.isCompanyListLoaded = true;
          }
        });
    },
    loadLimitedFreelancer() {
      this.$store
        .dispatch(POST_API, {
          data: { limit: 20, is_whitelist: this.is_whitelist },
          api: "/api/freelancer-list-create-whitelist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.createWhitelistFLList = this.$store.getters.getResults.freelancers;
            this.isFLListLoaded = true;
          }
        });
    },

    searchCompanies(searchDataCompany) {
      this.$store
        .dispatch(POST_API, {
          data: { keyword: searchDataCompany },
          api: "/api/company-list-create-whitelist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.createWhitelistCompaniesList = this.$store.getters.getResults.companies;
          }
        });
    },
    searchFreelancer(searchDataFL) {
      this.$store
        .dispatch(POST_API, {
          data: { keyword: searchDataFL },
          api: "/api/freelancer-list-create-whitelist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.createWhitelistFLList = this.$store.getters.getResults.freelancers;
          }
        });
    },
    // companyListCreateWhitelistAdmin(type) {
    //   this.$store
    //     .dispatch(POST_API, {
    //       data: {},
    //       api: "/api/company-list-create-whitelist-admin",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         this.showAlert();
    //       } else {
    //         this.createWhitelistCompaniesList = this.$store.getters.getResults.companies;
    //         this.isCompanyListLoaded = true;
    //       }
    //     });
    // },
    // freelancerListCreateWhitelistAdmin(type) {
    //   this.$store
    //     .dispatch(POST_API, {
    //       data: {},
    //       api: "/api/freelancer-list-create-whitelist-admin",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         this.showAlert();
    //       } else {
    //         this.createWhitelistFLList = this.$store.getters.getResults.freelancers;
    //         this.isFLListLoaded = true;
    //       }
    //     });
    // },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Companies WhiteLists");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.designation =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";

    this.permissionStaff();
    this.getEmployers();
    //document.title = "EL Connect - Admin - Website Contents - Job Posters";
  },
};
</script>
