<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between dashboard-headline"> -->
                <!-- <h3><a class="back-arrow" href="javascript:history.back(1);"></a>Edit User</h3> -->
                <!-- <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit User</h1> -->
            <!-- </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 common_fieldset">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <!-- <div class="col-auto img_mobile col-xl-5 submit-field">
                                    <div class="single-image-upload emp_img_one">
                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.profile_image"></b-form-file>
                                        <span>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/company-logo-placeholders.png'" :disabled="true" style="max-width: 300px;"></b-img>
                                        </span>
                                    </div>
                                </div> -->
                                <!-- <b-col lg="8" md="8"> -->
                                <b-col lg="6">
                                    <!-- <b-form-group id="title-group" label="" class="required"> -->
                                    <div class="submit-field">
                                        <h5>Account Type <span class="required_star">*</span></h5>
                                        <b-form-input id="title" v-model="form.user_type_id_val" type="text" readonly="readonly"></b-form-input>
                                        <!-- <b-form-select v-model="form.user_type_id_val" id="industry" readonly>
                                            <option value="" disabled>Select Account Type</option>
                                            <option value="3">Admin</option>
                                            <option value="4">Supervisor</option>
                                        </b-form-select> -->
                                    <!-- </b-form-group> -->
                                    </div>
                                </b-col>
                                  <b-col lg="6" class="submit-field big_screen_max_width_and_flex">
                                    <h5>Gender <span class="required_star">*</span></h5>
                                    <b-form-select data-size="7" v-model="form.gender" class="custom-select web_bottom_zero" id="gender">
                                        <option value="" disabled>Select</option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                    </b-form-select>
                                </b-col>
                                <!-- <b-col lg="6" md="6"> -->
                                <b-col lg="12">
                                    <!-- <b-form-group id="title-group" label="Given Name" class="required"> -->
                                    <div class="submit-field">
                                    <h5>Full Name <span class="required_star">*</span></h5>
                                        <b-form-input id="full_name" v-model="form.full_name" type="text" placeholder="Enter Full Name.."></b-form-input>
                                    <!-- </b-form-group> -->
                                    </div>
                                </b-col>

                                <!-- <b-col lg="6" md="6"> -->
                                <b-col lg="6">
                                    <!-- <b-form-group id="title-group" label="Email" class="required"> -->
                                     <div class="submit-field">
                                    <h5>Email <span class="required_star">*</span></h5>
                                        <b-form-input id="email" v-model="form.email" type="text" placeholder="Enter Email.." readonly="readonly"></b-form-input>
                                    <!-- </b-form-group> -->
                                     </div>
                                </b-col>
                                <!-- <b-col lg="6" md="6"> -->
                                <b-col lg="6">
                                    <!-- <b-form-group id="title-group" label="Mobile Number" class="required"> -->
                                    <div class="submit-field">
                                    <h5>Mobile Number <span class="required_star">*</span></h5>
                                        <div class="input-group mb-3 desktop_margin_bottom_zero">
                                        <b-form-input type="text" v-model="form.country_code" class="mobile_number_padding with-border mobile_input_prf form-control required-field country_code_txt" readonly="readonly"></b-form-input>
                                        <b-form-input type="text" v-model="form.mobile_number" class="with-border mobile_input_prf form-control required-field" id="contact" readonly="readonly"></b-form-input>
                                        </div>
                                    </div>
                                    <!-- </b-form-group> -->
                                </b-col>
                                <b-col lg="12" md="12">
                                    <div class="submit-field permission_listing">
                                        <h5>Module Permissions</h5>
                                        <b-form-group label="" class="required">
                                            <div v-for="(app_module,k) in app_modules" :key="k">
                                                <!-- Code for Single Minus Listing -->
                                                <div v-if="app_module.modules_count.length ==1 && app_module.modules[0].is_parent == 'yes'">
                                                    <b-form-checkbox-group name="selectedModule" v-model="selectedmodule" class="main_check_list"><hr>
                                                        <b-form-checkbox :value="app_module.modules[0].id" unchecked-value="no" @change="updateModule(k,0,app_module.modules[0].id,$event)"><b><strong>{{app_module.modules[0].name}}</strong></b></b-form-checkbox>
                                                        <b-form-checkbox-group name="selectedApp" v-model="selectedaction">
                                                            <b-form-checkbox :key="action.id" :value="action.id" v-for="(action,m) in app_module.modules[0].actions" @change="updateAction(k,0,action.id,$event)">{{action.name}}
                                                            </b-form-checkbox>
                                                        </b-form-checkbox-group>
                                                    </b-form-checkbox-group>
                                                </div>
                                                <div v-if="app_module.modules_count.length >= 1 && app_module.modules[0].is_parent == 'no'">
                                                    <b-form-checkbox-group name="selectedApp" v-model="selectedapp" class="main_check_list"><hr v-if="k != 0">
                                                        <b-form-checkbox :value="app_module.id" unchecked-value="no" @change="updateApp(k,app_module.id,$event)"><b><strong>{{app_module.module_name}}</strong></b></b-form-checkbox>
                                                        <b-form-checkbox-group name="selectedModule" v-model="selectedmodule">
                                                            <div v-for="(module,l) in app_module.modules">
                                                                <b-form-checkbox :value="module.id" unchecked-value="no" @change="updateModule(k,l,module.id,$event)">{{module.name}}
                                                                </b-form-checkbox>
                                                                <b-form-checkbox-group name="selectedAction" v-model="selectedaction">
                                                                    <b-form-checkbox :key="action.id" :value="action.id" v-for="(action,m) in module.actions" @change="updateAction(k,l,action.id,$event)">{{action.name}}
                                                                    </b-form-checkbox>
                                                                </b-form-checkbox-group>
                                                            </div>
                                                        </b-form-checkbox-group>
                                                    </b-form-checkbox-group>
                                                </div>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-col>

                                <!-- <b-col lg="6" md="6">
                                    <b-form-group id="title-group" label="Address" class="required">
                                        <b-form-input id="title" v-model="form.address" type="text" placeholder="Enter Address.."></b-form-input>
                                    </b-form-group>
                                </b-col> -->
                            </b-row>
                              <hr class="new_hr">
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'empManageUserList'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
                <b-modal ref="crop-modal" title="Edit Image" hide-footer :no-close-on-backdrop="noClose">
                    <b-form @submit="onSubmitImg">
                        <template>
                              <div>
                                <div class="content" @change="setImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImage">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import VueCropper from 'vue-cropperjs';

export default {

    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                full_name: '',

                gender:'',
                country_code: '+65',
                mobile_number: '',
                email: '',
                user_type_id: '',
                user_type_id_val:'',
                address: '',
                profile_image:'',
                selectedImage:{
                    name:'',
                    image:'',
                },
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            delta: undefined,
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true,
            webUrl: process.env.VUE_APP_URL,
            app_modules:'',
            selectedapp: [],
            selectedmodule: [],
            selectedaction: [],
            module_change: 'no',
        }
    },
    components: {
        VueCropper,
    },
    methods: {
        breadCrumb(){
                var item = [{
                    to:{name:'empManageUserList'},
                    text: 'Manage User',
                },{
                    to:null,
                    text: 'Edit User'
                }];
                return item;
        },
        cropImage() {
          this.$refs['crop-modal'].hide();
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImg = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();
            this.$refs['crop-modal'].hide();
            this.$refs['crop-modal'].refresh();
        },
        onprofileChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.profile_image = input.files[0];
                if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.profile_image.size > (1048576)*2) {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Profile image should be less than 2 MB.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.profile_image.name;
                        this.showImg();
                        event.target.value = '';
                    }
                }
            }
        },
        getModule() {
            return this.$store.dispatch(POST_API, {
                data: {
                        company_id: this.company_id,
                    },
                api: '/api/emp-module-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.app_modules = this.$store.getters.getResults.data;
                    console.log(this.app_modules, 'modules');
                }
            });
        },
        updateAction(app_key, module_key, action_value, event) {
            // console.log(app_key,module_key,action_value,'updateAction',event,this.checkedCategories3);
            console.log(app_key,module_key,action_value,'updateAction',event,event.includes(action_value));

            this.module_change = 'yes';

            if (event.includes(action_value)) {
                //check if app id existed in selectedapp

                this.selectedaction.push(action_value);

                var app_id = this.app_modules[app_key].id;
                var app_pos = this.selectedapp.indexOf(app_id);
                console.log('event not null',this.selectedapp,this.selectedapp.indexOf(app_id),app_id)

                if (app_pos < 0) {
                    this.selectedapp.push(app_id);
                }

                //Check for module existed in selectedmodule

                var module_id = this.app_modules[app_key].modules[module_key].id;
                var module_pos = this.selectedmodule.indexOf(module_id);

                if (module_pos < 0) {
                    this.selectedmodule.push(module_id);
                }
            } else {
                console.log('event  null',this.app_modules[app_key].modules[module_key].actions)
                if ((this.selectedaction ? this.selectedaction.includes(action_value) : false)) {
                    this.selectedaction.splice(this.selectedaction.indexOf(action_value), 1);
                }
                // this.selectedaction.splice(this.selectedaction.indexOf(action_value), 1);

                var selectedaction = this.selectedaction;

                var count = 0;

                this.app_modules[app_key].modules[module_key].actions.filter(function(item) {
                    console.log(selectedaction.indexOf(item.id),selectedaction,item.id)
                    if (selectedaction.indexOf(item.id) >= 0) {
                        count++;
                    }
                });

                if (count == 0) {

                    var module_pos = this.selectedmodule.indexOf(this.app_modules[app_key].modules[module_key].id);
                    if (module_pos >= 0) {
                        this.selectedmodule.splice(module_pos, 1);
                        //To uncheck app_module array
                        this.uncheckappModule(app_key);

                    }
                }
            }
        },
        updateModule(app_key, module_key, module_value, event) {
            // console.log(event,'ebvbf',module_value,event != 'update' ? event.includes(module_value) : event)
            // console.log(this.selectedmodule,'updateModule',event,'eventeventeventeventrrrrrrr',event);

            // console.log(app_key,'module_key',module_key,'gg',module_value,'event',event,'org');
            this.module_change = 'yes';

            if (( event != 'update' &&  event != null ? event.includes(module_value) : false) || event == 'update') {
                //Check for app module if exist
                // console.log('if','module',this.selectedapp.indexOf(this.app_modules[app_key].id),this.selectedmodule.indexOf(this.app_modules[app_key].modules[module_key].id))
                // console.log(this.selectedapp,this.app_modules,app_key,module_key,this.selectedmodule)
                if (this.selectedapp.indexOf(this.app_modules[app_key].id) < 0) {
                    this.selectedapp.push(this.app_modules[app_key].id);
                }
                //Insert Module key in selectedmodule array 

                if (this.selectedmodule.indexOf(this.app_modules[app_key].modules[module_key].id) < 0 || event == 'update') {

                    this.selectedmodule.push(this.app_modules[app_key].modules[module_key].id)
                }
                    //then insert all their action in selected action if it is not inserted
                    var selectedaction = this.selectedaction;

                    var action_length = this.app_modules[app_key].modules[module_key].actions.length;

                    // console.log(this.selectedaction);

                    for (var i = 0; i < action_length; i++) {
                        // console.log(selectedaction.indexOf(this.app_modules[app_key].modules[module_key].actions[i].id),'dfgfd11');
                        if (selectedaction.indexOf(this.app_modules[app_key].modules[module_key].actions[i].id) < 0) {
                            this.selectedaction.push(this.app_modules[app_key].modules[module_key].actions[i].id);
                        }
                    }
                // }
                // console.log(this.selectedmodule);
            } else {
                // console.log('else','module')
                if (( this.selectedmodule ? this.selectedmodule.includes(module_value) : false)) {
                this.selectedmodule.splice(this.selectedmodule.indexOf(module_value), 1);
                }
                // console.log(this.selectedaction,this.selectedmodule,'gg',module_value,this.app_modules[app_key].modules[module_key].actions.length);
                //Uncheck all actions
                var action_length = this.app_modules[app_key].modules[module_key].actions.length;
                //console.log(action_length+'al')
                //console.log(this.app_modules[app_key].modules[module_key]);
                for (var i = 0; i < action_length; i++) {
                    console.log(this.app_modules[app_key].modules[module_key].actions[i].id,'this.app_modules[app_key].modules[module_key].actions[i].id',this.selectedaction.indexOf(this.app_modules[app_key].modules[module_key].actions[i].id))
                    var sa = this.selectedaction.indexOf(this.app_modules[app_key].modules[module_key].actions[i].id);
                    if (sa >= 0) {
                      this.selectedaction.splice(sa, 1);  
                    }

                    //
                }
                // console.log(this.selectedaction,'dfd',this.selectedmodule);

                //check for app
                //To uncheck app_module array
                this.uncheckappModule(app_key);

            }
        },
        uncheckappModule(app_key) {
            var app_module_length = this.app_modules[app_key].modules.length;
            // console.log( app_module_length,app_key,'app_module uncheck');

            var modules = this.selectedmodule;
            var mod_count = 0;
            //console.log(modules);
            this.app_modules[app_key].modules.filter(function(item) {
                if (modules.indexOf(item.id) >= 0) {
                    mod_count++;
                }
            });

            if (mod_count == 0) {
                var app_pos = this.selectedapp.indexOf(this.app_modules[app_key].id);

                if (app_pos >= 0) {
                    this.selectedapp.splice(app_pos, 1);
                }
            }
            // console.log(mod_count,this.selectedapp,'this.selectedapp')
        },
        updateApp(app_key, app_value, event) {
            this.module_change = 'yes';
            // console.log('updateApp',event,app_key, app_value,'eventeventeventeventevent',event.includes(app_value),event);

            /*console.log('updateApp');*/
            if (event.includes(app_value)) {
            // if (event !== null) {
                if (this.selectedapp.indexOf(app_value) < 0) {
                    this.selectedapp.push(app_value);
                }
                //Check Module Length
                var module_length = this.app_modules[app_key].modules.length;
                
                //console.log(module_length+'length');
                //console.log(app_key+'update');

                for (var i = 0; i < module_length; i++) {
                    this.updateModule(app_key, i, this.app_modules[app_key].modules[i].id, 'update')
                }
            } else {
                var module_length = this.app_modules[app_key].modules.length;
                for (var i = 0; i < module_length; i++) {
                    this.updateModule(app_key, i, this.app_modules[app_key].modules[i].id, null)
                }
            }
            /*console.log(this.selectedapp);
            console.log(this.selectedmodule);
            console.log(this.selectedaction);*/
        },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        status: this.status,
                        page: this.currentPage,
                        keyword: this.filter,
                        id: this.id,
                        company_admin: this.user_id,
                        company_id: this.company_id,
                        profile_image: this.form.selectedImage.image,
                        first_name: this.form.first_name,
                        last_name: this.form.last_name,
                        full_name: this.form.full_name,

                        user_type_id:this.form.user_type_id,
                        country_code: this.form.country_code,
                        mobile_number: this.form.mobile_number,
                        email: this.form.email,
                        address: this.form.address,
                        items:this.form,
                    },
                    api: '/api/emp-manage-user-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.first_name = this.$store.getters.getResults.data.first_name;
                        this.form.last_name = this.$store.getters.getResults.data.last_name;
                        this.form.full_name = this.$store.getters.getResults.data.full_name;

                        this.form.country_code = this.$store.getters.getResults.data.country_code;
                        this.form.mobile_number = this.$store.getters.getResults.data.mobile_number;
                        this.form.email = this.$store.getters.getResults.data.email;
                        this.form.user_type_id_val = this.$store.getters.getResults.data.user_type_id == '3' ? 'OM' : 'Supervisor';
                        this.form.user_type_id = this.$store.getters.getResults.data.user_type_id;
                        this.form.gender = this.$store.getters.getResults.data.gender ? this.$store.getters.getResults.data.gender : '';
                        this.form.address = this.$store.getters.getResults.data.address;
                        this.form.selectedImage.image = this.$store.getters.getResults.data.profile_image;
                        this.selectedapp = this.$store.getters.getResults.app_module;
                        this.selectedmodule = this.$store.getters.getResults.modules;
                        this.selectedaction = this.$store.getters.getResults.actions;

                    }
                });
        },

        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            var err = false;

            // if ($('#first_name').val() == '' || $('#first_name').val().trim().length === 0) {
            //                         err = true;
            //                             this.$swal({
            //                             position: 'center',
            //                             // icon: 'error',
            //                             imageUrl: '/assets/images/404_elcy.gif',
            //                             customClass:{
            //                                 container: 'mascot_error_container',
            //                             },
            //                             title: 'Please enter Given name',
            //                             showConfirmButton: false,
            //                             timer: 1500
            //                         })
            //                     } else if($('#last_name').val() == '' || $('#last_name').val().trim().length === 0){
            //                         err = true;
            //                             this.$swal({
            //                             position: 'center',
            //                             // icon: 'error',
            //                             imageUrl: '/assets/images/404_elcy.gif',
            //                             customClass:{
            //                                 container: 'mascot_error_container',
            //                             },
            //                             title: 'Please enter Surname',
            //                             showConfirmButton: false,
            //                             timer: 1500
            //                         })
            //                     }

            if ($('#full_name').val() == '' || $('#full_name').val().trim().length === 0) {
                                    err = true;
                                        this.$swal({
                                        position: 'center',
                                        // icon: 'error',
                                        imageUrl: '/assets/images/404_elcy.gif',
                                        customClass:{
                                            container: 'mascot_error_container',
                                        },
                                        title: 'Please enter Full name',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                }

        if(err == false){
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        company_admin: this.user_id,
                        company_id: this.company_id,
                        profile_image: this.form.selectedImage.image,
                        first_name: this.form.first_name,
                        last_name: this.form.last_name,
                        full_name: this.form.full_name,

                        gender: this.form.gender,
                        user_type_id:this.form.user_type_id,
                        country_code: this.form.country_code,
                        country_id: '5',
                        mobile_number: this.form.mobile_number,
                        email: this.form.email,
                        address: this.form.address,
                        items:this.form,
                        selectedapp: this.selectedapp,
                        selectedmodule: this.selectedmodule,
                        selectedaction: this.selectedaction,
                        module_change: this.module_change,
                    },
                    api: "/api/emp-manage-user-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
            }
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'User Updated successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'empManageUserList' });
            });
        },
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
        this.getEditData();
        this.getModule();

    },
};

</script>
