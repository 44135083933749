import ApiService from "../../api/api.service";
import JwtService from "../../common/jwt.service";

import { SET_ERROR, CLEAR_ERRORS, SET_RESULT_DATA, CLEAR_RESULT_DATA } from "../mutations.type";
import {  GET_API, POST_API, PUT_API, DELETE_API, UPDATE_AUTH_POST_API , UPDATE_AUTH_PUT_API, SET_PROFILE_DATA,LOGOUT, MULTIPART_POST_API } from "../actions.type";
import Bus from "../../event-bus";

export default {
    state: {

    },
    getters: {
    },
        
    actions: {

    	[GET_API](context, credentials) {
            window.scrollTo(0,0);
            console.log('get api')
            $('.is-active').parent().parent().parent().parent().addClass("hide_overflow_y");
            Bus.$emit('showLoader');

            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(credentials.api)
                 .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                Bus.$emit('hideLoader');
                                setTimeout(() => {
                                    $('.is-active').parent().parent().parent().parent().removeClass("hide_overflow_y");
                                },900);
                                /*context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);*/
                                if (typeof data.invalid == 'undefined') {
                                    
                                    context.commit(
                                        SET_ERROR, { message: data.message }
                                    );
                                    reject(data);  
                                }
                            } else {
                                Bus.$emit('hideLoader');
                                setTimeout(() => {
                                    $('.is-active').parent().parent().parent().parent().removeClass("hide_overflow_y");
                                },900);
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong1");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        if(JwtService.getTLoggedInPanel() == 'jobseeker')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login-jobseeker');
                        }else if(JwtService.getTLoggedInPanel() == 'employer'){
                            context.commit(LOGOUT);
                            window.location.replace('/login-employer');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/v2/admin-login');
                        }
                    }
                });
        },
        [POST_API](context, credentials) {
            console.log('post api')
            //window.scrollTo(0,0);
            if(credentials.data && credentials.data.popup){

                var popup = true;
            }else{
                var popup = false;

            }

            if(!popup && credentials.api != '/api/blog-detail-view-count' && credentials.api != '/api/ft-job-view-count' && credentials.api != '/api/dp-job-view-count' && credentials.api != '/api/empApplyForDPNew' && credentials.api != '/api/updateUserJobPreference' && credentials.api != '/api/isUserActive' && credentials.api != '/api/userCoins' && credentials.api != '/api/getUsersCount' && credentials.api != '/api/get-support-ticket-message-count' && credentials.api != '/api/emp-admin-ticket-auto-reply' && credentials.api != '/api/getDPFTEmpJobCount' && credentials.api != '/api/getDPFTFLJobCount'){
             Bus.$emit('showLoader');
             $('.is-active').parent().parent().parent().parent().addClass("hide_overflow_y");
            }
            return new Promise((resolve, reject) => {
                    ApiService.setHeader();
                    ApiService.post(credentials.api, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                if(credentials.api != '/api/tagData' || popup){

                                    Bus.$emit('hideLoader');
                                }
                                if (typeof data.invalid == 'undefined') {
                                    context.commit(
                                        SET_ERROR, { message: data.message }
                                    );
                                    reject(data);  
                                }else{
                                    context.commit(LOGOUT);
                                    window.location.replace('/v2/not-found');
                                }
                                

                            } else {
                                // const currentUserDesignation = context.getters.currentUserDesignation;
                                // const userLoggedInPanel = context.getters.getUserLoggedInPanel;
                                // const isAuthenticated = context.getters.isAuthenticated;
                                // console.log('Current User Designation:', currentUserDesignation,userLoggedInPanel,isAuthenticated,'isAuthenticated');
                                // if(currentUserDesignation == 'External Agent' && userLoggedInPanel == 'admin' && isAuthenticated){
                                //     window.location.replace('/v2/admin/daily-deployment');
                                // }else{
                                    Bus.$emit('hideLoader');
                                    setTimeout(() => {
                                        $('.is-active').parent().parent().parent().parent().removeClass("hide_overflow_y");
                                    },900);
                                    
                                    context.commit(
                                        SET_RESULT_DATA, { resultData: data }
                                    );
                                    resolve(data);
                                // }
                            }
                        })
                        .catch(({ response }) => {
                            console.error('Error in API call:', response);
                            // console.log(!response,'response',"response.data",response)
                            if(response && response.data.logout == true){ 
                                
                                var user_role = JSON.parse(localStorage.getItem('userData'));;
                                
                               if (user_role.role == 'admin'){
                                    context.commit(LOGOUT);
                                    //window.location.replace('/login');
                               }else if(user_role.role == 'client'){
                                    context.commit(LOGOUT);
                               }
                               // Bus.$emit('logout');
                               //11 may 2023
                            }else if(!response){
                                context.commit(
                                    SET_ERROR, { message: 'Connection Timeout' }
                                );
                                reject(response);
                                Bus.$emit('hideLoader');
                                setTimeout(() => {
                                    $('.is-active').parent().parent().parent().parent().removeClass("hide_overflow_y");
                                },900);
                            }else {
                                // console.log(response.status,'response.status',response.data.status)
                                // if(response.status = '502'){
                                //     window.location.reload()
                                // }else{
                                //     context.commit(
                                //         SET_ERROR, { message: 'Something went wrong Error:2' }
                                //     );
                                //     reject(response);
                                //     Bus.$emit('hideLoader');
                                // }
                                context.commit(
                                    SET_ERROR, { message: 'System loading... Please wait' }
                                );
                                reject(response);
                                Bus.$emit('hideLoader');
                                setTimeout(() => {
                                    $('.is-active').parent().parent().parent().parent().removeClass("hide_overflow_y");
                                },900);
                            }
                            //11 may 2023
                        });
                })
                .catch(response => {

                    console.error('Promise rejected:', response);
                });
        },
        
         [PUT_API](context, credentials) {
            return new Promise((resolve, reject) => {
                    ApiService.setHeader();
                    ApiService.put(credentials.api+credentials.data.id, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                
                                
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                                
                                
                            } else {
                                
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data.data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        if(JwtService.getTLoggedInPanel() == 'jobseeker')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login-jobseeker');
                        }else if(JwtService.getTLoggedInPanel() == 'employer'){
                            context.commit(LOGOUT);
                            window.location.replace('/login-employer');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/v2/admin-login');
                        }
                    }
                });

        },
        [DELETE_API](context, credentials) {
            return new Promise((resolve, reject) => {
                    ApiService.setHeader();
                    ApiService.delete(credentials.api+credentials.data.id, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                            } else {
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        if(JwtService.getTLoggedInPanel() == 'jobseeker')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login-jobseeker');
                        }else if(JwtService.getTLoggedInPanel() == 'employer'){
                            context.commit(LOGOUT);
                            window.location.replace('/login-employer');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/v2/admin-login');
                        }
                    }
                });
        },
        [UPDATE_AUTH_PUT_API](context, credentials) {
            return new Promise((resolve, reject) => {
                ApiService.setMultipartHeader();
                    ApiService.setHeader();
                    ApiService.put(credentials.api+credentials.data.id, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                            } else {
                                context.commit(
                                    SET_PROFILE_DATA, { resultData: data }
                                );
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        if(JwtService.getTLoggedInPanel() == 'jobseeker')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login-jobseeker');
                        }else if(JwtService.getTLoggedInPanel() == 'employer'){
                            context.commit(LOGOUT);
                            window.location.replace('/login-employer');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/v2/admin-login');
                        }
                    }
                });
        },
        
        [UPDATE_AUTH_POST_API](context, credentials) {
            return new Promise((resolve, reject) => {
                ApiService.setMultipartHeader();
                    // ApiService.setHeader();
                    ApiService.post(credentials.api, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);

                            } else {
                                context.commit(
                                    SET_PROFILE_DATA, { resultData: data }
                                );
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            if(response.logout == true)
                            {
                                if(JwtService.getTLoggedInPanel() == 'jobseeker')
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/login-jobseeker');
                                }else if(JwtService.getTLoggedInPanel() == 'employer'){
                                    context.commit(LOGOUT);
                                    window.location.replace('/login-employer');
                                }
                                else
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/v2/admin-login');
                                }
                            }
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        context.commit(LOGOUT);
                    }
                });
        },
        [MULTIPART_POST_API](context, credentials) {
            return new Promise((resolve, reject) => {
                ApiService.setMultipartHeader();
                    ApiService.post(credentials.api, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                            } else {
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            if(response.logout == true)
                            {
                                if(JwtService.getTLoggedInPanel() == 'jobseeker')
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/login-jobseeker');
                                }else if(JwtService.getTLoggedInPanel() == 'employer'){
                                    context.commit(LOGOUT);
                                    window.location.replace('/login-employer');
                                }
                                else
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/v2/admin-login');
                                }
                            }
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        context.commit(LOGOUT);
                    }
                });
        },
        
    }
}

