<template>
    <div>
        
        <div class="row">
           
            <div class="container my_new_container">
                 <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline">
            <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>{{last_name | capitalize}} {{first_name | capitalize}} Referred Freelancers</h1>
          
            <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Referred Freelancers</li>
                </ul>
            </nav>
        </div> -->
        <div id="breadcrumb-v2">
            <b-breadcrumb>
                <b-breadcrumb-item href="" :to="{name: 'staff-list'}">Manage Staff</b-breadcrumb-item>
                <b-breadcrumb-item active>{{last_name | capitalize}} {{first_name | capitalize}} Attached Users</b-breadcrumb-item>
            </b-breadcrumb>
        </div>
                <!-- <div class="col-xl-4 remove_search_box_pdng">
                    <div class="search-box custom_search_admin">
                        <b-input-group>
                           
                            </b-input-group>
                        </div>
                    </div> -->
                </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-input-group-prepend>
                                    <b-form-input id="searchBtn" :value="searchKeyword" @change="v => searchKeyword = v" @keyup.enter.native="filter = searchKeyword" placeholder="Search by keyword"></b-form-input>
                                    <b-button class="btn points_events" @click="filter = searchKeyword"><i class="fa fa-search"></i></b-button>
                                </b-input-group-prepend>
                            </div>
                        </div> -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
				<b-table ref="datatable" show-empty striped hover responsive :items="getReferredFreelancers" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 transaction_single_admin_table">
                    <template #cell(referredBy)="items">
                        
                        <b-img v-if="items.item.referred_by_thumbnail || items.item.referred_by_profile_image" :src="items.item.thumbnail ? items.item.thumbnail: items.item.referred_by_profile_image ? items.item.referred_by_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                        </b-img>
                        <!-- <b-img v-else-if="items.item.referred_by_ft_profile_image_thumbnail || items.item.referred_by_ft_profile_image" :src="items.item.referred_by_ft_profile_image_thumbnail ? items.item.referred_by_ft_profile_image_thumbnail: items.item.referred_by_ft_profile_image ? items.item.referred_by_ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                        </b-img> -->

                        <b-img v-else :src="webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                        </b-img>
                        {{items.item.referred_by_last_name | capitalize}} {{items.item.referred_by_first_name | capitalize}}
                        <p class="grey-text">
                            {{items.item.referred_by_email | trim}}
                        </p>
                         <p class="grey-text">
                            {{items.item.referred_by_country_code}}-{{items.item.referred_by_mobile_number}}
                        </p>
                    </template>
                     <!-- <template #cell(mobile_number)="items">
                        <b-link :href="'https://wa.me/+65' + items.item.referred_by_mobile_number" target="_blank">
                        {{items.item.referred_by_country_code}}-{{items.item.referred_by_mobile_number}}
                        </b-link>
                    </template>    -->
                    <template #cell(referred_by_completed_jobs)="items">
                    {{items.item.referred_by_completed_jobs}}
                    </template>
                    <template #cell(referredTo)="items">
                        
                        <b-img v-if="items.item.referred_to_thumbnail || items.item.referred_to_profile_image" :src="items.item.thumbnail ? items.item.thumbnail: items.item.referred_to_profile_image ? items.item.referred_to_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                        </b-img>
                        <!-- <b-img v-else-if="items.item.referred_to_ft_profile_image_thumbnail || items.item.referred_to_ft_profile_image" :src="items.item.referred_to_ft_profile_image_thumbnail ? items.item.referred_to_ft_profile_image_thumbnail: items.item.referred_to_ft_profile_image ? items.item.referred_to_ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                        </b-img> -->

                        <b-img v-else :src="webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                        </b-img>
                        {{items.item.referred_to_last_name | capitalize}} {{items.item.referred_to_first_name | capitalize}}
                        <p class="grey-text">
                            {{items.item.referred_to_email | trim}}
                        </p>
                         <p class="grey-text">
                            {{items.item.referred_to_country_code}}-{{items.item.referred_to_mobile_number}}
                        </p>
                    </template>
                    <template #cell(status)="items">
                     <p v-if="items.item.status == 'completed'"> {{'Transferred'| capitalize}}</p>
                  <p v-else-if="items.item.status == 'awarded'">{{'Awarded' | capitalize}}</p>
                  <p v-else>{{'Hold' | capitalize}}</p>
                    </template>
                    <template #cell(completed_on)="items">
                    <p v-if="items.item.status == 'completed'">{{items.item.completed_on != null ? items.item.completed_on : ''| moment}}</p>
                    <p v-else> - </p>
                    </template> 
                    <template #cell(account_status)="items">
                    {{items.item.referred_to_account_verification_status | capitalize}}
                    </template>
                    <template #cell(created_at)="items">
                    {{items.item.created_at | moment}}
                    </template> 
                    
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getReferredFreelancers'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from "moment";

export default {

    data() {
        return {
            fields: [
                { key: 'referredBy', label: 'Referred By', sortable: true },
                { key: 'referred_by_completed_jobs', label: 'Jobs', sortable: false },
                { key: 'referredTo', label: 'Referred To', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'completed_on', label: 'Completed on', sortable: false },
                { key: 'account_status', label: 'Account Status', sortable: true },
                { key: 'created_at', label: 'Referred On', sortable: true },
            ],
            id: atob(this.$route.params.id),
            first_name:'',
            last_name:'',
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: '',
            sortDirection: 'desc',
            filter: null,
            searchKeyword: null,

            status: '',
            sortDesc: false,
            sortDirection: 'asc',
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            status: '',

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD MMM YYYY hh:mm A');
        },
        trim: function(value) {
            value = value.toString()
            if (value.length < 25) {
                return value;
            } else {
                return value.substring(0, 25) + '...';
            }
        }
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'jobseekers-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getReferredFreelancers() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        // user_id:this.id,
                        id:this.id,
                        status: this.status,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy,
                        sortDirection:this.sortDirection,
                        sortDesc:this.sortDesc,
                        type:'agent',
                        referred_by: this.id
                    },
                    api: '/api/staff-attached-users-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.totalRows = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.per_page;
                        this.from = this.$store.getters.getResults.data.from;
                        this.to = this.$store.getters.getResults.data.to;
                        return this.items;
                    }
                });
        },
        verifyEmail(id)
        {
            return this.$store.dispatch(POST_API, {
                data:{
                id: id,
                },
                api:'/api/jobseeker-verify-email-admin',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message='Email Verified successfully.';
                    this.$refs.datatable.refresh();
                    this.showSuccessAlert(message);

                }
            });
                
        },
        getUserData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: atob(this.$route.params.id),
                },
                api: '/api/user-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){
                        this.first_name = this.$store.getters.getResults.data.first_name;
                        this.last_name = this.$store.getters.getResults.data.last_name;
                    }
                }
            });
        },
        
        deleteJobseeker(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/admin-empjobseeker-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Jobseeker Deleted successfully.';
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
    },
    mounted(){
         $(".show_filter_arrow .table thead th div").contents().unwrap();
          $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
       // document.title = "EL Connect - Admin - Jobseekers List";
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.getUserData();
    }
}
</script>
