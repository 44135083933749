<template>
    <!-- Wrapper -->
<div id="wrapper wrapper_pdng">
<!-- <div class="back_arrow_icon">
	<span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>
</div> -->
	<div id="breadcrumb-v2" class="mb-1">
		<div class="d-flex justify-content-start breadcrumb-wrapper">
			<b-breadcrumb
			:items="breadCrumb()"
			/>
		</div>
    </div>
<!-- Titlebar
================================================== -->
<!--<div class="single-page-header freelancer-header single_top_bk">
	<div class="single_gradient">
	<div class="container mx_width">
		<div class="row ">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image"><img :src="items.company_logo ? items.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt=""></div>
						<div class="header-details">
							<h3 class="name_mrgn">{{ items.name | capitalize }} </h3>
							<h4 class="new_h4">Short Description</h4>
							<ul>
								 <li class="emp_li_fst"><div class="star-rating" data-rating="4.9" v-if="items.average_rating != null"></div></li> 
								<li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false"  v-if="items.average_rating != null"></star-rating></li>
								<li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li> 
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</div>-->


    <div class="bg_img_top new_bg_img_tp new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( items.banner_image ? items.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
        <div class="grd_div inside_grd height_three_zero_one">
        <div class="container my_new_container">
            <div class="row">
			<div class="col-md-12 ">
				<div class="company_profile_banner">
					<div class="left-side updated_left_side_banner full_left_if_right_not_available">
						<div class="header-image updated_header_image_banner updated_image_in_square_shape"><img :src="items.company_logo ? items.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt=""></div>
						<div class="header-details updated_header_details_banner">
							<h1 class="name_mrgn header_detail_new_h1  new_daily_paid_jjobs">{{ items.last_name | capitalize }} {{ items.first_name | capitalize }} </h1>
							<!-- <h4 class="new_h4">Short Description</h4> -->
							<ul class="desktop_margin_bottom_zero">
								 <li class="emp_li_fst rating_li" v-if="items.average_rating != null"><div class="star-rating" data-rating="4.9" v-if="items.average_rating != null"></div></li> 
								<!-- <li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false"  v-if="items.average_rating != null"></star-rating></li> -->
								<!--  <li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li> -->
								<li class="verified_li" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'"><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
					<!--<div class="right-side" v-if="min_salary && max_salary">
                        <div class="right_inner_contents">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
                        </div>
					</div>-->
				</div>
			</div>
		</div>
        </div>
     
        
    </div>
    </div>


<!-- Page Content
================================================== -->
<div class="container mx_width">
	<div class="row ">
		
		<!-- Content -->
		<div class="col-xl-8 col-lg-8 mobile_tab_padding_left_right_zero emp_prf single_left_content tab_max_width_and_flex">
			
			<!-- Page Content -->
			<div class="single-page-section" v-if="items.description">
				<h3 class="margin-bottom-25">About Company</h3>
				<p v-html="items.description"></p>
			</div>

			<!-- Boxed List -->
			<div class="boxed-list desktop_margin_top_zero" v-if="company_jobs.length > 0">
				<div class="boxed-list-headline boxed_border_unset">
					<h3><i class="icon-material-outline-business-center"></i> Open Positions</h3>
				</div>
				
				<div class="listings-container compact-list-layout">
					
					<!-- <a href="" class="job-listing" v-for="company_job in company_jobs"> -->
					<a href="#" @click="jobDetail(encrypt(company_job.jobId),$event)" class="job-listing custom_pagination_border_radius" v-for="(company_job, index) in company_jobs" v-if="index <= 1">


						<div class="job-listing-details">

							<div class="job-listing-description">
								<h3 class="job-listing-title new_all_job_title">{{company_job.job_title}}</h3>

								<div class="job-listing-footer">
									<ul>
										<li class="black_job_color"><i class="common_i_color icon-material-outline-location-on"></i>{{company_job.job_address == 'Other' ? company_job.other_location : company_job.job_address | capitalize}}</li>
										<li class="black_job_color"><i class="common_i_color icon-material-outline-business-center"></i> {{company_job.job_type | customizeRoleType | capitalize}}</li>
										<li class="black_job_color"><i class="common_i_color icon-material-outline-access-time"></i> {{company_job.posted_date | dateTimeAgo | capitalize}}</li>
									</ul>
								</div>
							</div>

						</div>
					</a>
				</div>

			</div> 
			 <!-- <div class="details_list_job">
                <b-link :to="{name: 'fulltime-emp-job-list', params: {id: id}}" target=_blank class="headline-link" v-if="company_jobs_count > 2">Browse All Listing</b-link>
            </div> -->
			<!-- Boxed List / End -->

			<!-- Boxed List -->
			<!-- <div class="boxed-list margin-bottom-60">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-thumb-up"></i> Reviews</h3>
				</div>
				<ul class="boxed-list-ul">
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Doing things the right way <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
										<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span>
									</div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> August 2019</div>
								</div>
								<div class="item-description">
									<p>Great company and especially ideal for the career-minded individual. The company is large enough to offer a variety of jobs in all kinds of interesting locations. Even if you never change roles, your job changes and evolves as the company grows, keeping things fresh.</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Outstanding Work Environment <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
									<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span></div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> May 2019</div>
								</div>
								<div class="item-description">
									<p>They do business with integrity and rational thinking. Overall, it's an excellent place to work, with products that are winning in the marketplace.</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div> -->

				

				<!-- <div class="centered-button margin-top-35">
					<b-button variant="success" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="changeUserStatus('approve', items.user_id)">Approve</b-button>&nbsp;
					<b-button variant="danger" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="openModel('reject', items.user_id)">Reject</b-button>
					
					 <a href="#small-dialog"  class="popup-with-zoom-anim button button-sliding-icon">Leave a Review <i class="icon-material-outline-arrow-right-alt review_btn"></i></a> 

				</div> -->

			<!-- Boxed List / End -->

		</div>
		

		<!-- Sidebar -->
		<div class="col-xl-4 col-lg-4 mobile_tab_padding_left_right_zero new_sidebar_i_margin_tp single_right_sidebar tab_max_width_and_flex">
			<div class="sidebar-container desktop_margin_bottom_zero">
				<div class="sidebar-widget jobseeker_dp_sidebar desktop_margin_bottom_zero new_jobseeker_dp_sidebar">
                <div class="job-overview">
                    <div class="job-overview-headline font_weight_600">Personal Detail</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li><i class="icon-material-outline-email"></i><span>Email</span><h5 class="common_word_break">{{items.user_email}}</h5></li>
                                <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span>Mobile Number</span><h5>{{items.country_code}}-{{items.user_mobile_number}}</h5></li>
								<li v-if="items.address"><i class="icon-material-outline-location-on"></i><span>Company Address</span><h5>{{items.address | capitalize}}</h5></li>
								<li v-if="items.address"><i class="icon-material-outline-business-center"></i><span>Company BRN/UEN</span><h5>{{items.registration_id}}</h5></li>
							</ul>
                        </div>
                    </div>
                </div>
				<!-- <div class="sidebar-widget">
					<a href="#small-dialog" class="apply-now-button popup-with-zoom-anim margin-bottom-50 msg_btn"><i class="fa fa-envelope new_letter"></i>Send a Message </a>
				</div> -->

				<!-- Location -->
				<!-- <div class="sidebar-widget nw_side" v-if="items.address">
					<h3>Company Address</h3>
					{{items.address | capitalize}} -->
					<!--
					 <div id="single-job-map-container">
						<div id="singleListingMap" data-latitude="52.520007" data-longitude="13.404954" data-map-icon="im im-icon-Hamburger"></div>
						<a href="#" id="streetView">Street View</a>
					</div>-->
				<!-- </div>  -->

				<!-- <div class="sidebar-widget nw_side" v-if="items.address">
					<h3>Company BRN/UEN</h3>
					{{items.registration_id}}
				</div>  -->

				<!-- Widget -->
				<!-- <div class="sidebar-widget" v-if="social_profile.length > 0">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul>
                            <li><a :href="social_profile[0].url" target="_blank"  title="Share on Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li><a :href="social_profile[1].url" target="_blank"  title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li><a :href="social_profile[2].url" target="_blank"  title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li><a :href="social_profile[3].url" target="_blank"  title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                            <li><a :href="social_profile[4].url" target="_blank"  title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
						</ul>
					</div>
				</div> -->

				<!-- <div class="sidebar-widget desktop_margin_bottom_zero margin-top-25" v-if="social_profile.length > 0 ? social_profile[0].url || social_profile[1].url ||  social_profile[2].url||  social_profile[3].url || social_profile[4].url : false">
					<h3 class="font_weight_600">Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile[0].url"><a :href="social_profile[0].url" target="_blank"  title="Share on Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile[1].url"><a :href="social_profile[1].url" target="_blank"  title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="social_profile[2].url"><a :href="social_profile[2].url" target="_blank"  title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li v-if="social_profile[3].url"><a :href="social_profile[3].url" target="_blank"  title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile[4].url"><a :href="social_profile[4].url" target="_blank"  title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div> -->
				<div class="sidebar-widget  margin-top-25 desktop_margin_bottom_zero" v-if="social_profile.length > 0 ? social_profile_website_url || social_profile_fb_url ||  social_profile_link_url || social_profile_twitter_url : false">
					<h3 class="font_weight_600">Social Profiles</h3>
					<div class="freelancer-socials">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile_website_url"><a :href="social_profile_website_url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile_fb_url"><a :href="social_profile_fb_url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <!-- <li v-if="social_profile_google_url"><a :href="social_profile_google_url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li> -->
                            <li v-if="social_profile_link_url"><a :href="social_profile_link_url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile_twitter_url"><a :href="social_profile_twitter_url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div>
				<!-- <div class="sidebar-widget" v-if="social_profile[0].url || social_profile[1].url || social_profile[3].url || social_profile[4].url">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul class="new_social_share_emp">
							<div v-for="sc_profile in social_profile">
                            <li v-if="sc_profile.acconunt_type='website'"><a :href="sc_profile.url" target="_blank"  title="Share on Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="sc_profile.acconunt_type='fb'"><a :href="sc_profile.url" target="_blank"  title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="sc_profile.acconunt_type='twitter'"><a :href="sc_profile.url" target="_blank"  title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                            <li v-if="sc_profile.acconunt_type='link'"><a :href="sc_profile.url" target="_blank"  title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
						</ul>
					</div>
				</div> -->
				<!-- Sidebar Widget -->
				<!-- <div class="sidebar-widget nw_side">
					<h3>Share</h3>
					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border">
						<button class="copy-url-button ripple-effect" data-clipboard-target="#copy-url" @click="copyUrl()" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
								<li><a href="#" data-button-color="#3b5998" title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							</ul>
						</div>
					</div>
				</div>  -->

			</div>
		</div>
		<b-modal ref="rejection-modal" title="Rejection Remark" hide-footer :no-close-on-backdrop="noClose">
			<!-- <b-form> -->
				<template>
					<div>
						<textarea type="text" class="with-border" value="" v-model="rejection_remark"></textarea>
					</div>
					<div class="pt-2 mt-3 text-md-left text-center">
					<b-button variant="success" class="text-uppercase x-md btn-shadow" @click="changeUserStatus('reject','')">Submit</b-button> &nbsp;
					<!-- <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button> -->
				</div>
				</template>
			<!-- </b-form> -->
		</b-modal>
	</div>
</div>


<!-- Spacer -->
<!-- <div class="margin-top-15"></div> -->
<!-- Spacer / End-->

</div>
<!-- Wrapper / End -->



</template>

<script>

import { POST_API } from '../../../store/actions.type'
import moment from "moment";
import StarRating from 'vue-star-rating';


export default {

    data() {
        return {
            fields: [
                { key: 'name', label: 'NAME & EMAIL', sortable: true },
                { key: 'mobile_number', label: 'MOBILE NUMBER', sortable: true   },
                { key: 'created_at', label: 'CREATED ON', sortable: true   },
                { key: 'status', label: 'STATUS', sortable: true },
                { key: 'toggleAction', label: 'ACTION', }
            ],
            id: atob(this.$route.params.id),
            pager: {},
            pageOfItems: [],
			company_jobs:[],
            items:[],
            social_profile:[],
			social_profile_website_url:'',
            social_profile_fb_url:'',
            social_profile_google_url:'',
            social_profile_link_url:'',
            social_profile_twitter_url:'',
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: false,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
			rejection_remark:'',
			rejected_by: '',
			rejected_user: '',
            noClose:true,

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD MMM YYYY')
        },
		dateTimeAgo: function(value) {
                var m = moment(value);  // or whatever start date you have
                // var today = moment().startOf('day');
                var today = moment();
                    var days = Math.round(moment.duration(today - m).asDays());
                if(days > 0){
                    return days +' Days Ago'
                }else{
                    return 'today'
                }
                // if (!value) return ''
                // const dateTimeAgo = moment(value).fromNow();
                // return dateTimeAgo
		    },
           
		customizeRoleType: function(value) {
			if (!value) return ''
			var i, frags = value.split('-');
			for (i=0; i<frags.length; i++) {
				frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
			}
			return frags.join(' ');
		},
    },
	components: {
      StarRating
    },
    methods:{
		breadCrumb(){
                var item = [{
                    to:{name:'empManageUserList'},
                    text: 'Manage User',
                },{
                    to:null,
                    text: 'View Profile'
                }];
                return item;
        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
				imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
		async copyURL() {
                    try {
						let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");
                        // var mytext = $('#copy-url').val();
                        // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                        //     if (result.state == "granted" || result.state == "prompt") {
                        //         navigator.clipboard.writeText(mytext);
                        //     }
                        // });
                        
                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        }); 
                        // alert('Copied to Clipboard.');
                    } catch($e) {

                        console.log($e);
                        
                        // alert('Cannot copy');
                    }
              },
        getEmployers() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/emp-profile-view-manage-user'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.company;
                        this.company_jobs = this.$store.getters.getResults.company_jobs;
                        this.company_jobs_count = this.$store.getters.getResults.company_jobs_count;
                        this.social_profile = this.$store.getters.getResults.social_profile;
						if(this.social_profile.length > 0){
							this.social_profile.forEach(element => {
								if(element.account_type == 'website'){
									this.social_profile_website_url = element.url;
								}
								if(element.account_type == 'fb'){
									this.social_profile_fb_url = element.url;
								}
								if(element.account_type == 'google'){
									this.social_profile_google_url = element.url;
								}
								if(element.account_type == 'link'){
									this.social_profile_link_url = element.url;
								}
								if(element.account_type == 'twitter'){
									this.social_profile_twitter_url = element.url;
								}
								
							});
							console.log(this.social_profile_google_url,'google')
						}
                        // this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        // this.perPage = this.$store.getters.getResults.pager.pageSize;
                        // this.from = this.$store.getters.getResults.pager.from;
                        // this.to = this.$store.getters.getResults.pager.to;
                        // return this.items;
                    }
                });
        },
		changeUserStatus(status, id){
			if(status == 'approve'){
				var status = status;
				var id = id;
				var rejected_by = ''

			}else{
				var status = this.status;
				var id = this.rejected_user;
				var rejected_by = this.rejected_by
			}

			return this.$store.dispatch(POST_API, {
				data:{
					user_id:id,
					status: status,
					rejected_by: rejected_by,
					rejection_remark: this.rejection_remark
				},
				api: '/api/change-emp-account-dpstatus-admin'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showAlert();

				} else {
					if(status == 'approve'){
						this.$swal({
							position: 'center',
							icon: 'success', 
							iconColor:'#4c8bf5',
							title: 'User approved successfully.',
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
							//  this.personal = false;
							// this.links = true;
							// this.disabled = false;                                                                                                                              
							window.location.replace('/v2/admin/employer');
							// window.scrollTo(0,0);
						});
					}else{
						this.$swal({
							position: 'center',
							// icon: 'error', 
							imageUrl: '/assets/images/404_elcy.gif',
							customClass:{
								container: 'mascot_error_container',
							},
							iconColor:'#4c8bf5',
							title: 'User rejected successfully.',
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
							//  this.personal = false;
							// this.links = true;
							// this.disabled = false;                                                                                                                              
							window.location.replace('/v2/admin/employer');
							// window.scrollTo(0,0);
						});
					}
					 
					
				}
			});
		},
		openModel(status, id){
          this.$refs['rejection-modal'].show(); 
		  this.rejected_user = id;
		  this.status = status
		  this.rejected_by = this.form.user_id


		},
		jobDetail(id,event){
            if (event) {
            event.stopPropagation()
            }
            window.location.replace('/v2/employer/ft-jobdetail/'+id);

        },
    },
    mounted(){
		$(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.getEmployers();
		 $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
    }
}
</script>
