<template>
    <div class="animated fadeIn">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Billing Type</h1>
            </div> -->
             <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Billing Type</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12" sm="12" class="z_index_zero submit-field notification_radio common_radio_and_checkbox_color">
                                    <h5 class="required" >Billable<span class="required" style="color:red">*</span></h5>
                                        <b-form-group id="input-group-8" label="" class="category-radio common_fix_radio desktop_margin_bottom_zero">
                                            <b-form-radio name="billable" value="yes" v-model="form.billable" >Yes</b-form-radio>
                                            <b-form-radio name="billable" value="no" v-model="form.billable" >No</b-form-radio>
                                        </b-form-group>
                                </b-col>
                                <b-col lg="12" md="12">
                                    <b-form-group id="Billing Type-name-group" label="Billing Type Name" class="required promo_require">
                                        <b-form-input id="name" v-model="form.name" type="text" placeholder="Enter Billing Type Name.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'BillingTypes'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import 'cropperjs/dist/cropper.css';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";


export default {

    data() {
        return {
            form: {
                name: '',
                billable:'no'

            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            
            delta: undefined,
            webUrl: process.env.VUE_APP_URL,
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true
    
        }
    },
    components: {
        Datepicker
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'BillingTypes'},
                text: 'Billing Types',
            },{
                to:null,
                text: 'Edit Billing Type'
            }];
            return item;
        },
        customFormatterTwo(date) {
            console.log(date)
            return moment(date).format('DD-MM-YYYY');
        },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        status: this.status,
                        page: this.currentPage,
                        keyword: this.filter,
                        id: this.id,
                    },
                    api: '/api/billing-type-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.name = this.$store.getters.getResults.data.name;
                        this.form.billable = this.$store.getters.getResults.data.billable;

                    }
                });
        },
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        name: this.form.name,
                        billable: this.form.billable,
                        items:this.form,
                    },
                    api: "/api/billing-type-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Billing Type Updated successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'BillingTypes' });
            });
        },
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Database - Edit BillingTypes";
        this.getEditData();
    },
};

</script>
