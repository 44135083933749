<template></template>

<script>
import { POST_API } from "../store/actions.type";
import VueCropper from "vue-cropperjs";
import CKEditor from "ckeditor4-vue";
import VueRouter from "vue-router";

var router = new VueRouter({
  mode: "history",
  routes: [],
});

export default {
  methods: {
    getRewardDetail() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/reward-detail-no-auth-api",
          data: {
            id: atob(this.$route.params.id),
          },
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            console.log(this.$store.getters.getResults.data);
            window.location.replace("/rewards");
            // window.location.replace(
            //   "/reward-detail/" +
            //     this.$route.params.id
            // );
          }
        });
    },
  },
  mounted() {
    this.user_type =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
    if (this.user_type == 3) {
      // window.location.replace("/v2/employer/reward-detail//" + this.$route.params.id);
      window.location.replace("/v2/employer/rewards");
    } else {
      this.getRewardDetail();
    }

    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>
