<template>
  <div>
    <section id="form">
      <div class="bg_color_singpass">
        <div class="main_two_div">
          <SingpassLeftSideContentComponent />
          <div class="right_side_div_sinpass">
            <div class="">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="create_account_h2_sing common_montesrat_regular_singpass">
                    Create Account
                  </h2>
                  <img :src="webUrl + 'assets/images/Group-733.png'" class="common_verify_img" />
                  <p
                    class="common_montesrat_regular_singpass top_information common_font_weight_600"
                  >
                    Kindly Confirm Your Email Address to Continue
                  </p>
                </div>
              </div>
            </div>
            <form id="formApplication" class="toggle_content">
              <div class="">
                <div class="row page_height_row">
                  <div class="col-md-7">
                    <div class="new_submit_field">
                      <h5 class="">Email Address</h5>
                      <div class="input-group new_submit_field">
                        <input
                          type="text"
                          class="form-control with-border"
                          v-model="email"
                          disabled
                        />
                        <!-- <input type="text" class="form-control with-border" v-model="email"> -->

                        <div class="verify-btn">
                          <button
                            type="submit"
                            v-if="this.showVerifyEmail"
                            id="verifybutton"
                            @click="emailVerify"
                            class="button ripple-effect continue_btn_singpass verify_btn_common"
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                      <h5 class="margin_top_13" v-if="countDownEmail > 0">
                        Didn't receive email? Resend email in
                        <span style="color: #4c8bf5 !important"> {{ countDownEmail }} seconds</span>
                      </h5>
                      <h5 class="web_bottom_zero margin_top_13" v-if="countDownEmail > 0">
                        Verification link has been sent to your email address. Please follow the
                        instructions written and press on the "Proceed" button after successfully
                        verifying your email.
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-12 verification_message_singpass">
                    <span
                      style="color: green; display: none"
                      id="verifyMsg"
                      class="message_text_singpass"
                      ><span class="material-icons-outlined message_box">markunread_outlined</span>
                      Mail sent successfully</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 align_end page_height_button">
                    <button
                      type="submit"
                      @click="redirect"
                      class="button ripple-effect continue_btn_singpass"
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="margin-top-70 mobile_tab_margin_top_45"></div> -->
  </div>
</template>

<script>
import { POST_API, PERMISSION_DATA } from "../../store/actions.type";
import axios from "axios";
import SingpassLeftSideContentComponent from "./SingpassLeftSideContentComponent.vue";

function str(data) {
  if (!data) return null;
  if (data.value) return data.value;
  else if (data.desc) return data.desc;
  else if (typeof data == "string") return data;
  else return "";
}

function populate(frm, data) {
  $.each(data, function (key, value) {
    $("[name=" + key + "]", frm).val(value);
    // $('[name=' + key + ']', frm).prop('disabled', true);
  });
}
// ---END---PREFILL FORM (with MyInfo data)

export default {
  metaInfo: {
    title: "EL Connect - Terms of Service",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - Terms of Service" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/terms-of-service" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/terms-of-service" }],
  },
  data() {
    return {
      x: "terms_jobseeker",
      terms_and_conditions_ptUser: "",
      terms_and_conditions_supervisor: "",
      showDismissibleAlert: false,
      error_message: "",
      display: true,
      link_error: "",
      webUrl: process.env.VUE_APP_URL,
      codeVerifier1: "",
      email: window.localStorage.getItem("email"),
      user_id: window.localStorage.getItem("user_id"),
      showVerifyEmail: true,
      countDownEmail: 0,
    };
  },
  components: {
    SingpassLeftSideContentComponent,
  },
  methods: {
    // getGenerateCode(){
    //      this.$store.dispatch(POST_API, {
    //         data:{
    //             authCode: this.$route.query.code,
    //         },
    //         api:'/getfl/generateCodeChallenge'
    //     })
    //     .then((res) => {

    // 			this.codeVerifier1 = this.$store.getters.getResults.sessiondata

    // 			console.log(this.codeVerifier1,'uuuuuuuuuu');
    // 			this.getSingpassData();
    // 	})
    // },
    // getSingpassData(){

    // 	this.$store.dispatch(POST_API, {
    //         data:{
    //             authCode: this.$route.query.code,
    // 			codeVerifier:this.codeVerifier1
    //         },
    //         api:'/getfl/getPersonData'
    //     })
    // 	// const formData = new FormData()
    // 	// formData.append('authCode', this.$route.query.code)
    // 	// formData.append('codeVerifier', this.codeVerifier1)

    // 	// axios.post('http://localhost:3001/v2/getfl/getPersonData', formData, {
    // 	// })
    // 	.then((res) => {

    // 		console.log(res);

    //         if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;

    //             this.$swal({
    //                 position: 'center',
    //                 icon: 'error',
    //                 title: this.error_message,
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             });

    //         } else {
    //         }
    // })

    // }
    countDownTimerEmail() {
      if (this.countDownEmail > 0) {
        this.showVerifyEmail = false;
        this.timeOutCounterEmail = setTimeout(() => {
          this.countDownEmail -= 1;
          this.countDownTimerEmail();
        }, 1000);
      } else {
        this.showVerifyEmail = true;
      }
    },
    emailVerify() {
      if (this.showVerifyEmail) {
        clearTimeout(this.timeOutCounterEmail);
        // this.countDownEmail = 30;
        return this.$store
          .dispatch(POST_API, {
            data: {
              email: this.email,
              user_id: this.user_id,
            },
            api: "/api/sendEmailVerify",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              (this.countDownEmail = 30), this.countDownTimerEmail();
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Verification mail sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // window.location.reload();
                // $('#verifyMsg').css("display","inline-block");
                // $('#verifybutton').css("display","none");
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please try again in " + this.countDownEmail + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    showAlert() {
      this.$swal({
        position: "center",
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    redirect() {
      this.getMenuPermission();
    },

    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.user_type == "3") {
              // window.location.replace('/v2/employer/dashboard');
              this.is_company_head = this.$store.getters.currentUser.is_company_head;
              this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                if (this.is_company_head == "yes") {
                  window.location.replace("/v2/employer/employer-profile");
                } else {
                  window.location.replace("/v2/employer/om-supervisor-profile");
                }
              }
            } else {
              window.location.replace("/v2/jobseeker/daily-job-profile");
            }
          }
        });
    },

    prefillForm(data) {
      console.log(data);
      // prefill form data
      var noaData = "";
      var address = "";
      if (data["noa-basic"]) {
        noaData = str(data["noa-basic"].amount)
          ? formatMoney(str(data["noa-basic"].amount), 2, ".", ",")
          : "";
      }
      if (data.regadd.type == "SG") {
        address =
          str(data.regadd.country) == ""
            ? ""
            : str(data.regadd.block) +
              " " +
              str(data.regadd.building) +
              " \n" +
              "#" +
              str(data.regadd.floor) +
              "-" +
              str(data.regadd.unit) +
              " " +
              str(data.regadd.street) +
              " \n" +
              "Singapore " +
              str(data.regadd.postal);
      } else if (data.regadd.type == "Unformatted") {
        address = str(data.regadd.line1) + "\n" + str(data.regadd.line2);
      }
      var formValues = {
        uinfin: str(data.uinfin),
        name: str(data.name),
        sex: str(data.sex),
        race: str(data.race),
        nationality: str(data.nationality),
        dob: str(data.dob),
        email: str(data.email),
        // str(data.mobileno.prefix) + str(data.mobileno.areacode) + " " +
        mobileno: str(data.mobileno.nbr),
        regadd: address,
      };

      // Populate values
      populate("#formApplication", formValues);
    },
  },
  mounted() {
    $(function () {
      $("#formAuthorize").submit(function (event) {
        event.preventDefault();
        callAuthorizeApi();
      });
      $("#formApplication").submit(function (event) {
        event.preventDefault();
        // add code here to submit the application form back to server for processing
        $("#complete").toggleClass("hidden");
      });
    });
    // ---END---MAIN HANDLER---

    // ---START---AUTH API---
    function callAuthorizeApi() {
      //Call backend server to generate code challenge
      $.ajax({
        url: "/getfl/generateCodeChallenge",
        data: {},
        type: "POST",
        success: function (result) {
          //Redirect to authorize url after generating code challenge
          var authorizeUrl =
            authApiUrl +
            "?client_id=" +
            clientId +
            "&scope=" +
            scope +
            "&purpose_id=" +
            purpose_id +
            "&code_challenge=" +
            result +
            "&code_challenge_method=" +
            method +
            "&redirect_uri=" +
            redirectUrl;

          window.location = authorizeUrl;
        },
        error: function (result) {
          alert("ERROR:" + JSON.stringify(result.responseJSON.error));
        },
      });
    }
    // ---END---AUTH API---

    var data = window.localStorage.getItem("singpassData");

    console.log(JSON.parse(data));

    //document.title = "EL Connect - Terms of Service";
    if (data) {
      this.prefillForm(JSON.parse(data));
    }

    function populate(frm, data) {
      $.each(data, function (key, value) {
        $("[name=" + key + "]", frm).val(value);
        $("[name=" + key + "]", frm).prop("disabled", true);
      });
    }

    window.scrollTo(0, 0);
  },
};
</script>
