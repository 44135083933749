
 <template>
    <div>

        <!-- Titlebar
        ================================================== -->
     <div id="titlebar" class="gradient signup_mrgn blog_headings">
        <div class="container blog_container">
            <div class="row">
                <div class="col-md-10">
                    <h1 class="text-center">{{blog_detail.length > 0 ? language == 'english' ? blog_detail[0].title : blog_detail[0].title_zh : 'Blog Post Page'}}</h1>
                </div>
                <div class="col-md-2" v-if="blog_detail[0].title_zh">
                    <button class="button button-sliding-icon ripple-effect" v-if="language == 'english'" @click="getBlogData('chinese')">Chinese</button>
                    <button class="button button-sliding-icon ripple-effect" v-else @click="getBlogData('english')">English</button>
                </div>
            </div>
        </div>
    </div>


    <!-- Page Content
        ================================================== -->
        <div class="container blog_container">
            <div class="row">
                <!-- Inner Content -->
                <div class="col-xl-12 col-lg-12">
                    <!-- Blog Post -->
                    <div class="blog-post single-post">

                        <!-- Blog Post Thumbnail -->
                        <div class="blog-post-thumbnail">
                            <div class="blog-post-thumbnail-inner">
                                <span class="blog-item-tag">{{blog_detail.length > 0 ? blog_detail[0].tag : ''}}</span>
                                <img :src="blog_detail.length > 0 ? blog_detail[0].image : webUrl+''" alt="Blog Image">
                            </div>
                        </div>

                        <!-- Blog Post Content -->
                        <div class="blog-post-content">
                             <div v-if="blog_detail.length > 0 && language == 'english'" class="blog_content">
                                <div v-if="blog_detail[0].description" class="">
                                     <p v-html="blog_detail[0].description" class="blog_whole_content_p_inr"></p>
                                    <!-- <p v-text="blog_detail[0].description" class="blog_whole_content_p_inr"></p> -->
                                </div>
                                <div v-else class="no_data_about  mobile_tab_max_width_flex">
                                    <h3>No Data Found</h3>
                                </div>
                            </div>
                            <div v-if="blog_detail.length > 0 && language == 'chinese'" class="blog_content">
                                <div v-if="blog_detail[0].description_zh" class="">
                                     <p v-html="blog_detail[0].description_zh" class="blog_whole_content_p_inr"></p>
                                    <!-- <p v-text="blog_detail[0].description_zh" class="blog_whole_content_p_inr"></p> -->
                                </div>
                                <div v-else class="no_data_about  mobile_tab_max_width_flex">
                                    <h3>No Data Found</h3>
                                </div>
                            </div>

                            <!-- Share Buttons -->

                            <div class="share-buttons margin-top-25">
                                <div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
                                <div class="share-buttons-content">
                                    <span>Interesting? <strong>Share It!</strong></span>
                                    <ul class="share-buttons-icons">
                                        <li style="">
                                                <ShareNetwork
                                                    network="facebook"
                                                    quote="AwesomeArticle"
                                                    :url="this.blogURL"
                                                    hashtags="Blogs, SharedBlogs">
                                                </ShareNetwork>
                                        </li>
                                        <li>
                                            <ShareNetwork
                                                network="twitter"
                                                :url="this.blogURL"
                                                title="Please find the shared blog by using the given url"
                                                hashtags="Blogs, SharedBlogs">
                                            </ShareNetwork>
                                        </li>
                                        <li>
                                            <ShareNetwork
                                                network="linkedin"
                                                :url="this.blogURL"
                                                title="Please find the shared blog by using the given url"
                                                description="test"
                                                hashtags="Blogs, SharedBlogs">
                                            </ShareNetwork>
                                        </li>
                                    </ul>
                                    <!-- <ul class="share-buttons-icons">
                                        <li><a href="#" data-button-color="#3b5998" title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                                        <li><a href="#" data-button-color="#1da1f2" title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                                        <li><a href="#" data-button-color="#dd4b39" title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                                        <li><a href="#" data-button-color="#0077b5" title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Blog Post Content / End -->

                    <!-- Blog Nav -->
                    <ul id="posts-nav" class="margin-top-0" v-if="(prev_blog.length > 0) || (next_blog.length > 0)">
                        <div class="">
                            <div  class="" v-if="prev_blog.length > 0">
                             <li class="prev-post">
                                <a href="#" @click="blogDetail($event,prev_blog[0].id,prev_blog[0].title,prev_blog[0].seo_permalink)" class="">
                                        <!-- <span class="blog-item-tag">{{ prev_blog[0].tag }}</span> -->
                                         <span>Previous Post</span>
                                        <!-- <img src="/assets/images/blog-02a.jpg" alt=""> -->
                                        <!-- <span class="blog-post-date">{{ prev_blog[0].date | momentDate }}</span> -->
                                        <strong>{{ prev_blog[0].title }}</strong>
                                        <!-- <p>{{ prev_blog[0].description }}</p> -->
                                        <div class="entry-icon"></div>
                                </a>
                             </li>
                            </div>
                            <div  class="recent_blog_post" v-if="next_blog.length > 0">
                                 <li class="next-post">
                                    <a href="#" @click="blogDetail($event,next_blog[0].id,next_blog[0].title,next_blog[0].seo_permalink)" class="">
                                            <!-- <span class="blog-item-tag">{{ next_blog[1].tag }}</span> -->
                                             <span>Next Post</span>
                                            <!-- <img src="/assets/images/blog-02a.jpg" alt=""> -->
                                            <!-- <span class="blog-post-date">{{ next_blog[1].date | momentDate }}</span> -->
                                            <strong>{{ next_blog[0].title }}</strong>
                                            <!-- <p>{{ next_blog[0].description }}</p> -->
                                            <div class="entry-icon"></div>
                                    </a>
                                 </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70 mobile_margin_top_35"></div>
        <!-- Spacer / End-->
    </div>
</template>

<script>
  import { POST_API } from '../../store/actions.type';
  import moment from 'moment';
    import { Carousel, Slide } from 'vue-carousel';
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import commonLocalBusinesSchemaComponent from './commonLocalBusinesSchemaComponent.vue';

  export default{
      metaInfo: {
            //   title: 'EL Connect - Blog',
              meta: [
                  { charset: 'utf-8' },
                //   { name: 'description', content: 'Blog - Unlock Your Potential with Daily-Paid Jobs! Explore Exciting Opportunities on Our Platform and Start Earning Daily. Join Us Today for a Brighter Tomorrow!' },
                  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                //   { property: 'og:title', content: 'EL Connect - Blog Detail'},
                  { property: 'og:site_name', content: 'EL Connect'},
                  { property: 'og:type', content: 'article'},
                //   { property: 'og:url', content: 'https://elconnect.sg/blog/detail'},
                //   { property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                //   { property: 'og:description', content: 'Blog - Unlock Your Potential with Daily-Paid Jobs! Explore Exciting Opportunities on Our Platform and Start Earning Daily. Join Us Today for a Brighter Tomorrow!'},
                  { name: 'twitter:card', content: 'summary'}
              ],
            //   link: [
            //       { rel: 'canonical', href: 'https://staging.elconnect.sg/blog/detail'}
            //   ]
      },
      data() {
          return {
              blog_id:this.$route.params.id,
              blog_title:this.$route.params.title,
              blog_detail:'',
              prev_blog:[],
              next_blog:[],
              blogURL:'',
              x: 'jobseeker',
              language:'english',
              webUrl:process.env.VUE_APP_URL,
              featured_post: [],
              recent_post: [],
              trending_post: [],
              settings: {
                  "slidesToShow": 3,
                  "slidesToScroll": 3,
                  "speed": 800,
                  "infinite": false,
                  responsive: [
                    {
                    // breakpoint: 768,
                    // settings: {
                    // 	"slidesToShow": 1,
                    // 		"slidesToScroll": 1,
                    // 		"speed": 800,
                    // 		"infinite": false,
                              //         "dots":true,
                              //         "arrows":false
                    //     }
                    "breakpoint": 998,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            // "infinite": true,
                            "dots": true,
                            "arrow":true
                        }
                        },
                        {
                        "breakpoint": 1000,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            // "infinite": true,
                            "dots": true,
                            "arrow":true
                        }
                        },
                        {
                        "breakpoint": 768,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "initialSlide": 1,
                            "dots": true,
                            "arrow":true
                        }
                        },
                        {
                        "breakpoint": 480,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "dots": true,
                            "arrow":true
                        }

                        }
                      ]
                    }
            }
      },
      components: {
          VueSlickCarousel,
          Carousel,
          Slide,
          commonLocalBusinesSchemaComponent
      },
      filters: {
        momentDate: function(date) {
          return moment(date).format("DD MMM YYYY")
        },

        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

        customFormatterOne(date) {
            return moment(date).format('DD-MM-YYYY');
        },

        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
      },

      methods:{
        getBlogData(language){
            this.language = language;
          this.$store.dispatch(POST_API, {
            data:{ id: (this.blog_id),language: this.language },
            api:'/api/blog-detail-data'
          })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    return [];
                }
                else {
                  this.blog_detail = this.$store.getters.getResults.blog_detail;
                  console.log(this.blog_detail,'++++++++++')
                  this.prev_blog = this.$store.getters.getResults.prev_blog;
                  this.next_blog = this.$store.getters.getResults.next_blog;
                  var imgTags = $(this.blog_detail[0].description).find('img');
                    var imgSrc = [];
                    for (var i = 0; i < imgTags.length; i++) {
                        var imgTag = imgTags[i];
                        imgSrc.push(imgTag.src);
                  }
                  document.title = this.language == 'english' ? this.blog_detail[0].seo_title : this.blog_detail[0].seo_title_zh;
                    const meta = document.createElement('meta');
                    meta.setAttribute('name', 'description');
                    meta.setAttribute('content', this.language == 'english' ? this.blog_detail[0].seo_description : this.blog_detail[0].seo_description_zh);
                    document.head.appendChild(meta);

                    const link = document.createElement('link');
                    link.setAttribute('rel', 'canonical');
                    this.blogURL = process.env.VUE_APP_URL + "blog/" + this.blog_id
                    link.setAttribute('href', this.blogURL);
                    document.head.appendChild(link);

                    const og_title = document.createElement('meta');
                    og_title.setAttribute('property', 'og:title');
                    og_title.setAttribute('content', document.title);
                    document.head.appendChild(og_title);

                    const og_url = document.createElement('meta');
                    og_url.setAttribute('property', 'og:url');
                    og_url.setAttribute('content', this.blogURL);
                    document.head.appendChild(og_url);

                    const og_image = document.createElement('meta');
                    og_image.setAttribute('property', 'og:image');
                    og_image.setAttribute('content', this.blog_detail[0].image);
                    document.head.appendChild(og_image);

                    const og_description = document.createElement('meta');
                    og_description.setAttribute('property', 'og:description');
                    og_description.setAttribute('content', this.language == 'english' ? this.blog_detail[0].seo_description : this.blog_detail[0].seo_description_zh);
                    document.head.appendChild(og_description);

                    const blogPostSchema = document.createElement('script');
                    blogPostSchema.setAttribute('type', 'application/ld+json');
                    var content = this.language == 'english' ? this.blog_detail[0].description : this.blog_detail[0].description_zh;
                    var text = $(content).text();
                    blogPostSchema.text = `
                     {
                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${this.blogURL}"
                        },
                        "headline": "${document.title ? document.title : this.language == 'english' ? this.blog_detail[0].title : this.blog_detail[0].title_zh}",
                        "description": "${this.language == 'english' ? this.blog_detail[0].seo_description ? this.blog_detail[0].seo_description : text : this.blog_detail[0].seo_description_zh ? this.blog_detail[0].seo_description_zh : text}",
                        "image": "${this.blog_detail[0].image}${!imgSrc ? '' : ','} ${imgSrc ? imgSrc : ''}",  
                        "author": {
                            "@type": "Organization",
                            "name": "El Connect",
                            "url": "https://elconnect.sg"
                        },  
                        "publisher": {
                            "@type": "Organization",
                            "name": "El Connect",
                            "logo": {
                            "@type": "ImageObject",
                            "url": "https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/logo2.png"
                            }
                        },
                        "datePublished": "${this.blog_detail[0].date}",
                        "keywords": "${this.blog_detail[0].tag}"
                        }`;
                        document.head.appendChild(blogPostSchema);

                        const blogCarouselDetailedListSchema = document.createElement('script');
                        blogCarouselDetailedListSchema.setAttribute('type', 'application/ld+json');
                        var content = this.language == 'english' ? this.blog_detail[0].description : this.blog_detail[0].description_zh;
                        var text = $(content).text();
                        
                        blogCarouselDetailedListSchema.text = `
                        {
                            "@context":"https://schema.org",
                            "@type":"ItemList",
                            "itemListElement":[
                                {
                                "@type":"ListItem",
                                "position":1,
                                "name": "${document.title ? document.title : this.language == 'english' ? this.blog_detail[0].title : this.blog_detail[0].title_zh}",
                                "url":"${this.blogURL}",
                                "image": "${this.blog_detail[0].image}${!imgSrc ? '' : ','} ${imgSrc ? imgSrc : ''}",
                                "description": "${this.language == 'english' ? this.blog_detail[0].seo_description ? this.blog_detail[0].seo_description : text : this.blog_detail[0].seo_description_zh ? this.blog_detail[0].seo_description_zh : text}"
                                }
                            ]
                            }`;
                            document.head.appendChild(blogCarouselDetailedListSchema);
                    }
             });
        },
        getlocalbusinesSchema(){
            <commonLocalBusinesSchemaComponent/>
        },
        blogDetail(event,id,title,seo_permalink){
          this.$store.dispatch(POST_API, {
            data:{ 
              id: id,
            },
            api:'/api/blog-detail-view-count'
          })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    return [];
                }
                else {
                  this.blogDetail2(event,id,title,seo_permalink);
                }
            });
        },
        blogDetail2(event,id,title,seo_permalink){
            if (event) {
                event.stopPropagation()
            }
            var titleNew = title.replace(/ /g, "-");
            window.location.replace('/blog/' +seo_permalink);
		},
      },

      mounted(){
        // this.getlocalbusinesSchema();
        $(".share-buttons-icons").addClass('hide_social_share');
            $(".share-buttons-content").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
            });
            $(".share-buttons-trigger").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
        });
        window.scrollTo(0,0);
        this.blogURL =  process.env.VUE_APP_URL + "blog/" + this.blog_id ;
        console.log(this.blogURL)
        this.getBlogData(this.language);
        // var metaDescriptionTag = document.querySelector('meta[name="description"]');
        // if(atob(this.blog_id)==8)
        // {
        //     document.title = 'Jobs & Manpower: Benefits of Sourcing them from EL Connect Site';
        //     metaDescriptionTag.content = 'Your new description goes here';
        // }
        // else{
        //      document.title = 'EL Connect Blog';
        // };
      }
  }

  </script>
