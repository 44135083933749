<template>
	<div >
        <div>
            <!-- Header Section HTML -->
            <JobseekerHeader />
        </div>

        <!-- Banner Section HTML -->
        <router-view></router-view>

        <div >
            <!-- Footer Section HTML -->
            <JobseekerFooter  />
        </div>
    </div>
</template>
<script>
import JobseekerHeader from './JobseekerHeader.vue';
import JobseekerFooter from './JobseekerFooter.vue';

export default {
    name: 'JobseekerNavbarContainer',
    components: {
      JobseekerHeader,
      JobseekerFooter ,
    },
    mounted(){

        $("#jobseeker-btn").on('click', function(){
            window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
        });
    }
}
</script>
<style lang="scss">

</style>
