<template>
    <div class="animated fadeIn">
      <div class="create-new-job-section email-template-section">
        <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                  <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit ELC Credit</h1>
              </div> -->
        <!-- <div class="dashboard-headline">
                  <h1 class="header_detail_new_h1"><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit ELC Credit</h1>
              </div> -->
        <div id="breadcrumb-v2" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb :items="breadCrumb()" />
          </div>
        </div>
        <div class="edit-profile-section border-radius-4 white-bg common_padding_30">
          <b-form @submit="formSubmit">
            <div class="edit-form-box desktop_margin_top_zero">
                <div class="profile-repeate form-box-field">                            
                    <b-row>
                        <b-col lg="12" md="12">
                            <b-form-group id="greetingMessage" label="Greeting Message" class="required">
                                <b-form-textarea id="greetingMessage" v-model="form.greetingMessage" type="text" placeholder="Enter Greeting Meessage..."></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                    <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                    <b-button :to="{name:'elcy-greeting-messages-list'}" variant="light">Cancel</b-button>
                </div>
              <!-- </b-col> -->
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { POST_API } from "../../../store/actions.type";
  
  export default {
    data() {
      return {
        form: {
          greetingMessage: ''
        },
        items: null,
        // id: Vue.prototype.decrypt(this.$route.params.id),
        messageId: atob(this.$route.params.messageId),
        error_message: "",
        selectedImage: "",
        success_message: "",
        dismissSecs: 3,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        delta: undefined,
      };
    },
  
    methods: {
      breadCrumb() {
        var item = [{
            to:{name:'elcy-greeting-messages-list'},
            text: 'Greeting Message',
        },{
            to:null,
            text: 'Edit Greeting Message'
        }];
        return item;
      },
      getEditData() {
        return this.$store
          .dispatch(POST_API, {
            data: {
                greetingMessage: this.greetingMessage,
                page: this.currentPage,
                keyword: this.filter,
                messageId: this.messageId,
            },
            api: "/api/greeting-messages-edit",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              this.items = this.$store.getters.getResults.data.data;
              this.form.greetingMessage = this.$store.getters.getResults.data.greeting_message;
            }
          });
      },
      getSettings() {
        this.$store
          .dispatch(POST_API, {
            data: {},
            api: "/api/expirySettings",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
  
              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.expiry = this.$store.getters.getResults.data[0].option_value;
              this.coinsperjob = parseInt(this.$store.getters.getResults.data1[0].option_value);
              this.postperjob = parseInt(this.$store.getters.getResults.data2[0].option_value);
            }
          });
      },
  
      showAlert() {
        this.$swal({
          position: "center",
          icon: "error",
          title: this.error_message,
          showConfirmButton: false,
          timer: 1500,
        });
      },
      formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        greetingMessage: this.form.greetingMessage,
                        messageId: this.messageId,
                        
                        items:this.form,
                    },
                    api: "/api/greeting-messages-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();    
                    }
                });
        },
  
      showSuccessAlert() {
        this.$swal({
          position: "center",
          icon: "success",
          iconColor: "#4c8bf5",
          title: "Greeting Message Updated successfully.",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          this.$router.push({ name: "elcy-greeting-messages-list" });
        });
      },
    },
    mounted() {
      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
      this.getSettings();
  
      this.getEditData();
    },
  };
  </script>
  