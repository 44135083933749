<template>
    <div>
         <div class="chit_header">
            <img :src="webUrl+'assets/jobeekerImages/Group 16@2x.png'">
       </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            webUrl: process.env.VUE_APP_URL,
        }
    },
}
</script>

<style scoped>
  @import '/assets/css/jobseekers-new.css';
  @import '/assets/css/common-font-family.css';
</style>
