<template>
  <div>
    <!-- Titlebar
        ================================================== -->
    <div
      class="bg_img_top static_page_bg"
      v-bind:style="{
        backgroundImage:
          terms_and_conditions_ptUser.image &&
          terms_and_conditions_ptUser.show_banner_image == 'yes'
            ? 'url(' + terms_and_conditions_ptUser.image + ')'
            : 'url(' + webUrl + 'assets/images/about-banner.png)',
      }"
    >
      <!-- <div id="titlebar" class="gradient signup_mrgn faq_mrgn"> -->
      <div class="full_gragient">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <!--<h1 class="text-center faq_haed" v-html="terms_and_conditions_ptUser.title"></h1>-->
              <h1 class="text-center faq_haed">{{ terms_and_conditions_ptUser.title }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="margin-top-50 mobile_tab_margin_top_35"></div>
    <!-- Page Content
        ================================================== -->
    <div class="container">
      <div class="row">
        <div class="col-xl-12 common_block common_desc">
          <div class="selected_faq_radio">
            <input type="radio" v-model="x" value="terms_jobseeker" /><span class="first_span_radio"
              >Job Seekers</span
            >
            <input type="radio" v-model="x" value="terms_hirers" /><span>Employers</span>
          </div>
          <div v-show="x === 'terms_jobseeker'">
            <div class="form-head mb-3 text-center">
              <h4 class="golden-text"><b>Job Seekers</b></h4>
            </div>
            <p v-html="terms_and_conditions_ptUser.description" class="rmp_p"></p>
          </div>
          <div v-show="x === 'terms_hirers'">
            <div class="form-head mb-3 text-center emp_top">
              <h4 class="golden-text mt-3"><b>Employers</b></h4>
            </div>
            <p v-html="terms_and_conditions_supervisor.description"></p>
          </div>
        </div>
      </div>
    </div>

    <!-- Spacer -->
    <div class="margin-top-50 mobile_tab_margin_top_35"></div>
    <!-- Spacer / End-->
  </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";

export default {
  metaInfo: {
    title: "EL Connect - Terms of Service",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - Terms of Service" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/terms-of-service" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/terms-of-service" }],
  },
  data() {
    return {
      x: "terms_jobseeker",
      terms_and_conditions_ptUser: "",
      terms_and_conditions_supervisor: "",
      showDismissibleAlert: false,
      error_message: "",
      display: true,
      link_error: "",
      webUrl: process.env.VUE_APP_URL,
    };
  },
  methods: {
    getTermsConditions() {
      this.$store
        .dispatch(POST_API, {
          data: {
            token: this.email,
          },
          api: "/api/terms-condition",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.terms_and_conditions_ptUser =
              this.$store.getters.getResults.data[0].key == "terms_and_conditions_ptUser"
                ? this.$store.getters.getResults.data[0]
                : this.$store.getters.getResults.data[1];
            this.terms_and_conditions_supervisor =
              this.$store.getters.getResults.data[1].key == "terms_and_conditions_supervisor"
                ? this.$store.getters.getResults.data[1]
                : this.$store.getters.getResults.data[0];
          }
        });
    },
  },
  mounted() {
    //document.title = "EL Connect - Terms of Service";
    this.getTermsConditions();
    window.scrollTo(0, 0);
  },
};
</script>
