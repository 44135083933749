<template>
  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Freelancer Rating</h1>
        </div>

        <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
              </div> -->
      </div>
    </div>
    <b-row>

              <b-col lg="12">
        <div class="tab-box--head top_custom_head desktop_search_top_div">
          <div class="row">
            
            <div
              class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <b-input-group-prepend>
                  <b-form-input
                    id="searchBtn"
                    @keyup.enter.native="filter = search"
                    v-model="search"
                    placeholder="Search by keyword"
                  ></b-form-input>
                  <b-button class="btn points_events"
                    ><i class="fa fa-search" @click="filter = search"></i
                  ></b-button>
                </b-input-group-prepend>
              </div>
            </div>
            <!-- <div class="col-xl-3 col-md-3  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        :options="employers"
                        :multiple="false"
                        placeholder="Employer"
                        track-by="id"
                        label="full_name"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="selectEmployer"
                      >
                      </multiselect>
                    </b-form-group>
                  </div> -->
            <div class="top_black_btn margin_left_15_desktop mobile_text_start">
              <b-button
              @click="clearFilter()"
              class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
              style="background-color: red; margin-top: 20px;"
              >
              <span class="mobile_reset">Reset filters</span>
              </b-button>
              <b-button v-if="show('export')"
                class="common_z_index_zero button ripple-effect add_btn_admin_e desktop_margin_right_zero"
                @click="exportData()"
                style="margin-left: 19px"
                >Export</b-button
              >
            </div>
          </div>
        </div>
        <div class="container my_new_container show_filter_arrow p-0">
          <b-table
            ref="datatable"
            show-empty
            striped
            hover
            responsive
            :items="getRatingListing"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="mobile_min_width_1000 nric_three_grid_table_new"
          >
          <template #cell(job_id)="items"  >
                  {{items.item.job_id.job_unique_id }}
                </template>
            <template #cell(employer_full_name)="items">
                    {{ items.item.rated_by.full_name }}
                  </template>
              <template #cell(freelancer_full_name)="items">
                
                {{ items.item.user_id.full_name }}
                
              </template>
              <template #cell(rating)="items">
  <div>
    <span v-for="n in 5" :key="n" class="star">
      {{ n <= items.item.rating ? '★' : '☆' }}
    </span>
  </div>
</template>

            <template #cell(created_at)="items">
                {{ items.item.created_at | moment}}
            </template>
            <template #cell(review)="items">
                {{ items.item.review }}
            </template>
            <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset" >
                             <b-dropdown class="mx-1" left text="Action" boundary="window">
                                <b-dropdown-item @click="deleteRating(items.item.id)" v-if="show('delete-rating')">Delete
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
          </b-table>
        </div>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @change="getRatingListing">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </b-col>

    </b-row>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Multiselect from 'vue-multiselect'
import permission from "../../../../../server/permission.js";
export default {
  data() {
    return {
      fields: [
      { key: "toggleAction", label: "Actions", sortable: false },
        { key: "job_id", label: "Job ID", sortable: true },
        { key: "employer_full_name", label: "(From)Employer", sortable: true },
        { key: "freelancer_full_name", label: "(To)Freelancer", sortable: true },
        { key: "rating", label: "Rating", sortable: true },
        { key: "created_at", label: "Rated On", sortable: true },
        { key: "review", label: "Review", sortable: true },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      search: "",
      perPage: 25,
      itemsJobs: null,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,
      month: moment().format('DD MMM YYYY'),
      pageOptions: [25, 50, 75, 100],
      locale: {
        direction: "ltr", //direction of text   
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      webUrlV1: process.env.VUE_APP_URL_V1,
      form: {
        rowsPerPage: 25,
        status: "",
      },
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
      actionsFreelancer: "",
      user_id:"",
      employers:[],
      employer_id:'',
      designation:''
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    moment: function(date) {
            if (!date) return '-'
            return moment(date).format('DD-MM-YYYY hh:mm A')
     },
    momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
  },
  components: {
    Datepicker,
    Multiselect
  },
  methods: {
    customFormatter(date) {
            return moment(date).format('MMM-YYYY');
     },
     selectEmployer(selectedOption){
      this.employer_id=selectedOption.id
      this.$refs.datatable.refresh()
     },
    exportData() {
      var base_url = this.webUrlV1;
      var search=this.filter||null
      //var page = this.currentPage;
      var export_url =
        '/api/export-ratings/ptuser/'+search+'/null'

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    clearFilter(){
      this.search="";
      this.employer_id="";
      this.$refs.datatable.refresh();
    },
    deleteRating(id)
        {
          this.$swal({
          position: "center",
          icon: "warning",
          iconColor: "#3085d6",
          title: "Please Confirm",
          text: 'Are you sure you want to delete this record?',
          showCancelButton: true,
        })
            .then(result => {
                if (result.value) {
                        return this.$store.dispatch(POST_API, {
                          data:{
                                id: id,
                                status: this.status,
                                page: this.currentPage,
                                keyword: this.filter
                              },
                          api:"/api/delete-rating",
                            })
                            .then(() => {
                                if (this.$store.getters.containsErrors) {
                                    this.error_message = this.$store.getters.getErrors;
                                    this.showAlert();
                                } else {
                                  var message = 'Rating removed successfully.';
                                    this.showSuccessAlert(message);
                                }
                            });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        showSuccessAlert(message) {
        this.$swal({
          position: "center",
          icon: "success",
          iconColor: "#4c8bf5",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
            this.getRatingListing().then(() => {
              this.$refs.datatable.refresh();
            });
        });
      },
      showAlert() {
        this.$swal({
          position: "center",
          icon: "error",
          title: this.error_message,
          showConfirmButton: false,
          timer: 1500,
        });
      },
      // getEmployers(){
      //       this.$store.dispatch(POST_API, {
      //           data:{
      //             type:"ptuser",
      //           },
      //           api:'/api/rating-employer-list'
      //       })
      //       .then(() => {
      //           if (this.$store.getters.containsErrors) {
      //               this.error_message = this.$store.getters.getErrors;

      //               this.$swal({
      //                   position: 'center',
      //                   icon: 'error',
      //                   title: this.error_message,
      //                   showConfirmButton: false,
      //                   timer: 1500
      //               });

      //           } else {
      //             this.employers = this.$store.getters.getResults.data
      //             return this.employers;
      //           }
      //       });
      //   }, 
    getRatingListing() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.search,
            monthYear: this.month? moment(this.month).format("MM-YYYY")
              : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "user_id",
            sortDesc:this.sortDesc,
            sortDirection: this.sortDirection ? "desc" : "asc",
            type:"ptuser",
            user_id:this.employer_id,
          },
         api: `/api/rating-list-of-admin`
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.totalRows = this.$store.getters.getResults.data.total;
            this.perPage = this.$store.getters.getResults.data.per_page;
            this.from = this.$store.getters.getResults.data.from
            this.to = this.$store.getters.getResults.data.to
            console.log(this.items)
            return this.items;
          }
        });
    },
    dateFilterFunction()
    {
        this.getRatingListing().then(() => {
            this.$refs.datatable.refresh();
        })
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Freelancer Rating");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    show(action){
        if (this.$store.getters.currentUser.user_type_id == 2) {
            return this.actions.indexOf(action) >= 0 ? true : false ;  
        }else{
            return true;
        }
    },
    showFreelancer(action) {
      return true;
      // if (this.$store.getters.currentUser.user_type_id == 2) {
      //   return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      // } else {
      //   return true;
      // }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
      this.permissionStaff();
  },
};
</script>
<style scoped>
.star {
  color: gold; /* Customize star color */
  font-size: 20px; /* Adjust size */
  margin-right: 2px; /* Space between stars */
}
</style>