<template>
    <div class="animated fadeIn">
        <div class="manage-job-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center">
                <h3 class="mr-auto">{{jobDetail['title']}} ({{jobDetail['job_unique_id']}})</h3>
            
            </div> -->
            <div class="dashboard-headline">
                <h1 class="common_text_transform"><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>{{jobDetail['title']}} ({{jobDetail['job_unique_id']}})</h1>
            </div>
        </div>
        <div v-for="item in items" :key="item">
            <!-- <div  class="profile-section p-lg-5 p-3 white-bg view_audit_top_div"> -->
             <div  class="profile-section common_padding_30 white-bg view_audit_top_div">
                <div class="profile-box pl-0">
                    <div class="profile-detail break_detail">
                        <ul class="desktop_padding_left_17 desktop_margin_bottom_zero">
                            <li v-if="item.process" class="pl-0 desktop_margin_top_zero">
                                <p><b>Process</b>- <i> {{item.process | capitalize}} </i></p>
                            </li>
                            <li v-if="item.api" class="pl-0">
                                <p><b>Api</b>- <i> {{item.api}} </i></p>
                            </li>
                            <li v-if="item.process_log" class="pl-0">
                                <p><b>Process Log</b>- <i> {{item.process_log}}</i></p>
                            </li>
                            <li v-if="item.updated_at" class="pl-0">
                                <p><b>Updated At</b>- <i> {{item.updated_at | moment}}</i></p>
                            </li>
                            <li v-if="item.updated_by" class="pl-0">
                                <p v-if="item.updated_by"><b>Updated By</b>- <i> {{item.last_name | capitalize}} {{item.first_name | capitalize}}</i></p>
                                <p v-else><b>Updated By</b>- <i> - </i></p>

                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <br> -->
        </div>
        
    </div>
</template>
<script>
import { POST_API } from '../../../store/actions.type'
import moment from "moment";

export default {

    data() {
        return {
            job_id: atob(this.$route.params.id),
            first_name:'',
            last_name:'',
            jobDetail:null,
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            }
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).format('DD MMM YYYY')
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'jobseekers-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getAuditJobs() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        job_id:this.job_id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/emp-audit-jobs-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.jobDetail = this.$store.getters.getResults.jobDetail;
                        this.items = this.$store.getters.getResults.logDetail;
                    }
                });
        },
    },
    mounted(){
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
       // document.title = "EL Connect - Admin - Jobseekers List";
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.getAuditJobs();
    }
}
</script>
