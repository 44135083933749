<template>
    <div class="animated fadeIn">
        <div class="row">
            <div class="container my_new_container">
            <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Online Training</h1>
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Online Training</li>
                </ul>
            </nav>
            
        </div>  
            <div class="row">
                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                        <b-input-group-prepend>
                            <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                            <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                        </b-input-group-prepend>
                    </div>
                </div>
                <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                    <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero"  @click="clickToAdd('add')" v-if="show('add')">Add</b-link>
                </div>
            </div>
            </div>
        </div>
      <b-row>
        <b-col lg="12">
          
            <div class="border-radius-4 white-bg border-midium-light">
                <b-table ref="datatable" show-empty striped hover responsive :items="getListing" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 el_coin_four_grid_table">
                    <template #cell(ID)="items">
                        {{items.item.question_no | capitalize}}
                    </template>
                    <template #cell(question)="items">
                        {{items.item.question | capitalize}}
                    </template>
                    <template #cell(option1)="items">
                        {{items.item.options[0]}}
                    </template>
                    <template #cell(option2)="items">
                        {{items.item.options[1]}}
                    </template>
                    <template #cell(option3)="items">
                        {{items.item.options[2]}}
                    </template>
                    
                    <template #cell(correct_option)="items">
                        {{items.item.correct_answer | capitalize}}
                    </template>
                    <template  #cell(status)="items">
                    <p class="btn green-bg-btn desktop_margin_left_zero desktop_margin_right_zero" v-if="items.item.status == 'active'">{{items.item.status | capitalize}}</p>
                    <p class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero" v-if="items.item.status == 'inactive'">{{items.item.status | capitalize}}</p>
                    </template>   

                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item  v-on:click="updateData(items.item.question_no)" v-if="show('edit')">Edit
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeStatus(items.item.question_no, items.item.status)" v-if="items.item.status == 'active' && show('activate/inactivate')">Inactivate</b-dropdown-item>
                                <b-dropdown-item v-else-if="items.item.status == 'inactive' && show('activate/inactivate')" v-on:click="changeStatus(items.item.question_no, items.item.status)">Activate</b-dropdown-item>
                                <b-dropdown-item v-on:click="deleteData(items.item.question_no)" v-if="show('delete')">Delete</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                    
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                             <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getListing'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
            </div>
           
        </b-col>
        <b-modal ref="modal" :title="modalTitle" hide-footer content-class="common_model_header common_model_title"  modal-class="background_custom_class">
            <b-form @submit="formSubmit">
              <div class="edit-form-box model_margin_top_zero">
                  <div class="profile-repeate form-box-field model_legend ">
                      <b-row>
                          <b-col lg="12" md="12">
                              <div class="form-map-box">
                                  <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Question" class="required">
                                      <b-form-input id="input-9" maxlength="200" v-model="form.question" type="text" placeholder="" class="model_border_and_box_shadow"></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Option A " class="required">
                                      <b-form-input id="input-9" maxlength="300" v-model="form.option1" type="text" placeholder="" class="model_border_and_box_shadow"></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Option B " class="required">
                                     <b-form-input id="input-9" maxlength="300" v-model="form.option2" type="text" placeholder="" class="model_border_and_box_shadow"></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col lg="12" md="12">
                                      
                                    <b-form-group id="input-group-9" label="Option C " class="required">
                                      <b-form-input id="input-9" maxlength="300" v-model="form.option3" type="text" placeholder="" class="model_border_and_box_shadow"></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col lg="12" md="12">
                                      <b-form-group id="input-group-8" label="Correct Answer " class="category-radio required employer_multiple_radio">
                                          <b-form-radio v-model="form.correct_answer" name=" correct Answer" value="A">A</b-form-radio>
                                          <b-form-radio v-model="form.correct_answer" name="correct Answer" value="B">B</b-form-radio>
                                          <b-form-radio v-model="form.correct_answer" name="correct Answer" value="C">C</b-form-radio>
                                          <p class="required-text mobile_margin_top_10_new">(Select from above options)</p>
                                      </b-form-group>
  
                                  </b-col>
                                  <b-col lg="12" md="12">
                                    <div slot="footer" class="form-btn common_crop_reset_btn">
                                        <b-button type="submit" variant="success" class="model_common_bt_success_color desktop_margin_right_zero desktop_margin_left_zero">Submit</b-button>
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                    </div>
                                  </b-col>
                              </div>
                          </b-col>
                      </b-row>
                  </div>
              </div>
            </b-form>
        </b-modal>
        
      </b-row>
  
    </div>
  
  </template>
  <script>
    import { POST_API,GET_API} from "../../../store/actions.type";
import permission from "../../../../../server/permission.js";

    import JwtService from "../../../common/jwt.service";
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import StarRating from 'vue-star-rating'
    export default {
      components: {
          Datepicker,
          StarRating
      },
      data() {
        return {
          fields: [
                  { key: 'ID', label: 'Que ID', sortable: false  , thStyle:  {width: '10%'}  },
                  { key: 'question', label: 'Question', sortable: false , thStyle:  {width: '20%'} },
                  { key: 'option1', label: 'Option 1', sortable: false , thStyle:  {width: '15%'} },
                  { key: 'option2', label: 'Option 2', sortable: false , thStyle:  {width: '15%'} },
                  { key: 'option3', label: 'Option 3', sortable: false , thStyle:  {width: '15%'} },
                  { key: 'correct_option', label: 'Correct Option', sortable: false , thStyle:  {width: '5%'}},
                  { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '10%'}},
                  { key: 'toggleAction', label: 'Actions', thStyle:  {width: '5%'}},
              ],
              form: {
                  rowsPerPage:25,
                  question: '',
                  option1: '',
                  option2: '',
                  option3: '',
                  correct_answer:'',
              },
              items:null,
              totalRows: 1,
              currentPage: 1,
              perPage: 25,
              pageOptions: [25, 50, 75, 100],
              sortBy: null,
              sortDirection: 'asc',
              filter: null,
              searchKeyword:'',
              status: 'active',
              isBusy: false,
              sortBy: '',
              sortDesc: false,
              sortDirection: 'asc',
              to:'',
              from:'',
              webUrl: process.env.MIX_APP_URL,
              actions:'',
              user_type_id:'',
              user_id:'',
              modalTitle: 'Add Question',
              type : 'add',
              id : '',
              dataArr : [],
             actions:'',

        }
      },
      filters: {
          capitalize: function(value) {
              if (!value) return ''
              value = value.toString()
              return value.charAt(0).toUpperCase() + value.slice(1)
          },
          date: function(date) {
              return moment(date).format('DD MMM YYYY')
          },
          time: function(time) {
              return moment(time,'H:mm').format('hh:mm A')
          },
      },
      methods: {
          
          onFiltered(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              this.totalRows = filteredItems.length
              this.currentPage = 1
          },
          clickToAdd(type)
          {
            this.$refs['modal'].show();
            this.modalTitle = type == 'add' ? 'Add Question' : 'Update Question'
          },
          hideModel()
          {
            this.$refs['modal'].hide();
            this.form.question = '';
            this.form.option1 = '';
            this.form.option2 = '';
            this.form.option3 = '';
            this.form.correct_answer = '';
            this.id = '';
  
          },
          getListing() {
              // console.log('static-page');
              return this.$store.dispatch(POST_API, { 
                     data:{
                       page: this.currentPage,
                       
                       keyword: this.filter,
                       rowsPerPage:this.form.rowsPerPage,
                       sortBy:this.sortBy,
                       sortDirection:this.sortDirection,
                       sortDesc:this.sortDesc
                     },
                     api: '/api/online-training-list'
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                          return [];
                      } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.items;
                      }
                  });
          },
          formSubmit(evt) {
              evt.preventDefault();
              
              if(this.type == 'add'){
                  var api = '/api/create-training-questions';
              }else{
                  var api = '/api/update-training-questions';
              }
  
              this.$store.dispatch(POST_API, {
                      data: {
                          id: this.id,
                          question: this.form.question,
                          option1: this.form.option1,
                          option2: this.form.option2,
                          option3: this.form.option3,
                          correct_answer: this.form.correct_answer ,
                      },
                      api: api,
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                      } else {
                          this.showSuccessAlert();
                      }
                  });
          },
          showAlert() {
              // window.scrollTo(0,0);
               this.$swal({
                position: 'top-center',
              type: 'error',
              text: this.error_message,
              showConfirmButton: false,
              timer: 3000
              })
          },
          updateData(id){
              this.clickToAdd('update');
              this.type = 'update';
              this.id = id;
  
              return this.$store.dispatch(POST_API, { 
                  data:{
                      que_id:id,
                  },
                  api: '/api/get-training-data'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  } else {
                      this.dataArr = this.$store.getters.getResults.data;
                      this.form.question = this.dataArr.question;
                      this.form.correct_answer = this.dataArr.correct_answer;
  
                      if(this.dataArr.options != null){
                        this.form.option1 = this.dataArr.options[0];
                        this.form.option2 = this.dataArr.options[1];
                        this.form.option3 = this.dataArr.options[2];
                      }
                     
                  }
              });
          },
          deleteData(id)
          {
               this.$swal({
                  title: 'Please Confirm',
                  text: 'Are you sure you want to delete this record?',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes!'
                })
              .then(result => {
                  if (result.value) {
                          return this.$store.dispatch(POST_API, {
                            data:{
                                  que_id: id,
                                },
                            api:"/api/delete-training-question",
                              })
                              .then(() => {
                                  if (this.$store.getters.containsErrors) {
                                      this.error_message = this.$store.getters.getErrors;
                                      this.showAlert();
                                  } else {
                                      this.showSuccessAlert();
                                      this.$refs.datatable.refresh();
                                  }
                              });
                      }
                  })
                  .catch(err => {
                      // An error occurred
                  })
          },
          showSuccessAlert(type) {
            if(type == 'add') var msg = 'Question Added Successfully.';
            msg = 'Question Updated Successfully.' 
  
              this.hideModel();
              this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: msg,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                 this.$refs.datatable.refresh();
                 this.cost ='';
              });
          },
          changeStatus(id, status) {
              if (status == 'active') {
                  var msg = 'Are you sure want to inactivate this record?';
                  var message='Data Inactivated Successfully.';
              } 
              else 
              {
                  var msg = 'Are you sure want to activate this record?';
                  var message='Data Activated Successfully.'
  
              }
             
               this.$swal({
                  title: 'Please Confirm',
                  text: msg,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No'
                })
              .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                       data:{
                            que_id: id,
                            status:status,
                          },
                          api:"/api/change-training-question-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                              
                               this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: message,
                                showConfirmButton: false,
                                timer: 1500
                              })
                              this.$refs.datatable.refresh();
                            }
                        });
                      }
                  })
                  .catch(err => {
                      // An error occurred
                  })
          },
            permissionStaff(){
                if(this.$store.getters.getCurrentUserPermission){
                if(this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
                var menu = this.$store.getters.getCurrentUserPermission.data;
                this.actions = permission.getPermissionStaff(menu,'Online Training');
                }
                }
            },
            
            show(action){
                if (this.$store.getters.currentUser.user_type_id == 2) {
                    return this.actions.indexOf(action) >= 0 ? true : false ;  
                }else{
                    return true;
                }
            },
  
      },
      mounted(){
          this.user_type_id = this.$store.getters.currentUser.user_type_id;
          this.user_id = this.$store.getters.currentUser.id;
          this.permissionStaff();
      }
    }
    
  </script>
  
  