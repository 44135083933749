<template>
	<div>
		<!-- Navigation-->
        <header class="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
            <link rel="stylesheet" href="/assets/css/jobseeker2.css">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
            <!-- Mobile -->

            <div class="container header_contaner">
            <div class="row">
                <div class="col-12">
                <nav class="main-nav">
                    <!-- logo -->
                    <b-link href="" :to="{name: 'jobseekers2'}" class="logo my_custom_logo">
                      <img :src="webUrl+'logo.png'"></b-link>
                    <ul class="nav my_custom_nav">
                      <!-- <li class="scroll-to-section mobile_tab_hide_btn"><div class="signUpbtn"><a @click="$bvModal.show('bv-modal-example')"> Sign Up Now !</a></div></li> -->
                      <li class="scroll-to-section mobile_tab_hide_btn"><div class="signUpbtn" @click="$bvModal.show('bv-modal-example')"><a > Sign Up Now !</a></div></li>
                    </ul>
                </nav>
                </div>
            </div>
            </div>
        </header>

	</div>
</template>
<script>

export default{
    data() {
		return {
			webUrl: process.env.VUE_APP_URL,
      // showMobileMenu: false,
		}
	},
	methods:{
    showMobileMenu(){
      // $(".mobile_nav").toggleClass("active");
      $(".mobile_nav").slideToggle('slow');
    },
    navMenu(){
      // $(".mobile_nav").removeClass("active");
      $(".mobile_nav").slideUp('slow');
    },
		reveal() {
			var reveals = document.querySelectorAll(".reveal");

			for (var i = 0; i < reveals.length; i++) {
				var windowHeight = window.innerHeight;
				var elementTop = reveals[i].getBoundingClientRect().top;
				var elementVisible = 150;

				if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add("active");
				} else {
				reveals[i].classList.remove("active");
				}
			}
		},
        openLink(){
            window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
        }
	},
	mounted()
    {
		window.addEventListener("scroll", this.reveal);
	}
}

</script>
