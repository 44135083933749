<template>
    <div>

         <!-- <div class="container full_container_dsk new_full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
    <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Ratings and Reviews</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark desktop_margin_top_zero">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Ratings and Reviews</li>
                </ul>
            </nav>
        </div>
                <div class="row">
                    <div class="submit-field col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job">
                        <div class="search-box custom_search_admin desktop_margin_top_zero">
                            <!-- <b-input-group> -->
                                <b-input-group-prepend>
                                    <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                    <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                                </b-input-group-prepend>
                                <!-- </b-input-group> -->
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk new_full_container_dsk">
           <div class="row"> -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getRatingsReviews" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="new_box_tbl_mrgn five_grid_tbl"> -->
                <b-table ref="datatable" show-empty striped hover responsive :items="getRatingsReviews" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 countries_five_grid_table">
                    <template #cell(job_unique_id)="items">
                        {{items.item.job_unique_id | capitalize}}
                    </template>
                    <template #cell(rated_by)="items">
                       {{items.item.last_name | capitalize}} {{items.item.first_name | capitalize}}
                    </template>
                    <template #cell(rating)="items">
                        <star-rating v-model="items.item.rating" :read-only='readOnly' v-bind:star-size="20" :show-rating="false"></star-rating>
                    </template>
                    <template #cell(review)="items">
                        {{items.item.review | capitalize}}
                    </template>
                     <template #cell(created_at)="items">
                        {{items.item.created_at | customFormatterOne}}
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getRatingsReviews'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../store/actions.type'
import moment from 'moment'
import StarRating from 'vue-star-rating'
import Bus from "../../event-bus";



export default {

    data() {
        return {
            fields: [
                { key: 'job_unique_id', label: 'Job ID', sortable: true },
                { key: 'rated_by', label: 'Rated By(From)', sortable: true },
                { key: 'rating', label: 'Rating', sortable: true },
                { key: 'review', label: 'Review', sortable: true },
                { key: 'created_at', label: 'Created At', sortable: true },
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            searchKeyword: '',

            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            readOnly: true,
        }
    },
    components: {
        StarRating
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).format('DD MMM YYYY');
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                // this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getRatingsReviews() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    user_id:this.user_id,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/emp-ratings-reviews'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },

       
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
             $(".dashboard-content-container .simplebar-scroll-content").animate({ 
                scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Employer - Transactions";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
            Bus.$emit('active_users');

     }
}
</script>
