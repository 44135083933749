
<template>


        <!-- <div v-if="loading" >
            <div class = 'loader'>
            </div>
        </div>    -->
    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->

             <div class="col-xl-12 pdng_zero" v-if="this.page == 1">
                        <div class="dashboard-box margin-top-0">

                            <!-- Headline -->
                            <div class="headline">
                                <h1 class="el_coin_h1"><i class="icon-material-outline-account-circle"></i> EL Coins</h1>
                            </div>

                            <div class="headline">
                                <h3>What is EL Coins</h3>
                            </div>

                            <div class="headline">
                                <h4>El coins are the coins used to purchase package</h4>
                            </div>


                        </div>
                             <a  v-if="page  < 3" href="#" @click="nextStep" class="button big ripple-effect margin-top-40 margin-bottom-65 mb_btn">Purchase EL Coins</a>

                </div>

                 <div class="col-xl-12 pdng_zero"  v-if="this.page == 2">
                    <h1 class="purchase_coin_h1">Purchase EL Coins</h1>

                <div id="test1" class="dashboard-box">

                    <!-- Headline -->
                    <div class="headline" v-for="coin in coins">

                        <input type="radio"  name="coinss" :value="coin.id" v-model="coinData" @change="onChange(coin.id)">{{coin.coin}} El Coins <span style="color:#4c8bf5" v-if="coin.discount != null">(+{{coin.discount}} Coins)</span>
                        <span class="coin_rate" v-if="coin.value_in_dollar != null"> ${{coin.value_in_dollar}}</span>


                    </div>

                </div>
                <a  v-if="page  > 1" href="#" @click="back" class="button big ripple-effect margin-top-40 margin-bottom-65 mb_btn" style="
                            color: black;
                            background-color: #bebbbb;
                            margin-right: 20px;">Back</a>

                     <a  v-if="page  < 3" href="#" @click="nextStep" class="button big ripple-effect margin-top-40 margin-bottom-65 mb_btn">Checkout</a>


                </div>

                <div class="row"  v-if="this.page == 3">
                    <div class="col-xl-8 col-lg-8 content-right-offset">


                <!-- Hedaline -->
                <h1>Checkout</h1>

                <!-- <div id="axiosForm">
                    <div class="loader" v-if="loading"></div>
                </div>  -->
                <!-- Billing Cycle Radios  -->
                <div class="margin-top-25">

                    <!-- Radio -->

                    <div class="content">
                                <ul class="fields-ul chek_page">
                                <li>
                                    <div class="row">

                                        <div class="col-xl-10">
                                        <div class="submit-field mobile_sbm">
                                                <h5>EL Coins package</h5>
                                                <input type="text" v-model="packageDataName" disabled="disabled" class="with-border form-control">
                                                <!-- <b-form-select v-model="packageData" :readonly="true">
                                                    <option :value="coin.id" v-for="coin in coins" v-if="packageData == coin.id">{{coin.total_coin}} Coins</option>
                                                </b-form-select> -->
                                            </div>
                                        </div>
                                        <div class="col-xl-2">

                                            <a href="#" @click="back" class="button ripple-effect margin-top-40 margin-bottom-65 change_btn">Change</a>

                                        </div>
                                        <div>
                                        </div>
                    <div class="col-xl-12">
                    <div class=" payment-tab-active my_custom_radio_bill" id="paypalData" style="max-height:1200px;">
                        <div class="headline top_headline">
                            <input checked id="bill" name="bill" type="radio" value="bill"  @click="showbill('bill')">
                            <label for="paypal">Select Billing Address</label>
                        </div>


                         <div class="col-xl-6" >
                            <div class="submit-field" >
                                <b-form-select v-model="billingAdd" id="billselect" @change.native="billChange($event)" >
                                    <option value="" disabled>Select Billing Address</option>
                                    <option :value="billingadd.id"  v-for="billingadd in billing"  >{{billingadd.name}}-{{billingadd.street}}</option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="headline new_headline">
                            <input id="bill" name="bill" type="radio" value="bill"  @click="showbill('billnew')" class="common_mrgn">
                            <label for="paypal" class="common_mrgn">New Billing Address</label>
                        </div>
                         <div style="display:none" id="billform">
                            <div class="row payment-form-row mrgn_tp">

                                <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Full Name</h5>
                                                    <b-form-input
                                                    type="text"
                                                    v-model="name"
                                                    id="name"
                                                    class="input-text with-border"
                                                    name="name"
                                                    placeholder=""
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Email</h5>
                                                    <b-form-input
                                                    type="text"
                                                    v-model="email"

                                                    id="name"
                                                    class="input-text with-border"
                                                    name="name"
                                                    placeholder=""
                                                    value=""
                                                />
                                            </div>
                                        </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Mobile Number</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="phone"

                                                        id="office_address"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        placeholder=""
                                                        value=""
                                                        maxlength="8"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Postal Code</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="code"

                                                        id="minSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                        maxlength="6"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-xl-12">
                                                <div class="submit-field">
                                                    <h5>Address 1</h5>
                                                    <b-form-input
                                                        type="text"
                                                    v-model="address"

                                                        id="maxSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>



                                            <div class="col-xl-12" v-if="address2">
                                                <div class="submit-field">
                                                    <h5>Address 2</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="address2"
                                                        id="maxSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="submit-field common_mrgnz">
                                                    <h5>City</h5>
                                                    <b-form-input
                                                        type="text"
                                                    v-model="city"

                                                        id="maxSal"
                                                        class="input-text with-border common_mrgn"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>

                                        <div class="col-xl-6" style="padding: 18px;">
                                            <div class="submit-field common_mrgnz">
                                                <h5>Country</h5>
                                                    <b-form-select v-model="country" class="common_mrgn">
                                                    <option :value="country.id" v-for="country in countries">{{country.country_name}}</option>
                                                </b-form-select>
                                            </div>
                                        </div>
                            </div>
                        </div>

                    </div>
                    </div>

                </div>

            </li>
        </ul>
        </div>


                    <!-- Radio -->

                </div>


                <!-- Hedline -->
                <h3 class="margin-top-50">Payment Method</h3>

                <!-- Payment Methods Accordion -->
                <div class="payment margin-top-30">

                    <div class="payment-tab payment-tab-active" id="paypalData">
                        <div class="payment-tab-trigger">
                            <input checked id="paypal" name="cardType" type="radio" value="paypal"  @click="show('paypal')">
                            <label for="paypal">Select Credit Card</label>
                            <!-- <img class="payment-logo paypal" src="https://i.imgur.com/ApBxkXU.png" alt=""> -->
                        </div>
                         <div class="col-xl-6">
                                <div class="submit-field">
                                        <b-form-select v-model="card"  @change.native="cardChange($event)">
                                        <option value="" disabled>Select Credit Card</option>
                                        <option :value="cardData.card_token"  v-for="cardData in cards">{{cardData.card_masked_num}}</option>
                                    </b-form-select>
                                </div>
                            </div>
                         <div class="payment-tab-content" style="display:none">
                            <div class="row payment-form-row">
                                        <input id="cardId" name="cardId"  type="hidden" placeholder="Cardholder Name" v-model="cardId">

                                <div class="col-md-6">
                                    <div class="card-label">
                                        <input id="nameOnCard" name="nameOnCard" required type="text" placeholder="Cardholder Name" v-model="cardHolderName">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="card-label">
                                        <input id="cardNumber" name="cardNumber" placeholder="Credit Card Number" required type="text" v-model="cardNumber">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <input id="expiryDate" placeholder="Expiry Month" required type="text" v-model="expiryMonth">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <label for="expiryDate">Expiry Year</label>

                                        <input id="expirynDate" placeholder="Expiry Year" required type="text" v-model="expiryYear">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <input id="cvv" required type="text" placeholder="CVV" v-model="cvv">
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>




                    <div class="payment-tab" id='cardDialogue'>
                        <div class="payment-tab-trigger">
                            <input type="radio" name="cardType" id="creditCart" value="creditCard" @click="show('card')">
                            <label for="creditCart">Credit / Debit Card</label>
                            <img class="payment-logo" src="https://i.imgur.com/IHEKLgm.png" alt="">
                        </div>

                        <div class="payment-tab-content">
                            <div class="row payment-form-row">

                                <div class="col-md-6">
                                    <div class="card-label">
                                        <input id="nameOnCard" name="nameOnCard" required type="text" placeholder="Cardholder Name" v-model="cardHolderName">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="card-label">
                                        <input id="cardNumber" name="cardNumber" placeholder="Credit Card Number" required type="text" v-model="cardNumber">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <!-- <input id="expiryDate" placeholder="Expiry Month" required type="text" v-model="expiryMonth"> -->
                                        <select v-model="expiryMonth" id ='expiryDate' @change="onChangeMonth('month')">
                                            <option value="" disabled>Expiry Month</option>
                                            <option :value="mn" v-for="(mn,id) in duration_mn" :key="id">{{mn}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <label for="expiryDate">Expiry Year</label>
                                        <datepicker v-model="expiryYear" :open-date="new Date()"  :format="customFormatterTwo" id="expirynDate" minimum-view="year" @selected="onChangeMonth('year')" style="border: unset;" required placeholder="Expiry Year"></datepicker>

                                        <!-- <input id="expirynDate" placeholder="Expiry Year" required type="text" v-model="expiryYear"> -->
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <input id="cvv" required type="text" placeholder="CVV" v-model="cvv">
                                    </div>
                                </div>

                                   <div class="col-md-12">
                                      <div class="input_divs">
                                        <input id="cvv" type="checkbox" placeholder="" v-model="saveLater">
                                         <span >Save For Future</span>
                                      </div>

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <a  v-if="page  > 1" href="#" @click="back" class="button big ripple-effect margin-top-40 margin-bottom-65" style="
                    color: black;
                    background-color: #bebbbb;
                    margin-right: 20px;">Back</a>
                <a  v-if="page  == 3" href="#" @click="makePayment()" class="button big ripple-effect margin-top-40 margin-bottom-65">Proceed Payment</a>

                <!-- Payment Methods Accordion / End -->

                <!-- <a href="#" @click="makePayment()" class="button big ripple-effect margin-top-40 margin-bottom-65">Proceed Payment</a> -->
                    </div>


                    <!-- Summary -->
                    <div class="col-xl-4 col-lg-4 margin-top-0 margin-bottom-60">

                        <!-- Summary -->
                        <div class="boxed-widget summary margin-top-0 side_bar_payment">
                            <div class="boxed-widget-headline">
                                <h3>Summary</h3>
                            </div>
                            <div class="boxed-widget-inner">
                                <ul>
                                    <li>Standard Plan <span>${{parseFloat(elcoinDatabyid[0].value_in_dollar).toFixed(2)}}</span></li>
                                    <li>GST ({{settings[0].option_value}}%) <span>${{parseFloat((elcoinDatabyid[0].value_in_dollar * settings[0].option_value) / 100).toFixed(2)}}</span></li>
                                    <li class="total-costs">Final Price <span>${{parseFloat(total_amount).toFixed(2)}}</span></li>
                                </ul>
                            </div>
                        </div>
                        <!-- Summary / End -->

                        <!-- Checkbox -->
                        <!-- <div class="checkbox margin-top-30">
                            <input type="checkbox" id="two-step">
                            <label for="two-step"><span class="checkbox-icon"></span>  I agree to the <a href="#">Terms and Conditions</a> and the <a href="#">Automatic Renewal Terms</a></label>
                        </div> -->
                    </div>
                </div>
                <!-- <a  v-if="page  > 1" href="#" @click="back" class="button big ripple-effect margin-top-40 margin-bottom-65" style="
                    color: black;
                    background-color: #bebbbb;
                    margin-right: 20px;">Back</a>

                <a  v-if="page  < 3" href="#" @click="nextStep" class="button big ripple-effect margin-top-40 margin-bottom-65">Next</a>

                <a  v-if="page  == 3" href="#" @click="makePayment()" class="button big ripple-effect margin-top-40 margin-bottom-65">Proceed Payment</a> -->

    </div>

</template>

<script>
  import { POST_API } from "../../../store/actions.type"
 import Datepicker from 'vuejs-datepicker';
    import moment from "moment";

  export default {

  data: () => {
    return {
             formPosition: 0,
             page : 1,
             coins: '',
             coinData:'',
             packageData:'',
             packageDataName:'',
             total_amount : 0,
            loading: false,
            cardId:'',
             cardHolderName:'',
            cardNumber:'',
            billingAdd:'',
            expiryMonth:'',
            expiryYear: '',
            cvv:'',
            name:'',
            email:'',
            phone:'',
            code:'',
            address:'',
            card:'',
            address2:'',
            token:'',
            city:'',
            country:'',
            elcoinDatabyid:'',
            settings:'',
            countries:'',
            saveLater:'',
            loader:'',
            duration_mn: [1,2,3,4,5,6,7,8,9,10,11,12],


            }
        },
        components: {
            Datepicker
    },
  methods: {
     nextStep(){
      this.page = this.page+1;
     },

     back(){
      this.page = this.page-1;
     },


    customFormatterTwo(date) {
        return moment(date).format('YYYY');
    },

    onChangeMonth(id){

        if(id == 'month'){

            $('#month-warning-message').hide();
            $('#month').removeClass('invalid');
        }else{
            $('#year-warning-message').hide();
            $('#year').removeClass('invalid');
        }

    },
     getElCoins(){

           this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/elCoins'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.coins = this.$store.getters.getResults.data;
                        this.coinData =  this.coins[0].id,
                        this.packageData = this.coins[0].id,

                        this.getElCoinsById();

                    }
                });
     },

      getElCoinsById(){

           this.$store.dispatch(POST_API, {
                    data:{
                        id: this.coinData
                    },
                    api:'/api/elCoinsById'
                })
                .then(() => {

                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {

                        this.elcoinDatabyid = this.$store.getters.getResults.data;

                        this.packageDataName = this.elcoinDatabyid[0].coin  + ' Coins' + " ( + " + this.elcoinDatabyid[0].discount + " Coins)";


                         const percent = (this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100;

                         this.total_amount = percent + parseInt(this.elcoinDatabyid[0].value_in_dollar);

                    }
                });
     },

      showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            makePayment(){


                this.loading = true;
                 this.$store.dispatch(POST_API, {
                        data:{
                            name:this.cardHolderName,
                            number:this.cardNumber,
                            expiryMonth:this.expiryMonth,
                            expiryYear:moment(this.expiryYear).format('YYYY'),
                            cvv:this.cvv,
                            user_id:this.user_id,
                            email:this.email,
                            fullname:this.name,
                            phone:this.phone,
                            code:this.code,
                            address:this.address,
                            address2:this.address2,
                            city:this.city,
                            country:this.country,
                            company_id: this.company_id,
                            coinData:this.coinData,
                            amount: this.elcoinDatabyid[0].value_in_dollar,
                            total_coins: this.elcoinDatabyid[0].coin,
                            discount: this.elcoinDatabyid[0].discount,
                            pdf_amount: parseFloat(this.elcoinDatabyid[0].value_in_dollar).toFixed(2),
                            pdf_gst_percent:this.settings[0].option_value,
                            pdf_gst: parseFloat((this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100).toFixed(2),
                            pdf_total_amount: parseFloat(this.total_amount).toFixed(2),
                            token : this.token,
                            saveLater:this.saveLater
                    },
                    api: '/api/makePayment'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        this.loading = false;

                        return [];
                    } else {
                            this.loading = false;

                            var message = this.$store.getters.getResults.message
                            window.open(this.$store.getters.getResults.data);
                            this.showSuccessAlert(message);
                            window.location.replace('/v2/jobseeker/elcoins');
                    }
                });

            },

             getCards(){

           this.$store.dispatch(POST_API, {
                    data:{
                        userId: this.user_id
                    },
                    api:'/api/cards'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.cards = this.$store.getters.getResults.data;

                    }
                });
     },


  getBilling(){

           this.$store.dispatch(POST_API, {
                    data:{
                        userId: this.user_id
                    },
                    api:'/api/billingAddress'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.billing = this.$store.getters.getResults.data;

                    }
                });
     },

            onChange(id){

                this.packageData = id,
                this.coinData = id,

                this.getElCoinsById();
            },


            cardChange(id){

                this.$store.dispatch(POST_API, {
                    data:{
                        cardId: id.target.value,
                        custId:this.custId
                    },
                    api:'/api/cardbyStripe'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {

                        this.token = this.$store.getters.getResults.data;

                    }
                });

            },

             billChange(id){


                this.$store.dispatch(POST_API, {
                    data:{
                        billid: id.target.value
                    },
                    api:'/api/billById'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.billingdata = this.$store.getters.getResults.data[0];
                        this.email = this.billingdata.billing_email;
                        this.address = this.billingdata.street;
                        this.address2 = this.billingdata.address_2;
                        this.name = this.billingdata.name,
                        this.phone = this.billingdata.mobile_number,
                        this.code = this.billingdata.postal_code,
                        this.city = this.billingdata.city,
                        this.country = this.billingdata.country_id,

                        $('#billform').show();

                    }
                });

            },

            show(id)
            {

                if(id == 'card'){
                   $('#cardDialogue').addClass('payment-tab-active');
                    $('#paypalData').removeClass('payment-tab-active');

                }else{
                    $('#paypalData').addClass('payment-tab-active');
                    $('#cardDialogue').removeClass('payment-tab-active');
                }
            },

         showbill(id)
            {
                if(id == 'bill'){
                    this.billingAdd = '';

                    $('#billselect').show();
                    $('#billform').hide();

                }else{
                        this.email = '';
                        this.address = '';
                        this.name = '',
                        this.phone = '',
                        this.code = '',
                        this.city = '',
                        this.country = '',
                    $('#billselect').hide();
                    $('#billform').show();
                }
            },

        getSettings(){

           this.$store.dispatch(POST_API, {

                    api:'/api/settings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.settings = this.$store.getters.getResults.data;

                    }
                });
     },

      getCountry(){

           this.$store.dispatch(POST_API, {

                    api:'/api/countriesData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.countries = this.$store.getters.getResults.data;

                    }
                });
     },

    },

     mounted(){
            //document.title = "EL Connect - Jobseeker - EL Coins";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.email = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            this.custId = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.customer_id : '');

            this.getElCoins();
            this.getSettings();
            this.getCountry();
            this.getCards();
            this.getBilling();
     }

    }
</script>
