<template>
    <div>
    <div id="titlebar" class="gradient">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h2>Checkout</h2>

				<!-- Breadcrumbs -->
				<nav id="breadcrumbs" class="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Pricing Plans</a></li>
						<li>Checkout</li>
					</ul>
				</nav>

			</div>
		</div>
	</div>
</div>


<!-- Content
================================================== -->
<!-- Container -->
<div class="container">
	 <div class="row">
                    <div class="col-xl-8 col-lg-8 content-right-offset">


                <!-- Hedaline -->
                <h3>Checkout</h3>

                <!-- Billing Cycle Radios  -->
                <div class="billing-cycle margin-top-25">

                    <!-- Radio -->

                    <div class="content">
                                <ul class="fields-ul">
                                <li>
                                    <div class="row">

                                        <div class="col-xl-12">
                                        <div class="submit-field">
                                                <h5>EL Coins package</h5>
                                                 <b-form-select v-model="coinData">
                                                        <option value="">Select Category</option>
                                                        <option :value="coin.id" v-for="coin in coins">{{coin.coin}} Coins</option>
                                                    </b-form-select>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                         <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Full Name</h5>
                                                    <b-form-input
                                                    type="text"

                                                    id="name"
                                                    class="input-text with-border"
                                                    name="name"
                                                    placeholder=""
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Email</h5>
                                                    <b-form-input
                                                    type="text"

                                                    id="name"
                                                    class="input-text with-border"
                                                    name="name"
                                                    placeholder=""
                                                    value=""
                                                />
                                            </div>
                                        </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Mobile Number</h5>
                                                    <b-form-input
                                                        type="text"

                                                        id="office_address"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        placeholder=""
                                                        value=""
                                                        maxlength="8"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Postal Code</h5>
                                                    <b-form-input
                                                        type="text"

                                                        id="minSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                        maxlength="6"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-xl-12">
                                                <div class="submit-field" style="margin-top: 30px;">
                                                    <h5>Address 1</h5>
                                                    <b-form-input
                                                        type="text"

                                                        id="maxSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>



                                            <div class="col-xl-12">
                                                <div class="submit-field" style="margin-top: 30px;">
                                                    <h5>Address 2</h5>
                                                    <b-form-input
                                                        type="text"

                                                        id="maxSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="submit-field" style="margin-top: 30px;">
                                                    <h5>City</h5>
                                                    <b-form-input
                                                        type="text"

                                                        id="maxSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>

                                        <div class="col-xl-6">
                                                <div class="submit-field" style="margin-top: 30px;">
                                                    <h5>Country</h5>
                                                    <b-form-input
                                                        type="text"

                                                        id="maxSal"
                                                        class="input-text with-border"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                            </div>


                                    </div>

                                </li>
                            </ul>
                            </div>


                    <!-- Radio -->

                </div>


                <!-- Hedline -->
                <h3 class="margin-top-50">Payment Method</h3>

                <!-- Payment Methods Accordion -->
                <div class="payment margin-top-30">

                    <div class="payment-tab payment-tab-active">
                        <div class="payment-tab-trigger">
                            <input checked id="paypal" name="cardType" type="radio" value="paypal">
                            <label for="paypal">PayPal</label>
                            <img class="payment-logo paypal" src="https://i.imgur.com/ApBxkXU.png" alt="">
                        </div>

                        <div class="payment-tab-content" id = 'paypal'>
                            <p>You will be redirected to PayPal to complete payment.</p>
                        </div>
                    </div>


                    <div class="payment-tab">
                        <div class="payment-tab-trigger">
                            <input type="radio" name="cardType" id="creditCart" value="creditCard">
                            <label for="creditCart">Credit / Debit Card</label>
                            <img class="payment-logo" src="https://i.imgur.com/IHEKLgm.png" alt="">
                        </div>

                        <div class="payment-tab-content" id='cardDialogue'>
                            <div class="row payment-form-row">

                                <div class="col-md-6">
                                    <div class="card-label">
                                        <input id="nameOnCard" name="nameOnCard" required type="text" placeholder="Cardholder Name" v-model="cardHolderName">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="card-label">
                                        <input id="cardNumber" name="cardNumber" placeholder="Credit Card Number" required type="text" v-model="cardNumber">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <input id="expiryDate" placeholder="Expiry Month" required type="text" v-model="expiryMonth">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <label for="expiryDate">Expiry Year</label>
                                        <input id="expirynDate" placeholder="Expiry Year" required type="text" v-model="expiryYear">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card-label">
                                        <input id="cvv" required type="text" placeholder="CVV" v-model="cvv">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <!-- Payment Methods Accordion / End -->

                <a href="#" @click="makePayment()" class="button big ripple-effect margin-top-40 margin-bottom-65">Proceed Payment</a>
                    </div>


                    <!-- Summary -->
                    <div class="col-xl-4 col-lg-4 margin-top-0 margin-bottom-60">

                        <!-- Summary -->
                        <div class="boxed-widget summary margin-top-0">
                            <div class="boxed-widget-headline">
                                <h3>Summary</h3>
                            </div>
                            <div class="boxed-widget-inner">
                                <ul>
                                    <li>Standard Plan <span>$49.00</span></li>
                                    <li>VAT (20%) <span>$9.80</span></li>
                                    <li class="total-costs">Final Price <span>$58.80</span></li>
                                </ul>
                            </div>
                        </div>
                        <!-- Summary / End -->

                        <!-- Checkbox -->
                        <div class="checkbox margin-top-30">
                            <input type="checkbox" id="two-step">
                            <label for="two-step"><span class="checkbox-icon"></span>  I agree to the <a href="#">Terms and Conditions</a> and the <a href="#">Automatic Renewal Terms</a></label>
                        </div>
                    </div>
                </div>
</div>
</div>
</template>

<script>
    import { POST_API } from "../../../store/actions.type"
    import 'vue-slider-component/theme/default.css'
    import VueSlider from 'vue-slider-component'
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {

        data() {
            return {
                cardHolderName:'',
                cardNumber:'',
                expiryMonth:[],
                expiryYear: '',
                cvv:''
            }
        },

        methods:{
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            makePayment(){
                 this.$store.dispatch(POST_API, {
                        data:{
                            name:this.cardHolderName,
                            number:this.cardNumber,
                            expiryMonth:this.expiryMonth,
                            expiryYear:this.expiryYear,
                            cvv:this.cvv,
                            user_id:this.user_id,
                            email:this.email,
                    },
                    api: '/api/makePayment'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        // var message = 'Job Bookmarked successfully.'
                        var message = this.$store.getters.getResults.message
                        this.showSuccessAlert(message);
                    }
                });

            },
        },
         mounted(){
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.email = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : '');

        }
    }
</script>


