<template>
  <div>

         <div class="row">
            <div class="container my_new_container">
                <!-- <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="empList()"></span>
            </div> -->
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline"> -->
            <!-- <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>{{name | capitalize}} Gallery</h1> -->
            <!-- <b-link :to="{}" class="btn green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn" @click="clickToAdd('add')">
                Upload Image
            </b-link> -->
            <!-- Breadcrumbs -->
            <!-- <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Gallery</li>
                </ul>
            </nav> -->
        <!-- </div> -->
          <div id="breadcrumb-v2">
                <b-breadcrumb>
                    <b-breadcrumb-item href="" :to="{name: 'employer-list'}">Employers</b-breadcrumb-item>
                    <b-breadcrumb-item active>{{name | capitalize}} Gallery</b-breadcrumb-item>
                </b-breadcrumb>
            </div>
                <div class="row">
                                  <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <b-input-group-prepend>
                                        <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                                    </b-input-group-prepend>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                    <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                        <b-link :to="{}" class="btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero desktop_margin_top_zero green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn" @click="clickToAdd('add')">
                            Upload Image
                        </b-link>
                    </div>
                </div>
                </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
              <b-table ref="datatable" show-empty striped hover responsive :items="getListing" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="admin_gallery_tbl mobile_min_width_1000 common_tbl_width gallery_width">

                <!-- <template #cell(title)="items">
                  {{items.item.title}}
                </template>
                <template #cell(description)="items">
                    {{ items.item.description != null ? items.item.description.substring(0,50)+"..." : ''}}
                </template> -->
                <template #cell(url)="items">
                  <a :href="items.item.url" target="_blank"><img :src="items.item.url ? items.item.url : webUrl + 'assets/images/logo.png'" height="100" width="100" alt="logo_url"/></a>
                </template>
                <template #cell(status)="items">
                  {{items.item.status | capitalize}}
                </template>

                <template #cell(toggleAction)="items">
                  <div class="toggle-action toggle_margin_unset">
                    <b-dropdown class="mx-1" right text="Actions" boundary="window">
                        <b-dropdown-item v-on:click="updateImage(items.item.id)">Change
                        </b-dropdown-item>
                        <b-dropdown-item v-if="items.item.status == 'active'" v-on:click="changeStatus(items.item.id,items.item.status)">Inactive
                        </b-dropdown-item>
                        <b-dropdown-item v-else v-on:click="changeStatus(items.item.id,items.item.status)">Active
                        </b-dropdown-item>
                        <b-dropdown-item v-on:click="deleteImage(items.item.id)">Delete
                        </b-dropdown-item>
                    </b-dropdown>

                  </div>
                </template>
              </b-table>
              <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"> <b-form-select v-model="form.rowsPerPage" @change='getListing'>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                      </b-form-select></span>
                </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
                <ul>
                    <li>
                        <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                    </li>
                </ul>
            </div>
            <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
          </nav>
          </div>

		</div>
        <b-modal ref="upload-update-modal" id="background_custom_class_for_banner" :title="modalTitle" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class">
            <b-form @submit="formSubmit">
                <div class="edit-form-box model_margin_top_zero">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <div class="form-map-box">
                                    <!-- <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Title" class="required">
                                        <b-form-input id="input-9" maxlength="20" v-model="form.title" type="text" placeholder=""></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Description" class="required">
                                        <b-form-textarea id="input-9" maxlength="200" v-model="form.description" type="text" placeholder=""></b-form-textarea>
                                    </b-form-group>
                                    </b-col> -->
                                    <b-col lg="12" md="12">
                                        <div class="upload-form-group upload-files-box">
                                            <b-form-group id="link-group" label="" class="required">
                                                <div class="single-image-upload my_single_img common_pointer_class" :class="selectedImage ? 'if_pop_upimage_exist' : 'if_pop_up_image_not_exist'">
                                                    <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onFileChange"  ref="url" id="userPic" v-model="form.url"></b-form-file>
                                                    <span v-bind:style="selectedImage ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                        <b-img v-if='selectedImage' :src="selectedImage" :disabled="true" class="img_height"></b-img>
                                                        <b-img v-else :src="webUrl +'assets/images/upload-icon.svg'" :disabled="true" class="default_image_thumbnail"></b-img>
                                                    </span>
                                                    <!-- <span>
                                                       <b-img  :src="selectedImage? selectedImage : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="logo_selected_image"></b-img>
                                                    </span> -->
                                                </div>
                                            <span>Note: Image should be of 1900 * 500</span>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                <div slot="footer" class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn">
                                    <b-button type="submit" variant="success" class="model_margin_left_zero">Submit</b-button>
                                    <b-button @click = "hideModel()" variant="light">Cancel</b-button>
                                </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-form>
        </b-modal>
        <b-modal ref="upload-add-modal" id="background_custom_class_for_banner" :title="modalTitle" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class">
            <b-form @submit="formSubmit">
                <div class="edit-form-box model_margin_top_zero">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <div class="form-map-box">
                                    <!-- <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Title" class="required">
                                        <b-form-input id="input-9" maxlength="20" v-model="form.title" type="text" placeholder=""></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12">
                                    <b-form-group id="input-group-9" label="Description" class="required">
                                        <b-form-textarea id="input-9" maxlength="200" v-model="form.description" type="text" placeholder=""></b-form-textarea>
                                    </b-form-group>
                                    </b-col> -->
                                    <!-- commented on 14-03-2024
                                    <b-col lg="12" md="12">
                                        <div class="upload-form-group upload-files-box">
                                            <b-form-group id="link-group" label="" class="required">
                                                <div class="single-image-upload my_single_img common_pointer_class" :class="selectedImage ? 'if_pop_upimage_exist' : 'if_pop_up_image_not_exist'">
                                                    <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onFileChange"  ref="url" id="userPic" v-model="form.url"></b-form-file>
                                                    <span v-bind:style="selectedImage ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                        <b-img v-if='selectedImage' :src="selectedImage" :disabled="true" class="img_height"></b-img>
                                                        <b-img v-else :src="webUrl +'assets/images/upload-icon.svg'" :disabled="true" class="default_image_thumbnail"></b-img>
                                                    </span>
                                                </div>
                                            <span>Note: Image should be of 1900 * 500</span>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                    commented on 14-03-2024 -->
                                    <b-col lg="12" md="12">
                                        <div class="upload-form-group upload-files-box">
                                            <b-form-group id="link-group" label="" class="required">
                                                <div class="single-image-upload my_single_img common_pointer_class" :class="selectedImage ? 'if_pop_upimage_exist' : 'if_pop_up_image_not_exist'">
                                                    <!-- <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onFileChange"  ref="url" id="userPic" v-model="form.url"></b-form-file>
                                                    <span v-bind:style="selectedImage ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                        <b-img v-if='selectedImage' :src="selectedImage" :disabled="true" class="img_height"></b-img>
                                                        <b-img v-else :src="webUrl +'assets/images/upload-icon.svg'" :disabled="true" class="default_image_thumbnail"></b-img>
                                                    </span> -->
                                                    <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage="maxImage" :multiple="multiple" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.document" accept=".jpg, .png, .jpeg" limit-exceeded="2" class="image_glr">
                                                    </vue-upload-multiple-image>
                                                </div>
                                            <span>Note: Image should be of 1900 * 500</span>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                <div slot="footer" class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn">
                                    <b-button type="submit" variant="success" class="model_margin_left_zero">Submit</b-button>
                                    <b-button @click = "hideModel()" variant="light">Cancel</b-button>
                                </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-form>
        </b-modal>
    
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
  </div>
</template>
<script>
  import { POST_API} from "../../../store/actions.type";
  import JwtService from "../../../common/jwt.service";
  import VueUploadMultipleImage from 'vue-upload-multiple-image';

  export default {

    data() {
      return {
        fields: [
                // { key: 'title', label: 'Title', sortable: true    },
                // { key: 'description', label: 'Description', sortable: true },
                { key: 'url', label: 'Image', sortable: true },
                { key: 'status', label: 'Status', sortable: true},
                { key: 'toggleAction', label: 'Actions', thStyle:  {width: '10%'}},
            ],
            form: {
                rowsPerPage:25,
                title: '',
                description: '',
                url: '',
            },
            pager: {},
            pageOfItems: [],
            selectedImage: '',
            selectedImageName: '',
            items:null,
            totalRows: 0,
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortBy: '',
            sortDesc: true,
            to:'',
            from:'',
            webUrl: process.env.VUE_APP_URL,
            actions:'',
            user_type_id:'',
            user_id:'',
            modalTitle: 'Upload Image',
            type : 'add',
            id : '',
            emp_id : atob(this.$route.params.id) ? atob(this.$route.params.id) : '',
            imagesDoc: [],
            maxImage:10,
            multiple:true,
            images: [],

      }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    components: {
      VueUploadMultipleImage
    },
    methods: {
        showSuccessAlert(message) {
            this.hideModel();
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                // this.getListing();
                this.$refs.datatable.refresh();
                // this.$router.push({ name: 'emp-gallery-admin' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        onFileChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.url = input.files[0];
                if(this.form.url.type !='image/png' && this.form.url.type !='image/jpeg' && this.form.url.type !='image/jpg'){
                    this.selectedImage = this.webUrl +'/images/upload-icon.svg'
                    this.error_message = 'Please select only .png, .jpg, or .jpeg image.';
                    this.selectedImage = '';
                    this.showAlert();
                  }
                  else if(this.form.url.size > 5242880 ){

                    this.selectedImage = this.webUrl +'/images/upload-icon.svg'
                    this.error_message = 'Gallery image should be less than 5 MB.';
                    this.selectedImage = '';
                    this.showAlert();
                  }
                  else
                  {
                    reader.onload = (e) => {
                    this.selectedImage = e.target.result;
                    this.selectedImageName = this.form.url.name;
                    }
                  }
            }
        },
        uploadImageSuccess(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path 
                        fileList[i].url = url;    
                    }
                }
            }
        
            this.imagesDoc = fileList

        },
        clickToAdd(typeVal)
        {
          
          this.modalTitle = typeVal == 'add' ? 'Upload Image' : 'Update Image'
          this.type = typeVal == 'add' ? 'Add' : 'Update'
          if(typeVal == 'add'){
            this.$refs['upload-add-modal'].show();
          }else{
            this.$refs['upload-update-modal'].show();
          }

        },
        hideModel()
        {
          this.$refs['upload-add-modal'].hide();
          this.$refs['upload-update-modal'].hide();

          this.form.title = '';
          this.form.description = '';
          this.form.url = '';
          this.selectedImage = '';
          this.images = [];
          this.imagesDoc = [];
        },
        getListing() {
            return this.$store.dispatch(POST_API, {
                   data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                    user_id:this.emp_id
                   },
                   api: '/api/gallery-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.items;
                    }
                });
        },

        formSubmit(evt) {
            evt.preventDefault();
            if(this.type == 'update'){
                var api = '/api/gallery-update';
            }else{
                var api = '/api/gallery-store';
            }

            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        user_id: this.emp_id,
                        title: this.form.title ? this.form.title : null,
                        description: this.form.description ? this.form.description : null,
                        documents: this.imagesDoc,
                        url: this.selectedImage ,
                        selectedImageName: this.selectedImageName,
                        type: 'others',
                    },
                    api: api,
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        if(this.type == 'update'){
                            var message='Image Changed successfully';
                        }else{
                            var message='Image Added successfully';
                        }
                        this.showSuccessAlert(message);
                    }
                });
        },

        updateImage(id){
            this.clickToAdd('update');
            this.type = 'update';
            this.id = id;

            return this.$store.dispatch(POST_API, {
                data:{
                    id:id,
                },
                api: '/api/gallery-edit'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    this.form.title = this.$store.getters.getResults.data.title;
                    this.form.description = this.$store.getters.getResults.data.description;
                    this.selectedImage = this.$store.getters.getResults.data.url;
                    // this.mo

                }
            });
        },

        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Image Inactivated successfully.';
                var new_status = 'inactive';
            }
            else{
                var msg = 'Are you sure you want to activate this record?';
                var message='Image Activated successfully.';
                var new_status = 'active';

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            status:new_status,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/gallery-status'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },

        deleteImage(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/gallery-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Image Deleted successfully.';
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        getComData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    company_id: atob(this.$route.params.id),
                },
                api: '/api/get-company-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){
                        this.name = this.$store.getters.getResults.data.name;
                    }
                }
            });
        },
        empList(event){
            if (event) {
            event.stopPropagation()
            }
            window.location.replace('/v2/admin/employer');
        },
         beforeRemove(index, done, fileList) {
            
            fileList.splice(index,1);
         
            this.imagesDoc = fileList;
        },
        editImage(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path 
                        fileList[i].url = url;    
                    }
                }
            }
            this.imagesDoc = fileList
        },
       

    },
    mounted(){
        $(".show_filter_arrow .table thead th div").contents().unwrap();
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        //document.title = "EL Connect - Admin - Website Contents - Gallery";
        this.user_type_id = this.$store.getters.currentUser.user_type_id;
        this.user_id = this.$store.getters.currentUser.id;
        this.getComData();

    }
  }

</script>

