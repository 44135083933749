<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Countries</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Countries</li>
            </ul>
          </nav>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <b-input-group-prepend>
                <b-form-input
                  id="searchBtn"
                  @keyup.enter.native="filter = searchKeyword"
                  v-model="searchKeyword"
                  placeholder="Search by keyword"
                ></b-form-input>
                <b-button class="btn points_events"
                  ><i class="fa fa-search" @click="filter = searchKeyword"></i
                ></b-button>
              </b-input-group-prepend>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <div class="row">
      <div class="container my_new_container show_filter_arrow">
        <b-table
          ref="datatable"
          show-empty
          striped
          hover
          responsive
          :items="getCountries"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="mobile_min_width_1000 countries_five_grid_table"
        >
          <template #cell(country_code)="items">
            {{ items.item.country_code | capitalize }}
          </template>
          <template #cell(country_name)="items">
            {{ items.item.country_name | capitalize }}
          </template>
          <template #cell(short_name)="items">
            {{ items.item.short_name | capitalize }}
          </template>
          <template #cell(status)="items">
            <p
              class="btn green-bg-btn desktop_margin_left_zero desktop_margin_right_zero"
              v-if="items.item.status == 'active'"
            >
              {{ items.item.status | capitalize }}
            </p>
            <p
              class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero"
              v-if="items.item.status == 'inactive'"
            >
              {{ items.item.status | capitalize }}
            </p>
          </template>

          <template #cell(toggleAction)="items">
            <div class="toggle-action toggle_margin_unset">
              <b-dropdown class="mx-1" right text="Action" boundary="window">
                <b-dropdown-item
                  v-on:click="changeStatus(items.item.id, items.item.status)"
                  v-if="items.item.status == 'active' && show('activate/inactivate')"
                  >Inactivate</b-dropdown-item
                >
                <b-dropdown-item
                  v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                  v-on:click="changeStatus(items.item.id, items.item.status)"
                  >Activate</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @change="getCountries">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      fields: [
        { key: "country_code", label: "Country Code", sortable: true },
        { key: "country_name", label: "Country Name", sortable: true },
        { key: "short_name", label: "Short Name", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "toggleAction", label: "Actions" },
      ],
      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "asc",
      filter: "",
      status: "active",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
      },
      actions: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "country-list" });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    getCountries() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "status",
            sortDirection: this.sortDesc ? "asc" : "desc",
          },
          api: "/api/country-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },

    changeStatus(id, status) {
      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Country Inactivated successfully.";
        var new_status = "inactive";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Country Activated successfully.";
        var new_status = "active";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: new_status,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/country-status",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Countries");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Admin - Database - Countries";
    this.permissionStaff();
  },
};
</script>
