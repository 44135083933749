<template>
    <div>
           
        <!-- Titlebar
        ================================================== -->
        <div  class="gradient faq_bg_img"> 
        
        <div id="titlebar" class="gradient signup_mrgn faq_mrgn">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-center faq_haed">Gigplace</h1>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div class="margin-top-35"></div>
    <!-- Page Content
        ================================================== -->
        <div class="container">
            <div class="row">
                <div class="col-xl-12 common_block common_desc">
                </div>
            </div>
        </div>

        

        <!-- Spacer -->
        <div class="margin-top-35"></div>
        <!-- Spacer / End-->
    </div>
</template>


<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        metaInfo: {
            title: 'EL Connect - Gigplace',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'Gigplace - Connect and browse gigplace jobs. Post gigs on our platform with more than 20,000 freelancers. ' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                {property: 'og:title', content: 'EL Connect - Gigplace'},
			    {property: 'og:site_name', content: 'EL Connect'},
			    {property: 'og:type', content: 'website'},
			    {property: 'og:url', content: 'https://elconnect.sg/gigplace'},
			    {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			    {property: 'og:description', content: 'Gigplace - Connect and browse gigplace jobs. Post gigs on our platform with more than 20,000 freelancers.'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/gigplace'}
  		    ]
        },
        data() {
            return {
                terms_and_conditions_ptUser: '',
                terms_and_conditions_supervisor:'',
                showDismissibleAlert:false,
                error_message:'',
                display:true,
                link_error:'',
                webUrl: process.env.VUE_APP_URL,
            }
        },
        methods:{
            getTermsConditions(){
                 this.$store.dispatch(POST_API, {
                    data:{
                        token: this.email,
                    },
                    api:'/api/terms-condition'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.terms_and_conditions_ptUser = this.$store.getters.getResults.data[0].key=='terms_and_conditions_ptUser' ? this.$store.getters.getResults.data[0]: this.$store.getters.getResults.data[1];
                        this.terms_and_conditions_supervisor = this.$store.getters.getResults.data[1].key=='terms_and_conditions_supervisor' ? this.$store.getters.getResults.data[1]: this.$store.getters.getResults.data[0];
                    }
            })

            }
        },
        mounted(){
            //document.title = "EL Connect - Terms of Service"; 
            this.getTermsConditions();
            window.scrollTo(0, 0)
        },
    }
</script>
