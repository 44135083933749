<template>
    <div>
        
         <div class="container full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline new_mobile_headline content_job_mbl">
            <h1>Jobseeker Contents</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Jobseeker Contents</li>
                </ul>
            </nav>
        </div>
                <div class="col-xl-12 remove_search_box_pdng">
                    <div class="search-box custom_search_admin">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-form-input id="searchBtn" v-model="filter" v-on:change="getJobseekerContents" placeholder="Enter keyword to search"></b-form-input>
                                <b-button class="btn"><i class="fa fa-search"></i></b-button>
                            </b-input-group-prepend>
                            </b-input-group>
                        </div>
                    </div>
                </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <div class="container full_container_dsk">
           <div class="row">
				<b-table ref="datatable" show-empty striped hover responsive :items="getJobseekerContents" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="static_page three_grid_tbl static_mobile_tbl">
                    <template #cell(title)="items">
                        {{items.item.title}}
                    </template>
                    <template #cell(description)="items">
                    <span v-html="items.item.description"></span>
                    </template> 
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item :to="{name: 'jobseeker-content-edit', params: {id: encrypt(items.item.id)}}">Edit
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4" class="jobseeker_tbl_footer">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getJobseekerContents'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul class="page_count_jb_seeker">
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons class="new_pagi"/>
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
export default {
    
    data() {
        return {
            fields: [
                { key: 'title', label: 'Title', sortable: true },
                { key: 'description', label: 'Description', sortable: false },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: false,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            }
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'jobseeker-content-list' });
            });
        },
        getJobseekerContents() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/jobseeker-content-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
    },
    mounted()
    {
       // document.title = "EL Connect - Admin - Website Contents - Jobseeker Contents";
    }
}
</script>
