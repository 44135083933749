<template>
  <div>
    <div class="row custom_dsk_row">
      <div class="container my_new_container">
        <div class="dashboard-headline">
          <h1>Manage Staff</h1>
          <!-- <b-link class="btn green-border-btn" :to="{name:'employer-create'}">Add</b-link> -->
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Manage Staff</li>
            </ul>
          </nav>
        </div>
        <!-- Dashboard Headline -->
        <div class="row">
          <div
            class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <!-- <b-input-group> -->
              <b-input-group-prepend>
                <b-form-input
                  id="searchBtn"
                  @keyup.enter.native="filter = searchKeyword"
                  v-model="searchKeyword"
                  placeholder="Search by keyword"
                ></b-form-input>
                <b-button class="btn points_events"
                  ><i class="fa fa-search" @click="filter = searchKeyword"></i
                ></b-button>
              </b-input-group-prepend>
              <!-- </b-input-group> -->
            </div>
          </div>
          <template>
            <div class="col-xl-4 col-md-4  search_common_margin_bottom_28 mobile_tab_max_width_flex">
              <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
              <multiselect
                id="selectStatus"
                v-model="status"
                :options="accountStatuses"
                :multiple = "false"
                placeholder="Account Status"
                track-by="value"
                label="label"
                class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                @input="changeAccountStatus()"
              >
              </multiselect>
              </b-form-group>
            </div>
          </template>
          <div
            class="col-xl-4 dual_admin_emp_btn col-md-12 mobile_tab_max_width_flex common_margin_bottom_28 mobile_tab_display_block"
          >
            <b-link
              class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero"
              :to="{ name: 'staff-create' }"
              v-if="show('create staff')"
              >Add</b-link
            >
            <button
              class="btn green-border-btn add_btn_admin_e employer_export_btn"
              @click="exportData()"
              style="background-color: #4c8bf5; color: white"
              v-if="show('export staff')"
            >
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <div class="row">
      <div class="container my_new_container show_filter_arrow">
        <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getStaffData" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="three_grid_tbl tbl_height race_width employer_list_table mobile_min_width_1000"> -->
        <b-table
          ref="datatable"
          show-empty
          striped
          hover
          responsive
          :items="getStaffData"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="manage_user_jbs_full_over_flow mobile_min_width_3000 memberhip_six_grid_table"
        >
          <template #cell(name)="items">
            <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                        </b-img> -->
            <div class="whole_div_img_and_name">
              <div class="manage_user_img">
                <b-img
                  v-if="items.item.thumbnail || items.item.profile_image"
                  :src="
                    items.item.thumbnail
                      ? items.item.thumbnail
                      : items.item.profile_image
                      ? items.item.profile_image
                      : webUrl + 'assets/images/user-avatar-small-01.png'
                  "
                  alt="User Icon"
                  height="100px"
                  width="100px"
                  class="user_img_emp square_img"
                >
                </b-img>
                <b-img
                  v-else-if="items.item.ft_profile_image_thumbnail || items.item.ft_profile_image"
                  :src="
                    items.item.ft_profile_image_thumbnail
                      ? items.item.ft_profile_image_thumbnail
                      : items.item.ft_profile_image
                      ? items.item.ft_profile_image
                      : webUrl + 'assets/images/user-avatar-small-01.png'
                  "
                  alt="User Icon"
                  height="100px"
                  width="100px"
                  class="user_img_emp square_img"
                >
                </b-img>

                <b-img
                  v-else
                  :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                  alt="User Icon"
                  height="100px"
                  width="100px"
                  class="user_img_emp square_img"
                >
                </b-img>
              </div>
              <div class="user_name_div">
                {{ items.item.last_name | capitalize }} {{ items.item.first_name | capitalize }}
              </div>
            </div>
            <div class="mail_with_verified">
              <p class="grey-text">
                {{ items.item.email }}
              </p>
              <div
                class="verified-badge-with-title"
                v-if="items.item.account_verification_status == 'completed'"
              >
                Verified
              </div>
            </div>
          </template>
          <template #cell(mobile_number)="items">
            {{ items.item.country_code }}-{{ items.item.mobile_number }}
          </template>
          <template #cell(designation)="items">
            {{ items.item.designation | capitalize }}
          </template>
          <template #cell(is_agent)="items">
            {{ items.item.is_agent | capitalize }}
          </template>
          <template #cell(status)="items">
            <p class="green_status" v-if="items.item.status == 'active'">
              {{ items.item.status | capitalize }}
            </p>
            <p class="orange__status" v-if="items.item.status == 'inactive'">
              {{ items.item.status | capitalize }}
            </p>
            <p class="red_status" v-if="items.item.status == 'deleted'">
              {{ items.item.status | capitalize }}
            </p>
          </template>
          <template #cell(created_at)="items">
            {{ items.item.created_at | moment }}
          </template>
          <template #cell(toggleAction)="items">
            <div class="toggle-action common_ul_radius toggle_margin_unset">
              <b-dropdown class="mx-1" right text="Action" boundary="window">
                <b-dropdown-item
                  :to="{ name: 'staff-edit', params: { id: encrypt(items.item.id) } }"
                  v-if="show('edit staff')"
                  >Edit
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  :href="webUrlV1Admin + '/admin/staff/edit/' + encrypt(items.item.id)"
                  v-if="show('edit staff')"
                  >Edit Permission V1
                </b-dropdown-item>
                <!-- <b-dropdown-item target="_blank" :href="webUrlV1Admin +'/staff/edit/' + encrypt(items.item.id)" v-if="show('edit staff')">Edit Permission V1
                                </b-dropdown-item> -->
                <!-- <b-dropdown-item :to="{name: 'staff-view-profile', params: {id: encrypt(items.item.id)}}" v-if="show('view profile')">View Profile
                                </b-dropdown-item>
                                <b-dropdown-item :to="{name: 'staff-attached-users', params: {id: encrypt(items.item.id)}}" v-if="show('attached-users')">Attached Users
                                </b-dropdown-item> -->
                <b-dropdown-item
                  @click="openAttachCompaniesModal(items.item.id)"
                  v-if="show('attach company')"
                  >Attach Company
                </b-dropdown-item>
                <b-dropdown-item
                  v-on:click="changeStaffStatus(items.item.id, items.item.status)"
                  v-if="items.item.status == 'active' && show('activate/inactivate')"
                  >Inactivate</b-dropdown-item
                >
                <b-dropdown-item
                  v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                  v-on:click="changeStaffStatus(items.item.id, items.item.status)"
                  >Activate
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="items.item.status != 'deleted' && show('delete')"
                  v-on:click="deleteStaff(items.item.id)"
                  >Delete</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="
                    items.item.email &&
                    (items.item.status == 'active' || items.item.status == 'inactive') &&
                    show('send password reset link')
                  "
                  @click="sendResetPasswordLink(items.item.id)"
                  >Send Reset Password Link
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block">
                <b-form-select v-model="form.rowsPerPage" @change="getStaffData">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
    <b-modal
      hide-footer
      id="attach-company-modal"
      ref="attach-company-modal"
      title="Select Company"
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-task margin_top_zero model_legend">
        <b-form @submit.prevent="attachCompanySubmit" method="post">
          <div class="popup-header">
            <!-- <h2 class="">Select User</h2> -->
            <div class="">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12">
                      <b-form-group
                        id="input-group-8"
                        label="All Companies"
                        class="required submit-field"
                      >
                        <multiselect
                          v-model="allCompanies"
                          :custom-label="customLabel"
                          tag-placeholder="Add this as new company"
                          placeholder="Enter to search company"
                          label="name"
                          track-by="id"
                          :options="selectedCompanies"
                          :multiple="true"
                          class="new_multi_slec_list job_list_multi_select model_multi_select"
                          ><span slot="noResult">No Company Found.</span></multiselect
                        >
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button type="submit" variant="success" class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Multiselect from "vue-multiselect";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      accountStatuses: [
        { value: 'all', label: 'Account Status'},
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
      ],
      fields: [
        { key: "name", label: "Name & Email", sortable: true },
        { key: "mobile_number", label: "Mobile Number", sortable: true },
        { key: "designation", label: "Designation", sortable: true },
        { key: "is_agent", label: "Is Agent", sortable: true },
        { key: "status", label: "Account Status", sortable: true },
        { key: "created_at", label: "Register On", sortable: true },
        { key: "toggleAction", label: "Actions" },
      ],
      pager: {},
      pageOfItems: [],

      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      selectedsup: [],
      searchKeyword: "",

      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "asc",
      filter: "",
      searchKeyword: "",
      name: "",
      status: { value: 'all', label: 'Account Status' },
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
      },
      webUrlV1: process.env.VUE_APP_URL_V1,
      allCompanies: "",
      selectedCompanies: [],
      staff_id: "",
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
      actions: "",
    };
  },
  components: {
    Multiselect,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      return moment(date).utc().format("DD MMM YYYY");
    },
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$refs.datatable.refresh();
        //this.$router.push({ name: 'empManageUserList' });
      });
    },

    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    customLabel({ name }) {
      return `${name}`;
    },
    changeAccountStatus() {
      this.getStaffData().then(() => {
        this.$refs.datatable.refresh();
      });
    },
    getStaffData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            status: this.status ? this.status.value : null,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "status",
            sortDirection: this.sortDesc ? "asc" : "desc",
          },
          api: "/api/staff-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },

    sendResetPasswordLink(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/jobseekerEmp-reset-password-link-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Reset Password Link Sent Successfully.";
            this.showSuccessAlert(message);
            // this.$refs.datatable.refresh();
          }
        });
    },
    hideModel() {
      this.$refs["attach-company-modal"].hide();
    },

    openAttachCompaniesModal(id) {
      this.$refs["attach-company-modal"].show();
      this.staff_id = id;

      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/list-companies-to-assign-staff",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.selectedCompanies = this.$store.getters.getResults.data;
            this.allCompanies = this.$store.getters.getResults.selected;
            // return this.selectedCompanies;
          }
        });
    },
    attachCompanySubmit(evt) {
      evt.preventDefault();
      this.hideModel();
      this.$store
        .dispatch(POST_API, {
          data: {
            selected_companies_id: this.allCompanies,
            staff_id: this.staff_id,
          },
          api: "/api/attach-companies-to-staff",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            // this.$refs.datatable.refresh();
            this.showSuccessAlert(this.success_message);
          }
        });
    },
    changeStaffStatus(id, status) {
      // var status = status != null ? status : this.usrStatus;

      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "User Inactivated Successfully.";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "User Activated Successfully.";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: status,
                },
                api: "/api/staff-status",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                  // this.datatableRefresh();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteStaff(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/staff-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "User Deleted successfully.";
                  // this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    exportData() {
      var base_url_v2 = this.webServerUrl;

      var filter = this.filter ? this.filter : "null";
      var status = this.status ? this.status.value : "all";

      var export_url =
        "/api/export-staff-list/" +
        filter +
        "/" +
        status +
        "/" +
        this.currentPage +
        "/" +
        this.form.rowsPerPage +
        "/" +
        this.sortBy +
        "/" +
        this.sortDirection;

      window.open(base_url_v2 + export_url, "_blank");
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Manage Staff");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.permissionStaff();
  },
};
</script>
