<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Survey Results</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Survey Results</li>
            </ul>
          </nav>
        </div>

        <b-tabs class="my_tab_job tab_header_action_th">

         <!-- Freelancer Tab
        ================================================== -->
          <b-tab :title="'Freelancer'" @click="changeTabFreelancer">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div class="col-xl-6 col-md-6 big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex select-service-box date-picker-field clearfix calender_z_index_9 calender_in_mobile new_calender_section">
                  <div>
                    <label class="new_style_label required_sign required desktop_margin_bottom_zero"
                      >Date Range</label>

                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero ">
                      <date-range-picker
                        class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
                        ref="picker"
                        :minDate="minDate"
                        :opens="center"
                        :locale-data="locale"
                        :auto-apply="auto"
                        v-model="dateRangeFreelancer"
                        :ranges="range"
                        @update="changeTabFreelancer()"
                      >
                        <div slot="input" slot-scope="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </div>
                      </date-range-picker>
                    </b-form-group>
                  </div>

                </div>
                <div class="col-xl-4 col-md-4 mobile_tab_max_width_fle search_common_margin_bottom_28">
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <b-form-select
    v-model="register_from" 
    :options="register_from_list" 
    class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select date_style_size"
    @change="selectRegisterFreelancer" 
    placeholder="Register From"
  >
  </b-form-select>
                    </b-form-group>
                  </div>

                <div class="col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_28">
                  <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">
                    <b-button
                      v-if="show('export')"
                      class="button ripple-effect add_btn_admin_e desktop_margin_right_zero"
                      @click="exportFreelancer()"
                      style="margin-left: 20px"
                      >Export
                    </b-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="p-0 container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableFreelancer"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getFreelancer"
                  :fields="fieldsFL"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filterFL"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 nine_grid_tbl_new"
                >
                  <!-- Date -->
                  <template #cell(created_at)="items">
                    {{ items.item.date }}
                  </template>
                  <!-- Google -->
                    <template #cell(google)="items">
                    {{ items.item.google ? items.item.google : "0" }}
                  </template>
                  <!-- Insta -->
                    <template #cell(instagram)="items">
                    {{ items.item.instagram ? items.item.instagram : "0" }}
                  </template>
                  <!-- Fren -->
                    <template #cell(friends_family)="items">
                    {{ items.item.friends_family ? items.item.friends_family : "0" }}
                  </template>
                  <!-- FB -->
                    <template #cell(facebook)="items">
                    {{ items.item.facebook ? items.item.facebook : "0" }}
                  </template>
                  <!-- Linkedin -->
                  <template #cell(linkedin)="items">
                    {{ items.item.linkedin ? items.item.linkedin : "0" }}
                  </template>
                  <!-- TikTok -->
                    <template #cell(tiktok)="items">
                    {{ items.item.tiktok ? items.item.tiktok : "0" }}
                  </template>
                  <!-- Others -->
                    <template #cell(other)="items">
                    {{ items.item.other ? items.item.other : "0" }}
                  </template>
                  <!-- Total -->
                  <template #cell(total)="items">
                    {{ items.item.total ? items.item.total : "0" }}
                  </template>
                </b-table>

                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getFreelancer">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
              </div>
              <div class="col-md-4">
                <div class="p-0 container my_new_container show_filter_arrow">
                  <canvas id="doughnut-chart-jobs" class="dougnut_chart_dashboard"></canvas>
                  <h1 v-if='items && items.length==0' class='text-center align-center mt-5'>No Records Found.</h1>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Employer Tab
        ================================================== -->
        <b-tab :title="'Employer'" @click="changeTabEmployer">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row align-items-center">
  <!-- Date Range Picker Container -->
  <div class="col-xl-6 col-md-6 mobile_tab_max_width_fle search_common_margin_bottom_28">
    <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>
    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
      <date-range-picker
        class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
        ref="picker"
        :minDate="minDate"
        :opens="center"
        :locale-data="locale"
        :auto-apply="auto"
        v-model="dateRangeEmployer"
        :ranges="range"
        @update="changeTabEmployer()"
      >
        <div slot="input" slot-scope="picker">
          {{ picker.startDate | date }} - {{ picker.endDate | date }}
        </div>
      </date-range-picker>
    </b-form-group>
  </div>

  <!-- Multiselect Container -->
  <div class="col-xl-4 col-md-4 mobile_tab_max_width_fle search_common_margin_bottom_28">
    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
      <b-form-select
    v-model="register_from" 
    :options="register_from_list" 
    class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select date_style_size" 
    @change="selectRegisterEmployer" 
    placeholder="Register From"
  >
  </b-form-select>
    </b-form-group>
  </div>
</div>

            </div>

            <div class="row">
                <div class="col-md-8">
              <div class="p-0 container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableEmployer"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getEmployer"
                  :fields="fieldsEmp"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filterEmp"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 nine_grid_tbl_new"
                >
                  <!-- Date -->
                  <template #cell(created_at)="items">
                    {{ items.item.date }}
                  </template>
                  <!-- Google -->
                    <template #cell(google)="items">
                    {{ items.item.google ? items.item.google : "0" }}
                  </template>
                  <!-- Insta -->
                    <template #cell(instagram)="items">
                    {{ items.item.instagram ? items.item.instagram : "0" }}
                  </template>
                  <!-- Fren -->
                    <template #cell(friends_family)="items">
                    {{ items.item.friends_family ? items.item.friends_family : "0" }}
                  </template>
                  <!-- FB -->
                    <template #cell(facebook)="items">
                    {{ items.item.facebook ? items.item.facebook : "0" }}
                  </template>
                  <!-- Linkedin -->
                  <template #cell(linkedin)="items">
                    {{ items.item.linkedin ? items.item.linkedin : "0" }}
                  </template>
                  <!-- TikTok -->
                    <template #cell(tiktok)="items">
                    {{ items.item.tiktok ? items.item.tiktok : "0" }}
                  </template>
                  <!-- Others -->
                    <template #cell(other)="items">
                    {{ items.item.other ? items.item.other : "0" }}
                  </template>
                  <!-- Total -->
                  <template #cell(total)="items">
                    {{ items.item.total ? items.item.total : "0" }}
                  </template>
                </b-table>

                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getEmployer">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
            <div class="col-md-4">
                <div class="p-0 container my_new_container show_filter_arrow">

                  <canvas  id="doughnut-chart-jobs-emp" class="dougnut_chart_dashboard"></canvas>
                  <h1 v-if='items && items.length==0' class='text-center align-center mt-5'>No Records Found.</h1>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import permission from "../../../../../../server/permission.js";
import Chart from "chart.js";
import Multiselect from 'vue-multiselect'

export default {
  data() {
    return {
      total_google: 0,
      total_instagram: 0,
      total_friends_family: 0,
      total_facebook: 0,
      total_linkedin: 0,
      total_other: 0,
      total_tiktok: 0,
      doughnutChartJobData: null,
      fieldsFL: [
        { key: "created_at", label: "Date", sortable: true },
        { key: "google", label: "Google", sortable: false },
        { key: "instagram", label: "Instagram", sortable: false },
        { key: "friends_family", label: "Friends & Family", sortable: false },
        { key: "facebook", label: "Facebook", sortable: false },
        { key: "linkedin", label: "LinkedIn", sortable: false },
        { key: "tiktok", label: "Tiktok", sortable: false },
        { key: "other", label: "Others", sortable: false },
        { key: "total", label: "Total", sortable: false },
      ],
      fieldsEmp: [
        { key: "created_at", label: "Date", sortable: true },
        { key: "google", label: "Google", sortable: false },
        { key: "instagram", label: "Instagram", sortable: false },
        { key: "friends_family", label: "Friends & Family", sortable: false },
        { key: "facebook", label: "Facebook", sortable: false },
        { key: "linkedin", label: "LinkedIn", sortable: false },
        { key: "tiktok", label: "Tiktok", sortable: false },
        { key: "other", label: "Others", sortable: false },
        { key: "total", label: "Total", sortable: false },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      searchKeywordFreelancers: "",
      perPage: 25,
      itemsJobs: null,
      currentPageFreelancers: 1,
      perPageFreelancers: 25,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,
      register_from_list:[
        {
          value:'all',text:"All"
        },
        {
          value:'web',text:'Website'
        },
        {
          value:'app',text:"App"
        }
    ],
      dateRangeFreelancer: {
        startDate: "",
        endDate: "",
      },
      dateRangeEmployer: {
        startDate: "",
        endDate: "",
      },
      pageOptions: [25, 50, 75, 100],
      dateRangeFreelancer: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      dateRangeEmployer: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      sortBy: null,
      sortDirection: "desc",
      sortByFreelancers: null,
      sortDirectionFreelancers: "desc",
      filter: "",
      filterFreelancers: "",
      sortDesc: true,
      sortDescFreelancers: true,
      webUrl: process.env.VUE_APP_URL,
      currentTab: "freelancer",
      form: {
        rowsPerPage: 25,
        rowsPerPageFreelancers: 25,
        status: "",
      },
      total_uploaded: 0,
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
      actionsFreelancer: "",
      register_from:'all',
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
  },

  components: {
    DateRangePicker,
    Chart,
    Multiselect
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        if (this.currentTab == "freelancer") {
          this.$refs.datatableFreelancer.refresh();

        } else {
          this.$refs.datatableEmployer.refresh();
        }
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    changeTabFreelancer() {
      this.currentTab = "freelancer";
      this.$refs.datatableFreelancer.refresh();
    },
    changeTabEmployer() {
      this.currentTab = "employer";
      this.$refs.datatableEmployer.refresh();
    },
    clearFilterFreelancer() {
      (this.dateRangeFreelancer.startDate = moment().subtract(7, "days").format("YYYY-MM-DD")),
        (this.dateRangeFreelancer.endDate = moment().format("YYYY-MM-DD"));
        this.register_from='all'
      this.$refs.datatableFreelancer.refresh();
    },
    clearFilterEmployer() {
      (this.dateRangeEmployer.startDate = moment().subtract(7, "days").format("YYYY-MM-DD")),
        (this.dateRangeEmployer.endDate = moment().format("YYYY-MM-DD"));
        this.register_from='all'
      this.$refs.datatableEmployer.refresh();
    },
    selectRegisterFreelancer(selectedOption){
      this.$refs.datatableFreelancer.refresh()
     },
     selectRegisterEmployer(selectedOption){
      this.$refs.datatableEmployer.refresh()
     },
    exportFreelancer() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRangeFreelancer.startDate
        ? moment(this.dateRangeFreelancer.startDate).format("YYYY-MM-DD")
        : "";
      var end_date = this.dateRangeFreelancer.endDate
        ? moment(this.dateRangeFreelancer.endDate).format("YYYY-MM-DD")
        : "";
      var sortBy = this.sortBy ? this.sortBy : "users.created_at";
      var sortDirection = this.sortDesc ? "desc" : "asc";

      var export_url =
        "/api/tableSurvey-fl-excel/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
    },
    exportEmployer() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRangeEmployer.startDate
        ? moment(this.dateRangeEmployer.startDate).format("YYYY-MM-DD")
        : "";
      var end_date = this.dateRangeEmployer.endDate
        ? moment(this.dateRangeEmployer.endDate).format("YYYY-MM-DD")
        : "";
      var sortBy = this.sortBy ? this.sortBy : "users.created_at";
      var sortDirection = this.sortDesc ? "desc" : "asc";

      var export_url =
        "/api/tableSurvey-emp-excel/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
    },
    getFreelancer() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            type: this.currentTab,
            register_from:this.register_from,
            start_date: this.dateRangeFreelancer.startDate
              ? moment(this.dateRangeFreelancer.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRangeFreelancer.endDate
              ? moment(this.dateRangeFreelancer.endDate).format("YYYY-MM-DD")
              : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "users.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/SurveyResultsFL",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;

              const totals = this.items.reduce((acc, item) => {
                acc.google += item.google;
                acc.instagram += item.instagram;
                acc.friends_family += item.friends_family;
                acc.facebook += item.facebook;
                acc.linkedin += item.linkedin;
                acc.tiktok += item.tiktok;
                acc.other += item.other;
                return acc;
              }, {
                google: 0,
                instagram: 0,
                friends_family: 0,
                facebook: 0,
                linkedin: 0,
                tiktok: 0,
                other: 0
              });

      this.total_google= totals.google
      this.total_instagram= totals.instagram
      this.total_friends_family=totals.friends_family,
      this.total_facebook=totals.facebook,
      this.total_linkedin=totals.linkedin
      this.total_other=totals.other
      this.total_tiktok=totals.tiktok
      this.doughnutChartJobData = {
              labels: [
              this.total_google + " Google",
              this.total_instagram+" Instagram",
              this.total_friends_family +" Friends and Family",
              this.total_facebook+ " Facebook",
              this.total_linkedin+ " Linkedin",
              this.total_tiktok + " Tiktok",
              this.total_other+" Others",
              ],
              datasets: [
                {
                  data: [
                  this.total_google,
                  this.total_instagram,
                  this.total_friends_family,
                  this.total_facebook,
                  this.total_linkedin,
                  this.total_tiktok,
                  this.total_other,
                  ],
                  // backgroundColor: ["#4c8bf5", "#e3ecfd"],
                  backgroundColor: [
                    "#f5b84d",
                    "#8d70fe",
                    "#f6614c",
                    "#689ef7",
                    "#3418a0",
                    "#f67ace",
                    "#808080",
                  ],

                  // hoverBackgroundColor: ["#4c8bf5", "#e3ecfd"],
                  hoverOffset: 4,
                  borderWidth: 0,
                  borderRadius: 100,
                },
              ],
            };

            const doughnutChartJob = {
              type: "doughnut",
              data: this.doughnutChartJobData,
              options: {
                responsive: false,
                legend: {
                  position: "bottom",
                  display: true,
                  responsive: true,
                  maintainAspectRatio: false,
                  labels: {
                    fontSize: 16,
                    fontColor: "#333333",
                    fontStyle: "bold",
                    boxWidth:20,


                   },
                },
                cutoutPercentage: 50,

                title: {
                  display: true,
                  text: "Survey Results",
                  fontSize:20,
                  fontColor: "#333333",
                    fontStyle: "bold",
                },
                tooltips: {
                  filter: function (item, data) {
                    var label = data.labels[item.index];
                    if (label) return item;
                  },
                },
              },
            };
            const ctxJob = document.getElementById("doughnut-chart-jobs");
            if(this.$store.getters.getResults.pageOfItems.length>0){
              ctxJob.setAttribute("width", "400");
              ctxJob.setAttribute("height", "330");
            }else{
              ctxJob.setAttribute("width", "0");
            ctxJob.setAttribute("height", "0");
            }

            new Chart(ctxJob, doughnutChartJob);

            return(this.items)
          }

        });


    },
    getEmployer() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            type: this.currentTab,
            register_from:this.register_from,
            start_date: this.dateRangeEmployer.startDate
              ? moment(this.dateRangeEmployer.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRangeEmployer.endDate
              ? moment(this.dateRangeEmployer.endDate).format("YYYY-MM-DD")
              : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "users.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/SurveyResultsEmp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;

              const totals = this.items.reduce((acc, item) => {
                acc.google += item.google;
                acc.instagram += item.instagram;
                acc.friends_family += item.friends_family;
                acc.facebook += item.facebook;
                acc.linkedin += item.linkedin;
                acc.tiktok += item.tiktok;
                acc.other += item.other;
                return acc;
              }, {
                google: 0,
                instagram: 0,
                friends_family: 0,
                facebook: 0,
                linkedin: 0,
                tiktok: 0,
                other: 0
              });

      this.total_google= totals.google
      this.total_instagram= totals.instagram
      this.total_friends_family=totals.friends_family,
      this.total_facebook=totals.facebook,
      this.total_linkedin=totals.linkedin
      this.total_other=totals.other
      this.total_tiktok=totals.tiktok

      this.doughnutChartJobData = {
              labels: [
              this.total_google + " Google",
              this.total_instagram+" Instagram",
              this.total_friends_family +" Friends and Family",
              this.total_facebook+ " Facebook",
              this.total_linkedin+ " Linkedin",
              this.total_tiktok + " Tiktok",
              this.total_other+" Others",
              ],
              datasets: [
                {
                  data: [
                  this.total_google,
                  this.total_instagram,
                  this.total_friends_family,
                  this.total_facebook,
                  this.total_linkedin,
                  this.total_tiktok,
                  this.total_other,
                  ],
                  // backgroundColor: ["#4c8bf5", "#e3ecfd"],
                  backgroundColor: [
                    "#f5b84d",
                    "#8d70fe",
                    "#f6614c",
                    "#689ef7",
                    "#3418a0",
                    "#f67ace",
                    "#808080",
                  ],

                  // hoverBackgroundColor: ["#4c8bf5", "#e3ecfd"],
                  hoverOffset: 4,
                  borderWidth: 0,
                  borderRadius: 100,
                },
              ],
            };

            const doughnutChartJob = {
              type: "doughnut",
              data: this.doughnutChartJobData,
              options: {
                responsive: false,
                legend: {
                  position: "bottom",
                  display: true,
                  responsive: true,
                  maintainAspectRatio: false,
                  labels: {
                    fontSize: 16,
                    fontColor: "#333333",
                    fontStyle: "bold",
                    boxWidth:20,


                   },
                },
                cutoutPercentage: 50,

                title: {
                  display: true,
                  text: "Survey Results",
                  fontSize:20,
                  fontColor: "#333333",
                    fontStyle: "bold",
                },
                tooltips: {
                  filter: function (item, data) {
                    var label = data.labels[item.index];
                    if (label) return item;
                  },
                },
              },
            };
            const ctxJobEmp = document.getElementById("doughnut-chart-jobs-emp");
            if(this.$store.getters.getResults.pageOfItems.length>0){
              ctxJobEmp.setAttribute("width", "400");
              ctxJobEmp.setAttribute("height", "330");
            }else{
              ctxJobEmp.setAttribute("width", "0");
              ctxJobEmp.setAttribute("height", "0");
            }

            new Chart(ctxJobEmp, doughnutChartJob);


            return(this.items)

          }
        });
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Survey Results");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.permissionStaff();
  },
};
</script>
