<template>
    <div>

         <div class="container full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Billing Address</h1>
            <b-link v-if="address_count == 0" class="btn green-border-btn" :to="{name:'jobseekerAddbillingAddress'}">Add</b-link>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Billing Address</li>
                </ul>
            </nav>
        </div>
                <div class="col-xl-12 remove_search_box_pdng">
                    <div class="search-box custom_search_admin new_custom_search_admin_remove">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-form-input id="searchBtn" v-model="filter" v-on:change="getBillingAddress" placeholder="Enter keyword to search"></b-form-input>
                                <b-button class="btn"><i class="fa fa-search"></i></b-button>
                            </b-input-group-prepend>
                            </b-input-group>
                        </div>
                    </div>
                </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <div class="container full_container_dsk">
           <div class="row">
               <b-table ref="datatable" show-empty striped hover responsive :items="getBillingAddress" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="new_box_tbl_mrgn five_grid_tbl common_tbl_width">
                    <template #cell(name)="items">
                        {{items.item.name | capitalize}}
                    </template>
                    <template #cell(billing_email)="items">
                        {{items.item.billing_email}}
                    </template>
                    <template #cell(street)="items">
                        {{items.item.street | capitalize}}
                    </template>
                    <template #cell(city)="items">
                        {{items.item.city | capitalize}}
                    </template>
            
                </b-table>
                <nav class="pagination-box">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getBillingAddress'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
export default {

    data() {
        return {
            fields: [
                { key: 'name', label: 'Name', sortable: true },
                { key: 'billing_email', label: 'Billing Email', sortable: true },
                { key: 'street', label: 'Address', sortable: true },
                { key: 'city', label: 'City', sortable: true },
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            address_count:0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            }
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getBillingAddress() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    id:this.user_id,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/billing-address'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.address_count = this.$store.getters.getResults.address_count;
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },

        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Country Inactivated successfully.';
                var new_status = 'inactive';
            } 
            else{
                var msg = 'Are you sure you want to activate this record?';
                var message='Country Activated successfully.';
                var new_status = 'active';
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            status:new_status,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/country-status' 
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
    },
     mounted(){
            //document.title = "EL Connect - Jobseeker - Billing Address";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
     }
}
</script>
