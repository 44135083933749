<template>
    <div>
        
        <!-- <div class="container full_container_dsk"> -->
        <div class="row">
           
              <!-- <div class="row mobile_email_row custom_dsk_row"> -->
              <div class="container my_new_container mobile_email_row custom_dsk_row">
                  <!-- Dashboard Headline -->
                  <div class="dashboard-headline">
                      <h1>Certificates</h1>

                      <!-- Breadcrumbs -->
                      <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                          <ul>
                              <li><a href="#">Home</a></li>
                              <li>Certificates</li>
                          </ul>
                      </nav>
                  </div>
                  <!-- <div class="col-xl-4 remove_search_box_pdng">
                      <div class="search-box custom_search_admin">
                          <b-input-group>
                          <b-input-group-prepend>
                              <b-form-input id="searchBtn" v-model="filter" v-on:change="getCert" placeholder="Enter keyword to search"></b-form-input>
                              <b-button class="btn"><i class="fa fa-search"></i></b-button>
                          </b-input-group-prepend>
                          </b-input-group>
                      </div>
                  </div> -->

                  
                  </div>

            </div>

        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk"> -->
        <div class="row">
           <!-- <div class="row"> -->
           <div class="container my_new_container show_filter_arrow">
				        <b-table ref="datatable" show-empty striped hover responsive="" :items="getCert" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 certificate_table">

                  <template #cell(certificate)="items">
                    {{items.item.title}}
                    </template>
                  <template #cell(url)="items">
                      <p v-if="items.item.user_cert.length > 0 ">
                        <a :href="items.item.user_cert[0].url" target="_blank"><img :src="items.item.user_cert[0].url" height="80" width="80"/></a>
                      </p>
                  </template>
                  <template #cell(certificate_number)="items">
                    {{(items.item.user_cert.length > 0 ? items.item.user_cert[0].certificate_number : '' ) | capitalize}}
                  </template>
                 <template #cell(obtainment_date)="items">
                    {{(items.item.user_cert.length > 0 ? items.item.user_cert[0].obtainment_date : '' ) | moment1}}
                  </template>
                 
                  <template #cell(expiry_date)="items">
                  <span v-if="items.item.user_cert.length > 0">
                    <!-- <span v-b-tooltip v-if="items.item.user_cert[0].expiry_date < three_month_after" title="Cert is expiring soon, kindly submit a ticket with your new certificate to renew."> -->
                    <span v-if="items.item.user_cert[0].expiry_date < three_month_after">
                        <b style="color:red">{{ (items.item.user_cert.length > 0 ? items.item.user_cert[0].expiry_date : '') | moment1 }}</b><br>
                        <b v-if="(items.item.user_cert[0].expiry_date < three_month_after) && (items.item.user_cert[0].expiry_date >= today_date)" style="color:orange">{{'Cert is expiring soon, kindly submit a support ticket with your new certificate to renew.'}}</b>
                        <b v-if="(items.item.user_cert[0].expiry_date < three_month_after) && (items.item.user_cert[0].expiry_date < today_date)" style="color:orange">{{'Cert expired, kindly submit a support ticket with your new certificate to renew.'}}</b>
                      
                      </span>
                      <span v-else>
                        {{ (items.item.user_cert.length > 0 ? items.item.user_cert[0].expiry_date : '') | moment1 }}
                      </span>
                    </span>
                  <span v-else>
                    {{(items.item.user_cert.length > 0  ? items.item.user_cert[0].expiry_date : '' ) | moment1}}
                  </span>
                  </template>
                   <!-- <template #cell(expiry_date)="items">
                    {{(items.item.user_cert.length > 0  ? items.item.user_cert[0].expiry_date : '' ) | moment1}}
                  </template> -->
                  <!-- <template slot="status" slot-scope="items">
                    {{items.item.certificate_types != null ? items.item.certificate_types.user_certificates.status  : '-' }}
                  </template> -->
                 
                    <template #cell(toggleAction)="items">
                    <div class="toggle-action">
                      <b-dropdown class="mx-1" right text="Actions" boundary="window">
                          <b-dropdown-item v-if="(items.item.user_cert.length > 0 && items.item.user_cert[0].status != 'approved')"  @click="deleteRecord(items.item.user_cert[0].id)">Delete Certificate 
                          </b-dropdown-item>
                          <b-dropdown-item v-if="(items.item.user_cert.length > 0 && items.item.user_cert[0].status != 'approved') || (items.item.user_cert.length > 0 && items.item.user_cert[0].expiry_date < today_date)" :to="{name:'edit-certificate', params: {id: encrypt(user_id), cert_id: encrypt(items.item.id), edit_id: encrypt(items.item.user_cert[0].id)}}">Edit Certificate
                          </b-dropdown-item>
                          <b-dropdown-item v-if="(items.item.user_cert.length == 0)" :to="{name:'add-certificate', params: {id: encrypt(user_id), cert_id: encrypt(items.item.id)}}">Add Certificate
                          </b-dropdown-item>
                      </b-dropdown>
                    </div>
                </template>
                </b-table>
                <nav class="pagination-box  custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getCert'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			      </div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
       
        
    </div>
</template>

<script>
    import { POST_API} from "../../../store/actions.type";
    import JwtService from "../../../common/jwt.service";
    import moment from "moment";
    import Vue from 'vue';
    import Bus from "../../../event-bus";

  
    export default {
  
      data() {
        return {
          fields: [
                  { key: 'certificate', label: 'Certificate', sortable: true },
                  { key: 'url', label: 'Url', sortable: true },
                  { key: 'certificate_number', label: 'Certificate No.', sortable: true },
                  { key: 'obtainment_date', label: 'Obtainment Date', sortable: true },
                  { key: 'expiry_date', label: 'Expiry Date', sortable: true, thStyle:  {width: '16.66%'}},

                  // { key: 'status', label: 'Status', sortable: true },
                  { key: 'toggleAction', label: 'Actions', }
              ],
              form: {
                rowsPerPage:25,
              },
              items:null,
              totalRows: 1,
              currentPage: 1,
              perPage: 25,
              pageOptions: [25, 50, 75, 100],
              sortBy: null,
              sortDirection: 'asc',
              sortDesc: false ,
              filter: null,
              status: 'active',
              isBusy: false,
              to:'',
              from:'',
              webUrl: process.env.MIX_APP_URL,
              actions:'',
              user_type_id:'',
              today_date: moment().format('YYYY-MM-DD'),
              three_month_after:moment().add(3, 'months').format('YYYY-MM-DD')

              
        }
      },
      filters: {
          moment1: function(date) {

             if(date != ""){

               return moment(date).format('DD MMM YYYY');

             }else{
               return '-'
             }
          },
          capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
          },
        },
      methods: {
          onFiltered(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              this.totalRows = filteredItems.length
              this.currentPage = 1
          },
          tooltipContent(item){
            console.log('dgd')
            return 'fgffg'
          },
          showAlert() {
            // window.scrollTo(0,0);
            Swal.fire({
              position: 'top-center',
              type: 'error',
              text: this.error_message,
              showConfirmButton: false,
              timer: 3000
            })
          },
          deleteRecord(id) {

              this.$store.dispatch(POST_API, {
                data:{
                  id:id
                },
                api: '/api/delete-fl-certificate'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                  } else {
                    this.success_message = this.$store.getters.getResults.message;

                    
                         this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Certificate deleted successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {

                   
                   window.location.reload();
                        })
                  }
              });
          },
          getCert() {
              return this.$store.dispatch(POST_API, {
                     data:{
                       status: this.status,
                       user_id:this.user_id,
                       page: this.currentPage,
                       keyword: this.filter,
                       rowsPerPage:this.form.rowsPerPage,
                       sortBy:this.sortBy,
                       sortDirection:this.sortDirection,
                       sortDesc:this.sortDesc,
                       type:'web'
                     },
                     api: '/api/certificateList'
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                          return [];
                      } else {

                           
                          this.items = this.$store.getters.getResults.pageOfItems;
                          this.totalRows = this.$store.getters.getResults.pager.total;
                          this.perPage = this.$store.getters.getResults.pager.per_page;
                          this.from = this.$store.getters.getResults.pager.from
                          this.to = this.$store.getters.getResults.pager.to
                         
                          return this.items;
                      }
                  });
          },
          
          
      },
      mounted(){
          $(".show_filter_arrow .table thead th div").contents().unwrap();
          this.user_type_id = this.$store.getters.currentUser.user_type_id;
          this.user_id = this.$store.getters.currentUser.id;
          Bus.$emit('active_users');

          window.scrollTo(0,0);
          $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");

      }
    }
    
  </script>
  
  