<template>

    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->
        <div class="">
            <div class="dashboard-headline">
                <div class="back_arrow_icon">
                    <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
                </div>
                <h1>Edit Saved Job</h1>
                <nav id="breadcrumbs" class="dark employer_nav">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Job</a></li>
                            <li>Edit Saved Job</li>
                        </ul>
                    </nav>
            </div>
            <div class="row common_row">
            <b-form method="post" id="register-account-form" class="emp_frm_mobile nw_frm">
                <p id="error"></p>
				<!-- Dashboard Box -->

                <div class="col-xl-12 post_job_mbl">
                    <div class="dashboard-box">
                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> Job Submission Form</h3>
                        </div>

                        <div class="content">
                            <ul class="fields-ul second_indiv_step_wrap">
                                <li>
                                    <div class="row">

                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Job Title <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.title"
                                                    id="company_reg_no"
                                                    class="input-text with-border required-field"
                                                    name="company_reg_no"
                                                    placeholder=""
                                                    value=""
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Job Title !</span>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Job Role Type <span class="required_star">*</span></h5>
                                                <b-form-select v-model="form.role_type_id"  id="job_role_type" class="job_role_type" @change="salaryTypeData(form.role_type_id)">
                                                    <option value="" disabled>Select Role Type</option>
                                                    <option :value="role_type.id" v-for="role_type in role_types">{{role_type.name | removeUnderscore}}</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Job Role Type !</span>
                                        </div>
                                        <div class="col-xl-2 minMaxSalary">
                                            <div class="submit-field">
                                                <h5>{{salaryType}} </h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.min_salary"
                                                    id="minSal"
                                                    class="input-text with-border"
                                                    name="office_address"
                                                    placeholder="Min (SGD)"
                                                    value=""
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Min (SGD) Amount !</span>

                                        </div>
                                        <div class="col-xl-2 minMaxSalary">
                                            <div class="submit-field">
                                                <h5></h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.max_salary"
                                                    id="maxSal"
                                                    class="input-text with-border"
                                                    name="office_address"
                                                    placeholder="Max (SGD)"
                                                    value=""
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Max (SGD) Amount !</span>
                                        </div>
                                        <!-- <div class="col-xl-4 perHourRate">
                                            <div class="submit-field">
                                                <h5>Per Hour Rate <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"

                                                    id="per_hour"
                                                    class="input-text with-border required-field"
                                                    name="office_address"
                                                    placeholder="Per Hour Rate (SGD)"
                                                    value=""
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Per Hour Rate !</span>
                                        </div> -->

                                        <div class="tags_div">
                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Tags</h5>
                                                    <b-form-tags input-id="tags-basic" v-model="form.tagArr" ></b-form-tags>

                                            </div>
                                        </div>
                                        </div>

                                        <div class="cat_div">
                                        <div class="top_inline_div">
                                        <div class="col-xl-4 cat_inner_divs">
                                            <div class="submit-field">
                                                <h5>Job Category <span class="required_star">*</span></h5>
                                                <b-form-select v-model="form.service_categories_id" id="jbs_category" class="jbs_category" @change="showCategory(form.service_categories_id)">
                                                    <option value="" disabled>Select Category</option>
                                                    <option :value="category.id" v-for="category in categories">{{category.name |capitalize}}</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Job Category !</span>




                                        </div>
                                        <div class="col-xl-4 cat_inner_divs other_category_class">
                                            <div class="submit-field">
                                                <h5>Specify Job Category</h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.other_service_category"
                                                    class="input-text with-border"
                                                    name="company_reg_no"
                                                    placeholder="Please Specify Category"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        </div>

                                        <div class="top_inline_div">
                                            <div class="col-xl-4 cat_inner_divs">
                                                <div class="submit-field">
                                                    <h5>Location <span class="required_star">*</span></h5>
                                                     <select v-model="form.location_id" id="job_location" class="job_location" @change="showLocation(form.location_id)">
                                                        <option value='' selected='selected' disabled>Select Location</option>
                                                        <optgroup :label="location.title" v-for="location in locations">
                                                            <option :value="location.location_names_id[index]" v-for="(area,index) in location.location_names_val">{{area | capitalize}}</option>
                                                        </optgroup>
                                                    </select>
                                                    <!-- <b-form-select v-model="form.location_id" id="job_location" class="job_location">
                                                        <option value="" disabled>Select Location</option>
                                                        <option :value="location.id" v-for="location in locations">{{location.title | capitalize}}</option>
                                                    </b-form-select> -->
                                                </div>
                                                <span class="error_x_white">Please enter Location !</span>

                                            </div>

                                            <div class="col-xl-4 cat_inner_divs other_location_class">
                                                <div class="submit-field">
                                                    <h5>Specify Location</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.other_job_location"
                                                        class="input-text with-border"
                                                        name="company_reg_no"
                                                        placeholder="Please Specify Location"
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    <div class="col-xl-12">
                                            <div class="submit-field">
                                                <h5>Job Description <span class="required_star">*</span></h5>
                                                <ckeditor v-model="form.description" :config="editorConfig" id="jb_description" class="jb_description"></ckeditor>
                                                    <!-- <textarea type="text" class="with-border" value="" v-model="form.description"></textarea> -->
                                            </div>
                                                <span class="error_x_white">Please enter job description !</span>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                    <h5>Minimum Qualification <span class="required_star">*</span></h5>
                                                    <b-form-select v-model="form.qualification_id" id="job_qualification" class="job_qualification">
                                                        <option value="" disabled>Select Qualification</option>
                                                        <option :value="qualification.id" v-for="qualification in qualifications">{{qualification.title | capitalize}}</option>
                                                    </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Qualification !</span>
                                        </div>

                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Minimum Year of Experience <span class="required_star">*</span></h5>
                                                <select v-model="form.experience" class="custom-select min_year_exp" id="min_year_exp">
                                                    <option value="" disabled>Select</option>
                                                    <option :value="experience_yr" v-for="(experience_yr,id) in experience_yrs" :key="id">{{experience_yr}}</option>
                                                </select>
                                            </div>
                                            <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                                        </div>

                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Position Level <span class="required_star">*</span></h5>
                                                <b-form-select v-model="form.position_level_id" id="position_level" class="position_level">
                                                    <option value="" disabled>Select Position Level</option>
                                                    <option :value="position_level.id" v-for="position_level in position_levels">{{position_level.title | capitalize}}</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Position Level !</span>
                                        </div>

                                         <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Job Posting Package</h5>
                                                <b-form-select v-model="form.package" id="package" class="package">
                                                    <option value="" disabled>Select Posting Package</option>
                                                    <!-- <option value="free">{{expiry}} days - Free Post - {{form.packageName}} ({{remaining_jobs}} Remaining)</option> -->
                                                    <option value="coins">{{expiry}} days - {{coinsperjob}} ELC Credits</option>                                                            </b-form-select>
                                            </div>
                                                <span class="error_x_white">Please select Posting Package !</span>

                                        </div>

                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Job Publish Date <span class="required_star">*</span></h5>
                                                <datepicker v-model="form.scheduled_post" :open-date="new Date()"  :format="customFormatterTwo" id="scheduled_post" class="scheduled_post"></datepicker>
                                            </div>
                                            <span class="error_x_white">Please enter Job Publish Date !</span>
                                        </div>

                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="col-xl-12 post_jb_btn">
                        <!-- <a @click="submitEdit" class="button ripple-effect big margin-top-30 employee_btn"><span class="icon-feather-edit"></span> Update Draft</a> -->
                        <a @click="submitPost" class="button ripple-effect big margin-top-30 employee_btn" style="
    background: #4c8bf5 !important;
"> + Post Now</a>
                    </div>
                </div>
                <!-- Button -->
            </b-form>
		    </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
        <!-- Row / End -->

    </div>

</template>

<script>
    import { POST_API } from "../../../store/actions.type"
    import Multiselect from 'vue-multiselect'
    import CKEditor from 'ckeditor4-vue';
    import Datepicker from 'vuejs-datepicker';
    import moment from "moment";

    export default {

        data() {
            return {
                form:{
                    title: '',
                    type: 'full_time',
                    role_type_id:'',
                    service_categories_id:'',
                    location :'',
                    min_salary :'',
                    max_salary: '',
                    tagArr: [],
                    qualification_id:'',
                    location_id:'',
                    other_job_location:'',

                    position_level_id:'',
                    qualification:'',
                    experience: '',
                    level: '',
                    package: 'coins',
                    scheduled_post: '',
                    user_id: '',
                    freejobs:'',
                },
                expiry:'',
                coinsperjob:'',
                postedjobs:'',
                salaryType:'Salary',
                part_time_id:'',
                package_id: '',
                remaining_jobs:0,
                other_location_id:'',
                other_category_id:'',

                packageName:'',
                qualifications:[],
                locations:[],
                position_levels: [],
                categories: [],
                role_types:[],
                experience_yrs: ['No experience', 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
                job_id: atob(this.$route.params.id),
                tag_data:[],
                showDismissibleAlert:false,
                error_message:'',
                isActive: 1,
                personal:true,
                links:false,
                disabled:true,
                disabledFromDate: {
                    to: new Date(Date.now() - 8640000)
                },
                editorConfig: {
                versionCheck : false,
                 removeButtons:'Maximize,Paste,Cut,Copy,Undo,Redo,SpellCheck,Link,Unlink,Anchor,Image,Table,Source,Subscript,Superscript,Blockquote,Styles,PasteText,RemoveFormat,Scayt,PasteFromWord,RemoveFormat,Blockquote,HorizontalRule,SpecialChar,Strike,About,Format',
                 //extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
                },
                coreStyles_bold: {
                    element: 'b',
                    overrides: 'strong'
                },
                // Custom style definition for the Italic feature.
                coreStyles_italic: {
                    element: 'i',
                    overrides: 'em'
                },
            }
        },
        components: {
            ckeditor: CKEditor.component,
            Multiselect,
            Datepicker
        },

        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },

        methods:{
            activeTab(){
                this.personal = true;
                this.links = false;
            },

            showSalary(id){
                if(id == this.part_time_id){
                    $('.perHourRate').css('display', 'block');
                    $('.minMaxSalary').css('display', 'none');
                }else{
                    $('.perHourRate').css('display', 'none');
                    $('.minMaxSalary').css('display', 'block');
                }
            },
            showCategory(id){
                if(id == this.other_category_id){
                    $('.other_category_class').css('display', 'block');
                }else{
                    $('.other_category_class').css('display', 'none');
                }
            },
            showLocation(id){
                if(id == this.other_location_id){
                    $('.other_location_class').css('display', 'block');
                }else{
                    $('.other_location_class').css('display', 'none');
                }
            },

            customFormatterTwo(date) {
                return moment(date).format('DD-MM-YYYY');
            },

            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },

            getDropdownData(type){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/dropdown-list-category'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.categories = this.$store.getters.getResults.data;
                        this.other_category_id = this.$store.getters.getResults.other_category_id;
                        $('.other_category_class').css('display', 'none');
                    }
                });
            },
            removeTag(value) {

                var id = value.id;
                var selected_record = this.subvalue;
                var new_array = [];
                for (var i = 0; i <= selected_record.length - 1; i++) {
                    if (selected_record[i].parent_category != value.id) {
                        new_array.push(selected_record[i]);
                    }
                }
                this.subvalue = new_array;
            },

            submitEdit() {
                var company_reg_no=$('#company_reg_no').val();
                var job_role_type=$('#job_role_type').val();
                var minSal=$('#minSal').val();
                var maxSal=$('#maxSal').val();
                var per_hour=$('#per_hour').val();
                var jbs_category =$('#jbs_category').val();
                var job_location =$('#job_location').val();
                var job_qualification =$('#job_qualification').val();
                var min_year_exp =$('#min_year_exp').val();
                var position_level =$('#position_level').val();
                var scheduled_post =$('#scheduled_post').val();

    				 $('.required-field').each(function () {

                if ($(this).val() == '') {
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.job_role_type').each(function () {

                if( !$('#job_role_type').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });


                 if( $('#maxSal').val() ){
                     if(!$('#minSal').val()){
                         $('#minSal').addClass('error');
                        $('#minSal').parent().parent().find('.required_content').addClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {
                        $('#minSal').removeClass('error');
                        $('#minSal').parent().parent().find('.required_content').removeClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').removeClass('show');
                }

                }

                if( $('#minSal').val() ){
                     if(!$('#maxSal').val()){
                         $('#maxSal').addClass('error');
                        $('#maxSal').parent().parent().find('.required_content').addClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {
                        $('#maxSal').removeClass('error');
                        $('#maxSal').parent().parent().find('.required_content').removeClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').removeClass('show');
                    }

                }

            $('.jbs_category').each(function () {

                if( !$('#jbs_category').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }



            });
             $('.job_location').each(function () {

                if( !$('#job_location').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });


            $("#job_role_type").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#jbs_category").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#job_location").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            // $(".scheduled_post").focusin(function(){
            //    $(this).removeClass('error');
            //    $(this).parent().parent().find('.error_x_white').removeClass('show');
            // });
            // $(".scheduled_post").focusout(function(){
            //    $(this).addClass('error');
            //    $(this).parent().parent().find('.error_x_white').addClass('show');
            // });

           $("#package").focusout(function(){
                        if($('#package').val() === ""){
                            $('#package').addClass('invalid');
                            $('#package-warning-message').show();
                        }else{
                        $('#package').removeClass('invalid');
                        $('#package-warning-message').hide();
                        }
                    });




            setTimeout(function ()
            {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show');
                    });
            }, 100);

            if(this.form.max_salary && this.form.min_salary){

                if(parseInt(this.form.max_salary) < parseInt(this.form.min_salary)){

                    err = true
                    this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: 'Max salary cannot be less than min salary.',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }


                }

            if(this.form.service_categories_id == this.other_category_id){

                    if(!this.form.other_service_category){
                        err = true
                        this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Please specify the category.',
                                showConfirmButton: false,
                                timer: 1500
                            });
                    }
                }

                if(this.form.location_id == this.other_location_id){

                    if(!this.form.other_job_location){
                        err = true
                        this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Please specify the location.',
                                showConfirmButton: false,
                                timer: 1500
                            });
                    }
                }



                // if(this.form.role_type_id == this.part_time_id){
                //     this.form.min_salary = 0
                // }
                this.$store.dispatch(POST_API, {
                    data:{
                        id:this.job_id,
                        title: this.form.title,
                        type: this.form.type,
                        email: this.form.email,
                        category: this.form.service_categories_id,
                        other_category: this.form.other_service_category,

                        location: this.form.location_id,
                        other_location: this.form.other_job_location,

                        salarymin: this.form.min_salary ? this.form.min_salary: 0,
                        salaryMax: this.form.max_salary ? this.form.max_salary: 0,
                        tag: this.form.tagArr,
                        description:this.form.description ? this.form.description : '',
                        qualification: this.form.qualification_id ? this.form.qualification_id : '',
                        experience : this.form.experience,
                        level : this.form.position_level_id ? this.form.position_level_id : '',
                        userId : this.logged_in_user_id,
                        actual_posted_by : this.logged_in_user_id,
                        job_type_id: this.form.role_type_id,
                        start_date: this.form.scheduled_post ? moment(this.form.scheduled_post).format('YYYY-MM-DD') : null,
                        status:'updateDraft'
                    },
                    api:'/api/draftJob'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;


                    } else {
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Job Updated successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            window.location.replace('/v2/employer/drafted-jobs');

                            // this.$router.push({ name: 'saved-jobs' });

                        });
                    }
                });
            },

            submitPost() {
                var company_reg_no=$('#company_reg_no').val();
                var job_role_type=$('#job_role_type').val();
                var minSal=$('#minSal').val();
                var maxSal=$('#maxSal').val();
                var per_hour=$('#per_hour').val();
                var jbs_category =$('#jbs_category').val();
                var job_location =$('#job_location').val();
                var job_qualification =$('#job_qualification').val();
                var min_year_exp =$('#min_year_exp').val();
                var position_level =$('#position_level').val();
                var scheduled_post =$('#scheduled_post').val();

    				 $('.required-field').each(function () {

                if ($(this).val() == '') {
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.job_role_type').each(function () {

                if( !$('#job_role_type').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });


                 if( $('#maxSal').val() ){
                     if(!$('#minSal').val()){
                         $('#minSal').addClass('error');
                        $('#minSal').parent().parent().find('.required_content').addClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {
                        $('#minSal').removeClass('error');
                        $('#minSal').parent().parent().find('.required_content').removeClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').removeClass('show');
                }

                }

                if( $('#minSal').val() ){
                     if(!$('#maxSal').val()){
                         $('#maxSal').addClass('error');
                        $('#maxSal').parent().parent().find('.required_content').addClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {
                        $('#maxSal').removeClass('error');
                        $('#maxSal').parent().parent().find('.required_content').removeClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').removeClass('show');
                    }

                }

            $('.jbs_category').each(function () {

                if( !$('#jbs_category').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }



            });
             $('.job_location').each(function () {

                if( !$('#job_location').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });
            $('.job_qualification').each(function () {

                if( !$('#job_qualification').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.min_year_exp').each(function () {

                if( !$('#min_year_exp').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.position_level').each(function () {

                if( !$('#position_level').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.scheduled_post').each(function () {

                if( !$('#scheduled_post').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.package').each(function () {

                if( !$('#package').val() ){
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });



            $("#job_role_type").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#jbs_category").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#job_location").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#job_qualification").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
             $("#package").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#min_year_exp").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#position_level").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $(".scheduled_post").focusin(function(){
               $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            // $(".scheduled_post").focusout(function(){
            //    $(this).addClass('error');
            //    $(this).parent().parent().find('.error_x_white').addClass('show');
            // });

           $("#package").focusout(function(){
                        if($('#package').val() === ""){
                            $('#package').addClass('invalid');
                            $('#package-warning-message').show();
                        }else{
                        $('#package').removeClass('invalid');
                        $('#package-warning-message').hide();
                        }
                    });




            setTimeout(function ()
            {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show');
                    });
            }, 100);

                     if(this.form.service_categories_id == this.other_category_id){

                    if(!this.form.other_service_category){
                        err = true
                        this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Please specify the category.',
                                showConfirmButton: false,
                                timer: 1500
                            });
                    }
                }

                if(this.form.location_id == this.other_location_id){

                    if(!this.form.other_job_location){
                        err = true
                        this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Please specify the location.',
                                showConfirmButton: false,
                                timer: 1500
                            });
                    }
                }
                // if(this.form.role_type_id == this.part_time_id){
                //     this.form.min_salary = 0
                // }
                this.$store.dispatch(POST_API, {
                    data:{
                        id:this.job_id,
                        title: this.form.title,
                        type: this.form.type,
                        email: this.form.email,
                        category: this.form.service_categories_id,
                        other_category: this.form.other_service_category,

                        location: this.form.location_id,
                        other_location: this.form.other_job_location,

                        salarymin: this.form.min_salary ? this.form.min_salary: 0,
                        salaryMax: this.form.max_salary ? this.form.max_salary: 0,
                        tag: this.form.tagArr,
                        description:this.form.description,
                        qualification: this.form.qualification_id,
                        experience : this.form.experience,
                        level : this.form.position_level_id,
                        userId : this.logged_in_user_id,
                        actual_posted_by: 1,
                        job_type_id: this.form.role_type_id,
                        package: this.form.package,
                        remaining_free: this.remaining_jobs,

                        start_date: this.form.scheduled_post ? moment(this.form.scheduled_post).format('YYYY-MM-DD') : null,
                    },
                    api:'/api/postDraftJob'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                    } else {
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Job Posted successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            window.location.replace('v2//employer/manage-jobs');

                            // this.$router.push({ name: 'manage-jobs' });

                        });
                    }
                });
            },

            getEditData() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.job_id
                    },
                    api: '/api/editJob'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.form = this.$store.getters.getResults.job_data;
                        this.form.location_id = this.$store.getters.getResults.job_data.address;
                        this.form.other_job_location = this.$store.getters.getResults.job_data.other_location;
                        this.form.other_service_category = this.$store.getters.getResults.job_data.other_service_category;

                        this.form.qualification_id = this.$store.getters.getResults.job_data.educational_qualification_id;
                        this.form.position_level_id = this.$store.getters.getResults.job_data.level;
                        this.form.role_type_id = this.$store.getters.getResults.job_data.job_type_id;
                        // this.form.scheduled_post = this.$store.getters.getResults.job_data.start_date;
                        this.form.scheduled_post = '';
                        this.form.tagArr = this.$store.getters.getResults.tag_data;
                        this.form.package = '';
                        this.form.min_salary = this.$store.getters.getResults.job_data.min_salary;
                        this.form.max_salary = this.$store.getters.getResults.job_data.max_salary;
                        if(this.form.min_salary == '0' && this.form.max_salary == '0'){
                            this.form.min_salary = '';
                            this.form.max_salary = '';
                        }
                        if(this.form.role_type_id == '4'){
                            this.salaryType = 'Per Hour Rate';
                        }else{
                            this.salaryType = 'Salary';
                        }
                        if(this.form.role_type_id == this.part_time_id){
                            $('.perHourRate').css('display', 'block');
                            $('.minMaxSalary').css('display', 'none');
                        }else{
                            $('.perHourRate').css('display', 'none');
                            $('.minMaxSalary').css('display', 'block');
                        }
                        if(this.form.location_id == this.other_location_id){
                            $('.other_location_class').css('display', 'block');
                        }else{
                            $('.other_location_class').css('display', 'none');
                        }

                        if(this.form.service_categories_id == this.other_category_id){
                            $('.other_category_class').css('display', 'block');
                        }else{
                            $('.other_category_class').css('display', 'none');
                        }
                    }
                });
            },

             salaryTypeData(id){
                if(id == '4'){
                    this.salaryType = 'Per Hour Rate';
                }else{
                    this.salaryType = 'Salary';
                }
            },

            subCatg(value) {

                this.service_id = value.map(a => a.id);

            },

             postedJobs() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.logged_in_user_id,
                    },
                    api: '/api/postedJobs'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){
                            this.postedjobs = this.$store.getters.getResults.data[0].posted_jobs;
                            this.getPackageData();

                        }
                    }
                });
            },

            getPackageData() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.package_id,
                    },
                    api: '/api/package-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){

                            this.form.freejobs = this.$store.getters.getResults.data[0].option_value;
                            this.packageName = this.$store.getters.getResults.data[0].title;
                            const rem_jobs = parseInt(this.form.freejobs) - this.postedjobs;
                            if(rem_jobs < 0){
                                this.remaining_jobs = 0;
                            }else{
                                this.remaining_jobs = rem_jobs;
                            }

                        }
                    }
                });
            },




            getQualification(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/qualification'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.qualifications = this.$store.getters.getResults.data;
                    }
                });
            },
            getLocation(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/location'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.locations = this.$store.getters.getResults.data;
                        this.other_location_id = this.$store.getters.getResults.other_location_id;
                        this.locations.forEach(element => {
                            if(element.location_names){
                                element.location_names_val = element.location_names.split(',');
                                element.location_names_id = element.location_ids.split(',');
                                // location[element.location_ids.split(',')] = element.location_names.split(',')
                            }
                        });

                        this.getEditData();

                    }
                });
            },
            getPositionLevel(type){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/position-level'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.position_levels = this.$store.getters.getResults.data;
                    }
                });
            },
            getjobRoleType(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/job-role-type'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.role_types = this.$store.getters.getResults.data;
                        this.role_types = this.$store.getters.getResults.data;
                        this.role_types.forEach(element => {
                            if(element.name == 'part-time'){
                                this.part_time_id = element.id;
                            }
                        });
                        $('.perHourRate').css('display', 'none');
                        $('.minMaxSalary').css('display', 'block');
                    }
                });
            },
             getElCoins(){
           this.$store.dispatch(POST_API, {
                    data:{
                        user_id:this.logged_in_user_id
                    },
                    api:'/api/userCoins'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.coins = this.$store.getters.getResults.data;

                    }
                });
     },

      getSettings(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/expirySettings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.expiry = this.$store.getters.getResults.data[0].option_value;
                        this.coinsperjob = this.$store.getters.getResults.data1[0].option_value;


                    }
                });
            },

        },
        mounted(){
            this.logged_in_user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.package_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.package_id : '');
            this.getLocation();
            this.getDropdownData('service_categories');
            this.getjobRoleType();
            this.getEditData();
            this.postedJobs();
            this.getQualification();
            this.getPositionLevel();
            this.getElCoins();
            this.getPackageData();
            this.getSettings();
        }
    }
</script>


