<template>
        
    <div class="all_job_color">
        <div class="container zero_mrgn_lft_rgt_container">
           <div class="row common_row">
               <div class="full-page-container cnt_height">
                        
                        <div class="full-page-content-container job_list_height" data-simplebar>
                            <div class="full-page-content-inner job_list_btm">

                                <h1 class="page-title search_h1">Promotion Data</h1>

                                <div class="notify-box margin-top-15">
                                    <div class="switch-container">
                                    </div> 
                                </div>
                                
                                <div class="content" v-if="jobData.length > 0">
                                    <div class="listings-container grid-layout margin-top-35" v-for="jobDatas in jobData" :current-page="currentPage" :per-page="perPage">
                                        <!-- Job Listing -->
                                        <a href="#" @click="promoDetail((jobDatas.id),$event)" class="job-listing" style="width: 100%;">
                                        <!-- <a href="#"  class="job-listing" style="width: 100%;"> -->

                                            <!-- Job Listing Details -->
                                            <div class="job-listing-details">
                                                <!-- Logo -->
                                                <div class="job-listing-company-logo">
                                                    <img :src="jobDatas.image != null ? jobDatas.image: 'assets/images/company-logo-placeholders.png'" alt="company_logo">
                                                    <!--<h5 v-if="jobDatas.featured == 'yes'"><b>Yes</b></h5>
                                                    <h5 v-else><b>No</b></h5>-->
                                                </div>
                                                <!-- Details -->
                                                <div class="job_listing_card_inner">
                                                <div class="job-listing-description">
                                                    <h4 class="job-listing-company"><b>{{jobDatas.title}}</b></h4>
                                                    <h3 class="job-listing-title" v-if="jobDatas.start_date">
                                                        <p v-html="jobDatas.start_date"></p>
                                                    </h3>
                                                    <h3 class="job-listing-title" v-if="jobDatas.end_date">
                                                        <p v-html="jobDatas.end_date"></p>
                                                    </h3>
                                                </div>
                                                
                                                </div>
                                            </div>

                                            <!-- Job Listing Footer -->
                                           
                                        </a>	
                                        <!-- Job Listing -->
                                    </div>
                                </div>
                                <div class="content text-center" v-else>
                                    <p><b>No Record Found</b> </p>
                                </div>

                                <div class="clearfix"></div>
                                <nav class="pagination-box job_pagination">
                                    <div class="page-row-box d-inline-block">
                                        <b-form-group id="input-group-4">
                                            <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getpromotionData'>
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>75</option>
                                                        <option>100</option>
                                            </b-form-select></span>
                                        </b-form-group>
                                    </div>
                                    <div class="total-page-count d-inline-block">
                                        <ul>
                                            <li>
                                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <b-pagination @input='getpromotionData'   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                </nav>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                </div>
			</div>
        </div>
      
    </div>

</template>



<script>
    import { POST_API } from "../../store/actions.type"
    import 'vue-slider-component/theme/default.css'
    import VueSlider from 'vue-slider-component'
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {

        data() {
            return {
                job_id:'',
                jobData: '',
                category_id:'',
                daily:'',
                full_time:'',
                type:'',
                salary:'',
                error_message:'',
                categories: [],
                tagArr: [],
                value: [0, 15000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                sliderValue: 50,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                locations:[],
                location:'',
                type:'',
                role_types:[],
                category_id :this.$route.params.category_id == 'all' ||this.$route.params.category_id == '' ? '':this.$route.params.category_id,
                keyword :this.$route.params.keyword == 'all' ||this.$route.params.keyword == '' ? '' :this.$route.params.keyword,
                location_id :this.$route.params.location == 'all' ||this.$route.params.location == '' ? '':this.$route.params.location,
                role_type_id :this.$route.params.role_type == 'all' ||this.$route.params.role_type == '' ? '':this.$route.params.role_type
            }
        },
        components: {
                VueSlider,Multiselect
        },

        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            
            dateTimeAgo: function(value) {
                if (!value) return ''
                const dateTimeAgo = moment(value).fromNow();
                return dateTimeAgo
            },
            
            customFormatterOne(date) {
                return moment(date).utc().format('DD-MM-YYYY');
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },
        created() {
            this.min = 0
            this.max = 15000
            this.formatter = value => `$${value}`
        },

        methods:{   
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            clear(){
                this.category_id = '';
                this.location_id = '';
                this.type = '';
                this.keyword = '';
                this.skill = '';
                // this.value = '';
                this.daily = '';
                this.tagArr = '';
                this.role_type_id = '';
                this.full_time = '';
                this.getjobsData();
            },
            
            getDropdownData(type){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/dropdown-list-category'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                                
                    } else {
                        this.categories = this.$store.getters.getResults.data;
                    
                    }       
                }); 
            },

            promoDetail(id,event){

                if (event) {
                event.stopPropagation()
                }
                 window.location.replace('/promotion-detail/'+ (id));

            },
                
            getpromotionData() {
                return this.$store.dispatch(POST_API, {
                        data:{
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                          
                },
                    api: '/api/promotion-list-web'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {                   
                        this.jobData = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                    
                        return this.jobData;
                    }
                });
            },
           

           
        },
         mounted(){
             window.scrollTo(0,0);

            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getpromotionData();
            
            //document.title = "EL Connect - All Jobs Listing"; 


        }
    }
</script>


