<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Add Past Winner</h1>
            </div> -->
             <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Add Past Winner</h1>
            </div> -->
             <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Title" class="required">
                                        <b-form-input id="title" v-model="form.title" type="text" placeholder="Enter Title.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Month & Year" class="required">
                                        <datepicker v-model="form.month_year" :open-date="new Date()"  :format="customFormatterTwo" id="scheduled_post" class="scheduled_post " minimum-view="month"  style="border: unset;" ></datepicker>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="8" md="8">
                                    <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage="maxImage" :multiple="multiple" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.image" accept=".jpg, .png, .jpeg" limit-exceeded="15" class="image_glr">
                                    </vue-upload-multiple-image>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'past-winner-list'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import VueUploadMultipleImage from 'vue-upload-multiple-image';

export default {

    data() {
        return {
            form: {
                title: '',
                document:'',
                month_year:''
            },
            items: null,
            areas:[],
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: this.$route.params.id,
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            imagesDoc: [],
            docx: [],
            maxImage:15,
            multiple:true,
            images: [],
        }
    },
    components: {
        Datepicker,
        VueUploadMultipleImage
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'past-winner-list'},
                text: 'Past Winner',
            },{
                to:null,
                text: 'Add Past Winner'
            }];
            return item;
        },
        customFormatterTwo(date) {
            return moment(date).format('MMM YYYY');
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        title: this.form.title,
                        month_year:this.form.month_year,
                        documents: this.imagesDoc,
                    },
                    api: "/api/past-winner-store",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Record Added successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.form.image = '';
                this.images = [];
                this.imagesDoc = [];
                this.$router.push({ name: 'past-winner-list' });
            });
        },
        beforeRemove(index, done, fileList) {
            
            fileList.splice(index,1);
         
            this.imagesDoc = fileList;
        },
        editImage(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path ``
                        fileList[i].url = url;    
                    }
                }
            }
            this.imagesDoc = fileList
        },
        uploadImageSuccess(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path 
                        fileList[i].url = url;    
                    }
                }
            }
        
            this.imagesDoc = fileList

        },
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
       // document.title = "EL Connect - Admin - Database - Add Location";
    }
};

</script>
