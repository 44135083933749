<template>
    <div class="animated fadeIn">
        <div class="support-section">
            <div class="black-strip-head d-lg-flex d-sm-flex align-items-center top_black_heading_ticket dashboard-headline">
                <!-- <h3 class="mr-auto">Support</h3>            
                <h3 class="">Contact No. {{contact ? contact.option_value : ''}}</h3> -->
                <h1 class="mr-auto">Support</h1>            
                <h1 class="">Contact No. {{contact ? contact.option_value : ''}}</h1>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow">
                <b-form @submit="formSubmit" class="ticket_top_from">
                    <div class="edit-profile-box pl-0">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="6" md="6">
                                    <div class="submit-field">
                                        <h5>Ticket Type <span class="required_star">*</span></h5>
                                            <b-form-select v-model="country" id="country" disabled class="desktop_margin_bottom_zero">
                                            <option value="" disabled>Select Ticket type</option>
                                            <option :value="country.id" v-for="country in countries">{{country.title}}</option>
                                        </b-form-select>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="8" md="8">
                                    <b-form-group id="input-group-1" label="Subject" class="required">
                                        <b-form-input id="input-1" type="text" v-model="form.ticket_subject" placeholder=""></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                            <b-row>
                                <b-col lg="8" md="8">
                                    <b-form-group id="input-group-1" label="Job ID (Optional)">
                                        <b-form-input id="input-1" type="text" v-model="form.job_id" placeholder=""></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                            <b-row>
                                <b-col lg="8" md="8">
                                    <b-form-group id="input-group-5" label="Message" class="required">
                                         <b-form-textarea id="textarea-3" v-model="form.message" type="textarea" placeholder=""></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="8" md="8">
                                    <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage="maxImage" :multiple="multiple" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.document" accept=".jpg, .png, .jpeg" limit-exceeded="2" class="image_glr">
                                    </vue-upload-multiple-image>
                                </b-col>
                            </b-row>
                        </div>                    
                        <div slot="footer" class="form-btn pt-lg-3 desktop_padding_top_zero">
                            <b-button type="submit" class="button ripple-effect big desktop_margin_left_zero desktop_margin_right_zero common_btn_hover_unset">Generate Ticket</b-button>           
                        </div>
                    </div>
                </b-form>
            </div>
            <div class="tab-section pt-4" v-if="items && items.length == 0">
                <div class="page-heading pb-3">                
                    <h3 class="list-inline-item">Your Tickets</h3>
                </div>
                 <div class="inprogress-tab"> 
                    <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 record_not_found_div desktop_margin_bottom_zero">
                        <p class="desktop_margin_bottom_zero common_color_333">No record found</p>
                    </div>
                </div>
            </div>
            <div class="ticket_second_box "  v-if="items && items.length > 0">
                <h3 class="ticket_h3">Your Tickets</h3>
            
                <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow common_ticket_margin" v-if="items != null" v-for="(items,key) in items">
                    <ul class="ticket_second_box_ul new_ticket_list">
                        <li>TICKET ID - <span> {{items.ticket_unique_id}}</span></li>
                        <li>TICKET DATE - <span> {{items.created_at | moment}}</span></li>
                    </ul>
                    <div class="ticket_unread_div" v-if="items.unread_message_count > 0">
                            <h2>{{items.unread_message_count}}</h2>
                    </div>
                    <h3 class="ticket_box_second_heading">{{items.ticket_subject | capitalize}}</h3>
                    <div>
                        <div class="images" v-viewer>
                        <img v-for="src in images" :key="src" :src="src">
                        </div>
                        <b-link type="button" style="text-decoration: underline;" @click="showDoc(items.medical_document)" v-if="items.medical_document.length > 0">Click to show Attachment</b-link>
                    </div>
                    <p v-if="items.ticket_messages_val[0].message.length <=150"><span class="red-text">Issue - </span>{{ items.ticket_messages_val[0].message | capitalize }}</p>
                    <p v-else><span class="red-text" v-if="items.ticket_messages_val[0].message.length <=150">Issue - </span>{{ items.ticket_messages_val[0].message.substring(0,150)+"..." | capitalize}}</p>
                    <div class="ticket_detail_second_box_inner" v-if="items.ticket_messages_val">
                        <div class="issue_div">
                        <!-- <b-row>
                            <b-col md="2" v-for="(docs,key) in items.medical_document" :key="key">
                                <viewer :images="items">
                                    
                                <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                                <b-img :src="webUrl+'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>
                                        
                                </viewer>
                            </b-col>
                        </b-row> -->
                        </div>
                        <div class="ticket_btn_div">
                            <b-link :to="{name: 'employer-ticket-view', params: {id: encrypt(items.id)}}" class="button ripple-effect big">Ticket Detail</b-link>
                            <!-- <a href="#" class="button ripple-effect big">Ticket Detail</a> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tab Section HTML -->
            <!-- <div class="tab-section pt-4" v-if="items && items.length == 0">
                <div class="page-heading pb-3">                
                    <h3 class="list-inline-item">Your Tickets</h3>
                </div>
                 <div class="inprogress-tab"> 
                    <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3">
                        <p>No record found</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="tab-section pt-4" v-if="items && items.length > 0">
                <div class="page-heading pb-3">                
                    <h3 class="list-inline-item">Your Tickets</h3>
                </div>
                <div class="inprogress-tab"> 
                    <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3" v-if="items != null" v-for="(items,key) in items">
                        <div class="box-listing-head d-lg-flex d-sm-flex">
                            <div class="listing-head-left">
                                <p><span class="pl-0 ml-0">Ticket Id - {{items.ticket_unique_id}}</span><span>Ticket Date - {{items.created_at | moment}}</span></p>
                                <h3 class="sub-head pt-1">{{items.ticket_subject | capitalize}}</h3>
                            </div>
                           
                        </div>
                        
                        <div class="box-listing-content" v-if="items.ticket_messages_val">
                            <p v-if="items.ticket_messages_val[0].message.length <=150"><span class="red-text">Issue -</span>{{ items.ticket_messages_val[0].message | capitalize }} </p>
                            <p v-else><span class="red-text">Issue -</span>{{ items.ticket_messages_val[0].message.substring(0,150)+"..." | capitalize}}</p>
                            <b-link :to="{name: 'ViewTicketCompany', params: {id: encrypt(items.id)}}" class="btn green-bg-btn">Ticket Detail</b-link>
                        </div>
                    </div>
                   
                </div>
            </div> -->
             

            <!-- /.Tab Section HTML -->
        </div>
        <!-- <div class="contact_chatbot_section fadeInBottomContact">
            <span :style="{ backgroundImage: 'url('+webUrl+'assets/employerImages/Rectangle-312.png'+')'}">Meet Elcy our new AI chatbot!<br> Get instant assistance anytime</span>
            <b-link href="https://ai-chat-for-customer-service-d14ada.zapier.app/chat" target="_blank">
                <img :src="webUrl+'assets/employerImages/chatbot-icon.png'">
            </b-link>
        </div> -->
    </div>

</template>
<script>
  import { POST_API,GET_API, LOGOUT} from "../../../store/actions.type";
  import moment from "moment";
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  import Bus from "../../../event-bus";

  export default {

    data() {
      return {
        fields: [
                
            ],
            form: {
              job_id:'',
              ticket_subject:'',
              message:'',
              rowsPerPage:100,
              userServices:'all',
              document:''
            },
            // country:'1',
            country:'',

            countries:'',
            items:[],
            contact:null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: null,
            status: 'active',
            isBusy: false,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            to:'',
            from:'',

            webUrl: process.env.VUE_APP_URL,
            imagesDoc: [],
            docx: [],
            maxImage:2,
            multiple:true,
            images: [],
            ticket_auto_reply:false,
            ticket_id:'',
            user_type_id:''
      }
    },
    components: {
      VueUploadMultipleImage
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
           return moment(date).format('DD MMM YYYY hh:mm A')

        },
        
        encode: function(value) {
          return btoa(value);
        }

    },
    methods: {
        showDoc(doc){
            var docArr = []
            doc.forEach(element => {
                docArr.push(element.document)
            });
            this.$viewerApi({
                images: docArr,
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
         formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        job_id: this.form.job_id,
                        ticket_subject: this.form.ticket_subject,
                        reason: 'other',
                        image_type: 'pdf',
                        message: this.form.message,
                        user_id:this.user_id,
                        ticket_type:this.country,
                        device:'web',
                        documents: this.imagesDoc,
                    },
                    api: "/api/emp-generate-ticket",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.ticket_auto_reply = this.$store.getters.getResults.ticket_auto_reply == 'yes' || this.$store.getters.getResults.ticket_auto_reply == 'Yes' ? true : false;
                        this.ticket_id = this.$store.getters.getResults.ticket_id;
                        var message = 'Ticket generated successfully';
                        this.showSuccessAlert(message);
                    }
                });
        },
        // postAutoReply() {
        //     this.$store.dispatch(POST_API, {
        //             data: {
        //                 ticket_id: this.ticket_id,
        //             },
        //             api: "/api/emp-admin-ticket-auto-reply",
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 // this.showAlert();
        //             } 
        //         });
        // },
        getTicketListing() {
            return this.$store.dispatch(POST_API, {
                   data:{
                    user_id:this.user_id,
                    status: this.form.userServices,
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy:this.sortBy,
                    sortDirection:this.sortDirection,
                    sortDesc:this.sortDesc
                   },
                   api: '/api/emp-ticket-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.contact = this.$store.getters.getResults.contact;
                        // return this.items;
                    }
                });
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.form.job_id = '';
                this.form.ticket_subject = '';
                this.form.message = '';
                this.form.document = '';
                this.images = [];
                this.imagesDoc = [];
                this.getTicketListing();
                // if(this.ticket_auto_reply){
                //     this.postAutoReply();
                // }
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        uploadImageSuccess(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path 
                        fileList[i].url = url;    
                    }
                }
            }
        
            this.imagesDoc = fileList

        },
        getTicketType(){

        this.$store.dispatch(POST_API, {
         data:{
            user_type_id:this.user_type_id,
        },
         api:'/api/ticketTypeData'
     })
     .then(() => {
         if (this.$store.getters.containsErrors) {
             this.error_message = this.$store.getters.getErrors;
             
             this.$swal({
                 position: 'center',
                //  icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                 title: this.error_message,
                 showConfirmButton: false,
                 timer: 1500
             });
                     
         } else {
             this.countries = this.$store.getters.getResults.data;
             this.country = this.$store.getters.getResults.emp_enquiry_type_id ? this.$store.getters.getResults.emp_enquiry_type_id : 1 ;

         
         }       
     }); 
},
        beforeRemove(index, done, fileList) {
            
            fileList.splice(index,1);
         
            this.imagesDoc = fileList;
        },
        editImage(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path 
                        fileList[i].url = url;    
                    }
                }
            }
            this.imagesDoc = fileList
        },
    },
     mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');
        this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
        this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
        this.getTicketListing();
        this.getTicketType();
        Bus.$emit('em_fl_unread_msg_count');
    },
  }
  
</script>

