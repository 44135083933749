<template>
    <!-- <div class="dashboard-content-container" data-simplebar> -->
    <div class="dashboard-content-container common_scroll_content box_shadow_ct">
		<div class="row" >
			<div class="container my_new_container">
			<!-- Dashboard Headline -->
			<div class="dashboard-headline">
				<h1>Bookmarked Jobseeker</h1>

				<!-- Breadcrumbs -->
				<nav id="breadcrumbs" class="dark nav_on_emp">
					<ul>
						<li><a href="#">Home</a></li>
						<li>Bookmarked Jobseekers</li>
					</ul>
				</nav>
			</div>
            </div>
	</div>
			<!-- Row -->
			<div class="row">
                <div class="container my_new_container">
				<!-- Dashboard Box -->
                    <div class=" mobile_left_right_zero_pdng">
                        <div class="dashboard-box margin-top-0 common_bookmark_top_dv">

                            <!-- Headline -->
                            <div class="headline">
                                <h3 class="common_font_weight_bold"><i class="icon-material-outline-face"></i> Bookmarked Jobseeker</h3>
                            </div>

                            <div class="full-page-content-container job_list_height" data-simplebar>
                                <div class="full-page-content-inner job_list_btm desktop_padding_unset">

                                    <div class="content bookmark_padding_left_right_40" v-if="userDatas.length > 0">
                                        <div class="listings-container new_liting_containerz grid-layout  new_bookmark_list_dtl" v-for="userData in userDatas" :current-page="currentPage" :per-page="perPage">
                                            <!-- Job Listing -->
                                            <a class="new_job_listingz job-listing " style="width: 100%;">
                                            <!-- <a href="#" @click="jobseekerDetail(encrypt(userData.bookmark_to_id),$event)" class="new_job_listingz job-listing " style="width: 100%;"> -->
                                           
                                            <!-- <a href="#"  class="job-listing" style="width: 100%;"> -->

                                                <!-- Job Listing Details -->
                                                <div class="job-listing-details new_job_listing_detailz bookmark_page_list">
                                                    <!-- Logo -->
                                                    <div class="job-listing-company-logo new_job_listing_company_logo bookmark_jobseeker_img border_raduis_job">
                                                        <img v-if="userData.ft_profile_image_thumbnail || userData.ft_profile_image" :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                        <img v-else :src="userData.ft_profile_image_thumbnail ? userData.ft_profile_image_thumbnail : userData.ft_profile_image ? userData.ft_profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="user_avatar" class="common_max_width_unset">
                                                    </div>
                                                    <!-- Details -->
                                                    <div class="job_listing_card_inner new_job_listing_card_innerz">
                                                    <div class="job-listing-description">
                                                        <div class="title_and_stars bookmark_flex_big_screen">
                                                            <div class="content_jobz">
                                                                <!-- <h5>About the Employer</h5> -->
                                                                <!-- <a href="#"> -->
                                                                <h4 class="name_mrgn job-listing-company"><b>{{ userData.last_name | capitalize }} {{ userData.first_name | capitalize }}</b> 
                                                                <span class="flag_pin" v-if="userData.gender"><i class="fa fa-mars" v-if="userData.gender == 'male'"></i>
                                                                <i class="fa fa-venus" v-else></i></span>
                                                                ({{userData.dob | calculateAge}} Yrs)
                                                                <!-- <span>{{ userData.designation }}</span> -->
                                                                </h4>
                                                                
                                                                <!-- <i class="icon-material-outline-business" v-if="userData.user_address"></i> {{userData.user_address == 'Other' ? userData.other_location : userData.user_address | capitalize}} -->
                                                                <!-- </a> -->
                                                                <h3 class="job-listing-title height_unset" v-if="userData.about">
                                                                    <p v-if="userData.about.length >= 250" v-html="userData.about.substring(0,250)+'...' "></p>
                                                                    <p v-else v-html="userData.about"></p>
                                                                </h3>
                                                                <div class="">
                                                                    <a href="#" @click="jobseekerDetail(encrypt(userData.bookmark_to_id),$event)" class="button ripple-effect bookmark_profile_btn"> View Profile </a>
                                                                </div>
                                                            </div>
                                                            <div class="star_icon">
                                                                <ul class="bookmark_delete_icon">
                                                                    <b-link href="" @click="removeBookmark(userData.bookmark_to_id,$event)" class="button red ripple-effect ico new_delete_icon" title="Remove" data-tippy-placement="left">
                                                                        <!-- <i class="icon-feather-trash-2"></i> -->
                                                                        <span class = "material-icons custom">delete_outline_outlined</span>
                                                                    </b-link>
                                                                    <!-- <b-form-checkbox :value="userData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(userData.jobId,$event)" :disabled="true"></b-form-checkbox> -->
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="star_icon">
                                                            <ul class="bookmark_delete_icon">
                                                        <b-link href="" @click="removeBookmark(userData.bookmark_to_id,$event)" class="button red ripple-effect ico new_delete_icon" title="Remove" data-tippy-placement="left"> -->
                                                            <!-- <i class="icon-feather-trash-2"></i> -->
                                                            <!-- <span class = "material-icons custom">delete_outline_outlined</span>
                                                        </b-link> -->
                                                        <!-- <b-form-checkbox :value="userData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(userData.jobId,$event)" :disabled="true"></b-form-checkbox> -->
                                                    <!-- </ul>
                                                    </div> -->
                                                    </div>
                                                </div>

                                                <!-- Job Listing Footer -->
                                                <div class="job-listing-footer">
                                                    <!-- <div class="radio-star">
                                                    <b-form-group>
                                                        <b-form-checkbox :value="userData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(userData.jobId)" :disabled="true"></b-form-checkbox>
                                                    </b-form-group>
                                                    </div>
                                                    <span class="bookmark-icon" @click="bookmarkJob(userData.jobId)"></span> -->
                                                    <!-- <ul>
                                                        <li><i class="icon-material-outline-location-on"></i>{{userData.job_address == 'Other' ? userData.other_location : userData.job_address | capitalize}}</li>
                                                        <li><i class="icon-material-outline-business-center"></i>{{userData.job_type | customizeRoleType}}</li>
                                                        <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{userData.min_salary}}-${{userData.max_salary}}</li>
                                                        <li><i class="icon-material-outline-access-time"></i>{{userData.posted_date | dateTimeAgo | capitalize}}</li>
                                                    </ul> -->
                                                    <ul>
                                                        <li class="black_job_color common_word_break"><i class="icon-material-outline-email common_i_color"></i>{{userData.email}}</li>
                                                        <li class="black_job_color"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">phone_iphone</i>{{userData.country_code}}-{{userData.mobile_number | capitalize}}</li>
                                                        
                                                    </ul>
                                                </div>
                                            </a>	
                                            <!-- Job Listing -->
                                        </div>
                                    </div>
                                    <div class="content text-center" v-else>
                                        <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="pagination_padding_left_right_40">
                                        <nav class="pagination-box">
                                            <div class="page-row-box d-inline-block">
                                                <b-form-group id="input-group-4">
                                                    <span class="d-inline-block">Rows per page</span>
                                                <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input='getBookarkedJobseekers'>
                                                                <option>25</option>
                                                                <option>50</option>
                                                                <option>75</option>
                                                                <option>100</option>
                                                    </b-form-select></span>
                                                </b-form-group>
                                            </div>
                                            <div class="total-page-count d-inline-block">
                                                <ul>
                                                    <li>
                                                        <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <b-pagination @input='getBookarkedJobseekers'   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                        </nav>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
			</div>
			<!-- Row / End -->
            
		
        
	</div>
    
</template>
<script>
    import { POST_API } from "../../../store/actions.type"
    import moment from 'moment'
    import Bus from "../../../event-bus";

    export default {

         data() {
            return {
				userDatas:[],
                pager: {},
                pageOfItems: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                form:{
                    rowsPerPage:25,
                },
                webUrl: process.env.VUE_APP_URL,
                age:0,
            }
        },
		filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            
            dateTimeAgo: function(value) {
                if (!value) return ''
                const dateTimeAgo = moment(value).fromNow();
                return dateTimeAgo
            },
            calculateAge: function(value) {
                if (!value) return ''
                return moment().diff(value, 'years')
                // return  moment(value, "YYYY-MM-DD").month(0).from(moment().month(0))
            },
        },
		
        methods:{
            showAlert() {
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    }, 
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
			showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },
            
			removeBookmark(bookmark_to,event){
                if (event) {
                event.stopPropagation()
                }
                this.$swal({
                    title: 'Please Confirm',
                    text: 'Are you sure you want to remove this record from bookmark?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                })
                .then(result => {
                    if (result.value) {
                       return this.$store.dispatch(POST_API, {
                        data:{
                            bookmark_to: bookmark_to,
                            bookmark_by: this.user_id
                        },
                            api: '/api/bookmark-user'
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                                return [];
                            } else {
                                var message = this.$store.getters.getResults.message
                                this.showSuccessAlert(message);
                                this.getBookarkedJobseekers()
                            }
                        });
                    }
                })
                .catch(err => {
                })
            },

            jobseekerDetail(id,event){
                if (event) {
                event.stopPropagation()
                }
                window.location.replace('/v2/employer/jobseeker-profile/view/'+id);

            },

            getBookarkedJobseekers() {
                    return this.$store.dispatch(POST_API, {
                        data:{
							bookmark_by:this.user_id,
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                    },
                        api: '/api/employer-bookmark-jobseeker-list'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            // this.showAlert();
                            return [];
                        } else {
							this.userDatas = this.$store.getters.getResults.pageOfItems;
							this.totalRows = this.$store.getters.getResults.pager.totalItems;
							this.perPage = this.$store.getters.getResults.pager.pageSize;
							this.from = this.$store.getters.getResults.pager.from;
							this.to = this.$store.getters.getResults.pager.to;
                    		return this.userDatas;
                        }
                    });
            },
        
        },
         mounted(){
            //document.title = "EL Connect - Employer - Bookmarked Jobseekers";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            Bus.$emit('active_users');
            this.getBookarkedJobseekers();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        }
    }
</script>
