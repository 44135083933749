<template>
    <div>
           
        <!-- Titlebar
        ================================================== -->
        
        <div class="bg_img_top static_page_bg" v-bind:style="{backgroundImage: career_with_us[0].image && career_with_us[0].show_banner_image == 'yes' ? 'url('+ career_with_us[0].image +')' : 'url('+ webUrl+ 'assets/images/faq-bg.jpeg)'}">
       
        <!-- <div id="titlebar" class="gradient signup_mrgn faq_mrgn"> -->
            <div class="full_gragient">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-center faq_haed" v-html="career_with_us[0].title"></h1>
                    </div>
                </div>
            </div>
            </div>
        </div>
        

        <div class="margin-top-35"></div> 
    <!-- Page Content
        ================================================== -->
        <div class="container">
            <div class="row">
                <div class="col-xl-12 common_block common_desc">
                   <p v-html="career_with_us[0].description"></p>
                </div>
            </div>
        </div>

       

        <!-- Spacer -->
        <div class="margin-top-35"></div>
        <!-- Spacer / End-->
    </div>
</template>



<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        
        data() {
            return {
                career_with_us:[],
                showDismissibleAlert:false,
                error_message:'',
                display:true,
                link_error:'',
                webUrl: process.env.VUE_APP_URL,
            }
        },
        methods:{
            getCarrierWithUS(){
                 this.$store.dispatch(POST_API, {
                    data:{
                        token: this.email,
                    },
                    api:'/api/career-with-us'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.career_with_us = this.$store.getters.getResults.career_with_us;
                    }
            })

            }
        },
        mounted(){
            //document.title = "EL Connect - Privacy Policy"; 
            this.getCarrierWithUS();
            window.scrollTo(0, 0)
        },
    }
</script>
