<template>     
    <div>     
        
        <!-- <div class="container full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
    <div class="row">
            <div class="container my_new_container">
    <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Process Descriptions</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Process Descriptions</li>
                </ul>
            </nav>
        </div>
            <div class="row">
                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                        <b-input-group-prepend>
                            <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                            <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                        </b-input-group-prepend>
                    </div>
                </div>
            </div>
            </div>
    </div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk">
           <div class="row"> -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
				<!-- <b-table ref="datatable" show-empty striped hover responsive :items="getProcessDescriptions" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="static_page three_grid_tbl static_mobile_tbl mobile_prs"> -->
				<b-table ref="datatable" show-empty striped hover responsive :items="getProcessDescriptions" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 el_coin_four_grid_table">
                
                    <template #cell(title)="items">
                        {{items.item.title | capitalize}}
                    </template>
                    <template #cell(description)="items">
                        <p v-if="items.item.description.length > 500">
                    <span v-html="items.item.description.substring(0,500)+'..'"></span>
                    </p>
                    <p v-else>
                        <span v-html="items.item.description"></span>
                    </p>
                    </template>  
                    <template #cell(type)="items">
                        {{items.item.type | capitalize}}
                    </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item :to="{name: 'process-description-edit', params: {id: encrypt(items.item.id)}}" v-if="show('edit process description')">Edit
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getProcessDescriptions'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import permission from "../../../../../server/permission.js";

export default {

    data() {
        return {
            fields: [
                { key: 'title', label: 'Title', sortable: true },
                { key: 'description', label: 'Description', sortable: false },
                { key: 'type', label: 'Type', sortable: true },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            actions:'',

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

    },
    methods:{
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getProcessDescriptions() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/process-description-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Process Description');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
    },
    mounted()
    {
        $(".show_filter_arrow .table thead th div").contents().unwrap();
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.permissionStaff();

        //document.title = "EL Connect - Admin - Website Contents - Process Descriptions";
    }
}
</script>
