<template>
    <div class="animated fadeIn">
        <div class="support-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center top_black_heading_ticket dashboard-headline"> -->
                <!-- <div class="back_arrow_icon">
                    <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
                </div> -->
                <!-- <h3 class="mr-auto"><span class="icon-material-outline-arrow-back my_back_arrow" @click="$router.go(-1)"></span>Ticket Detail</h3>             -->
                <!-- <h1 class="mr-auto"><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Ticket Detail</h1>    -->
            <!-- </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow">
                <h3 class="common_weight_600">{{itemTicket ? itemTicket.ticket_subject:'' | capitalize}}</h3>
                <ul class="ticket_detail_view_ul">
                    <li><strong>Issue:</strong> {{itemTicket.ticket_messages_val ? itemTicket.ticket_messages_val[0].message : '' | capitalize}}</li> 
                    <li><strong>Ticket ID:</strong> {{itemTicket ? itemTicket.ticket_unique_id : ''}}</li> 
                    <li><strong>Job ID:</strong> {{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</li> 
                    <li><strong>Name:</strong> {{itemTicket.ticket_messages_val ? itemTicket.ticket_messages_val[0].ticket_sender_profile.full_name : '' | capitalize}}</li> 
                    <li><strong>Email:</strong> {{itemTicket.ticket_messages_val ? itemTicket.ticket_messages_val[0].ticket_sender_profile.email : '' }}</li> 
                    <li><strong>Ticket Status:</strong> {{itemTicket ? itemTicket.status :'' | capitalize}}</li> 
                    <li><strong>{{itemTicket ? itemTicket.created_at : '' | moment}}</strong></li>
                </ul>
                
                <b-row>
                    <b-col md="2" v-for="(docs,key) in itemTicket.medical_document" :key="key">
                        <viewer :images="items"><br>
                            
                        <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                        <b-img :src="webUrl+'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>
                                
                        </viewer>
                    </b-col>
                </b-row>
            </div>
            <div class="ticket_second_box">
                <h3 class="ticket_h3">Reply</h3>
                <VuePerfectScrollbar class="scroll-area" id="chatbox">
                          <div class="reply-listing-box border-radius-4 white-bg border-midium-light mb-3 p-3 p-lg-4" v-if="items" v-for="(items,key) in items">
                            <div class="repeate">
                                 <h4 v-if="user_id != items.ticket_sender_profile.id" class="reply_h4">Admin</h4>
                                 <!-- {{items.ticket_sender_profile.full_name}} -->
                                <h4 v-else class="reply_h4">Me</h4>
                                <span class="common_color_and_font line_height_12">{{items.created_at | moment}}</span>
                                <p class="desktop_margin_bottom_zero common_color_and_font common_word_break">{{items.message}}</p>
                          </div>
                          <div>
                            <div class="images" v-viewer>
                                <img v-for="src in images" :key="src" :src="src">
                            </div>
                            <b-link type="button" style="text-decoration: underline;" @click="showDocChat(items.medical_document)" v-if="items.medical_document.length > 0">Click to show Attachment</b-link>
                          </div>
                          <!-- <b-row>
                            <b-col md="2" v-for="(docs,key) in items.medical_document" :key="key">
                                <viewer :images="items"><br>
                                    
                                <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                                <b-img :src="webUrl+ 'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>
                                        
                                </viewer>
                            </b-col>
                          </b-row> -->
                      </div>
                      </VuePerfectScrollbar>
                <!-- <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow">
                    <h3 class="common_weight_600">Me</h3>
                    <ul class="ticket_detail_view_ul">
                        <li>09 Dec, 2022 12:56 PM</li>
                        <li>dfgg</li>
                    </ul>
                </div> -->
            </div>
            <!-- <div class="post_rply_div ticket_second_box">
                <h3 class="ticket_h3">Post a reply</h3>
                 <b-form-textarea id="textarea-3" v-model="form.message" type="textarea" placeholder="Message"></b-form-textarea>
                <div slot="footer" class="form-btn pt-lg-3 desktop_padding_top_zero">
                    <b-button type="submit" @click="formSubmit" class="button ripple-effect big desktop_margin_bottom_zero desktop_margin_left_zero desktop_margin_right_zero common_btn_hover_unset">Post</b-button>           
                </div>
            </div> -->
             <div class="post-repy-form ticket_second_box" >
                <h3 class="ticket_h3">Post a reply</h3>
                    <b-form @submit="formSubmit">
                    <b-form-group id="input-group-5" label="" class="required textare_pdng">
                        <b-form-textarea id="textarea-3" v-model="form.message" type="textarea" placeholder="Message"></b-form-textarea>
                    </b-form-group>
                    <!-- <b-row> -->
                        <!-- <b-col lg="12" md="12" class="textare_pdng"> -->
                        <div class="textare_pdng">
                            <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage="maxImage" :multiple="multiple" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.document" accept=".jpg, .png, .jpeg" limit-exceeded="2" class="image_glr">
                            </vue-upload-multiple-image>
                        </div>
                        <!-- </b-col> -->
                    <!-- </b-row> -->
                    <div slot="footer" class="form-btn textare_pdng">
                        <b-button type="submit" variant="success" class="common_btn_hover_unset button_min_width_130 button ripple-effect desktop_margin_right_zero desktop_margin_left_zero">Post</b-button>           
                    </div>
                </b-form>
            </div>

            <!-- Tab Section HTML -->
            <!-- <div class="tab-section pt-4" v-if="items && items.length == 0">
                <div class="page-heading pb-3">                
                    <h3 class="list-inline-item">Your Tickets</h3>
                </div>
                 <div class="inprogress-tab"> 
                    <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3">
                        <p>No record found</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="tab-section pt-4" v-if="items && items.length > 0">
                <div class="page-heading pb-3">                
                    <h3 class="list-inline-item">Your Tickets</h3>
                </div>
                <div class="inprogress-tab"> 
                    <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3" v-if="items != null" v-for="(items,key) in items">
                        <div class="box-listing-head d-lg-flex d-sm-flex">
                            <div class="listing-head-left">
                                <p><span class="pl-0 ml-0">Ticket Id - {{items.ticket_unique_id}}</span><span>Ticket Date - {{items.created_at | moment}}</span></p>
                                <h3 class="sub-head pt-1">{{items.ticket_subject | capitalize}}</h3>
                            </div>
                           
                        </div>
                        
                        <div class="box-listing-content" v-if="items.ticket_messages_val">
                            <p v-if="items.ticket_messages_val[0].message.length <=150"><span class="red-text">Issue -</span>{{ items.ticket_messages_val[0].message | capitalize }} </p>
                            <p v-else><span class="red-text">Issue -</span>{{ items.ticket_messages_val[0].message.substring(0,150)+"..." | capitalize}}</p>
                            <b-link :to="{name: 'ViewTicketCompany', params: {id: encrypt(items.id)}}" class="btn green-bg-btn">Ticket Detail</b-link>
                        </div>
                    </div>
                   
                </div>
            </div> -->
             

            <!-- /.Tab Section HTML -->
        </div>
    </div>

</template>
<script>
  import { POST_API,GET_API, LOGOUT} from "../../../store/actions.type";
  import moment from "moment";
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  import Bus from "../../../event-bus";

  export default {
    component:{
      VuePerfectScrollbar,
      VueUploadMultipleImage
    },
    data() {
      return {
        fields: [
                
            ],
            form: {
              job_id:'',
              ticket_subject:'',
              message:'',
              rowsPerPage:100,
              userServices:'all',
              document:'',
            },
            items:null,
            contact:null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: null,
            status: 'active',
            isBusy: false,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            to:'',
            from:'',

            webUrl: process.env.VUE_APP_URL,
            id: atob(this.$route.params.id),
            itemTicket:[],
            imagesDoc: [],
            docx: [],
            maxImage:2,
            multiple:true,
            images: [],

      }
    },
    components: {
      VuePerfectScrollbar,
      VueUploadMultipleImage
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
           return moment(date).format('DD MMM YYYY hh:mm A')

        },
    },
    
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'jobseeker-ticket-list'},
                text: 'Contact Support',
            },{
                to:null,
                text: 'Ticket Detail'
            }];
            return item;
        },
        showDocChat(doc){
            var docArr = []
            doc.forEach(element => {
                docArr.push(element.document)
            });
            this.$viewerApi({
                images: docArr,
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        // scrollToEnd: function() {
        //     this.scollRepltDiv=true;
        //     setTimeout(() => {
        //         var myDiv = document.getElementById('chatbox');
        //         myDiv.scrollTop = myDiv.scrollHeight;
        //     }, 200);
        // },
         formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        ticket_id: this.id,
                        message: this.form.message,
                        user_id: this.user_id,
                        documents: this.imagesDoc,
                    },
                    api: "/api/emp-post-ticket-reply",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Reply saved successfully';
                        this.form.message='';
                        this.form.document = '';
                        this.images = [];
                        this.imagesDoc = [];
                        this.showSuccessAlert(message);
                    }
                });
        },
        getTicketListing() {
            
            return this.$store.dispatch(POST_API, {
                   data:{
                    signupType:'web',
                    status: this.form.userServices,
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy:this.sortBy,
                    sortDirection:this.sortDirection,
                    sortDesc:this.sortDesc,
                    ticket_id: this.id,
                    user_id:this.user_id
                   },
                   api: '/api/emp-view-ticket-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.ticketMessage;
                        this.itemTicket = this.$store.getters.getResults.data.ticketDetail;
                        // this.items = this.$store.getters.getResults.pageOfItems;
                        // this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        // this.perPage = this.$store.getters.getResults.pager.pageSize;
                        // this.from = this.$store.getters.getResults.pager.from;
                        // this.to = this.$store.getters.getResults.pager.to;
                        // this.contact = this.$store.getters.getResults.contact;

                        Bus.$emit('em_fl_unread_msg_count');
                        
                        return this.items;
                    }
                });
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                // this.form.job_id = '';
                // this.form.ticket_subject = '';
                this.form.message = '';
                this.images = [];
                this.form.document = '';
                this.imagesDoc = [];
                this.getTicketListing();
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        uploadImageSuccess(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path 
                        fileList[i].url = url;    
                    }
                }
            }
        
            this.imagesDoc = fileList

        },
        beforeRemove(index, done, fileList) {
            
            fileList.splice(index,1);
         
            this.imagesDoc = fileList;
        },
        editImage(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path 
                        fileList[i].url = url;    
                    }
                }
            }
            this.imagesDoc = fileList
        },
    },
     mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
        this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
        this.getTicketListing();
        Bus.$emit('em_fl_unread_msg_count');
    },
  }
  
</script>

