
import jobseekers2 from '../components/website/jobseekers2/jobseekers.vue';
export default [
   
  /**New Jbseekers Route Start**/
  {
    path: '/',
    name: 'jobseekers2',
    component: jobseekers2,
    meta: { requiresAuth: false, hideForAuth:true , showFor:'admin'}
  },
  /**New Jbseekers Route End**/
]
