<template>
  <div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->
    <div class="">
      <div class="dashboard-headline">
        <h1>Edit Profile</h1>

        <!-- Breadcrumbs -->
        <nav id="breadcrumbs" class="dark nav_on_emp">
          <ul>
            <li><a href="#">Home</a></li>
            <li>Profile</li>
          </ul>
        </nav>
      </div>
      <!-- <div class="notification notice" v-if="status == 'pending'">
            <p><span class="icon-feather-info"></span> Account Status {{status}}</p>
            <a class="close"></a>
        </div> -->
      <div class="notification warning" v-if="status == 'pending'">
        <p><span class="icon-feather-info"></span> Your Temp. Staff Status is pending approval.</p>
        <a class="close"></a>
      </div>
      <div
        class="notification warning"
        v-if="status == 'incomplete' && validErr.length > 0"
        style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
      >
        <p>
          <span class="icon-feather-info"></span> Account Status {{ status }}. To complete profile:
        </p>
        <ul class="notification_box" v-for="(err, key) in validErr">
          <li>{{ key + 1 }}. {{ err }}</li>
          <!-- <li> Save changes to profile </li> -->
        </ul>
        <a class="close"></a>
      </div>
      <div
        class="notification warning new_notification_warning"
        v-if="this.account_verification_status == 'rejected'"
        style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
      >
        <!-- <h3 v-if="this.form.pending_msg == true && this.account_verification_status != 'rejected'"  style="color:blue">Your Daily Paid Status is pending approval.</h3> -->
        <h3 v-if="this.account_verification_status == 'rejected'" style="color: red">
          Temp. Staff Status: Rejected
        </h3>
        <h3 v-if="this.account_verification_status == 'rejected'" style="color: red">
          Rejection reason: {{ rejected_comment }}
        </h3>
      </div>
      <div class="row second_indiv_step_wrap">
        <b-form
          method="post"
          id="register-account-form"
          class="emp_frm_mobile nw_frm desktop_margin_top_zero"
        >
          <!-- <p id="error"></p> -->
          <!-- Dashboard Box -->
          <div class="col-xl-12">
            <div class="dashboard-box margin-top-0">
              <!-- Headline -->
              <div class="headline">
                <h3>
                  <!-- <i class="icon-material-outline-face"></i>  -->
                  My Account ({{ form.user_type_id_val }})
                </h3>
              </div>

              <!-- <div class="content with-padding padding-bottom-0"> -->
              <div class="content with-padding">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div
                        class="col-xl-4 img_mobile big_screen_bottom_28 mobile_margin_bottom_28 desktop_margin_bottom_zero submit-field max_width_and_flex_769 mobile_max_width_and_flex big_screen_max_width_and_flex"
                      >
                        <div class="">
                          <!-- <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">

                                                        <div class="upload-button"></div>
                                                            <input class="file-upload" type="file" @change="onprofileChange" accept="image/*"/>
                                                    </div> -->
                          <h5>Company Logo <span class="required_star">*</span></h5>
                          <!--<div class="single-image-upload emp_img_one">
                                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.profile_image"></b-form-file>
                                                        <span>
                                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/company-logo-placeholders.png'" :disabled="true" style="max-width: 300px;"></b-img>
                                                        </span>
                                                    </div>-->

                          <b-form-group
                            id="company_logo"
                            class="error_submit_field employer_company_logo top_banner_jobs_full new_update_banner profile_top_div"
                            :class="
                              (this.form.mobile_verified_check == true &&
                                this.form.email_verify == 'yes') ||
                              (this.form.mobile_verified_check == true &&
                                this.form.email_verify == 'no') ||
                              (this.form.mobile_verified_check == false &&
                                this.form.email_verify == 'yes')
                                ? 'new_profile_top_div_with_text'
                                : 'new_profile_top_div_without_note_text'
                            "
                          >
                            <div class="single-image-upload">
                              <b-form-file
                                class="customUpload form-control-file"
                                name="Image"
                                id=""
                                accept="image/*"
                                @change="onprofileChange"
                                ref="image"
                                v-model="form.profile_image"
                              ></b-form-file>
                              <span>
                                <b-img
                                  v-if="form.selectedImage"
                                  :src="
                                    form.selectedImage.image
                                      ? form.selectedImage.image
                                      : webUrl + 'assets/images/company-logo-placeholders.png'
                                  "
                                ></b-img>
                                <!--<b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                              </span>
                            </div>
                            <!-- <div class="textare_pdng">
                                            <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage=1 :multiple="false" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.image" accept=".jpg, .png, .jpeg" limit-exceeded="5" class="customize_delete_icon_file_upload image_glr new_post_image">
                                            </vue-upload-multiple-image>
                                        </div> -->
                          </b-form-group>
                          <!-- <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage="maxImage" :multiple="multiple" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.images" accept=".jpg, .png, .jpeg" limit-exceeded="5" class="customize_delete_icon_file_upload image_glr new_post_image">
                          </vue-upload-multiple-image> -->
                          <div class="show_span">
                            <span class="error_x_white_company_logo"
                              >Please Upload Company Logo
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-8">
                        <div class="row">
                          <!-- <div class="col-xl-6">
                                                        <div class="submit-field error_submit_field">
                                                            <h5>First Name <span class="required_star">*</span></h5>
                                                            <input type="text" v-model="form.first_name" class="with-border form-control required-field" id="first_name">
                                                            <input type="hidden" v-model="form.user_id" class="with-border" >
                                                        </div>
                                                            <span class="error_x_white">Please enter First Name </span>
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="submit-field error_submit_field">
                                                            <h5>Last Name <span class="required_star">*</span></h5>
                                                            <input type="text" v-model="form.last_name" class="with-border form-control required-field" id="last_name">
                                                        </div>
                                                            <span class="error_x_white">Please enter Last Name </span>
                                                    </div> -->
                          <div class="col-xl-12">
                            <div class="submit-field error_submit_field">
                              <h5>Full Name<span class="required_star">*</span></h5>
                              <input
                                type="text"
                                v-model="form.full_name"
                                class="with-border form-control required-field"
                                id="last_name"
                              />
                              <input type="hidden" v-model="form.user_id" class="with-border" />
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white">Please enter Full Name </span>
                            <!-- </div> -->
                          </div>
                          <div
                            class="col-xl-6 submit-field mobile_view_numbr mobile_email big_screen_bottom_28 mobile_margin_bottom_28"
                          >
                            <h5>
                              Mobile Number
                              <span class="required_star">*</span>
                              <!-- <span class="verify_badge" v-if="this.form.mobile_verified_check == true"><i class="fa fa-check"></i>Verified</span> -->
                              <div
                                class="verified-badge-with-title custom_verify_badge_em"
                                v-if="this.form.mobile_verified_check == true"
                              >
                                Verified
                              </div>
                              <div
                                class="tooltip_icon"
                                @click="mobileToolTipNumber"
                                :class="
                                  this.form.mobile_verified_check == true
                                    ? 'email_tooltip_with_verified'
                                    : 'email_tooltip_with_no_verified'
                                "
                                v-if="this.form.mobile_verified_check == true"
                              >
                                <span class="material-icons-outlined info_tooltip_icon"
                                  >info_outlined</span
                                >
                                <span class="tooltip_text tooltip_txt_mobile_number"
                                  >Note: Contact support to update Mobile number</span
                                >
                              </div>
                            </h5>
                            <div class="input-group mb-3 web_bottom_zero error_submit_field">
                              <input
                                type="text"
                                v-model="form.country_code"
                                class="with-border mobile_input_prf form-control required-field country_code_txt"
                                readonly="readonly"
                              />
                              <input
                                type="text"
                                v-model="form.mobile"
                                class="with-border mobile_input_prf form-control required-field"
                                id="contact"
                                @keyup.enter="sendOTP"
                              />
                              <div
                                class="verify-btn"
                                v-if="this.form.mobile_verified_check == false"
                              >
                                <button
                                  @click="sendOTP"
                                  class="btn btn-outline-primary verify_btn_jb otp_btn_hgt"
                                  type="button"
                                >
                                  Send OTP
                                </button>
                              </div>
                            </div>
                            <span
                              class="error_x_white desktop_margin_bottom_zero mobile_margin_bottom_28"
                              >Please enter Mobile Number
                            </span>
                          </div>
                          <!-- <div class="show_span">
                                                        <span class="error_x_white">Please enter Contact Number </span>
                                                    </div> -->
                          <div class="col-xl-6">
                            <div
                              class="submit-field mobile_margin_bottom_28 mobile_view_numbr mobile_email big_screen_bottom_28 error_submit_field"
                            >
                              <h5>
                                Email Address <span class="required_star">*</span>
                                <!-- <span class="verify_badge" v-if="this.form.email_verify == 'yes'"><i class="fa fa-check"></i>Verified</span> -->
                                <div
                                  class="verified-badge-with-title custom_verify_badge_em"
                                  v-if="this.form.email_verify == 'yes'"
                                >
                                  Verified
                                </div>
                                <div
                                  class="tooltip_icon"
                                  @click="mobileToolTipEmail"
                                  :class="
                                    this.form.email_verify == 'yes'
                                      ? 'email_tooltip_with_verified'
                                      : 'email_tooltip_with_no_verified'
                                  "
                                  v-if="this.form.email_verify == 'yes'"
                                >
                                  <span class="material-icons-outlined info_tooltip_icon"
                                    >info_outlined</span
                                  >
                                  <span class="tooltip_text tooltip_txt_email"
                                    >Note: Contact support to update Email</span
                                  >
                                </div>
                              </h5>
                              <div class="input-group mb-3 web_bottom_zero error_submit_field">
                                <input
                                  type="text"
                                  v-model="form.email"
                                  class="with-border mobile_input_prf form-control required-field"
                                  id="email"
                                  disable
                                />
                                <div
                                  class="verify-btn"
                                  v-if="this.form.email_verify == 'no' && this.showVerifyEmail"
                                >
                                  <button
                                    class="btn btn-outline-primary verify_btn_jb otp_btn_hgt"
                                    @click="emailVerify"
                                    type="button"
                                  >
                                    Verify Email
                                  </button>
                                </div>
                                <!-- <h5 class="web_bottom_zero" v-if="countDownEmail > 0">Didn't receive email? Resend email in <span style="color: #4c8bf5 !important;"> {{ countDownEmail }} seconds</span></h5> -->
                              </div>
                              <h5 class="web_bottom_zero margin_top_13" v-if="countDownEmail > 0">
                                Didn't receive email? Resend email in
                                <span style="color: #4c8bf5 !important">
                                  {{ countDownEmail }} seconds</span
                                >
                              </h5>
                              <span class="error_x_white desktop_margin_bottom_zero"
                                >Please enter Email
                              </span>
                            </div>
                            <!-- <div class="show_span">
                                                                    <span class="error_x_white">Please enter Email </span>
                                                                </div> -->
                          </div>
                          <!-- <div class="col-xl-12 note_txt" v-if="this.form.mobile_verified_check == true && this.form.email_verify == 'no'">
                                                        <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number</b></span>
                                                    </div>
                                                    <div class="col-xl-12 note_txt" v-else-if="this.form.mobile_verified_check == false && this.form.email_verify == 'yes'">
                                                        <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Email</b></span>
                                                    </div>
                                                    <div class="col-xl-12 note_txt" v-else-if="this.form.mobile_verified_check == true && this.form.email_verify == 'yes'">
                                                        <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number or Email</b></span>
                                                    </div> -->
                          <div class="col-xl-6">
                            <div class="submit-field error_submit_field">
                              <h5>Company Name <span class="required_star">*</span></h5>
                              <b-form-input
                                type="text"
                                v-model="form.company_name"
                                id="company_name"
                                class="input-text with-border form-control required-field web_bottom_zero"
                                name="co_name"
                                placeholder=""
                                value=""
                                :disabled="
                                  account_verification_status == 'completed' ||
                                  account_verification_status == 'pending'
                                "
                              />
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white">Please enter Company Name </span>
                            <!-- </div> -->
                          </div>
                          <div class="col-xl-6">
                            <div
                              class="submit-field error_submit_field mobile_tab_big_screen_margin"
                            >
                              <h5>UEN <span class="required_star">*</span></h5>
                              <b-form-input
                                type="text"
                                v-model="form.company_reg_no"
                                id="registration_number"
                                class="input-text with-border form-control required-field uen"
                                name="company_reg_no"
                                placeholder=""
                                value=""
                                :disabled="
                                  account_verification_status == 'completed' ||
                                  account_verification_status == 'pending'
                                "
                              />
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white">Please enter UEN </span>
                            <span class="error_x_white_uen error red_span"
                              >UEN in use - Please contact your own company admin or our support for
                              help
                            </span>

                            <!-- </div> -->
                          </div>
                          <!-- <div class="col-xl-6">
                                                        <div class="submit-field desktop_margin_bottom_zero mobile_margin_bottom_28 big_screen_bottom_28">
                                                            <h5>Account Type <span class="required_star">*</span></h5>
                                                            <input type="text" v-model="form.user_type_id_val" readonly="readonly" class="desktop_margin_bottom_zero with-border form-control">
                                                        </div>

                                                    </div> -->
                          <div class="col-xl-6">
                            <div class="submit-field error_submit_field">
                              <h5>Company Industry <span class="required_star">*</span></h5>
                              <b-form-select
                                v-model="form.skill"
                                id="industry"
                                class="web_bottom_zero"
                                @change="showCategory(form.skill)"
                              >
                                <option value="" disabled>Select Company Industry</option>
                                <option :value="skillsArrs.id" v-for="skillsArrs in skillsArr">
                                  {{ skillsArrs.name }}
                                </option>
                              </b-form-select>
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white">Please enter Industry </span>
                            <!-- </div> -->
                          </div>
                          <div class="col-xl-6 cat_inner_divs other_category_class">
                            <div class="submit-field" v-if="form.skill == other_category_id">
                              <h5>Specify Industry</h5>
                              <b-form-input
                                type="text"
                                v-model="form.specified_company_industry"
                                class="input-text with-border"
                                name="company_reg_no"
                                placeholder="Please Specify Industry"
                                value=""
                              />
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="submit-field web_bottom_zero error_submit_field">
                              <h5>Gender <span class="required_star">*</span></h5>
                              <b-form-select
                                data-size="7"
                                v-model="form.gender"
                                class="custom-select web_bottom_zero"
                                id="gender"
                              >
                                <option value="" disabled>Select</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                              </b-form-select>
                            </div>
                            <!-- <div class="show_span"> -->
                            <span class="error_x_white desktop_margin_bottom_zero"
                              >Please enter Gender
                            </span>
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="hundered_width">
                        <b-input-group>
                          <div class="col-xl-4 rm"></div>

                          <!-- <div class="col-xl-6">
                                                        <div class="submit-field mobile_view_numbr mobile_email" v-if="this.form.mobile_verified_check == false">
                                                            <h5>Verification Code</h5>
                                                            <div class="input-group mb-3">
                                                            <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" >
                                                            <div class="input-group-append" v-if="this.form.verify_otp_button == true">
                                                                <button class="btn btn-outline-primary" @click="mobileVerify" type="button">Verify OTP</button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="dashboard-box">
              <div class="headline"><h3>Optional Details</h3></div>
              <!-- Headline -->
              <!-- commented on 14-12-23
                            <div class="headline edit_profile_headline">
                                <h3 class="apply_sec_h3"><i class="icon-material-outline-business-center"></i> Company Profile</h3>
                                <div class="apply_div_section">
                                    <button class="btn btn-outline-primary desktop_margin_left_zero apply_profile_sing_btn" v-if="(this.form.apply_for_dp == false && this.form.pending_msg == false) && this.account_verification_status != 'rejected'" @click="New" type="button" id="apply_for_dp_button">Apply for Daily Paid Posting</button>
                                    <b-link @click="showApplyDPModal" v-if="(this.form.apply_for_dp == false && this.form.pending_msg == false) && this.account_verification_status != 'rejected'" style="color: #4c8bf5 !important;" class=""><i class="material-icons-outlined info_profile_icon">info_outlined</i></b-link>

                                    <h3 v-if="this.form.pending_msg == true && this.account_verification_status != 'rejected'"  style="color:blue">Daily Paid Status: Pending</h3>
                                    <h3 v-if="this.account_verification_status == 'rejected'"  style="color:red;margin-top: 17px;">Daily Paid Status: Rejected</h3>
                                    <h3 v-if="this.account_verification_status == 'rejected'"  style="color:red">Rejected reason : {{rejected_comment}}</h3>

                                    <button class="btn btn-outline-primary" v-if="this.form.apply_for_dp == false && this.form.pending_msg == false && this.account_verification_status == 'rejected'" @click="New" type="button" id="apply_for_dp_button" style="
                                    margin-top: 12px;">Re-apply for Daily Paid Posting</button>
                                    <ul v-if="this.form.show_required_field_apply_dp_msg" class="notification_box profile_notification_box" style="color: rgb(234, 20, 81);">
                                    <li v-if="!this.showACRA || this.billing_address.length == 0">* Additional Requirements</li>
                                    <li v-if="!this.showACRA"> {{ dailyerr1 }}. Upload Company ACRA Document</li>
                                    <li v-if="this.billing_address.length == 0"> {{ dailyerr2 }}. Add Billing Address</li>
                                    </ul>
                                </div>
                            </div>
                            commented on 14-12-23 -->
              <div class="content">
                <ul class="fields-ul">
                  <li class="common_padding_30 custom_pagination_border_radius">
                    <div class="row">
                      <div class="col-xl-12 submit-field">
                        <h5 class="img_txt">
                          Company Banner Image
                          <span>(Recommended Banner size should be 952*280.)</span>
                        </h5>

                        <b-form-group
                          id="link-group"
                          class="top_banner_jobs_full new_update_banner"
                        >
                          <div class="single-image-upload">
                            <b-form-file
                              class="customUpload form-control-file"
                              name="BannerImage"
                              accept="image/*"
                              @change="onBannerChange"
                              ref="image"
                              v-model="form.banner_image"
                            ></b-form-file>
                            <span>
                              <b-img
                                v-if="form.selectedBannerImage.image"
                                :src="form.selectedBannerImage.image"
                              ></b-img>
                              <!--<b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                              <i v-else class="fa fa-camera default_img"></i>
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <div class="row">
                          <!-- <div class="col-xl-3"> -->
                          <!-- commented on 14-12-23
                                                <div class="col-xl-4">
                                                    <div class="submit-field web_bottom_zero big_screen_bottom_28 mobile_margin_bottom_28">
                                                        <h5>Company ACRA (Bizfile) (Maximum file size 10 MB. PDF Only) <span class="required_star"></span></h5>
                                                        <div class="attachments-container margin-top-0 margin-bottom-0">
                                                        <div class="" v-if="showACRA">
                                                            <div class="file_name_full_div">
                                                                <span class="file_name_span">File Name </span>
                                                                <span class="file_name_clr file_first_last">{{form.last_name | capitalize}} {{form.first_name | capitalize}} - <span class="file_name_clr">ACRA.pdf</span>
                                                                <button v-if="showACRA && this.form.apply_for_dp == false" type="button" class="remove-attachment remove_cv_btn" @click="removeACRA($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                                                </span>
                                                            </div>
                                                            <span>Uploaded </span>
                                                            <span class="file_name_clr">{{acra_detail}}</span>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                        <div class="uploadButton margin-top-0 web_bottom_zero" v-if="!showACRA">
                                                            <input class="uploadButton-input" type="file" accept=".pdf" @change="onAcraChange" id="upload"/>
                                                            <label class="uploadButton-button ripple-effect" for="upload"  v-if="!showACRA">Upload</label>
                                                            <span class="" v-if="!showACRA">Maximum file size: 10 MB</span>
                                                        </div>
                                                        <div class="multiple_view_btn" v-if="showACRA && this.form.apply_for_dp == false">
                                                                <button v-if="showACRA && this.form.apply_for_dp == false" type="button" class="uploadButton-button ripple-effect" data-tippy-placement="top" @click="viewACRA($event)">View</button>

                                                                <div class="uploadButton margin-top-0 web_bottom_zero" v-if="showACRA && this.form.apply_for_dp == false">
                                                                    <input class="uploadButton-input" type="file" accept=".pdf" @change="onAcraChange" id="upload"/>
                                                                    <label class="uploadButton-button ripple-effect replace_btn" for="upload"  v-if="showACRA">Replace</label>
                                                                </div>
                                                        </div>
                                                        <p class="uploadButton-file-name desktop_margin_bottom_zero" v-if="showACRA && this.form.apply_for_dp == false">Maximum file size: 10 MB</p>
                                                        <span class="uploadButton-file-name unset_min_height" id="acra" style="color: blue;font-weight: bold;text-decoration: underline;"></span>
                                                        <button v-if="showACRA && this.form.apply_for_dp == true" type="button" class="uploadButton-button ripple-effect single_view_btn" data-tippy-placement="top" @click="viewACRA($event)">View</button>
                                                    </div>
                                                </div>
                                                commented on 14-12-23 -->
                          <!-- <div class="col-xl-4">
                                                <div class="submit-field error_submit_field">
                                                        <h5>Company Industry</h5>
                                                            <b-form-select v-model="form.skill" id="industry" class="web_bottom_zero" @change="showCategory(form.skill)">
                                                                <option value="" disabled>Select Company Industry</option>
                                                                <option :value="skillsArrs.id" v-for="skillsArrs in skillsArr">{{skillsArrs.name}}</option>
                                                            </b-form-select>
                                                    </div>
                                                            <span class="error_x_white">Please enter Industry </span>
                                                </div>
                                                <div class="col-xl-4 cat_inner_divs other_category_class">
                                                    <div class="submit-field" v-if="form.skill == other_category_id">
                                                        <h5>Specify Industry</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="form.specified_company_industry"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder="Please Specify Industry"
                                                            value=""
                                                        />
                                                    </div>
                                        </div> -->
                          <!-- <div class="col-xl-6">
                                                        <div class="submit-field mobile_view_numbr mobile_email" >
                                                            <h5>Company Telephone No. <span class="required_star">*</span></h5>
                                                            <div class="input-group mb-3">
                                                            <input type="text" v-model="form.company_contact_number" class="with-border mobile_input_prf form-control required-field" id="company_number">
                                                            </div>
                                                        </div>
                                                        <div class="show_span">
                                                            <span class="error_x_white">Please enter Company Telephone Number </span>
                                                        </div>
                                                    </div>-->
                          <div class="col-xl-12">
                            <div class="submit-field">
                              <h5>Company Office Address</h5>
                              <b-form-input
                                type="text"
                                v-model="form.office_address"
                                id="address"
                                class="input-text with-border"
                                name="office_address"
                                placeholder=""
                                value=""
                              />
                            </div>
                            <div class="show_span">
                              <span class="error_x_white">Please enter Office Address </span>
                            </div>
                          </div>

                          <div class="col-xl-12">
                            <div class="submit-field">
                              <h5>Company Short Description (Max 100 characters are allowed)</h5>
                              <input
                                type="text"
                                class="with-border"
                                v-model="form.sortdesc"
                                maxlength="100"
                              />
                            </div>
                          </div>

                          <div class="col-xl-12">
                            <div class="submit-field">
                              <h5>Company Overview</h5>
                              <ckeditor
                                v-model="form.overview"
                                :config="editorConfig"
                                id="overview"
                                class="employer_editor"
                              ></ckeditor>
                              <!-- <textarea type="text" class="with-border" value="" v-model="form.overview"></textarea> -->
                            </div>
                            <div class="show_span">
                              <span class="error_x_white">Please enter Overview </span>
                            </div>
                          </div>
                         <!--  <div class="col-xl-12">
                            <div class="submit-field web_bottom_zero">
                              <h5>Referred by Sales Staff</h5>
                              <multiselect
                                v-model="form.staffName"
                                id="ajax"
                                :custom-label="customLabel"
                                track-by="id"
                                placeholder="Type to search staff name"
                                open-direction="bottom"
                                :options="allStaff"
                                :searchable="true"
                                :clear-on-select="true"
                                :close-on-select="true"
                                :allow-empty="true"
                                :multiple="false"
                                :disabled="
                                  account_verification_status == 'completed' ||
                                  account_verification_status == 'pending'
                                "
                              >
                                <span slot="noResult">No Staff Found.</span>
                              </multiselect>
                            </div>
                          </div> -->
                          <!-- <div class="col-xl-12">
                                                    <div class="submit-field">
                                                        <h5>Certificates</h5>
                                                        <div class="edit-images-listing inline_cnt">
                                                        <div class="profile-content-detail" >
                                                            <ul  v-if='docs.length != 0'>
                                                                <li v-for="doc in docs">
                                                                    <b-button @click="deleteImage(doc.id)" class="cross_btn">X</b-button>
                                                                    <b-img v-if="doc.image_type == 'image'" :src="doc.document" alt="certification img"></b-img>
                                                                    <b-img v-if="doc.image_type == 'pdf'" :src="webUrl+ 'assets/images/pdf.png'"></b-img>
                                                                    <iframe v-if="doc.image_type == 'pdf'" :src="doc.document"></iframe>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </div>

                                                        <vue-upload-multiple-image @upload-success="uploadImageSuccess" @before-remove="beforeRemove" @edit-image="editImage" :data-images="images" dragText="Browse" browseText="Select Certificate" primaryText="" markIsPrimaryText="" popupText="Image"
                                                                            v-model="form.certificate" accept=".jpg, .png, .jpeg, .pdf">
                                                        </vue-upload-multiple-image>
                                                    </div>
                                                </div> -->
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <b-container class="single_accordian">
            <!-- <br> -->
            <div>
              <div class="faq-content-box clearfix">
                <div>
                  <div class="accordion-listing-box coloured_icon">
                    <b-card no-body>
                      <b-card-header header-tag="header" role="tab">
                        <b-button v-b-toggle="'accordion-' + key" class="m-1" variant="info">
                          <div class="dashboard-box">
                            <div class="headline">
                              <h3>
                                <i class="icon-line-awesome-bullhorn"></i>Linked Social Account
                                (Increase your connectivity or get others to know you better)
                              </h3>
                            </div>
                          </div>
                        </b-button>
                      </b-card-header>
                      <b-collapse :id="'accordion-' + key" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <div class="content with-padding">
                            <div class="row">
                              <div class="col-xl-8">
                                <div class="submit-field">
                                  <h5>
                                    <span class="icon-feather-globe"></span>Company Website URL Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.website"
                                    id="website"
                                    class="input-text with-border"
                                    name="website"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="col-xl-8">
                                <div class="submit-field">
                                  <h5>
                                    <span class="icon-brand-facebook-f"></span>Company Facebook URL
                                    Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.fb"
                                    id="fb"
                                    class="input-text with-border"
                                    name="fb"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <div class="col-xl-8">
                                <div class="submit-field">
                                  <h5>
                                    <span class="icon-line-awesome-twitter-square"></span>Company
                                    Twitter URL Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.twitter"
                                    id="twitter"
                                    class="input-text with-border"
                                    name="twitter"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                              <!-- <div class="col-xl-8">
                                                                <div class="submit-field">
                                                                    <h5><span class="icon-brand-google-plus-g"></span>&nbsp;Google Profile URL Link</h5>
                                                                    <b-form-input
                                                                        type="text"
                                                                        v-model="link.google"
                                                                        id="google"
                                                                        class="input-text with-border"
                                                                        name="google"
                                                                        placeholder=""
                                                                        value=""
                                                                    />
                                                                </div>
                                                            </div> -->
                              <div class="col-xl-8">
                                <div class="submit-field common_mobile_mrgn">
                                  <h5>
                                    <span class="icon-brand-linkedin-in"></span>&nbsp;Company
                                    LinkedIn URL Link
                                  </h5>
                                  <b-form-input
                                    type="text"
                                    v-model="link.link"
                                    id=""
                                    class="input-text with-border desktop_margin_bottom_zero"
                                    name="link"
                                    placeholder=""
                                    value=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
          <!-- <div class="col-xl-12 coloured_icon"> -->
          <!-- <div id="test1" class="dashboard-box"> -->

          <!-- Headline -->
          <!-- <div class="headline">
                        <h3><i class="icon-line-awesome-bullhorn"></i>Linked Social Account (Increase your connectivity or get others to know you better)</h3>


                    </div> -->

          <!-- <div class="content with-padding"> -->
          <!-- <div class="row"> -->
          <!-- <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-feather-globe"></span>Company Website URL Link</h5>
                                        <b-form-input
                                            type="text"
                                            v-model="link.website"
                                            id="website"
                                            class="input-text with-border"
                                            name="website"
                                            placeholder=""
                                            value=""
                                        />
                                    </div>
                            </div>
                            <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-brand-facebook-f"></span>Company Facebook URL Link</h5>
                                    <b-form-input
                                        type="text"
                                        v-model="link.fb"
                                        id="fb"
                                        class="input-text with-border"
                                        name="fb"
                                        placeholder=""
                                        value=""
                                    />
                                </div>
                            </div>
                            <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-line-awesome-twitter-square"></span>Company Twitter URL Link</h5>
                                    <b-form-input
                                        type="text"
                                        v-model="link.twitter"
                                        id="twitter"
                                        class="input-text with-border"
                                        name="twitter"
                                        placeholder=""
                                        value=""
                                    />
                                </div>
                            </div> -->
          <!-- <div class="col-xl-8">
                                <div class="submit-field">
                                    <h5><span class="icon-brand-google-plus-g"></span>&nbsp;Google Profile URL Link</h5>
                                    <b-form-input
                                        type="text"
                                        v-model="link.google"
                                        id="google"
                                        class="input-text with-border"
                                        name="google"
                                        placeholder=""
                                        value=""
                                    />
                                </div>
                            </div> -->
          <!-- <div class="col-xl-8">
                                <div class="submit-field common_mobile_mrgn">
                                    <h5><span class="icon-brand-linkedin-in"></span>&nbsp;Company LinkedIn URL Link</h5>
                                    <b-form-input
                                        type="text"
                                        v-model="link.link"
                                        id="link"
                                        class="input-text with-border"
                                        name="link"
                                        placeholder=""
                                        value=""
                                    />
                                </div>
                            </div> -->

          <!-- </div> -->
          <!-- </div> -->
          <!-- </div> -->

          <!-- </div> -->

          <div class="col-xl-12 post_jb_btn daily_paid_details_btn">
            <a
              @click="submit"
              class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn"
              style="background: #4c8bf5 !important"
              >Save Changes</a
            >
            <!-- <b-link to="#" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn" style="background:black !important">Cancel</b-link> -->
            <b-link
              :to="{ name: 'employer-dashboard' }"
              class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn"
              style="background: black !important"
              >Cancel</b-link
            >
          </div>
          <!-- Button -->
        </b-form>
        <b-modal
          ref="crop-modal"
          title="Edit Image"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitImg">
            <template>
              <div>
                <div class="content" @change="setImage">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="1 / 1"
                        :src="tempLogo"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropImage"
                  >Crop</b-button
                >

                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>
        <b-modal
          ref="crop-modal-banner"
          title="Edit Banner Image"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitImgBanner">
            <template>
              <div>
                <div class="content" @change="setImageBanner">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="10 / 3"
                        :src="tempLogoBanner"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropImageBanner"
                  >Crop</b-button
                >

                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>
        <b-modal
          ref="verify-mobile-modal"
          title="Phone Verification"
          no-close-on-backdrop
          hide-footer
          content-class="mobile_verification_model common_model_title"
          modal-class="background_custom_class mobile_pop_up_mdl"
        >
          <template #modal-title>
            Phone Verification
            <span
              ><button type="button" @click="mobileVerifyCancel" class="close closefirstmodal">
                &times;
              </button></span
            >
          </template>
          <b-form class="">
            <!-- <button type="button" @click="mobileVerifyCancel" class="close closefirstmodal">&times;</button> -->
            <div class="edit-form-box web_top_zero">
              <div class="profile-repeate form-box-field">
                <div class="col-xl-12">
                  <!-- <h3><b>Phone Verification</b></h3> -->
                  <h5 class="gray_h5">
                    Enter the verification code that<br />was sent to your mobile number.
                  </h5>
                </div>
                <b-row>
                  <b-col lg="12" md="12">
                    <div class="form-map-box">
                      <b-col lg="12" md="12" class="input-with-icon-left">
                        <i class="fa fa-mobile pop_up_i"></i>
                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                          <b-form-input
                            id="input-9"
                            maxlength="20"
                            v-model="form.mobile"
                            type="text"
                            placeholder=""
                            readonly="readonly"
                            class="number_input form-control"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                          <input
                            type="text"
                            v-model="form.var_code"
                            class="with-border mobile_tab_margin_bottom_16 mobile_input_prf form-control"
                            placeholder="Enter the 4-digit code"
                          />
                        </b-form-group>
                      </b-col>

                      <div slot="footer" class="form-btn col-md-12 col-lg-12">
                        <b-button
                          @click="mobileVerify"
                          variant="success"
                          style="background-color: #4c8bf5 !important"
                          class="otp_btn verify_otp_btn_mod"
                          >Verify OTP</b-button
                        >
                        <!-- <b-button @click = "hideVerifyMobileModel()" variant="light">Cancel</b-button> -->
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <div class="col-xl-12 bottom_text_pop_phone">
                  <h5>
                    Verification code may take up to a few <br />minutes to be sent to your phone.
                  </h5>
                  <h5 class="web_bottom_zero" v-if="countDown > 0">
                    Didn't receive the code? Resend code in
                    <span style="color: #4c8bf5 !important"> {{ countDown }} seconds</span>
                  </h5>
                  <h5 class="web_bottom_zero" v-else>
                    Didn't receive the code?
                    <b-link @click="sendOTP" style="color: #4c8bf5 !important"> Resend Code</b-link>
                  </h5>
                </div>
              </div>
            </div>
          </b-form>
        </b-modal>
        <b-modal
          ref="apply-dp-jobs-modal"
          title="Temp. Staffing Manpower Services"
          no-close-on-backdrop
          centered
          hide-footer
          content-class="modal-content mobile_verification_model  common_model_title manpower_title"
          modal-class="background_custom_class"
        >
          <b-form class="">
            <div class="edit-form-box web_top_zero">
              <div class="profile-repeate form-box-field">
                <div class="col-xl-12">
                  <!-- <h3><b>Sample Image</b></h3> -->
                </div>
                <b-row>
                  <b-col lg="12" md="12" class="manpower_services_pop_up">
                    <!-- <p>EL Connect have a huge pool of freelancers that are ready to work for you even on the next hour!</p> -->
                    <!-- <p>You may contact our friendly business development team @ Whatsapp <a href="https://api.whatsapp.com/send/?phone=6588535796&text=Hi%2C+I+would+like+to+enquire+daily+paid+services+to+help+my+business+to+get+manpower+needs&type=phone_number&app_absent=0" target="_blank">88535796</a></p> -->

                    <p>
                      EL Connect offers a vast pool of freelancers who are ready to help you, even
                      within the next hour!
                    </p>

                    <p>
                      Don't hesitate to contact our friendly business development team through calls
                      or WhatsApp at
                      <a
                        href="https://api.whatsapp.com/send/?phone=6588535796&text=Hi%2C+I+would+like+to+enquire+daily+paid+services+to+help+my+business+to+get+manpower+needs&type=phone_number&app_absent=0"
                        target="_blank"
                        >+65 88535796</a
                      >
                    </p>

                    <p>
                      or
                      <a :href="webUrlEmployer" target="_blank"
                        >Please click for further details.</a
                      >
                    </p>
                    <div class="manpower_services_btn_center">
                      <button
                        @click="closeApplyDPModal"
                        type="button"
                        class="manpower_services_pop_up_close_btn"
                      >
                        Close
                      </button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-form>
        </b-modal>
        <b-modal
          ref="survey-modal"
          id="background_custom_class_for_banner"
          title="Where did you hear about us?"
          hide-footer
          centered
          hide-header
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class survey_model"
        >
          <b-form>
            <template>
              <div class="row survay_img_mobile">
                <div class="col-xl-8 col-md-8 survay_img">
                  <img :src="webUrl + 'assets/images/Pencil 1.png'" />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <h2 class="survay_heading">Where did you hear about us?</h2>
                </div>
              </div>
              <div class="row">
                <div
                  class="mobile_tab_col_four col-xl-4 col-md-4 desktop_margin_bottom_zero single_profile_eye"
                  id="show_hide_current_password"
                >
                  <b-form-group
                    id="input-group-8"
                    label=""
                    class="category-radio employer_multiple_radio"
                  >
                    <b-form-radio v-model="heard_from" value="facebook"
                      ><b>Facebook</b></b-form-radio
                    >
                    <b-form-radio v-model="heard_from" value="instagram"
                      ><b>Instagram</b></b-form-radio
                    >
                    <b-form-radio v-model="heard_from" value="tiktok"><b>Tiktok</b></b-form-radio>
                    <b-form-radio v-model="heard_from" value="linkedin"
                      ><b>Linkedin</b></b-form-radio
                    >
                    <b-form-radio v-model="heard_from" value="google"><b>Google</b></b-form-radio>
                    <b-form-radio v-model="heard_from" value="friends_family"
                      ><b>Friends/Family</b></b-form-radio
                    >
                    <b-form-radio v-model="heard_from" value="other"><b>Other</b></b-form-radio>
                  </b-form-group>
                </div>
                <div class="col-xl-8 col-md-8 survay_img survay_img_desktop">
                  <img :src="webUrl + 'assets/images/Pencil 1.png'" />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-md-8">
                  <div
                    class="submit-field desktop_margin_bottom_zero"
                    :class="heard_from == 'other' ? '' : 'other_margin_top_zero'"
                    v-if="heard_from == 'other'"
                  >
                    <div class="">
                      <div class="survay_textarea">
                        <!-- <h5 class="">Remark<span class="required_star">*</span></h5> -->
                        <textarea
                          type="text"
                          class="with-border desktop_margin_bottom_zero form-control"
                          v-model="heard_from_comment"
                          placeholder="State here"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Button -->
                <div
                  class="col-xl-4 col-md-4 text-md-right new_text_align_end common_crop_reset_btn survey_model_btn"
                >
                  <b-button
                    variant="success"
                    class="text-uppercase x-md btn-shadow"
                    @click.prevent="surveyFormUpdateFLEmp"
                    >Submit</b-button
                  >
                </div>
              </div>
            </template>
          </b-form>
        </b-modal>
        <b-modal
          ref="job-preference-modal"
          title="Choose your Posting Preference"
          id="background_custom_class_for_banner"
          hide-footer
          centered
          hide-header
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class job_prefrence_model"
        >
          <b-form>
            <template>
              <div class="row">
                <div class="col-xl-12 single_profile_eye" id="show_hide_current_password">
                  <h2>Choose one or both services</h2>
                  <h3> Welcome! Let's tailor your experience to match your hiring needs
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-md-6" @click="selectJobTypeDP">
                  <!-- <input v-model = 'job_preference_dp'> -->
                  <!-- <b-form-input>hello</b-form-input> -->
                  <b-form-group
                    id="input-group-6"
                    label=""
                    class="job_type_dp left_side_checkbox model_margin_bottom_zero required model_date_picker">
                    <img src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/dp_job.png"/>
                    <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"></b-form-checkbox> -->
                    <strong>Temporary Staffing Services</strong>
                    <p>Ideal for short-term, part-time, seasonal demands & project-based staffing</p>
                  </b-form-group>
                </div>
                <div class="col-xl-6 col-md-6" @click="selectJobTypeFT">
                  <b-form-group
                    id="input-group-6"
                    label=""
                    class="job_type_ft right_side_checkbox model_margin_bottom_zero required model_date_picker">
                    <img src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/ft_job.png"/>
                    <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"></b-form-checkbox> -->
                    <strong>Full Time Posting</strong>
                    <p>Permanent employment for your business hiring needs</p>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-md-12 text-md-center common_crop_reset_btn mt-5">
                  <b-button
                    variant="success"
                    class="btn green-border-btntext-uppercase x-md btn-shadow"
                    :class="
                      job_preference_dp == 'yes' || job_preference_ft == 'yes' ? '' : 'disabled'
                    "
                    @click.prevent="updateUserJobPreference"
                    >Submit</b-button
                  >
                </div>
              </div>
            </template>
          </b-form>
        </b-modal>
      </div>
    </div>

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";
import VueCropper from "vue-cropperjs";
import CKEditor from "ckeditor4-vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Multiselect from "vue-multiselect";
import * as faceapi from "face-api.js";
import Bus from "../../event-bus";

export default {
  metaInfo: {
    title: "EL Connect - Employer - Profile",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "Employer - Profile - Start posting Daily Paid and Full Time jobs with us as we offer a pool of more than 20,000 jobseekers. ",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - Employer - Profile" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/employer/employer-profile" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "EL Connect - Employer - Profile - Start posting Daily Paid and Full Time jobs with us as we offer a pool of more than 20,000 jobseekers.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/employer/employer-profile" }],
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        full_name: "",
        user_type_id_val: "",
        gender: "",
        country_code: "",
        mobile: "",
        company_contact_number: "",
        var_code: "",
        email: "",
        other_category_id: "",
        specified_company_industry: "",
        skill: "",
        email_verify: "",
        mobile_verify: "",
        verify_otp_button: false,
        mobile_verified_check: false,
        apply_for_dp: false,
        show_required_field_apply_dp_msg: false,
        pending_msg: false,
        emp_daily_paid_status: "",
        company_name: "",
        office_address: "",
        company_reg_no: "",
        job_preference: "",
        overview: "",
        staffName: "",
        sortdesc: "",
        website: "",
        certificate: "",
        fb: "",
        google: "",
        twitter: "",
        link: "",
        user_id: "",
        image: "",
        acra: "",
        profile_image: "",
        banner_image: "",
        selectedImage: {
          name: "",
          image: "",
          company_id: "",
        },
        selectedBannerImage: {
          name: "",
          image: "",
        },
        selectedAcra: {
          name: "",
          image: "",
        },
      },
      validErr: [],
      countDown: 60,
      countDownEmail: 0,
      showVerifyOtp: true,
      showVerifyEmail: true,
      link: {
        website: "",
        fb: "",
        google: "",
        twitter: "",
        link: "",
        user_id: "",
      },
      images: [],
      multiple:false,
      maxImage:1,
      allStaff: [],
      other_category_id: "",
      showDismissibleAlert: false,
      error_message: "",
      isActive: 1,
      disabled: true,
      personal: true,
      skillsArr: [],
      tempLogo: "",
      links: false,
      disabled: true,
      noClose: true,
      cropImg: "",
      showACRA: "",
      acra_detail: "",
      acraUrl: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      cropImgBanner: "",
      tempLogoBanner: "",
      noClose: true,
      webUrl: process.env.VUE_APP_URL,
      webUrlEmployer: process.env.VUE_APP_URL + "employer",
      docs: [],
      editorConfig: {
        versionCheck : false,
        removeButtons:
          "Maximize,Paste,Cut,Copy,Undo,Redo,Link,Unlink,Anchor,Image,Table,Source,HorizontalRule,SpecialChar,Scayt,PasteFromWord,PasteText",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      status: "",
      account_verification_status: "",
      rejected_comment: "",
      key: "",
      heard_from: "",
      heard_from_comment: "",
      job_preference_update: "",
      job_preference_dp: "no",
      job_preference_ft: "no",
      is_company_head: "",
    };
  },
  components: {
    VueCropper,
    ckeditor: CKEditor.component,
    Multiselect,
    VueUploadMultipleImage,
    faceapi,
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  methods: {
    selectJobTypeDP(){
                if(this.job_preference_dp == 'no')
                {
                    $('.job_type_dp').addClass("add_border_dp");
                    this.job_preference_dp = 'yes'
                }
                else{
                    $('.job_type_dp').removeClass("add_border_dp");
                    this.job_preference_dp = 'no'
                }

            },
            selectJobTypeFT(){
                if(this.job_preference_ft == 'no')
                {
                    $('.job_type_ft').addClass("add_border_dp");
                    this.job_preference_ft = 'yes'
                }
                else{
                    $('.job_type_ft').removeClass("add_border_dp");
                    this.job_preference_ft = 'no'
                }
            },
    mobileToolTipNumber() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_mobile_number").toggleClass("tooltip_text_mobile_view_number");
      }
    },
    mobileToolTipEmail() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_email").toggleClass("tooltip_text_mobile_view_email");
      }
    },
    customLabel({ first_name, last_name }) {
      return `${last_name} ${first_name}`;
    },
    activeTab() {
      this.personal = true;
      this.links = false;
    },
    showVerifyMobileModel() {
      (this.countDown = 60), (this.form.var_code = "");
      this.countDownTimer();
      this.form.var_code = "";
      this.$refs["verify-mobile-modal"].show();
    },
    showCategory(id) {
      if (id == this.other_category_id) {
        $(".other_category_class").css("display", "block");
      } else {
        $(".other_category_class").css("display", "none");
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.showVerifyOtp = false;
        this.timeOutCounter = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.showVerifyOtp = true;
      }
    },
    countDownTimerEmail() {
      if (this.countDownEmail > 0) {
        this.showVerifyEmail = false;
        this.timeOutCounterEmail = setTimeout(() => {
          this.countDownEmail -= 1;
          this.countDownTimerEmail();
        }, 1000);
      } else {
        this.showVerifyEmail = true;
      }
    },
    // uploadImageSuccess(formData, index, fileList) {
    //   console.log(formData)
    //   console.log(fileList)
    //   for (var i = 0; i < fileList.length; i++) {
    //     var name = fileList[i].name.split(".");
    //     if (name[name.length - 1] == "pdf") {
    //       if (typeof fileList[i].url == "undefined") {
    //         var url = fileList[i].image;
    //         fileList[i].url = url;
    //       }
    //       fileList[i].image = this.webUrl + "assets/images/pdf.png";
    //     } else {
    //       if (typeof fileList[i].url == "undefined") {
    //         var url = fileList[i].image;
    //         fileList[i].url = url;
    //       }
    //     }
    //   }

    //   this.form.selectedImage.image = fileList[0].path;
    //   console.log(this.form.selectedImage.image)
    // },

    beforeRemove(index, done, fileList) {
      fileList.splice(index, 1);
      this.images = fileList;
    },

    editImage(formData, index, fileList) {
      for (var i = 0; i < fileList.length; i++) {
        var name = fileList[i].name.split(".");
        if (name[name.length - 1] == "pdf") {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].image;
            fileList[i].url = url;
          }
          fileList[i].image = this.webUrl + "/images/pdf.png";
        } else {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].image;
            fileList[i].url = url;
          }
        }
      }
      this.images = fileList;
    },
    showApplyDPModal() {
      this.$refs["apply-dp-jobs-modal"].show();
    },
    closeApplyDPModal() {
      this.$refs["apply-dp-jobs-modal"].hide();
    },
    mobileVerifyCancel() {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to close this popup?",
        // text: "You can resend OTP in " +this.countDown+ " seconds.Are you sure you want to close this popup?",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((value) => {
        if (value.isConfirmed) {
          //clearTimeout(this.timeOutCounter);
          this.$refs["verify-mobile-modal"].hide();
        }
      });
    },
    hideVerifyMobileModel() {
      clearTimeout(this.timeOutCounter);
      this.$refs["verify-mobile-modal"].hide();
    },
    cropImage() {
      this.$refs["crop-modal"].hide();
      this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
      console.log(this.form.selectedImage.image)
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImg = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    showImg() {
      this.$refs["crop-modal"].show();
    },
    showAlert() {
      this.$swal({
        position: "center",
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    onSubmitImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal"].hide();
      this.$refs["crop-modal"].refresh();
    },
    cropImageBanner() {
      this.$refs["crop-modal-banner"].hide();
      this.form.selectedBannerImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImageBanner(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBanner = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showImgBanner() {
      this.$refs["crop-modal-banner"].show();
    },
    onSubmitImgBanner(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner"].hide();
      this.$refs["crop-modal-banner"].refresh();
    },
    onBannerChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image = input.files[0];
        if (
          this.form.banner_image.type != "image/png" &&
          this.form.banner_image.type != "image/jpeg" &&
          this.form.banner_image.type != "image/jpg"
        ) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.banner_image.size > 5242880) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Banner image should be less than 5 MB.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.selectedBannerImage.name = this.form.banner_image.name;
            this.showImgBanner();
            event.target.value = "";
          };
        }
      }
    },

    deleteImage(img_id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this image?",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((value) => {
          return this.$store
            .dispatch(POST_API, {
              data: {
                id: img_id,
              },
              api: "/api/delete-document",
            })
            .then(() => {
              alert;
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                window.location.replace("/v2/employer/employer-profile");
              }
            });
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onAcraChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.acra = input.files[0];
        if (this.form.acra.type != "application/pdf") {
          this.error_message = "Please select only .pdf only.";
          this.form.selectedAcra.image = "";
          this.form.selectedAcra.name = "";
          $("#acra").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else if (this.form.acra.size > 10523520) {
          this.form.selectedAcra.image = this.webUrl + "/images/default-user-icon.svg";
          this.error_message = "ACRA should be less than 10 MB.";
          this.form.selectedAcra.image = "";
          this.form.selectedAcra.name = "";
          $("#acra").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.modelBox = "logo";
            this.form.selectedAcra.image = e.target.result;
            this.form.selectedAcra.name = this.form.acra.name;
            $("#acra").empty().append(this.form.selectedAcra.name);
          };
        }
      }
    },
    viewACRA(event) {
      if (event) {
        event.stopPropagation();
      }
      window.open(this.acraUrl, "_blank");
    },
    removeACRA(event) {
      if (event) {
        event.stopPropagation();
      }

      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to remove ACRA?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  company_id: this.form.company_id,
                  user_id: this.user_id,
                },
                api: "/api/employer-acra-remove",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  // this.showAlert();
                  return [];
                } else {
                  this.form.acra = "";
                  this.showACRA = false;
                  this.form.apply_for_dp = false;
                  $("#acra").empty().append("");
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: "ACRA removed successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.getDropdownData("service_categories");
                  this.getEditData();
                  this.getSocialData();
                  this.getBillingAddress();
                }
              });
          }
        })
        .catch((err) => {});
    },
    onprofileChange(event) {
      var input = event.target;
      // const image = loadRandomImage();
      // const faces =  faceapi.detectAllFaces(input.files[0], new faceapi.TinyFaceDetectorOptions());
      // const detection = await faceapi.detectSingleFace(input)
      //console.log("change profile")
      // console.log(input.files[0])

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.profile_image = input.files[0];
        if (
          this.form.profile_image.type != "image/png" &&
          this.form.profile_image.type != "image/jpeg" &&
          this.form.profile_image.type != "image/jpg"
        ) {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.profile_image.size > 1048576) {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Company Logo should be less than 1 MB.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedImage.name = this.form.profile_image.name;
            this.showImg();
            event.target.value = "";
            $("#company_logo").removeClass("error");
            $("#company_logo").removeClass("bind_error_class");
            $(".error_x_white_company_logo").hide();
          };
        }
      }
    },
    // onFileChange(event) {
    //     var input = event.target;
    //     if (input.files && input.files[0]) {
    //         var reader = new FileReader();
    //         // reader.onload = (e) => {
    //         //     this.selectedImage = e.target.result;
    //         // }
    //         reader.readAsDataURL(input.files[0]);
    //         this.form.profile_image = input.files[0];
    //         if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
    //             this.selectedImage = this.webUrl + '/images/upload-icon.svg'
    //             this.error_message = 'Please select only .png,.jpg or .jpeg image.';
    //             this.selectedImage='';
    //             this.showAlert();
    //         } else if (this.form.profile_image.size > 1048576) {

    //             this.selectedImage = this.webUrl + '/images/upload-icon.svg'
    //             this.error_message = 'Logo should be less than 1 MB.';
    //             this.selectedImage='';
    //             this.showAlert();
    //         } else {
    //             reader.onload = (e) => {
    //                 this.tempLogo = e.target.result;
    //                 this.showImg();
    //             }
    //         }
    //         // if(this.form.profile_image.type !='image/png' && this.form.profile_image.type !='image/jpeg' && this.form.profile_image.type !='image/jpg'){
    //         //   this.$bvModal.msgBoxOk('Please select only .png,.jpg or .jpeg image.')
    //         // }
    //     }
    // },
    empApplyForDP() {
      var err = false;
      if (this.form.skill == this.other_category_id) {
        if (this.form.specified_company_industry) {
          err = false;
        } else {
          err = true;
          this.$swal({
            position: "center",
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Specify industry is required",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              billing_address: this.billing_address,
              skill: this.form.skill,
              specified_company_industry:
                this.form.skill == this.other_category_id
                  ? this.form.specified_company_industry
                  : null,
              company_name: this.form.company_name,
              company_id: this.form.company_id,
              company_reg_no: this.form.company_reg_no,
              mobile_verified: this.form.mobile_verify,
              email_verified: this.form.email_verify,
              acra: this.form.selectedAcra.image,
              profile_image: this.form.selectedImage.image,
              user_id: this.user_id,
              items: this.form,
            },
            api: "/api/empApplyForDP",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.form.apply_for_dp == false;

              this.$swal({
                position: "center",
                imageUrl: "/assets/images/404_elcy.gif",
                customClass: {
                  container: "mascot_error_container",
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.form.apply_for_dp == true;
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Applied for Temp. Staffing successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {});
              this.getDropdownData("service_categories");
              this.getEditData();
              this.getSocialData();
              this.getBillingAddress();
              // this.showVerifyMobileModel();
            }
          });
      }
    },

    sendOTP() {
      if (this.showVerifyOtp) {
        clearTimeout(this.timeOutCounter);
        this.$store
          .dispatch(POST_API, {
            data: {
              mobile: this.form.mobile,
              user_id: this.user_id,
              company_id: this.form.company_id,
              user_type_id: 3,
            },
            api: "/api/sendOTP",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.$swal({
                position: "center",
                imageUrl: "/assets/images/404_elcy.gif",
                customClass: {
                  container: "mascot_error_container",
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              var userData = this.$store.getters.currentUser;
              this.form.verify_otp_button = true;
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "OTP sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.showVerifyMobileModel();
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: "Please try again in " + this.countDown + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getDropdownData(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/dropdown-list-category",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.skillsArr = this.$store.getters.getResults.data;

            this.other_category_id = this.$store.getters.getResults.other_category_id;
            $(".other_category_class").css("display", "none");
          }
        });
    },

    submit() {
      var err = false;

      $(".required-field").each(function () {
        console.log($(this), "console");

        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(".error_x_white_uen").hide();
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#gender").each(function () {
        if (!$("#gender").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          document
            .querySelector(".second_indiv_step_wrap .bind_error_class")
            .scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#gender").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#industry").each(function () {
        if (!$("#industry").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          document
            .querySelector(".second_indiv_step_wrap .bind_error_class")
            .scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#industry").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      if (!this.form.selectedImage.image && !this.form.selectedImage.image) {
        err = true;
        $(".error_x_white_uen").hide();
        $("#company_logo")
          .addClass("error")
          .parent()
          .find(".error_submit_field")
          .addClass("bind_error_class");
        $(".error_x_white_company_logo").show();
      } else {
        if (err == false) {
          err = false;
        }
        $("#company_logo").removeClass("error");
        $(".error_x_white_company_logo").hide();
      }
      // if(this.form.mobile_verify != 'yes'){
      //     err = true;
      //     $('.error_x_white_uen').hide();
      //     $('#contact').addClass('error');
      // }else{
      //     if(err== false){
      //      err = false;
      //     }
      //     $('#contact').removeClass('error');
      // }

      console.log(err, "gghh");

      setTimeout(function () {
        document
          .querySelector(".second_indiv_step_wrap .error")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        //$('.second_indiv_step_wrap .error').first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      if (this.form.skill == this.other_category_id) {
        if (this.form.specified_company_industry) {
          err = false;
        } else {
          err = true;
          this.$swal({
            position: "center",
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Specify industry is required",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (err == false) {
        this.isEmailVerifiedCheck();

        // $("#overview").focusin(function(){
        //     $('#overview-warning-message').hide();
        //     $('#overview').removeClass('invalid');
        // });
        // $("#overview").focusout(function(){
        //     if($('#overview').val() === ""){
        //         $('#overview').addClass('invalid');
        //         $('#overview-warning-message').show();
        //     }else{
        //     $('#overview').removeClass('invalid');
        //     $('#overview-warning-message').hide();
        //     }
        // });
        // window.scrollTo(0,0);
      }
    },
    isEmailVerifiedCheck() {
      console.log("hhhhhh");
      // console.log(this.form.selectedImage.image)
      // console.log(this.images)
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            console.log("sdfgdf1");
            if (this.$store.getters.getResults.data) {
              this.form.email_verify = this.$store.getters.getResults.user["email_verified"];
              this.form.mobile_verify = this.$store.getters.getResults.user["mobile_verified"];
            }
            if (this.form.email_verify == "yes" && this.form.mobile_verify == "yes") {
              console.log("sdfgdf12");
              $("#email").removeClass("error");
              $("#contact").removeClass("error");
              this.$store
                .dispatch(POST_API, {
                  data: {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    full_name: this.form.full_name,
                    gender: this.form.gender,
                    mobile_number: this.form.mobile,
                    var_code: this.form.var_code,
                    email: this.form.email,
                    company_name: this.form.company_name,
                    contact_number: this.form.mobile,
                    //contact_number: this.form.company_contact_number,
                    office_address: this.form.office_address,
                    company_reg_no: this.form.company_reg_no,
                    emp_daily_paid_status: this.form.emp_daily_paid_status,
                    overview: this.form.overview,
                    closed_by:
                      this.form.staffName != "" && this.form.staffName != null
                        ? this.form.staffName.id
                        : "",
                    profile_image: this.form.selectedImage.image,
                    banner_image: this.form.selectedBannerImage.image,
                    acra: this.form.selectedAcra.image,
                    items: this.form,
                    website: this.link.website,
                    fb: this.link.fb,
                    google: this.link.google,
                    link: this.link.link,
                    twitter: this.link.twitter,
                    user_id: this.user_id,
                    company_id: this.form.company_id,
                    short_description: this.form.sortdesc,
                    specified_company_industry:
                      this.form.skill == this.other_category_id
                        ? this.form.specified_company_industry
                        : null,
                    skill: this.form.skill,
                    ft_account_status: this.status,
                    certificate: this.form.certificate,
                    images: this.images,
                    mobile_verified: this.form.mobile_verify,
                    email_verified: this.form.email_verify,
                  },
                  api: "/api/submit",
                })
                .then(() => {
                  if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;

                    this.$swal({
                      position: "center",
                      imageUrl: "/assets/images/404_elcy.gif",
                      customClass: {
                        container: "mascot_error_container",
                      },
                      title: this.error_message,
                      showConfirmButton: false,
                      timer: 1500,
                    });

                    if (
                      this.error_message ==
                      "UEN Already in use! Please create support ticket for help"
                    ) {
                      $(".uen").addClass("error");
                      setTimeout(function () {
                        //$('.second_indiv_step_wrap .error').first().focus();
                        document
                          .querySelector(".second_indiv_step_wrap .error")
                          .scrollIntoView({ behavior: "smooth", block: "center" });
                        $(".error").unbind("keypress");
                        $(".error").bind("keypress", function () {
                          $(this).removeClass("error");
                          $(this)
                            .parent()
                            .parent()
                            .find(".error_x_white")
                            .removeClass("show")
                            .parent()
                            .find(".error_submit_field")
                            .removeClass("bind_error_class");
                        });
                      }, 100);
                      $(".error_x_white_uen").show();
                    } else {
                      $(".error_x_white_uen").hide();
                    }
                  } else {
                    this.applyForDailyPaidNew();
                    Bus.$emit("active_users");
                    this.$swal({
                      position: "center",
                      icon: "success",
                      iconColor: "#4c8bf5",
                      title: "Profile Updated successfully.",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      window.location.replace("/v2/employer/employer-profile");
                    });
                    // if (!this.form.job_preference) {
                    //   this.dataItem = this.$store.getters.getResults.dataItem;
                    //   this.sum_total = this.$store.getters.getResults.sum_total_cost;
                    //   this.$swal({
                    //     title: "",
                    //     html:
                    //       "<b class='profile_upadte_pop_up_txt'>Profile Updated Successfully</b>" +
                    //       "<center><p class='welcome_txt_new_pop_up'>Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs</p>",
                    //     type: "success",
                    //     icon: "success",
                    //     iconColor: "#4c8bf5",
                    //     showCancelButton: false,
                    //     showDenyButton: true,
                    //     confirmButtonColor: "#3085d6",
                    //     denyButtonColor: "#cccccc",
                    //     cancelButtonColor: "#d33",
                    //     confirmButtonText: "Daily Paid",
                    //     denyButtonText: `Full Time`,
                    //     customClass: {
                    //       container: "new_register_user_emp",
                    //     },
                    //   }).then((res) => {
                    //     if (res.isConfirmed) {
                    //       this.updateUserJobPreferencePrev("daily_paid");
                    //       window.location.replace("/v2/employer/profile-daily-paid-detail");
                    //     } else if (res.isDenied) {
                    //       this.updateUserJobPreferencePrev("full_time");
                    //       window.location.replace("/v2/employer/manage-jobs");
                    //     }
                    //   });
                    // } else {
                    //   this.$swal({
                    //     position: "center",
                    //     icon: "success",
                    //     iconColor: "#4c8bf5",
                    //     title: "Profile Updated successfully.",
                    //     showConfirmButton: false,
                    //     timer: 1500,
                    //   }).then((result) => {
                    //     window.location.replace("/v2/employer/employer-profile");
                    //   });
                    // }
                  }
                });
            } else {
              if (this.form.mobile_verify != "yes") {
                $(".error_x_white_uen").hide();
                $("#contact").addClass("error");
              } else {
                $("#contact").removeClass("error");
              }
              if (this.form.email_verify != "yes") {
                $(".error_x_white_uen").hide();
                $("#email").addClass("error");
              } else {
                $("#email").removeClass("error");
              }
              setTimeout(function () {
                document
                  .querySelector(".second_indiv_step_wrap .error")
                  .scrollIntoView({ behavior: "smooth", block: "center" });
                //$('.second_indiv_step_wrap .error').first().focus();

                $(".error").unbind("keypress");
                $(".error").bind("keypress", function () {
                  $(this).removeClass("error");
                  $(this)
                    .parent()
                    .parent()
                    .find(".error_x_white")
                    .removeClass("show")
                    .parent()
                    .find(".error_submit_field")
                    .removeClass("bind_error_class");
                });
              }, 100);
            }
          }
        });
    },

    updateUserJobPreferencePrev(job_preference) {
      this.$store
        .dispatch(POST_API, {
          data: {
            job_preference: job_preference,
            user_id: this.user_id,
          },
          api: "/api/updateUserJobPreference",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
          } else {
            console.log("updateUserJobPreference");
          }
        });
    },
    updateUserJobPreference() {
      if (this.job_preference_dp == "no" && this.job_preference_ft == "no") {
        this.error_message = "Please select Job Preference";
        this.showAlert();
      } else if (this.job_preference_dp == "yes" && this.job_preference_ft == "yes") {
        this.job_preference_update = "both";
      } else if (this.job_preference_dp == "yes") {
        this.job_preference_update = "daily_paid";
      } else {
        this.job_preference_update = "full_time";
      }
      this.$store
        .dispatch(POST_API, {
          data: {
            job_preference: this.job_preference_update,
            user_id: this.user_id,
          },
          api: "/api/updateUserJobPreference",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
          } else {
            console.log("updateUserJobPreference");
            this.$refs["job-preference-modal"].hide();

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Preference saved successfully",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.$refs["job-preference-modal"].hide();
              if (
                (this.heard_from == "" || this.heard_from == null) &&
                this.is_company_head == "yes"
              ) {
                this.$refs["survey-modal"].show();
              }
            });
          }
        });
    },
    surveyFormUpdateFLEmp() {
      this.$store
        .dispatch(POST_API, {
          data: {
            heard_from: this.heard_from,
            heard_from_comment: this.heard_from_comment,
            user_id: this.user_id,
          },
          api: "/api/surveyFormUpdateFLEmp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.$refs["survey-modal"].hide();
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Thank you for your time",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              console.log("survey completed");
            });
          }
        });
    },
    applyForDailyPaidNew() {
      this.$store
        .dispatch(POST_API, {
          data: {
            company_id: this.form.company_id,
            user_id: this.user_id,
          },
          api: "/api/empApplyForDPNew",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
            this.form.apply_for_dp == false;
          } else {
            this.form.apply_for_dp == true;
          }
        });
    },

    storeAccounts() {
      this.$store
        .dispatch(POST_API, {
          data: {
            website: this.link.website,
            fb: this.link.fb,
            google: this.link.google,
            link: this.link.link,
            twitter: this.link.twitter,
            user_id: this.user_id,
          },

          api: "/api/storeAccounts",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showDismissibleAlert = true;
            this.$swal({
              position: "center",
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            var userData = this.$store.getters.currentUser;

            // userData.ability = [{
            //     action: "manage", subject: "all"
            // }];

            // localStorage.setItem('userData', JSON.stringify(userData))
            // this.$ability.update(userData.ability)

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Step 2 completed successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {});
          }
        });
    },
    //     onprofileChange(event) {
    //     var input = event.target;
    //     if (input.files && input.files[0]) {
    //         var reader = new FileReader();
    //         reader.readAsDataURL(input.files[0]);
    //         this.form.profile_image = input.files[0];

    //         if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
    //             this.form.selectedImage = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Please select only .png,.jpg or .jpeg image.';
    //             this.selectedImage = '';
    //             this.showAlert();
    //         } else if (this.form.profile_image.size > 1048576) {

    //             this.form.selectedImage = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Profile image should be less than 1 MB.';
    //             this.form.selectedImage = '';
    //             this.showAlert();
    //         } else {
    //             reader.onload = (e) => {
    //                 this.modelBox = 'logo';
    //                 this.form.selectedImage.image = e.target.result;
    //                 this.form.selectedImage.name = this.form.profile_image.name;
    //                 $('#profile').empty().append(this.form.selectedImage.name)

    //             }
    //         }
    //     }
    // },
    getEditData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              if (this.$store.getters.getResults.user["mobile_number"] == "12345678") {
                this.form.mobile = "";
              } else {
                this.form.mobile = this.$store.getters.getResults.user["mobile_number"];
                this.form.verify_otp_button = this.$store.getters.getResults.user["mobile_number"]
                  ? (this.form.verify_otp_button = true)
                  : (this.form.verify_otp_button = false);
              }

              if (this.$store.getters.getResults.data.contact_number == "12345678") {
                this.form.company_contact_number = "";
              } else {
                this.form.company_contact_number =
                  this.$store.getters.getResults.data.contact_number;
              }

              this.form.email = this.$store.getters.getResults.user["email"];
              if (this.$store.getters.getResults.data.name == "test.co") {
                this.form.company_name = "";
              } else {
                this.form.company_name = this.$store.getters.getResults.data.name;
              }
              this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
              this.form.overview = this.$store.getters.getResults.data.description;
              this.form.job_preference = this.$store.getters.getResults.user.job_preference;

              this.form.staffName = this.$store.getters.getResults.data.closed_by;
              this.form.office_address = this.$store.getters.getResults.data.address;
              this.form.selectedImage.image = this.$store.getters.getResults.data.company_logo;
              this.form.selectedAcra.image = this.$store.getters.getResults.data.acra;
              this.form.selectedBannerImage.image =
                this.$store.getters.getResults.user["banner_image"];
              this.form.banner_image =
                this.$store.getters.getResults.user["banner_image"] != null
                  ? this.$store.getters.getResults.user["banner_image"]
                  : "";
              if (this.$store.getters.getResults.user["first_name"] == "Demo") {
                this.form.first_name = "";
                this.form.full_name = "";
              } else {
                this.form.first_name = this.$store.getters.getResults.user["first_name"];
                this.form.full_name = this.$store.getters.getResults.user["full_name"];
              }
              if (this.$store.getters.getResults.user["last_name"] == "Demo") {
                this.form.last_name = "";
                this.form.full_name = "";
              } else {
                this.form.last_name = this.$store.getters.getResults.user["last_name"];
                this.form.full_name = this.$store.getters.getResults.user["full_name"];
              }

              //this.form.first_name = this.$store.getters.getResults.user['first_name'];
              //this.form.last_name = this.$store.getters.getResults.user['last_name'];
              this.form.gender = this.$store.getters.getResults.user["gender"]
                ? this.$store.getters.getResults.user["gender"]
                : "";

              this.form.country_code = this.$store.getters.getResults.user["country_code"];
              this.form.user_type_id_val =
                this.$store.getters.getResults.user["user_type_id"] == "3"
                  ? "Company Admin"
                  : "Company";
              this.form.email_verify = this.$store.getters.getResults.user["email_verified"];
              this.form.mobile_verify = this.$store.getters.getResults.user["mobile_verified"];
              this.form.sortdesc = this.$store.getters.getResults.user["short_description"];

              this.form.mobile_verified_check =
                this.$store.getters.getResults.user["mobile_verified"] == "yes"
                  ? (this.form.mobile_verified_check = true)
                  : (this.form.mobile_verified_check = false);
              this.form.skill = this.$store.getters.getResults.data.company_industry_id
                ? this.$store.getters.getResults.data.company_industry_id
                : "";
              this.form.specified_company_industry =
                this.$store.getters.getResults.data.specified_company_industry;
              this.form.company_id = this.$store.getters.getResults.data.id;
              this.acra_detail = this.$store.getters.getResults.acra_detail;

              this.showACRA =
                this.$store.getters.getResults.data.acra != "" &&
                this.$store.getters.getResults.data.acra != null
                  ? true
                  : false;
              this.acraUrl =
                this.$store.getters.getResults.data.acra != "" &&
                this.$store.getters.getResults.data.acra != null
                  ? this.$store.getters.getResults.data.acra
                  : "";
              this.form.acra = this.acraUrl;

              // this.form.apply_for_dp = false;
              // this.form.apply_for_dp = this.$store.getters.getResults.user['emp_daily_paid_status'] == 'yes' ? this.form.apply_for_dp = true : this.form.apply_for_dp = false;
              this.form.apply_for_dp =
                this.$store.getters.getResults.user["account_verification_status"] == "completed" ||
                this.$store.getters.getResults.user["account_verification_status"] == "pending"
                  ? (this.form.apply_for_dp = true)
                  : (this.form.apply_for_dp = false);
              this.form.pending_msg =
                this.$store.getters.getResults.user["account_verification_status"] == "pending"
                  ? (this.form.pending_msg = true)
                  : (this.form.pending_msg = false);

              this.form.emp_daily_paid_status =
                this.$store.getters.getResults.user["emp_daily_paid_status"];
              //this.status = this.$store.getters.getResults.user['ft_account_status'];
              this.status = this.$store.getters.getResults.user["account_verification_status"];

              this.account_verification_status =
                this.$store.getters.getResults.user["account_verification_status"];
              this.rejected_comment = this.$store.getters.getResults.rejected
                ? this.$store.getters.getResults.rejected["comment"]
                : "";

              this.getDocuments();
              this.getClosedByData(this.form.staffName);
              if (this.form.mobile_verified_check != true) {
                this.validErr.push("Verify Mobile Number");
              }
              if (this.form.email_verify != "yes") {
                this.validErr.push("Verify Email Address");
              }
              if (this.form.company_name == null || this.form.company_name == "") {
                this.validErr.push("Add Company Name");
              }
              if (this.form.company_reg_no == null || this.form.company_reg_no == "") {
                this.validErr.push("Add Company Registration Number (UEN)");
              }
              if (this.form.skill == null) {
                this.validErr.push("Add Company Industry");
              }
              console.log(
                this.form.selectedImage.image,
                !this.form.selectedImage.image,
                "this.form.selectedImage.image"
              );
              if (!this.form.selectedImage.image) {
                this.validErr.push("Add Company Logo");
              }

              if (!this.showACRA) {
                this.dailyerr1 = 1;
                this.dailyerr2 = 2;
              } else {
                this.dailyerr2 = 1;
              }

              if (this.form.skill == this.other_category_id) {
                $(".other_category_class").css("display", "block");
              } else {
                $(".other_category_class").css("display", "none");
              }

              if (this.form.email_verify == "yes") {
                $("#email").attr("disabled", "disabled");
              } else {
                $("#email").removeAttr("disabled");
              }

              if (this.form.mobile_verify == "yes") {
                $("#contact").attr("disabled", "disabled");
              } else {
                $("#contact").removeAttr("disabled");
              }
            } else {
              this.form.mobile = "";
              this.form.email = "";
            }
          }
          this.heard_from = this.$store.getters.getResults.user.heard_from;

          if (
            (this.form.job_preference == "" || this.form.job_preference == null) &&
            this.is_company_head == "yes"
          ) {
            this.$refs["job-preference-modal"].show();
          }
          if (
            this.form.job_preference != "" &&
            this.form.job_preference != null &&
            this.is_company_head == "yes" &&
            (this.heard_from == "" || this.heard_from == null) &&
            this.is_company_head == "yes"
          ) {
            this.$refs["survey-modal"].show();
          }
          this.getBillingAddress();
        });
    },
    getSocialData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/employee-social",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              var link = this.link;

              this.$store.getters.getResults.data.forEach(function (accounts) {
                if (accounts.account_type == "website") {
                  link.website = accounts.url;
                }

                if (accounts.account_type == "fb") {
                  link.fb = accounts.url;
                }
                if (accounts.account_type == "google") {
                  link.google = accounts.url;
                }
                if (accounts.account_type == "link") {
                  link.link = accounts.url;
                }
                if (accounts.account_type == "twitter") {
                  link.twitter = accounts.url;
                }

                // code
              });

              // this.form.mobile = this.$store.getters.getResults.data.contact_number;
              // this.form.email = this.$store.getters.getResults.data.email;
              // this.form.company_name = this.$store.getters.getResults.data.name;
              // this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
              // this.form.overview = this.$store.getters.getResults.data.description;
            } else {
              // this.form.mobile = '';
              // this.form.email = '';
            }
          }
        });
    },
    getBillingAddress() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.user_id,
          },
          api: "/api/emp-get-billing-address",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.billing_address = this.$store.getters.getResults.data;
            if (!this.showACRA || this.billing_address.length == 0) {
              this.form.show_required_field_apply_dp_msg = true;
              $("#apply_for_dp_button").attr("disabled", "disabled").addClass("if_disabled_exist");
            } else {
              this.form.show_required_field_apply_dp_msg = false;
              $("#apply_for_dp_button").removeAttr("disabled").removeClass("if_disabled_exist");
            }
          }
        });
    },
    allStaffList() {
      return this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/update-sales-all-staff-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.allStaff = this.$store.getters.getResults.data;
            // return this.selectedSupervisor;
          }
        });
    },
    getClosedByData(closed_by) {
      this.$store
        .dispatch(POST_API, {
          data: {
            closed_by: closed_by,
          },
          api: "/api/update-sales-closed-by-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.form.staffName = this.$store.getters.getResults.data;
          }
        });
    },

    getDocuments() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.form.company_id,
          },
          api: "/api/employee-document",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.docs = this.$store.getters.getResults.data;
          }
        });
    },
    emailVerify() {
      if (this.showVerifyEmail) {
        clearTimeout(this.timeOutCounterEmail);
        // this.countDownEmail = 30;
        return this.$store
          .dispatch(POST_API, {
            data: {
              email: this.form.email,
              user_id: this.user_id,
            },
            api: "/api/sendEmailVerify",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              (this.countDownEmail = 30), this.countDownTimerEmail();
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Verification mail sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // window.location.reload();
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: "Please try again in " + this.countDownEmail + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    mobileVerify() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            verification_code: this.form.var_code,
            user_id: this.user_id,
          },
          api: "/api/verifyMobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            // return [];
          } else {
            this.form.mobile_verified_check = true;
            this.hideVerifyMobileModel();

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Mobile Number verified successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.form.mobile_verify = "yes";
              if (this.form.mobile_verify == "yes") {
                $("#contact").removeClass("error");
              } else {
                $("#contact").addClass("error");
              }
              // if(this.form.mobile_verify == 'yes'){
              $("#contact").attr("disabled", "disabled");
              // }else{
              //     $('#contact').removeAttr('disabled');
              // }
              // window.location.reload();
            });
          }
        });
    },
  },
  mounted() {
    $(".error_x_white_uen").hide();
    $(".error_x_white_company_logo").hide();

    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.is_company_head =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.is_company_head
        : "no";

    Bus.$emit("active_users");
    this.getDropdownData("service_categories");
    this.allStaffList();
    this.getEditData();
    this.getSocialData();

    //this.getDocuments();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>
