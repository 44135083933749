<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Messages</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Messages</li>
                </ul>
            </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th">
                <b-tab :title="'Pending Messages'" @click="changeTab">
                        <div class="container new_cnt_pdng my_new_container input_feild_big_screenz">
                            <div class="row">
                                <div class="col-md-3 col-xl-3 col-md-3 col-sm-12 desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex new_search_div_daily_job search_common_margin_bottom_28">
                                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <!-- <b-input-group> -->
                                            <!-- <br/> -->
                                            <b-input-group-prepend>
                                                <b-form-input id="searchBtn" @keyup.enter.native="filterPending = searchKeywordPending" v-model="searchKeywordPending" placeholder="Search by keyword"></b-form-input>
                                                <b-button class="btn points_events"><i class="fa fa-search" @click="filterPending = searchKeywordPending"></i></b-button>
                                            </b-input-group-prepend>
                                            <!-- </b-input-group> -->
                                    </div>
                                </div>
                                <div class="col-md-3 col-xl-3  col-sm-12  mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex">
                                    <b-form-group id="input-group-3" class="contact-fields desktop_margin_bottom_zero">
                                        <span class="custom-dropdown w-100">
                                            <b-form-select v-model="ticket_type_filter_pending" class="common_height_38 desktop_margin_bottom_zero date_style_size" v-on:change="changeTab">
                                            <option value="">Ticket Type</option>
                                            <option value="daily-paid">Daily Paid</option>
                                            <option value="full-time">Full Time</option>
                                        </b-form-select>
                                        </span>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
               
        <!-- Page Content
        ================================================== -->
                    <div class="row">
                        <div class="container my_new_container show_filter_arrow">
                            <b-table ref="datatablePending" show-empty striped hover responsive :items="getPendingMessagesList" :fields="fieldsPending" :current-page="currentPagePending" :per-page="perPagePending" :filter="filterPending" :sort-by.sync="sortByPending" :sort-desc.sync="sortDescPending" :sort-direction="sortDirectionPending">
                            <template #cell(type)="items">
                            <p v-if="items.item.type">{{items.item.type | setTicketType}}</p>
                                    <p v-else>
                                            {{ 'Daily Paid' }}
                                    </p>
                            </template>
                            <template #cell(mobile_number)="items">
                            {{items.item.country_code}}{{items.item.country_code ? '-' : ''}}{{items.item.mobile_number}}
                            </template>
                            <template #cell(message)="items">
                                <p class="td--content" v-if="items.item.message.length<50">{{items.item.message}}</p>
                                <p class="td--content" v-if="items.item.message.length>50">
                                        {{ items.item.message.substring(0,100)+"..." }}
                                </p>
                                </template>
                            <template #cell(status)="items">
                            {{items.item.status=='active' ? 'Pending' : 'Solved'}}
                            </template>
                            <template #cell(date)="items">
                                {{items.item.created_at | date_formate}}
                            </template>
                            
                            <template #cell(toggleAction)="items">
                            <div class="toggle-action">
                                <b-dropdown class="mx-1" right text="Actions" boundary="window">
                                        <b-dropdown-item  :to="{name: 'admin-support-message-pending', params: {id: encrypt(items.item.id)}}" v-if="show('view detail')">View</b-dropdown-item>
                                        <b-dropdown-item v-on:click="changeMessageStatus(items.item.id, items.item.status,'solved')" v-if="items.item.status == 'active' && show('mark pending')">Mark Solved</b-dropdown-item>
                                        <b-dropdown-item v-if="items.item.status == 'inactive' && show('mark pending')" v-on:click="changeMessageStatus(items.item.id, items.item.status,'solved')">Mark Pending</b-dropdown-item>
                                        <b-dropdown-item v-on:click="deleteMessage(items.item.id,items.item.status,'deleted')" v-if="show('delete message')">Delete</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </template>
                        
                            </b-table>
                            <nav class="pagination-box custom_pagination_border_radius">
                                <div class="page-row-box d-inline-block">
                                    <b-form-group id="input-group-4">
                                        <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block">
                                        <b-form-select v-model="form.rowsPerPagePending" @change='changeTab'>
                                                    <option>25</option>
                                                    <option>50</option>
                                                    <option>75</option>
                                                    <option>100</option>
                                        </b-form-select></span>
                                    </b-form-group>
                                </div>
                                <div class="total-page-count d-inline-block">
                                    <ul>
                                        <li>
                                            <span v-if='totalRowsPending != 0'>{{fromPending}} - {{toPending}} of {{totalRowsPending}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <b-pagination @input="changeTab" :total-rows="totalRowsPending" :per-page="perPagePending" v-model="currentPagePending" prev-text="" next-text="" hide-goto-end-buttons />
                            </nav>
                        </div>
                    </div>
                </b-tab>

                <b-tab :title="'Solved Messages'" @click="changeTabSolved">
                    <div class="container new_cnt_pdng my_new_container input_feild_big_screenz">
                        <div class="row">
                            <div class="col-md-3 col-xl-3 col-md-3 col-sm-12 desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <!-- <b-input-group> -->
                                        <!-- <br/> -->
                                        <b-input-group-prepend>
                                            <b-form-input id="searchBtn" @keyup.enter.native="filterSolved = searchKeywordSolved" v-model="searchKeywordSolved" placeholder="Search by keyword"></b-form-input>
                                            <b-button class="btn points_events"><i class="fa fa-search" @click="filterSolved = searchKeywordSolved"></i></b-button>
                                        </b-input-group-prepend>
                                        <!-- </b-input-group> -->
                                </div>
                            </div>
                             <div class="col-md-3 col-xl-3  col-sm-12  mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex">
                                    <b-form-group id="input-group-3" class="contact-fields desktop_margin_bottom_zero">
                                        <span class="custom-dropdown w-100">
                                            <b-form-select v-model="ticket_type_filter_solved" class="common_height_38 desktop_margin_bottom_zero date_style_size" v-on:change="changeTabSolved">
                                            <option value="">Ticket Type</option>
                                            <option value="daily-paid">Daily Paid</option>
                                            <option value="full-time">Full Time</option>
                                        </b-form-select>
                                        </span>
                                    </b-form-group>
                                </div>
                        </div>
                    </div>
               
                    <!-- Page Content
                    ================================================== -->
                    <div class="row">
                        <div class="container my_new_container show_filter_arrow">
                            <b-table ref="datatableSolved" show-empty striped hover responsive :items="getSolvedMessagesList" :fields="fieldsSolved" :current-page="currentPageSolved" :per-page="perPageSolved" :filter="filterSolved" :sort-by.sync="sortBySolved" :sort-desc.sync="sortDescSolved" :sort-direction="sortDirectionSolved">
                                <template #cell(type)="items" >
                                    <p v-if="items.item.type">{{items.item.type | setTicketType}}</p>
                                    <p v-else>
                                            {{ 'Daily Paid' }}
                                    </p>
                                </template>
                                <template #cell(message)="items">
                                <p class="td--content" v-if="items.item.message.length<50">{{items.item.message}}</p>
                                <p class="td--content" v-if="items.item.message.length>50">
                                        {{ items.item.message.substring(0,100)+"..." }}
                                </p>
                                </template>
                                <template #cell(mobile_number)="items">
                                {{items.item.country_code}}{{items.item.country_code ? '-' : ''}}{{items.item.mobile_number}}
                                </template>
                                <template #cell(status)="items">
                                {{items.item.status=='active' ? 'Pending' : 'Solved'}}
                                </template>
                                <template #cell(created_at)="items">
                                {{items.item.created_at | date_formate}}
                                </template>
                                <template #cell(reply)="items">
                                <p class="td--content" v-if="items.item.reply != null ? items.item.reply.length<50 : ''">
                                    {{items.item.reply != null ? items.item.reply.substring(0,100)+"..." : '' }}
                                </p>
                                </template>
                                <template #cell(reply_by)="items">
                                {{items.item.reply_by_last_name != null ? (items.item.reply_by_last_name != null ? items.item.reply_by_last_name : '') : '' | capitalize}} {{items.item.reply_by_first_name != null ? (items.item.reply_by_first_name != null ? items.item.reply_by_first_name : '') : '' | capitalize}}
                                </template>
                                <template #cell(reply_at)="items">
                                {{items.item.reply_at ? items.item.reply_at :''  | date_formate}}
                                </template>
                                <template #cell(toggleAction)="items">
                                <div class="toggle-action">
                                    <b-dropdown class="mx-1" right text="Actions" boundary="window">
                                        <b-dropdown-item  :to="{name: 'admin-support-message-solved', params: {id: encrypt(items.item.id)}}" v-if="show('view detail')">View</b-dropdown-item>
                                        <b-dropdown-item v-on:click="changeMessageStatus(items.item.id, items.item.status,'active')" v-if="show('mark pending')">Mark Pending</b-dropdown-item>
                                        <b-dropdown-item v-on:click="deleteMessage(items.item.id,items.item.status,'deleted')" v-if="show('delete message')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </template>
                            
                            </b-table>
                            <nav class="pagination-box custom_pagination_border_radius">
                                <div class="page-row-box d-inline-block">
                                    <b-form-group id="input-group-4">
                                        <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block">
                                        <b-form-select v-model="form.rowsPerPageSolved" @change='changeTabSolved'>
                                                    <option>25</option>
                                                    <option>50</option>
                                                    <option>75</option>
                                                    <option>100</option>
                                        </b-form-select></span>
                                    </b-form-group>
                                </div>
                                <div class="total-page-count d-inline-block">
                                    <ul>
                                        <li>
                                            <span v-if='totalRowsSolved != 0'>{{fromSolved}} - {{toSolved}} of {{totalRowsSolved}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <b-pagination @input="changeTabSolved" :total-rows="totalRowsSolved" :per-page="perPageSolved" v-model="currentPageSolved" prev-text="" next-text="" hide-goto-end-buttons />
                            </nav>
                        </div>
                    </div>
                </b-tab>
        </b-tabs>
        </div>
        </div>
        
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Bus from "../../../event-bus";
import permission from "../../../../../server/permission.js";


export default {

    data() {
        return {
            fieldsPending: [
                { key: 'name', label: 'Name', sortable: true },
                { key: 'email', label: 'Email', sortable: true },
                { key: 'type', label: 'Type', sortable: true },
                { key: 'message', label: 'Message', sortable: true },
                { key: 'mobile_number', label: 'Mobile Number', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'date', label: 'Created At', sortable: true },
                { key: 'toggleAction', label: 'Actions', }
            ],
            // fieldsSolved: [
            //     { key: 'name', label: 'Name', sortable: true  , thStyle:  {width: '7%'}},
            //     { key: 'email', label: 'Email', sortable: true  , thStyle:  {width: '10%'}},
            //     { key: 'type', label: 'Type', sortable: true  , thStyle:  {width: '10%'}},
            //     { key: 'message', label: 'Message', sortable: true  , thStyle:  {width: '20%'}},
            //     { key: 'mobile_number', label: 'Mobile Number', sortable: true  , thStyle:  {width: '15%'}},
            //     { key: 'status', label: 'Status', sortable: true  , thStyle:  {width: '7%'}},
            //     { key: 'reply', label: 'Reply', sortable: true  , thStyle:  {width: '10%'}},
            //     { key: 'reply_by', label: 'Reply By', sortable: true  , thStyle:  {width: '10%'}},
            //     { key: 'reply_at', label: 'Reply Date', sortable: true  , thStyle:  {width: '20%'}},
            //     { key: 'toggleAction', label: 'Actions', thStyle:  {width: '5%'}}
            // ],
            fieldsSolved: [
                { key: 'name', label: 'Name', sortable: true},
                { key: 'email', label: 'Email', sortable: true},
                { key: 'type', label: 'Type', sortable: true},
                { key: 'message', label: 'Message', sortable: true},
                { key: 'mobile_number', label: 'Mobile Number', sortable: true},
                { key: 'status', label: 'Status', sortable: true},
                { key: 'reply', label: 'Reply', sortable: true},
                { key: 'reply_by', label: 'Reply By', sortable: true},
                { key: 'reply_at', label: 'Reply Date', sortable: true,thStyle:  {width: '25%'}},
                { key: 'toggleAction', label: 'Actions'}
            ],
            pager: {},
            pageOfItems: [],
            minDate: null,
            date: moment().format('YYYY-MM-DD'),
            format: 'YYYY-MM-DD HH:mm',
            itemsPending:null,
            totalRowsPending: 0,
            fromPending: '',
            toPending: '',
            currentPagePending: 1,
            searchKeywordPending:'',
            perPagePending: 25,
            pageOptionsPending: [25, 50, 75, 100],
            searchKeywordSolved:'',
            itemsSolved:null,
            items:null,
            totalRowsSolved: 0,
            fromSolved: '',
            toSolved: '',
            currentPageSolved: 1,
            perPageSolved: 25,
            pageOptionsSolved: [25, 50, 75, 100],
            auto: true,
            range: false,
            filterPending: '',
            filterSolved:'',
            ticket_type_filter_pending:'',
            ticket_type_filter_solved:'',
            status: 'active',
            sortByPending: null,
            sortDirectionPending: 'desc',
            sortDescPending: true,
            sortBySolved: null,
            sortDirectionSolved: 'desc',
            sortDescSolved: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPagePending:25,
                rowsPerPageSolved:25,
                mode:'',
                type:'',
                status:'',
                tran_type:''

            },
            userFilter:'',
            priorityFilter:'',
            ticketFilter:'',
            userFilterSolved:'',
            priorityFilterSolved:'',
            ticketFilterSolved:'',
            users:[],
            usersSolved:[],
            ticket_type:[],
            actions:'',

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        date_formate: function(date) {
           if(date==''){
                return '';
            }else{
            return moment(date).utc().format('DD MMM YYYY hh:mm A');
            }
        },
       
        
        
        setTicketType: function(value) {
            if (!value) return ''
            var i, frags = value.split('-');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
    },

    components: {
            DateRangePicker,
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$refs.datatablePending.refresh();
                this.$refs.datatableSolved.refresh();
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },

        changeTab()
        {  
            this.getPendingMessagesList().then(() => {
                this.$refs.datatablePending.refresh();
            });
        },
        
        changeTabSolved()
        {   
            this.getSolvedMessagesList().then(() => {
                this.$refs.datatableSolved.refresh();
            });
        },
        deleteMessage(id,status,set_status)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                          status:status,
                          set_status: set_status,
                          remarks: this.remarks,
                        },
                        api:'/api/support-message-change-status-admin',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message = this.$store.getters.getResults.message;
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        changeMessageStatus(id, status,set_status) {
            if (status == 'active') {
                var msg = 'Are you sure want to to mark this record solved?';
                var message='Pending Message Marked Solved Successfully.'
            } 
            else 
            {
                var msg = 'Are you sure want to activate this record?';
                var message='Pending Message Marked Activated Successfully.'
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          id: id,
                          status:status,
                          set_status: set_status,
                        },
                        api:"/api/support-message-change-status-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            var message = this.$store.getters.getResults.message;
                            this.showSuccessAlert(message);
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        getPendingMessagesList() {
            return this.$store.dispatch(POST_API, {
                data:{
                    status: 'active',
                    page: this.currentPagePending,
                    keyword: this.filterPending,
                    rowsPerPage:this.form.rowsPerPagePending,
                    sortBy:this.sortByPending ? this.sortByPending: 'id',
                    sortDirection:this.sortDirectionPending,
                    sortDesc:this.sortDescPending,
                    ticket_type: this.ticket_type_filter_pending
                },
                api: '/api/all-support-message-list-admin'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsPending = this.$store.getters.getResults.pageOfItems;
                    // this.contact = this.$store.getters.getResults.contact;
                    this.ticket_type = this.$store.getters.getResults.ticket_type;
                    this.totalRowsPending = this.$store.getters.getResults.pager.totalItems;
                    this.perPagePending = this.$store.getters.getResults.pager.per_page;
                    this.fromPending = this.$store.getters.getResults.pager.from
                    this.toPending = this.$store.getters.getResults.pager.to;
                    Bus.$emit('get_admin_support_count');
                    return this.itemsPending;
                }
            });
        },
        getSolvedMessagesList() {
            return this.$store.dispatch(POST_API, {
                data:{
                    status: 'solved',
                    page: this.currentPageSolved,
                    keyword: this.filterSolved,
                    rowsPerPage:this.form.rowsPerPageSolved,
                    sortBy:this.sortBySolved ? this.sortBySolved: 'id',
                    sortDirection:this.sortDirectionSolved,
                    sortDesc:this.sortDescSolved,
                    ticket_type: this.ticket_type_filter_solved
                },
                api: '/api/all-support-message-list-admin'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsSolved = this.$store.getters.getResults.pageOfItems;
                    // this.contact = this.$store.getters.getResults.contact;
                    this.ticket_type = this.$store.getters.getResults.ticket_type;
                    this.totalRowsSolved = this.$store.getters.getResults.pager.totalItems;
                    this.perPageSolved = this.$store.getters.getResults.pager.pageSize;
                    this.fromSolved = this.$store.getters.getResults.pager.from
                    this.toSolved = this.$store.getters.getResults.pager.to;
                    Bus.$emit('get_admin_support_count');
                    return this.itemsSolved;
                }
            });
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Messages');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
       
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getPendingMessagesList();
            this.permissionStaff();
     }
}
</script>
