<template>
	<div>
		<!-- Navigation-->
        <header class="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
            <link rel="stylesheet" href="/assets/css/employer.css">
            <ul class="mobile_nav">
                      <li class="scroll-to-section"><a href="#reason" @click="navMenu">Why Us?</a></li>
                      <li class="scroll-to-section"><a href="#about" @click="navMenu">How It Works</a></li>
                      <li class="scroll-to-section"><a href="#clientele" @click="navMenu">Clientele</a></li>
                      <li class="scroll-to-section"><a href="#testimonial" @click="navMenu">Testimonials</a></li>
                      <!-- <li class="scroll-to-section mobile_tab_hide_btn"><div class="enquirebutton"><a href="#enquire">Enquire Now</a></div></li>  -->
            </ul>
            <div class="container header_contaner">
            <div class="row">
                <div class="col-12">
                <nav class="main-nav">
                    <!-- logo -->
                    <b-link href="" :to="{name: 'jobseekers2'}" class="logo my_custom_logo">
                      <img :src="webUrl+'/assets/employerImages/logo.png'"></b-link>
                      <i class="fa fa-bars mobile_bars" @click="showMobileMenu"></i>
                    <ul class="nav my_custom_nav">
                      <li class="scroll-to-section"><a href="#reason">Why Us?</a></li>
                      <li class="scroll-to-section"><a href="#about">How It Works</a></li>
                      <li class="scroll-to-section"><a href="#clientele">Clientele</a></li>
                      <li class="scroll-to-section"><a href="#testimonial">Testimonials</a></li>
                      <li class="scroll-to-section mobile_tab_hide_btn"><div class="enquirebutton"><a href="#contact">Enquire Now</a></div></li>
                    </ul>
                </nav>
                </div>
            </div>
            </div>
        </header>

	</div>
</template>
<!-- Start of HubSpot Embed Code -->

<script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/24329888.js"></script>

<!-- End of HubSpot Embed Code -->




  <!-- Google Tag Manager -->




  <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':




  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],




  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=




  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);




  })(window,document,'script','dataLayer','GTM-NFBKXM7');</script>




  <!-- End Google Tag Manager -->




  <!-- Meta Pixel Code -->

  <script>

    !function(f,b,e,v,n,t,s)

    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?

    n.callMethod.apply(n,arguments):n.queue.push(arguments)};

    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

    n.queue=[];t=b.createElement(e);t.async=!0;

    t.src=v;s=b.getElementsByTagName(e)[0];

    s.parentNode.insertBefore(t,s)}(window, document,'script',

    'https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', '807554476930538');

    fbq('track', 'PageView');

  </script>

  <noscript><img height="1" width="1" style="display:none"

    src="https://www.facebook.com/tr?id=807554476930538&ev=PageView&noscript=1"

  /></noscript>

  <!-- End Meta Pixel Code -->

<script>



export default{
    data() {
		return {
			webUrl: process.env.VUE_APP_URL,
      // showMobileMenu: false,
		}
	},
	methods:{
    showMobileMenu(){
      // $(".mobile_nav").toggleClass("active");
      $(".mobile_nav").slideToggle('slow');
    },
    navMenu(){
      // $(".mobile_nav").removeClass("active");
      $(".mobile_nav").slideUp('slow');
    },
		reveal() {
			var reveals = document.querySelectorAll(".reveal");

			for (var i = 0; i < reveals.length; i++) {
				var windowHeight = window.innerHeight;
				var elementTop = reveals[i].getBoundingClientRect().top;
				var elementVisible = 150;

				if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add("active");
				} else {
				reveals[i].classList.remove("active");
				}
			}
		},
        openLink(){
            window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
        }
	},
	mounted()
    {
      
		window.addEventListener("scroll", this.reveal);
	}
}

</script>
