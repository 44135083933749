<template>
    <!-- Wrapper -->
<div id="wrapper wrapper_pdng">
<div class="back_arrow_icon new_bak_arr back_with_ft_btn">
	<span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
	<!-- <div class="new_edit_ft_btn"> -->
		<b-link class="btn green-border-btn new_edit_ft_btn desktop_margin_top_zero" :to="{name: 'jobseekers-ft-edit', params: {id: this.$route.params.id}}" >Edit Profile</b-link>
	<!-- </div> -->
</div>
<!-- <b-link class="btn green-border-btn" :to="{name: 'jobseekers-ft-edit', params: {id: this.$route.params.id}}" >Edit FT Profile</b-link> -->

<!-- Titlebar
================================================== -->
<!--<div class="single-page-header freelancer-header single_top_bk">
	<div class="single_gradient">
	<div class="container" v-if="items != null">
		<div class="row">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image freelancer-avatar jobseeker_img"><img :src="items.profile_image ? items.profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="profile_image"></div>
						<div class="header-details">
							<h3 class="name_mrgn">{{ items.last_name | capitalize }} {{ items.first_name | capitalize }} <span>{{ items.designation }}</span></h3>
                            <h4 class="new_h4">Short Description</h4>
							<ul >
								<li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false" v-if="items.average_rating != null"></star-rating></li>
								<li><div class="star-rating" data-rating="5.0" v-if="items.average_rating != null"></div></li> 
								<li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li> 
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</div>-->


    <div class="bg_img_top new_bg_img_tp new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( items.banner_image ? items.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
        <div class="grd_div inside_grd height_three_zero_one">
        <div class="container my_new_container">
            <div class="row">
			<div class="col-md-12">
				<div class="">
					<!-- <div class="left-side new_left_sides"> -->
					<div class="left-side full_left_if_right_not_available">
						<!-- <div class="header-image freelancer-avatar jobseeker_img remove_profile_back_pdng"> -->
						<div class="header-image">
							<viewer>
							<img :src="items.ft_profile_image ? items.ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="profile_image" class="set_img_height">
							</viewer>
						</div>
						<div class="header-details">
							<h1 class="name_mrgn header_detail_new_h1 heading_margin_bottom_8 new_daily_paid_jjobs">{{ items.last_name | capitalize }} {{ items.first_name | capitalize }} <span>{{ items.designation }}</span></h1>
                            <!-- <h4 class="new_h4" v-if="items.short_description">{{ items.short_description | capitalize }}</h4> -->
							<ul >
								<!-- <li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false" v-if="items.average_rating != null"></star-rating> -->
								<!-- <li><div class="star-rating" data-rating="5.0" v-if="items.average_rating != null"></div></li> -->
								<!--<li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li> -->
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
					<!-- <div class="right_daily_paid custom_salary_box">
                        <div class="right_inner_contents">
                            <div class="salary-box">
                                <li><i class="icon-material-outline-email"></i><span>Email</span><h5>{{items.email}}</h5></li>
                                <li><i class="icon-line-awesome-mobile-phone"></i><span>Mobile Number</span><h5>{{items.country_code}}-{{items.mobile_number}}</h5></li>
                            </div>
                        </div>
                    </div> -->
					<!--<div class="right-side" v-if="min_salary && max_salary">
                        <div class="right_inner_contents">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
                        </div>
					</div>-->
				</div>
			</div>
		</div>
        </div>
     
        
    </div>
    </div>







<!-- Page Content
================================================== -->
<div class="container job_detail_container  my_new_container">
	<!-- <div class="row"> -->
		<!-- <div class="col-xl-8"> -->
			<!-- <div>
				<span v-if="items.nric"><b>NRIC</b></span><h5>{{items.nric}}</h5>
				<span v-if="items.nationality"><b>Nationality</b></span><h5>{{items.nationality_title}}</h5>
				
				<span v-if="items.expected_salary"><b>Expeted Salary</b></span><h5>{{items.expected_salary}}</h5>
				<span v-if="items.address"><b>Address</b></span><h5>{{items.address}}</h5>
				<span v-if="items.short_description"><b>Short Description</b></span><h5>{{items.short_description}}</h5>
				
			</div> -->
		<!-- </div> -->
		<!-- Content -->
	<div class="row">
		<div class="col-xl-8 col-lg-8  emp_prf single_left_content tab_max_width_and_flex">
			
			<!-- Page Content -->
			<div class="single-page-section" v-if="items != null && items.about != null && items.about != ''"  :class="items != null && items.about != null && items.about != '' ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<h3 class="margin-bottom-25">About Me</h3>
				<p v-html="items.about"></p>
			</div>

			<!-- Boxed List -->
			<div class="boxed-list desktop_margin_top_zero single-page-section" v-if="work_history.length > 0" :class="work_history.length ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<div class="boxed-list-headline boxed_border_unset">
					<h3><i class="icon-material-outline-thumb-up"></i> Work History and Feedback</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li v-for="wr_history in work_history">
						<div class="boxed-list-item">
							<!-- Content -->
							<div class="item-content new_tab_item_content">
								<h4>{{wr_history.title | capitalize}} <span>Rated as Freelancer</span></h4>
								<div class="item-details margin-top-10" v-if="wr_history.rating || wr_history.end_date">
									<div><star-rating v-model="wr_history.rating" v-bind:star-size="20" :show-rating="false"  v-if="wr_history.rating"></star-rating></div>
									<!-- <div class="star-rating" data-rating="5.0" v-if="wr_history.rating"></div> -->
									<div class="detail-item" v-if="wr_history.end_date"><i class="icon-material-outline-date-range"></i> {{wr_history.end_date | moment}}</div>
								</div>
								<div class="item-description" v-if="wr_history.review">
									<p>{{wr_history.review}}</p>
								</div>
							</div>
						</div>
					</li>
				</ul>

				<!-- Pagination -->
				<div class="clearfix"></div>
				<div class="pagination-container margin-top-40">
					<nav class="pagination-box">
						<div class="page-row-box d-inline-block">
							<b-form-group id="input-group-4">
								<span class="d-inline-block">Rows per page</span>
							<span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input="getJobseekers">
											<option>10</option>
											<option>20</option>
											<option>30</option>
											<option>40</option>
								</b-form-select></span>
							</b-form-group>
						</div>
						<div class="total-page-count d-inline-block">
							<ul>
								<li>
									<span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
								</li>
							</ul>
						</div>
						<b-pagination @input="getJobseekers"   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
					</nav>
				</div>
				<div class="clearfix"></div>
				<!-- Pagination / End -->

			</div>
			<!-- Boxed List / End -->
			
			<!-- Boxed List -->
			<div class="boxed-list desktop_margin_top_zero single-page-section" v-if="employment_history.length >0" :class="employment_history.length > 0 ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<div class="boxed-list-headline boxed_border_unset">
					<h3 class="desktop_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Employment History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="em_history in employment_history">
						<div class="boxed-list-item new_tab_item_content" v-if="em_history.job_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="profile_image">
							</div> -->
							
							<!-- Content -->
							<div class="item-content ">
								<h4>{{em_history.job_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="em_history.company_name"><i class="icon-material-outline-business"></i> {{em_history.company_name | capitalize}}</div>
									<!-- <div class="detail-item" v-if="em_history.experience_in_year > 0 || em_history.months > 0"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year > 0 ">{{em_history.experience_in_year }} years</span> <span v-if="em_history.months > 0 ">{{em_history.months}} months</span></div>
									<div class="detail-item" v-else><i class="icon-material-outline-date-range"></i>No Experience</div> -->
									<div class="detail-item" v-if="em_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="em_history.from_year">{{em_history.from_year | educationMonthYear}} - </span><span v-if="em_history.currently == 'yes'">Currently Working</span> <span v-else>{{em_history.to_year | educationMonthYear}} </span></div>
								</div>
									<div class="item-description" v-if="em_history.description">
										<p>{{em_history.description  | capitalize}}</p>
									</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>
			<div class="boxed-list desktop_margin_top_zero margin-bottom-0" v-if="education_history.length > 0" :class="education_history.length > 0 ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<div class="boxed-list-headline boxed_border_unset">
					<h3><i class="icon-line-awesome-graduation-cap"></i> Education History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="ed_history in education_history">
						<div class="boxed-list-item" v-if="ed_history.eq_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->
							
							<!-- Content -->
							<div class="item-content">
								<h4>{{ed_history.eq_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="ed_history.fs_title"><i class="icon-material-outline-business"></i> {{ed_history.fs_title == 'Other' ? ed_history.specified_field_study : ed_history.fs_title | capitalize}}</div>
									<div class="detail-item" v-if="ed_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="ed_history.from_year">{{ed_history.from_year | educationMonthYear}} - </span> <span v-if="ed_history.currently == 'yes'">Currently Studying</span> <span v-else>{{ed_history.to_month | educationMonthYear}}</span></div>
								</div>
								<div class="item-description">
									<p>{{ed_history.un_title  == 'Other' ? ed_history.specified_university : ed_history.un_title | capitalize}}</p>
								</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>
            <!--<div class="boxed-list margin-bottom-60" v-if="education_history.length > 0">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-business"></i> Education History</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li v-for="ed_history in education_history">
						<div class="boxed-list-item">
							 Avatar 
							<div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div>
							
							 Content 
							<div class="item-content">
								<h4>{{ed_history.eq_title}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item"><i class="icon-material-outline-business"></i> {{ed_history.fs_title}}</div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i>{{ed_history.from_year | educationMonthYear}}</div>
								</div>
								<div class="item-description">
									<p>{{ed_history.un_title}}</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div> -->
			<!-- Boxed List / End -->

            <!-- Boxed List -->
			<!-- <div class="boxed-list margin-bottom-60">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-thumb-up"></i> Reviews</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Doing things the right way <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
										<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span>
									</div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> August 2019</div>
								</div>
								<div class="item-description">
									<p>Great company and especially ideal for the career-minded individual. The company is large enough to offer a variety of jobs in all kinds of interesting locations. Even if you never change roles, your job changes and evolves as the company grows, keeping things fresh.</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Outstanding Work Environment <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
									<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span></div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> May 2019</div>
								</div>
								<div class="item-description">
									<p>They do business with integrity and rational thinking. Overall, it's an excellent place to work, with products that are winning in the marketplace.</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div> -->

				

				<!-- <div class="centered-button margin-top-35">
					<b-button variant="success" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="changeUserStatus('approve', items.user_id)">Approve</b-button>&nbsp;
					<b-button variant="danger" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="openModel('reject', items.user_id)">Reject</b-button>
					<a href="#small-dialog"  class="popup-with-zoom-anim button button-sliding-icon">Leave a Review <i class="icon-material-outline-arrow-right-alt review_btn"></i></a> 
				</div> -->

			<!-- Boxed List / End -->
		</div>

		

		<!-- Sidebar -->
		<div class="col-xl-4 col-lg-4  new_sidebar_i_margin_tp single_right_sidebar tab_max_width_and_flex">
			<div class="desktop_margin_bottom_zero sidebar-container mobile_margin_bottom_zero tab_margin_bottom_zero">
				<div class="sidebar-widget margin-bottom-25 mobile_and_tab_apply_btn_top_25"><a class="mobile_margin_bottom_zero mobile_tab_margin_bottom_zero apply-now-button popup-with-zoom-anim  msg_btn view_daily_paid_profile_btn" @click="dailyPaidProfile((items.user_id),$event)">View Daily Paid Profile </a></div>
				<!-- <b-button variant="success" @click="dailyPaidProfile((items.user_id),$event)" style="margin: 10px;">View</b-button> -->
            
                <!-- <div class="sidebar-widget" v-if="social_profile.length > 0 ? social_profile[0].url || social_profile[1].url ||  social_profile[2].url ||  social_profile[3].url : false">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile[0].url"><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile[1].url"><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="social_profile[2].url"><a :href="social_profile[2].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile[3].url"><a :href="social_profile[3].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div> -->
                <!-- Profile Overview 
				<div class="profile-overview">
					<div class="overview-item" v-if="expected_salary"><strong>${{expected_salary}}</strong><span style="white-space: nowrap;">Expected Salary</span></div>
					<div class="overview-item"><strong>53</strong><span>Jobs Done</span></div>
					<div class="overview-item"><strong>22</strong><span>Rehired</span></div>
				</div>-->

				<!-- Button -->
				<!-- <a href="#small-dialog" class="apply-now-button popup-with-zoom-anim margin-bottom-50">Make an Offer <i class="icon-material-outline-arrow-right-alt"></i></a> -->

				<!-- Freelancer Indicators 
				<div class="sidebar-widget">
					<div class="freelancer-indicators">-->

						<!-- Indicator 
						<div class="indicator">
							<strong>88%</strong>
							<div class="indicator-bar" data-indicator-percentage="88"><span></span></div>
							<span>Job Success</span>
						</div>-->

						<!-- Indicator 
						<div class="indicator">
							<strong>100%</strong>
							<div class="indicator-bar" data-indicator-percentage="100"><span></span></div>
							<span>Recommendation</span>
						</div>-->
						
						<!-- Indicator 
						<div class="indicator">
							<strong>90%</strong>
							<div class="indicator-bar" data-indicator-percentage="90"><span></span></div>
							<span>On Time</span>
						</div>	-->
											
						<!-- Indicator 
						<div class="indicator">
							<strong>80%</strong>
							<div class="indicator-bar" data-indicator-percentage="80"><span></span></div>
							<span>On Budget</span>
						</div>
					</div>
				</div>-->
				
				<!-- Widget -->
				<!-- <div class="sidebar-widget" v-if="social_profile.length > 0">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul>
                            <li><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li><a :href="social_profile[2].url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li><a :href="social_profile[3].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                            <li><a :href="social_profile[4].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
						</ul>
					</div>
				</div> -->

				<!-- Widget -->
				<div class="sidebar-widget jobseeker_dp_sidebar desktop_margin_bottom_zero mobile_tab_margin_top_zero">
                <div class="job-overview">
                    <div class="job-overview-headline font_weight_600">Personal Detail</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li><i class="icon-material-outline-email"></i><span>Email</span><h5 class="common_word_break">{{items.email}}</h5></li>
                                <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span>Mobile Number</span><h5>{{items.country_code}}-{{items.mobile_number}}</h5></li>
                            </ul>
                        </div>
                    </div>
                </div>

				

				<!-- <div class="sidebar-widget" v-if="items.account_verification_status">
					<h3>Daily Paid Profile</h3>
					<div>
						<span>{{items.account_verification_status | capitalize}}</span>
						<b-button variant="success" @click="dailyPaidProfile((items.user_id),$event)" style="margin: 10px;">View</b-button>

						<b-link :to="{name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.user_id)}}">View</b-link>
					</div>

				</div> -->
				<!-- <div class="sidebar-widget" v-if="skillArr.length > 0">
					<h3>Skills</h3>
					<div class="task-tags">
						<span v-for="skills in skillArr">{{skills}}</span>
					</div>
				</div> -->
				<!-- Widget -->
				<div class="sidebar-widget common_side_bar_sections_margin new_attachmnet" v-if="cvUrl != ''">
					<h3 class="font_weight_600">Attachments</h3>
					<div class="attachments-container margin-top-0 margin-bottom-0">
                        <a :href="cvUrl" target="_blank">
							<div class="attachment-box ripple-effect cv_profile" v-show="showCV">
                                <span>Cover Letter</span>
								<i v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
								<i v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'">IMAGE</i>
								<i v-else>DOC</i>
                                <!-- <i>PDF</i> -->
                            </div>
                        </a>
						<!-- <a :href="cvUrl" class="attachment-box ripple-effect"><span>Cover Letter</span><i>PDF</i></a> -->
						<!-- <a :href="cvUrl" class="attachment-box ripple-effect"><span>Contract</span><i>DOCX</i></a> -->
					</div>
				</div>
				
                <!-- <div class="sidebar-widget nw_side">
					<h3>Share</h3>
					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border">
						<button class="copy-url-button ripple-effect" @click="copyUrl()" data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
								<li><a href="#" data-button-color="#3b5998" title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							</ul>
						</div>
					</div>
				</div>  -->
				<!-- Sidebar Widget -->
				<!-- <div class="sidebar-widget">
					<h3>Bookmark or Share</h3>

					<button class="bookmark-button margin-bottom-25">
						<span class="bookmark-icon"></span>
						<span class="bookmark-text">Bookmark</span>
						<span class="bookmarked-text">Bookmarked</span>
					</button>

					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border">
						<button class="copy-url-button ripple-effect" data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
								<li><a href="#" data-button-color="#3b5998" title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							</ul>
						</div>
					</div>
				</div> -->

			</div>
		</div>

	</div>
</div>


<!-- Spacer -->
<!-- <div class="margin-top-15"></div> -->
<!-- Spacer / End-->

</div>
<!-- Wrapper / End -->



</template>

<script>

import { POST_API } from '../../../store/actions.type'
import moment from "moment";
import StarRating from 'vue-star-rating'


export default {

    data() {
        return {
            id: atob(this.$route.params.id),
            pager: {},
            pageOfItems: [],
            items:[],
            work_history:[],
            education_history:[],
            employment_history:[],
            social_profile:[],
            totalRows: 0,
            from: '',
            to: '',
			expected_salary:'',
			skillArr:[],
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,20,30,40],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: false,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:10,
            },
            showCV:'',
            cvUrl:''
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD MMM YYYY')
        },
		jobMonth: function(date) {
			return moment(date, 'M').format('MMMM');
        },
		educationMonthYear: function(date) {
            return moment(date).format('MMM YYYY')
        },
    },
    components: {
      StarRating
    },
    methods:{
		async copyURL() {
                    try {
                        // var mytext = $('#copy-url').val();
                        // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                        //     if (result.state == "granted" || result.state == "prompt") {
                        //         navigator.clipboard.writeText(mytext);
                        //     }
                        // });
						let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");
                        
                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        }); 
                        // alert('Copied to Clipboard.');
                    } catch($e) {

                        console.log($e);
                        
                        // alert('Cannot copy');
                    }
              },
			  dailyPaidProfile(id,event){

                if (event) {
                event.stopPropagation()
                }
                 window.location.replace('/v2/admin/database/jobseeker-dp-profile/view/'+ btoa(id));

            },
        getJobseekers() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/jobseeker-profile-view'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.user;
						this.expected_salary = this.$store.getters.getResults.user.expected_salary? this.$store.getters.getResults.user.expected_salary : '';
                        this.education_history = this.$store.getters.getResults.education_history;
                        this.employment_history = this.$store.getters.getResults.employment_history;
                        this.social_profile = this.$store.getters.getResults.social_profile;
                        this.showCV = (this.$store.getters.getResults.user.cv != '' && this.$store.getters.getResults.user.cv != null) ? true : false;
                        this.cvUrl = (this.$store.getters.getResults.user.cv != '' && this.$store.getters.getResults.user.cv != null) ? this.$store.getters.getResults.user.cv : '';
                        this.work_history = this.$store.getters.getResults.work_history;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
						this.skillArr = this.items.skills ? this.items.skills.split(',') : [];
                        return this.items;
                    }
                });
        },
    },
    mounted(){
		$(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
		//document.title = "EL Connect - Admin - Jobseeker Profile";
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.getJobseekers();
        // $('#copy-url').val(window.location.href);
		 $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
    }
}
</script>
