<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Company Posting</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Company Posting</li>
                </ul>
            </nav>
        </div>
        <!-- <b-tabs class="my_tab_job tab_header_action_th daily_deployment_tabs jobseeker_tab ">
            <b-tab :title="'PAST STATS'" @click="changeTabPast"> -->
                <div class="">
                    <div class="row">
                        <div class="col-xl-3  mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <b-input-group-prepend class="common_bg_white">
                                        <!-- <b-form-input id="searchBtn" @keyup.enter.native="filterPast = searchKeywordPast" v-model="searchKeywordPast" placeholder="Search" class="common_bg_white"></b-form-input> -->
                                        <!-- <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="filterPast = searchKeywordPast"></i></b-button> -->
                                    <b-form-input id="searchBtn" :value="searchKeywordPast" @change="v => searchKeywordPast = v" @keyup.enter="getRecentPosting" placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="getRecentPosting"></i></b-button>
                                    </b-input-group-prepend>
                            </div>
                        </div>
                        <!-- <template> -->
                            <div class="col-xl-3  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                                <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                                    <multiselect
                                        id="selectServices"
                                        v-model="company_id"
                                        :options="allStatusCompanies"
                                        :close-on-select="true"
                                        placeholder="Company"
                                        label="name"
                                        track-by="name"
                                        :searchable="true"
                                        :clear-on-select="true"
                                        :allow-empty="true"
                                        open-direction="bottom"
                                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                                        @input="getRecentPosting()"
                                    >
                                    </multiselect>
                                    
                                </b-form-group>
                            </div>
                        <!-- </template> -->
                            
                        <template>
                            <div class="col-xl-3   search_common_margin_bottom_28 mobile_tab_max_width_flex">
                                <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                                    <multiselect
                                        id="selectClosedBy"
                                        v-model="closed_by"
                                        :options="allClosedByWithFullName"
                                        :multiple="false"
                                        placeholder="Closed By"
                                        label="fullName"
                                        track-by="value"
                                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                                        @input="getRecentPosting()"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                        </template>
                            <div class="col-xl-3 calneder_open_is  mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Upload Date</label>  -->
                                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero common_date_range_picker_new">
                                        <date-range-picker class="common_date_range_picker_new  web_bottom_zero transactions_jb_calender calender_div_job_in_job" @update="getRecentPosting"  ref="picker" :maxDate="maxDateUpcoming"  :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeRecentPosting" :ranges='range'>
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | datePicker }} - {{ picker.endDate | datePicker }}
                                            </div>
                                        </date-range-picker>
                                    </b-form-group>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>



                        
                        <div class="col-xl-12 col-md-12 mobile_tab_max_width_flex search_common_margin_bottom_28 deployment_mobile_btn_div">
                            <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">
                                <b-button v-if="(filterPast || searchKeywordPast || company_id || closed_by || (dateRangeRecentPosting.startDate != start_date_filter || dateRangeRecentPosting.endDate != end_date_filter))" @click="clearFilterPast()" class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0" style="background-color: red; margin-top: 20px;"><span class="mobile_reset">Reset filters</span></b-button>
                                <b-button @click="getRecentPosting()" class="mobile_height_unset button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero mr-3" style="width: 120px;">Search</b-button>
                                <b-button class="mobile_height_unset button search_btn_all add_btn_admin_e ripple-effect filter_btn_widht_120  ml-3 mr-3" v-if="show('export')" @click="exportPast()">Export</b-button>
                                <!-- <b-button @click="clearFilterPast()" class="button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero desktop_margin_left_zero" style="background-color: red;">Reset Filter</b-button> -->
                            </div>
                        </div>
                    </div>
                </div>

    <!-- Page Content
    ================================================== -->
    <div class="row">
        <div class="container my_new_container show_filter_arrow" v-if="itemsPast && itemsPast.length > 0">
            <div class="single_accordian daily_deployment_accordian">
                <div class="faq-content-box clearfix">
                    <div class="accordion-listing-box">

                    <!-- Loop through each date -->
                    <b-card v-for="(item, index) in itemsPast" :key="index" no-body>
                        
                        <!-- Date Accordion Header -->
                        <b-card-header header-tag="header" role="tab">
                        <b-button v-b-toggle="'collapse-' + index" variant="info" class="m-1" block>
                            {{ item.date }}
                        </b-button>
                        </b-card-header>

                        <!-- Date Accordion Content -->
                        <b-collapse :id="'collapse-' + index">
                        
                        <!-- Table Heading (Show only once below the date) -->
                        <b-row>
                            <b-col cols="12">
                            <div class=" common_box_shadow_unset table-responsive breakdown_table recent_posting_table">
                                <table class="table table-hover">
                                <thead>
                                    <tr>
                                    <th class=" width_20 text_align_start_table">Company</th>
                                    <th class=" width_20 text_align_start_table">Total Posted</th>
                                    <th class=" width_20 text_align_start_table">Total True Vacancies</th>
                                    <th class="deployed_td width_20 text_align_start_table">Total Deployed</th>
                                    <th class="applicants_td width_20 text_align_start_table">Total Applicants</th>
                                    </tr>
                                </thead>
                                </table>
                            </div>
                            </b-col>
                        </b-row>

                        <!-- Loop through companies for each date -->
                        <b-row v-for="(jobs, companyName, companyIndex) in item.jobs" :key="companyName">
                             <!-- Company Name -->
                              <b-col cols="12">
                                <div class=" common_box_shadow_unset table-responsive breakdown_table recent_posting_table">
                                    <table class="table table-hover">
                                    <!-- <thead>
                                        <tr>
                                            <th class="">Company</th>
                                            <th class="">Total Posted</th>
                                            <th class="deployed_td">Total Deployed</th>
                                            <th class="applicants_td">Total Applicants</th>
                                        </tr>
                                    </thead> -->
                                    <tbody>
                                        <tr>
                                            <td class=" width_20 company_posting_tb">
                                                <span>
                                                    <div class="whole_div_img_and_name">
                                                        <div class="manage_user_img">
                                                            <b-img :src="jobs.jobs[0].job_data.thumbnail != null ? jobs.jobs[0].job_data.thumbnail : (jobs.jobs[0].job_data != null ? jobs.jobs[0].job_data.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon" width="100" height="100">
                                                            </b-img>
                                                        </div>
                                                    <div class="user_name_div">
                                                        <h3 class=""><b>{{ companyName }}</b></h3>
                                                        <p class="grey-text common_br_word">
                                                            {{ jobs.jobs[0].job_data.email | capitalize }}
                                                        </p>
                                                    </div>
                                                    </div>
                                                </span>
                                            </td>
                                            <td class="recent_color  width_20 text_align_start_table">{{ jobs.totalJobTitle }}</td>
                                            <td class="recent_color  width_20 text_align_start_table">{{ jobs.totalTrueVacancies }}</td>
                                            <td class="recent_color deployed_td width_20 text_align_start_table">{{ jobs.totalDeployed }}</td>
                                            <td class="recent_color applicants_td width_20 text_align_start_table">{{ jobs.totalApplicants }}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                              </b-col>
                            <!-- Job Listings for the Company -->
                            <b-col cols="12">
                            <div class=" table-responsive  common_box_shadow_unset">
                                <b-table :items="jobs.jobs" :fields="fieldsPast" class="daily_deployment_tbl table-hover mobile_tr_posting" thead-tr-class="d-none">

                                <!-- Job Title & Other Job Data -->
                                <template #cell(title)="job">
                                    <span v-if="job.item.job_data.status == 'completed'">
                                    <a :href='webUrlV1Admin +"/admin/jobs/view-jobsDetail-completed/" + encrypt(job.item.job_data.id) + "/"+job.item.job_data.status+"/null/null/null"' target="_blank" rel="noreferrer" style="color: #4c8bf5;">
                                        <p>{{ job.item.job_data.title | capitalize }}</p>
                                    </a>
                                    </span>
                                    <span v-else-if="job.item.job_data.status == 'in-progress'">
                                    <a :href='webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(job.item.job_data.id) + "/"+job.item.job_data.status+"/null/null/null"' target="_blank" rel="noreferrer" style="color: #4c8bf5;">
                                        <p>{{ job.item.job_data.title | capitalize }}</p>
                                    </a>
                                    </span>
                                    <span v-else>
                                    <a :href='webUrlV1Admin +"/admin/jobs/view-jobsDetail-upcoming/" + encrypt(job.item.job_data.id) + "/"+job.item.job_data.status+"/null/null/null"' target="_blank" rel="noreferrer" style="color: #4c8bf5;">
                                        <p>{{ job.item.job_data.title | capitalize }}</p>
                                    </a>
                                    </span>
                                </template>

                                <!-- Other Job Fields -->
                                <template #cell(total_jobs_with_same_title)="job">
                                    {{ job.item.job_data.total_jobs_with_same_title }}
                                </template>
                                <template #cell(true_vacancies)="job">
                                    {{ job.item.job_data.true_vacancies ? job.item.job_data.true_vacancies : 0}}
                                </template>
                                <template #cell(job_completed_suspended_count)="job">
                                    {{ job.item.job_data.job_completed_suspended_count }}
                                </template>
                                <template #cell(selected_candidates_data_count)="job">
                                    {{ job.item.job_data.selected_candidates_data_count }}
                                </template>
                                <template v-slot:custom-foot v-if="totalJobsCategoryCountsPast && (totalJobsCategoryCountsPast[item.date].totalJobsCategory == companyIndex + 1)">
                                    <tr v-if="totalCountsPast && totalCountsPast[item.date]" class="">
                                        <!-- <td></td> -->
                                        <td class="total_td_recent_posting width_20"><h3 class="total-count"><b>Total</b></h3></td>
                                        <td v-for="(count, key) in totalCountsPast[item.date]" :key="key" class="width_20">
                                            <h3 class="total-count"><b>{{ count }}</b></h3>
                                        </td>
                                    </tr>
                                    </template>

                                </b-table>
                            </div>
                            </b-col>

                        </b-row>

                        </b-collapse>
                    </b-card>

                    </div>
                </div>
            </div>
            <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPagePast" @input='getRecentPosting'>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                        </b-form-select></span>
                    </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                    <ul>
                        <li>
                            <span v-if='totalRowsPast != 0'>{{fromPast}} - {{toPast}} of {{totalRowsPast}}</span>
                        </li>
                    </ul>
                </div>
                <b-pagination @input='getRecentPosting' :total-rows="totalRowsPast"  :per-page="perPagePast" v-model="currentPagePast" prev-text="" next-text="" hide-goto-end-buttons />
            </nav>
        </div>
        <div class="container my_new_container show_filter_arrow" v-else>
            <div class="table-responsive">
            <table class="table b-table table-striped">
                <tbody>
                    <tr class="b-table-empty-row">
                        <td>
                            <div>
                                <div class="text-center my-2">
                                    There are no records to show
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
            <!-- </b-tab>
        </b-tabs> -->

        </div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'
import Chart from 'chart.js';
import permission from "../../../../../server/permission.js";
import axios from 'axios';

export default {

    data() {
        return {
            closed_by: [], // Selected closed_by array (for multi-select)

            selectedCompanies: [], // Array to hold selected company IDs

            fields: [
                { key: 'last_name', label: 'User Name', sortable: true },
                { key: 'mobile_number', label: 'Mobile Number', sortable: true , thStyle:  {width: '13%'} },
                { key: 'certificate', label: 'Certificate Type', sortable: true },
                // { key: 'certificate_number', label: 'Certificate No.', sortable: true },
                { key: 'obtainment_date', label: 'Obtainment Date', sortable: true },
                { key: 'expiry_date', label: 'Expiry Date', sortable: true },
                { key: 'created_at', label: 'Upload Date', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'rejected_reason', label: 'Rejected Reason', sortable: true },
                { key: 'url', label: 'Certificate', sortable: true },
            ],
            
            
            fieldsPast: [
                { key: 'title', label: 'Company', sortable: false, thStyle:{width: '20%'},  tdClass: 'width_20'},
                { key: 'total_jobs_with_same_title', label: 'Total Posted', sortable: false, thStyle:{width: '20%'}, tdClass: 'width_20'},
                { key: 'true_vacancies', label: 'Total True Vacancies', sortable: false, thStyle:{width: '20%'}, tdClass: 'width_20'},
                { key: 'job_completed_suspended_count', label: 'Total Deployed', sortable: false, thStyle:{width: '20%'}, tdClass: 'width_20'},

                { key: 'selected_candidates_data_count', label: 'Total Applicant', sortable: false, thStyle:{width: '20%'}, tdClass: 'width_20'},
                
            ],

            counts:[{
                'name': '',
                'title': 'Total',
                'vacancies': 0,
                'pending_count': 0,
                'awarded_count': 0,
                'self_withdraw_count': 0,
                'system_withdraw_count': 0,

            }],

            pager: {},
            pageOfItems: [],
            minDate: null,
            maxDate:null,
            center: 'center',
            // date: moment().format('YYYY-MM-DD'),

            items:[],
            itemsGraph:[],
            filter: '',
            totalRows: 0,
            format: 'YYYY-MM-DD HH:mm',
            sortBy: null,
            sortDesc: true,
            sortDirection: 'desc',
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            pageOptions: [25, 50, 75, 100],

            itemsBreakdown:[],
            searchKeywordBreakdown:'',
            totalRowsBreakdown: 0,
            fromBreakdown: '',
            toBreakdown: '',
            currentPageBreakdown: 1,
            perPageBreakdown: 50,
            pageOptionsBreakdown: [25, 50, 75, 100],
            sortByBreakdown: null,
            sortDirectionBreakdown: 'desc',
            filterBreakdown:'',
            sortDescBreakdown: true,

            itemsUpcoming:[],
            totalCountsUpcoming:{},
            totalJobsCategoryCountsUpcoming:'',

            searchKeywordUpcoming:'',
            totalRowsUpcoming: 0,
            fromUpcoming: '',
            toUpcoming: '',
            currentPageUpcoming: 1,
            perPageUpcoming: 50,
            pageOptionsUpcoming: [25, 50, 75, 100],
            sortByUpcoming: null,
            sortDirectionUpcoming: 'desc',
            filterUpcoming:'',
            sortDescUpcoming: false,

            itemsUpcomingV1:[],
            searchKeywordUpcomingV1:'',
            totalRowsUpcomingV1: 0,
            fromUpcomingV1: '',
            toUpcomingV1: '',
            currentPageUpcomingV1: 1,
            perPageUpcomingV1: 50,
            pageOptionsUpcomingV1: [25, 50, 75, 100],
            sortByUpcomingV1: null,
            sortDirectionUpcomingV1: 'desc',
            filterUpcomingV1:'',
            sortDescUpcomingV1: false,

            itemsPast:[],
            totalCountsPast:{},
            totalJobsCategoryCountsPast:'',
            searchKeywordPast:'',
            totalRowsPast: 0,
            fromPast: '',
            toPast: '',
            currentPagePast: 1,
            perPagePast: 50,
            pageOptionsPast: [25, 50, 75, 100],
            sortByPast: null,
            sortDirectionPast: 'desc',
            filterPast:'',
            sortDescPast: false,

            auto: true,
            range: false,
            maxDateUpcoming:moment().format('YYYY-MM-DD'),
            minDateUpcomingV1:moment().format('YYYY-MM-DD'),
            maxDatePast:moment().subtract(1, 'days').format('YYYY-MM-DD'),
           
            
            dateRangeRecentPosting: {
                startDate: '',
                endDate: ''
            },
            pageOptions: [25, 50, 75, 100],
            
            
            dateRangeRecentPosting: {
                // endDate: moment().add(2, 'month').format('YYYY-MM-DD')
                startDate: moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),


            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 0, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },

            webUrl: process.env.VUE_APP_URL,
            webUrlV1: process.env.VUE_APP_URL_V1,

            currentTab:'total',
            totalDeployedArr:[],
            allDatesArr:[],
            form:{
                rowsPerPage:25,
                rowsPerPageBreakdown:100,
                rowsPerPageUpcoming:25,
                rowsPerPageUpcomingV1:50,
                rowsPerPagePast:25,
                status:'',
                userStatus:'all',
            },
            total_uploaded:0,
            total_uploaded_past:0,
            webServerUrl: process.env.VUE_APP_SERVER_URL,
            designation:'',
            attachedCompanies:'',
            allStatusCompanies:[],
            selectedTime:[],
            selectedTimeUpcomingV1:[],
            job_start_time:null,
            job_start_time_upcoming_v1:null,
            allCompanies:[],
            allServiceCategory:[],
            allClosedBy:[],
            company_id:"",
            service_category_id: '',
            closed_by: '',
            fieldsBreakdownDynamic:[],
            start_date_filter:moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
            end_date_filter:moment().format('YYYY-MM-DD'),
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,

            chartData: {
                // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                labels: [],

                datasets: [{
                label: 'Sales Data',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                // data: [65, 59, 80, 81, 56, 55, 40]
                data: []

                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: false,
                    position: 'top', // Position legend at the top
                    labels: {
                        boxWidth: 0, // Hide color box
                    }
                },
                scales: {
                yAxes: [{
                    ticks: {
                    beginAtZero: true,
                    }
                }]
                }
            },
            sum_upcoming_v1 : 0,
            company_id_upcoming_v1:'all',
            closed_by_upcoming_v1:'all',
            actions:'',
            actionsEmployer:'',
            actionsManageDPJobs:''
        }
    },
    computed: {
        allClosedByWithFullName() {
            return this.allClosedBy.map(user => ({
                ...user,
                fullName: `${this.capitalize(user.last_name)} ${this.capitalize(user.first_name)}`,
            }));
        },
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY hh:mm A');
        },

        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        date: function(date) {
            if(date){
                return moment(date).format('DD MMM YYYY');
            }else{
                return '-'
            }
        },
        datePicker: function(value) {
            if (value == null) {
                return '';
            } else {
                return moment(value).format('DD MMM YYYY')
            }
        },
        momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
        momentTime: function(date) {
            if(date != null)
              return moment(date, "HH:mm:ss").format("hh:mm A")
            else
              return '-';
        },
    },

    components: {
            DateRangePicker,
            Datepicker,
            Multiselect,
            Chart
    },

    methods:{
        async fetchClosedBy() {
            try {
                const response = await axios.get('/api/closed_by'); // Update the URL with your API endpoint
                this.allClosedBy = response.data; // Assuming the response contains the closed_by users
            } catch (error) {
                console.error('Error fetching Closed By data:', error);
            }
        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },
        // async fetchCompanies() {
        //     try {
        //         const response = await axios.get('/api/companies'); // Update this URL to your actual API endpoint
        //         this.allStatusCompanies = response.data; // Assuming the response contains the companies
        //     } catch (error) {
        //         console.error('Error fetching companies:', error);
        //     }
        // },
       

        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                
                
                    this.getRecentPosting().then(() => {
                        this.company_id = "";
                this.getAllCompaniesByStatus('past');
                        // this.$refs.datatablePast.refresh();
                    });
                
            });
        },
        dateFilterFunction()
        {
            
                this.getRecentPosting().then(() => {
                this.company_id = '';
                this.getAllCompaniesByStatus('past');
                    // this.$refs.datatablePast.refresh();
                });
            
        },
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },

        changeTabPast()
        {
            this.currentTab='past';
            this.company_id = '';
            this.service_category_id = '';
            this.closed_by = '';
            this.job_start_time = null;
            this.selectedTime = [];
            this.getAllCompaniesByStatus('past');
            this.getRecentPosting().then(() => {
                // this.$refs.datatablePast.refresh();
            });
        },
        
        clearFilterPast(){
            this.filterPast = '';
            this.searchKeywordPast = '';
            this.company_id = '';
            this.service_category_id = '';
            this.closed_by = '';
            this.job_start_time = null;
            this.selectedTime = [];
            this.dateRangeRecentPosting.startDate = moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD')
            this.dateRangeRecentPosting.endDate = moment().format('YYYY-MM-DD'),
            this.getRecentPosting();
            this.dateFilterFunction();
        },
        // exportTotal(){
        //     var base_url = this.webServerUrl;
        //     var page= this.currentPage;
        //     var rowsPerPage= this.form.rowsPerPage;
        //     var start_date= this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '';
        //     var end_date= this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') :  '';
        //     var sortBy=  this.sortBy ? this.sortBy : 'user_certificates.created_at';
        //     var sortDirection=this.sortDesc ? 'asc' : 'desc';
        //     var export_url = '/api/daily-deployment-total-excel/'+page+'/'+rowsPerPage+'/'+start_date+'/'+end_date+'/'+sortBy+'/'+sortDirection;
        //     window.open(base_url+export_url,'_blank');
        //     // window.open(export_url,'_blank')

        // },
        
        exportPast(){
                var base_url = this.webServerUrl;
                var page= this.currentPagePast;
                var rowsPerPage= this.form.rowsPerPagePast;
                var keyword = this.searchKeywordPast ? this.searchKeywordPast : null;
                var start_date= this.dateRangeRecentPosting.startDate ? moment(this.dateRangeRecentPosting.startDate).format('YYYY-MM-DD') : '';
                var end_date= this.dateRangeRecentPosting.endDate ? moment(this.dateRangeRecentPosting.endDate).format('YYYY-MM-DD') : '';

                var sortBy=  this.sortByPast ? this.sortByPast : 'jobs.start_date';
                var sortDirection=this.sortDescPast ? 'desc' : 'asc';
                var attachedCompanies = (this.user_type_id == 2) && (this.designation == 'external agent') ? this.attachedCompanies :(this.company_id != '' ? this.company_id : 'null');
                // var company_id= this.company_id ? this.company_id : 'null'
                var company_id = this.company_id && this.company_id.id ? this.company_id.id
                    : "null"
                // var closed_by= this.closed_by ? this.closed_by : 'null'
                var closed_by= this.closed_by && this.closed_by.id ? this.closed_by.id : 'null'
                var time = (this.job_start_time ? this.job_start_time.map(obj => obj.start_time):  'null');
                var export_url = '/api/company-posting-excel/'+keyword+'/'+company_id+'/'+attachedCompanies+'/'+page+'/'+rowsPerPage+'/'+start_date+'/'+end_date+'/'+closed_by+'/'+sortBy+'/'+sortDirection;
                window.open(base_url+export_url,'_blank')
        },

        
         getRecentPosting() {
            console.log(this.company_id,'this.company_id')
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPagePast,

                    keyword: this.searchKeywordPast,
                    type:this.currentTab,
                    start_date: this.dateRangeRecentPosting.startDate ? moment(this.dateRangeRecentPosting.startDate).format('YYYY-MM-DD') : '',
                    end_date: this.dateRangeRecentPosting.endDate ? moment(this.dateRangeRecentPosting.endDate).format('YYYY-MM-DD') : '',
                    // start_date:'2021-01-01',
                    // end_date:'2025-12-31',
                    rowsPerPage:this.form.rowsPerPagePast,
                    sortBy: this.sortByPast ? this.sortByPast: 'id',
                    sortDirection:this.sortDescPast ? 'desc' : 'asc',
                    sortDesc:this.sortDescPast ? true : false,
                    from_v2: 'yes',
                    logged_in_user: this.user_id,
                    start_time: (this.job_start_time ? this.job_start_time.map(obj => obj.start_time):  null),
                    // company_id:this.company_id,
                    company_id: this.company_id && this.company_id.id ? this.company_id.id : "",
                    // closed_by:this.closed_by,
                    closed_by:this.closed_by && this.closed_by.id ? this.closed_by.id : "",
                    service_categories_id:this.service_category_id,
                    attachedCompanies:this.attachedCompanies
                },
                api: '/api/company-posting-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsPast = this.$store.getters.getResults.pageOfItems;
                    this.totalCountsPast = this.$store.getters.getResults.totalCounts;
                    this.totalJobsCategoryCountsPast = this.$store.getters.getResults.totalJobsCategoryCounts;

                    this.totalRowsPast = this.$store.getters.getResults.pager.totalItems;
                    this.perPagePast = this.$store.getters.getResults.pager.pageSize;
                    this.fromPast = this.$store.getters.getResults.pager.from;
                    this.toPast = this.$store.getters.getResults.pager.to;
                    this.getAllCompaniesByStatus('upcoming');
                    this.getAllClosedByByStatus();

                    // const distinctStartTimes = this.itemsPast.flatMap(page =>
                    // Object.values(page.jobs)
                    //     .flatMap(jobArray => jobArray.map(job => job.job_data.start_time))
                    //     ).filter((start_time, index, self) =>
                    //         index === self.findIndex(t => t === start_time)
                    //     ).map(start_time => ({ start_time })); // Add a key named start_time for each value

                    // this.selectedTime = distinctStartTimes;

                    // let pastCountArray = [];

                    // this.itemsPast.forEach(item => {
                    //     Object.values(item.jobs).forEach(jobs => {
                    //         jobs.forEach(job => {
                    //             pastCountArray.push(job.job_data.job_completed_suspended_count);
                    //         });
                    //     });
                    // });

                    // this.total_uploaded_past = pastCountArray.reduce((total, count) => total + count, 0) > 0 ? pastCountArray.reduce((total, count) => total + count, 0) : 0;
                    // console.log("Total Job Past Count:", this.total_uploaded_past);
                    return this.itemsPast;
                }
            });
        },

        getAllCompaniesByStatus(status){
            var start_date = '';
            var end_date = '';
            console.log('dddd',status)
            if(status == 'upcoming'){
                start_date = this.dateRangeRecentPosting.startDate ? moment(this.dateRangeRecentPosting.startDate).format('YYYY-MM-DD') : '';
                end_date = this.dateRangeRecentPosting.endDate ? moment(this.dateRangeRecentPosting.endDate).format('YYYY-MM-DD') : '';
            
            // }else if(status == 'past'){
            //     start_date = this.dateRangeRecentPosting.startDate ? moment(this.dateRangeRecentPosting.startDate).format('YYYY-MM-DD') : '';
            //     end_date = this.dateRangeRecentPosting.endDate ? moment(this.dateRangeRecentPosting.endDate).format('YYYY-MM-DD') : '';
           
            return this.$store.dispatch(POST_API, {
                 data:{
                    start_date : start_date,
                    end_date : end_date,
                    status: 'company_posting',
                    date:this.dateRangeBreakdown,
                    from_v2: 'yes',
                    logged_in_user: this.user_id
                 },
                 api: '/api/daily-deployment-companies-by-status'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  } else {
                      this.allStatusCompanies = this.$store.getters.getResults.data;
                  }
              });
        }
        },
        
        getAllClosedByByStatus(){
              return this.$store.dispatch(POST_API, {
                 data:{
                 },
                 api: '/api/daily-deployment-closedby-by-status'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  } else {
                      this.allClosedBy = this.$store.getters.getResults.data;
                  }
              });
        },
        // getAllCompanies(){
        //     this.$store.dispatch(POST_API, {
        //         data:{
        //         },
        //         api:'/api/employer-job-list'
        //     })
        //     .then(() => {
        //         if (this.$store.getters.containsErrors) {
        //             this.error_message = this.$store.getters.getErrors;

        //             this.$swal({
        //                 position: 'center',
        //                 icon: 'error',
        //                 title: this.error_message,
        //                 showConfirmButton: false,
        //                 timer: 1500
        //             });

        //         } else {
        //             this.allCompanies = this.$store.getters.getResults.data;
        //         }
        //     });
        // },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Company Posting');
               this.actionsEmployer = permission.getPermissionStaff(menu,'Companies');
               this.actionsManageDPJobs = permission.getPermissionStaff(menu,'DP Jobs Admin');

              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showEmployer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showManageDPJobs(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
            }, "fast");
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');

            this.designation = this.$store.getters.currentUser.designation ? this.$store.getters.currentUser.designation.toLowerCase() : '';
            this.attachedCompanies = (this.$store.getters.currentUser.attached_with_company != null ? this.$store.getters.currentUser.attached_with_company : 'null') ;
            this.changeTabPast();
            this.permissionStaff();

            // this.fetchCompanies(); // Fetch the companies when the component mounts
            


    }
}
</script>
