<template>

    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->
        <div class="">
            <!-- <div class="dashboard-headline"> -->
                <!-- <div class="back_arrow_icon">
                    <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
                </div> -->
                <!-- <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Job</h1>
                <nav id="breadcrumbs" class="dark employer_nav">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Full Time Jobs</a></li>
                            <li><a href="#">Job Detail</a></li>
                            <li>Edit Job</li>
                        </ul>
                    </nav> -->
            <!-- </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="row">
            <b-form method="post" id="register-account-form" class=" nw_frm">
                <!-- <p id="error"></p> -->
				<!-- Dashboard Box -->

                <div class="col-xl-12">
                    <div class="dashboard-box desktop_margin_top_zero">
                        <!-- Headline -->
                        <div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> Job Submission Form</h3>
                        </div>

                       <div class="content">
                            <ul class="fields-ul second_indiv_step_wrap">
                                <li class="common_padding_30">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="submit-field">
                                                <h5>Employer <span class="required_star">*</span></h5>
                                                <b-form-select disabled v-model="form.company_id" id="job_employer" class="job_employer desktop_margin_bottom_zero">
                                                    <option value="" disabled>Select Employer</option>
                                                    <option :value="employer.company_id" v-for="employer in employers">{{employer.name | capitalize}}({{employer.last_name | capitalize}} {{employer.first_name | capitalize}})</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Employer !</span>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Job Title <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.title"
                                                    id="company_reg_no"
                                                    class="input-text with-border required-field"
                                                    name="company_reg_no"
                                                    placeholder=""
                                                    value=""
                                                    @input="form.notify_updated = true"
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Job Title !</span>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Job Role Type <span class="required_star">*</span></h5>
                                                <b-form-select v-model="form.role_type_id"  id="job_role_type" class="job_role_type" @change="salaryTypeData(form.role_type_id); form.notify_updated = true">
                                                    <option value="" disabled>Select Role Type</option>
                                                    <option :value="role_type.id" v-for="role_type in role_types">{{role_type.name | removeUnderscore}}</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Job Role Type !</span>
                                        </div>
                                        <div class="col-xl-2 minMaxSalary">
                                            <div class="submit-field">
                                                <h5>{{salaryType}} </h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.min_salary"
                                                    id="minSal"
                                                    class="input-text with-border"
                                                    name="office_address"
                                                    placeholder="Min (SGD)"
                                                    value=""
                                                    @input="form.notify_updated = true"
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Min (SGD) Amount !</span>

                                        </div>
                                        <div class="col-xl-2 minMaxSalary">
                                            <div class="submit-field">
                                                <h5 class="hidden_h5">Salary</h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.max_salary"
                                                    id="maxSal"
                                                    class="desktop_margin_top_zero input-text with-border big_screen_margin_top_zero mobile_tab_margin_top_zero"
                                                    name="office_address"
                                                    placeholder="Max (SGD)"
                                                    value=""
                                                    @input="form.notify_updated = true"
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Max (SGD) Amount !</span>
                                        </div>
                                        <!-- <div class="col-xl-4 perHourRate">
                                            <div class="submit-field">
                                                <h5>Per Hour Rate <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"

                                                    id="per_hour"
                                                    class="input-text with-border required-field"
                                                    name="office_address"
                                                    placeholder="Per Hour Rate (SGD)"
                                                    value=""
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Per Hour Rate !</span>
                                        </div> -->

                                        <div class="tags_div">
                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Tags</h5>
                                                    <!--<b-form-tags input-id="tags-basic" v-model="form.tagArr" ></b-form-tags>-->
                                            <b-form-tags v-model="form.tagArr" no-outer-focus class="mb-2 new_tag_ul">
                                    <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                                        <b-input-group class="mb-2 full_skill_border hundered_width hundered_width desktop_margin_bottom_28">
                                        <b-form-input
                                            v-bind="inputAttrs"
                                            v-on="inputHandlers"
                                            placeholder="Add Tags"
                                            class="form-control remove_shadow tag_input_check" @keydown.enter.prevent="tagScroll();"
                                        ></b-form-input>
                                        <b-input-group-append class="plus_div new_plus_div">
                                            <b-button @click="addTag(); tagScroll()" variant="primary" class="add_tag_plus_btn">+</b-button>
                                        </b-input-group-append>
                                        </b-input-group>
                                        <div class="d-inline-block desktop_width_hundered selected_tag_full_div" style="font-size: 1.5rem;" v-if="form.tagArr.length>0">
                                        <h5>Selected Tags</h5>
                                        <div class="tag_blocks_section d-inline-block" style="font-size: 1.5rem;" :class="form.tagArr.length>2 ? 'inner_scroll' : 'not_scroll'">
                                        <b-form-tag
                                            v-for="tag in tags"
                                            @remove="removeTag(tag)"
                                            :key="tag"
                                            :title="tag"
                                            :variant="tagVariant"
                                            class="mr-1"
                                        >{{ tag }}</b-form-tag>
                                        </div>
                                        </div>
                                    </template>
                                    </b-form-tags>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="cat_div">
                                        <div class="top_inline_div">
                                        <div class="col-xl-4 cat_inner_divs">
                                            <div class="submit-field">
                                                <h5>Job Category <span class="required_star">*</span></h5>
                                                <b-form-select v-model="form.service_categories_id" id="jbs_category" class="jbs_category" @change="showCategory(form.service_categories_id); form.notify_updated = true">
                                                    <option value="" disabled>Select Category</option>
                                                    <option :value="category.id" v-for="category in categories">{{category.name |capitalize}}</option>
                                                </b-form-select>
                                            </div>
                                            <span class="error_x_white">Please enter Job Category !</span>




                                        </div>
                                        <div class="col-xl-4 cat_inner_divs other_category_class">
                                            <div class="submit-field">
                                                <h5>Specify Job Category</h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="form.other_service_category"
                                                    class="input-text with-border"
                                                    name="company_reg_no"
                                                    placeholder="Please Specify Category"
                                                    value=""
                                                    @input="form.notify_updated = true"
                                                />
                                            </div>
                                        </div>
                                        </div>

                                        <div class="top_inline_div">
                                            <div class="col-xl-4 cat_inner_divs">
                                                <div class="submit-field">
                                                    <h5>Location <span class="required_star">*</span></h5>
                                                    <select v-model="form.location_id" id="job_location" class="job_location custom-select" @change="showLocation(form.location_id); form.notify_updated = true">
                                                        <option value='' selected='selected' disabled>Select Location</option>
                                                        <optgroup :label="location.title" v-for="location in locations">
                                                            <option :value="location.location_names_id[index]" v-for="(area,index) in location.location_names_val">{{area | capitalize}}</option>
                                                        </optgroup>
                                                    </select>
                                                    <!-- <b-form-select v-model="form.location_id" id="job_location" class="job_location">
                                                        <option value="" disabled>Select Location</option>
                                                        <option :value="location.id" v-for="location in locations">{{location.title | capitalize}}</option>
                                                    </b-form-select> -->
                                                </div>
                                                <span class="error_x_white">Please enter Location !</span>

                                            </div>

                                            <div class="col-xl-4 cat_inner_divs other_location_class">
                                                <div class="submit-field">
                                                    <h5>Specify Location</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.other_job_location"
                                                        class="input-text with-border"
                                                        name="company_reg_no"
                                                        placeholder="Please Specify Location"
                                                        value=""
                                                        @input="form.notify_updated = true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    <div class="col-xl-12">
                                            <div class="submit-field">
                                                <h5>Job Description <span class="required_star">*</span></h5>
                                                <ckeditor v-model="form.description" :config="editorConfig" id="jb_description" class="jb_description" @input="form.notify_updated = true"></ckeditor>
                                                    <!-- <textarea type="text" class="with-border" value="" v-model="form.description"></textarea> -->
                                            </div>
                                                <span class="error_x_white">Please enter job description !</span>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="submit-field">
                                                <h5>Minimum Qualification <span class="required_star">*</span></h5>
                                                <b-form-select v-model="form.qualification_id" id="job_qualification" class="job_qualification" @change="form.notify_updated = true">
                                                    <option value="" disabled>Select Qualification</option>
                                                    <option :value="qualification.id" v-for="qualification in qualifications">{{qualification.title | capitalize}}</option>
                                                </b-form-select>
                                        </div>
                                        <span class="error_x_white">Please enter Qualification !</span>
                                    </div>

                                    <div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Minimum Year of Experience <span class="required_star">*</span></h5>
                                            <select v-model="form.experience" class="custom-select min_year_exp" id="min_year_exp" @change="form.notify_updated = true">
                                                <option value="" disabled>Select</option>
                                                <option :value="experience_yr" v-for="(experience_yr,id) in experience_yrs" :key="id">{{experience_yr}}</option>
                                            </select>
                                        </div>
                                        <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                                    </div>

                                    <div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Position Level <span class="required_star">*</span></h5>
                                            <b-form-select v-model="form.position_level_id" id="position_level" class="position_level" @change="form.notify_updated = true">
                                                <option value="" disabled>Select Position Level</option>
                                                <option :value="position_level.id" v-for="position_level in position_levels">{{position_level.title | capitalize}}</option>
                                            </b-form-select>
                                        </div>
                                        <span class="error_x_white">Please enter Position Level !</span>
                                    </div>

                                    <!-- <div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Job Posting Package</h5>
                                            <b-form-select v-model="form.package">
                                                <option value="" disabled>Select Posting Package</option>
                                                <option value="free">30 days - Free Post - {{form.packageName}} ({{remaining_jobs}} Remaining)</option>
                                                <option value="coins">30 days - 800 ELC Credits</option>                                                            </b-form-select>
                                        </div>
                                    </div> -->

                                    <div class="col-xl-4">
                                        <div class="submit-field desktop_margin_bottom_zero">
                                            <h5>Job Publish Date <span class="required_star">*</span></h5>
                                            <datepicker v-model="form.scheduled_post" :open-date="new Date()"   :format="customFormatterTwo" id="scheduled_post" class="scheduled_post add_calender_icon show_calender_top" @input="form.notify_updated = true"></datepicker>
                                        </div>
                                        <span class="error_x_white">Please enter Job Publish Date !</span>
                                    </div>
                                            <div class="col-xl-4">
                                                <div class="submit-field desktop_margin_bottom_zero">
                                                    <h5 class="common_opacity_zero">Featured ad</h5>
                                                    <b-form-checkbox v-model='form.feature_job' value="yes" unchecked-value="no" class="middle_checkbox opacity_unset"><strong>Featured ad </strong></b-form-checkbox>
                                                </div>
                                            </div>
                                    <b-container class="single_accordian">

                                        <!-- <br> -->
                                        <div >
                                            <div class="faq-content-box clearfix">
                                                <div >
                                                    <div class="accordion-listing-box ">

                                                        <b-card no-body >
                                                            <b-card-header header-tag="header" role="tab" class="contact_header">
                                                                <b-button v-b-toggle="'accordion-emergency-contact-details-admin-edit-1'" class="m-1" variant="info">
                                                                    <div class="dashboard-box">
                                                                        <div class="headline">
                                                                            <h3><i class="material-icons-outlined new_icon_sidebar_material accordian_contact">mail_outlined</i>Email Notifications for updates (max 3 users)</h3>

                                                                        </div>
                                                                    </div>
                                                                </b-button>
                                                            </b-card-header>
                                                            <b-collapse visible id="accordion-emergency-contact-details-admin-edit-1" accordion="my-accordion" role="tabpanel" class="error_mrgn">
                                                                <b-card-body>
                                                                    <div class="content">

                                                                                <div class="row">
                                                                                    <div class="col-xl-4">
                                                                                        <b-form-group>

                                                                                            <b-form-checkbox v-model = 'form.admin_notify' value="yes" unchecked-value="no" @change="form.notify_updated = true"><strong>Admin to receive notification? </strong></b-form-checkbox>
                                                                                        </b-form-group>
                                                                                    </div>
                                                                                </div>


                                                                                <div  v-for="(user,index) in form.users" :key="index">
                                                                                    <!-- <div class="row"> -->

                                                                                        <!-- <div class="col-xl-4">
                                                                                            <div class="submit-field web_bottom_zero">
                                                                                                <h5>Select User</h5>

                                                                                            </div>

                                                                                        </div>

                                                                                        <div class="col-xl-4 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28">
                                                                                            <div class="submit-field web_bottom_zero">
                                                                                                <h5>Email Address</h5>
                                                                                            </div>

                                                                                        </div> -->
                                                                                        <!-- <div class="col-xl-4 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28"> -->
                                                                                            <!-- <a href="javascript:void(0);" class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom" @click="addUser()" v-if="form.users.length <=2"><span class="add_plus">+</span> Add More</a> -->
                                                                                        <!-- </div> -->

                                                                                    <!-- </div> -->
                                                                                    <div class="row" v-if="company_users.length > 0">
                                                                                        <div class="col-xl-4 custom_tab_and_big_screen_col_md_4">
                                                                                            <div class="submit-field" :class="index > 0 ? 'if_email_notification_exist' : 'if_email_notification_not_exist'">

                                                                                                <b-form-select data-size="7" title="Select User" v-model="user.user_id" class="custom-select web_bottom_zero" id="sel_user" @change="form.notify_updated = true">
                                                                                                    <option value="">Select User</option>
                                                                                                    <option :value="cu.id" v-for="(cu,i) in company_users" :disabled="checkDisabled(cu.id)" :key="i">{{cu.full_name}}</option>

                                                                                                </b-form-select>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-xl-4 custom_tab_and_big_screen_col_md_4">
                                                                                            <div class="submit-field" :class="index > 0 ? 'if_email_notification_exist' : 'if_email_notification_not_exist'">

                                                                                                <input type="text" readonly :value="filterUser(user.user_id)" class="with-border web_bottom_zero" id="user_email" placeholder="User Email">
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="mobile_tab_margin_top_zero red_div col-xl-4 custom_tab_and_big_screen_col_md_4">
                                                                                            <!-- <a href="javascript:void(0);" class="button margin_top_zero_993 ripple-effect  mobile_anchore_set common_vertical_align_bottom" @click="removeUser(index)" style="background-color: red;"  ><span class="add_plus">x</span>Remove</a> -->
                                                                                            <a href="javascript:void(0);" class="new_plus_btn_round  button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom" @click="addUser()" v-if="form.users.length <=2 && index==0"><span class="add_plus material-icons-outlined">add_circle_outlined</span></a>

                                                                                            <a href="javascript:void(0);" class="new_plus_btn_round  button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom minus_margin_top_btn" @click="removeUser(index)" v-if="index > 0"><span class="add_plus material-icons-outlined red_icon">remove_circle_outlined</span></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-container>



                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xl-12">
                    <a @click="submit" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn">Update Job</a>
                </div>
                <!-- Button -->
            </b-form>
		    </div>
        </div>

        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->

    </div>

</template>

<script>
    import { POST_API } from "../../../store/actions.type"
    import Multiselect from 'vue-multiselect'
    import CKEditor from 'ckeditor4-vue';
    import Datepicker from 'vuejs-datepicker';
    import moment from "moment";

    export default {

        data() {
            return {
                form:{
                    title: '',
                    type: 'full_time',
                    role_type_id:'',
                    service_categories_id:'',
                    location :'',
                    min_salary :'',
                    max_salary: '',
                    tagArr: [],
                    qualification_id:'',
                    employer_id:'',
                    location_id:'',
                    other_job_location:'',
                    position_level_id:'',
                    qualification:'',
                    experience: '',
                    level: '',
                    package: '',
                    scheduled_post: '',
                    user_id: '',
                    company_id:'',
                    users: [{
                        user_id:''
                    }],
                    admin_notify: 'no',
                    notify_updated:false,
                    feature_job: false
                },
                salaryType:'Salary',
                part_time_id:'',
                other_location_id:'',
                other_category_id:'',
                employers:[],
                qualifications:[],
                locations:[],
                position_levels: [],
                categories: [],
                role_types:[],
                experience_yrs: ['No experience', 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
                job_id: atob(this.$route.params.id),
                tag_data:[],
                showDismissibleAlert:false,
                error_message:'',
                isActive: 1,
                personal:true,
                links:false,
                disabled:true,
                disabledFromDate: {
                    to: new Date(Date.now() - 8640000)
                },
                editorConfig: {
                versionCheck : false,
                 removeButtons:'Maximize,Paste,Cut,Copy,Undo,Redo,SpellCheck,Link,Unlink,Anchor,Image,Table,Source,Subscript,Superscript,Blockquote,Styles,PasteText,RemoveFormat,Scayt,PasteFromWord,RemoveFormat,Blockquote,HorizontalRule,SpecialChar,Strike,About,Format',
                 //extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
                },
                coreStyles_bold: {
                    element: 'b',
                    overrides: 'strong'
                },
                // Custom style definition for the Italic feature.
                coreStyles_italic: {
                    element: 'i',
                    overrides: 'em'
                },
                company_users:[]
            }
        },
        components: {
            ckeditor: CKEditor.component,
            Multiselect,
            Datepicker
        },

        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },

        methods:{
            breadCrumb(){
                var item = [{
                    to:{name:'fulltime-job-list'},
                    text: 'Job Ads',
                },{
                    to:null,
                    text: 'Edit Job'
                }];
                return item;
            },
            tagScroll(){
                if ($('.tag_input_check').val() != '') {
                    $( ".inner_scroll" ).animate({scrollLeft:'+=1500'},500);
                }
                $( ".not_scroll" ).animate({scrollLeft:'+=1500'},500);
            },
            activeTab(){
                this.personal = true;
                this.links = false;
            },

            showSalary(id){
                if(id == this.part_time_id){
                    $('.perHourRate').css('display', 'block');
                    $('.minMaxSalary').css('display', 'none');
                }else{
                    $('.perHourRate').css('display', 'none');
                    $('.minMaxSalary').css('display', 'block');
                }
            },
            showCategory(id){
                if(id == this.other_category_id){
                    $('.other_category_class').css('display', 'block');
                }else{
                    $('.other_category_class').css('display', 'none');
                }
            },
            showLocation(id){
                if(id == this.other_location_id){
                    $('.other_location_class').css('display', 'block');
                }else{
                    $('.other_location_class').css('display', 'none');
                }
            },

            customFormatterTwo(date) {
                return moment(date).format('DD-MM-YYYY');
            },

            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },

            getDropdownData(type){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/dropdown-list-category'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.categories = this.$store.getters.getResults.data;
                        this.other_category_id = this.$store.getters.getResults.other_category_id;
                        $('.other_category_class').css('display', 'none');

                    }
                });
            },
            removeTag(value) {

                var id = value.id;
                var selected_record = this.subvalue;
                var new_array = [];
                for (var i = 0; i <= selected_record.length - 1; i++) {
                    if (selected_record[i].parent_category != value.id) {
                        new_array.push(selected_record[i]);
                    }
                }
                this.subvalue = new_array;
            },

            salaryTypeData(id){


                if(id == '4'){
                    this.salaryType = 'Per Hour Rate';
                }else{
                    this.salaryType = 'Salary';
                }
            },

            submit() {

                var company_reg_no=$('#company_reg_no').val();
                var job_role_type=$('#job_role_type').val();
                var job_employer =$('#job_employer').val();

                var minSal=$('#minSal').val();
                var maxSal=$('#maxSal').val();
                var per_hour=$('#per_hour').val();
                var cke_editor1=$('#cke_editor1').val();
                var jbs_category =$('#jbs_category').val();
                var job_location =$('#job_location').val();
                var job_qualification =$('#job_qualification').val();
                var min_year_exp =$('#min_year_exp').val();
                var position_level =$('#position_level').val();
                var scheduled_post =$('#scheduled_post').val();
                var err = false;

            $('.required-field').each(function () {

                if ($(this).val() == '') {
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.job_employer').each(function () {

                if( !$('#job_employer').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });
            $('.job_role_type').each(function () {

                if( !$('#job_role_type').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });


                 if( $('#maxSal').val() ){
                     if(!$('#minSal').val()){
                        err = true;
                         $('#minSal').addClass('error');
                        $('#minSal').parent().parent().find('.required_content').addClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {
                        $('#minSal').removeClass('error');
                        $('#minSal').parent().parent().find('.required_content').removeClass('show');
                        $('#minSal').parent().parent().find('.error_x_white').removeClass('show');
                }

                }

                if( $('#minSal').val() ){
                     if(!$('#maxSal').val()){
                        err = true;
                         $('#maxSal').addClass('error');
                        $('#maxSal').parent().parent().find('.required_content').addClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').addClass('show');
                     } else {
                        $('#maxSal').removeClass('error');
                        $('#maxSal').parent().parent().find('.required_content').removeClass('show');
                        $('#maxSal').parent().parent().find('.error_x_white').removeClass('show');
                    }

                }

            $('.jbs_category').each(function () {

                if( !$('#jbs_category').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }



            });
             $('.job_location').each(function () {

                if( !$('#job_location').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });
            $('.job_qualification').each(function () {

                if( !$('#job_qualification').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.min_year_exp').each(function () {

                if( !$('#min_year_exp').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.position_level').each(function () {

                if( !$('#position_level').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $('.scheduled_post').each(function () {

                if( !$('#scheduled_post').val() ){
                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {
                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

            $("#job_employer").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });

            $("#job_role_type").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#jbs_category").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#job_location").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#job_qualification").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#min_year_exp").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $("#position_level").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            $(".scheduled_post").focusin(function(){
               $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            // $(".scheduled_post").focusout(function(){
            //    $(this).addClass('error');
            //    $(this).parent().parent().find('.error_x_white').addClass('show');
            // });

           $("#package").focusout(function(){
                        if($('#package').val() === ""){
                            $('#package').addClass('invalid');
                            $('#package-warning-message').show();
                        }else{
                        $('#package').removeClass('invalid');
                        $('#package-warning-message').hide();
                        }
                    });




            setTimeout(function ()
            {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show');
                    });
            }, 100);


            if(this.form.max_salary && this.form.min_salary){

                if(parseInt(this.form.max_salary) < parseInt(this.form.min_salary)){

                    err = true
                    this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: 'Max salary cannot be less than min salary.',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }


                }


            if(this.form.service_categories_id == this.other_category_id){

                    if(!this.form.other_service_category){
                        err = true
                        this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Please specify the category.',
                                showConfirmButton: false,
                                timer: 1500
                            });
                    }
                }

             if(this.form.location_id == this.other_location_id){

                    if(!this.form.other_job_location){
                        err = true
                        this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Please specify the location.',
                                showConfirmButton: false,
                                timer: 1500
                            });
                    }
                }


                // if(this.form.role_type_id == this.part_time_id){
                //     this.form.min_salary = 0
                // }
                if(err == false){
                    this.$store.dispatch(POST_API, {
                        data:{
                            id:this.job_id,
                            title: this.form.title,
                            type: this.form.type,
                            email: this.form.email,
                            category: this.form.service_categories_id,
                            other_category: this.form.other_service_category,

                            location: this.form.location_id,
                            other_location: this.form.other_job_location,

                            salarymin: this.form.min_salary ? this.form.min_salary: 0,
                            salaryMax: this.form.max_salary ? this.form.max_salary: 0,
                            tag: this.form.tagArr,
                            description:this.form.description,
                            qualification: this.form.qualification_id,
                            experience : this.form.experience,
                            level : this.form.position_level_id,
                            posted_by : this.form.employer_id,
                            company_id : this.form.company_id,
                            job_type_id: this.form.role_type_id,
                            start_date: this.form.scheduled_post ? moment(this.form.scheduled_post).format('YYYY-MM-DD') : null,
                            notify_users : this.form.users,
                            admin_notify: this.form.admin_notify,
                            updated_by: this.logged_in_user_id,
                            notify_updated:this.form.notify_updated,
                            company_id:this.form.company_id.length > 0 ? this.form.company_id[0].company_id : this.form.company_id,
                            feature_job: this.form.feature_job
                        },
                        api:'/api/updateJob'
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Job Updated successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            window.location.replace('/v2/admin/fulltime-job-list');
                            // this.$router.push({ name: 'manage-jobs' });

                        });
                    }
                });

                }
            },

            getEditData() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.job_id
                    },
                    api: '/api/editJob'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        //this.form = this.$store.getters.getResults.job_data;
                        this.form.title = this.$store.getters.getResults.job_data.title;
                        this.form.experience = this.$store.getters.getResults.job_data.experience;
                        this.form.description = this.$store.getters.getResults.job_data.description;
                        this.form.service_categories_id = this.$store.getters.getResults.job_data.service_categories_id;
                        this.form.location_id = this.$store.getters.getResults.job_data.address;
                        this.form.company_id = this.$store.getters.getResults.job_data.company_id;
                        this.form.other_job_location = this.$store.getters.getResults.job_data.other_location;
                        this.form.other_service_category = this.$store.getters.getResults.job_data.other_service_category;
                        this.form.qualification_id = this.$store.getters.getResults.job_data.educational_qualification_id;
                        this.form.employer_id = this.$store.getters.getResults.job_data.posted_by;
                        this.form.position_level_id = this.$store.getters.getResults.job_data.level;
                        this.form.role_type_id = this.$store.getters.getResults.job_data.job_type_id;
                        this.form.scheduled_post = this.$store.getters.getResults.job_data.start_date;
                        this.form.tagArr = this.$store.getters.getResults.tag_data;
                        this.form.package = '';
                        this.form.min_salary = this.$store.getters.getResults.job_data.min_salary;
                        this.form.max_salary = this.$store.getters.getResults.job_data.max_salary;
                        if(this.form.min_salary == '0' && this.form.max_salary == '0'){
                            this.form.min_salary = '';
                            this.form.max_salary = '';
                        }



                        // if(this.form.role_type_id == this.part_time_id){
                        //     $('.perHourRate').css('display', 'block');
                        //     $('.minMaxSalary').css('display', 'none');
                        // }else{
                        //     $('.perHourRate').css('display', 'none');
                        //     $('.minMaxSalary').css('display', 'block');
                        // }

                        if (this.$store.getters.getResults.notify_user.length > 0) {

                            var arr = []
                            this.$store.getters.getResults.notify_user.forEach(item => {
                                arr.push({user_id:item.user_id})
                            });

                            this.form.users = arr;
                        }else{
                            this.form.users = [{user_id:''}];
                        }

                        this.form.admin_notify = this.$store.getters.getResults.job_data.admin_notify;
                        this.form.feature_job = this.$store.getters.getResults.job_data.featured;



                        if(this.form.role_type_id == '4'){
                            this.salaryType = 'Per Hour Rate';
                        }else{
                            this.salaryType = 'Salary';
                        }
                        if(this.form.location_id == this.other_location_id){
                            $('.other_location_class').css('display', 'block');
                        }else{
                            $('.other_location_class').css('display', 'none');
                        }

                        if(this.form.service_categories_id == this.other_category_id){
                            $('.other_category_class').css('display', 'block');
                        }else{
                            $('.other_category_class').css('display', 'none');
                        }

                        this.getCompanyUsers();
                    }
                });
            },

            subCatg(value) {

                this.service_id = value.map(a => a.id);

            },

            getQualification(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/qualification'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.qualifications = this.$store.getters.getResults.data;
                    }
                });
            },
            getLocation(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/location'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.locations = this.$store.getters.getResults.data;
                        this.other_location_id = this.$store.getters.getResults.other_location_id;
                        this.locations.forEach(element => {
                            if(element.location_names){
                                element.location_names_val = element.location_names.split(',');
                                element.location_names_id = element.location_ids.split(',');
                                // location[element.location_ids.split(',')] = element.location_names.split(',')
                            }
                        });
                        $('.other_location_class').css('display', 'none');
                    }
                });
            },
            getPositionLevel(type){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/position-level'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.position_levels = this.$store.getters.getResults.data;
                    }
                });
            },
            getEmployers(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/employer-job-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.employers = this.$store.getters.getResults.data;
                    }
                });
            },
            getjobRoleType(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/job-role-type'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.role_types = this.$store.getters.getResults.data;
                        this.role_types = this.$store.getters.getResults.data;
                        this.role_types.forEach(element => {
                            if(element.name == 'part-time'){
                                this.part_time_id = element.id;
                            }
                        });
                        $('.perHourRate').css('display', 'none');
                        $('.minMaxSalary').css('display', 'block');
                    }
                });
            },
            addUser(){


                if(this.form.users.length < 3){
                    this.form.users.push({user_id:''});
                }
            },
            removeUser(index){
                this.form.users.splice(index,1);
                if(this.form.users.length == 0){
                    this.addUser();
                }
            },
            getCompanyUsers() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        company_id: this.form.company_id,
                    },
                    api: '/api/all-company-users'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){
                            this.company_users = this.$store.getters.getResults.data;
                            return this.company_users;

                        }
                    }
                });
            },
            filterUser(id){
                let selUser = this.company_users.find(item => { return item.id == id});
                return selUser ? selUser.email : "";
            },
            checkDisabled(id){

                var exp = this.form.users.map(item => item.user_id).indexOf(id) >= 0 ? true : false;

                return exp;
            }
        },
        mounted(){
            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
            }, "fast");
            //document.title = "EL Connect - Admin - Full Time Jobs - Edit Job";
            this.logged_in_user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getLocation();
            this.getEmployers();
            this.getDropdownData('service_categories');
            this.getjobRoleType();
            this.getEditData();
            this.getQualification();
            this.getPositionLevel();

        }
    }
</script>


