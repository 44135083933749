<template>
  <div>
  <!-- Featured Posts -->
  <div id="titlebar" class="white margin-bottom-30">
    <div class="container blog_container">
      <div class="row">
        <div class="col-md-12">
          <h2><strong>Blog</strong></h2>
          <h3 v-if="featured_post.length">Featured Posts</h3>
        </div>
      </div>
    </div>
  </div>

<div class="section white padding-top-0 padding-bottom-60 full-width-carousel-fix featured_blog_post blog_slider mobile_padding_bottom_25">
    <div class="container">
      <div class="row">
          <div class="col-xl-12">
               <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" ref="carousel" v-if="featured_post.length" class="job_detail_slider">
                  <div class="blog-compact-item" v-for="(featured, h) in featured_post" :key="h">
                      <a @click="blogDetail($event,featured.id,featured.title,featured.seo_permalink)" class="">
                        <!-- <img src="/assets/images/blog-05a.jpg" alt=""> -->
                        <img :src="(featured.cover_image != null ? featured.cover_image: webUrl+'assets/images/blog cover.jpg')" alt="featured_post_image">
                        <span class="blog-item-tag">{{ featured.tag }}</span>
                        <div class="blog-compact-item-content">
                         <!--  <ul class="blog-post-tags">
                            <li>{{ featured.date | momentDate }}</li>
                          </ul> -->
                          <h3>{{ featured.title }}</h3>
                           <p v-if="featured.description.length<85" v-html="featured.description"></p>
                           <p v-else v-html="featured.description.substring(0,85)+'...'"></p>
                          <!-- <p>{{ featured.description }}</p> -->
                        </div>
                      </a>
                    </div>
                </VueSlickCarousel>
          </div>
        </div>
      </div>
</div>

  <!-- Recent Posts -->
  <div class="section gray">
    <div class="container blog_container">
      <div class="row">

        <!-- Left -->
        <div class="col-xl-8 col-lg-8 blog_padding_bottom_60">
          <!-- Recent Post -->
          <div class="section-headline margin-top-60 margin-bottom-35 mobile_margin_top_25">
            <h4>Trending Posts</h4>
          </div>
          <div v-for="(trending, h) in trending_post" :key="h" class="recent_blog_post" :current-page="currentPage" :per-page="perPage">
              <a @click="blogDetail($event,trending.id,trending.title,trending.seo_permalink)" class="blog-post desktop_margin_bottom_zero common_cusror_pointer_hand">
                  <div class="blog-post-thumbnail">
                    <div class="blog-post-thumbnail-inner" :style="{ 'background-image': 'url(' + (trending.cover_image != null ? trending.cover_image : webUrl+'assets/images/blog cover.jpg') + ')' }">
                      <span class="blog-item-tag">{{ trending.tag }}</span>
                    <!--   <img :src="(recent.cover_image != null ? recent.cover_image: webUrl+'assets/images/blog cover.jpg')" alt="recent_post_image"> -->
                    </div>
                  </div>
                  <div class="blog-post-content">
                    <span class="blog-post-date">{{ trending.date | momentDate }}</span>
                    <h3>{{ trending.title }}</h3>
                     <!-- <p v-html="recent.description"></p> -->
                     <p v-if="trending.description.length<150" v-html="trending.description"></p>
                     <p v-else v-html="trending.description.substring(0,170)+'...'"></p>
                  </div>
                    <div class="entry-icon"></div>
                </a>
            </div>

            <nav class="pagination-box">
                                    <div class="page-row-box d-inline-block">
                                        <b-form-group id="input-group-4">
                                            <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getBlogData'>
                                                        <option>4</option>
                                                        <option>8</option>
                                                        <option>12</option>
                                                        <option>16</option>
                                            </b-form-select></span>
                                        </b-form-group>
                                    </div>
                                    <div class="total-page-count d-inline-block">
                                        <ul>
                                            <li>
                                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <b-pagination @input='getBlogData' :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                </nav>

        </div>

        <!-- Right -->
        <div class="col-xl-4 col-lg-4 content-left-offset mobile_blog_sidebar">
          <div class="sidebar-container margin-top-65 mobile_margin_top_zero">
            <div class="sidebar-widget">

              <!-- Trending Post -->
              <h3>Top Viewed</h3>
              <ul class="widget-tabs blog_widget_tab">
                <li v-for="(top_view, h) in top_viewed" :key="h">
                  <a @click="blogDetail($event,top_view.id,top_view.title,top_view.seo_permalink)" class="widget-content common_cusror_pointer_hand">
                    <img :src="(top_view.image != null ? top_view.image: webUrl+'assets/images/blog cover.jpg')" alt="trending_post_image">
                    <div class="widget-text">
                      <h5>{{ top_view.title }}</h5>
                      <!-- <span>{{ trending.date | momentDate }}</span> -->
                    </div>
                  </a>
                </li>
              </ul>
            </div>
             <!-- Social Profiles -->
            <div class="sidebar-widget">
              <h3>Social Profiles</h3>
              <div class="freelancer-socials margin-top-25">
                <ul>
                  <li><a href="https://www.facebook.com/elconnectsgp/" title="Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                  <li><a href="https://www.instagram.com/elconnectsgp/" title="Instagram" data-tippy-placement="top"><i class="icon-brand-instagram"></i></a></li>
                  <li><a href="https://www.youtube.com/c/elconnect" title="Youtube" data-tippy-placement="top"><i class="icon-brand-youtube"></i></a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
  </template>

<script>
  import { POST_API } from '../../store/actions.type';
  import moment from 'moment';
    import { Carousel, Slide } from 'vue-carousel';
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

  export default{
      metaInfo: {
              title: 'Jobs & Manpower: Benefits of Sourcing them from EL Connect Site',
              meta: [
                  { charset: 'utf-8' },
                  { name: 'description', content: 'Join the evolving group of employers, post your jobs and source manpower from the EL Connect job site. Find out its benefits in this article!' },
                  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                  { property: 'og:title', content: 'EL Connect - Blog'},
                  { property: 'og:site_name', content: 'EL Connect'},
                  { property: 'og:type', content: 'website'},
                  { property: 'og:url', content: 'https://elconnect.sg/blog'},
                  { property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                  { property: 'og:description', content: 'Blog - Unlock Your Potential with Daily-Paid Jobs! Explore Exciting Opportunities on Our Platform and Start Earning Daily. Join Us Today for a Brighter Tomorrow!'},
                  { name: 'twitter:card', content: 'summary'}
              ],
              link: [
                  { rel: 'canonical', href: 'https://elconnect.sg/blog'}
              ]
      },
      data() {
          return {
              x: 'jobseeker',
              webUrl:process.env.VUE_APP_URL,
              featured_post: [],
              recent_post: [],
              trending_post: [],
              top_viewed:[],
              from: '',
                to: '',
                currentPage: 1,
                perPage: 4,

                pageOptions: [4, 8, 12, 16],
                form:{
                    rowsPerPage:4,
                },
              pager: {},
              pageOfItems: [],
              options: [],
              items:null,
              totalRows: 0,
              settings: {
                  "slidesToShow": 3,
                  "slidesToScroll": 3,
                  "speed": 800,
                  "infinite": false,
                  responsive: [
                    {
                    // breakpoint: 768,
                    // settings: {
                    // 	"slidesToShow": 1,
                    // 		"slidesToScroll": 1,
                    // 		"speed": 800,
                    // 		"infinite": false,
                              //         "dots":true,
                              //         "arrows":false
                    //     }
                    "breakpoint": 998,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            // "infinite": true,
                            "dots": true,
                            "arrow":true
                        }
                        },
                        {
                        "breakpoint": 1000,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            // "infinite": true,
                            "dots": true,
                            "arrow":true
                        }
                        },
                        {
                        "breakpoint": 768,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "initialSlide": 1,
                            "dots": true,
                            "arrow":true
                        }
                        },
                        {
                        "breakpoint": 480,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "dots": true,
                            "arrow":true
                        }

                        }
                      ]
                    }
            }
      },
      components: {
          VueSlickCarousel,
          Carousel,
          Slide
      },
      filters: {
        momentDate: function(date) {
          return moment(date).format("DD MMM YYYY")
        },

        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

        customFormatterOne(date) {
            return moment(date).format('DD-MM-YYYY');
        },

        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i < frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
      },

      methods:{
        extractContent(html) {
            return new DOMParser()
                .parseFromString(html, "text/html")
                .documentElement.textContent;
        },
        getBlogData(){
          this.$store.dispatch(POST_API, {

            data:{
              keyword: this.filter,
              page: this.currentPage,
              rowsPerPage:this.form.rowsPerPage,
            },
            api:'/api/blog-data'
          })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    return [];
                }
                else {
                  this.featured_post = this.$store.getters.getResults.featured_post;
                  // this.recent_post = this.$store.getters.getResults.recent_post;
                  this.trending_post = this.$store.getters.getResults.pageOfItems;
                  this.totalRows = this.$store.getters.getResults.pager.totalItems;
                  this.perPage = this.$store.getters.getResults.pager.pageSize;
                  this.from = this.$store.getters.getResults.pager.from;
                  this.to = this.$store.getters.getResults.pager.to;

                  this.top_viewed = this.$store.getters.getResults.top_viewed;
                  this.totalRows = this.$store.getters.getResults.pager.totalItems;
                  this.perPage = this.$store.getters.getResults.pager.pageSize;
                  this.from = this.$store.getters.getResults.pager.from;
                  this.to = this.$store.getters.getResults.pager.to;

                  const blogBreadcrumbSchema = document.createElement('script');
                    blogBreadcrumbSchema.setAttribute('type', 'application/ld+json');
                    const mergeAllBlogInOne = this.featured_post.concat(this.trending_post, this.top_viewed ); 
                    var blogMergeList = []
                  
                    mergeAllBlogInOne.forEach((element,key)=> {
                      blogMergeList.push({
                        "@type": "ListItem",
                         "position": key+1,
                         "name": element.title,
                         "item": element.seo_permalink ? this.webUrl+'blog/'+element.seo_permalink : this.webUrl+'/blog/'+element.title
                      })
                        
                    });
                    console.log(blogMergeList,'++++++++++')
                    blogBreadcrumbSchema.text = `
                     {
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": ${JSON.stringify(blogMergeList)}
                      }`;
                        document.head.appendChild(blogBreadcrumbSchema);


                    const blogCarouselSchema = document.createElement('script');
                    blogCarouselSchema.setAttribute('type', 'application/ld+json');
                    const mergeAllBlogInOne2 = this.featured_post.concat(this.trending_post, this.top_viewed ); 
                    var blogMergeListCarousel = []
                  
                    mergeAllBlogInOne2.forEach((element,key)=> {
                      blogMergeListCarousel.push({
                        "@type": "ListItem",
                         "position": key+1,
                         "url": element.seo_permalink ? this.webUrl+'blog/'+element.seo_permalink : this.webUrl+'/blog/'+element.title
                      })
                        
                    });
                    console.log(blogMergeListCarousel,'++++++++++')
                    blogCarouselSchema.text = `
                     {
                        "@context":"https://schema.org",
                        "@type":"ItemList",
                        "itemListElement": ${JSON.stringify(blogMergeListCarousel)}
                      }`;
                        document.head.appendChild(blogCarouselSchema);

                    const blogSitehLinkSearchBoxSchema = document.createElement('script');
                    blogSitehLinkSearchBoxSchema.setAttribute('type', 'application/ld+json');
                    blogSitehLinkSearchBoxSchema.text = `
                     {
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "El Connect",
                        "url": "https://elconnect.sg",
                        "potentialAction": {
                          "@type": "SearchAction",
                          "target": "https://elconnect.sg/blog{search_term_string}",
                          "query-input": "required name=search_term_string"
                        }
                      }`;
                      document.head.appendChild(blogSitehLinkSearchBoxSchema);
                }
            });
        },
        blogDetail(event,id,title,seo_permalink){
          this.$store.dispatch(POST_API, {
            data:{
              id: id,
            },
            api:'/api/blog-detail-view-count'
          })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    return [];
                }
                else {
                  this.blogDetail2(event,id,title,seo_permalink);
                }
            });
        },
        blogDetail2(event,id,title,seo_permalink){
          if (event) {
              event.stopPropagation()
          }
          if(seo_permalink == null){
            var titleNew = title.replace(/ /g, "-");
          }else{
            var titleNew = seo_permalink;
          }
          window.location.replace('/blog/'+ titleNew);
          // window.location.replace('/blog/detail/'+btoa(id)+ '/' +titleNew);

			  },

      },

      mounted(){
        window.scrollTo(0,0);

        this.getBlogData();
      }
  }

  </script>
