<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Whatsapp Template</h1>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Whatsapp Template</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <!-- <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 edit_genneral_stng_dsk"> -->
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Template Name" class="required">
                                        <b-form-input id="title" v-model="form.template_name" type="text" placeholder="Enter Template Name.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Company Name" class="required">
                                        <b-form-input id="title" v-model="form.company_name" type="text" placeholder="Enter Company Name.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Template Message" class="required">
                                        <textarea cols="30" rows="5" class="with-border web_bottom_zero" v-model="form.template_msg"></textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Sample Media URL" class="required">
                                        <b-form-input id="title" v-model="form.sample_media_url" type="text" placeholder="Enter Sample Media URL.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Condition" class="required">
                                        <b-form-input id="title" v-model="form.msg_condition" type="text" placeholder="Enter Condition.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                            
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Badge" class="required">
                                        <b-form-input id="title" v-model="form.badge" type="text" placeholder="Enter Badge.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Template Type" class="required ">
                                        <b-form-select v-model="form.template_type" id="industry" class="">
                                            <option value="" disabled>Select Template Type</option>
                                            <option value="normal_template">Normal Template</option>
                                            <option value="content_editor">Content Editor</option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="upload-form-group">
                                        <b-form-group id="link-group" label="Upload Sample Message Image" class="required">
                                            <div class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.selectedImage.image ? 'if_image_exist' : 'if_image_not_exist'">
                                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                                <span v-bind:style="form.selectedImage.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                     <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="image"></b-img> -->
                                                     <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" alt="image" class="actual_img"></b-img>
                                                     <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" alt="image" class="dummy_img"></b-img>
                                                </span>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'whatsapp-template-list'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
                <b-modal ref="crop-modal" title="Edit Image" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitImg">
                        <template>
                              <div>
                                <div class="content" @change="setImage">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogo" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropImage">Crop</b-button>
                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    data() {
        return {
            form: {
                template_name: '',
                company_name: '',
                template_msg: '',
                sample_media_url: '',
                msg_condition: '',
                badge: '',
                template_type: '',
                image: '',
                selectedImage:{
                    name:'',
                    image:''
                },
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            webUrl: process.env.VUE_APP_URL,
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true,
        }
    },
    components: {
        VueCropper
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'whatsapp-template-list'},
                text: 'Whatsapp Templates',
            },{
                to:null,
                text: 'Edit Whatsapp Template'
            }];
            return item;
        },
        cropImage() {
          this.$refs['crop-modal'].hide(); 
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
              this.cropImg = event.target.result;
              this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal'].hide(); 
            this.$refs['crop-modal'].refresh();
          
        },
        onImageChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.image = input.files[0];
                if (this.form.image.type != 'image/jpeg' && this.form.image.type != 'image/jpg') {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Please select only .jpg or .jpeg image.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.image.size > 1048576) {
                    this.form.selectedImage.image = this.webUrl + '/images/upload-icon.svg'
                    this.error_message = 'Logo should be less than 1 MB.';
                    this.form.selectedImage.image='';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogo = e.target.result;
                        this.form.selectedImage.name = this.form.image.name;
                        //this.showImg();
                        this.form.selectedImage.image = e.target.result;
                        event.target.value = '';

                    }
                }
            }
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'whatsapp-template-list' });
            });
        },
        
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        page: this.currentPage,
                        keyword: this.filter,
                        id: this.id,
                    },
                    api: '/api/whatsapp-template-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.template_name = this.$store.getters.getResults.data.template_name;
                        this.form.company_name = this.$store.getters.getResults.data.company_name;
                        this.form.template_msg = this.$store.getters.getResults.data.template_msg;
                        this.form.sample_media_url = this.$store.getters.getResults.data.sample_media_url;
                        this.form.msg_condition = this.$store.getters.getResults.data.msg_condition;
                        this.form.badge = this.$store.getters.getResults.data.badge;
                        this.form.template_type = this.$store.getters.getResults.data.template_type;
                        this.form.selectedImage.image = this.$store.getters.getResults.data.sample_msg_image;
                    }
                });
        },
        
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        template_name: this.form.template_name,
                        company_name: this.form.company_name,
                        template_msg: this.form.template_msg,
                        sample_media_url: this.form.sample_media_url,
                        msg_condition: this.form.msg_condition,
                        badge: this.form.badge,
                        template_type: this.form.template_type,
                        image: this.form.selectedImage.image,
                        items:this.form,
                    },
                    api: "/api/whatsapp-template-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Whatsapp Template Updated successfully.'
                        this.showSuccessAlert(message);
                        // this.$router.push({ name: 'whatsapp-template-list' });
                    }
                });
        },
        
        
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Website Contents - Edit Whatsapp Template";
        this.getEditData();
    },
};

</script>
