<script>
    import { POST_API } from "../../store/actions.type"

    export default {
       
        mounted(){

            this.$store.dispatch(POST_API, {
                    data: {
                        cat_id: this.$route.query.cat_id,
                    },
                    api: '/api/catByIdft'
                })
                .then(() => {

                    if(this.$store.getters.getResults.data.length > 0){
                        var cat_alias = this.$store.getters.getResults.data[0].alias


                    }else{
                        var cat_alias = 'all-categories-jobs'
                    }


            //document.title = "EL Connect - Terms of Service"; 
                    window.location.href = process.env.VUE_APP_URL +'singapore-full-time-jobs/all-role-jobs/'+ cat_alias + "/all-location-jobs/" + atob(this.$route.query.filter) + "/all-tag-jobs/0-22000";
                })
        },
    }
</script>
