<template>
    <div>
        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->
        <div class="dashboard-headline">
            <h1>Upload ACRA & Billing Address</h1>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark nav_on_emp">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Profile</li>
                </ul>
            </nav>
        </div>
        <div class="notification warning" v-if="status == 'pending' || status == 'incomplete'">
            <!-- <p><span class="icon-feather-info"></span> Account status {{acStatus}}. Jobs can be posted after profile completion.</p> -->
            <p class="offers_desktop"><span class="icon-feather-info"></span> EL Connect provides temporary staffing solutions, allowing instant access to freelancers without the traditional hiring process. Enjoy cost-efficiency and guaranteed applicants <a :href="'https://wa.link/k7p8ww'" target="_blank">Contact us</a>  to know more.</p>
            <div class="offers_mobile">
                <p>
                    <span class="icon-feather-info"></span> EL Connect provides temporary staffing solutions, allowing instant access to freelancers without the traditional hiring process. </p>
                    <p>Enjoy cost-efficiency and guaranteed applicants. </p>
                    <p><a :href="'https://wa.link/k7p8ww'" target="_blank">Contact us</a>  to know more.</p>
            </div>
        </div>
        <div class="notification warning" v-if="this.form.pending_msg == true && this.account_verification_status == 'pending'">
            <p><span class="icon-feather-info"></span> Your Temp. Staff Status is pending approval.</p>
            <a class="close"></a>
        </div>
        <div class="apply_div_section">
            <div class="notification warning notification_box profile_notification_box new_notification_warning" v-if="this.account_verification_status == 'rejected' && !this.form.show_required_field_apply_dp_msg">
                <!-- <h3 v-if="this.form.pending_msg == true && this.account_verification_status != 'rejected'"  style="color:blue">Your Daily Paid Status is pending approval.</h3> -->
                <h3 v-if="this.account_verification_status == 'rejected'"  style="color:red;">Temp. Staff Status: Rejected</h3>
                <h3 v-if="this.account_verification_status == 'rejected'"  style="color:red">Rejection reason: {{rejected_comment}}</h3>
            </div>
            <!-- <button class="btn btn-outline-primary" v-if="this.form.apply_for_dp == false && this.form.pending_msg == false && this.account_verification_status == 'rejected'" @click="applyForDailyPaid" type="button" id="apply_for_dp_button" style="
            margin-top: 12px;">Re-apply for Daily Paid Posting</button> -->
            <div v-if="this.form.show_required_field_apply_dp_msg">
            <ul v-if="this.form.show_required_field_apply_dp_msg" class="notification warning notification_box profile_notification_box new_notification_warning" style="color: rgb(234, 20, 81);">
            <p v-if="!this.showACRA || !this.billingdata"><span class="icon-feather-info"></span> Profile Status incomplete. Please finalize your profile by:</p>
            <li v-if="!this.showACRA"> {{ dailyerr1 }}.    Upload ACRA</li>
            <li v-if="!this.billingdata"> {{ dailyerr2 }}. Add Billing Address</li>
            </ul>
            </div>
            <div class="notification warning" v-else-if="status == 'incomplete' && validErr.length > 0" style="background-color: rgb(236 159 167 / 49%);color: rgb(234, 20, 81);">
                    <p><span class="icon-feather-info"></span> Account Status {{status}}. To complete profile:</p>
                    <ul class="notification_box" v-for="(err,key) in validErr">
                        <li> {{key + 1 }}. {{err}}</li>
                        <!-- <li> Save changes to profile </li> -->
                    </ul>
                    <a class="close"></a>
                </div>
        </div>
        <div class="row" >
            <!-- <div class="col-xl-12 col-lg-12 content-right-offset"> -->
            <div class="col-xl-12 col-lg-12">
                    <!-- Hedaline -->
                    <!-- <div class="dashboard-headline">
                        <h1>Edit Billing Address</h1>
                    </div> -->
                    <div class="dashboard-box desktop_margin_top_zero">
                        <div class="headline edit_profile_headline">
                            <div class="">
                                <h3 class="apply_sec_h3">
                                    <!-- <i class="icon-material-outline-business-center"></i> -->
                                    Company Document
                                </h3>
                            </div>
                        </div>
                        <!-- Billing Cycle Radios  -->
                        <!-- <div class="billing-cycle desktop_display_block"> -->
                            <!-- Radio -->
                            <div class="content with-padding submit-field web_bottom_zero big_screen_bottom_28 mobile_margin_bottom_28">
                                                            <h5>Company ACRA (Bizfile) (PDF Only) <span class="required_star">*</span></h5>

                                                            <!-- Attachments -->
                                                            <!-- <div class="attachments-container margin-top-0 margin-bottom-0">
                                                                <a :href="acraUrl" target="_blank">
                                                                    <div class="attachment-box ripple-effect web_bottom_zero" v-show="showACRA">
                                                                        <span>ACRA</span>
                                                                        <i v-if="acraUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                                                                        <i v-else>DOC</i>
                                                                    </div>
                                                                </a>
                                                                <button v-if="showACRA && this.form.apply_for_dp == false" type="button" class="remove-attachment" @click="removeACRA($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                                            </div> -->
                                                            <div class="attachments-container margin-top-0 margin-bottom-0">
                                                        <!-- <a :href="acraUrl" target="_blank"> -->
                                                            <div class="" v-if="showACRA">
                                                                <div class="file_name_full_div file_name_font">
                                                                    <span class="file_name_span file_name_color"><b>File Name: </b> </span>
                                                                    <span class="file_name_clr file_first_last">{{form.last_name | capitalize}} {{form.first_name | capitalize}} - <span class="file_name_clr">ACRA.pdf</span>
                                                                    <button v-if="showACRA && this.form.apply_for_dp == false" type="button" class="remove-attachment remove_cv_btn" @click="removeACRA($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                                                    </span>
                                                                </div>
                                                                <!-- <span></span> -->
                                                                <span class="file_name_color file_name_font"><b>Uploaded: </b> </span>
                                                                <span class="file_name_clr file_name_font">{{acra_detail}}</span>
                                                                <span></span>
                                                            </div>
                                                        <!-- </a> -->
                                                        <!-- <button v-if="showACRA && this.form.apply_for_dp == false" type="button" class="remove-attachment" @click="removeACRA($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button> -->
                                                    </div>
                                                        <div class="clearfix"></div>
                                                            <!-- Upload Button -->
                                                            <div class="uploadButton margin-top-0 web_bottom_zero" v-if="!showACRA">
                                                                <input class="uploadButton-input" type="file" accept=".pdf" @change="onAcraChange" id="upload"/>
                                                                <label class="uploadButton-button ripple-effect" for="upload"  v-if="!showACRA">Upload</label>
                                                                <span class="max_file_size_txt" v-if="!showACRA">Maximum file size: 10 MB</span>
                                                                <!-- <span class="uploadButton-file-name" v-if="!showACRA">Maximum file size: 10 MB</span> -->
                                                                <!-- <span class="uploadButton-file-name unset_min_height" id="acra" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                                                            </div>
                                                            <div class="multiple_view_btn" v-if="showACRA && this.form.apply_for_dp == false">
                                                                    <button v-if="showACRA && this.form.apply_for_dp == false" type="button" class="view_btn uploadButton-button ripple-effect" data-tippy-placement="top" @click="viewACRA($event)">View</button>

                                                                    <div class="uploadButton margin-top-0 web_bottom_zero" v-if="showACRA && this.form.apply_for_dp == false">
                                                                        <input class="uploadButton-input" type="file" accept=".pdf" @change="onAcraChange" id="upload"/>
                                                                        <label class="uploadButton-button ripple-effect replace_btn" for="upload"  v-if="showACRA">Replace</label>
                                                                        <!-- <span class="" v-if="showACRA">Maximum file size: 10 MB</span> -->
                                                                        <!-- <span id="acra" class="uploadButton-file-name unset_min_height" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                                                                    </div>
                                                            </div>
                                                            <p class="max_file_size_txt uploadButton-file-name desktop_margin_bottom_zero" v-if="showACRA && this.form.apply_for_dp == false">Maximum file size: 10 MB</p>
                                                    <span class="uploadButton-file-name unset_min_height" id="acra" style="color: blue;font-weight: bold;text-decoration: underline;"></span>
                                                    <button v-if="showACRA && this.form.apply_for_dp == true" type="button" class="uploadButton-button ripple-effect single_view_btn" data-tippy-placement="top" @click="viewACRA($event)">View</button>
                            </div>
                    </div>
                    <div class="content dashboard-box">
                        <div class="headline">
                            <h3>
                                <!-- <i class="icon-material-outline-face"></i>
                                My Account -->
                                Billing Address
                            </h3>
                        </div>
                        <ul class="fields-ul chek_page second_indiv_step_wrap content with-padding">
                        <li class="common_input_field desktop_padding_top_zero desktop_padding_bottom_zero daily_paid_details_li">
                            <div class="row">
                                 <div class="col-xl-6">
                                    <div class="submit-field error_submit_field">
                                        <h5>
                                            Billing Company Name
                                             <div class="tooltip_icon"  @click="companyNameToolTip">
                                                <span class="material-icons-outlined info_tooltip_icon">info_outlined</span>
                                                <span class="tooltip_text tooltip_text2 tooltip_txt_mobile_number">Note: Kindly contact our support to update your company name</span>
                                             </div>
                                        </h5>
                                            <b-form-input
                                            type="text"
                                            v-model="billing_company_name"
                                            id="billing_company_name"
                                            class="input-text with-border"
                                            name="billing_company_name"
                                            placeholder=""
                                            value=""
                                            disabled
                                        />

                                    </div>
                                        <!-- <div class="show_span"> -->
                                            <!-- <span class="error_x_white">Please enter Billing Company Name </span> -->
                                        <!-- </div> -->
                                </div>
                                <div class="col-xl-6">
                                    <div class="submit-field error_submit_field">
                                        <h5>Full Name <span class="required_star">*</span></h5>
                                            <b-form-input
                                            type="text"
                                            v-model="name"
                                            id="full_name"
                                            class="input-text with-border required-field"
                                            name="name"
                                            placeholder=""
                                            value=""
                                        />
                                    </div>
                                        <!-- <div class="show_span"> -->
                                            <span class="error_x_white">Please enter Name </span>
                                        <!-- </div> -->
                                </div>
                                <div class="col-xl-12">
                                    <div v-for="(app, index) in appendExp" :key="index">
                                        <!-- <div class="row job_rw">
                                            <div class="jb1">
                                                <div class="submit-field">
                                                    <h5>Email</h5>
                                                    <input type="text" class="with-border"  v-model="app.email" >
                                                </div>
                                            </div>

                                            <div class="jb5 padding_right_993 mobile_remove_left_pdng col-md-3 max_width_and_flex_769 mobile_max_width_and_flex  eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex mobile_remove_left_pdng big_screen_padding_left_right_zero"  v-if="index != 0">
                                                <div class="submit-field remove_bt web_bottom_zero new_mobile_margin_bottom_zero">
                                                    <a href="#" class="button margin_top_zero_993 ripple-effect margin-top-30 mobile_anchore_set common_vertical_align_bottom" style="background-color: red;" @click="removeExperience(index)" ><span class="add_plus">x</span>Remove</a>
                                                </div>
                                            </div>
                                            <div class="jb6  max_width_and_flex_993 col-md-3 eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_remove_left_pdng big_screen_max_width_and_flex big_screen_padding_left_right_zero ">
                                                <div class="submit-field new_mobile_margin_bottom_zero web_bottom_zero">
                                                    <a href="#" class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero margin-top-30 mobile_anchore_set common_vertical_align_bottom" v-if="index == 0" @click="addExperience"><span class="add_plus">+</span> Add More</a>

                                                </div>
                                            </div>
                                        </div> -->
                                            <div class="row">
                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Email <span class="required_star">*</span></h5>
                                                    <input type="text" class="with-border"  v-model="app.email" >
                                                </div>
                                            </div>

                                            <div class="jb5 padding_right_993  col-md-3 max_width_and_flex_769 mobile_max_width_and_flex   eleven_zero_one_max_width_and_flex mobile_max_width_and_flex  "  v-if="index != 0">
                                                <div class="submit-field ">
                                                    <h5 class="common_opacity_zero">Email</h5>
                                                    <a href="javascript:void(0);" class="button margin_top_zero_993 ripple-effect  mobile_anchore_set common_vertical_align_bottom" style="background-color: red;" @click="removeExperience(index)" ><span class="add_plus">x</span>Remove</a>
                                                </div>
                                            </div>
                                            <div class="jb6  max_width_and_flex_993 col-md-3  eleven_zero_one_max_width_and_flex  big_screen_max_width_and_flex  " v-if="index == 0" @click="addExperience">
                                                <div class="submit-field  ">
                                                    <h5 class="common_opacity_zero">Email</h5>
                                                    <a href="javascript:void(0);" class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero  mobile_anchore_set common_vertical_align_bottom" ><span class="add_plus">+</span> Add More</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                    <div class="col-xl-6">
                                        <div class="submit-field error_submit_field">
                                            <h5>Mobile Number <span class="required_star">*</span></h5>
                                            <b-form-input
                                                type="text"
                                                v-model="phone"

                                                id="number"
                                                class="input-text with-border required-field"
                                                name="office_address"
                                                placeholder=""
                                                value=""
                                                maxlength="8"
                                            />
                                        </div>
                                            <!-- <div class="show_span"> -->
                                                <span class="error_x_white">Please enter Mobile number </span>
                                            <!-- </div> -->
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="submit-field error_submit_field">
                                            <h5>Postal Code <span class="required_star">*</span></h5>
                                            <b-form-input
                                                type="text"
                                                v-model="code"

                                                id="code"
                                                class="input-text with-border required-field"
                                                name="office_address"
                                                value=""
                                                maxlength="6"
                                            />
                                        </div>
                                        <!-- <div class="show_span"> -->
                                            <span class="error_x_white">Please enter Postal Code </span>
                                        <!-- </div> -->
                                    </div>

                                    <div class="col-xl-12">
                                        <div class="submit-field error_submit_field">
                                            <h5>Address 1 <span class="required_star">*</span></h5>
                                            <b-form-input
                                                type="text"
                                            v-model="address"

                                                id="address"
                                                class="input-text with-border required-field"
                                                name="office_address"
                                                value=""
                                            />
                                        </div>
                                        <!-- <div class="show_span"> -->
                                            <span class="error_x_white">Please enter Address </span>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="submit-field">
                                            <h5>Address 2</h5>
                                            <b-form-input
                                                type="text"
                                            v-model="address2"

                                                id="maxSal"
                                                class="input-text with-border desktop_margin_top_zero"
                                                name="office_address"
                                                value=""
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                            <div class="submit-field mobile_margin_bottom_28 mobile_email big_screen_bottom_28 web_bottom_zero">
                                                <h5>Unit No.</h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="unit"

                                                    id="maxSal"
                                                    class="input-text with-border desktop_margin_top_zero"
                                                    name="office_address"
                                                    value=""
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter unit </span>
                                        </div>
                                    <div class="col-xl-4">
                                        <div class="submit-field submit-field mobile_margin_bottom_28 mobile_email big_screen_bottom_28 web_bottom_zero">
                                            <h5>City <span class="required_star">*</span></h5>
                                            <b-form-input
                                                type="text"
                                            v-model="city"

                                                id="city"
                                                class="input-text with-border"
                                                name="office_address"
                                                value=""
                                            />
                                        </div>
                                        <!-- <div class="show_span"> -->
                                            <span class="error_x_white">Please enter City </span>
                                        <!-- </div> -->
                                    </div>

                                <div class="col-xl-4">
                                        <div class="submit-field web_bottom_zero error_submit_field">
                                            <h5>Country <span class="required_star">*</span></h5>
                                                <b-form-select v-model="country" id="country">
                                                <option :value="country.id" v-for="country in countries">{{country.country_name}}</option>
                                            </b-form-select>
                                        </div>

                                        <div class="show_span">
                                            <span class="error_x_white">Please select Country </span>
                                        </div>
                                    </div>


                            </div>

                        </li>
                    </ul>
                    </div>
                        <!-- Radio -->
                    <!-- </div> -->
            </div>
            <div class="col-xl-12 post_jb_btn daily_paid_details_btn">
                <a @click="submit" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn" style="background:#4c8bf5 !important">Submit</a>
                <!-- <a  @click="$router.go(-1)" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn" style="background:black !important">Cancel</a> -->
                <b-link :to="{name: 'employer-dashboard'}" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn" style="background:black !important">Cancel</b-link>

            </div>
            <!-- Summary -->
        </div>
    </div>
</template>

<script>
  import { POST_API } from "../../store/actions.type"
  import Bus from "../../event-bus";

  export default {

  data() {
    return {
            form:{
                apply_for_dp: false,
                acra:'',
                selectedAcra:{
                    name:'',
                    image:'',
                },
                show_required_field_apply_dp_msg: false,
                pending_msg: false,
                emp_daily_paid_status:'',
                first_name:'',
                last_name:'',

                selectedImage:{
                    name:'',
                    image:'',
                    company_id:'',
                },
                skill:'',
                email_verify :'',
                mobile_verify :'',
                company_name: '',
                company_reg_no: '',
            },
            account_verification_status: '',
            rejected_comment: '',
             formPosition: 0,
             page : 1,
             coins: '',
             coinData:'',
             packageData:'',
             total_amount : 0,
             loading: false,
            // user_id: atob(this.$route.params.id),

            cardHolderName:'',
            cardNumber:'',
            expiryMonth:'',
            expiryYear: '',
            cvv:'',
            name:'',
            billing_company_name:'',
            email:'',
            phone:'',
            blockRemovalExperience:true,
            code:'',
            address:'',
            address2:'',
            unit:'',
            city:'Singapore',
            country:'5',
            elcoinDatabyid:'',
            settings:'',
            countries:'',
            appendExp:[],
            showACRA:'',
            acra_detail:'',
            acraUrl:'',
            dailyerr1:'',
            dailyerr2:'',
            validErr:[],
            status:''


            }},
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
  methods: {
     companyNameToolTip() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_mobile_number").toggleClass("tooltip_text_mobile_view_number");
      }
    },
    breadCrumb(){
            var item = [{
                to:{name:'billingAddress'},
                text: 'Manage Billing Address',
            },{
                to:null,
                text: 'Edit Billing Address'
            }];
            return item;
    },
     nextStep(){
      this.page = this.page+1;
     },

     back(){
      this.page = this.page-1;
     },

      addExperience()
        {
            this.appendExp.push( {
                    email: ""

                }
            );
        },

         removeExperience (lineId) {
          if (this.blockRemovalExperience )
            {
                this.appendExp.splice(lineId, 1);
            }
        },
        onAcraChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.acra = input.files[0];
                if (this.form.acra.type != 'application/pdf'){
                    this.error_message = 'Please select only .pdf only.';
                    this.form.selectedAcra.image = '';
                    this.form.selectedAcra.name='';
                    $('#acra').empty().append('');
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.acra.size > 10523520) {
                    this.form.selectedAcra.image = this.webUrl + '/images/default-user-icon.svg'
                    this.error_message = 'ACRA should be less than 10 MB.';
                    this.form.selectedAcra.image = '';
                    this.form.selectedAcra.name='';
                    $('#acra').empty().append('');
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.modelBox = 'logo';
                        this.form.selectedAcra.image = e.target.result;
                        this.form.selectedAcra.name = this.form.acra.name;
                        $('#acra').empty().append(this.form.selectedAcra.name)
                    }
                }
            }
        },
        removeACRA(event){
        if (event) {
            event.stopPropagation()
        }

        this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to remove ACRA?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if(result.value){
                    return this.$store.dispatch(POST_API, {
                        data:{
                        company_id: this.company_id,
                        user_id: this.user_id,
                        },
                        api:'/api/employer-acra-remove',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            // this.showAlert();
                            return [];
                        } else {
                            this.form.acra = '';
                            this.showACRA = false;
                            this.form.apply_for_dp = false;
                                $('#acra').empty().append('');
                            this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'ACRA removed successfully',
                            showConfirmButton: false,
                            timer: 1500
                            });
                            // this.getEditData();
                            if(!this.showACRA){
                                this.dailyerr1 = 1
                                this.dailyerr2 = 2
                            }else{
                                this.dailyerr2 = 1
                            }
                            if(!this.showACRA || !this.billingdata){
                                this.form.show_required_field_apply_dp_msg = true;
                                $('#apply_for_dp_button').attr('disabled', 'disabled').addClass('if_disabled_exist');
                            }else{
                                this.form.show_required_field_apply_dp_msg = false;
                                $('#apply_for_dp_button').removeAttr('disabled').removeClass('if_disabled_exist');
                            }
                            }
                    });
                }
            })
            .catch(err => {
            })

        },
        viewACRA(event){
            if (event) {
                event.stopPropagation()
            }
            window.open(this.acraUrl,'_blank')
        },

      getEditData(){

           this.$store.dispatch(POST_API, {
                    data:{
                        user_id: this.user_id,
                        company_id: this.company_id,

                    },
                    api:'/api/emp-dp-profile-edit'
                })
                .then(() => {

                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.billingdata = this.$store.getters.getResults.data;
                        this.acra_detail = this.$store.getters.getResults.acra;
                        this.form.selectedAcra.image = this.$store.getters.getResults.company_data.acra;
                        this.showACRA = (this.$store.getters.getResults.company_data.acra != '' && this.$store.getters.getResults.company_data.acra != null) ? true : false;
                        this.acraUrl = (this.$store.getters.getResults.company_data.acra != '' && this.$store.getters.getResults.company_data.acra != null) ? this.$store.getters.getResults.company_data.acra : '';
                        this.form.acra = this.acraUrl;
                        this.form.apply_for_dp = this.$store.getters.getResults.user['account_verification_status'] == 'completed' || this.$store.getters.getResults.user['account_verification_status'] == 'pending' ? this.form.apply_for_dp = true : this.form.apply_for_dp = false;

                        this.form.pending_msg = this.$store.getters.getResults.user['account_verification_status'] == 'pending' ? this.form.pending_msg = true : this.form.pending_msg = false;
                        this.form.emp_daily_paid_status = this.$store.getters.getResults.user['emp_daily_paid_status'];
                        this.status = this.$store.getters.getResults.user['account_verification_status'];
                        if(this.$store.getters.getResults.user['first_name'] == 'Demo'){
                            this.form.first_name = '';
                        }else{
                            this.form.first_name = this.$store.getters.getResults.user['first_name'];
                        }
                        if(this.$store.getters.getResults.user['last_name'] == 'Demo'){
                            this.form.last_name = '';
                        }else{
                            this.form.last_name = this.$store.getters.getResults.user['last_name'];
                        }
                        this.account_verification_status = this.$store.getters.getResults.user['account_verification_status'];
                        this.rejected_comment = this.$store.getters.getResults.rejected_comment ? this.$store.getters.getResults.rejected_comment['comment'] : '';

                        this.form.email_verify = this.$store.getters.getResults.user['email_verified'];
                        this.form.mobile_verify = this.$store.getters.getResults.user['mobile_verified'];
                        this.form.skill = this.$store.getters.getResults.company_data.company_industry_id ? this.$store.getters.getResults.company_data.company_industry_id : '';
                        if(this.$store.getters.getResults.company_data.name == 'test.co'){
                            this.form.company_name = '';
                        }else{
                            this.form.company_name = this.$store.getters.getResults.company_data.name;
                        }
                        this.form.company_reg_no = this.$store.getters.getResults.company_data.registration_id;
                        this.form.selectedImage.image = this.$store.getters.getResults.company_data.company_logo;
                        if(this.form.mobile_verify != 'yes'){
                            this.validErr.push('Verify Mobile Number');
                        }
                        if(this.form.email_verify != 'yes'){
                            this.validErr.push('Verify Email Address');
                        }
                        if(this.form.company_name == null || this.form.company_name == ''){
                            this.validErr.push('Add Company Name');
                        }
                        if(this.form.company_reg_no == null || this.form.company_reg_no == ''){
                            this.validErr.push('Add Company Registration Number (UEN)');
                        }
                        if(this.form.skill == null){
                            this.validErr.push('Add Company Industry');
                        }
                        // console.log(this.form.selectedImage.image,!this.form.selectedImage.image,'this.form.selectedImage.image')
                        if(!this.form.selectedImage.image){
                            this.validErr.push('Add Company Logo');
                        }

                        if(!this.showACRA){
                            this.dailyerr1 = 1
                            this.dailyerr2 = 2
                        }else{
                            this.dailyerr2 = 1
                        }
                        if(!this.showACRA || !this.billingdata){
                            this.form.show_required_field_apply_dp_msg = true;
                            $('#apply_for_dp_button').attr('disabled', 'disabled').addClass('if_disabled_exist');
                        }else{
                            this.form.show_required_field_apply_dp_msg = false;
                            $('#apply_for_dp_button').removeAttr('disabled').removeClass('if_disabled_exist');
                        }
                        if(this.billingdata){
                            var appendExp = this.appendExp;
                            var emailarr =  this.billingdata.billing_email.split(", ");
                            emailarr.forEach(function(item,index){
                                appendExp.push({email:item});
                            })

                            this.name = this.billingdata.name;
                            // this.billing_company_name = this.billingdata.billing_company_name ? this.billingdata.billing_company_name : this.$store.getters.getResults.company_data.name ? this.$store.getters.getResults.company_data.name : '';
                            this.billing_company_name = this.billingdata.billing_company_name ? this.billingdata.billing_company_name :  '';


                        // this.email = this.billingdata.billing_email;
                            this.phone = this.billingdata.mobile_number;
                            this.address = this.billingdata.street;
                            this.city = this.billingdata.city;
                            this.unit = this.billingdata.unit;

                            this.country = this.billingdata.country_id;
                            this.address2 = this.billingdata.address_2;
                            this.code = this.billingdata.postal_code;
                        }else{
                            this.appendExp.push({email:'' })
                            this.name = this.$store.getters.getResults.user.last_name + ' '+ this.$store.getters.getResults.user.first_name;
                            this.phone =  this.$store.getters.getResults.user.mobile_number;
                            this.billing_company_name = this.$store.getters.getResults.company_data.name ? this.$store.getters.getResults.company_data.name : '';

                        }
                    }
                });
     },

      showAlert() {
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            submit(){
                var err = false;
                var erremail = false;
                var emailarr = [];
                 if(this.appendExp.length > 0){

                     this.appendExp.forEach(function(item,index){
                        emailarr.push(item.email);
                     })



                }

                this.email = emailarr.toString();
                 $('.required-field').each(function () {

                        if ($(this).val() == '' || $(this).val().trim().length === 0) {
                            err = true;
                            $(this).addClass('error');
                            $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                            $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                        } else {


                            $(this).removeClass('error');
                            $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                            $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                        }

                    });

                      $('#country').each(function () {

                if( !$('#country').val() ){
                    err = true;

                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {


                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });

                setTimeout(function ()
             {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    });
            }, 100);
            var emailFormat = "[a-zA-Z0-9._-]+@[0-9a-zA-Z-_.]+\.[a-zA-Z]{2,3}$";


                if(err == false){

                const hasDuplicates = (arr) => arr.length !== new Set(arr).size;



                for (const email of emailarr) {

                 if(email != '' && !email.match(emailFormat)){

                    erremail = true;

                     this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: 'Invalid email',
                        showConfirmButton: false,
                        timer: 1500
                })
                 }

               }

            if(erremail == false){
               if(hasDuplicates(emailarr)){
                    erremail = true;

                     this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: 'Duplicate email value exist',
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
            }
            }


            if(err == false && erremail == false){
                this.$store.dispatch(POST_API, {
                        data:{
                            user_id:this.user_id,
                            email:this.email,
                            fullname:this.name,
                            billing_company_name:this.billing_company_name,
                            phone:this.phone,
                            code:this.code,
                            address:this.address,
                            address2:this.address2,
                            city:this.city,
                            unit:this.unit,

                            country:this.country,
                            company_id: this.company_id,
                            id: this.user_id,
                            items:this.form,
                            acra: this.form.selectedAcra.image,

                    },
                    api: '/api/emp-dp-profile-update'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.applyForDailyPaidNew();
                        Bus.$emit('active_users');
                        var message = this.$store.getters.getResults.message
                        this.showSuccessAlert(message);
                        this.loading = false;
                        window.location.replace('/v2/employer/profile-daily-paid-detail');
                    }
                });
            }

            },
        applyForDailyPaidNew(){
            this.$store.dispatch(POST_API, {
                data:{
                    company_id:this.company_id,
                    user_id:this.user_id,
                },
                api:'/api/empApplyForDPNew'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    console.log(this.$store.getters.getErrors)
                    this.form.apply_for_dp == false
                } else {
                    this.form.apply_for_dp == true
                }
            });
        },

      getCountry(){

           this.$store.dispatch(POST_API, {

                    api:'/api/countriesData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.countries = this.$store.getters.getResults.data;

                    }
                });
     },

    },

     mounted(){
         window.scrollTo(0,0);
         $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
            //document.title = "EL Connect - Employer - Add Billing Address";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.email = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
                // this.user_id = atob(this.$route.params.id);
            Bus.$emit('active_users');

            this.getCountry();

            this.getEditData();
     }

    }
</script>
