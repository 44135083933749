<template>
    <div>

        <!-- Titlebar
        ================================================== -->
        <!-- <div id="titlebar" class="gradient" > -->
        <div id="titlebar" class="gradient signup_mrgn">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">Popular Full Time Job Categories</h2>
                    </div>
                </div>
            </div>
        </div>


    <!-- Page Content
        ================================================== -->
        <div class="container all_seperate_category_listing" v-if="job_categories.length > 0">
            <div class="row">
                <div class="categories-container">

							<b-link :to="{name: 'allJobsListing', params: {id: encrypt(job_cat.id)}}" class="category-box" v-for="(job_cat,index) in job_categories" v-bind:style="{backgroundImage: 'url('+ job_cat.service_image +')'}">
								<!--<div class="category-box-icon">
									<img :src="job_cat.service_image ? job_cat.service_image: webUrl+'assets/images/company-logo-placeholders.png'" alt="Partners Logo" class="">
									<i :class="job_cat.logo"></i>
								</div> -->
								<div class="whole_inner_content">
								<div class="category-box-counter">{{job_cat.job_count}}</div>
								<div class="category-box-content">
									<h3 v-if="job_cat.name.length<36">{{job_cat.name}}</h3>
									<h3 v-else>{{job_cat.name.substring(0,35)+"..."}}</h3>
									<span v-if="job_cat.description">
                                    <!--<p v-if="job_cat.description.length<50">{{job_cat.description}}</p>-->
                                    <p>{{job_cat.description.substring(0,45)+"..."}}</p>
                                    </span>
								</div>
								</div>
							</b-link>
						</div>
            </div>
        </div>

        <div class="container" v-else>
            <div class="row">
                <div class="col-xl-5 offset-xl-3">
                    <div class="col-xl-5 offset-xl-3">
                        <h3>No Data Found</h3>
                    </div>
                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
    </div>
</template>

<style scoped>
  @import '../../assets/css/style.css';
  @import '../../assets/css/icons.css';

  /* Color css  */

  @import '../../assets/css/colors/blue.css';

</style>
<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        data() {
            return {
                job_categories:[],
                showDismissibleAlert:false,
                error_message:'',
                display:true,
                link_error:'',
                webUrl: process.env.VUE_APP_URL,
            }
        },
        methods:{
            getWebsiteData() {
            return this.$store.dispatch(POST_API, {
				data:{
                    keyword: this.filter,
                },
                api: '/api/website-data'
            })
            .then(() => {
				//window.scrollTo(0,0);
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.job_categories = this.$store.getters.getResults.job_categories;
                }
            });
        },
        },
        mounted(){
            this.getWebsiteData();
            window.scrollTo(0, 0)
        },
    }
</script>
