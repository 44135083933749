<template>
    <div>

         <div class="row custom_dsk_row">
            <div class="container my_new_container ">
                <!-- <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="empList()"></span>
            </div> -->
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline"> -->
            <!-- <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="empList()"></span>{{name | capitalize}} Manage User</h1> -->
            <!-- <b-link class="btn green-border-btn" :to="{name:'empManageUserCreateAdmin', params: {id: encrypt(company_id)}}">Add</b-link> -->
            <!-- Breadcrumbs -->
            <!-- <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Manage User</li>
                </ul>
            </nav> -->
        <!-- </div> -->
        <div id="breadcrumb-v2">
            <b-breadcrumb>
                <b-breadcrumb-item href="" :to="{name: 'employer-list'}">Employers</b-breadcrumb-item>
                <b-breadcrumb-item active>{{name | capitalize}} Manage User</b-breadcrumb-item>
            </b-breadcrumb>
        </div>
        <div class="row">
                               <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <b-input-group-prepend>
                                        <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                                    </b-input-group-prepend>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                    <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                        <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" v-if="companyDetail.status == 'active' && (companyAdmin.account_verification_status == 'completed' || companyAdmin.ft_account_status == 'completed') && show('add')" :to="{name:'empManageUserCreateAdmin', params: {id: encrypt(company_id)}}">Add</b-link>
                    </div>
            </div>
                </div>
         </div>
        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getEmpUsers" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="three_grid_tbl tbl_height race_width employer_list_table mobile_min_width_1000"> -->
               <b-table ref="datatable" show-empty striped hover responsive :items="getEmpUsers" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="manage_user_jbs_full_over_flow  mobile_min_width_3000 memberhip_six_grid_table ">
                    <template #cell(name)="items">

                        <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                        </b-img> -->
                        <div class="whole_div_img_and_name">
                            <div class="manage_user_img">
                                <b-img v-if="items.item.thumbnail || items.item.profile_image" :src="items.item.thumbnail ? items.item.thumbnail: items.item.profile_image ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="user_img_emp square_img">
                                </b-img>
                                <b-img v-else-if="items.item.ft_profile_image_thumbnail || items.item.ft_profile_image" :src="items.item.ft_profile_image_thumbnail ? items.item.ft_profile_image_thumbnail: items.item.ft_profile_image ? items.item.ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="user_img_emp square_img">
                                </b-img>

                                <b-img v-else :src="webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="user_img_emp square_img">
                                </b-img>
                            </div>
                            <div class="user_name_div">
                                {{items.item.last_name}} {{items.item.first_name}}
                            </div>
                        </div>
                          <div class="mail_with_verified">
                            <p class="grey-text">
                                {{items.item.email}}
                            </p>
                            <div class="verified-badge-with-title" v-if="items.item.account_verification_status == 'completed'">Verified</div>
                          </div>
                    </template>
                    <template #cell(mobile_number)="items">
                    {{items.item.country_code}}-{{items.item.mobile_number}}
                    </template>
                    <template #cell(created_at)="items">
                    {{items.item.created_at | moment}}
                    </template>
                    <template #cell(status)="items">
                    <p class="green_status" v-if="items.item.status == 'active'">{{items.item.status | capitalize}}</p>
                    <p class="orange__status" v-if="items.item.status == 'inactive'">{{items.item.status | capitalize}}</p>
                    <p class="red_status" v-if="items.item.status == 'deleted'">{{items.item.status | capitalize}}</p>
                    <p class="red_status" v-if="items.item.status == 'blacklisted'">{{items.item.status | capitalize}}</p>

                    </template>
                    <!-- <template #cell(account_status)="items">
                    {{items.item.ft_account_status | capitalize}}
                    </template> -->
                    <!-- <template #cell(ft_account_status)="items">
                    {{items.item.ft_account_status | capitalize}}
                    </template>
                     <template #cell(account_verification_status)="items">
                    {{items.item.account_verification_status | capitalize}}
                    </template> -->
                    <template #cell(user_type)="items">
                    <p v-if="items.item.user_type_id == '3' && items.item.is_company_head == 'yes'">Admin</p>
                    <p v-else-if="items.item.user_type_id == '3'">OM</p>
                    <p v-if="items.item.user_type_id == '4'">Supervisor</p>
                    </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action common_ul_radius toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item v-if="items.item.is_company_head == 'no' && (items.item.status == 'active' || items.item.status == 'inactive') && show('edit')" :to="{name: 'EmpManageUserEditAdmin', params: {id: encrypt(items.item.id),company_id: encrypt(company_id)}}">Edit
                                </b-dropdown-item>
                                <b-dropdown-item v-if="items.item.user_type_id == '3' && items.item.is_company_head == 'yes' && showComAdmin('view profile')" :to="{name: 'employer-profile-view', params: {id: encrypt(items.item.id),company_id: encrypt(items.item.company_id)}}">View Profile
                                </b-dropdown-item>
                                <b-dropdown-item v-else-if="show('view profile')" :to="{name: 'EmpManageUserViewAdmin', params: {id: encrypt(items.item.id)}}">View Profile
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeCompanyUserStatus(items.item.id,items.item.status)" v-if="items.item.status == 'active' && items.item.is_company_head == 'no' && show('activate/inactivate')">Inactivate</b-dropdown-item>
                                <b-dropdown-item v-else-if="items.item.status == 'inactive' && items.item.is_company_head == 'no' && show('activate/inactivate')" v-on:click="changeCompanyUserStatus(items.item.id,items.item.status)">Activate
                                </b-dropdown-item>
                                <!-- <b-dropdown-item v-if="items.item.is_company_head == 'no' && items.item.status != 'deleted' && show('delete')" v-on:click="deleteCompanyUser(items.item.id)">Delete</b-dropdown-item> -->
                                <b-dropdown-item v-on:click="changeCompanyUserStatus(items.item.id, items.item.status)" v-if="items.item.status == 'deleted' && show('activate/inactivate')">Activate</b-dropdown-item>
                                <b-dropdown-item v-if="items.item.email && (items.item.status == 'active' || items.item.status == 'inactive') && show('send reset password link')" @click="sendResetPasswordLink(items.item.id)">Send Reset Password Link
                                </b-dropdown-item>
                                <b-dropdown-item v-if="(items.item.user_type_id == 4 || (items.item.user_type_id == 3 && items.item.is_company_head == 'no')) && (items.item.status == 'active') && show('change role')" @click="changeRole(items.item.id, items.item.user_type_id)">Change Role
                                </b-dropdown-item>
                                <!-- <b-dropdown-item v-if="(items.item.user_type_id == 3 && items.item.is_company_head == 'no' && items.item.email_verified == 'yes' && items.item.mobile_verified == 'yes') && (items.item.status == 'active') && show('make company admin')" @click="makeCompanyAdmin(items.item.id, items.item.company_id)">Make Company Admin
                                </b-dropdown-item> -->
                                <b-dropdown-item v-if="(items.item.user_type_id == 3 && items.item.is_company_head == 'no' && items.item.mobile_verified == 'yes') && (items.item.status == 'active') && show('make company admin')" @click="makeCompanyAdmin(items.item.id, items.item.company_id)">Make Company Admin
                                </b-dropdown-item>
                                <!-- <b-dropdown-item v-if="items.item.user_type_id == 3 && items.item.is_company_head == 'no'" @click="openAttachSupModal(items.item.id)">Attach User
                                </b-dropdown-item> -->
                                <b-dropdown-item v-if="items.item.status == 'active' && show('update password')" @click="openInfoModel(items.item.id,items.item.company_id,items.item.is_company_head,'password')">Update Password
                                </b-dropdown-item>
                                <b-dropdown-item v-if="items.item.account_verification_status != 'rejected' && items.item.status != 'deleted' && show('update email')" @click="openInfoModel(items.item.id,items.item.company_id,items.item.is_company_head,'email')">Update Email </b-dropdown-item>
                                <b-dropdown-item v-if="items.item.account_verification_status != 'rejected' && items.item.status != 'deleted' && show('update mobile number')" @click="openInfoModel(items.item.id,items.item.company_id,items.item.is_company_head,'mobile')">Update Mobile Number </b-dropdown-item>
                                 <b-dropdown-item v-if="items.item.email_verified == 'no' && items.item.email && (items.item.status == 'active') && show('verify email')" v-on:click="verifyEmail(items.item.id)">Verify Email</b-dropdown-item>
                                <b-dropdown-item v-if="items.item.mobile_verified == 'no' && items.item.mobile_number && (items.item.status == 'active') && show('verify mobile number')" v-on:click="verifyMobileNumber(items.item.id)">Verify Mobile Number</b-dropdown-item>
                                <b-dropdown-item v-if="items.item.user_type_id == 3 && items.item.is_company_head == 'no' && (items.item.status == 'active') && show('attach user')" @click="openAttachSupModal(items.item.id)">Attach User
                                </b-dropdown-item>
                                <!-- <b-dropdown-item  v-on:click="changeCompanyUserStatus(items.item.id,items.item.status)" v-if="items.item.status == 'active' && items.item.account_verification_status == 'completed'">Inactivate</b-dropdown-item>
                                <b-dropdown-item v-else-if="items.item.status == 'inactive'" v-on:click="changeCompanyUserStatus(items.item.id,items.item.status)">Activate
                                </b-dropdown-item> -->

                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                             <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getEmpUsers'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
            <b-modal hide-footer  id="attach-sup-modal" ref="attach-sup-modal" title="Select User" modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <div class="edit-task margin_top_zero model_legend">
                    <b-form @submit.prevent="attachSupSubmit" method="post">
                        <div class="popup-header">
                            <!-- <h2 class="">Select User</h2> -->
                            <div class="">
                                <div class="addUserForm">
                                    <div class="editForm mw-100 pl-0">
                                        <b-row>
                                          <b-col lg="12" md="12">
                                            <b-form-group id="input-group-8" label="All Users" class="required submit-field">
                                             <multiselect v-model="supervisorName" :custom-label="customLabel" tag-placeholder="Add this as new user" placeholder="Enter to search user" label="full_name" track-by="id" :options="selectedSupervisor" :multiple="true" class="new_multi_slec_list job_list_multi_select model_multi_select" ><span slot="noResult">No User Found.</span></multiselect>
                                            </b-form-group>
                                          </b-col>

                                        </b-row>

                                        <div slot="footer" class="form-btn common_crop_reset_btn">
                                          <b-button type="submit" variant="success" class="model_common_bt_success_color">Submit</b-button>
                                          <b-button @click="hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </b-modal>
            <b-modal ref="info-modal" :title="updateInfoTitle" hide-footer content-class="common_model_header common_model_title"  modal-class="background_custom_class">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box model_legend">
                                      <b-col lg="0" md="0" v-if="updateInfoTitle == 'Update Password'" class="submit-field">
                                         <div id="show_hide_password_login" class="submit-field">
                                          <b-form-group id="input-group-0" label="New Password" class="required model_margin_bottom_zero">
                                              <b-form-input id="input-0" type="password" placeholder="" v-model="new_password" class="input-with-border model_border_and_box_shadow"></b-form-input>
                                          </b-form-group>
                                          <div class="input-group-addon-custom update_pass_eye_div1">
                                            <a  @click="passwordClick">
                                                <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                         </div>
                                          <div id="show_hide_repassword_login">
                                          <b-form-group id="input-group-0" label="Repeat New Password" class="required model_margin_bottom_zero">
                                              <b-form-input id="input-0" type="password" placeholder="" v-model="confirm_new_password" class="input-with-border model_border_and_box_shadow"></b-form-input>
                                          </b-form-group>
                                          <div class="input-group-addon-custom update_pass_eye_div">
                                            <a  @click="repasswordClick">
                                                <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                            </a>
                                          </div>
                                          </div>
                                      </b-col>
                                      <b-col lg="9" md="9" v-if="updateInfoTitle == 'Update Email'">
                                          <b-form-group id="input-group-0" label="Email" class="required">
                                              <b-form-input id="input-0" type="text" placeholder="" :value="update_email" @change="v => update_email = v"></b-form-input>
                                          </b-form-group>
                                      </b-col>
                                      <b-col lg="9" md="9" v-if="updateInfoTitle == 'Update Mobile'">
                                          <b-form-group id="input-group-0" label="Mobile Number" class="required">
                                              <b-form-input id="input-0" type="text" placeholder="" :value="update_mobile_number" @change="v => update_mobile_number = v"></b-form-input>
                                          </b-form-group>
                                      </b-col>
                                      <b-col lg="0" md="0">
                                      <div slot="footer" class="form-btn common_crop_reset_btn">
                                          <b-button type="submit" variant="success"  v-if="updateInfoTitle == 'Update Password'" @click="submitUpdateInfoPassword()" class="model_common_bt_success_color">Submit</b-button>
                                          <b-button type="submit" variant="success" v-else @click="submitUpdateInfo()">Submit</b-button>
                                          <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                      </div>
                                      </b-col>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
            </b-modal>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from "moment";
import Multiselect from 'vue-multiselect'
import permission from "../../../../../server/permission.js";

export default {

    data() {
        return {
            fields: [
                { key: 'name', label: 'Name & Email', sortable: true },
                { key: 'mobile_number', label: 'Mobile Number', sortable: true   },
                { key: 'created_at', label: 'Register On', sortable: true   },
                // { key: 'status', label: 'Status', sortable: true },
                // { key: 'account_status', label: 'Account Status', sortable: true },
                { key: 'status', label: 'Account Status', sortable: true },
                // { key: 'ft_account_status', label: 'FT Status', sortable: true },
                // { key: 'account_verification_status', label: 'DP Status', sortable: true },
                { key: 'user_type', label: 'User Type', sortable: true },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            company_id: atob(this.$route.params.id),

            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            selectedsup:[],
            searchKeyword:'',
            supervisorName:'',
            selectedSupervisor:[],
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: '',
            searchKeyword:'',
            name:'',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            updateInfoTitle : 'Update Password',
            new_password:'',
            confirm_new_password:'',
            userData:[],
            update_user_id:'',
            update_email:'',
            update_mobile_number:'',
            update_user_type:'',
            companyDetail:'',
            companyAdmin: '',
            actions:'',
            actionsComAdmin:''

        }
    },
    components: {
                    Multiselect
     },
   filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD MMM YYYY')
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$refs.datatable.refresh();
                //this.$router.push({ name: 'empManageUserList' });
            });
        },
        customLabel({ first_name, last_name }) {
          return `${last_name} ${first_name}`
        },
        hideModel() {
            this.$refs['attach-sup-modal'].hide()
            this.$refs['info-modal'].hide();
        },
        attachSupSubmit(evt) {
          evt.preventDefault();
          this.hideModel();
          this.$store.dispatch(POST_API, {
            data:{
              user_id: this.supervisorName,
              supervisor_id: this.supervisor_id,
            },
            api: "/api/attach-supervisor-to-om",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
                this.success_message = this.$store.getters.getResults.message;
                // this.$refs.datatable.refresh();
                this.showSuccessAlert(this.success_message);
            }
          });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        verifyEmail(id)
        {
            return this.$store.dispatch(POST_API, {
                data:{
                user_id: id,
                },
                api:'/api/employer-verify-email-admin',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message='Email Verified successfully.';
                    // this.$refs.datatable.refresh();
                    this.showSuccessAlert(message);

                }
            });

        },
        empList(event){
            if (event) {
            event.stopPropagation()
            }
            window.location.replace('/v2/admin/employer');
        },
        verifyMobileNumber(id)
        {
            return this.$store.dispatch(POST_API, {
                data:{
                user_id: id,
                },
                api:'/api/employer-verify-mob-admin',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message='Mobile Number Verified successfully.';
                    // this.$refs.datatable.refresh();
                    this.showSuccessAlert(message);

                }
            });

        },
        getEmpUsers() {
            return this.$store.dispatch(POST_API, {
                data:{
                    company_id: this.company_id,
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'asc' : 'desc',
                },
                api: '/api/empManageUserList'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
        getComData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    company_id: atob(this.$route.params.id),
                },
                api: '/api/get-company-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){
                        this.name = this.$store.getters.getResults.data.name;
                    }
                    this.companyAdmin = this.$store.getters.getResults.companyAdmin;
                    this.companyDetail = this.$store.getters.getResults.data;
                }
            });
        },
        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='User Inactivated successfully.';
                var new_status = 'inactive';
            }
            else{
                var msg = 'Are you sure you want to activate this record?';
                var message='User Activated successfully.';
                var new_status = 'active';
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            status:new_status,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/emp-active-inactive-supom-admin'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            // this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        changeRole(id, type) {

            // var status = status != null ? status : this.usrStatus;

            if (type == '4') {
              var msg = 'Are you sure want to make this user OM?';
              var message='Role changed successfully.';
            }
            else
            {
              var msg = 'Are you sure want to make this user Supervisor?';
              var message='Role changed successfully.';

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          id: id,
                          type: type
                        },
                        api:"/api/emp-change-role-supom-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.showSuccessAlert(message);
                            // this.$refs.datatable.refresh();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        makeCompanyAdmin(id,company_id)
        {
            return this.$store.dispatch(POST_API, {
            data:{
                user_id: id,
                company_id: company_id
                },
            api:"/api/emp-make-company-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Company Admin Changed Successfully.';
                    this.showSuccessAlert(message);
                    // this.$refs.datatable.refresh();
                }
            });
        },
        sendResetPasswordLink(id)
        {
            return this.$store.dispatch(POST_API, {
            data:{
                user_id: id,
                },
            api:"/api/jobseekerEmp-reset-password-link-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Reset Password Link Sent Successfully.';
                    this.showSuccessAlert(message);
                    // this.$refs.datatable.refresh();
                }
            });
        },
        openInfoModel(id,company_id,update_user_type,type,info = null)
        {
            if(type == 'mobile'){
                this.updateInfoTitle = 'Update Mobile';
            } else if(type == 'email'){
                this.updateInfoTitle = 'Update Email';
            } else{
                this.updateInfoTitle = 'Update Password';
            }
            this.new_password = '';
            this.confirm_new_password = '';
            this.update_user_id = id;
            this.update_company_id = company_id;
            this.update_user_type = update_user_type == 'yes' ? 'admin' : 'om_supervisor'
            if(type == 'password'){
                this.$refs['info-modal'].show();
            }else{
                this.$refs['info-modal'].show();
                this.getUserData(id);
            }
        },
        getUserData(id) {
            this.$store.dispatch(POST_API, {
                data:{
                    id:id,
                },
                api: '/api/user-data-email-mobile'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.userData = this.$store.getters.getResults.data;
                    this.update_mobile_number = this.userData.mobile_number;
                    this.update_email = this.userData.email;
                    this.update_user_id = this.userData.id;
                    
                }
            });
        },
        passwordClick(event){
            event.preventDefault();
            if($('#show_hide_password_login input').attr("type") == "text"){
                $('#show_hide_password_login input').attr('type', 'password');
                $('#show_hide_password_login i').addClass( "fa-eye-slash" );
                $('#show_hide_password_login i').removeClass( "fa-eye" );
            }else if($('#show_hide_password_login input').attr("type") == "password"){
                $('#show_hide_password_login input').attr('type', 'text');
                $('#show_hide_password_login i').removeClass( "fa-eye-slash" );
                $('#show_hide_password_login i').addClass( "fa-eye" );
            }
        },
        repasswordClick(event){
            event.preventDefault();
            if($('#show_hide_repassword_login input').attr("type") == "text"){
                $('#show_hide_repassword_login input').attr('type', 'password');
                $('#show_hide_repassword_login i').addClass( "fa-eye-slash" );
                $('#show_hide_repassword_login i').removeClass( "fa-eye" );
            }else if($('#show_hide_repassword_login input').attr("type") == "password"){
                $('#show_hide_repassword_login input').attr('type', 'text');
                $('#show_hide_repassword_login i').removeClass( "fa-eye-slash" );
                $('#show_hide_repassword_login i').addClass( "fa-eye" );
            }
        },
        submitUpdateInfoPassword(){
          return this.$store.dispatch(POST_API, {
                data:{
                  user_id : this.update_user_id,
                  new_password : this.new_password,
                  confirm_new_password : this.confirm_new_password,
                },
                api: '/api/emp-company-update-password-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        this.new_password = '';
                        this.confirm_new_password = '';
                        this.hideModel();
                        this.showSuccessAlert(this.success_message);
                        // this.refreshDataTable();

                    }
                });
        },
        submitUpdateInfo(){
          return this.$store.dispatch(POST_API, {
                data:{
                  user_id : this.update_user_id,
                  company_id : this.update_company_id,
                  email : this.update_email,
                  mobile_number : this.update_mobile_number,
                  user_type : this.update_user_type,
                  type : (this.updateInfoTitle == 'Update Mobile' ? 'mobile' : 'email')
                },
                api: '/api/emp-save-email-mobile-adminomsupervisor'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        this.hideModel();
                        this.showSuccessAlert(this.success_message);
                        this.update_mobile_number = '';
                        this.update_email = '';
                        this.update_user_id = '';
                        this.update_company_id = '';
                        this.update_user_type = ''
                        // this.refreshDataTable();
                    }
                });
        },
        changeCompanyUserStatus(id, status) {

            // var status = status != null ? status : this.usrStatus;

            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='User Inactivated Successfully.';
            }
            else if (status == 'deleted' || status == 'inactive') {
                var msg = 'Are you sure you want to activate this record?';
                var message='User Activated Successfully.';
                this.remarks = null

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          id: id,
                          status:status,
                        },
                        api:"/api/admin-emp-comuser-active-inactive",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.showSuccessAlert(message);
                            // this.datatableRefresh();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        deleteCompanyUser(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/admin-empjobseeker-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Employer Deleted successfully.';
                            // this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
         openAttachSupModal(id)
        {
          this.$refs['attach-sup-modal'].show();
          this.supervisor_id = id;

          return this.$store.dispatch(POST_API, {
            data:{
                  user_id: id,
                },
            api:"/api/list-supervisors-to-assign-om",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
              }
              else {
                  this.selectedSupervisor = this.$store.getters.getResults.data;
                  this.supervisorName = this.$store.getters.getResults.selected;

                  // return this.selectedSupervisor;
              }
          });
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
                this.actions = permission.getPermissionStaff(menu,'Company Users');
                this.actionsComAdmin = permission.getPermissionStaff(menu,'Companies');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showComAdmin(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actionsComAdmin.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },

    },
    mounted() {
        $(".show_filter_arrow .table thead th div").contents().unwrap();
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.company_id = atob(this.$route.params.id);
        this.getComData();
        this.permissionStaff();


    },
}
</script>
