<template>
    <div>

        <!-- Titlebar
        ================================================== -->

        <div  class="bg_img_top static_page_bg" v-bind:style="{backgroundImage: faq_data[0].image && faq_data[0].show_banner_image == 'yes' ? 'url('+ faq_data[0].image +')' : 'url('+ webUrl+ 'assets/images/faq-bg.jpeg)'}">

        <!-- <div id="titlebar" class="gradient signup_mrgn faq_mrgn"> -->
            <div class="full_gragient">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-center faq_haed" v-html="faq_data[0].title"></h1>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div class="margin-top-50 mobile_tab_margin_top_35"></div>
    <!-- Page Content
        ================================================== -->
        <div class="container">
            <div class="row">
                <div class="col-xl-12 common_block">
                    <div class="selected_faq_radio">
                        <input type="radio" v-model="x" value="jobseeker"><span class="first_span_radio">Job Seekers</span>
                        <input type="radio" v-model="x" value="hirers"><span>Hirers</span>
                    </div>
                    <!-- <div v-show="x === 'one'">One</div>
                    <div v-show="x === 'two'">Two</div> -->



                    <b-container v-if="faqs" class="faq_paragraph">
                        <div v-show="x === 'jobseeker'">
                            <div class="form-head mb-3 text-center">
                                <h4 class="golden-text"><b>Job Seekers</b></h4>
                            </div>
                            <!-- <div class="col-xl-5 col-md-5"> -->
                                <!-- <div class="col-xl-12 col-md-12"> -->
                                    <div class="single_search_web faq_search">
                                        <input type="text" class="keyword-input" placeholder="Search by keyword" v-model="filter" v-on:change="getWebsiteData" style="background-color: rgba(0,0,0,.03); border:3px solid #ced4da ;">
                                        <b-button @click="getWebsiteData" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                    </div>
                            <!-- </div> -->

                            <div class="faq-content-box clearfix" v-if="faqs.length > 0">
                                <div v-for="(faq,key) in faqs">
                                    <div class="accordion-listing-box max_width_hundered">
                                        <b-card no-body v-if="faq.faq_type == 'Employee'">
                                            <b-card-header header-tag="header" role="tab">
                                                <b-button v-b-toggle="'accordion-'+key" class="m-1" variant="info">{{faq.question}}</b-button>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-'+key" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-card-text v-if="faq.url"><p><a :href="faq.url" target="_blank">{{faq.answer}}</a></p></b-card-text>
                                                    <b-card-text v-else><p>{{faq.answer}}</p></b-card-text>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                    </div>
                                </div>
                            </div>
                            <div  v-else>
                                <p class="margin_top_1rem" style="text-align:center;"><b>No Record Found</b> </p>
                            </div>
                        </div>
                        <!-- <br> -->
                        <div v-show="x === 'hirers'">
                            <div class="form-head mb-3 text-center">
                                <h4 class="golden-text"><b>Hirers</b></h4>
                            </div>
                            <!-- <div class="col-xl-5 col-md-5"> -->
                            <div class="single_search_web faq_search">
                                <input type="text" class="keyword-input" placeholder="Search by keyword" v-model="filter" v-on:change="getWebsiteData" style="background-color: rgba(0,0,0,.03); border:3px solid #ced4da ;">
                                <b-button @click="getWebsiteData" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                            </div>
                            <!-- </div> -->
                            <div class="faq-content-box clearfix" v-if="faqs.length > 0">
                                <div v-for="(faq,key) in faqs">
                                    <div class="accordion-listing-box max_width_hundered">

                                        <b-card no-body v-if="faq.faq_type == 'Employer'">
                                            <b-card-header header-tag="header" role="tab">
                                                <b-button v-b-toggle="'accordion-'+key" class="m-1" variant="info">{{faq.question}}</b-button>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-'+key" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-card-text v-if="faq.url"><p><a :href="faq.url" target="_blank">{{faq.answer}}</a></p></b-card-text>
                                                    <b-card-text v-else><p>{{faq.answer}}</p></b-card-text>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p class="margin_top_1rem" style="text-align:center;"><b>No Record Found</b> </p>
                            </div>
                        </div>
                    </b-container>
                </div>
            </div>
        </div>



        <!-- Spacer -->
        <div class="margin-top-30"></div>
        <!-- Spacer / End-->
        <div>
            <center>

            <h2 class="common_futura_heavy">
                Do you have any query?
            </h2><br/>

            <h4 class="common_futura_medium">
                Contact our support team to get quick answers
            </h4><br/>

            <b-link class="btn green-border-btn desktop_margin_top_zero desktop_margin_right_zero" :to="{name:'ContactUs'}" style="margin-bottom: 40px !important;">Contact Us Now</b-link>
<br/>

        </center>
        </div>
    </div>
</template>


<script>
import { POST_API } from '../../store/actions.type';
import { Carousel, Slide } from 'vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import moment from 'moment'

export default{
    metaInfo: {
            title: 'EL Connect - FAQ',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'FAQ - Not sure how to use EL Connect Manpower on Demand Job Platform? Browse our FAQs to get assistance with applying for jobs. ' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                {property: 'og:title', content: 'EL Connect - Faq'},
			    {property: 'og:site_name', content: 'EL Connect'},
			    {property: 'og:type', content: 'website'},
			    {property: 'og:url', content: 'https://elconnect.sg/faq'},
			    {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			    {property: 'og:description', content: 'FAQ - Not sure how to use EL Connect Manpower on Demand Job Platform? Browse our FAQs to get assistance with applying for jobs.'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/faq'}
  		    ]
    },
    data() {
        return {
            x: 'jobseeker',
            webUrl:process.env.VUE_APP_URL,
			banners:[],
			faqs:[],
            faq_data:[],
			process_descriptions:[],
			partners:[],
			promotions:[],
			jobs:[],
			filter:'',
			tagArray:[],
			BackImage: 'url(/assets/images/home-background.jpg)',
			settings: {
				"slidesToShow": 2,
				"slidesToScroll": 2,
				"speed": 800,
				"infinite": false,
				responsive: [
					{
					breakpoint: 768,
					settings: {
						"slidesToShow": 1,
							"slidesToScroll": 1,
							"speed": 800,
							"infinite": false
					}
					}
				]
			}
        }
    },
	components: {
        VueSlickCarousel,
        Carousel,
        Slide
    },
	filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },

            customFormatterOne(date) {
                return moment(date).utc().format('DD-MM-YYYY');
            },

            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
    },
	methods:{
        FaqStaticData(){
                 this.$store.dispatch(POST_API, {
                    data:{
                        token: this.email,
                    },
                    api:'/api/faq-static-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.faq_data = this.$store.getters.getResults.faq_data;
                    }
            })

            },
		getWebsiteData() {
            return this.$store.dispatch(POST_API, {
				data:{
                    keyword: this.filter,
                },
                api: '/api/faq'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.faqs = this.$store.getters.getResults.data;



                }
            });
        },
		// getJobData() {
        //     return this.$store.dispatch(POST_API, {
		// 		data:{
        //             keyword: this.filter,
        //         },
        //         api: '/api/job-data'
        //     })
        //     .then(() => {
        //         if (this.$store.getters.containsErrors) {
        //             this.error_message = this.$store.getters.getErrors;
        //             // this.showAlert();
        //             return [];
        //         } else {
        //             this.jobs = this.$store.getters.getResults.jobs;

        //         }
        //     });
        // },
	},

	mounted(){
             window.scrollTo(0,0);

        this.getWebsiteData();
        this.FaqStaticData();
		// this.getJobData();
        //document.title = "EL Connect - Faq";
    }
}


</script>
