<template>
  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Invoices</h1>
        </div>

        <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
              </div> -->
      </div>
    </div>
    <b-row>
      <b-col lg="12">
        <div class="tab-box--head top_custom_head desktop_search_top_div">
          <div class="row">
            <div
              class="col-xl-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <b-input-group-prepend>
                  <b-form-input
                    id="searchBtn"
                    @keyup.enter.native="filter = search"
                    v-model="search"
                    placeholder="Search by keyword"
                  ></b-form-input>
                  <b-button class="btn points_events"
                    ><i class="fa fa-search" @click="filter = search"></i
                  ></b-button>
                </b-input-group-prepend>
              </div>
            </div>
            <div class="col-xl-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <label class="timesheet_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Created on</label>       
              <div class="select-service-box date-picker-field clearfix">
                <!-- <div class="label_div text_align_end_desktop">
                          <label>Date</label>
                        </div> -->
                <b-form-group id="input-group-7" class="required mb-0">
                  <date-range-picker
                        class=" web_bottom_zero timesheet_calender_new calender_border transactions_jb_calender calender_div_job_in_job common_width_100"
                        ref="picker"
                        :minDate="minDate"
                        :opens="center"
                        :locale-data="locale"
                        :auto-apply="auto"
                        v-model="dateRange"
                        :ranges="range"
                        @update="dateFilterFunction()"
                      >
                        <div slot="input" slot-scope="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </div>
                      </date-range-picker>
                </b-form-group>
              </div>
            </div>
              <div class="col-xl-12 mobile_tab_max_width_flex search_common_margin_bottom_28 invoices_btns">
                <b-button
                  v-if="search != null || dateRange.startDate != null && dateRange.endDate != null"
                  @click="resetFilter()"
                  class="mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter_invoices  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120"
                  style="background-color: red"
                  ><span class="mobile_reset">Reset filters</span></b-button
                >
                  <b-button
                    class="first_screen_invoices_button ml-0 common_z_index_zero button ripple-effect new_add_btn_admin_e desktop_margin_right_zero"
                    @click="openModel('', 'bi_weekly', '', '')"
                    >Export Bi-Weekly Report</b-button
                  >
                  <b-button
                    class="first_screen_invoices_button common_z_index_zero button ripple-effect new_add_btn_admin_e desktop_margin_right_zero"
                    @click="exportData()"
                    style="margin-left: 19px"
                    >Export Invoices Data</b-button
                  >
                  <b-button v-if="show('download-all-pdfs')"
                    class="first_screen_invoices_button common_z_index_zero button ripple-effect new_add_btn_admin_e desktop_margin_right_zero"
                    @click="openModel('', 'all_pdf', '', '')"
                    style="margin-left: 19px"
                    >Download All Pdf</b-button
                  >
            </div>
          </div>
        </div>
        <div class="my_new_container show_filter_arrow">
          <b-table
            ref="datatable"
            show-empty
            striped
            hover
            responsive
            :items="getListing"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="new_layout_table_like_v1"
          >
            <template #cell(invoice_number)="items"  >
              {{items.item.invoice_user != null ? items.item.invoice_user.invoice_number : '' | capitalize}}
            </template>
            <template #cell(inv_month)="items">
              {{items.item.invoice_user != null ? items.item.start_date : '' | monthYear}}
            </template>
            <template #cell(act_inv_month)="items">
              {{items.item.invoice_user != null ? items.item.end_date : '' | date}}
            </template>
            <template #cell(company)="items">
              {{items.item.user_id.billing_adddress.length > 0 ? (items.item.user_id.billing_adddress[0].billing_company_name != null ? items.item.user_id.billing_adddress[0].billing_company_name : (items.item.user_id.company_id != null ? items.item.user_id.company_id.name : '')) : (items.item.user_id.company_id != null ? items.item.user_id.company_id.name : '-') | capitalize}}<br>
                <a href="javascript:void(0);">
                  <b-button @click="items.toggleDetails" v-if="items.item.user_id.company_id.has_department == 'yes'" class="revenue_show_hide_btns ml-0 pl-0 common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120" style="text-decoration: underline">
                    {{ items.detailsShowing ? 'Hide Department' : 'Show Department' }}
                  </b-button>
                </a>
            </template>
            <template #cell(user)="items">
              {{items.item.user_id.last_name | capitalize}} {{items.item.user_id.first_name | capitalize}}
            </template>
            <template #cell(amount)="items">
              S$ {{items.item.tamount | capitalize}}
            </template>
            <template #cell(status)="items">
              <span class="btn green-bg text-white mr-0 ml-0" v-if="items.item.inStatus == 'closed'">{{items.item.inStatus | capitalize}}</span>
              <span class="btn dark-red-bg text-white mr-0 ml-0" v-if="items.item.inStatus == 'open'">{{items.item.inStatus | capitalize}}</span>
            </template>
            <template #row-details="row">
              <b-table ref="datatable" show-empty striped hover responsive :items="row.item.invoice_user.department_invoices" thead-class="hidden_header" :fields="fields1" class="show_department_invoice_tbl">
                <template #cell(department_inv)="items">
                  {{items.item.dep_invoice_no != null ? items.item.dep_invoice_no : ''}}
                </template>
                <template #cell(department)="items">
                  {{items.item.department_id.department_name != null ? items.item.department_id.department_name : '' | capitalize}}
                </template>
                <template #cell(amount)="items">
                  ${{items.item.total != null ? items.item.total : 0 }}
                </template>
                <template #cell(amount_with_gst)="items">
                  ${{items.item.total_with_gst != null ? items.item.total_with_gst : 0 }}
                </template>
                <template #cell(download)="items">
                  <b-link :to="{}" @click="downloadPdf(items.item.url)" >Download Report
                  </b-link> 
                </template>
                <template #cell(created_on)="items">
                  {{items.item.created_at != null ? items.item.created_at : '' | date_formate}}
                </template>
                <template #cell(toggleAction)="items">
                  <div class="toggle-action">
                    <b-dropdown class="mx-1" right text="Actions" boundary="window">
                        <b-dropdown-item v-on:click="downloadCombinedPdf(items.item,'department')" v-if="show('download-pdfs')">Download Combined PDF
                        </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>

              </b-table>
            </template>
            <template #cell(created_on)="items">
              {{items.item.invoice_user != null ? items.item.invoice_user.invoice_date : '' | date_formate}}
            </template>
            <template #cell(due_date)="items">
              {{due_date(items.item.end_date, items.item.payment_terms)}}
            </template>
            <template #cell(toggleAction)="items">
              <div class="toggle-action">
                <b-dropdown class="mx-1" right text="Actions" boundary="window">
                  <b-dropdown-item :to="{}" @click="downloadPdf(items.item.url)" v-if="show('download')">Download Report
                  </b-dropdown-item>
                  <b-dropdown-item :to="{}" @click="openModel(items.item.invoice_id, 'department', items.item.zip_url, '')" v-if="show('download') && items.item.user_id.company_id.has_department == 'yes'">Export as Zip
                  </b-dropdown-item>
                  <b-dropdown-item :to="{}" @click="downloadFile(items.item.id,items.item.zip_url)" v-if="show('download') && items.item.user_id.company_id.has_department == 'no'">Export as Zip
                  </b-dropdown-item>
                  <b-dropdown-item :to="{}" @click="downloadCombinedPdf(items.item,'invoice')" v-if="show('download-pdfs')">Download Combined PDF
                  </b-dropdown-item>
                  <b-dropdown-item :to="{}" @click="openModel(items.item.user_id.company_id.id, 'summary', items.item.billing_cycle, '')" v-if="show('download-summary')">Download Summary
                  </b-dropdown-item>
                  <b-dropdown-item :to="{}" @click="sendInvoice(items.item.invoice_id,items.item.user_id.company_id.id,items.item.zip_url)" v-if="show('send-invoice')">Send Invoice Email
                  </b-dropdown-item>
                  <b-dropdown-item :to="{}" @click="deleteRecord(items.item.invoice_id)" v-if="show('delete')">Delete
                  </b-dropdown-item>
                  <b-dropdown-item @click="openModel(items.item.user_id.company_id.id, 'generate_inv', items.item.billing_cycle, 'invoice')" v-if="show('regenerate-invoice')">Generate Invoice
                  </b-dropdown-item>
                  <b-dropdown-item @click="openModel(items.item.user_id.company_id.id, 'generate_inv', items.item.billing_cycle, 'department_invoice')" v-if="show('regenerate-invoice') && items.item.user_id.company_id.has_department == 'yes'">Generate Department Invoice
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @change="getListing">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span>
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </b-col>
    </b-row>
    <b-modal ref="invoice-modal" title="Select Option" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class">
        <!-- <b-form> -->
            <div class="edit-form-box model_margin_top_zero">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="12" md="12">
                            <div class="form-map-box model_legend">
                              <div v-if="generate_type == 'department'">
                                <b-col lg="0" md="0">
                                  <b-form-group id="input-group-8" label="With Department Zip " class="category-radio">
                                    <b-form-radio v-model="withDepartmentZip" name="withDepartmentZip" value="yes">Yes</b-form-radio>
                                    <b-form-radio v-model="withDepartmentZip" name="withDepartmentZip" value="no">No</b-form-radio>    
                                  </b-form-group>
                                </b-col>
                              </div>
                              <div v-else>
                                <b-col lg="0" md="0">
                                  <b-form-group id="input-group-6" label="Invoice Month & Year" class="required">
                                      <datepicker v-model="month" :format="customFormatterThree" initial-view="year" minimum-view="month" :disabled-dates="disabledFromDate1" class="ticket_close_btn_from_date"></datepicker>
                                      <span class="input-icon normal_calender_single"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                  </b-form-group>
                                </b-col>
                                <b-col lg="0" md="0">
                                  <b-form-group id="input-group-8" label="Duration " class="category-radio" v-if="billing_cycle == 'monthly'">
                                      <b-form-radio v-model="duration" name="duration" value="3">1-30</b-form-radio>   
                                  </b-form-group>
                                  <b-form-group id="input-group-8" label="Duration " class="category-radio employer_multiple_radio" v-else>
                                      <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                                      <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>       
                                  </b-form-group>
                                </b-col>
                                <b-col lg="6" md="6" v-if="generate_type == 'generate_inv'">
                                    <b-form-group>
                                        <b-form-checkbox v-model = 'withNoEmail'  @input="withNoEmailFunc($event)"><strong>With No Email</strong></b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                              </div>
                              <div slot="footer" class="form-btn common_crop_reset_btn">
                                  <b-button type="submit" variant="success" v-if="generate_type == 'generate_inv'" @click="onGenerateInvoice()" class="model_common_bt_success_color">Submit</b-button>
                                  <b-button type="submit" variant="success" v-else-if="generate_type == 'summary'" @click="downloadSummary()" class="model_common_bt_success_color">Submit</b-button>
                                  <b-button type="submit" variant="success" v-else-if="generate_type == 'department'" @click="downloadZip()" class="model_common_bt_success_color">Submit</b-button>
                                  <b-button type="submit" variant="success" v-else @click="downloadAllPdf()" class="model_common_bt_success_color">Submit</b-button>
                                  <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                              </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        <!-- </b-form> -->
    </b-modal>
    <b-modal ref="range-modal" title="Select Range" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class">
        <!-- <b-form> -->
            <div class="edit-form-box model_margin_top_zero">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="12" md="12">
                            <div class="form-map-box model_legend">
                                <b-col lg="0" md="0">
                                  <b-form-group id="input-group-6" label="Date Range:" >
                                        <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="updateRange()" class="width_100_per common_date_range_width_hundered common_date_range_model_form_control">
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </div>
                                        </date-range-picker>
                                        <span class="input-icon normal_calender_single">
                                            <b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                        </span>
                                    </b-form-group>
                                </b-col>
                                <!-- <b-col lg="6" md="6" v-if="generate_type == 'generate_inv'">
                                    <b-form-group>
                                        <b-form-checkbox v-model = 'withNoEmail'  @input="withNoEmailFunc($event)"><strong>With No Email</strong></b-form-checkbox>
                                    </b-form-group>
                                </b-col> -->
                              <div slot="footer" class="form-btn common_crop_reset_btn">
                                  <b-button type="submit" variant="success" class="model_common_bt_success_color" @click="exportWeeklyReport()">Submit</b-button>
                                  <b-button @click="hideRangeModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                              </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        <!-- </b-form> -->
    </b-modal>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type.js";
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import permission from "../../../../../server/permission.js";
import DateRangePicker from 'vue2-daterange-picker'

export default {
  data() {
    return {
      fields: [
        { key: 'invoice_number', label: 'Invoice Number', sortable: true, thStyle: { width: "15%" } },
        { key: 'inv_month', label: 'Invoice Month & Year', sortable: true,thStyle: { width: "15%" } },
        { key: 'act_inv_month', label: 'Invoice Date', sortable: true, thStyle: { width: "10%" } },
        { key: 'user', label: 'Employer', sortable: true, thStyle: { width: "10%" } },
        { key: 'company', label: 'Company', sortable: true, thStyle: { width: "10%" } },
        { key: 'status', label: 'Status', sortable: true, thStyle: { width: "10%" } },
        { key: 'due_date', label: 'Due Date', sortable: true,thStyle: { width: "10%" } },
        { key: 'created_on', label: 'Created On', sortable: true, thStyle: { width: "10%" } },
        { key: 'amount', label: 'Amount', sortable: true, thStyle: { width: "5%" } },
        { key: 'toggleAction', label: 'Actions',  thStyle: { width: "5%" } }
      ],
      fields1: [
        { key: 'department_inv', label: 'Invoice Number', sortable: false },
        { key: 'department', label: 'Department', sortable: false },
        { key: 'amount', label: 'Amount', sortable: false },
        { key: 'amount_with_gst', label: 'Amount(+GST)', sortable: false },
        { key: 'download', label: 'Download', sortable: false },
        { key: 'created_on', label: 'Created On', sortable: false },
        { key: 'toggleAction', label: 'Actions', }
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      search: null,
      perPage: 25,
      itemsJobs: null,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,
      month: '',
      pageOptions: [25, 50, 75, 100],
      locale: {
        direction: "ltr", //direction of text   
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      webUrlV1: process.env.VUE_APP_URL_V1,
      form: {
        rowsPerPage: 25,
        status: "",
      },
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
      actionsFreelancer: "",
      user_id:"",
      designation:"",
      dateRange: {
        startDate: null,
        endDate: null,
      },
      zip_url:'',
      invoice_generate_for_user:'',
      withNoEmail:true,
      generate_type:'generate_inv',
      billing_cycle: "bi-monthly",
      inv_type:'invoice',
      duration:1,
      disabledFromDate1: {
        from: new Date()
      },
      withDepartmentZip:'yes',
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    moment: function(date) {
            if (!date) return '-'
            return moment(date).format('DD-MM-YYYY hh:mm A')
     },
    momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
    monthYear: function(value) {
        if (value == null) {
            return '';
        } else {
          var spl = value.split('-');
        
          if(spl[2] == '16'){
            return moment(value).format('16 MMM YYYY')
          }else{
            return moment(value).format('01 MMM YYYY')
          }
        }
    },
    date_formate: function(date) {
      if(date != null)
        return moment(date).format('DD-MM-YYYY hh:mm A')
      else
        return '-';
    },
  },
  components: {
    Datepicker,
    DateRangePicker
  },
  methods: {
    customFormatter(date) {
            return moment(date).format('MMM-YYYY');
     },
    exportData() {
      var base_url = this.webUrlV1;
      //var page = this.currentPage;
      var keyword = this.search ? this.search : "null";
      //var rowsPerPage = this.form.rowsPerPage;
      var startDate = this.dateRange.startDate 
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD") 
        : "null";
      var endDate = this.dateRange.endDate 
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD") 
        : "null";
      //var sortBy = this.sortBy ? this.sortBy : "date";
      //var sortDirection = this.sortDesc ? "desc" : "asc";
      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/export-invoice-from-admin/" +
        keyword +
        "/" +
        startDate +
        "/" +
        endDate;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    getListing() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.search,
            rowsPerPage:this.form.rowsPerPage,
            sortBy:this.sortBy,
            sortDirection:this.sortDirection,
            sortDesc:this.sortDesc,
            startDate: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
            endDate: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
          },
          api: "/api/invoice-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.totalRows = this.$store.getters.getResults.data.total;
            this.perPage = this.$store.getters.getResults.data.per_page;
            this.from = this.$store.getters.getResults.data.from
            this.to = this.$store.getters.getResults.data.to
              
            return this.items;
          }
        });
    },
    resetFilter() {
        this.search = null;
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.getListing().then(() => {
          this.$refs.datatable.refresh();
        })
      },
    openModel(id,type, billing_cycle, inv_type)
      {
        if(type == 'department'){
          this.zip_url = billing_cycle;
        }

        this.inv_type = inv_type;

        this.billing_cycle = billing_cycle;
        
        if(type == 'generate_inv' || type == 'summary' || type == 'department'){
          this.invoice_generate_for_user = id;
        }else{
          this.invoice_generate_for_user = this.user_id;
        }

        this.generate_type = type;

        if(type == 'bi_weekly'){
          this.$refs['range-modal'].show();
        }else{
          this.$refs['invoice-modal'].show();

        }
        
      },
    downloadPdf(url){
      window.open(url, '_blank')
    },
    downloadCombinedPdf(items,type){
        return this.$store.dispatch(POST_API, {
              data:{
                items: items,
                type:type
              },
              api: '/api/download-combined-pdf'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    var data = this.$store.getters.getResults.data;
                    console.log(data);
                    this.downloadFile(data.name, data.url);
                }
            });
    },
    showSuccessAlertStatus(message) {
      this.$swal.fire({
        position: 'top-center',
        type: 'success',
        text: message,
        showConfirmButton: false,
        timer: 3000
      })
    },
    downloadFile(name, path){
        console.log(name,path);
        var file_path = path;
        var a = document.createElement('A');
        a.target= '_blank';
        a.href = file_path;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
    sendInvoice(id,company_id,zip_url){

      return this.$store.dispatch(POST_API, { 
          data:{
            id:id,
            company_id:company_id,
            url:zip_url
          },
          api: '/api/send-invoices-to-mail'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
          } else {
            var msg = this.$store.getters.getResults.message;
                
            this.showSuccessAlertStatus(msg);
            this.getListing();
            this.$refs.datatable.refresh();

          }
      });
    },
    due_date(due_date,interval){

    var intrvl = interval != '' ? interval : '0';
    // console.log(due_date, interval, (parseInt(intrvl) + 3), intrvl, moment(due_date).add((parseInt(intrvl) + 3),'days').format('DD-MM-YYYY HH:mm:ss'));
    return moment(due_date).add((parseInt(intrvl) + 3),'days').format('DD-MM-YYYY');
    },
    deleteRecord(id){
      var msg = "Are you sure you want to delete this invoice?";

        this.$swal.fire({
            title: 'Please Confirm',
            text: msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          })
        .then(result => {
          if (result.value) {

            return this.$store.dispatch(POST_API, { 
                data:{
                  id:id
                },
                api: '/api/delete-invoice'
              })
              .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                var msg = this.$store.getters.getResults.message;
                
                this.showSuccessAlertStatus(msg);
                this.getListing();
                this.$refs.datatable.refresh();

              }
            });
    
          }
        })
        .catch(err => {
            // An error occurred
        })
    },
    showAlert() {
        // window.scrollTo(0,0);
        this.dismissCountDown = this.dismissSecs
        this.$swal.fire({
          position: 'top-center',
          type: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },
    customFormatterThree(date) {
        return moment(date).format('MMM,YYYY');
    },
    withNoEmailFunc($event){
        if($event != null){
            this.withNoEmail = $event;
        }else{
            this.withNoEmail = $event;
        }
    },
    onGenerateInvoice(id){
      this.hideModel();
      this.$store.dispatch(POST_API, {
            data:{
            month: this.month != '' ? moment(this.month).format('MM') : '',
            year: this.month != '' ? moment(this.month).format('YYYY') : '',
            company_id:this.invoice_generate_for_user,
            with_no_email:this.withNoEmail,
            type:'admin',
            //is_company:'yes',
            duration:this.duration
            },
          api: (this.inv_type == 'invoice' ? '/api/admin-generate-invoice' : '/api/admin-generate-department-invoice')
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                
            } else {
                this.items = this.$store.getters.getResults.data;
                  this.success_message = this.$store.getters.getResults.message;

                  this.payslip_generate_for_user = '';
                  this.month = '';
                
                  this.$swal.fire({
                    position: 'top-center',
                    type: 'success',
                    text: this.success_message,
                    showConfirmButton: false,
                    timer: 3000
                  })    
                this.hideModel();
                this.$refs.datatable.refresh();
              }
        });
      },        
    
    downloadSummary(){
      return this.$store.dispatch(POST_API, {
            data:{
              month: this.month != '' ? moment(this.month).format('MM') : '',
              year: this.month != '' ? moment(this.month).format('YYYY') : '',
              company_id:this.invoice_generate_for_user,
              duration:this.duration

            },
            api: '/api/download-invoices-summary'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  return [];
              } else {
                  var data = this.$store.getters.getResults.data;
                  console.log(data);
                  this.downloadFile(data.name, data.url);
                  this.$refs['invoice-modal'].hide();
              }
          });
    },
      downloadZip(){
        if(this.withDepartmentZip == 'no'){
          this.downloadFile(this.invoice_generate_for_user,this.zip_url);
        }else{
          return this.$store.dispatch(POST_API, { 
                data:{
                  withDepartmentZip:this.withDepartmentZip,
                  invoice_id: this.invoice_generate_for_user,
                  zip_url: this.zip_url,

                },
                api: '/api/download-invoices-zip-with-department'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                  this.hideModel();
                    var data = this.$store.getters.getResults.data;
                    console.log(data);
                    this.downloadFile(data.name, data.url);
                }
            });
          }
      },
      downloadAllPdf(){
        return this.$store.dispatch(POST_API, {
              data:{
                month: this.month != '' ? moment(this.month).format('MM') : '',
                year: this.month != '' ? moment(this.month).format('YYYY') : '',
                // user_id:this.user_id,
                duration:this.duration

              },
              api: '/api/download-invoices-zip'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    var data = this.$store.getters.getResults.data;
                    console.log(data);
                    this.downloadFile(data.name, data.url);
                    this.$refs['invoice-modal'].hide();
                }
            });
      },
      hideModel()
      {
        this.$refs['invoice-modal'].hide();
      },
      hideRangeModel(){
        this.$refs['range-modal'].hide();
      },
      exportWeeklyReport(){

        if(this.dateRange.startDate == null){
          this.error_message = 'Please select date range';
          this.showAlert();
          return false;
        }
        if(this.dateRange.endDate == null){
          this.error_message = 'Please select date range';
          this.showAlert();
          return false;

        }

        var base_url = this.webUrlV1;
        var filter = this.filter ? this.filter : "null";

        var export_url = '/api/export-monthly-invoice-report-for-finance/'+filter+'/'+(this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null')+'/'+(this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null')+'/bi-weekly';

        window.open(base_url+export_url,'_blank')
        },
      dateFilterFunction()
      {
          this.getListing().then(() => {
              this.$refs.datatable.refresh();
          })
      },
      permissionStaff() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.user_type_id == 2
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.actions = permission.getPermissionStaff(menu, "Employer Invoices");
            this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
          }
        }
      },
      show(action){
          if (this.$store.getters.currentUser.user_type_id == 2) {
              return this.actions.indexOf(action) >= 0 ? true : false ;  
          }else{
              return true;
          }
      },
      showFreelancer(action) {
        return true;
        // if (this.$store.getters.currentUser.user_type_id == 2) {
        //   return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
        // } else {
        //   return true;
        // }
      },
    },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
      this.permissionStaff();
  },
};
</script>
