<template>
    <div class="animated fadeIn">
      <div class="manage-job-section">
        <div
          class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
        >
          <div class="dashboard-headline">
            <h1 class="mr-auto">Freelancer Payslip</h1>
          </div>
          <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                  <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect new_add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
          </div> -->
        </div>
      </div>
      <b-row>
        <b-col lg="12">
          <div class="tab-box--head top_custom_head desktop_search_top_div">
            <div class="row">
               <div class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28" >
                <div class="search-box custom_search_admin desktop_margin_top_zero">
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = searchKeyword"
                      v-model="searchKeyword"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = searchKeyword"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>
              <div class="col-xl-4 mobile_max_widht_and_flex">
                <div class="select-service-box date-picker-field clearfix">
                  <!-- <div class="label_div text_align_end_desktop">
                            <label>Date</label>
                          </div> -->
                  <b-form-group id="input-group-7" class="required">
                    <datepicker
                        v-model="defaultDate"
                        :format="customFormatterOne"
                        :typeable="false"
                        :placeholder="'MMMM-YYYY'"
                        minimum-view='month' maximum-view='year'
                        :disabled-dates="disabledFromDate"
                        @input="dateFilterFunction"
                        class="add_calender_icon common_normal_calender_height freelancer_incentive_calender"
                    ></datepicker>
                  </b-form-group>
                </div>
              </div>
              <div
                class="col-xl-4 invoices_btns mobile_tab_max_width_flex new_search_div_daily_job tab_margin_bottom_10 search_common_margin_bottom_28 mobile_margin_bottom_10"
              >
              <b-button
              @click="clearFilter()"
              class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
              style="background-color: red; margin-top: 20px;"
              >
              <span class="mobile_reset">Reset filters</span>
              </b-button>
              </div>
              <div class="col-xl-12 top_black_btn margin_left_15_desktop mobile_text_start pb-3 invoices_btns">
                
              <b-button
                  v-if="show('export')"
                  class="ml-0 common_z_index_zero button ripple-effect new_add_btn_admin_e desktop_margin_right_zero"
                  @click="exportAllData()"
                  >Export All Closing Balance</b-button
                >
                <b-button
                  v-if="show('export')"
                  class="common_z_index_zero button ripple-effect new_add_btn_admin_e desktop_margin_right_zero"
                  @click="exportData()"
                  style="margin-left: 19px"
                  >Export Payslip Data</b-button
                >
                <b-button
                  v-if="show('export')"
                  class="common_z_index_zero button ripple-effect new_add_btn_admin_e desktop_margin_right_zero"
                  @click="exportPDF()"
                  style="margin-left: 19px"
                  >Download All Pdf</b-button
                >
                
            </div>
          </div>
          <div class="container my_new_container show_filter_arrow p-0">
            <b-table
              ref="datatable"
              show-empty
              striped
              hover
              responsive
              :items="getPaySlip"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="new_layout_table_like_v1"
            >
            <template #cell(payslip_id)="items">
                  {{items.item.payslip_number}}
                </template>
                <template #cell(employer)="items">
                {{items.item.employer | capitalize}}
                </template>
                <template #cell(nric)="items">
                  {{items.item.nric | capitalize}}
                </template>
                <template #cell(ft)="items">
                  {{items.item.is_fulltime | capitalize}}
                </template>
                <template #cell(wage)="items">
                  {{(items.item.basic_wage-items.item.transport_completed_sum) | if_number}}
                </template>

                <template #cell(lastBalance)="items">
                  {{items.item.last_month_balance | if_number}}
                </template>
                <template #cell(lastIncentive)="items">
                  {{items.item.last_month_incentive | if_number}}
                </template>
                <template #cell(others)="items">
                  {{items.item.signup_bonus | if_number}}
                </template>
                <template #cell(penalty_waiver)="items">
                  {{items.item.penalty_waiver | if_number}}
                </template>
                <template #cell(transport_reimbursement)="items">
                  {{items.item.transport_completed_sum || items.item.transport_cancelled_sum | if_number}}
                </template>
                <template #cell(credit)="items">
                  {{items.item.credit | if_number}}
                </template>
                <template #cell(campaign)="items">
                  {{items.item.campaign_sum | if_number}}
                </template>
                <template #cell(totalWallet)="items">
                  {{(items.item.basic_wage + items.item.last_month_balance + items.item.last_month_incentive + items.item.signup_bonus + items.item.credit + items.item.penalty_waiver + (items.item.transport_cancelled_sum) + (isAugustData ? items.item.campaign_sum : items.item.pre_campaign_sum || 0)) | if_number}}
                </template>
                <template #cell(deduction)="items">
                  {{items.item.others | if_number}}
                </template>
                <template #cell(insurance)="items">
                  {{items.item.insurance_sum | if_number}}
                </template>
                <template #cell(debit)="items">
                  {{items.item.debit | if_number}}
                </template>
                <template #cell(cashOut)="items">
                  {{items.item.cash_advance | if_number}}
                </template>
                <template #cell(curMonthBalance)="items">
                  {{(items.item.balance) | if_number}}
                </template>
                <template #cell(curMonthIncentive)="items">
                  {{items.item.incentive + items.item.referral + items.item.camp_amount | if_number}}
                </template>
                <template #cell(created_at)="items">
                  {{items.item.created_at | date_formate}}
                </template>
                <template #cell(toggleAction)="items">
                  <div class="toggle-action">
                    <b-dropdown class="mx-1" right text="Actions" boundary="window">
                        <b-dropdown-item :to="{}" @click="downloadPdf(items.item.url)" v-if="show('download')">View Payslip
                        </b-dropdown-item>
                        <b-dropdown-item v-if="show('generate payslip')" @click="openModel(items.item.user_id)">Generate Payslip
                        </b-dropdown-item>

                    </b-dropdown>
                  </div>
              </template>
            </b-table>
          </div>
          <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
              <b-form-group id="input-group-4">
                <span class="d-inline-block">Rows per page</span>
                <span class="d-inline-block"
                  ><b-form-select v-model="form.rowsPerPage" @change="getPaySlip">
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                  </b-form-select></span
                >
              </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
              <ul>
                <li>
                  <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                </li>
              </ul>
            </div>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              prev-text=""
              next-text=""
              hide-goto-end-buttons
            />
          </nav>
        </div>
        </b-col>
      </b-row>
      <b-modal ref="payslip-modal" title="Select Month" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
          <!-- <b-form> -->
              <div class="edit-form-box model_margin_top_zero model_legend model_half_calender">
                  <div class="profile-repeate form-box-field">
                      <b-row>
                          <!-- <b-col lg="12" md="12"> -->
                              <div class="form-map-box">
                                  <b-col lg="12" md="12">
                                      <b-form-group id="input-group-6" label="Payslip Month & Year" class="model_margin_bottom_zero required">
                                          <datepicker v-model="month" :format="customFormatterTwo" initial-view="year" minimum-view="month" :disabled-dates="disabledFromDate"></datepicker>
                                          <span class="input-icon"><b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                      </b-form-group>
                                  </b-col>
                                  <b-col lg="12" md="12">
                                      <b-form-group>
                                          <b-form-checkbox v-model = 'withNoEmail'  @input="withNoEmailFunc($event)"><strong>With No Email</strong></b-form-checkbox>
                                      </b-form-group>
                                  </b-col>
                                  <b-col lg="12" md="12">
                                      <div slot="footer" class="form-btn common_crop_reset_btn">
                                          <b-button type="submit" variant="success" @click="generatePayslip()" class="model_common_bt_success_color">Submit</b-button>
                                          <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                      </div>
                                  </b-col>
                              </div>
                          <!-- </b-col> -->
                      </b-row>
                  </div>
              </div>
          <!-- </b-form> -->
      </b-modal>
    </div>
    
</template>
  <script>
  import { POST_API, GET_API } from "../../../store/actions.type";
  import moment from "moment";
  import Datepicker from "vuejs-datepicker";
  import Vue from "vue";
  import Multiselect from 'vue-multiselect'
  import permission from "../../../../../server/permission.js";

  export default {
    components: {
      Datepicker,
      Multiselect
    },
    data() {
      return {
        fields: [],
        // fields: [
        //         { key: 'payslip_id', label: 'Advise ID', sortable: false, thStyle:{width:'5%'} },
        //         { key: 'employer', label: 'MW Name', sortable: true , thStyle:{width:'6%'}},
        //         { key: 'nric', label: 'NRIC', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'ft', label: 'Is FT FL', sortable: true , thStyle:{width:'4%'}},
        //         { key: 'wage', label: '(A) '+this.dynamicHeading+' Earnings', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'lastBalance', label: '(B) '+this.dynamicHeading1+' Balance', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'lastIncentive', label: '(C) '+this.dynamicHeading1+' Extra Earnings', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'others', label: '(D) Others', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'penalty_waiver', label: '(E) Penalty Waiver', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'credit', label: '(F) Other Credit', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'campaign', label: 'Campaign', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'totalWallet', label: '(G) Total Amount in Wallet (A+B+C+D+E+F)', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'deduction', label: '(H) Deductions', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'debit', label: '(I) Other Debit', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'cashOut', label: '(J) Cash-Out', sortable: false , thStyle:{width:'5%'}},
        //         { key: 'curMonthBalance', label: '(K) '+this.dynamicHeading+' Balance (G-H-I-J)', sortable: false , thStyle:{width:'5%'}},
        //         { key: 'curMonthIncentive', label: '(L) '+this.dynamicHeading+' Extra Earnings', sortable: false , thStyle:{width:'5%'}},
        //         { key: 'created_at', label: 'Created On', sortable: true , thStyle:{width:'5%'}},
        //         { key: 'toggleAction', label: 'Actions', thStyle:{width:'5%'}}
        //     ],
        form: {
          rowsPerPage: 50,
        },
        items: null,
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [25, 50, 75, 100],
        sortBy: null,
        sortDirection: "desc",
        filter: null,
        status: "all",
        isBusy: false,
        sortDesc: true,
        to: "",
        from: "",
        dat: new Date(),
        webUrl: process.env.VUE_APP_URL,
        actions: "",
        user_type_id: "",
        startDate: new Date(moment().format("YYYY-MM-01")),
        defaultDate: moment().subtract(1, "month").format("DD MMM YYYY"),
        disabledFromDate: {
          from: new Date(moment().subtract(1, "month")),
        },
        disabledFromDate1: {
          to: new Date(),
        },
        dynamicHeading: moment().subtract(1, "month").format("MMMM-YYYY"),
        dynamicHeading1: moment().subtract(2, "month").format("MMMM-YYYY"),
        withNoEmail: true,
        month: "",
        payslip_generate_for_user: "",
        webServerUrl: process.env.VUE_APP_SERVER_URL,
        actionsEmployer: "",
        searchKeyword:"",
        isAugustData:false,
        success_message:"",
        month: '',
        year:''
      };
    },
    filters: {
      capitalize: function (value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      date_formate: function (date) {
        return moment(date).format("DD-MM-YYYY hh:mm A");
      },
      date: function (value) {
        if (value == null) {
          return "";
        } else {
          return moment(value).format("DD MMM YYYY");
        }
      },
      date1: function (value) {
        if (value == null) {
          return "-";
        } else {
          return moment(value).format("DD MMM YYYY");
        }
      },
      if_number: function (value) {
        if (!value) {
          return "0.00";
        }
        return parseFloat(value).toFixed(2);
      },
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      customFormatterOne(date) {
        return moment(date).format("MMMM-YYYY");
      },
      customFormatterTwo(date) {
        return moment(date).format("YYYY-MM-DD");
      },
      reset() {
        this.filter = null;
        this.$refs.picker.start = null;
        this.$refs.picker.end = null;
        this.getPaySlip().then(() => {
          this.$refs.datatable.refresh();
        });
      },
      dateFilterFunction(status)
        {
          this.getPaySlip().then(() => {
              this.$refs.datatable.refresh();
          });
          this.dynamicHeading = moment(this.defaultDate).format('MMM')
          this.dynamicHeading1 = moment(this.defaultDate).subtract(1, 'month').format('MMM')

          if(moment(this.defaultDate).format('MM-YYYY')==='08-2024'){
            this.isAugustData=true
          }
          console.log(this.isAugustData)
          this.fields = [
                { key: 'payslip_id', label: 'Advice ID', sortable: false, thStyle:{width:'5%'} },
                { key: 'employer', label: 'MW Name', sortable: true , thStyle:{width:'6%'}},
                { key: 'nric', label: 'NRIC', sortable: true , thStyle:{width:'5%'}},
                { key: 'ft', label: 'Is FT FL?', sortable: true , thStyle:{width:'4%'}},
                { key: 'wage', label: '(A) '+this.dynamicHeading+' Earnings', sortable: true , thStyle:{width:'5%'}},
                { key: 'lastBalance', label: '(B) '+this.dynamicHeading1+' Balance', sortable: true , thStyle:{width:'5%'}},
                { key: 'lastIncentive', label: '(C) '+this.dynamicHeading1+' Extra Earnings', sortable: true , thStyle:{width:'5%'}},
                { key: 'others', label: '(D) Others', sortable: true , thStyle:{width:'5%'}},
                { key: 'penalty_waiver', label: '(E) Penalty Waiver', sortable: true , thStyle:{width:'5%'}},
                { key: "transport_reimbursement", label: "(F) Transport Reimbursement", sortable: true,thStyle:{width:'5%'} },
                { key: 'credit', label: '(G) Other Credit', sortable: true , thStyle:{width:'5%'}},            
                { key: 'campaign', label: '(O) Campaign', sortable: true , thStyle:{width:'5%'}},
                { key: 'totalWallet', label: '(H) Total Amount in Wallet (A+B+C+D+E+F+O)', sortable: true , thStyle:{width:'5%'}},
                { key: 'deduction', label: '(I) Deductions', sortable: true , thStyle:{width:'5%'}},
                { key: 'insurance', label: '(J) Insurance', sortable: true , thStyle:{width:'5%'}},
                { key: 'debit', label: '(K) Other Debit', sortable: true , thStyle:{width:'5%'}},
                { key: 'cashOut', label: '(L) Cash-Out', sortable: false , thStyle:{width:'5%'}},
                { key: 'curMonthBalance', label: '(M) '+this.dynamicHeading+' Balance (G-H-I-J)', sortable: false , thStyle:{width:'5%'}},
                { key: 'curMonthIncentive', label: '(N) '+this.dynamicHeading+' Extra Earnings', sortable: false , thStyle:{width:'5%'}},
                { key: 'created_at', label: 'Created On', sortable: true , thStyle:{width:'5%'}},
                { key: 'toggleAction', label: 'Actions', thStyle:{width:'5%'}}
            ];
        },
      selectEmployer(selectedOption){
      this.status=selectedOption.value
      this.$refs.datatable.refresh()
     },
      getPaySlip() {
        return this.$store
          .dispatch(POST_API, {
            data: {
              page: this.currentPage,
              keyword: this.filter,
              rowsPerPage: this.form.rowsPerPage,
              sortBy: this.sortBy ? this.sortBy : "users.nric",
              sortDirection: this.sortDesc ? "desc" : "asc",
              sortDesc: this.sortDesc,
              status:this.status ? this.status:"all",
              monthYear:this.defaultDate ? moment(this.defaultDate).format('MM-YYYY') : ''
            },
            api: "/api/fl-payslip",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              this.items = this.$store.getters.getResults.pageOfItems;
              this.totalRows = this.$store.getters.getResults.pager.totalItems;
              this.perPage = this.$store.getters.getResults.pager.pageSize;
              this.from = this.$store.getters.getResults.pager.from;
              this.to = this.$store.getters.getResults.pager.to;
              return this.items;
            }
          });
      },

      downloadPdf(url) {
        window.open(url, "_blank");
      },
      exportData() {
        var base_url = this.webServerUrl;
        var page = this.currentPage;
        var rowsPerPage = this.form.rowsPerPage;
        var monthYear = this.defaultDate ? moment(this.defaultDate).format("MM-YYYY") : "null";
        var sortBy = this.sortBy ? this.sortBy : "users.nric";
        var sortDesc= this.sortDesc;
        var sortDirection = this.sortDesc ? "desc" : "asc";
        var status =this.status ? this.status:"all";
        var keyword = this.filter ? this.filter : null;
        var export_url =
          "/api/fl-payslip-export/" +
          keyword +
          "/" +
          monthYear +
          "/" +
          status +
          "/" +
          page +
          "/" +
          rowsPerPage +
          "/" +
          sortBy +
          "/" +
          sortDesc;
        window.open(base_url + export_url, "_blank");
        // window.open(export_url,'_blank')
      },
      exportAllData() {
        var base_url = this.webServerUrl;
        
        var monthYear = this.defaultDate ? moment(this.defaultDate).format("MM-YYYY") : "null";
        
        var export_url =
          "/api/all-fl-payslip-export/" +
          monthYear;
        window.open(base_url + export_url, "_blank");
        // window.open(export_url,'_blank')
      },
      exportPDF() {
        var base_url = this.webServerUrl;
        var keyword=this.filter
        var monthYear = this.defaultDate ? moment(this.defaultDate).format("MM-YYYY") : "null";
        var rowsPerPage = this.form.rowsPerPage;
        var page=this.currentPage

        var export_url =
          "/api/fl-payslip-pdf-export/" + 
          keyword +'/'+
          monthYear + '/' +
          rowsPerPage + '/' +
          page;
        window.open(base_url + export_url, "_blank");
        // window.open(export_url,'_blank')
      },
      generatePayslip(){
          return this.$store.dispatch(POST_API, {
                   data:{
                    month: this.month != '' ? moment(this.month).format('MM') : '',
                    year: this.month != '' ? moment(this.month).format('YYYY') : '',
                    user_id:this.payslip_generate_for_user,
                    with_no_email:this.withNoEmail,
                    type:'admin'
                   },
                   api: '/api/jobseeker-generate-payslip-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.items = this.$store.getters.getResults.data;
                        this.success_message = this.$store.getters.getResults.message;
                        this.success = this.$store.getters.getResults.success;
                        if(this.success){
                            window.open(this.$store.getters.getResults.url,'_blank');
                            this.showSuccessAlert(this.success_message);
                        }else{
                            this.showSuccessAlert(this.error_message);
                        }
                    }
                    this.payslip_generate_for_user = '';
                    this.month = '';
                    this.year = '';
                    this.hideModel();
                    // this.refreshDataTable();
                });
        },

      showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                return null 
            });
        },
      showAlert() {
        // window.scrollTo(0,0);
        this.dismissCountDown = this.dismissSecs;
        this.$swal({
          position: "center",
          icon: "error",
          iconColor:'#4c8bf5',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000,
        });
      },
      clearFilter(){
      this.filter="";
      this.defaultDate= moment().subtract(1, "month").format("DD MMM YYYY"),
      this.$refs.datatable.refresh();
    },
      downloadAllPdf() {
        return this.$store
          .dispatch(POST_API, {
            data: {
              monthYear: this.defaultDate ? moment(this.defaultDate).format("MM-YYYY") : "",
              rowsPerPage: this.form.rowsPerPage,
              page: this.currentPage,
            },
            api: "/api/download-payslips-zip",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              var data = this.$store.getters.getResults.data;
              console.log(data);
              this.downloadFile(data.name, data.url);
            }
          });
      },
      downloadFile(name, path) {
        console.log(name, path);
        var file_path = path;
        var a = document.createElement("A");
        a.href = file_path;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      openModel(id) {
        this.payslip_generate_for_user = id;
        this.$refs["payslip-modal"].show();
      },

      withNoEmailFunc($event) {
        if ($event != null) {
          this.withNoEmail = $event;
        } else {
          this.withNoEmail = $event;
        }
      },
      hideModel() {
        this.$refs["payslip-modal"].hide();
      },


      permissionStaff() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.user_type_id == 2
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.actions = permission.getPermissionStaff(menu, "FL Payslip");
          }
        }
      },
      show(action) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          return this.actions.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
      showEmployer(action){
              if (this.$store.getters.currentUser.user_type_id == 2) {
                  return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;
              }else{
                  return true;
              }
          },
    },
    mounted() {
      $(".show_filter_arrow .table thead th div").contents().unwrap();
      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
      this.user_type_id = this.$store.getters.currentUser.user_type_id;
      this.permissionStaff();
      setTimeout( () =>{
          this.dynamicHeading = moment(this.defaultDate).format('MMM')
          this.dynamicHeading1 = moment(this.defaultDate).subtract(1, 'month').format('MMM')
          this.fields = [
                { key: 'payslip_id', label: 'Advice ID', sortable: false, thStyle:{width:'5%'} },
                { key: 'employer', label: 'MW Name', sortable: true , thStyle:{width:'6%'}},
                { key: 'nric', label: 'NRIC', sortable: true , thStyle:{width:'5%'}},
                { key: 'ft', label: 'Is FT FL?', sortable: true , thStyle:{width:'4%'}},
                { key: 'wage', label: '(A) '+this.dynamicHeading+' Earnings', sortable: true , thStyle:{width:'5%'}},
                { key: 'lastBalance', label: '(B) '+this.dynamicHeading1+' Balance', sortable: true , thStyle:{width:'5%'}},
                { key: 'lastIncentive', label: '(C) '+this.dynamicHeading1+' Extra Earnings', sortable: true , thStyle:{width:'5%'}},
                { key: 'others', label: '(D) Others', sortable: true , thStyle:{width:'5%'}},
                { key: 'penalty_waiver', label: '(E) Penalty Waiver', sortable: true , thStyle:{width:'5%'}},
                { key: "transport_reimbursement", label: "(F) Transport Reimbursement", sortable: true,thStyle:{width:'5%'} },
                { key: 'credit', label: '(G) Other Credit', sortable: true , thStyle:{width:'5%'}},            
                { key: 'campaign', label: '(O) Campaign', sortable: true , thStyle:{width:'5%'}},
                { key: 'totalWallet', label: '(H) Total Amount in Wallet (A+B+C+D+E+F+O)', sortable: true , thStyle:{width:'5%'}},
                { key: 'deduction', label: '(I) Deductions', sortable: true , thStyle:{width:'5%'}},
                { key: 'insurance', label: '(J) Insurance', sortable: true , thStyle:{width:'5%'}},
                { key: 'debit', label: '(K) Other Debit', sortable: true , thStyle:{width:'5%'}},
                { key: 'cashOut', label: '(L) Cash-Out', sortable: false , thStyle:{width:'5%'}},
                { key: 'curMonthBalance', label: '(M) '+this.dynamicHeading+' Balance (G-H-I-J)', sortable: false , thStyle:{width:'5%'}},
                { key: 'curMonthIncentive', label: '(N) '+this.dynamicHeading+' Extra Earnings', sortable: false , thStyle:{width:'5%'}},
                { key: 'created_at', label: 'Created On', sortable: true , thStyle:{width:'5%'}},
                { key: 'toggleAction', label: 'Actions', thStyle:{width:'5%'}}
            ];

        },1000)
    },
  };
  </script>
