<template>
  <div>
    <div class="bg_lucky_draw" style="background-color: #4c8bf5">
      <LuckyDrawHeaderComponent />
      <div class="first_step_container">
          <b-row class="swap_row">
                <b-col
                  class="col-xl-6 padding_right_zero_jbs padding_left_zero_jbs col-md-6 mobile_max_width_flex_jobseeker mobile_col_six_lucky_draw mobile_pb_35"
                  v-if="this.stage == 1"
                >
                  <b-form id="b_form" v-if="this.stage == 1" class="lukcy_draw_forms">
                    <div class="whole_lucky_draw_form">
                      <div class="">
                      <h2
                        class="pink_elucky_draw_text elucky_draw_desktop extra_bold"
                        v-if="this.stage == 1"
                      >
                        Welcome to <br /><span class="yellow_elucky_draw_text extra_bold"
                          >REVIEW AND REWARDS</span
                        >
                      </h2>
                      <h2
                        class="pink_elucky_draw_text elucky_draw_mobile mt-0 mb-4 extra_bold"
                        v-if="this.stage == 1"
                      >
                        Welcome to <br /><span class="yellow_elucky_draw_text extra_bold"
                          >REVIEW AND REWARDS</span
                        >
                      </h2>
                      <h5>Your Full Name:</h5>
                      <b-form-input
                        v-model="fullName"
                        placeholder="example: Roger Lim Jun Jie"
                        required
                        id="b_input"
                      ></b-form-input>
                      <!-- DESKTOP -->
                      <div class="elucky_draw_desktop" v-if="this.stage == 1">
                        <h5>
                          Your NRIC:
                          <span style="margin-left: 230px" class="mobile_text">Your Mobile:</span>
                        </h5>
                        <b-row class="">
                          <b-col class="nric_lucky_draw">
                            <b-form-input
                              v-model="nric"
                              placeholder="example : S0001234A"
                              required
                              id="b_input"
                            ></b-form-input>
                          </b-col>
                          <b-col class="country_code_lucky_draw">
                            <b-form-input
                              disabled
                              placeholder="+65"
                              id="b_input"
                              style="font-size: 10px"
                            ></b-form-input>
                          </b-col>
                          <b-col class="number_lucky_draw">
                            <b-form-input
                              v-model="mobileNo"
                              placeholder="example : 9876 5432"
                              required
                              id="b_input"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                      </div>
                      <!-- MOBILE -->
                      <div class="elucky_draw_mobile mt-0" v-if="this.stage == 1">
                        <h5>Your NRIC:</h5>
                        <b-form-input
                          v-model="nric"
                          placeholder="example : S0001234A"
                          required
                          id="b_input"
                        ></b-form-input>
                        <h5>Your Mobile:</h5>
                        <b-row>
                          <b-col class="col-2 country_code_lucky_draw">
                            <b-form-input
                              disabled
                              placeholder="+65"
                              id="b_input"
                              style="font-size: 10px"
                            ></b-form-input>
                          </b-col>
                          <b-col class="number_lucky_draw">
                            <b-form-input
                              v-model="mobileNo"
                              placeholder="example : 9876 5432"
                              required
                              id="b_input"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                      </div>
                      <h5>When did you join EL CONNECT?</h5>
                      <b-row>
                        <div class="col-6">
                          <b-form-select
                            v-model="joinMonth"
                            :options="month"
                            placeholder="Month"
                            id="b_input"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Month --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </div>
                        <div class="col-6">
                          <b-form-select
                            v-model="joinYear"
                            :options="years"
                            placeholder="Year"
                            id="b_input"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- YYYY --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </div>
                      </b-row>
                      <h5>Is your Mobile linked to PayNow?</h5>
                      <b-row>
                        <b-col class="col-4 employer_multiple_radio lucky_draw_radio">
                          <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                              id="radio-group-1"
                              v-model="payNowLink"
                              :options="options"
                              :aria-describedby="ariaDescribedby"
                              name="radio-options"
                              style="width: 100%; height: auto"
                              class=""
                              size="lg"
                            ></b-form-radio-group>
                          </b-form-group>
                        </b-col>
                        <b-col class="col-8">
                          <!-- <b-row> -->
                          <h6>Please input your PayNow Number:</h6>
                          <!-- </b-row> -->
                          <b-row class="no_wrap">
                            <b-col class="col-1 pay_now_code"> +65 </b-col>
                            <b-col class="col-10 col_10_mobile_lukcy_draw">
                              <b-form-input
                                v-if="payNowLink == 'no'"
                                v-model="payNowNo"
                                placeholder="example : 9876 5432"
                                id="b_input"
                              ></b-form-input>
                              <b-form-input
                                v-else-if="payNowLink == 'yes'"
                                v-model="mobileNo"
                                placeholder=""
                                id="b_input"
                                disabled
                                >mobileNo</b-form-input
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <!-- <h5> When did you join EL CONNECT?</h5>
                                            <datepicker v-model="joinDate" class="lucky_draw_datepicker"></datepicker> -->
                      <!-- <div class="next_button_lucky_draw elucky_draw_mobile">
                                        <b-button @click="inputCheck" value="Next">NEXT</b-button>
                                    </div> -->
                      <div class="next_button_lucky_draw">
                        <b-button @click="inputCheck" value="Next"
                          >NEXT<i class="material-icons-outlined arrow_right_with_circle"
                            >expand_circle_down_outlined</i
                          ></b-button
                        >
                      </div>
                      </div>
                    </div>
                  </b-form>
                  <!-- <h2 class="pink_elucky_draw_text elucky_draw_desktop" v-if="this.stage == 2">You are almost there!</h2> -->
                </b-col>
                <b-col class="col-xl-6 padding_left_zero_jbs mobile_max_width_flex_jobseeker col-md-6 mobile_col_six_lucky_draw">
                  <!-- <img :src="webUrl + 'assets/jobeekerImages/cornerlogo.png'" class="corner_logo"> -->
                  <div class="box_tip_chit stage_one_chit" v-if="this.stage == 1">
                    <div>
                    <span class=""
                      >Kindly fill in the form<br />
                      before you get started</span
                    >
                    <!-- <span class="elucky_draw_mobile">Congratulations! You've been
                                    selected for a chance to be one of our winners!Enter now for your shot at big prizes!
                                </span> -->
                                <img :src="webUrl + 'assets/jobeekerImages/Group 129.png'" class="tip_img_nw elucky_draw_desktop">
                                <img :src="webUrl + 'assets/jobeekerImages/Group-144-1.png'" class="tip_img_nw elucky_draw_mobile">
                                <p class="term_jbs first_stage_p elucky_draw_desktop">*Term & condition applies.</p>
                            </div>
                  </div>
                </b-col>
            </b-row>
        </div>
          <!-- outside of container to make my form more centered -->
          <b-container class="stage2_container">
              <!-- <img :src="webUrl + 'assets/jobeekerImages/Path 10.png'"
                   v-if="this.stage == 2"> -->
        <!-- <div class="box_tip_chit box_tip_chit_for_stage_two elucky_draw_desktop" v-if="this.stage == 2">
                  <img :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.png'">
                  <img :src="webUrl + 'assets/jobeekerImages/coin.png'" class="coin_img">
                  <span class="">Your feedback is important & we would like to understand you more! Please take your time to fill in this questions! </span>
              </div> -->
        <div
          class="box_tip_chit box_tip_chit_for_stage_two elucky_draw_mobile"
          v-if="this.stage == 2"
        >
          <div class="text_with_penguin_stage_two">
            <span class="">
              <img :src="webUrl + 'assets/jobeekerImages/coin.png'" class="coin_img" /><br />
              Your feedback is important & <br />we would like to understand<br />
              you more! Please take your<br />
              time to fill in this questions!
            </span>
            <img :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.png'" class="rt_penguin" />
          </div>
        </div>
        <b-form id="b_form" v-if="this.stage == 2" class="lukcy_draw_forms stage_2_form">
          <div
            class="stage2_full_form"
            :style="{
              backgroundImage:
                'url(' + webUrl + 'assets/jobeekerImages/Questionnaire-Page.png' + ')',
            }"
          >
            <div class="stage_tow_form stage2_lucky_draw_form">
              <div class="inner_jb_fr">
                <h4 class="pink_text">Question 1</h4>
                <h5>What do you like about our EL CONNECT APP?<span> (Min.15 Characters)</span></h5>
                <b-form-textarea
                  v-model="q1Ans"
                  rows="3"
                  minlength="15"
                  required
                  id="minle"
                ></b-form-textarea>
                <h4 class="pink_text">Question 2</h4>
                <h5>
                  Where do you think EL CONNECT can improve on? <span> (Min.15 Characters)</span>
                </h5>
                <b-form-textarea v-model="q2Ans" rows="3" minlength="15" required></b-form-textarea>
                <h4 class="pink_text">Question 3</h4>
                <h5>How do you know EL CONNECT? <span> (Min.15 Characters)</span></h5>
                <b-form-textarea v-model="q3Ans" rows="3" minlength="15" required></b-form-textarea>
                <!-- <div class="next_button_lucky_draw elucky_draw_mobile">
                          <b-button @click="updateFeedback" value="Next">NEXT</b-button>

                      </div> -->
                <div class="stage2_next_button next_button_lucky_draw">
                  <b-button @click="updateFeedback" value="Next"
                    >NEXT
                    <i class="material-icons-outlined next_btn_icon arrow_right_with_circle"
                      >expand_circle_down_outlined</i
                    ></b-button
                  >
                </div>
              </div>
            </div>
            <p class="term_jbs starge2_term mobile_pb_35">*Term & condition applies.</p>
          </div>
        </b-form>
        <div
          class="box_tip_chit box_tip_chit_for_stage_two elucky_draw_desktop"
          v-if="this.stage == 2"
        >
          <div class="text_with_penguin_stage_two">
            <img :src="webUrl + 'assets/jobeekerImages/coin.png'" class="coin_img" />
            <span class=""
              >Your feedback is important & <br />we would like to understand<br />
              you more! Please take your<br />
              time to fill in this questions!
            </span>
            <img :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.png'" class="rt_penguin" />
          </div>
        </div>
      </b-container>
      <b-container class="stage3_container" v-if="this.stage == 3">
        <!-- <img :src="webUrl + 'assets/jobeekerImages/Path 10.png'" class="bkg_stage3" v-if="this.stage == 3"> -->
        <!-- <img :src="webUrl + 'assets/jobeekerImages/Path 10.png'"
                  style="z-index: 0;position:absolute;left: -30px;top: 10px;" v-if="this.stage == 3"> -->
        <div class="connect_img">
          <img :src="webUrl + 'assets/jobeekerImages/Connect-w-Us-Page.png'" />
        </div>
        <div class="connect_us_form">
          <div class="box_tip_chit box_tip_chit_for_stage_three" v-if="this.stage == 3">
            <img :src="webUrl + 'assets/jobeekerImages/mascot-left.png'" class="stage_three_img" />

            <span class="" style="font-weight: 600">
              <img :src="webUrl + 'assets/jobeekerImages/starbox.png'" class="star_boxtip" /><br />
              <i class="elucky_draw_desktop"
                >Stay in the know! Hit that follow button on Facebook</i
              >
              <i class="elucky_draw_mobile"
                >Stay in the know! Hit<br />
                that follow button<br />
                on Facebook</i
              >
              <img :src="webUrl + 'assets/jobeekerImages/starbox.png'" class="star_boxtip2" />
            </span>
          </div>

          <b-form id="b_form" v-if="this.stage == 3" class="stage_three">
            <!-- <h2 class="mobile_margin_top_zero rating_lucky_draw">Last part! Give us a
                                    <i class="material-icons-outlined">star</i>
                                    <i class="material-icons-outlined">star</i>
                                    <i class="material-icons-outlined">star</i>
                                    <i class="material-icons-outlined">star</i>
                                    <i class="material-icons-outlined">star</i>
                                    review :)
                                </h2> -->
            <h2 class="mobile_margin_top_zero rating_lucky_draw">
              Last part! Lets Stay Connected!
            </h2>
            <b-link href="#" @click="handleLink('facebook')">
              <div
                class="social_link_lucky_draw"
                :style="{
                  backgroundImage:
                    'url(' + webUrl + 'assets/jobeekerImages/Rectangle29@2x.png' + ')',
                }"
              >
                <img :src="webUrl + 'assets/jobeekerImages/Image -1.png'" />
                <p>Click here to leave us a review on FACEBOOK</p>
              </div>
            </b-link>
            <h2 class="mobile_margin_top_zero rating_lucky_draw">We Love To Hear From You!</h2>
            <b-link href="#" @click="handleLink('google')">
              <div
                class="social_link_lucky_draw"
                :style="{
                  backgroundImage:
                    'url(' + webUrl + 'assets/jobeekerImages/Rectangle29@2x.png' + ')',
                }"
              >
                <img :src="webUrl + 'assets/jobeekerImages/Mask Group 1.png'" />
                <p>Click here to leave us a review on GOOGLE</p>
              </div>
            </b-link>

            <!-- <b-link href="#" @click="handleLink('playstore')">
                                    <div class="social_link_lucky_draw"
                                        :style="{ backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Rectangle29@2x.png' + ')' }">
                                        <img :src="webUrl + 'assets/jobeekerImages/Mask Group 3.png'">
                                        <p>Click here to leave us a review on PLAYSTORE</p>
                                    </div>
                                </b-link>

                                <b-link href="#" @click="handleLink('appstore')">
                                    <div class="social_link_lucky_draw"
                                        :style="{ backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Rectangle29@2x.png' + ')' }">
                                        <img :src="webUrl + 'assets/jobeekerImages/Mask Group 2.png'">
                                        <p>Click here to leave us a review on APPSTORE</p>
                                    </div>
                                </b-link> -->

            <div class="next_button_lucky_draw">
              <b-button @click="warn" value="Next" v-if="count < 1"
                >NEXT<i class="material-icons-outlined arrow_right_with_circle"
                  >expand_circle_down_outlined</i
                ></b-button
              >
              <b-button
                :to="{ name: 'elucky-draw-selection', params: { id: encrypt(this.playerID) } }"
                value="Next"
                v-else-if="count >= 1"
                >NEXT<i class="material-icons-outlined arrow_right_with_circle"
                  >expand_circle_down_outlined</i
                ></b-button
              >
            </div>
            <!-- @click="moveToSelection" -->
          </b-form>
        </div>
        <p class="term_jbs3 mobile_pb_35" v-if="this.stage == 3">*Term & condition applies.</p>
      </b-container>
    </div>
  </div>
</template>

<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import LuckyDrawHeaderComponent from "./LuckyDrawHeaderComponent.vue";

export default {
  data() {
    return {
      webUrl: process.env.VUE_APP_URL,
      fullName: null,
      nric: null,
      mobileNo: null,
      payNowLink: "yes",
      payNowNo: null,
      joinMonth: null,
      joinYear: null,
      joinDate: null,
      stage: 1,
      q1Ans: null,
      q2Ans: null,
      q3Ans: null,
      errMessage: "",
      options: [
        { text: "YES", value: "yes" },
        { text: "NO", value: "no" },
      ],
      count: 0,
      playerID: 0,
      years: ["2020", "2021", "2022", "2023", "2024"],
      month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  components: {
    Datepicker,
    LuckyDrawHeaderComponent,
  },
  methods: {
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.errMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    // customFormatter(date) {
    //     return moment(date).format('YYYY-MM-DD');
    // },
    inputCheck() {
      let numCheck = /\d{8}/;
      // this.joinDate = this.customFormatter(this.joinDate);
      if (this.payNowLink == "no") {
        if (this.payNowNo == null) {
          this.errMessage = "PayNow Number is required";
          this.showAlert();
        } else if (!numCheck.test(this.payNowNo)) {
          this.errMessage = "PayNow Number must be 8 numbers";
          this.showAlert();
        } else {
          this.errMessage = "";
          this.validateUser();
        }
      } else {
        this.payNowNo = this.mobileNo;
        this.validateUser();
      }
    },

    validateUser() {
      this.fullName = this.fullName == null ? null : this.fullName.trim();
      this.nric = this.nric == null ? null : this.nric.trim();
      this.mobileNo = this.mobileNo == null ? null : this.mobileNo.trim();
      this.payNowNo = this.payNowNo == null ? null : this.payNowNo.trim();
      this.joinMonth = this.joinMonth == null ? null : this.joinMonth;
      this.joinYear = this.joinYear == null ? null : this.joinYear;
      return this.$store
        .dispatch(POST_API, {
          data: {
            name: this.fullName,
            nric: this.nric,
            mobileNo: this.mobileNo,
            payNowLink: this.payNowLink,
            payNowNo: this.payNowNo,
            joinMonth: this.joinMonth,
            joinYear: this.joinYear,
          },
          api: "/api/elucky-draw-validate",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.errMessage = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.errMessage = "";
            this.stage += 1;
            this.playerID = this.$store.getters.getResults.data.id;
            window.scrollTo(0, 0);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    updateFeedback() {
      this.q1Ans = this.q1Ans == null ? null : this.q1Ans.trim();
      this.q2Ans = this.q2Ans == null ? null : this.q2Ans.trim();
      this.q3Ans = this.q3Ans == null ? null : this.q3Ans.trim();
      /*  // Check if any answer is less than 15 characters
          if (this.q1Ans.length < 15 || this.q2Ans.length < 15 || this.q3Ans.length < 15) {
              // Show an error message or prevent form submission
              alert('Please ensure all answers have at least 15 characters.');
              return;
          } */
      return this.$store
        .dispatch(POST_API, {
          data: {
            q1Ans: this.q1Ans,
            q2Ans: this.q2Ans,
            q3Ans: this.q3Ans,
            playerID: this.playerID,
          },
          api: "/api/elucky-draw-feedback",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.errMessage = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.errMessage = "";
            this.stage += 1;
            window.scrollTo(0, 0);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    handleLink(channel) {
      switch (channel) {
        case "google": {
          window.open("https://g.co/kgs/BEQxMx", "_blank");
          break;
        }
        case "facebook": {
          window.open("https://www.facebook.com/elconnectsgp/reviews", "_blank");
          break;
        }
        case "playstore": {
          window.open(
            "https://play.google.com/store/apps/details?id=com.elconnectptuser&gl=US",
            "_blank"
          );
          break;
        }
        case "appstore": {
          window.open(
            "https://apps.apple.com/sg/app/el-connect-part-time-jobs/id1515715893",
            "_blank"
          );
          break;
        }
      }
      this.count += 1;
    },
    warn() {
      this.errMessage = "Please give review on at least 1 platform";
      this.showAlert();
    },
    moveToSelection() {
      this.errMessage = "";
      if (this.playerID != 0) {
        setTimeout(function () {
          window.location.replace("/v2/jobseeker/elucky-draw-selection/" + this.playerID);
        }, 1000);
      }
    },
  },
  mounted() {
    (window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }),
      history.pushState(null, null, window.location.href);
    history.back();
    window.onpopstate = () => history.forward();
  },
};
</script>

<style>
#b_input {
  border-radius: 30px;
}
</style>
