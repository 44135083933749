<template>
  <div>
    <div style="display: none">
      <section class="hero-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 d-flex align-items-center full-screen-height">
              <div class="hero-content-div">
                <div class="hero-content">
                  <h1>Singpass Login</h1>
                  <!-- <p>This demo is an example of how your application should integrate with MyInfo.</p>
									<hr>
									<p>To start the SingPass login and consent process, click on the "Retrieve MyInfo" button
										below.</p> -->
                </div>
                <form id="formAuthorize">
                  <a href="#" onclick="$(this).closest('form').submit()" class="btn2"
                    >Retrieve MyInfo</a
                  >
                </form>
                <hr />
                <small
                  >Note: refer to the
                  <a
                    href="https://www.ndi-api.gov.sg/library/myinfo/resources-personas"
                    target="_BLANK"
                    >Personas</a
                  >
                  on the NDI Developer and Partner Portal for the test accounts to be used.</small
                >
              </div>
            </div>
            <!-- <div class="col-lg-6 col-md-6 d-flex align-items-center full-screen-height mobile-hidden">
							<div class="right-img">
								<div data-depth="0.40" class="layer">
									<div class="right-img-bg-1"></div>
								</div>
								<div data-depth="0.30" class="layer">
									<div class="right-img-bg-2"></div>
								</div>
								<div data-depth="0.40" class="layer">
								</div>
							</div>
						</div> -->
          </div>
        </div>
      </section>

      <a href="#form" class="form-link">
        <p>form below</p>
      </a>

      <section id="form" class="lone-about-us section-padding">
        <form id="formApplication" class="toggle_content">
          <div class="container">
            <div class="single-heading">
              <h2>Form</h2>
              <h4>Application pre-filled with MyInfo!</h4>
            </div>
            <div class="row">
              <div class="col-md-12 mb-4">
                <p style="text-align: center">
                  Confirm your details below and click "Submit Application".
                </p>
              </div>
            </div>
            <div class="row justify-content-around">
              <div class="col-md-12 col-lg-5 form-box mb-4">
                <h3>Personal Information</h3>
                <hr />
                <div class="form-group">
                  <label>NRIC</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="uinfin"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Full Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Sex</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="sex"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Race</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="race"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Nationality</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="nationality"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Date Of Birth</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="dob"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <hr />
                <div class="form-group">
                  <label>Email</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Mobile Number</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="mobileno"
                      value=""
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Registered Address</label>
                  <div class="input-group">
                    <textarea cols="50" rows="3" name="regadd"></textarea>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <a href="#" class="btn2">Submit Application</a>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
    <div class="col-xl-12">
      <div class="myinfo relative_div_singpass_loader">
        <!-- <div class="loading-container">
					<div class="loading"></div>
					<div id="loading-text">Please Wait</div>
				</div> -->
        <div class="loading_with_singpass">
          <span
            ><span
              ><img
                src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/singpass-device-detail.png"
            /></span>
            authentication complete.
            <!-- <div class="singpass_loader"></div> --> </span
          ><br />
          <span
            >Redirecting back to
            <span
              ><img
                src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/elc_logo_new.png"
            /></span>
            <div class="singpass_loader"></div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { POST_API, LOGIN, PERMISSION_DATA } from "../../store/actions.type";
import axios from "axios";

export default {
  metaInfo: {
    title: "EL Connect - Terms of Service",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - Terms of Service" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/terms-of-service" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/terms-of-service" }],
  },
  data() {
    return {
      x: "terms_jobseeker",
      terms_and_conditions_ptUser: "",
      terms_and_conditions_supervisor: "",
      showDismissibleAlert: false,
      error_message: "",
      display: true,
      link_error: "",
      webUrl: process.env.VUE_APP_URL,
      codeVerifier1: "",
      email: "",
      password: "",
      user_type: "5",
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch(LOGIN, {
          data: {
            emailormobile: this.email,
            password: this.password,
            user_type: this.user_type,
            role: "user",
            login_on: "web",
            popup: false,
            singpassLogin: true,
          },
          api: "/api/login",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Welcome to EL Connect",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //commented on 26-12-23
              // if(this.user_type == '3'){
              //     window.location.replace('/v2/employer/employer-profile');
              // }else{
              //     window.location.replace('/v2/jobseeker/jobseeker-profile');
              // }
              //commented on 26-12-23
              //added on 26-12-23
              this.getMenuPermission();
            });
          }
        });
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.user_type == "3") {
              // window.location.replace('/v2/employer/dashboard');
              this.is_company_head = this.$store.getters.currentUser.is_company_head;
              this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                if (this.is_company_head == "yes") {
                  window.location.replace("/v2/employer/employer-profile");
                } else {
                  window.location.replace("/v2/employer/om-supervisor-profile");
                }
              }
            } else {
              window.location.replace("/v2/jobseeker/daily-job-profile");
            }
          }
        });
    },
  },
  mounted() {
    console.log(JSON.parse(atob(this.$route.query.res)), "json data");

    var singpassData = JSON.parse(atob(this.$route.query.res));

    localStorage.setItem("singpassData", atob(this.$route.query.res));

    this.$store
      .dispatch(POST_API, {
        data: {
          mobile: singpassData.mobileno.nbr.value,
          user_type: 5,
          email: singpassData.email.value,
          nric: singpassData.uinfin.value,
        },
        api: "/api/userDetailWithMobileApi",
      })
      .then(() => {
        console.log(this.$store.getters.getResults.user, "data");

        if (this.$store.getters.getResults.user) {
          this.email = this.$store.getters.getResults.user.email;
          this.password = this.$store.getters.getResults.user.password;
          this.login();
        } else {
          window.location.replace("/singpass-login");
        }
      });
  },
};
</script>
