export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const ADMIN_LOGIN = 'admin_login';
export const OTP_LOGIN = 'otp_login';
export const ADMINLOGOUT = 'admin-logout';
export const LOGOUT = 'logout';
export const SET_PROFILE_DATA = 'set_profile_data';
export const ADMIN = 'admin';
export const WEBSITE = 'website';

export const GET_API = 'get_api';
export const POST_API = 'post_api';
export const PUT_API = 'put_api';
export const DELETE_API = 'delete_api';
export const UPDATE_AUTH_PUT_API = 'update_auth_put_api';
export const UPDATE_AUTH_POST_API = 'update_auth_post_api';
export const MULTIPART_POST_API = 'multipart_post_api';

export const SET_CHAT_DATA = "setChatData";
export const SET_SELECTED_JOB = "setSelectedJob";
export const SET_SELECTED_USER = "setSelectedUser";
export const SET_CURRENT_SECTION = "setCurrentSection";

export const CHANGE_PASSWORD = 'change_password';
export const PERMISSION_DATA = 'permission';




