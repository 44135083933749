<template>
  <div>
    <!-- <div class="container full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Rewards</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Rewards</li>
            </ul>
          </nav>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <b-input-group-prepend>
                <b-form-input
                  id="searchBtn"
                  @keyup.enter.native="filter = searchKeyword"
                  v-model="searchKeyword"
                  placeholder="Search by keyword"
                ></b-form-input>
                <b-button class="btn points_events"
                  ><i class="fa fa-search" @click="filter = searchKeyword"></i
                ></b-button>
              </b-input-group-prepend>
            </div>
          </div>
          <div
            class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28"
          >
            <b-link
              class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero"
              :to="{ name: 'reward-create' }"
              v-if="show('add')"
              >Add</b-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <!-- <div class="container full_container_dsk">
           <div class="row"> -->
    <div class="row">
      <div class="container my_new_container show_filter_arrow">
        <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getReward" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="static_page three_grid_tbl static_mobile_tbl mobile_prs"> -->
        <b-table
          ref="datatable"
          show-empty
          striped
          hover
          responsive
          :items="getReward"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="mobile_min_width_1000 el_coin_four_grid_table"
        >
          <template #cell(title)="items">
            {{ items.item.title | capitalize }}
          </template>
          <template #cell(description)="items">
            <p v-if="items.item.description.length > 500">
              <span v-html="items.item.description.substring(0, 500) + '..'"></span>
            </p>
            <p v-else>
              <span v-html="items.item.description"></span>
            </p>
          </template>
          <template #cell(company_name)="items">
            {{ items.item.company_name | capitalize }}
          </template>
          <template #cell(total_claim_count)="items">
            {{ items.item.total_claim_count }}
          </template>
          <template #cell(start_date)="items">
            <p v-if="items.item.end_date">
              {{ items.item.start_date | moment }} - {{ items.item.end_date | moment}}
            </p>
            <p v-else>
              {{ items.item.start_date | moment }}
            </p>
          </template>
          <template #cell(status)="items">
            {{ items.item.status | capitalize }}
          </template>
          <template #cell(toggleAction)="items">
            <div class="toggle-action toggle_margin_unset">
              <b-dropdown class="mx-1" right text="Action" boundary="window">
                <b-dropdown-item
                  :to="{ name: 'reward-edit', params: { id: encrypt(items.item.id) } }"
                  v-if="show('edit')"
                  >Edit
                </b-dropdown-item>
                <b-dropdown-item
                  v-on:click="changeStatus(items.item.id, items.item.status)"
                  v-if="items.item.status == 'active' && show('activate/inactivate')"
                  >Inactivate</b-dropdown-item
                >
                <b-dropdown-item
                  v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                  v-on:click="changeStatus(items.item.id, items.item.status)"
                  >Activate</b-dropdown-item
                >
                <b-dropdown-item v-on:click="deleteReward(items.item.id)" v-if="show('delete')"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block">
                <b-form-select v-model="form.rowsPerPage" @change="getReward">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import permission from "../../../../../server/permission.js";

import moment from "moment";

export default {
  data() {
    return {
      fields: [
        { key: "title", label: "Title", sortable: true },
        { key: "company_name", label: "Company Name", sortable: true },
        { key: "description", label: "Description", sortable: false },
        { key: "total_claim_count", label: "Claim Count", sortable: true },
        { key: "start_date", label: "Duration", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "toggleAction", label: "Actions" },
      ],
      searchKeyword: "",
      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "active",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
      },
      actions: "",
    };
  },
  filters: {
    moment: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    getReward() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "rewards.id",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/reward-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    changeStatus(id, status) {
      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Reward Inactivated successfully.";
        var new_status = "inactive";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Reward Activated successfully.";
        var new_status = "active";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: new_status,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/reward-status",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },

    deleteReward(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/reward-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Reward Deleted successfully.";
                  this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Rewards");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.permissionStaff();

    //document.title = "EL Connect - Admin - Website Contents - Process Descriptions";
  },
};
</script>
