<template>
    <div class="home_go_top home_padding_set_mobile_n_tab">

        <!-- Intro Banner
        ================================================== -->
		<carousel :per-page="1" :mouse-drag="false" :arrows="false" :autoplay=true :loop=true :centerMode=true :speed=50 class="home_top_slider">
            <slide v-for="(banner,h) in banners" :key="h" aria-hidden="false">
				<div class="background_img_custom home_bg_new_div"  v-bind:style="{backgroundImage: 'url('+ banner.banner_image +')'}">
					<div class="inner_gradient_home hundered_height" v-if="banner_opacity == 'yes'">
						<div class="container mobile_tab_container_hundered">

							<!-- Intro Headline -->
							<div class="row">
							<div class="col-md-12">
								<div class="banner-headline new-banner-headline">
									<h1><strong :style="{color:banner.title_color}">{{banner.image_title}}</strong><br>
									<span :style="{color:banner.description_color}" class="common_futura_book">{{banner.image_description}}</span>
										</h1>
									</div>
								</div>
							</div>
							<!-- Search Bar -->
								<div class="row">
								<div class="col-md-12">
									<div class="intro-banner-search-form margin-top-95 home_banner_update">
								<div class="bg_div">
								<!-- Search Field -->
								<div class="intro-search-field with-label">
									<label for="intro-keywords" class="field-title ripple-effect">Dream Job Search</label>
									<input id="intro-keywords" type="text" v-model="job_title_V1" placeholder="Job Title or Keywords" class="input_pdng single_home_input" @keyup.enter="getAllJobOnSearchDPFT($event,category_id_V1,job_title_V1)">
								</div>
								<div class="intro-search-field with-autocomplete with-label  category_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Job Type</label>
									<div class="input-with-icon">
										<b-form-select v-model="category_ftdp_job_type" class="select_mrgn picker" v-on:change="onChangeJobType">
                                            <option value="daily_paid">Daily Paid</option>
                                            <option value="full_time">Full Time</option>
                                        </b-form-select>
									</div>
								</div>
								<div class="intro-search-field with-autocomplete with-label  category_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Category</label>
									<div class="input-with-icon">
										<b-form-select v-model="category_id_V1" class="select_mrgn picker">
                                             <option value="" disabled>All Category</option>
                                            <option :value="categoryV1.alias" v-for="categoryV1 in categoriesV1">{{categoryV1.name | capitalize}}</option>
                                        </b-form-select>
									</div>
								</div>
								<!-- <div class="intro-search-field with-label">
									<label for="intro-keywords" class="field-title ripple-effect">Dream Job Search</label>
									<input id="intro-keywords" type="text" v-model="job_title" placeholder="Job Title or Keywords" class="input_pdng single_home_input">
								</div>

								<div class="intro-search-field with-autocomplete with-label  category_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Category</label>
									<div class="input-with-icon">
										<b-form-select v-model="category_id" class="select_mrgn picker">
                                             <option value="" disabled>All Category</option>
                                            <option :value="category.id" v-for="category in categories">{{category.name | capitalize}}</option>
                                        </b-form-select>
									</div>
								</div>
								<div class="intro-search-field with-autocomplete with-label location_width_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Where?</label>
									<div class="input-with-icon">
										<select v-model="location_id" id="job_location" class="job_location custom-select">
											<option value='' selected='selected' disabled>All Location</option>
											<optgroup :label="location.title" v-for="location in locations">
												<option :value="location.location_names_id[index]" v-for="(area,index) in location.location_names_val">{{area | capitalize}}</option>
											</optgroup>
										</select>
									</div>
								</div>
								<div class="intro-search-field with-autocomplete with-label job_type_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Job Type</label>
									<div class="input-with-icon">
										<b-form-select v-model="role_type_id" class="common_margin_bottom_zero">
											<option value="" disabled>All Job Type</option>
											<option :value="role_type.id" v-for="role_type in role_types">{{role_type.name | customizeRoleType}}</option>
										</b-form-select>
									</div>
								</div> -->
								<div  class="intro-search-button banner_search_btn">
									<!-- <button class="button ripple-effect" @click="getAllJobOnSearch($event,role_type_id,category_id,job_title,location_id)">Search</button> -->
									<!-- <button class="button ripple-effect" @click="getAllJobOnSearchDP($event,category_id_V1,job_title_V1)">Search</button> -->
									<button class="button ripple-effect" @click="getAllJobOnSearchDPFT($event,category_id_V1,job_title_V1)">Search</button>

								</div>
								</div>
							</div>
								</div>
							</div>
						</div>
					</div>
					<div class="inner_gradient_home inner_gradient_home_remove hundered_height" v-else>

						<div class="container mobile_tab_container_hundered">

							<!-- Intro Headline -->
							<div class="row">
							<div class="col-md-12">
								<div class="banner-headline new-banner-headline">
									<h3><strong :style="{color:banner.title_color}">{{banner.image_title}}</strong><br>
									<span :style="{color:banner.description_color}">{{banner.image_description}}</span>
										</h3>
									</div>
								</div>
							</div>
							<!-- Search Bar -->
								<div class="row">
								<div class="col-md-12">
									<div class="intro-banner-search-form margin-top-95 home_banner_update">
								<div class="bg_div">
								<!-- Search Field -->
								<div class="intro-search-field with-label">
									<label for="intro-keywords" class="field-title ripple-effect">Dream Job Search</label>
									<input id="intro-keywords" type="text" v-model="job_title_V1" placeholder="Job Title or Keywords" class="input_pdng single_home_input" @keyup.enter="getAllJobOnSearchDPFT($event,category_id_V1,job_title_V1)">
								</div>
								<div class="intro-search-field with-autocomplete with-label  category_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Job Type</label>
									<div class="input-with-icon">
										<b-form-select v-model="category_ftdp_job_type" class="select_mrgn picker" v-on:change="onChangeJobType">
                                            <option value="daily_paid">Daily Paid</option>
                                            <option value="full_time">Full Time</option>
                                        </b-form-select>
									</div>
								</div>
								<div class="intro-search-field with-autocomplete with-label  category_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Category</label>
									<div class="input-with-icon">
										<!--<input id="autocomplete-input" type="text" placeholder="Design/Finance" class="input_pdng">-->
										<b-form-select v-model="category_id_V1" class="select_mrgn picker">
                                             <option value="" disabled>All Category</option>
                                            <option :value="categoryV1.alias" v-for="categoryV1 in categoriesV1">{{categoryV1.name | capitalize}}</option>
                                        </b-form-select>
                                                 <!-- <multiselect v-model="category_id" @search-change="getDropdownData()" placeholder="Enter to search category" :select-label="''" :show-labels=false  label="name" track-by="id" :options="categories" :multiple="false" class="home_multi_select"><span slot="noResult">No elements found.</span></multiselect> -->
                                                 <!-- <multiselect v-model="category_id" @search-change="getDropdownData($event)" placeholder="Enter to search category"  label="name" track-by="id" :options="categories" class="home_multi_select"><span slot="noResult">No elements found.</span></multiselect> -->

										<!-- <i class="icon-material-outline-location-on"></i> -->
									</div>
								</div>
								<!-- <div class="intro-search-field with-label">
									<label for="intro-keywords" class="field-title ripple-effect">Dream Job Search</label>
									<input id="intro-keywords" type="text" v-model="job_title" placeholder="Job Title or Keywords" class="input_pdng single_home_input">
								</div>
								<div class="intro-search-field with-autocomplete with-label  category_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Category</label>
									<div class="input-with-icon">
										<b-form-select v-model="category_id" class="select_mrgn picker">
                                             <option value="" disabled>All Category</option>
                                            <option :value="category.id" v-for="category in categories">{{category.name | capitalize}}</option>
                                        </b-form-select>
									</div>
								</div>
								<div class="intro-search-field with-autocomplete with-label location_width_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Where?</label>
									<div class="input-with-icon">
										<select v-model="location_id" id="job_location" class="job_location custom-select">
											<option value='' selected='selected' disabled>All Location</option>
											<optgroup :label="location.title" v-for="location in locations">
												<option :value="location.location_names_id[index]" v-for="(area,index) in location.location_names_val">{{area | capitalize}}</option>
											</optgroup>
										</select>
									</div>

								</div>
								<div class="intro-search-field with-autocomplete with-label job_type_widht_home"><div class="pac-container pac-logo hdpi" style="display: none;"></div>
									<label for="autocomplete-input" class="field-title ripple-effect">Job Type</label>
									<div class="input-with-icon">
										<b-form-select v-model="role_type_id" class="common_margin_bottom_zero">
											<option value="" disabled>All Job Type</option>
											<option :value="role_type.id" v-for="role_type in role_types">{{role_type.name | customizeRoleType}}</option>
										</b-form-select>
									</div>
								</div>
								<div  class="intro-search-button banner_search_btn">
									<button class="button ripple-effect" @click="getAllJobOnSearch($event,role_type_id,category_id,job_title,location_id)">Search</button>
								</div> -->
								<!-- Button -->
								<div  class="intro-search-button banner_search_btn">
									<!-- <button class="button ripple-effect" @click="getAllJobOnSearch($event,encrypt(role_type_id),category_id,encrypt(job_title),location_id)">Search</button> -->
									<!-- <button class="button ripple-effect" @click="getAllJobOnSearchDP($event,category_id_V1,job_title_V1)">Search</button> -->
									<button class="button ripple-effect" @click="getAllJobOnSearchDPFT($event,category_id_V1,job_title_V1)">Search</button>

									<!-- <b-link :to="{name: 'allJobsListing', params: {role_type: encrypt(role_type_id),category_id: encrypt(category_id.id),keyword: encrypt(job_title),location: encrypt(location_id)}}">Search</b-link> -->
									<!-- <button class="button ripple-effect" @click="getWebsiteData()">Search</button> -->
								</div>
									</div>
							</div>
								</div>
							</div>

							<!-- Stats -->


						</div>
					</div>
				</div>
			</slide>
        </carousel>
        <!-- Content
        ================================================== -->
		<!-- <div class="container">
	<div class="row">
	<div class="keywords-container">
		<div class="keyword-input-container">
		<multiselect v-model="tagArr" @search-change="getDropdownListData($event)" @input="getDataDropDown($event)" tag-placeholder="Add this as new tag" placeholder="Enter to search tag" label="title" track-by="id" :options="options" :multiple="true" class="new_multi_slec_list" ></multiselect>
		</div>
	</div>
	</div>
</div> -->


<!--- Two Grif Custom  Slider Start--->
	<!--<div class="section  padding-top-65 padding-bottom-70 custom_two_grid_slider_home">
	<div class="container">


			<div class="">
				Section Headline
				<div class="section-headline margin-top-0 margin-bottom-25">
					<h3 class="frelance_h3">Daily Paid Jobs</h3>
					<h4 class="freeelance_h4">Looking for a quick way to earn money but don't want to commit to a working schedule? join the Daily Paid platform now!</h4>
				</div>

					<VueSlickCarousel :arrows="true" :dots="true" v-bind="settings">
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (3).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (2).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (3).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (2).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (3).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (2).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (3).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (2).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (3).png'"></div>
						<div><img :src="webUrl+'assets/images/MicrosoftTeams-image (2).png'"></div>
				</VueSlickCarousel>

		</div>
	</div>
	</div>-->

<!--- Two Grid Custom  Slider End--->
		<!-- Features Jobs -->
        <!-- <div class="section   padding-top-65 padding-bottom-75 daily_paid_job_home_page" v-if = "dailyjob_datas.length > 0"> -->
		<div class="section   padding-top-50 padding-bottom-0 daily_paid_job_home_page" v-if = "dailyjob_datas.length > 0">
			<div class="container mobile_tab_container_hundered">
				<div class="row">
					<div class="col-xl-12">

						<!-- Section Headline -->
						<div class="section-headline margin-top-0 margin-bottom-35 hide_in_mobile_home">
							<h2 class="common_new_color_heading"><b>Daily Paid Job Listings</b></h2>
							<b-link :to="{name: 'allDailyPaidJoblisting'}" class="headline-link common_futura_medium common_font_18_futura">Browse All Listing</b-link>
							<!-- <b-link :to="{name: 'allDailyPaidJoblisting'}" class="headline-link" v-if="dailyjob_datas_count > 5">Browse All Listing</b-link> -->
						</div>
						<div class="daily_paid_moobile">
						<div class="mobile_freelance_heading">
							<h1 class="freeelance_h4 common_mobile_tab_h1"><b>Daily Paid Job Listings</b></h1>
							</div>
							<div class="mobile_see_more_btn">
								<div>
								<span class="see_more_btn"><b-link :to="{name: 'allDailyPaidJoblisting'}" class="headline-link common_futura_medium common_font_18_futura" v-if="dailyjob_datas_count > 5" >Browse All Listing </b-link></span>
							</div>
							</div>
						</div>

						<!-- Jobs Container -->
						<div class="tasks-list-container compact-list margin-top-35 full_box_grid_shows home_dp_jb">

							<!-- Task -->
							<span class="task-listing home_listing" v-for="(dailyjob_data, index) in dailyjob_datas" v-if="index <= 7">
								<!-- Job Listing Details -->
								<div class="task-listing-details">
									<!-- Details -->
									<div class="task-listing-description">
										<h3 class="task-listing-title"><b>{{dailyjob_data.title | capitalize}}</b><span class="featured_box desktop_on_demand_text" v-if="dailyjob_data.job_type_id == 2">On-Demand</span></h3>
										<p class="featured_box mobile_tab_on_demand_text" v-if="dailyjob_data.job_type_id == 2"><span>On-Demand</span></p>
										<ul class="task-icons">
											<li><i class="icon-line-awesome-calendar-o"></i>{{dailyjob_data.start_date | momentDate}}</li>
											<li><i class="icon-material-outline-access-time"></i>{{dailyjob_data.start_time | momentTime}} - {{dailyjob_data.end_time | momentTime}}</li>
											<li><i class="icon-feather-users"></i>{{dailyjob_data.category_name}}</li>
											<li><i class="icon-material-outline-location-on"></i>{{dailyjob_data.address | capitalize}}</li>

											<!--<li><i class="icon-material-outline-access-time"></i>2 minutes ago</li>-->

										</ul>
									</div>
								</div>

								<div class="task-listing-bid">
									<div class="task-listing-bid-inner new_task_bid_mobile">
										<div class="task-offers">
											<strong>S${{dailyjob_data.hourly_cost.toFixed(2)}}/HR</strong>
											<!--<span>Fixed Price</span>-->
										</div>
										<span><b-link class="button button-sliding-icon ripple-effect" :to="{name: 'allDailyPaidJobDetailNew', params: {company_name:dailyjob_data.alias,id: encrypt(dailyjob_data.id),job_alias:dailyjob_data.job_alias}}" target="_blank">Details<i class="icon-material-outline-arrow-right-alt"></i></b-link></span>
										<!-- <span class="button button-sliding-icon ripple-effect" @click="dailyPaidJobDetail((dailyjob_data.id),$event)">Check Now <i class="icon-material-outline-arrow-right-alt"></i></span> -->
									</div>
								</div>
							</span>

							<!-- <a href="#" class="task-listing">

								<div class="task-listing-details">

									<div class="task-listing-description">
										<h3 class="task-listing-title">F&B Server Needed @ Orchard Paragon<span class="featured_box">Featured</span></h3>
										<ul class="task-icons">
											<li><i class="icon-material-outline-location-on"></i>Orchard</li>
											<li><i class="icon-material-outline-access-time"></i>2 minutes ago</li>
											<li><i class="icon-material-outline-access-time"></i>12:00 AM - 10:00 PM</li>
											<li><i class="icon-line-awesome-calendar-o"></i>09 Jul, 2022</li>
											<li><i class="icon-feather-users"></i>1 Vacancy</li>

										</ul>
										<div class="task-tags margin-top-15">
											<span>iOS</span>
											<span>Android</span>
											<span>mobile apps</span>
											<span>design</span>
										</div>
									</div>

								</div>

								<div class="task-listing-bid">
									<div class="task-listing-bid-inner">
										<div class="task-offers">
											<strong>$12.00 / HR</strong>
											<span>Fixed Price</span>
										</div>
										<span class="button button-sliding-icon ripple-effect">Check Now <i class="icon-material-outline-arrow-right-alt"></i></span>
									</div>
								</div>
							</a> -->

						</div>
						<!-- Jobs Container / End -->

					</div>
				</div>
			</div>
		</div>
        <!-- Featured Jobs / End -->

		<!--- Two Grif Custom  Slider Start--->

		<!-- Popular Job Categories -->
        <!-- <div class="section gray  padding-top-65 padding-bottom-75"> -->
		<div class="section   padding-top-50 padding-bottom-50">
			<div class="container mobile_tab_container_hundered">
				<div class="row">
					<div class="col-xl-12">

						<div class="section-headline centered margin-bottom-15 mobile_tab_section_cat">
							<h2 class="job_cat_hdn common_new_color_heading" :class="job_categories.length > 8 ? 'heading_in_start' : 'heading_in_center'"><b>Popular Full Time Job Categories</b></h2>
							<b-link :to="{name: 'allJobsListing'}" class="headline-link common_futura_medium common_font_18_futura desktop_browse" v-if="job_categories.length > 8">Browse All Listing</b-link>
							<div class="mobile_tab_browse">
								<b-link :to="{name: 'allJobsListing'}" class="headline-link common_futura_medium" v-if="job_categories.length > 8">Browse All Listing</b-link>
							</div>
						</div>

						<!-- Category Boxes Container -->
						<div class="categories-container">

							<!-- <b-link :to="{name: 'allJobsListing', params: {role_type: 'all',category_id: job_cat.id,keyword: 'all',location: 'all'}}" class="category-box" v-for="(job_cat,index) in job_categories" v-bind:style="{backgroundImage: 'url('+ job_cat.service_image +')'}" v-if ="index <= 7"> -->
							<b-link @click="getAllJobOnSearch($event,'all-role-jobs',job_cat.alias,'all-title-jobs','all-location-jobs')" class="category-box" v-for="(job_cat,index) in job_categories" v-bind:style="{backgroundImage: 'url('+ job_cat.service_image +')'}" v-if ="index <= 7">

								<!--<div class="category-box-icon">
									<img :src="job_cat.service_image ? job_cat.service_image: webUrl+'assets/images/company-logo-placeholders.png'" alt="Partners Logo" class="">
									<i :class="job_cat.logo"></i>
								</div> -->
								<div class="whole_inner_content">
								<div class="category-box-counter"><b style="font-size: 20px;">{{job_cat.job_count}}</b></div>
								<div class="category-box-content">
									<h2 class="category_h2" v-if="job_cat.name.length<36">{{job_cat.name}}</h2>
									<h2 class="category_h2" v-else>{{job_cat.name.substring(0,35)+"..."}}</h2>
									<span v-if="job_cat.description" class="cat">
                                    <!--<p v-if="job_cat.description.length<50">{{job_cat.description}}</p>-->
                                    <p>{{job_cat.description.substring(0,45)+"..."}}</p>
                                    </span>
								</div>
								</div>
							</b-link>
						</div>
					</div>
				</div>
			</div>
		</div>
        <!--Popular Job / End -->

	<!-- <div class="section gray padding-top-65 padding-bottom-70 custom_two_grid_slider_home">
	<div class="container">


			<div class="">
				<div class="section-headline margin-top-0 margin-bottom-25 section_headline_remove_pdng">
					<h3 class="frelance_h3 common_center">Gigplace</h3>
					<h4 class="freeelance_h4 home_gray common_center">Engage popular professional services for every kind of needs!</h4>
				</div>

				<VueSlickCarousel :arrows="true" :dots="true" v-bind="settingsGig" ref="carousel" v-if="gigs.length" class="gigplace_section_home">




								<div  class="category-box" v-for="(gig, index) in gigs" v-bind:style="{backgroundImage: 'url('+ gig.image +')'}" >
									<div class="whole_inner_content">
									<div class="category-box-content">
										<h3 style="color:white !important; " v-if="gig.title.length<30">{{gig.title}}</h3>
										<h3 style="color:white !important;" v-else>{{gig.title.substring(0,22)+"..."}}</h3>
										<span v-if="gig.description">
										<p  style="color:white !important;" v-if="gig.description.length<30">{{gig.description}}</p>
										<p  style="color:white !important;" v-else>{{gig.description.substring(0,25)+"..."}}</p>
										</span>
								</div></div>
								</div>
				</VueSlickCarousel>


					<VueSlickCarousel :arrows="true" :dots="true" v-bind="settingsGig" class="custom_carousell_home">


						<div class="home_four_grid_carousells" v-for="(gig, index) in gigs" v-bind:style="{backgroundImage: 'url('+ gig.image +')'}" >
							<b-link :to="{name: 'Gigplace'}" class="">


								<div class="whole_inner_content">
								<div class="category-box-content">
									<h3 v-if="gig.title.length<30">{{gig.title}}</h3>
									<h3 v-else>{{gig.title.substring(0,22)+"..."}}</h3>
									<span v-if="gig.description">
                                    <p v-if="gig.description.length<30">{{gig.description}}</p>
                                    <p v-else>{{gig.description.substring(0,45)+"..."}}</p>
                                    </span>
								</div>
								</div>
							</b-link>
						</div>
				</VueSlickCarousel>

		</div>
	</div>
	</div> -->

<!--- Two Grid Custom  Slider End--->
        <!-- Features Jobs -->
        <!--<div class="section  job_home padding-top-65 padding-bottom-75 " v-if = "jobs.length > 0">
			<div class="container">
				<div class="row">
					<div class="col-xl-12">-->
						<!-- Section Headline -->
						<!--<div class="section-headline margin-top-0 margin-bottom-35 mobile_align_text_in_two">
							<h3>Jobs Listing</h3>
							<b-link :to="{name: 'allJobsListing'}" class="headline-link" v-if="jobs.length > 4">Browse All Listing</b-link>
						</div>-->

						<!-- Jobs Container -->
						<!--<div class="tasks-list-container compact-list margin-top-35">-->

							<!-- Task -->
							<!--<a href="javascript:void(0);" @click="jobDetail(job.id,$event)" class="task-listing" v-for="(job, index) in jobs" v-if="index <= 4">-->

								<!-- Job Listing Details -->
								<!--<div class="task-listing-details">-->

									<!-- Details -->
									<!--<div class="task-listing-description">
										<h3 class="task-listing-title">{{job.title | capitalize}}<span><i class="fa fa-star checked"></i></span></h3>
										<ul class="task-icons">
											<li><i class="icon-material-outline-location-on"></i> {{job.address | capitalize}}</li>
											<li><i class="icon-material-outline-access-time"></i> {{job.posted_date | dateTimeAgo | capitalize}}</li>
										</ul>
										<div class="task-tags margin-top-15">
											<span v-for="tags in job.tag_names_val">{{tags | capitalize}}</span>
										</div>
									</div>

								</div>-->

								<!--<div class="task-listing-bid">
									<div class="task-listing-bid-inner">
										<div class="task-offers">
											<strong>${{job.min_salary}} - ${{job.max_salary}}</strong>-->
											<!-- <span>Fixed Price</span> -->
										<!--</div>
										<span class="button button-sliding-icon ripple-effect">Check Now <i class="icon-material-outline-arrow-right-alt"></i></span>
									</div>
								</div>
							</a>

						</div>-->
						<!-- Jobs Container / End -->
					<!--
					</div>
				</div>
			</div>
		</div>-->
        <!-- Featured Jobs / End -->

        <!-- Icon Boxes -->
        <div class="section padding-top-65 padding-bottom-65" v-if = "process_descriptions.length > 0">
            <div class="container mobile_tab_container_hundered">
                <div class="row">

                    <div class="col-xl-12">
                        <!-- Section Headline -->
                        <div class="section-headline centered margin-top-0 margin-bottom-5">
                            <h3>How It Works?</h3>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-4" v-for="process_des in process_descriptions">
                        <!-- Icon Box -->
                        <div class="icon-box with-line">
                            <!-- Icon -->
                            <div class="icon-box-circle">
                                <div class="icon-box-circle-inner">
                                    <i class="icon-line-awesome-lock"></i>
                                    <div class="icon-box-check"><i class="icon-material-outline-check"></i></div>
									<!-- <img :src="process_des.image" alt="Promotion Logo" class=""> -->
                                </div>
                            </div>
                            <h3>{{process_des.title}}</h3>
                            <p v-html="process_des.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Icon Boxes / End -->
        <!-- Our Partner Start -->
           <div class="common_display_none_desktop section padding-bottom-50 padding-top-50" v-if = "partners.length > 0" >
				<div class="container mobile_tab_container_hundered">
					<div class="row">

						<div class="col-xl-12">
							<!-- Section Headline -->
							<div class="section-headline centered  margin-bottom-5">
								<h3 class="prtner_heading"><b>Partners</b></h3>
							</div>
						</div>
						<div class="col-xl-12">
              <logo-slider>
                  <div class="slider">
                      <!-- <img  v-for="(item,val) in items" :key="val" :title="item.name" :src="item.logo" alt="Partner Logo"> -->
                        <div v-for="partner in partners" class="slider_logo">
                          <img :title="partners.name" :src="partner.logo" alt="Partner Logo" width="100" height="100" loading="lazy">
                  </div>
                  </div>
              </logo-slider>
						<!-- 	<div class="whole_company">
							<div class="company_first_row" v-for="partner in partners">

										<span>
											<img :title="partner.name" :src="partner.logo" alt="Partner Logo" class="">
										</span>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
        <!-- Our Partner End -->


         <!-- slider2 Start -->
            <!--<div class="section gray common_display_none_desktop padding-top-50  full-width-carousel-fix custom_freelancer" v-if = "promotions.length > 0">
				<div class="container mobile_tab_container_hundered">
					<div class="row">

						<div class="col-xl-12">
							<!-- Section Headline 
							<div class="section-headline margin-top-0 margin-bottom-25 custom_freelancer_inner">
								<h3 class="frelance_h3"><b>Latest Promotions</b></h3>
								<h4 class="freeelance_h4 home_gray">Looking for the latest sweet deals? Look no further!<span class="see_more_btn"><b-link :to="{name: 'allPromotionlisting'}" v-if="promotions.length > 8">View All<i class='fa fa-angle-right'></i></b-link></span></h4>
							<div class="mobile_freelance_heading">
							<h4 class="freeelance_h4">Looking for the latest sweet deals? Look no further!</h4>
							</div>
							<div class="mobile_see_more_btn">
								<span class="see_more_btn"><a href="#" class="">View All <i class='fa fa-angle-right'></i></a></span>
							</div>
							</div>
						</div>

						<div class="three_grid_div_main col-xl-12">
							<div class="home_grd_three img-hover-zoom" v-for="(promotion, index) in promotions" v-if="index <= 8">

								<b-link :to="{name: 'allPromotionDetail', params: {id: promotion.id}}" class="job-listing" style="width: 100%;">


								<img :src="promotion.image ? promotion.image : webUrl+'assets/images/logo.png'" alt="Promotion Logo" loading="lazy">
								</b-link>
							</div>
						</div>
					</div>
				</div>
			</div> -->


        <!-- slider2 End -->

		<!--- My Custom Testimonial Slider Start -->

		<!--<div class="section  padding-bottom-50 my_custom_slider" :class="promotions.length > 0 ? 'padding_top_50_true' : 'padding_top_50_false'">
			<div class="container mobile_tab_container_hundered">
				<div class="col-xl-12"><div class="section-headline centered margin-top-0 margin-bottom-5"><h3 class="common_new_color_heading"><b>Testimonials</b></h3></div></div>
					<VueSlickCarousel :arrows="true" :dots="true" ref="carousel" v-if="testimonial.length" :adaptive-height="true" class="home_slick_arrrow">
					<div class="testimonial-box" v-for="(test,h) in testimonial">
						<div class="testimonial-avatar">
							<img :src="test.image" alt="Freelancer Avatar" loading="lazy" width="85" height="85">
						</div>
							<div class="testimonial-author">
							<h4>{{test.title}}</h4>
							<span>{{test.user_type | capitalize}}</span>
							</div>
							<div class="testimonial">
								{{test.description}}
							</div>
						</div>

					</VueSlickCarousel>
  			</div>

		</div>-->

	<!--- My Custom Testimonial Slider End -->
            <!-- Counters -->
            <div class="section common_display_none_desktop gray padding-top-50 padding-bottom-50">
                <div class="container mobile_tab_container_hundered">
                    <div class="row">

                        <div class="col-xl-12">
                            <div class="counters-container home_counters_container">

                                <!-- Counter -->
                                <div class="single-counter">
                                    <i class="icon-line-awesome-suitcase"></i>
                                    <div class="counter-inner">
                                        <h3><span class="">{{ (Math.ceil((ft_jobs_count + dp_jobs_count) / 1000 ) * 1000).toLocaleString('en-IN') }}+</span></h3>
                                        <span class="counter-title">Total Jobs Posted</span>
                                    </div>
                                </div>

                                <!-- Counter -->
                                <!-- <div class="single-counter">
                                   <span class='material-icons-outlined tools_icon'>handyman</span>
                                    <div class="counter-inner">
                                        <h3><span class="counter">{{dp_jobs_count}}</span></h3>
                                        <span class="counter-title">Daily Paid Jobs Posted</span>
                                    </div>
                                </div> -->

                                <!-- Counter -->
                                <div class="single-counter">
                                    <i class="icon-feather-file-text"></i>
                                    <div class="counter-inner">
                                        <h3><span class="">{{ (Math.ceil(companies_count / 10 ) * 10).toLocaleString('en-IN') }}+</span></h3>
                                        <span class="counter-title">Companies Onboarded</span>
                                    </div>
                                </div>

                                <!-- Counter -->
                                <div class="single-counter">
                                    <i class="icon-feather-user"></i>
                                    <div class="counter-inner">
                                        <h3><span class="">{{ (Math.ceil(users_count / 1000 ) * 1000).toLocaleString('en-IN')}}+</span></h3>
                                        <span class="counter-title">Registered Users</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Counters / End -->

		<!-- 	<div class="home_blog_content_new_index section white padding-bottom-50 full-width-carousel-fix blog_slider mobile_padding_bottom_25">
				<div class="container">
          <div class="row">
						<div class="col-xl-12">
							<div class="section-headline centered margin-top-0 margin-bottom-5">
								<h3 class="common_new_color_heading"><b>Blog</b></h3>
							</div>
						</div>
						<div class="col-xl-12">
							<VueSlickCarousel :arrows="true" :dots="true" v-bind="settingsHomeBlog" ref="carousel" v-if="featured_post.length" class="job_detail_slider">
								<div class="index_blog_compact_item" v-for="(featured, h) in featured_post" :key="h">
									<a href="#" @click="blogDetail($event,featured.id,featured.title)" class="">
										<img :src="featured.image" alt="" class="index_blog_image">
										<span class="line_index_span"><img :src="webUrl+'assets/images/Line 24.png'"><span class="index_blog_item_tag">{{ featured.tag }}</span></span>
										<div class="index_blog_compact_item_content">
										<h3 v-if="featured.title.length<97">{{ featured.title }}</h3>
										<h3 v-else>{{ featured.title.substring(0,97)+""}}</h3>
										<p  v-if="featured.description.length<157">{{ featured.description }}</p>
										<p v-else>{{ featured.description.substring(0,157)+""}}</p>
										</div>
									</a>
								</div>
							</VueSlickCarousel>
						</div>
						<div class="col-xl-12">
							<div class="btn_div_center">
								<b-link class="index_blog_btn" :to="{name:'blog'}" target="_blank">See All Articles</b-link>
							</div>
						</div>
					</div>
        </div>
      </div> -->

        </div>
</template>


<script>
import { POST_API } from '../../store/actions.type';
import { Carousel, Slide } from 'vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default{
	metaInfo: {
		title: 'Ultimate Job Matching Platform in Singapore - EL Connect ',
		meta: [
			{ charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
			{ name: 'description', content: 'EL Connect is the ultimate job matching platform in Singapore that offers flexible daily gigs, permanent part time and full time jobs. Apply for jobs now!' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
      		{ name: 'keywords', content: 'el connect, job platform singapore, full time jobs, daily paid jobs, gig jobs, permanent part time, flexible work, part time daily paid jobs, daily paid jobs singapore, job posting site, part time jobs, daily paid, daily paid job, apply for full time jobs'},
			{property: 'og:title', content: 'Ultimate Job Matching Platform in Singapore - EL Connect'},
			{property: 'og:site_name', content: 'EL Connect'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://elconnect.sg'},
			{property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			{property: 'og:description', content: 'EL Connect is the ultimate job matching platform in Singapore that offers flexible daily gigs, permanent part time and full time jobs. Apply for jobs now!' },
			{name: 'twitter:card', content: 'summary'}
		],
		link: [
    		{rel: 'canonical', href: 'https://elconnect.sg'}
  		]
	},
    data() {
        return {
			featured_post: [],
			options: [],
			tagArr: [],
			location:'',
			locations:[],
			location_id:'',
			job_title:'',
			job_title_V1:'',
			category_ftdp_job_type:'daily_paid',
			category_id:'',
			category_id_V1:'',
			categories: [],
			categoriesV1: [],
			testimonial:[],
			daily:'',
            full_time:'',
			type:'',
			job_id:'',
            jobData: '',
			role_type_id:'',
            role_types:[],
            webUrl:process.env.VUE_APP_URL,
			banners:[],
			job_categories:[],
			process_descriptions:[],
			dailyjob_datas:[],
			dailyjob_datas_count:'',
			partners:[],
			//promotions:[],
			gigs:[],
			banner_opacity:'yes',
			jobs:[],
			filter:'',
			tagArray:[],
			jobs_count:'',
			users_count:'',
			dp_jobs_count:'',
			ft_jobs_count:'',
			companies_count:'',
			BackImage: 'url(/assets/images/home-background.jpg)',
			settings: {
				"slidesToShow": 2,
				"slidesToScroll": 2,
				"speed": 800,
				"infinite": false,
				responsive: [
					{
					breakpoint: 768,
					settings: {
						"slidesToShow": 1,
							"slidesToScroll": 1,
							"speed": 800,
							"infinite": false
					}
					}
				]
			},
			settingsGig: {
				"slidesToShow": 4,
				"slidesToScroll": 4,
				"speed": 800,
				"infinite": false,
				// responsive: [
				// 	{
				// 	breakpoint: 768,
				// 	settings: {
				// 		"slidesToShow": 1,
				// 			"slidesToScroll": 1,
				// 			"speed": 800,
				// 			"infinite": false
				// 	}
				// 	}
				// ]
				 responsive: [
					{
					// breakpoint: 768,
					// settings: {
					// 	"slidesToShow": 1,
					// 		"slidesToScroll": 1,
					// 		"speed": 800,
					// 		"infinite": false,
                    //         "dots":true,
                    //         "arrows":false
					//     }
                    "breakpoint": 998,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 1000,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 768,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "initialSlide": 1,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "dots": false,
                                "arrow":true
                            }

					}
				]
			},
			settingsHomeBlog: {
				"slidesToShow": 3,
				"slidesToScroll": 3,
				"speed": 800,
				"infinite": false,
				 responsive: [
					{
					// breakpoint: 768,
					// settings: {
					// 	"slidesToShow": 1,
					// 		"slidesToScroll": 1,
					// 		"speed": 800,
					// 		"infinite": false,
                    //         "dots":true,
                    //         "arrows":false
					//     }
                    "breakpoint": 998,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 1000,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 768,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "initialSlide": 1,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "dots": false,
                                "arrow":true
                            }

					}
				]
			},
        }
    },
	components: {
        VueSlickCarousel,
        Carousel,
        Slide,
		Multiselect
    },
	filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },

            dateTimeAgo: function(value) {
                if (!value) return ''
                const dateTimeAgo = moment(value).fromNow();
                return dateTimeAgo
            },


			customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
			momentDate: function(date) {
				return moment(date).format("DD MMM YYYY")
			},
			momentTime: function(date) {
				return moment(date, "HH:mm:ss").format("hh:mm A")
			},
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
    },
	methods:{
		getBlogData(){
          this.$store.dispatch(POST_API, {
            data:{ keyword: this.filter },
            api:'/api/blog-data'
          })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    return [];
                }
                else {
                  this.featured_post = this.$store.getters.getResults.featured_post;
                  this.recent_post = this.$store.getters.getResults.recent_post;
                  this.trending_post = this.$store.getters.getResults.trending_post;
                }
            });
        },
        blogDetail(event,id,title){
          if (event) {
              event.stopPropagation()
          }
          var titleNew = title.replace(/ /g, "-");
          window.location.replace('/blog/'+btoa(id)+ '/' +titleNew);
		},
		// getDropdownListData(ev) {

        //         if(ev){
        //             return this.$store.dispatch(POST_API, {
        //                 data:{
		// 					table:'service_categories'
        //             },
        //             api:'/api/dropdown-list-category'
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 this.showAlert();
        //                 return [];
        //             } else {
        //                 this.options = this.$store.getters.getResults.data;
        //             }
        //         });
        //         }else{
        //                 this.options = [];
        //         }

        //     },

		promoDetail(id,event){

                if (event) {
                event.stopPropagation()
                }
                 window.location.replace('/promotion-detail/'+ (id));

            },
			getDataDropDown(ev){

                console.log(ev)
            },
		// submit() {
        //         this.$store.dispatch(POST_API, {

        //             data:{
        //                 location: this.location_id,
        //                 type: this.type,
        //                 keyword: this.keyword,
        //                 category: this.category_id,
        //                 salary: this.value,
        //                 daily: this.daily ? true: false,
        //                 tagArr: this.tagArr,
        //                 full_time: this.full_time ? true:false,
        //                 job_type_id:this.role_type_id,
        //                 page: this.currentPage,
        //                 rowsPerPage:this.form.rowsPerPage,
        //             },
        //             api:'/api/all-jobs-listing'
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 this.showAlert();
        //                 return [];
        //             } else {
        //                 // this.location_id = this.$store.getters.getResults.pager.location;
        //                 this.jobData = this.$store.getters.getResults.pageOfItems;
        //                 this.totalRows = this.$store.getters.getResults.pager.totalItems;
        //                 this.perPage = this.$store.getters.getResults.pager.pageSize;
        //                 this.from = this.$store.getters.getResults.pager.from;
        //                 this.to = this.$store.getters.getResults.pager.to;
        //                 // return this.jobData;
        //             }
        //         });
        //     },
		onChangeJobType(){
			if(this.category_ftdp_job_type =='full_time'){
				this.category_id_V1 = '';
				this.getDropdownData();
			}else{
				this.category_id_V1 = '';
				this.getDropdownDataV1();
			}
		},
		getDropdownData(){
				// if(event){
					this.$store.dispatch(POST_API, {
						data:{
							table:'service_categories',
                        	category_id_string:''
						},
						api:'/api/webCategory'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;

							this.$swal({
								position: 'center',
								icon: 'error',
								title: this.error_message,
								showConfirmButton: false,
								timer: 1500
							});

						} else {
							this.categoriesV1 = this.$store.getters.getResults.data;
						}
                	});
				// }else{
                //     this.categories = [];
                // }

			},
			getDropdownDataV1(){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/dropdown-list-category-v1'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.categoriesV1 = this.$store.getters.getResults.data;

                    }
                });
            },
			// getLocation(){
            //     this.$store.dispatch(POST_API, {
            //         data:{
            //         },
            //         api:'/api/webLocation'
            //     })
            //     .then(() => {
            //         if (this.$store.getters.containsErrors) {
            //             this.error_message = this.$store.getters.getErrors;

            //             this.$swal({
            //                 position: 'center',
            //                 icon: 'error',
            //                 title: this.error_message,
            //                 showConfirmButton: false,
            //                 timer: 1500
            //             });

            //         } else {
            //             this.locations = this.$store.getters.getResults.data;
            //             this.locations.forEach(element => {
            //                 if(element.location_names){
            //                     element.location_names_val = element.location_names.split(',');
            //                     element.location_names_id = element.location_ids.split(',');
            //                 }
            //             });
            //         }
            //     });
            // },

			getLocation(){
				// if(event){
					this.$store.dispatch(POST_API, {
						data:{
						},
						api:'/api/webLocation'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;

							this.$swal({
								position: 'center',
								icon: 'error',
								title: this.error_message,
								showConfirmButton: false,
								timer: 1500
							});

						} else {
							this.locations = this.$store.getters.getResults.data;
							this.locations.forEach(element => {
								if(element.location_names){
									element.location_names_val = element.location_names.split(',');
									element.location_names_id = element.location_ids.split(',');
								}
							});
						}
					});
				// }else{
				// 	this.locations = [];
				// }
            },
			getOpacity(){
                 this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/banner-opacity-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.banner_opacity = this.$store.getters.getResults.banner_opacity[0].option_value;
                    }
            	})

            },

		getjobRoleType(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/job-role-type'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.role_types = this.$store.getters.getResults.data;
                    }
                });
            },
			getAllJobOnSearchDPFT(event,category_id_V1,job_title_V1){
				if(this.category_ftdp_job_type =='full_time'){
					this.getAllJobOnSearch(event,'all-role-jobs',category_id_V1,job_title_V1,'all-location-jobs');
				}else{
					this.getAllJobOnSearchDP(event,category_id_V1,job_title_V1);
				}
			},
			getAllJobOnSearchDP(event,category_id_V1,job_title_V1){
				if (event) {
                event.stopPropagation()
                }
				console.log(category_id_V1,job_title_V1)

				// if(category_id.id){
				// 	category_id = category_id.id;
				// }else{
				// 	category_id = 'YWxs'
				// 	category_id = 'all'
				// }
				// if(location_id.id){
				// 	location_id = location_id.id;
				// }else{
				// 	location_id = 'all'
				// }
				category_id_V1 = category_id_V1 ? category_id_V1: 'all-categories-jobs';
				// company_id_V1 = company_id_V1 ? company_id_V1: 'all-companies-jobs';
				var company_id_V1 = 'all-companies-jobs';


				job_title_V1 = job_title_V1 ? job_title_V1.split(' ').join('-'): '';
				var allSearchDataV1 = {
					category_id_V1: category_id_V1,
					company_id_V1: company_id_V1,
					keyword_V1:job_title_V1,
				};

				localStorage.setItem('allSearchDataV1', JSON.stringify(allSearchDataV1))
                window.location.replace('/singapore-daily-paid-jobs/'+category_id_V1+'/'+company_id_V1+'/'+job_title_V1);
                // window.location.replace('/all-jobs-listing');
				// this.$router.push({ name: 'allDailyPaidJoblisting' })

			},
			getAllJobOnSearch(event,role_type_id,category_id,job_title,location_id){
				if (event) {
                event.stopPropagation()
                }
				console.log(role_type_id,category_id,job_title,location_id,location_id)
				role_type_id = role_type_id ? role_type_id: 'all-role-jobs';
				category_id = category_id ? category_id: 'all-categories-jobs';
				// company_id = company_id ? company_id: 'all-companies-jobs';
				var company_id = 'all-companies-jobs';

				job_title = job_title ? job_title.split(' ').join('-'): 'all-title-jobs';
				location_id = location_id ? location_id: 'all-location-jobs';
				var allSearchData = {
					// role_type_id: role_type_id,
					category_id: category_id,
					company_id: company_id,
					keyword:job_title.split(' ').join('-'),
					// location_id:location_id
				};

				localStorage.setItem('allSearchData', JSON.stringify(allSearchData))
                window.location.replace('/singapore-full-time-jobs/'+role_type_id+'/'+category_id+'/'+company_id+'/'+location_id+'/'+job_title);

				// this.$router.push({ name: 'allJobsListing' })

			},

		getWebsiteData() {
            return this.$store.dispatch(POST_API, {
				data:{
                    keyword: this.filter,
                },
                api: '/api/website-data'
            })
            .then(() => {
				//window.scrollTo(0,0);
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
					//this.role_types = this.$store.getters.getResults.data;
                    this.banners = this.$store.getters.getResults.banners;
                    this.job_categories = this.$store.getters.getResults.job_categories;
                    this.process_descriptions = this.$store.getters.getResults.process_descriptions;
                    this.partners = this.$store.getters.getResults.partners;
                    //this.promotions = this.$store.getters.getResults.promotions;
                    this.jobs_count = this.$store.getters.getResults.jobs;
                    this.gigs = this.$store.getters.getResults.gigs;
                    this.dp_jobs_count = this.$store.getters.getResults.dp_jobs_count;
					this.ft_jobs_count = this.$store.getters.getResults.ft_jobs_count;
					this.companies_count = this.$store.getters.getResults.companies_count;
                    this.users_count = this.$store.getters.getResults.users_count;

                    this.dailyjob_datas = this.$store.getters.getResults.dailyjob_datas;
                    this.dailyjob_datas_count = this.$store.getters.getResults.dailyjob_datas_count;

                    this.testimonial = this.$store.getters.getResults.testimonial;

					// this.jobs.forEach(element => {
					// 	if(element.tag_names){
					// 		element.tag_names_val = element.tag_names.split(',');
					// 	}
					// });
						console.log(this.jobs);


                }
            });
        },
		// getJobData() {
        //     return this.$store.dispatch(POST_API, {
		// 		data:{
        //             keyword: this.filter,
        //         },
        //         api: '/api/job-data'
        //     })
        //     .then(() => {
        //         if (this.$store.getters.containsErrors) {
        //             this.error_message = this.$store.getters.getErrors;
        //             // this.showAlert();
        //             return [];
        //         } else {
        //             this.jobs = this.$store.getters.getResults.jobs;

        //         }
        //     });
        // },
		        jobDetail(id,event){
				window.scrollTo(0,0);
                if (event) {
                event.stopPropagation()
                }
               // window.location.replace('/all-jobs-detail/'+id);
				this.$router.push({name: 'allJobsDetail', params: { id: id }})


            },
			dailyPaidJobDetail(id,event){

                if (event) {
                event.stopPropagation()
                }
                 window.location.replace('/dp-jobs-detail/'+ (id));

            },
	},

	mounted(){
		this.getBlogData();
		window.scrollTo(0,0);
        this.getWebsiteData();
		this.getjobRoleType();
		this.getDropdownDataV1();
		// this.getDropdownData();
		this.getLocation();
		this.getOpacity();
		$(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
		// this.getDropdownListData();
		// this.getJobData();
		//document.title = "EL Connect - Home";
    }
}


</script>
