<template>
    <div class="animated fadeIn">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Holiday</h1>
            </div> -->
             <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Holiday</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="input-group-8" label="Transaction Type" class="required">
                                        <div class="custom-dropdown">
                                            <b-form-select v-model="form.transaction_type">
                                                <option value="" disabled>Select Transaction</option>
                                                <option :value="transaction" v-for="transaction in transactions">{{transaction | capitalize}}</option>
                                            </b-form-select>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="title-group" label="Title" class="required">
                                        <b-form-input id="title" v-model="form.title" type="text" placeholder="Enter Title.."></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'TransactionSubTypes'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import 'cropperjs/dist/cropper.css';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";


export default {

    data() {
        return {
            form: {
                title: '',
                transaction_type:'',
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            
            delta: undefined,
            webUrl: process.env.VUE_APP_URL,
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            noClose:true,
            transactions:[]
    
        }
    },
    components: {
        Datepicker
    },
    filters: {
        capitalize: function(value) {

            var i = '';

            if(value != null){

                var v = value.replace('_', ' ');
                v = v.toString()
                i = v.charAt(0).toUpperCase() + v.slice(1)
                return i;

            }else{
                
                return value;
            }

        },
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'transaction-sub-type-list'},
                text: 'transaction Sub Types',
            },{
                to:null,
                text: 'Edit transaction Sub Type'
            }];
            return item;
        },
        customFormatterTwo(date) {
            console.log(date)
            return moment(date).format('DD-MM-YYYY');
        },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                    },
                    api: '/api/transaction-sub-type-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.title = this.$store.getters.getResults.data.title;
                        this.form.transaction_type = this.$store.getters.getResults.data.transaction_type;
                    }
                });
        },
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        title: this.form.title,
                        transaction_type:this.form.transaction_type,
                        items:this.form,
                    },
                    api: "/api/transaction-sub-type-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Transaction Sub Type Updated successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'TransactionSubTypes' });
            });
        },
        getTransactions() {
            return this.$store.dispatch(POST_API, {
                    api: '/api/transaction-types-master'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.transactions = this.$store.getters.getResults.data;
                        return this.transactions;
                    }
            });
        },
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Database - Edit TransactionSubTypes";
        this.getEditData();
        this.getTransactions();

    },
};

</script>
