var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg_img_top static_page_bg",style:({
      backgroundImage:
        _vm.terms_and_conditions_ptUser.image &&
        _vm.terms_and_conditions_ptUser.show_banner_image == 'yes'
          ? 'url(' + _vm.terms_and_conditions_ptUser.image + ')'
          : 'url(' + _vm.webUrl + 'assets/images/about-banner.png)',
    })},[_c('div',{staticClass:"full_gragient"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h1',{staticClass:"text-center faq_haed"},[_vm._v(_vm._s(_vm.terms_and_conditions_ptUser.title))])])])])])]),_c('div',{staticClass:"margin-top-50 mobile_tab_margin_top_35"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 common_block common_desc"},[_c('div',{staticClass:"selected_faq_radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.x),expression:"x"}],attrs:{"type":"radio","value":"terms_jobseeker"},domProps:{"checked":_vm._q(_vm.x,"terms_jobseeker")},on:{"change":function($event){_vm.x="terms_jobseeker"}}}),_c('span',{staticClass:"first_span_radio"},[_vm._v("Job Seekers")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.x),expression:"x"}],attrs:{"type":"radio","value":"terms_hirers"},domProps:{"checked":_vm._q(_vm.x,"terms_hirers")},on:{"change":function($event){_vm.x="terms_hirers"}}}),_c('span',[_vm._v("Employers")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.x === 'terms_jobseeker'),expression:"x === 'terms_jobseeker'"}]},[_vm._m(0),_c('p',{staticClass:"rmp_p",domProps:{"innerHTML":_vm._s(_vm.terms_and_conditions_ptUser.description)}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.x === 'terms_hirers'),expression:"x === 'terms_hirers'"}]},[_vm._m(1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.terms_and_conditions_supervisor.description)}})])])])]),_c('div',{staticClass:"margin-top-50 mobile_tab_margin_top_35"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-head mb-3 text-center"},[_c('h4',{staticClass:"golden-text"},[_c('b',[_vm._v("Job Seekers")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-head mb-3 text-center emp_top"},[_c('h4',{staticClass:"golden-text mt-3"},[_c('b',[_vm._v("Employers")])])])
}]

export { render, staticRenderFns }