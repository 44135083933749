
<template>


        
    <div>
        
        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->

             <div class="col-xl-12">
                        <div class="dashboard-box margin-top-0">

                            <!-- Headline -->
                            <div class="headline">
                                <h3><i class="icon-material-outline-account-circle"></i> EL Coins</h3>
                            </div>


                            <div class="headline">
                                <h4>Total EL Coins : </h4>  {{coins[0].total_coins}}
                            </div>

                            <div class="headline" v-if="remaining > 0">
                                <h4>Remaining coins to reach next tier: </h4>  {{remaining}}
                            </div>

                            
                        </div>
                             <a  href="#" @click="nextStep" class="button big ripple-effect margin-top-40 margin-bottom-65">Purchase More Coins</a>

                </div>
                <!-- <a  v-if="page  > 1" href="#" @click="back" class="button big ripple-effect margin-top-40 margin-bottom-65" style="
                    color: black;
                    background-color: #bebbbb;
                    margin-right: 20px;">Back</a>

                <a  v-if="page  < 3" href="#" @click="nextStep" class="button big ripple-effect margin-top-40 margin-bottom-65">Next</a>

                <a  v-if="page  == 3" href="#" @click="makePayment()" class="button big ripple-effect margin-top-40 margin-bottom-65">Proceed Payment</a> -->

    </div>

</template>

<script>
  import { POST_API } from "../../../store/actions.type"

  export default {
  data: () => {
    return {
                coins:0,
                remaining : 0,
            }
        },
  methods: {
     nextStep(){
         window.location.replace('/v2/jobseeker/elcoins');

     },

     back(){
      this.page = this.page-1;
     },

     getElCoins(){
           this.$store.dispatch(POST_API, {
                    data:{
                        user_id:this.user_id
                    },
                    api:'/api/userCoins'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                                
                    } else {
                        this.coins = this.$store.getters.getResults.data;

                        if(this.coins[0].total_coins < 2000){

                            this.remaining = 1000 - this.coins[0].total_coins;
                        }else if(this.coins[0].total_coins >= 2000 && this.coins[0].total_coins < 5000){

                            this.remaining = 5000 - this.coins[0].total_coins;

                        }else if(this.coins[0].total_coins >= 5000 && this.coins[0].total_coins < 10000){

                            this.remaining = 10000 - this.coins[0].total_coins;

                        }
                    
                    }       
                }); 
     },

      getElCoinsById(){

           this.$store.dispatch(POST_API, {
                    data:{
                        id: this.coinData
                    },
                    api:'/api/elCoinsById'
                })
                .then(() => {

                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                                
                    } else {
                        this.elcoinDatabyid = this.$store.getters.getResults.data;

                         const percent = (this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100;

                         this.total_amount = percent + parseInt(this.elcoinDatabyid[0].value_in_dollar);
                    
                    }       
                }); 
     },

      showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
      },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            });
        },
     
    },

     mounted(){
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.email = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            this.getElCoins();
     }
    
    }
</script>
