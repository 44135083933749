<template>
  <div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->

    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <!-- Hedaline -->
        <!-- <div class="dashboard-headline">
                    <h1>Edit Billing Address</h1>
               </div> -->
        <div id="breadcrumb-v2" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb :items="breadCrumb()" />
          </div>
        </div>

        <!-- Billing Cycle Radios  -->
        <div class="billing-cycle desktop_display_block">
          <!-- Radio -->

          <div class="content">
            <ul class="fields-ul chek_page second_indiv_step_wrap">
              <li class="common_input_field desktop_padding_top_zero">
                <div class="row">
                  <div></div>
                  <div class="col-xl-6">
                    <div class="submit-field desktop_margin_bottom_zero error_submit_field">
                      <h5>Department Type <span class="required_star">*</span></h5>
                      <b-form-select
                        data-size="7"
                        v-model="department_type"
                        class="custom-select web_bottom_zero required-field"
                        id="department_type"
                      >
                        <option value="" disabled>Select</option>
                        <option value="job">Job</option>
                        <option value="other">Other</option>
                      </b-form-select>
                    </div>
                    <!-- <div class="show_span"> -->
                    <span class="error_x_white desktop_margin_bottom_zero"
                      >Please select Department Type
                    </span>
                    <!-- </div> -->
                  </div>
                  <div class="col-xl-6">
                    <div class="submit-field error_submit_field">
                      <h5>Department Name</h5>
                      <b-form-input
                        type="text"
                        v-model="department_name"
                        id="department_name"
                        class="input-text with-border required-field"
                        name="department_name"
                        placeholder=""
                        value=""
                      />
                    </div>
                    <span class="error_x_white">Please enter Department Name </span>
                  </div>

                  <div class="col-xl-12">
                    <div v-for="(app, index) in appendExp" :key="index">
                      <div class="row">
                        <div class="col-xl-5">
                          <div class="submit-field error_submit_field">
                            <h5>Name <span class="required_star">*</span></h5>
                            <b-form-input
                              type="text"
                              v-model="app.name"
                              id="full_name"
                              class="input-text with-border"
                              name="name"
                              placeholder=""
                              value=""
                            />
                          </div>
                          <span class="error_x_white">Please enter Name </span>
                        </div>
                        <div class="col-xl-5">
                          <div class="submit-field">
                            <h5>Email <span class="required_star">*</span></h5>
                            <input type="text" class="with-border" v-model="app.email" />
                          </div>
                        </div>

                        <div
                          class="jb5 padding_right_993 col-md-2 max_width_and_flex_769 mobile_max_width_and_flex eleven_zero_one_max_width_and_flex mobile_max_width_and_flex"
                          v-if="index != 0"
                        >
                          <div class="submit-field">
                            <h5 class="common_opacity_zero">Email</h5>
                            <a
                              href="javascript:void(0);"
                              class="button margin_top_zero_993 ripple-effect mobile_anchore_set common_vertical_align_bottom"
                              style="background-color: red"
                              @click="removeExperience(index)"
                              ><span class="add_plus">x</span>Remove</a
                            >
                          </div>
                        </div>
                        <div
                          class="jb6 max_width_and_flex_993 col-md-2 eleven_zero_one_max_width_and_flex big_screen_max_width_and_flex"
                          v-if="index == 0"
                          @click="addExperience"
                        >
                          <div class="submit-field">
                            <h5 class="common_opacity_zero">Email</h5>
                            <a
                              href="javascript:void(0);"
                              class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom"
                              id="add_more"
                              :disabled="addDisabled"
                              ><span class="add_plus">+</span> Add More</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- Radio -->
        </div>
      </div>

      <div class="col-xl-12 post_jb_btn">
        <a
          @click="submit"
          class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn"
          style="background: #4c8bf5 !important"
          >Submit</a
        >
        <a
          @click="$router.go(-1)"
          class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn"
          style="background: black !important"
          >Cancel</a
        >
      </div>
      <!-- <div class="col-xl-12">
                    <a @click="submit" class="button ripple-effect big margin-top-30 employee_btn mobile_last_btn_mrgn">Submit</a>
                </div> -->
      <!-- Summary -->
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";

export default {
  data: () => {
    return {
      formPosition: 0,
      page: 1,
      coins: "",
      coinData: "",
      packageData: "",
      total_amount: 0,
      loading: false,
      department_type: "",
      department_name: "",
      email: "",
      name: "",
      blockRemovalExperience: true,
      appendExp: [],
      addDisabled: false,
    };
  },
  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "employer-list" },
          text: "Employers",
        },
        {
          to: null,
          text: "Edit Company Department",
        },
      ];
      return item;
    },
    nextStep() {
      this.page = this.page + 1;
    },

    back() {
      this.page = this.page - 1;
    },

    addExperience() {
      if (this.appendExp.length >= 3) {
        $("#add_more").attr("disabled", true);
      } else {
        this.appendExp.push({
          name: "",
          email: "",
        });
      }
    },

    removeExperience(lineId) {
      if (this.blockRemovalExperience) {
        this.appendExp.splice(lineId, 1);
      }
    },

    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    submit() {
      var err = false;
      var erremail = false;

      var emailarr = [];

      // if(this.appendExp.length > 0){

      //     this.appendExp.forEach(function(item,index){
      //     emailarr.push(item.email);
      //     })

      // }

      this.email = emailarr.toString();
      $(".required-field").each(function () {
        if ($(this).val() == "" || ($(this).val() && $(this).val().trim().length === 0)) {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      // var emailFormat = "[a-z0-9]+@[a-z]+\.[a-z]{2,3}$";
      // var emailFormat = "[a-zA-Z0-9._-]+@[0-9a-zA-Z-_.]+\.[a-zA-Z]{2,3}$";

      //     if(err == false){

      //     const hasDuplicates = (arr) => arr.length !== new Set(arr).size;

      //     for (const email of emailarr) {

      //      if(email != '' && !email.match(emailFormat)){

      //         erremail = true;

      //          this.$swal({
      //             position: 'center',
      //             icon: 'error',
      //             title: 'Invalid email',
      //             showConfirmButton: false,
      //             timer: 1500
      //     })
      //      }

      //    }

      // if(erremail == false){
      //    if(hasDuplicates(emailarr)){
      //         erremail = true;

      //          this.$swal({
      //             position: 'center',
      //             icon: 'error',
      //             title: 'Duplicate email value exist',
      //             showConfirmButton: false,
      //             timer: 1500
      //         })

      //     }
      // }
      // }

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              id: atob(this.$route.params.id),
              department_name: this.department_name,
              type: this.department_type,
              // email:this.email,
              name_array: this.appendExp,
              company_id: atob(this.$route.params.company_id),
            },
            api: "/api/update-company-department",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              // var message = 'Job Bookmarked successfully.'
              var message = this.$store.getters.getResults.message;
              this.showSuccessAlert(message);
              this.loading = false;
              //$router.go(-1)
              window.location.replace(
                "/v2/admin/company-department/" +
                  this.$route.params.user_id +
                  "/" +
                  this.$route.params.company_id
              );
            }
          });
      }
    },

    onChange(id) {
      (this.packageData = id), (this.coinData = id), this.getElCoinsById();
    },

    show(id) {
      if (id == "card") {
        $("#cardDialogue").addClass("payment-tab-active");
        $("#paypalData").removeClass("payment-tab-active");
      } else {
        $("#paypalData").addClass("payment-tab-active");
        $("#cardDialogue").removeClass("payment-tab-active");
      }
    },

    getSettings() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/settings",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.settings = this.$store.getters.getResults.data;
          }
        });
    },

    getBillingById() {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: atob(this.$route.params.id),
          },
          api: "/api/edit-company-department",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.departmentdata = this.$store.getters.getResults.data;
            var appendExp = this.appendExp;
            console.log(this.departmentdata, "this.departmentdata");
            if (this.departmentdata.name1 != null) {
              appendExp.push({
                name: this.departmentdata.name1,
                email: this.departmentdata.email1,
              });
            }
            if (this.departmentdata.name2 != null) {
              appendExp.push({
                name: this.departmentdata.name2,
                email: this.departmentdata.email2,
              });
            }
            if (this.departmentdata.name3 != null) {
              appendExp.push({
                name: this.departmentdata.name3,
                email: this.departmentdata.email3,
              });
            }
            console.log(appendExp, "appendExp");

            this.department_name = this.departmentdata.department_name;
            this.department_type = this.departmentdata.type;
          }
        });
    },
  },

  mounted() {
    //document.title = "EL Connect - Employer - Add Billing Address";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.email =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : "";

    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    this.getSettings();
    this.getBillingById();
  },
};
</script>
