<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center">
            <h1 class="mr-auto header_detail_new_h1">
                <b-link class="back-arrow" :to="{name:'faq-list'}"></b-link>Edit FAQ
            </h1>
        </div> -->
        <!-- <div class="dashboard-headline">
            <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit FAQ</h1>
        </div> -->
         <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
        </div>
        <!-- <div class="border-radius-4 white-bg p-lg-4 p-3 edit_genneral_stng_dsk faq_last"> -->
        <div class="border-radius-4 white-bg common_padding_30 commmon_label_heading">
            <b-form @submit="formSubmit">
                <div class="edit-form-box form-box-field desktop_margin_top_zero">
                    <b-form-group id="input-group-8" label="FAQ Type" class="required">
                      <div class="custom-dropdown">
                          <b-form-select v-model="form.faq_type">
                              <option  v-for="faq_type in faq_type_list">{{faq_type}}</option>
                          </b-form-select>
                      </div>
                    </b-form-group>
                    <b-form-group label="Question" class="required">
                        <b-form-textarea id="question" type="text" v-model="form.question" placeholder="Enter Question ..">
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Answer" class="required">
                        <b-form-textarea id="answer" v-model="form.answer" type="text" placeholder="Enter Answer ..">
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group label="URL" class="required">
                        <b-form-input id="url" name="url" type="text" placeholder="Enter URL.." v-model="form.url">
                        </b-form-input>
                    </b-form-group>
                    <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                        <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                        <b-button :to="{name:'faq-list'}" variant="light">Cancel</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import Vue from 'vue';
export default {

    data() {
        return {
            form: {
                question: '',
                answer: '',
                url:'',
            },
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            faq_type_list:['Select FAQ Type','Employer','Employee'],
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
        }
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'faq-list'},
                text: 'FAQs',
            },{
                to:null,
                text: 'Edit FAQ'
            }];
            return item;
        },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        status: this.status,
                        page: this.currentPage,
                        keyword: this.filter,
                        id: this.id,
                    },
                    api: '/api/faq-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.question = this.$store.getters.getResults.data.question;
                        this.form.answer = this.$store.getters.getResults.data.answer;
                        this.form.url = this.$store.getters.getResults.data.url;
                        this.form.faq_type = this.$store.getters.getResults.data.faq_type == 'Employee' ? this.form.faq_type = 'Employee' : this.form.faq_type ='Employer';
                        return this.items;
                    }
                });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        question: this.form.question,
                        answer: this.form.answer,
                        url: this.form.url,
                        faq_type: this.form.faq_type == 'Select FAQ Type' ? '' : this.form.faq_type,

                    },
                    api: "/api/faq-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
        },
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'FAQ Updated successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'faq-list' });
            });
        },
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Website Contents - Edit FAQ";
        this.getEditData();
    }
};

</script>
