import employer2 from '../components/website/employer2/Employer.vue';

export default[
    
    {
        path: '/employer',
        name: 'employer2',
        component: employer2,
        meta: { requiresAuth: false, hideForAuth:true , showFor:'admin'}
    },

];