<template>
	<div >
        <div>
            <!-- Header Section HTML -->
            <EmployerHeader />
            <!-- /.Header Section HTML -->
        </div>

        
        <!-- Banner Section HTML -->
        <router-view></router-view>
        
    
        <div >
            <!-- Footer Section HTML -->
            <EmployerFooter />
            <!-- /.Footer Section HTML -->
        </div>
    </div>
</template>
<script>
import EmployerHeader from '../employer/EmployerHeader.vue';
import EmployerFooter from '../employer/EmployerFooter.vue';

export default {
    name: 'EmployerNavbarContainer',
    components: {
        EmployerHeader,
        EmployerFooter,
    },
    mounted(){
            
        $("#jobseeker-btn").on('click', function(){
            window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
        });
    }
}
</script>
<style lang="scss">

</style>
