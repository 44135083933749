export const SET_AUTH = "setUser"
export const RESET_AUTH = "logout"
export const SET_ERROR = "setError"
export const CLEAR_ERRORS = "clearErrors"
export const SET_RESULT_DATA = "setResultData"
export const CLEAR_RESULT_DATA = "clearResultData"
export const UPDATE_USER_INFO = "updateUserInfo"
export const CHAT_DATA = "chatData"
export const SELECTED_JOB = "selectedJob"
export const SELECTED_USER = "selectedUser"
export const CURRENT_SECTION = "currentSection"
export const SET_PERMISSION_DATA = "setPermissionData"
