<template>
    <div>
    <div>
		<div class="dashboard-content-inner my_custom_dash desktop_padding_top_zero tab_padding_left_right_zero" >
			
			<!-- Dashboard Headline -->
			<div class="dashboard-headline">
				<h1>Drafted Job Ads</h1>

				<!-- Breadcrumbs -->
				<nav id="breadcrumbs" class="dark employer_nav">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Job</a></li>
						<li>Drafted Job Ads</li>
					</ul>
				</nav>
			</div>
	
			<!-- Row -->
			<!-- <div class="row emplpyees_manage_job_emp_row"> -->
            <div class="row">

				<!-- Dashboard Box -->
				<!-- <div class="col-xl-12 mobile_left_right_zero_pdng"> -->
                <div class="col-xl-12">
					<div class="dashboard-box margin-top-0">

						<!-- Headline -->
						<div class="headline">
							<h3><i class="icon-material-outline-business-center"></i> My Drafted Job Ads</h3>
						</div>

						<div class="content" v-if="jobData.length > 0">
							<ul class="dashboard-box-list">
								<li v-for="jobDatas in jobData" :current-page="currentPage" :per-page="perPage">
									<!-- Job Listing -->
									<div class="job-listing" >

										<!-- Job Listing Details -->
										<div class="job-listing-details">

											<!-- Logo -->
<!-- 											<a href="#" class="job-listing-company-logo">
												<img src="images/company-logo-05.png" alt="company_logo">
											</a> -->

											<!-- Details -->
											<div class="job-listing-description">
												<!-- <span class="job-listing-title"><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                                <span class="job-listing-title" ><b>{{jobDatas.title | capitalize}}</b></span> 
                                                <h3 class="job-listing-title new_all_job_title mobile_margin_top_15" v-if="jobDatas.description" style="margin-bottom: 15px; margin-left: 20px;">
                                                    <!-- <p v-if="jobDatas.description.length >= 250" v-html="jobDatas.description.substring(0,250)+'...' "></p>
                                                    <p v-else v-html="jobDatas.description"></p> -->
                                                </h3>
												<!-- Job Listing Footer -->
												<div class="job-listing-footer">
                                                    <ul class="fullTimeJobdata new_full_time_data">
                                                        <li>
                                                            <span v-if="jobDatas.start_date != null"><i class="icon-material-outline-access-time"></i>{{jobDatas.start_date | customFormatterOne}} </span><span v-if="jobDatas.end_date != null"> - {{jobDatas.end_date | customFormatterOne}}</span>
                                                            <!-- <br v-if="jobDatas.end_date != null"> -->
                                                            <span v-else><i class="icon-material-outline-access-time"></i> N/A </span>
                                                            
                                                        </li>
                                                    </ul>
                                                    
                                                    <ul class="fullTimeJobdata new_full_time_data">
                                                        <li v-if="jobDatas.service_categories_name">
                                                        <span ><i class="icon-material-outline-business"></i> <span>{{jobDatas.service_categories_name == 'Other' ? jobDatas.other_service_category : jobDatas.service_categories_name | removeUnderscore}}</span></span>
                                                        </li>
                                                        <li class="mobile_jb_hide" v-if="jobDatas.job_type">
                                                            <span ><i class="icon-material-outline-business-center"></i> <span>{{jobDatas.job_type | customizeRoleType | capitalize}}</span></span>
                                                        
                                                        </li>
                                                        <li class="mobile_jb_hide" v-if="jobDatas.level_title">
                                                            <span ><i class="icon-material-outline-face"></i> {{jobDatas.level_title | capitalize}}</span>
                                                        </li>
                                                    </ul>
                                                    <ul class="fullTimeJobdata new_full_time_data">
                                                        <li v-if="jobDatas.actual_posted_by_first_name"> <span > <i class="icon-material-outline-date-range"></i>Posted By: {{jobDatas.actual_posted_by_last_name | capitalize}} {{jobDatas.actual_posted_by_first_name | capitalize}}</span></li>

                                                        <li v-if="jobDatas.posted_date"> <span > <i class="icon-material-outline-date-range"></i>Posted On: {{jobDatas.posted_date | customFormatterposted}}</span></li>

                                                        <li v-if="jobDatas.job_address">
                                                        <span ><i class="icon-material-outline-location-on"></i> <span>{{jobDatas.job_address == 'Other' ? jobDatas.other_location : jobDatas.job_address | capitalize}}</span></span>
                                                        
                                                        </li>

                                                        <li v-if="jobDatas.job_unique_id">
                                                                    <span ><i class="material-icons-outlined job_id_icon">card_travel_outlined</i>Ads ID: <span>{{jobDatas.job_unique_id | capitalize}}</span></span>
                                                            </li>
                                                        <li><span v-if="(jobDatas.min_salary != 0 && jobDatas.max_salary != 0) || jobDatas.min_salary > 0 || jobDatas.max_salary > 0"> <i class="icon-material-outline-local-atm"></i><span v-if="jobDatas.job_type == 'part-time'">Per Hour Rate</span>
                                                        <span v-else></span> ${{jobDatas.min_salary}}-${{jobDatas.max_salary}}</span></li>
                                                    </ul>
													<!-- <ul>
                                                        <li>
                                                            <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Job Duration: {{jobDatas.start_date | customFormatterOne}} </span><span v-if="jobDatas.end_date != null"> - {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                            <span v-if="jobDatas.posted_date"> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span>
                                                            <span v-if="jobDatas.service_categories_name"> <i class="icon-material-outline-date-range"></i>Category <h5>{{jobDatas.service_categories_name == 'Other' ? jobDatas.other_service_category : jobDatas.service_categories_name | removeUnderscore}}</h5></span>
                                                            <span v-if="jobDatas.job_type"> <i class="icon-material-outline-date-range"></i>Role Type <h5>{{jobDatas.job_type | customizeRoleType | capitalize}}</h5></span>
                                                            <span v-if="jobDatas.level_title"> <i class="icon-material-outline-date-range"></i>Position Level {{jobDatas.level_title | capitalize}}</span>
                                                            <span v-if="jobDatas.job_address"> <i class="icon-material-outline-date-range"></i>Location <h5>{{jobDatas.job_address == 'Other' ? jobDatas.other_location : jobDatas.job_address | capitalize}}</h5></span>
                                                            <span v-if="(jobDatas.min_salary != 0 && jobDatas.max_salary != 0) || jobDatas.min_salary > 0 || jobDatas.max_salary > 0"> <i class="icon-material-outline-date-range"></i><span v-if="jobDatas.job_type == 'part-time'">Per Hour Rate</span>
                                                            <span v-else>Salary</span> ${{jobDatas.min_salary}}-${{jobDatas.max_salary}}</span>
                                                            <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                            <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                            <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span>
                                                        </li>
                                                    </ul> -->
                                                    <!-- <ul>
                                                        <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                    </ul> -->
												</div>
											</div>
										</div>
									</div>

									<!-- Buttons -->
									<div class="buttons-to-right always-visible new_other_op">
                                        <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>View Detail </a> -->
                                        <a :href="'/v2/employer/ft-jobdetail/'+ encrypt(jobDatas.jobId)" target=_blank class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>View Detail </a>
										<b-link href="javascript:void(0);" :to="{name: 'editSavedJob', params: {id: encrypt(jobDatas.jobId)}}" class="button gray ripple-effect ico edit-btn-job-list" data-tippy-placement="top"><i class="material-icons-outlined common_overflow_unset">border_color_outlined</i>Edit</b-link>
                                        <a href="javascript:void(0);" @click="deleteJob(jobDatas.jobId)" class="button gray ripple-effect ico remove-btn-job-list" data-tippy-placement="top"><i class="icon-feather-trash-2"></i>Remove</a>
                                    </div>
								</li>

							</ul>
						</div>
                        <div class="content text-center" v-else>
                            <p class="no_record_candidate_padding desktop_margin_bottom_zero"><b>No Record Found</b> </p>
                        </div>
					</div>
				</div>

			</div>
            <!-- Row / End -->
        <!-- <div class="margin-top-70"></div> -->
		</div>
	</div>
    </div>
</template>

<script>
    import { POST_API } from "../../store/actions.type"
    import moment from 'moment';
    import Bus from "../../event-bus";


    export default {

        data() {
            return {
                jobData: '',
                skill:'',
                daily:'',
                full_time:'',
                location:'',
                type:'',
                keyword:'',
                salary:'',
                user_id:'',
                company_id:'',
                error_message:'',
                skillsArr: [],
                tagArr: [],
                value: [0, 15000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                sliderValue: 50,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                 }
            }
        },
        filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        capitalizeAll: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY');
        },

        customFormatterposted(date) {
            return moment(date).utc().format('DD MMM YYYY HH:mm A');
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        customizeRoleType: function(value) {
            if (!value) return ''
            var i, frags = value.split('-');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
    },

        methods:{   
            showAlert() {
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                }).then((result) => {
                    // this.$router.push({ name: 'saved-jobs' });
                });
            },
            jobDetail(id,event){

                if (event) {
                event.stopPropagation()
                }
                //  window.location.replace('/v2/employer/ft-jobdetail/'+ id);
                  window.open('/v2/employer/ft-jobdetail/'+ id, '_blank');

            },
            getjobsData() {
                return this.$store.dispatch(POST_API, {
                        data:{
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                            userId:this.user_id,
                            company_id:this.company_id,
                },
                    api: '/api/savedJobDataForUser'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {

                        this.jobData = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.jobData;
                    }
                });
            },
            
            deleteJob(id){
                this.$swal({
                    title: 'Please Confirm',
                    text: 'Are you sure you want to delete this record?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            },
                            api:'/api/deleteJob',
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                var message='Job Deleted successfully.';
                                this.showSuccessAlert(message);
                                this.getjobsData();
                                Bus.$emit('get_em_job_count');
                                // window.location.replace('/v2/employer/drafted-jobs');
                            }
                        });
                    }
                })
                .catch(err => {
                })
            },

        },
        mounted(){
            //document.title = "EL Connect - Employer - Saved Jobs";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            Bus.$emit('active_users');
            Bus.$emit('get_em_job_count');
            this.getjobsData();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        }
    }
</script>

