<template>
    <div>
       <!--  <b-row class="mt-5 mb-4">
                 <b-col class="col-4 nav_lucky_draw_image">
                    <img src='../../../../../client/public/assets/jobeekerImages/lucky_draw_logo.png'>
                </b-col>
        </b-row> -->
          <b-row class="">
                 <div class="col-12 nav_lucky_draw_image">
                    <img :src="webUrl + 'assets/jobeekerImages/cornerlogo.png'" class="right_logo_jbs elucky_draw_desktop">
                    <img :src="webUrl + 'assets/jobeekerImages/lucky_draw_logo.png'" class="right_logo_jbs_mobile elucky_draw_mobile">
                </div>
        </b-row>
    </div>
</template>

<script>
export default {
  data() {
      return {
          webUrl: process.env.VUE_APP_URL,
          
      }

  },
  components: {
  
  },
  methods: {
      
  },
  mounted() {

  }
}
</script>
<style scoped>
  @import '/assets/css/jobseekers-new.css';
  @import '/assets/css/common-font-family.css';
</style>
