<script>
    import { POST_API } from "../../store/actions.type"

    export default {
       
        mounted(){

            window.location.href = process.env.VUE_APP_URL +'web-emp-profile/view/'+ atob(this.$route.query.emp_id);
        },
    }
</script>
