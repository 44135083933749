<template>
  <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
    <div class="create-new-job-section email-template-section">
      <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Reward</h1 >
            </div> -->
      <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Reward</h1 >
            </div> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <div
        class="edit-profile-section border-radius-4 white-bg  common_padding_30"
      >
        <b-form @submit="formSubmit">
          <div class="edit-form-box desktop_margin_top_zero">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="3" md="3" class="submit-field mobile_max_width_and_flex img_mobile">
                  <div class="upload-form-group">
                    <h5 id="title-group" class="required">
                      Comapny Logo <span class="required_star">*</span>
                    </h5>
                    <b-form-group id="link-group" class="required mb-0 error_submit_field employer_company_logo top_banner_jobs_full new_update_banner profile_top_div">
                      <div
                        class="reward_enquiry_pg single-image-upload  common_pointer_class emp_img_one  mobile_max_width_image"
                        :class="form.companyLogo.image ? 'if_image_exist_reward_enquiry' : 'if_image_not_exist_reward_enquiry'"
                      >
                        <b-form-file
                          class="customUpload form-control-file"
                          name="Image"
                          accept="image/*"
                          @change="onCompanyLogoChange"
                          ref="coverimage"
                          v-model="form.company_logo"
                        ></b-form-file>
                        <!-- <span
                          v-bind:style="
                            form.companyLogo.image
                              ? 'background-color: #fff !important;'
                              : 'background-color:#F2FBFC !important;'
                          "
                        > -->
                        <span>
                          <b-img
                            v-if="form.companyLogo.image"
                            :src="form.companyLogo.image"
                            alt="cover-image"
                            class="actual_img"
                          ></b-img>
                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/company-logo-placeholders.png'"
                            alt="image"
                            class="dummy_img"
                          ></b-img>
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="9" md="9" class="submit-field mobile_max_width_and_flex">
                    <h5 id="title-group" class="required">
                      Company Name <span class="required_star">*</span>
                    </h5>
                    <b-form-input
                      id="title"
                      v-model="form.company_name"
                      type="text"
                      placeholder="Enter Company Name.."
                      class="with-border mb-0"
                    ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" md="6" class="submit-field mobile_max_width_and_flex">
                  <h5 id="title-group" class="required">
                    Title <span class="required_star">*</span>
                  </h5>
                  <b-form-input
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Enter Title.."
                    class="with-border  mb-0"
                  ></b-form-input>
                </b-col>
                <b-col lg="6" md="6" class="submit-field mobile_max_width_and_flex">
                    <h5>Validity Period<span class="required_star">*</span></h5>
                    <b-form-group id="title-group" label="" class="required">
                      <date-range-picker
                        ref="picker"
                        :minDate="minDate"
                        :opens="center"
                        :locale-data="locale"
                        :auto-apply="auto"
                        v-model="form.dateRangeValidity"
                        :ranges="range"
                        class="last_login_date_range_calender common_width_100  manage_job_date_claneder reward_enquiry_calender"
                      >
                        <div slot="input" slot-scope="picker" style="padding: 0px !important">
                          {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                        </div>
                      </date-range-picker>
                      <!-- <span class="input-icon calender_input_icon obtainment_date_calender"
                        ><b-img
                          :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                          alt="Calander Icon"
                        ></b-img
                      ></span> -->
                    </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5 id="title-group" class="required">
                    Description, T&C, Store Location <span class="required_star">*</span>
                  </h5>
                  <ckeditor v-model="form.description" :config="editorConfigDesc"></ckeditor>
                </b-col>
              </b-row>

             
            </div>
            <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
              <b-button type="submit" variant="success" class="desktop_margin_left_zero mr-0"
                >Submit</b-button
              >
              <b-button :to="{ name: 'emp-reward-list' }" variant="light">Cancel</b-button>
            </div>
          </div>
        </b-form>
        <b-modal
          ref="crop-modal-company-logo"
          title="Edit Company Logo"
          hide-footer
          :no-close-on-backdrop="noClose"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
        >
          <b-form @submit="onSubmitCompanyLogo">
            <template>
              <div>
                <div class="content" @change="setCompanyLogo">
                  <section class="cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="0 / 0"
                        :src="tempLogo"
                        preview=".preview"
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="cropCompanyLogoFun"
                  >Crop</b-button
                >
                <b-button
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="reset"
                  >Reset</b-button
                >
              </div>
            </template>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import CKEditor from "ckeditor4-vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";

export default {
  data() {
    return {
      form: {
        title: "",
        description: "",
        company_name: "",
        terms_and_conditions: "",
        address: "",
        claim_conditions: "",

        company_logo: "",
        companyLogo: {
          name: "",
          image: "",
        },

        // addressArr:[],
        addressArr: [{ address: "" }],
        company_id: "",
        user_id: "",
        dateRangeValidity: {
          startDate: moment().format("DD MMM YYYY"),
          endDate: moment().format("DD MMM YYYY"),
        },
      },
      items: null,
      minDate: moment().format("DD MMM YYYY"),
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      range: false,
      date: moment().utc().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      error_message: "",
      success_message: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,

      delta: undefined,
      editorConfig: {
        versionCheck: false,
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
        allowedContent: true,
      },
      editorConfigDesc: {
        versionCheck: false,
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
        allowedContent: true,
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      webUrl: process.env.VUE_APP_URL,
      cropBannerImage: "",
      cropCompanyLogo: "",
      cropQRCodeImage: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      noClose: true,
      disabledFromDate: {
        to: new Date(Date.now() + 8640000),
      },
      addressErrors: [],
      companies: [],
    };
  },
  components: {
    ckeditor: CKEditor.component,
    VueCropper,
    DateRangePicker,
  },
  filters: {
    datePicker1: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },
  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "emp-reward-list" },
          text: "Rewards",
        },
        {
          to: null,
          text: "Reward Enquiry",
        },
      ];
      return item;
    },

    customFormatterOne(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    disabledStartDate() {
      const minStartDate = new Date();
      minStartDate.setDate(minStartDate.getDate() - 1);
      return { to: minStartDate };
    },
    disabledEndDate() {
      if (!this.form.start_date) {
        return { to: new Date() };
      } else {
        var minEndDate = new Date(this.form.start_date);
        minEndDate.setDate(minEndDate.getDate());
        return {
          to: minEndDate,
        };
      }
    },

    cropCompanyLogoFun() {
      this.$refs["crop-modal-company-logo"].hide();
      this.form.companyLogo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },

    setCompanyLogo(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropCompanyLogo = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },

    showCompanyLogo() {
      this.$refs["crop-modal-company-logo"].show();
    },

    onSubmitCompanyLogo(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-company-logo"].hide();
      this.$refs["crop-modal-company-logo"].refresh();
    },

    onCompanyLogoChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.company_logo = input.files[0];
        if (
          this.form.company_logo.type != "image/jpeg" &&
          this.form.company_logo.type != "image/jpg"
        ) {
          this.form.companyLogo.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .jpg or .jpeg image.";
          this.form.companyLogo.image = "";
          this.showAlert();
        } else if (this.form.company_logo.size > 5242880) {
          this.form.companyLogo.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.companyLogo.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.companyLogo.name = this.form.company_logo.name;
            this.showCompanyLogo();
            event.target.value = "";
          };
        }
      }
    },

    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "emp-reward-list" });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    formSubmit(evt) {
      evt.preventDefault();
      var err = false;
      if (
        err == false &&
        (this.form.dateRangeValidity.startDate == "Invalid date" ||
          this.form.dateRangeValidity.startDate == null ||
          this.form.dateRangeValidity.startDate == "")
      ) {
        err = true;
        this.error_message = "Please select start date";
        this.showAlert();
      }
      if (
        err == false &&
        this.form.dateRangeValidity.endDate &&
        this.form.dateRangeValidity.endDate != "Invalid date" &&
        (this.form.dateRangeValidity.startDate == "Invalid date" ||
          this.form.dateRangeValidity.startDate == null ||
          this.form.dateRangeValidity.startDate == "")
      ) {
        err = true;
        this.error_message = "Please select start date";
        this.showAlert();
      }
      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              id: this.id,
              title: this.form.title,
              description: this.form.description,
              expiry_date: this.form.expiry_date,
              company_name: this.form.company_name,
              start_date:
                moment(this.form.dateRangeValidity.startDate).format("YYYY-MM-DD") == "Invalid date"
                  ? null
                  : moment(this.form.dateRangeValidity.startDate).format("YYYY-MM-DD"),
              end_date:
                moment(this.form.dateRangeValidity.endDate).format("YYYY-MM-DD") == "Invalid date"
                  ? null
                  : moment(this.form.dateRangeValidity.endDate).format("YYYY-MM-DD"),
              company_logo: this.form.companyLogo.image,
              // company_id:
              //   this.form.company_id && this.form.company_id.id ? this.form.company_id.id : "",
              // company_id: this.form.company_id.length > 0 ? this.form.company_id[0].company_id : this.form.company_id,
              company_id: this.form.company_id,
              user_id: this.form.user_id,
              items: this.form,
            },
            api: "/api/emp-reward-enquiry",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              var message = "Enquiry Created successfully.";
              this.showSuccessAlert(message);
            }
          });
      }
    },
  },
  mounted() {
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.form.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    // document.title = "EL Connect - Admin - Website Contents - Edit Reward";
  },
};
</script>

<style>
.submit-field {
  margin-bottom: 1rem;
}
.is-invalid {
  border-color: #dc3545;
}
.invalid-feedback {
  display: block;
  color: #dc3545;
}
</style>
