<style scoped>
#axiosForm{  /* Components Root Element ID */
    position: relative;
}
.loader{  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#eceaea;
    background-image: url('../../assets/Spinner-3.gif');
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
</style>
<template>



    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->



                <div class="row" >
                    <div class="col-xl-12 col-lg-12 content-right-offset">


                <!-- Hedaline -->
                <!-- <h1>Add Card</h1> -->
                <div id="breadcrumb-v2" class="mb-1">
                    <div class="d-flex justify-content-start breadcrumb-wrapper">
                        <b-breadcrumb
                        :items="breadCrumb()"
                        />
                    </div>
                </div>

                <div id="axiosForm">
                    <div class="loader" v-if="loading"></div>
                </div>
                <!-- Billing Cycle Radios  -->
                <div class="billing-cycle margin-top-25">

                    <!-- Radio -->

                    <div class="content">
                                <ul class="fields-ul chek_page second_indiv_step_wrap">
                                <li>
                                    <div class="row">


                                        <div>
                                        </div>
                                         <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Card Holder Name <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                    type="text"
                                                    v-model="cardHolderName"
                                                    id="name"
                                                    class="input-text with-border  required-field"
                                                    name="name"
                                                    placeholder="Cardholder Name"
                                                    value=""
                                                />
                                            </div>
                                            <div class="show_span">
                                                <span class="error_x_white">Please enter card holder name </span>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                        <div class="submit-field">
                                                <h5>Card Number <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                    type="text"
                                                    v-model="cardNumber"
                                                    maxlength="16"
                                                    id="number"
                                                    class="input-text with-border  required-field"
                                                    name="name"
                                                    placeholder="1234-1234-1234-1234"
                                                    value=""
                                                />
                                            </div>
                                            <div class="show_span">
                                                <span class="error_x_white">Please enter card number </span>
                                            </div>
                                        </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Expiry Month <span class="required_star">*</span></h5>
                                                    <select v-model="expiryMonth" id ='month' class="custom-select " @change="onChangeMonth('month')">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="mn" v-for="(mn,id) in duration_mn" :key="id">{{mn}}</option>
                                                        </select>
                                                </div>
                                                <div class="show_span">
                                                <span class="error_x_white">Please Select Month </span>
                                                </div>
                                            </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Expiry Year <span class="required_star">*</span></h5>
                                                    <datepicker v-model="expiryYear" :open-date="new Date()"  :format="customFormatterTwo" id="scheduled_post" class="scheduled_post " minimum-view="year" @selected="onChangeMonth('year')" style="border: unset;" ></datepicker>

                                                </div>
                                                <div class="show_span">
                                                <span class="error_x_white">Please Select Year </span>
                                                </div>
                                            </div>

                                            <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>CVV <span class="required_star">*</span></h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="cvv"
                                                        id="cvv"
                                                        maxlength="3"
                                                        class="input-text with-border  required-field"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                                <div class="show_span">
                                                <span class="error_x_white">Please Enter CVV </span>
                                                </div>
                                            </div>


                                    </div>

                                </li>
                            </ul>
                            </div>


                    <!-- Radio -->

                </div>
              </div>

                <div class="col-xl-12">
                    <a @click="submit" class="button ripple-effect big margin-top-30 employee_btn mobile_last_btn_mrgn">Submit</a>
                </div>
                    <!-- Summary -->
                </div>

    </div>

</template>

<script>
  import { POST_API } from "../../store/actions.type"
   import Datepicker from 'vuejs-datepicker';
    import moment from "moment";

  export default {

  data: () => {
    return {
             formPosition: 0,
             page : 1,
             coins: '',
             coinData:'',
             packageData:'',
             total_amount : 0,
            loading: false,
            duration_mn: [1,2,3,4,5,6,7,8,9,10,11,12],

             cardHolderName:'',
            cardNumber:'',
            expiryMonth:'',
            expiryYear: '',
            cvv:'',
            name:'',
            email:'',
            phone:'',
            code:'',
            address:'',
            city:'',
            country:'',
            elcoinDatabyid:'',
            settings:'',
            countries:''


            }},
              components: {
                    Datepicker
            },
  methods: {
    breadCrumb(){
            var item = [{
                to:{name:'cardList'},
                text: 'Manage Cards',
            },{
                to:null,
                text: 'Add Card'
            }];
            return item;
    },
     nextStep(){
      this.page = this.page+1;
     },

     back(){
      this.page = this.page-1;
     },

      showAlert() {
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },



            customFormatterTwo(date) {
                return moment(date).format('YYYY');
            },

            submit(){

                var err = false;

                $('.required-field').each(function () {

                if ($(this).val() == '' || $(this).val().trim().length === 0) {


                    err = true;
                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });

             $('#month').each(function () {

                if( !$('#month').val() ){

                    err = true;

                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });

                $('.scheduled_post').each(function () {

                if( !$('#scheduled_post').val() ){

                    err = true;

                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {

                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

            });


             $(".scheduled_post").focusin(function(){
               $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });
            // $(".scheduled_post").focusout(function(){
            //    $(this).addClass('error');
            //    $(this).parent().parent().find('.error_x_white').addClass('show');
            // });


            $("#month").change(function(){
                $(this).removeClass('error');
               $(this).parent().parent().find('.error_x_white').removeClass('show');
            });

             setTimeout(function ()
             {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show');
                    });
            }, 100);


                if(err == false){


                 this.$store.dispatch(POST_API, {
                        data:{
                            user_id:this.user_id,
                            cardHolderName:this.cardHolderName,
                            cardNumber:this.cardNumber.split("-").join(""),
                            expiryMonth:this.expiryMonth,
                            expiryYear: moment(this.expiryYear).format('YYYY'),
                            cvv:this.cvv,
                            company_id: this.company_id,
                    },
                    api: '/api/storeCard'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        // var message = 'Job Bookmarked successfully.'
                        var message = this.$store.getters.getResults.message
                        this.showSuccessAlert(message);
                        window.location.replace('/v2/employer/card-list');
                    }
                });
                }

            },



            onChangeMonth(id){

                if(id == 'month'){

                    $('#month-warning-message').hide();
                    $('#month').removeClass('invalid');
                }else{
                    $('#year-warning-message').hide();
                    $('#year').removeClass('invalid');
                }

            },

            onChange(id){

                this.packageData = id,
                this.coinData = id,

                this.getElCoinsById();
            },

            show(id)
            {

                if(id == 'card'){
                   $('#cardDialogue').addClass('payment-tab-active');
                    $('#paypalData').removeClass('payment-tab-active');

                }else{
                    $('#paypalData').addClass('payment-tab-active');
                    $('#cardDialogue').removeClass('payment-tab-active');
                }
            },

        getSettings(){

           this.$store.dispatch(POST_API, {

                    api:'/api/settings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.settings = this.$store.getters.getResults.data;

                    }
                });
     },

      getCountry(){

           this.$store.dispatch(POST_API, {

                    api:'/api/countriesData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.countries = this.$store.getters.getResults.data;

                    }
                });
     },

    },

     mounted(){

        var input = document.getElementById("number");

        $('#number').keyup(function(e)
            {
            if (/\D/g.test(this.value))
            {
                // Filter non-digits from input value.
                this.value = this.value.replace(/\D/g, '');
            }
            });

        input.onkeyup = function (event) {
            const key = event.key; // const {key} = event; ES6+
            if (key != "Backspace") {
                var foo = $(this).val().split("-").join(""); // remove hyphens
                if (foo.length > 0) {
                    foo = foo.match(new RegExp('.{1,4}', 'g')).join("-");
                }
                $(this).val(foo);
            }
        }

            //document.title = "EL Connect - Employer - Add Card";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.email = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            this.getSettings();
            this.getCountry();
     }

    }
</script>
