<template>
    <div class="animated fadeUp">
        <div class="job-detail-section companyViewProfile">
            <div class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between">
                <div class="top_h3_black mr-auto">
                    <!-- <h3 class="">View Service Agreement</h3> -->
                </div>
                <div class="top_black_btn margin_left_15_desktop mobile_text_start common_margin_bottom_30">
                                <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" :to="{name:'service-agreement-edit'}">Edit</b-link>
                </div>
            </div>
            <div class="profile-section p-lg-5 p-3 white-bg common_padding_30">
                <p v-html="items.description">
                </p>
            </div>
        </div>
            
    </div>
</template>

<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import JwtService from "../../../common/jwt.service";
import Vue from 'vue';
import moment from "moment";
    export default {
    
    data() {
        return {
            items:null,
            webUrl: process.env.MIX_APP_URL,
            error_message:'',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            statusClass:' text-warning',
            actions: '',
            user_type_id: '',
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD-MM-YYYY hh:mm A')
        }
    },
    methods: {
        getErrors() {
            return this.$store.getters.getErrors[0];
        },
        containsErrors() {
            return this.$store.getters.getErrors.length > 0;
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        getEmploymentContractData() {
        
            this.$store.dispatch(POST_API, {
                    data:{
                        id:''
                    },
                    api:'/api/view-service-agreement'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                    } else {
                        this.items = this.$store.getters.getResults.data;
                       // return this.items;         
                    }
                });
          
        },
        
    },
    
    mounted()
    {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
       this.getEmploymentContractData(); 
    }
}

</script>