import employer from '../components/website/employer/Employer.vue';

export default[
    
    {
        path: '/employer-old',
        name: 'employer',
        component: employer,
        meta: { requiresAuth: false, hideForAuth:true , showFor:'admin'}
    },

];