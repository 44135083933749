<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline">
            <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>{{last_name | capitalize}} {{first_name | capitalize}} Payslips</h1>

            <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Payslips</li>
                </ul>
            </nav>
        </div> -->
        <div id="breadcrumb-v2">
          <b-breadcrumb>
            <b-breadcrumb-item href="" :to="{ name: 'jobseekers-list' }"
              >Jobseekers</b-breadcrumb-item
            >
            <b-breadcrumb-item active
              >{{ last_name | capitalize }}
              {{ first_name | capitalize }} Payslips</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
        <!-- <div class="col-xl-4 remove_search_box_pdng">
                    <div class="search-box custom_search_admin">
                        <b-input-group>
                           
                            </b-input-group>
                        </div>
                    </div> -->
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <div class="row">
      <div class="container my_new_container show_filter_arrow">
        <b-table
          ref="datatable"
          show-empty
          striped
          hover
          responsive
          :items="getJobseekers"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="mobile_min_width_1000 countries_five_grid_table"
        >
          <template #cell(payslip_number)="items">
            {{ items.item.payslip_number }}
          </template>
          <template #cell(start_date)="items">
            {{ items.item.start_date | momentDate }}
          </template>
          <!-- <template #cell(end_date)="items">
                    <p class="btn yellow-bg align_start desktop_margin_left_zero desktop_margin_right_zero">{{items.item.end_date | capitalize}}</p>
                    </template> -->
          <!-- <template #cell(url)="items">
                    <p class="common_word_break">{{items.item.url }}</p>
                    </template>  -->

          <template #cell(created_at)="items">
            {{ items.item.created_at | moment }}
          </template>
          <template #cell(toggleAction)="items">
            <div class="toggle-action toggle_margin_unset">
              <b-dropdown class="mx-1" right text="Action" boundary="window">
                <b-dropdown-item @click="downloadPdf(items.item.url)">View Payslip</b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block">
                <b-form-select v-model="form.rowsPerPage" @change="getJobseekers">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";

export default {
  data() {
    return {
      fields: [
        { key: "payslip_number", label: "Payslip Number", sortable: true },
        { key: "start_date", label: "PaySlip", sortable: true },
        // { key: 'end_date', label: 'End Date', sortable: true   },
        // { key: 'url', label: 'URL', sortable: true },
        { key: "created_at", label: "Payslip Generated At", sortable: true },
        { key: "toggleAction", label: "Actions" },
      ],
      id: atob(this.$route.params.id),
      first_name: "",
      last_name: "",
      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "active",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
      },
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    momentDate: function (date) {
      return moment(date).format("MMM-YYYY");
    },
    moment: function (date) {
      return moment(date).utc().format("DD MMM YYYY");
    },
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "jobseekers-list" });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    getJobseekers() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.id,
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "id",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/payslip-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    downloadPdf(url) {
      window.open(url);
    },
    verifyEmail(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/jobseeker-verify-email-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Email Verified successfully.";
            this.$refs.datatable.refresh();
            this.showSuccessAlert(message);
          }
        });
    },
    getUserData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: atob(this.$route.params.id),
          },
          api: "/api/user-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.first_name = this.$store.getters.getResults.data.first_name;
              this.last_name = this.$store.getters.getResults.data.last_name;
            }
          }
        });
    },

    deleteJobseeker(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/admin-empjobseeker-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Jobseeker Deleted successfully.";
                  this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    // document.title = "EL Connect - Admin - Jobseekers List";
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.getUserData();
  },
};
</script>
