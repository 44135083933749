<template>
        
    <div>
        
        <!-- Dashboard Headline -->
        <!-- Page Content
        ================================================== -->
     
        <div class="grd_div" style="padding:24px 0">
        <div class="container">
             <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
            </div>
            </div>
            </div>
        
        
    <!--<div class="single-page-header" :data-background-image="jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg'">-->
    <div class="bg_img_top" v-bind:style="{backgroundImage: 'url('+( jobData.image ? jobData.image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
      
		
    </div>


<div class="container new_detail_bottom_mrgn">
	<div class="row">
		
		<!-- Content -->
		<div class="col-xl-8 col-lg-8 content-right-offset single_left_content">

			<div class="single-page-section" v-if="title">
				<h3 class="margin-bottom-25">Promotion Title</h3>
				<p v-html="title"></p>
				<h3 class="margin-bottom-25">Start Date</h3>

                <p v-html="start"></p>
                <h3 class="margin-bottom-25">End Date</h3>

                <p v-html="end"></p>

                <h3 class="margin-bottom-25">Promotion Description</h3>
                <p v-html="description"></p>
			</div>

		
			
		</div>
		

		<!-- Sidebar -->
	

	</div>
     <!-- Single Page Job Slider Start-->

       
       
        <!-- Single Page Job Slider / End -->
</div>

    </div>

</template>

<script>
    import { POST_API } from "../../store/actions.type"
    import moment from 'moment'
    import { Carousel, Slide } from 'vue-carousel';
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import VueSocialSharing from 'vue-social-sharing'


    export default {

         data() {
            return {
                bookmarkVal:'',
                bookmarkjobs:[],
				simJobs: '',
                company_name:'',
                jobData:[],
                id: (this.$route.params.id),
                title:'',
                start:'',
                end:'',
                posted_by:'',
                profile_image:'',
                email_verified:'',
                designation:'',
                address:'',
                other_location:'',
                description:'',
                company_description:'',
                category_id:'',
                end_date:'',
                items: null,
                job_type:'',
                keyword:'',
                userId:'',
                jobId:'',
                tagArr:[],
                average_rating:'',
                alreadyApplied : false,
                min_salary:'',
                max_salary:'',
                error_message:'',
                skillsArr: [],
                tagArr: [],

                pager: {},
                pageOfItems: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                webUrl: process.env.VUE_APP_URL,
                user_address:'',
                settings: {
				"slidesToShow": 3,
				"slidesToScroll": 3,
				"speed": 800,
				"infinite": false,
				responsive: [
					{
					breakpoint: 768,
					settings: {
						"slidesToShow": 1,
							"slidesToScroll": 1,
							"speed": 800,
							"infinite": false,
                            "dots":true,
                            "arrows":false
					    }
					}
				]
			}	
            }
        },


        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            
            
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },
     
        methods:{

            async copyURL() {
                    try {
                        let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");
                        
                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        }); 
                        // alert('Copied to Clipboard.');
                    } catch($e) {

                        console.log($e);
                        
                        // alert('Cannot copy');
                    }
              },
            
            showAlert() {
                    this.$swal({
                        position: 'center',
                        icon: 'error',
                        title: this.error_message,
                        showConfirmButton: false,
                        timer: 1500
                    })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            getpromotionsDataById() {
               
                return this.$store.dispatch(POST_API, {
                     data:{
                            id: this.id,
                },
                    api: '/api/promotion-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.jobData = this.$store.getters.getResults.data;
                        this.title = this.$store.getters.getResults.data.title;
                        this.start = this.$store.getters.getResults.data.start_date;
                        this.end = this.$store.getters.getResults.data.end_date;
                        this.description = this.$store.getters.getResults.data.description;
                        
                    }
                });
        },
		

        },
         mounted(){
            //document.title = "EL Connect - All Jobs Detail"; 
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        
            this.getpromotionsDataById();

        },
        components: {
        VueSlickCarousel,
        Carousel,
        VueSocialSharing,

        Slide
    }
    }
</script>


