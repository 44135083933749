<template>
    <div class="animated fadeIn">
        <div class="manage-job-section">
            <div class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <!-- <div class="top_h3_black mr-auto">
                            <h3 class="">
                            <b-link class="top_black_header_anchor" :to="{name:'broadcast-notification-list'}"><i class="fa fa-long-arrow-left" aria-hidden="true"></i></b-link>Notification Detail
                            </h3>
                        </div> -->
                         <div id="breadcrumb-v2" class="mb-1">
                            <div class="d-flex justify-content-start breadcrumb-wrapper">
                                <b-breadcrumb
                                :items="breadCrumb()"
                                />
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="profile-section common_padding_30 white-bg">
            <div class="profile-box pl-0">
                <div class="profile-detail full_notification_view_ul">
                    
                    <ul>
                        <li v-if="items1.title" class="pl-0">
                            <p><b>Title</b>- <i>{{items1 != null ? items1.title:'' }}</i></p>
                        </li>
                        <li v-if="items1.description" class="pl-0">
                            <div v-html="items1.description"></div>
                        </li><br/>
                        <li v-if="items1.job_id" class="pl-0">
                            <p><b>Title</b>- 
                                <b-link v-if="items1.job_id.status == 'completed'" :to="{name: items1.job_id.status == 'in-progress' ? 'ViewJobDetailInprogressAdmin' : items1.job_id.status == 'active' ? 'ViewJobDetailUpcomingAdmin' : items1.job_id.status == 'completed' ? 'ViewJobDetailCompletedAdmin' : items1.job_id.status == 'cancelled' ? 'ViewJobDetailCancelledAdmin' : '', params: {id: encrypt(items1.job_id.id),from:(items.job_id.status),search:('null'),companyFilter:('null'),serviceCategory:('null')}}" target="_blank">
                                    {{items1.job_id.job_unique_id}} 
                                </b-link>
                            </p>
                        </li><br/>
                        <li v-if="items1.notification_type" class="pl-0">
                            <p><b>Notification Type</b>- <i>{{items1.notification_type | capitalize}}</i></p>
                        </li>
                        
                        <li v-if="items1.platform" class="pl-0">
                            <p><b>Platform</b>- <i>{{items1.platform | capitalize}}</i></p>
                        </li><br/>
                        <li v-if="items1.status" class="pl-0">
                            <p><b>Status</b>- <i>{{items1.status | capitalize}}</i></p>
                        </li><br/>
                         <li v-if="items1.created_at" class="pl-0">
                            <p><b>Created Date</b>- <i>{{items1.created_at | moment}}</i></p>
                        </li><br/>
                         <li class="pl-0">
                            <p><b>Scheduled Date</b>- <i v-if="items1.scheduled_start_date != null" >{{items1.scheduled_start_date | moment}}</i></p>
                        </li><br/>
                         <li v-if="items1.created_at" class="pl-0">
                            <p><b>Send Date</b>- <i>{{items1.status == 'sent' ? items1.updated_at : ''| moment}}</i></p>
                        </li>
                    </ul>
                </div>
            </div>
            <br>
        </div>
        <div class="border-radius-4 white-bg  p-lg-4 p-3 desktop_padding_top_zero">
              <b-table ref="datatable" show-empty striped hover responsive :items="getNotificationList" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="add_new_table_border ">

                <template slot="full_name" slot-scope="items">
                  {{items.item.user_id.full_name | capitalize}}
                </template>
                
                <template slot="email" slot-scope="items">
                  {{items.item.user_id.email  | capitalize}}
                </template>

                <template slot="mobile" slot-scope="items">
                  {{items.item.user_id.country_code}} {{items.item.user_id.mobile_number}}
                </template>
               
              </b-table>
        </div>
        <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                     <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPage" @change='getNotificationList'>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                        </b-form-select>
                     </span>
                </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
                <ul>
                    <li>
                        <span v-if='from != "" && to != "" && totalRows != ""'>{{from}} - {{to}} of {{totalRows}}</span>
                    </li>
                </ul>
            </div>
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
        </nav>
    </div>
</template>
<script>
import { POST_API, GET_API} from "../../../store/actions.type";
import moment from "moment";
import JwtService from "../../../common/jwt.service";
import Vue from 'vue';

export default {

    data() {
        return {
            fields: [
                { key: 'full_name', label: 'User Name', sortable: true },
                { key: 'email', label: 'Email', sortable: true },
                { key: 'mobile', label: 'Contact Number', sortable: true }

            ],
            form: {
                rowsPerPage: 25,
                userServices: 'all',
            },
            items1: null,
            items: null,
            userDetail:null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: null,
            status: 'active',
            isBusy: false,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            to: '',
            from: '',
            country:'',
            countries:'',
            Country:'',
            webUrl: process.env.MIX_APP_URL,

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            if(date != '')
                return moment(date).format('DD MMM, YYYY | hh:mm A');
            else
                return '';
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            value=value.split('_').join(' ');
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }

    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'broadcast-notification-list'},
                text: 'Broadcast Notification',
            },{
                to:null,
                text: 'Notification Detail'
            }];
            return item;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getNotificationList() {

             return this.$store.dispatch(POST_API, {
                   data:{
                     status: this.status,
                     page: this.currentPage,
                     keyword: this.filter,
                     rowsPerPage:this.form.rowsPerPage,
                     sortBy:this.sortBy ? this.sortBy : 'user_notifications.id',
                     sortDirection:this.sortDirection,
                     sortDesc:this.sortDesc,
                     id:Vue.prototype.decrypt(this.$route.params.id)
                   },
                   api: '/api/notification-user-detail-list-view'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.totalRows = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.per_page;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        
                        return this.items;
                    }
                });
        },
        getNotificationDetail() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        id:Vue.prototype.decrypt(this.$route.params.id),
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage: this.form.rowsPerPage,
                        sortBy: this.sortBy,
                        sortDirection: this.sortDirection,
                        sortDesc: this.sortDesc
                    },
                    api: '/api//notification-detail-list-view'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items1 = this.$store.getters.getResults.data;
                        this.items1.description = '<b>Description</b>- '+this.items1.description;
                        return this.items1;
                    }
                });
        },

    
       
        
    },
    mounted() {
        this.getNotificationDetail();
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
    }

}

</script>
