<template>
    <div>
        <!-- Titlebar
        ================================================== -->
        <div id="titlebar" class="gradient login_title signup_mrgn">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <h1 class="common_center">Admin Log In</h1>

                    </div>
                </div>
            </div>
        </div>

        <!-- Page Content
        ================================================== -->
        <div class="container">
            <div class="row">
                <div class="col-xl-5 common_block">

                    <div class="login-register-page">
                        <!-- Welcome Text -->
                        <!-- <div class="welcome-text">
                            <h3>We're glad to see you again!</h3>
                            <span>Don't have an account? <b-link href="" :to="{name: 'signup'}" >Sign Up!</b-link></span>
                        </div> -->
                        <!-- Form -->
                        <b-form method="post" id="login-form" @submit.prevent="login">
                            <div class="input-with-icon-left">
                                <i class="icon-material-baseline-mail-outline height_four_eight"></i>
                                <b-form-input
                                    type="text" 
                                    id="email"
                                    v-model="email"
                                    name="login-email"
                                    class="input-text with-border" 
                                    placeholder="john@example.com"
                                    autofocus
                                />
                            </div>

                            <div class="input-with-icon-left" id="show_hide_password_login">
                                <i class="icon-material-outline-lock height_four_eight"></i>
                                <b-form-input
                                    id="password"
                                    v-model="password"
                                    type="password"
                                    class="input-text with-border"
                                    name="login-password"
                                    placeholder="Password"
                                />
                                <div class="input-group-addon-custom">
                                    <a href="" @click="passwordClick"><i class="fa fa-eye-slash height_four_eight" aria-hidden="true"></i></a>
                                </div>            
                            </div>
                            <span><b-link href="" :to="{name: 'adminforgotpassword'}" >Forgot Password?</b-link></span>
                        </b-form>
                        <!-- Button -->
                        <button class="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit" form="login-form">Log In <i class="icon-material-outline-arrow-right-alt"></i></button>
                        
                    </div>

                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
    </div>
</template>

<script>
    import { LOGIN,PERMISSION_DATA } from "../../store/actions.type"

    export default {
        data() {
            return {
                email: '',
                password: '',
                user_type: '5',
                status: '',
                showDismissibleAlert:false,
                error_message:''
            }
        },
        computed: {
            passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            }
        },
        methods:{
            getMenuPermissionStaff(){
                this.$store.dispatch(PERMISSION_DATA, {
                    data: {
                        user_id: this.$store.getters.currentUser.id
                    },
                    api: '/api/staff-menu-permissions'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.menus = this.$store.getters.getResults.data;
                    }
                        
                });
            },
            login() {
                this.$store.dispatch(LOGIN, {
                    data:{
                        emailormobile: this.email,
                        password:this.password,
                        user_type:"1,2",
                        role:'admin'
                    },
                    api:'/api/admin-login-v2'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                    
                        var userData = this.$store.getters.currentUser;
                        
                        // userData.ability = [{
                        //     action: "manage", subject: "all"
                        // }];

                        // localStorage.setItem('userData', JSON.stringify(userData))
                        // this.$ability.update(userData.ability)
                        this.getMenuPermissionStaff();
                        if(userData.designation == null || userData.designation != 'External Agent'){
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: 'Login successfully.',
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                window.location.replace('/v2/admin/jobseekers');
                            });
                        }else{
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: 'Login successfully.',
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                window.location.replace('/v2/admin/daily-deployment');
                            });
                        }
                        
                    }       
                }); 
            },

            passwordClick(event){
                event.preventDefault();
                if($('#show_hide_password_login input').attr("type") == "text"){
                    $('#show_hide_password_login input').attr('type', 'password');
                    $('#show_hide_password_login i').addClass( "fa-eye-slash" );
                    $('#show_hide_password_login i').removeClass( "fa-eye" );
                }else if($('#show_hide_password_login input').attr("type") == "password"){
                    $('#show_hide_password_login input').attr('type', 'text');
                    $('#show_hide_password_login i').removeClass( "fa-eye-slash" );
                    $('#show_hide_password_login i').addClass( "fa-eye" );
                }
            }
            
        },
        mounted()
            {
                //document.title = "EL Connect - Admin Login"; 
            }
    }
</script>