<template>
    <div class="animated fadeIn">
        <div class="campaign-section">
            <div class="black-strip-head  padding_top_25 manage_job_top_anchor mobile_display_block align-items-center justify-content-between">
                <div class="row">
                    <div class="col-md-12">
                        <!-- <div class="top_h3_black ">
                            <h3 class="mr-auto default_heading"><a href="javascript:history.back(1);" class="top_black_header_anchor"><span class="icon-material-outline-arrow-back arrow_left_black_colour"></span></a>Incentives</h3>
                        </div> -->
                        <div id="breadcrumb-v2" class="mb-1">
                            <div class="d-flex justify-content-start breadcrumb-wrapper">
                                <b-breadcrumb
                                :items="breadCrumb()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="campaign-listing">
                <div class="border-radius-4 white-bg border-midium-light mb-3">
                    <div class="content">
                        <span class="tag green-bg" v-if="is_active == 'active'">{{is_active | capitalize}}</span>

                        <span class="tag yellow-bg" v-if="is_active == 'inactive'">{{is_active | capitalize}}</span>

                        <span class="tag grey-bg" v-if="is_active == 'ended'">{{is_active | capitalize}}</span>

                        <h6>{{start_date | moment}} - {{end_date | moment}}</h6>
                        <p class="dark-red-text margin-bottom-0">Ends in {{diff_days}} days</p>
                    </div>
                    <div class="rupee-content" >
                        <span v-if="is_active != 'inactive'">{{total_jobs}}</span>
                        <span v-if="is_active == 'inactive'">0</span>
                    </div>
                    <div class="price-range-box">
                        
                        <label for="range-1">{{type | capitalize}}</label>
                        <div class="incentive_range_flex">
                            <b-form-input id="range-1" v-model="earned" type="range" min="0" :max="max_range" class="range_select_incentive"></b-form-input><span>{{max_range}}</span>
                        </div>
                        <div class="mt-2">Incentive Earned: <span class="green-text">S${{earned}}</span></div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </div>
</template>
<script>
import { POST_API, GET_API } from "../../../store/actions.type";

import moment from "moment";
import JwtService from "../../../common/jwt.service";
import Vue from 'vue';

export default {

    data() {
        return {
            value: '1',
            items: null,
            total_jobs:0,
            earned:0,
            is_active:'',
            max_range:0,
            type:'',
            user_id: '',
            hour_incentive: null,
            wage_incentive: null,
            job_incentive: null,
            rowsPerPage: 25,
            webUrl: process.env.MIX_APP_URL,
            start_date:'',
            end_date:'',
            diff_days:''
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment(date) {
            if(date != null)
                return moment(date).format('DD MMM');
            else
                return '-';

        },
        ifNumber: function(value) {
            if (!value) {
                return '0.00';
            }
            return parseFloat(value).toFixed(2);
        },
    },
    methods:{
        breadCrumb(){
                var item = [{
                    to:{name:'jobseekers-list'},
                    text: 'Jobseekers',
                },{
                    to:null,
                    text: 'Incentives'
                }];
                return item;
        },
        getIncentiveData() {
            return this.$store.dispatch(POST_API, {
                   data:{
                     user_id: Vue.prototype.decrypt(this.$route.params.id),
                   },
                   api: '/api/incentiveData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                    //    this.items = this.$store.getters.getResults;
                        this.total_jobs = this.$store.getters.getResults.total_jobs;
                        this.earned = this.$store.getters.getResults.earned;
                        this.is_active = this.$store.getters.getResults.is_active;
                        this.max_range = this.$store.getters.getResults.max_range;
                        this.type = this.$store.getters.getResults.type;

                        //this.hour_incentive = this.$store.getters.getResults.data.hour_incentive;
                        // this.job_incentive = this.$store.getters.getResults.data.job_incentive;
                        // this.wage_incentive = this.$store.getters.getResults.data.wage_incentive;
                        this.start_date = this.$store.getters.getResults.start_date;
                        this.end_date = this.$store.getters.getResults.end_date;
                        this.diff_days = this.$store.getters.getResults.diff_days;


                        
                        //return this.items;
                    }
                });
        },
    },
    mounted() {
         $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.getIncentiveData();
    }
}

</script>
