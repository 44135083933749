<template>

    <div class="all_job_color all_job_top">
        <div class="row">
           <div class="container mobile_tab_container_hundered">
               <div class="full-page-container cnt_height common_scroll_content mobile_tab_full_left_right_padding new_layout_side">
                    <div class="full-page-sidebar my_sd_bar daily_paid_side_bar">
                            <div class="full-page-sidebar-inner" data-simplebar>
                                <div class="sidebar-container remove_pdng jb_side_bar internal_daily_paid_side_bar tab_padding_right_zero">

                                     <!-- Keywords -->
                                    <div class="sidebar-widget">
                                        <p>Keywords</p>
                                        <div class="keywords-container">
                                            <div class="keyword-input-container">
                                                <input type="text" class="keyword-input" placeholder="e.g. job title" v-model="keyword" v-on:keyup.enter="submit"/>
                                            </div>
                                            <!-- <div class="keywords-list"></div> -->
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <!-- Category -->
                                    <!-- <div class="sidebar-widget">
                                        <h3>Category</h3>
                                        <b-form-select v-model="skill" class="select_mrgn" v-on:change="submit">
                                            <option value="" >All Categories</option>
                                            <option :value="skillsArrs.id" v-for="skillsArrs in skillsArr">{{skillsArrs.name | capitalize}}</option>
                                        </b-form-select>
                                    </div> -->
                                    <div class="sidebar-widget">
                                        <p>Company</p>
                                        <b-form-group id="input-group-8" class="required">
                                            <multiselect id="ajax" v-model="company_id_filter"  tag-placeholder="Search Company" placeholder="Enter to search Company" :options="employers" label="name" track-by="company_id" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="true" :show-labels="false" class="job_list_multi_select model_multi_select" ><span slot="noResult">No Company Found.</span></multiselect>
                                        </b-form-group>
                                    </div>

                                    <div class="sidebar-widget">
                                        <p>Category</p>
                                        <b-form-group id="input-group-8" class="required">
                                            <multiselect id="ajax"  v-model="skill" tag-placeholder="Search Category" placeholder="Enter to search Category" :options="skillsArr" label="name" track-by="id" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="true" :show-labels="false" class="job_list_multi_select model_multi_select" ><span slot="noResult">No Category Found.</span></multiselect>
                                        </b-form-group>
                                    </div>

                                    <!-- Location -->
                                    <!-- <div class="sidebar-widget">
                                        <h3>Location</h3>
                                        <div class="input-with-icon">
                                            <select v-model="location_id" id="job_location" class="job_location custom-select">
                                                <option value='' selected='selected' disabled>Select Location</option>
                                                <optgroup :label="location.title" v-for="location in locations">
                                                    <option :value="location.location_names_id[index]" v-for="(area,index) in location.location_names_val">{{area | capitalize}}</option>
                                                </optgroup>
                                            </select> -->
                                            <!-- <b-form-select v-model="location_id">
                                                <option value=""  disabled>Select Location</option>
                                                <option :value="location.id" v-for="location in locations">{{location.title | capitalize}}</option>
                                            </b-form-select> -->
                                            <!-- <i class="icon-material-outline-location-on"></i> -->
                                        <!-- </div>
                                    </div> -->



                                    <!-- Job Types -->
                                    <!-- <div class="sidebar-widget">
                                        <h3>Job Role Type</h3>
                                        <b-form-select v-model="role_type_id" @change="get_job_type($event)">
                                                    <option value=""  disabled>Select Job Role Type</option>
                                                    <option :value="role_type.id" v-for="role_type in role_types">{{role_type.name | customizeRoleType}}</option>
                                                </b-form-select> -->
                                        <!-- <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" v-model="daily"><span class="switch-button"></span> Daily</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" v-model="full_time"><span class="switch-button"></span> Full Time</label>
                                            </div>
                                        </div> -->
                                    <!-- </div> -->

                                    <!-- Salary -->
                                    <!-- <div class="sidebar-widget">
                                        <h3>Salary</h3>

                                        <div class="salary_slider"><span>${{this.minimum_val}}-${{this.maximum_val}}</span></div>
                                         <vue-slider v-model="value" :min="min" :max="max" :interval="interval"  @change="get_salary_data($event)" :formatter="formatter"  class="point_slider" style="padding:11px 27px !important"></vue-slider>
                                   </div> -->

                                     <!-- <div class="sidebar-widget">
                                        <h3>Tags</h3>
                                        <div class="keywords-container">
                                            <div class="keyword-input-container">
                                                 <multiselect v-model="tagArr" @search-change="getTagData($event)" @input="getDataTag($event)" :show-labels=false tag-placeholder="Add this as new tag" placeholder="Enter to search tag" label="title" track-by="id" :options="options" :multiple="true" class="new_multi_slec_list" ><span slot="noResult">No Result.</span></multiselect>
                                           </div>
                                        </div>
                                    </div> -->
                                     <div class=" sidebar-widget job_list_btn_search mobile_space_between">
                                    <b-button @click="submit" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                    <b-button @click="clear" class="button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero" style="background-color: red; margin-left:20px;">Clear Filter</b-button>

                                </div>

                                 <!-- <div class="sidebar-search-button-container sidebar-widget job_list_btn_search">
                                </div> -->
                                </div>


                                <!-- Search Button / End-->
                            </div>
                        </div>
                        <div class="full-page-content-container job_list_height fit_height remove_white_bg" data-simplebar>
                            <div class="full-page-content-inner job_list_btm full_box_grid_shows new_job_listss common_padding_30 add_white_bg">

                                <h1 class="page-title search_h1">Daily Paid Jobs and Part Time Job in Singapore</h1>

                                <!-- <div class="notify-box margin-top-15">
                                    <div class="switch-container">
                                    </div>
                                </div> -->
                                <div class="content margin-top-30" v-if="dailyjob_datas.length > 0">
                                    <div class="listings-container new_liting_containerz grid-layout " v-for="dailyjob_data in dailyjob_datas" :current-page="currentPage" :per-page="perPage">
                                        <!-- Job Listing -->
                                        <b-link  :to="{name: 'allDailyPaidJobDetailNew', params: {id: encrypt(dailyjob_data.id) , company_name:dailyjob_data.alias, job_alias:dailyjob_data.job_alias}}" class="new_job_listingz job-listing" style="width: 100%;">
                                            <!-- Job Listing Details -->
                                            <!-- <div class="job-listing-details mobile_display_block"> -->
                                                <!-- Details -->
                                                <div class="job-listing-details mobile_display_block new_job_listing_detailz">
                                                    <div class="job-listing-company-logo new_job_listing_company_logo single_logo_img">
                                                        <!-- <img v-if='dailyjob_data.company_logo' :src="dailyjob_data.company_logo">
                                                        <img v-else :src="webUrl+'assets/images/company-logo-placeholders.png'"> -->
                                                        <img :src="dailyjob_data.thumbnail != null ? dailyjob_data.thumbnail: dailyjob_data.company_logo != null ? dailyjob_data.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo">

                                                        <!-- <img :src="dailyjob_data.company_logo != null ? dailyjob_data.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt=""> -->
                                                    </div>
                                                    <div class="job_listing_card_inner mobile_display_block block_999 new_job_listing_card_innerz inner_flexz">
                                                    <div class="job-listing-description common_br_word_job_listing">
                                                        <!-- <h4 class="job-listing-company bold_heading_iinner suggested_title" v-if="dailyjob_data.title.length >= 25"><b>{{dailyjob_data.title.substring(0,25)+'...' | capitalize}}</b></h4>
                                                        <h4 class="job-listing-company bold_heading_iinner suggested_title" v-else><b>{{dailyjob_data.title | capitalize}}</b></h4> -->
                                                        <h2 class="job-listing-company bold_heading_iinner suggested_title" ><b>{{dailyjob_data.title | capitalize}}</b></h2>
                                                        <p class="common_futura_book id_p_listing">{{dailyjob_data.job_unique_id}}</p>
                                                        <p class="job_list_green_h4">S${{dailyjob_data.hourly_cost.toFixed(2)}}/HR</p>

                                                    </div>
                                                    <div class="daily_paid_description star_icon joblist_star_icon desktop_flex margin_top_20_on_999" v-if="dailyjob_data.job_type_id == 2">
                                                        <div class="featured_job_section_lists">
                                                            <p class="daily_paid_on_demand_h5" ><span class="" >On-Demand</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                            <!-- </div> -->
                                        <div class="job-listing-footer">
                                            <ul class="daily_paid_uls">

                                                <!--<li><i class="icon-material-outline-access-time"></i>2 minutes ago</li>-->
                                                <li><i class="icon-line-awesome-calendar-o"></i>{{dailyjob_data.start_date | momentDate}}</li>

                                                <li><i class="icon-material-outline-access-time"></i>{{dailyjob_data.start_time | momentTime}} - {{dailyjob_data.end_time | momentTime}}</li>
                                               <!-- <li><i class="icon-feather-users"></i>{{dailyjob_data.total_vacancies}} Vacancy</li>-->
                                                <li><i class="icon-feather-users"></i>{{dailyjob_data.category_name}}</li>

                                                <li><i class="icon-material-outline-location-on"></i>{{dailyjob_data.address | capitalize}}</li>
                                            </ul>
                                            <!-- <span><b-link class="button button-sliding-icon ripple-effect all_daily_paid_btn"  :to="{name: 'allDailyPaidJobDetail', params: {id: encrypt(dailyjob_data.id)}}" >Check Now<i class="icon-material-outline-arrow-right-alt"></i></b-link></span> -->
                                        </div>
                                            <!-- <div class="task-listing-bid"> -->
                                                <!-- <div class="task-listing-bid-inner"> -->
                                                    <!-- <div class="task-offers"> -->
                                                        <!-- <strong>S${{dailyjob_data.hourly_cost}} / HR</strong> -->
                                                        <!--<span>Fixed Price</span>-->
                                                    <!-- </div> -->
										            <!-- <span><a class="button button-sliding-icon ripple-effect" href="https://elconnectptuser.app.link/OSbf0HwLOib" target="_blank">Check Now<i class="icon-material-outline-arrow-right-alt"></i></a></span> -->
                                                    <!-- <span class="button button-sliding-icon ripple-effect" @click="dailyPaidJobDetail((dailyjob_data.id),$event)">Check Now <i class="icon-material-outline-arrow-right-alt"></i></span> -->
                                                <!-- </div> -->
                                            <!-- </div> -->
                                        </b-link>
                                        <!-- Job Listing -->
                                    </div>
                                </div>
                                <div class="content text-center" v-else>
                                    <p><b>No Record Found</b> </p>
                                </div>

                                <div class="clearfix"></div>
                                <nav class="pagination-box">
                                    <div class="page-row-box d-inline-block">
                                        <b-form-group id="input-group-4">
                                            <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getDPJobsData'>
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>75</option>
                                                        <option>100</option>
                                            </b-form-select></span>
                                        </b-form-group>
                                    </div>
                                    <div class="total-page-count d-inline-block">
                                        <ul>
                                            <li>
                                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <b-pagination @input='getDPJobsData' :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                </nav>
                                <div class="clearfix"></div>
                            </div>
                            <div class="tag_blocks_section d-inline-block saerch_tag_web">
                                  <p>People also searched:</p>
                                    <b-form-tag
                                            v-for="jobSearch in jobSearchArr"
                                            :key="jobSearch"
                                            :title="jobSearch"
                                            class="mr-1"
                                        >
                                        <span>
                                        <b-button
                                            @click="onCLickRelatedSearch(jobSearch)"
                                            size="sm"
                                            class="search_btn_filter"
                                            ><i class="material-icons-outlined">search_outlined</i></b-button>
                                        <b-button  @click="onCLickRelatedSearch(jobSearch)" class="tag_btn_webs"> {{ jobSearch }}</b-button></span>
                                    </b-form-tag>
                            </div>
                        </div>
                </div>
			</div>
        </div>

    </div>

</template>



<script>
    import { POST_API } from "../../store/actions.type"
    import 'vue-slider-component/theme/default.css'
    import VueSlider from 'vue-slider-component'
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {
        metaInfo: {
            title: 'Part Time Job - Daily Paid Jobs Listing in Singapore',
            meta: [
                { charset: 'utf-8' },
                { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: "Daily Paid Jobs Listing in Singapore - Need cash urgently? Say goodbye to waiting for paydays and explore our flexible part time jobs with daily cash payments!"},
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'keywords', content: 'part time daily paid jobs, daily paid jobs singapore, part time job daily paid, daily paid, part-time jobs, part time jobs, flexible job, gig jobs,unskilled jobs'},
                {property: 'og:title', content: 'Part Time Job - Daily Paid Jobs Listing in Singapore'},
                {property: 'og:site_name', content: 'EL Connect'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://elconnect.sg/singapore-daily-paid-jobs'},
                {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                {property: 'og:description', content: "Daily Paid Jobs Listing in Singapore - Need cash urgently? Say goodbye to waiting for paydays and explore our flexible part time jobs with daily cash payments!"},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/singapore-daily-paid-jobs'}
  		    ]
	    },
        data() {
            return {
                keyword:'',
                skill:[],
                category_id_string:'',
                skillsArr: [],
                company_id_string:'',
                company_id_filter:[],
                employers: [],
                companyArr:[],
                job_id:'',
                jobData: '',
                catArr:[],
                category_id:'',
                daily:'',
                full_time:'',
                type:'',
                salary:'',
                error_message:'',
                categories: [],
                tagArr: [],
                value: [0, 15000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                sliderValue: 50,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                dailyjob_datas:[],
                webUrl: process.env.VUE_APP_URL,
                jobSearchData:[],
                jobSearchArr: []
            }
        },
        components: {
                VueSlider,Multiselect
        },

        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },

            momentDate: function(date) {
				return moment(date).format("DD MMM YYYY")
			},
			momentTime: function(date) {
				return moment(date, "HH:mm:ss").format("hh:mm A")
			},

            customFormatterOne(date) {
                return moment(date).format('DD-MM-YYYY');
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },
        created() {
            this.min = 0
            this.max = 15000
            this.formatter = value => `$${value}`
        },

        methods:{
            onCLickRelatedSearch(title){
                this.keyword = title;
                this.submit();
                this.getJobSerachData();
                window.scrollTo(0,0);
            },
            getEmployers(){
                this.$store.dispatch(POST_API, {
                    data:{
                         company_id_string:this.company_id_string,
                    },
                    api:'/api/employer-job-list-website'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.employers = this.$store.getters.getResults.data;
                        this.company_id_filter = this.company_id_string ? this.$store.getters.getResults.company_array : this.company_id_filter;
                        this.getDPJobsData();
                    }
                });
            },
            submit() {

                console.log(this.skill,'jhjjj')

                this.skill.forEach(element => {
                  this.catArr.push(element.alias);
                })
                this.skill = this.catArr.toString();
                console.log(this.skill,'llll')


                this.skill = this.skill.replaceAll(',', '--')
                // console.log(this.skill,'jhjj')

                this.keyword = this.keyword.replaceAll(' ', '-')

                if(this.skill == ''){

                    this.skill = 'all-categories-jobs';
                }

                console.log(this.company_id_filter);
                // if(this.company_id_filter.length > 0 && this.company_id_filter != 'all-companies-jobs' && this.company_id_filter != '' && this.company_id_filter != []){
                    this.company_id_filter.forEach(async element => {
                        console.log(element);
                    await this.companyArr.push(element.alias);
                    })
                    console.log(this.companyArr,'this.companyArr')
                    this.company_id_filter = this.companyArr.toString();

                    this.company_id_filter = this.company_id_filter.replaceAll(',', '--')
                    this.company_id_filter = this.company_id_filter.replaceAll('/', '*')
                // }

                if(this.company_id_filter == ''){

                    this.company_id_filter = 'all-companies-jobs';
                }
                console.log(this.company_id_filter,'this.company_id_filter');

                window.location.replace('/singapore-daily-paid-jobs/'+this.skill+'/'+this.company_id_filter+'/'+this.keyword);


                // this.$store.dispatch(POST_API, {
                //     data:{
                //         type: this.type,
                //         keyword: this.keyword,
                //         category: this.skill,
                //         job_type_id:this.role_type_id,
                //         page: this.currentPage,
                //         rowsPerPage:this.form.rowsPerPage,
                //         user_id: this.user_id
                //     },
                //     api:'/api/all-dpjobs-web'
                // })
                // .then(() => {
                    // if (this.$store.getters.containsErrors) {
                    //     this.error_message = this.$store.getters.getErrors;
                    //     this.showAlert();
                    //     return [];
                    // } else {
                    //     this.dailyjob_datas = this.$store.getters.getResults.pageOfItems;
                    //     this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    //     this.perPage = this.$store.getters.getResults.pager.pageSize;
                    //     this.from = this.$store.getters.getResults.pager.from ? this.$store.getters.getResults.pager.from : 1;
                    //     this.to = this.$store.getters.getResults.pager.to;
                    //     // return this.dailyjob_datas;
                    // }
                // });
            },
            clear(){
                window.location.replace('/singapore-daily-paid-jobs');

                // this.location_id = '';
                // this.type = '';
                // this.keyword = '';
                // this.skill = [];
                // // this.value = '';
                // this.daily = '';
                // this.tagArr = '';
                // this.role_type_id = '';
                // this.full_time = '';
                // this.getDPJobsData();
                // this.getJobSerachData();

            },
            getDropdownData(){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories',
                        category_id_string:this.category_id_string
                    },
                    api:'/api/dropdown-list-category-v1'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.skillsArr = this.$store.getters.getResults.data;
                        this.skill = this.$store.getters.getResults.category_array ? this.$store.getters.getResults.category_array : [];

                        console.log(this.skill,'skill')

                        this.getDPJobsData();


                        // this.skill = this.category_id_string ? this.$store.getters.getResults.category_array : this.skill;
                    }
                });
            },
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },
            getJobSerachData(){
                this.jobSearchArr = [];
                this.$store.dispatch(POST_API, {
                    data:{
                        type: 'daily_paid',
                        title: this.keyword,
                    },
                    api:'/api/job-search-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.jobSearchData = this.$store.getters.getResults.data;
                        this.jobSearchData.forEach(element => {
                            this.jobSearchArr.push(element.title);
                        });
                    }
                });
            },

            dailyPaidJobDetail(id,event){
                if (event) {
                    event.stopPropagation()
                }
                window.location.replace('/dp-jobs-detail/'+ (id));
            },

            getDPJobsData() {
                // console.log(this.skill,'kk',this.company_id_filter,this.company_id)
                return this.$store.dispatch(POST_API, {
                        data:{
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                            category: this.skill == 'all' || this.skill == [] || this.skill == '' ? [] : this.skill,
                            company: this.company_id_filter == 'all-companies-jobs' || this.company_id_filter == [] || this.company_id_filter == '' ? [] : this.company_id_filter,
                            keyword: this.keyword == 'all' || this.keyword == '' ? '' : this.keyword,

                },
                api: '/api/all-dpjobs-web'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.dailyjob_datas = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from ? this.$store.getters.getResults.pager.from : 1;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.dailyjob_datas;
                    }
                });
            },



        },
         mounted(){
             window.scrollTo(0,0);

            this.category_id_string = this.$route.params.category_id;
            this.keyword = this.$route.params.keyword ? this.$route.params.keyword.split('-').join(' ') : '';
            this.company_id_string = this.$route.params.company_id;


            this.allSearchDataV1 = JSON.parse(localStorage.getItem('allSearchDataV1')) ? JSON.parse(localStorage.getItem('allSearchDataV1')) : '';
            // this.skill = this.allSearchDataV1.category_id_V1 == 'all' || this.allSearchDataV1.category_id_V1 == '' ? []: this.allSearchDataV1.category_id_V1 ? this.allSearchDataV1.category_id_V1 : [],
            // this.category_id_string = this.allSearchDataV1.category_id_V1 == 'all' || this.allSearchDataV1.category_id_V1 == '' ? '': this.allSearchDataV1.category_id_V1 ? this.allSearchDataV1.category_id_V1 : '',
            // this.keyword = this.allSearchDataV1.keyword_V1 == 'all' || this.allSearchDataV1.keyword_V1 == '' ? '' : this.allSearchDataV1.keyword_V1 ? this.allSearchDataV1.keyword_V1 : '',
            localStorage.removeItem('allSearchDataV1')
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getDropdownData();
            this.getJobSerachData();
            this.getEmployers();

            //document.title = "EL Connect - All Jobs Listing";


        }
    }
</script>


