<template>
  <div>
    <section id="form">
      <div class="bg_color_singpass">
        <div class="main_two_div">
          <SingpassLeftSideContentComponent />
          <div class="right_side_div_sinpass">
            <div class="">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="create_account_h2_sing common_montesrat_regular_singpass">
                    Create Account
                  </h2>
                  <p class="common_montesrat_regular_singpass top_information">
                    We have collected this information from Myinfo. Please double check on the
                    information.
                  </p>
                  <img :src="webUrl + 'assets/images/account.png'" class="common_verify_img" />
                </div>
              </div>
            </div>
            <b-form method="post" id="formApplication" @submit.prevent="signup">
              <!-- <b-input-group> -->
              <div class="">
                <div class="row">
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5 class="red_font">Mobile Number <span class="red_span">*</span></h5>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text country_code_singpass">+65</span>
                        </div>
                        <input
                          type="text"
                          class="form-control with-border"
                          id="mobileno"
                          v-model="mobile"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5 class="red_font">Email Address <span class="red_span">*</span></h5>
                      <input
                        type="text"
                        class="form-control with-border"
                        id="email"
                        v-model="email"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>Full Name</h5>
                      <input
                        type="text"
                        class="form-control with-border"
                        id="name"
                        v-model="full_name"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>NRIC</h5>
                      <input
                        type="text"
                        class="form-control with-border"
                        id="uinfin"
                        disabled
                        v-model="nric"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>Nationality</h5>
                      <input
                        type="text"
                        class="form-control with-border"
                        id="nationality"
                        disabled
                        v-model="nationality"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>Date Of Birth</h5>
                      <input
                        type="date"
                        class="form-control with-border singpass_form_control_height"
                        id="dob"
                        disabled
                        v-model="dob"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>Race</h5>
                      <input
                        type="text"
                        class="form-control with-border"
                        id="race"
                        disabled
                        v-model="race"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>Gender</h5>
                      <input
                        type="text"
                        class="form-control with-border"
                        id="sex"
                        disabled
                        v-model="gender"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>
                        Password <span class="red_span">*</span>
                        <div class="tooltip_icon_singpass" @click="tooltipPassword">
                          <span class="material-icons-outlined info_tooltip_icon"
                            >info_outlined</span
                          >
                          <span class="tooltip_text_singpass tooltip_text_password"
                            >Minimum 8 characters, must contain an uppercase letter, a lowercase
                            letter, at least 1 number and 1 special character</span
                          >
                        </div>
                      </h5>
                      <div class="input-group" id="password-click-singpass">
                        <input
                          type="password"
                          class="form-control with-border"
                          id="pass"
                          v-model="password"
                        />
                        <div
                          class="input-group-append input-with-icon-left custom_input_with_icon_left"
                        >
                          <span href="" @click="passwordClick">
                            <i
                              class="fa fa-eye-slash cusror_point common_profile_icon"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>
                        Confirm Password <span class="red_span">*</span>
                        <div class="tooltip_icon_singpass" @click="tooltiprePassword">
                          <span class="material-icons-outlined info_tooltip_icon"
                            >info_outlined</span
                          >
                          <span class="tooltip_text_singpass tooltip_text_repassword"
                            >Minimum 8 characters, must contain an uppercase letter, a lowercase
                            letter, at least 1 number and 1 special character</span
                          >
                        </div>
                      </h5>
                      <div class="input-group" id="re-password-click-singpass">
                        <input
                          type="password"
                          class="form-control with-border"
                          id="confirm_pass"
                          v-model="repassword"
                        />
                        <div
                          class="input-group-append input-with-icon-left custom_input_with_icon_left"
                        >
                          <span href="" @click="repasswordClick">
                            <i
                              class="fa fa-eye-slash cusror_point common_profile_icon"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>Address</h5>
                      <input type="text" disabled class="form-control with-border" v-model="address" />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="new_submit_field">
                      <h5>Referral Code (Optional)</h5>
                      <input type="text" class="form-control with-border" v-model="referral_code" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="new_submit_field">
                      <h4
                        class="desktop_policy_singpass agree_h4 common_montesrat_regular_singpass"
                      >
                        By signing up, I agree to EL Connect’s
                        <span class="common_blue_color">Terms & Conditions</span> and
                        <span class="common_blue_color">Privacy Policy</span
                        ><span
                          ><b-form-radio
                            id="checkbox"
                            type="radio"
                            value="true"
                            class="radio_singpass employer_multiple_radio checkbox"
                            style="font-size: 8px"
                            v-model="checkbox"
                        /></span>
                      </h4>
                      <div class="mobile_policy_singpass">
                        <h4 class="agree_h4 common_montesrat_regular_singpass">
                          <span
                            ><b-form-radio
                              id="checkbox1"
                              type="radio"
                              value="true"
                              class="radio_singpass employer_multiple_radio checkbox"
                              style="font-size: 8px"
                              v-model="checkbox"
                          /></span>
                        </h4>
                        <h4>
                          By signing up, I agree to EL Connect’s
                          <span class="common_blue_color">Terms & Conditions</span> and
                          <span class="common_blue_color">Privacy Policy</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 align_end">
                    <button
                      type="submit"
                      class="button ripple-effect common_montesrat_regular_singpass continue_btn_singpass"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
              <!-- </b-input-group> -->
            </b-form>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="margin-top-70 mobile_tab_margin_top_45"></div> -->
  </div>
</template>

<script>
// import { POST_API } from "../../store/actions.type"
import { POST_API, LOGIN, PERMISSION_DATA } from "../../store/actions.type";
import SingpassLeftSideContentComponent from "./SingpassLeftSideContentComponent.vue";

import axios from "axios";

function str(data) {
  if (!data) return null;
  if (data.value) return data.value;
  else if (data.desc) return data.desc;
  else if (typeof data == "string") return data;
  else return "";
}

function populate(frm, data) {
  $.each(data, function (key, value) {
    $("[id=" + key + "]", frm).val(value);
    // $('[name=' + key + ']', frm).prop('disabled', true);
  });
}

// ---END---PREFILL FORM (with MyInfo data)

export default {
  metaInfo: {
    title: "EL Connect - Singpass Login",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - Terms of Service" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/terms-of-service" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "Terms of Service - View your agreements with EL Connect when you use our Job Seeking and Manpower Searching platform.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/terms-of-service" }],
  },
  data() {
    return {
      x: "terms_jobseeker",
      terms_and_conditions_ptUser: "",
      terms_and_conditions_supervisor: "",
      showDismissibleAlert: false,
      error_message: "",
      display: true,
      link_error: "",
      webUrl: process.env.VUE_APP_URL,
      codeVerifier1: "",
      fname: "",
      lname: "",
      email: "",
      comp_name: null,
      full_name: null,
      referral_code: null,
      address: null,
      emailormobile: "",
      old_email: "",
      old_mobile: "",
      email: "",
      mobile: "",
      password: "",
      repassword: "",
      user_type: "5",
      status: "",
      showDismissibleAlert: false,
      error_message: "",
      gender: "",
      race: "",
      dob: "",
      nationality: "",
      nric: "",
      mobile_verified: "no",
      email_verified: "no",
      checkbox: false,
      singpassLogin: true,
    };
  },
  components: {
    SingpassLeftSideContentComponent,
  },
  methods: {
    tooltipPassword() {
      $(".tooltip_text_password").toggle();
    },
    tooltiprePassword() {
      $(".tooltip_text_repassword").toggle();
    },
    passwordClick(event) {
      event.preventDefault();
      if ($("#password-click-singpass input").attr("type") == "text") {
        $("#password-click-singpass input").attr("type", "password");
        $("#password-click-singpass i").addClass("fa-eye-slash");
        $("#password-click-singpass i").removeClass("fa-eye");
      } else if ($("#password-click-singpass input").attr("type") == "password") {
        $("#password-click-singpass input").attr("type", "text");
        $("#password-click-singpass i").removeClass("fa-eye-slash");
        $("#password-click-singpass i").addClass("fa-eye");
      }
    },
    repasswordClick(event) {
      event.preventDefault();
      if ($("#re-password-click-singpass input").attr("type") == "text") {
        $("#re-password-click-singpass input").attr("type", "password");
        $("#re-password-click-singpass i").addClass("fa-eye-slash");
        $("#re-password-click-singpass i").removeClass("fa-eye");
      } else if ($("#re-password-click-singpass input").attr("type") == "password") {
        $("#re-password-click-singpass input").attr("type", "text");
        $("#re-password-click-singpass i").removeClass("fa-eye-slash");
        $("#re-password-click-singpass i").addClass("fa-eye");
      }
    },
    // getGenerateCode(){
    //      this.$store.dispatch(POST_API, {
    //         data:{
    //             authCode: this.$route.query.code,
    //         },
    //         api:'/getfl/generateCodeChallenge'
    //     })
    //     .then((res) => {

    // 			this.codeVerifier1 = this.$store.getters.getResults.sessiondata

    // 			console.log(this.codeVerifier1,'uuuuuuuuuu');
    // 			this.getSingpassData();
    // 	})
    // },
    // getSingpassData(){

    // 	this.$store.dispatch(POST_API, {
    //         data:{
    //             authCode: this.$route.query.code,
    // 			codeVerifier:this.codeVerifier1
    //         },
    //         api:'/getfl/getPersonData'
    //     })
    // 	// const formData = new FormData()
    // 	// formData.append('authCode', this.$route.query.code)
    // 	// formData.append('codeVerifier', this.codeVerifier1)

    // 	// axios.post('http://localhost:3001/v2/getfl/getPersonData', formData, {
    // 	// })
    // 	.then((res) => {

    // 		console.log(res);

    //         if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;

    //             this.$swal({
    //                 position: 'center',
    //                 icon: 'error',
    //                 title: this.error_message,
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             });

    //         } else {
    //         }
    // })

    // }

    signup() {
      localStorage.setItem("email", $("#email").val());
      localStorage.setItem("mobile", $("#mobileno").val());

      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);

      console.log(this.checkbox, "lll");
      if (this.checkbox != "true" && err == false) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please accept terms and condition first.",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      console.log(this.full_name, this.email);

      const fullArr = this.full_name.split(" ");

      for (var i = 0; i < fullArr.length; i++) {
        fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
      }

      this.lname = fullArr[0];

      fullArr.shift();

      this.fname = fullArr.join(" ");

      if (this.old_email == window.localStorage.getItem("email")) {
        this.email_verified = "yes";
      }

      if (this.old_mobile == window.localStorage.getItem("mobile")) {
        this.mobile_verified = "yes";
      }

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              full_name: this.full_name,
              email: this.email,
              emailormobile: this.emailormobile,
              mobile_number: this.mobile,
              nric: this.nric,
              race_id: this.race,
              nationality: this.nationality,
              gender: this.gender,
              dob: this.dob,
              password: this.password,
              user_type: this.user_type,
              repassword: this.repassword,
              register_from: "web",
              popup: false,
              email_verified: this.email_verified,
              mobile_verified: this.mobile_verified,
              referral_code: this.referral_code,
              address: this.address,
              checkbox: this.checkbox == "true" ? true : false,
            },
            api: "/api/singpassSignupWeb",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              var userData = this.$store.getters.currentUser;

              this.login();
            }
          });
      }
    },

    login() {
      this.$store
        .dispatch(LOGIN, {
          data: {
            emailormobile: this.email,
            password: this.password,
            user_type: this.user_type,
            role: "user",
            login_on: "web",
            popup: false,
            singpassLogin: this.singpassLogin,
          },
          api: "/api/login",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            console.log(this.$store.getters.currentUser);

            localStorage.setItem("user_id", this.$store.getters.currentUser.id);

            if (
              this.old_email == window.localStorage.getItem("email") &&
              this.old_mobile == window.localStorage.getItem("mobile")
            ) {
              this.getMenuPermission();
            } else if (this.old_mobile != window.localStorage.getItem("mobile")) {
              window.location.replace("/singpass-verify-mobile");
            } else {
              window.location.replace("/singpass-verify-email");
            }
            // this.$swal({
            //   position: "center",
            //   icon: "success",
            //   iconColor: "#4c8bf5",
            //   showConfirmButton: false,
            //   timer: 1500,
            // }).then((result) => {
            //   console.log(this.$store.getters.currentUser);

            //   localStorage.setItem("user_id", this.$store.getters.currentUser.id);

            //   if (
            //     this.old_email == window.localStorage.getItem("email") &&
            //     this.old_mobile == window.localStorage.getItem("mobile")
            //   ) {
            //     this.getMenuPermission();
            //   } else if (this.old_mobile != window.localStorage.getItem("mobile")) {
            //     window.location.replace("/singpass-verify-mobile");
            //   } else {
            //     window.location.replace("/singpass-verify-email");
            //   }
            //   //commented on 26-12-23
            //   // if(this.user_type == '3'){
            //   //     window.location.replace('/v2/employer/employer-profile');
            //   // }else{
            //   //     window.location.replace('/v2/jobseeker/jobseeker-profile');
            //   // }
            //   //commented on 26-12-23
            //   //added on 26-12-23
            //   // this.getMenuPermission();
            // });
          }
        });
    },

    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Welcome to EL Connect",
              showConfirmButton: false,
              timer: 1500,
            });

            if (this.user_type == "3") {
              // window.location.replace("/v2/employer/dashboard");
              this.is_company_head = this.$store.getters.currentUser.is_company_head;
              this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                if (this.is_company_head == "yes") {
                  window.location.replace("/v2/employer/employer-profile");
                } else {
                  window.location.replace("/v2/employer/om-supervisor-profile");
                }
              }
            } else {
              window.location.replace("/v2/jobseeker/daily-job-profile");
            }
          }
        });
    },

    prefillForm(data) {
      console.log(data);
      // prefill form data
      var noaData = "";
      var address = "";
      if (data["noa-basic"]) {
        noaData = str(data["noa-basic"].amount)
          ? formatMoney(str(data["noa-basic"].amount), 2, ".", ",")
          : "";
      }
      if (data.regadd.type == "SG") {
        address =
          str(data.regadd.country) == ""
            ? ""
            : str(data.regadd.block) +
              " " +
              str(data.regadd.building) +
              " \n" +
              "#" +
              str(data.regadd.floor) +
              "-" +
              str(data.regadd.unit) +
              " " +
              str(data.regadd.street) +
              " \n" +
              "Singapore " +
              str(data.regadd.postal);
      } else if (data.regadd.type == "Unformatted" || data.regadd.type == "UNFORMATTED") {
        address = str(data.regadd.line1) + "\n" + str(data.regadd.line2);
      }

      console.log(address, "jjj");

      this.nric = str(data.uinfin);
      this.full_name = str(data.name);
      this.gender = str(data.sex);
      this.nationality = str(data.nationality);
      this.race = str(data.race);
      this.dob = str(data.dob);
      this.email = str(data.email);
      this.mobile = str(data.mobileno.nbr);
      this.old_mobile = str(data.mobileno.nbr);
      this.old_email = str(data.email);
      this.address = address;

      localStorage.setItem("old_email", this.old_email);

      var formValues = {
        uinfin: str(data.uinfin),
        name: str(data.name),
        sex: str(data.sex),
        race: str(data.race),
        nationality: str(data.nationality),
        dob: str(data.dob),
        email: str(data.email),
        // str(data.mobileno.prefix) + str(data.mobileno.areacode) + " " +
        mobileno: str(data.mobileno.nbr),
        regadd: address,
      };

      // Populate values
      populate("#formApplication", formValues);
    },
  },
  mounted() {
    $(function () {
      $("#formAuthorize").submit(function (event) {
        event.preventDefault();
        callAuthorizeApi();
      });
      $("#formApplication").submit(function (event) {
        event.preventDefault();
        // add code here to submit the application form back to server for processing
        $("#complete").toggleClass("hidden");
      });
    });
    // ---END---MAIN HANDLER---

    // ---START---AUTH API---
    function callAuthorizeApi() {
      //Call backend server to generate code challenge
      $.ajax({
        url: "/getfl/generateCodeChallenge",
        data: {},
        type: "POST",
        success: function (result) {
          //Redirect to authorize url after generating code challenge
          var authorizeUrl =
            authApiUrl +
            "?client_id=" +
            clientId +
            "&scope=" +
            scope +
            "&purpose_id=" +
            purpose_id +
            "&code_challenge=" +
            result +
            "&code_challenge_method=" +
            method +
            "&redirect_uri=" +
            redirectUrl;

          window.location = authorizeUrl;
        },
        error: function (result) {
          alert("ERROR:" + JSON.stringify(result.responseJSON.error));
        },
      });
    }
    // ---END---AUTH API---

    var data = window.localStorage.getItem("singpassData");

    console.log(JSON.parse(data));

    //document.title = "EL Connect - Terms of Service";
    if (data) {
      this.prefillForm(JSON.parse(data));
    }

    window.scrollTo(0, 0);
  },
};
</script>
