<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
                <!-- Dashboard Headline -->
                <div class="dashboard-headline">
                    <h1>Monthly Incentives Management</h1>
                    <!-- Breadcrumbs -->
                    <nav id="breadcrumbs" class="dark">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Monthly Incentives Management</li>
                        </ul>
                    </nav>
                </div>
                <div class="row">
                     <div class="col-xl-5 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <label class="new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Date:</label>                                
                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">   
                            <datepicker v-model="month_year" @input="changeUserIncentiveFilter" :format="customFormatter" minimum-view="month" maximum-view="year" class="add_calender_icon common_normal_calender_height freelancer_incentive_calender"></datepicker>     
                        </b-form-group>
                    </div>
                    <div class="col-xl-7 monthly_incentive_management_btn_div search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <!-- <b-link class="btn mt-0 green-border-btn desktop_margin_left_zero desktop_magin_right_zero" :to="{name:'master-incentive-management-list'}">Master Incentives Management</b-link> -->
                        <b-link class="btn mt-0 mr-0 green-border-btn desktop_margin_left_zero desktop_magin_right_zero" :to="{name:'incentive-management-create'}" v-if="show('add')">Create Incentives</b-link>
                    </div>
                    <!-- <div class="service_col_2 col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28 new_reset_btn">
                        <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">
                            <b-button v-if="(month_year)" @click="clearMonthlyIncentiveFilter()" class="button ripple-effect search_all_red_btn  filter_btn_widht_120 desktop_margin_right_zero" style="background-color: red;"><span>Reset all filters</span></b-button>
                        </div>
                    </div> -->
                </div>
                    
                <!-- Page Content
                ================================================== -->
                
                <div class="row">
                <div class="container my_new_container show_filter_arrow">
                    <b-table ref="datatable" show-empty striped hover responsive :items="getMonthlyIncentiveData" :fields="fieldsUserIncentiveData" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="desktop_margin_top_zero new_box_tbl_mrgn   mobile_min_width_1000 nine_grid_tbl_new ">
                            
                            <template #cell(range_type)="items">
                                {{(items.item.range_type ? items.item.range_type : '' ) | capitalize}}
                            </template>
                            <template #cell(level)="items">
                                {{(items.item.level  ? items.item.level : '' )}}
                            </template>
                            <template #cell(min_range)="items">
                                <p v-if="items.item.max_range != '-'">
                                    {{items.item.min_range}}-{{items.item.max_range}}
                                </p>
                                <p v-else>{{items.item.min_range}}=<</p>
                            </template>
                            <template #cell(amount)="items">
                                {{items.item.amount ? 'S$'+items.item.amount : 'S$0'}}
                            </template>
                            <template #cell(status)="items">
                            <p class="green_status" v-if="items.item.status == 'active'">{{items.item.status | capitalize}}</p>
                            <p class="orange__status" v-if="items.item.status == 'inactive'">{{items.item.status | capitalize}}</p>
                            </template>
                            <template #cell(toggleAction)="items">
                                <div class="toggle-action  toggle_margin_unset top_100">
                                    <b-dropdown class="mx-1" left text="Action" boundary="window">
                                        <b-dropdown-item :to="{name: 'incentive-management-edit', params: {id: encrypt(items.item.id)}}" v-if="show('edit')">Edit
                                        </b-dropdown-item>
                                        <b-dropdown-item v-on:click="changeStatus(items.item.id,items.item.status)" v-if="items.item.status == 'active' && show('activate/inactivate')">Inactivate</b-dropdown-item>
                                        <b-dropdown-item v-else-if="items.item.status == 'inactive' && show('activate/inactivate')" v-on:click="changeStatus(items.item.id,items.item.status)">Activate
                                        </b-dropdown-item>
                                        <b-dropdown-item v-if="items.item.status != 'deleted' && show('delete incentives')" v-on:click="deleteIncentive(items.item.id)">Delete
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </template>
                        </b-table>

                        <nav class="pagination-box custom_pagination_border_radius">
                            <div class="page-row-box d-inline-block">
                                <b-form-group id="input-group-4">
                                    <span class="d-inline-block">Rows per page</span>
                                    <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getMonthlyIncentiveData'>
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                    </b-form-select></span>
                                </b-form-group>
                            </div>
                            <div class="total-page-count d-inline-block">
                                <ul>
                                    <li>
                                        <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                    </li>
                                </ul>
                            </div>
                            <b-pagination :total-rows="totalRows"  :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                        </nav>
                    </div>
                </div>
                        
                </div>
                </div>
        </div>
</template>

<script>
import { POST_API } from '../../../../store/actions.type'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Datepicker from 'vuejs-datepicker';
import permission from "../../../../../../server/permission.js";

export default {

    data() {
        return {
            fieldsUserIncentiveData: [
                { key: 'range_type', label: 'Range Type', sortable: true },
                { key: 'level', label: 'Level', sortable: true },
                { key: 'min_range', label: 'Range', sortable: true },
                { key: 'amount', label: 'Amount', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            date: moment().format('YYYY-MM-DD'),
            items:null,
            totalRows: 0,
            format: 'YYYY-MM-DD HH:mm',
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            // month_year: moment().subtract(1, 'month').format('MMM YYYY'),
            month_year: moment().format('MMM YYYY'),

            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            
            sortBy: null,
            sortDirection: 'asc',
            filter: '',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            disabledFrom: {
                to: new Date(Date.now() - 8640000)
            },
            actions:'',

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function () {
            return moment();
        }
    },

    components: {
        Datepicker
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$refs.datatable.refresh();
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        
        changeUserIncentiveFilter()
        {  
            this.getMonthlyIncentiveData().then(() => {
                this.$refs.datatable.refresh();
            });
        },
        customFormatter(date) {
            return moment(date).format('MMM,YYYY');
        },
        clearMonthlyIncentiveFilter(){
            this.month_year = '';
            this.$refs.datatable.refresh();
        },
        
        getMonthlyIncentiveData() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.searchKeyword,
                    month_year:this.month_year ? moment(this.month_year).format('MMM YYYY') : '',
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'incentives_for_ptuser_range.level',
                    sortDirection:this.sortDesc ? 'asc' : 'desc',
                },
                api: '/api/incentive-list-admin'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },

        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Record Inactivated Successfully.';
            }
            else {
                var msg = 'Are you sure you want to activate this record?';
                var message='Record Activated Successfully.';
                this.remarks = null

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          id: id,
                          status:status,
                        },
                        api:"/api/incentive-active-inactive-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.showSuccessAlert(message);
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                    console.log(err)
                })
        },
        deleteIncentive(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/incentive-delete-admin',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Data Deleted successfully.';
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Incentives Management');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.permissionStaff();
     }
}
</script>
