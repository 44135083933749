<template>
    <div>
        
         <!-- <div class="container full_container_dsk">
            <div class="row custom_dsk_row"> -->
        <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h3>Manage User</h3>
            <!-- <b-link class="btn green-border-btn" :to="{name:'empManageUserCreate'}">Add</b-link> -->
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Manage User</li>
                </ul>
            </nav>
        </div>
            <div class="row">
                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <b-input-group-prepend>
                                        <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                                    </b-input-group-prepend>
                                    <!-- </b-input-group> -->
                            </div>
                </div> 
                <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                    <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" :to="{name:'empManageUserCreate'}">Add</b-link>
                </div>
            </div>
        </div>
    </div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk">
           <div class="row"> -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getEmpUsers" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="three_grid_tbl tbl_height race_width new_box_tbl_mrgn"> -->
            <b-table ref="datatable" show-empty striped hover responsive :items="getEmpUsers" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_3000 memberhip_six_grid_table ">
                    <template #cell(name)="items">
                       
                        <!-- <b-img :src="(items.item.profile_image != null ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp">
                        </b-img> -->
                        <div class="whole_div_img_and_name">
                            <div class="manage_user_img">
                                <b-img class="user_img_emp square_img" v-if="items.item.thumbnail || items.item.profile_image" :src="items.item.thumbnail ? items.item.thumbnail: items.item.profile_image ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px">
                                </b-img>
                                <b-img class="user_img_emp square_img" v-else-if="items.item.ft_profile_image_thumbnail || items.item.ft_profile_image" :src="items.item.ft_profile_image_thumbnail ? items.item.ft_profile_image_thumbnail: items.item.ft_profile_image ? items.item.ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px">
                                </b-img>

                                <b-img class="user_img_emp square_img" v-else :src="webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px">
                                </b-img>
                            </div>
                            <div class="user_name_div">
                                {{items.item.last_name}} {{items.item.first_name}}
                            </div>
                        </div>
                        <div class="mail_with_verified"> 
                            <p class="grey-text">
                                {{items.item.email}}
                            </p>
                            <div class="verified-badge-with-title" v-if="items.item.account_verification_status == 'completed'">Verified</div>
                        </div>
                    </template>
                    <template #cell(mobile_number)="items">
                    {{items.item.country_code}}-{{items.item.mobile_number}}
                    </template>   
                    <template #cell(created_at)="items">
                    {{items.item.created_at | moment}}
                    </template> 
                    <template #cell(status)="items">
                    <p class="green_status" v-if="items.item.status == 'active'">{{items.item.status | capitalize}}</p>
                    <p class="orange__status" v-if="items.item.status == 'inactive'">{{items.item.status | capitalize}}</p>
                    <p class="red_status" v-if="items.item.status == 'deleted'">{{items.item.status | capitalize}}</p>
                    </template> 
                    <!-- <template #cell(account_status)="items">
                    {{items.item.ft_account_status | capitalize}}
                    </template> -->
                    <!-- <template #cell(ft_account_status)="items">
                    {{items.item.ft_account_status | capitalize}}
                    </template> 
                     <template #cell(account_verification_status)="items">
                    {{items.item.account_verification_status | capitalize}}
                    </template>  -->
                    <template #cell(user_type)="items">
                    <p v-if="items.item.user_type_id == '3'">OM</p>
                    <p v-if="items.item.user_type_id == '4'">Supervisor</p>
                    </template> 
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item v-if="items.item.status == 'active' || items.item.status == 'inactive'" :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                </b-dropdown-item>
                                <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeCompanyUserStatus(items.item.id,items.item.status)" v-if="items.item.status == 'active' && items.item.is_company_head == 'no'">Inactivate</b-dropdown-item>
                                <b-dropdown-item v-else-if="items.item.status == 'inactive' && items.item.is_company_head == 'no'" v-on:click="changeCompanyUserStatus(items.item.id,items.item.status)">Activate
                                </b-dropdown-item>
                                <b-dropdown-item v-if="items.item.is_company_head == 'no' && items.item.status != 'deleted' && show('cancel job')" v-on:click="deleteCompanyUser(items.item.id)">Delete</b-dropdown-item>
                                <b-dropdown-item v-if="items.item.user_type_id == 3 && items.item.is_company_head == 'no' && items.item.status == 'active'" @click="openAttachSupModal(items.item.id)">Attach User
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                             <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getEmpUsers'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
            <!-- <b-modal hide-footer hide-header id="attach-sup-modal" ref="attach-sup-modal" centered> -->
             <b-modal hide-footer  id="attach-sup-modal" ref="attach-sup-modal" title="Select User" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                <div class="edit-task model_legend">
                    <b-form @submit.prevent="attachSupSubmit" method="post">
                        <div class="popup-header">
                            <!-- <h2 class="">Select User</h2> -->
                            <div class="mt-2">
                                <div class="addUserForm">
                                    <div class="editForm mw-100 pl-0">
                                        <b-row>
                                          <b-col lg="12" md="12">
                                            <div class="submit-field">
                                                <b-form-group id="input-group-8" label="All Users" class="required">
                                                <multiselect v-model="supervisorName" :custom-label="customLabel" tag-placeholder="Add this as new user" placeholder="Enter to search user" label="full_name" track-by="id" :options="selectedSupervisor" :multiple="true" class="job_list_multi_select  model_multi_select" ><span slot="noResult">No User Found.</span></multiselect>
                                                </b-form-group>
                                            </div>
                                          </b-col>

                                        </b-row>

                                        <div slot="footer" class="form-btn common_crop_reset_btn">
                                          <b-button type="submit" variant="success" class="model_common_bt_success_color">Submit</b-button>
                                          <b-button @click="hideModal()" variant="light" class="model_common_bt_lite_color ">Cancel</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </b-modal>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from "moment";
import Multiselect from 'vue-multiselect'
import Bus from "../../../event-bus";
import permission from "../../../../../server/permission.js";
export default {
    
    data() {
        return {
            fields: [
                { key: 'name', label: 'Name & Email', sortable: true },
                { key: 'mobile_number', label: 'Mobile Number', sortable: true   },
                { key: 'created_at', label: 'Register On', sortable: true   },
                // { key: 'status', label: 'Status', sortable: true },
                // { key: 'account_status', label: 'Account Status', sortable: true },
                { key: 'status', label: 'Account Status', sortable: true },
                // { key: 'ft_account_status', label: 'FT Status', sortable: true },
                // { key: 'account_verification_status', label: 'DP Status', sortable: true },
                { key: 'user_type', label: 'User Type', sortable: true },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            selectedsup:[],
            searchKeyword:'',
            supervisorName:'',
            selectedSupervisor:[],
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            actions:'',
        }
    },
    components: {
                    Multiselect
     },
   filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD MMM YYYY')
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                 this.$refs.datatable.refresh();
                // this.$router.push({ name: 'empManageUserList' });
            });
        },
        customLabel({ first_name, last_name }) {
          return `${last_name} ${first_name}`
        },
         hideModal() {
                 this.$refs['attach-sup-modal'].hide()
            },
        attachSupSubmit(evt) {
          evt.preventDefault();
          this.hideModal();
          this.$store.dispatch(POST_API, {
            data:{
              user_id: this.supervisorName,
              supervisor_id: this.supervisor_id,
            },
            api: "/api/attach-supervisor-to-om",   
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              
              this.success_message = this.$store.getters.getResults.message;
                    
             this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: this.success_message,
                showConfirmButton: false,
                timer: 1500
             })
            }
          });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getEmpUsers() {
            return this.$store.dispatch(POST_API, {
                data:{
                    company_admin: this.user_id,
                    company_id: this.company_id,
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'asc' : 'desc',
                },
                api: '/api/emp-manage-user-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },

        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='User Inactivated successfully.';
                var new_status = 'inactive';
            } 
            else{
                var msg = 'Are you sure you want to activate this record?';
                var message='User Activated successfully.';
                var new_status = 'active';
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            status:new_status,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/emp-manage-user-status' 
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            // this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        changeCompanyUserStatus(id, status) {

            // var status = status != null ? status : this.usrStatus;

            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='User Inactivated Successfully.';
            }
            else if (status == 'deleted' || status == 'inactive') {
                var msg = 'Are you sure you want to activate this record?';
                var message='User Activated Successfully.';
                this.remarks = null

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          id: id,
                          status:status,
                        },
                        api:"/api/admin-emp-comuser-active-inactive",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.showSuccessAlert(message);
                            // this.datatableRefresh();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        deleteCompanyUser(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/admin-empjobseeker-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Employer Deleted successfully.';
                            // this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
         openAttachSupModal(id)
        {
          this.$refs['attach-sup-modal'].show();
          this.supervisor_id = id;

          return this.$store.dispatch(POST_API, {
            data:{
                  user_id: id,
                },
            api:"/api/list-supervisors-to-assign-om",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
              } 
              else {
                  this.selectedSupervisor = this.$store.getters.getResults.data;
                  this.supervisorName = this.$store.getters.getResults.selected;

                  // return this.selectedSupervisor;
              }
          });
        },
        
        permission(){
            // console.log(this.$store.getters,'this.$store.getters.')
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 3 || this.$store.getters.currentUser.user_type_id == 4) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermission(menu,'Manage Jobs');
               console.log(this.actions,'sdgdaction',this.actions.indexOf('cancel job') >= 0)
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 3 || this.$store.getters.currentUser.user_type_id == 4) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        
    },
    mounted() {
        $(".show_filter_arrow .table thead th div").contents().unwrap();
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
        Bus.$emit('active_users');
        this.permission();


    
    },
}
</script>