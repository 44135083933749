<template>
  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Freelancer Timesheet</h1>
        </div>
        <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
        </div> -->
      </div>
    </div>
    <b-row>
        <b-col lg="12">
          <div class="tab-box--head top_custom_head desktop_search_top_div">
            <div class="row">
              <div
                class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
              >
                <div class="search-box custom_search_admin desktop_margin_top_zero">
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = search"
                      v-model="search"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = search"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>
              <div class="col-xl-5 mobile_max_widht_and_flex">
                <label class="timesheet_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Created on</label>       
                <div class="select-service-box date-picker-field clearfix">
                  <!-- <div class="label_div text_align_end_desktop">
                            <label>Date</label>
                          </div> -->
                  <b-form-group id="input-group-7" class="required">
                    <date-range-picker
                          class="web_bottom_zero timesheet_calender_new calender_border transactions_jb_calender calender_div_job_in_job common_width_100"
                          ref="picker"
                          :minDate="minDate"
                          :opens="center"
                          :locale-data="locale"
                          :auto-apply="auto"
                          v-model="dateRange"
                          :ranges="range"
                          @update="dateFilterFunction()"
                        >
                          <div slot="input" slot-scope="picker">
                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                          </div>
                        </date-range-picker>
                  </b-form-group>
                </div>
              </div>
              <div class="col-xl-3 mobile_max_widht_and_flex search_common_margin_bottom_28">
                <b-button
                          v-if="search != null || dateRange.startDate != null && dateRange.endDate != null"
                          @click="resetFilter()"
                          class="mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 mr-3"
                          style="background-color: red"
                          ><span class="mobile_reset">Reset filters</span></b-button
                        >
                <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start"> -->
                  <b-button v-if="show('export')"
                    class="ml-0 customize_filter_btn line_height_22 common_z_index_zero button ripple-effect  desktop_margin_right_zero"
                    @click="exportData()"
                    >Export</b-button
                  >
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="my_new_container show_filter_arrow">
            <b-table
              ref="datatable"
              show-empty
              striped
              hover
              responsive
              :items="getTimesheetListing"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="new_layout_table_like_v1"
            >
              <template #cell(timesheet_id)="items">
                  {{ items.item.timesheet_id}}
              </template>
              <template #cell(title)="items">
                  {{ items.item.job_data_val.title | capitalize}}
              </template>
              <template #cell(job_id)="items">
                  {{ items.item.job_data_val.job_unique_id}}
              </template>
              <template #cell(name)="items">
                <b-link :to=" {name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.candidate_profile.id)}}" target="_blank" style="color: #4c8bf5;">
                  {{items.item.candidate_profile.full_name != null ? items.item.candidate_profile.full_name : '-' | capitalize}}
                </b-link>
              </template>
              <template #cell(created_at)="items">
                  {{ items.item.timesheet_generated_on | date_formate}}
              </template>
              <template #cell(paid_on)="items">
                  {{items.item.user_transactions != null ? items.item.user_transactions.created_at : '' | moment}}
              </template>
              <template #cell(actions)="items">
                          <div class="toggle-action toggle_margin_unset">
                              <b-dropdown class="mx-1" right text="Actions" boundary="window">
                                <b-dropdown-item v-on:click="downloadPdf(items.item.timesheet_url)" v-if="show('download')">View Timesheet</b-dropdown-item>
                              </b-dropdown>
                          </div>
                      </template>
            </b-table>
          </div>
          <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
              <b-form-group id="input-group-4">
                <span class="d-inline-block">Rows per page</span>
                <span class="d-inline-block"
                  ><b-form-select v-model="form.rowsPerPage" @change="getTimesheetListing">
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                  </b-form-select></span
                >
              </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
              <ul>
                <li>
                  <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                </li>
              </ul>
            </div>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              prev-text=""
              next-text=""
              hide-goto-end-buttons
            />
          </nav>
        </b-col>
    </b-row>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import permission from "../../../../../server/permission.js";
import DateRangePicker from 'vue2-daterange-picker'
export default {
  data() {
    return {
      fields: [
        { key: "timesheet_id", label: "Timesheet ID", sortable: true, thStyle: { width: "20%" }  },
        { key: "title", label: "Job Title", sortable: true, thStyle: { width: "20%" }  },
        { key: "job_id", label: "Job ID", sortable: true, thStyle: { width: "15%" }  },
        { key: "name", label: "FL Name", sortable: true, thStyle: { width: "20%" }  },
        { key: "created_at", label: "Created On", sortable: true, thStyle: { width: "20%" }  },
        { key: "actions", label: "Actions", thStyle: { width: "5%" } },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      search: null,
      perPage: 25,
      itemsJobs: null,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,
      month: moment().format('DD MMM YYYY'),
      pageOptions: [25, 50, 75, 100],
      locale: {
        direction: "ltr", //direction of text   
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
        
      },
      dateRange: {
        startDate: null,
        endDate: null
      },
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: false,
      webUrl: process.env.VUE_APP_URL,
      webUrlV1: process.env.VUE_APP_URL_V1,
      form: {
        rowsPerPage: 25,
        status: "",
      },
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
      actionsFreelancer: "",
      user_id:"",
      designation:""

    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    moment: function(date) {
            if (!date) return '-'
            return moment(date).format('DD-MM-YYYY hh:mm A')
     },
    momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
    date_formate: function(date) {
            return moment(date).format('DD-MM-YYYY hh:mm A')
        },
  },
  components: {
    Datepicker,
    DateRangePicker
  },
  methods: {
    customFormatter(date) {
            return moment(date).format('MMM-YYYY');
     },
    exportData() {
      var base_url = this.webUrlV1;
      //var page = this.currentPage;
      var keyword = this.search ? this.search : null;
      //var rowsPerPage = this.form.rowsPerPage;
      var startdate = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : "null";
      var enddate = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : "null";  
      //var sortBy = this.sortBy ? this.sortBy : "date";
      //var sortDirection = this.sortDesc ? "desc" : "asc";
      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/export-timesheet-v1/" +
        keyword +
        "/" + startdate + '/' + enddate;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    getTimesheetListing() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.search ? this.search : null,
            startDate: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
            endDate: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "",
            sortDesc:this.sortDesc,
            sortDirection: this.sortDirection ? "asc" : "desc",
          },
          api: "/api/timesheet-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.totalRows = this.$store.getters.getResults.data.total;
            this.perPage = this.$store.getters.getResults.data.per_page;
            this.from = this.$store.getters.getResults.data.from
            this.to = this.$store.getters.getResults.data.to
              
            return this.items;
          }
        });
    },
    dateFilterFunction()
    {
        this.getTimesheetListing().then(() => {
            this.$refs.datatable.refresh();
        })
    },
    resetFilter() {
        this.search = null;
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.$refs.datatable.refresh();
      },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "FL Timesheet");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    show(action){
        if (this.$store.getters.currentUser.user_type_id == 2) {
            return this.actions.indexOf(action) >= 0 ? true : false ;  
        }else{
            return true;
        }
    },
    downloadPdf(url){
          window.open(url, '_blank');
    },
    showFreelancer(action) {
      return true;
      // if (this.$store.getters.currentUser.user_type_id == 2) {
      //   return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      // } else {
      //   return true;
      // }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
      this.permissionStaff();
  },
};
</script>
