<template>
    <div class="animated fadeIn create_footer">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit EL Membership</h1>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit EL Membership</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading ">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12" class="">
                                    <!-- <h5>Type</h5> -->
                                    <b-form-group id="type-group" label="Type" class="   ">
                                        <b-form-input id="type" v-model="form.type" :readonly="true" class="       "></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" md="12" class="">
                                    <b-form-group :id="form.option_key" :label="form.option_key | capitalize | removeUnderscore" class="required    ">
                                        <b-form-input :id="form.option_value" name="form.option_value" type="text" v-model="form.option_value" class="       ">
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button :to="{name:'el-membership-list'}" variant="light">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import CKEditor from 'ckeditor4-vue';
import Vue from 'vue';

// import { button, input, select } from 'iview';

export default {

    data() {
        return {
            form: {
                option_key: '',
                option_value: '',
                type: ''
            },
            items: null,
            educational_qualifications:[],
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            selectedImage: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            
            delta: undefined,
            editorConfig: {
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
                },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
              // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            }
        
    
        }
    },
    components: {
        ckeditor: CKEditor.component
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            return value.split('_').join(' ');

        },
        date: function(value) {
            if (value == null) {
                return '';
            } else {

                return moment(value).utc().format('DD MMM YYYY hh:mm A');
            }
        },
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'el-membership-list'},
                text: 'EL Memberships',
            },{
                to:null,
                text: 'Edit EL Membership'
            }];
            return item;
        },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        status: this.status,
                        page: this.currentPage,
                        keyword: this.filter,
                        id: this.id,
                    },
                    api: '/api/el-membership-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data;
                        this.form.option_key = this.$store.getters.getResults.data.option_key;
                        this.form.option_value = this.$store.getters.getResults.data.option_value;
                        this.form.type = this.$store.getters.getResults.data.type == 'employer' ? 'Employer' : 'Jobseeker';;
                    }
                });
        },
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.items.feature_id,
                        option_key: this.form.option_key,
                        option_value: this.form.option_value,
                    },
                    api: "/api/el-membership-update",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert();
                    }
                });
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'EL Membership Updated successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'el-membership-list' });
            });
        },
        getEducationalQualification() {
            return this.$store.dispatch(POST_API, {
                    api: '/api/educational-qualification-list-api'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.educational_qualifications = this.$store.getters.getResults.data;
                        return this.educational_qualifications;
                    }
                });
        },
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Edit EL Membership";
        this.getEditData();
        this.getEducationalQualification();
    },
};

</script>
