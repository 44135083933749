<template>
  <div>
    <div class="mobile_employer_header">
            <div class="mobile_logo_with_toggle_webs">
                        <b-link href="" :to="{name: 'jobseekers2'}" class="logo my_custom_logo">
                        <img :src="webUrl+'logo.png'" class="jobseeker_logo" alt="Employer logo">
                        </b-link>
                    <!-- </span> -->
                    <span class="toggle_employer hamburger"  id="hamburger-2" @click="mobileHumbergerWebsite">
                        <!-- <img :src="webUrl+'assets/employerImages/Vector.png'" @click="mobileHumbergerWebsite"> -->
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                    </span>
            </div>
            <div class="mobile_navigation_menu_website">
                <ul>
                    <li class="home_link"><b-link href="" :to="{}">Home</b-link></li>
                    <li @click="showHideMobileDropDownMenuEmployer"><p class="dropdown_custom_arrow main_menu_dropdown_mobile_view_employer">For Jobseeker<span class="material-icons-outlined">expand_more_outlined</span></p>
                        <div class="sub_menu_mobile_view_employer" style="display:none;">
                            <ul class="pb-0 pt-0">
                                <li><b-link href="" :to="{name: 'jobseekers2'}">Jobseekers</b-link></li>
<!--                                  <li><b-link href="" :to="{name: 'signup'}">Signup</b-link></li>
                                <li><b-link href="" :to="{name: 'login'}">Login</b-link></li> -->
                                <li><b-link href="" :to="{name: 'allDailyPaidJoblisting'}">Daily Paid Jobs</b-link></li>
                                <li><b-link href="" :to="{name: 'allJobsListing'}">Full Time Jobs</b-link></li>
                                <li><a :href="'resume-builder'" target="_blank">Resume Builder</a></li>
                            </ul>
                        </div>
                    </li>
                    <li><b-link href="" :to="{name: 'employer2'}">For Employer</b-link></li>
                    <li><b-link href="" :to="{name: 'blog'}">Blog</b-link></li>
                    <li><b-link href="" :to="{name: 'Rewards'}">Rewards</b-link></li>
                    <li><b-link href="" :to="{name: 'aboutUs'}">About Us</b-link></li>
                    <li>
                        <a href="#" @click="$bvModal.show('bv-modal-example')" class="mobile_login_register"
                        >Log In / Sign Up</a
                        >
                    </li>
                </ul>
            </div>
          </div>
  </div>
</template>

<script>

export default{
    data() {
		return {
            webUrl: process.env.VUE_APP_URL
        }
	},
	methods:{
        mobileHumbergerWebsite(){
            $('.mobile_navigation_menu_website').slideToggle();
            $('.mobile_login_register').click(function(){
                $('.mobile_navigation_menu_website').hide();
            });
        },
        showHideMobileDropDownMenuEmployer(){
            $('.sub_menu_mobile_view_employer').slideToggle('fast','swing');
        },
	},
    components:{

    },
	mounted()
    {

    }
}

</script>
