<template>
    <div>
        <div class="input-box">
            <div class="input-row">
                <label for="full_name">Full Name:</label>
                <input id="full_name" v-model="full_name">
            </div>
            <div class="input-row">
                <label for="nric">NRIC/FIN:</label>
                <input id="nric" v-model="nric">
            </div>
            <div class="input-row">
                <label for="mobile_no">Mobile No:</label>
                <input id="mobile_no" v-model="mobile_no">
            </div>
            <div class="input-row">
                <label for="url">URL:</label>
                <input id="url" v-model="url">
            </div>
            <div class="input-row">
                <label for="token">Token:</label>
                <input id="token" v-model="token">
            </div>
            <div class="input-row">
                <button @click.prevent="rpaTrigger">Submit</button>
            </div>
        </div>
    </div>
</template>

<script>
import { POST_API } from '../../store/actions.type';
export default {
    data() {
        return {
            full_name: 'Test Name',
            nric: '1234A',
            mobile_no: '12349876',
            url: '',
            token: '',
        }
    },
    methods: {
        rpaTrigger() {
            this.$store.dispatch(POST_API, {
                data: {
                    full_name: this.full_name,
                    nric: this.nric,
                    mobile_no: this.mobile_no,
                    url: this.url,
                    token: this.token
                },
                api: '/api/rpa-testing'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        console.log(this.error_message);
                    } else {
                        console.log(this.$store.getters.getResults.data)
                    }
                })
// fetch(this.name, {
//     headers: {
//         Authorization: "Bearer " + this.id
//     },
//     method: "GET"
// }).then((res) => {
//     console.log(`res`, res)
// })
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
.input-box {
    width: 60%;
    margin: 0 auto;
    height: 60svh;
    box-shadow: 3px 3px 10px #d6d5d2;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 15px 20px;
    margin-top: 20px;
}

.input-row {
    display: flex;
    flex-wrap: row;
    gap: 15px;

}

.input-row:deep(label) {
    font-size: 16px;
    width: 100px;
    text-align: right;
}

.input-row:deep(input) {
    width: 300px;
}

.input-row:deep(button) {
    box-shadow: 3px 3px 10px #d6d5d2;
    width: 100px;
    height: 32px;
    border-radius: 15px;
}
</style>