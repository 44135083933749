
import jobseekers from '../components/website/jobseeker/Jobseekers.vue';
export default [
    {
      path: '/v2/jobseekers',
      name: 'admin-dashboard',
      route: 'admin-dashboard',
      component: () => import('../components/Dashboard'),
      meta: { requiresAuth: true, hideForAuth:false, showFor:'admin' }
    },
    {
      path: '/jobseekers-old',
      name: 'jobseeker',
      component: jobseekers,
      meta: { requiresAuth: false, hideForAuth:true , showFor:'admin'}
  },
]
