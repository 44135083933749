<template>
  <div>
    <div>
      <div
        class="dashboard-content-inner my_custom_dash employer_manage_job mobile_min_width_1000 desktop_padding_top_zero"
      >
        <!-- Dashboard Headline -->
        <div class="dashboard-headline heading_with_btn manage_jobs_new_layout_btn">
          <h1>Manage Jobs</h1>
          <div class="manage_jobs_new_layout_btn_div">
            <b-link
              class="btn green-border-btn ml-2 text-white desktop_margin_top_zero transparent_btn"
              @click="exportJobsByName()"
              :class="jobDatas.length == 0 ? 'disabled' : ''"
              v-if="showPermissionEM('export job by name')"
              >Export Job By Name</b-link
            >
            <b-link
              class="btn green-border-btn ml-2 text-white desktop_margin_top_zero transparent_btn"
              @click="exportJobs()"
              :class="jobDatas.length == 0 ? 'disabled' : ''"
              v-if="showPermissionEM('export job')"
              >Export Job</b-link
            >
          </div>
          <b-link
            class="post_btn_manage_jobnew_layout btn green-border-btn desktop_margin_top_zero"
            :to="{ name: 'DailyJobPost' }"
            :class="this.acStatus != 'completed' ? 'disabled' : ''"
            v-if="showPermissionEM('create job')"
            >Post Job</b-link
          >
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark employer_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Job</a></li>
              <li>Manage Jobs</li>
            </ul>
          </nav>
        </div>
        <!-- Row -->
        <!-- <div class="row emplpyees_manage_job_emp_row" > -->
        <div class="row">
          <!-- Dashboard Box -->
          <!-- <div class="col-xl-12 mobile_left_right_zero_pdng"> -->
          <div class="col-xl-12">
            <div class="dashboard-box margin-top-0 daily_dashboard_box">
              <!-- Headline -->
              <!--<div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> My Job Listings</h3>
                        </div>-->
              <!-- <div v-if="loading" >
                        <div class = 'loader'>
                        </div> -->

              <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
              <!-- </div> -->
              <!-- <div style="float: right;margin-top: -25px;">
                    <b-link class="btn green-border-btn" :to="{name:'DailyJobPost'}">Post Job</b-link>
                </div> -->
              <!-- <div class="double_btn" style="text-align:left !important"> -->

              <!-- <button>Create Job</button> -->

              <!-- <div class="col-xl-12"> -->

              <!-- </div> -->
              <div class="tab-section daily_job_mange_div manage_job_new_tabs_layout">
                <b-tabs class="my_tab_job remove_bx">
                  <div class="row desktop_search_top_div">
                    <div class="col-xl-12">
                      <div class="row">
                        <!-- <div class="upper_mix_functiobality"> -->
                        <!-- <div class="col-xl-4 col-md-4 mobile_max_width_and_flex mobile_paddding_unset search_div_daily_job new_search_manage_job"> -->
                        <div
                          class="col-xl-4 search_common_margin_bottom_28 submit-field mobile_tab_max_width_flex col-md-4 mobile_max_width_and_flex new_search_div_daily_job new_search_manage_job"
                        >
                          <div
                            class="search-box custom_search_admin new_custom_search_admin_remove"
                          >
                            <div role="group" class="input-group">
                              <!---->
                              <div
                                class="input-group-prepend new_border_color_manage_job search_text_with_btn_manage_job"
                              >
                                <input
                                  id="searchBtn"
                                  type="text"
                                  placeholder="Search by keyword"
                                  v-model="filterSearch"
                                  v-on:keyup.enter="
                                    getList(activeTab, activeApplicantTab, filterSearch)
                                  "
                                  class="form-control"
                                />
                                <b-button class="btn points_events"
                                  ><i
                                    class="fa fa-search"
                                    @click="getList(activeTab, activeApplicantTab, filterSearch)"
                                  ></i
                                ></b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-3 dropdown_daily_job">
                                    <div class="label_div text_align_end_desktop">
                                        <span>Company:</span>
                                    </div>
                                    <div class="input_div select_dropdown">
                                    <select name="cars" id="cars">
                                        <option value="All">All</option>
                                        <option value="saab">Saab</option>
                                        <option value="mercedes">Mercedes</option>
                                        <option value="audi">Audi</option>
                                        </select>
                                    </div>
                                </div> -->

                        <div
                          class="common_mobile_hide col-6 col-sm-6 col-md-6 col-xs-6 search_common_margin_bottom_28 submit-field"
                        >
                          <div class="reset_btn_full_flex">
                            <div
                              class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                            >
                              <label
                                class="new_style_label desktop_margin_bottom_zero new_layout_manage_job_label"
                                >Date Range:</label
                              >
                              <!-- <div class="input_div"> -->
                              <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                <date-range-picker
                                  ref="picker"
                                  :minDate="minDate"
                                  :opens="center"
                                  :locale-data="locale"
                                  :auto-apply="auto"
                                  v-model="dateRange"
                                  :ranges="range"
                                  @update="
                                    changeTabStatus(
                                      activeTab,
                                      activeApplicantTab,
                                      filterSearch,
                                      'datepick'
                                    )
                                  "
                                  class="manage_job_date_claneder date_range_border_color_manage_job common_date_range_picker_new"
                                >
                                  <div slot="input" slot-scope="picker">
                                    {{ picker.startDate | datePicker1 }} -
                                    {{ picker.endDate | datePicker1 }}
                                  </div>
                                </date-range-picker>
                                <span class="input-icon manage_job_icon_input new_icon_input">
                                  <b-img
                                    v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                                    alt="Calander Icon"
                                  ></b-img>
                                </span>
                              </b-form-group>
                              <!-- </div> -->
                              <!-- <div class="input_div">
                                                <input type="date" id="birthday" name="birthday">
                                            </div> -->
                            </div>
                            <!-- <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                                        <button @click="clear" class="">Reset</button>
                                        </div> -->
                          </div>
                        </div>
                        <div class="common_mobile_hide col-xl-2 col-md-2">
                          <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                            <button @click="clear" class="">Reset Filter</button>
                          </div>
                        </div>

                        <div
                          class="common_desktop_hide col-8 col-sm-8 col-md-8 col-xs-8 search_common_margin_bottom_28 submit-field"
                        >
                          <div class="reset_btn_full_flex">
                            <div
                              class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                            >
                              <label
                                class="new_style_label desktop_margin_bottom_zero new_layout_manage_job_label"
                                >Date Range:</label
                              >
                              <!-- <div class="input_div"> -->
                              <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                <date-range-picker
                                  ref="picker"
                                  :minDate="minDate"
                                  :opens="center"
                                  :locale-data="locale"
                                  :auto-apply="auto"
                                  v-model="dateRange"
                                  :ranges="range"
                                  @update="
                                    changeTabStatus(activeTab, activeApplicantTab, filterSearch, '')
                                  "
                                  class="manage_job_date_claneder date_range_border_color_manage_job common_date_range_picker_new"
                                >
                                  <div slot="input" slot-scope="picker">
                                    {{ picker.startDate | datePicker1 }} -
                                    {{ picker.endDate | datePicker1 }}
                                  </div>
                                </date-range-picker>
                                <span class="input-icon manage_job_icon_input new_icon_input">
                                  <b-img
                                    v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                                    alt="Calander Icon"
                                  ></b-img>
                                </span>
                              </b-form-group>
                              <!-- </div> -->
                              <!-- <div class="input_div">
                                                <input type="date" id="birthday" name="birthday">
                                            </div> -->
                            </div>
                            <!-- <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                                        <button @click="clear" class="">Reset</button>
                                        </div> -->
                          </div>
                        </div>
                        <div class="common_desktop_hide col-4 col-sm-4 col-md-4 col-xs-4">
                          <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                            <button @click="clear" class="">Reset Filter</button>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                  <b-tab
                    :title="'IN PROGRESS (' + inprogressCount + ')'"
                    :active="inprogress"
                    @click.prevent="changeTabStatus('inprogress', 'selected', filterSearch, '')"
                    class=""
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li v-if="jobDataVal.assign_user">
                              <button
                                class="demand_btn assign_user_name"
                                style="pointer-events: none"
                              >
                                Assigned User : {{ jobDataVal.assign_user.last_name }}
                                {{ jobDataVal.assign_user.first_name }}
                              </button>
                            </li>
                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.serviceCat.color,
                                  borderColor: jobDataVal.serviceCat.color,
                                }"
                              >
                                {{ jobDataVal.serviceCat.name }}
                              </button>
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>

                            <li v-if="jobDataVal.job_type_id == 2">
                              <button
                                class="demand_btn"
                                style="background: #28a745; pointer-events: none"
                              >
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                @click="openAttachSupModal(jobDataVal.id)"
                                v-if="showPermissionEM('assign user')"
                                >Assign User
                              </b-dropdown-item>
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="
                                  showPermissionEM('duplicate job') &&
                                  jobDataVal.jobInstructionImages.length == 0
                                "
                                >Repost
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-on:click="
                                  showQR(
                                    jobDataVal.job_code,
                                    jobDataVal.job_unique_id,
                                    jobDataVal.id
                                  )
                                "
                                v-if="
                                  jobDataVal.userData &&
                                  jobDataVal.userData.companydata &&
                                  jobDataVal.userData.companydata.companyuser.qr_based_arrival ==
                                    'yes' &&
                                  jobDataVal.start_date + ' ' + jobDataVal.start_time <= addDays
                                "
                                >Show QR Code
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="locationPtuser(jobDataVal.id, jobDataVal.assigned_user)"
                                >Track Users
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                              <!-- <div class="dtl_text" v-if="jobDataVal.userData">
                                <h2>Posted By:</h2>
                                <p class="mb-0">
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.last_name
                                      ? jobDataVal.userData.last_name
                                      : ""
                                  }}
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.first_name
                                      ? jobDataVal.userData.first_name
                                      : ""
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{ jobDataVal.created_at | customFormatterOnecreated }}
                                </p>
                              </div> -->
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->

                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <div class="rate_text"> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- </div> -->
                            <!-- <button class="ack_btn new_ack_btn_divs"  @click="openAttachSupModal(jobDataVal.id)">Assign User</button>
                                                                <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="fa fa-retweet"></i></b-link>
                                                                <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                                <b-button style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays">Show QR Code</b-button>
                                                                <button class="ack_btn new_ack_btn_divs"  @click="locationPtuser(jobDataVal.id, jobDataVal.assigned_user)">Track Users</button>
                                                            -->
                            <!-- <b-link class="button gray ripple-effect ico repost_icon_btn"  @click="openAttachSupModal(jobDataVal.id)" v-if="showPermissionEM('assign user')" title="Assign User" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">person_add_alt_outlined</i></b-link>
                                                            <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link>
                                                            <b-link style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData && jobDataVal.userData.companydata && jobDataVal.userData.companydata.companyuser.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays" class="button gray ripple-effect ico repost_icon_btn" title="Show QR Code" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">qr_code_2_outlined</i></b-link>
                                                            <b-link class="button gray ripple-effect ico repost_icon_btn"  @click="locationPtuser(jobDataVal.id, jobDataVal.assigned_user)" title="Track Users" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">person_search_outlined</i></b-link> -->

                            <!-- </div> -->

                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">
                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->

                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <div class="user_apl">
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >{{ jobDataVal.userJobsselected.length }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <div class="working_completed_section">
                              <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                              ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                            </div>
                          </div>
                          <div class="full_desc_emps">
                            <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} <br> {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} <br> {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                            <div class="single_accordian manage_job_accordian mb-3">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_job_details_inprogress-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="
                                            'accordion_view_job_details_inprogress-' + jobDatakey
                                          "
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <div
                                                    class="comp_heading_div_manage_job"
                                                    v-if="jobDataVal.userData"
                                                  >
                                                    <div class="dtl_text">
                                                      <h2>Posted By:</h2>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.last_name
                                                            ? jobDataVal.userData.last_name
                                                            : ""
                                                        }}
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.first_name
                                                            ? jobDataVal.userData.first_name
                                                            : ""
                                                        }}
                                                      </p>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.created_at
                                                            | customFormatterOnecreated
                                                        }}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                          <!-- <th>Job Posted Date & Time:</th> -->
                                                          <!-- <th>Job Date & Time:</th> -->
                                                          <!-- <th>Job Hours:</th> -->
                                                          <!-- <th>Vacancy:</th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td v-if="jobDataVal.userData">
                                                                                            {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                        </td> -->
                                                          <!-- <td>
                                                                                            {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                        </td> -->
                                                          <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}}</td> -->
                                                          <!-- <td>{{jobDataVal.working_hours}}</td> -->
                                                          <!-- <td>
                                                                                            {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                        </td> -->
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div class="dtl_text">
                                                    <div
                                                      class="large-content-requirement-inprogress"
                                                    >
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-inprogress">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeEyeReveal(jobDatakey)
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address" class="mb-0">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="single_accordian manage_job_accordian">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box">
                                      <b-card no-body>
                                        <b-card-header
                                          header-tag="header"
                                          role="tab"
                                          @click="
                                            changeApplicantTabStatus(
                                              'inprogress',
                                              'selected',
                                              jobDataVal.id,
                                              jobDatakey,
                                              jobDataVal.posted_by
                                            )
                                          "
                                        >
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_candidates_inprogress-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">View candidates</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_candidates_inprogress-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <!-- <div class="col-md-4">
                                              <div class="search-box">
                                                <b-form-group>
                                                  <b-form-input
                                                    id="searchBtn"
                                                    v-model="filterSearchSelected"
                                                    @change="
                                                      changeApplicantTabStatus(
                                                        'inprogress',
                                                        'selected',
                                                        jobDataVal.id,
                                                        jobDatakey,
                                                        jobDataVal.posted_by
                                                      )
                                                    "
                                                    placeholder="Search by Name"
                                                  ></b-form-input>
                                                  <b-button class="search_btn_tw_grd"></b-button>
                                                </b-form-group>
                                              </div>
                                            </div> -->
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <b-tabs
                                                    class="custom_tabs_daily_manage_jobs custom_tabs_daily_manage_jobs_inprogress"
                                                  >
                                                    <div v-if="jobDataVal.userJobsselected">
                                                      <b-tab
                                                        active
                                                        :title="
                                                          'SELECTED (' +
                                                          jobDataVal.userJobsselected.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'inprogress',
                                                            'selected',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <!-- <template #cell(mobile_number)="items"> -->
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .country_code
                                                                          ? items.item.userdetail
                                                                              .country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.userdetail
                                                                          .mobile_number
                                                                          ? items.item.userdetail
                                                                              .mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <!-- </template> -->
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <div class="com_imgs">
                                                                      <img
                                                                        v-if="
                                                                          items.item.completed_count
                                                                            .length > 0 &&
                                                                          jobDataVal.userData &&
                                                                          jobDataVal.userData
                                                                            .companydata &&
                                                                          jobDataVal.userData
                                                                            .companydata
                                                                            .company_logo != null
                                                                        "
                                                                        :src="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        :key="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        alt="User Icon"
                                                                        style="width: 63%"
                                                                        class="innner_round_image_in_tab"
                                                                      />
                                                                    </div>
                                                                    <!-- <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span> -->
                                                                  </div>

                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.timesheet_url }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item
                                                                      .confirm_job_completion_by
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div class="timing_div arrow_time">
                                                                <ul>
                                                                  <li>
                                                                    <i
                                                                      class="fa fa-arrow-down green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.start_time
                                                                        ? items.item.start_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span>
                                                                    <i
                                                                      class="fa fa-arrow-up red_arrow green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.end_time
                                                                        ? items.item.end_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <template #cell(thumb)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#">{{
                                                                  items.item.extended_hours
                                                                    ? "+" +
                                                                      items.item.extended_hours +
                                                                      " Hours @$" +
                                                                      items.item.extension_rate
                                                                    : ""
                                                                }}</a>
                                                              </div>
                                                            </template>

                                                            <template #cell(ac_button)="items">
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.mark_as_arrived == null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn upcoming_btn"
                                                                  style="background-color: #ffbb00"
                                                                >
                                                                  Upcoming
                                                                </button>
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>

                                                                <!-- <b-button class="grey-border-btn">CONFIRM ARRIVAl</b-button> -->
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.mark_as_arrived ==
                                                                    'yes' &&
                                                                  items.item.marked_by == null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn upcoming_btn"
                                                                  style="background-color: #ffbb00"
                                                                >
                                                                  Upcoming
                                                                </button>
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.marked_by != null &&
                                                                  items.item
                                                                    .confirm_job_completion !=
                                                                    'yes' &&
                                                                  items.item.job_status !=
                                                                    'suspended'
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn"
                                                                  style="background-color: #4c8bf5"
                                                                >
                                                                  In Progress
                                                                </button>
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item
                                                                    .confirm_job_completion ==
                                                                    'yes' &&
                                                                  items.item.original_end_time ==
                                                                    null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn"
                                                                  style="background-color: #4c8bf5"
                                                                >
                                                                  In Progress
                                                                </button>
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item
                                                                    .confirm_job_completion ==
                                                                    'yes' &&
                                                                  items.item.original_end_time !=
                                                                    null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn completed_btn"
                                                                  style="background-color: green"
                                                                >
                                                                  Completed
                                                                </button>
                                                                <button
                                                                  class="ack_btn tag_ack_btn"
                                                                  v-if="
                                                                    items.item
                                                                      .rated_by_supervisor != null
                                                                  "
                                                                  style="background-color: green"
                                                                >
                                                                  Rated
                                                                </button>
                                                                <button
                                                                  class="prg_btn"
                                                                  v-if="
                                                                    items.item
                                                                      .rated_by_supervisor == null
                                                                  "
                                                                  @click="
                                                                    ratePtUser(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDataKey
                                                                    )
                                                                  "
                                                                >
                                                                  Rate
                                                                </button>
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                    'suspended' &&
                                                                  items.item.suspended_by != null
                                                                "
                                                              >
                                                                <b-button
                                                                  class="prg_btn tag_ack_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                    'suspended'
                                                                  "
                                                                  style="background-color: red"
                                                                  >Stopped</b-button
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button class="ack_btn" v-if="items.item.acknowledged == 'yes' && items.item.job_status != 'suspended'">ACNOWLEDGED</button>
                                                                                    <button class="prg_btn" v-if="items.item.mark_as_arrived == 'yes' && items.item.job_status != 'suspended'">INPROGRESS</button>
                                                                                    <button class="prg_btn" v-if="items.item.mark_as_arrived != 'yes' && items.item.job_status != 'suspended'">MARK ARRIVED</button>
                                                                                    <button class="prg_btn" v-if="items.item.job_status == 'suspended'" style="background-color:red;">Stopped</button>



                                                                                </div>
                                                                            </template>  -->
                                                            <template
                                                              #cell(toggleAction)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                                v-if="
                                                                  items.item.job_status !=
                                                                    'suspended' &&
                                                                  items.item.extended_hours ==
                                                                    null &&
                                                                  items.item.extended_hours !=
                                                                    'cancelled'
                                                                "
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    @click="
                                                                      extendHour(
                                                                        items.item.user_id,
                                                                        items.item.hourly_cost,
                                                                        items.item.job_id,
                                                                        jobDatakey
                                                                      )
                                                                    "
                                                                    v-if="
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      items.item.extended_hours ==
                                                                        null &&
                                                                      items.item.marked_by !=
                                                                        null &&
                                                                      items.item
                                                                        .original_end_time ==
                                                                        null &&
                                                                      items.item
                                                                        .rated_by_supervisor == null
                                                                    "
                                                                    >Extend Hour
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    @click="
                                                                      suspendApplicant(
                                                                        items.item.user_id,
                                                                        items.item.job_id,
                                                                        jobDataVal.lunch_pay_hours,
                                                                        jobDataVal.lunch_pay_not_provided,
                                                                        jobDataVal.start_date,
                                                                        jobDataVal.start_time,
                                                                        jobDatakey
                                                                      )
                                                                    "
                                                                    v-if="
                                                                      items.item
                                                                        .original_start_time !=
                                                                        null &&
                                                                      items.item.extended_hours ==
                                                                        null &&
                                                                      items.item.job_status ==
                                                                        'in-progress'
                                                                    "
                                                                    >Stop Work
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.mark_as_arrived ==
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'cancelled'
                                                                    "
                                                                    @click="
                                                                      cancelApplicant(
                                                                        items.item.user_id,
                                                                        items.item.job_id,
                                                                        jobDatakey
                                                                      )
                                                                    "
                                                                    >Cancel
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                  'suspended'
                                                                "
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                      'suspended'
                                                                    "
                                                                    @click="
                                                                      viewSuspensionReason(
                                                                        items.item.suspention_reason
                                                                      )
                                                                    "
                                                                    >View Suspension Reason
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div v-if="jobDataVal.userJobsApplication">
                                                      <b-tab
                                                        :title="
                                                          'ALL APPLICANTS (' +
                                                          jobDataVal.userJobsApplication.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'inprogress',
                                                            'application',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>

                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span> -->
                                                                    <div class="com_imgs">
                                                                      <img
                                                                        v-if="
                                                                          items.item.completed_count
                                                                            .length > 0 &&
                                                                          jobDataVal.userData &&
                                                                          jobDataVal.userData
                                                                            .companydata &&
                                                                          jobDataVal.userData
                                                                            .companydata
                                                                            .company_logo != null
                                                                        "
                                                                        :src="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        :key="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        alt="User Icon"
                                                                        style="width: 63%"
                                                                        class="innner_round_image_in_tab"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <!-- <div>
                                                                                                <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                                <p v-else>ETA: NA</p>
                                                                                            </div> -->
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.timesheet_url }}</a
                                                                >
                                                              </div>

                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item
                                                                      .confirm_job_completion_by
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>

                                                            <template #cell(time)="items">
                                                              <div class="phone_nmber_div">
                                                                <p href="#">
                                                                  <span>{{
                                                                    items.item.created_at
                                                                      ? items.item.created_at
                                                                      : "" | moment
                                                                  }}</span>
                                                                  <span>
                                                                    {{
                                                                      items.item.created_at
                                                                        ? items.item.created_at
                                                                        : "" | applicantOriginalSET
                                                                    }}</span
                                                                  >
                                                                </p>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>  -->
                                                            <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>  -->
                                                            <template
                                                              #cell(ac_button)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                                <button
                                                                  @click="
                                                                    awardJob(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey,
                                                                      jobDataVal.posted_by
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: green !important;
                                                                  "
                                                                >
                                                                  Award
                                                                </button>
                                                                <button
                                                                  @click="
                                                                    notSuitable(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: red !important;
                                                                  "
                                                                >
                                                                  Not Suitable
                                                                </button>
                                                              </div>
                                                              <div class="status_btn"></div>
                                                            </template>
                                                            <!-- <template #cell(toggleAction)="items" v-if="items.item.mark_as_arrived == null &&  items.item.withdraw_application == 'no'" >
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                    <b-dropdown-item v-if="items.item.mark_as_arrived == null &&  items.item.withdraw_application == 'no'" @click="cancelApplicant(items.item.id)">CANCEL
                                                                                        </b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div v-if="jobDataVal.userJobsCancelled">
                                                      <b-tab
                                                        :title="
                                                          'CANCELLED (' +
                                                          jobDataVal.userJobsCancelled.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'inprogress',
                                                            'cancelled',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .country_code
                                                                          ? items.item.userdetail
                                                                              .country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.userdetail
                                                                          .mobile_number
                                                                          ? items.item.userdetail
                                                                              .mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.timesheet_url }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item
                                                                      .confirm_job_completion_by
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div
                                                                class="timing_div cancelled_div_tb"
                                                              >
                                                                <ul>
                                                                  <li>
                                                                    <span
                                                                      ><b
                                                                        >Cancelled Reason:</b
                                                                      ></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancellation_reason
                                                                        ? items.item
                                                                            .cancellation_reason
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                  <br />
                                                                  <li>
                                                                    <span
                                                                      ><b>Cancelled At:</b></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancelled_at
                                                                        ? items.item.cancelled_at
                                                                        : "" | customFormatterOne
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>  -->

                                                            <!-- <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <!-- <b-tab :title="'APPLICANT WIDTHDRAWN (' + jobDataVal.userJobsApplicationWithdrawn.length + ')'"  class="reject_last_li mobile_min_width_1000" @click="changeApplicantTabStatus('inprogress','applicant_withdrawn',jobDataVal.id,jobDatakey,jobDataVal.posted_by)">
                                                                    <div v-if="jobDataVal.userJobs.length > 0">
                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_image_job">
                                                                                    <div class="daily_job_manage_img_div">
                                                                                        <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                    </div>
                                                                                    <div class="daily_job_manage_user_description_div">
                                                                                        <b-link :to="{name: 'employer-jobseeker-dp-profile-view', params: {id: encrypt(items.item.user_id)}}" target="_blank"><h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2></b-link>
                                                                                        <div class="star-rating">
                                                                                            <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                        </div>
                                                                                            <div>
                                                                                                <p v-if="items.item.proposed_arrival_time != null  && jobDataVal.job_type_id == 2">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            </div>
                                                                                    </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number1)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">Applied - {{items.item.timesheet_url}}</a>
                                                                            </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li>Applied On<span>{{items.item.created_at ? items.item.created_at : '' | customFormatterOne}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                            <li v-if="items.item.acknowledged == 'yes' && items.item.job_status != 'suspended'"><b>ACNOWLEDGED</b></li>

                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </div>

                                                                    <div v-else>
                                                                        <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center">
                                                                        <p class="actionData">No Record Found</p>
                                                                    </div>
                                                                    </div>
                                                                </b-tab>
                                                                <b-tab :title="' SYSTEM WIDTHDRAWN (' + jobDataVal.userJobsSystemWithdrawn.length + ')'" class="reject_last_li mobile_min_width_1000" @click="changeApplicantTabStatus('inprogress','system_withdrawn',jobDataVal.id,jobDatakey,jobDataVal.posted_by)">
                                                                    <div v-if="jobDataVal.userJobs.length > 0">

                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_image_job">
                                                                                    <div class="daily_job_manage_img_div">
                                                                                        <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                    </div>
                                                                                    <div class="daily_job_manage_user_description_div">
                                                                                    <b-link :to="{name: 'employer-jobseeker-dp-profile-view', params: {id: encrypt(items.item.user_id)}}" target="_blank"> <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2></b-link>
                                                                                        <div class="star-rating">
                                                                                            <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                        </div>
                                                                                            <div>
                                                                                                <p v-if="items.item.proposed_arrival_time != null && jobDataVal.job_type_id == 2">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            </div>
                                                                                    </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number1)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">Applied - {{items.item.timesheet_url}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantOriginalSET}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                            <li v-if="items.item.acknowledged == 'yes' && items.item.job_status != 'suspended'"><b>ACNOWLEDGED</b></li>

                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </div>
                                                                    <div v-else>
                                                                        <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center">
                                                                        <p class="actionData">No Record Found</p>
                                                                    </div>
                                                                    </div>
                                                                </b-tab> -->
                                                    <div v-if="jobDataVal.ratings">
                                                      <b-tab
                                                        :title="
                                                          ' RATE & REVIEW (' +
                                                          jobDataVal.ratings.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'inprogress',
                                                            'rate_review',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.ratings"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.rated_user
                                                                        .profile_image != null
                                                                        ? items.item.rated_user
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.rated_user
                                                                          .average_rating
                                                                          ? items.item.rated_user
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <h2 v-if="items.item.rated_user">
                                                                    {{
                                                                      items.item.rated_user
                                                                        .last_name
                                                                        ? items.item.rated_user
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.rated_user
                                                                        .first_name
                                                                        ? items.item.rated_user
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="star-rating"></div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number)="items">
                                                              <star-rating
                                                                v-model="items.item.rating"
                                                                v-bind:star-size="20"
                                                                :show-rating="false"
                                                              ></star-rating>
                                                            </template>
                                                            <template #cell(time)="items">
                                                              <div class="timing_div">
                                                                <ul>
                                                                  <li>
                                                                    <span>{{
                                                                      items.item.review
                                                                        ? items.item.review
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>

                                                            <!-- <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>

                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div
                        class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                        v-if="jobDatas.length == 0"
                      >
                        <p class="actionData">No Record Found</p>
                      </div> -->
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData"><strong>No Record Found</strong></p>
                    </div> -->
                  </b-tab>
                  <b-tab
                    :title="'UPCOMING (' + upcomingCount + ')'"
                    :active="upcoming"
                    @click.prevent="changeTabStatus('upcoming', 'selected', filterSearch, '')"
                    class="reject_last_li"
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul new_com_id_ulz">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li v-if="jobDataVal.assign_user">
                              <button
                                class="demand_btn assign_user_name"
                                style="pointer-events: none"
                              >
                                Assigned User : {{ jobDataVal.assign_user.last_name }}
                                {{ jobDataVal.assign_user.first_name }}
                              </button>
                            </li>
                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.serviceCat.color,
                                  borderColor: jobDataVal.serviceCat.color,
                                }"
                              >
                                {{ jobDataVal.serviceCat.name }}
                              </button>
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>

                            <li v-if="jobDataVal.job_type_id == 2">
                              <button
                                class="demand_btn"
                                style="background: #28a745; pointer-events: none"
                              >
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                @click="openAttachSupModal(jobDataVal.id)"
                                v-if="showPermissionEM('assign user')"
                                >Assign User
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-on:click="cancelJob(jobDataVal.id)"
                                v-if="showPermissionEM('cancel job')"
                                >Cancel Job
                              </b-dropdown-item>
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="
                                  showPermissionEM('duplicate job') &&
                                  jobDataVal.jobInstructionImages.length == 0
                                "
                                >Repost
                              </b-dropdown-item>
                              <b-dropdown-item
                                :to="{
                                  name: 'DailyJobEdit',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="showPermissionEM('edit job')"
                                >Edit
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-on:click="
                                  showQR(
                                    jobDataVal.job_code,
                                    jobDataVal.job_unique_id,
                                    jobDataVal.id
                                  )
                                "
                                v-if="
                                  jobDataVal.userData &&
                                  jobDataVal.userData.companydata &&
                                  jobDataVal.userData.companydata.companyuser.qr_based_arrival ==
                                    'yes' &&
                                  jobDataVal.start_date + ' ' + jobDataVal.start_time <= addDays
                                "
                                >Show QR Code
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                              <!-- <div class="dtl_text" v-if="jobDataVal.userData">
                                <h2>Posted By:</h2>
                                <p class="mb-0">
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.last_name
                                      ? jobDataVal.userData.last_name
                                      : ""
                                  }}
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.first_name
                                      ? jobDataVal.userData.first_name
                                      : ""
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{ jobDataVal.created_at | customFormatterOnecreated }}
                                </p>
                              </div> -->
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->
                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <button class="ack_btn new_ack_btn_divs"  @click="openAttachSupModal(jobDataVal.id)">Assign User</button>
                                                            <b-button style="background-color:#rgb(216 43 37);color: white;" v-on:click="cancelJob(jobDataVal.id)">CANCEL JOB</b-button>
                                                            <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="fa fa-retweet"></i></b-link>
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                            <b-button style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays">Show QR Code</b-button> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- <b-link class="button gray ripple-effect ico repost_icon_btn" title="Assign User" data-tippy-placement="top" @click="openAttachSupModal(jobDataVal.id)" v-if="showPermissionEM('assign user')"><i class="material-icons-outlined assign_users_i">person_add_alt_outlined</i></b-link>
                                                            <b-link style="background-color:#rgb(216 43 37);color: white;" v-on:click="cancelJob(jobDataVal.id)" v-if="showPermissionEM('cancel job')" class="button gray ripple-effect ico repost_icon_btn" title="Cancel Job" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">cancel_outlined</i></b-link>
                                                            <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link>
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('edit job')" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">border_color_outlined</i></b-link>
                                                            <b-link style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData && jobDataVal.userData.companydata &&  jobDataVal.userData.companydata.companyuser.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays" class="button gray ripple-effect ico repost_icon_btn" title="Show QR Code" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">qr_code_2_outlined</i></b-link> -->
                            <!-- </div> -->
                            <!-- <div class="status_btn">
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                    </div> -->
                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">
                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->

                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <div class="user_apl">
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >{{ jobDataVal.userJobsselected.length }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <div class="working_completed_section">
                              <span>Awarded: {{ jobDataVal.awardCount.length }}</span
                              ><span>Pending: {{ jobDataVal.pendingCount.length }}</span>
                            </div>
                          </div>
                          <div class="full_desc_emps">
                            <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                            <div class="single_accordian manage_job_accordian mb-3">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_job_details_upcoming-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_job_details_upcoming-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <div
                                                    class="comp_heading_div_manage_job"
                                                    v-if="jobDataVal.userData"
                                                  >
                                                    <div class="dtl_text">
                                                      <h2>Posted By:</h2>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.last_name
                                                            ? jobDataVal.userData.last_name
                                                            : ""
                                                        }}
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.first_name
                                                            ? jobDataVal.userData.first_name
                                                            : ""
                                                        }}
                                                      </p>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.created_at
                                                            | customFormatterOnecreated
                                                        }}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                          <!-- <th>Job Posted Date & Time:</th> -->
                                                          <!-- <th>Job Date & Time:</th> -->
                                                          <!-- <th>Job Hours:</th> -->
                                                          <!-- <th>Vacancy:</th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td v-if="jobDataVal.userData">
                                                                                              {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                          </td> -->
                                                          <!-- <td>
                                                                                              {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                          </td> -->
                                                          <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                          <td>{{jobDataVal.working_hours}}</td>
                                                                                          <td>
                                                                                              {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                          </td> -->
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div class="dtl_text">
                                                    <div class="large-content-requirement-upcoming">
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_upcoming_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementUpcomingEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-upcoming">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_upcoming_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeUpcomingEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address" class="mb-0">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="single_accordian manage_job_accordian">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_candidates_upcoming-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                            @click="
                                              changeApplicantTabStatus(
                                                'upcoming',
                                                'selected',
                                                jobDataVal.id,
                                                jobDatakey,
                                                jobDataVal.posted_by
                                              )
                                            "
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">View candidates</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_candidates_upcoming-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <!-- <div class="col-md-4">
                                              <div class="search-box">
                                                <b-form-group>
                                                  <b-form-input
                                                    id="searchBtn"
                                                    v-model="filterSearchSelected"
                                                    @change="
                                                      changeApplicantTabStatus(
                                                        'upcoming',
                                                        'selected',
                                                        jobDataVal.id,
                                                        jobDatakey,
                                                        jobDataVal.posted_by
                                                      )
                                                    "
                                                    placeholder="Search by Name"
                                                  ></b-form-input>
                                                  <b-button class="search_btn_tw_grd"></b-button>
                                                </b-form-group>
                                              </div>
                                            </div> -->
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <b-tabs
                                                    class="custom_tabs_daily_manage_jobs custom_tabs_daily_manage_jobs_upcoming"
                                                  >
                                                    <div v-if="jobDataVal.userJobsselected">
                                                      <b-tab
                                                        :title="
                                                          'SELECTED (' +
                                                          jobDataVal.userJobsselected.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'upcoming',
                                                            'selected',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .country_code
                                                                          ? items.item.userdetail
                                                                              .country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.userdetail
                                                                          .mobile_number
                                                                          ? items.item.userdetail
                                                                              .mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                                <span class="star"></span>
                                                                                                <span class="star"></span>
                                                                                                <span class="star"></span>
                                                                                                <span class="star"></span> -->
                                                                    <div class="com_imgs">
                                                                      <img
                                                                        v-if="
                                                                          items.item.completed_count
                                                                            .length > 0 &&
                                                                          jobDataVal.userData &&
                                                                          jobDataVal.userData
                                                                            .companydata &&
                                                                          jobDataVal.userData
                                                                            .companydata
                                                                            .company_logo != null
                                                                        "
                                                                        :src="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        :key="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        alt="User Icon"
                                                                        style="width: 63%"
                                                                        class="innner_round_image_in_tab"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>

                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.timesheet_url }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item
                                                                      .confirm_job_completion_by
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                                <div class="phone_nmber_div">
                                                                                    <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                                </div>
                                                                                </template> -->

                                                            <!-- <template #cell(thumb)="items">
                                                                                    <div class="thumb_div">
                                                                                    <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                                </div>
                                                                                </template>  -->
                                                            <template #cell(ac_button)="items">
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                                <button
                                                                  class="ack_btn upcoming_btn"
                                                                >
                                                                  {{
                                                                    items.item.job_status
                                                                      | capitalize
                                                                  }}
                                                                </button>
                                                              </div>
                                                            </template>
                                                            <template
                                                              #cell(toggleAction)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                  'upcoming'
                                                                "
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                      'upcoming'
                                                                    "
                                                                    @click="
                                                                      cancelApplicant(
                                                                        items.item.user_id,
                                                                        items.item.job_id,
                                                                        jobDatakey
                                                                      )
                                                                    "
                                                                    >Cancel
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div v-if="jobDataVal.userJobsApplication">
                                                      <b-tab
                                                        :title="
                                                          'ALL APPLICANTS (' +
                                                          jobDataVal.userJobsApplication.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'upcoming',
                                                            'application',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>

                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span> -->

                                                                    <div class="com_imgs">
                                                                      <img
                                                                        v-if="
                                                                          items.item.completed_count
                                                                            .length > 0 &&
                                                                          jobDataVal.userData &&
                                                                          jobDataVal.userData
                                                                            .companydata &&
                                                                          jobDataVal.userData
                                                                            .companydata
                                                                            .company_logo != null
                                                                        "
                                                                        :src="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        :key="
                                                                          jobDataVal.userData
                                                                            .companydata.thumbnail
                                                                            ? jobDataVal.userData
                                                                                .companydata
                                                                                .thumbnail
                                                                            : jobDataVal.userData
                                                                                .companydata
                                                                                .company_logo
                                                                        "
                                                                        alt="User Icon"
                                                                        style="width: 63%"
                                                                        class="innner_round_image_in_tab"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <!-- <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p> -->
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.timesheet_url }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item
                                                                      .confirm_job_completion_by
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>

                                                            <template #cell(time)="items">
                                                              <div class="timing_div">
                                                                <p href="#">
                                                                  <span>Applied On: </span>
                                                                  <span>{{
                                                                    items.item.created_at
                                                                      ? items.item.created_at
                                                                      : "" | applicantOriginalSET1
                                                                  }}</span>
                                                                </p>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>  -->
                                                            <template
                                                              #cell(ac_button)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>

                                                                <button
                                                                  @click="
                                                                    notSuitable(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: red !important;
                                                                  "
                                                                >
                                                                  Not Suitable
                                                                </button>
                                                                <button
                                                                  @click="
                                                                    awardJob(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey,
                                                                      jobDataVal.posted_by
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: green !important;
                                                                  "
                                                                >
                                                                  Award
                                                                </button>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>

                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div v-if="jobDataVal.userJobsCancelled">
                                                      <b-tab
                                                        :title="
                                                          'CANCELLED (' +
                                                          jobDataVal.userJobsCancelled.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'upcoming',
                                                            'cancelled',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .country_code
                                                                          ? items.item.userdetail
                                                                              .country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.userdetail
                                                                          .mobile_number
                                                                          ? items.item.userdetail
                                                                              .mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.timesheet_url }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item
                                                                      .confirm_job_completion_by
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div
                                                                class="timing_div cancelled_div_tb"
                                                              >
                                                                <ul>
                                                                  <li>
                                                                    <span
                                                                      ><b
                                                                        >Cancelled Reason:</b
                                                                      ></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancellation_reason
                                                                        ? items.item
                                                                            .cancellation_reason
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                  <br />
                                                                  <li>
                                                                    <span
                                                                      ><b>Cancelled At:</b></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancelled_at
                                                                        ? items.item.cancelled_at
                                                                        : "" | customFormatterOne
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>

                                                            <!-- <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <!-- <b-tab :title="'APPLICANT WIDTHDRAWN (' + jobDataVal.userJobsApplicationWithdrawn.length + ')'"  class="reject_last_li" @click="changeApplicantTabStatus('upcoming','applicant_withdrawn',jobDataVal.id,jobDatakey)">
                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li>Applied On<span>{{items.item.created_at ? items.item.created_at : '' | customFormatterOne}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">

                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </b-tab>
                                                                    <b-tab :title="' SYSTEM WIDTHDRAWN (' + jobDataVal.userJobsSystemWithdrawn.length + ')'" class="reject_last_li" @click="changeApplicantTabStatus('upcoming','system_withdrawn',jobDataVal.id,jobDatakey)">
                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button class="ack_btn">ACNOWLEDGED</button>
                                                                                    <button class="prg_btn">INPROGRESS</button>
                                                                                    <button class="comp_tbn">MAKE COMPLETION</button>
                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </b-tab>
                                                                    <b-tab :title="' RATE & REVIEW (' + jobDataVal.ratings.length + ')'"  class="reject_last_li" @click="changeApplicantTabStatus('upcoming','selected',jobDataVal.id,jobDatakey)">
                                                                    <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button class="ack_btn">ACNOWLEDGED</button>
                                                                                    <button class="prg_btn">INPROGRESS</button>
                                                                                    <button class="comp_tbn">MAKE COMPLETION</button>
                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </b-tab> -->
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData"><strong>No Record Found</strong></p>
                    </div> -->
                  </b-tab>
                  <b-tab
                    :title="'COMPLETED'"
                    :active="completed"
                    @click.prevent="changeTabStatus('completed', 'selected', filterSearch, '')"
                    class="reject_last_li"
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li v-if="jobDataVal.assign_user">
                              <button
                                class="demand_btn assign_user_name"
                                style="pointer-events: none"
                              >
                                Assigned User : {{ jobDataVal.assign_user.last_name }}
                                {{ jobDataVal.assign_user.first_name }}
                              </button>
                            </li>
                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.serviceCat.color,
                                  borderColor: jobDataVal.serviceCat.color,
                                }"
                              >
                                {{ jobDataVal.serviceCat.name }}
                              </button>
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>

                            <li v-if="jobDataVal.job_type_id == 2">
                              <button
                                class="demand_btn"
                                style="background: #28a745; pointer-events: none"
                              >
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="
                                  showPermissionEM('duplicate job') &&
                                  jobDataVal.jobInstructionImages.length == 0
                                "
                                >Repost
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->
                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                            <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->

                            <!-- <div class="task-count-text" v-if="jobDataVal.pdf_url != null && showPermissionEM('show sr') && showJobListRateAdmin('show_job_list_rate')">
                                                                <div class="small_pdf_box">
                                                                    <img :src="webUrl+ 'assets/images/pdf.png'" style="max-height: 36px;" @click="pdfViewer(jobDataVal.pdf_url)" >
                                                                </div>
                                                            </div> -->
                            <!-- </div> -->
                            <!-- <div class="status_btn">
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                    </div> -->
                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">

                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->
                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <div class="user_apl">
                            <img
                              :src="webUrl + 'assets/images/pdf.png'"
                              v-if="
                                jobDataVal.pdf_url != null &&
                                showPermissionEM('show sr') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                              style="max-height: 36px"
                              @click="pdfViewer(jobDataVal.pdf_url)"
                              class="pdf_manage_job"
                            />
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >{{ jobDataVal.userJobsselected.length }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <!-- <div class="working_completed_section">
                              <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                              ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                            </div> -->
                          </div>
                          <div class="full_desc_emps">
                            <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                            <div class="single_accordian manage_job_accordian mb-3">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_job_details_completed-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_job_details_completed-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <div
                                                    class="comp_heading_div_manage_job"
                                                    v-if="jobDataVal.userData"
                                                  >
                                                    <div class="dtl_text">
                                                      <h2>Posted By:</h2>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.last_name
                                                            ? jobDataVal.userData.last_name
                                                            : ""
                                                        }}
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.first_name
                                                            ? jobDataVal.userData.first_name
                                                            : ""
                                                        }}
                                                      </p>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.created_at
                                                            | customFormatterOnecreated
                                                        }}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                          <!-- <th>Job Posted Date & Time:</th> -->
                                                          <!-- <th>Job Date & Time:</th>
                                                                                          <th>Job Hours:</th>
                                                                                          <th>Vacancy:</th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td v-if="jobDataVal.userData">
                                                                                              {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                          </td> -->
                                                          <!-- <td>
                                                                                              {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                          </td> -->
                                                          <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                          <td>{{jobDataVal.working_hours}}</td>
                                                                                          <td>
                                                                                              {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                          </td> -->
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div class="dtl_text">
                                                    <div
                                                      class="large-content-requirement-completed"
                                                    >
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_completed_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementCompletedEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-completed' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-completed">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_completed_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeCompletedEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-completed' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address" class="mb-0">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="single_accordian manage_job_accordian">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_candidates_completed-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                            @click="
                                              changeApplicantTabStatus(
                                                'completed',
                                                'selected',
                                                jobDataVal.id,
                                                jobDatakey,
                                                jobDataVal.posted_by
                                              )
                                            "
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">View candidates</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_candidates_completed-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <b-tabs
                                                    class="custom_tabs_daily_manage_jobs custom_tabs_daily_manage_jobs_completed"
                                                  >
                                                    <div v-if="jobDataVal.userJobsselected">
                                                      <b-tab
                                                        :title="
                                                          'SELECTED (' +
                                                          jobDataVal.userJobsselected.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'completed',
                                                            'selected',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                    ><h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .country_code
                                                                          ? items.item.userdetail
                                                                              .country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.userdetail
                                                                          .mobile_number
                                                                          ? items.item.userdetail
                                                                              .mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>

                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div class="timing_div arrow_time">
                                                                <ul>
                                                                  <li>
                                                                    <i
                                                                      class="fa fa-arrow-down green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.start_time
                                                                        ? items.item.start_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span
                                                                    ><i
                                                                      class="fa fa-arrow-up red_arrow green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.end_time
                                                                        ? items.item.end_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <template #cell(thumb)="items">
                                                              <div class="thumb_div">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                              </div>
                                                            </template>
                                                            <template
                                                              #cell(ac_button)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div class="status_btn">
                                                                <b-button
                                                                  class="ack_btn tag_ack_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                      'completed' &&
                                                                    items.item
                                                                      .rated_by_supervisor == 'yes'
                                                                  "
                                                                  >Rated</b-button
                                                                >
                                                                <b-button
                                                                  class="prg_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                      'completed' &&
                                                                    items.item
                                                                      .rated_by_supervisor != 'yes'
                                                                  "
                                                                  @click="
                                                                    ratePtUser(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDataKey
                                                                    )
                                                                  "
                                                                  >Rate</b-button
                                                                >
                                                                <b-button
                                                                  class="red-bg comp_tbn tag_ack_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                    'suspended'
                                                                  "
                                                                  style="background-color: red"
                                                                  >Stopped</b-button
                                                                >
                                                                <!-- <b-button class="comp_tbn" v-if="items.item.timesheet_url != null && charge_type != 'fixed_rate'"  @click="pdfViewer(items.item.timesheet_url)" >Timesheet</b-button> -->
                                                              </div>
                                                            </template>
                                                            <template
                                                              #cell(toggleAction)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                  'suspended'
                                                                "
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    @click="
                                                                      viewSuspensionReason(
                                                                        items.item.suspention_reason
                                                                      )
                                                                    "
                                                                    >View Suspension Reason
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>

                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div v-if="jobDataVal.userJobsCancelled">
                                                      <b-tab
                                                        :title="
                                                          'CANCELLED (' +
                                                          jobDataVal.userJobsCancelled.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'completed',
                                                            'cancelled',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.userJobs"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper cancelled_div_tb"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.userdetail
                                                                        .profile_image != null
                                                                        ? items.item.userdetail
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .average_rating
                                                                          ? items.item.userdetail
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                    ><h2>
                                                                      {{
                                                                        items.item.userdetail
                                                                          .last_name
                                                                          ? items.item.userdetail
                                                                              .last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.userdetail
                                                                          .first_name
                                                                          ? items.item.userdetail
                                                                              .first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.userdetail
                                                                          .country_code
                                                                          ? items.item.userdetail
                                                                              .country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.userdetail
                                                                          .mobile_number
                                                                          ? items.item.userdetail
                                                                              .mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item
                                                                        .awarded_user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .awarded_user_badges"
                                                                      :key="us"
                                                                      :src="
                                                                        usb.badge_data.badge_image
                                                                      "
                                                                      :title="usb.badge_data.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        items.item
                                                                          .proposed_arrival_time !=
                                                                        null
                                                                          ? items.item
                                                                              .proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>

                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div class="timing_div">
                                                                <ul>
                                                                  <li>
                                                                    <span
                                                                      ><b
                                                                        >Cancelled Reason:</b
                                                                      ></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancellation_reason
                                                                        ? items.item
                                                                            .cancellation_reason
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                  <br />
                                                                  <li>
                                                                    <span
                                                                      ><b>Cancelled At:</b></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancelled_at
                                                                        ? items.item.cancelled_at
                                                                        : "" | customFormatterOne
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(thumb)="items">
                                                                  <div class="thumb_div">
                                                                    <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                  </div>
                                                                </template> -->
                                                            <template #cell(ac_button)="items">
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                                <button class="comp_tbn">
                                                                  AWARDED
                                                                </button>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div v-if="jobDataVal.ratings">
                                                      <b-tab
                                                        :title="
                                                          ' RATE & REVIEW (' +
                                                          jobDataVal.ratings.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'completed',
                                                            'rate_review',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.userJobs &&
                                                            jobDataVal.userJobs.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.ratings"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.rated_user
                                                                        .profile_image != null
                                                                        ? items.item.rated_user
                                                                            .profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.rated_user
                                                                          .average_rating
                                                                          ? items.item.rated_user
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <h2 v-if="items.item.rated_user">
                                                                    {{
                                                                      items.item.rated_user
                                                                        .last_name
                                                                        ? items.item.rated_user
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.rated_user
                                                                        .first_name
                                                                        ? items.item.rated_user
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="star-rating"></div>
                                                                </div>
                                                              </div>
                                                            </template>

                                                            <template #cell(mobile_number)="items">
                                                              <star-rating
                                                                v-model="items.item.rating"
                                                                v-bind:star-size="20"
                                                                :show-rating="false"
                                                              ></star-rating>
                                                            </template>
                                                            <template #cell(time)="items">
                                                              <div class="timing_div">
                                                                <ul>
                                                                  <li>
                                                                    <span>{{
                                                                      items.item.review
                                                                        ? items.item.review
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>

                                                            <!-- <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData"><strong>No Record Found</strong></p>
                    </div> -->
                  </b-tab>
                  <b-tab
                    :title="'CANCELLED'"
                    :active="cancelled"
                    @click.prevent="
                      changeTabStatus('cancelled', 'applicationCancel', filterSearch, '')
                    "
                    class="reject_last_li"
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.serviceCat.color,
                                  borderColor: jobDataVal.serviceCat.color,
                                }"
                              >
                                {{ jobDataVal.serviceCat.name }}
                              </button>
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>
                            <li v-if="jobDataVal.job_type_id == 2">
                              <button class="demand_btn" style="background: #28a745">
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="
                                  showPermissionEM('duplicate job') &&
                                  jobDataVal.jobInstructionImages.length == 0
                                "
                                >Repost
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                              <!-- <div class="dtl_text" v-if="jobDataVal.userData">
                                <h2>Posted By:</h2>
                                <p class="mb-0">
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.last_name
                                      ? jobDataVal.userData.last_name
                                      : ""
                                  }}
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.first_name
                                      ? jobDataVal.userData.first_name
                                      : ""
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{ jobDataVal.created_at | customFormatterOnecreated }}
                                </p>
                              </div> -->
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->
                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                            <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->
                            <!-- </div> -->
                            <!-- <div class="status_btn">
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                    </div> -->
                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">
                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->
                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <div class="user_apl" v-if="jobDataVal.userJobsCancelledCount.length > 0">
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >{{ jobDataVal.userJobsCancelledCount.length }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <!-- <div class="working_completed_section">
                              <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                              ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                            </div> -->
                          </div>
                          <div class="full_desc_emps">
                            <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                            <div class="single_accordian manage_job_accordian mb-3">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_job_details_cancelled-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_job_details_cancelled-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <div
                                                    class="comp_heading_div_manage_job"
                                                    v-if="jobDataVal.userData"
                                                  >
                                                    <div class="dtl_text">
                                                      <h2>Posted By:</h2>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.last_name
                                                            ? jobDataVal.userData.last_name
                                                            : ""
                                                        }}
                                                        {{
                                                          jobDataVal.userData &&
                                                          jobDataVal.userData.first_name
                                                            ? jobDataVal.userData.first_name
                                                            : ""
                                                        }}
                                                      </p>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.created_at
                                                            | customFormatterOnecreated
                                                        }}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                          <!-- <th>Job Posted Date & Time:</th> -->
                                                          <!-- <th>Job Date & Time:</th>
                                                                                        <th>Job Hours:</th>
                                                                                        <th>Vacancy:</th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person Name:
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td v-if="jobDataVal.userData">
                                                                                            {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                        </td> -->
                                                          <!-- <td>
                                                                                            {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                        </td> -->
                                                          <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                        <td>{{jobDataVal.working_hours}}</td>
                                                                                        <td>
                                                                                            {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                        </td> -->
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div class="dtl_text">
                                                    <div
                                                      class="large-content-requirement-cancelled"
                                                    >
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_cancelled_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementCancelledEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-cancelled' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-cancelled' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-cancelled' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-cancelled">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_cancelled_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeCancelledEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-cancelled' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-cancelled' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-cancelled' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                    <p class="mb-0">
                                                      <strong>Cancellation Reason - </strong
                                                      >{{ jobDataVal.cancellation_reason }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="single_accordian manage_job_accordian">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_candidates_cancelled-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                            @click="
                                              changeApplicantTabStatus(
                                                'cancelled',
                                                'applicationCancel',
                                                jobDataVal.id,
                                                jobDatakey,
                                                jobDataVal.posted_by
                                              )
                                            "
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">View candidates</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_candidates_cancelled-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <b-tabs class="custom_tabs_daily_manage_jobs">
                                                    <div v-if="jobDataVal.userJobsCancelled">
                                                      <b-tab
                                                        :title="
                                                          'ALL APPLICANTS (' +
                                                          jobDataVal.userJobsCancelled.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'cancelled',
                                                            'applicationCancel',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <b-table
                                                          ref="datatable"
                                                          show-empty
                                                          striped
                                                          hover
                                                          responsive
                                                          :items="jobDataVal.userJobs"
                                                          :fields="fields"
                                                          :filter="filter"
                                                          :sort-by.sync="sortBy"
                                                          :sort-desc.sync="sortDesc"
                                                          :sort-direction="sortDirection"
                                                          class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                        >
                                                          <template #cell(name)="items">
                                                            <div class="daily_image_job">
                                                              <div
                                                                class="daily_job_manage_img_div"
                                                                v-if="items.item.userdetail"
                                                              >
                                                                <b-img
                                                                  :src="
                                                                    items.item.userdetail
                                                                      .profile_image != null
                                                                      ? items.item.userdetail
                                                                          .profile_image
                                                                      : webUrl +
                                                                        'assets/images/user-avatar-small-01.png'
                                                                  "
                                                                  alt="User Icon"
                                                                  height="100px"
                                                                  width="100px"
                                                                  class="user_img_emp"
                                                                ></b-img>
                                                                <div
                                                                  class="custom_star_rate_manage_job"
                                                                >
                                                                  <span
                                                                    ><i class="fa fa-star"></i
                                                                    >{{
                                                                      items.item.userdetail
                                                                        .average_rating
                                                                        ? items.item.userdetail
                                                                            .average_rating
                                                                        : 0
                                                                    }}</span
                                                                  >
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="daily_job_manage_user_description_div"
                                                              >
                                                                <b-link
                                                                  v-if="
                                                                    showPermissionEM('view profile')
                                                                  "
                                                                  target="_blank"
                                                                  :to="{
                                                                    name: 'employer-jobseeker-dp-profile-view',
                                                                    params: {
                                                                      id: encrypt(
                                                                        items.item.user_id
                                                                      ),
                                                                    },
                                                                  }"
                                                                  ><h2>
                                                                    {{
                                                                      items.item.userdetail
                                                                        .last_name
                                                                        ? items.item.userdetail
                                                                            .last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.userdetail
                                                                        .first_name
                                                                        ? items.item.userdetail
                                                                            .first_name
                                                                        : ""
                                                                    }}
                                                                  </h2></b-link
                                                                >
                                                                <h2 v-else>
                                                                  {{
                                                                    items.item.userdetail.last_name
                                                                      ? items.item.userdetail
                                                                          .last_name
                                                                      : ""
                                                                  }}
                                                                  {{
                                                                    items.item.userdetail.first_name
                                                                      ? items.item.userdetail
                                                                          .first_name
                                                                      : ""
                                                                  }}
                                                                </h2>

                                                                <div class="star-rating">
                                                                  <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                </div>
                                                                <div>
                                                                  <p
                                                                    v-if="
                                                                      items.item
                                                                        .proposed_arrival_time !=
                                                                        null &&
                                                                      jobDataVal.job_type_id == 2
                                                                    "
                                                                  >
                                                                    ETA:
                                                                    {{
                                                                      items.item
                                                                        .proposed_arrival_time !=
                                                                      null
                                                                        ? items.item
                                                                            .proposed_arrival_time
                                                                        : "" | moment
                                                                    }}
                                                                    |
                                                                    {{
                                                                      items.item
                                                                        .proposed_arrival_time !=
                                                                      null
                                                                        ? items.item
                                                                            .proposed_arrival_time
                                                                        : "" | applicantOriginalSET
                                                                    }}
                                                                  </p>
                                                                  <!-- <p v-else>ETA: NA</p> -->
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </template>

                                                          <template #cell(time)="items">
                                                            <div
                                                              class="timing_div cancelled_div_tb"
                                                            >
                                                              <ul>
                                                                <li>
                                                                  <span
                                                                    ><b>Cancelled Reason:</b></span
                                                                  >
                                                                  <span>{{
                                                                    items.item.cancellation_reason
                                                                      ? items.item
                                                                          .cancellation_reason
                                                                      : ""
                                                                  }}</span>
                                                                </li>
                                                                <br />
                                                                <li>
                                                                  <span><b>Cancelled At:</b></span
                                                                  ><span>{{
                                                                    items.item.cancelled_at
                                                                      ? items.item.cancelled_at
                                                                      : "" | customFormatterOne
                                                                  }}</span>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </template>
                                                          <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template> -->
                                                          <template #cell(ac_button)="items">
                                                            <div class="status_btn flex_item_start">
                                                              <li
                                                                v-if="
                                                                  items.item.acknowledged_count > 0
                                                                "
                                                              >
                                                                <b
                                                                  >Acknowleged -
                                                                  {{
                                                                    items.item.acknowledged_count
                                                                  }}
                                                                </b>
                                                              </li>
                                                              <button
                                                                class="comp_tbn"
                                                                v-if="items.item.awarded_by != null"
                                                              >
                                                                Awarded
                                                              </button>
                                                            </div>
                                                          </template>
                                                        </b-table>
                                                      </b-tab>
                                                    </div>

                                                    <!-- <b-tab :title="'APPLICATIONS (' + jobDataVal.userJobsApplication.length + ')'"  class="reject_last_li" @click="changeApplicantTabStatus('cancelled','application',jobDataVal.id,jobDatakey)">
                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button @click="notSuitable(items.item.user_id, items.item.job_id)" class="ack_btn">Not Suitable</button>

                                                                                </div>
                                                                            </template>
                                                                            <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template>
                                                                        </b-table>
                                                                    </b-tab>
                                                                    -->
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData"><strong>No Record Found</strong></p>
                    </div> -->
                  </b-tab>
                  <b-tab
                    :title="'ALL'"
                    :active="all"
                    @click="changeTabStatusAll('all', 'selected')"
                    class="reject_last_li"
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul">
                            <li>{{ jobDataVal.job_unique_id }}</li>
                            <li v-if="jobDataVal.job_unique_id == 2">
                              <button class="demand_btn">ON DEMAND JOB</button>
                            </li>
                            <li>SRVICE AMT: S${{ jobDataVal.service_fee }}</li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                href=""
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                class=""
                                v-if="jobDataVal.jobInstructionImages.length == 0"
                                >Repost
                              </b-dropdown-item>
                              <b-dropdown-item
                                href=""
                                :to="{
                                  name: 'DailyJobEdit',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                class=""
                                >Edit</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                              <!-- <div class="dtl_text" v-if="jobDataVal.userData">
                                <h2>Posted By:</h2>
                                <p class="mb-0">
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.last_name
                                      ? jobDataVal.userData.last_name
                                      : ""
                                  }}
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.first_name
                                      ? jobDataVal.userData.first_name
                                      : ""
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{ jobDataVal.created_at | customFormatterOnecreated }}
                                </p>
                              </div> -->
                            </div>
                          </div>
                          <!-- <div class="rate_div new_rate_divs">
                            <div class="status_btn new_status_btn_divs">
                                <b-link
                                href=""
                                :to="{
                                    name: 'repostJobDaily',
                                    params: { id: encrypt(jobDataVal.id) },
                                }"
                                class="button gray ripple-effect ico repost_icon_btn"
                                title="Repost"
                                data-tippy-placement="top"
                                ><i class="fa fa-retweet"></i
                                ></b-link>
                                <b-link
                                href=""
                                :to="{ name: 'DailyJobEdit', params: { id: encrypt(jobDataVal.id) } }"
                                class="button gray ripple-effect ico edit_icon_btn"
                                title="Edit"
                                data-tippy-placement="top"
                                ><i class="fa fa-edit"></i
                                ></b-link>
                            </div>

                            <div
                                class="rate_text"
                                v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                                "
                            >
                                <h2>S${{ jobDataVal.hourly_cost }}/HR</h2>
                            </div>
                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.start_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <div class="user_apl">
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >{{ jobDataVal.userJobsselected.length }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <!-- <div class="working_completed_section">
                                <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                                ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                                </div> -->
                          </div>
                          <div class="single_accordian manage_job_accordian mb-3">
                            <!-- <br> -->
                            <div>
                              <div class="faq-content-box clearfix">
                                <div>
                                  <div class="accordion-listing-box coloured_icon">
                                    <b-card no-body>
                                      <b-card-header header-tag="header" role="tab">
                                        <b-button
                                          v-b-toggle="
                                            'accordion_view_job_details_all-' + jobDatakey
                                          "
                                          class="m-1"
                                          variant="info"
                                        >
                                          <div class="dashboard-box">
                                            <div class="headline">
                                              <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                            </div>
                                          </div>
                                        </b-button>
                                      </b-card-header>
                                      <b-collapse
                                        :id="'accordion_view_job_details_all-' + jobDatakey"
                                        role="tabpanel"
                                      >
                                        <b-card-body>
                                          <div class="">
                                            <div class="row">
                                              <div class="col-xl-12">
                                                <div
                                                  class="comp_heading_div_manage_job"
                                                  v-if="jobDataVal.userData"
                                                >
                                                  <div class="dtl_text">
                                                    <h2>Posted By:</h2>
                                                    <p class="mb-0">
                                                      {{
                                                        jobDataVal.userData &&
                                                        jobDataVal.userData.last_name
                                                          ? jobDataVal.userData.last_name
                                                          : ""
                                                      }}
                                                      {{
                                                        jobDataVal.userData &&
                                                        jobDataVal.userData.first_name
                                                          ? jobDataVal.userData.first_name
                                                          : ""
                                                      }}
                                                    </p>
                                                    <p class="mb-0">
                                                      {{
                                                        jobDataVal.created_at
                                                          | customFormatterOnecreated
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="full_desc_emps">
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th>Posted By:</th>
                                                                    <th>Job Posted Date & Time:</th>
                                                                    <th>Job Duration:</th>
                                                                    <th>Job Hours:</th>
                                                                    <th>Vacancy:</th>
                                                                    <th v-if="jobDataVal.work_site_contact_person">
                                                                        Work Site Contact Person Name:
                                                                    </th>
                                                                    <th v-if="jobDataVal.work_site_contact_person_mobile_no">
                                                                        Work Site Contact Person Number:
                                                                    </th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td>
                                                                        {{
                                                                        jobDataVal.userData && jobDataVal.userData.last_name
                                                                            ? jobDataVal.userData.last_name
                                                                            : ""
                                                                        }}
                                                                        {{
                                                                        jobDataVal.userData && jobDataVal.userData.first_name
                                                                            ? jobDataVal.userData.first_name
                                                                            : ""
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{ jobDataVal.start_date | moment }}
                                                                        {{ jobDataVal.start_time | jobStartEndTime }}
                                                                    </td>
                                                                    <td>
                                                                        {{ jobDataVal.start_date | moment }}
                                                                        {{ jobDataVal.start_time | jobStartEndTime }} -
                                                                        {{ jobDataVal.start_time | jobStartEndTime }}
                                                                    </td>
                                                                    <td>{{ jobDataVal.working_hours }}</td>
                                                                    <td>
                                                                        {{ jobDataVal.userJobs.length }}/{{
                                                                        jobDataVal.total_vacancies
                                                                        }}
                                                                    </td>
                                                                    <td v-if="jobDataVal.work_site_contact_person">
                                                                        {{
                                                                        jobDataVal.work_site_contact_person
                                                                            ? jobDataVal.work_site_contact_person
                                                                            : ""
                                                                        }}
                                                                    </td>
                                                                    <td v-if="jobDataVal.work_site_contact_person_mobile_no">
                                                                        {{
                                                                        jobDataVal.work_site_contact_person_country_code
                                                                            ? jobDataVal.work_site_contact_person_country_code
                                                                            : ""
                                                                        }}-{{
                                                                        jobDataVal.work_site_contact_person_mobile_no
                                                                            ? jobDataVal.work_site_contact_person_mobile_no
                                                                            : ""
                                                                        }}
                                                                    </td> -->

                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <!-- <div class="dtl_text">
                                                                <h2 v-if="jobDataVal.description">Job Requirements</h2>
                                                                <p>{{ jobDataVal.description }}</p>
                                                                <h2 v-if="jobDataVal.job_scope">Job Scope:</h2>
                                                                <p>{{ jobDataVal.job_scope }}</p>
                                                                <p v-if="jobDataVal.address">
                                                                <i class="fa fa-map-marker"></i>{{ jobDataVal.address }}
                                                                </p>
                                                                <p v-if="jobDataVal.lunch_pay_hours">
                                                                <strong>Note:</strong>Break hour not paid -
                                                                {{ jobDataVal.lunch_pay_hours }} Hr
                                                                </p>
                                                            </div> -->
                                                  <div class="dtl_text">
                                                    <div class="large-content-requirement-all">
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_eye_icon_all' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementAllEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-all' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-all' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-all' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-all">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_eye_icon_all' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeAllEyeReveal(jobDatakey)
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-all' + jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-all' + jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-all' + jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address" class="mb-0">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="single_accordian manage_job_accordian">
                            <!-- <br> -->
                            <div>
                              <div class="faq-content-box clearfix">
                                <div>
                                  <div class="accordion-listing-box coloured_icon">
                                    <b-card no-body>
                                      <b-card-header header-tag="header" role="tab">
                                        <b-button
                                          v-b-toggle="'accordion_view_candidates_all-' + jobDatakey"
                                          class="m-1"
                                          variant="info"
                                          @click="
                                            changeApplicantTabStatus(
                                              'all',
                                              'selected',
                                              jobDataVal.id,
                                              jobDatakey,
                                              jobDataVal.posted_by
                                            )
                                          "
                                        >
                                          <div class="dashboard-box">
                                            <div class="headline">
                                              <h3 class="heading_section_h3">View candidates</h3>
                                            </div>
                                          </div>
                                        </b-button>
                                      </b-card-header>
                                      <b-collapse
                                        :id="'accordion_view_candidates_all-' + jobDatakey"
                                        role="tabpanel"
                                      >
                                        <b-card-body>
                                          <div class="">
                                            <div class="row">
                                              <div class="col-xl-12">
                                                <b-tabs
                                                  class="custom_tabs_daily_manage_jobs custom_tabs_daily_manage_jobs_all"
                                                >
                                                  <div v-if="jobDataVal.userJobsselected">
                                                    <b-tab
                                                      :title="
                                                        'SELECTED (' +
                                                        jobDataVal.userJobsselected.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li"
                                                      @click="
                                                        changeApplicantTabStatus(
                                                          'all',
                                                          'selected',
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          jobDataVal.posted_by
                                                        )
                                                      "
                                                    >
                                                      <b-table
                                                        ref="datatable"
                                                        show-empty
                                                        striped
                                                        hover
                                                        responsive
                                                        :items="jobDataVal.userJobs"
                                                        :fields="fields"
                                                        :filter="filter"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper manage_job_min_width_all_tab"
                                                      >
                                                        <template #cell(name)="items">
                                                          <div class="daily_image_job">
                                                            <div class="daily_job_manage_img_div">
                                                              <b-img
                                                                :src="
                                                                  items.item.userdetail
                                                                    .profile_image != null
                                                                    ? items.item.userdetail
                                                                        .profile_image
                                                                    : webUrl +
                                                                      'assets/images/user-avatar-small-01.png'
                                                                "
                                                                alt="User Icon"
                                                                height="100px"
                                                                width="100px"
                                                                class="user_img_emp"
                                                              ></b-img>
                                                              <div
                                                                class="custom_star_rate_manage_job"
                                                              >
                                                                <span
                                                                  ><i class="fa fa-star"></i
                                                                  >{{
                                                                    items.item.userdetail
                                                                      .average_rating
                                                                      ? items.item.userdetail
                                                                          .average_rating
                                                                      : 0
                                                                  }}</span
                                                                >
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="daily_job_manage_user_description_div"
                                                            >
                                                              <h2>
                                                                {{
                                                                  items.item.userdetail.last_name
                                                                    ? items.item.userdetail
                                                                        .last_name
                                                                    : ""
                                                                }}
                                                                {{
                                                                  items.item.userdetail.first_name
                                                                    ? items.item.userdetail
                                                                        .first_name
                                                                    : ""
                                                                }}
                                                              </h2>
                                                              <!-- <div class="star-rating">
                                                                      <star-rating
                                                                        v-model="items.item.userdetail.average_rating"
                                                                        v-bind:star-size="20"
                                                                        :show-rating="false"
                                                                      ></star-rating>
                                                                    </div> -->
                                                              <div>
                                                                <div class="phone_nmber_div">
                                                                  <a href="#"
                                                                    >{{
                                                                      items.item.userdetail
                                                                        .country_code
                                                                        ? items.item.userdetail
                                                                            .country_code
                                                                        : ""
                                                                    }}-{{
                                                                      items.item.userdetail
                                                                        .mobile_number
                                                                        ? items.item.userdetail
                                                                            .mobile_number
                                                                        : ""
                                                                    }}</a
                                                                  >
                                                                </div>
                                                                <p
                                                                  v-if="
                                                                    items.item
                                                                      .proposed_arrival_time != null
                                                                  "
                                                                >
                                                                  ETA:
                                                                  {{
                                                                    items.item
                                                                      .proposed_arrival_time != null
                                                                      ? items.item
                                                                          .proposed_arrival_time
                                                                      : "" | moment
                                                                  }}
                                                                  |
                                                                  {{
                                                                    items.item
                                                                      .proposed_arrival_time != null
                                                                      ? items.item
                                                                          .proposed_arrival_time
                                                                      : "" | applicantOriginalSET
                                                                  }}
                                                                </p>
                                                                <p v-else>ETA: NA</p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </template>
                                                        <!-- <template #cell(mobile_number)="items">
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.userdetail.country_code
                                                                          ? items.item.userdetail.country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.userdetail.mobile_number
                                                                          ? items.item.userdetail.mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                </template> -->
                                                        <template #cell(time)="items">
                                                          <div class="timing_div arrow_time">
                                                            <ul>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_start_time
                                                                    ? items.item.original_start_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up red_arrow green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_end_time
                                                                    ? items.item.original_end_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span>
                                                              </li>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.start_time
                                                                    ? items.item.start_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.end_time
                                                                    ? items.item.end_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </template>
                                                        <template #cell(thumb)="items">
                                                          <div class="thumb_div">
                                                            <i
                                                              class="fa fa-thumbs-up blue_thumb"
                                                            ></i>
                                                          </div>
                                                        </template>
                                                        <template #cell(ac_button)="items">
                                                          <div class="status_btn">
                                                            <button class="ack_btn">
                                                              ACNOWLEDGED
                                                            </button>
                                                            <button class="prg_btn">
                                                              INPROGRESS
                                                            </button>
                                                            <button class="comp_tbn">
                                                              MAKE COMPLETION
                                                            </button>
                                                          </div>
                                                        </template>
                                                      </b-table>
                                                    </b-tab>
                                                  </div>
                                                  <div v-if="jobDataVal.userJobsApplication">
                                                    <b-tab
                                                      :title="
                                                        'APPLICATIONS (' +
                                                        jobDataVal.userJobsApplication.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li"
                                                      @click="
                                                        changeApplicantTabStatus(
                                                          'all',
                                                          'application',
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          jobDataVal.posted_by
                                                        )
                                                      "
                                                    >
                                                      <b-table
                                                        ref="datatable"
                                                        show-empty
                                                        striped
                                                        hover
                                                        responsive
                                                        :items="jobDataVal.userJobs"
                                                        :fields="fields"
                                                        :filter="filter"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                      >
                                                        <template #cell(name)="items">
                                                          <div class="daily_job_manage_img_div">
                                                            <b-img
                                                              :src="
                                                                items.item.userdetail
                                                                  .profile_image != null
                                                                  ? items.item.userdetail
                                                                      .profile_image
                                                                  : webUrl +
                                                                    'assets/images/user-avatar-small-01.png'
                                                              "
                                                              alt="User Icon"
                                                              height="100px"
                                                              width="100px"
                                                              class="user_img_emp"
                                                            ></b-img>
                                                          </div>
                                                          <div
                                                            class="daily_job_manage_user_description_div"
                                                          >
                                                            <h2>
                                                              {{
                                                                items.item.userdetail.last_name
                                                                  ? items.item.userdetail.last_name
                                                                  : ""
                                                              }}
                                                              {{
                                                                items.item.userdetail.first_name
                                                                  ? items.item.userdetail.first_name
                                                                  : ""
                                                              }}
                                                            </h2>
                                                            <div class="star-rating">
                                                              <star-rating
                                                                v-model="
                                                                  items.item.userdetail
                                                                    .average_rating
                                                                "
                                                                v-bind:star-size="20"
                                                                :show-rating="false"
                                                              ></star-rating>
                                                            </div>
                                                            <div>
                                                              <p
                                                                v-if="
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                "
                                                              >
                                                                ETA:
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | moment
                                                                }}
                                                                |
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | applicantOriginalSET
                                                                }}
                                                              </p>
                                                              <p v-else>ETA: NA</p>
                                                            </div>
                                                          </div>
                                                        </template>
                                                        <template #cell(mobile_number)="items">
                                                          <div class="phone_nmber_div">
                                                            <a href="#"
                                                              >{{
                                                                items.item.userdetail.country_code
                                                                  ? items.item.userdetail
                                                                      .country_code
                                                                  : ""
                                                              }}-{{
                                                                items.item.userdetail.mobile_number
                                                                  ? items.item.userdetail
                                                                      .mobile_number
                                                                  : ""
                                                              }}</a
                                                            >
                                                          </div>
                                                        </template>
                                                        <template #cell(time)="items">
                                                          <div class="timing_div arrow_time">
                                                            <ul>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_start_time
                                                                    ? items.item.original_start_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up red_arrow green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_end_time
                                                                    ? items.item.original_end_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span>
                                                              </li>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.start_time
                                                                    ? items.item.start_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.end_time
                                                                    ? items.item.end_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </template>
                                                        <template #cell(thumb)="items">
                                                          <div class="thumb_div">
                                                            <i
                                                              class="fa fa-thumbs-up blue_thumb"
                                                            ></i>
                                                          </div>
                                                        </template>
                                                        <template #cell(ac_button)="items">
                                                          <div class="status_btn">
                                                            <button
                                                              @click="
                                                                notSuitable(
                                                                  items.item.user_id,
                                                                  items.item.job_id,
                                                                  jobDatakey
                                                                )
                                                              "
                                                              class="ack_btn"
                                                            >
                                                              Not Suitable
                                                            </button>
                                                          </div>
                                                        </template>
                                                        <template #cell(toggleAction)="items">
                                                          <div
                                                            class="toggle-action custom_toggle_three_dot dp_job_action"
                                                          >
                                                            <b-dropdown
                                                              class="mx-1"
                                                              right
                                                              text=""
                                                              boundary="window"
                                                            >
                                                              <b-dropdown-item
                                                                :to="{
                                                                  name: 'empManageUserEdit',
                                                                  params: {
                                                                    id: encrypt(items.item.id),
                                                                  },
                                                                }"
                                                                >Edit
                                                              </b-dropdown-item>
                                                              <b-dropdown-item
                                                                :to="{
                                                                  name: 'emp-manage-user-view',
                                                                  params: {
                                                                    id: encrypt(items.item.id),
                                                                  },
                                                                }"
                                                                >View Profile
                                                              </b-dropdown-item>
                                                              <b-dropdown-item
                                                                v-on:click="
                                                                  deleteEmployer(items.item.id)
                                                                "
                                                                >Delete</b-dropdown-item
                                                              >
                                                            </b-dropdown>
                                                          </div>
                                                        </template>
                                                      </b-table>
                                                    </b-tab>
                                                  </div>
                                                  <div v-if="jobDataVal.userJobsCancelled">
                                                    <b-tab
                                                      :title="
                                                        'CANCELLED (' +
                                                        jobDataVal.userJobsCancelled.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li"
                                                      @click="
                                                        changeApplicantTabStatus(
                                                          'all',
                                                          'cancelled',
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          jobDataVal.posted_by
                                                        )
                                                      "
                                                    >
                                                      <b-table
                                                        ref="datatable"
                                                        show-empty
                                                        striped
                                                        hover
                                                        responsive
                                                        :items="jobDataVal.userJobs"
                                                        :fields="fields"
                                                        :filter="filter"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                      >
                                                        <template #cell(name)="items">
                                                          <div class="daily_job_manage_img_div">
                                                            <b-img
                                                              :src="
                                                                items.item.userdetail
                                                                  .profile_image != null
                                                                  ? items.item.userdetail
                                                                      .profile_image
                                                                  : webUrl +
                                                                    'assets/images/user-avatar-small-01.png'
                                                              "
                                                              alt="User Icon"
                                                              height="100px"
                                                              width="100px"
                                                              class="user_img_emp"
                                                            ></b-img>
                                                          </div>
                                                          <div
                                                            class="daily_job_manage_user_description_div"
                                                          >
                                                            <h2>
                                                              {{
                                                                items.item.userdetail.last_name
                                                                  ? items.item.userdetail.last_name
                                                                  : ""
                                                              }}
                                                              {{
                                                                items.item.userdetail.first_name
                                                                  ? items.item.userdetail.first_name
                                                                  : ""
                                                              }}
                                                            </h2>
                                                            <div class="star-rating">
                                                              <star-rating
                                                                v-model="
                                                                  items.item.userdetail
                                                                    .average_rating
                                                                "
                                                                v-bind:star-size="20"
                                                                :show-rating="false"
                                                              ></star-rating>
                                                            </div>
                                                            <div>
                                                              <p
                                                                v-if="
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                "
                                                              >
                                                                ETA:
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | moment
                                                                }}
                                                                |
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | applicantOriginalSET
                                                                }}
                                                              </p>
                                                              <p v-else>ETA: NA</p>
                                                            </div>
                                                          </div>
                                                        </template>
                                                        <template #cell(mobile_number)="items">
                                                          <div class="phone_nmber_div">
                                                            <a href="#"
                                                              >{{
                                                                items.item.userdetail.country_code
                                                                  ? items.item.userdetail
                                                                      .country_code
                                                                  : ""
                                                              }}-{{
                                                                items.item.userdetail.mobile_number
                                                                  ? items.item.userdetail
                                                                      .mobile_number
                                                                  : ""
                                                              }}</a
                                                            >
                                                          </div>
                                                        </template>
                                                        <template #cell(time)="items">
                                                          <div class="timing_div cancelled_div_tb">
                                                            <ul>
                                                              <li>
                                                                <span><b>Cancelled Reason:</b></span
                                                                ><span>{{
                                                                  items.item.cancellation_reason
                                                                    ? items.item.cancellation_reason
                                                                    : ""
                                                                }}</span>
                                                              </li>
                                                              <li>
                                                                <span><b>Cancelled At:</b></span
                                                                ><span>{{
                                                                  items.item.cancelled_at
                                                                    ? items.item.cancelled_at
                                                                    : "" | customFormatterOne
                                                                }}</span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </template>
                                                        <template #cell(thumb)="items">
                                                          <div class="thumb_div">
                                                            <i
                                                              class="fa fa-thumbs-up blue_thumb"
                                                            ></i>
                                                          </div>
                                                        </template>
                                                        <template #cell(ac_button)="items">
                                                          <div class="status_btn">
                                                            <button class="ack_btn">
                                                              ACNOWLEDGED
                                                            </button>
                                                            <button class="comp_tbn">
                                                              AWARDED
                                                            </button>
                                                          </div>
                                                        </template>
                                                      </b-table>
                                                    </b-tab>
                                                  </div>
                                                  <div
                                                    v-if="jobDataVal.userJobsApplicationWithdrawn"
                                                  >
                                                    <b-tab
                                                      :title="
                                                        'APPLICANT WIDTHDRAWN (' +
                                                        jobDataVal.userJobsApplicationWithdrawn
                                                          .length +
                                                        ')'
                                                      "
                                                      class="reject_last_li"
                                                      @click="
                                                        changeApplicantTabStatus(
                                                          'all',
                                                          'applicant_withdrawn',
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          jobDataVal.posted_by
                                                        )
                                                      "
                                                    >
                                                      <b-table
                                                        ref="datatable"
                                                        show-empty
                                                        striped
                                                        hover
                                                        responsive
                                                        :items="jobDataVal.userJobs"
                                                        :fields="fields"
                                                        :filter="filter"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                      >
                                                        <template #cell(name)="items">
                                                          <div class="daily_job_manage_img_div">
                                                            <b-img
                                                              :src="
                                                                items.item.userdetail
                                                                  .profile_image != null
                                                                  ? items.item.userdetail
                                                                      .profile_image
                                                                  : webUrl +
                                                                    'assets/images/user-avatar-small-01.png'
                                                              "
                                                              alt="User Icon"
                                                              height="100px"
                                                              width="100px"
                                                              class="user_img_emp"
                                                            ></b-img>
                                                          </div>
                                                          <div
                                                            class="daily_job_manage_user_description_div"
                                                          >
                                                            <h2>
                                                              {{
                                                                items.item.userdetail.last_name
                                                                  ? items.item.userdetail.last_name
                                                                  : ""
                                                              }}
                                                              {{
                                                                items.item.userdetail.first_name
                                                                  ? items.item.userdetail.first_name
                                                                  : ""
                                                              }}
                                                            </h2>
                                                            <div class="star-rating">
                                                              <star-rating
                                                                v-model="
                                                                  items.item.userdetail
                                                                    .average_rating
                                                                "
                                                                v-bind:star-size="20"
                                                                :show-rating="false"
                                                              ></star-rating>
                                                            </div>
                                                            <div>
                                                              <p
                                                                v-if="
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                "
                                                              >
                                                                ETA:
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | moment
                                                                }}
                                                                |
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | applicantOriginalSET
                                                                }}
                                                              </p>
                                                              <p v-else>ETA: NA</p>
                                                            </div>
                                                          </div>
                                                        </template>
                                                        <template #cell(mobile_number)="items">
                                                          <div class="phone_nmber_div">
                                                            <a href="#"
                                                              >{{
                                                                items.item.userdetail.country_code
                                                                  ? items.item.userdetail
                                                                      .country_code
                                                                  : ""
                                                              }}-{{
                                                                items.item.userdetail.mobile_number
                                                                  ? items.item.userdetail
                                                                      .mobile_number
                                                                  : ""
                                                              }}</a
                                                            >
                                                          </div>
                                                        </template>
                                                        <template #cell(time)="items">
                                                          <div class="timing_div">
                                                            <ul>
                                                              <li>
                                                                Applied On<span>{{
                                                                  items.item.created_at
                                                                    ? items.item.created_at
                                                                    : "" | customFormatterOne
                                                                }}</span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </template>
                                                        <template #cell(thumb)="items">
                                                          <div class="thumb_div">
                                                            <i
                                                              class="fa fa-thumbs-up blue_thumb"
                                                            ></i>
                                                          </div>
                                                        </template>
                                                        <template #cell(ac_button)="items">
                                                          <div class="status_btn"></div>
                                                        </template>
                                                      </b-table>
                                                    </b-tab>
                                                  </div>
                                                  <div v-if="jobDataVal.userJobsSystemWithdrawn">
                                                    <b-tab
                                                      :title="
                                                        ' SYSTEM WIDTHDRAWN (' +
                                                        jobDataVal.userJobsSystemWithdrawn.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li"
                                                      @click="
                                                        changeApplicantTabStatus(
                                                          'all',
                                                          'system_withdrawn',
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          jobDataVal.posted_by
                                                        )
                                                      "
                                                    >
                                                      <b-table
                                                        ref="datatable"
                                                        show-empty
                                                        striped
                                                        hover
                                                        responsive
                                                        :items="jobDataVal.userJobs"
                                                        :fields="fields"
                                                        :filter="filter"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                      >
                                                        <template #cell(name)="items">
                                                          <div class="daily_job_manage_img_div">
                                                            <b-img
                                                              :src="
                                                                items.item.userdetail
                                                                  .profile_image != null
                                                                  ? items.item.userdetail
                                                                      .profile_image
                                                                  : webUrl +
                                                                    'assets/images/user-avatar-small-01.png'
                                                              "
                                                              alt="User Icon"
                                                              height="100px"
                                                              width="100px"
                                                              class="user_img_emp"
                                                            ></b-img>
                                                          </div>
                                                          <div
                                                            class="daily_job_manage_user_description_div"
                                                          >
                                                            <h2>
                                                              {{
                                                                items.item.userdetail.last_name
                                                                  ? items.item.userdetail.last_name
                                                                  : ""
                                                              }}
                                                              {{
                                                                items.item.userdetail.first_name
                                                                  ? items.item.userdetail.first_name
                                                                  : ""
                                                              }}
                                                            </h2>
                                                            <div class="star-rating">
                                                              <star-rating
                                                                v-model="
                                                                  items.item.userdetail
                                                                    .average_rating
                                                                "
                                                                v-bind:star-size="20"
                                                                :show-rating="false"
                                                              ></star-rating>
                                                            </div>
                                                            <div>
                                                              <p
                                                                v-if="
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                "
                                                              >
                                                                ETA:
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | moment
                                                                }}
                                                                |
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | applicantOriginalSET
                                                                }}
                                                              </p>
                                                              <p v-else>ETA: NA</p>
                                                            </div>
                                                          </div>
                                                        </template>
                                                        <template #cell(mobile_number)="items">
                                                          <div class="phone_nmber_div">
                                                            <a href="#"
                                                              >{{
                                                                items.item.userdetail.country_code
                                                                  ? items.item.userdetail
                                                                      .country_code
                                                                  : ""
                                                              }}-{{
                                                                items.item.userdetail.mobile_number
                                                                  ? items.item.userdetail
                                                                      .mobile_number
                                                                  : ""
                                                              }}</a
                                                            >
                                                          </div>
                                                        </template>
                                                        <template #cell(time)="items">
                                                          <div class="timing_div arrow_time">
                                                            <ul>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_start_time
                                                                    ? items.item.original_start_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up red_arrow green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_end_time
                                                                    ? items.item.original_end_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span>
                                                              </li>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.start_time
                                                                    ? items.item.start_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.end_time
                                                                    ? items.item.end_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </template>
                                                        <template #cell(thumb)="items">
                                                          <div class="thumb_div">
                                                            <i
                                                              class="fa fa-thumbs-up blue_thumb"
                                                            ></i>
                                                          </div>
                                                        </template>
                                                        <template #cell(ac_button)="items">
                                                          <div class="status_btn">
                                                            <button class="ack_btn">
                                                              ACNOWLEDGED
                                                            </button>
                                                            <button class="prg_btn">
                                                              INPROGRESS
                                                            </button>
                                                            <button class="comp_tbn">
                                                              MAKE COMPLETION
                                                            </button>
                                                          </div>
                                                        </template>
                                                      </b-table>
                                                    </b-tab>
                                                  </div>
                                                  <div v-if="jobDataVal.ratings">
                                                    <b-tab
                                                      :title="
                                                        ' RATE & REVIEW (' +
                                                        jobDataVal.ratings.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li"
                                                      @click="
                                                        changeApplicantTabStatus(
                                                          'all',
                                                          'selected',
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          jobDataVal.posted_by
                                                        )
                                                      "
                                                    >
                                                      <b-table
                                                        ref="datatable"
                                                        show-empty
                                                        striped
                                                        hover
                                                        responsive
                                                        :items="jobDataVal.userJobs"
                                                        :fields="fields"
                                                        :filter="filter"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                      >
                                                        <template #cell(name)="items">
                                                          <div class="daily_job_manage_img_div">
                                                            <b-img
                                                              :src="
                                                                items.item.userdetail
                                                                  .profile_image != null
                                                                  ? items.item.userdetail
                                                                      .profile_image
                                                                  : webUrl +
                                                                    'assets/images/user-avatar-small-01.png'
                                                              "
                                                              alt="User Icon"
                                                              height="100px"
                                                              width="100px"
                                                              class="user_img_emp"
                                                            ></b-img>
                                                          </div>
                                                          <div
                                                            class="daily_job_manage_user_description_div"
                                                          >
                                                            <h2>
                                                              {{
                                                                items.item.userdetail.last_name
                                                                  ? items.item.userdetail.last_name
                                                                  : ""
                                                              }}
                                                              {{
                                                                items.item.userdetail.first_name
                                                                  ? items.item.userdetail.first_name
                                                                  : ""
                                                              }}
                                                            </h2>
                                                            <div class="star-rating">
                                                              <star-rating
                                                                v-model="
                                                                  items.item.userdetail
                                                                    .average_rating
                                                                "
                                                                v-bind:star-size="20"
                                                                :show-rating="false"
                                                              ></star-rating>
                                                            </div>
                                                            <div>
                                                              <p
                                                                v-if="
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                "
                                                              >
                                                                ETA:
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | moment
                                                                }}
                                                                |
                                                                {{
                                                                  items.item
                                                                    .proposed_arrival_time != null
                                                                    ? items.item
                                                                        .proposed_arrival_time
                                                                    : "" | applicantOriginalSET
                                                                }}
                                                              </p>
                                                              <p v-else>ETA: NA</p>
                                                            </div>
                                                          </div>
                                                        </template>
                                                        <template #cell(mobile_number)="items">
                                                          <div class="phone_nmber_div">
                                                            <a href="#"
                                                              >{{
                                                                items.item.userdetail.country_code
                                                                  ? items.item.userdetail
                                                                      .country_code
                                                                  : ""
                                                              }}-{{
                                                                items.item.userdetail.mobile_number
                                                                  ? items.item.userdetail
                                                                      .mobile_number
                                                                  : ""
                                                              }}</a
                                                            >
                                                          </div>
                                                        </template>
                                                        <template #cell(time)="items">
                                                          <div class="timing_div arrow_time">
                                                            <ul>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_start_time
                                                                    ? items.item.original_start_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up red_arrow green_arrow"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.original_end_time
                                                                    ? items.item.original_end_time
                                                                    : "" | applicantOriginalSET
                                                                }}</span>
                                                              </li>
                                                              <li>
                                                                <i
                                                                  class="fa fa-arrow-down green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.start_time
                                                                    ? items.item.start_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span
                                                                ><i
                                                                  class="fa fa-arrow-up green_arrow gray_tab_round"
                                                                ></i
                                                                ><span>{{
                                                                  items.item.end_time
                                                                    ? items.item.end_time
                                                                    : "" | applicantStartEndTime
                                                                }}</span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </template>
                                                        <template #cell(thumb)="items">
                                                          <div class="thumb_div">
                                                            <i
                                                              class="fa fa-thumbs-up blue_thumb"
                                                            ></i>
                                                          </div>
                                                        </template>
                                                        <template #cell(ac_button)="items">
                                                          <div class="status_btn">
                                                            <button class="ack_btn">
                                                              ACNOWLEDGED
                                                            </button>
                                                            <button class="prg_btn">
                                                              INPROGRESS
                                                            </button>
                                                            <button class="comp_tbn">
                                                              MAKE COMPLETION
                                                            </button>
                                                          </div>
                                                        </template>
                                                      </b-table>
                                                    </b-tab>
                                                  </div>
                                                </b-tabs>
                                              </div>
                                            </div>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData">No Record Found</p>
                    </div> -->
                  </b-tab>
                  <nav
                    class="pagination-box custom_pagination_border_radius manage_job_employer_pagination"
                  >
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select
                            v-model="form.rowsPerPage"
                            @change="getList(activeTab, 'selected', filterSearch)"
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block custom_pgn_tab">
                      <ul>
                        <li>
                          <span v-if="totalRows != 0"
                            >{{ from }} - {{ to }} of {{ totalRows }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRows"
                      @input="getList(activeTab, 'selected', filterSearch)"
                      :per-page="perPage"
                      v-model="currentPage"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- Row / End -->
        <!-- Row / End -->
        <div class="clearfix"></div>

        <div class="clearfix"></div>
        <!-- <div class="margin-top-70"></div> -->
      </div>

      <b-modal
        ref="extend-modal"
        title="Extend Hours"
        hide-footer
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <b-form @submit="onSubmitExtend">
          <div class="edit-form-box model_margin_top_zero model_legend model_input">
            <!-- <b-alert :show="dismissCountDownExtend" dismissible variant="danger" @dismissed="dismissCountDownExtend=0" @dismiss-count-down="countDownChangedExtend">
                            <span>{{ error_messages_extend }}</span>
                        </b-alert> -->
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-4" label="Hours to Extend">
                    <span class="custom-dropdown">
                      <b-form-select v-model="extended_hours">
                        <option value="" disabled="true">Please Select Hours to Extend</option>
                        <option :value="arr" v-for="arr in hours">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-4" label="Minutes to Extend">
                    <span class="custom-dropdown">
                      <select
                        v-model="extended_min"
                        class="custom-select min_year_exp"
                        id="min_year_exp"
                      >
                        <option value="" disabled>Select</option>
                        <option value="00">00</option>
                        <option value="30">30</option>
                      </select>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group
                    id="input-group-3"
                    label="Total price rate for Extended Time"
                    class="required"
                  >
                    <span class="custom-dropdown">
                      <b-form-select v-model="extension_rate">
                        <option value="" disabled="true">Please Select Extension Rate</option>
                        <option :value="cost.per_hour_cost" v-for="cost in costListing">
                          {{ cost.per_hour_cost }}
                        </option>
                      </b-form-select>
                    </span>
                    <!--  <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Rate" v-model="form.extension_rate"></b-form-textarea> -->
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-md-left text-center model_margin_top_zero common_crop_reset_btn">
                <b-button
                  type="submit"
                  variant="danger"
                  class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>

      <b-modal
        ref="cancel-modal"
        title="Cancel Applicant"
        hide-footer
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <b-form @submit="onSubmitCancel">
          <div class="edit-form-box model_margin_top_zero model_legend">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Cancellation Reason">
                    <textarea
                      id="applicant_cancellation_reason"
                      type="textarea"
                      placeholder="Enter Reason"
                      :value="applicant_cancellation_reason"
                      @change="cancellationReason('applicant')"
                    ></textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-md-left text-center common_crop_reset_btn">
                <b-button
                  type="submit"
                  variant="danger"
                  class="model_common_bt_success_color text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        ref="suspend-modal"
        title="Stop Work"
        hide-footer
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <b-form method="post">
          <div
            class="edit-form-box model_margin_top_zero model_legend common_input_field model_input"
          >
            <!-- <b-alert :show="dismissCountDownSuspend" dismissible variant="danger" @dismissed="dismissCountDownSuspend=0" @dismiss-count-down="countDownChangedSuspend">
                            <span>{{ error_messages_suspend }}</span>
                        </b-alert> -->
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Stoppage Reason">
                    <textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Reason"
                      v-model="suspention_reason"
                    ></textarea>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="mobile_sm_6">
                  <b-form-group id="input-group-7" label="Start Date" class="required">
                    <datepicker
                      v-model="job_start_date"
                      :disabled-dates="disabledFromDate1"
                      :format="customFormatterTwoSuspend"
                      class="add_calender_icon"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="mobile_sm_6">
                  <b-form-group id="input-group-7" label="Start Time">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="job_start_time"
                      class="common_time_picker"
                    ></vue-timepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="mobile_sm_6">
                  <b-form-group id="input-group-7" label="End Date" class="required">
                    <datepicker
                      v-model="job_end_date"
                      :format="customFormatterTwo"
                      class="add_calender_icon"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" class="mobile_sm_6">
                  <b-form-group id="input-group-7" label="End Time">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="job_end_time"
                      class="common_time_picker"
                    ></vue-timepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12" v-if="lunch_break == 'yes'">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox
                        value="yes"
                        unchecked-value="no"
                        v-model="lunch_pay_not_provided"
                        ><strong>Lunch Break Taken?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12" v-if="lunch_pay_not_provided == 'yes'">
                  <b-form-group id="input-group-4" label="No. Of Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="lunch_pay_hours">
                        <option value="" disabled="true">Please Select No. of Hours</option>
                        <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-md-left model_margin_top_zero common_crop_reset_btn">
                <b-button
                  type="button"
                  @click="onSubmitSuspend('submit')"
                  variant="danger"
                  class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  >Submit</b-button
                >
                <b-button
                  type="button"
                  @click="onSubmitSuspend('no_pay')"
                  variant="info"
                  class="model_common_bt_lite_color common_red_bg text-uppercase x-md btn-shadow"
                  >Stop work with no pay</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <!-- <b-modal hide-footer hide-header id="attach-sup-modal" ref="attach-sup-modal" title="Select User to assign job" centered modal-class="background_custom_class" content-class="common_model_header common_model_title"> -->
      <b-modal
        hide-footer
        id="attach-sup-modal"
        ref="attach-sup-modal"
        title="Select User to assign job"
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <div class="edit-task">
          <b-form @submit.prevent="assignJobstouser" method="post">
            <div class="popup-header">
              <!-- <h2 class="">Select User to assign job</h2> -->
              <div class="">
                <div class="addUserForm model_legend">
                  <div class="editForm mw-100 pl-0">
                    <b-row>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-8" label="All Supervisors" class="required">
                          <multiselect
                            v-model="supervisorName"
                            :custom-label="customLabel"
                            tag-placeholder="Add this as new tag"
                            placeholder="Enter to search Supervisors"
                            label="full_name"
                            track-by="id"
                            :options="selectedSupervisor"
                            :multiple="false"
                            class="job_list_multi_select model_multi_select"
                            ><span slot="noResult">No Supervisor Found.</span></multiselect
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div slot="footer" class="form-btn common_crop_reset_btn">
                      <b-button
                        type="submit"
                        variant="success"
                        class="model_common_bt_success_color"
                        >Submit</b-button
                      >
                      <b-button
                        @click="hideModal()"
                        variant="light"
                        class="model_common_bt_lite_color"
                        >Cancel</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </b-modal>

      <b-modal ref="remarks-modal" title="Suspension Remarks" hide-footer>
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div>{{ remarks }}</div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-modal>
      <b-modal
        ref="review-modal"
        title="Rate and Review"
        hide-footer
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <b-form @submit="onSubmitReview">
          <div class="edit-form-box model_margin_top_zero model_legend">
            <b-alert
              :show="dismissCountDown"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
            >
              <span>{{ error_message }}</span>
            </b-alert>

            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <div class="radio-star">
                    <b-form-group id="input-group-4" class="rating-star-list">
                      <star-rating
                        v-model="rating"
                        :star-size="40"
                        :show-rating="false"
                      ></star-rating>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Review">
                    <textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Comment"
                      :value="review"
                      @change="(v) => (review1 = v)"
                    ></textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-md-left text-center common_crop_reset_btn">
                <b-button
                  type="submit"
                  variant="danger"
                  class="model_common_bt_success_color text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        @hidden="resetModal"
        hide-footer
        hide-header
        size="md"
        id="qr-code-modal"
        ref="qr-code-modal"
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <div class="edit-task">
          <div class="d-block text-center">
            <h2>
              <b>{{ qr_title }}</b>
            </h2>
            <br />
            <center>
              <VueQRCodeComponent class="qr-class" :text="qrcode"></VueQRCodeComponent>
            </center>
            <div class="mt-3 common_crop_reset_btn">
              <b-button
                @click="downloadQR()"
                variant="success"
                class="model_common_bt_success_color"
                >Download</b-button
              >
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="job-cancel-modal"
        title="Cancel Job"
        hide-footer
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <b-form @submit="onSubmitCancelJob">
          <div class="edit-form-box model_margin_top_zero model_legend">
            <!-- <b-alert :show="dismissCountDownSuspend" dismissible variant="danger" @dismissed="dismissCountDownSuspend=0" @dismiss-count-down="countDownChangedSuspend">
                            <span>{{ error_messages_suspend }}</span>
                        </b-alert> -->
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <b-form-group
                    id="input-group-3"
                    label="Cancellation Reason"
                    class="model_margin_bottom_zero"
                  >
                    <b-form-textarea
                      id="job_cancellation_reason"
                      type="textarea"
                      placeholder="Enter Reason"
                      :value="job_cancellation_reason"
                      @change="cancellationReason('job')"
                      class="model_border_and_box_shadow"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-md-left text-center model_margin_top_zero common_crop_reset_btn">
                <b-button
                  type="submit"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow model_common_bt_success_color"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>

      <b-modal
        ref="location-modal"
        title="Location"
        hide-footer
        @hide="clearLatlong"
        modal-class="background_custom_class"
        content-class="common_model_header common_model_title"
      >
        <!-- <b-form @submit="onSubmitLocation"> -->
        <div class="edit-form-box model_margin_top_zero">
          <!-- <b-alert :show="dismissCountDownSuspend" dismissible variant="danger" @dismissed="dismissCountDownSuspend=0" @dismiss-count-down="countDownChangedSuspend">
                            <span>{{ error_messages_suspend }}</span>
                        </b-alert> -->
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <gmap-map
                    ref="googleMap"
                    :center="center"
                    :zoom="12"
                    style="width: 100%; height: 400px"
                  >
                    <gmap-marker
                      :key="index"
                      class="markerCricle"
                      v-for="(m, index) in latlongMarkers"
                      :position="m.position"
                      :icon="m.icon"
                      @click="toggleInfoWindow(m, index)"
                      style="border-radius: 30px"
                    ></gmap-marker>
                    <gmap-info-window
                      :options="infoOptions"
                      :position="infoWindowPos"
                      :opened="infoWinOpen"
                      @closeclick="infoWinOpen = false"
                    >
                      <div v-html="infoContent"></div>
                    </gmap-info-window>
                  </gmap-map>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import { GET_API } from "../../../store/actions.type";

import moment from "moment";
import moment1 from "moment";

import StarRating from "vue-star-rating";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vuejs-timepicker";
import Bus from "../../../event-bus";
import VueQRCodeComponent from "vue-qrcode-component";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      charge_type: "",
      hours: [0, 1, 2, 3, 4, 5, 6],
      suspention_reason: "",
      job_start_time: {
        hh: "09",
        mm: "33",
        A: "AM",
      },
      job_end_time: {
        hh: "00",
        mm: "00",
        A: "AM",
      },
      disabledFromDate1: {
        from: new Date(Date.now() + 3600 * 1000 * 24),
      },
      qrcode: "",
      job_start_date: "",
      job_end_date: "",
      lunch_pay_not_provided: "no",
      job_cancellation_reason: "",
      lunch_pay_hours: "",
      jobDatas: [],
      skill: "",
      daily: "",
      full_time: "",
      extended_hours: "",
      extension_rate: "",
      min: "",
      location: "",
      remarks: "",
      type: "",
      keyword: "",
      cancellation_reason: "",
      salary: "",
      error_message: "",
      skillsArr: [],
      tagArr: [],
      value: [0, 15000],
      pager: {},
      pageOfItems: [],
      options: [],
      items: null,
      totalRows: 0,
      sliderValue: 50,
      costListing: [],
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      form: {
        rowsPerPage: 25,
      },
      activeTab: "inprogress",
      activeApplicantTab: "selected",
      keyVal: 0,
      inprogress: true,
      upcoming: false,
      completed: false,
      cancelled: false,
      all: false,
      selected: true,
      application: false,
      system_withdrawn: false,
      applicant_withdrawn: false,
      rate_review: false,
      review1: "",
      activeCount: "",
      shortlistedCount: "",
      hiredCount: "",
      rejectedCount: "",
      allCount: "",
      remaining_feature_jobs: 0,
      loading: false,
      webUrl: process.env.VUE_APP_URL,
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      webUrlV1: process.env.VUE_APP_URL_V1,
      userDatas: "",
      selectedsup: [],
      supervisorName: "",
      selectedSupervisor: [],
      fields: [
        { key: "name", label: "NAME & EMAIL", sortable: true },
        { key: "mobile_number", label: "MOBILE NUMBER", sortable: true },
        { key: "mobile_number1", label: "MOBILE NUMBER", sortable: true },

        { key: "time", label: "Time", sortable: true },
        { key: "thumb", label: "THUMB", sortable: true },
        { key: "ac_button", label: "Button", sortable: true },
        { key: "toggleAction", label: "ACTION" },
      ],

      checkedStar: [],
      sortBy: null,
      acStatus: "",
      sortDirection: "asc",
      lunch_break: "no",
      filter: "",
      status: "active",
      sortDesc: true,
      range: false,
      date: moment().utc().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      latlongMarkers: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      fullscreenControl: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      MarkerOptions: {
        zIndex: 999999,
        opacity: 0.2,
      },
      timePicker24Hour: true,
      timePicker: true,
      dateInput: {
        placeholder: "Select Date",
      },
      dismissCountDown: 0,
      countDownChanged: "",
      calendarTimeInput: {
        readonly: true,
        step: 30,
        inputClass: "my_custom_class",
      },
      rating: 0,
      addDays: moment().add("3", "days").format("YYYY-MM-DD h:m:s"),
      readOnly: true,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      start_date_filter: "",
      end_date_filter: "",
      filterSearch: "",
      filterServiceCategory: null,
      qr_title: "",
      inprogressCount: 0,
      upcomingCount: 0,
      vacancySet: 0,
      applicant_cancellation_reason: "",
      permissionActions: "",
      allManageJobDPLocal: [],
      extended_min: 0,
      filterSearchSelected: "",
      default_increment_in_vacancy: 0,
    };
  },
  components: {
    StarRating,
    DateRangePicker,
    Datepicker,
    Multiselect,
    VueTimepicker,
    VueQRCodeComponent,
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM YYYY");
    },
    moment_1: function (date) {
      return moment(date).format("ddd DD MMM YYYY");
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalizeAll: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },

    customFormatterOnecreated(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    applicantOriginalSET: function (date) {
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },

    applicantOriginalSET1: function (date) {
      console.log(date, "applied date1");
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date).utc().format("DD MMM YYYY hh:mm A");
      }
    },
    applicantStartEndTime: function (date) {
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },

    applicantStartEndTime1: function (date) {
      if (date == "") {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },
    jobStartEndTime: function (date) {
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },
    datePicker1: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },

  methods: {
    ManageJobRequirementEyeReveal(jobDatakey) {
      $("#manage_job_requirement_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
      //   $(".manage_job_requirements").toggleClass("show_full_manage_job_requirement");
      $(".large-content-requirement-inprogress")
        .find("#visible-content-requirement-inprogress" + jobDatakey)
        .toggle();
      $(".large-content-requirement-inprogress")
        .find("#invisible-content-requirement-inprogress" + jobDatakey)
        .toggle();
    },
    ManageJobScopeEyeReveal(jobDatakey) {
      $("#manage_job_scope_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
      //   $(".manage_job_scope").toggleClass("show_full_manage_job_scope");
      $(".large-content-scope-inprogress")
        .find("#visible-content-scope-inprogress" + jobDatakey)
        .toggle();
      $(".large-content-scope-inprogress")
        .find("#invisible-content-scope-inprogress" + jobDatakey)
        .toggle();
    },
    ManageJobRequirementUpcomingEyeReveal(jobDatakey) {
      $("#manage_job_requirement_upcoming_eye_icon" + jobDatakey).toggleClass(
        "fa-eye fa-eye-slash"
      );
      //   $(".manage_job_requirements_upcoming").toggleClass(
      //     "show_full_manage_job_requirement_upcoming"
      //   );
      $(".large-content-requirement-upcoming")
        .find("#visible-content-requirement-upcoming" + jobDatakey)
        .toggle();
      $(".large-content-requirement-upcoming")
        .find("#invisible-content-requirement-upcoming" + jobDatakey)
        .toggle();
    },
    ManageJobScopeUpcomingEyeReveal(jobDatakey) {
      $("#manage_job_scope_upcoming_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
      //   $(".manage_job_scope_upcoming").toggleClass("show_full_manage_job_scope_upcoming");
      $(".large-content-scope-upcoming")
        .find("#invisible-content-scope-upcoming" + jobDatakey)
        .toggle();
      $(".large-content-scope-upcoming")
        .find("#visible-content-scope-upcoming" + jobDatakey)
        .toggle();
    },
    ManageJobRequirementCompletedEyeReveal(jobDatakey) {
      $("#manage_job_requirement_completed_eye_icon" + jobDatakey).toggleClass(
        "fa-eye fa-eye-slash"
      );
      //   $(".manage_job_requirements_completed").toggleClass(
      //     "show_full_manage_job_requirement_completed"
      //   );
      $(".large-content-requirement-completed")
        .find("#visible-content-requirement-completed" + jobDatakey)
        .toggle();
      $(".large-content-requirement-completed")
        .find("#invisible-content-requirement-completed" + jobDatakey)
        .toggle();
    },

    ManageJobScopeCompletedEyeReveal(jobDatakey) {
      $("#manage_job_scope_completed_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
      //   $(".manage_job_scope_completed").toggleClass("show_full_manage_job_scope_completed");
      $(".large-content-scope-completed")
        .find("#visible-content-scope-completed" + jobDatakey)
        .toggle();
      $(".large-content-scope-completed")
        .find("#invisible-content-scope-completed" + jobDatakey)
        .toggle();
    },
    ManageJobRequirementCancelledEyeReveal(jobDatakey) {
      $("#manage_job_requirement_cancelled_eye_icon" + jobDatakey).toggleClass(
        "fa-eye fa-eye-slash"
      );
      //   $(".manage_job_requirements_cancelled").toggleClass(
      //     "show_full_manage_job_requirement_cancelled"
      //   );
      $(".large-content-requirement-cancelled")
        .find("#visible-content-requirement-cancelled" + jobDatakey)
        .toggle();
      $(".large-content-requirement-cancelled")
        .find("#invisible-content-requirement-cancelled" + jobDatakey)
        .toggle();
    },
    ManageJobScopeCancelledEyeReveal(jobDatakey) {
      $("#manage_job_scope_cancelled_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
      //   $(".manage_job_scope_cancelled").toggleClass("show_full_manage_job_scope_cancelled");
      $(".large-content-scope-cancelled")
        .find("#visible-content-scope-cancelled" + jobDatakey)
        .toggle();
      $(".large-content-scope-cancelled")
        .find("#invisible-content-scope-cancelled" + jobDatakey)
        .toggle();
    },

    ManageJobRequirementAllEyeReveal(jobDatakey) {
      $("#manage_job_requirement_eye_icon_all" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
      //   $(".manage_job_requirements_all").toggleClass("show_full_manage_job_requirement_all");
      $(".large-content-requirement-all")
        .find("#visible-content-requirement-all" + jobDatakey)
        .toggle();
      $(".large-content-requirement-all")
        .find("#invisible-content-requirement-all" + jobDatakey)
        .toggle();
    },
    ManageJobScopeAllEyeReveal(jobDatakey) {
      $("#manage_job_scope_eye_icon_all" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
      //   $(".manage_job_scope_all").toggleClass("show_full_manage_job_scope_all");
      $(".large-content-scope-all")
        .find("#visible-content-scope-all" + jobDatakey)
        .toggle();
      $(".large-content-scope-all")
        .find("#invisible-content-scope-all" + jobDatakey)
        .toggle();
    },
    customFormatterTwo(date) {
      return moment(date).utc().format("DD-MM-YYYY");
    },
    customFormatterTwoSuspend(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    cancelJob(id) {
      // this.form.username = name;
      this.job_cancellation_reason = "";
      this.job_id = id;
      this.$refs["job-cancel-modal"].show();
    },
    totalVacancy(current_vacancy) {
      console.log(current_vacancy, this.default_increment_in_vacancy);
      return parseInt(current_vacancy) - parseInt(this.default_increment_in_vacancy);
    },

    locationPtuser(job_id, id) {
      this.job_id = job_id;
      this.getLatLong().then(() => {
        this.geolocate();
        // this.$refs.address.focus();
        this.setPlace(this.currentPlace);
        this.addMarker();
      });
      this.form.user_id = id;
      this.$refs["location-modal"].show();
    },
    clear() {
      this.filterSearch = "";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.getList(this.activeTab, this.activeApplicantTab, this.filterSearch);
    },
    clearLatlong() {
      this.center = { lat: 0, lng: 0 };
      // this.latlongMarker = [];
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: +position.coords.latitude,
          lng: +position.coords.longitude,
        };
      });
    },

    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: +parseFloat(this.currentPlace.geometry.location.lat()),
          lng: +parseFloat(this.currentPlace.geometry.location.lng()),
        };
        this.form.formattedAddress = this.currentPlace.formatted_address;
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },

    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },

    getLatLong() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            // status: status ? status : 'in-progress',
            // servicesFilter: userServices ? userServices : 'all',

            page: this.currentPage,
            keyword: this.filterSearchRate,
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            sortDesc: this.sortDesc,
          },
          api: "/api/get-latlong",
        })

        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.marker = this.$store.getters.getResults.data.latLong;
            this.jobMarker = this.$store.getters.getResults.data.job_location;

            this.updatelatlongMarker();
          }
        });
    },

    updatelatlongMarker() {
      var markers = [];
      $.each(this.marker, function (index, val) {
        var icon = {
          url: val.candidate_profile.profile_image, // url

          scaledSize: new google.maps.Size(30, 30), // scaled size
          // radius:20,
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0), // anchor
          labelClass: "labelIconlabelIcon",
          //shape:{coords:[17,17,18],type:"circle"},
          //set optimized to false otherwise the marker  will be rendered via canvas
          //and is not accessible via CSS
          // optimized:false,
          // title: 'spot'
          // path: google.maps.SymbolPath.CIRCLE,
          // scale: 8.5,
          // fillColor: "#F00",
          // fillOpacity: 0.4,
          // strokeWeight: 0.4,
          // scaledSize: new google.maps.Size(10,20), // scaled size
          // origin: new google.maps.Point(0,0), // origin
          // anchor: new google.maps.Point(0, 32) // anchor
        };
        markers.push({
          position: { lat: Number(val.current_lat), lng: Number(val.current_long) },
          icon: icon,
          labelClass: "labelIcon",
          // icon:val.candidate_profile.profile_image,
          type: "ptuser",
          //shape:{coords: [0,0,3,3], type: "rect"},
        });
        // str['long'][index].push(val.current_long);
      });
      var jobmarkers = [];

      $.each(this.jobMarker, function (index, val) {
        markers.push({
          position: { lat: Number(val.lat), lng: Number(val.long) },
          type: "company",
        });
        // str['long'][index].push(val.current_long);
      });
      this.latlongMarkers = markers;
      this.center = { lat: 0, lng: 0 };
      setTimeout(() => {
        this.center = this.latlongMarkers[0].position;
      }, 500);
    },

    onSubmitCancelJob(evt) {
      evt.preventDefault();
      this.$refs["job-cancel-modal"].hide();
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            cancellation_reason: this.job_cancellation_reason,
            user_id: this.user_id,
            logged_user_id: this.logged_user_id,
          },
          api: "/api/cancel-job-api",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.job_cancellation_reason = "";
            this.success_message = this.$store.getters.getResults.message;
            this.items = this.$store.getters.getResults.data;
            this.showSuccessAlert(this.success_message);

            // Toast.fire({
            //     type: 'success',
            //     title: 'Job Cancelled Successfully.',
            // });

            /// this.changeTabStatus(this.activeTab,this.activeApplicantTab)

            window.location.reload();
          }
        });
    },

    cancelApplicant(id, job_id, key) {
      // this.form.username = name;
      this.cancellation_reason = "";
      this.applicant_cancellation_reason = "";
      this.user_id = id;
      this.job_id = job_id;
      this.keyVal = key;
      this.$refs["cancel-modal"].show();
    },

    onSubmitReview(evt) {
      evt.preventDefault();
      this.$refs["review-modal"].hide();
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            review: this.review1,
            rating: this.rating,
            logged_user_id: this.logged_user_id,
          },
          api: "/api/supervisor-rate-ptuserWeb",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.form.review = "";
            this.form.user_id_id = "";
            this.rating = "";
            //this.$refs['review-modal'].hide();
            this.showSuccessAlert(this.success_message);
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
            // window.location.reload();

            //this.getList(this.activeTab,'selected',this.filterSearch);
            // this.$router.push({ name: 'ViewJobDetailCompleted' });
          }
        });
    },

    pdfViewer(link) {
      window.open(link);
    },

    ratePtUser(id, job_id, key) {
      // this.form.username = name;
      this.review1 = "";
      this.user_id = id;
      this.job_id = job_id;
      this.keyVal = key;

      this.checkedStar = [];
      this.$refs["review-modal"].show();
    },
    cancellationReason(type) {
      if (type == "applicant") {
        this.applicant_cancellation_reason = document.getElementById(
          "applicant_cancellation_reason"
        ).value;
      } else {
        this.job_cancellation_reason = document.getElementById("job_cancellation_reason").value;
      }
    },
    onSubmitCancel(evt) {
      console.log(this.applicant_cancellation_reason, "this.applicant_cancellation_reason");
      evt.preventDefault();
      this.$refs["cancel-modal"].hide();
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            cancellation_reason: this.applicant_cancellation_reason,
            logged_user_id: this.logged_user_id,
          },
          api: "/api/cancel-pt-user",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_messages_cancel = this.$store.getters.getErrors;
            this.showAlertCancel();
          } else {
            this.form.cancellation_reason = "";
            this.form.user_id = "";
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            // this.getList(this.activeTab,'selected',this.filterSearch);
            // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
            // window.location.reload();
          }
        });
    },

    downloadQR(evt) {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.qr_job_id,
          },
          api: "/api/show-qr-code",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_messages_cancel = this.$store.getters.getErrors;
            this.showAlertCancel();
          } else {
            this.qr_url = this.$store.getters.getResults.url;
            var name = this.$store.getters.getResults.name;
            this.$refs["qr-code-modal"].hide();

            window.open(this.qr_url, "_blank");
          }
        });
    },

    extendHour(id, cost, jobId, key) {
      // this.form.username = name;
      this.extended_hours = "";
      this.extended_min = "00";
      this.extension_rate = parseFloat(cost);
      this.user_id = id;
      this.job_id = jobId;
      this.keyVal = key;
      this.$refs["extend-modal"].show();
    },

    viewSuspensionReason(remarks) {
      this.$refs["remarks-modal"].show();
      this.remarks = remarks;
    },

    suspendApplicant(
      user_id,
      job_id,
      lunch_pay_hours_old,
      lunch_pay_not_provided_old,
      job_start_date,
      start_time,
      key
    ) {
      this.suspention_reason = "";
      this.user_id = user_id;
      this.job_id = job_id;
      this.keyVal = key;

      console.log(this.keyVal);

      this.lunch_pay_not_provided = "no";
      this.lunch_break = lunch_pay_not_provided_old;
      this.lunch_pay_hours = lunch_pay_hours_old;

      (this.job_start_time.hh = moment(job_start_date + " " + start_time).format("hh")),
        (this.job_start_time.mm = moment(job_start_date + " " + start_time).format("mm")),
        (this.job_start_time.A = moment(job_start_date + " " + start_time).format("A"));

      this.job_start_date =
        job_start_date + " " + start_time != null
          ? moment(job_start_date + " " + start_time, "YYYY-MM-DD").toDate()
          : "";
      this.job_end_date = moment().format("YYYY-MM-DD");

      this.$refs["suspend-modal"].show();
    },

    // suspendApplicant(user_id,job_id,lunch_pay_hours_old,lunch_pay_not_provided_old,job_start_date,start_time) {

    //     this.suspention_reason = '';
    //     this.user_id = user_id;
    //     this.job_id = job_id;

    //     this.lunch_pay_not_provided = 'no';
    //     this.lunch_break = lunch_pay_not_provided_old
    //     this.lunch_pay_hours = lunch_pay_hours_old;

    //         this.job_start_time.hh = moment((job_start_date+' '+ start_time)).utc().format('hh'),
    //         this.job_start_time.mm = moment((job_start_date+' '+ start_time)).utc().format('mm'),
    //         this.job_start_time.A = moment((job_start_date+' '+ start_time)).utc().format('A');

    //         this.job_start_date = (job_start_date+' '+ start_time) != null ? moment((job_start_date+' '+ start_time),'YYYY-MM-DD').utc().toDate() : ''

    //     this.$refs['suspend-modal'].show();

    // },

    onSubmitSuspend(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            logged_user_id: this.logged_user_id,
            end_time: this.job_end_time,
            end_date: this.job_end_date != "" ? moment(this.job_end_date).format("YYYY-MM-DD") : "",
            lunch_pay_not_provided: this.lunch_break == "yes" ? this.lunch_pay_not_provided : "no",
            lunch_pay_hours: this.lunch_pay_hours,
            suspention_reason: this.suspention_reason,
            type: type,
          },
          api: "/api/suspend-pt-userWeb",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.form.review = "";
            this.form.user_id = "";
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.$refs["suspend-modal"].hide();
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            //this.changeTabStatus(this.activeTab,this.activeApplicantTab,this.filterSearch)
            // window.location.reload();

            // this.getList(this.activeTab,'selected',this.filterSearch);

            // window.location.reload();
          }
        });
    },

    onSubmitExtend(evt) {
      evt.preventDefault();
      this.$refs["extend-modal"].hide();

      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            // extended_hours: (this.extended_min == '00' || this.extended_min == '' || this.extended_min == null) ? this.extended_hours + '.0' : this.extended_hours +'.5',
            extended_hours:
              this.extended_min == "00" || this.extended_min == "" || this.extended_min == null
                ? this.extended_hours + ".0"
                : this.extended_hours + "." + this.extended_min,

            extension_rate: this.extension_rate,
            logged_user_id: this.logged_user_id,
            from_web: true,
          },
          api: "/api/extend-pt-user-time",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.extended_hours = "";
            this.extended_min = "00";
            // this.form.extension_rate = '';

            this.success_message = this.$store.getters.getResults.message;
            this.$swal({
              position: "center",
              icon: "success",
              title: "Hour extended successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            this.user_id = "";

            // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
            // window.location.reload();

            // this.getList(this.activeTab,'selected',this.filterSearch);

            // window.location.reload();
          }
        });
    },
    getEmpUsers() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_admin: this.user_id,
            company_id: this.company_id,
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "id",
            sortDirection: this.sortDesc ? "asc" : "desc",
          },
          api: "/api/emp-manage-user-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    getHourlyCost() {
      return this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/hourly-cost",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.costListing = this.$store.getters.getResults.data;
            }
          }
        });
    },
    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    showAlertCancel() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_messages_cancel,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    btoa(id) {
      return btoa(id);
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.$router.push({ name: 'manage-jobs' });
      });
    },
    exportJobs() {
      var base_url = this.webUrlV1;
      var comp = null;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var search = this.filterSearch != "" ? this.filterSearch : null;

      var act_tab = this.activeTab == "inprogress" ? "in-progress" : this.activeTab;

      var export_url =
        "/api/export-jobs/" +
        act_tab +
        "/" +
        this.user_id +
        "/" +
        search +
        "/" +
        this.filterServiceCategory +
        "/" +
        comp +
        "/" +
        start_date +
        "/" +
        end_date;

      window.open(base_url + export_url);
    },
    exportJobsByName() {
      var base_url = this.webUrlV1;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var search = this.filterSearch != "" ? this.filterSearch : null;
      var comp = null;

      var act_tab = this.activeTab == "inprogress" ? "in-progress" : this.activeTab;

      var export_url =
        "/api/export-job-by-name/" +
        act_tab +
        "/" +
        this.user_id +
        "/" +
        search +
        "/" +
        this.filterServiceCategory +
        "/" +
        comp +
        "/" +
        start_date +
        "/" +
        end_date;

      window.open(base_url + export_url);
    },

    // exportJobsByName(){
    //     var tabStatus = this.activeTab == 'inprogress' ? 'in-progress' : this.activeTab;
    //     var start_date_filter = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : null;
    //     var end_date_filter = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : null;
    //     var filterSearch = this.filterSearch != '' ? this.filterSearch : null;
    //     return this.$store.dispatch(GET_API, {
    //         // api: '/api/exportJobsByName/'+tabStatus
    //         api: '/api/exportJobsByName/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch
    //     })
    //     .then(() => {
    //         var base_url = this.webServerUrl;
    //         // var export_url = '/api/exportJobsByName/'+tabStatus+'?nocache=1666084804705/';
    //         var export_url = '/api/exportJobsByName/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch+'?nocache=1666084804705/';
    //         // var export_url = '/api/export-jobs/'+this.status+'/'+this.user_id+'/'+search+'/'+this.form.filterServiceCategory+'/'+companiesFilter+'/'+start_date+'/'+end_date;
    //         window.open(base_url+export_url,'_blank')
    //     });

    // },

    changeTabStatusAll(status, user_status, filterSearch) {
      this.loading = true;
      this.activeTab = status;
      this.activeApplicantTab = "selected";

      this.dateRange.startDate = null;
      this.dateRange.endDate = null;

      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 10;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.form.rowsPerPage = 25;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      }
      // $(".dashboard-content-inner").animate({
      //         scrollTop: 0
      // }, "slow");
      (this.start_date_filter = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : ""),
        (this.end_date_filter = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
          : "");
      var api =
        this.activeTab == "all" ? "/api/dailyPaidJobsEmployerAll" : "/api/dailyPaidJobsEmployer";
      if (this.start_date_filter || this.end_date_filter) {
        return this.$store
          .dispatch(POST_API, {
            data: {
              page: this.currentPage,
              rowsPerPage: this.form.rowsPerPage,
              userId: this.logged_user_id,
              company_id: this.company_id,
              user_type_id: this.user_type_id,
              is_company_head: this.is_company_head,
              start_date_filter: this.start_date_filter,
              end_date_filter: this.end_date_filter,
              activeTab: status,
              status: status == "inprogress" ? "in-progress" : status,
              user_status: user_status,
              filterSearch: filterSearch,
            },
            api: api,
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              this.loading = false;

              return [];
            } else {
              this.loading = false;

              this.jobDatas = this.$store.getters.getResults.pageOfItems;
              this.totalRows = this.$store.getters.getResults.pager.totalItems;
              this.perPage = this.$store.getters.getResults.pager.pageSize;
              this.from = this.$store.getters.getResults.pager.from;
              this.to = this.$store.getters.getResults.pager.to;
              this.inprogressCount = this.$store.getters.getResults.inprogresscounts;
              this.upcomingCount = this.$store.getters.getResults.upcommingcounts;
              // this.vacancySet = this.$store.getters.getResults.vacancySet;

              Bus.$emit("get_em_job_count");
              //return this.jobDatas;
            }
          });
      } else {
        this.jobDatas = [];
        this.totalRows = 0;
        this.perPage = 0;
        this.from = 1;
        this.to = 1;
      }
    },

    changeTabStatus(status, user_status, filterSearch, datepick) {
      this.loading = true;
      this.activeTab = status;
      this.activeApplicantTab = "selected";

      if (datepick == "") {
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
      }

      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 10;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.form.rowsPerPage = 25;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      }
      // $(".dashboard-content-inner").animate({
      //         scrollTop: 0
      // }, "slow");
      (this.start_date_filter = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : ""),
        (this.end_date_filter = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
          : "");
      var api =
        this.activeTab == "all" ? "/api/dailyPaidJobsEmployerAll" : "/api/dailyPaidJobsEmployer";

      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            userId: this.logged_user_id,
            company_id: this.company_id,
            user_type_id: this.user_type_id,
            is_company_head: this.is_company_head,
            start_date_filter: this.start_date_filter,
            end_date_filter: this.end_date_filter,
            activeTab: status,
            status: status == "inprogress" ? "in-progress" : status,
            user_status: user_status,
            filterSearch: filterSearch,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;

            return [];
          } else {
            this.loading = false;

            this.jobDatas = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            this.inprogressCount = this.$store.getters.getResults.inprogresscounts;
            this.upcomingCount = this.$store.getters.getResults.upcommingcounts;
            // this.vacancySet = this.$store.getters.getResults.vacancySet;

            Bus.$emit("get_em_job_count");
            //return this.jobDatas;
          }
        });
    },

    paginate(status, user_status) {
      this.loading = true;
      this.activeTab = status;
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      }

      (this.start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
        : ""),
        (this.end_date = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
          : "");
      var api =
        this.activeTab == "all" ? "/api/dailyPaidJobsEmployerAll" : "/api/dailyPaidJobsEmployer";
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            userId: this.user_id,
            company_id: this.company_id,
            user_type_id: this.user_type_id,
            is_company_head: this.is_company_head,
            activeTab: status,
            status: status == "inprogress" ? "in-progress" : status,
            user_status: user_status,
            filterSearch: filterSearch,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;

            return [];
          } else {
            this.loading = false;

            this.jobDatas = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            // this.inprogressCount = this.$store.getters.getResults.inprogresscounts.progressJobs
            // this.upcomingCount = this.$store.getters.getResults.upcommingcounts.upcomingJobs

            //return this.jobDatas;
          }
        });
    },

    getList(status, user_status, filterSearch) {
      console.log("aga");
      this.loading = true;
      this.activeTab = status;
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      }

      (this.start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
        : ""),
        (this.end_date = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
          : "");
      var api =
        this.activeTab == "all" ? "/api/dailyPaidJobsEmployerAll" : "/api/dailyPaidJobsEmployer";
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            userId: this.user_id,
            company_id: this.company_id,
            user_type_id: this.user_type_id,
            is_company_head: this.is_company_head,
            activeTab: status,
            status: status == "inprogress" ? "in-progress" : status,
            user_status: user_status,
            filterSearch: filterSearch,
            start_date_filter: this.start_date,
            end_date_filter: this.end_date,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;

            return [];
          } else {
            $(".dashboard-content-container .simplebar-scroll-content").animate(
              {
                scrollTop: 0,
              },
              "slow"
            );
            this.loading = false;

            this.jobDatas = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            this.inprogressCount = this.$store.getters.getResults.inprogresscounts;
            this.upcomingCount = this.$store.getters.getResults.upcommingcounts;
            this.default_increment_in_vacancy =
              this.$store.getters.getResults.default_increment_in_vacancy;

            // this.vacancySet = this.$store.getters.getResults.vacancySet;

            //return this.jobDatas;
          }
        });
    },
    resetModal() {
      this.form.id = "";
      this.supervisorName = "";
    },
    deleteJob(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/deleteJob",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Job Deleted successfully.";
                  this.showSuccessAlert(message);
                  window.location.replace("/v2/employer/manage-jobs");
                }
              });
          }
        })
        .catch((err) => {});
    },
    changeApplicantTabStatus(status, user_status, id, key, posted_by) {
      /** Start In Progress Active Tab Selection **/
      if (user_status == "selected") {
        this.activeApplicantTab = "selected";
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(1) a").addClass(
          "active"
        );
      }
      if (user_status == "application") {
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(2) a").addClass(
          "active"
        );
      }
      if (user_status == "cancelled") {
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(3) a").addClass(
          "active"
        );
      }
      if (user_status == "rate_review") {
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(4) a").addClass(
          "active"
        );
      }
      /** End In Progress Active Tab Selection **/

      /** Start Upcoming Active Tab Selection **/
      if (user_status == "selected") {
        this.activeApplicantTab = "selected";

        $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item:nth-child(1) a").addClass(
          "active"
        );
      }
      if (user_status == "application") {
        $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item:nth-child(2) a").addClass(
          "active"
        );
      }
      if (user_status == "cancelled") {
        $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item:nth-child(3) a").addClass(
          "active"
        );
      }
      /** End Upcoming Active Tab Selection **/

      /** Start Completed Active Tab Selection **/
      if (user_status == "selected") {
        $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item:nth-child(1) a").addClass(
          "active"
        );
      }
      if (user_status == "cancelled") {
        $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item:nth-child(2) a").addClass(
          "active"
        );
      }
      if (user_status == "rate_review") {
        $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item:nth-child(3) a").addClass(
          "active"
        );
      }
      /** End Completed Active Tab Selection **/

      /** Start All Active Tab Selection **/
      if (user_status == "selected") {
        this.activeApplicantTab = "selected";

        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(1) a").addClass("active");
      }
      if (user_status == "application") {
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(2) a").addClass("active");
      }
      if (user_status == "cancelled") {
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(3) a").addClass("active");
      }
      if (user_status == "applicant_withdrawn") {
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(4) a").addClass("active");
      }
      if (user_status == "system_withdrawn") {
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(5) a").addClass("active");
      }
      if (user_status == "rate_review") {
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
        $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(6) a").addClass("active");
      }
      /** End All Active Tab Selection **/

      // $('.manage_job_new_tabs_layout .custom_tabs_daily_manage_jobs ul.nav.nav-tabs li a').removeClass('active');
      // $('div#app .manage_job_new_tabs_layout .custom_tabs_daily_manage_jobs ul.nav.nav-tabs li:nth-child(1) a').addClass('active');
      console.log(user_status, "userstay");
      this.activeTab = status;
      this.activeApplicantTab = user_status;
      // if(this.activeApplicantTab == 'selected'){
      //     this.selected = true;
      //     this.application = false;
      //     this.system_withdrawn = false;
      //     this.applicant_withdrawn = false;
      //     this.rate_review = false;
      // }
      // if(this.activeApplicantTab == 'selected'){
      //     this.upcoming = true;
      //     this.inprogress = false;
      //     this.completed = false;
      //     this.cancelled = false;
      //     this.all = false;
      // }else if(this.activeTab == 'completed'){
      //     this.completed = true;
      //     this.inprogress = false;
      //     this.upcoming = false;
      //     this.cancelled = false;
      //     this.all = false;
      // }else if(this.activeTab == 'cancelled'){
      //     this.cancelled = true;
      //     this.inprogress = false;
      //     this.upcoming = false;
      //     this.completed = false;
      //     this.all = false;
      // }else if(this.activeTab == 'all'){
      //     this.all = true;
      //     this.inprogress = false;
      //     this.upcoming = false;
      //     this.completed = false;
      //     this.cancelled = false;
      // }else{
      //     this.inprogress = true;
      //     this.expired = false;
      //     this.upcoming = false;
      //     this.completed = false;
      //     this.cancelled = false;
      //     this.all = false;
      // }
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            jobId: id,
            // jobId:1887,
            status: status == "inprogress" ? "in-progress" : status,
            user_status: user_status,
            user_id: posted_by,
            filterSearchSelected: this.filterSearchSelected,
          },
          api: "/api/dailyPaidJobsEmployerById",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            var userDatas =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].userJobs
                : [];
            var userJobsApplication =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].userJobsApplication
                : [];
            var userJobsApplicationWithdrawn =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].userJobsApplicationWithdrawn
                : [];
            var userJobsCancelled =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].userJobsCancelled
                : [];
            var userJobsSystemWithdrawn =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].userJobsSystemWithdrawn
                : [];
            var userJobsselected =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].userJobsselected
                : [];

            var inprogressCount =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].inprogressCount
                : [];

            var completedCount =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].completedCount
                : [];

            var awardCount =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].awardCount
                : [];

            var pendingCount =
              this.$store.getters.getResults.data.length > 0
                ? this.$store.getters.getResults.data[0].pendingCount
                : [];

            console.log(this.jobDatas, key, "jobData");

            this.jobDatas[key].userJobs = userDatas;
            this.jobDatas[key].userJobsApplication = userJobsApplication;
            this.jobDatas[key].userJobsApplicationWithdrawn = userJobsApplicationWithdrawn;
            this.jobDatas[key].userJobsCancelled = userJobsCancelled;
            this.jobDatas[key].userJobsSystemWithdrawn = userJobsSystemWithdrawn;
            this.jobDatas[key].userJobsselected = userJobsselected;
            this.jobDatas[key].completedCount = completedCount;
            this.jobDatas[key].inprogressCount = inprogressCount;
            this.jobDatas[key].pendingCount = pendingCount;
            this.jobDatas[key].awardCount = awardCount;
            // this.vacancySet = this.$store.getters.getResults.vacancySet;

            // return this.jobDatas.key;
          }
        });
    },

    changeStatus(id, status) {
      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Job Inactivated successfully.";
        var new_status = "inactive";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Job Activated successfully.";
        var new_status = "active";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: new_status,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/changeJobStatus",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                  this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
                  this.getTotalCount();
                  // window.location.replace('/v2/manage-jobs');
                }
              });
          }
        })
        .catch((err) => {});
    },

    featureJob(id, company_id) {
      if (this.remaining_feature_jobs == 0) {
        var msg =
          "Are you sure you want to feature this job? Since you do not have any remaining feature jobs so coins will be deducted from your account";
      } else {
        var msg =
          "Are you sure you want to feature this job? Since you have remaining feature jobs so coins will not be deducted";
      }
      var message = "Job featured successfully.";

      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  company_admin_id: this.company_admin_id ? this.company_admin_id : this.user_id,
                  company_id: company_id,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/featureJob",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  if ((this.error_message = "Insufficient Coins for featuring the job.")) {
                    this.$router.push({ name: "elcoins" });
                  }
                } else {
                  this.showSuccessAlert(message);
                  this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
                  this.getTotalCount();
                  window.location.replace("/v2/employer/manage-jobs");
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    manageCandidate(id) {
      window.location.replace("/v2/manage-candidates/" + id);
    },

    awardJob(userId, jobId, jobDatakey, posted_by) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure to award this job.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                job_id: jobId,
                user_id: userId,
                employer_id: this.user_id,
                logged_user_id: this.logged_user_id,
              },
              api: "/api/awardUpcomingJobWeb",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                return [];
              } else {
                this.items = this.$store.getters.getResults.data;
                this.success_message = this.$store.getters.getResults.message;
                this.showSuccessAlert(this.success_message);
                // this.selected = true;
                this.changeApplicantTabStatus(
                  this.activeTab,
                  this.activeApplicantTab,
                  jobId,
                  jobDatakey,
                  posted_by
                );
                // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);

                // getList()
                // window.location.reload();

                // this.getList(this.activeTab,'selected',this.filterSearch);
                // return this.items;
              }
            });
        }
      });
    },

    notSuitable(userId, jobId, key) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure to remove this user.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                job_id: jobId,
                user_id: userId,
                logged_user_id: this.logged_user_id,
              },
              api: "/api/mw-not-suitable",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                return [];
              } else {
                this.keyVal = key;
                this.items = this.$store.getters.getResults.data;
                this.success_message = this.$store.getters.getResults.message;
                this.showSuccessAlert(this.success_message);
                this.activeApplicantTab = "application";

                console.log(this.keyVal);
                this.changeApplicantTabStatus(
                  this.activeTab,
                  this.activeApplicantTab,
                  jobId,
                  this.keyVal,
                  userId
                );
                // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
                //  window.location.reload();

                // this.getList('in-progress','selected',this.filterSearch);
                return this.items;
              }
            });
        }
      });
    },

    markArrival(userId, jobId) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure to mark as arrival.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                job_id: jobId,
                user_id: userId,
                status: "in-progress",
                lat: "",
                long: "",
                from: "web",
                logged_user_id: this.logged_user_id,
              },
              api: "/api/markArrival",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                return [];
              } else {
                this.items = this.$store.getters.getResults.data;
                this.success_message = this.$store.getters.getResults.message;
                this.showSuccessAlert(this.success_message);
                this.getList("in-progress", "selected", this.filterSearch);
                window.location.reload();

                return this.items;
              }
            });
        }
      });
    },

    getEmployeeProfile() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.acStatus = this.$store.getters.getResults.user["account_verification_status"];
              this.show_job_list_rate = this.$store.getters.getResults.data["show_job_list_rate"];
            } else {
              this.form.mobile = "";
              this.form.email = "";
            }
          }
        });
    },

    assignJobstouser(evt) {
      evt.preventDefault();
      this.hideModal();
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.supervisorName,
            job_id: this.job_id_data,
            logged_user_id: this.logged_user_id,
          },
          api: "/api/assignJobstouser",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: this.success_message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.changeTabStatus(this.activeTab, this.activeApplicantTab, null);
          }
        });
    },

    openAttachSupModal(id) {
      this.$refs["attach-sup-modal"].show();
      this.job_id_data = id;

      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.user_id,
            job_id: this.job_id_data,
          },
          api: "/api/list-supervisors-to-assign-om-jobs",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.selectedSupervisor = this.$store.getters.getResults.data;
            this.supervisorName = this.$store.getters.getResults.selected;

            return this.selectedSupervisor;
          }
        });
    },
    customLabel({ first_name, last_name }) {
      return `${last_name} ${first_name}`;
    },
    hideModal() {
      this.$refs["attach-sup-modal"].hide();
    },
    showQR(code, title, job_id) {
      this.$refs["qr-code-modal"].show();
      this.qrcode = code;
      this.qr_title = title;
      this.qr_job_id = job_id;
    },
    permissionEM() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.is_company_head == "no" &&
          (this.$store.getters.currentUser.user_type_id == 3 ||
            this.$store.getters.currentUser.user_type_id == 4)
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.permissionActions = permission.getPermission(menu, "Manage Jobs");
        }
      }
    },
    showPermissionEM(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionActions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showJobListRateAdmin(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "yes" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.show_job_list_rate == "yes" ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(document).ready(function () {
      // $(document).on('click', '.btn', function(){
      // var moreLessButton = $(".invisible-content").is(':visible') ? 'Read More' : 'Read Less';
      // $(this).text(moreLessButton);
      // $(this).parent('.large-content').find(".invisible-content").toggle();
      // $(this).parent('.large-content').find(".visible-content").toggle();
      // });
    });

    //document.title = "EL Connect - Employer - Manage Jobs";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.logged_user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.charge_type =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.charge_type : "";

    this.user_type_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
    this.is_company_head =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.is_company_head
        : "";

    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    this.getEmployeeProfile();

    this.permissionEM();
    Bus.$emit("active_users");
    Bus.$emit("get_em_job_count");
    this.allManageJobDPLocal = JSON.parse(localStorage.getItem("allManageJobDPLocal"))
      ? JSON.parse(localStorage.getItem("allManageJobDPLocal"))
      : "";
    this.activeTab = this.allManageJobDPLocal.activeTabLocal
      ? this.allManageJobDPLocal.activeTabLocal
      : "inprogress";
    this.getList(this.activeTab, this.activeApplicantTab, this.filterSearch);
    localStorage.removeItem("allManageJobDPLocal");

    this.getHourlyCost();
    // this.changeApplicantTabStatus(this.activeApplicantTab);

    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>

<style scoped></style>
