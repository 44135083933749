<template>
  <div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->
    <div class="">
      <div class="dashboard-headline">
        <h1>Dashboard</h1>
        <b-link href="" :to="{ name: 'postJob' }">
          <div class="employer_banner_dashboard">
            <h2>COMPLIMENTARY <span>2 JOB ADS /MONTH</span></h2>
            <p>* full time jobs only</p>
          </div>
        </b-link>
        <nav id="breadcrumbs" class="dark employer_nav">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">Job</a></li>
            <li>Post Job</li>
          </ul>
        </nav>
      </div>
      <div class="row common_row">
        <div
          class="col-xl-8 custom_col_eight_dashboard"
          :class="
            is_company_head == 'yes'
              ? 'half_width_post_job_dashboard'
              : 'full_width_post_job_dashboard'
          "
        >
          <div class="daily_paid_first_box daily_paid_first_box_web">
            <div class="row">
              <div class="col-xl-7">
                <div class="image_dashboard_div">
                  <img :src="webUrl + 'assets/images/DP 1.png'" />
                </div>
              </div>
              <div class="col-xl-5">
                <div class="text_dashboard_div">
                  <h2>Welcome To <br />EL Connect</h2>
                  <p>Explore Temp. Staff Job Postings. <br />Start hiring today!</p>
                  <button
                    v-if="
                      showPermissionEM('create job') && account_verification_status == 'completed'
                    "
                  >
                    <b-link href="" :to="{ name: 'DailyJobPost' }">Post A Job</b-link>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="daily_paid_first_box daily_paid_first_box_mobile">
            <div class="row">
              <div class="col-xl-5">
                <div class="text_dashboard_div">
                  <h2>Welcome To EL Connect</h2>
                  <p>Explore temp. staffing job postings. Start hiring today!</p>
                  <!-- <button v-if="showPermissionEM('create job')"><b-link href="" :to="{name: 'DailyJobPost'}">Post A Job</b-link></button> -->
                </div>
              </div>
              <div class="col-xl-7">
                <div class="image_dashboard_div text_dashboard_div">
                  <img :src="webUrl + 'assets/images/DP 1.png'" />
                  <button
                    v-if="
                      showPermissionEM('create job') && account_verification_status == 'completed'
                    "
                  >
                    <b-link href="" :to="{ name: 'DailyJobPost' }">Post A Job</b-link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 custom_col_two_dashboard" v-if="is_company_head == 'yes'">
          <div class="dashborad_second_common_box">
            <h2>Temp. Staffing Profile</h2>
            <!-- <img :src="webUrl+'assets/images/Circular Progress indicator.png'"> -->
            <div class="relative_chart">
              <p class="middle_chart_text">
                Profile Completion<br /><b>{{ this.dp_chart + "%" }}</b>
              </p>
            </div>
            <div class="canvas_dp">
              <canvas id="doughnut-chart-dp"></canvas>
            </div>
            <div class="anchor_center">
              <!-- <a href="javascript:void(0);" class="blue_anchor">Complete profile</a> -->
              <div v-if="dp_chart < 100">
                <b-link
                  v-if="mobile_verified && email_verified"
                  :to="{ name: 'empDailyPaidProfile' }"
                  >Complete Profile</b-link
                >
                <b-link v-else :to="{ name: 'employee-profile' }">Complete Profile</b-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 custom_col_two_dashboard" v-if="is_company_head == 'yes'">
          <div class="dashborad_second_common_box">
            <h2>Full Time Profile</h2>
            <!-- <img :src="webUrl+'assets/images/Circular Progress indicator Pink.png'"> -->
            <div class="relative_chart">
              <p class="middle_chart_text">
                Profile Completion<br /><b>{{ this.ft_chart + "%" }}</b>
              </p>
            </div>
            <div class="canvas_dp">
              <canvas id="doughnut-chart-ft"></canvas>
            </div>
            <div class="anchor_center">
              <!-- <a href="javascript:void(0);" class="pink_anchor">Complete profile</a> -->
              <b-link :to="{ name: 'employee-profile' }" v-if="ft_chart < 100"
                >Complete profile</b-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mobile_swap_row">
        <div class="col-xl-8">
          <div class="graph_div">
            <h2>Upcoming Jobs</h2>
            <span>Upcoming jobs within a month</span>
            <!-- <img id="placeholder-image" :src="webUrl+'assets/images/placeholder-image.png'" alt="Placeholder Image" /> -->
            <!-- <img :src="webUrl+'assets/images/image graph.png'"> -->
            <canvas id="upcoming-bar-chart"></canvas>
            <img id="no-data-graph" :src="webUrl + 'assets/images/no-record-graph.png'" />
          </div>
        </div>
        <div class="col-xl-4">
          <div class="tab_ul_div">
            <h2>My Jobs</h2>
            <ul v-if="showPermissionEM('view')">
              <li @click="dpJobList($event, 'inprogress')">
                <span class="orange_span"
                  >In-Progress Jobs<span class="number_span_dashboard">{{
                    inprogress_jobs_count
                  }}</span></span
                >
              </li>
              <li @click="dpJobList($event, 'upcoming')">
                <span class="blue_span"
                  >Upcoming Jobs<span class="number_span_dashboard">{{
                    upcoming_jobs_count
                  }}</span></span
                >
              </li>
              <li @click="dpJobList($event, 'completed')">
                <span class="green_span"
                  >Completed Jobs<span class="number_span_dashboard">{{
                    completed_jobs_count
                  }}</span></span
                >
              </li>
            </ul>
            <ul v-else>
              <li>
                <span class="orange_span"
                  >In-Progress Jobs<span class="number_span_dashboard">{{
                    inprogress_jobs_count
                  }}</span></span
                >
              </li>
              <li>
                <span class="blue_span"
                  >Upcoming Jobs<span class="number_span_dashboard">{{
                    upcoming_jobs_count
                  }}</span></span
                >
              </li>
              <li>
                <span class="green_span"
                  >Completed Jobs<span class="number_span_dashboard">{{
                    completed_jobs_count
                  }}</span></span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
    <canvas id="upcoming-bar-chart"></canvas>
    <canvas id="doughnut-chart-dp"></canvas>
    <canvas id="doughnut-chart-ft"></canvas>


  </div> -->
    <b-modal
      ref="survey-modal"
      id="background_custom_class_for_banner"
      title="Where did you hear about us?"
      hide-footer
      centered
      hide-header
      :no-close-on-backdrop="noClose"
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class survey_model"
    >
      <b-form>
        <template>
          <div class="row survay_img_mobile">
            <div class="col-xl-8 col-md-8 survay_img">
              <img :src="webUrl + 'assets/images/Pencil 1.png'" />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <h2 class="survay_heading">Where did you hear about us?</h2>
            </div>
          </div>
          <div class="row">
            <div
              class="mobile_tab_col_four col-xl-4 col-md-4 desktop_margin_bottom_zero single_profile_eye"
              id="show_hide_current_password"
            >
              <b-form-group
                id="input-group-8"
                label=""
                class="category-radio employer_multiple_radio"
              >
                <b-form-radio v-model="heard_from" value="facebook"><b>Facebook</b></b-form-radio>
                <b-form-radio v-model="heard_from" value="instagram"><b>Instagram</b></b-form-radio>
                <b-form-radio v-model="heard_from" value="tiktok"><b>Tiktok</b></b-form-radio>
                <b-form-radio v-model="heard_from" value="linkedin"><b>Linkedin</b></b-form-radio>
                <b-form-radio v-model="heard_from" value="google"><b>Google</b></b-form-radio>
                <b-form-radio v-model="heard_from" value="friends_family"
                  ><b>Friends/Family</b></b-form-radio
                >
                <b-form-radio v-model="heard_from" value="other"><b>Other</b></b-form-radio>
              </b-form-group>
            </div>
            <div class="col-xl-8 col-md-8 survay_img survay_img_desktop">
              <img :src="webUrl + 'assets/images/Pencil 1.png'" />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-8 col-md-8">
              <div
                class="submit-field desktop_margin_bottom_zero"
                :class="heard_from == 'other' ? '' : 'other_margin_top_zero'"
                v-if="heard_from == 'other'"
              >
                <div class="">
                  <div class="survay_textarea">
                    <!-- <h5 class="">Remark<span class="required_star">*</span></h5> -->
                    <textarea
                      type="text"
                      class="with-border desktop_margin_bottom_zero form-control"
                      v-model="heard_from_comment"
                      placeholder="State here"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <!-- Button -->
            <div
              class="col-xl-4 col-md-4 text-md-right new_text_align_end common_crop_reset_btn survey_model_btn"
            >
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="surveyFormUpdateFLEmp"
                >Submit</b-button
              >
            </div>
          </div>
        </template>
      </b-form>
    </b-modal>
    <b-modal
      ref="job-preference-modal"
      title="Choose your Posting Preference"
      id="background_custom_class_for_banner"
      hide-footer
      centered
      hide-header
      :no-close-on-backdrop="noClose"
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class job_prefrence_model"
    >
      <b-form>
        <template>
          <div class="row">
            <div class="col-xl-12 single_profile_eye" id="show_hide_current_password">
              <h2>Choose one or both services</h2>
              <h3>Welcome! Let's tailor your experience to match your hiring needs</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-md-6" @click="selectJobTypeDP">
              <!-- <input v-model = 'job_preference_dp'> -->
              <!-- <b-form-input>hello</b-form-input> -->
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_dp left_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/dp_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"></b-form-checkbox> -->
                <strong>Temporary Staffing Services</strong>
                <p>Ideal for short-term, part-time, seasonal demands & project-based staffing</p>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-md-6" @click="selectJobTypeFT">
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_ft right_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/ft_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"></b-form-checkbox> -->
                <strong>Full Time Posting</strong>
                <p>Permanent employment for your business hiring needs</p>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 text-md-center common_crop_reset_btn mt-5">
              <b-button
                variant="success"
                class="btn green-border-btntext-uppercase x-md btn-shadow"
                :class="job_preference_dp == 'yes' || job_preference_ft == 'yes' ? '' : 'disabled'"
                @click.prevent="updateUserJobPreference"
                >Submit</b-button
              >
            </div>
          </div>
        </template>
      </b-form>
    </b-modal>
    <div class="custom_spacer_emp_dashboard"></div>
    <!-- Spacer -->

    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";
import Bus from "../../event-bus";
import Chart from "chart.js";
import permission from "../../../../server/permission.js";

export default {
  components: {
    Chart,
  },
  name: "PlanetChart",
  data() {
    return {
      account_verification_status: "",
      webUrl: process.env.VUE_APP_URL,
      company_admin_id: "",
      logged_in_user_id: "",
      is_company_head: "",
      company_id: "",
      allDatas: [],
      permissionActions: "",
      email_verified: false,
      mobile_verified: false,
      inprogress_jobs_count: "",
      upcoming_jobs_count: "",
      completed_jobs_count: "",
      total_data: [],
      upcomingBarChartData: null,
      doughnutChartDPData: null,
      doughnutChartFTData: null,
      ft_chart: 20,
      dp_chart: 20,
      link: {
        website: "",
        fb: "",
        google: "",
        twitter: "",
        link: "",
      },
      noClose: true,
      heard_from: "",
      heard_from_comment: "",
      job_preference: "",
      job_preference_update: "",
      job_preference_dp: "no",
      job_preference_ft: "no",
    };
  },
  methods: {
    selectJobTypeDP() {
      if (this.job_preference_dp == "no") {
        $(".job_type_dp").addClass("add_border_dp");
        this.job_preference_dp = "yes";
      } else {
        $(".job_type_dp").removeClass("add_border_dp");
        this.job_preference_dp = "no";
      }
    },
    selectJobTypeFT() {
      if (this.job_preference_ft == "no") {
        $(".job_type_ft").addClass("add_border_dp");
        this.job_preference_ft = "yes";
      } else {
        $(".job_type_ft").removeClass("add_border_dp");
        this.job_preference_ft = "no";
      }
    },
    dpJobList(event, activeTabLocal) {
      if (event) {
        event.stopPropagation();
      }

      activeTabLocal = activeTabLocal ? activeTabLocal : "inprogress";
      var allManageJobDPLocal = {
        activeTabLocal: activeTabLocal,
      };

      localStorage.setItem("allManageJobDPLocal", JSON.stringify(allManageJobDPLocal));
      window.location.replace("/v2/employer/dp-job-manage");
    },
    getEmpDashboardData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.logged_in_user_id,
            company_id: this.company_id,
          },
          api: "/api/emp-dashboard-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            console.log(this.error_message);
            return [];
          } else {
            this.inprogress_jobs_count = this.$store.getters.getResults.inprogress_jobs_count;
            this.upcoming_jobs_count = this.$store.getters.getResults.upcoming_jobs_count;
            this.completed_jobs_count = this.$store.getters.getResults.completed_jobs_count;
            this.total_data = this.$store.getters.getResults.job_data;
            var total_jobs = [];
            var total_vacancies = [];
            var total_applied = [];
            var total_selected = [];
            if (this.total_data.length > 0) {
              this.total_data.forEach((element, key) => {
                total_jobs.push(element.job_unique_id);
                total_vacancies.push(element.total_vacancies);
                total_applied.push(element.userJobsApplication.length);
                total_selected.push(element.userJobsselected.length);
                if (key == this.total_data.length - 1) {
                  this.upcomingBarChartData1 = {
                    labels: total_jobs,
                    datasets: [
                      {
                        label: "Selected",
                        type: "bar",
                        data: total_selected,
                        backgroundColor: "#4472c4",
                        borderColor: "#4472c4",
                        borderWidth: 1,
                      },
                      {
                        label: "Applied",
                        type: "bar",
                        // data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
                        data: total_applied,
                        backgroundColor: "#ed7d31",
                        borderColor: "#ed7d31",
                        borderWidth: 1,
                      },
                      {
                        label: "Vacancies",
                        type: "bar",
                        // data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
                        data: total_vacancies,
                        backgroundColor: "#a5a5a5",
                        borderColor: "#a5a5a5",
                        borderWidth: 1,
                        strokeColor: "#79D1CF",
                      },
                    ],
                  };
                  const upcomingBarChartData = {
                    type: "bar",
                    data: this.upcomingBarChartData1,
                    options: {
                      legend: {
                        position: "bottom",
                        display: true,
                        labels: {
                          boxWidth: 10,
                        },
                      },
                      animation: {
                        // duration: 1,
                        duration: 500,
                        easing: "easeOutQuart",
                        onComplete: function () {
                          var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                          ctx.font = Chart.helpers.fontString(
                            Chart.defaults.global.defaultFontSize,
                            Chart.defaults.global.defaultFontStyle,
                            Chart.defaults.global.defaultFontFamily
                          );
                          ctx.textAlign = "center";
                          ctx.textBaseline = "bottom";

                          this.data.datasets.forEach(function (dataset, i) {
                            if (i == 2) {
                              var meta = chartInstance.controller.getDatasetMeta(i);
                              meta.data.forEach(function (bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                              });
                            }
                          });
                        },
                      },
                      scales: {
                        xAxes: [
                          {
                            display: true,
                            // barPercentage: 0.2,
                            gridLines: {
                              display: false,
                            },
                            ticks: {
                              maxRotation: 60,
                              minRotation: 60,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            display: true,
                            position: "bottom",
                            ticks: {
                              stepSize: 2,
                              autoSkip: false,
                              beginAtZero: true,
                            },
                          },
                        ],
                        responsive: true,
                        maintainAspectRatio: true,
                      },
                    },
                  };
                  document.getElementById("no-data-graph").style.display = "none";
                  const ctx = document.getElementById("upcoming-bar-chart");
                  new Chart(ctx, upcomingBarChartData);
                }
              });
            } else {
              document.getElementById("upcoming-bar-chart").style.display = "none";
              document.getElementById("no-data-graph").style.display = "block";
              // this.upcomingBarChartData1= {
              //     labels: total_jobs,
              //     datasets: [
              //         {
              //             label: "Selected",
              //             type: "bar",
              //             data: total_selected,
              //             backgroundColor: "#4472c4",
              //             borderColor: "#4472c4",
              //             borderWidth: 1
              //         },
              //         {
              //             label: "Applied",
              //             type: "bar",
              //             // data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
              //             data: total_applied,
              //             backgroundColor: "#ed7d31",
              //             borderColor: "#ed7d31",
              //             borderWidth: 1
              //         },
              //         {
              //             label: "Vacancies",
              //             type: "bar",
              //             // data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
              //             data: total_vacancies,
              //             backgroundColor: "#a5a5a5",
              //             borderColor: "#a5a5a5",
              //             borderWidth: 1,
              //         }
              //     ]
              // }
              // const upcomingBarChartData = {
              //     type: "bar",
              //     data: this.upcomingBarChartData1,
              //     options: {
              //         legend: {
              //             position: 'bottom',
              //             display: true,
              //             labels: {
              //                 boxWidth: 10
              //             }
              //         },
              //         scales: {
              //             xAxes: [{
              //                 display: true,
              //                 // barPercentage: 0.2,
              //                 gridLines: {
              //                     display: false,
              //                 },
              //                 ticks: {
              //                     maxRotation: 60,
              //                     minRotation: 60
              //                 },
              //             }],
              //             yAxes: [{
              //                 display: true,
              //                 position: 'bottom',
              //                 ticks: {
              //                 stepSize: 2,
              //                 autoSkip: false,
              //                 beginAtZero: true,
              //                 },
              //             }],

              //         },
              //     }
              // };
              // const ctx = document.getElementById('upcoming-bar-chart');
              // new Chart(ctx, upcomingBarChartData);
            }
          }
        });
    },
    getEditData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.logged_in_user_id,
            company_admin_id: this.company_admin_id
              ? this.company_admin_id
              : this.logged_in_user_id,
          },
          api: "/api/employee-profile-dashboard",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            console.log(this.error_message);
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.allDatas = this.$store.getters.getResults;
              this.email_verified = this.allDatas.user["email_verified"] == "yes" ? true : false;
              this.mobile_verified = this.allDatas.user["mobile_verified"] == "yes" ? true : false;
              this.account_verification_status =
                this.$store.getters.getResults.user.account_verification_status;
              this.heard_from = this.$store.getters.getResults.user.heard_from;
              this.job_preference = this.$store.getters.getResults.user.job_preference;
              if (
                (this.job_preference == "" || this.job_preference == null) &&
                this.is_company_head == "yes"
              ) {
                this.$refs["job-preference-modal"].show();
              }
              if (
                this.job_preference != "" &&
                this.job_preference != null &&
                this.is_company_head == "yes" &&
                (this.heard_from == "" || this.heard_from == null) &&
                this.is_company_head == "yes"
              ) {
                this.$refs["survey-modal"].show();
              }

              if (this.allDatas.user["email_verified"] == "yes") {
                this.ft_chart = this.ft_chart + 10;
                this.dp_chart = this.dp_chart + 10;
              }
              if (this.allDatas.user["mobile_verified"] == "yes") {
                this.ft_chart = this.ft_chart + 10;
                this.dp_chart = this.dp_chart + 10;
              }
              if (this.allDatas.data.acra && this.allDatas.isAddress == 1) {
                this.dp_chart = this.dp_chart + 20;
              }
              // if((this.allDatas.user['full_name'] && this.allDatas.user['full_name'] != 'Demo Demo') && (this.allDatas.user['mobile_number']
              //     && this.allDatas.user['mobile_number'] != '12345678') && this.allDatas.user['email'] && this.allDatas.user['gender'] && (this.allDatas.data['name'] && this.allDatas.data['name'] != 'test.co')
              //     && this.allDatas.data['registration_id'] && this.allDatas.data['company_logo'] && this.allDatas.user['banner_image'] && this.allDatas.data.address && this.allDatas.data.description && this.allDatas.user['short_description']
              //     && this.allDatas.data.company_industry_id
              //     && this.allDatas.data.closed_by
              //     && this.link.website && this.link.fb && this.link.link && this.link.twitter
              // ){
              //     this.ft_chart = this.ft_chart +60;
              //     this.dp_chart = this.dp_chart +40;

              // }
              if (
                this.allDatas.user["full_name"] &&
                this.allDatas.user["full_name"] != "Demo Demo" &&
                this.allDatas.user["mobile_number"] &&
                this.allDatas.user["mobile_number"] != "12345678" &&
                this.allDatas.user["email"] &&
                this.allDatas.user["gender"] &&
                this.allDatas.data["name"] &&
                this.allDatas.data["name"] != "test.co" &&
                this.allDatas.data["registration_id"] &&
                this.allDatas.data["company_logo"] &&
                this.allDatas.data.company_industry_id
              ) {
                this.ft_chart = this.ft_chart + 40;
                this.dp_chart = this.dp_chart + 40;
              }

              if (
                this.allDatas.user["banner_image"] &&
                this.allDatas.data.address &&
                this.allDatas.data.description &&
                this.allDatas.user["short_description"]
              ) {
                // if(this.link.website || this.link.fb || this.link.link || this.link.twitter){
                //     this.ft_chart = this.ft_chart +20;
                // }else{
                //     this.ft_chart = this.ft_chart +10;
                // }
                this.ft_chart = this.ft_chart + 20;
              }

              this.doughnutChartDPData = {
                labels: ["", ""],
                datasets: [
                  {
                    label: "Profile Completion",
                    data: [this.dp_chart, 100 - this.dp_chart],
                    backgroundColor: ["#4c8bf5", "#e3ecfd"],
                    hoverBackgroundColor: ["#4c8bf5", "#e3ecfd"],
                    hoverOffset: 4,
                    borderWidth: 1,
                    borderRadius: 1000,
                    borderWidth: 0,
                  },
                ],
              };
              const doughnutChartDP = {
                type: "doughnut",
                data: this.doughnutChartDPData,
                options: {
                  responsive: true,
                  legend: {
                    position: "bottom",
                    display: false,
                    responsive: true,
                    maintainAspectRatio: false,
                  },
                  cutoutPercentage: 80,
                  title: {
                    display: true,
                    // text: "Profile Completion " + this.dp_chart + "%",
                    // position: 'bottom'
                  },
                  tooltips: {
                    filter: function (item, data) {
                      var label = data.labels[item.index];
                      if (label) return item;
                    },
                  },
                },
              };

              const ctxDP = document.getElementById("doughnut-chart-dp");
              Chart.pluginService.register({
                afterUpdate: function (ctxDP) {
                  var a = ctxDP.config.data.datasets.length - 1;
                  for (let i in ctxDP.config.data.datasets) {
                    for (var j = ctxDP.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                      if (Number(j) == ctxDP.config.data.datasets[i].data.length - 1) continue;
                      var arc = ctxDP.getDatasetMeta(i).data[j];
                      arc.round = {
                        x: (ctxDP.chartArea.left + ctxDP.chartArea.right) / 2,
                        y: (ctxDP.chartArea.top + ctxDP.chartArea.bottom) / 2,
                        radius: ctxDP.innerRadius + ctxDP.radiusLength / 2 + a * ctxDP.radiusLength,
                        thickness: ctxDP.radiusLength / 2 - 0,
                        backgroundColor: arc._model.backgroundColor,
                      };
                    }
                    a--;
                  }
                },
                afterDraw: function (ctxDP) {
                  var ctx = ctxDP.chart.ctx;
                  for (let i in ctxDP.config.data.datasets) {
                    for (var j = ctxDP.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                      if (Number(j) == ctxDP.config.data.datasets[i].data.length - 1) continue;
                      var arc = ctxDP.getDatasetMeta(i).data[j];
                      var startAngle = Math.PI / 2 - arc._view.startAngle;
                      var endAngle = Math.PI / 2 - arc._view.endAngle;

                      ctx.save();
                      // console.log(typeof arc != "undefined",'arcarcarc',typeof arc.round,arc);
                      if (typeof arc.round != "undefined") {
                        ctx.translate(arc.round.x, arc.round.y);
                        // console.log(arc.round.startAngle)
                        ctx.fillStyle = arc.round.backgroundColor;
                        ctx.beginPath();
                        ctx.arc(
                          arc.round.radius * Math.sin(startAngle),
                          arc.round.radius * Math.cos(startAngle),
                          arc.round.thickness,
                          0,
                          4 * Math.PI
                        );
                        ctx.arc(
                          arc.round.radius * Math.sin(endAngle),
                          arc.round.radius * Math.cos(endAngle),
                          arc.round.thickness,
                          0,
                          4 * Math.PI
                        );
                        ctx.closePath();
                        ctx.fill();
                        ctx.restore();
                      }
                    }
                  }
                },
              });
              ctxDP.setAttribute("width", "300");
              ctxDP.setAttribute("height", "370");
              new Chart(ctxDP, doughnutChartDP);

              this.doughnutChartFTData = {
                labels: ["", ""],
                datasets: [
                  {
                    label: "Profile Completion",
                    data: [this.ft_chart, 100 - this.ft_chart],
                    // data: [60,100],
                    backgroundColor: ["#ef1aa7", "#ffedf9"],
                    hoverBackgroundColor: ["#ef1aa7", "#ffedf9"],
                    hoverOffset: 4,
                    borderWidth: [0, 0],
                  },
                ],
              };
              const doughnutChartFT = {
                type: "doughnut",
                data: this.doughnutChartFTData,
                options: {
                  legend: {
                    position: "bottom",
                    display: false,
                  },
                  responsive: true,
                  cutoutPercentage: 80,
                  borderRadius: 10,
                  title: {
                    display: true,
                    // text: "Profile Completion " + this.ft_chart +"%"
                  },
                  position: "center",
                  verticalAlign: "middle",
                  tooltips: {
                    filter: function (item, data) {
                      var label = data.labels[item.index];
                      if (label) return item;
                    },
                  },
                },
              };

              const ctxFT = document.getElementById("doughnut-chart-ft");
              Chart.pluginService.register({
                afterUpdate: function (ctxFT) {
                  var a = ctxFT.config.data.datasets.length - 1;
                  for (let i in ctxFT.config.data.datasets) {
                    for (var j = ctxFT.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                      if (Number(j) == ctxFT.config.data.datasets[i].data.length - 1) continue;
                      var arc = ctxFT.getDatasetMeta(i).data[j];
                      arc.round = {
                        x: (ctxFT.chartArea.left + ctxFT.chartArea.right) / 2,
                        y: (ctxFT.chartArea.top + ctxFT.chartArea.bottom) / 2,
                        radius: ctxFT.innerRadius + ctxFT.radiusLength / 2 + a * ctxFT.radiusLength,
                        thickness: ctxFT.radiusLength / 2 - 0,
                        backgroundColor: arc._model.backgroundColor,
                      };
                    }
                    a--;
                  }
                },
                afterDraw: function (ctxFT) {
                  var ctx = ctxFT.chart.ctx;
                  for (let i in ctxFT.config.data.datasets) {
                    for (var j = ctxFT.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                      if (Number(j) == ctxFT.config.data.datasets[i].data.length - 1) continue;
                      var arc = ctxFT.getDatasetMeta(i).data[j];
                      var startAngle = Math.PI / 2 - arc._view.startAngle;
                      var endAngle = Math.PI / 2 - arc._view.endAngle;

                      ctx.save();
                      if (typeof arc.round != "undefined") {
                        ctx.translate(arc.round.x, arc.round.y);
                        // console.log(arc.round.startAngle)
                        ctx.fillStyle = arc.round.backgroundColor;
                        ctx.beginPath();
                        ctx.arc(
                          arc.round.radius * Math.sin(startAngle),
                          arc.round.radius * Math.cos(startAngle),
                          arc.round.thickness,
                          0,
                          4 * Math.PI
                        );
                        ctx.arc(
                          arc.round.radius * Math.sin(endAngle),
                          arc.round.radius * Math.cos(endAngle),
                          arc.round.thickness,
                          0,
                          4 * Math.PI
                        );
                        ctx.closePath();
                        ctx.fill();
                        ctx.restore();
                      }
                    }
                  }
                },
              });
              ctxFT.setAttribute("width", "300");
              ctxFT.setAttribute("height", "370");
              new Chart(ctxFT, doughnutChartFT);
            }
          }
        });
    },
    getSocialData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.logged_in_user_id,
          },
          api: "/api/employee-social",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            var totalLink = [];
            totalLink = this.$store.getters.getResults.data;
            if (totalLink.length > 0) {
              totalLink.forEach((accounts, key) => {
                if (accounts.account_type == "website") {
                  this.link.website = accounts.url;
                }

                if (accounts.account_type == "fb") {
                  this.link.fb = accounts.url;
                }
                if (accounts.account_type == "google") {
                  this.link.google = accounts.url;
                }
                if (accounts.account_type == "link") {
                  this.link.link = accounts.url;
                }
                if (accounts.account_type == "twitter") {
                  this.link.twitter = accounts.url;
                }
                if (key == totalLink.length - 1) {
                  this.getEditData();
                }
              });
            } else {
              this.getEditData();
            }
          }
        });
    },

    permissionEM() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.is_company_head == "no" &&
          (this.$store.getters.currentUser.user_type_id == 3 ||
            this.$store.getters.currentUser.user_type_id == 4)
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.permissionActions = permission.getPermission(menu, "Manage Jobs");
        }
      }
    },
    showPermissionEM(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionActions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    updateUserJobPreference() {
      if (this.job_preference_dp == "no" && this.job_preference_ft == "no") {
        this.error_message = "Please select Job Preference";
        this.showAlert();
      } else if (this.job_preference_dp == "yes" && this.job_preference_ft == "yes") {
        this.job_preference_update = "both";
      } else if (this.job_preference_dp == "yes") {
        this.job_preference_update = "daily_paid";
      } else {
        this.job_preference_update = "full_time";
      }
      this.$store
        .dispatch(POST_API, {
          data: {
            job_preference: this.job_preference_update,
            user_id: this.logged_in_user_id,
          },
          api: "/api/updateUserJobPreference",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
          } else {
            console.log("updateUserJobPreference");
            this.$refs["job-preference-modal"].hide();

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Preference saved successfully",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.$refs["job-preference-modal"].hide();
              if (
                (this.heard_from == "" || this.heard_from == null) &&
                this.is_company_head == "yes"
              ) {
                this.$refs["survey-modal"].show();
              }
            });
          }
        });
    },
    surveyFormUpdateFLEmp() {
      this.$store
        .dispatch(POST_API, {
          data: {
            heard_from: this.heard_from,
            heard_from_comment: this.heard_from_comment,
            user_id: this.logged_in_user_id,
          },
          api: "/api/surveyFormUpdateFLEmp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.$refs["survey-modal"].hide();
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Thank you for your time",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              console.log("survey completed");
            });
          }
        });
    },
  },
  mounted() {
    this.logged_in_user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    this.is_company_head =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.is_company_head
        : "no";
    this.permissionEM();

    Bus.$emit("active_users");

    // this.getEditData();
    this.getSocialData();
    this.getEmpDashboardData();

    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>
