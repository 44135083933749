<template>
  <div class="animated fadeIn">
    <div class="create-new-job-section email-template-section">
      <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit ELC Credit</h1>
            </div> -->
      <!-- <div class="dashboard-headline">
                <h1 class="header_detail_new_h1"><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit ELC Credit</h1>
            </div> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <div class="edit-profile-section border-radius-4 white-bg common_padding_30">
        <b-form @submit="formSubmit">
          <div class="edit-form-box desktop_margin_top_zero">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5>Package Name</h5>
                  <b-form-group
                    id="coin-group"
                    label=""
                    class="required desktop_margin_bottom_zero"
                  >
                    <b-form-input
                      id="coin"
                      v-model="form.package_name"
                      type="text"
                      placeholder="Enter Package Name"
                      class="with-border desktop_margin_bottom_zero remove_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5>No. of Post</h5>
                  <b-form-group
                    id="coin-group"
                    label=""
                    class="required desktop_margin_bottom_zero"
                  >
                    <b-form-input
                      id="coin"
                      v-on:keyup="getCoinDollarVal"
                      v-model="form.no_of_post"
                      type="number"
                      min="1"
                      max="100000"
                      placeholder="Enter No. of Post.."
                      class="with-border desktop_margin_bottom_zero remove_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5>Discount</h5>
                  <b-form-group
                    id="discount-group"
                    label=""
                    class="required desktop_margin_bottom_zero"
                  >
                    <b-form-input
                      id="discount"
                      v-on:keyup="getCoinDollarVal"
                      v-model="form.discount"
                      type="number"
                      min="0"
                      max="100000"
                      placeholder="Enter Discount.."
                      class="with-border desktop_margin_bottom_zero remove_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col lg="12" md="12" class="submit-field">
                                    <h5>ELC Credit</h5>
                                    <b-form-group id="coin-group" label="" class="required desktop_margin_bottom_zero">
                                        <b-form-input id="coin" v-on:keyup="getCoinDollarVal" v-model="form.coin" type="number" :readonly="true" min="1" max="100000" placeholder="Enter ELC Credit.." class="with-border desktop_margin_bottom_zero remove_box_shadow"></b-form-input>
                                    </b-form-group>
                                </b-col> -->

                <b-col lg="12" md="12" class="submit-field">
                  <h5>Featured Jobs</h5>
                  <b-form-group
                    id="coin-group"
                    label=""
                    class="required desktop_margin_bottom_zero"
                  >
                    <b-form-input
                      id="feature"
                      v-model="form.feature"
                      type="number"
                      min="0"
                      max="100000"
                      placeholder="Enter Featured Jobs"
                      class="with-border desktop_margin_bottom_zero remove_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5>Original Package Price($)</h5>
                  <b-form-group
                    id="coin-dollar-value-group"
                    label=""
                    class="required desktop_margin_bottom_zero"
                  >
                    <b-form-input
                      id="value_in_dollar"
                      v-model="form.value_in_dollar_original"
                      type="text"
                      :readonly="true"
                      class="with-border desktop_margin_bottom_zero remove_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12" class="submit-field">
                  <h5>Selling Price($)</h5>
                  <b-form-group
                    id="coin-dollar-value-group"
                    label=""
                    class="required desktop_margin_bottom_zero"
                  >
                    <b-form-input
                      id="value_in_dollar"
                      v-model="form.value_in_dollar"
                      type="text"
                      :readonly="true"
                      class="with-border desktop_margin_bottom_zero remove_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <!-- <b-col lg="12" md="12"> -->
            <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
              <b-button type="submit" variant="success" class="desktop_margin_left_zero"
                >Submit</b-button
              >
              <b-button :to="{ name: 'el-coin-list' }" variant="light">Cancel</b-button>
            </div>
            <!-- </b-col> -->
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";

export default {
  data() {
    return {
      form: {
        coin: "",
        discount: "",
        feature: "",
        value_in_dollar: "",
        dollar_value: "",
        total_coin: "",
      },
      items: null,
      // id: Vue.prototype.decrypt(this.$route.params.id),
      id: atob(this.$route.params.id),
      error_message: "",
      selectedImage: "",
      success_message: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      delta: undefined,
    };
  },

  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "el-coin-list" },
          text: "ELC Credits",
        },
        {
          to: null,
          text: "Edit ELC Credit",
        },
      ];
      return item;
    },
    getCoinDollarVal() {
      //this.form.value_in_dollar = this.form.coin / this.form.dollar_value;
      this.form.coin = this.coinsperjob * this.form.no_of_post;
      this.form.coin = this.coinsperjob * this.form.no_of_post;
      this.form.value_in_dollar_original = this.postperjob * this.form.no_of_post;
      this.form.value_in_dollar =
        this.postperjob * this.form.no_of_post * (1 - this.form.discount / 100);
    },
    getEditData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: this.status,
            page: this.currentPage,
            keyword: this.filter,
            id: this.id,
          },
          api: "/api/el-coin-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.form.coin = this.$store.getters.getResults.data.coin;
            this.form.no_of_post = this.$store.getters.getResults.data.no_of_post;
            this.form.package_name = this.$store.getters.getResults.data.package_name;

            this.form.discount = this.$store.getters.getResults.data.discount;
            this.form.feature = this.$store.getters.getResults.data.featured_jobs;

            // this.form.dollar_value = this.$store.getters.getResults.value_in_dollar.option_value;
            this.form.value_in_dollar = this.$store.getters.getResults.data.value_in_dollar;
            this.form.value_in_dollar_original = this.postperjob * this.form.no_of_post;
          }
        });
    },
    getSettings() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/expirySettings",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.expiry = this.$store.getters.getResults.data[0].option_value;
            this.coinsperjob = parseInt(this.$store.getters.getResults.data1[0].option_value);
            this.postperjob = parseInt(this.$store.getters.getResults.data2[0].option_value);
          }
        });
    },

    // getDollarValue() {
    //     return this.$store.dispatch(POST_API, {
    //             data: {
    //                 status: this.status,
    //                 page: this.currentPage,
    //                 keyword: this.filter,
    //                 id: this.id,
    //             },
    //             api: '/api/el-coin-dollar-value'
    //         })
    //         .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //                 this.error_message = this.$store.getters.getErrors;
    //                 this.showAlert();
    //                 return [];
    //             } else {
    //                 this.form.dollar_value = this.form.coin / this.$store.getters.getResults.data.option_value;
    //             }
    //         });
    // },

    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    formSubmit(evt) {
      evt.preventDefault();
      this.form.total_coin =
        parseInt(this.form.coin) + parseInt((this.form.coin * this.form.discount) / 100);
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.id,
            coin: this.form.coin,
            discount: this.form.discount,
            feature: this.form.feature,
            value_in_dollar: this.form.value_in_dollar,
            no_of_post: this.form.no_of_post,
            package_name: this.form.package_name,
            total_coin: this.form.coin,
          },
          api: "/api/el-coin-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.showSuccessAlert();
          }
        });
    },

    showSuccessAlert() {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: "ELC Credit Updated successfully.",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "el-coin-list" });
      });
    },
  },
  mounted() {
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Admin - Edit ELC Credit";
    this.getSettings();

    this.getEditData();

    // this.getDollarValue();
  },
};
</script>
