<template>
    <div class="animated fadeIn">
        <div class="support-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center top_black_heading_ticket dashboard-headline"> -->
                <!-- <div class="back_arrow_icon">
                    <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
                </div> -->
                <!-- <h3 class="mr-auto"><span class="icon-material-outline-arrow-back my_back_arrow" @click="$router.go(-1)"></span>Ticket Detail</h3>             -->
                <!-- <h1 class="mr-auto"><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Ticket Detail</h1> -->
            <!-- </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow">
                <div class="row">
                    <div class="col-md-8 col-sm-8 new_ticket_max_width_and_flex">
                    <h3 class="common_weight_600">{{itemTicket ? itemTicket.ticket_subject:'' | capitalize}}</h3>
                    <ul class="ticket_detail_view_ul">
                        <li><strong>Issue: </strong> {{itemTicket.ticket_messages_val ? itemTicket.ticket_messages_val[0].message : '' | capitalize}}</li>
                        <li><strong>Created At: </strong>{{itemTicket ? itemTicket.created_at : '' | moment}}</li>
                        <li><strong>Ticket Status: </strong> {{itemTicket ? itemTicket.status :'' | capitalize}}</li>
                        <li v-if="itemTicket.ticket_messages_val && itemTicket.ticket_messages_val[0].ticket_sender_profile != null && (itemTicket.ticket_messages_val[0].ticket_sender_profile.user_type_id == 5)"><strong>Name:</strong><b-link :to="showFreelancer('view profile') ? {name: 'jobseeker-dp-profile-view', params: {id: encrypt(itemTicket.ticket_messages_val[0].ticket_sender_profile.id)}} : null" target="_blank" style="color: #4c8bf5;"><span> {{(itemTicket.ticket_messages_val != null ? itemTicket.ticket_messages_val[0].ticket_sender_profile.full_name : '')}}</span></b-link></li>
                        <li v-if="itemTicket.ticket_messages_val &&  itemTicket.ticket_messages_val[0].ticket_sender_profile != null && (itemTicket.ticket_messages_val[0].ticket_sender_profile.user_type_id == 3 || itemTicket.ticket_messages_val[0].ticket_sender_profile.user_type_id == 4)"><strong>Name:</strong><b-link :to="showEmployer('view profile') ? {name: 'employer-profile-view', params: {id: encrypt(itemTicket.ticket_messages_val[0].ticket_sender_profile.id),company_id: encrypt(itemTicket.ticket_messages_val[0].ticket_sender_profile.company_id)}} : null" target="_blank" style="color: #4c8bf5;"><span> {{(itemTicket.ticket_messages_val != null ? itemTicket.ticket_messages_val[0].ticket_sender_profile.full_name : '')}}</span></b-link></li>
                        <!-- <li><strong>Ticket ID:</strong> {{itemTicket ? itemTicket.ticket_unique_id : ''}}</li>  -->
                        <li v-if="itemTicket && itemTicket.medical_document && itemTicket.medical_document.length > 0 && itemTicket.medical_document[0].document_status == 'pending' && itemTicket.reason == 'medical'"><strong>Medical Document Status :</strong> {{itemTicket ? itemTicket.medical_document[0].document_status :'' | capitalize}}</li>
                        <li v-if="itemTicket && itemTicket.certificate_document && itemTicket.certificate_document.length > 0 && itemTicket.certificate_document[0].status == 'pending' && itemTicket.reason == 'certificate'"><strong>Certificate Document Status :</strong> {{itemTicket ? itemTicket.certificate_document[0].status :'' | capitalize}}</li>

                        <li v-if="itemTicket && itemTicket.from_date"><span><strong>From: </strong> {{itemTicket ? itemTicket.from_date :'' | customFormatterObtainmentDate}}</span></li>
                        <li v-if="itemTicket && itemTicket.to_date" class="ticket_inline_li"><span><strong>To: </strong> {{itemTicket ? itemTicket.to_date :'' | customFormatterObtainmentDate}}</span></li>
                        <b-button variant="success" v-if="itemTicket && itemTicket.from_date && itemTicket.to_date" @click="editFromToDate(itemTicket.from_date,itemTicket.to_date)" class="ticket_edit_date_btn">Edit Date </b-button>

                        <li v-if="itemTicket.medical_document && itemTicket.medical_document.length >0 && itemTicket.medical_document[0].document_status == 'rejected'"><strong>Rejection Comment :</strong> {{itemTicket ? itemTicket.medical_document[0].rejection_comment :'' | capitalize}}</li>
                        <li v-if="itemTicket.certificate_document &&  itemTicket.certificate_document.length >0 && itemTicket.certificate_document[0].status == 'rejected'"><strong>Rejection Comment :</strong> {{itemTicket ? itemTicket.certificate_document[0].rejected_reason :'' | capitalize}}</li>

                        <div v-if="itemTicket.ticket_messages_val && itemTicket.ticket_messages_val.length > 0">
                            <span v-if="itemTicket.ticket_messages_val[0].ticket_sender_profile">
                                <!-- <li v-if="itemTicket.ticket_messages_val && itemTicket.ticket_messages_val[0].ticket_sender_profile.user_type_id == 5"><strong>Name:</strong><b-link :to="showFreelancer('view profile') ? {name: 'jobseeker-dp-profile-view', params: {id: encrypt(itemTicket.ticket_messages_val[0].ticket_sender_profile.id)}} : null" target="_blank" style="color: #4c8bf5;"><span> {{(itemTicket.ticket_messages_val != null ? itemTicket.ticket_messages_val[0].ticket_sender_profile.full_name : '')}}</span></b-link></li>
                                <li v-if="itemTicket.ticket_messages_val && (itemTicket.ticket_messages_val[0].ticket_sender_profile.user_type_id == 3 || itemTicket.ticket_messages_val[0].ticket_sender_profile.user_type_id == 4)"><strong>Name:</strong><b-link :to="showEmployer('view profile') ? {name: 'employer-profile-view', params: {id: encrypt(itemTicket.ticket_messages_val[0].ticket_sender_profile.id),company_id: encrypt(itemTicket.ticket_messages_val[0].ticket_sender_profile.company_id)}} : null" target="_blank" style="color: #4c8bf5;"><span> {{(itemTicket.ticket_messages_val != null ? itemTicket.ticket_messages_val[0].ticket_sender_profile.full_name : '')}}</span></b-link></li> -->
                                <!-- <li><strong>Email:</strong> {{itemTicket.ticket_messages_val ? itemTicket.ticket_messages_val[0].ticket_sender_profile.email : '' }}</li>  -->
                            </span>
                        </div>
                        <!-- <li><strong>Name:</strong> {{itemTicket.ticket_messages_val ? itemTicket.ticket_messages_val[0].ticket_sender_profile.full_name : '' | capitalize}}</li>  -->
                        <!-- <li><strong>Job ID:</strong> {{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</li>  -->
                    </ul>

                    <b-row>
                        <b-col md="2" v-for="(docs,key) in itemTicket.medical_document" :key="key">
                            <viewer :images="items"><br>

                            <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                            <b-img :src="webUrl+'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>

                            </viewer>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" v-for="(docs,key) in itemTicket.certificate_document" :key="key">
                            <viewer :images="items"><br>

                            <b-img :src="docs.url" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                            <ul class="certificate_image_in_ticket_view">
                               <li><span><strong>Certificate Number: </strong> {{docs && docs.certificate_number ? docs.certificate_number :'N/A'}}</span></li>
                               <li><span><strong>Obtainment Date: </strong> {{docs ? docs.obtainment_date :'' | customFormatterObtainmentDate}}</span></li>
                                <li class="ticket_inline_li"><span><strong>Expiry Date: </strong> {{docs ? docs.expiry_date :'' | customFormatterObtainmentDate}}</span></li>
                                <b-button variant="success" v-if="docs && docs.obtainment_date && docs.expiry_date && docs.status == 'pending' && show('edit certificate')" @click="editCertData(docs.id,docs.certificate_id,docs.user_id,docs.obtainment_date,docs.expiry_date,docs.certificate_number,docs.cert_type)" class="ticket_edit_date_btn edit_certificate_btns_ticket">Edit Certificate </b-button>

                            </ul>
                            </viewer>

                        </b-col>
                    </b-row>
                    <div v-if="itemTicket && itemTicket.medical_document && itemTicket.medical_document.length > 0 && itemTicket.medical_document[0].document_status == 'pending' && itemTicket.reason == 'medical' && (show('approve medical document') || show('reject medical document'))" class="approve_reject_btn_ticket_detail">
                        <b-button v-if="show('approve medical document')" type="submit" size="sm" variant="success" @click="approveDocument(itemTicket.medical_document[0].user_id)" class="desktop_margin_left_zero"><i class="fa fa-dot-circle-o"></i> Approve</b-button>

                        <b-button v-if="show('reject medical document')" type="reset" size="sm" variant="danger" @click="rejectDocument"><i class="fa fa-ban"></i> Reject</b-button>
                    </div>
                    <div v-if="itemTicket && itemTicket.certificate_document && itemTicket.certificate_document.length > 0 && itemTicket.certificate_document[0].status == 'pending' && itemTicket.reason == 'certificate' && (show('approve certificate') || show('reject certificate'))" class="approve_reject_btn_ticket_detail">
                        <b-button v-if="show('approve certificate')" type="submit" size="sm" variant="success" @click="approveCertificate" class="desktop_margin_left_zero"><i class="fa fa-dot-circle-o"></i> Approve</b-button>

                        <b-button v-if="show('reject certificate')" type="reset" size="sm" variant="danger" @click="rejectCertificate"><i class="fa fa-ban"></i> Reject</b-button>
                    </div>
                    </div>
                     <div class="col-md-4 col-sm-4 new_ticket_max_width_and_flex common_text_align_end_desktop">
                        <b-button @click="changeStatusPending(itemTicket.status) " variant="success" class="ticket_margin_top_zero mt-lg-0 mt-md-0 mt-3 desktop_margin_left_zero view_tkt_btn" v-if="(itemTicket != null) && (itemTicket.status == 'open') && show('change status')">PENDING TICKET</b-button>
                        <b-button @click="changeStatus(itemTicket.status) " variant="success" class="ticket_margin_top_zero mt-lg-0 mt-md-0 mt-3 desktop_margin_right_zero desktop_margin_left_zero view_tkt_btn" v-if="(itemTicket != null) && show('change status') && (itemTicket.status == 'open' || itemTicket.status == 'pending')">CLOSE TICKET</b-button>
                        <b-button @click="changeStatus(itemTicket.status)" variant="danger" class="ticket_margin_top_zero mt-lg-0 mt-md-0 mt-3 desktop_margin_right_zero desktop_margin_left_zero view_tkt_btn" v-if="(itemTicket != null) && show('change status') && (itemTicket.status == 'close')">OPEN TICKET</b-button>
                    </div>

                </div>
                <div class="row">
                        <div class="col-md-8 col-sm-12 new_ticket_max_width_and_flex last_section_detail">
                            <ul>
                                <span v-if="itemTicket && itemTicket.job != null">

                                <li v-if="(itemTicket && itemTicket.job != null && itemTicket.job.status == 'in-progress')" class="single_li"><i class="material-icons-outlined job_icon_ticket">business_center_outlined</i> <a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(itemTicket.job.id)+ "/"+itemTicket.job.status+"/null/null/null" : null'
                                        target="_blank"
                                        rel="noreferrer"
                                        style="color: #4c8bf5;"
                                        >{{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</a></li>
                                <li v-else-if="(itemTicket && itemTicket.job != null && itemTicket.job.status == 'active')" class="single_li"><i class="material-icons-outlined job_icon_ticket">business_center_outlined</i> <a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-upcoming/" + encrypt(itemTicket.job.id)+ "/"+itemTicket.job.status+"/null/null/null" : null'
                                        target="_blank"
                                        rel="noreferrer"
                                        style="color: #4c8bf5;"
                                        >{{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</a></li>
                                <li v-else-if="(itemTicket && itemTicket.job != null && itemTicket.job.status == 'completed')" class="single_li"><i class="material-icons-outlined job_icon_ticket">business_center_outlined</i> <a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-completed/" + encrypt(itemTicket.job.id)+ "/"+itemTicket.job.status+"/null/null/null" : null'
                                        target="_blank"
                                        rel="noreferrer"
                                        style="color: #4c8bf5;"
                                        >{{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</a></li>
                                <li v-else-if="(itemTicket && itemTicket.job != null && itemTicket.job.status == 'cancelled')" class="single_li"><i class="material-icons-outlined job_icon_ticket">business_center_outlined</i> <a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-cancelled/" + encrypt(itemTicket.job.id)+ "/"+itemTicket.job.status+"/null/null/null" : null'
                                        target="_blank"
                                        rel="noreferrer"
                                        style="color: #4c8bf5;"
                                        >{{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</a></li>
                                <li v-else class="single_li"><i class="material-icons-outlined job_icon_ticket">business_center_outlined</i> {{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</li>
                                
                                </span> 
                                <span v-else>
                                    <li class="single_li"><i class="material-icons-outlined job_icon_ticket">business_center_outlined</i> {{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</li>
                                </span>
                                <!-- <li class="single_li"><i class="material-icons-outlined job_icon_ticket">business_center_outlined</i> {{itemTicket ? (itemTicket.job != null ? itemTicket.job.job_unique_id : 'N/A') : ''}}</li> -->
                                <li><i class="material-icons-outlined job_icon_ticket">mail_outlined</i>  {{itemTicket.ticket_messages_val && itemTicket.ticket_messages_val[0].ticket_sender_profile != null ? itemTicket.ticket_messages_val[0].ticket_sender_profile.email : '' }}</li>
                            </ul>
                        </div>
                        <div class="last_section_detail col-md-4 col-sm-12 new_ticket_max_width_and_flex common_text_align_end_desktop">
                            <ul>
                              <li><strong>Ticket ID:</strong> {{itemTicket ? itemTicket.ticket_unique_id : ''}}</li>
                            </ul>
                        </div>
                    </div>
            </div>
            <!-- <div class="col-md-3 col-sm-3 ticket_align_end_desktop ticket_max_width_and_flex">
                <b-button @click="changeStatus(itemTicket.status) " variant="success" class="ticket_margin_top_zero mt-lg-0 mt-md-0 mt-3 desktop_margin_right_zero desktop_margin_left_zero" v-if="(itemTicket != null) && (itemTicket.status == 'open')">Close Ticket</b-button>

                <b-button @click="changeStatus(itemTicket.status)" variant="danger" class="ticket_margin_top_zero mt-lg-0 mt-md-0 mt-3 desktop_margin_right_zero desktop_margin_left_zero" v-if="(itemTicket != null) && (itemTicket.status == 'close')">Open Ticket</b-button>
            </div> -->
            <div class="ticket_second_box">
                <h3 class="ticket_h3">Reply</h3>
                <VuePerfectScrollbar class="scroll-area" id="chatbox">
                          <div class="reply-listing-box border-radius-4 white-bg border-midium-light mb-3 p-3 p-lg-4" v-if="items" v-for="(items,key) in items" :class="((items.ticket_sender_profile != null ? items.ticket_sender_profile.user_type_id : '') == 1 || (items.ticket_sender_profile != null ? items.ticket_sender_profile.user_type_id : '') == 2) ? 'blue_reply' : 'white_reply'">
                            <div class="repeate">
                                 <h4 v-if="user_id != (items.ticket_sender_profile != null ? items.ticket_sender_profile.id : '')" class="reply_h4">{{(items.ticket_sender_profile != null ? items.ticket_sender_profile.full_name : '')}}</h4>
                                 <!-- {{itemTicket.ticket_messages_val[0].ticket_sender_profile.full_name}} -->
                                <h4 v-else-if="(items.ticket_sender_profile != null ? items.ticket_sender_profile.user_type_id : '') == 1 || (items.ticket_sender_profile != null ? items.ticket_sender_profile.user_type_id : '') == 2" class="reply_h4">{{items.ticket_sender_profile.full_name}}</h4>
                                <span class="common_color_and_font line_height_12">{{items.created_at | moment}}</span>
                                <p class="desktop_margin_bottom_zero common_color_and_font common_word_break_full common_white_space_pre_wrap">{{items.message}}</p>
                          </div>
                          <div  class="ticket_inline_img" v-if="items.medical_document && items.medical_document.length > 0">
                             <viewer :images="images">
                                <img v-for="docs in items.medical_document.slice()" track-by="id" :src="docs.document" :key="docs.document" height="80" width="100" alt="certification img" class="ticket_document_img">
                            </viewer>
                            <!-- <div class="images" v-viewer>
                                <img v-for="src in images" :key="src" :src="src">
                            </div>
                            <b-link  type="button" style="text-decoration: underline;" @click="showDocChat(items.medical_document)" v-if="items.medical_document.length > 0"><img :src="docs.document"  height="80" width="100" alt="certification img" class="ticket_document_img"></b-link> -->
                            <!-- <viewer :images="items"><br>
                                <b-img :src="docs.document" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                            </viewer> -->
                          </div>
                          <!-- <b-row>
                            <b-col md="2" v-for="(docs,key) in items.medical_document" :key="key">
                                <viewer :images="items"><br>

                                <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                                <b-img :src="webUrl+ 'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>

                                </viewer>
                            </b-col>
                          </b-row> -->
                      </div>
                      </VuePerfectScrollbar>
                <!-- <div class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow">
                    <h3 class="common_weight_600">Me</h3>
                    <ul class="ticket_detail_view_ul">
                        <li>09 Dec, 2022 12:56 PM</li>
                        <li>dfgg</li>
                    </ul>
                </div> -->
            </div>
            <!-- <div class="post_rply_div ticket_second_box">
                <h3 class="ticket_h3">Post a reply</h3>
                 <b-form-textarea id="textarea-3" v-model="form.message" type="textarea" placeholder="Message"></b-form-textarea>
                <div slot="footer" class="form-btn pt-lg-3 desktop_padding_top_zero">
                    <b-button type="submit" @click="formSubmit" class="button ripple-effect big desktop_margin_bottom_zero desktop_margin_left_zero desktop_margin_right_zero common_btn_hover_unset">Post</b-button>
                </div>
            </div> -->
             <div class="post-repy-form ticket_second_box" >
                <h3 class="ticket_h3 common_blue_color">Post a reply</h3>
                    <b-form @submit="formSubmit">
                        <div class="row">
                            <div class="post_reply_width col-md-9 col-sm-12 mobile_max_width_and_flex">
                                <b-form-group id="input-group-5" label="" class="required textare_pdng">
                                    <b-form-textarea id="textarea-3" v-model="form.message" type="textarea" placeholder="Message"></b-form-textarea>
                                </b-form-group>
                            </div>
                            <!-- <b-row> -->
                                <!-- <b-col lg="12" md="12" class="textare_pdng"> -->
                                    <div class="col-md-3 col-sm-12 admin_multiple_image_container common_text_align_end_desktop mobile_max_width_and_flex">
                                        <div class="textare_pdng">
                                            <vue-upload-multiple-image @upload-success="uploadImageSuccess" :maxImage="maxImage" :multiple="multiple" @before-remove="beforeRemove" @edit-image="editImage" :showEdit="false" :data-images="images" dragText="Browse" browseText="Select Files"  primaryText="" markIsPrimaryText="" popupText="Image" v-model="form.images" accept=".jpg, .png, .jpeg" limit-exceeded="5" class="customize_delete_icon_file_upload image_glr new_post_image">
                                            </vue-upload-multiple-image>
                                        </div>
                                    </div>
                                <!-- </b-col> -->
                            <!-- </b-row> -->
                        </div>
                     <div slot="footer" class="form-btn textare_pdng">
                        <b-button type="submit" variant="success" class="common_btn_hover_unset button_min_width_130 button ripple-effect desktop_margin_right_zero desktop_margin_left_zero" v-if="show('post')">Post</b-button>
                    </div>
                    </b-form>


            </div>

            <!-- Tab Section HTML -->
            <!-- <div class="tab-section pt-4" v-if="items && items.length == 0">
                <div class="page-heading pb-3">
                    <h3 class="list-inline-item">Your Tickets</h3>
                </div>
                 <div class="inprogress-tab">
                    <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3">
                        <p>No record found</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="tab-section pt-4" v-if="items && items.length > 0">
                <div class="page-heading pb-3">
                    <h3 class="list-inline-item">Your Tickets</h3>
                </div>
                <div class="inprogress-tab">
                    <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3" v-if="items != null" v-for="(items,key) in items">
                        <div class="box-listing-head d-lg-flex d-sm-flex">
                            <div class="listing-head-left">
                                <p><span class="pl-0 ml-0">Ticket Id - {{items.ticket_unique_id}}</span><span>Ticket Date - {{items.created_at | moment}}</span></p>
                                <h3 class="sub-head pt-1">{{items.ticket_subject | capitalize}}</h3>
                            </div>

                        </div>

                        <div class="box-listing-content" v-if="items.ticket_messages_val">
                            <p v-if="items.ticket_messages_val[0].message.length <=150"><span class="red-text">Issue -</span>{{ items.ticket_messages_val[0].message | capitalize }} </p>
                            <p v-else><span class="red-text">Issue -</span>{{ items.ticket_messages_val[0].message.substring(0,150)+"..." | capitalize}}</p>
                            <b-link :to="{name: 'ViewTicketCompany', params: {id: encrypt(items.id)}}" class="btn green-bg-btn">Ticket Detail</b-link>
                        </div>
                    </div>

                </div>
            </div> -->


            <!-- /.Tab Section HTML -->
            <b-modal ref="reject-modal" title="Reject Medical Document" hide-footer >
                <b-form @submit="onSubmit">
                    <div class="edit-form-box">
                        <!-- <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                            <span>{{ error_message }}</span>
                        </b-alert> -->
                        <div class="profile-repeate form-box-field">

                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="input-group-3" label="Reject Comment">
                                        <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Comment" v-model="form.comment"></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow">Reject</b-button>
                        </div>
                        </div>
                    </div>
                </b-form>
            </b-modal>

            <b-modal ref="reject-cert-modal" title="Reject Certificate Document" hide-footer >
                <b-form @submit="onSubmitCert">
                    <div class="edit-form-box">
                        <!-- <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                            <span>{{ error_message }}</span>
                        </b-alert> -->
                        <div class="profile-repeate form-box-field">

                            <b-row>
                                <b-col lg="12" md="12">
                                    <b-form-group id="input-group-3" label="Reject Comment">
                                        <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Comment" v-model="form.commentcert"></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow">Reject</b-button>
                        </div>
                        </div>
                    </div>
                </b-form>
            </b-modal>
        </div>
        <b-modal ref="update-date-modal" title="Update Date" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <!-- <b-col lg="12" md="12"> -->
                                            <div class="submit-field">
                                                <!-- <b-form-group id="input-group-7" label="From Date" class="model_margin_bottom_zero"> -->
                                                    <h5>From Date<span class="required_star">*</span></h5>
                                                    <datepicker v-model="new_from_date" :disabled-dates="disabledFromDateObtainment" :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date"></datepicker>
                                                    <span class="input-icon calender_input_icon obtainment_date_calender"><b-img :src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                                <!-- </b-form-group> -->
                                            </div>
                                              <div class="submit-field">
                                                <!-- <b-form-group id="input-group-7" label="To Date" class="model_margin_bottom_zero"> -->
                                                    <h5>To Date<span class="required_star">*</span></h5>
                                                    <datepicker v-model="new_to_date" :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date"></datepicker>
                                                    <span class="input-icon calender_input_icon edit_date_calender_ticket"><b-img :src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                                <!-- </b-form-group> -->
                                              </div>
                                        <!-- </b-col> -->
                                <!-- <b-col lg="12" md="12"> -->
                                    <div slot="footer" class="form-btn common_crop_reset_btn">
                                        <b-button type="submit" variant="success" @click="dateFromTosubmit()" class="model_common_bt_success_color">Submit</b-button>
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                    </div>
                                <!-- </b-col> -->
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
        </b-modal>
        <b-modal ref="close-ticket-modal" title="Pending Ticket" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <!-- <b-col lg="12" md="12"> -->
                                            <div class="submit-field">
                                                <b-form-group id="input-group-8" label="Select Admin In-Charge" class="required common_margin_bottom_28">
                                                    <div class="custom-dropdown">
                                                        <b-form-select v-model="selected_admin_id" class="model_margin_bottom_zero">
                                                            <option value="" disabled>Select Admin</option>
                                                            <option :value="ct.id" v-for="ct in inChargeAdminList">{{ct.last_name}} {{ct.first_name}}</option>
                                                        </b-form-select>
                                                    </div>
                                                </b-form-group>
                                            </div>
                                        <!-- </b-col> -->
                                <!-- <b-col lg="12" md="12"> -->
                                    <div slot="footer" class="form-btn common_crop_reset_btn">
                                        <b-button type="submit" variant="success" @click="changeStatusPendingTicket('open')" class="model_common_bt_success_color">Submit</b-button>
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                    </div>
                                <!-- </b-col> -->
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
            </b-modal>
        <b-modal ref="update-cert-modal" title="Update Cert" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <!-- <b-col lg="12" md="12"> -->
                                            <div class="submit-field">
                                                <b-form-group id="input-group-8" :label="cert_lable" class="required common_margin_bottom_28">
                                                    <div class="custom-dropdown">
                                                        <b-form-select v-model="certificate_type" class="model_margin_bottom_zero">
                                                            <option value="" disabled>Please Select</option>
                                                            <option :value="ct.id" v-for="ct in cert_type">{{ct.name}}</option>
                                                        </b-form-select>
                                                    </div>
                                                </b-form-group>
                                            </div>
                                            <div class="submit-field">
                                                <!-- <b-form-group id="input-group-7" label="Obtainment Date" class="model_margin_bottom_zero"> -->
                                                    <h5>Obtainment Date<span class="required_star">*</span></h5>
                                                    <datepicker v-model="new_obtainment_date" :disabled-dates="disabledFromDateObtainment"  :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date"></datepicker>
                                                    <span class="input-icon calender_input_icon obtainment_date_calender update_cert_calender"><b-img :src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                                <!-- </b-form-group> -->
                                            </div>
                                              <!-- <div class="submit-field">
                                                    <h5>Expiry Date<span class="required_star">*</span></h5>
                                                    <datepicker v-model="new_expiry_date" :disabled-dates="disabledFromDate"  :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date"></datepicker>
                                                    <span class="input-icon calender_input_icon expiry_date_calender"><b-img :src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                              </div> -->
                                               <div class="submit-field">
                                                <!-- <b-form-group id="input-group-7" label="Certificate Number" class=""> -->
                                                     <h5>Certificate Number<span class="required_star">*</span></h5>
                                                    <b-form-input id="title" v-model="new_cert_number" type="text" placeholder="Enter Certificate No.." class="common_input_model"></b-form-input>
                                                <!-- </b-form-group> -->
                                              </div>
                                        <!-- </b-col> -->
                                <!-- <b-col lg="12" md="12"> -->
                                    <div slot="footer" class="form-btn common_crop_reset_btn">
                                        <b-button type="submit" variant="success" @click="updateCertData()" class="model_common_bt_success_color">Submit</b-button>
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                    </div>
                                <!-- </b-col> -->
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
            </b-modal>

        <b-modal ref="penalty-modal" title="Add Remarks" hide-footer content-class="common_model_header common_model_title penality_model" modal-class="background_custom_class">
        <b-form >
            <div class="edit-form-box model_margin_top_zero model_legend">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="6" md="6">
                          <p><b>Total Cancellation Count: </b> {{cancel_count}}</p>
                        </b-col>
                        <b-col lg="6" md="6">
                          <p><b>Never Turn Up Count: </b>{{ never_turn_up_count }}</p>
                        </b-col>
                        <b-col lg="6" md="6">
                          <p><b>Waiver Count: </b>{{ waive_off_count }}</p>
                        </b-col>

                        <b-col lg="12" md="12" class="mt-2">
                          <b-form-group id="input-group-3" class="contact-fields mobile_margin_bottom_zero">
                              <span class="custom-dropdown w-100">
                                  <b-form-select v-model="form.remarks" @change="updateAutoRemarks" class="desktop_margin_bottom_zero">
                                    <option value="">Please select</option>
                                    <option :value="rem.remarks" v-for="rem in remarksList">{{rem.remarks | capitalize}}</option>
                                    <option value="others">Others</option>
                                  </b-form-select>
                              </span>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" v-if="form.remarks == 'others'">
                            <b-form-group id="input-group-3" label="Remarks">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="Remarks" v-model="form.other_remarks"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" v-else-if="form.remarks != 'others' && form.remarks != ''">
                            <b-form-group id="input-group-3" label="Extra Remarks">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="Remarks" v-model="form.extra_remarks"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" v-if="(form.type == 'ptuser') || (form.type == 'employer')">
                              <b-form-group id="input-group-8" label="Submitted Documents?" class="category-radio required">
                                  <b-form-radio v-model="form.mc_uploaded" name="mc_uploaded" value="yes">Yes</b-form-radio>
                                  <b-form-radio v-model="form.mc_uploaded" name="mc_uploaded" value="no">No</b-form-radio>
                              </b-form-group>
                          </b-col>

                          <b-col lg="12" md="12">
                              <!-- <b-form-group id="input-group-8" label="Reduce count" class="category-radio required"> -->
                                <div class="penality_radio">
                                 <span style="font-size: 13px !important">Reduce count? <span class="required_star">*</span></span>
                                  <b-form-radio v-model="form.decrease_never_turn_up_count" name="decrease_never_turn_up_count" value="yes">Yes</b-form-radio>
                                  <b-form-radio v-model="form.decrease_never_turn_up_count" name="decrease_never_turn_up_count" value="no">No</b-form-radio>
                                  <b-form-radio v-model="form.decrease_never_turn_up_count" name="decrease_never_turn_up_count" value="waiver">Waiver</b-form-radio>
                                </div>
                              <!-- </b-form-group> -->
                          </b-col>
                          <b-col lg="12" md="12">
                            <!-- <b-form-group id="input-group-8" label="With Email?" class="category-radio required"> -->
                            <div class="penality_radio">
                                <span style="font-size: 13px !important">With Email? <span class="required_star">*<span class="hide_span">hd</span></span></span>
                                  <b-form-radio v-model="form.withEmail" name="withEmail" value="yes">Yes</b-form-radio>
                                  <b-form-radio v-model="form.withEmail" name="withEmail" value="no">No</b-form-radio>
                            </div>
                              <!-- </b-form-group> -->
                          </b-col>

                    </b-row>
                <div class="pt-2 text-md-left text-center desktop_margin_top_zero common_crop_reset_btn penality_btn_div">
                    <b-button type="button" variant="danger" class="text-uppercase x-md btn-shadow desktop_margin_right_zero desktop_margin_left_zero model_common_bt_success_color" @click="approveDocumentFinal">Submit</b-button>
                    <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                </div>
                </div>
            </div>
        </b-form>
        </b-modal>
    </div>

</template>
<script>
  import { POST_API,GET_API, LOGOUT} from "../../../store/actions.type";
  import moment from "moment";
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  import Bus from "../../../event-bus";
  import Datepicker from 'vuejs-datepicker';
import permission from "../../../../../server/permission.js";

  export default {
    component:{
      VuePerfectScrollbar,
      VueUploadMultipleImage,
      Datepicker
    },
    data() {
      return {
        fields: [

            ],
            form: {
                job_id:'',
                ticket_subject:'',
                message:'',
                rowsPerPage:100,
                userServices:'all',
                document:'',
                comment:'',
                commentcert:'',
                mc_uploaded:'no',
                decrease_never_turn_up_count:'yes',
                other_remarks:'',
                extra_remarks:'',
                withEmail:'no',
                remarks:"others"
            },
            items:null,
            contact:null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: null,
            status: 'active',
            isBusy: false,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            to:'',
            from:'',

            webUrl: process.env.VUE_APP_URL,
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,

            id: atob(this.$route.params.id),
            itemTicket:[],
            imagesDoc: [],
            docx: [],
            maxImage:5,
            multiple:true,
            images: [],
            new_from_date:'',
            new_to_date:'',
            user_certificate_id:'',
            certificate_id:'',
            cert_user_id:'',
            new_obtainment_date:'',
            certificate_type:'',
            cert_type:[],
            inChargeAdminList:[],
            selected_admin_id:'',
            new_expiry_date:'',
            new_cert_number:'',
            new_cert_type:'',
            cert_lable:'',
            disabledFromDateObtainment: {
                from: new Date(Date.now() + 8640000)
            },
            disabledFromDate: {
                to: new Date()
            },
            remarksList:[],
            waive_off_count:0,
            cancel_count:0,
            never_turn_up_count:0,
            actions:'',
            actionsFreelancer:'',
            actionsEmployer:'',
            actionsManageDPJobs:'',
      }
    },
    components: {
      VuePerfectScrollbar,
      VueUploadMultipleImage,
      Datepicker
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
           return moment(date).format('DD MMM YYYY hh:mm A')

        },
        customFormatterObtainmentDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
    },

    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'admin-support-ticket-list'},
                text: 'Ticket',
            },{
                to:null,
                text: 'Ticket Detail'
            }];
            return item;
        },
        customFormatterOne(date) {

            return moment(date).format('DD-MM-YYYY');
        },
        showDocChat(doc){
            var docArr = []
            doc.forEach(element => {
                docArr.push(element.document)
            });
            this.$viewerApi({
                images: docArr,
            })
        },
        editFromToDate(from,to)
        {
          this.new_from_date = from;
          this.new_to_date = to;
          this.$refs['update-date-modal'].show();
        },
        editCertData(id,certificate_id,cert_user_id,obtainment_date,expiry_date,cert_number,cert_type_val)
        {
          this.user_certificate_id = id
          this.certificate_id = certificate_id;
          this.cert_user_id = cert_user_id;
          this.new_obtainment_date = obtainment_date;
          this.new_expiry_date = expiry_date;
          this.new_cert_number = cert_number;
          this.new_cert_type = cert_type_val;

          this.getCertType(certificate_id);
          console.log(id,certificate_id,cert_user_id,obtainment_date,expiry_date,cert_number,cert_type_val,'dfh')
          this.$refs['update-cert-modal'].show();
        },
        getCertType(certificate_id) {

            this.$store.dispatch(POST_API, {
                data:{
                    id:certificate_id
                },
                api: '/api/certificatetype'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.cert_type = this.$store.getters.getResults.data;
                    this.cert_lable = this.$store.getters.getResults.cert_type
                    this.certificate_type = this.new_cert_type;
                }
            });
        },
        hideModel()
        {
            this.new_from_date = '';
            this.new_to_date = '';
            this.$refs['update-date-modal'].hide();
            this.new_obtainment_date = '';
            this.new_expiry_date = '';
            this.new_cert_number = '';
            this.user_certificate_id = '';
            this.certificate_id = '';
            this.cert_user_id = '';
            this.new_cert_type = '';
            this.$refs['update-cert-modal'].hide();
            this.$refs['penalty-modal'].hide();
            this.$refs['close-ticket-modal'].hide();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        // scrollToEnd: function() {
        //     this.scollRepltDiv=true;
        //     setTimeout(() => {
        //         var myDiv = document.getElementById('chatbox');
        //         myDiv.scrollTop = myDiv.scrollHeight;
        //     }, 200);
        // },

        dateFromTosubmit(){
            var err = false;
            var msg = '';
            // console.log(moment(this.new_from_date).diff(moment(this.new_to_date)),moment(this.new_from_date).diff(moment(this.new_to_date)) >= 0 );

            if(this.new_from_date && this.new_to_date){
                // if(moment(this.new_from_date).format('DD-MM-YYYY') > moment(this.new_to_date).format('DD-MM-YYYY')){
                if(moment(this.new_from_date).diff(moment(this.new_to_date)) > 0){
                    err = true;
                    msg = 'Please select correct from and to date';
                }
            }
            if(err == false){
                return this.$store.dispatch(POST_API, {
                data: {
                        ticket_id: this.id,
                        from_date: this.new_from_date,
                        to_date:this.new_to_date,
                },
                api: '/api/admin-ticket-save-date'
                })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();

                        } else {
                            this.hideModel();
                            var message = 'Date updated successfully';
                            this.showSuccessAlert(message);
                            this.new_from_date = '';
                            this.new_to_date = '';
                        }
                    });
            }else{
                this.$swal({
                position: 'center',
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            });
            }
        },
        updateCertData(){
            var err = false;
            var msg = ''
            // if(this.new_obtainment_date && this.new_expiry_date){
            //     if(moment(this.new_obtainment_date).format('DD-MM-YYYY') >= moment(this.new_expiry_date).format('DD-MM-YYYY')){
            //     err = true;
            //     msg = 'Please select correct obtainment and expiry date';
            //     }
            // }

            if(err == false){
                return this.$store.dispatch(POST_API, {
                    data: {
                        user_certificate_id: this.user_certificate_id,
                        cert_user_id: this.cert_user_id,
                        certificate_id: this.certificate_id,
                        obtainment_date: this.new_obtainment_date,
                        // expiry_date:this.new_expiry_date,
                        certificate_number:this.new_cert_number,
                        cert_type: this.certificate_type,
                        prev_cert_type: this.new_cert_type

                    },
                    api: '/api/admin-cert-update-data'
                    })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.hideModel();
                        var message = 'Data updated successfully';
                        this.showSuccessAlert(message);
                        this.new_obtainment_date = '';
                        this.new_expiry_date = '';
                        this.new_cert_number = '';
                        this.user_certificate_id = '';
                        this.certificate_id = '';
                        this.cert_user_id = '';
                        this.new_cert_type = '';
                    }
                });
            }else{
                this.$swal({
                position: 'center',
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 1500
                });
            }
        },
         formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        ticket_id: this.id,
                        message: this.form.message,
                        user_id: this.user_id,
                        documents: this.imagesDoc,
                    },
                    api: "/api/admin-ticket-post-ticket-reply",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'Reply saved successfully';
                        this.form.message='';
                        this.form.document = '';
                        this.images = [];
                        this.imagesDoc = [];
                        this.showSuccessAlert(message);
                    }
                });
        },
        changeStatusPending()
        {
          this.selected_admin_id = '';
          this.$refs['close-ticket-modal'].show();
        },
        changeStatusPendingTicket(value) {
            console.log(value);
            if(value == 'open'){
              value = 'close';
            }else{
              value= 'open';
            }
            this.$swal({
                title: 'Please Confirm',
                // text: 'Are you sure to '+value+' the ticket?',
                text: 'Are you sure to change ticket status to pending?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
              })

            .then(result => {
                if (result.value) {
                    // var api = this.selected_admin_id ? '/api/admin-ticket-change-status-pending' : '/api/admin-ticket-change-status';
                    var api = '/api/admin-ticket-change-status-pending';
                    return this.$store.dispatch(POST_API, {
                    data: {
                        ticket_id: this.id,
                        user_id:this.user_id,
                        selected_admin_id:this.selected_admin_id
                    },
                    api: api,
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message='Ticket Status Changed to Pending Successfully.';
                        this.hideModel();
                        // Bus.$emit('refresh-support');
                        this.showSuccessAlert(message);
                    }
                });

                    }
                })

        },
        changeStatus(value) {
            console.log(value);
            if(value == 'open' || value == 'pending'){
              value = 'close';
              this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure to '+value+' the ticket?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
                input: 'checkbox',
                inputValue: 0, // Initial value
                inputPlaceholder: 'Ask for Review?',
                customClass:{
                    container: 'close_ticket_review',
                },
                preConfirm: (checkboxValue) => {
                    return { 
                        confirmed: checkboxValue, // Store checkbox value in a custom key
                        isConfirmed: true // Ensure isConfirmed is set to true
                    };
                }
              })

            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data: {
                            ticket_id: this.id,
                            user_id:this.user_id,
                            new_status: value,
                            ask_to_review: result.value.confirmed == 1 ? 'yes' : 'no',

                        },
                        api: "/api/admin-ticket-change-status",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Ticket Status Changed Successfully.';
                            // Bus.$emit('refresh-support');
                            this.showSuccessAlert(message);
                        }
                    });

                    }
                })
            }else{
              value= 'open';
              this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure to '+value+' the ticket?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
              })

            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data: {
                            ticket_id: this.id,
                            user_id:this.user_id,
                            new_status: value,
                            ask_to_review: result.value.confirmed == 1 ? 'yes' : 'no',

                        },
                        api: "/api/admin-ticket-change-status",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Ticket Status Changed Successfully.';
                            // Bus.$emit('refresh-support');
                            this.showSuccessAlert(message);
                        }
                    });

                }
            })
            }
        },
        getPenaltyRemarks() {
            return this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api: '/api/penalty-remarks',
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.remarksList = this.$store.getters.getResults.data;
                        this.form.remarks = 'MC submitted';
                        // this.subCatg(this.service_id);
                        return this.remarksList;
                    }
                });
        },
        getInChargeAdminList() {
            return this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api: '/api/incharge-admin-list',
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.inChargeAdminList = this.$store.getters.getResults.data;
                        // this.subCatg(this.service_id);
                        return this.inChargeAdminList;
                    }
                });
        },
        updateAutoRemarks(e){
          console.log(e, 'pp');
          if((e != 'others' && e != '') && (this.form.type == 'ptuser')){
            var remarks = this.remarksList.filter(item => { return item.remarks == e});
            this.form.extra_remarks = remarks.length > 0 ? remarks[0].sub_remarks : ''
          }
        },
        getPenaltyData(user_id){
          return this.$store.dispatch(POST_API, {
              data:{
                user_id: user_id,
              },
              api: '/api/get-penalty-support-ticket'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  return [];
              } else {

                this.never_turn_up_count = this.$store.getters.getResults.never_turn_up_count
                this.waive_off_count = this.$store.getters.getResults.waived_off_count
                this.cancel_count = this.$store.getters.getResults.cancel_count

                // var rem = this.$store.getters.getResults.data.remarks;
                var rem = '';
                this.form.remarks = 'MC submitted';
                this.form.other_remarks = '';

                // this.form.decrease_never_turn_up_count = ((this.$store.getters.getResults.user_job_data.is_waived_off == 'waiver') ? 'waiver' : ((this.$store.getters.getResults.user_job_data.not_turn_up == 'yes') ? 'yes' : 'no'));

                // for (let index = 0; index < this.remarksList.length; index++) {

                //   if(this.remarksList[index].remarks == rem){
                //     var spl = rem.split(' - ');
                //     if(spl.length > 1){
                //       this.form.remarks = spl[0];
                //       this.form.extra_remarks = spl[1];

                //     }else{
                //       this.form.remarks = spl[0];
                //     }
                //   }else{
                //     this.form.remarks = 'others';
                //     this.form.other_remarks = rem;
                //   }
                // }

              }
          })
        },
        approveDocument(user_id) {
            // this.getPenaltyRemarks();
            this.form.other_remarks = '';
            this.form.extra_remarks = '';
            this.form.withEmail = 'no';
            this.form.decrease_never_turn_up_count = 'no';
            this.getPenaltyData(user_id);

            this.remarksTitle = 'Add Remark';

              this.$refs['penalty-modal'].show();
        },
        approveDocumentFinal() {
            // this.$swal({
            //     title: 'Please Confirm',
            //     text: 'Are you sure you want to approve this document?',
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Yes!'
            //   })
            // .then(result => {
            //     if (result.value) {
                    var remarks = (this.form.remarks != '' ? (this.form.remarks == 'others' ? this.form.other_remarks : ((this.form.extra_remarks != '' && this.form.extra_remarks != null) ? this.form.remarks +' - '+this.form.extra_remarks : this.form.remarks)) : ((this.form.extra_remarks != '' && this.form.extra_remarks != null) ? this.form.remarks +' - '+this.form.extra_remarks : this.form.remarks));
                      // evt.preventDefault();
                    return this.$store.dispatch(POST_API, {
                    data: {
                        ticket_id: this.id,
                        user_id:this.user_id,
                        withEmail:this.form.withEmail,
                        type: 'update',
                        decrease_never_turn_up_count: this.form.decrease_never_turn_up_count,
                        remarks: remarks,
                        // remarks: this.form.other_remarks,
                    },
                    api: "/api/admin-approve-medical-document",
                        })
                        .then(async () => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                var message='Medical Document Approved Successfully.';
                                this.hideModel();
                                // Bus.$emit('refresh-support');
                                var monthlyTicketCount = await this.mcTicketCheck();
                                console.log(`monthly ticket count`, monthlyTicketCount)
                                if (monthlyTicketCount >= 2){
                                    var htmlString = `<p style="text-align: center">This user has ${monthlyTicketCount} approved MC in this month</br>Kindly review accordingly</p>`;
                                    this.$swal({
                                        position: 'center',
                                        imageUrl: '/assets/images/alert-icon.png',
                                        title: 'Gentle Reminder',
                                        html: htmlString,
                                        type: 'warning',
                                        showConfirmButton: false,
                                        showCloseButton: true,
                                    }).then(() => {
                                        this.showSuccessAlert(message);
                                    })
                                } else {
                                    this.showSuccessAlert(message);
                                }
                            }
                        });
                    // }
                // })

        },
        rejectDocument(){
            this.form.comment = '';
            this.$refs['reject-modal'].show();
        },
        onSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                data:{
                    ticket_id: this.id,
                    comment: this.form.comment,
                },
                api:'/api/admin-reject-medical-document'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                    else {
                    this.form.comment = '';
                    this.$refs['reject-modal'].hide();
                    var message ="Medical Document Rejected Successfully."
                    this.showSuccessAlert(message);

                }
            });
        },

        approveCertificate() {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to approve this certificate?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
              })

            .then(result => {
                if (result.value) {
                      // evt.preventDefault();
                    return this.$store.dispatch(POST_API, {
                    data: {
                        ticket_id: this.id,
                        user_id:this.user_id
                    },
                    api: "/api/admin-approve-certificate-document",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message='Certificate Document Approved Successfully.';
                        // Bus.$emit('refresh-support');
                        this.showSuccessAlert(message);
                    }
                });

                    }
                })

        },
        rejectCertificate(){
            this.form.commentcert = '';
            this.$refs['reject-cert-modal'].show();
        },
        onSubmitCert(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                data:{
                    ticket_id: this.id,
                    comment: this.form.commentcert,
                },
                api:'/api/admin-reject-certificate-document'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                    else {
                    this.form.commentcert = '';
                    this.$refs['reject-cert-modal'].hide();
                    var message ="Certificate Document Rejected Successfully."
                    this.showSuccessAlert(message);

                }
            });
        },
        getTicketListing() {

            return this.$store.dispatch(POST_API, {
                   data:{
                    signupType:'web',
                    status: this.form.userServices,
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy:this.sortBy,
                    sortDirection:this.sortDirection,
                    sortDesc:this.sortDesc,
                    ticket_id: this.id,
                    user_id:this.user_id
                   },
                   api: '/api/support-ticket-admin-view-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.ticketMessage;
                        this.itemTicket = this.$store.getters.getResults.data.ticketDetail;
                        this.selected_admin_id = this.$store.getters.getResults.data.ticketDetail.selected_admin_id;

                        Bus.$emit('get_admin_support_count');
                        return this.items;
                    }
                });
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                // this.form.job_id = '';
                // this.form.ticket_subject = '';
                this.form.message = '';
                this.images = [];
                this.form.document = '';
                this.imagesDoc = [];
                this.getTicketListing();
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        uploadImageSuccess(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path
                        fileList[i].url = url;
                    }
                }
            }

            this.imagesDoc = fileList

        },
        beforeRemove(index, done, fileList) {

            fileList.splice(index,1);

            this.imagesDoc = fileList;
        },
        editImage(formData, index, fileList) {
            for (var i = 0; i < fileList.length; i++) {
                var stringLength = fileList[i].path.length - 'data:image/png;base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMB=sizeInBytes/1000000;
                var name = fileList[i].name.split('.');
                if (name[name.length -1] != 'png' && name[name.length -1] != 'jpg' && name[name.length -1] != 'jpeg') {
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else if(sizeInMB > 5){
                    this.error_message = 'Image should be less than 5 MB.'
                    fileList.splice(index,1);
                    this.showAlert();
                }else{
                    if (typeof fileList[i].url == "undefined") {
                        var url = fileList[i].path
                        fileList[i].url = url;
                    }
                }
            }
            this.imagesDoc = fileList
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Tickets');
               this.actionsFreelancer = permission.getPermissionStaff(menu,'Jobseekers');
               this.actionsEmployer = permission.getPermissionStaff(menu,'Companies');
               this.actionsManageDPJobs = permission.getPermissionStaff(menu,'DP Jobs Admin');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showFreelancer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsFreelancer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showEmployer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showManageDPJobs(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        mcTicketCheck(){
            return this.$store.dispatch(POST_API, {
                   data: {
                        ticketSenderId: this.itemTicket.ticket_messages_val[0].ticket_sender_profile.id
                   },
                   api: '/api/admin-medical-ticket-check'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return Promise.resolve(-1);
                    } else {
                        // console.log(this.$store.getters.getResults.data)
                        return Promise.resolve(this.$store.getters.getResults.data);
                    }
                });
        }
    },
     mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
        this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
        this.getTicketListing();
        this.getPenaltyRemarks();
        this.getInChargeAdminList();
        this.permissionStaff();

    },
  }

</script>

