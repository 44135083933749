<template>
   <div>


    <!-- Main Banner -->
    <div class="main-banner custom_main_banner" id="top">
        <div class="container my_custom_container_emp">
            <div class="row">
                <div class="col-lg-12">
                <div class="">
                    <div class="">
                    <div class="item header-text">
                        <h1 class="common_font_family">Manpower on Demand Job Posting Site</h1>
                        <h3 class="common_font_family">Let us handle your staffing needs, so you can focus on your business.</h3>
                        <ul>
                            <li><span><b>Free</b> job posting & Consultation</span></li>
                            <li><span>Access to <b>Singapore #1 growing database</b> of over {{totalFLCount}}+ diverse and talented applicants</span> </li>
                            <li><span><b>Effortlessly achieve staffing</b> success with our user friendly job posting site</span></li>
                            <li><span> Flexible staffing solutions <b>tailored for your business</b> in Singapore</span></li>
                            <li class="scroll-to-section"><div class="enquirebutton"><a href="#contact">Enquire Now</a></div></li>
                        </ul>
                    </div>
                    </div>
                    <div class="col-lg-2 align-self-center">
                    <div class="background-image">
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Why Us?-->
    <div class="reasons section common_padding_top_50" id="reason">
        <div class="container my_custom_container_emp">
            <div class="row">
            <div class="col-xl-12">

                    <!-- <div class="container"> -->
                    <div class="">
                        <div class="section-heading">
                            <h2 class="why_heading common_font_family">Why Us?</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="fact-item">
                                    <div class="icon">
                                        <img
                                        :src="webUrl+'assets/employerImages/Vector 1.png'"
                                        alt="Afforfable Pricing"
                                        />
                                    </div>
                                    <div class="count-title">Affordable Pricing</div>
                                        <p>
                                            We are offering a 7 days free
                                            trial at 0% platform fees.
                                            Thereafter a reasonable
                                            platform fee will be charged
                                            base on the hirer usage.
                                        </p>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="fact-item">
                                    <div class="icon">
                                        <img
                                        :src="webUrl+'assets/employerImages/Vector 2.png'"
                                        alt="Free Up Your HR"
                                        />
                                    </div>
                                    <div class="count-title">Free Up Your HR</div>
                                    <p>
                                        With EL Connect, long gone are
                                        the days where your HR
                                        Personnels will need to conduct
                                        interviews, handle payments
                                        and create time consuming
                                        payroll reports.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="fact-item">
                                    <div class="icon">
                                        <img
                                        :src="webUrl+'assets/employerImages/Vector 3.png'"
                                        alt="Be in Control"
                                        />
                                    </div>
                                    <div class="count-title">Be in Control</div>
                                        <p>
                                            You will have full control of the
                                            entire hiring process, from
                                            selection of applicants to the
                                            rating of workers after a job
                                            completion.
                                        </p>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="fact-item">
                                    <div class="icon">
                                        <img
                                        :src="webUrl+'assets/employerImages/Vector 4.png'"
                                        alt="Timely Updates"
                                        />
                                    </div>
                                    <div class="count-title">Timely Updates</div>
                                    <p class="mobile_margin_bottom_zero">
                                        Hirer will receive timely in-app
                                        notifications, service reports after
                                        a job completion and detailed
                                        billing after every cycle.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- How it works -->
    <div class="about-us section common_padding_top_50 common_padding_bottom_50" id="about" >
        <!-- <div class="container my_custom_container_emp">
            <div class="row">
                <div class="col-xl-12">
                    <div class="column">
                        <div class="column left">
                            <div class="coloumn_lft">
                            <div class="slideshow-container">
                                <h1 class="common_font_family">How It Works</h1>
                                <div id="slider">
                                    <div class="slides">
                                        <div class="slider">
                                            <div class="content">
                                                <div class="content-txt">
                                                    <h3>Post Jobs</h3>
                                                    <h4>Post jobs in under 30 seconds<br>
                                                        Posting is absolutely free!</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="slider">
                                            <div class="content">
                                                <div class="content-txt">
                                                    <h3>Award Applicants</h3>
                                                    <h4>View the profile of the <br>
                                                        applicants and find the right <br>
                                                        fit for your organisation.</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="slider">
                                            <div class="content">
                                                <div class="content-txt">
                                                    <h3>Overview</h3>
                                                    <h4>Access all your job posts<br>
                                                        and service reports with <br>
                                                        a click of a few icons</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="switch">
                                        <ul>
                                        <li ><div class="on" id="first-switch"></div></li>
                                        <li ><div class="" id="second-switch"></div></li>
                                        <li ><div class="" id="third-switch"></div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="enquirebutton">
                                <button class="enquire">Enquire Now</button>
                            </div>
                            </div>
                            <div class="column right coloumn_rgt">
                                <div class="phone-image">
                                    <img :src="webUrl+'assets/employerImages/g1.gif'" class="phone-img">
                                </div>
                            </div>
                            <div id="slider" class="mobile_slider">
                                    <div class="slides">
                                        <div class="slider">
                                            <div class="content">
                                                <div class="content-txt">
                                                    <h3>Post Jobs</h3>
                                                    <h4>Post jobs in under 30 seconds<br>
                                                        Posting is absolutely free!</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="slider">
                                            <div class="content">
                                                <div class="content-txt">
                                                    <h3>Award Applicants</h3>
                                                    <h4>View the profile of the <br>
                                                        applicants and find the right <br>
                                                        fit for your organisation.</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="slider">
                                            <div class="content">
                                                <div class="content-txt">
                                                    <h3>Overview</h3>
                                                    <h4>Access all your job posts<br>
                                                        and service reports with <br>
                                                        a click of a few icons</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="switch">
                                        <ul>
                                        <li><div class="on"></div></li>
                                        <li></li>
                                        <li></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="enquirebutton mobile_enquire_btn_slider">
                                    <button class="enquire">Enquire Now</button>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->
        <div class="container my_custom_container_emp">
            <div class="row">
                <div class="col-xl-12">
                    <div class="slideshow-container">
                        <h1 class="common_font_family">How It Works</h1>
                    </div>
                    <div id="slideshow-mobile" class="mobile_slider_works_new">
                        <div data-delay="8000" class="common_text_slider_works">
                            <div class="content_full_div">
                                <div class="slider_gif">
                                    <div class="phone-image">
                                        <img :src="webUrl+'assets/employerImages/g1.gif'" class="phone-img">
                                    </div>
                                </div>
                                <div class="contet_slider">
                                    <h3>Post Jobs</h3>
                                    <ul class="switch_ul">
                                        <li class="active_li"></li>
                                        <li class="non_active_li"></li>
                                        <li class="non_active_li"></li>
                                    </ul>
                                    <h4>Post jobs in under 30 seconds<br>
                                    Posting is absolutely free!</h4>
                                     <!-- <div class="enquirebutton works_btn">
                                        <button class="enquire">Enquire Now</button>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                        <div data-delay="9000" class="common_text_slider_works">
                            <div class="content_full_div">
                                  <div class="slider_gif">
                                    <div class="phone-image">
                                        <img :src="webUrl+'assets/employerImages/g2.gif'" class="phone-img">
                                    </div>
                                </div>
                                <div class="contet_slider">
                                    <h3>Award Applicants</h3>
                                            <ul class="switch_ul">
                                                <li class="non_active_li"></li>
                                                <li class="active_li"></li>
                                                <li class="non_active_li"></li>
                                            </ul>
                                    <h4>View the profile of the <br>
                                        applicants and find the right <br>
                                        fit for your organisation.</h4>
                                    <!-- <div class="enquirebutton works_btn">
                                        <button class="enquire">Enquire Now</button>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                        <div data-delay="7500" class="common_text_slider_works">
                            <div class="content_full_div">
                                <div class="slider_gif">
                                    <div class="phone-image">
                                        <img :src="webUrl+'assets/employerImages/g3.gif'" class="phone-img">
                                    </div>
                                </div>
                                <div class="contet_slider">
                                    <h3>Overview</h3>
                                        <ul class="switch_ul">
                                            <li class="non_active_li"></li>
                                            <li class="non_active_li"></li>
                                            <li class="active_li"></li>
                                        </ul>
                                    <h4>Access all your job posts<br>
                                    and service reports with <br>
                                    a click of a few icons</h4>
                                    <!-- <div class="enquirebutton works_btn">
                                        <button class="enquire">Enquire Now</button>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                    </div>
                    <div id="slideshow" class="desktop_slider_works_new">
                        <div data-delay="8000" class="common_text_slider_works">
                            <div class="content_full_div">
                                <div class="contet_slider">
                                    <h3>Post Jobs</h3>
                                    <ul class="switch_ul">
                                        <li class="active_li"></li>
                                        <li class="non_active_li"></li>
                                        <li class="non_active_li"></li>
                                    </ul>
                                    <h4>Post jobs in under 30 seconds<br>
                                    Posting is absolutely free!</h4>
                                     <!-- <div class="enquirebutton works_btn">
                                        <button class="enquire">Enquire Now</button>
                                    </div> -->
                                </div>
                                <div class="slider_gif">
                                    <div class="phone-image">
                                        <img :src="webUrl+'assets/employerImages/g1.gif'" class="phone-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-delay="9000" class="common_text_slider_works">
                            <div class="content_full_div">
                                <div class="contet_slider">
                                    <h3>Award Applicants</h3>
                                            <ul class="switch_ul">
                                                <li class="non_active_li"></li>
                                                <li class="active_li"></li>
                                                <li class="non_active_li"></li>
                                            </ul>
                                    <h4>View the profile of the <br>
                                        applicants and find the right <br>
                                        fit for your organisation.</h4>
                                    <!-- <div class="enquirebutton works_btn">
                                        <button class="enquire">Enquire Now</button>
                                    </div> -->
                                </div>
                                <div class="slider_gif">
                                    <div class="phone-image">
                                        <img :src="webUrl+'assets/employerImages/g2.gif'" class="phone-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-delay="7500" class="common_text_slider_works">
                            <div class="content_full_div">
                                <div class="contet_slider">
                                    <h3>Overview</h3>
                                        <ul class="switch_ul">
                                            <li class="non_active_li"></li>
                                            <li class="non_active_li"></li>
                                            <li class="active_li"></li>
                                        </ul>
                                    <h4>Access all your job posts<br>
                                    and service reports with <br>
                                    a click of a few icons</h4>
                                    <!-- <div class="enquirebutton works_btn">
                                        <button class="enquire">Enquire Now</button>
                                    </div> -->
                                </div>
                                <div class="slider_gif">
                                    <div class="phone-image">
                                        <img :src="webUrl+'assets/employerImages/g3.gif'" class="phone-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   <div class="enquirebutton works_btn">
                        <a href="#contact"><button class="enquire">Enquire Now</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Clientele-- &&-- Testimonials -->
    <div class="section-3 common_padding_top_50 common_padding_bottom_50" id="clientele">
        <div class="container my_custom_container_emp">
            <div class="row">
                <div class="clientele">
                    <div class="col-xl-12">
                        <div class="header">
                            <h2 class="common_font_family">Clientele</h2>
                        </div>
                        <logo-slider>
                            <div class="slider">
                                <!-- <img  v-for="(item,val) in items" :key="val" :title="item.name" :src="item.logo" alt="Partner Logo"> -->
                                 <div v-for="(item,val) in items" :key="val" class="slider_logo">
                                    <img   :title="item.name" :src="item.logo" alt="Partner Logo">
                            </div>
                            </div>
                        </logo-slider>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-section gray common_padding_top_50 common_padding_bottom_50 default_slider_hide_home" id="testimonial">
        <div class="container my_custom_container_emp">
            <div class="row">
                <div class="col-xl-12">
                    <div class="section-headline centered margin-top-0 margin-bottom-5">
                        <header class="testimonial_hdr new">
                        <h2 class="common_font_family common_h2">Testimonials</h2>
                        </header>
                    </div>


                            <VueSlickCarousel :arrows="true" :dots="true" ref="carousel" v-if="testimonial.length" :adaptive-height="true" >
                                <div class="testimonial-box" v-for="(test,h) in testimonial">
                                    <div class="testimonial-avatar">
                                        <img :src="test.image" alt="freelancer_avatar">
                                    </div>
                                        <div class="testimonial-author">
                                        <h4>{{test.title}}</h4>
                                        <span>{{test.user_type | capitalize}}</span>
                                        </div>
                                        <div class="testimonial">
                                            {{test.description}}
                                        </div>
                                    </div>
					        </VueSlickCarousel>

                </div>
            </div>
         </div>
    </div>

    <!-- Video -->
    <div class="section-6 common_padding_top_50 common_padding_bottom_50" id="exploration">
        <div class="container my_custom_container_emp">
            <div class="row">
                <div class="col-xl-12">
                    <h2>Exploration</h2>
                    <div class="column row">
                        <div class="column left col-xl-6">
                        <p class="content">Our platform provides access to a diverse pool of talents, offering customised staffing solutions tailored to meet your unique business needs.
                        Trust us to help you build a workforce that drives success. Watch our video to learn more about how we can support your business growth.
                        Just simply post a job with us now and is absolutely free.
                        </p>
                        </div>
                        <div class="column right col-xl-6">
                        <iframe src="https://www.youtube.com/embed/gARuZnwtBW0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contact-us section" id="contact" >
            <div class="container my_custom_container_emp">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-heading">
                            <h1>Take Your Business to the Next Level</h1>
                            <h2>Connect with us now to explore the possibilities and <br>
                                the benefits it can bring to your business.</h2>
                        </div>
                        <div class="">
                            <b-form @submit="formSubmit">

                                <div class="form">
                                    <div class="form_flex">
                                        <img :src="webUrl+'assets/employerImages/name icon.png'" class="  ">
                                        <input type="text" id="name" placeholder="Name" required  v-model='name'/>
                                    </div>
                                    <div class="form_flex">
                                        <img :src="webUrl+'assets/employerImages/company icon.jpg'" class="  ">
                                        <input type="text" id="company_name" placeholder="Company Name" required  v-model='company_name'/>
                                    </div>
                                     <div class="form_flex">
                                        <img :src="webUrl+'assets/employerImages/email icon.png'" class="">
                                        <input type="email" id="email" placeholder="Email" required  v-model='email'/>
                                     </div>
                                      <div class="form_flex">
                                        <img :src="webUrl+'assets/employerImages/phone number icon.png'" class="  ">
                                        <input type="tel" id="phone" placeholder="Mobile Number" required v-model="phone_number" maxlength="8"/>
                                      </div>
                                </div>
                                <!-- <div class="col-lg-12"> -->
                                    <button class="button" type="submit" id="form-submit" href="/">Get In Touch!</button>
                                <!-- </div> -->
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 </div>

</template>

<script>
import { POST_API,GET_API} from "../../../store/actions.type";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { Carousel, Slide } from 'vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel'




  export default {
        metaInfo: {
        title: 'Job Posting Site - EL Connect ',
        meta: [
          { charset: 'utf-8' },
          { equiv: 'content-type', content: 'text/html' },
          { name: 'description', content: 'EL Connect is a job posting site in Singapore where employers can post full-time and part-time job ads. Enjoy our free job posting now!' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { name: 'keywords', content: 'el connect, job platform singapore, full time jobs, permanent part time, flexible job, daily paid jobs, part time daily paid jobs, daily paid jobs singapore, job posting site, part time jobs, daily paid, daily paid job, connect'},
          {property: 'og:title', content: 'EL Connect - Employers Landing Page'},
			    {property: 'og:site_name', content: 'EL Connect'},
			    {property: 'og:type', content: 'website'},
			    {property: 'og:url', content: 'https://elconnect.sg/employers'},
			    {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			    {property: 'og:description', content: 'EL Connect is a job posting site in Singapore where employers can post full-time and part-time job ads for free. Free trial available!'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/employers'}
  		    ]
        },

    data() {
      return {

        items:null,
        testimonial:[],
        webUrl: process.env.VUE_APP_URL,
        error_message:'',
        job_items:[],
        settings: {
				"slidesToShow": 2,
				"slidesToScroll": 2,
				"speed": 800,
				"infinite": false,
				responsive: [
					{
					breakpoint: 768,
					settings: {
						"slidesToShow": 1,
							"slidesToScroll": 1,
							"speed": 800,
							"infinite": false
					}
					}
				]
			},
        name: '',
        email: '',
        phone_number: '',
        company_name:'',
        success_message: '',
        totalFLCount: '24,000'

      }
    },

    filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },

            dateTimeAgo: function(value) {
                if (!value) return ''
                const dateTimeAgo = moment(value).fromNow();
                return dateTimeAgo
            },


			customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },


            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
    },
    // filters: {
    //     trimString: function(value) {
    //         if (!value) return ''
    //         return value.length > 35 ? value.substring(0, 32) + "..." : value;
    //     }
    // },
    methods:{


        getWebsiteData() {
            return this.$store.dispatch(POST_API, {
				data:{
                    keyword: this.filter,
                },
                api: '/api/website-data'
            })
            .then(() => {
				//window.scrollTo(0,0);
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
					//this.role_types = this.$store.getters.getResults.data;
                    this.banners = this.$store.getters.getResults.banners;
                    this.job_categories = this.$store.getters.getResults.job_categories;
                    this.process_descriptions = this.$store.getters.getResults.process_descriptions;
                    this.items = this.$store.getters.getResults.partners;
                    this.promotions = this.$store.getters.getResults.promotions;
                    this.jobs_count = this.$store.getters.getResults.jobs;
                    this.gigs = this.$store.getters.getResults.gigs;
                    this.dp_jobs_count = this.$store.getters.getResults.dp_jobs_count;
					this.ft_jobs_count = this.$store.getters.getResults.ft_jobs_count;
					this.companies_count = this.$store.getters.getResults.companies_count;
                    this.users_count = this.$store.getters.getResults.users_count;

                    this.dailyjob_datas = this.$store.getters.getResults.dailyjob_datas;
                    this.dailyjob_datas_count = this.$store.getters.getResults.dailyjob_datas_count;

                    this.testimonial = this.$store.getters.getResults.testimonial_emp;

					// this.jobs.forEach(element => {
					// 	if(element.tag_names){
					// 		element.tag_names_val = element.tag_names.split(',');
					// 	}
					// });


                }
            });
        },
        openLink(){
            window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
        },
        showAlert() {
            // window.scrollTo(0,0);
            this.dismissCountDown = this.dismissSecs

            this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 3000
            });
        },
        formSubmit(evt)
        {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        name: this.name,
                        email: this.email,
                        company_name:this.company_name,
                        phone_number: "+65 " + this.phone_number,
                    },
                    api: '/api/get-in-touch-employer'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.success_message = this.$store.getters.getResults.message;

                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: this.success_message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.name = "",
                        this.email = "",
                        this.phone_number = "",
                        this.company_name = ""
                    }
                });
        },


    },
    mounted(){


      window.scrollTo(0, 0);
      this.getWebsiteData();
            $("#slideshow > div:gt(0)").hide();

        // Function for changing to the next slide
        let nextSlide = (count, delay) => {
        // Set the delay on the slide
        setTimeout(() => {
            // Hide the current slide
            $('#slideshow > div:nth-of-type(' + count + ')').hide().removeClass('slide_dynamic_class');
            // If we've reached the end of the slides, set count back to 1,
            // otherwise, increment by 1
            (count !== $('#slideshow > div').length) ? count++ : count = 1;
            // Show the next slide
            $('#slideshow > div:nth-of-type(' + count + ')').show().addClass('slide_dynamic_class');
            $(".slide_dynamic_class").css({"position":"relative","opacity": 0, "left":"+=10000"});
            $(".slide_dynamic_class").animate({left:0, opacity:1});
            // Set the delay to the value of the data-delay attribute on the
            // slide elements
            delay = $('#slideshow > div:nth-of-type(' + count + ')').data('delay');
            // Call this function recursively
            nextSlide(count, delay);
        }, delay);
        }

        nextSlide(1, $('#slideshow > div:first').data('delay'));

        //Mobile Slide Start

            $("#slideshow-mobile > div:gt(0)").hide();

                // Function for changing to the next slide
                let nextSlideMobile = (count, delay) => {
                // Set the delay on the slide
                setTimeout(() => {
                    // Hide the current slide
                    $('#slideshow-mobile > div:nth-of-type(' + count + ')').hide().removeClass('slide_dynamic_class');
                    // If we've reached the end of the slides, set count back to 1,
                    // otherwise, increment by 1
                    (count !== $('#slideshow-mobile > div').length) ? count++ : count = 1;
                    // Show the next slide
                    $('#slideshow-mobile > div:nth-of-type(' + count + ')').show().addClass('slide_dynamic_class');
                    $(".slide_dynamic_class").css({"position":"relative","opacity": 0, "left":"+=10000"});
                    $(".slide_dynamic_class").animate({left:0, opacity:1});
                    // Set the delay to the value of the data-delay attribute on the
                    // slide elements
                    delay = $('#slideshow-mobile > div:nth-of-type(' + count + ')').data('delay');
                    // Call this function recursively
                    nextSlideMobile(count, delay);
                }, delay);
                }

                nextSlideMobile(1, $('#slideshow-mobile > div:first').data('delay'));




        //Mobile Slider End
    },

    components: {
                    VueSlickCarousel,
                    Carousel,
                    Slide
                }
 }

</script>

