<template>
    <div class="animated fadeIn">
        <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center">
            <h3 class="mr-auto">
                <b-link class="back-arrow" :to="{name:'ManageCertificate'}"></b-link>Add FL Certificate
            </h3>
        </div> -->
        <!-- <div class="dashboard-headline">
            <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Add {{last_name | capitalize}} {{first_name | capitalize}} Certificate</h1>
        </div> -->
         <div id="breadcrumb-v2">
            <b-breadcrumb>
                <b-breadcrumb-item href="" :to="{name: 'jobseekers-list'}">Jobseekers</b-breadcrumb-item>
                <b-breadcrumb-item active>Add {{last_name | capitalize}} {{first_name | capitalize}} Certificate</b-breadcrumb-item>
            </b-breadcrumb>
        </div>
        <div class="border-radius-4 white-bg p-lg-4 p-3 common_padding_30 common_label">
            <b-form @submit="formSubmit">
                <div class="edit-form-box form-box-field desktop_margin_top_zero">
                    
                    <!-- <b-form-group id="input-group-8" label="Certificates" class="required">
                      <div class="custom-dropdown">
                          <b-form-select v-model="form.certificate" @change="getCertType()">
                              <option value="">Please Select</option>
                              <option :value="cm.id"  v-for="cm in cert_master">{{(cm.title)}}</option>
                          </b-form-select>
                      </div>
                    </b-form-group> -->
                    <b-form-group id="input-group-8" :label="cert_lable" class="required common_margin_bottom_28">
                        <div class="custom-dropdown">
                            <b-form-select v-model="form.certificate_type" class="desktop_margin_bottom_zero">
                                <option value="">Please Select</option>
                                <option :value="ct.id" v-for="ct in cert_type">{{ct.name}}</option>
                            </b-form-select>
                        </div>
                    </b-form-group>
                    <b-form-group id="input-group-7" label="Obtainment Date" class="required common_margin_bottom_28">
                        <datepicker v-model="form.obtainment_date" :format="customFormatterTwo" :disabled-dates="disabledFromDate" class="certificate_date_picker"></datepicker>
                    </b-form-group>
                    <b-form-group id="title-group" label="Certificate No." class="common_margin_bottom_28">
                        <b-form-input id="title" v-model="form.certificate_number" type="text" placeholder="Enter Certificate No.." class="input-text with-border"></b-form-input>
                    </b-form-group>
                    <b-row>
                   <div class="col-xl-3">
                        <div class="submit-field">
                            <h5>Upload Image<span class="required_star"></span></h5>
                            
                            <!-- Attachments -->
                            <div class="single-image-upload my_single_img new_my_single_img_update common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.selectedFoodCert.image ? 'if_image_exist' : 'if_image_not_exist'">
                                    <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onFoodCertChange" ref="image"  v-model="form.food_cert_image"></b-form-file>
                                    <span v-bind:style="form.selectedFoodCert.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                        <!-- <b-img v-if='form.selectedFoodCert' :src="form.selectedFoodCert.image ? form.selectedFoodCert.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;"></b-img> -->
                                        <b-img v-if='form.selectedFoodCert.image' :src="form.selectedFoodCert.image" class="actual_img"></b-img>
                                        <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="dummy_img"></b-img>
                                        <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 300px;"></b-img> -->
                                    </span>
                            </div>
                        <div class="clearfix"></div>
                        
                        <!-- Upload Button -->
                        

                    </div>
                </div>
                </b-row>
                    <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                        <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                        <b-button :to="{name:'jobseeker-certificate-manage', params: {id: this.$route.params.id}}" variant="light">Cancel</b-button>
                    </div>
                </div>
            </b-form>

            <b-modal ref="crop-modal-food" title="Edit Food Certificate" hide-footer :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class">
                    <b-form @submit="onSubmitFoodCert">
                        <template>
                              <div>
                                <div class="content" @change="setImageBannerback">
                                <section class="cropper-area">
                                    <div class="img-cropper">
                                      <vue-cropper ref="cropper" :aspect-ratio="0 / 0" :src="tempLogoBanner" preview=".preview"/>
                                    </div>
                                </section>
                            </div>
                          </div>
                          <div class="pt-2 mt-3 text-md-left  common_crop_reset_btn">
                            <b-button variant="success" class="text-uppercase x-md btn-shadow" @click.prevent="cropFoodCert">Crop</b-button>

                            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button>
                        </div>
                        </template>
                    </b-form>
            </b-modal>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../../store/actions.type";
import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';


export default {
    data() {
        return {
            form: {
                certificate: '',
                certificate_type: '',
                image:'',
                obtainment_date: '',
                certificate_number: '',
                selectedFoodCert:{
                    name:'',
                    image:''
                },
            },
            first_name:'',
            last_name:'',
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            cropImgBanner: '',
            cropImgBannerback: '',

            tempLogoBanner:'',
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
            // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            },
            
            cert_master:[],
            cert_type:[],
            cert_lable:'',
            error_message: '',
            success_message: '',
            selectedImage: '',
            webUrl: process.env.VUE_APP_URL,
            disabledFromDate: {
                from: new Date(Date.now() + 8640000)
            },

        }
    },
    components: {
        Datepicker,
         VueCropper,
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    methods: {
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        showSuccessAlert() {
          this.$swal({
            position: 'center',
            icon: 'success',
            iconColor:'#4c8bf5',
            title: 'Certificate Added Successfully.',
            showConfirmButton: false,
            timer: 1500
            })
            this.$router.push({name:'jobseeker-certificate-manage', params: {id: this.$route.params.id}});
        },
        showsampleimageModel(){
          this.$refs['sample-image-modal'].show();
        },
        cropImage() {
          this.$refs['crop-modal'].hide(); 
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImg = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal'].hide(); 
            this.$refs['crop-modal'].refresh();
        },

        cropImageBanner() {
          this.$refs['crop-modal-banner'].hide(); 
          this.form.nric_front.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },

        

        cropImageBannerback() {
          this.$refs['crop-modal-banner-back'].hide(); 
          this.form.nric_back.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },

        cropFoodCert() {
          this.$refs['crop-modal-food'].hide(); 
          this.form.selectedFoodCert.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },

        cropSeqCert() {
          this.$refs['crop-modal-seq'].hide(); 
          this.form.selectedseqCert.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImageBanner(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBanner = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },

        setImageBannerback(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBannerback = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },

        currently(e){

            if(!this.form.job_current){
                $("#year0").css('pointer-events', 'none');

                $("#year0").val(null);

                $("#month0").css('pointer-events', 'none');
            }else{
                $("#year0").css('pointer-events', 'auto');
                $("#month0").css('pointer-events', 'auto');
            }

        },
        showImgBanner() {
            this.$refs['crop-modal-banner'].show();
        },

        showfoodCert() {
            this.$refs['crop-modal-food'].show();
        },
         showseqCert() {
            this.$refs['crop-modal-seq'].show();
        },

        showImgBannerback() {
            this.$refs['crop-modal-banner-back'].show();
        },
        onSubmitImgBanner(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal-banner'].hide(); 
            this.$refs['crop-modal-banner'].refresh();
        },

        onSubmitImgBannerback(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal-banner-back'].hide(); 
            this.$refs['crop-modal-banner-back'].refresh();
        },

        onSubmitFoodCert(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal-food'].hide(); 
            this.$refs['crop-modal-food'].refresh();
        },

        onSubmitSeqCert(evt) {
            evt.preventDefault();         
            this.$refs['crop-modal-seq'].hide(); 
            this.$refs['crop-modal-seq'].refresh();
        },

        onFoodCertChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.food_cert_image = input.files[0];
                if (this.form.food_cert_image.type != 'image/png' && this.form.food_cert_image.type != 'image/jpeg' && this.form.food_cert_image.type != 'image/jpg') {
                    this.form.selectedFoodCert.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.selectedFoodCert.image='';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.food_cert_image.size > 5242880) {
                    this.form.selectedFoodCert.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Food certificate should be less than 5 MB.';
                    this.form.selectedFoodCert.image='';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.selectedFoodCert.name = this.form.food_cert_image.name;
                        this.showfoodCert();
                        event.target.value = '';

                    }
                }
            }
        },

        
       
       
        customFormatterTwo(date) {
            return moment(date).format('DD-MM-YYYY');
        },

        maxdate() {
            return moment().subtract(16, 'years');;
        },

        getCertificate() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/certificate'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.cerificate = this.$store.getters.getResults.data;

                        if(this.cerificate[1]){

                            this.getSecurityType();
                        }

                         if(this.cerificate[0]){

                            this.getFoodType();
                        }

                        // this.showSuccessPopup();
                    }
                });
        },
       
        onInput(value) {
            this.inputArr = value.map(a => a.id);
        },
        
        getFoodType() {
            return this.$store.dispatch(POST_API, {
                    data: {
                            id: this.cerificate[0].id,
                    },
                    api: '/api/certificatetype'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.foodcerificateType = this.$store.getters.getResults.data;
                        // this.showSuccessPopup();
                    }
                });
        },

        getSecurityType() {
            return this.$store.dispatch(POST_API, {
                    data: {
                            id: this.cerificate[1].id,
                    },
                    api: '/api/certificatetype'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.seqcerificateType = this.$store.getters.getResults.data;
                        // this.showSuccessPopup();
                    }
                });
        },
        
        getUserData() {

            return this.$store.dispatch(POST_API, {
                data: {
                    id: atob(this.$route.params.id),
                },
                api: '/api/user-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){
                        this.first_name = this.$store.getters.getResults.data.first_name;
                        this.last_name = this.$store.getters.getResults.data.last_name;
                    }
                }
            });
        },
        
        customFormatterTwo(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        getCert() {

            this.$store.dispatch(POST_API, {
                api: '/api/certificates-master'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.cert_master = this.$store.getters.getResults.data;
                    return this.cert_master;
                }
            });
        },
        getCertType() {

            this.$store.dispatch(POST_API, {
                data:{
                    id:Vue.prototype.decrypt(this.$route.params.cert_id)
                },
                api: '/api/certificatetype'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.cert_type = this.$store.getters.getResults.data;
                    this.cert_lable = this.$store.getters.getResults.cert_type
                }
            });
        },
        onFileChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
               
                reader.readAsDataURL(input.files[0]);
                this.form.url = input.files[0];
                if(this.form.url.type !='image/png' && this.form.url.type !='image/jpeg' && this.form.url.type !='image/jpg'){
                    this.selectedImage = this.webUrl +'/images/upload-icon.svg'
                    this.error_message = 'Please select only .png, .jpg, or .jpeg image.';
                    this.selectedImage = '';
                    this.showAlert();
                  }
                  else if(this.form.url.size > 5242880 ){
                    
                    this.selectedImage = this.webUrl +'/images/upload-icon.svg'
                    this.error_message = 'Gallery image should be less than 5 MB.';
                    this.selectedImage = '';
                    this.showAlert();
                  }
                  else
                  {
                    reader.onload = (e) => {
                     this.selectedImage = e.target.result;
                    }
                  }
            }
        },
        
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        user_id: atob(this.$route.params.id),
                        cert_id: this.form.certificate_type,
                        obtainment_date: this.form.obtainment_date,
                        certificate_number: this.form.certificate_number,
                        cert: this.form.selectedFoodCert,
                        cert_image: this.form.selectedFoodCert.image,
                        certificate_master_id: atob(this.$route.params.cert_id),

                    },
                    api: "/api/store-user-certificates-admin",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.showSuccessAlert() 
                        // this.$swal({
                        //     position: 'center',
                        //     icon: 'success',
                        //     iconColor:'#4c8bf5',
                        //     title: 'Certificate added successfully.',
                        //     showConfirmButton: false,
                        //     timer: 1500
                        // }).then((result) => {

                        //     this.$router.push({ name: 'manage-certificate' });

                        // })
                    }
                });
        },
        
    },
    mounted(){
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.getUserData();
        this.getCertType();
    }
};

</script>
