<template>
    <!-- <div class="dashboard-content-container common_scroll_content unset_height" data-simplebar> -->
    <div class="dashboard-content-container common_scroll_content unset_height box_shadow_ct">
		<div class="row" >
			<div class="container my_new_container">
			<!-- Dashboard Headline -->
			<div class="dashboard-headline">
				<h1>Bookmarked Jobs</h1>

				<!-- Breadcrumbs -->
				<nav id="breadcrumbs" class="dark nav_on_emp">
					<ul>
						<li><a href="#">Home</a></li>
						<li>Bookmarked Jobs</li>
					</ul>
				</nav>
			</div>
	
			<!-- Row -->
			<!-- <div class="row emplpyees_manage_job_emp_row mobile_list_mrgn_btm"> -->
            <div class="row">
                <div class="container my_new_container"> 
				<!-- Dashboard Box -->
                    <!-- <div class="col-xl-12 box_shadow_padding"> -->
                        <div class="">
                        <div class="dashboard-box margin-top-0 common_bookmark_top_dv" >

                            <!-- Headline -->
                            <div class="headline">
                                <h3 class="common_font_weight_bold"><i class="icon-material-outline-business-center"></i> Bookmarked Jobs</h3>
                            </div>

                            <div class="full-page-content-container job_list_height" data-simplebar>
                                <div class="full-page-content-inner desktop_padding_unset job_list_btm">
                                    <div class="">
                                    <div class="content bookmark_padding_left_right_40" v-if="jobDatas.length > 0">
                                        <div class="listings-container new_liting_containerz grid-layout  new_bookmark_list_dtl" v-for="jobData in jobDatas" :current-page="currentPage" :per-page="perPage">
                                            <!-- Job Listing -->
                                            <a class="new_job_listingz job-listing " style="width: 100%;">
                                            <!-- <a href="#" @click="jobDetail(encrypt(jobData.jobId),$event)" class="new_job_listingz job-listing " style="width: 100%;"> -->

                                                <!-- Job Listing Details -->
                                                <div class="job-listing-details new_job_listing_detailz bookmark_page_list">
                                                    <!-- Logo -->
                                                    <div class="job-listing-company-logo new_job_listing_company_logo single_logo_img">
                                                        <img :src="jobData.thumbnail != null ? jobData.thumbnail: jobData.company_logo != null ? jobData.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo">
                                                        <!-- <img :src="jobData.company_logo != null ? jobData.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt="logo"> -->
                                                    </div>
                                                    <!-- Details -->
                                                    <div class="job_listing_card_inner new_job_listing_card_innerz">
                                                    <div class="job-listing-description">
                                                        <div class="title_and_stars bookmark_flex_big_screen">
                                                            <div class="content_jobz">
                                                                <h4 class="job-listing-company desktop_margin_right_zero new_font_title" v-if="jobData.job_title.length >= 161"><b>{{jobData.job_title.substring(0,161)+'...' | capitalize}}</b></h4>
                                                                <h4 class="job-listing-company desktop_margin_right_zero new_font_title" v-else><b>{{jobData.job_title | capitalize}}</b></h4>
                                                                <!-- <h5>About the Employer</h5> -->
                                                                <!-- <a href="#"> -->
                                                                <i class="icon-material-outline-business common_i_color"></i> {{jobData.company_name | capitalize}}
                                                                <h3 class="job-listing-title new_all_job_title" v-if="jobData.job_description" style="margin-left: 20px;">
                                                                    <p v-if="jobData.job_description.length >= 250" v-html="jobData.job_description.substring(0,250)+'...' "></p>
                                                                    <p v-else v-html="jobData.job_description"></p>
                                                                </h3>
                                                                <div class="">
                                                                    <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobData.jobId),$event)" class="bookmark_profile_btn button ripple-effect"> View Detail </a> -->
                                                                    <a :href="'/v2/jobseeker/ft-jobdetail/'+ encrypt(jobData.jobId)" target="_blank" class="bookmark_profile_btn button ripple-effect"> View Detail </a>
                                                                </div>
                                                            </div>
                                                        <!-- </a> -->
                                                        <!-- <h3 class="job-listing-title" v-if="jobData.job_description">
                                                            <p v-if="jobData.job_description.length >= 110" v-html="jobData.job_description.substring(0,110)+'...' "></p>
                                                            <p v-else v-html="jobData.job_description"></p>
                                                        </h3> -->
                                                        <div class="star_icon">
                                                            <ul class="bookmark_delete_icon">
                                                                <b-link href="" @click="removeBookmark(jobData.jobId,$event)" class="button red ripple-effect ico new_delete_icon" title="Remove" data-tippy-placement="left">
                                                                    <!-- <i class="icon-feather-trash-2"></i> -->
                                                                    <span class = "material-icons custom">delete_outline_outlined</span>
                                                                </b-link>
                                                                <!-- <b-form-checkbox :value="jobData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobData.jobId,$event)" :disabled="true"></b-form-checkbox> -->
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <!-- <h3 class="job-listing-title new_all_job_title" v-if="jobData.job_description">
                                                            <p v-if="jobData.job_description.length >= 110" v-html="jobData.job_description.substring(0,110)+'...' "></p>
                                                            <p v-else v-html="jobData.job_description"></p>
                                                        </h3> -->
                                                    </div>
                                                    <!-- <div class="star_icon">
                                                            <ul>
                                                        <b-link href="" @click="removeBookmark(jobData.jobId,$event)" class="button red ripple-effect ico" title="Remove" data-tippy-placement="left"><i class="icon-feather-trash-2"></i></b-link> -->
                                                        <!-- <b-form-checkbox :value="jobData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobData.jobId,$event)" :disabled="true"></b-form-checkbox> -->
                                                    <!-- </ul>
                                                    </div> -->
                                                    </div>
                                                </div>

                                                <!-- Job Listing Footer -->
                                                <div class="job-listing-footer">

                                                    <!-- <div class="radio-star">
                                                    <b-form-group>
                                                        <b-form-checkbox :value="jobData.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(jobData.jobId)" :disabled="true"></b-form-checkbox>
                                                    </b-form-group>
                                                    </div> -->
                                                    <!-- <span class="bookmark-icon" @click="bookmarkJob(jobData.jobId)"></span> -->
                                                    <ul>
                                                        <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{jobData.job_address == 'Other' ? jobData.other_location : jobData.job_address | capitalize}}</li>
                                                        <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{jobData.job_type | customizeRoleType}}</li>
                                                        <li class="black_job_color" v-if="jobData.min_salary && jobData.max_salary">
                                                            <!-- <i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> -->
                                                            <i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{jobData.min_salary}}-${{jobData.max_salary}}
                                                        </li>
                                                        <li class="black_job_color"><i class="icon-material-outline-access-time common_i_color"></i>{{jobData.posted_date | dateTimeAgo | capitalize}}</li>
                                                    </ul>
                                                </div>
                                            </a>	
                                            <!-- Job Listing -->
                                        </div>
                                    </div>
                                    <div class="content text-center" v-else>
                                        <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="pagination_padding_left_right_40">
                                    <nav class="pagination-box ">
                                        <div class="page-row-box d-inline-block">
                                            <b-form-group id="input-group-4">
                                                <span class="d-inline-block">Rows per page</span>
                                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input='getBookarkedJobs'>
                                                            <option>25</option>
                                                            <option>50</option>
                                                            <option>75</option>
                                                            <option>100</option>
                                                </b-form-select></span>
                                            </b-form-group>
                                        </div>
                                        <div class="total-page-count d-inline-block">
                                            <ul>
                                                <li>
                                                    <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <b-pagination @input='getBookarkedJobs'   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                    </nav>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            </div>

                            
                        </div>
                    </div>
                </div>

			</div>
			<!-- Row / End -->
        </div>
            
		</div>
        
	</div>
    
</template>
<script>
    import { POST_API } from "../../../store/actions.type"
    import moment from 'moment'
    import Bus from "../../../event-bus";


    export default {

         data() {
            return {
				jobDatas:[],
                pager: {},
                pageOfItems: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                form:{
                    rowsPerPage:25,
                },
                webUrl: process.env.VUE_APP_URL,

            }
        },
		filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            
            dateTimeAgo: function(value) {
                var m = moment(value);  // or whatever start date you have
                // var today = moment().startOf('day');
                var today = moment();
                    var days = Math.round(moment.duration(today - m).asDays());
                if(days > 0){
                    return days +' Days Ago'
                }else{
                    return 'today'
                }
                // if (!value) return ''
                // const dateTimeAgo = moment(value).fromNow();
                // return dateTimeAgo
		    },
            
            customFormatterOne(date) {
                return moment(date).utc().format('DD-MM-YYYY');
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },
		
        methods:{
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
			showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

			removeBookmark(job_id,event){
                if (event) {
                event.stopPropagation()
                }
                this.$swal({
                    title: 'Please Confirm',
                    text: 'Are you sure you want to remove this record from bookmark?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                job_id:job_id,
                                user_id:this.user_id
                        },
                        api: '/api/bookmark-job'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            // this.showAlert();
                            return [];
                        } else {
                            this.bookmarkVal = this.$store.getters.getResults.bookmarkStatus
                            var message = this.$store.getters.getResults.message
                            if (this.bookmarkVal == 'yes') {
                                this.bookmarkjobs = true;
                            } else {
                                this.bookmarkjobs = false;
                            }
                            this.showSuccessAlert(message);
                            this.getBookarkedJobs()
                        }
                    });
                    }
                })
                .catch(err => {
                })
            },

            jobDetail(id,event){
                if (event) {
                event.stopPropagation()
                }
                // window.location.replace('/v2/jobseeker/ft-jobdetail/'+id);
                window.open('/v2/jobseeker/ft-jobdetail/'+ id, '_blank');


            },

            getBookarkedJobs() {
                    return this.$store.dispatch(POST_API, {
                        data:{
							user_id:this.user_id,
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                    },
                        api: '/api/jobseeker-bookmark-job-list'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            // this.showAlert();
                            return [];
                        } else {
							this.jobDatas = this.$store.getters.getResults.pageOfItems;
							this.totalRows = this.$store.getters.getResults.pager.totalItems;
							this.perPage = this.$store.getters.getResults.pager.pageSize;
							this.from = this.$store.getters.getResults.pager.from;
							this.to = this.$store.getters.getResults.pager.to;
                    		return this.jobDatas;
                        }
                    });
            },
        
        },
         mounted(){
            //document.title = "EL Connect - Jobseeker - Bookmarked Jobs";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            Bus.$emit('active_users');
            this.getBookarkedJobs();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        }
    }
</script>
