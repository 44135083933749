<template>
    <div>

         <!-- <div class="container full_container_dsk new_full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
        <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline margin_bottom_40 mobile_margin_bottom_20"> -->
         <div class="dashboard-headline">
            <h1>Employer Service Reports</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark desktop_margin_top_zero">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Employer Service Reports</li>
                </ul>
            </nav>
        </div>
        <!-- <div class="col-xl-12"> -->
            <!-- <div class="row desktop_search_top_div">  -->
            <div class="row"> 
                <div class="col-xl-4 col-md-4 mobile_margin_bottom_18 mobile_tab_max_width_flex new_search_div_daily_job submit-field">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <b-input-group-prepend>
                        <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                        <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                    </b-input-group-prepend>

                
                    <!-- </b-input-group> -->
                    </div>
                </div> 
                <div class="col-xl-5 col-md-5 mobile_margin_bottom_18 mobile_tab_max_width_flex new_search_div_daily_job submit-field">
                    <div class="tab-head-inline-form d-lg-flex d-sm-flex align-items-left desktop_display_block">
                        <div class="select-service-box date-picker-field clearfix common_date_range_picker_new">
                            <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" , :ranges='range' @update="dateFilterFunction" class="add_calender_icon line_height_22">
                                    <div slot="input" slot-scope="picker">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </div>
                                </date-range-picker>
                                
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="double_btn service_report_dual_btn submit-field">
                        <b-link class="btn green-border-btn ml-2 text-white desktop_margin_top_zero desktop_margin_left_zero" :class="this.totalRows == 0 ? 'disabled' : ''"  @click="exportData()" v-if="showPermissionEM('export service report data')">Export Service Report Data</b-link>
                        <b-link class="btn green-border-btn ml-2 text-white desktop_margin_top_zero desktop_margin_right_zero" :class="this.totalRows == 0 ? 'disabled' : ''" @click="zipSR()" v-if="showPermissionEM('zip service reports')">Zip Service Reports</b-link>
                    </div>
                </div>
            </div>
        <!-- </div> -->
                 <!-- <button class="btn green-border-btn" @click="zipSR()" style="margin-left: 5px; float:right;background-color:#007bff;color:white">Zip Service Reports</button>
                 <button class="btn green-border-btn" @click="exportData()" style="margin-left: 5px; float:right;background-color:#007bff;color:white">Export Service Report Data</button> -->
                       
                    
                <!-- <b-link href="#" class="btn grey-border-btn" @click="reset">Reset</b-link> -->
    </div>
</div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk margin_top_25_mobile new_full_container_dsk">
           <div class="row"> -->
        <div class="row">
            <div class="container my_new_container show_filter_arrow">
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getServiceReport" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class=" six_grid_tbl common_tbl_width"> -->
                <b-table ref="datatable" show-empty striped hover responsive :items="getServiceReport" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="memberhip_six_grid_table mobile_min_width_1000">
                    <template #cell(title)="items">
                        {{items.item.title | capitalize}}
                    </template>
                    <template #cell(job_unique_id)="items">
                        {{items.item.job_unique_id}}
                    </template>
                    <template #cell(name)="items">
                        {{items.item.name | capitalize}}
                    </template>
                    <template #cell(employer_name)="items">
                       {{items.item.last_name | capitalize}} {{items.item.first_name | capitalize}}
                    </template>
                     <template #cell(start_date)="items">
                        {{items.item.start_date | customFormatterOne}}
                    </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item @click="downloadPdf(items.item.pdf_url)" v-if="showPermissionEM('view report')">View Report</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getServiceReport'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API,GET_API } from '../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Bus from "../../event-bus";
import permission from "../../../../server/permission.js";



export default {
    components: {
        DateRangePicker
    },

    data() {
        return {
            fields: [
                { key: 'title', label: 'Job Title', sortable: true },
                { key: 'job_unique_id', label: 'Job ID', sortable: true },
                { key: 'name', label: 'Company Name', sortable: true },
                { key: 'employer_name', label: 'Employer Name', sortable: true },
                { key: 'start_date', label: 'Service On', sortable: true },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            searchKeyword:'',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: null,
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            webServerUrl: process.env.VUE_APP_SERVER_URL,
            base_url: process.env.VUE_APP_URL_V1,
            form:{
                rowsPerPage:25,
            },
            range: false,
            date: moment().format('YYYY-MM-DD'),
            auto: true,
            center: 'center',
            
            minDate: null,

            dateRange: {
                startDate: moment().startOf('month').format('YYYY-MM-DD'),
                endDate: moment().endOf('month').format('YYYY-MM-DD')
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1 //ISO first day of week - see moment documenations for details
            },
            permissionActions:''


        }
    },
    
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).format('DD MMM YYYY');
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        date_formate: function(date) {
          if(date != '')
            //return moment(date).format('DD-MM-YYYY hh:mm A')
            return moment(date).format('DD-MM-YYYY')
          else
            return '';
        },
        date: function(value) {
            if (value == null) {
                return '';
            } else {
                return moment(value).format('DD MMM YYYY')
            }
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                // this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        reset() {
            this.filter = '';
            this.dateRange = {
                endDate: null,
                startDate: null
            }
            this.$refs.picker.start = null;
            this.$refs.picker.end = null;
            this.getServiceReport().then(() => {
                this.$refs.datatable.refresh();
            });
        },
        dateFilterFunction(status)
        {
            this.getServiceReport().then(() => {
                this.$refs.datatable.refresh();
            });
        },
        downloadPdf(url){
          window.open(url);
        },

        zipSR() {
            return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.filter,
                     rowsPerPage:this.form.rowsPerPage,
                     sortBy:this.sortBy,
                     sortDirection:this.sortDirection,
                     sortDesc:this.sortDesc,
                     startDate: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                     endDate: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                     company_id: this.company_id,
                     user_id: this.user_id

                   },
                   api: '/api/service-report-zip'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        var data = this.$store.getters.getResults.data;
                        
                        this.downloadFile(data.name, data.url);
                        
                        // Swal.fire({
                        //   position: 'top-center',
                        //   type: 'success',
                        //   text: this.success_message,
                        //   showConfirmButton: false,
                        //   timer: 3000
                        // })
                    }
                });
        },

        downloadFile(name, path){
            var file_path = path;

            if (path != '') {
              var a = document.createElement('A');
              a.href = file_path;
              a.download = name;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
            /*var a = document.createElement('A');
            a.href = file_path;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);*/
        },
       exportData(){
          var base_url = this.base_url;
          var export_url = '/api/export-service-report/'+this.filter+'/'+(this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null')+'/'+(this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null')+'/'+this.company_id;
          window.open(base_url+export_url,'_blank')
        },
        getServiceReport() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    id:this.user_id,
                    company_id:this.company_id,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'jobs.start_date',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                    startDate: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    endDate: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                },
                api: '/api/emp-service-report'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
        permissionEM(){
            if(this.$store.getters.getCurrentUserPermission){
                 if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.is_company_head == 'no' && (this.$store.getters.currentUser.user_type_id == 3 || this.$store.getters.currentUser.user_type_id == 4)) {
                    var menu = this.$store.getters.getCurrentUserPermission.data;
                    this.permissionActions = permission.getPermission(menu,'Service Reports');
                }
            }
        },
        showPermissionEM(action){
            if (this.$store.getters.currentUser.is_company_head == 'no' && (this.$store.getters.currentUser.user_type_id == 3 || this.$store.getters.currentUser.user_type_id == 4)) {
                return this.permissionActions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },

       
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Employer - Transactions";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
            this.permissionEM();
            Bus.$emit('active_users');
            

    }
}
</script>
