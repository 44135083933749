<template></template>

<script>
import { POST_API } from "../store/actions.type";
import VueCropper from "vue-cropperjs";
import CKEditor from "ckeditor4-vue";
import VueRouter from "vue-router";

var router = new VueRouter({
  mode: "history",
  routes: [],
});

export default {
  methods: {
    getJobDetail() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/dailyJobDataById",
          data: {
            id: atob(this.$route.query.jobId),
          },
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.$route.query.company_name =
              this.$store.getters.getResults.data[0].company_name.replaceAll(" ", "-");
            this.$route.query.job_alias = this.$store.getters.getResults.data[0].alias;
            console.log(this.$store.getters.getResults.data);

            window.location.replace(
              "/singapore-jobs-daily-paid/" +
                this.$route.query.company_name +
                "/" +
                this.$route.query.job_alias +
                "/" +
                this.$route.query.jobId
            );
          }
        });
    },
  },
  mounted() {
    this.user_type =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
    if (this.user_type == 5) {
      window.location.replace("/v2/jobseeker/dp-jobdetail/" + this.$route.query.jobId);
    } else if (this.user_type == 3) {
      window.location.replace("/dp-jobs-detail/" + this.$route.query.jobId);
    } else {
      this.getJobDetail();
    }
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>
