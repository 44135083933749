<template>
	<div >
         <loading :active.sync="isLoading"
            :is-full-page="fullPage" loader="dots"
            :background-color="backgroundColor" :color="color"
            :height="height" :width="width" :opacity="opacity">

            </loading>
        <div>
            <!-- Header Section HTML -->
            <JobseekerHeader />
        </div>

        <!-- Banner Section HTML -->
        <router-view></router-view>

        <div >
            <!-- Footer Section HTML -->
            <JobseekerFooter  />
        </div>
    </div>
</template>
<script>
import JobseekerHeader from './JobseekerHeader.vue';
import JobseekerFooter from './JobseekerFooter.vue';
import Bus from "../../../event-bus";


// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'JobseekerNavbarContainer2',
    data() {
        return {
            isLoading: true,
            color:'#FFD700',
            backgroundColor: '#808080',
            width: 140,
  			height: 100,
            fullPage: true,
            opacity:0.6,

        }
    },
    components: {
      JobseekerHeader,
      JobseekerFooter ,
      Loading
    },
    mounted(){
        $("#jobseeker-btn").on('click', function(){
            window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
        });
          Bus.$on('showLoader', () => {
          this.isLoading = true;

        })

        Bus.$on('hideLoader', () => {
          this.isLoading = false;

        })
    }
}
</script>
<style lang="scss">

</style>
