<template>
    <div>

         <!-- <div class="container full_container_dsk new_full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
        <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline margin_bottom_40 mobile_margin_bottom_20"> -->
         <div class="dashboard-headline">
            <h1>Payout Request</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark desktop_margin_top_zero">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Payout Request</li>
                </ul>
            </nav>
        </div>
        <!-- <div class="col-xl-12"> -->
            <!-- <div class="row desktop_search_top_div">  -->
            <div class="row">
                 <div class="transaction_1250_screen col-xl-2 mobile_tab_max_width_flex  submit-field  auto_pay_col_payout">
                    <b-form-checkbox v-model="auto_pay_enabled" size="lg" name="check-button" switch @change="change_auto_pay($event)" class="payout_auto_pay">
                        Auto Pay
                    </b-form-checkbox>
                 </div>
                <div class="transaction_1250_screen col-xl-4  mobile_margin_bottom_18 mobile_tab_max_width_flex new_search_div_daily_job submit-field search_payout_request_col">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <b-input-group-prepend>
                        <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                        <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                    </b-input-group-prepend>


                    <!-- </b-input-group> -->
                    </div>
                </div>
                <!-- <div class="col-md-4 col-sm-12 mobile_max_widht_and_flex">
                    <div class="search-box mb-3 max_width_desktop left_search_btn_desktop mobile_search_box">
                    <b-form-checkbox v-model="auto_pay_enabled" size="lg" name="check-button" switch @change="change_auto_pay($event)">
                        Auto Pay
                    </b-form-checkbox>
                    </div>
                </div> -->
                <template>
                    <div class="col-xl-4 col-md-4  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                        <multiselect
                            v-model="status"
                            :options="statuses"
                            placeholder="Payout Status"
                            track-by="value"
                            label="label"
                            class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                            @input="changeServices()"
                        >
                        </multiselect>
                    </b-form-group>
                    </div>
                </template>
                <template>
                    <div class="col-xl-4 col-md-4  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                    <multiselect
                        v-model="payment_type"
                        :options="paymentModes"
                        :close-on-select="true"
                        placeholder="Payment Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeServices()"
                    >
                    </multiselect>
                    </b-form-group>
                    </div>
                </template>
                <div class="payout_calender_admin transaction_1250_screen col-xl-4  mobile_margin_bottom_18 mobile_tab_max_width_flex new_search_div_daily_job submit-field">
                    <div class="tab-head-inline-form d-lg-flex d-sm-flex align-items-left desktop_display_block">
                        <div class="select-service-box date-picker-field clearfix common_date_range_picker_new">
                            <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" , :ranges='range' @update="dateFilterFunction" class="add_calender_icon line_height_22">
                                    <div slot="input" slot-scope="picker">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </div>
                                </date-range-picker>

                            </b-form-group>
                        </div>
                    </div>

                </div>
                <div class="new_mobile_margin_bottom_zero double_btn service_report_dual_btn transaction_1250_screen col-xl-8  mobile_tab_max_width_flex search_common_margin_bottom_28 clear_with_payout">
                        <!-- <div class="sidebar-widget  mobile_space_between penalty_btn desktop_margin_bottom_zero"> -->
                            <!-- <b-button @click="changeServices()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button> -->
                            <b-button @click="clearFilter()" class=" mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0 mr-0" style="background-color: red; margin-top: 20px;"><span class="mobile_reset">Reset filters</span></b-button>
                            <b-button type="button" v-if="hold_count > 0 && show('update all hold to pending')"  @click="holdPayoutReq('pending')" variant="info" class="mobile_margin_bottom_18 common_payout_btns_admin button ripple-effect  update_hold_btn x-md btn-shadow common_hover_effect_white_bg desktop_margin_left_zero">Update All Hold To Pending</b-button>
                            <b-button class="mobile_margin_bottom_18 common_payout_btns_admin button ripple-effect  desktop_margin_left_zero common_hover_effect_white_bg payout_mobile_btn"  @click="addRequest()" v-if="show('add payout request')">Add Payout Request</b-button>
                            <b-link  v-if="show('export-pdf')" class="mobile_margin_bottom_18 common_payout_btns_admin btn green-border-btn ml-2 text-white desktop_margin_top_zero desktop_margin_left_zero common_line_height_unset" :class="this.totalRows == 0 ? 'disabled' : ''"  @click="exportPdf()">Export PDF</b-link>
                        <b-link  v-if="show('export-excel')" class="mobile_margin_bottom_18  common_payout_btns_admin btn green-border-btn ml-2 text-white desktop_margin_top_zero desktop_margin_right_zero desktop_margin_left_zero common_line_height_unset" :class="this.totalRows == 0 ? 'disabled' : ''"  @click="exportData()">Export Excel</b-link>
                        <!-- </div> -->
                    </div>
            </div>
        <!-- </div> -->
                 <!-- <button class="btn green-border-btn" @click="zipSR()" style="margin-left: 5px; float:right;background-color:#007bff;color:white">Zip Service Reports</button>
                 <button class="btn green-border-btn" @click="exportData()" style="margin-left: 5px; float:right;background-color:#007bff;color:white">Export Service Report Data</button> -->


                <!-- <b-link href="#" class="btn grey-border-btn" @click="reset">Reset</b-link> -->
    </div>
</div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk margin_top_25_mobile new_full_container_dsk">
           <div class="row"> -->
        <div class="row">
            <div class="container my_new_container show_filter_arrow">
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getPayoutList" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class=" six_grid_tbl common_tbl_width"> -->

                <b-table ref="datatable" show-empty striped hover responsive :items="getPayoutList" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="common_small_font_table mobile_min_width_1500 new_layout_table_like_v1">
                     <!-- Custom header for the Name column with Expand/Collapse button -->
      <template #head(name)="data">
        
          <div class="d-flex align-items-center">
            {{ data.label }}
            <b-button
    size="sm"
    @click="toggleAllRows"
    class=" expand-button"
    style="background-color: white; border: 1px solid #ccc; " 
  >
    <i class="fas" :class="allExpanded ? 'fa-chevron-up' : 'fa-chevron-down'" style="color: black;"></i>
  </b-button>
          </div>
        
      </template>
                    <template #cell(name)="items">
                    <div class="d-flex align-items-center">
                        <p v-if="items.item.status == 'hold'">
                            <span class="mr-2 badge badge-danger">!</span>
                        </p>
                        <b-link :to="showFreelancer('view profile') ? {name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.user_id)}} : null" target="_blank" style="color: #4c8bf5;">
                            {{items.item.last_name | capitalize}} {{items.item.first_name | capitalize}}
                        </b-link>
                        <b-button 
                            size="sm" 
                            @click="items.toggleDetails" 
                            class="expand-button"
                            :class="{ 'expanded': items.detailsShowing }"
                        >
                        <i class="fas" :class="items.detailsShowing ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                        </b-button>
                    </div>
                    
                </template>
                <!-- <template #cell(nric)="items">
                    {{items.item.nric}}
                </template> -->
                <template #cell(mobile)="items">
                    <b-link :href="'https://wa.me/+65' + items.item.mobile_number" target="_blank">
                    <p> {{items.item.country_code}}-{{items.item.mobile_number}}</p>
                    </b-link>
                </template>
                <template #cell(amount)="items">
                  <p v-if="items.item.status == 'approved'">
                    S$ {{items.item.amount + items.item.payout_request_charges}}
                  </p>
                  <p v-else>
                    S$ {{items.item.amount}}
                  </p>
                </template>
                <template #cell(charges)="items">
                  S$ {{items.item.payout_request_charges}}
                </template>
                <template #cell(amount_without_charges)="items">
                  <p v-if="items.item.status == 'approved'">
                    S$ {{items.item.amount}}
                  </p>
                  <p v-else>
                    S$ {{items.item.amount - items.item.payout_request_charges}}
                  </p>
                </template>

                <template #cell(transaction_type)="items">
                  {{items.item.transaction_type | removeUnderscore}}
                </template>

                <!-- <template #cell(transaction_id)="items">
                  {{items.item.transaction_id}}
                  <b-button size="sm" @click="items.toggleDetails">
                    {{ items.detailsShowing ? "Hide" : "Show" }}
                  </b-button>
                </template> -->
                <template #row-details="row">
  <div class="d-flex w-100">
    <b-card class="expanded-card flex-grow-1">
      <ul class="list-unstyled mb-0">
        <li><strong>Transaction ID:</strong> {{ row.item.transaction_id || "-" }}</li>
        <li><strong>Transaction Number:</strong> {{ row.item.transaction_number || "-" }}</li>
        <li><strong>NRIC:</strong> {{ row.item.nric || "-" }}</li>
      </ul>
    </b-card>
  </div>
</template>


                <!-- <template #cell(transaction_number)="items">
                  {{items.item.transaction_number}}
                </template> -->

                <template #cell(created_at)="items">
                  {{items.item.created_at | moment}}
                </template>

                <template #cell(approved_on)="items">
                  {{items.item.approved_on | moment}}
                </template>
                <template #cell(approved_by)="items">
                    <p v-if="items.item.approved_by != null">
                    {{items.item.approved_by_last_name | capitalize}} {{items.item.approved_by_first_name | capitalize}}
                    </p>
                    <p>
                        {{'-'}}
                    </p>
                </template>
                <template #cell(status)="items">
                  <p>
                    {{items.item.status | capitalize}}
                  </p>
                </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset" v-if="items.item.status == 'pending' || items.item.status == 'hold'">
                        <!-- <div class="toggle-action toggle_margin_unset" v-if="items.item.status == 'pending' || items.item.status == 'hold'"> -->

                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                             <b-dropdown class="mx-1" left text="Action" boundary="window">
                                <b-dropdown-item  @click="getPayoutData(items.item.id)" v-if="show('pay')">Pay
                                </b-dropdown-item>
                                <!-- <b-dropdown-item :to="{name: 'payout-view', params: {id: encrypt(items.item.id) }}" >View Detail
                                </b-dropdown-item> -->
                                <b-dropdown-item v-if="items.item.status != 'hold' && show('hold request')" @click="payoutModel(items.item.id,'','hold')" >Hold Request
                                </b-dropdown-item>
                                <b-dropdown-item v-if="(items.item.status == 'hold' || items.item.remarks) && show('update hold remarks')" @click="payoutModel(items.item.id,items.item.remarks,'update_remarks')" >Update Hold Remarks
                                </b-dropdown-item>
                                <b-dropdown-item @click="deletePayoutReq(items.item.id)" v-if="show('delete request')">Delete Request
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getPayoutList'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
        <b-modal ref="request-modal" title="Add Payout Request" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
            <div class="edit-form-box model_margin_top_zero model_legend">

                <div class="profile-repeate form-box-field">
                    <b-row>

                        <b-col lg="12" md="12" >
                            <b-form-group id="input-group-8" label="Select Option " class="category-radio required employer_multiple_radio">
                                <b-form-radio v-model="user_type" name="user_type" value="full_time" @change="getMwList('full_time')">Fulltime FL</b-form-radio>
                                <b-form-radio v-model="user_type" name="user_type" value="normal" @change="getMwList('normal')">Normal FL</b-form-radio>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12">
                            <b-form-group id="input-group-4" label="Select Freelancer" class="required">
                                <span class="custom-dropdown">
                                    <multiselect v-model="selected_mw" id="ajax" label="full_name" track-by="id" placeholder="Type to search freelancer" open-direction="bottom" :options="mwList" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" @input="onFlInput(selected_mw)" class="job_list_multi_select model_multi_select">
                                        <span slot="noResult">No Freelancer Found.</span>
                                    </multiselect>
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="6">
                            <b-form-group id="input-group-5" label="Wallet Amount" class="required">
                                <b-form-input id="input-1" type="text" placeholder="" v-model="wallet_amount" disabled class="with-border"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="6">
                            <b-form-group id="input-group-5" label="Requested Amount" class="required">
                                <b-form-input id="input-1" type="text" placeholder="" v-model="requested_amount" class="with-border"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
                    <b-button type="button" variant="success" class="text-uppercase model_common_bt_success_color  desktop_margin_left_zero" @click="submitRequest">Submit</b-button>
                </div>
                </div>
            </div>
        </b-modal>
        <b-modal ref="pay-modal" title="Pay" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
        <div class="edit-form-box model_margin_top_zero model_legend">

          <div class="profile-repeate form-box-field" v-if="item != ''">
                <b-row>
                    <b-col lg="12" md="12">
                        <b-form-group id="input-group-9" label="Name" class="required">
                            <b-form-input id="input-9" maxlength="20" v-model="item.user.full_name" type="text" placeholder="" disabled class="with-border"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" md="6">
                        <b-form-group id="input-group-9" label="Amount" class="required">
                            <b-form-input id="amount" maxlength="20" v-model="item.amount" type="text" placeholder="" class="with-border"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6" md="6">
                        <b-form-group id="input-group-9" label="Fees" class="required">
                            <b-form-input id="charges" maxlength="20" v-model="item.payout_request_charges" type="text" placeholder="" @keyup="onPayoutChange()" class="with-border"></b-form-input>
                        </b-form-group>
                    </b-col>
                  <b-col lg="6" md="6" >
                      <b-form-group id="input-group-0" label="Current Wallet">
                          <b-form-input id="input-0" type="text" placeholder="" disabled v-model="item.current_wallet" class="with-border"></b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" >
                      <b-form-group id="input-group-0" label="Recalculated Amount" >
                          <b-form-input id="input-0" type="text" placeholder="" disabled v-model="item.total" class="with-border"></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" md="6">
                        <b-form-group id="input-group-9" label="Payment Mode" class="required">
                            <b-form-input id="input-9" maxlength="20" value="Pay Now" type="text" placeholder="" v-if="item.transaction_type == 'paynow'" disabled class="with-border"></b-form-input>

                            <b-form-input id="input-9" maxlength="20" value="Fast Payment" type="text" placeholder="" v-else disabled class="with-border"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6" md="6">
                        <b-form-group id="input-group-9" label="Status" class="required">
                            <b-form-input id="input-9" maxlength="20" value="Pending" type="text" placeholder="" v-if="item.status == 'pending'" disabled class="with-border"></b-form-input>

                            <b-form-input id="input-9" maxlength="20" value="Approved" type="text" placeholder="" v-else disabled class="with-border"></b-form-input>

                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" md="12" v-if="prod != 'yes'">
                        <b-form-group id="input-group-9" label="Transaction ID" class="required">
                            <b-form-input id="input-9" maxlength="20" v-model="transaction_id" type="text" placeholder="" class="with-border"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- <b-col lg="6" md="6">
                        <b-form-group id="input-group-9" label="OCBC Token" class="required">
                            <b-form-input id="input-9" :value="token" type="text" placeholder="" disabled></b-form-input>
                        </b-form-group>
                    </b-col> -->
                    <!-- <b-col lg="6" md="6" class="form-btn mt-3">
                        <b-button variant="primary" @click="generateToken">Generate Token</b-button>
                    </b-col> -->
                </b-row>
                <div class="desktop_margin_top_zero form-btn common_crop_reset_btn">
                  <b-button type="submit" variant="success" class="desktop_margin_right_zero desktop_margin_left_zero  model_common_bt_success_color min_width_90" @click="pay">Pay</b-button>
                    <b-button variant="light" class="model_common_bt_lite_color" @click="hideModel()">Cancel</b-button>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal ref="remarks-modal" title="Hold Remarks" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
        <b-form method="post">
            <div class="edit-form-box model_margin_top_zero model_legend">
                <div class="profile-repeate form-box-field">
                  <b-row>
                    <b-col lg="12" md="12">
                        <b-form-group id="input-group-3" label="Remarks">
                            <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Remarks" v-model="remarks"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                  </b-row>
                <div class="desktop_margin_top_zero common_crop_reset_btn">
                    <b-button type="button" @click="holdPayoutReq('hold')" variant="success" class="text-uppercase x-md btn-shadow desktop_margin_right_zero desktop_margin_left_zero model_common_bt_success_color">Submit</b-button>
                </div>
                </div>
            </div>
        </b-form>
    </b-modal>
    </div>
</template>

<script>
import { POST_API,GET_API } from '../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Multiselect from 'vue-multiselect'
import Bus from "../../../event-bus";
import permission from "../../../../../server/permission.js";



export default {
    components: {
        DateRangePicker,
        Multiselect
    },

    data() {
        return {
            statuses: [
                    { value: 'all', label: 'Payout Status' },
                    { value: 'pending', label: 'Pending' },
                    { value: 'approved', label: 'Approved' },
                    { value: 'hold', label: 'Hold' },
            ],
                paymentModes: [
                    { value: 'all', label: 'Payment Mode' },
                    { value: 'paynow', label: 'Pay Now' },
                    { value: 'fast_payment', label: 'Fast Payment' },
            ],
            allExpanded: false,
            fields: [
                { key: 'toggleAction', label: 'Actions', thStyle:  {width: '4%'}},

                { key: 'name', label: 'Name', sortable: true , thStyle:  {width: '15%'}},

                //{ key: 'nric', label: 'NRIC', sortable: true , thStyle:  {width: '6%'}},

                { key: 'mobile', label: 'Mobile Number', sortable: true   , thStyle:  {width: '10%'}},
                { key: 'amount', label: 'Amount', sortable: true   , thStyle:  {width: '4%'}},
                { key: 'charges', label: 'Fee', sortable: true   , thStyle:  {width: '4%'}},
                { key: 'amount_without_charges', label: 'Cash-out', sortable: true   , thStyle:  {width: '10%'}},

                { key: 'transaction_type', label: 'Mode', sortable: true   , thStyle:  {width: '10%'}},
                //{ key: 'transaction_id', label: 'Transaction ID', sortable: true   , thStyle:  {width: '0%'}},
                //{ key: 'transaction_number', label: 'Transaction Number', sortable: true   , thStyle:  {width: '0%'}},
                { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '5%'}},
                { key: 'remarks', label: 'Remarks', sortable: true , thStyle:  {width: '9%'}},
                { key: 'approved_on', label: 'Approved On', sortable: true , thStyle:  {width: '8%'}},
                { key: 'approved_by', label: 'Approved By', sortable: true , thStyle:  {width: '8%'}},
                { key: 'created_at', label: 'Applied On', sortable: true   , thStyle:  {width: '13%'}},
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            searchKeyword:null,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: null,
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            webServerUrl: process.env.VUE_APP_SERVER_URL,
            webUrlV1: process.env.VUE_APP_URL_V1,
            form:{
                rowsPerPage:25,
            },
            range: false,
            date: moment().format('YYYY-MM-DD'),
            auto: true,
            center: 'center',
            remarks:'',
            type_with_remark:'',

            minDate: null,
            status: { value: 'all', label: 'Payout Status' },
            payment_type: { value: 'all', label: 'Payment Mode' },
            dateRange: {
                startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1 //ISO first day of week - see moment documenations for details
            },
            permissionActions:'',
            mwList:[],
            user_type: 'normal',
            selected_mw:'',
            wallet_amount:0,
            requested_amount:0,
            request_type:'paynow',
            item:{
              user:{
                full_name:'',
              },
              payout_request_charges:0,
              amount:0
            },
            token:'',
            transaction_id:'',
            prod:'',
            disable:true,
            ini_amount:0,
            deducted_amount:0,
            user_id:'',
            auto_pay_enabled:false,
            type:'hold',
            hold_count:0,
            actions:'',
            actionsFreelancer:''
        }
    },

    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

        filtercapitalize:function(value){
          if (!value) return ''
          value = value.toString()
          value = value.replace('_',' ');
          return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
          if (date == null || date == "") return "-";
            return moment(date).utc().format('DD MMM, YYYY hh:mm A')
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },

        date: function(value) {
            if (value == null) {
                return '';
            } else {
                return moment(value).format('DD MMM YYYY')
            }
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$refs.datatable.refresh();
                // this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        hideModel()
        {
          this.$refs['pay-modal'].hide();
        },
        onPayoutChange(){

          var amt = 0;

          amt = (parseFloat(this.item.payout_request_charges) == 0
           ?  parseFloat(this.ini_amount)
           : (parseFloat(this.ini_amount) - parseFloat(this.item.payout_request_charges))
           );

          amt = isNaN(amt) ? this.ini_amount : parseFloat(amt).toFixed(2);
          // console.log(amt,this.item.payout_request_charges,this.deducted_amount,this.ini_amount,this.item.amount  );

          this.item.amount = amt > this.ini_amount ? this.ini_amount : parseFloat(amt).toFixed(2);

        },
        onFlInput(id){
            this.wallet_amount = 0;
          return this.$store.dispatch(POST_API, {
              data:{
                // user_id : this.selected_mw.length > 0 ? this.selected_mw[0].id : '',
                user_id : this.selected_mw,
              },
              api: '/api/get-wallet-amount-payout',
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
              } else {
                  this.wallet_amount = this.$store.getters.getResults.data.available_cashout;
                  // this.subCatg(this.service_id);
              }
          });
        },
        getMwList(type) {
            this.selected_mw = '';
            this.requested_amount = 0;
            this.wallet_amount = 0,
            this.request_type = 'paynow';
            // console.log(moment().subtract(16, 'years').format('DD-MM-YYYY'));
            return this.$store.dispatch(POST_API, {
                    data:{
                      user_type : type
                    },
                    api: '/api/fl-list-payout',
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.mwList = this.$store.getters.getResults.data;
                        // this.subCatg(this.service_id);
                        return this.mwList;
                    }
                });
        },
        changeServices()
        {
             this.getPayoutList().then(() => {
                this.$refs.datatable.refresh();
             });
        },
        clearFilter() {
            this.filter = null;
            this.payment_type = '';
            this.status = '';
            this.dateRange = {
                startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            };
            // this.getPayoutList().then(() => {
                this.$refs.datatable.refresh();
            // });
        },
        dateFilterFunction(status)
        {
            // this.getPayoutList().then(() => {
                this.$refs.datatable.refresh();
            // });
        },
        downloadPdf(url){
          window.open(url);
        },
        getPayoutData(id) {
          this.id = id;
          this.$refs['pay-modal'].show();
          return this.$store.dispatch(POST_API, {
                  data: {
                      id: this.id,
                  },
                  api: '/api/payout-detail'
              })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {

                    this.item = this.$store.getters.getResults.data;
                    this.prod = this.$store.getters.getResults.prod;
                    this.ini_amount = this.item.amount
                    this.deducted_amount = parseFloat(this.item.amount) - parseFloat(this.item.payout_request_charges)
                    this.item.amount = this.deducted_amount;


                    // console.log(this.item);
                    return this.item;
                }
            });
        },
        deletePayoutReq(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: "Are you sure want to delete this record?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                        id:id
                        },
                    api:"/api/delete-payout-request",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.success_message = this.$store.getters.getResults.message;
                            this.showSuccessAlert(this.success_message);
                            // this.$refs.datatable.refresh();
                            // this.getPayoutList().then(() => {
                            //     this.$refs.datatable.refresh();
                            // });
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        pay(){
          this.$refs['pay-modal'].hide();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        transaction_id: this.transaction_id,
                        token: this.token ,
                        item:this.item,
                        approved_by:this.user_id,
                        payout_request_charges : ((this.item.payout_request_charges == '') ? 0 : parseFloat(this.item.payout_request_charges).toFixed(2) )

                    },
                    api: "/api/payout-payment",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        // this.$router.push({ name: 'Payout' });
                    } else {
                        // Bus.$emit('refresh-support');
                        this.showSuccessAlert('Transaction Successful.');
                        // this.changeServices()

                    }
                });
        },

        payoutModel(id,remarks,type){
          this.id = id;
          this.$refs['remarks-modal'].show();
          this.remarks = remarks;
          this.type = type
          this.type_with_remark = type == 'update_remarks' ? 'update_remarks' : type

        },
        holdPayoutReq(type){

          return this.$store.dispatch(POST_API, {
            data:{
              payout_id: this.id,
              remarks: this.remarks,
              type: type,

            },
            api: '/api/hold-payout-request'
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
            this.success_message = this.$store.getters.getResults.message;
            //   if(this.type == 'hold'){
            //     this.success_message = this.$store.getters.getResults.message;
            //   }else{
            //     this.success_message = 'Remarks updated successfully';
            //   }
               if(this.type_with_remark == 'update_remarks'){
                this.success_message = 'Remarks updated successfully';
              }else{
                this.success_message = this.$store.getters.getResults.message;
              }
              this.showSuccessAlert(this.success_message);
            //   this.getPayoutList().then(() => {
                // this.$refs.datatable.refresh();
            //   });
              this.$refs['remarks-modal'].hide();
              this.id = '';
              this.remarks = '';
              this.type = '';
              this.type_with_remark = '';
            }
          });
        },
        addRequest(){
            this.requested_amount = 0;
            this.wallet_amount = 0;
            this.request_type = 'paynow';
            this.selected_mw = '';
            this.getMwList('normal');
            this.$refs['request-modal'].show();
        },
        exportPdf(){
            var base_url = this.webUrlV1;
            var start_date = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null';
            var end_date = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null';
            var filter = this.filter ? this.filter : "null";

            if(start_date == 'null' || end_date == 'null'){
                this.error_message = 'Please select date range.';
                this.showAlert();
            }else{
                var export_url = '/api/export-payout-pdf/'+this.status.value+'/'+filter+'/'+this.payment_type.value+'/'+start_date+'/'+end_date;
                window.open(base_url+export_url,'_blank')
            }
        },
        exportData(){
            var base_url = this.webUrlV1;
            var filter = this.filter ? this.filter : "null";
            var start_date = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null';
            var end_date = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null';

            if(start_date == 'null' || end_date == 'null'){
                this.error_message = 'Please select date range.';
                this.showAlert();
            }else{
                var export_url = '/api/export-payout/'+this.status.value+'/'+filter+'/'+this.payment_type.value+'/'+start_date+'/'+end_date;

                window.open(base_url+export_url,'_blank')
            }
        },
        submitRequest()
        {
            this.$swal({
                title: 'Please Confirm',
                text: "Are you sure to create this payout request?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                        amount:this.requested_amount,
                        request_type:this.request_type,
                        user_id:this.selected_mw.id,
                        from_admin:'yes'
                        },
                    api:"/api/payout-request-submit",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.success_message = this.$store.getters.getResults.message;
                            this.showSuccessAlert(this.success_message);
                            this.$refs['request-modal'].hide();
                            // this.getPayoutList().then(() => {
                            // this.$refs.datatable.refresh();
                            // });
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        getPayoutList() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter == null ? '' : this.filter,
                    status: this.status ? this.status.value : "all",
                    payment_type: this.payment_type ? this.payment_type.value : "all",
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'payout_requests.id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                    // start_time: '2021-09-05',
                    //  end_time: '2023-02-23',
                    start_time: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    end_time: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                },
                api: '/api/payout-admin-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems.map(item => ({
                ...item,
                _showDetails: false // Set _showDetails to false initially for each item
            }));
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;

                    this.auto_pay_enabled = this.$store.getters.getResults.auto_pay == 'yes' || this.$store.getters.getResults.auto_pay == 'Yes' ? true : false;
                    this.hold_count = this.$store.getters.getResults.hold;
                    // this.hold_count = 1;
                    Bus.$emit('get_pending_hold_payout_count');
                    return this.items;
                }
            });
        },

        change_auto_pay($event) {
            if($event != null){
              this.auto_pay_enabled = $event;
            }else{
                this.auto_pay_enabled = $event;
            }
            if(this.auto_pay_enabled == true){
                var msg = 'Are you sure to Enable Auto Pay?';
                var message = 'Auto Pay Enabled Successfully.';
            }else{
                var msg = 'Are you sure to Disable Auto Pay?';
                var message = 'Auto Pay Disabled Successfully.';
            }

            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
                if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          auto_pay: this.auto_pay_enabled
                        },
                        api:"/api/update-auto-payout-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.showSuccessAlert(message);
                            this.auto_pay_enabled = this.$store.getters.getResults.auto_pay;
                          }
                      });
                    }else{
                        if($event == true){
                         this.auto_pay_enabled = false
                        }else{
                         this.auto_pay_enabled = true
                        }
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Payout');
               this.actionsFreelancer = permission.getPermissionStaff(menu,'Jobseekers');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        showFreelancer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsFreelancer.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        toggleAllRows() {
    this.allExpanded = !this.allExpanded;
    this.items.forEach((item) => {
      item._showDetails = this.allExpanded;
    });
  }

    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
            }, "fast");
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getMwList('normal');
            this.permissionStaff();
    }
}
</script>
<style scoped>
.expandable-table {
  position: relative;
}

.expand-button {
  background: none;
  border: none;
  color: #4c8bf5;
  padding: 0;
  font-size: 1.2em;
}

.expand-button:focus {
  outline: none;
  box-shadow: none;
}

.expanded-card {
  width: 100% !important;
  display: block !important; /* Make sure it takes up the entire row width */
}

.table-responsive td {
  display: table-cell; /* Make sure table cells behave properly */
  width: 100%;
}





</style>