
<template>



    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->



    <div class="row" >
                    <!-- <div class="col-xl-12 col-lg-12 content-right-offset"> -->
        <div class="col-xl-12 col-lg-12">
                <!-- Hedaline -->
                <!-- <div class="dashboard-headline">
                    <h1>Edit Billing Address</h1>
                </div> -->
                <div id="breadcrumb-v2" class="mb-1">
                    <div class="d-flex justify-content-start breadcrumb-wrapper">
                        <b-breadcrumb
                        :items="breadCrumb()"
                        />
                    </div>
                </div>
                <!-- Billing Cycle Radios  -->
                <div class="billing-cycle desktop_display_block">
                    <!-- Radio -->
                        <div class="content">
                            <ul class="fields-ul chek_page second_indiv_step_wrap">
                            <li class="common_input_field desktop_padding_top_zero">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="submit-field error_submit_field">
                                            <h5>Full Name <span class="required_star">*</span></h5>
                                                <b-form-input
                                                type="text"
                                                v-model="name"
                                                id="full_name"
                                                class="input-text with-border required-field"
                                                name="name"
                                                placeholder=""
                                                value=""
                                            />
                                        </div>
                                            <!-- <div class="show_span"> -->
                                                <span class="error_x_white">Please enter Name </span>
                                            <!-- </div> -->
                                    </div>
                                    <div class="col-xl-12">
                                        <div v-for="(app, index) in appendExp" :key="index">
                                            <!-- <div class="row job_rw">
                                                <div class="jb1">
                                                    <div class="submit-field">
                                                        <h5>Email</h5>
                                                        <input type="text" class="with-border"  v-model="app.email" >
                                                    </div>
                                                </div>

                                                <div class="jb5 padding_right_993 mobile_remove_left_pdng col-md-3 max_width_and_flex_769 mobile_max_width_and_flex  eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex mobile_remove_left_pdng big_screen_padding_left_right_zero"  v-if="index != 0">
                                                    <div class="submit-field remove_bt web_bottom_zero new_mobile_margin_bottom_zero">
                                                        <a href="#" class="button margin_top_zero_993 ripple-effect margin-top-30 mobile_anchore_set common_vertical_align_bottom" style="background-color: red;" @click="removeExperience(index)" ><span class="add_plus">x</span>Remove</a>
                                                    </div>
                                                </div>
                                                <div class="jb6  max_width_and_flex_993 col-md-3 eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_remove_left_pdng big_screen_max_width_and_flex big_screen_padding_left_right_zero ">
                                                    <div class="submit-field new_mobile_margin_bottom_zero web_bottom_zero">
                                                        <a href="#" class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero margin-top-30 mobile_anchore_set common_vertical_align_bottom" v-if="index == 0" @click="addExperience"><span class="add_plus">+</span> Add More</a>

                                                    </div>
                                                </div>
                                            </div> -->
                                             <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="submit-field">
                                                        <h5>Email <span class="required_star">*</span></h5>
                                                        <input type="text" class="with-border"  v-model="app.email" >
                                                    </div>
                                                </div>

                                                <div class="jb5 padding_right_993  col-md-3 max_width_and_flex_769 mobile_max_width_and_flex   eleven_zero_one_max_width_and_flex mobile_max_width_and_flex  "  v-if="index != 0">
                                                    <div class="submit-field ">
                                                        <h5 class="common_opacity_zero">Email</h5>
                                                        <a href="javascript:void(0);" class="button margin_top_zero_993 ripple-effect  mobile_anchore_set common_vertical_align_bottom" style="background-color: red;" @click="removeExperience(index)" ><span class="add_plus">x</span>Remove</a>
                                                    </div>
                                                </div>
                                                <div class="jb6  max_width_and_flex_993 col-md-3  eleven_zero_one_max_width_and_flex  big_screen_max_width_and_flex  " v-if="index == 0" @click="addExperience">
                                                    <div class="submit-field  ">
                                                        <h5 class="common_opacity_zero">Email</h5>
                                                        <a href="javascript:void(0);" class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero  mobile_anchore_set common_vertical_align_bottom" ><span class="add_plus">+</span> Add More</a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5>Mobile Number <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="phone"

                                                    id="number"
                                                    class="input-text with-border required-field"
                                                    name="office_address"
                                                    placeholder=""
                                                    value=""
                                                    maxlength="8"
                                                />
                                            </div>
                                                <!-- <div class="show_span"> -->
                                                    <span class="error_x_white">Please enter Mobile number </span>
                                                <!-- </div> -->
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5>Postal Code <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"
                                                    v-model="code"

                                                    id="code"
                                                    class="input-text with-border required-field"
                                                    name="office_address"
                                                    value=""
                                                    maxlength="6"
                                                />
                                            </div>
                                            <!-- <div class="show_span"> -->
                                                <span class="error_x_white">Please enter Postal Code </span>
                                            <!-- </div> -->
                                        </div>

                                        <div class="col-xl-12">
                                            <div class="submit-field error_submit_field">
                                                <h5>Address 1 <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"
                                                v-model="address"

                                                    id="address"
                                                    class="input-text with-border required-field"
                                                    name="office_address"
                                                    value=""
                                                />
                                            </div>
                                            <!-- <div class="show_span"> -->
                                                <span class="error_x_white">Please enter Address </span>
                                            <!-- </div> -->
                                        </div>
                                        <div class="col-xl-12">
                                            <div class="submit-field">
                                                <h5>Address 2</h5>
                                                <b-form-input
                                                    type="text"
                                                v-model="address2"

                                                    id="maxSal"
                                                    class="input-text with-border desktop_margin_top_zero"
                                                    name="office_address"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                                <div class="submit-field error_submit_field">
                                                    <h5>Unit No.</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="unit"

                                                        id="maxSal"
                                                        class="input-text with-border desktop_margin_top_zero"
                                                        name="office_address"
                                                        value=""
                                                    />
                                                </div>
                                                <span class="error_x_white">Please enter unit </span>
                                            </div>
                                        <div class="col-xl-6">
                                            <div class="submit-field error_submit_field">
                                                <h5>City</h5>
                                                <b-form-input
                                                    type="text"
                                                v-model="city"

                                                    id="city"
                                                    class="input-text with-border"
                                                    name="office_address"
                                                    value=""
                                                />
                                            </div>
                                            <!-- <div class="show_span"> -->
                                                <span class="error_x_white">Please enter City </span>
                                            <!-- </div> -->
                                        </div>

                                    <div class="col-xl-6">
                                            <div class="submit-field">
                                                <h5>Country <span class="required_star">*</span></h5>
                                                    <b-form-select v-model="country" id="country">
                                                    <option :value="country.id" v-for="country in countries">{{country.country_name}}</option>
                                                </b-form-select>
                                            </div>

                                            <div class="show_span">
                                                <span class="error_x_white">Please select Country </span>
                                            </div>
                                        </div>


                                </div>

                            </li>
                        </ul>
                        </div>
                    <!-- Radio -->
                </div>
        </div>

        <div class="col-xl-12 post_jb_btn ">
            <a @click="submit" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn" style="background:#4c8bf5 !important">Submit</a>
            <a  @click="$router.go(-1)" class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn" style="background:black !important">Cancel</a>
        </div>
                    <!-- Summary -->
    </div>

    </div>

</template>

<script>
  import { POST_API } from "../../store/actions.type"

  export default {

  data() {
    return {
             formPosition: 0,
             page : 1,
             coins: '',
             coinData:'',
             packageData:'',
             total_amount : 0,
            loading: false,
            add_id: atob(this.$route.params.id),

            cardHolderName:'',
            cardNumber:'',
            expiryMonth:'',
            expiryYear: '',
            cvv:'',
            name:'',
            email:'',
            phone:'',
            blockRemovalExperience:true,
            code:'',
            address:'',
            address2:'',
            unit:'',


            city:'',
            country:'',
            elcoinDatabyid:'',
            settings:'',
            countries:'',
            appendExp:[]



            }},
  methods: {
    breadCrumb(){
            var item = [{
                to:{name:'billingAddress'},
                text: 'Manage Billing Address',
            },{
                to:null,
                text: 'Edit Billing Address'
            }];
            return item;
    },
     nextStep(){
      this.page = this.page+1;
     },

     back(){
      this.page = this.page-1;
     },

      addExperience()
        {
            this.appendExp.push( {
                    email: ""

                }
            );
        },

         removeExperience (lineId) {
          if (this.blockRemovalExperience )
            {
                this.appendExp.splice(lineId, 1);
            }
        },


      getBillingById(){

           this.$store.dispatch(POST_API, {
                    data:{
                        id: this.add_id,
                    },
                    api:'/api/edit-billing-address'
                })
                .then(() => {

                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.billingdata = this.$store.getters.getResults.data;
                        var appendExp = this.appendExp;

                       var emailarr =  this.billingdata.billing_email.split(", ");
                        emailarr.forEach(function(item,index){


                             appendExp.push({email:item});
                        })

                        this.name = this.billingdata.name;
                       // this.email = this.billingdata.billing_email;
                        this.phone = this.billingdata.mobile_number;
                        this.address = this.billingdata.street;
                        this.city = this.billingdata.city;
                        this.unit = this.billingdata.unit;

                        this.country = this.billingdata.country_id;
                        this.address2 = this.billingdata.address_2;
                        this.code = this.billingdata.postal_code;


                    }
                });
     },

      showAlert() {
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },

            submit(){
                var err = false;
                var erremail = false;
                var emailarr = [];
                 if(this.appendExp.length > 0){

                     this.appendExp.forEach(function(item,index){
                        emailarr.push(item.email);
                     })



                }

                this.email = emailarr.toString();
                 $('.required-field').each(function () {

                        if ($(this).val() == '' || $(this).val().trim().length === 0) {
                            err = true;
                            $(this).addClass('error');
                            $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                            $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                        } else {


                            $(this).removeClass('error');
                            $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                            $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                        }

                    });

                      $('#country').each(function () {

                if( !$('#country').val() ){
                    err = true;

                    $(this).addClass('error');
                    $(this).parent().parent().find('.required_content').addClass('show');
                    $(this).parent().parent().find('.error_x_white').addClass('show');
                } else {


                    $(this).removeClass('error');
                    $(this).parent().parent().find('.required_content').removeClass('show');
                    $(this).parent().parent().find('.error_x_white').removeClass('show');
                }

                });

                setTimeout(function ()
             {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    });
            }, 100);
            var emailFormat = "[a-zA-Z0-9._-]+@[0-9a-zA-Z-_.]+\.[a-zA-Z]{2,3}$";


                if(err == false){

                const hasDuplicates = (arr) => arr.length !== new Set(arr).size;



                for (const email of emailarr) {

                 if(email != '' && !email.match(emailFormat)){

                    erremail = true;

                     this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: 'Invalid email',
                        showConfirmButton: false,
                        timer: 1500
                })
                 }

               }

            if(erremail == false){
               if(hasDuplicates(emailarr)){
                    erremail = true;

                     this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: 'Duplicate email value exist',
                        showConfirmButton: false,
                        timer: 1500
                    })

                }
            }
            }


            if(err == false && erremail == false){
                this.$store.dispatch(POST_API, {
                        data:{
                            user_id:this.user_id,
                            email:this.email,
                            fullname:this.name,
                            phone:this.phone,
                            code:this.code,
                            address:this.address,
                            address2:this.address2,
                            city:this.city,
                            unit:this.unit,

                            country:this.country,
                            company_id: this.company_id,
                            id: this.add_id,

                    },
                    api: '/api/update-billing-address'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        // var message = 'Job Bookmarked successfully.'
                        var message = this.$store.getters.getResults.message
                        this.showSuccessAlert(message);
                        this.loading = false;
                        window.location.replace('/v2/employer/billing-address-list');
                    }
                });
            }

            },



      getCountry(){

           this.$store.dispatch(POST_API, {

                    api:'/api/countriesData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.countries = this.$store.getters.getResults.data;

                    }
                });
     },

    },

     mounted(){
         window.scrollTo(0,0);
         $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
            //document.title = "EL Connect - Employer - Add Billing Address";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.email = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
                this.add_id = atob(this.$route.params.id);

            this.getCountry();

            this.getBillingById();
     }

    }
</script>
