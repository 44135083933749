<template>
    <div class="animated fadeIn">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h3><a class="back-arrow" href="javascript:history.back(1);"></a>Edit User</h3>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit User</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                    <div class="d-flex justify-content-start breadcrumb-wrapper">
                        <b-breadcrumb
                        :items="breadCrumb()"
                        />
                    </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 edit_genneral_stng_dsk">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="6" md="6" class="submit-field">
                                    <!-- <b-form-group id="input-group-9" label="Range" class="required"> -->
                                        <h5>Range</h5>
                                        <b-form-input id="input-9" maxlength="4" v-model="form.range_type" type="text" placeholder="" disabled class="with-border mb-0"></b-form-input>
                                    <!-- </b-form-group> -->
                                </b-col>
                                <b-col lg="6" md="6" class="submit-field">
                                     <h5>Minimum Range</h5>
                                    <!-- <b-form-group id="input-group-10" label="Minimum Range" class="required"> -->
                                        <b-form-input id="input-10" maxlength="4" v-model="form.min_range" type="text" placeholder="" class="with-border mb-0"></b-form-input>
                                    <!-- </b-form-group> -->
                                </b-col>
                                 <b-col lg="6" md="6" class="submit-field">
                                    <h5>Maximum Range</h5>
                                    <!-- <b-form-group id="input-group-10" label="Maximum Range" class="required"> -->
                                        <b-form-input id="input-10" maxlength="4" v-model="form.max_range" type="text" placeholder="" class="with-border mb-0"></b-form-input>
                                    <!-- </b-form-group> -->
                                </b-col>
                                <b-col lg="6" md="6" class="submit-field">
                                     <h5>Amount</h5>
                                    <!-- <b-form-group id="input-group-9" label="Amount" class="required"> -->
                                        <b-form-input id="input-9" maxlength="4" v-model="form.amount" type="text" placeholder="" class="with-border mb-0"></b-form-input>
                                    <!-- </b-form-group> -->
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn mt-3">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                            <b-button @click="$router.go(-1)" variant="light" style="background-color:black">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../../store/actions.type";
import VueCropper from 'vue-cropperjs';

export default {

    data() {
        return {
            form: {
                range_type:'',
                min_range: '',
                max_range: '',
                amount: '',

            },
            id: atob(this.$route.params.id),
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            title: 'Edit Monthly Incentive'
        }
    },
    components: {
        VueCropper,
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:   this.title == 'Edit Monthly Incentive' ? {name:'monthly-incentive-management-list'} : {name:'master-incentive-management-list'},
                text: this.title == 'Edit Monthly Incentive' ? 'Monthly Incentive Management' : 'Master Incentive Management',
            },{
                to:null,
                text: this.title
            }];
            return item;
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Record Updated successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                if(this.title == 'Edit Monthly Incentive'){
                    this.$router.push({ name: 'incentive-management-list' });
                }else{
                    this.$router.push({ name: 'master-incentive-management-list' });
                }
            });
        },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                    },
                    api: '/api/incentive-edit-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.form.range_type = this.$store.getters.getResults.data.range_type;
                        this.form.min_range = this.$store.getters.getResults.data.min_range;
                        this.form.max_range = this.$store.getters.getResults.data.max_range;
                        this.form.amount = this.$store.getters.getResults.data.amount;
                        this.title = (this.$store.getters.getResults.data.month != null ? 'Edit Monthly Incentive' : 'Edit Master Incentive');

                    }
                });
        },

        
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                data: {
                    id: this.id,
                    range_type: this.form.range_type,
                    min_range: this.form.min_range,
                    max_range: this.form.max_range, 
                    amount: this.form.amount, 
                    is_master : (this.title == 'Edit Monthly Incentive' ? false : true)
                },
                api: "/api/incentive-update-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.showSuccessAlert();
                }
            });
        },


        
        
    },
    mounted() {
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.getEditData();
    },
};

</script>
