<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Heat Map</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Heat Map</li>
                </ul>
            </nav>
        </div>
            </div>
         </div>
        <!-- <b-tabs class="my_tab_job tab_header_action_th daily_deployment_tabs jobseeker_tab ">
            <b-tab :title="'PAST STATS'" @click="changeTabPast"> -->
               

    <!-- Page Content
    ================================================== -->
     <b-row>
       <div class="container my_new_container">
        <b-row>
          <!-- <b-col lg="12"> -->
            <!-- <div class="tab-box--head mb-3 pl-0 top_custom_head desktop_search_top_div"> -->
              <!-- <div class="tab-head-inline-form d-lg-flex d-sm-flex align-items-center"> -->
                <!-- <div class="row"> -->
                  <div class="mobile_tab_max_width_flex col-xl-3 col-md-3 heatmap_status search_common_margin_bottom_28">
                    <div class="select-service-box clearfix staff_flex font_size_10_big_sc">
                        <!-- <label class="new_style_label mobile_heap_margin_top_zero" for="inlineInput1">Status:</label> -->
                        <div class="heatmap_status_down_arrow">
                          <b-form-select id="selectServices" size="lg" :plain="true" v-model="account_status" @change="filter" class="common_box_shadow_unset mb-0 date_style_size">
                              <option value="pending">Pending</option>
                              <option value="incomplete">Incomplete</option>
                              <option value="completed">Completed</option>
                          </b-form-select>
                        </div>
                    </div>
                  </div>
                <div class="mobile_tab_max_width_flex col-xl-3 col-md-3 heatmap_records search_common_margin_bottom_28">
                  <div class="select-service-box clearfix staff_flex font_size_10_big_sc">
                      <!-- <label class="new_style_label" for="inlineInput1">Records:</label> -->
                      <div class="heatmap_status_down_arrow">
                        <b-form-select id="selectServices" size="lg" :plain="true" v-model="records" @change="filter" class="mb-0 common_box_shadow_unset date_style_size">
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </b-form-select>
                      </div>
                  </div>
                </div>
                <div class="mobile_tab_max_width_flex col-xl-6 col-md-6 mobile_max_widht_and_flex heatmap_user search_common_margin_bottom_28">
                  <div class="service_report_flex"> 
                    <div class="select-service-box clearfix desktop_width_100 staff_flex font_size_10_big_sc heatmap_location">
                        <!-- <label class="new_style_label" for="inlineInput1">User:</label> -->
                        <div class="heatmap_user_status_down_arrow desktop_width_100">
                          <b-form-select id="selectServices" size="lg" :plain="true" v-model="user_type" @change="filter" class="mb-0 common_box_shadow_unset date_style_size">
                              <option value="last_login">Last Login</option>
                              <option value="nearest_job">Nearest Job</option>
                              <option value="last_location">Location Update</option>
                              
                          </b-form-select>
                        </div>
                    </div>
                    <div class="reset_btn_top reset_btn_top_heatmap">
                        <b-button @click="onRefresh()" class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter heatmap_new_black_reset_filter common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0" style="background-color: red; margin-top: 20px; width: 100% !important;"><span class="mobile_reset">Reset filters</span></b-button>
                      <!-- <b-button type="submit" variant="danger" @click="onRefresh">Refresh</b-button> -->
                    </div>
                  </div>
                </div>
            <!-- </div> -->
              <!-- </div> -->
            <!-- </div> -->
          <!-- </b-col> -->
        </b-row>
       </div>
        </b-row>
        <!-- </div> -->
        <b-row>
          <div class="container my_new_container">  
              <b-row>
                  <b-col lg="12">
                    <div class="select-service-box clearfix search_common_margin_bottom_28">
                          <multiselect v-model="ptUserName" id="ajax" :custom-label="firstnameWithLastname" track-by="id" placeholder="Type to search Freelancer name" open-direction="bottom" :options="marker"  :searchable="true" :clear-on-select="true" :close-on-select="true" :multiple="true"
                          :allow-empty="true" @search-change="asyncFind" :loading="isLoading" @input="selectPtUser()" @remove="selectPtUser()" class="job_list_multi_select model_multi_select heatmap_multiselect">
                              <span slot="noResult">No Freelancer Found.</span>
                          </multiselect>
                    </div>
                    </b-col> 
                    <!-- <div class="mt-12"> -->
                    <b-col lg="12">

                    <div class="form-map-box">
                        <!-- <gmap-map
                                ref="googleMap"
                              :center="center"
                              :zoom="zoom"
                              :options="{scrollwheel: true}"
                              style="width:1120px;  height: 500px; overflow: auto"
                            >
                                <gmap-marker
                                  :key="index"
                                  class="markerCricle"
                                  v-for="(m, index) in latlongMarkers"
                                  :position="m.position"
                                  :icon="m.icon"
                                  @click="toggleInfoWindow(m, index)"
                                  
                                ></gmap-marker>
                              <gmap-info-window
                              v-if="infoWinOpen && infoContent && infoWindowPos"
                                  :options="infoOptions"
                                  :position="infoWindowPos"
                                  :opened="infoWinOpen"
                                  @closeclick="infoWinOpen=false"
                                >
                                  <div v-html="infoContent"></div>
                              </gmap-info-window>
                          </gmap-map> -->
        <gmap-map
            ref="googleMap"
            :center="center"
            :zoom="zoom"
            :options="{scrollwheel: true}"
            style="height: 500px; overflow: auto"
            @click="closeInfoBox"
          >
            <gmap-marker
              v-for="(m, index) in latlongMarkers"
              :key="index"
              :position="m.position"
              :icon="m.icon"
              @click="showInfoBox(m, index)"
            ></gmap-marker>
            
            <!-- Custom info box for displaying marker info -->
            <CustomInfoBox
          v-if="infoBoxVisible"
          :visible="infoBoxVisible"
          :content="infoContent"
          :position="infoBoxPosition"
        />
          </gmap-map>

                    </div>
                    <!-- </div> -->
                  </b-col>
                  <!-- <b-col lg="3" class="mobile_margin_35">
                    <div class="border-radius-4 white-bg border-midium-light heatTable">
                        <b-tableref="datatable" striped hover show-empty responsive :items="marker" :fields="fields" @row-clicked="onRowSelected" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :empty-text="noRecord" :empty-filtered-text="noRecord" class="map_filter_heatmap">
                          <template slot="full_name" slot-scope="items">
                                  {{items.item.full_name}}
                          </template>
                        </b-table>
                    </div>
                  </b-col> -->
                  </b-row>
                </div>
        </b-row>
            <!-- </b-tab>
        </b-tabs> -->

        <!-- </div> -->
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
</template>

<script>
  import Vue from 'vue';

import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import permission from "../../../../../server/permission.js";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as VueGoogleMaps from 'vue2-google-maps'
import CustomInfoBox from './CustomInfoBox.vue'; 
  import GmapCluster from "vue2-google-maps/dist/components/cluster";
  Vue.component('GmapCluster', GmapCluster);
  
export default {
    components:{
        VuePerfectScrollbar,
        GmapCluster,
        Multiselect,
        CustomInfoBox
      },
    data() {
      return {
       
            infoWindow: {
            position: {lat: 0, lng: 0},
            open: false,
            template: ''
            },
            form: {
              rowsPerPage:100,
             
            },
            items:null,
            mapName: this.name + "-map",
            center: { lat: 0, lng: 0 },

            // markers: [{'position':{ "lat": 26.60, "lng": 80.921  }},{'position':{ "lat": 26.76, "lng": 80.11  }},{ 'position':{"lat": 26.12, "lng": 80.23  }}],
            // markers: [],
            places: [],
            marker: [],
            jobMarker:[],
            currentPlace: null,

            mapOptions:{
              fullscreenControl:false,
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              disableDefaultUi: false,
            },
            map: null,
            infoContent: '',
            infoWindowPos: {
              lat: 0,
              lng: 0
            },
            infoWinOpen: false,
            currentMidx: null,
            fullscreenControl:false,
            infoOptions: {
              pixelOffset: {
                width: 0,
                height: -35
              }
            },  
            MarkerOptions:{
              zIndex:999999,
              opacity:0.2

            },
            markers: this.marker,
            latlongMarkers:[],
            ptusersListing: [],
            ptUserName:[],
            weburl: process.env.VUE_APP_URL,
            // weburl: process.env.MIX_APP_URL,
            actions:'',
            scollRepltDiv:false,
            ptuser_id:'',
            user_type_id:'',
            account_status:'completed',
            records:'500',
            user_type:'last_location',
            fields: [
              { key: 'full_name', label: 'Mobile Users',sortable: false },
            ],
            zoom:12,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDesc: null,
            sortDirection: 'asc',
            noRecord:'No Records Found',
            isLoading:false,
            logged_in_user:'',
            infoBoxVisible: false, // Controls visibility of the custom info box
      infoContent: {}, // Info box content
      infoBoxPosition: { top: 0, left: 0 },
            
      }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    methods: {

        // scrollToEnd: function() {
        //     this.scollRepltDiv=true;
        //     setTimeout(() => {
        //         var myDiv = document.getElementById('chatbox');
        //         myDiv.scrollTop = myDiv.scrollHeight;
        //     }, 200);
        // },
        firstnameWithLastname ({ last_name, first_name }) {
          return `${last_name} ${first_name}`
        },
       showInfoBox(marker, idx) {
  console.log('Marker clicked', marker, idx); // Debugging: Ensure this is triggered
  this.infoBoxVisible = false;
  $('.vue-map-hidden').removeClass('show_box_info');
  setTimeout(() => {
  // Get the pixel position of the marker
  const map = this.$refs.googleMap.$mapObject;
  const scale = Math.pow(2, map.getZoom());
  const nw = new google.maps.LatLng(
    map.getBounds().getNorthEast().lat(),
    map.getBounds().getSouthWest().lng()
  );
  const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
  const worldCoordinate = map.getProjection().fromLatLngToPoint(marker.position);
  const pixelOffset = new google.maps.Point(
    Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
    Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
  );

  console.log('Pixel offset', pixelOffset); // Debugging

  // Set content to display in the custom info box
  this.infoContent = {
    image: this.getImage(idx),
    link: this.getLink(idx),
    name: this.getName(idx),
    date: this.getDate(idx),
  };

  // Set the position of the custom info box relative to the marker
  this.infoBoxPosition = {
    top: pixelOffset.y + 20,  // Adjust as needed for visibility
    left: pixelOffset.x - 50, // Adjust as needed for visibility
  };

  // Using $nextTick to ensure reactivity kicks in and DOM updates
  this.$nextTick(() => {
  this.infoBoxVisible = true;
   $('.vue-map-hidden').toggleClass('show_box_info');
  const parentElement = this.$refs.googleMap.$el; // Get the map element
  parentElement.classList.remove('vue-map-hidden'); // Remove the class
  console.log('Info box visibility after nextTick:', this.infoBoxVisible); // Debugging
});
}, 100);
},
    closeInfoBox() {
      this.infoBoxVisible = false;
    },
    getImage(idx) {
      console.log(this.marker[idx].profile_image,'this.marker[idx]profile_image')

      return this.marker[idx].profile_image || this.weburl + 'images/user-avatar-small-01.png';
    },
    getLink(idx) {
      return this.user_type == 'nearest_job'
        ? this.weburl + 'v2/admin/database/jobseeker-dp-profile/view/' + this.encrypt(this.marker[idx].id)
        : this.weburl + 'v2/admin/database/jobseeker-dp-profile/view/' + this.encrypt(this.marker[idx].id);
    },
    getName(idx) {
      return this.marker[idx].full_name;
    },
    getDate(idx) {
      console.log(this.marker[idx],'this.marker[idx]date')
      if (this.user_type == 'nearest_job') {
        return moment(
          this.marker[idx].heat_job[0].job_data_val.start_date +
          ' ' +
          this.marker[idx].heat_job[0].job_data_val.start_time
        ).format('DD MMM YYYY, h:mm A');
      } else {
        return moment(this.marker[idx].location_updated_at).format('DD MMM YYYY, h:mm A');
      }
    },
  
        
      //   toggleInfoWindow: function (marker, idx) {
      //   console.log(marker,idx);
      //       // console.log(marker,'d',idx,this.marker);
      //   this.infoWindowPos = marker.position;
      //   this.infoContent = this.getInfoWindowContent(idx,this.marker);
      //   console.log(this.currentMidx,idx,this.infoWinOpen,'idx');
      //   console.log(this.infoContent,'this.infoContent')
      //   // //check if its the same marker that was selected if yes toggle
      //   if (this.currentMidx == idx) {

      //     this.infoWinOpen = !this.infoWinOpen;
      //   }
      //   //if different marker set infowindow to open and reset current marker index
      //   else {
      //     this.infoWinOpen = true;
      //     this.currentMidx = idx;
      //   }
      // },
     
      // getInfoWindowContent: function (idx,detailData) {
      //   console.log('ddf',detailData,idx,this.user_type);
      //    var img = this.weburl+'/images/user-avatar-small-01.png';

      //       if (detailData[idx].profile_image) {
      //           if (this.user_type == 'nearest_job') {
      //             return (
      //             `<div class="">
      //               <div>
      //                 <div>
      //                   <div class="m-2"> 
      //                     <img  style="width: 15px;"src="${detailData[idx].profile_image}" alt="Ital Trulli">
      //                     <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/ptuser-jobs/'+this.encrypt(detailData[idx].id)}" target="_blank">
      //                         <b>${detailData[idx].full_name}</b></a>
                          
      //                         <br>
      //                     ${moment(detailData[idx].heat_job.job_data_val.start_date+' '+detailData[idx].heat_job.job_data_val.start_time).format('DD MMM YYYY, h:mm A')}
      //                     </div>
      //                   </div>
      //                 </div>
                     
      //               </div>
      //             </div>`);
      //           }else{
      //             return (
      //             `<div class="">
      //               <div>
      //                 <div>
      //                   <div class="m-2"> 
      //                     <img  style="width: 15px;"src="${detailData[idx].profile_image}" alt="Ital Trulli">
      //                     <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/mobileWorker/view/'+this.encrypt(detailData[idx].id)}" target="_blank">
      //                         <b>${detailData[idx].full_name}</b></a>
                          
      //                         <br>
      //                     ${moment(detailData[idx].location_updated_at).format('DD MMM YYYY, h:mm A')}
      //                     </div>
      //                   </div>
      //                 </div>
                     
      //               </div>
      //             </div>`);
      //           }
      //       }
      //       else{
      //           if (this.user_type == 'nearest_job') {
      //             return (
      //             `<div class="">
      //               <div>
      //                 <div>
      //                   <div class="m-2"> 
      //                     <img  style="width: 15px;"src=${img} alt="Ital Trulli">
      //                     <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/ptuser-jobs/'+this.encrypt(detailData[idx].id)}" target="_blank">
      //                         <b>${detailData[idx].full_name}</b></a>
                          
      //                         <br>
      //                     ${moment(detailData[idx].heat_job.job_data_val.start_date+' '+detailData[idx].heat_job.job_data_val.start_time).format('DD MMM YYYY, h:mm A')}
      //                     </div>
      //                   </div>
      //                 </div>
                     
      //               </div>
      //             </div>`);
      //           }else{
      //             return (
      //             `<div class="">
      //               <div>
      //                 <div>
      //                   <div class="m-2"> 
      //                     <img  style="width: 15px;"src=${img} alt="Italn Trulli">
      //                     <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/mobileWorker/view/'+this.encrypt(detailData[idx].id)}" target="_blank">
      //                         <b>${detailData[idx].full_name}</b></a>
      //                         <br>
      //                     ${moment(detailData[idx].location_updated_at).format('DD MMM YYYY, h:mm A')}
      //                     </div>
      //                   </div>
      //                 </div>
                     
      //               </div>
      //             </div>`);
      //           }
                
      //       }

        
      // },
      ptUser(value) {
        // alert(value);
            // this.ptuser_id = value.map(a => a.id);
            this.getLatLong(value);
        },
        getLatLong(ptuserId) {

            /*return this.$store.dispatch(POST_API, {
                data:{
                    
                    page: this.currentPage,
                     keyword:ptuserId ? ptuserId : '',
                     page: this.currentPage,
                     rowsPerPage:this.form.rowsPerPage,
                     sortBy:this.sortBy,
                     sortDirection:this.sortDirection,
                     sortDesc:this.sortDesc
                   },
                    api: '/api/get-latlong-ptuser'
                })
               
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.marker = this.$store.getters.getResults.data.latLong;
                        console.log('sdc',this.marker);
                        this.updatelatlongMarker();
                    }
                });*/
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        updatelatlongMarker(){
            var markers = [];
          $.each(this.marker,function(index,val){
              console.log(val,'val',val.profile_image);
              var shape = {
                  coords: [0, 0, 60],
                  type: 'circle'
              };
              var icon = {
                  url: val.profile_image, // url
                  shape:shape,

                    scaledSize: new google.maps.Size(30, 30), // scaled size
                    // shape:{coords:[0,0,60],type:"circle"},
                    // border-radius: '30px',
                  // radius:'30px',
                  origin: new google.maps.Point(0,0), // origin
                  anchor: new google.maps.Point(0, 0), // anchor
                  // class: "labelIconlabelIcon",
                  
              };
              markers.push({
                  'position':
                      { 'lat':Number(val.lat), 
                        'lng':Number(val.long)
                      },
                      icon:icon,

                      shape:shape

              })
          });
          // console.log(markers,'markers');
          this.latlongMarkers = markers;
          this.center = {lat:0, lng:0}
          setTimeout(()=>{
              this.center = this.latlongMarkers[0].position
          }, 500);
        },
        onRefresh(){
          this.ptUserName=[];
          this.account_status = 'completed';
          this.records = '500';
          this.user_type = 'last_location';
          this.zoom=12;
          this.getLatLong('');
          this.getPtuserList('');
        },

        getPtuserList(response) {
            this.infoWinOpen = false;
            var user_ids = [];

            if(this.ptUserName.length > 0){
              this.ptUserName.filter(function(item){
                user_ids.push(item.id);
              });
            }
            console.log(this.ptUserName, 'this.ptUserName')
            return this.$store.dispatch(POST_API, {
                    data: {
                      keyword:response,
                      status : this.account_status,
                      records: this.records,
                      user_ids: user_ids ,
                      user_type: this.user_type,
                      from_v2: 'yes',
                      logged_in_user: this.logged_in_user
                    },
                    api: '/api/get-heatmap-ptuser'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.ptusersListing = this.$store.getters.getResults.data.all_user;
                        this.marker = this.$store.getters.getResults.data.selected
                        this.updatelatlongMarker();
                        return this.ptusersListing;
                    }
                });
        },
        
        selectPtUser(){
          this.getPtuserList();
        },
        filter(){
          // this.ptUserName = [];
          this.getPtuserList();
        },
        asyncFind (query) {
            this.isLoading = true
            this.getPtuserList(query).then(response => {
                this.mwSearch = response
                this.isLoading = false
            })
        },
        
    },
    
    mounted(){

        this.user_type_id = this.$store.getters.currentUser.user_type_id;
        this.logged_in_user = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');

        this.getPtuserList();
        this.getLatLong('');
        
        
    }
  }
</script>

