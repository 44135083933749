<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <div class="dashboard-headline">
          <h1>Broadcast Notification Email</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Broadcast Notification Email</li>
            </ul>
          </nav>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <!-- <b-input-group> -->
              <b-input-group-prepend>
                <b-form-input
                  id="searchBtn"
                  @keyup.enter.native="filter = searchKeyword"
                  v-model="searchKeyword"
                  placeholder="Search by keyword"
                ></b-form-input>
                <b-button class="btn points_events"
                  ><i class="fa fa-search" @click="filter = searchKeyword"></i
                ></b-button>
              </b-input-group-prepend>
              <!-- </b-input-group> -->
            </div>
          </div>
          <div
            class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28"
          >
            <b-link
              :to="{}"
              v-if="show('add')"
              class="btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero desktop_margin_top_zero green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn"
              @click="clickToAdd('add')"
            >
              Add Email
            </b-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <div class="row">
      <div class="container my_new_container show_filter_arrow">
        <b-table
          ref="datatable"
          show-empty
          striped
          hover
          responsive
          :items="getListing"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="admin_gallery_tbl mobile_min_width_1000 common_tbl_width gallery_width"
        >
          <template #cell(email)="items">
            {{ items.item.email | capitalize }}
          </template>
          <template #cell(status)="items">
            {{ items.item.status | capitalize }}
          </template>

          <template #cell(toggleAction)="items">
            <div class="toggle-action toggle_margin_unset">
              <b-dropdown class="mx-1" right text="Actions" boundary="window">
                <b-dropdown-item v-if="show('edit')" v-on:click="updateEmail(items.item.id)"
                  >Edit Email
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="items.item.status == 'active' && show('activate/inactivate')"
                  v-on:click="changeStatus(items.item.id, items.item.status)"
                  >Inactive
                </b-dropdown-item>
                <b-dropdown-item
                  v-else-if="show('activate/inactivate')"
                  v-on:click="changeStatus(items.item.id, items.item.status)"
                  >Active
                </b-dropdown-item>
                <b-dropdown-item v-if="show('delete')" v-on:click="deleteEmail(items.item.id)"
                  >Delete
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block">
                <b-form-select v-model="form.rowsPerPage" @change="getListing">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </div>
    </div>
    <b-modal
      ref="upload-update-modal"
      id="background_custom_class_for_banner"
      :title="modalTitle"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <b-form @submit="formSubmit">
        <div class="edit-form-box model_margin_top_zero">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-9" label="Email" class="required">
                      <b-form-input
                        id="input-9"
                        maxlength="50"
                        v-model="form.email"
                        type="text"
                        placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-9" label="App Password" class="required">
                      <b-form-input
                        id="input-9"
                        maxlength="50"
                        v-model="form.password"
                        type="text"
                        placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <div
                    slot="footer"
                    class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn"
                  >
                    <b-button type="submit" variant="success" class="model_margin_left_zero"
                      >Submit</b-button
                    >
                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      ref="upload-add-modal"
      id="background_custom_class_for_banner"
      :title="modalTitle"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <b-form @submit="formSubmit">
        <div class="edit-form-box model_margin_top_zero">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-9" label="Email" class="required">
                      <b-form-input
                        id="input-9"
                        maxlength="50"
                        v-model="form.email"
                        type="text"
                        placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-9" label="App Password" class="required">
                      <b-form-input
                        id="input-9"
                        maxlength="50"
                        v-model="form.password"
                        type="text"
                        placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <div
                    slot="footer"
                    class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn"
                  >
                    <b-button type="submit" variant="success" class="model_margin_left_zero"
                      >Submit</b-button
                    >
                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      fields: [
        { key: "email", label: "Email", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "toggleAction", label: "Actions", thStyle: { width: "10%" } },
      ],
      form: {
        rowsPerPage: 25,
        email: "",
        password: "",
      },
      pager: {},
      pageOfItems: [],
      selectedImage: "",
      selectedImageName: "",
      items: null,
      totalRows: 0,
      currentPage: 1,
      searchKeyword: "",
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "active",
      sortBy: "",
      sortDesc: true,
      to: "",
      from: "",
      webUrl: process.env.VUE_APP_URL,
      actions: "",
      user_type_id: "",
      user_id: "",
      modalTitle: "Add Email",
      type: "add",
      id: "",
      actions: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  methods: {
    showSuccessAlert(message) {
      this.hideModel();
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.getListing();
        this.$refs.datatable.refresh();
        // this.$router.push({ name: 'emp-broadcast-notification-email-admin' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    clickToAdd(typeVal) {
      this.modalTitle = typeVal == "add" ? "Add Email" : "Update Email";
      this.type = typeVal == "add" ? "Add" : "Update";
      if (typeVal == "add") {
        this.form.email = "";
        this.form.password = "";
        this.$refs["upload-add-modal"].show();
      } else {
        this.$refs["upload-update-modal"].show();
      }
    },
    hideModel() {
      this.$refs["upload-add-modal"].hide();
      this.$refs["upload-update-modal"].hide();
      this.form.email = "";
      this.form.password = "";
    },
    getListing() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "id",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/broadcast-notification-email-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },

    formSubmit(evt) {
      evt.preventDefault();
      if (this.type == "update") {
        var api = "/api/broadcast-notification-email-update";
      } else {
        var api = "/api/broadcast-notification-email-store";
      }

      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.id,
            email: this.form.email ? this.form.email : null,
            password: this.form.password ? this.form.password : null,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.type == "update") {
              var message = "Email Updated successfully";
            } else {
              var message = "Email Added successfully";
            }
            this.showSuccessAlert(message);
          }
        });
    },

    updateEmail(id) {
      this.clickToAdd("update");
      this.type = "update";
      this.id = id;

      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/broadcast-notification-email-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.form.email = this.$store.getters.getResults.data.email;
            this.form.password = this.$store.getters.getResults.data.password;
          }
        });
    },

    changeStatus(id, status) {
      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Email Inactivated successfully.";
        var new_status = "inactive";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Email Activated successfully.";
        var new_status = "active";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: new_status,
                },
                api: "/api/broadcast-notification-email-status",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },

    deleteEmail(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/broadcast-notification-email-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Email Deleted successfully.";
                  this.$refs.datatable.refresh();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Broadcast Notification Email");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Admin - Website Contents - Gallery";
    this.user_type_id = this.$store.getters.currentUser.user_type_id;
    this.user_id = this.$store.getters.currentUser.id;
    this.permissionStaff();
  },
};
</script>
