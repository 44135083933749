<template>
  <div class="animated fadeIn">
    <div class="create-new-job-section email-template-section">
      <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Postal District</h1>
            </div> -->
      <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Postal District</h1>
            </div> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <div
        class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading"
      >
        <b-form @submit="formSubmit">
          <div class="edit-form-box desktop_margin_top_zero">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group
                    id="postal-district-name-group"
                    label="Postal District Name"
                    class="required"
                  >
                    <b-form-input
                      id="postal_district"
                      v-model="form.postal_district"
                      type="number"
                      min="1"
                      max="100"
                      placeholder="Enter Postal District.."
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group
                    id="postal-sector-name-group"
                    label="Postal Sector Name"
                    class="required"
                  >
                    <b-form-input
                      id="postal_sector"
                      v-model="form.postal_sector"
                      type="text"
                      placeholder="Enter Postal Sector.."
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group
                    id="general-location-group"
                    label="General Location"
                    class="required"
                  >
                    <b-form-input
                      id="general_location"
                      v-model="form.general_location"
                      type="text"
                      placeholder="Enter General Location.."
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
              <b-button type="submit" variant="success" class="desktop_margin_left_zero"
                >Submit</b-button
              >
              <b-button :to="{ name: 'postal-district-list' }" variant="light">Cancel</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import Vue from "vue";

// import { button, input, select } from 'iview';

export default {
  data() {
    return {
      form: {
        postal_district: "",
        postal_sector: "",
        general_location: "",
      },
      items: null,
      // id: Vue.prototype.decrypt(this.$route.params.id),
      id: atob(this.$route.params.id),
      error_message: "",
      selectedImage: "",
      success_message: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,

      delta: undefined,
    };
  },

  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "postal-district-list" },
          text: "Postal Districts",
        },
        {
          to: null,
          text: "Edit Postal District",
        },
      ];
      return item;
    },
    getEditData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: this.status,
            page: this.currentPage,
            keyword: this.filter,
            id: this.id,
          },
          api: "/api/postal-district-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.form.postal_district = this.$store.getters.getResults.data.postal_district;
            this.form.postal_sector = this.$store.getters.getResults.data.postal_sector;
            this.form.general_location = this.$store.getters.getResults.data.general_location;
          }
        });
    },

    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    formSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.id,
            postal_district: this.form.postal_district,
            postal_sector: this.form.postal_sector,
            general_location: this.form.general_location,
          },
          api: "/api/postal-district-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.showSuccessAlert();
          }
        });
    },

    showSuccessAlert() {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: "Postal District Updated successfully.",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "postal-district-list" });
      });
    },
  },
  mounted() {
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Admin - Database - Edit Postal District";
    this.getEditData();
  },
};
</script>
