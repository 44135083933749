<template>
  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Daily FL Signup / Approved</h1>
        </div>

        <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
              </div> -->
      </div>
    </div>
    <b-row>

        <b-col lg="5" class="search_common_margin_bottom_28">
            <div>
              <label class="new_style_label required_sign required desktop_margin_bottom_zero"
                >Date Range</label
              >
              <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                <date-range-picker
                  class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
                  ref="picker"
                  :minDate="minDate"
                  :opens="center"
                  :locale-data="locale"
                  :auto-apply="auto"
                  v-model="dateRange"
                  :ranges="range"
                  @update="dateFilterFunction()"
                >
                  <div slot="input" slot-scope="picker">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </div>
                </date-range-picker>
              </b-form-group>
            </div>
        </b-col>
        <b-col lg="7" class="search_common_margin_bottom_28">
            <div class="top_black_btn margin_left_15_desktop mobile_text_start">
              <b-button                
                class="common_z_index_zero ml-0 button ripple-effect add_btn_admin_e desktop_margin_right_zero"
                @click="exportSignup()"
                style="margin-left: 19px"
                >Export</b-button
              >
            </div>
        </b-col>
        <div class="container my_new_container show_filter_arrow">
          <b-table
            ref="datatable"
            show-empty
            striped
            hover
            responsive
            :items="getSignupListing"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="mobile_min_width_1000 nric_three_grid_table_new"
          >
            <template #cell(date)="items">
              {{ items.item.date | momentDate }}
            </template>
            <template #cell(signup)="items">
              {{ items.item.signup }}
            </template>

              <!-- <template #cell(curMonthBalance)="items">
                {{ items.item.balance | if_number }}
              </template> -->
              <template #cell(approved)="items">
                {{ items.item.approved }}
              </template>
            </b-table>
            <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
              <b-form-group id="input-group-4">
                <span class="d-inline-block">Rows per page</span>
                <span class="d-inline-block"
                  ><b-form-select v-model="form.rowsPerPage" @change="dateFilterFunction">
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                  </b-form-select></span
                >
              </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
              <ul>
                <li>
                  <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                </li>
              </ul>
            </div>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              prev-text=""
              next-text=""
              hide-goto-end-buttons
            />
            </nav>
        </div>

    </b-row>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  data() {
    return {
      fields: [
        // { key: 'certificate_number', label: 'Certificate No.', sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "signup", label: "Total FL Signup", sortable: true },
        { key: "approved", label: "Total FL Approved", sortable: true },
      ],
      fieldsOverall: [
        { key: "date", label: "Date", sortable: true },
        { key: "signup", label: "Total FL Signup", sortable: false },
        { key: "approved", label: "Total FL Approved", sortable: true },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      perPage: 25,
      itemsJobs: null,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,

      dateRange: {
        startDate: "",
        endDate: "",
      },
      pageOptions: [25, 50, 75, 100],
      dateRange: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
        status: "",
      },
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
  },
  components: {
    DateRangePicker,
  },
  methods: {
    exportSignup() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "";
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "";
      var sortBy = this.sortBy ? this.sortBy : "date";
      var sortDirection = this.sortDesc ? "desc" : "asc";
      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/signUpApprovedList-overall-excel/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    getSignupListing() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            start_date: this.dateRange.startDate
              ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange.endDate
              ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
              : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "date",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/signUpApprovedListOverall",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            // this.total_uploaded = this.items.length > 0 ? this.items[0].total_uploaded : 0;
              
            return this.items;
          }
        });
    },
            dateFilterFunction()
        {
                this.getSignupListing().then(() => {
                    this.$refs.datatable.refresh();
                })
        },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
  },
};
</script>
