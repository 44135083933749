<template>
    <div>
        
         <!-- <div class="container full_container_dsk">
            <div class="row custom_dsk_row"> -->
        <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
                <div class="dashboard-headline">
                    <h1>Whatsapp Templates</h1>
                    <b-link class="desktop_margin_left_zero btn green-border-btn" :to="{name:'whatsapp-template-create'}">Add</b-link>
                    <!-- Breadcrumbs -->
                    <nav id="breadcrumbs" class="dark">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Whatsapp Templates</li>
                        </ul>
                    </nav>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                        <div class="search-box custom_search_admin desktop_margin_top_zero">
                            <b-input-group-prepend>
                                <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                            </b-input-group-prepend>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
        <div class="row">
            <div class="container my_new_container show_filter_arrow">
				<b-table ref="datatable" show-empty striped hover responsive :items="getWhatsappTemplates" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 countries_five_grid_table"> 
                    <template #cell(template_name)="items">
                        {{items.item.template_name}}
                    </template>
                    <template #cell(template_msg)="items">
                    {{items.item.template_msg | capitalize}}
                    </template> 
                    <template #cell(sample_msg_image)="items">
                        <!-- <img :src="(items.item.sample_msg_image != null ? items.item.sample_msg_image: webUrl+'assets/images/logo.png')" height="100px" width="100px" alt="logo"> -->
                        <img v-if="items.item.sample_msg_image" :src=" items.item.sample_msg_image" height="100px" width="100px" alt="logo">
                    </template>
                    <template #cell(template_type)="items">
                    {{items.item.template_type | removeUnderscore}}
                    </template>  
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item :to="{name: 'whatsapp-template-edit', params: {id: encrypt(items.item.id)}}" v-if="show('edit')">Edit
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getWhatsappTemplates'> 
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type';
import permission from "../../../../../server/permission.js";

import moment from "moment";

export default {
    data() {
        return {
            fields: [
                { key: 'template_name', label: 'Template Name', sortable: false },
                { key: 'template_msg', label: 'Template Message', sortable: false },
                { key: 'sample_msg_image', label: 'Sample Message Image', sortable: false },
                { key: 'template_type', label: 'Template Type', sortable: false },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            actions:'',

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
        if(date != null)
            return moment(date).utc().format('DD MMM YYYY hh:mm A');
        else
            return '-';
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
    },
    methods:{
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getWhatsappTemplates() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/whatsapp-template-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
        
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Whatsapp Templates');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
    },
    mounted()
    {
        $(".show_filter_arrow .table thead th div").contents().unwrap();
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Website Contents - Whatsapp Templates";
        this.permissionStaff();

    }
}
</script>
