<template>
    <div>

         <!-- <div class="container full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
    <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <!-- <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
            </div> -->
            <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Job Approval</h1>
            <b-link style="float: right;margin-top: -15px;" class="btn green-border-btn" :class="idArr.length == 0 ? 'disabled' : ''" @click="approveMultiple()">Approve</b-link>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Job Approval</li>
                </ul>
            </nav>
        </div>
            <div class="row"> 
                
                <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                </div>
            </div>
            </div>
         </div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk">
           <div class="row"> -->
      <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getBillingAddress" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="new_box_tbl_mrgn five_grid_tbl common_tbl_width"> -->
              <b-table ref="datatable" show-empty striped hover responsive :items="getBillingAddress" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 el_coin_four_grid_table">

                    <template #cell(checkBox)="items">
                        <b-form-checkbox
                            @input="onPreviewClick($event,items.item.job_unique_id,items.item.start_date,items.item.start_time)"
                        ></b-form-checkbox>
                    </template>
                    <template #cell(job_id)="items">

                        {{items.item.title | capitalize}} <br>
                        {{items.item.job_unique_id | capitalize}} 
                       
                    </template>
                    <template #cell(start_date)="items">
                        {{items.item.start_date | moment}}  {{items.item.start_time | moment2}}
                    </template>
                    <template #cell(vacancy)="items">
                        {{totalVacancy(items.item.total_vacancies)}}
                    </template>
                    <template #cell(per_hour_cost)="items">
                        {{items.item.hourly_cost}}
                    </template>


                    <!-- <template v-if="!item.isEditing">{{items.item.job_hour | capitalize}}</template>
                        <b-form-input v-else v-model="item[key]" /> -->
                    <template  #cell(hourly_cost)="items">
                        <template >{{items.item.working_hours | capitalize}} (+{{items.item.lunch_pay_hours ? items.item.lunch_pay_hours : 0}} Hours Unpaid break)</template>
                        <!-- <b-form-input v-else v-model="item[key]" /> -->
                    </template>
                      <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item @click="openJobModel(items.item.job_unique_id,items.item.total_vacancies,items.item.working_hours,items.item.lunch_pay_hours,items.item.lunch_pay_not_provided,items.item.start_date,items.item.start_time,items.item.hourly_cost)">Edit
                                </b-dropdown-item>
                                <b-dropdown-item @click="approve(items.item.id)">Approve
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                    
            
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getBillingAddress'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <b-modal ref="job-modal" :title="'Update Job Data('+ job_id +')' " hide-footer content-class="common_model_header common_model_title"  modal-class="background_custom_class">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box model_legend model_label">
                        
                                      <b-col lg="0" md="0" class="submit-field">
                                         <div id="show_hide_password_login" class="submit-field">
                                          <!-- <b-form-group id="input-group-0" label="Add Coins" class="required model_margin_bottom_zero"> -->
                                              <!-- <b-form-input id="input-0" type="number" min="0" placeholder="" :value="new_user_coins" @change="v => new_user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                                             <!-- <div class="row">
                                                <div class="col-xl-7 mobile_col_7">
                                                    <label class="model_margin_bottom_zero">Job ID</label>
                                                </div>
                                                <div class="col-xl-5 mobile_col_5">
                                                    <b-form-input id="input-0" type="text" placeholder="" :value="job_id" @change="v => job_id = v" class="input-with-border model_border_and_box_shadow" disabled></b-form-input>
                                                </div>
                                             </div><br> -->

                                             

                                             <div class="row">
                                                <div class="col-xl-7 mobile_col_7">
                                                    <label class="model_margin_bottom_zero">Vacancy</label>
                                                </div>
                                                <div class="col-xl-5 mobile_col_5">
                                                    <b-form-input id="input-0" type="text" placeholder="" :value="total_vacancies" @change="v => total_vacancies = v" class="input-with-border model_border_and_box_shadow"></b-form-input>
                                                </div>
                                             </div><br>

                                             <div class="row">
                                                <div class="col-xl-7 mobile_col_7">
                                                    <label class="model_margin_bottom_zero">Job Hour (Including Unpaid break)</label>
                                                </div>
                                                <div class="col-xl-5 mobile_col_5">
                                                    <select v-model="hour" class="custom-select number_of_hour" id="number_of_hour">
                                                    <option value="" disabled>Select</option>
                                                    <option :value="hour" v-for="(hour,id) in hours" :key="id">{{hour}}</option>
                                                </select>
                                                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="hour" @change="v => hour = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                                                </div>
                                             </div>

                                             <div class="row">
                                                <div class="col-xl-7 mobile_col_7">
                                                    <label class="model_margin_bottom_zero">Job Min</label>
                                                </div>
                                                <div class="col-xl-5 mobile_col_5">
                                                    <select v-model="min" class="custom-select min_year_exp" id="min_year_exp">
                                                        <option value="" disabled>Select</option>
                                                        <option value="00">00</option>
                                                        <option value="45">45</option>
                                                        <option value="30">30</option>
                                                    </select>
                                                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="hour" @change="v => hour = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                                                </div>
                                             </div><br>

                                             <div class="row">
                                                <div class="col-xl-7 mobile_col_7">
                                                    <label class="model_margin_bottom_zero">Per Hour Cost</label>
                                                </div>
                                                <div class="col-xl-5 mobile_col_5">
                                                    <b-form-input id="input-0" type="text" placeholder="" :value="per_hour_cost" @change="v => per_hour_cost = v" class="input-with-border model_border_and_box_shadow"></b-form-input>
                                                </div>
                                             </div><br>
                                             <div class="row">
                                                <div class="col-xl-7 mobile_col_7">
                                                    <label class="model_margin_bottom_zero">Unpaid break</label>
                                                </div>
                                                <div class="col-xl-5 mobile_col_5">
                                                    <b-form-input id="input-0" type="text" placeholder="" :value="lunch_pay_hours" @change="v => lunch_pay_hours = v" class="input-with-border model_border_and_box_shadow" disabled></b-form-input>
                                                </div>
                                             </div>
 
                                          <!-- </b-form-group> -->
                                         </div>
                                      </b-col>
                                      <b-col lg="0" md="0">
                                      <div slot="footer" class="form-btn common_crop_reset_btn">
                                          <b-button type="submit" variant="success" @click="submitUpdateJob()" class="model_common_bt_success_color">Update</b-button>
                                          <b-button type="submit" variant="success" @click="submitUpdateApproveJob()" class="model_common_bt_success_color">Update & Approve</b-button>

                                          <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                      </div>
                                      </b-col>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
            </b-modal>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>



<script>
import { POST_API } from '../../store/actions.type'
import moment from "moment";

export default {

    data() {
        return {
            fields: [
                { key: 'checkBox', label: '#', sortable: false },

                { key: 'job_id', label: 'Job Title & Id', sortable: true },
                { key: 'start_date', label: 'Start Date & Time', sortable: true },

                { key: 'vacancy', label: 'Vacancy', sortable: true },
                { key: 'per_hour_cost', label: 'Per Hour Cost', sortable: true },

                { key: 'hourly_cost', label: 'Job Hour', sortable: true },
                { key: 'toggleAction', label: 'Actions', }

            ],
            pager: {},
            
            pageOfItems: [],
            items:null,
            totalRows: 0,
            address_count:0,
            job_id:'',
            total_vacancies:'',
            working_hours:'',
            min:'',
            per_hour_cost:'',
            lunch_pay_hours:0,
            startDate:'',
            hour:'',
            name:'',
            from: '',
            idArr:[],
            idArrwithTime:[],

            to: '',
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            hours: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],

            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            default_increment_in_vacancy:0

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            if(date != null)
              return moment(date).utc().format('DD MMM YYYY');
            else
              return '-';
        },
        moment2: function(date) {
            if(date =='' || date == null){
                    return '';
                }else{
                    return moment(date, "HH:mm:ss").format("hh:mm A");
                }
        },
    },
    methods:{

        edit(item){

            console.log(item)

            if (item.isEditing){
                    item.isEditing = false;
            }
                else{
                    this.$set(item, 'isEditing', true)
                }


        },
        onPreviewClick(eve, id,start_date,start_time){


            console.log(id);

            if(eve == true){

                this.idArr.push(id)

                this.idArrwithTime.push(id+"("+moment(start_date).format('YYYY-MM-DD')+ " "+start_time+")")
            }else{
                for (let i = 0; i < this.idArr.length; i++) {
                    if (this.idArr[i] == id) {
                         this.idArr.splice(i, 1);
                        
                    }
                 }

                 for (let i = 0; i < this.idArrwithTime.length; i++) {
                    if (this.idArrwithTime[i] == id+"("+moment(start_date).format('YYYY-MM-DD')+ " "+start_time+")") {
                         this.idArrwithTime.splice(i, 1);
                        
                    }
                 }

            }

            console.log(this.idArrwithTime);



        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                //this.$router.push({ name: 'country-list' });
            });
        },

        isInteger(num) {
            return num.toString().indexOf('.') === -1;
        },
        totalVacancy(current_vacancy){
            return (parseInt(current_vacancy) - parseInt(this.default_increment_in_vacancy));
        },

        openJobModel(job_unique_id,vacancy,hour,lunch_pay_hours,lunch_pay_not_provided,start_date,start_time,hourlycost)
        {
            this.job_id = job_unique_id;
            this.total_vacancies = vacancy ? this.totalVacancy(vacancy) : 0;
            this.per_hour_cost = hourlycost ? hourlycost : 0;

            this.working_hours = hour ? hour : 0;
            this.lunch_pay_hours = lunch_pay_hours ? lunch_pay_hours : 0;
            this.lunch_pay_not_provided = lunch_pay_not_provided
            this.startDate = moment(start_date).format('YYYY-MM-DD') + " " + start_time;

            console.log(this.startDate,'jhjjj');

            var sum_hrs = parseFloat(this.working_hours) + parseFloat(this.lunch_pay_hours);

                        if(lunch_pay_not_provided == 'yes'){

                            this.hour = this.isInteger(sum_hrs) ? Math.floor(sum_hrs) : Math.floor(sum_hrs);
                        }else{
                            this.hour = this.isInteger(this.working_hours) ? this.working_hours : Math.floor(this.working_hours);
                        }
                        var h = sum_hrs.toString();
                        
                        var split = this.isInteger(h) ? [h] : h.split(".");
                        console.log(split, 1)
                        if(split.length > 1){
                            if(split[1] == '25'){
                                // this.form.hours = (this.form.hours - 1);
                                this.min = '30';
                            }else{
                                this.min = split[1] == '75' ? '45' : (split[1] == '5' ? '30' : '00');
                            }
                        }else{
                            this.min = '00'
                        }



            this.$refs['job-modal'].show();
        },

        hideModel()
        {
          
          this.$refs['job-modal'].hide();
        },
        submitUpdateJob(){
          return this.$store.dispatch(POST_API, {
                data:{
                  job_id : this.job_id,
                  total_vacancies : this.total_vacancies,
                  hour: this.hour,
                  min: this.min,
                  lunch_pay_hours: this.lunch_pay_hours,
                  start_date : this.startDate,
                  per_hour_cost : this.per_hour_cost,


                },
                api: '/api/update-job-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        this.hideModel();
                        this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: this.success_message,
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                 this.$refs.datatable.refresh();
                            });

                    }
                });
        },
        submitUpdateApproveJob(){
          return this.$store.dispatch(POST_API, {
                data:{
                    job_id : this.job_id,
                  total_vacancies : this.total_vacancies,
                  hour: this.hour,
                  min: this.min,
                  lunch_pay_hours: this.lunch_pay_hours,
                  start_date : this.startDate,
                  name:this.name,
                  per_hour_cost : this.per_hour_cost,

                },
                api: '/api/update-job-data-approve'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        this.hideModel();
                        this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: this.success_message,
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                 this.$refs.datatable.refresh();
                            });

                    }
                });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getBillingAddress() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    company_id:this.company_id,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/job-data-for-approval'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;

                    this.items.forEach(element => {

                        this.$set(element, 'isEditing', true)
                        
                    });
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    this.default_increment_in_vacancy = this.$store.getters.getResults.default_increment_in_vacancy;

                    return this.items;
                }
            });
        },

        approve(id) {
            
         
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            page: this.currentPage,
                            name:this.name
                            },
                            api:'/api/approve-job' 
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: 'Job approved successfully.',
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                 this.$refs.datatable.refresh();
                            });
                        }
                    });
              
                },

                approveMultiple() {
            
         
            return this.$store.dispatch(POST_API, {
                    data:{
                    id: this.idArr,
                    name:this.name,
                    idArrwithTime:this.idArrwithTime
                    },
                    api:'/api/approve-job-multiple' 
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        iconColor:'#4c8bf5',
                        title: 'Job approved successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                         window.location.reload()
                    });
                }
            });
      
        }
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();

            this.idArr = [];
            //document.title = "EL Connect - Employer - Billing Address"; 
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.name = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.last_name + " " + this.$store.getters.currentUser.first_name: '');

            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');

            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
     }
}
</script>
