<template>
    <div>
        <div class="input-box">
            <img src="../../../public/assets/images/RPA_testing_qr.png" class="qr-img">
            <div style="margin: 0 auto; font-size: large;"><a target="_blank" href="https://www.v2.elconnect.sg/rpa-testing1">Click here if you can't scan</a></div>
        </div>
    </div>
</template>

<script>
// import { POST_API } from '../../store/actions.type';
export default {
    data() {
        return {
            name: '',
            id: '',
            test: '',
            show_input: '',
        }
    },
    methods: {
        showInput(){
            this.show_input = true;
            console.log('button clicked')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
.input-box {
    width: 60%;
    margin: 0 auto;
    height: 60svh;
    box-shadow: 3px 3px 10px #d6d5d2;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 15px 20px;
    margin-top: 20px;
}

.input-row {
    display: flex;
    flex-wrap: row;
    gap: 15px;

}

.input-row:deep(label) {
    font-size: 16px;
    width: 50px;
    text-align: right;
}

.input-row:deep(input) {
    width: 300px;
}

.input-row:deep(button){
    box-shadow: 3px 3px 10px #d6d5d2;
    width: 100px;
    height: 32px;
    border-radius: 15px;
}

.qr-img {
    max-width: 300px;
    height: 300px;
    margin: 0 auto;
    margin-bottom: 20px;
}
</style>