<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Transactions</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Transactions</li>
            </ul>
          </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th">
          <b-tab :title="'Jobs'" @click="changeTabJob">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterJobs = searchKeywordJobs"
                        v-model="searchKeywordJobs"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterJobs = searchKeywordJobs"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-form-group
                      id="input-group-6"
                      class="common_date_range_border_new desktop_margin_bottom_zero common_date_range_picker_new"
                    >
                      <date-range-picker
                        class="web_bottom_zero transactions_jb_calender calender_div_job_in_job"
                        ref="picker"
                        :minDate="minDate"
                        :opens="center"
                        :locale-data="locale"
                        :auto-apply="auto"
                        v-model="dateRange"
                        :ranges="range"
                        @update="changeTabJob()"
                      >
                        <div slot="input" slot-scope="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </div>
                      </date-range-picker>
                    </b-form-group>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <template>
                  <div class="col-xl-4 col-md-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="form.tran_type"
                        :options="freelancerOptions"
                        :multiple="false"
                        placeholder="Select Freelancer"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJob()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <template>
                  <div class="col-xl-3 col-md-3 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="form.status"
                        :options="transactionStatusOptions"
                        :multiple="false"
                        placeholder="Transaction Status"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJob()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>


                <template>
                  <div class="col-xl-3 col-md-3  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="form.mode"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJob()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <div
                  class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <!-- <b-form-select id="selectServices" :plain="true" v-model="form.type" @change="changeTabJob()" class="remove_box_shadow desktop_margin_bottom_zero">
                                      <option value="">Transaction Type</option>
                                      <option value="add_back">Add Back</option>
                                      <option value="deduct">Deduct</option>
                                      <option value="fast_payment">Fast Payment Cash-out</option>
                                      <option value="incentive">Incentive</option>
                                      <option value="insurance">Insurance</option>
                                      <option value="job_completed">Job Completed</option>
                                      <option value="card">Job Payment Card</option>
                                      <option value="monthly_salary_payment">Monthly Salary Payment</option>
                                      <option value="paynow">Pay Now Cash-out</option>
                                      <option value="penalty">Penalty</option>
                                      <option value="penalty_waiver">Penalty Waiver</option>
                                      <option value="referral">Referral</option>
                                      <option value="refund">Refund</option>
                                      <option value="signup_bonus">Signup Bonus</option>
                                      <option value="transport_reimburse">Transport Reimburse</option>

                                    </b-form-select> -->
                    <b-form-select
                      v-model="form.type"
                      @change="changeTabJob()"
                      class="remove_box_shadow desktop_margin_bottom_zero date_style_size"
                    >
                      <option value="">Select Transaction</option>
                      <option :value="transaction" v-for="transaction in transactions">
                        {{ transaction | removeUnderscore }}
                      </option>
                    </b-form-select>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-form-select
                      v-model="form.sub_type"
                      @change="changeTabJob()"
                      class="remove_box_shadow desktop_margin_bottom_zero date_style_size"
                    >
                      <option value="">Select Sub Transaction</option>
                      <option :value="transaction.id" v-for="transaction in transaction_sub_types">
                        {{ transaction.title | capitalize }}
                      </option>
                    </b-form-select>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <div
                  class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen col-xl-12 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <button
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    @click="openMultipleTransactionModel()"
                    style="background-color: #4c8bf5; color: white"
                  >
                    Add
                  </button>
                  <b-button
                    class="common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportJobsTransactions()"
                    v-if="show('export')"
                    >Export</b-button
                  >
                  <!--<b-button
                    class="common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportJobsTransactionsWithCalculation()"
                    v-if="show('export')"
                    >Export with calculation</b-button>
                  >-->
                  <b-button
                    @click="changeTabJob()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <b-button
                    v-if="
                      filterJobs ||
                      searchKeywordJobs ||
                      form.status ||
                      form.mode ||
                      form.type ||
                      form.tran_type ||
                      form.sub_type ||
                      dateRange.startDate != start_date_filter ||
                      dateRange.endDate != end_date_filter
                    "
                    @click="clearFilterChangeJobs()"
                    class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px;"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableAll"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getJobTransaction"
                  :fields="fields"
                  :current-page="currentPageJobs"
                  :per-page="perPageJobs"
                  :filter="filterJobs"
                  :sort-by.sync="sortByJobs"
                  :sort-desc.sync="sortDescJobs"
                  :sort-direction="sortDirectionJobs"
                  class="common_small_font_table new_layout_table_like_v1 desktop_margin_top_zero new_box_tbl_mrgn"
                >
                  <template #cell(transaction_number)="items">
                    {{ items.item.transaction_number | capitalize }}
                  </template>
                  <!-- <template #cell(transaction_id)="items">
                        {{items.item.transaction_id | capitalize}}
                    </template> -->
                  <template #cell(amount)="items">
                    <!-- ${{items.item.amount}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      ${{ items.item.amount }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      ${{ items.item.amount }}
                    </p>
                  </template>
                  <template #cell(last_name)="items">
                    <b-link
                      :to="
                        showFreelancer('view profile')
                          ? {
                              name: 'jobseeker-dp-profile-view',
                              params: { id: encrypt(items.item.user_id) },
                            }
                          : null
                      "
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                      {{ items.item.last_name | capitalize }}
                      {{ items.item.first_name | capitalize }}
                    </b-link>
                  </template>
                  <template #cell(job_unique_id)="items">
                    <p
                      v-if="
                        items.item.job_id
                      "
                    >
                      <a
                        v-if="items.item.job_v1_status == 'in-progress'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-inprogress/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        <!-- {{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}  -->
                        {{
                          items.item.job_unique_id
                            ? items.item.job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.job_v1_status == 'active'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-upcoming/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_unique_id
                            ? items.item.job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.job_v1_status == 'completed'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-completed/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_unique_id
                            ? items.item.job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-cancelled/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_unique_id
                            ? items.item.job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                    </p>
                    <p v-else>{{ "-" }}</p>
                  </template>

                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transaction_status == "successfull"
                        ? "successful"
                        : items.item.transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(coins)="items">
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                    <!-- ${{items.item.coins}} -->
                  </template>
                  <template #cell(type)="items">
                    {{ items.item.type | capitalize | removeUnderscore }}<br />
                    <span v-if="items.item.sub_type_name != null"
                      >({{ items.item.sub_type_name | capitalize }})</span
                    >
                  </template>

                  <template #cell(billing_type)="items">
                    {{ items.item.billing_type_name | capitalize }}
                  </template>
                  <template #cell(mode)="items">
                    <!-- {{items.item.mode | capitalize}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.remarks | capitalize }}
                  </template>
                  <template #cell(internal_remarks)="items">
                    {{ items.item.internal_remarks | capitalize }}
                  </template>
                  <template #cell(created_by_user_last_name)="items">
                    {{ items.item.created_by_user_last_name | capitalize }}
                    {{ items.item.created_by_user_first_name | capitalize }}
                  </template>
                  <template #cell(created_at)="items">
                    {{ items.item.trans_created_at | customFormatterOne }}
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item @click="openModel(items.item.id)"
                          >View Transaction Details
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.remarks || items.item.internal_remarks) &&
                            show('update remark')
                          "
                          v-on:click="updateRemark(items.item.id, 'update', items.item.type)"
                          >Edit Remark
                        </b-dropdown-item>
                        <!-- <b-dropdown-item
                          v-else-if="show('add remark')"
                          v-on:click="updateRemark(items.item.id, 'add', items.item.type)"
                          >Add Remark
                        </b-dropdown-item> -->
                        <b-dropdown-item
                          v-if="
                            items.item.mode == 'debit' &&
                            items.item.type == 'job_completed' &&
                            (items.item.coins != '' || items.item.coins != null) &&
                            show('update elc credits')
                          "
                          v-on:click="
                            openCoinModel(
                              items.item.id,
                              items.item.user_id,
                              items.item.coins,
                              items.item.mode
                            )
                          "
                          >Update ELC Credits
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.type == 'add_back' ||
                              items.item.type == 'deduct' ||
                              items.item.type == 'transport_reimburse') &&
                            show('update amount')
                          "
                          v-on:click="
                            openAmountModel(
                              items.item.id,
                              items.item.user_id,
                              items.item.amount,
                              items.item.mode
                            )
                          "
                          >Update Amount
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.type == 'add_back' ||
                              items.item.type == 'deduct' ||
                              items.item.type == 'transport_reimburse') &&
                            show('delete transaction')
                          "
                          v-on:click="
                            deleteJobsAddBackDeductData(
                              items.item.id,
                              items.item.user_id,
                              items.item.amount,
                              items.item.mode,
                              items.item.type,
                              items.item.created_by
                            )
                          "
                          >Delete Transaction
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="items.item.has_image == 'yes' && show('view images')"
                          v-on:click="openImagesModel(items.item.id)"
                          >View Images
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="downloadPdf(items.item.invoice_pdf)">View Invoice</b-dropdown-item> -->
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPageJobs" @change="getJobTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsJobs != 0"
                          >{{ fromJobs }} - {{ toJobs }} of {{ totalRowsJobs }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsJobs"
                    :per-page="perPageJobs"
                    v-model="currentPageJobs"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'ELC Credits'" @click="changeTab">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero transaction_search">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <multiselect
                      id="ajax"
                      v-model="company_id_filter"
                      :custom-label="customLabel"
                      tag-placeholder="Search Company"
                      placeholder="Company"
                      :options="companies"
                      label="full_name"
                      track-by="company_id"
                      @input="changeTab()"
                      :multiple="false"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      open-direction="bottom"
                      class="common_multiselect_height common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                      ><span slot="noResult">No Company Found.</span></multiselect
                    >
                  </b-form-group>
                </div>

                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                 -->
                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                    <date-range-picker
                      class="date_range_height_36 cut_calender_fix manage_job_date_claneder common_date_range_picker_new common_width_100 transactions_jb_calender"
                      ref="picker"
                      :minDate="minDate"
                      :opens="center"
                      :locale-data="locale"
                      :auto-apply="auto"
                      v-model="dateRange1"
                      :ranges="range"
                      @update="changeTab()"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </b-form-group>
                </div>

                <template>
                  <div class="col-xl-3 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="mode_filter"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_multiselect_height common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTab()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <div
                  class="col-xl-3 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <multiselect
                      id="ajax"
                      @input="changeTab()"
                      v-model="trans_type_filter"
                      :custom-label="customLabelTransType"
                      tag-placeholder="Search Transaction Type"
                      placeholder="Transaction Type"
                      :options="transactionTypesMultiselect"
                      label="full_name"
                      track-by="name"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      open-direction="bottom"
                      class="common_multiselect_height common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                      ><span slot="noResult">No Transaction Type Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <div
                  class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <button
                    class="desktop_margin_left_zero common_z_index_zero btn button ripple-effect add_btn_admin_e"
                    @click="openMultipleTransactionModel()"
                    style="background-color: #4c8bf5; color: white"
                  >
                    Add
                  </button>
                  <b-button
                    class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportCoinTransactions()"
                    v-if="show('export')"
                    >Export</b-button
                  >
                  <b-button
                    @click="changeTab()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn new_reset_btn"> -->
                  <b-button
                    v-if="
                      filter ||
                      searchKeyword ||
                      company_id_filter ||
                      trans_type_filter ||
                      mode_filter ||
                      dateRange1.startDate != start_date_filter ||
                      dateRange1.endDate != end_date_filter
                    "
                    @click="clearFilterChangeCoin()"
                    class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px;"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableCoin"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getCoinTransaction"
                  :fields="fieldsCoin"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn common_small_font_table new_layout_table_like_v1"
                >
                
                  <template #cell(last_name)="items">
                    <span v-if="items.item.transactions[0].company_id">
                      <b-link
                        :to="
                          showEmployer('view profile')
                            ? {
                                name: 'employer-profile-view',
                                params: {
                                  id: encrypt(items.item.transactions[0].user_id),
                                  company_id: encrypt(items.item.transactions[0].company_id),
                                },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.transactions[0].last_name != null ? items.item.transactions[0].last_name : "" | capitalize }}
                        {{ items.item.transactions[0].first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else-if="items.item.transactions[0].last_name">
                      <b-link
                        v-if="items.item.transactions[0].user_type_id == 5"
                        :to="
                          showFreelancer('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.transactions[0].user_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.transactions[0].last_name != null ? items.item.transactions[0].last_name : "" | capitalize }}
                        {{ items.item.transactions[0].first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else>
                      {{ items.item.transactions[0].last_name | capitalize }}
                      {{ items.item.transactions[0].first_name | capitalize }}
                    </span>
                  </template>
                  <template #cell(company_name)="items">
                    <div class="d-flex align-items-center">
                      {{ items.item.transactions[0].company_name ? items.item.transactions[0].company_name : "-" | capitalize }}
                    <b-button 
                    v-if="items.item.isExpandable==true"
                            size="sm" 
                            @click="items.toggleDetails" 
                            class="expand-button"
                            :class="{ 'expanded': items.detailsShowing }"
                        >
                        <i class="fas" :class="items.detailsShowing ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                        </b-button>
                    </div>
                    
                  </template>
                  <template #row-details="row">
  <div class="d-flex flex-column w-100">
    <!-- Loop through all transactions -->
    <b-card v-for="(transaction, index) in row.item.transactions" :key="index" class="expanded-card mb-2">
      <div class="d-flex justify-content-start align-items-center">
        <!-- Status Icon -->
        
        <i 
  class="fas" 
  :class="[transaction.transaction_status === 'successfull' ? 'fa-check text-success' : 'fa-times text-danger']">
</i>

        
        <!-- Date and Time -->
        <div class="me-auto">
          <small class="px-2">{{ transaction.trans_created_at ? new Date(transaction.trans_created_at).toLocaleDateString() : "-" }}</small>
          <small class="pr-2">{{ transaction.trans_created_at ? new Date(transaction.trans_created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "-" }}</small>
        </div>

        <!-- Created by -->
        <div>
          <small class="pl-5">Created by: {{ transaction.created_by || "-" }}</small>
        </div>
      </div>
      
      <!-- Transaction Details -->
      <ul class="list-unstyled mt-2 mb-0 ps-3">
        <li><strong>ELC Credits:</strong> {{ transaction.coins || "-" }}</li>
        <li>
          <strong>Transact Type:</strong> 
          <span :class="transaction.mode=='credit' ? 'text-success' : 'text-danger'">
            {{ `${transaction.trans_type_export} (${transaction.mode})` || "-" }}
          </span>
        </li>
        <li><strong>Transac ID:</strong> {{ transaction.transaction_id || "-" }}</li>
        <li><strong>Transac No:</strong> {{ transaction.transaction_number || "-" }}</li>
      </ul>
    </b-card>
  </div>
</template>


                  <!-- <template #cell(job_unique_id)="items">
                        <p v-if="items.item.job_unique_id && items.item.invoice_id">
                        <b-link :to="showManageFTJobs('view job Detail') ? {name: 'fullTimeJobDetail', params: {id: encrypt(items.item.job_id)}} : null" target="_blank" style="color: #4c8bf5;">
                            {{items.item.job_unique_id ? items.item.job_unique_id : '-' | capitalize}}
                        </b-link><br></p>
                        <p v-else-if="items.item.job_id && !items.item.invoice_id && !items.item.job_unique_id">{{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}</p>
                        <p v-else>{{'-'}}</p>
                     </template> -->

                  <template #cell(job_unique_id)="items">
                    <p v-if="items.item.transactions[0].job_unique_id && items.item.transactions[0].invoice_id">
                      <b-link
                        :to="
                          showManageFTJobs('view job Detail')
                            ? {
                                name: 'fullTimeJobDetail',
                                params: { id: encrypt(items.item.transactions[0].job_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_unique_id ? items.item.transactions[0].job_unique_id : "-" | capitalize
                        }} </b-link
                      ><br />
                    </p>
                    <p
                      v-else-if="
                        items.item.transactions[0].job_id &&
                        !items.item.transactions[0].invoice_id &&
                        items.item.transactions[0].job_v1_job_unique_id
                      "
                    >
                      <a
                        v-if="items.item.transactions[0].job_v1_status == 'in-progress'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-inprogress/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/' +
                              items.item.transactions[0].job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        <!-- {{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}  -->
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.transactions[0].job_v1_status == 'active'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-upcoming/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.transactions[0].job_v1_status == 'completed'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-completed/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/' +
                              items.item.transactions[0].job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-cancelled/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/' +
                              items.item.transactions[0].job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                    </p>
                    <p v-else>{{ "-" }}</p>
                  </template>

                  <!-- <template #cell(job_unique_id)="items" v-else>
                        <p>{{'-'}}</p>
                     </template> -->
                  <template #cell(transaction_number)="items">
                    {{ items.item.transactions[0].transaction_number | capitalize }}
                  </template>
                  <template #cell(coins)="items">
                    <p
                      v-if="items.item.transactions[0].mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.transactions[0].mode == 'credit' }"
                    >
                      {{ items.item.transactions[0].coins }}
                    </p>
                    <p
                      v-if="items.item.transactions[0].mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.transactions[0].mode == 'debit' }"
                    >
                      {{ items.item.transactions[0].coins }}
                    </p>
                    <!-- ${{items.item.coins}} -->
                  </template>
                  <!-- <template #cell(type)="items">
                        {{items.item.type == 'job_completed' ? 'Job Ad Posted' : items.item.type == 'elcoins' ? 'ELC Credits' : items.item.type | capitalize | removeUnderscore}}
                    </template> -->
                  <template #cell(type)="items">
                    {{ (items.item.transactions[0].trans_type_export == 'Pending' ? 'Hold' : items.item.transactions[0].trans_type_export) | capitalize | removeUnderscore }}
                  </template>
                  <template #cell(mode)="items">
                    <p
                      v-if="items.item.transactions[0].mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.transactions[0].mode == 'credit' }"
                    >
                      {{ items.item.transactions[0].mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.transactions[0].mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.transactions[0].mode == 'debit' }"
                    >
                      {{ items.item.transactions[0].mode | capitalize }}
                    </p>
                    <!-- {{items.item.mode | capitalize}} -->
                  </template>
                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transactions[0].transaction_status == "successfull"
                        ? "successful"
                        : items.item.transactions[0].transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.transactions[0].remarks | capitalize }}
                  </template>
                  <template #cell(internal_remarks)="items">
                    {{ items.item.transactions[0].internal_remarks | capitalize }}
                  </template>
                  <template #cell(created_by_user_last_name)="items">
                    {{ items.item.transactions[0].created_by_user_last_name | capitalize }}
                    {{ items.item.transactions[0].created_by_user_first_name | capitalize }}
                  </template>
                  <template #cell(created_at)="items">
                    {{ items.item.transactions[0].trans_created_at | customFormatterOne }}
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          v-if="
                            (items.item.transactions[0].remarks || items.item.transactions[0].internal_remarks) &&
                            show('update remark')
                          "
                          v-on:click="updateRemark(items.transactions[0].item.id, 'update', items.transactions[0].item.type)"
                          >Update Remark
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-else-if="show('add remark')"
                          v-on:click="updateRemark(items.item.transactions[0].id, 'add', items.item.transactions[0].type)"
                          >Add Remark
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="show('update elc credits')"
                          v-on:click="
                            openCoinModel(
                              items.item.transactions[0].id,
                              items.item.transactions[0].user_id,
                              items.item.transactions[0].coins,
                              items.item.transactions[0].mode
                            )
                          "
                          >Update ELC Credits
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.transactions[0].type == 'add_back' || items.item.transactions[0].type == 'deduct') &&
                            show('delete transaction')
                          "
                          v-on:click="
                            deleteCoinsAddBackDeductTransaction(
                              items.item.transactions[0].id,
                              items.item.transactions[0].user_id,
                              items.item.transactions[0].coins,
                              items.item.transactions[0].mode,
                              items.item.transactions[0].type,
                              items.item.transactions[0].created_by
                            )
                          "
                          >Delete Transaction
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="items.item.transactions[0].has_image == 'yes' && show('view images')"
                          v-on:click="openImagesModel(items.item.transactions[0].id)"
                          >View Images
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="downloadPdf(items.item.invoice_pdf)">View Invoice</b-dropdown-item> -->
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getCoinTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'Job Ads'" @click="changeTabJobAds">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeywordJobAds"
                        v-model="searchKeywordJobAds"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeywordJobAds"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <multiselect
                      id="ajax"
                      v-model="company_id_filter"
                      :custom-label="customLabel"
                      tag-placeholder="Search Company"
                      placeholder="Company"
                      :options="companies"
                      label="full_name"
                      track-by="company_id"
                      @input="changeTabJobAds()"
                      :multiple="false"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      open-direction="bottom"
                      class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                      ><span slot="noResult">No Company Found.</span></multiselect
                    >
                  </b-form-group>
                </div>

                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                 -->
                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                    <date-range-picker
                      class="cut_calender_fix manage_job_date_claneder common_date_range_picker_new common_width_100 transactions_jb_calender"
                      ref="picker"
                      :minDate="minDate"
                      :opens="center"
                      :locale-data="locale"
                      :auto-apply="auto"
                      v-model="dateRange1"
                      :ranges="range"
                      @update="changeTabJobAds()"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </b-form-group>
                </div>

                <template>
                  <div class="service_col_3 col-xl-3  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="mode_filter"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJobAds()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <div
                  class="service_col_3 col-xl-3 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <multiselect
                      id="ajax"
                      @input="changeTabJobAds()"
                      v-model="trans_type_filter"
                      :custom-label="customLabelTransType"
                      tag-placeholder="Search Transaction Type"
                      placeholder="Transaction Type"
                      :options="transactionTypesMultiselect"
                      label="full_name"
                      track-by="name"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      open-direction="bottom"
                      class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                      ><span slot="noResult">No Transaction Type Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <div
                  class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <button
                    class="btn button green-border-btn add_btn_admin_e desktop_margin_left_zero"
                    @click="openMultipleTransactionModel()"
                    style="background-color: #4c8bf5; color: white"
                  >
                    Add
                  </button>
                  <b-button
                    class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportCoinTransactions()"
                    v-if="show('export')"
                    >Export</b-button
                  >
                  <b-button
                    @click="changeTabJobAds()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn new_reset_btn"> -->
                  <b-button
                    v-if="
                      filter ||
                      searchKeywordJobAds ||
                      company_id_filter ||
                      trans_type_filter ||
                      mode_filter ||
                      dateRange1.startDate != start_date_filter ||
                      dateRange1.endDate != end_date_filter
                    "
                    @click="clearFilterChangeCoin()"
                    class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px;"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableJobAds"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getCoinTransaction"
                  :fields="fieldsJobAds"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn common_small_font_table new_layout_table_like_v1"
                >
                  <template #cell(last_name)="items">
                    <span v-if="items.item.company_id">
                      <b-link
                        :to="
                          showEmployer('view profile')
                            ? {
                                name: 'employer-profile-view',
                                params: {
                                  id: encrypt(items.item.user_id),
                                  company_id: encrypt(items.item.company_id),
                                },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name != null ? items.item.last_name : "" | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else-if="items.item.last_name">
                      <b-link
                        v-if="items.item.user_type_id == 5"
                        :to="
                          showFreelancer('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.user_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name != null ? items.item.last_name : "" | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else>
                      {{ items.item.last_name | capitalize }}
                      {{ items.item.first_name | capitalize }}
                    </span>
                  </template>
                  <template #cell(company_name)="items">
                    {{ items.item.company_name ? items.item.company_name : "-" | capitalize }}
                  </template>
                  <!-- <template #cell(job_unique_id)="items">
                        <p v-if="items.item.job_unique_id && items.item.invoice_id">
                        <b-link :to="showManageFTJobs('view job Detail') ? {name: 'fullTimeJobDetail', params: {id: encrypt(items.item.job_id)}} : null" target="_blank" style="color: #4c8bf5;">
                            {{items.item.job_unique_id ? items.item.job_unique_id : '-' | capitalize}}
                        </b-link><br></p>
                        <p v-else-if="items.item.job_id && !items.item.invoice_id && !items.item.job_unique_id">{{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}</p>
                        <p v-else>{{'-'}}</p>
                     </template> -->

                  <template #cell(job_unique_id)="items">
                    <p v-if="items.item.job_unique_id && items.item.invoice_id">
                      <b-link
                        :to="
                          showManageFTJobs('view job Detail')
                            ? {
                                name: 'fullTimeJobDetail',
                                params: { id: encrypt(items.item.job_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_unique_id ? items.item.job_unique_id : "-" | capitalize
                        }} </b-link
                      ><br />
                    </p>
                    <p
                      v-else-if="
                        items.item.job_id &&
                        !items.item.invoice_id &&
                        items.item.job_v1_job_unique_id
                      "
                    >
                      <a
                        v-if="items.item.job_v1_status == 'in-progress'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-inprogress/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        <!-- {{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}  -->
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.job_v1_status == 'active'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-upcoming/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.job_v1_status == 'completed'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-completed/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-cancelled/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                    </p>
                    <p v-else>{{ "-" }}</p>
                  </template>

                  <!-- <template #cell(job_unique_id)="items" v-else>
                        <p>{{'-'}}</p>
                     </template> -->
                     <template #cell(transaction_id)="items">
                    {{ items.item.transaction_id | capitalize }}
                  </template>
                  <template #cell(transaction_number)="items">
                    {{ items.item.transaction_number | capitalize }}
                  </template>
                
                  <template #cell(invoice_id)="items">
  <span v-if="items.item.trans_type_export === 'Job Ad Posted'||items.item.trans_type_export==='Featured Ad Posted'">
    <a :href="items.item.invoice_pdf" target="_blank">{{ items.item.invoice_id | capitalize }}</a>
  </span>
  <span v-else>
    {{ items.item.invoice_id | capitalize }}
  </span>
</template>

                  <template #cell(coins)="items">
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      1 Job Ads
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      1 Job Ads
                    </p>
                    <!-- ${{items.item.coins}} -->
                  </template>
                  <!-- <template #cell(type)="items">
                        {{items.item.type == 'job_completed' ? 'Job Ad Posted' : items.item.type == 'elcoins' ? 'ELC Credits' : items.item.type | capitalize | removeUnderscore}}
                    </template> -->
                  <template #cell(type)="items">
                    {{ items.item.trans_type_export | capitalize | removeUnderscore }}
                  </template>
                  <template #cell(mode)="items">
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <!-- {{items.item.mode | capitalize}} -->
                  </template>
                  <template #cell(gst)="items">
                    {{ items.item.gst }}
                  </template>
                  <template #cell(amount)="items">
                    {{ items.item.amount }}
                  </template>
                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transaction_status == "successfull"
                        ? "successful"
                        : items.item.transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.remarks | capitalize }}
                  </template>
                  <template #cell(internal_remarks)="items">
                    {{ items.item.internal_remarks | capitalize }}
                  </template>
                  <template #cell(created_by_user_last_name)="items">
                    {{ items.item.created_by_user_last_name | capitalize }}
                    {{ items.item.created_by_user_first_name | capitalize }}
                  </template>
                  <template #cell(created_at)="items">
                    {{ items.item.trans_created_at | customFormatterOne }}
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getCoinTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <b-modal
      ref="multiple-transaction-modal"
      title="Add Multiple transaction"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <multiselect
                    v-model="freelancer_id"
                    id="ajax"
                    label="full_name"
                    track-by="id"
                    placeholder="Type to search user"
                    open-direction="bottom"
                    :options="freelancerList"
                    :multiple="true"
                    :searchable="true"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :value="referred_to"
                    :allow-empty="true"
                    class="job_list_multi_select model_multi_select"
                  >
                    <span slot="noResult">No Data Found.</span>
                  </multiselect>
                </span>
              </b-form-group>
            </b-col>

            <b-col lg="6" md="6">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select
                    v-model="transaction_type"
                    class="model_margin_bottom_zero"
                    @change="getTransactionSubType()"
                  >
                    <option value="add_back">Add Back</option>
                    <option value="deduct">Deduct</option>
                    <option value="campaign">Campaign</option>
                    <option value="purchase">Purchase</option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6">
              <b-form-group id="input-group-5" label="" class="required submit-field">
                <b-form-input
                  id="input-5"
                  type="text"
                  placeholder="Amount"
                  :value="deduct_amount"
                  @change="(v) => (deduct_amount = v)"
                  maxlength="1000"
                  class="input-with-border model_border_and_box_shadow"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12" v-if="sub_transactions.length > 0">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select v-model="transaction_sub_type" class="model_margin_bottom_zero">
                    <option value="" disabled>Select Transaction Type</option>
                    <option :value="transaction.id" v-for="transaction in sub_transactions">
                      {{ transaction.title | capitalize }}
                    </option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <div class="submit-field">
                <datepicker
                  placeholder="Transaction Date"
                  :disabled-dates="disabledDateFromTransaction"
                  v-model="transaction_created_at"
                  :format="customFormatterOne"
                  clear-button=""
                  class="ticket_close_btn_from_date"
                ></datepicker>
                <span
                  class="input-icon calender_input_icon obtainment_date_calender common_pop_up_calnder_icon"
                  ><b-img
                    :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                    alt="Calander Icon"
                  ></b-img
                ></span>
              </div>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="" class="submit-field">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Full company name - job ID - Reason e.g., Eng Leng Contractors Pte Ltd-ID123456-Taxi Claim"
                  :value="transaction_remarks"
                  @change="(v) => (transaction_remarks = v)"
                  class="model_border_and_box_shadow model_textarea_height model_margin_bottom_zero"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select v-model="billing" class="model_margin_bottom_zero">
                    <option value="" disabled>Select Bill Type</option>
                    <option :value="bt.id" v-for="bt in billing_types">
                      {{ bt.name | capitalize }}
                    </option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="" class="submit-field">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Internal Remarks - Finance view for reference, what is this transaction meant for"
                  :value="transaction_internal_remarks"
                  @change="(v) => (transaction_internal_remarks = v)"
                  class="model_border_and_box_shadow model_textarea_height model_margin_bottom_zero"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <!-- <b-col lg="12" md="12" class="submit-field">
                                <div class="upload-form-group">
                                    <h5 id="title-group">Upload Image </h5>
                                    <b-form-group id="link-group" class="required">
                                        <div class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.selectedImage.image ? 'if_image_exist' : 'if_image_not_exist'">
                                            <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                            <span v-bind:style="form.selectedImage.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" alt="image" class="actual_img"></b-img>
                                                <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" alt="image" class="dummy_img"></b-img>
                                            </span>
                                        </div>
                                    </b-form-group>
                                </div>
                            </b-col> -->
            <div
              class="col-md-12 col-sm-12 common_text_align_end_desktop mobile_max_width_and_flex"
            >
              <div class="textare_pdng">
                <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  :maxImage="maxImage"
                  :multiple="multiple"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  :showEdit="false"
                  :data-images="images"
                  dragText="Browse"
                  browseText="Select Files"
                  primaryText=""
                  markIsPrimaryText=""
                  popupText="Image"
                  v-model="form.document"
                  accept=".jpg, .png, .jpeg"
                  limit-exceeded="2"
                  class="image_glr new_post_image add_transaction_img"
                >
                </vue-upload-multiple-image>
              </div>
            </div>
          </b-row>
          <div class="text-md-right new_text_align_end common_crop_reset_btn">
            <b-button
              type="button"
              v-if="designation != 'Customer Service'"
              @click="deduct_multiple_transaction()"
              variant="danger"
              class="mr-0 model_common_bt_success_color text-uppercase x-md btn-shadow"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="coin-modal"
      title="Update ELC Credits"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Transaction ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero"
                      >Transaction ELC Credits</label
                    ><span class="model_font_16 model_float_right">
                      {{ transaction_prev_coins }}</span
                    >
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 common_align_middle_text">
                        <label class="model_margin_bottom_zero">ELC Credits</label>
                      </div>
                      <div class="col-xl-5 mobile_col_5">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="transaction_new_coins"
                          @change="(v) => (transaction_new_coins = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateCoinInfo($event)"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="amount-modal"
      title="Update Amount"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Transaction ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero">Transaction Amount</label
                    ><span class="model_font_16 model_float_right">
                      {{ transaction_prev_amount }}</span
                    >
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 common_align_middle_text">
                        <label class="model_margin_bottom_zero">Amount</label>
                      </div>
                      <div class="col-xl-5 mobile_col_5">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="transaction_new_amount"
                          @change="(v) => (transaction_new_amount = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateAmountInfo($event)"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <!-- <b-modal ref="upload-modal" :title="modalTitle" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class">
            <b-form @submit="formSubmit">
                <div class="edit-form-box model_legend model_margin_top_zero">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <div class="form-map-box">
                                    <b-col lg="12" md="12">
                                    <b-form-group id="input-group-10" label="Remarks" class="required">
                                        <b-form-textarea id="input-10" maxlength="500" v-model="form.remarks" type="text" placeholder="" class="model_border_and_box_shadow"></b-form-textarea>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12" class="model_submit_field employer_multiple_radio">
                                        <b-form-group>
                                            <b-form-checkbox v-model = 'form.copyRemarksVal'  @input="clickToCopyRemark()"><strong>Internal Remark same as Remark</strong></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12">
                                    <b-form-group id="input-group-10" label="Internal Remarks" class="required">
                                        <b-form-textarea id="input-10" maxlength="500" v-model="form.internal_remarks" type="text" placeholder=""></b-form-textarea>
                                    </b-form-group>
                                    </b-col>
                                <div slot="footer" class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn">
                                    <b-button type="submit" variant="success" class="model_common_bt_success_color">Submit</b-button>
                                    <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-form>
        </b-modal> -->
    <b-modal
      ref="upload-modal"
      :title="modalTitle"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <b-form @submit="formSubmit">
        <div class="edit-form-box model_legend model_margin_top_zero">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col
                    lg="12"
                    md="12"
                    v-if="
                      currentTab == 'jobs' &&
                      (transaction_type_val == 'add_back' || transaction_type_val == 'deduct')
                    "
                  >
                    <b-form-group
                      id="input-group-3"
                      class="contact-fields mobile_margin_bottom_zero submit-field"
                    >
                      <span class="custom-dropdown w-100">
                        <b-form-select
                          disabled
                          v-model="transaction_type_val"
                          class="model_margin_bottom_zero"
                          @change="getTransactionSubType()"
                        >
                          <option value="add_back">Add Back</option>
                          <option value="deduct">Deduct</option>
                        </b-form-select>
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="12"
                    md="12"
                    v-if="
                      (transaction_type_val == 'add_back' || transaction_type_val == 'deduct') &&
                      sub_transactions.length > 0 &&
                      currentTab == 'jobs'
                    "
                  >
                    <b-form-group
                      id="input-group-3"
                      class="contact-fields mobile_margin_bottom_zero submit-field"
                    >
                      <span class="custom-dropdown w-100">
                        <b-form-select
                          v-model="transaction_sub_type_val"
                          class="model_margin_bottom_zero"
                        >
                          <option value="" disabled>Select Transaction Type</option>
                          <option :value="transaction.id" v-for="transaction in sub_transactions">
                            {{ transaction.title | capitalize }}
                          </option>
                        </b-form-select>
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12" v-if="currentTab == 'jobs'">
                    <b-form-group
                      id="input-group-3"
                      class="contact-fields mobile_margin_bottom_zero submit-field"
                    >
                      <span class="custom-dropdown w-100">
                        <b-form-select v-model="billing" class="model_margin_bottom_zero">
                          <option value="" disabled>Select Bill Type</option>
                          <option :value="bt.id" v-for="bt in billing_types">
                            {{ bt.name | capitalize }}
                          </option>
                        </b-form-select>
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-10" label="Remarks" class="required">
                      <b-form-textarea
                        id="input-10"
                        maxlength="500"
                        v-model="form.remarks"
                        type="text"
                        placeholder=""
                        class="model_border_and_box_shadow"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12" class="model_submit_field employer_multiple_radio">
                    <b-form-group>
                      <b-form-checkbox v-model="form.copyRemarksVal" @input="clickToCopyRemark()"
                        ><strong>Internal Remark same as Remark</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-10" label="Internal Remarks" class="required">
                      <b-form-textarea
                        id="input-10"
                        maxlength="500"
                        v-model="form.internal_remarks"
                        type="text"
                        placeholder=""
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <div
                    slot="footer"
                    class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn"
                  >
                    <b-button type="submit" variant="success" class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      ref="transaction-images-modal"
      title="Transaction Images"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" v-if="uploads.length > 0">
              <h3 style="margin-bottom: 14px"><b>Click to view images</b></h3>
              <div class="images_listing admin_image_listing">
                <viewer :images="uploads">
                  <img
                    v-for="docs in uploads.slice()"
                    track-by="id"
                    :src="docs"
                    :key="docs"
                    class="desktop_margin_bottom_zero"
                  />
                </viewer>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="transaction-modal"
      title="Transaction Details"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <template>
        <div class="transaction_details">
          <div class="transaction_data">
            <h4>Transaction Number :</h4>
            <span>{{ databyid.transaction_number | capitalize }} </span>
          </div>
          <div class="transaction_data">
            <h4>Amount :</h4>
            <span>S${{ databyid.amount }} </span>
          </div>
          <div class="transaction_data">
            <h4>Transaction Status :</h4>
            <span>{{ databyid.transaction_status | capitalize }} </span>
          </div>
          <div class="transaction_data">
            <h4>Mode :</h4>
            <span>{{ databyid.mode | capitalize }} </span>
          </div>
          <div class="transaction_data">
            <h4>Type :</h4>
            <span
              >{{
                capture_return != ""
                  ? capture_return.ProxyType == "MSISDN"
                    ? "Paynow"
                    : capture_return != ""
                    ? capture_return.ProxyType == "NRIC"
                      ? "Paynow (NRIC)"
                      : "Fast Payment"
                    : databyid.type
                  : databyid.type | capitalize | capitalize
              }}
            </span>
          </div>
          <div
            v-if="databyid.transaction_status == 'failed' && databyid.capture_return"
            class="transaction_data"
          >
            <h4>Reason :</h4>
            <span style="word-wrap: break-word">{{ databyid.capture_return }} </span>
          </div>
          <div class="transaction_data">
            <h4>Created At :</h4>
            <span>{{ databyid.created_at | date }} </span>
          </div>
          <div class="transaction_data">
            <h4>User Name :</h4>
            <span
              >{{ databyid.last_name | capitalize }} {{ databyid.first_name | capitalize }}</span
            >
          </div>
          <div class="transaction_data">
            <h4>Mobile Number :</h4>
            <span
              >{{ databyid.country_code | capitalize }}-{{
                databyid.mobile_number | capitalize
              }}</span
            >
          </div>
          <div
            v-if="
              databyid &&
              databyid.type == 'fast_payment' &&
              otherdatabyid &&
              otherdatabyid.account_number_string
            "
            class="transaction_data"
          >
            <h4>Bank Name :</h4>
            <span>{{ otherdatabyid.bank_name | capitalize }}</span>
          </div>
          <div
            v-if="
              databyid &&
              databyid.type == 'fast_payment' &&
              otherdatabyid &&
              otherdatabyid.account_number_string
            "
            class="transaction_data"
          >
            <h4>Account Holder Name :</h4>
            <span>{{ otherdatabyid.account_holder_name | capitalize }}</span>
          </div>
          <div
            v-if="
              databyid &&
              databyid.type == 'fast_payment' &&
              otherdatabyid &&
              otherdatabyid.account_number_string
            "
            class="transaction_data"
          >
            <h4>Account Number :</h4>
            <span>{{ otherdatabyid.account_number_string }}</span>
          </div>
          <div v-if="databyid && databyid.type == 'paynow'" class="transaction_data">
            <h4>
              PayNow
              {{
                capture_return != ""
                  ? capture_return.ProxyType == "MSISDN"
                    ? "Number"
                    : "NRIC"
                  : "Number"
              }}
              :
            </h4>
            <span>{{
              capture_return != ""
                ? capture_return.ProxyValue
                : databyid.country_code + "-" + databyid.mobile_number
            }}</span>
          </div>
          <div class="transaction_data">
            <h4>Remarks :</h4>
            <span>{{ databyid.remarks | capitalize }}</span>
          </div>
          <div class="transaction_data">
            <h4>Internal Remarks :</h4>
            <span>{{ databyid.internal_remarks | capitalize }}</span>
          </div>
        </div>
        <!-- <div class="pt-2 mt-3 text-md-left text-center"> -->
        <!-- <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button> -->
        <!-- </div> -->
      </template>
      <!-- </b-form> -->
    </b-modal>

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Multiselect from "vue-multiselect";
import permission from "../../../../../server/permission.js";
import Datepicker from "vuejs-datepicker";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import BIcon from 'bootstrap-vue'

export default {
  data() {
    return {
      transactionStatusOptions: [
        { value: '', label: 'Transaction Status' },
        { value: 'successfull', label: 'Successful' },
        { value: 'failed', label: 'Failed' },
      ],
      transactionModeOptions: [
        { value: '', label: 'Transaction Mode'},
        { value: 'credit', label: 'Credit' },
        { value: 'debit', label: 'Debit' },
      ],
      freelancerOptions: [
        { value: '', label: 'All Freelancer' },
        { value: 'ft_transaction', label: 'Full Time Freelancer' },
      ],
      fields: [
        {
          key: "transaction_number",
          label: "Transaction Number",
          sortable: true,
          thStyle: { width: "10%" },
        },
        // { key: 'transaction_id', label: 'Transaction Id', sortable: true },
        { key: "amount", label: "Amount", sortable: true, thStyle: { width: "8%" } },
        { key: "last_name", label: "User Name", sortable: true, thStyle: { width: "10%" } },
        { key: "job_unique_id", label: "Job ID", sortable: true, thStyle: { width: "10%" } },
        { key: "transaction_status", label: "Status", sortable: true, thStyle: { width: "12%" } },
        { key: "coins", label: "ELC Credits", sortable: true, thStyle: { width: "9%" } },
        { key: "type", label: "Type", sortable: true, thStyle: { width: "9%" } },
        { key: "billing_type", label: "Billing Type", sortable: true, thStyle: { width: "9%" } },
        { key: "mode", label: "Mode", sortable: true, thStyle: { width: "9%" } },
        { key: "remarks", label: "Remarks", sortable: true, thStyle: { width: "5%" } },
        {
          key: "internal_remarks",
          label: "Internal Remarks",
          sortable: true,
          thStyle: { width: "5%" },
        },
        {
          key: "created_by_user_last_name",
          label: "Created By",
          sortable: true,
          thStyle: { width: "5%" },
        },
        { key: "created_at", label: "Transaction Date", sortable: true, thStyle: { width: "5%" } },
        { key: "toggleAction", label: "Actions", thStyle: { width: "4%" } },
      ],
      fieldsCoin: [
        { key: "last_name", label: "Name", sortable: true, thStyle: { width: "10%" } },
        { key: "company_name", label: "Company Name", sortable: true, thStyle: { width: "10%" } },

        { key: "job_unique_id", label: "Job ID", sortable: true, thStyle: { width: "10%" } },
        {
          key: "transaction_number",
          label: "Transaction ID",
          sortable: true,
          thStyle: { width: "8%" },
        },
        { key: "coins", label: "ELC Credits", sortable: true, thStyle: { width: "8%" } },
        { key: "type", label: "Type", sortable: true, thStyle: { width: "8%" } },
        { key: "mode", label: "Mode", sortable: true, thStyle: { width: "8%" } },
        { key: "transaction_status", label: "Status", sortable: true, thStyle: { width: "8%" } },
        { key: "remarks", label: "Remarks", sortable: true, thStyle: { width: "8%" } },
        {
          key: "internal_remarks",
          label: "Internal Remarks",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "created_by_user_last_name",
          label: "Created By",
          sortable: true,
          thStyle: { width: "5%" },
        },
        { key: "created_at", label: "Transaction Date", sortable: true, thStyle: { width: "5%" } },
        { key: "toggleAction", label: "Actions", thStyle: { width: "4%" } },
      ],
      fieldsJobAds: [
        { key: "last_name", label: "Name", sortable: true, thStyle: { width: "8%" } },
        { key: "company_name", label: "Company Name", sortable: true, thStyle: { width: "8%" } },

        { key: "job_unique_id", label: "Job ID", sortable: true, thStyle: { width: "8%" } },
        {
          key: "transaction_number",
          label: "Transaction Number",
          sortable: true,
          thStyle: { width: "8%" },
        },
        {
          key: "transaction_id",
          label: "Transaction ID",
          sortable: true,
          thStyle: { width: "8%" },
        },
        { key: "invoice_id", label: "Invoice ID", sortable: true, thStyle: { width: "8%" } },

        { key: "coins", label: "Job Ads", sortable: true, thStyle: { width: "5%" } },
        { key: "type", label: "Type", sortable: true, thStyle: { width: "5%" } },
        { key: "mode", label: "Mode", sortable: true, thStyle: { width: "5%" } },
        { key: "transaction_status", label: "Status", sortable: true, thStyle: { width: "8%" } },
        { key: "gst", label: "GST", sortable: true, thStyle: { width: "7%" } },
        { key: "amount", label: "Amount With GST", sortable: true, thStyle: { width: "7%" } },
        { key: "remarks", label: "Remarks", sortable: true, thStyle: { width: "7%" } },
        {
          key: "internal_remarks",
          label: "Internal Remarks",
          sortable: true,
          thStyle: { width: "5%" },
        },
        {
          key: "created_by_user_last_name",
          label: "Created By",
          sortable: true,
          thStyle: { width: "7%" },
        },
        { key: "created_at", label: "Transaction Date", sortable: true, thStyle: { width: "4%" } },

        // { key: "toggleAction", label: "Actions" },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      searchKeywordJobs: "",
      searchKeywordJobAds: "",
      perPage: 25,
      itemsJobs: null,
      itemsJobAds: null,
      totalRowsJobs: 0,
      fromJobs: "",
      toJobs: "",
      currentPageJobs: 1,
      perPageJobs: 50,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },

      // dateRange: {
      //   startDate: "",
      //   endDate: "",
      // },
      // dateRange1: {
      //   startDate: "",
      //   endDate: "",
      // },
      pageOptions: [25, 50, 75, 100],
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      dateRange1: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },

      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "active",
      sortDesc: true,
      sortByJobs: null,
      sortDirectionJobs: "desc",
      filterJobs: "",
      sortDescJobs: true,
      webUrl: process.env.VUE_APP_URL,
      currentTab: "jobs",
      form: {
        rowsPerPage: 25,
        rowsPerPageJobs: 25,
        mode: "",
        type: "",
        status: "",
        tran_type: "",
        remarks: "",
        internal_remarks: "",
        copyRemarksVal: false,
        coins: "",
        sub_type: "",
      },
      modalTitle: "Add Remark",
      type: "add",
      transaction_id: "",
      update_coin_user_id: "",
      update_coin_transaction_id: "",
      transaction_prev_coins: "",
      transaction_coin_mode: "",
      transaction_new_coins: "",
      update_amount_user_id: "",
      update_amount_transaction_id: "",
      transaction_prev_amount: "",
      transaction_amount_mode: "",
      transaction_new_amount: "",
      company_id_filter: "",
      trans_type_filter: [],
      transactionTypesMultiselect: [
        { name: "Add Back", value: "add_back" },
        { name: "Top UP", value: "top_up" },
        { name: "Refund", value: "refund" },
        { name: "Deduct", value: "deduct" },
        { name: "ELC Credits", value: "elcoins" },
        { name: "Pending", value: "pending" },
        { name: "Featured ad", value: "featured_ad" },
        { name: "Job Completed", value: "job_completed" },
        { name: "Job Ad Posted", value: "job_ad_posted" },
      ],
      mode_filter: "",
      companies: [],
      start_date_filter: moment().subtract(1, "months").format("YYYY-MM-DD"),
      end_date_filter: moment().format("YYYY-MM-DD"),
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
      webUrlV1: process.env.VUE_APP_URL_V1,

      uploads: [],
      actions: "",
      actionsFreelancer: "",
      actionsEmployer: "",
      actionsManageFTJobs: "",
      actionsManageDPJobs: "",
      transactions: [],
      transaction_sub_types: [],

      transaction_type_val: "add_back",
      transaction_type: "add_back",

      sub_transactions: [],
      transaction_sub_type_val: "",
      billing_types: [],
      billing: "",
      freelancer_id: [],
      freelancerList: [],
      multiple: true,
      deduct_amount: "",
      disabledDateFromTransaction: {
        from: new Date(Date.now() + 8640000),
      },
      transaction_remarks: "",
      transaction_internal_remarks: "",
      transaction_type: "add_back",
      transaction_created_at: "",
      maxImage: 10,
      images: [],
      referred_to: "",
      databyid: [],
      otherdatabyid: [],
      capture_return: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    date: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },

  components: {
    Datepicker,
    DateRangePicker,
    Multiselect,
    VueCropper,
    VueUploadMultipleImage,
    BIcon,
  },
  methods: {
    cropImage() {
      this.$refs["crop-modal"].hide();
      this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImg = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    showImg() {
      this.$refs["crop-modal"].show();
    },
    onSubmitImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal"].hide();
      this.$refs["crop-modal"].refresh();
    },
    onImageChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.image = input.files[0];
        if (this.form.image.type != "image/jpeg" && this.form.image.type != "image/jpg") {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .jpg or .jpeg image.";
          this.form.selectedImage.image = "";
          this.showAlert();
        } else if (this.form.image.size > 5242880) {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.selectedImage.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedImage.name = this.form.image.name;
            this.showImg();
            event.target.value = "";
          };
        }
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      for (var i = 0; i < fileList.length; i++) {
        var stringLength = fileList[i].path.length - "data:image/png;base64,".length;
        var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
        var sizeInMB = sizeInBytes / 1000000;
        var name = fileList[i].name.split(".");
        if (
          name[name.length - 1] != "png" &&
          name[name.length - 1] != "jpg" &&
          name[name.length - 1] != "jpeg"
        ) {
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          fileList.splice(index, 1);
          this.showAlert();
        } else if (sizeInMB > 5) {
          this.error_message = "Image should be less than 5 MB.";
          fileList.splice(index, 1);
          this.showAlert();
        } else {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].path;
            fileList[i].url = url;
          }
        }
      }

      this.imagesDoc = fileList;
    },
    beforeRemove(index, done, fileList) {
      fileList.splice(index, 1);

      this.imagesDoc = fileList;
    },
    editImage(formData, index, fileList) {
      for (var i = 0; i < fileList.length; i++) {
        var stringLength = fileList[i].path.length - "data:image/png;base64,".length;
        var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
        var sizeInMB = sizeInBytes / 1000000;
        var name = fileList[i].name.split(".");
        if (
          name[name.length - 1] != "png" &&
          name[name.length - 1] != "jpg" &&
          name[name.length - 1] != "jpeg"
        ) {
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          fileList.splice(index, 1);
          this.showAlert();
        } else if (sizeInMB > 5) {
          this.error_message = "Image should be less than 5 MB.";
          fileList.splice(index, 1);
          this.showAlert();
        } else {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].path;
            fileList[i].url = url;
          }
        }
      }
      this.imagesDoc = fileList;
    },
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    customLabelTransType({ name }) {
      return `${name}`;
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        if (this.currentTab == "coins") {
          this.getCoinTransaction().then(() => {
            this.$refs.datatableCoin.refresh();
          });
        } else {
          this.getJobTransaction().then(() => {
            this.$refs.datatableAll.refresh();
          });
        }
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    downloadPdf(url) {
      window.open(url);
    },
    clearFilterChangeCoin() {
      this.filter = "";
      this.searchKeyword = "";
      this.status = "";
      // (this.dateRange1.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
      //   (this.dateRange1.endDate = moment().format("YYYY-MM-DD"));
      this.dateRange1 = { startDate: null, endDate: null };
      this.company_id_filter = "";
      this.trans_type_filter = [];
      this.mode_filter = "";

      this.$refs.datatableCoin.refresh();
      this.$refs.datatableJobAds.refresh();
    },
    clearFilterChangeJobs() {
      this.filterJobs = "";
      this.searchKeywordJobs = "";
      this.form.status = "";
      this.form.mode = "";
      this.form.type = "";
      this.form.tran_type = "";
      this.dateRange1 = { startDate: null, endDate: null };
      this.form.sub_type = "";

      // (this.dateRange.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
      //   (this.dateRange.endDate = moment().format("YYYY-MM-DD"));
      this.$refs.datatableAll.refresh();
    },

    clearFilterChangeJobs() {
      this.filterJobs = "";
      this.searchKeywordJobAds = "";
      this.form.status = "";
      this.form.mode = "";
      this.form.type = "";
      this.form.tran_type = "";
      this.dateRange1 = { startDate: null, endDate: null };
      this.form.sub_type = "";

      // (this.dateRange.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
      //   (this.dateRange.endDate = moment().format("YYYY-MM-DD"));
      this.$refs.datatableAll.refresh();
    },

    changeTab() {
      this.currentTab = "coins";
      // this.filter = '';
      // this.searchKeyword = '';
      // this.dateRange1.startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      // this.dateRange1.endDate = moment().format('YYYY-MM-DD');
      // this.company_id_filter = '';
      this.getCoinTransaction().then(() => {
        this.$refs.datatableCoin.refresh();
      });
    },
    changeTabJob() {
      const selectedStatusValue = this.form.status ? this.form.status : null; // Directly use form.status since it's a string
      this.currentTab = 'jobs';

      // Call your API or function that needs the selected status
      this.getJobTransaction(selectedStatusValue).then(() => {
        this.$refs.datatableAll.refresh();
      });
    },

    changeTabJobAds() {
      this.currentTab = "job_ads";

      this.getJobAdsTransaction().then(() => {
        this.$refs.datatableJobAds.refresh();
      });
    },
    openCoinModel(id, user_id, transaction_prev_coins, mode) {
      this.update_coin_user_id = user_id;
      this.update_coin_transaction_id = id;
      this.transaction_prev_coins = transaction_prev_coins ? transaction_prev_coins : 0;
      this.transaction_coin_mode = mode;
      this.transaction_new_coins = "";
      this.$refs["coin-modal"].show();
    },

    openAmountModel(id, user_id, transaction_prev_amount, mode) {
      this.update_amount_user_id = user_id;
      this.update_amount_transaction_id = id;
      this.transaction_prev_amount = transaction_prev_amount ? transaction_prev_amount : 0;
      this.transaction_amount_mode = mode;
      this.transaction_new_amount = "";
      this.$refs["amount-modal"].show();
    },
    getEmployers() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/employer-job-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.companies = this.$store.getters.getResults.data;
          }
        });
    },
    getCoinTransaction() {
  return this.$store
    .dispatch(POST_API, {
      data: {
        page: this.currentPage,
        keyword: this.searchKeyword,
        id: this.user_id,
        type: this.currentTab,
        trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
        mode_filter: this.mode_filter ? this.mode_filter.value : "",
        start_date: this.dateRange1.startDate
          ? moment(this.dateRange1.startDate).format("YYYY-MM-DD")
          : "",
        end_date: this.dateRange1.endDate
          ? moment(this.dateRange1.endDate).format("YYYY-MM-DD")
          : "",
        company_id: this.company_id_filter.company_id ? this.company_id_filter.company_id : "",
        rowsPerPage: this.form.rowsPerPage,
        sortBy: this.sortBy ? this.sortBy : "transactions.created_at",
        sortDirection: this.sortDesc ? "desc" : "asc",
        dateRange: this.dateRange1,
      },
      api: "/api/all-transaction-list-admin",
    })
    .then(() => {
      if (this.$store.getters.containsErrors) {
        this.error_message = this.$store.getters.getErrors;
        return [];
      } else {
        this.totalRows = this.$store.getters.getResults.pager.totalItems;
        this.perPage = this.$store.getters.getResults.pager.pageSize;
        this.from = this.$store.getters.getResults.pager.from;
        this.to = this.$store.getters.getResults.pager.to;
        if(this.currentTab=="job_ads"){
          this.items=this.$store.getters.getResults.pageOfItems;
          return this.items
        }else{
          this.items = this.$store.getters.getResults.pageOfItems;
        console.log(this.items)
        // Grouping by job_id (you can modify grouping logic here as needed)
        const groupedTransactions = this.items.reduce((groups, transaction) => {
          const jobId = transaction.job_id || 'no_job_id'; // Group by job_id, or 'no_job_id' for items without job_id
          if (!groups[jobId]) {
            groups[jobId] = {
              job_id: jobId,
              transactions: [],
              isExpandable: !!transaction.job_id,
            };
          }
          groups[jobId].transactions.push(transaction);
          return groups;
        }, {});
        console.log(groupedTransactions)
        this.groupedItems = Object.values(groupedTransactions); // Store grouped items for display
        console.log(this.groupedItems)
        return this.groupedItems;
        }
        
        
        
        
      }
    });
}
,

    getJobTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageJobs,
            keyword: this.filterJobs,
            id: this.user_id,
            type: this.currentTab,
            tran_type: this.form.tran_type ? this.form.tran_type.value : "",
            transaction_type: this.form.type,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            mode: this.form.mode ? this.form.mode.value : "",
            status: this.form.status ? this.form.status.value: "",
            dateRange: this.dateRange,
            rowsPerPage: this.form.rowsPerPageJobs,
            sortBy: this.sortByJobs ? this.sortByJobs : "transactions.created_at",
            sortDirection: this.sortDescJobs ? "desc" : "asc",
            sub_type: this.form.sub_type,
          },
          api: "/api/all-transaction-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsJobs = this.$store.getters.getResults.pageOfItems;
            this.totalRowsJobs = this.$store.getters.getResults.pager.totalItems;
            this.perPageJobs = this.$store.getters.getResults.pager.pageSize;
            this.fromJobs = this.$store.getters.getResults.pager.from;
            this.toJobs = this.$store.getters.getResults.pager.to;
            return this.itemsJobs;
          }
        });
    },

    getJobAdsTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageJobs,
            keyword: this.searchKeywordJobAds,
            id: this.user_id,
            type: this.currentTab,
            tran_type: this.form.tran_type,
            transaction_type: this.form.type,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            mode: this.form.mode,
            status: this.form.status,
            dateRange: this.dateRange,
            rowsPerPage: this.form.rowsPerPageJobs,
            sortBy: this.sortByJobs ? this.sortByJobs : "transactions.created_at",
            sortDirection: this.sortDescJobs ? "desc" : "asc",
            sub_type: this.form.sub_type,
          },
          api: "/api/all-transaction-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsJobAds = this.$store.getters.getResults.pageOfItems;
            this.totalRowsJobs = this.$store.getters.getResults.pager.totalItems;
            this.perPageJobs = this.$store.getters.getResults.pager.pageSize;
            this.fromJobs = this.$store.getters.getResults.pager.from;
            this.toJobs = this.$store.getters.getResults.pager.to;
            console.log(this.itemsJobAds)
            return this.itemsJobAds;
          }
        });
    },
    clickToAdd(typeVal) {
      this.getTransactionSubType();
      this.getBillingTypes();
      this.form.copyRemarksVal = false;
      this.$refs["upload-modal"].show();
      this.modalTitle = typeVal == "add" ? "Add Remark" : "Update Remark";
      this.type = typeVal == "add" ? "Add" : "Update";
      this.transaction_sub_type_val = "";
      this.billing = "";
    },
    getTransactionSubType() {
      this.$store
        .dispatch(POST_API, {
          data: {
            transaction_type: this.transaction_type_val,
          },
          api: "/api/transaction-sub-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.sub_transactions = this.$store.getters.getResults.data;
          }
        });
    },
    getBillingTypes() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/billing-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.billing_types = this.$store.getters.getResults.data;
          }
        });
    },
    clickToCopyRemark() {
      if (this.form.copyRemarksVal) {
        this.form.internal_remarks = this.form.remarks;
      } else {
        this.form.internal_remarks = this.form.internal_remarks;
      }
    },
    hideModel() {
      this.$refs["upload-modal"].hide();
      this.form.remarks = "";
      this.form.internal_remarks = "";
      this.form.copyRemarksVal = false;
      this.$refs["coin-modal"].hide();
      this.update_coin_user_id = "";
      this.update_coin_transaction_id = "";
      this.transaction_prev_coins = "";
      this.transaction_coin_mode = "";
      this.transaction_new_coins = "";
      this.$refs["amount-modal"].hide();
      this.update_amount_user_id = "";
      this.update_amount_transaction_id = "";
      this.transaction_prev_amount = "";
      this.transaction_amount_mode = "";
      this.transaction_new_amount = "";
      this.$refs["transaction-images-modal"].hide();
      this.uploads = [];
      this.$refs["multiple-transaction-modal"].hide();
      this.$refs["transaction-modal"].hide();
    },
    getTransactionImages(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            transaction_id: id,
          },
          api: "/api/transaction-images",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            var img = this.$store.getters.getResults.items;
            var arr = [];

            if (img.length > 0) {
              var j = 0;
              for (var i = 0; i < img.length; i++) {
                this.uploads.push(img[j].url);
                // this.type[j] = this.$store.getters.getResults.document[i].type;
                j++;
                // console.log(img.length , this.uploads.length, 'lenght')
                if (img.length == this.uploads.length) {
                  this.$refs["transaction-images-modal"].show();
                }
              }
            }
            console.log(this.uploads, "ll");
            // if(this.images.length > 0){
            //     this.$refs['transaction-images-modal'].show();
            // }
          }
        });
    },
    submitUpdateCoinInfo(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            currentTab: this.currentTab,
            user_id: this.update_coin_user_id,
            transaction_id: this.update_coin_transaction_id,
            transaction_prev_coins: this.transaction_prev_coins,
            transaction_mode: this.transaction_coin_mode,
            transaction_new_coins: this.transaction_new_coins,
          },
          api: "/api/admin-transaction-coins-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // if(this.type == 'update'){
            //     var message='Remark Updated successfully';
            // }else{
            //     var message='Remark Added successfully';
            // }
            var message = "ELC Credits Updated successfully";
            this.hideModel();
            this.showSuccessAlert(message);
          }
        });
    },
    deleteCoinsAddBackDeductTransaction(id, user_id, coins, mode, type, created_by) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  user_id: user_id,
                  mode: mode,
                  type: type,
                  currentTab: this.currentTab,
                  updated_by: this.user_id,
                  created_by: created_by,
                  transaction_prev_coins: coins ? coins : 0,
                  transaction_new_coins: 0,
                },
                api: "/api/admin-transaction-adddbackdeduct-coins-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  return [];
                } else {
                  var message = "Transaction Deleted successfully";
                  this.hideModel();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    submitUpdateAmountInfo(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            currentTab: this.currentTab,
            user_id: this.update_amount_user_id,
            transaction_id: this.update_amount_transaction_id,
            transaction_prev_amount: this.transaction_prev_amount,
            transaction_mode: this.transaction_amount_mode,
            transaction_new_amount: this.transaction_new_amount,
          },
          api: "/api/admin-transaction-amount-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // if(this.type == 'update'){
            //     var message='Remark Updated successfully';
            // }else{
            //     var message='Remark Added successfully';
            // }
            var message = "Amount Updated successfully";
            this.hideModel();
            this.showSuccessAlert(message);
          }
        });
    },
    deleteJobsAddBackDeductData(id, user_id, amount, mode, type, created_by) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  user_id: user_id,
                  amount: amount,
                  mode: mode,
                  type: type,
                  currentTab: this.currentTab,
                  updated_by: this.user_id,
                  created_by: created_by,
                },
                api: "/api/admin-transaction-adddbackdeduct-jobs-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  return [];
                } else {
                  var message = "Transaction Deleted successfully";
                  this.hideModel();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    formSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.transaction_id,
            remarks: this.form.remarks,
            internal_remarks: this.form.internal_remarks,
            currentTab: this.currentTab,
            billing_type: this.billing ? this.billing : null,
            sub_type: this.transaction_sub_type_val ? this.transaction_sub_type_val : null,
          },
          api: "/api/admin-transaction-remarks-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.type == "update") {
              var message = "Remark Updated successfully";
            } else {
              var message = "Remark Added successfully";
            }
            this.hideModel();
            this.showSuccessAlert(message);
          }
        });
    },

    updateRemark(id, addUpdate, transaction_type) {
      this.transaction_type_val = transaction_type;

      this.clickToAdd(addUpdate);
      this.type = addUpdate;
      this.transaction_id = id;
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
            currentTab: this.currentTab,
          },
          api: "/api/admin-transaction-remarks-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.form.remarks = this.$store.getters.getResults.data.remarks;
            this.form.internal_remarks = this.$store.getters.getResults.data.internal_remarks;
            this.form.coins = this.$store.getters.getResults.data.coins;
            this.billing = this.$store.getters.getResults.data.billing_type
              ? this.$store.getters.getResults.data.billing_type
              : "";
            this.transaction_sub_type_val = this.$store.getters.getResults.data.sub_type
              ? this.$store.getters.getResults.data.sub_type
              : "";
          }
        });
    },

    exportCoinTransactions() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRange1.startDate
        ? moment(this.dateRange1.startDate).format("YYYY-MM-DD")
        : "null";
      var end_date = this.dateRange1.endDate
        ? moment(this.dateRange1.endDate).format("YYYY-MM-DD")
        : "null";
      var sortBy = this.sortBy ? this.sortBy : "transactions.created_at";
      var sortDirection = this.sortDesc ? "desc" : "asc";
      var type = this.currentTab ? this.currentTab : "coins";
      var company_id_filter = this.company_id_filter.company_id
        ? this.company_id_filter.company_id
        : null;
      // var trans_type_filter = this.trans_type_filter.length > 0 ? this.trans_type_filter : null;
      var trans_type_filter = null;

      var mode_filter = this.mode_filter ? this.mode_filter : null;

      // var keyword = this.filter ? this.filter : null;
      var keyword = this.searchKeyword ? this.searchKeyword : null;

      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/all-transaction-list-admin-export/" +
        keyword +
        "/" +
        type +
        "/" +
        company_id_filter +
        "/" +
        trans_type_filter +
        "/" +
        mode_filter +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },

    openMultipleTransactionModel(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.getTransactionSubType();
      this.getBillingTypes();
      this.getFreelancerList();
      this.transaction_user_id = id;
      this.imagesDoc = [];
      this.transaction_created_at = "";
      this.$refs["multiple-transaction-modal"].show();
      this.transaction_sub_type = "";
      this.sub_transactions = [];
      this.billing = "";
    },

    customFormatterOne(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    customFormatterTwo(date) {
      return moment(date).format("MMM,YYYY");
    },
    disabledFromDate2() {
      var d = new Date();
      return {
        to: new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1),
      };
    },

    getFreelancerList() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/freelancer-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.freelancerList = this.$store.getters.getResults.data;
          }
        });
    },

    getTransactionSubType() {
      this.$store
        .dispatch(POST_API, {
          data: {
            transaction_type: this.transaction_type,
          },
          api: "/api/transaction-sub-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.sub_transactions = this.$store.getters.getResults.data;
          }
        });
    },

    getBillingTypes() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/billing-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.billing_types = this.$store.getters.getResults.data;
          }
        });
    },

    deduct_multiple_transaction() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.transaction_user_id,
            fl_id: this.freelancer_id,
            transaction_type: this.transaction_type,
            transaction_sub_type: this.transaction_sub_type,
            billing_type: this.billing,
            amount: this.deduct_amount,
            transaction_remarks: this.transaction_remarks,
            transaction_internal_remarks: this.transaction_internal_remarks,
            loggedin_user_id: this.form.user_id,
            transaction_created_at: this.transaction_created_at
              ? moment(this.transaction_created_at).tz("Singapore").format("YYYY-MM-DD HH:mm:ss")
              : null,
            documents: this.imagesDoc,
          },
          api: "/api/deduct-multiple-transaction",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.hideModel();

            this.success_message = this.$store.getters.getResults.message;

            this.showSuccessAlert(this.success_message);

            this.payslip_generate_for_user = "";
            this.deduct_amount = "";
            this.transaction_remarks = "";
            this.transaction_internal_remarks = "";
            this.transaction_type = "add_back";
          }
        });
    },

    exportJobsTransactions() {
      var base_url = this.webUrlV1;
      var user_id = null;
      // var status = this.form.status != "" ? this.form.status : 0;
      // var mode = this.form.mode != "" ? this.form.mode : 0;
      var status = this.form.status ? this.form.status.value : 0;
      var mode = this.form.mode ? this.form.mode.value : 0;
      var sub_type = this.form.sub_type ? this.form.sub_type : 0;

      var date = this.form.date != "" ? moment(this.form.date).format("YYYY-MM-DD") : "null";
      //commented on 01-07-2024
      // var start_date = this.dateRange.startDate
      //   ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
      //   : "null";
      // var end_date = this.dateRange.endDate
      //   ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
      //   : "null";

      //Added on 01-07-2024
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "null";
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "null";
      // var filter = this.filter != '' && this.filter != null ? this.filter : 0;
      var filter = this.searchKeywordJobs != "" && this.searchKeywordJobs != null ? this.searchKeywordJobs : 0;
      var type = this.form.type != "" ? this.form.type : 0;
      console.log(filter)
      console.log(this.searchKeyword)
      var user_type = "normal";
      var export_url =
        "/api/export-transactions-v1-jobs/" +
        status +
        "/" +
        mode +
        "/" +
        user_type +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        filter +
        "/" +
        type +
        "/" +
        sub_type;
      // var export_url =
      //   "/api/export-ptuser-transactions-v1/" +
      //   status +
      //   "/" +
      //   mode +
      //   "/" +
      //   date +
      //   "/" +
      //   filter +
      //   "/" +
      //   start_date +
      //   "/" +
      //   end_date +
      //   "/" +
      //   type +
      //   "/" +
      //   user_id;

      // this.$router.push(base_url+export_url)
      // window.location.href = base_url+export_url
      window.open(base_url + export_url, "_blank");
    },
    exportJobsTransactionsOld() {
      var base_url = this.webServerUrl;
      var page = this.currentPageJobs;  
      var rowsPerPage = this.form.rowsPerPageJobs;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "";
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "";
      var sortBy = this.sortByJobs ? this.sortByJobs : "transactions.created_at";
      var sortDirection = this.sortDescJobs ? "desc" : "asc";
      var type = this.currentTab ? this.currentTab : "coins";
      var status = this.form.status ? this.form.status : null;
      var transaction_type = this.form.type ? this.form.type : null;
      var tran_type = this.form.tran_type ? this.form.tran_type : null;
      var sub_type = this.form.sub_type ? this.form.sub_type : null;

      var mode_filter = this.form.mode ? this.form.mode : null;
      // var keyword = this.filter ? this.filter : null;
      var keyword = this.searchKeywordJobs ? this.searchKeywordJobs : null;

      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/all-transaction-list-admin-jobs-export/" +
        keyword +
        "/" +
        type +
        "/" +
        status +
        "/" +
        mode_filter +
        "/" +
        transaction_type +
        "/" +
        sub_type +
        "/" +
        tran_type +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      console.log(export_url);
      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    exportJobsTransactionsWithCalculation() {
      var base_url = this.webServerUrl;
      var page = this.currentPageJobs;  
      var rowsPerPage = this.form.rowsPerPageJobs;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "null";
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "null";
      var sortBy = this.sortByJobs ? this.sortByJobs : "transactions.created_at";
      var sortDirection = this.sortDescJobs ? "asc" : "asc";
      var type = this.currentTab ? this.currentTab : "coins";
      var status = this.form.status ? this.form.status : null;
      var transaction_type = this.form.type ? this.form.type : null;
      var tran_type = this.form.tran_type ? this.form.tran_type : null;
      var sub_type = this.form.sub_type ? this.form.sub_type : null;

      var mode_filter = this.form.mode ? this.form.mode : null;
      // var keyword = this.filter ? this.filter : null;
      var keyword = this.searchKeywordJobs ? encodeURIComponent(this.searchKeywordJobs)  : null;

      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/all-transaction-list-admin-jobs-with-calculation-export/" +
        keyword +
        "/" +
        type +
        "/" +
        status +
        "/" +
        mode_filter +
        "/" +
        transaction_type +
        "/" +
        sub_type +
        "/" +
        tran_type +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      console.log(export_url);
      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    openImagesModel(id) {
      this.getTransactionImages(id);
      this.uploads = [];
    },

    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Transactions");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
          this.actionsEmployer = permission.getPermissionStaff(menu, "Companies");
          this.actionsManageFTJobs = permission.getPermissionStaff(menu, "Job Ads");
          this.actionsManageDPJobs = permission.getPermissionStaff(menu, "DP Jobs Admin");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showEmployer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsEmployer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageFTJobs(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageFTJobs.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageDPJobs(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    getTransactions(type) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            type: type,
            transaction_types: this.transaction_types,
          },
          api: type == "all" ? "/api/transaction-types-master" : "/api/transaction-sub-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (type == "all") {
              this.transactions = this.$store.getters.getResults.data;
              return this.transactions;
            } else {
              this.transaction_sub_types = this.$store.getters.getResults.data;
              return this.transaction_sub_types;
            }
          }
        });
    },
    openModel(id) {
      this.$refs["transaction-modal"].show();
      this.getTransactionById(id);
    },
    getTransactionById(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/transactionbyid",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.databyid = this.$store.getters.getResults.data;
            var json =
              this.databyid.capture_return != "" ? JSON.parse(this.databyid.capture_return) : "";
            this.capture_return = json != "" ? (json.Result != "" ? json.Result : "") : "";
            console.log(this.capture_return, "capture_return");
            this.otherdatabyid = this.$store.getters.getResults.other_data;
          }
        });
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.designation =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
    this.getEmployers();
    this.permissionStaff();
    this.getTransactions("all");
    this.getTransactions("sub_types");
    this.getFreelancerList();
  },
};
</script>
<style scoped>
.expandable-table {
  position: relative;
}

.expand-button {
  background: none;
  border: none;
  color: #4c8bf5;
  padding: 0;
  font-size: 1.2em;
}

.expand-button:focus {
  outline: none;
  box-shadow: none;
}

.expanded-card {
  width: 100% !important;
  display: block !important; /* Make sure it takes up the entire row width */
}

.table-responsive td {
  display: table-cell; /* Make sure table cells behave properly */
  width: 100%;
}
</style>