<template>
    <div class="animated fadeIn">
        <div class="create-new-job-section email-template-section">
           
            <div id="breadcrumb-v2" class="mb-1">
                    <div class="d-flex justify-content-start breadcrumb-wrapper">
                        <b-breadcrumb
                        :items="breadCrumb()"
                        />
                    </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 edit_genneral_stng_dsk">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="6" md="6">
                                    <!-- <b-form-group id="input-group-9" label="Name" class="required"> -->
                                    <div class="submit-field"> 
                                        <h5>Name</h5>
                                        <b-form-input id="input-9" maxlength="20" v-model="item.user.full_name" type="text" placeholder="" disabled class="with-border"></b-form-input>
                                    </div>
                                    <!-- </b-form-group> -->
                                </b-col>
                                <b-col lg="6" md="6">
                                    <!-- <b-form-group id="input-group-9" label="Payment Mode" class="required"> -->
                                    <div class="submit-field"> 
                                        <h5>Payment Mode</h5>
                                        <b-form-input id="input-9" maxlength="20" value="Pay Now" type="text" placeholder="" v-if="item.transaction_type == 'paynow'" disabled class="with-border"></b-form-input>
    
                                        <b-form-input id="input-9" maxlength="20" value="Fast Payment" type="text" placeholder="" v-else disabled class="with-border"></b-form-input>
                                    </div>
                                    <!-- </b-form-group> -->
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" md="6">
                                    <!-- <b-form-group id="input-group-9" label="Amount" class="required"> -->
                                    <div class="submit-field"> 
                                        <h5>Amount</h5>
                                        <b-form-input id="input-9" maxlength="20" v-model="item.amount" type="text" placeholder="" class="with-border"></b-form-input>
                                    </div>
                                    <!-- </b-form-group> -->
                                </b-col>
                                 <b-col lg="6" md="6">
                                    <!-- <b-form-group id="input-group-9" label="Fees" class="required"> -->
                                    <div class="submit-field"> 
                                        <h5>Fees</h5>
                                        <b-form-input id="input-9" maxlength="20" v-model="item.payout_request_charges" type="text" placeholder="" @keyup="onPayoutChange()" class="with-border"></b-form-input>
                                    </div>
                                    <!-- </b-form-group> -->
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" md="6" v-if="prod != 'yes'">
                                    <!-- <b-form-group id="input-group-9" label="Transaction ID" class="required"> -->
                                    <div class="submit-field"> 
                                        <h5>Transaction ID</h5>
                                        <b-form-input id="input-9" maxlength="20" v-model="transaction_id" type="text" placeholder="" class="with-border"></b-form-input>
                                    </div>
                                    <!-- </b-form-group> -->
                                </b-col>
                                <b-col lg="6" md="6"> 
                                    <!-- <b-form-group id="input-group-9" label="Status" class="required"> -->
                                     <div class="submit-field"> 
                                        <h5>Status</h5>
                                        <b-form-input id="input-9" maxlength="20" value="Pending" type="text" placeholder="" v-if="item.status == 'pending'" disabled class="with-border"></b-form-input>
    
                                        <b-form-input id="input-9" maxlength="20" value="Approved" type="text" placeholder="" v-else disabled class="with-border"></b-form-input>
                                     </div>
                                    <!-- </b-form-group> -->
                                </b-col>
                            </b-row>
                        </div>
                        <div slot="footer" class="form-btn common_crop_reset_btn">
                            <b-button type="submit" variant="success" class="desktop_margin_left_zero model_common_bt_success_color ">Pay Manually</b-button>
                            <b-button @click="$router.go(-1)" variant="light" style="background-color:black" class="model_common_bt_lite_color ">Cancel</b-button>
                        </div>
                    </div>
                </b-form>
               
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API,GET_API } from "../../../store/actions.type";

export default {
    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                gender:'',
                country_code: '+65',
                mobile_number: '',
                email: '',
                user_type_id: '',
                user_type_id_val:'',
                address: '',
                profile_image:'',
                company_id:'',
                selectedImage:{
                    name:'',
                    image:'',
                },
            },
            items: null,
            // id: Vue.prototype.decrypt(this.$route.params.id),
            id: atob(this.$route.params.id),
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            delta: undefined,
            cropImg: '',
            data: null,
            webUrl: process.env.VUE_APP_URL,
            token:'',
            auth_url:'',
            transaction_id:'',
            prod:'',
            disable:true,
            user_type_id:'',
            actions:'',
            ini_amount:0,
            deducted_amount:0,
            user_id:'',
            item:'',
        }
    },
    
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'payout-list'},
                text: 'Payout',
            },{
                to:null,
                text: 'View'
            }];
            return item;
        },
        onPayoutChange(){

            var amt = 0;
                    
            amt = (parseFloat(this.item.payout_request_charges) == 0
            ?  parseFloat(this.ini_amount) 
            : (parseFloat(this.ini_amount) - parseFloat(this.item.payout_request_charges)) 
            );

            amt = isNaN(amt) ? this.ini_amount : parseFloat(amt).toFixed(2);
            // console.log(amt,this.item.payout_request_charges,this.deducted_amount,this.ini_amount,this.item.amount  );

            this.item.amount = amt > this.ini_amount ? this.ini_amount : parseFloat(amt).toFixed(2);

        },
        getPayoutData() {
            // console.log('static-page');
            return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                    },
                    api: '/api/payout-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.item = this.$store.getters.getResults.data;
                        this.prod = this.$store.getters.getResults.prod;
                        this.ini_amount = this.item.amount
                        this.deducted_amount = parseFloat(this.item.amount) - parseFloat(this.item.payout_request_charges)

                        this.item.amount = this.deducted_amount;
                        // console.log(this.item);
                        return this.item;
                    }
                });
        },
        
        
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        transaction_id: this.transaction_id,
                        token: this.token ,
                        item:this.item,
                        approved_by:this.user_id,
                        payout_request_charges : ((this.item.payout_request_charges == '') ? 0 : parseFloat(this.item.payout_request_charges).toFixed(2) )

                    },
                    api: "/api/payout-payment",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        // this.$router.push({ name: 'Payout' });
                    } else {
                        // Bus.$emit('refresh-support');
                        this.showSuccessAlert();
                    }
                });
        },
        
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Transaction Successfull.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'payout-list' });
            });
        },
        
        
        // disableAmount(){
        //     console.log(this.show('pay'), 'lalal');
        //     this.disable = (this.user_type_id == 1 || this.show('pay')) ? false :true;
        // },
        authOCBCUrl(){
            return this.$store.dispatch(POST_API, {
                   data:{
                   },
                   api: '/api/get-ocbc-auth-url'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.auth_url = this.$store.getters.getResults.data;
                    }
            });
        },
        getAuthTokenDB(){
            return this.$store.dispatch(POST_API, {
                   data:{
                   },
                   api: '/api/get-ocbc-token-payout'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.token = this.$store.getters.getResults.data ? this.$store.getters.getResults.data.auth_token : '';
                    }
            });
        },
        generateToken(){
            generateAccessToken(this.auth_url);
        },
    },
    mounted() {
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
        this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');

        this.getPayoutData();
        this.authOCBCUrl();
        this.getAuthTokenDB();
        // this.disableAmount();
    },
};

</script>
