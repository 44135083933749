<template>

    <div>

        <!-- Dashboard Headline -->

        <!-- Page Content
        ================================================== -->
        <div class="">
            <div class="dashboard-headline">
            <h1>Edit Profile(Employer)</h1>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark nav_on_emp">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Profile</li>
                </ul>
            </nav>
        </div>
        <div class="notification notice" v-if="status == 'pending'">
            <p><span class="icon-feather-info"></span> Account Status {{status}}</p>
            <a class="close"></a>
        </div>
        <div class="notification warning" v-if="status == 'incomplete'">
            <p><span class="icon-feather-info"></span> Account Status {{status}}. To complete profile:</p>
            <ul class="notification_box">
                <li> 1. Verify Email Address</li>
            </ul>
            <a class="close"></a>
        </div>
           <!-- <div class="row common_row second_indiv_step_wrap"> -->
            <div class="row  second_indiv_step_wrap">

            <!-- <b-form method="post" id="register-account-form" class="emp_frm_mobile nw_frm"> -->
            <b-form method="post" id="register-account-form" class="nw_frm">
                <!-- <p id="error"></p> -->
				<!-- Dashboard Box -->
				 <div class="col-xl-12">
                        <div class="dashboard-box margin-top-0">

                            <!-- Headline -->
                            <div class="headline">
                                <h3><i class="icon-material-outline-face"></i> My Account ({{form.user_type_id_val}})</h3>
                            </div>

                            <!-- <div class="content with-padding padding-bottom-0"> -->
                             <div class="content with-padding">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <div class="submit-field error_submit_field">
                                                    <h5>First Name <span class="required_star">*</span></h5>
                                                    <input type="text" v-model="form.first_name" class="with-border required-field" id="first_name">
                                                    <input type="hidden" v-model="form.user_id" class="with-border" >
                                                </div>
                                                  <!-- <div class="show_span"> -->
                                                     <span class="error_x_white">Please enter First Name </span>
                                                  <!-- </div> -->
                                            </div>

                                            <div class="col-xl-4">
                                                <div class="submit-field error_submit_field">
                                                    <h5>Last Name <span class="required_star">*</span></h5>
                                                    <input type="text" v-model="form.last_name" class="with-border required-field" id="last_name">
                                                </div>
                                                <!-- <div class="show_span"> -->
                                                     <span class="error_x_white">Please enter Last Name </span>
                                                  <!-- </div> -->
                                            </div>

                                            <!-- <div class="col-xl-4">
                                                <div class="submit-field">
                                                    <h5>Account Type </h5>
                                                    <input type="text" v-model="form.user_type_id_val" readonly="readonly" class="with-border form-control">
                                                </div>
                                            </div> -->

                                            <div class="hundered_width">
                                                <b-input-group>
                                                    <div class="col-xl-4 rm">
                                                        <div class="submit-field mobile_view_numbr mobile_email  mobile_email big_screen_bottom_28 web_bottom_zero mobile_margin_bottom_28" >
                                                            <h5>Mobile Number <span class="required_star">*</span>
                                                                <!-- <span class="verify_badge" v-if="this.form.mobile_verified_check == true"><i class="fa fa-check"></i>Verified</span> -->
                                                                <div class="verified-badge-with-title custom_verify_badge_em" v-if="this.form.mobile_verified_check == true">Verified</div>
                                                            </h5>
                                                            <div class="input-group mb-3 desktop_margin_bottom_zero">
                                                            <input type="text" v-model="form.country_code" class="with-border  form-control required-field country_code_txt" readonly="readonly">
                                                            <input type="text" v-model="form.mobile" class="with-border  form-control required-field" id="contact" readonly="readonly">
                                                            <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false">
                                                                <button @click="sendOTP" class="btn btn-outline-primary verify_btn_jb" type="button">Send OTP</button>
                                                            </div> -->
                                                            </div>
                                                        </div>
                                                         <!-- <div class="show_span">
                                                            <span class="error_x_white">Please enter Contact Number </span>
                                                        </div> -->
                                                    </div>
                                                    <div class="col-xl-4" :class="this.form.email_verify == 'no' && this.showVerifyEmail ? 'email_profile_col' : 'col-xl-4'">
                                                <div class="submit-field mobile_view_numbr mobile_email error_submit_field mobile_margin_bottom_28 big_screen_bottom_28 desktop_margin_bottom_zero" >
                                                    <h5>Email Address <span class="required_star">*</span>
                                                        <!-- <span class="verify_badge" v-if="this.form.email_verify == 'yes'"><i class="fa fa-check"></i>Verified</span> -->
                                                        <div class="verified-badge-with-title custom_verify_badge_em" v-if="this.form.email_verify == 'yes'">Verified</div>
                                                    </h5>
                                                    <div class="input-group mb-3 desktop_margin_bottom_zero error_submit_field">
                                                    <input type="text" v-model="form.email" class="with-border  form-control required-field" id="email" disable>
                                                    <div class="input-group-append" v-if="this.form.email_verify == 'no' && this.showVerifyEmail" >
                                                        <button class="btn btn-outline-primary verify_btn_jb otp_btn_hgt" @click="emailVerify" type="button">Verify Email</button>
                                                    </div>
                                                        <!-- <h5 class="web_bottom_zero" v-if="countDownEmail > 0">Didn't receive email? Resend email in <span style="color: #4c8bf5 !important;"> {{ countDownEmail }} seconds</span></h5> -->
                                                    </div>
                                                    <h5 class="web_bottom_zero margin_top_13" v-if="countDownEmail > 0">Didn't receive email? Resend email in <span style="color: #4c8bf5 !important;"> {{ countDownEmail }} seconds</span></h5>
                                                    <span class="error_x_white desktop_margin_bottom_zero">Please enter Email </span>
                                                </div>
                                                 <!-- <div class="show_span"> -->
                                                    <!-- <span class="error_x_white">Please enter Email </span> -->
                                                <!-- </div> -->
                                                    </div>
                                                    <div class="col-xl-4" :class="this.form.email_verify == 'no' && this.showVerifyEmail ? 'gender_profile_col' : 'col-xl-4'">
                                                <div class="submit-field desktop_margin_bottom_zero error_submit_field">
                                                        <h5>Gender <span class="required_star">*</span></h5>
                                                            <b-form-select data-size="7" v-model="form.gender" class="custom-select web_bottom_zero" id="gender">
                                                                <option value="" disabled>Select</option>
                                                                <option value="female">Female</option>
                                                                <option value="male">Male</option>
                                                            </b-form-select>
                                                    </div>
                                                <!-- <div class="show_span"> -->
                                                    <span class="error_x_white desktop_margin_bottom_zero">Please enter Gender </span>
                                                <!-- </div> -->
                                                    </div>
                                                </b-input-group>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div class="col-xl-12">
                    <a @click="submit" class="button ripple-effect big margin-top-30 employee_btn common_vertical_align_bottom">Save Changes</a>
                </div>
				<!-- Button -->
            </b-form>
             <b-modal ref="verify-mobile-modal" no-close-on-backdrop hide-footer content-class="mobile_verification_model">
                <b-form class="">
                    <div class="edit-form-box web_top_zero">
                        <div class="profile-repeate form-box-field">
                            <div class="col-xl-12">
                            <h3><b>Phone Verification</b></h3>
                            <h5 class="gray_h5">Enter the verification code that<br>  was sent to your mobile number.</h5>
                            </div>
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <b-col lg="12" md="12" class="input-with-icon-left">
                                        <i class="fa fa-mobile pop_up_i"></i>
                                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                                            <b-form-input id="input-9" maxlength="20" v-model="form.mobile" type="text" placeholder="" readonly="readonly" class="number_input form-control"></b-form-input>
                                        </b-form-group>
                                        </b-col>
                                        <b-col lg="12" md="12">
                                        <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                                            <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" placeholder="Enter the 4-digit code">
                                        </b-form-group>
                                        </b-col>

                                    <div slot="footer" class="form-btn col-md-12 col-lg-12">
                                        <b-button @click="mobileVerify" variant="success" style="background-color: #4c8bf5 !important;" class="otp_btn">Verify OTP</b-button>
                                        <!-- <b-button @click = "hideVerifyMobileModel()" variant="light">Cancel</b-button> -->
                                    </div>
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="col-xl-12 bottom_text_pop_phone">
                                <h5>Verification code may take up to a few <br>minutes to be sent to your phone.</h5>
                                <h5 class="web_bottom_zero" v-if="countDown > 0">Didn't receive the code? Resend code in <span style="color: #4c8bf5 !important;"> {{ countDown }} seconds</span></h5>
                                <h5 class="web_bottom_zero" v-else>Didn't receive the code? <b-link @click="sendOTP" style="color: #4c8bf5 !important;"> Resend Code</b-link></h5>
                        </div>
                        </div>
                    </div>
                </b-form>
            </b-modal>

			</div>
        </div>

        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->

    </div>

</template>

<script>
    import { POST_API } from "../../store/actions.type"
    import Bus from "../../event-bus";

    export default {

        data() {
            return {
                form:{
                    first_name: '',
                    last_name: '',
                    gender: '',
                    user_type_id_val:'',
                    country_code:'',
                    mobile: '',
                    company_contact_number:'',
                    var_code: '',
                    email: '',
                    email_verify :'',
                    mobile_verify :'',
                    verify_otp_button: false,
                    mobile_verified_check: false,
                    company_name: '',
                    office_address: '',
                    company_reg_no: '',
                    user_id: '',
                    image: '',
                },
                countDown: 60,
                countDownEmail: 0,
                showVerifyOtp:true,
                showVerifyEmail:true,
                images:[],
                showDismissibleAlert:false,
                error_message:'',
                isActive: 1,
                disabled:true,
                disabled:true,
                noClose:true,
                cropImg: '',
                data: null,
                webUrl: process.env.VUE_APP_URL,
                status:'',
                key:''
            }
        },


        methods:{

        showVerifyMobileModel()
        {
            this.countDown = 60,
            this.countDownTimer();
            this.$refs['verify-mobile-modal'].show();

        },
        countDownTimer () {
            if (this.countDown > 0) {
                this.showVerifyOtp = false;
                this.timeOutCounter = setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }else{
                this.showVerifyOtp = true;
            }
        },
        countDownTimerEmail () {
            if (this.countDownEmail > 0) {
                this.showVerifyEmail = false;
                this.timeOutCounterEmail = setTimeout(() => {
                    this.countDownEmail -= 1
                    this.countDownTimerEmail()
                }, 1000)
            }else{
                this.showVerifyEmail = true;
            }
        },

        hideVerifyMobileModel()
        {
         clearTimeout(this.timeOutCounter);
          this.$refs['verify-mobile-modal'].hide();

        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
            sendOTP() {
                if(this.showVerifyOtp){
                    clearTimeout(this.timeOutCounter);
                    this.$store.dispatch(POST_API, {
                        data:{
                            mobile: this.form.mobile,
                            user_id: this.user_id,
                            company_id: this.form.company_id,
                            user_type_id: 3

                        },
                        api:'/api/sendOTP'
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        this.$swal({
                            position: 'center',
                            // icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {

                        var userData = this.$store.getters.currentUser;
                        this.form.verify_otp_button = true;
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'OTP sent successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                        });
                        this.showVerifyMobileModel();

                    }
                });
                }else{
                    this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: 'Please try again in ' +this.countDown+ ' seconds.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            },
             submit() {

                var err = false;
                $('.required-field').each(function () {

                        if ($(this).val() == '' || $(this).val().trim().length === 0) {
                            err = true;
                            $(this).addClass('error');
                            $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                            $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                        } else {
                            $(this).removeClass('error');
                            $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                            $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                        }

                    });
                $('#gender').each(function () {

                    if( !$('#gender').val() ){
                        err = true;

                        $(this).addClass('error');
                        $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                        $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
                    } else {

                        $(this).removeClass('error');
                        $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                        $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    }

                    });
                    $('#gender').change(function(){
                    if ($(this).val()!="")
                    {
                    $(this).removeClass('error');
                        $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                        $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                    }
                });
                setTimeout(function ()
                {
                        $('.second_indiv_step_wrap .error').first().focus();

                        $('.error').unbind('keypress');
                        $('.error').bind('keypress', function(){
                            $(this).removeClass('error');
                            $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
                        });
                }, 100);
                if(err == false){
                    this.$store.dispatch(POST_API, {
                        data:{
                            first_name: this.form.first_name,
                            last_name: this.form.last_name,
                            gender: this.form.gender,
                            mobile_number: this.form.mobile,
                            email: this.form.email,
                            contact_number: this.form.company_contact_number,
                            items:this.form,
                            user_id: this.user_id,
                            status:this.status,
                            company_id:this.form.company_id,
                            ft_account_status:this.status,
                            mobile_verified:this.form.mobile_verify,
                            email_verified:this.form.email_verify,
                        },
                        api:'/api/submit-om-supervisor'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.$swal({
                                    position: 'center',
                                    // icon: 'error',
                                    imageUrl: '/assets/images/404_elcy.gif',
                                    customClass:{
                                        container: 'mascot_error_container',
                                    },
                                    title: this.error_message,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                        } else {
                            Bus.$emit('active_users');
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: 'Profile Updated successfully.',
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                window.location.replace('/v2/employer/om-supervisor-profile');
                                // window.scrollTo(0,0);
                            });
                        }
                    });
                }
            },
            getEditData() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.user_id,
                    },
                    api: '/api/om-supervisor-profile'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){

                            if(this.$store.getters.getResults.user['mobile_number'] == '12345678'){
                                this.form.mobile = '';
                            }else{
                                this.form.mobile = this.$store.getters.getResults.user['mobile_number'];
                                // this.form.verify_otp_button = this.$store.getters.getResults.user['mobile_number'] ? this.form.verify_otp_button = true : this.form.verify_otp_button = false;
                            }

                            // if(this.$store.getters.getResults.data.contact_number == '12345678'){
                            //     this.form.mobile = '';
                            // }else{
                            //     this.form.mobile = this.$store.getters.getResults.data.contact_number;
                            // }

                            this.form.email = this.$store.getters.getResults.user.email;
                            if(this.$store.getters.getResults.data.name == 'test.co'){
                                this.form.company_name = '';
                            }else{
                                this.form.company_name = this.$store.getters.getResults.data.name;
                            }
                            // this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
                            // this.form.overview = this.$store.getters.getResults.data.description;
                            // this.form.office_address = this.$store.getters.getResults.data.address;
                            // this.form.selectedImage.image = this.$store.getters.getResults.data.company_logo;
                            // this.form.selectedBannerImage.image = this.$store.getters.getResults.user['banner_image'] ;
                            // this.form.banner_image = this.$store.getters.getResults.user['banner_image'] != null ? this.$store.getters.getResults.user['banner_image'] : '';

                            this.form.first_name = this.$store.getters.getResults.user['first_name'];
                            this.form.last_name = this.$store.getters.getResults.user['last_name'];
                            this.form.gender = this.$store.getters.getResults.user['gender'] ? this.$store.getters.getResults.user['gender'] : '';
                            this.form.country_code = this.$store.getters.getResults.user['country_code'];
                            this.form.user_type_id_val = this.$store.getters.getResults.user['user_type_id'] == '3' ? 'OM' : 'Supervisor';
                            this.form.email_verify = this.$store.getters.getResults.user['email_verified'];
                            this.form.mobile_verify = this.$store.getters.getResults.user['mobile_verified'];
                            // this.form.sortdesc = this.$store.getters.getResults.user['short_description'];

                            this.form.mobile_verified_check = this.$store.getters.getResults.user['mobile_verified'] == 'yes' ? this.form.mobile_verified_check = true : this.form.mobile_verified_check = false;
                            // this.form.skill = this.$store.getters.getResults.data.company_industry_id ? this.$store.getters.getResults.data.company_industry_id : '';
                            // this.form.specified_company_industry = this.$store.getters.getResults.data.specified_company_industry;
                            this.form.company_id = this.$store.getters.getResults.data.id;

                            // this.showACRA = (this.$store.getters.getResults.data.acra != '' && this.$store.getters.getResults.data.acra != null) ? true : false;
                            // this.acraUrl = (this.$store.getters.getResults.data.acra != '' && this.$store.getters.getResults.data.acra != null) ? this.$store.getters.getResults.data.acra : '';
                            // this.form.acra = this.acraUrl;
                            // this.form.apply_for_dp = false;
                            // this.form.apply_for_dp = this.$store.getters.getResults.user['emp_daily_paid_status'] == 'yes' ? this.form.apply_for_dp = true : this.form.apply_for_dp = false;
                            // this.form.apply_for_dp = this.$store.getters.getResults.user['account_verification_status'] == 'completed' ? this.form.apply_for_dp = true : this.form.apply_for_dp = false;
                            // this.form.emp_daily_paid_status = this.$store.getters.getResults.user['emp_daily_paid_status'];
                            this.status = this.$store.getters.getResults.ft_account_status;

                            // this.getDocuments();

                            // if(this.form.skill == this.other_category_id){
                            //     $('.other_category_class').css('display', 'block');
                            // }else{
                            //     $('.other_category_class').css('display', 'none');
                            // }

                            if(this.form.email_verify == 'yes'){
                                $('#email').attr('disabled', 'disabled');
                            }else{
                                $('#email').removeAttr('disabled');
                            }

                            if(this.form.mobile_verify == 'yes'){
                                $('#contact').attr('disabled', 'disabled');
                            }else{
                                $('#contact').removeAttr('disabled');
                            }
                        }else{
                            this.form.mobile = '';
                            this.form.email = '';
                        }
                    }
                });
        },
        emailVerify() {
            if(this.showVerifyEmail){
                clearTimeout(this.timeOutCounterEmail);
                this.countDownEmail = 30;
                return this.$store.dispatch(POST_API, {
                    data: {
                        email: this.form.email,
                        user_id: this.user_id,

                    },
                    api: '/api/sendEmailVerify'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.countDownEmail = 30,
                        this.countDownTimerEmail();
                         this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Verification mail sent successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            // window.location.reload();

                        });
                    }
                });
            }else{
                this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: 'Please try again in ' +this.countDownEmail+ ' seconds.',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        },
        mobileVerify() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        verification_code: this.form.var_code,
                        user_id: this.user_id,

                    },
                    api: '/api/verifyMobile'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        // return [];
                    } else {
                        this.form.mobile_verified_check = true;
                         this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Mobile Number verified successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            this.form.mobile_verify == 'yes';
                            this.hideVerifyMobileModel();
                            // if(this.form.mobile_verify == 'yes'){
                                $('#contact').attr('disabled', 'disabled');
                            // }else{
                            //     $('#contact').removeAttr('disabled');
                            // }
                            // window.location.reload();
                        });
                    }
                });
        },
        },
        mounted(){
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            Bus.$emit('active_users');
            this.getEditData();
            //this.getDocuments();
            $(".dashboard-content-container .simplebar-scroll-content").animate({
                scrollTop: 0
            }, "fast");
        }
    }
</script>


