<template>
    <!-- Wrapper -->
<div id="wrapper wrapper_pdng">
<div class="back_arrow_icon">
	<span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
</div>
<!-- Titlebar
================================================== -->
    <div class="bg_img_top new_job_detail_full_dvz " v-bind:style="{backgroundImage: 'url('+(webUrl+'assets/images/single-job.jpg' )+ ')'} " >
        <div class="grd_div inside_grd height_three_zero_one">
        <div class="container">
            <div class="row">
			<div class="col-md-12">
				<div class="company_profile_banner">
					<div class="left-side updated_left_side_banner full_left_if_right_not_available">
						<div class="header-image updated_header_image_banner updated_image_in_square_shape"><img :src="items.profile_image ? items.profile_image: webUrl+'assets/images/company-logo-placeholders.png'" alt="" class="common_max_width_unset"></div>
						<div class="header-details updated_header_details_banner">
							<h1 class="name_mrgn header_detail_new_h1 new_daily_paid_jjobs">{{ items.last_name | capitalize }} {{ items.first_name | capitalize }} </h1>
							<!-- <h4 class="new_h4">Short Description</h4> -->
							<ul>
								 <!-- <li class="emp_li_fst"><div class="star-rating" data-rating="4.9" v-if="items.average_rating != null"></div></li>  -->
								<!-- <li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false"  v-if="items.average_rating != null"></star-rating></li> -->
								 <li> {{ items.designation }}</li>
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
        </div>
     
        
    </div>
    </div>


<!-- Page Content
================================================== -->
<div class="container mx_width">
	<div class="row ">
		
		<!-- Sidebar -->
		<div class="col-xl-4 col-lg-4 emp_prf">
			<div class="sidebar-container">
				<!-- <div class="sidebar-widget">
					<a href="#small-dialog" class="apply-now-button popup-with-zoom-anim margin-bottom-50 msg_btn"><i class="fa fa-envelope new_letter"></i>Send a Message </a>
				</div> -->
				<div class="sidebar-widget">
                <div class="job-overview">
                    <div class="job-overview-headline font_weight_600">Personal Detail</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li><i class="icon-material-outline-email"></i><span>Email</span><h5>{{items.email}} <b-badge variant="danger" v-if="items.email_verified == 'no'" class="profile_danger_badge">!</b-badge></h5></li>
                                <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span>Mobile Number</span><h5>{{items.country_code}}-{{items.mobile_number}} <b-badge variant="danger" v-if="items.mobile_verified == 'no'" class="profile_danger_badge">!</b-badge></h5></li>
                                <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span>NRIC</span><h5>{{items.nric}}</h5></li>
							</ul>
                        </div>
                    </div>
                </div>
				<!-- Location -->
				<div class="sidebar-widget nw_side" v-if="items.address">
					<h3>Address</h3>
					{{items.address}}
				</div> 
			</div>
		</div>
		
	</div>
</div>


<!-- Spacer -->
<div class="margin-top-15"></div>
<!-- Spacer / End-->

</div>
<!-- Wrapper / End -->



</template>

<script>

import { POST_API } from '../../../store/actions.type'
import moment from "moment";

export default {

    data() {
        return {
            id: atob(this.$route.params.id),
            pager: {},
            pageOfItems: [],
            items:[],
            webUrl: process.env.VUE_APP_URL,
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).format('DD MMM YYYY')
        },
		dateTimeAgo: function(value) {
			var m = moment(value);  // or whatever start date you have
			// var today = moment().startOf('day');
			var today = moment();
				var days = Math.round(moment.duration(today - m).asDays());
			if(days > 0){
				return days +' Days Ago'
			}else{
				return 'today'
			}
			// if (!value) return ''
			// const dateTimeAgo = moment(value).fromNow();
			// return dateTimeAgo
		},
           
		customizeRoleType: function(value) {
			if (!value) return ''
			var i, frags = value.split('-');
			for (i=0; i<frags.length; i++) {
				frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
			}
			return frags.join(' ');
		},
    },
	
    methods:{
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
		
        getStaffData() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.id,
                        
                    },
                    api: '/api/staff-view-profile'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data;
                    }
                });
        },
		
    },
    mounted(){
        this.getStaffData();
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
    }
}
</script>
