<template>
    <div class="animated fadeIn">
        <div class="create-new-job-section email-template-section">
            <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h3><a class="back-arrow" href="javascript:history.back(1);"></a>Edit User</h3>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit User</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                    <div class="d-flex justify-content-start breadcrumb-wrapper">
                        <b-breadcrumb
                        :items="breadCrumb()"
                        />
                    </div>
            </div>
            <div class="edit-profile-section border-radius-4 white-bg common_padding_30 edit_genneral_stng_dsk">
                <b-form @submit="formSubmit">
                    <div class="edit-form-box desktop_margin_top_zero">
                        <div class="row">
                                <div class="submit-field col-xl-4">
                                    <h5>Range Type<span class="required_star">*</span></h5>
                                    <input type="text" class="with-border desktop_margin_bottom_zero common_disabled_exist"  v-model="range_type" disabled>
                                </div>
                                <div class="submit-field col-xl-4 select_month_custom">
                                    <h5>Select Month<span class="required_star">*</span></h5>
                                    <div class="new_date_pk">
                                        <!-- <b-input-group> -->
                                            <datepicker  v-model="month_year" :disabled-dates="disabledFromDate" @input="addMonthYear()" :format="customFormatter" minimum-view="month" maximum-view="year" class="add_calender_icon month_input_check freelancer_incentive_calender"></datepicker>         
                                        <!-- </b-input-group> -->
                                    </div>
                                    <!-- <input type="text" class="with-border"  v-model="month_year" > -->
                                </div>
                                <div class="submit-field col-xl-4 "  v-if="selectedMonths.length > 0">
                                    <div>
                                       <h5> Selected Months:</h5>
                                        <!-- <ul class="form-control">
                                            <li v-for="(month, index) in selectedMonths" :key="index">{{ month }}</li>
                                        </ul> -->
                                        <div class="form-control selected_form_control" :class="selectedMonths.length >= 2 ? 'month_selected_incentives' : 'month_not_selected_incentives'"> 
                                            <b-form-tag 
                                                v-for="(month, index) in selectedMonths" :key="index"
                                            >{{ month }}<span @click="removeMonth(month)" class="selected_remove_btn material-icons-outlined">clear</span></b-form-tag>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <!-- <div > -->
                                <div class="row" v-for="(app, index) in appendIncentive" :key="index">
                                        <div class="col-xl-3">
                                            <div class="submit-field">
                                                <h5>Level<span class="required_star">*</span></h5>
                                                <input type="text"  maxlength="4" class="with-border desktop_margin_bottom_zero"  v-model="app.level" >
                                            </div>
                                        </div>
                                        <div class="col-xl-3">
                                            <div class="submit-field">
                                                <h5>Amount<span class="required_star">*</span></h5>
                                                <input type="text" maxlength="4" class="with-border desktop_margin_bottom_zero"  v-model="app.amount" >
                                            </div>
                                        </div>
                                        <div class="col-xl-2 minimum_incentive_range">
                                            <div class="submit-field">
                                                <h5>Minimum Range<span class="required_star">*</span></h5>
                                                <input type="text" maxlength="4" class="with-border desktop_margin_bottom_zero"  v-model="app.min_range" >
                                            </div>
                                        </div>
                                        <div class="col-xl-2 maximum_incentive_range">
                                            <div class="submit-field">
                                                <h5>Maximum Range<span class="required_star">*</span></h5>
                                                <input type="text" maxlength="4" class="with-border desktop_margin_bottom_zero"  v-model="app.max_range" >
                                            </div>
                                        </div>
                                      
                                        <!-- <div class="jb3">
                                            <div class="submit-field employment_history big_screen_calender_font">
                                                <h5>Start Date</h5>
                                                <datepicker v-model="app.job_yrsfrom" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" class="add_calender_icon web_bottom_zero"></datepicker>


                                            </div>
                                        </div> -->
                                        <div class="col-xl-1 plus_icon_margin_bottom_big_screen"  v-if="index != 0">
                                            <div class="submit-field remove_bt web_bottom_zero ">
                                                <a href="javascript:void(0);" class="new_plus_btn_round button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom minus_margin_top_btn" @click="removeIncentive(index)" >
                                                    <span class="add_plus material-icons-outlined red_icon">remove_circle_outlined</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-xl-1 plus_icon_margin_bottom_big_screen" v-if="index == 0">
                                            <div class="submit-field remove_bt web_bottom_zero ">
                                                <a href="javascript:void(0);" class="new_plus_btn_round button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom minus_margin_top_btn" v-if="index == 0" @click="addIncentive">
                                                    <span class="add_plus material-icons-outlined">add_circle_outlined</span>
                                                </a>

                                            </div>
                                        </div>
                                    <!-- </div> -->
                                    <!-- <hr class="mobile_hr" v-if= "index + 1 != appendIncentive.length"> -->
                                </div>
                                
                            <div slot="footer" class="form-btn mt-3">
                                <b-button type="submit" variant="success" class="desktop_margin_left_zero">Submit</b-button>
                                <b-button @click="$router.go(-1)" variant="light" style="background-color:black">Cancel</b-button>
                            </div>
                        
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../../store/actions.type";
import Datepicker from 'vuejs-datepicker';
import moment from "moment";

$('#scheduled_post_multi').click(function(e){
            this.dateArr = new Date();
});


export default {

    data() {
        return {
            dateArr:[new Date().toISOString().split('T')[0]],
            month_year: '',
            // month_year: moment().add(1, 'month').format('MMM YYYY'),
            disabledFromDate: {
                to: new Date(Date.now() - 8640000),
                // to: new Date(Date.now(moment().format('MMM YYYY')) - 8640000),
            },
            range_type:'Job completion per month',
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            appendIncentive: [],
            incentivePrevData:[],
            blockRemovalIncentive: true,
            selectedDates: [],
            selectedMonths:[],
            customFormat: 'MMM YYYY',
            language: require('vuejs-datepicker/dist/locale/translations/en.js'), // Change to desired language
            highlightedDates: [], // Add any specific dates to highlight here
        }
    },
    components: {
        Datepicker,
        // datePicker: VuePersianDatetimePicker,
    },
    methods: {
        removeMonth(month){
            console.log(month);
            //  this.selectedMonths.splice(this.selectedMonths.indexOf(indexId), 1);
             const index = this.selectedMonths.indexOf(month);
                if (index > -1) { // only splice array when item is found
                    this.selectedMonths.splice(index, 1); // 2nd parameter means remove one item only
                    console.log(this.selectedMonths.length,this.month_year)
                if(this.selectedMonths.length==0)
                {
                    this.selectedMonths= [];
                }
                }
                
        },
        breadCrumb(){
            var item = [{
                to:  {name:'incentive-management-list'},
                text: 'Monthly Incentive Management' ,
            },{
                to:null,
                text: 'Create Monthly Incentive'
            }];
            return item;
        },
        customFormatter(date) {
            return moment(date).format('MMM,YYYY');
        },
        formatDate(date) {
      const options = { month: 'short', year: 'numeric' };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    },
    dateupdate(){

                if(this.dateArr.length > 0){
                    this.dateArr.sort( ( a, b) => {
                        return new Date(a) - new Date(b);
                    });
                }
            },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        showSuccessAlert() {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: 'Record Added successfully.',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'incentive-management-list' });
            });
        },
        
        addMonthYear() {  
            if (this.month_year) {
                if (!this.selectedMonths.includes(moment(this.month_year).format('MMM YYYY'))) {
                this.selectedMonths.push(moment(this.month_year).format('MMM YYYY'));
                // Create a copy of the original array to avoid mutating it
                    const datesCopy = this.selectedMonths;
                        // Sort the dates using a custom comparator function
                    datesCopy.sort((a, b) => {
                        // Extract the month and year from the date strings
                        const [monthA, yearA] = a.split(' ');
                        const [monthB, yearB] = b.split(' ');
                        // Convert month strings to numerical values
                        const monthValueA = new Date(Date.parse(`${monthA} 1, ${yearA}`)).getMonth();
                        const monthValueB = new Date(Date.parse(`${monthB} 1, ${yearB}`)).getMonth();
                        // Compare years first, then months
                        if (yearA !== yearB) {
                            return parseInt(yearA) - parseInt(yearB);
                        } else {
                            return monthValueA - monthValueB;
                        }
                    });
                    this.selectedMonths  = datesCopy;
                }
                this.month_year = '';
            }
            if(this.selectedMonths.length >= 2)
            {
                $( ".month_selected_incentives" ).animate({scrollLeft:'+=1500'},500); 
            }
        },
        
        addIncentive()
        {
            this.appendIncentive.push( {
                level: "",
                min_range: "",
                max_range: "",
                amount: "",
            }
        );
        },
        removeIncentive (lineId) {
          if (this.blockRemovalIncentive )
            {
                this.appendIncentive.splice(lineId, 1);
            }
        },
        formSubmit(evt) {
            var err = false;
            evt.preventDefault();
            if(this.selectedMonths.length == 0){
                err = true
                this.error_message = 'Please Select Month';
                this.showAlert();
            }else{
                err = false
            }
            if(err == false){
                this.$store.dispatch(POST_API, {
                    data: {
                        range_type: this.range_type,
                        month: this.selectedMonths,
                        // month: 'Jan 2024',
                        appendIncentive: this.appendIncentive,
                    },
                    api: "/api/incentive-store-admin",
                })
                .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.showSuccessAlert();
                }
            });
            }
       },
        getEditData() {
            return this.$store.dispatch(POST_API, {
                    data: {
                    },
                    api: '/api/incentive-get-create-data-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        // this.showAlert();
                        return [];
                    } else {
                        this.incentivePrevData = this.$store.getters.getResults.data;
                        this.appendIncentive = [];
                        var appendIncentive = this.appendIncentive;
                        if(this.incentivePrevData.length > 0){
                            this.incentivePrevData.forEach(function(item,index){
                                appendIncentive.push({
                                    level:item.level,
                                    min_range:item.min_range,
                                    max_range:item.max_range,
                                    amount:item.amount,
                                });

                            })
                        }
                        else
                        {
                            this.appendIncentive = [];
                            this.addIncentive();
                        }
                    }
                });
        },
        
    },
    mounted() {
      
        this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.addIncentive();
        this.getEditData();
        $('.date_select .vpd-actions:nth-child(2)').click(function(e){
                this.dateArr = []
                });

    },
};

</script>
