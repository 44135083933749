<script>
            const localbusinesSchema = document.createElement('script');
            localbusinesSchema.setAttribute('type', 'application/ld+json');
            localbusinesSchema.text = `
                {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "EL Connect",
                    "url": "https://elconnect.sg/",
                    "logo": "https://elconnect.sg/logo.png",
                    "image": "https://elconnect.sg/logo.png",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "745 Lor. 5 Toa Payoh, #03-02",
                        "addressLocality": "Singapore",
                        "addressRegion": "Singapore",
                        "addressCountry": "SG",
                        "postalCode": "319455",
                        "Telephone": "+65 68029119"
                    },
                    "sameAs": [
                        "https://www.facebook.com/elconnectsgp",
                        "https://www.instagram.com/elconnectsgp",
                        "https://www.youtube.com/c/elconnect"
                    ]
                }`;
                // console.log(window.location.pathname.split('/').includes('blog'),'111')
                var blogDetailUrl = window.location.pathname.split('/').includes('blog');
                if(['/','/blog'].includes(window.location.pathname)|| blogDetailUrl)
                {
                    document.head.appendChild(localbusinesSchema);
                }
            
export default{
    name: 'commonLocalBusinesSchemaComponent',
}
</script>

