<template>
  <div>
    <div class="blue_elipse_relative common_display_none_employer">
      <div class="blue_elipse_img">
        <img :src="webUrl + 'assets/employerImages/Ellipse 17.png'" alt="Ellipse" />
      </div>
    </div>
    <div class="container my_custom_container_employer">
      <div class="row common_padding_top_section_employer">
        <div class="col-xl-3 col-md-3 mobile_tab_max_width_flex">
          <div class="employer_company_count mobile_margin_top_zero">
            <p class="d-none" id="company-count">(Math.ceil(users_count / 1000 ) * 1000)</p>
            <p class="number_count_employer common_base_pink_color">
              <span
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/employerImages/Ellipse-32.png' + ')',
                }"
                class="count_bg_img_employer"
                ><span class="count" :data-count="Math.ceil(users_count / 1000) * 1000">{{
                  Math.ceil(users_count / 1000) * 1000
                }}</span
                >+</span
              >
            </p>
            <p class="text_1_count common_navy_blue_color">Total Users</p>
            <p class="text_2_count common_navy_blue_color">Registered</p>
          </div>
        </div>
        <div class="col-xl-3 col-md-3 mobile_tab_max_width_flex">
          <div class="employer_company_count second_box_company_count">
            <p class="number_count_employer common_base_pink_color">
              <span><span class="count1">4.6</span></span>
            </p>
            <img :src="webUrl + 'assets/employerImages/ratings.png'" alt="Star rating" />
            <p class="text_1_count common_navy_blue_color">User Ratings</p>
            <p class="text_2_count common_navy_blue_color">Google Review</p>
          </div>
        </div>
        <div
          class="col-xl-6 col-md-6 about_bg_emp_div mobile_tab_max_width_flex mobile_padding_top_section"
        >
          <h2 class="common_futura_bold_font_employer common_employer_heading_color">
            <span
              class="about_bg_emp common_futura_bold_font_employer"
              :style="{
                backgroundImage: 'url(' + webUrl + 'assets/employerImages/Vector-5.png' + ')',
              }"
              >About Us</span
            >
          </h2>
          <p class="common_employer_heading_color">
            EL Connect is a job posting site that directly connects both full-time and part-time job
            seekers to hirers in the Singapore's job market. With our intuitive mobile app, Hirers
            can bypass traditional hiring processes, and job seekers' search for employment is
            simplified.
          </p>
          <p class="common_employer_heading_color goal_p">
            Our objective is to streamline both full-time and part-time hiring processes, making it
            efficient and seamless experiences for all.
          </p>
        </div>
        <div class="col-xl-12 col-md-12 start_h">
          <div class="hire_btn">
            <div class="get_started">
              <button
                class="common_employer_animation_btn start_hiring_web"
                @click="getStartedEM()"
              >
                <div>
                  <span class="first_animation_span"
                    >Start hiring today<i class="material-icons-outlined">east_outlined</i></span
                  >
                  <span class="second_animation_span"
                    >Start hiring today<i class="material-icons-outlined">east_outlined</i></span
                  >
                </div>
              </button>
              <button
                class="common_employer_animation_btn start_hiring_mobile"
                @click="getStartedEMMobile()"
              >
                <div>
                  <span class="first_animation_span"
                    >Start hiring today<i class="material-icons-outlined">east_outlined</i></span
                  >
                  <span class="second_animation_span"
                    >Start hiring today<i class="material-icons-outlined">east_outlined</i></span
                  >
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="common_padding_top_section_employer"></div>
        </div>
      </div>
    </div>
    <div class="pink_elipse_relative common_display_none_employer">
      <div class="pink_elipse_img">
        <img :src="webUrl + 'assets/employerImages/Ellipse 18.png'" alt="Pink ellipse" />
      </div>
    </div>
    <div class="organization_bg_div">
      <div class="container my_custom_container_employer">
        <div class="row">
          <div class="col-xl-12">
            <div class="organization_div">
              <h2 class="common_futura_bold_font_employer">Trusted by leading organizations</h2>
              <p>Join 800+ companies already hiring with EL Connect</p>
              <!-- <img :src="webUrl+'assets/employerImages/Group 242.png'"> -->
              <div class="img_companies desktop_companies">
                <img :src="webUrl + 'assets/employerImages/mcd color.png'" alt="Mcd logo" />
                <img :src="webUrl + 'assets/employerImages/sats color.png'" alt="Sats logo" />
                <img
                  :src="webUrl + 'assets/employerImages/sushi tei color.png'"
                  alt="Sushi tei logo"
                />
                <img :src="webUrl + 'assets/employerImages/hilton color.png'" alt="Hilton logo" />
                <img :src="webUrl + 'assets/employerImages/jnt color.png'" alt="Jnt logo" />
                <img :src="webUrl + 'assets/employerImages/spx logo.png'" alt="Shopee logo" />
              </div>
              <div class="img_companies mobile_companies">
                <div class="row">
                  <div class="col-xl-6">
                    <img :src="webUrl + 'assets/employerImages/mcd color.png'" alt="Mcd logo" />
                  </div>
                  <div class="col-xl-6">
                    <img :src="webUrl + 'assets/employerImages/sats color.png'" alt="Sats logo" />
                  </div>
                  <div class="col-xl-6">
                    <img
                      :src="webUrl + 'assets/employerImages/sushi tei color.png'"
                      alt="Sushi tei logo"
                    />
                  </div>
                  <div class="col-xl-6">
                    <img
                      :src="webUrl + 'assets/employerImages/hilton color.png'"
                      alt="Hilton logo"
                    />
                  </div>
                  <div class="col-xl-6 margin_top_30">
                    <img :src="webUrl + 'assets/employerImages/jnt color.png'" alt="Jnt logo" />
                  </div>
                  <div class="col-xl-6 margin_top_30">
                    <img :src="webUrl + 'assets/employerImages/spx logo.png'" alt="Shopee logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my_custom_container_employer">
      <div class="row common_padding_top_section_employer">
        <div class="col-xl-12">
          <div class="benefit_div">
            <h2 class="common_futura_bold_font_employer common_employer_heading_color">
              Enhance Employer<span
                class="common_employer_heading_color benefits_bg common_futura_bold_font_employer"
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/employerImages/Vector-6.png' + ')',
                }"
                >Benefits</span
              >
            </h2>
            <p class="common_employer_heading_color">
              experience our offerings for enhanced hiring
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
          <div class="benefits_box">
            <img
              :src="webUrl + 'assets/employerImages/ELC_Employer_Illustrations-11.png'"
              alt="Free trial"
            />
            <h3>Manpower Supply Solution</h3>
            <p>
              Complemented by free consultation and advisory services. Let us assist you in all your
              manpower needs, lightening your HR process.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
          <div class="benefits_box">
            <img
              :src="webUrl + 'assets/employerImages/ELC_Employer_Illustrations-12.png'"
              alt="Free full time job postings"
            />
            <h3>Free Full-Time Job Postings</h3>
            <p>
              Post full-time positions without charge on our job site. Supports your hiring needs
              and ensure you reach the best candidates.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex common_z_index">
          <div class="benefits_box">
            <!-- <img class="image1" :src="webUrl+'assets/employerImages/Ellipse-16.png'"> -->
            <img
              class=""
              :src="webUrl + 'assets/employerImages/ELC_Employer_Illustrations-13 1.png'"
              alt="Free CV downloads"
            />
            <!-- <img :src="webUrl+'assets/employerImages/Rectangle-567.png'"> -->
            <h3>Free CV downloads & Real-Time Alerts</h3>
            <p>Receive instant notifications when candidates apply and enjoy free CV downloads.</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container mobile_ext_pdng my_custom_container_employer common_padding_top_section_employer common_padding_bottom_section"
    >
      <div class="row">
        <div class="col-xl-4 col-md-4 mobile_features_benefit_common">
          <div class="features_heading">
            <img :src="webUrl + 'assets/employerImages/Line 28.png'" alt="Border left" />
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mobile_features_benefit_single">
          <div class="features_heading">
            <p>Features & Benefits</p>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mobile_features_benefit_common">
          <div class="features_heading">
            <img :src="webUrl + 'assets/employerImages/Line 29.png'" alt="Border right" />
          </div>
        </div>
      </div>
    </div>
    <div class="container my_custom_container_employer">
      <div class="row">
        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
          <div class="features_key">
            <p>Features</p>
            <ul>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >On-Demand Staffing Marketplace
              </li>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >Risk-Free Trial Posting
              </li>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >Efficient Hiring Process
              </li>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >Complimentary Full-Time Job Listings
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
          <div class="features_key features_key_mobile">
            <p>Benefits</p>
            <ul>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >Instant Applicant Tracking
              </li>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >Flexible Workforce Solutions
              </li>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >Dedicated Employer Support
              </li>
              <li>
                <span
                  ><img
                    :src="webUrl + 'assets/employerImages/Vector-check.png'"
                    alt="Check" /></span
                >Long-Term Talent Partnerships
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
          <div class="join_us_btn color_download_btn_employer">
            <button
              class="active start_hiring_web"
              @click="getStartedEM()"
              data-text="JOIN US"
            ></button>
            <button
              class="active start_hiring_mobile"
              @click="getStartedEMMobile()"
              data-text="JOIN US"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <div class="pink_elipse_relative_second">
      <div class="pink_elipse_img_second">
        <img :src="webUrl + 'assets/employerImages/Ellipse 19.png'" alt="Pink ellipse" />
      </div>
    </div>
    <div class="emp_slider_section section common_padding_top_section_employer padding-bottom-10">
      <div class="container mobile_tab_container_hundered">
        <div class="col-xl-12">
          <div class="benefit_div">
            <h2 class="common_futura_bold_font_employer common_employer_heading_color">
              What Clients Say
            </h2>
            <h4>Check out the testimonials from clients who already using our platform</h4>
          </div>
        </div>
        <VueSlickCarousel
          :arrows="true"
          v-bind="settings"
          :dots="true"
          ref="carousel"
          v-if="testimonial.length"
          :adaptive-height="true"
          class="home_slick_arrrow"
        >
          <div class="testimonial-box" v-for="(test, h) in testimonial">
            <div class="testimonial-avatar">
              <img
                :src="test.image"
                alt="Freelancer Avatar"
                loading="lazy"
                width="85"
                height="85"
              />
            </div>
            <div class="testimonial-author">
              <h4>{{ test.title }}</h4>
              <span>{{ test.user_type | capitalize }}</span>
            </div>
            <div class="testimonial">
              {{ test.description }}
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <div
      class="reg_bg"
      :style="{
        backgroundImage: 'url(' + webUrl + 'assets/employerImages/last-section-bg.png' + ')',
      }"
      id="mobile_tab_max_width_flex1"
    >
      <div
        class="container my_custom_container_employer common_padding_top_section_employer padding_bottom_65"
      >
        <div class="row">
          <div class="col-xl-6 col-md-6 mobile_tab_max_width_flex">
            <div class="reg_left_div">
              <h2 class="top_heading_h1_emp common_futura_bold_font">
                <span class="talent_employer">Join Today!</span><br />Discover More Talent From Us
              </h2>
              <p class="para_jobseeker_head_emp">
                Effortlessly achieve staffing success with our user friendly job posting site. Trust
                us to help you build a workforce that drives success.
              </p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 mobile_tab_max_width_flex">
            <div class="header_people_image">
              <video autoplay muted playsinline preload>
                <source :src="webUrl + 'assets/employerImages/emp video.mp4'" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { POST_API, GET_API, LOGIN, PERMISSION_DATA } from "../../../store/actions.type";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  metaInfo: {
    title: "Job Posting Site in Singapore- EL Connect",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "EL Connect is a job posting site in Singapore where employers can post full-time and part-time job ads. Enjoy our free job posting now!",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "keywords",
        content:
          "el connect, job platform singapore, full time jobs, permanent part time, flexible job, daily paid jobs, temp staffing, temporary staffing jobs, part time daily paid jobs, daily paid jobs singapore, job posting site, part time jobs, daily paid, daily paid job, connect",
      },
      { property: "og:title", content: "Job Posting Site in Singapore- EL Connect" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/employers" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "EL Connect is a job posting site in Singapore where employers can post full-time and part-time job ads. Enjoy our free job posting now!",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/employers" }],
  },

  data() {
    return {
      items: null,
      testimonial: [],
      webUrl: process.env.VUE_APP_URL,
      error_message: "",
      job_items: [],
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 800,
        infinite: false,
        responsive: [
          {
            // breakpoint: 768,
            // settings: {
            // 	"slidesToShow": 1,
            // 		"slidesToScroll": 1,
            // 		"speed": 800,
            // 		"infinite": false,
            //         "dots":true,
            //         "arrows":false
            //     }
            breakpoint: 998,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrow: true,
            },
          },
        ],
      },
      phone_number: "",
      company_name: "",
      success_message: "",
      totalFLCount: "24,000",
      email: "",
      password: "",
      fname1: "",
      comp_name1: "",
      lname1: "",
      semail1: "",
      mobile_number1: "",
      spassword1: "",
      srepassword1: "",
      user_type: "3",
      user_type_id: "",
      users_count: "",
    };
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    dateTimeAgo: function (value) {
      if (!value) return "";
      const dateTimeAgo = moment(value).fromNow();
      return dateTimeAgo;
    },

    customizeRoleType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },
  // filters: {
  //     trimString: function(value) {
  //         if (!value) return ''
  //         return value.length > 35 ? value.substring(0, 32) + "..." : value;
  //     }
  // },
  methods: {
    employerPasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_employer input").attr("type") == "text") {
        $("#show_hide_password_employer input").attr("type", "password");
        $("#show_hide_password_employer i").addClass("fa-eye-slash");
        $("#show_hide_password_employer i").removeClass("fa-eye");
      } else if ($("#show_hide_password_employer input").attr("type") == "password") {
        $("#show_hide_password_employer input").attr("type", "text");
        $("#show_hide_password_employer i").removeClass("fa-eye-slash");
        $("#show_hide_password_employer i").addClass("fa-eye");
      }
    },
    employerRepasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repeat_password_employer input").attr("type") == "text") {
        $("#show_hide_repeat_password_employer input").attr("type", "password");
        $("#show_hide_repeat_password_employer i").addClass("fa-eye-slash");
        $("#show_hide_repeat_password_employer i").removeClass("fa-eye");
      } else if ($("#show_hide_repeat_password_employer input").attr("type") == "password") {
        $("#show_hide_repeat_password_employer input").attr("type", "text");
        $("#show_hide_repeat_password_employer i").removeClass("fa-eye-slash");
        $("#show_hide_repeat_password_employer i").addClass("fa-eye");
      }
    },
    getStartedEMMobile() {
      document
        .querySelector("#mobile_tab_max_width_flex12Mobile")
        .scrollIntoView({ behavior: "smooth", block: "center" });

      // document.querySelector("#mobile_tab_max_width_flex1").scrollIntoView({ behavior: "smooth", block: "center" });
    },
    getStartedEM() {
      document
        .querySelector("#mobile_tab_max_width_flex12")
        .scrollIntoView({ behavior: "smooth", block: "center" });

      // document.querySelector("#mobile_tab_max_width_flex1").scrollIntoView({ behavior: "smooth", block: "center" });
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // console.log(this.$store.getters.getResults.data.full_name)
            this.menus = this.$store.getters.getResults.data;
            // JwtService.setMenuPermission(JSON.stringify(this.menus));
            // window.location.replace('/admin/dashboard');
            if (
              this.is_company_head == "no" &&
              (this.user_type_id == "3" || this.user_type_id == "4")
            ) {
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/om-supervisor-profile');
              // }
              // window.location.replace('/v2/employer/om-supervisor-profile');

              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/om-supervisor-profile");
              }
            } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
              // this.getMenuPermission();
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/employer-profile');
              // }
              // window.location.replace('/v2/employer/employer-profile');
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/employer-profile");
              }
            } else if (this.user_type_id == "5") {
              window.location.replace("/v2/jobseeker/jobseeker-profile");
            } else {
              window.location.replace("/v2/admin/dashboard");
            }
          }
        });
    },
    signup1() {
      console.log("fhfhfg");

      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-field1").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error-jbs");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).removeClass("error-jbs");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });
      $(".required-field-password").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).parent().addClass("error-jbs");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).parent().removeClass("error-jbs");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error-jbs").first().focus();

        $(".error-jbs").unbind("keypress");
        $(".error-jbs").bind("keypress", function () {
          $(this).removeClass("error-jbs");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      // if($('#checkbox:checked').val() != "true" && err == false){
      //     err = true;
      //     document.getElementById("errorIdSignup1").classList.add("show");
      //     document.getElementById("errorIdSignup1").textContent = 'Please accept terms and condition first.';
      // }
      if (err == false) {
        document.getElementById("errorIdSignup1").classList.remove("show");
        document.getElementById("errorIdSignup1").value = "";
        this.$store
          .dispatch(POST_API, {
            data: {
              //   first_name: 'Demo',
              //   last_name: 'Demo',
              first_name: this.fname1,
              last_name: this.lname1,
              comp_name: this.comp_name1,
              email: this.semail1,
              mobile_number: this.mobile_number1,
              password: this.spassword1,
              user_type: this.user_type,
              repassword: this.spassword1,
              register_from: "web",
              is_inquiry: "yes",
              popup: true,
              //   checkbox:$('#checkbox:checked').val() == 'true' ? true : false,
              checkbox: true,
            },
            api: "/api/signup",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignup1").classList.add("show");
              document.getElementById("errorIdSignup1").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignup1").classList.remove("show");
              document.getElementById("errorIdSignup1").value = "";
              var userTypeId = {
                user_type_id: this.user_type,
              };
              localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
              //   this.$swal({
              //                 position: 'center',
              //                 icon: 'success',
              //                 iconColor:'#4c8bf5',
              //                 title: 'Register successfully.',
              //                 showConfirmButton: false,
              //                 timer: 1500
              //     }).then(() => {
              this.slogin();

              //   this.$router.push({ name: 'login'});
              // })
              //   this.slogin();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },
    slogin() {
      var err = false;
      $(".required-field1").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail1,
              password: this.spassword1,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              document.getElementById("errorIdSignup").classList.add("show");
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              // this.$bvModal.hide('bv-modal-example');
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    getWebsiteData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            keyword: this.filter,
          },
          api: "/api/website-data",
        })
        .then(() => {
          //window.scrollTo(0,0);
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            //this.role_types = this.$store.getters.getResults.data;
            this.banners = this.$store.getters.getResults.banners;
            this.job_categories = this.$store.getters.getResults.job_categories;
            this.process_descriptions = this.$store.getters.getResults.process_descriptions;
            this.items = this.$store.getters.getResults.partners;
            this.promotions = this.$store.getters.getResults.promotions;
            this.jobs_count = this.$store.getters.getResults.jobs;
            this.gigs = this.$store.getters.getResults.gigs;
            this.dp_jobs_count = this.$store.getters.getResults.dp_jobs_count;
            this.ft_jobs_count = this.$store.getters.getResults.ft_jobs_count;
            this.companies_count = this.$store.getters.getResults.companies_count;
            this.users_count = this.$store.getters.getResults.users_count;

            this.dailyjob_datas = this.$store.getters.getResults.dailyjob_datas;
            this.dailyjob_datas_count = this.$store.getters.getResults.dailyjob_datas_count;

            this.testimonial = this.$store.getters.getResults.testimonial_emp;

            // this.jobs.forEach(element => {
            // 	if(element.tag_names){
            // 		element.tag_names_val = element.tag_names.split(',');
            // 	}
            // });
          }
        });
    },
    openLink() {
      window.open("https://elconnectptuser.app.link/OSbf0HwLOib", "_blank");
    },
    showAlert() {
      // window.scrollTo(0,0);
      this.dismissCountDown = this.dismissSecs;

      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    formSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            name: this.name,
            email: this.email,
            company_name: this.company_name,
            phone_number: "+65 " + this.phone_number,
          },
          api: "/api/get-in-touch-employer",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: this.success_message,
              showConfirmButton: false,
              timer: 1500,
            });
            (this.name = ""), (this.email = ""), (this.phone_number = ""), (this.company_name = "");
          }
        });
    },
  },
  mounted() {
    // $('.count').each(function () {
    //     $(this).text(0);
    // });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    window.scrollTo(0, 0);
    //  this.getWebsiteData();
    this.getWebsiteData().then(() => {
      async function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return elemBottom <= docViewBottom && elemTop >= docViewTop;
      }
      $(window).scroll(async function () {
        var element = document.getElementById("company-count");
        var value = element.textContent;
        //    console.log(value);
        $(".count").each(async function () {
          if ((await isScrolledIntoView(this)) === true) {
            $(this)
              .prop("Counter", 0)
              .animate(
                {
                  Counter: $(this).data("count"),
                },
                {
                  duration: 3000,
                  easing: "swing",
                  step: function (now) {
                    $(this).text(Math.ceil(now).toLocaleString("en-IN"));
                  },
                }
              );
          }
        });
      });
    });
    $("#slideshow > div:gt(0)").hide();

    // Function for changing to the next slide
    let nextSlide = (count, delay) => {
      // Set the delay on the slide
      setTimeout(() => {
        // Hide the current slide
        $("#slideshow > div:nth-of-type(" + count + ")")
          .hide()
          .removeClass("slide_dynamic_class");
        // If we've reached the end of the slides, set count back to 1,
        // otherwise, increment by 1
        count !== $("#slideshow > div").length ? count++ : (count = 1);
        // Show the next slide
        $("#slideshow > div:nth-of-type(" + count + ")")
          .show()
          .addClass("slide_dynamic_class");
        $(".slide_dynamic_class").css({ position: "relative", opacity: 0, left: "+=10000" });
        $(".slide_dynamic_class").animate({ left: 0, opacity: 1 });
        // Set the delay to the value of the data-delay attribute on the
        // slide elements
        delay = $("#slideshow > div:nth-of-type(" + count + ")").data("delay");
        // Call this function recursively
        nextSlide(count, delay);
      }, delay);
    };

    nextSlide(1, $("#slideshow > div:first").data("delay"));

    //Mobile Slide Start

    $("#slideshow-mobile > div:gt(0)").hide();

    // Function for changing to the next slide
    let nextSlideMobile = (count, delay) => {
      // Set the delay on the slide
      setTimeout(() => {
        // Hide the current slide
        $("#slideshow-mobile > div:nth-of-type(" + count + ")")
          .hide()
          .removeClass("slide_dynamic_class");
        // If we've reached the end of the slides, set count back to 1,
        // otherwise, increment by 1
        count !== $("#slideshow-mobile > div").length ? count++ : (count = 1);
        // Show the next slide
        $("#slideshow-mobile > div:nth-of-type(" + count + ")")
          .show()
          .addClass("slide_dynamic_class");
        $(".slide_dynamic_class").css({ position: "relative", opacity: 0, left: "+=10000" });
        $(".slide_dynamic_class").animate({ left: 0, opacity: 1 });
        // Set the delay to the value of the data-delay attribute on the
        // slide elements
        delay = $("#slideshow-mobile > div:nth-of-type(" + count + ")").data("delay");
        // Call this function recursively
        nextSlideMobile(count, delay);
      }, delay);
    };

    nextSlideMobile(1, $("#slideshow-mobile > div:first").data("delay"));

    //Mobile Slider End
  },

  components: {
    VueSlickCarousel,
    Carousel,
    Slide,
  },
};
</script>
