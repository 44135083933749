<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>User Logs</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>User Logs</li>
                </ul>
            </nav>
        </div>
                <div class="row">
                    <div class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <!-- <b-input-group> -->
                                        <!-- <br/> -->
                                        <b-input-group-prepend class="common_bg_white">
                                            <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search" class="common_bg_white"></b-form-input>
                                            <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="filter = searchKeyword"></i></b-button>
                                        </b-input-group-prepend>
                                        <!-- </b-input-group> -->
                                </div>
                    </div>
                    <!-- <div class="search-box custom_search_admin desktop_margin_top_zero">
                            <b-input-group-prepend>
                                <b-form-input id="searchBtn" v-model="filter" v-on:change="getTransaction" placeholder="Enter keyword to search"></b-form-input>
                                <b-button class="btn"><i class="fa fa-search"></i></b-button>
                            </b-input-group-prepend>
                        </div> -->
                        <div class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex  select-service-box date-picker-field clearfix col-xl-6 col-md-6 calender_z_index_9 calender_in_mobile  new_calender_section">
                            <!-- <div class="search-box custom_search_admin desktop_margin_top_zero"> -->
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <div>
                                        <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                
                                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">        
                                                <date-range-picker class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100" ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="getDataWithFilter()">
                                                <div slot="input" slot-scope="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </div>
                                            </date-range-picker> 
                                        </b-form-group>
                                    </div>
                                    <!-- </b-input-group> -->
                            <!-- </div> -->
                        </div> 
                        <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">
                            <b-button @click="clearFilter()" class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0" style="background-color: red; margin-top: 20px;"><span class="mobile_reset">Reset filters</span></b-button>
                        </div>
                </div>
            </div>
         </div>
        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container">
               <b-table ref="datatable" show-empty striped hover responsive :items="getTransaction" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="nric_three_grid_table_new mobile_min_width_1000 ">
                    <!-- <template #cell(edited_user)="items">
                        {{items.item.edited_last_name | capitalize}} {{items.item.edited_first_name | capitalize}}
                    </template> -->
                    <template #cell(edited_user)="items">
                        <p v-if="items.item.edited_last_name || items.item.edited_first_name">
                            <b-link v-if="items.item.edited_user_type_id == 5" :to="showFreelancer('view profile') ? {name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.user_id)}} : null" target="_blank" style="color: #4c8bf5;">
                                {{items.item.edited_last_name | capitalize}} {{items.item.edited_first_name | capitalize}}
                            </b-link>
                            <b-link v-else :to="showEmployer('view profile') ? {name: 'employer-profile-view', params: {id: encrypt(items.item.user_id),company_id: encrypt(items.item.edited_user_company_id)}} : null" target="_blank" style="color: #4c8bf5;">
                                {{items.item.edited_last_name | capitalize}} {{items.item.edited_first_name | capitalize}}
                            </b-link>
                        </p>
                    </template>
                    <template #cell(editor_user)="items">
                        {{items.item.editor_last_name | capitalize}} {{items.item.editor_first_name | capitalize}}
                    </template>
                    <template #cell(process)="items">
                        {{items.item.process | removeUnderscore}}
                    </template>
                    
                     <template #cell(edited_on)="items">
                        {{items.item.created_at | customFormatterOne}}
                    </template>
                    
                </b-table>
                <nav class="pagination-box">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getTransaction'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import permission from "../../../../../server/permission.js";

export default {
    data() {
        return {
            fields: [
                { key: 'edited_user', label: 'User Name', sortable: true },
                { key: 'editor_user', label: 'Edited By', sortable: true },
                { key: 'process', label: 'Process', sortable: true },
                { key: 'edited_on', label: 'Edited On', sortable: true },

            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            searchKeyword:'',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            dateRange: {
                startDate: '',
                endDate: ''
            },
            dateRange: {
                startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            auto: true,
            range: false,
            minDate: null,
            center: 'center',
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 0, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },
            actionsFreelancer:'',
            actionsEmployer:'',
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY');
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        date: function(date) {
            if(date){
                return moment(date).format('DD MMM YYYY');
            }else{
                return '-'
            }
        },
    },
    components: {
        DateRangePicker,
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                // this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },
        clearFilter(){
            this.filter = '';
            this.searchKeyword = '';
            this.dateRange.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD'),
            this.dateRange.endDate = moment().format('YYYY-MM-DD')
            this.$refs.datatable.refresh();
        },
        getDataWithFilter()
        {  
            this.$refs.datatable.refresh();
            // this.getTransaction().then(() => {
            //     this.$refs.datatable.refresh();
            // });
        
        },
        getTransaction() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    id:this.user_id,
                    rowsPerPage:this.form.rowsPerPage,
                    start_date:this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    end_date:this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                    sortBy: this.sortBy ? this.sortBy: 'user_logs.id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                },
                api: '/api/user-log'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actionsFreelancer = permission.getPermissionStaff(menu,'Jobseekers');
               this.actionsEmployer = permission.getPermissionStaff(menu,'Companies');
              }
            }
        },
        showFreelancer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsFreelancer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showEmployer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
       
    },
     mounted(){
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.permissionStaff();
            
     }
}
</script>
