<template>
    <div class="animated fadeIn">
        <div class="manage-job-section">
          <div
            class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
          >
            <div class="dashboard-headline">
              <h1 class="mr-auto">Freelancer Balance</h1>
            </div>
            <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                    <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
            </div> -->
          </div>
        </div>
        <b-row>
          <b-col lg="12">
            <div class="tab-box--head top_custom_head desktop_search_top_div">
              <div class="row">
                <div class="col-xl-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>
                <div class="col-xl-3 mobile_max_widht_and_flex search_common_margin_bottom_28">
                  <div class="select-service-box date-picker-field clearfix">
                    <!-- <div class="label_div text_align_end_desktop">
                              <label>Date</label>
                    </div> -->
                    <b-form-group id="input-group-7" class="required mb-0">
                      <datepicker
                        v-model="startDate"
                        :format="customFormatterTwo"
                        @input="dateFilterFunction()"
                        class="add_calender_icon common_normal_calender_height freelancer_incentive_calender"
                      ></datepicker>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-xl-3   search_common_margin_bottom_28 mobile_tab_max_width_flex">
                      <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                        <multiselect
                          :options="statusList"
                          :multiple="false"
                          placeholder="Status"
                          track-by="value"
                          label="label"
                          class="new_height_multiselect common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                          @input="selectEmployer"
                        >
                        </multiselect>
                      </b-form-group>
                </div>
                <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start"> -->
                <div class="col-xl-3 mobile_tab_max_width_flex search_common_margin_bottom_28">
                  <b-button
                    v-if="show('export')"
                    class="ml-0  common_export_btn common_z_index_zero button ripple-effect  desktop_margin_right_zero"
                    @click="exportData()"
                    >Export</b-button
                  >
                </div>
                <!-- </div> -->
              </div>
            </div>
            <div class="container my_new_container show_filter_arrow p-0">
              <b-table
                ref="datatable"
                show-empty
                striped
                hover
                responsive
                :items="getBalanceListing"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="new_layout_table_like_v1"
              >
                <template #cell(full_name)="items">
                  <b-link
                    :to="
                      show('view profile')
                        ? {
                            name: 'jobseeker-dp-profile-view',
                            params: { id: encrypt(items.item.user_id) },
                          }
                        : null
                    "
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                  {{ items.item.full_name || capitalize }}
                  </b-link>
                </template>
                <template #cell(status)="items">
                  {{items.item.status | capitalize}}
                </template>
                <template #cell(nric)="items">
                  {{items.item.nric | capitalize}}
                </template>
                <template #cell(is_ft_fl)="items">
                  {{items.item.is_fulltime | capitalize}}
                </template>
                <!-- <template #cell(curMonthBalance)="items">
                  {{ items.item.balance | if_number }}
                </template> -->
                <template #cell(amount)="items">
                  {{ items.item.balance | if_number }}
                </template>
              </b-table>
            </div>
            <nav class="pagination-box custom_pagination_border_radius">
              <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                  <span class="d-inline-block">Rows per page</span>
                  <span class="d-inline-block"
                    ><b-form-select v-model="form.rowsPerPage" @change="getBalanceListing">
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                    </b-form-select></span
                  >
                </b-form-group>
              </div>
              <div class="total-page-count d-inline-block">
                <ul>
                  <li>
                    <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                  </li>
                </ul>
              </div>
              <b-pagination
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                prev-text=""
                next-text=""
                hide-goto-end-buttons
              />
            </nav>
          </b-col>
        </b-row>
    </div>
  </template>
  <script>
  import { POST_API, GET_API } from "../../../store/actions.type";
  import JwtService from "../../../common/jwt.service";
  import moment from "moment";
  import Datepicker from "vuejs-datepicker";
  import Vue from "vue";
  import Multiselect from 'vue-multiselect'
  import permission from "../../../../../server/permission.js";
  
  export default {
    components: {
      Datepicker,
      Multiselect
    },
    data() {
      return {
        fields: [
          { key: "full_name", label: "Full Name", sortable: true, thStyle: { width: "20%" } },
          { key: "status", label: "Status", sortable: true, thStyle: { width: "20%" } },
          { key: "nric", label: "NRIC", sortable: true, thStyle: { width: "20%" } },
          { key: "is_ft_fl", label: "Is FT FL?", sortable: true, thStyle: { width: "20%" } },
          {
            key: "amount",
            label: "Balance",
            sortable: true,
            thStyle: { width: "20%" }
            
          }, // { key: "curMonthBalance", label: "Available Balance", sortable: true, thStyle: { width: "40%" } },
        ],
        form: {
          rowsPerPage: 50,
        },
        items: null,
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [25, 50, 75, 100],
        sortBy: null,
        sortDirection: "desc",
        filter: null,
        status: "all",
        statusList:[
        {
            label:"All",value:"all"
        }, 
        {
            label:"Active",value:"active"
        },
        {
            label:"Inactive",value:"inactive"
        },
        {
            label:"Blacklisted",value:"blacklisted"
        },
        {
            label:"Deleted",value:"deleted"
        }
        ],
        isBusy: false,
        sortDesc: true,
        to: "",
        from: "",
        dat: new Date(),
        webUrl: process.env.MIX_APP_URL,
        actions: "",
        user_type_id: "",
        startDate: new Date(moment().format("YYYY-MM-DD")),
        defaultDate: moment().subtract(1, "month").format("DD MMM YYYY"),
        disabledFromDate: {
          from: new Date(moment().subtract(1, "month")),
        },
        disabledFromDate1: {
          to: new Date(),
        },
        dynamicHeading: moment().subtract(1, "month").format("MMMM-YYYY"),
        dynamicHeading1: moment().subtract(2, "month").format("MMMM-YYYY"),
        withNoEmail: true,
        month: "",
        payslip_generate_for_user: "",
        webServerUrl: process.env.VUE_APP_SERVER_URL,
        actionsEmployer: "",
        searchKeyword:"",
  
      };
    },
    filters: {
      capitalize: function (value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      date_formate: function (date) {
        return moment(date).format("DD-MM-YYYY hh:mm A");
      },
      date: function (value) {
        if (value == null) {
          return "";
        } else {
          return moment(value).format("DD MMM YYYY");
        }
      },
      date1: function (value) {
        if (value == null) {
          return "-";
        } else {
          return moment(value).format("DD MMM YYYY");
        }
      },
      if_number: function (value) {
        if (!value) {
          return "0.00";
        }
        return parseFloat(value).toFixed(2);
      },
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      customFormatterOne(date) {
        return moment(date).format("MMMM-YYYY");
      },
      customFormatterTwo(date) {
        return moment(date).format("YYYY-MM-DD");
      },
      reset() {
        this.filter = null;
        this.$refs.picker.start = null;
        this.$refs.picker.end = null;
        this.getBalanceListing().then(() => {
          this.$refs.datatable.refresh();
        });
      },
      dateFilterFunction() {
        this.getBalanceListing().then(() => {
          this.$refs.datatable.refresh();
        });
        this.fields = [
          { key: "full_name", label: "Full Name", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "nric", label: "NRIC", sortable: true },
          { key: "is_ft_fl", label: "Is FT FL?", sortable: true },
          {
            key: "amount",
            label: "Balance",
            sortable: true,
            
          }, // { key: "curMonthBalance", label: "Available Balance", sortable: true, thStyle: { width: "40%" } },
        ]
      },
      selectEmployer(selectedOption){
      this.status=selectedOption.value
      this.$refs.datatable.refresh()
     },
      getBalanceListing() {
        return this.$store
          .dispatch(POST_API, {
            data: {
              page: this.currentPage,
              keyword: this.filter,
              rowsPerPage: this.form.rowsPerPage,
              sortBy: this.sortBy ? this.sortBy : "users.nric",
              sortDirection: this.sortDesc ? "desc" : "asc",
              sortDesc: this.sortDesc,
              status:this.status ? this.status:"all",
              start_date: moment(this.startDate).format("YYYY-MM-DD"),
            },
            api: "/api/fl-balance",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              this.items = this.$store.getters.getResults.pageOfItems;
              this.totalRows = this.$store.getters.getResults.pager.totalItems;
              this.perPage = this.$store.getters.getResults.pager.pageSize;
              this.from = this.$store.getters.getResults.pager.from;
              this.to = this.$store.getters.getResults.pager.to;
              return this.items;
            }
          });
      },
  
      downloadPdf(url) {
        window.open(url, "_blank");
      },
      exportData() {
        var base_url = this.webServerUrl;
        var page = this.currentPage;
        var rowsPerPage = this.form.rowsPerPage;
        var start_date = this.startDate ? moment(this.startDate).format("YYYY-MM-DD") : "null";
        var sortBy = this.sortBy ? this.sortBy : "users.nric";
        var sortDirection = this.sortDesc ? "desc" : "asc";
        var sortDesc= this.sortDesc;
        var status =this.status ? this.status:"all";
        var keyword = this.filter ? this.filter : null;
        var export_url =
          "/api/fl-balance-export/" +
          keyword +
          "/" +
          start_date +
          "/" +
          status +
          "/" +
          page +
          "/" +
          rowsPerPage +
          "/" +
          sortBy +
          "/" +
          sortDesc;
        window.open(base_url + export_url, "_blank");
        // window.open(export_url,'_blank')
      },
      showAlert() {
        // window.scrollTo(0,0);
        this.dismissCountDown = this.dismissSecs;
        Swal.fire({
          position: "top-center",
          type: "error",
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000,
        });
      },
  
      downloadAllPdf() {
        return this.$store
          .dispatch(POST_API, {
            data: {
              monthYear: this.defaultDate ? moment(this.defaultDate).format("MM-YYYY") : "",
              rowsPerPage: this.form.rowsPerPage,
              page: this.currentPage,
            },
            api: "/api/download-payslips-zip",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              var data = this.$store.getters.getResults.data;
              console.log(data);
              this.downloadFile(data.name, data.url);
            }
          });
      },
      downloadFile(name, path) {
        console.log(name, path);
        var file_path = path;
        var a = document.createElement("A");
        a.href = file_path;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      openModel(id) {
        this.payslip_generate_for_user = id;
        this.$refs["payslip-modal"].show();
      },
  
      withNoEmailFunc($event) {
        if ($event != null) {
          this.withNoEmail = $event;
        } else {
          this.withNoEmail = $event;
        }
      },
      hideModel() {
        this.$refs["payslip-modal"].hide();
      },
  
      
      permissionStaff() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.user_type_id == 2
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.actions = permission.getPermissionStaff(menu, "FL Balance");
          }
        }
      },
      show(action) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          return this.actions.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
      showEmployer(action){
              if (this.$store.getters.currentUser.user_type_id == 2) {
                  return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;  
              }else{
                  return true;
              }
          },
    },
    mounted() {
      $(".show_filter_arrow .table thead th div").contents().unwrap();
      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
      this.user_type_id = this.$store.getters.currentUser.user_type_id;
      this.permissionStaff();
      setTimeout(() => {
        this.fields = [
          { key: "full_name", label: "Full Name", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "nric", label: "NRIC", sortable: true },
          { key: "is_ft_fl", label: "Is FT FL?", sortable: true },
          {
            key: "amount",
            label: "Balance",
            sortable: true,
            
          }, // { key: "curMonthBalance", label: "Available Balance", sortable: true, thStyle: { width: "40%" } },
        ]
      }, 1000);
    },
  };
  </script>
  