<script setup>

onMounted(async () => {


 
});

</script>

<template>
  <body>
    <main>
     
      <h1>Thank you!</h1>
      
    </main>
  </body>
</template>