<template>
    <div class="animated fadeIn">
        <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit General Settings</h1>
            </div> -->
            <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit General Settings</h1>
            </div> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
        <div class="border-radius-4 white-bg common_padding_30">
            <b-form @submit="formSubmit">
                <!-- <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                    <span>{{ error_message }}</span>
                </b-alert> -->
                <div class="edit-form-box form-box-field desktop_margin_top_zero general_setting_admin_full">
                    <div v-for="(item,key) in items">
                        <b-form-group v-if="item.option_key != 'company_logo' && item.option_key != 'website_logo' " :id="item.option_key" :label="item.option_key | capitalize | removeUnderscore" class="required">
                            <b-form-input :id="item.option_value" name="item.option_value" type="text" v-model="item.option_value" class="with-border remove_box_shadow desktop_margin_bottom_zero">
                            </b-form-input>
                        </b-form-group>
                        <div class="upload-form-group" v-else-if="item.option_key == 'company_logo'">
                            <!-- <div class="col-auto">
                                <div class="avatar-wrapper" data-tippy-placement="bottom" title="Upload Company Logo">
                                    <img class="profile-pic" :src="form.company_logo != null ? form.company_logo : 'images/logo.png'" alt="" />
                                    <div class="upload-button"></div>
                                    <input class="file-upload" type="file" @change="onCompanyLogoChange" accept="image/*"/>
                                </div>
                            </div> -->
                            <b-form-group id="link-group" label="Upload Company Logo">
                                <div class="single-image-upload general_setting_image_upload_admin">
                                    <div class="uploadButton margin-top-0">
                                        <b-form-file  class="uploadButton-input" id="uploadCompany"  @change="onCompanyLogoChange" accept="image/*"></b-form-file>
                                        <label class="uploadButton-button ripple-effect" for="uploadCompany">Upload logo</label>
                                        <span class="uploadButton-file-name" id="companyLogoImage"></span>
                                         <b-img v-if='form.selectedCompanyLogo.image' :src="form.selectedCompanyLogo.image" :disabled="true" style="max-width: 150px;" alt="company_logo"></b-img>
                                        <b-img v-else :src="form.company_logo != null ? form.company_logo : 'images/logo.png'" alt="company_logo" ></b-img>
                                        <!-- <img class="uploadButton-file-name" :src="form.company_logo != null ? form.company_logo : 'images/logo.png'" alt="" /> -->
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="upload-form-group" v-else>
                            <b-form-group id="link-group" label="Upload Website Logo">
                                <div class="single-image-upload general_setting_image_upload_admin">
                                    <div class="uploadButton margin-top-0">
                                        <b-form-file  class="uploadButton-input" id="uploadWebsite"  @change="onWebsiteLogoChange" accept="image/*"></b-form-file>
                                        <label class="uploadButton-button ripple-effect" for="uploadWebsite">Upload logo</label>
                                        <span class="uploadButton-file-name" id="websiteLogoImage"></span>
                                        <!--<img class="uploadButton-file-name" :src="form.website_logo != null ? form.website_logo : 'images/logo.png'" alt="" />-->
                                        <span>
                                            <b-img v-if='form.selectedWebsiteLogo.image' :src="form.selectedWebsiteLogo.image" :disabled="true" style="max-width: 150px;" alt="website_logo"></b-img>
                                            <b-img v-else :src="form.website_logo != null ? form.website_logo : 'images/logo.png'" alt="website_logo" ></b-img>
                                        </span>
                                    </div>
                                </div>
                            </b-form-group>
                            <!-- <div class="col-auto">
                                <div class="avatar-wrapper" data-tippy-placement="bottom" title="Upload Website Logo">
                                    <img class="profile-pic" :src="form.website_logo != null ? form.website_logo : 'images/logo.png'" alt="website_logo" />
                                    <div class="upload-button"></div>
                                    <input class="file-upload" type="file" @change="onWebsiteLogoChange" accept="image/*"/>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="select-service-box date-picker-field clearfix w-75">
                            <b-form-group id="input-group-6" v-if="item.option_key == 'cashout_request_time_frame'"  label="Date Range:">
                                <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="dateFilterFunction('in-progress')" :timePicker="timePicker" :timePicker24Hour="timePicker24Hour" >
                                    <div slot="input" slot-scope="picker">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </div>
                                </date-range-picker>

                                <span class="input-icon">
                                    <b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                </span>
                            </b-form-group>
                        </div> -->
                    </div>
                    <div slot="footer" class="form-btn mt-3">
                        <b-button type="submit" variant="success" class="desktop_margin_left_zero desktop_margin_right_zero">Submit</b-button>
                        <b-button :to="{name:'general-setting-list'}" variant="light">Cancel</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {

    data() {
        return {
            form: {
                company_logo: [],
                website_logo: [],
                selectedCompanyLogo:{
                    name:'',
                    image:''
                },
                selectedWebsiteLogo:{
                    name:'',
                    image:''
                },
            },
            filter: null,
            items: null,
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            data: null,
            profile_image:'',
            website_image:'',
            selectedImage: '',
            selectedLogo: '',
            webUrl: process.env.MIX_APP_URL,
            dateRange: {
                startDate: '',
                endDate: ''
            },
            auto: true,
            center: 'center',
            minDate: null,
            range: false,
            date: moment().format('YYYY-MM-DD'),
            format: 'YYYY-MM-DD HH:mm',
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY HH:mm:ss', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },
            timePicker24Hour: true,
            timePicker: true,
            dateInput: {
                placeholder: "Select Date"
            },
            
        }
    },
    components: {
        DateRangePicker,
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            return value.split('_').join(' ');

        },
        date: function(value) {
            if (value == null) {
                return '';
            } else {

                return moment(value).utc().format('DD MMM YYYY hh:mm A');
            }
        },
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'general-setting-list'},
                text: 'General Settings',
            },{
                to:null,
                text: 'Edit General Settings'
            }];
            return item;
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'general-setting-list' });
            });
        },
        generalsettingsEdit() {
            return this.$store.dispatch(POST_API, {
                    data: {
                        page: this.currentPage,
                        keyword: this.filter,
                    },
                    api: '/api/general-setting-edit'

                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data;

                        for (var i = 0; i < this.items.length; i++) {
                            if(this.items[i]['option_key'] == 'company_logo')
                            {
                                // this.form.company_logo = this.items[i]['option_value'];
                                this.form.selectedCompanyLogo.image = this.items[i]['option_value'];

                            }
                            if(this.items[i]['option_key'] == 'website_logo')
                            {
                                // this.form.website_logo = this.items[i]['option_value'];
                                this.form.selectedWebsiteLogo.image = this.items[i]['option_value'];

                            }
                        }
                        return this.items;
                    }
                });
            return [];
        },
        
        onCompanyLogoChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.company_logo = input.files[0];
                
                if (this.form.company_logo.type != 'image/png' && this.form.company_logo.type != 'image/jpeg' && this.form.company_logo.type != 'image/jpg') {
                    this.form.selectedCompanyLogo.image = this.webUrl + '/images/default-user-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.selectedCompanyLogo.image = '';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.company_logo.size > 1048576) {

                    this.form.selectedCompanyLogo.image = this.webUrl + '/images/default-user-icon.svg'
                    this.error_message = 'Company Logo should be less than 1 MB.';
                    this.form.selectedCompanyLogo.image = '';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.modelBox = 'company_logo';
                        this.form.selectedCompanyLogo.image = e.target.result;
                        this.form.selectedCompanyLogo.name = this.form.company_logo.name;
                        $('#companyLogoImage').empty().append(this.form.selectedCompanyLogo.name)
                        event.target.value = '';
                    }
                }
            }
        },
        onWebsiteLogoChange(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.website_logo = input.files[0];
                
                if (this.form.website_logo.type != 'image/png' && this.form.website_logo.type != 'image/jpeg' && this.form.website_logo.type != 'image/jpg') {
                    this.form.selectedWebsiteLogo.image = this.webUrl + '/images/default-user-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.selectedWebsiteLogo.image = '';
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.website_logo.size > 1048576) {

                    this.form.selectedWebsiteLogo.image = this.webUrl + '/images/default-user-icon.svg'
                    this.error_message = 'Website Logo should be less than 1 MB.';
                    this.form.selectedWebsiteLogo.image = '';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.modelBox = 'website_logo';
                        this.form.selectedWebsiteLogo.image = e.target.result;
                        this.form.selectedWebsiteLogo.name = this.form.website_logo.name;
                        $('#websiteLogoImage').empty().append(this.form.selectedWebsiteLogo.name)
                        event.target.value = '';
                    }
                }
            }
        },
        
        formSubmit(evt) {
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        array: this.items,
                        // company_logo:this.form.company_logo,
                        // website_logo:this.form.website_logo,
                        company_logo:this.form.selectedCompanyLogo.image,
                        website_logo:this.form.selectedWebsiteLogo.image,
                        formItems:this.form,
                    },
                    api: '/api/general-setting-update'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        var message = 'General Setting Updated successfully.'
                        this.showSuccessAlert(message);
                        this.$router.push({ name: 'general-setting-list' });
                    }
                });
        },
       
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Admin - Edit General Settings";
        this.generalsettingsEdit();
    }
}

</script>
