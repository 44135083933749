




exports.getPermission = (menu,page) => {
    // console.log(menu,page,'dfgfdhhhhhhh')
    var page = page;

    var permission = '';

    menu.filter(function(item){
       item.userModules.filter(function(val){
        //    console.log('val.module.name',page,val,item)
           if (val.userModulesModules.name == page) {
               
               permission = val.action_value;
           }
       })
    });
    // console.log(permission,permission.length);

    var actions = permission ? permission.split(',') : [];
    // console.log(actions,'**');
    return actions;

};

exports.getPermissionStaff = (menu,page) => {
    // console.log(menu,page,'dfgfdhhhhhhh')
    var page = page;

    var permission = '';

    menu.filter(function(item){
       item.userModules.filter(function(val){
        //    console.log('val.module.name',page,val,item)
           if (val.userModulesModules.name == page) {
               
               permission = val.action_value;
           }
       })
    });
    // console.log(permission,permission.length);

    var actions = permission ? permission.split(',') : [];
    // console.log(actions,'**');
    return actions;

};

