import Sortable from 'sortablejs';

const draggabletable = {
  bind(el, binding, vnode) {
    const table = el;
    table._draggable = Sortable.create(table.querySelector('tbody'), {
      ...binding.value,
      onEnd: evt => {
        const data = vnode.context;
        const targetItem = data.items.splice(evt.oldIndex, 1)[0];
        data.items.splice(evt.newIndex, 0, targetItem);
      }
    });
  }
};

const draggabletags = {
  bind(el, binding, vnode) {
    const ul = el.querySelector('ul');
    ul._draggable = Sortable.create(ul, {
      ...binding.value,
      onEnd: evt => {
        const data = vnode.context;
        const targetItem = data.tags.splice(evt.oldIndex, 1)[0];
        data.tags.splice(evt.newIndex, 0, targetItem);
      }
    });
  }
};

export { draggabletable, draggabletags };
