<template>
    <div>
      
         <div class="row">
             
            <div class="container my_new_container">
                  <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>General Settings</h1>
            <!-- <b-link class="btn green-border-btn" :to="{name:'general-setting-edit'}">Edit</b-link> -->
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>General Settings</li>
                </ul>
            </nav>
        </div>
                        <div class="row">
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <!-- <b-input-group> -->
                                        <b-input-group-prepend>
                                            <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                            <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                                        </b-input-group-prepend>
                                        <!-- </b-input-group> -->
                                </div>
                            </div> 
                            <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28" v-if="user_type_id == 1 || user_type_id == 2">
                                <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" :to="{name:'general-setting-edit'}" v-if="show('edit settings')">Edit</b-link>
                            </div>
                        </div>
                </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <b-table ref="datatable" show-empty striped hover fixed responsive :items="getSettings" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 two_grid_tbl_new">
                    <template #cell(option_key)="items">
                        {{items.item.option_key | capitalize}}
                    </template>
                    <template #cell(option_value)="items">
                        <p v-if="items.item.option_key == 'company_logo' || items.item.option_key == 'website_logo'">
                            <img :src="items.item.option_value" height="100px" width="120px">
                        </p>
                        <p v-else>
                            {{items.item.option_value | trim}}
                        </p>
                    </template>
                    
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                             <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getSettings'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type';
import permission from "../../../../../server/permission.js";

export default {

    data() {
        return {
            fields: [
                { key: 'option_key', label: 'Key', sortable: true , thStyle:  {width: '10%'}},
                { key: 'option_value', label: 'Value', sortable: true , thStyle:  {width: '20%'}},
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            searchKeyword:'',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            user_type_id: '',
            actions:'',

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        trim: function(value) {
            value = value.toString()
            if (value.length < 50) {
                return value;
            } else {
                return value.substring(0, 50) + '...';
            }
        }
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'general-setting-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        getSettings() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/general-setting-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.items;
                    }
                });
        },

        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'General Settings');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
    },
    mounted() {
        this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');

        $(".show_filter_arrow .table thead th div").contents().unwrap();
        //document.title = "EL Connect - Admin - General Settings";
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        this.permissionStaff();

    },
    
}
</script>
