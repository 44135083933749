<template>
  <div id="app" :class="this.$route.name == 'SingpassData' ? 'hide_padding' : 'show_padding'">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>
