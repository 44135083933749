<template>
  <div>
    <div class="bg_lucky_draw1">
      <!-- <ChitHeaderImageComponent /> -->
      <LuckyDrawHeaderComponent />
      <div
        class="card_selection_bg_img_jbs"
        :style="{
          backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Pick-Coin-Page.png' + ')',
        }"
      >
        <b-container class="common_lucky_draw_container">
          <div>
            <img :src="webUrl + 'assets/jobeekerImages/Group 132.png'" class="card_selection_img elucky_draw_desktop" />
             <img :src="webUrl + 'assets/jobeekerImages/Frame-1948-1.png'" class="card_selection_img elucky_draw_mobile" />
            <!-- <img :src="webUrl + 'assets/jobeekerImages/Path 10.png'" style="z-index: 0;position:absolute;left: 47px;top: 10px;" class="bkg_card">
              <img :src="webUrl + 'assets/jobeekerImages/Path 10.png'" style="z-index: 0;position:absolute;transform: scaleX(-1 );top: 10px;left: 798px;" class="bkg_card2">
              <img :src="webUrl + 'assets/jobeekerImages/Path 11.png'" style="z-index: 0;position:absolute;transform: scaleX(-1 );top: 411px;left: 434px;" class="bkg_card3"> -->
            <div class="select_coin">
              <div class="cardbox lucky_draw_card_box">
                <div :class="cardOne">
                  <div
                    class="card__face card__face--front"
                    @click="clickCheck(1)"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Group62.png' + ')',
                    }"
                  >
                    <!-- <img :src="webUrl + 'assets/jobeekerImages/winning_logo.png'"> -->
                  </div>
                  <div
                    class="card__face card__face--back"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Group60.png' + ')',
                    }"
                  >
                    <div class="result_draw card_selection_amount">
                      <p>${{ result }}</p>
                    </div>
                  </div>
                </div>
                <div :class="cardTwo">
                  <div
                    class="card__face card__face--front"
                    @click="clickCheck(2)"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Group62.png' + ')',
                    }"
                  >
                    <!--  <img :src="webUrl + 'assets/jobeekerImages/winning_logo.png'"> -->
                  </div>
                  <div
                    class="card__face card__face--back"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Group60.png' + ')',
                    }"
                  >
                    <div class="result_draw card_selection_amount">
                      <p>${{ result }}</p>
                    </div>
                  </div>
                </div>
                <div :class="cardThree">
                  <div
                    class="card__face card__face--front"
                    @click="clickCheck(3)"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Group62.png' + ')',
                    }"
                  >
                    <!--  <img :src="webUrl + 'assets/jobeekerImages/winning_logo.png'"> -->
                  </div>
                  <div
                    class="card__face card__face--back"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Group60.png' + ')',
                    }"
                  >
                    <div class="result_draw card_selection_amount">
                      <p>${{ result }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="elucky_draw_desktop">
            <div class="best_of_luck_text">
              <span>GOOD LUCK!!</span>
              <img :src="webUrl + 'assets/jobeekerImages/mascot-left.png'" class="card_penguin_img" />
            </div>
          </div>
          <!-- <div class="box_tip_chit box_tip_chit_for_stage_two elucky_draw_mobile good_luck_mobile">
                      <div class="text_with_penguin_stage_two">
                          <span>GOOD LUCK!!</span>
                          <img :src="webUrl+'/assets/jobeekerImages/mascot-left.png'" class="rt_penguin">
                          </div>
                      </div> -->
          <p class="black_term elucky_draw_desktop">*Term & condition applies.</p>
        </b-container>
      
      </div>
      
    </div>
    <div class="elucky_draw_mobile">
          <div class="best_of_luck_text">
            <span>GOOD LUCK!!</span>
            <img :src="webUrl + 'assets/jobeekerImages/mascot-left.png'" class="card_penguin_img" />
          </div>
      </div>
      <b-container>
        <p class="black_term selection_p elucky_draw_mobile">*Term & condition applies.</p>
      </b-container>
  </div>
</template>

<script>
import { timingSafeEqual } from "crypto";
import { POST_API, GET_API } from "../../../store/actions.type";
import LuckyDrawHeaderComponent from "./LuckyDrawHeaderComponent.vue";
import ChitHeaderImageComponent from "./ChitHeaderImageComponent.vue";
export default {
  data() {
    return {
      webUrl: process.env.VUE_APP_URL,
      cardOne: ["cards"],
      cardTwo: ["cards"],
      cardThree: ["cards"],
      result: 0,
      playerID: atob(this.$route.params.id),
      picked_card: 0,
      count: 0,
    };
  },
  components: {
    LuckyDrawHeaderComponent,
    ChitHeaderImageComponent,
  },
  methods: {
    getRandom() {
      // let index = Math.floor(Math.random() * this.rewards.length);
      // this.result = this.rewards[index];
      // this.rewards = this.rewards.splice(0,index).concat(this.rewards.splice(index+1))
      return this.$store
        .dispatch(POST_API, {
          data: { id: this.playerID },
          api: "/api/elucky-draw-getRandNum",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            if (this.$store.getters.getErrors == "user already have selected reward") {
              this.$router.push({ name: "elucky-draw" });
            }
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.result = this.$store.getters.getResults.data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    transform(cardNum) {
      this.picked_card = cardNum;
      // console.log(this.picked_card)
      return this.$store
        .dispatch(POST_API, {
          data: {
            picked_card: this.picked_card,
            id: this.playerID,
          },
          api: "/api/elucky-draw-selection",
        })
        .then(() => {
          if (!this.$store.getters.containsErrors) {
            this.getRandom().then(() => {
              if (this.picked_card == 1) {
                this.cardOne.push("is-flipped");
              } else if (this.picked_card == 2) {
                this.cardTwo.push("is-flipped");
              } else {
                this.cardThree.push("is-flipped");
              }
              if (!this.$store.getters.containsErrors) {
                setTimeout(() => {
                  this.$router.push({
                    name: "elucky-draw-result",
                    params: { id: btoa(this.playerID), amount: btoa(this.result) },
                  });
                }, 2000);
              }
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    checkSelected() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/elucky-draw-checkSelectedCard",
        })
        .then(() => {
          if (!this.$store.getters.containsErrors) {
            var toFlip = this.$store.getters.getResults.data;
            // for (let i = 0; i < toFlip.length; i++) {
            //     if (toFlip[i] == 1) {
            //         this.cardOne = ['preflipped']
            //     } else if (toFlip[i] == 2) {
            //         this.cardTwo = ['preflipped']
            //     } else {
            //         this.cardThree = ['preflipped']
            //     }
            // }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    clickCheck(cardNum) {
      this.count = this.count + 1;
      this.cardOne.push("disabled");
      this.cardTwo.push("disabled");
      this.cardThree.push("disabled");
      if (this.count == 1) {
        this.transform(cardNum);
      } else {
        return;
      }
    },
  },
  // created() {
  //     this.checkSelected()
  // },
  mounted() {
    history.pushState(null, null, window.location.href);
    history.back();
    window.onpopstate = () => history.forward();
  },
};
</script>

<style>
.cardbox {
  /* width: 600px;
  height: 400px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, auto); */
}

.cards {
  position: relative;
  width: 150px;
  height: 250px;
  margin: auto;
  /* display: block; */
  transition: transform 2s;
  /* box-shadow: 5px 5px grey; */
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  border-radius: 10px;
}

.cards.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
}

.cards.disabled {
  cursor: not-allowed;
}

.preflipped {
  /* position: relative;
  width: 150px;
  height: 250px;
  margin: auto; */
  /* display: block; */
  /* transition: transform;
  box-shadow: 5px 5px grey; */
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  /* border-radius: 10px; */
  transform: translateX(-100%) rotateY(-180deg);
}

.card__face {
  /* border: 1px solid; */
  /* border-radius: 10px; */
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 250px;
  text-align: center;
  font-size: 40px;
  backface-visibility: hidden;
}

.card__face--front {
  /* background: rgb(177, 220, 20); */
}

.card__face--back {
  /* background: rgb(121, 121, 131); */
  transform: rotateY(180deg);
  cursor: not-allowed;
}
</style>
