var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg_lucky_draw1"},[_c('LuckyDrawHeaderComponent'),_c('div',{staticClass:"card_selection_bg_img_jbs",style:({
        backgroundImage: 'url(' + _vm.webUrl + 'assets/jobeekerImages/Pick-Coin-Page.png' + ')',
      })},[_c('b-container',{staticClass:"common_lucky_draw_container"},[_c('div',[_c('img',{staticClass:"card_selection_img elucky_draw_desktop",attrs:{"src":_vm.webUrl + 'assets/jobeekerImages/Group 132.png'}}),_c('img',{staticClass:"card_selection_img elucky_draw_mobile",attrs:{"src":_vm.webUrl + 'assets/jobeekerImages/Frame-1948-1.png'}}),_c('div',{staticClass:"select_coin"},[_c('div',{staticClass:"cardbox lucky_draw_card_box"},[_c('div',{class:_vm.cardOne},[_c('div',{staticClass:"card__face card__face--front",style:({
                    backgroundImage: 'url(' + _vm.webUrl + 'assets/jobeekerImages/Group62.png' + ')',
                  }),on:{"click":function($event){return _vm.clickCheck(1)}}}),_c('div',{staticClass:"card__face card__face--back",style:({
                    backgroundImage: 'url(' + _vm.webUrl + 'assets/jobeekerImages/Group60.png' + ')',
                  })},[_c('div',{staticClass:"result_draw card_selection_amount"},[_c('p',[_vm._v("$"+_vm._s(_vm.result))])])])]),_c('div',{class:_vm.cardTwo},[_c('div',{staticClass:"card__face card__face--front",style:({
                    backgroundImage: 'url(' + _vm.webUrl + 'assets/jobeekerImages/Group62.png' + ')',
                  }),on:{"click":function($event){return _vm.clickCheck(2)}}}),_c('div',{staticClass:"card__face card__face--back",style:({
                    backgroundImage: 'url(' + _vm.webUrl + 'assets/jobeekerImages/Group60.png' + ')',
                  })},[_c('div',{staticClass:"result_draw card_selection_amount"},[_c('p',[_vm._v("$"+_vm._s(_vm.result))])])])]),_c('div',{class:_vm.cardThree},[_c('div',{staticClass:"card__face card__face--front",style:({
                    backgroundImage: 'url(' + _vm.webUrl + 'assets/jobeekerImages/Group62.png' + ')',
                  }),on:{"click":function($event){return _vm.clickCheck(3)}}}),_c('div',{staticClass:"card__face card__face--back",style:({
                    backgroundImage: 'url(' + _vm.webUrl + 'assets/jobeekerImages/Group60.png' + ')',
                  })},[_c('div',{staticClass:"result_draw card_selection_amount"},[_c('p',[_vm._v("$"+_vm._s(_vm.result))])])])])])])]),_c('div',{staticClass:"elucky_draw_desktop"},[_c('div',{staticClass:"best_of_luck_text"},[_c('span',[_vm._v("GOOD LUCK!!")]),_c('img',{staticClass:"card_penguin_img",attrs:{"src":_vm.webUrl + 'assets/jobeekerImages/mascot-left.png'}})])]),_c('p',{staticClass:"black_term elucky_draw_desktop"},[_vm._v("*Term & condition applies.")])])],1)],1),_c('div',{staticClass:"elucky_draw_mobile"},[_c('div',{staticClass:"best_of_luck_text"},[_c('span',[_vm._v("GOOD LUCK!!")]),_c('img',{staticClass:"card_penguin_img",attrs:{"src":_vm.webUrl + 'assets/jobeekerImages/mascot-left.png'}})])]),_c('b-container',[_c('p',{staticClass:"black_term selection_p elucky_draw_mobile"},[_vm._v("*Term & condition applies.")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }