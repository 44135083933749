<template>
    <div>
       <div class="header_bg_chatbot">
        <div class="container chatbot_container">
            <div class="row">
                <div class="col-md-12">
                    <div class="chatbot_whole_div chatbot_dektop_div">
                        <div class="whole_chatbot_header">
                            <span class="material-icons-outlined back_arrow_chatbot">arrow_back_outlined</span>
                            <h2 class="chatbot_header_h2">Need assistance? Chat with us</h2>
                            <p class="chatbot_header_p"><span class="chatbot_status"></span>We reply immediately</p>
                        </div>
                        <!-- <div class="support_img_div_chatbot whole_chatbot_header">
                            <span class="img_span"><img :src="webUrl+'assets/chatbotImages/contact supp icon 2 1.png'"></span>
                        </div> -->
                    </div>
                    <div class="chatbot_mobile_div">
                        <div class="whole_chatbot_header">
                            <h2 class="chatbot_header_h2"><span class="material-icons-outlined back_arrow_chatbot">arrow_back_outlined</span>
                            <span>AI CHATBOT</span></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container chatbot_container scroll_container">
        <div class="row">
            <div class="col-md-12">
                <div class="chat_section">
                    <div class="ai_img">
                        <img :src="webUrl+'assets/chatbotImages/Frame 1283.png'">
                        <div class="chatbot_text_section">
                            <span class="chatbot_text_section_name">ai elcy</span>
                            <div class="left_side_box_message_chatbot">
                                <span>Hi there! I am AI chat assistant Elcy from EL Connect. How can i help you?</span>
                            </div>
                        </div>
                    </div>
                    <div class="chatbot_text_right_section">
                        <p class="user_name_chatbot">You</p>
                        <div class="right_side_box_message_chatbot">
                            <span>Hi can i know how to apply for job?</span>
                        </div>
                    </div>
                    <div class="ai_img">
                        <img :src="webUrl+'assets/chatbotImages/Frame 1283.png'">
                        <div class="chatbot_text_section">
                            <span class="chatbot_text_section_name">ai elcy</span>
                            <div class="left_side_box_message_chatbot">
                                <span>
										Apply for a job in 4 easy steps: 
										<ul>
										<li>Step 1: Download App</li>
										<li>Step 2: Create an account </li>
										<li>Step 3: Update your profile </li>
										<li>Step 4: Browse and apply</li>
									</ul>
								</span>
                            </div>
                        </div>
                    </div>
                    <div class="chatbot_text_right_section">
                        <p class="user_name_chatbot">You</p>
                        <div class="right_side_box_message_chatbot">
                            <span>Hi can i know why cant i apply for job? blabla</span>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="">
                    <div class="support_chatbox">
                        <a href="javascript:void(0);">Need help? Contact Support</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="textarea_chatbox_div">
        <div class="container chatbot_container">
            <div class="row">
                <div class="col-md-12">
                    <textarea class="inner_textarea" placeholder="Your Message"></textarea>
					<div class="send_icon">
						 <img :src="webUrl+'assets/chatbotImages/tablers.png'">
					</div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
  @import '/assets/css/chatbot.css';
</style>
<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        data() {
            return {
                webUrl: process.env.VUE_APP_URL
            }
        },
        methods:{
           
        },
        mounted(){
            window.scrollTo(0, 0);
        },
    }
</script>
