<template>
  <div>
       <div class="row">
         <div class="container my_new_container">
            
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Penalty Remark</h1>
            <!-- <b-link :to="{}" class="btn green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn" @click="clickToAdd('add')" v-if="show('upload-image')">
                Add
            </b-link> -->
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Penalty Remark</li>
                </ul>
            </nav>
        </div>
            <div class="row">
                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                        <!-- <b-input-group> -->
                            <b-input-group-prepend>
                                <b-form-input id="searchBtn" v-model="filter" v-on:change="getListing" placeholder="Enter keyword to search"></b-form-input>
                                <b-button class="btn"><i class="fa fa-search"></i></b-button>
                            </b-input-group-prepend>
                            <!-- </b-input-group> -->
                        </div>
                </div>
                <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                    <b-link :to="{}" class="btn green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" @click="clickToAdd('add')" v-if="show('add')">
                        Add
                    </b-link>
                </div>
            </div>
        </div>
       </div>
        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container">
              <b-table ref="datatable" show-empty striped hover responsive :items="getListing" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="el_coin_four_grid_table  mobile_min_width_1000  gallery_width">
                
                <template #cell(remarks)="items">
                  {{items.item.remarks}}
                </template>
                <template #cell(status)="items">
                  <p v-if="items.item.status == 'hold'">
                    {{items.item.status | capitalize}}
                    <span class="badge badge-danger">!</span>
                  </p>
                  <p v-else>
                    {{items.item.status | capitalize}}
                  </p>
                </template>
                <template #cell(created_at)="items">
                  {{items.item.created_at | moment}}
                </template>          
                <template #cell(toggleAction)="items">
                  <div class="toggle-action">
                    <b-dropdown class="mx-1" right text="Actions" boundary="window">
                        <b-dropdown-item v-on:click="updateRemark(items.item.id)" v-if="show('update')">Update
                        </b-dropdown-item>
                        <b-dropdown-item v-if="items.item.status == 'active' && show('activate/inactivate')" v-on:click="changeStatus(items.item.id,items.item.status)">Inactive
                        </b-dropdown-item>
                        <b-dropdown-item v-else-if="items.item.status == 'inactive' && show('activate/inactivate')" v-on:click="changeStatus(items.item.id,items.item.status)">Active
                        </b-dropdown-item>
                        <!-- <b-dropdown-item v-on:click="deleteRemark(items.item.id)">Delete
                        </b-dropdown-item> -->
                    </b-dropdown>

                  </div>
                </template>
              </b-table>
              <nav class="pagination-box">
            <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"> <b-form-select v-model="form.rowsPerPage" @change='getListing'>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                      </b-form-select></span>
                </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
                <ul>
                    <li>
                        <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                    </li>
                </ul>
            </div>
            <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
          </nav>
          </div>
          
		</div>
        
        <b-modal ref="remark-modal" :title="modalTitle" hide-footer content-class="common_model_header common_model_title"  modal-class="background_custom_class">
            <b-form @submit="formSubmit">
                 <div class="edit-form-box model_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <div class="form-map-box model_legend">
                                    <b-col lg="0" md="0">
                                    <b-form-group id="input-group-9" label="Remarks" class="required submit-field">
                                        <b-form-input id="input-9" maxlength="20" v-model="form.remarks" type="text" placeholder="" class="model_border_and_box_shadow"></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="0" md="0">
                                      <div slot="footer" class="form-btn common_crop_reset_btn">
                                          <b-button type="submit" variant="success" class="model_common_bt_success_color">Submit</b-button>
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                      </div>
                                    </b-col>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-form>
        </b-modal>
      
        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
        <!-- Row / End -->
  </div>
</template>
<script>
  import { POST_API} from "../../../store/actions.type";
  import moment from "moment";
  import permission from "../../../../../server/permission.js";



  export default {

    data() {
      return {
        fields: [
                { key: 'remarks', label: 'Remarks', sortable: true    },
                { key: 'status', label: 'Status', sortable: true},
                { key: 'created_at', label: 'CREATED ON', sortable: true},
                { key: 'toggleAction', label: 'Actions', thStyle:  {width: '10%'}},
            ],
            form: {
                rowsPerPage:25,
                remarks: '',
            },
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortBy: '',
            sortDesc: true,
            to:'',
            from:'',
            webUrl: process.env.VUE_APP_URL,
            actions:'',
            user_type_id:'',
            user_id:'',
            modalTitle: 'Add Remarks',
            type : 'add',
            id : '',
            actions:'',

      }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            if(date != null)
              return moment(date).utc().format('DD MMM YYYY hh:mm A');
            else
              return '-';
        },
    },
    methods: {
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'penalty-remark-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        
        clickToAdd(typeVal)
        {
          this.$refs['remark-modal'].show();
          this.modalTitle = typeVal == 'add' ? 'Add Remarks' : 'Update Remarks'
          this.type = typeVal == 'add' ? 'Add' : 'Update'

        },
        hideModel()
        {
          this.$refs['remark-modal'].hide();
          this.form.remark = '';
        },
        getListing() {
            return this.$store.dispatch(POST_API, { 
                   data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                   },
                   api: '/api/penalty-remark-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.items;
                    }
                });
        },

        formSubmit(evt) {
            evt.preventDefault();
            if(this.type == 'update'){
                var api = '/api/penalty-remark-update';
            }else{
                var api = '/api/penalty-remark-store';
            }

            this.$store.dispatch(POST_API, {
                    data: {
                        id: this.id,
                        remarks: this.form.remarks,
                    },
                    api: api,
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        if(this.type == 'update'){
                            var message='Remarks Updated successfully';
                        }else{
                            var message='Remarks Added successfully';
                        }
                        this.$refs.datatable.refresh();
                        this.showSuccessAlert(message);
                        this.hideModel();
                    }
                });
        },
    
        updateRemark(id){
            this.clickToAdd('update');
            this.type = 'update';
            this.id = id;

            return this.$store.dispatch(POST_API, { 
                data:{
                    id:id,
                },
                api: '/api/penalty-remark-edit'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    this.form.remarks = this.$store.getters.getResults.data.remarks;
                   
                }
            });
        },
        
        changeStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Remark Inactivated successfully.';
                var new_status = 'inactive';
            } 
            else{
                var msg = 'Are you sure you want to activate this record?';
                var message='Remark Activated successfully.';
                var new_status = 'active';

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            status:new_status,
                            page: this.currentPage,
                            keyword: this.filter
                            },
                            api:'/api/penalty-remark-status' 
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        
        deleteRemark(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/penalty-remark-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Remark Deleted successfully.';
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
    
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Penalty Remark');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },

    },
    mounted(){
        //document.title = "EL Connect - Admin - Website Contents - Gallery";
        this.user_type_id = this.$store.getters.currentUser.user_type_id;
        this.user_id = this.$store.getters.currentUser.id;
        this.permissionStaff();
    }
  }
  
</script>

