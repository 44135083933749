import ApiService from "../../api/api.service";
import JwtService from "../../common/jwt.service";

// import { SET_ERROR, CLEAR_ERRORS, SET_RESULT_DATA, CLEAR_RESULT_DATA, CURRENT_SECTION,SET_PERMISSION_DATA } from "../mutations.type";
import { SET_ERROR, CLEAR_ERRORS, SET_RESULT_DATA, CLEAR_RESULT_DATA, CURRENT_SECTION } from "../mutations.type";

import { SET_CURRENT_SECTION } from "../actions.type";

export default {
    state: {
        errors: [],
        resultData: [],
        currentSection:'',
    },
    mutations: {
        [CLEAR_ERRORS](state) {
            state.errors = [];
        },
        [SET_ERROR](state, { message }) {
            state.errors.push(message);
        },
        [SET_RESULT_DATA](state, data) {
            state.resultData = data.resultData;
        },
        // [SET_PERMISSION_DATA](state, data) {
        //     state.permissionData = data.permissionData;
        // },
        [CLEAR_RESULT_DATA](state) {
            state.resultData = [];
        },
        [CURRENT_SECTION](state, section) {
            state.currentSection = section;
        },
    },
    getters: {
        getErrors(state) {
            return state.errors[0];
        },
        getResults(state) {
            return state.resultData;
        },
        containsErrors(state) {
            return state.errors.length > 0;
        },
        getCurrentSection(state){
            return state.currentSection;
        }
    },
    actions: {
        [SET_CURRENT_SECTION](context, section) {
            context.commit(CURRENT_SECTION, { section: section });
        },
    }
}
        


