<template>
    <div>
           
        <!-- Titlebar
        ================================================== -->
        
        
        <div class="bg_img_top static_page_bg" v-bind:style="{backgroundImage: advertising_terms_and_conditions[0].image && advertising_terms_and_conditions[0].show_banner_image == 'yes' ? 'url('+ advertising_terms_and_conditions[0].image +')' : 'url('+ webUrl+ 'assets/images/faq-bg.jpeg)'}">
            <div class="full_gragient">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-center faq_haed" v-html="advertising_terms_and_conditions[0].title"></h1>
                    </div>
                </div>
            </div>
            </div>
        </div>
        

        <div class="margin-top-35"></div> 
    <!-- Page Content
        ================================================== -->
        <div class="container">
            <div class="row">
                <div class="col-xl-12 common_block common_desc">
                   <p v-html="advertising_terms_and_conditions[0].description"></p>
                </div>
            </div>
        </div>

        

        <!-- Spacer -->
        <div class="margin-top-35"></div>
        <!-- Spacer / End-->
    </div>
</template>


<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        metaInfo: {
            title: 'EL Connect - Advertising Terms & Conditions',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'Advertising Terms & Conditions - Want to advertise with our job platform? Simply contact us at support@elconnect.sg for details.' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                {property: 'og:title', content: 'EL Connect - Advertising Terms & Conditions'},
			    {property: 'og:site_name', content: 'EL Connect'},
			    {property: 'og:type', content: 'website'},
			    {property: 'og:url', content: 'https://elconnect.sg/advertising-terms-and-conditions'},
			    {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			    {property: 'og:description', content: 'Advertising Terms & Conditions - Want to advertise with our job platform? Simply contact us at support@elconnect.sg for details.'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/advertising-terms-and-conditions'}
  		    ]
        },
        data() {
            return {
                advertising_terms_and_conditions:[],
                showDismissibleAlert:false,
                error_message:'',
                display:true,
                link_error:'',
                webUrl: process.env.VUE_APP_URL,
            }
        },
        methods:{
            getAdvertisingTermsConditions(){
                 this.$store.dispatch(POST_API, {
                    data:{
                        token: this.email,
                    },
                    api:'/api/advertising-terms-and-conditions'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.advertising_terms_and_conditions = this.$store.getters.getResults.advertising_terms_and_conditions;
                    }
            })

            }
        },
        mounted(){
            //document.title = "EL Connect - Privacy Policy"; 
            this.getAdvertisingTermsConditions();
            window.scrollTo(0, 0)
        },
    }
</script>
